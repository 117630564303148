import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const quantitySelector: ComponentTemplate = {
  id: "07f46e24-b3dd-44d0-b19b-0d1c29ea6722",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Quantity Selector",
  leftBarCategory: { main: "product", subCategory: "productData" },
  tags: ["product", "product quantity selector"],
  preview: "product/quantity-selector.svg",
  template: {
    id: "07f46e24-b3dd-44d0-b19b-0d1c29ea6722",
    type: "quantitySelector",
    props: {
      style: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        maxWidth: "100%",
        alignSelf: "stretch",
        __flexGap: "24px",
        borderTopColor: "#000000FF",
        borderRightColor: "#000000FF",
        borderBottomColor: "#000000FF",
        borderLeftColor: "#000000FF",
        borderTopStyle: "solid",
        borderLeftStyle: "solid",
        borderBottomStyle: "solid",
        borderRightStyle: "solid",
        borderTopWidth: "3px",
        borderRightWidth: "3px",
        borderBottomWidth: "3px",
        borderLeftWidth: "3px",
        paddingRight: "12px",
        paddingLeft: "12px",
      },
    },
    children: [
      {
        id: "a0ae4d25-8aec-437b-b30f-057ca6b6dd9b",
        type: "icon",
        props: {
          iconName: "minus",
          style: { width: "24px", height: "24px" },
          onClick: [
            {
              id: "493102b3-9685-47c3-9659-eaed116c36c5",
              type: "decreaseProductQuantity",
              value: 1,
            },
          ],
        },
      },
      {
        id: "9c0a7262-76ce-472a-955c-49de7b866242",
        type: "text",
        props: {
          text: "{{attributes._quantity}}",
          style: {
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "48px",
            width: null,
          },
        },
      },
      {
        id: "a0712f15-5120-47a9-9d57-f79c17534f39",
        type: "icon",
        props: {
          iconName: "plus",
          style: { width: "24px", height: "24px" },
          onClick: [
            {
              id: "2b93c040-cdb1-4b81-8493-6d35301a5281",
              type: "increaseProductQuantity",
              value: 1,
            },
          ],
        },
      },
    ],
    name: "Quantity Selector",
  },
};
