import * as React from "react";

import {
  SkeletonLoaderItem,
  SkeletonLoaderWrapper,
} from "@editor/components/common/designSystem/SkeletonLoader";

export const PreviewLinkAndSVGSkeleton: React.FC = () => {
  return (
    <SkeletonLoaderWrapper
      className="bg-slate-100 rounded-md"
      width={200}
      height={200}
    >
      <SkeletonLoaderItem yAxis="0" width="300" height="300" />
    </SkeletonLoaderWrapper>
  );
};

export const TemplateProductSelectorSkeleton: React.FC = () => {
  return (
    <SkeletonLoaderWrapper
      className="box-border bg-white absolute bottom-2 rounded"
      speed={2}
      height={24}
      width={232}
      backgroundColor="#e2e8f0"
      foregroundColor="#cbd5e1"
    >
      <SkeletonLoaderItem yAxis="0" width="256" height="24" />
    </SkeletonLoaderWrapper>
  );
};

export const WorkspaceCardSkeleton: React.FC = () => {
  return (
    <SkeletonLoaderWrapper
      className="box-border bg-white rounded"
      speed={2}
      height={68}
      width={275}
      backgroundColor="#e2e8f0"
      foregroundColor="#cbd5e1"
    >
      <circle cx="30" cy="30" r="19" />
      <SkeletonLoaderItem yAxis="9" xAxis="60" width="160" height="20" />
      <SkeletonLoaderItem yAxis="36" xAxis="60" width="160" height="16" />
    </SkeletonLoaderWrapper>
  );
};
