import type { SvgProps } from "replo-utils/react/components";
import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { Svg } from "replo-utils/react/components";

import { useComponentClassNames } from "../../../shared/utils/renderComponents";

const StarRating: React.FC<RenderComponentProps> = ({
  componentAttributes: attributes,
  component,
  context,
}) => {
  let rating = component.props._rating ?? 5;
  let totalStars = component.props._totalStars ?? 5;
  const starColor = component.props._starColor;
  const size = component.props._size ?? 16;

  // NOTE (Evan, 7/27/23) Due to a bug in the custom prop modifiers, these
  // may be strings in production. While this hasn't been an issue, we parse
  // these just to be sure.
  if (typeof rating === "string") {
    rating = Number.parseFloat(rating);
  }
  if (typeof totalStars === "string") {
    totalStars = Number.parseInt(totalStars);
  }
  const modifiedRating = Math.ceil(rating * 2) / 2;
  const filledStars = Math.floor(modifiedRating);
  const hasHalfStar = modifiedRating > Math.floor(modifiedRating);
  const emptyStars = totalStars - filledStars - (hasHalfStar ? 1 : 0);

  const classNameMap = useComponentClassNames("starRating", component, context);

  /**
   * Note (Noah, 2022-10-11, REPL-4487): We add the style prop as well as the width/height/color props
   * because some themes can have svg styling CSS that would otherwise override these props
   */
  return (
    <div {...attributes}>
      {Array.from({ length: filledStars }, (_, index) => (
        <StarFill
          width={size}
          height={size}
          fill={starColor}
          key={index}
          className={classNameMap?.icon}
        />
      ))}
      {hasHalfStar && (
        <StarFillHalf
          width={size}
          height={size}
          fill={starColor}
          className={classNameMap?.icon}
        />
      )}
      {Array.from({ length: emptyStars }, (_, index) => (
        <StarOutline
          width={size}
          height={size}
          fill={starColor}
          key={index}
          className={classNameMap?.icon}
        />
      ))}
    </div>
  );
};

function StarFill(props: SvgProps) {
  return (
    <Svg
      width={16}
      height={16}
      fill="currentColor"
      className="bi bi-star-fill"
      viewBox="0 0 16 16"
      {...props}
    >
      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
    </Svg>
  );
}

function StarFillHalf(props: SvgProps) {
  return (
    <Svg
      width={16}
      height={16}
      fill="currentColor"
      className="bi bi-star-half"
      viewBox="0 0 16 16"
      {...props}
    >
      <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z" />
    </Svg>
  );
}

function StarOutline(props: SvgProps) {
  return (
    <Svg
      width={16}
      height={16}
      fill="currentColor"
      className="bi bi-star"
      viewBox="0 0 16 16"
      {...props}
    >
      <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
    </Svg>
  );
}

export default StarRating;
