import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";

import React from "react";

import InlinePopover from "@common/designSystem/InlinePopover";
import { Input } from "@common/designSystem/Input";
import SelectionIndicator from "@common/designSystem/SelectionIndicator";
import FormFieldXButton from "@common/FormFieldXButton";
import SavedStyleRow from "@components/designLibrary/SavedStyleRow";
import TextSavedStyleModifier from "@editor/components/designLibrary/TextSavedStyleModifier";
import useGetDesignLibrarySavedStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import {
  generateTextSavedStylePreviewValue,
  getSavedStyleValue,
} from "@editor/utils/designLibrary";

import { Badge } from "@replo/design-system/components/badge";
import { BsSearch, BsSliders, BsType } from "react-icons/bs";

type DesignLibraryTextValueIndicatorProps = {
  savedStyleValueReference: string;
  onSavedStyleSelect(value: string): void;
  onRemove?(): void;
  popoverSideOffset?: number;
};

const DesignLibraryTextValueIndicator: React.FC<
  DesignLibraryTextValueIndicatorProps
> = ({
  savedStyleValueReference,
  onSavedStyleSelect,
  onRemove,
  popoverSideOffset,
}) => {
  const { textSavedStyles } = useGetDesignLibrarySavedStyles();
  const textSavedStyleName = getSavedStyleValue(
    textSavedStyles,
    savedStyleValueReference,
  )?.name;

  const isTextSavedStyleSelected = Boolean(textSavedStyleName);
  const shouldRenderXButton = onRemove && isTextSavedStyleSelected;

  return (
    <InlinePopover
      shouldPreventDefaultOnInteractOutside={false}
      title="Text Saved Style"
      content={
        <TextValueIndicatorPopoverContent
          onSavedStyleSelect={onSavedStyleSelect}
        />
      }
      sideOffset={popoverSideOffset}
      triggerAsChild
    >
      <SelectionIndicator
        title={textSavedStyleName}
        startEnhancer={<TextBadge />}
        placeholder="Select..."
        endEnhancer={
          shouldRenderXButton && <FormFieldXButton onClick={onRemove} />
        }
        style={{
          width: "100%",
        }}
      />
    </InlinePopover>
  );
};

const TextValueIndicatorPopoverContent: React.FC<{
  onSavedStyleSelect: (value: string) => void;
}> = ({ onSavedStyleSelect }) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const { textSavedStyles, designLibrary } = useGetDesignLibrarySavedStyles();
  const [isTextModifierPopoverOpen, setIsTextModifierPopoverOpen] =
    React.useState(false);
  const [editingSavedStyleId, setEditingSavedStyleId] = React.useState<
    string | null
  >(null);

  const filteredTextSavedStyles = textSavedStyles.filter((style) =>
    style.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div className="flex flex-col gap-2 w-full">
      <TextSavedStyleModifier
        isPopoverOpen={isTextModifierPopoverOpen}
        onPopoverOpenChange={(isOpen) => {
          if (!isOpen) {
            setEditingSavedStyleId(null);
          }
          setIsTextModifierPopoverOpen(isOpen);
        }}
        editingSavedStyle={
          textSavedStyles.find(
            (savedStyle) => savedStyle.id === editingSavedStyleId,
          ) ?? null
        }
        sideOffset={13}
        openedFrom="rightBar"
        alignOffset={-120}
      />
      <Input
        size="sm"
        value={searchTerm}
        startEnhancer={() => <BsSearch size={10} />}
        endEnhancer={() =>
          searchTerm?.trim() && (
            <FormFieldXButton onClick={() => setSearchTerm("")} />
          )
        }
        placeholder="Search saved styles..."
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <div className="flex flex-col gap-1 max-h-28 overflow-y-auto styled-scrollbar">
        {filteredTextSavedStyles.map((savedStyle) => (
          <SavedStyleRow
            key={savedStyle.id}
            name={savedStyle.name}
            value={generateTextSavedStylePreviewValue(
              savedStyle.attributes as SavedStyleTextAttributes,
            )}
            type="text"
            onClick={() =>
              onSavedStyleSelect?.(
                `library.${designLibrary?.id}.styles.${savedStyle.id}.attributes`,
              )
            }
            endEnhancer={
              <BsSliders
                className="text-black rotate-90"
                onClick={(e) => {
                  e.stopPropagation();
                  setEditingSavedStyleId(savedStyle.id);
                  setIsTextModifierPopoverOpen(true);
                }}
              />
            }
          />
        ))}
      </div>
    </div>
  );
};

const TextBadge: React.FC = () => {
  return <Badge type="icon" icon={<BsType className="text-subtle" />} />;
};

export default DesignLibraryTextValueIndicator;
