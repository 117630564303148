import * as React from "react";

import { Chip } from "@editor/components/common/designSystem/Chip";
import { useGlobalEditorActions } from "@editor/hooks/useGlobalEditorActions";
import { useReploHotkeys } from "@editor/hooks/useHotkeys";
import { useVisibleBreakpoints } from "@editor/hooks/useVisibleBreakpoints";
import { canvasToStyleMap } from "@editor/utils/editor";
import DocumentationInfoIcon from "@editorComponents/DocumentationInfoIcon";
import ModifierGroup from "@editorExtras/ModifierGroup";

import { BsFillCheckCircleFill } from "react-icons/bs";

const options = [
  {
    value: canvasToStyleMap.mobile,
    label: "Mobile",
    tooltipText: "Toggle mobile visibility",
  },
  {
    value: canvasToStyleMap.tablet,
    label: "Tablet",
    tooltipText: "Toggle tablet visibility",
  },
  {
    value: canvasToStyleMap.desktop,
    label: "Desktop",
    tooltipText: "Toggle desktop visibility",
  },
];

export const VisibilityModifier = () => {
  useVisibilityHotkeys();

  return (
    <ModifierGroup
      title="Visibility"
      titleEnhancer={<DocumentationInfoIcon documentationType="visibility" />}
    >
      <div className="flex w-full flex-row gap-1 rounded-md">
        {options.map((option) => (
          <VisibilityOption key={option.value} {...option} />
        ))}
      </div>
    </ModifierGroup>
  );
};

const VisibilityOption: React.FC<{
  label: string;
  value: string;
  tooltipText: string;
}> = ({ label, value, tooltipText }) => {
  const { visibleBreakpoints, onChangeVisibility } = useVisibleBreakpoints();
  const isSelected = visibleBreakpoints?.includes(value);

  return (
    <Chip
      tooltipText={tooltipText}
      isSelected={isSelected}
      startEnhancer={isSelected ? <BsFillCheckCircleFill size={12} /> : null}
      title={label}
      className="text-xs px-2 font-normal"
      onClick={() => {
        onChangeVisibility(value);
      }}
    >
      <span data-testid={`visibility-toggle-${label}`}>{label}</span>
    </Chip>
  );
};

function useVisibilityHotkeys() {
  const globalActions = useGlobalEditorActions();

  useReploHotkeys({
    toggleVisibility: globalActions.toggleVisibility,
  });
}
