import type { ShopifyComponentCommonProps } from "../../shared/types";

import * as React from "react";

import composeRefs from "@seznam/compose-react-refs";

import {
  RenderEnvironmentContext,
  RuntimeHooksContext,
  useRuntimeContext,
} from "../../shared/runtime-context";
import { getAlchemyGlobalPaintContext } from "../../shared/Window";
import RenderComponentPlaceholder from "./RenderComponentPlaceholder";
import ReploLiquidChunk from "./ReploLiquid/ReploLiquidChunk";

interface SharedShopifyLiquidProps extends ShopifyComponentCommonProps {
  liquidSource: string | null;
  placeholder?: string;
  repeatedIndexPath?: string;
  forceEditorPlaceholder: boolean;
  placeholderStyles?: React.CSSProperties;
}

export const SharedShopifyLiquid = React.forwardRef(
  function SharedShopifyLiquidWithRef(
    {
      liquidSource,
      componentId,
      componentAttributes,
      isLiquidSupported,
      placeholder = "Your custom Shopify Liquid content will appear here.",
      repeatedIndexPath,
      forceEditorPlaceholder,
      placeholderStyles,
    }: React.PropsWithChildren<SharedShopifyLiquidProps>,
    ref: React.Ref<HTMLDivElement>,
  ) {
    const renderedLiquid =
      useRuntimeContext(RuntimeHooksContext).useRenderedLiquid(liquidSource);
    const isRenderLiquidLoading =
      useRuntimeContext(RuntimeHooksContext).useIsRenderLiquidLoading(
        liquidSource,
      );
    const requestRenderLiquid =
      useRuntimeContext(RuntimeHooksContext).useRequestRenderLiquid();
    const { isEditorApp, isPublishing } = useRuntimeContext(
      RenderEnvironmentContext,
    );
    const [initialLiquid] = React.useState(liquidSource);
    const globalContext = getAlchemyGlobalPaintContext();

    const prerenderedNodeId = repeatedIndexPath
      ? `${componentId}-${repeatedIndexPath}`
      : componentId;
    const prerenderedLiquidNode =
      globalContext?.prerenderedNodes?.[prerenderedNodeId];

    const cachedNode = globalContext?.prerenderedNodes?.[prerenderedNodeId];
    const autoUpdatingRef = React.useCallback(
      (node: HTMLDivElement | null) => {
        if (!node) {
          return;
        }

        const placeholder = node.querySelector(
          "[data-alchemy-prerendered-placeholder]",
        );
        if (placeholder) {
          placeholder.remove();
        }
        if (cachedNode) {
          while (node.firstChild) {
            node.firstChild.remove();
          }
          // Insert the cached node we stored when the Replo element
          // first rendered.
          node.append(cachedNode);
        }
      },
      [cachedNode],
    );

    React.useEffect(() => {
      // NOTE (Martin, 2023-02-10): If there is liquid source already, lets render it.
      if (liquidSource) {
        requestRenderLiquid?.(liquidSource);
      }
    }, [liquidSource, requestRenderLiquid]);

    if (isLiquidSupported === false) {
      return (
        <div {...componentAttributes}>
          <RenderComponentPlaceholder
            style={placeholderStyles}
            title="Shopify Liquid content is not enabled for Shopify Articles"
          />
        </div>
      );
    }

    const isEditorAndForcingPlaceholder = isEditorApp && forceEditorPlaceholder;
    const shouldShowPlaceholder =
      isEditorApp &&
      !isPublishing &&
      (!prerenderedLiquidNode || initialLiquid !== liquidSource);

    if (shouldShowPlaceholder || isEditorAndForcingPlaceholder) {
      if (renderedLiquid && !isEditorAndForcingPlaceholder) {
        return (
          <div
            {...componentAttributes}
            ref={ref}
            data-replo-editor-liquid={true}
            dangerouslySetInnerHTML={{ __html: renderedLiquid }}
          />
        );
      }

      return (
        <div {...componentAttributes}>
          <RenderComponentPlaceholder
            style={placeholderStyles}
            isLoading={isRenderLiquidLoading}
            title={placeholder}
          />
        </div>
      );
    }

    let content = (
      <div
        style={{ width: "100%", height: "100%" }}
        data-alchemy-prerendered-placeholder
        dangerouslySetInnerHTML={{
          __html: isPublishing && liquidSource ? liquidSource : "",
        }}
      />
    );

    // NOTE (Matt 2024-07-02): We only wrap in a liquid chunk when publishing,
    // because that is the only time we'll be using the liquidSource.
    // If we have gotten to this point in the function, then we are either
    // generating the pre-hydrated html/liquid code, or we are hydrating
    // the element on the published page.
    if (isPublishing) {
      content = <ReploLiquidChunk>{content}</ReploLiquidChunk>;
    }

    return (
      <div
        {...componentAttributes}
        data-alchemy-prerendered-component-id={prerenderedNodeId}
        ref={composeRefs(ref, autoUpdatingRef)}
      >
        {content}
      </div>
    );
  },
);
