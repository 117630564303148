import type { RightBarTab } from "@editor/reducers/ui-reducer";

import * as React from "react";

import { TextTab } from "@editor/components/common/TextTab";
import {
  selectDraftComponentId,
  selectDraftComponentRightBarTabs,
  selectDraftComponentTypeIsModal,
} from "@editor/reducers/core-reducer";
import {
  selectRightBarActiveTab,
  setRightBarActiveTab,
} from "@editor/reducers/ui-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";

const TabOptions: React.FC<
  React.PropsWithChildren<{
    currentTab: RightBarTab;
    containerClassName?: string;
  }>
> = ({ currentTab, containerClassName }) => {
  const dispatch = useEditorDispatch();
  useResetActiveTab();

  const tabOptions = useEditorSelector(selectDraftComponentRightBarTabs);
  if (!tabOptions) {
    return null;
  }

  return (
    <TextTab
      options={tabOptions}
      selectedValue={currentTab}
      className="py-2"
      containerClassName={containerClassName}
      onChange={(value: RightBarTab) => {
        dispatch(setRightBarActiveTab(value));
      }}
    />
  );
};

/**
 * Hook to reset active tab when the draft component changes
 */
function useResetActiveTab() {
  const isModal = useEditorSelector(selectDraftComponentTypeIsModal);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const dispatch = useEditorDispatch();
  const store = useEditorStore();

  // biome-ignore lint/correctness/useExhaustiveDependencies(draftComponentId): I think we want this? Maybe not
  React.useEffect(() => {
    const state = store.getState();
    const currentTab = selectRightBarActiveTab(state);
    if (
      !(selectDraftComponentRightBarTabs(state) ?? [])
        .filter((tab) => tab.isVisible ?? false)
        .map((tab) => tab.value)
        .includes(currentTab)
    ) {
      // Always set the tab to the design tab when you select a new component
      // unless it's a modal, because it doesn't have a design tab
      dispatch(setRightBarActiveTab(isModal ? "custom" : "design"));
    }
  }, [isModal, dispatch, draftComponentId, store]);
}

export default TabOptions;
