import type { SvgProps } from "replo-utils/react/components";

import * as React from "react";

import { Svg } from "replo-utils/react/components";

interface FixedWidthProps extends SvgProps {
  isActive: boolean;
}

export const FixedWidth = ({ isActive, ...props }: FixedWidthProps) => {
  if (isActive) {
    return (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 113 48"
        width="90"
        {...props}
      >
        <g clipPath="url(#clip0_194_2537)">
          <rect x="8" y="21" width="12" height="12" rx="6" fill="#BFDBFE" />
          <path
            d="M13.6486 27.0002L15.1335 25.5153L14.7093 25.091L12.8001 27.0002L14.7093 28.9094L15.1335 28.4851L13.6486 27.0002Z"
            fill="#1E40AF"
          />
          <rect
            x="22"
            y="9"
            width="31.3333"
            height="36"
            rx="4"
            fill="#2563EB"
          />
          <path
            d="M38.6522 23.7273V31H37.7715V24.6506H37.7289L35.9533 25.8295V24.9347L37.7715 23.7273H38.6522Z"
            fill="#60A5FA"
          />
          <rect
            x="55.3333"
            y="9"
            width="31.3333"
            height="36"
            rx="4"
            fill="#2563EB"
          />
          <path
            d="M68.7255 31V30.3608L71.1261 27.733C71.4078 27.4252 71.6398 27.1577 71.8221 26.9304C72.0044 26.7008 72.1393 26.4853 72.2269 26.2841C72.3169 26.0805 72.3619 25.8674 72.3619 25.6449C72.3619 25.3892 72.3003 25.1679 72.1772 24.9808C72.0565 24.7938 71.8907 24.6494 71.68 24.5476C71.4693 24.4458 71.2326 24.3949 70.9698 24.3949C70.6905 24.3949 70.4466 24.4529 70.2383 24.5689C70.0323 24.6825 69.8725 24.8423 69.7589 25.0483C69.6476 25.2543 69.592 25.4957 69.592 25.7727H68.7539C68.7539 25.3466 68.8522 24.9725 69.0487 24.6506C69.2451 24.3286 69.5127 24.0777 69.8512 23.8977C70.1921 23.7178 70.5745 23.6278 70.9982 23.6278C71.4244 23.6278 71.802 23.7178 72.131 23.8977C72.4601 24.0777 72.7182 24.3203 72.9052 24.6257C73.0922 24.9311 73.1857 25.2708 73.1857 25.6449C73.1857 25.9124 73.1372 26.174 73.0401 26.4297C72.9454 26.683 72.7797 26.9659 72.543 27.2784C72.3086 27.5885 71.9831 27.9673 71.5664 28.4148L69.9329 30.1619V30.2188H73.3136V31H68.7255Z"
            fill="#60A5FA"
          />
          <rect
            x="88.6667"
            y="9"
            width="31.3333"
            height="36"
            rx="4"
            fill="#2563EB"
          />
          <path
            d="M104.36 31.0994C103.891 31.0994 103.474 31.0189 103.107 30.858C102.742 30.697 102.452 30.4732 102.237 30.1868C102.023 29.898 101.907 29.563 101.889 29.1818H102.783C102.802 29.4162 102.883 29.6186 103.025 29.7891C103.167 29.9571 103.353 30.0874 103.582 30.1797C103.812 30.272 104.067 30.3182 104.346 30.3182C104.658 30.3182 104.935 30.2637 105.177 30.1548C105.418 30.0459 105.608 29.8944 105.745 29.7003C105.882 29.5062 105.951 29.2812 105.951 29.0256C105.951 28.758 105.885 28.5225 105.752 28.3189C105.62 28.1129 105.425 27.9519 105.17 27.8359C104.914 27.7199 104.602 27.6619 104.232 27.6619H103.65V26.8807H104.232C104.521 26.8807 104.774 26.8286 104.992 26.7244C105.212 26.6203 105.384 26.4735 105.507 26.2841C105.633 26.0947 105.695 25.8722 105.695 25.6165C105.695 25.3703 105.641 25.156 105.532 24.9737C105.423 24.7914 105.269 24.6494 105.07 24.5476C104.874 24.4458 104.642 24.3949 104.374 24.3949C104.123 24.3949 103.887 24.4411 103.664 24.5334C103.444 24.6233 103.264 24.7547 103.124 24.9276C102.985 25.098 102.909 25.304 102.897 25.5455H102.045C102.059 25.1643 102.174 24.8305 102.389 24.544C102.605 24.2552 102.886 24.0303 103.234 23.8693C103.585 23.7083 103.97 23.6278 104.389 23.6278C104.838 23.6278 105.224 23.719 105.546 23.9013C105.868 24.0812 106.116 24.3191 106.288 24.6151C106.461 24.911 106.548 25.2306 106.548 25.5739C106.548 25.9834 106.44 26.3326 106.224 26.6214C106.011 26.9103 105.721 27.1103 105.354 27.2216V27.2784C105.814 27.3542 106.172 27.5495 106.43 27.8643C106.688 28.1768 106.818 28.5639 106.818 29.0256C106.818 29.4209 106.71 29.776 106.494 30.0909C106.281 30.4034 105.99 30.6496 105.621 30.8295C105.251 31.0095 104.831 31.0994 104.36 31.0994Z"
            fill="#60A5FA"
          />
        </g>
        <defs>
          <clipPath id="clip0_194_2537">
            <rect
              width="97"
              height="42"
              fill="white"
              transform="translate(8 6)"
            />
          </clipPath>
        </defs>
      </Svg>
    );
  }

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 113 48"
      width="90"
      {...props}
    >
      <g clipPath="url(#clip0_194_3133)">
        <rect x="8" y="21" width="12" height="12" rx="6" fill="#E2E8F0" />
        <path
          d="M13.6486 27.0002L15.1335 25.5153L14.7093 25.091L12.8001 27.0002L14.7093 28.9094L15.1335 28.4851L13.6486 27.0002Z"
          fill="#94A3B8"
        />
        <rect x="22" y="9" width="31.3333" height="36" rx="4" fill="#E2E8F0" />
        <path
          d="M38.6522 23.7273V31H37.7715V24.6506H37.7289L35.9533 25.8295V24.9347L37.7715 23.7273H38.6522Z"
          fill="#94A3B8"
        />
        <rect
          x="55.3333"
          y="9"
          width="31.3333"
          height="36"
          rx="4"
          fill="#E2E8F0"
        />
        <path
          d="M68.7255 31V30.3608L71.1261 27.733C71.4078 27.4252 71.6398 27.1577 71.8221 26.9304C72.0044 26.7008 72.1393 26.4853 72.2269 26.2841C72.3169 26.0805 72.3619 25.8674 72.3619 25.6449C72.3619 25.3892 72.3003 25.1679 72.1772 24.9808C72.0565 24.7938 71.8907 24.6494 71.68 24.5476C71.4693 24.4458 71.2326 24.3949 70.9698 24.3949C70.6905 24.3949 70.4466 24.4529 70.2383 24.5689C70.0323 24.6825 69.8725 24.8423 69.7589 25.0483C69.6476 25.2543 69.592 25.4957 69.592 25.7727H68.7539C68.7539 25.3466 68.8522 24.9725 69.0487 24.6506C69.2451 24.3286 69.5127 24.0777 69.8512 23.8977C70.1921 23.7178 70.5745 23.6278 70.9982 23.6278C71.4244 23.6278 71.802 23.7178 72.131 23.8977C72.4601 24.0777 72.7182 24.3203 72.9052 24.6257C73.0922 24.9311 73.1857 25.2708 73.1857 25.6449C73.1857 25.9124 73.1372 26.174 73.0401 26.4297C72.9454 26.683 72.7797 26.9659 72.543 27.2784C72.3086 27.5885 71.9831 27.9673 71.5664 28.4148L69.9329 30.1619V30.2188H73.3136V31H68.7255Z"
          fill="#94A3B8"
        />
        <rect
          x="88.6667"
          y="9"
          width="31.3333"
          height="36"
          rx="4"
          fill="#E2E8F0"
        />
        <path
          d="M104.36 31.0994C103.891 31.0994 103.474 31.0189 103.107 30.858C102.742 30.697 102.452 30.4732 102.237 30.1868C102.023 29.898 101.907 29.563 101.889 29.1818H102.783C102.802 29.4162 102.883 29.6186 103.025 29.7891C103.167 29.9571 103.353 30.0874 103.582 30.1797C103.812 30.272 104.067 30.3182 104.346 30.3182C104.658 30.3182 104.935 30.2637 105.177 30.1548C105.418 30.0459 105.608 29.8944 105.745 29.7003C105.882 29.5062 105.951 29.2812 105.951 29.0256C105.951 28.758 105.885 28.5225 105.752 28.3189C105.62 28.1129 105.425 27.9519 105.17 27.8359C104.914 27.7199 104.602 27.6619 104.232 27.6619H103.65V26.8807H104.232C104.521 26.8807 104.774 26.8286 104.992 26.7244C105.212 26.6203 105.384 26.4735 105.507 26.2841C105.633 26.0947 105.695 25.8722 105.695 25.6165C105.695 25.3703 105.641 25.156 105.532 24.9737C105.423 24.7914 105.269 24.6494 105.07 24.5476C104.874 24.4458 104.642 24.3949 104.374 24.3949C104.123 24.3949 103.887 24.4411 103.664 24.5334C103.444 24.6233 103.264 24.7547 103.124 24.9276C102.985 25.098 102.909 25.304 102.897 25.5455H102.045C102.059 25.1643 102.174 24.8305 102.389 24.544C102.605 24.2552 102.886 24.0303 103.234 23.8693C103.585 23.7083 103.97 23.6278 104.389 23.6278C104.838 23.6278 105.224 23.719 105.546 23.9013C105.868 24.0812 106.116 24.3191 106.288 24.6151C106.461 24.911 106.548 25.2306 106.548 25.5739C106.548 25.9834 106.44 26.3326 106.224 26.6214C106.011 26.9103 105.721 27.1103 105.354 27.2216V27.2784C105.814 27.3542 106.172 27.5495 106.43 27.8643C106.688 28.1768 106.818 28.5639 106.818 29.0256C106.818 29.4209 106.71 29.776 106.494 30.0909C106.281 30.4034 105.99 30.6496 105.621 30.8295C105.251 31.0095 104.831 31.0994 104.36 31.0994Z"
          fill="#94A3B8"
        />
      </g>
      <defs>
        <clipPath id="clip0_194_3133">
          <rect
            width="97"
            height="42"
            fill="white"
            transform="translate(8 6)"
          />
        </clipPath>
      </defs>
    </Svg>
  );
};
export default FixedWidth;
