/**
 * NOTE (Max, 2024-08-22):
 *
 * We always want to show 5 page numbers. e.g:
 * 1 2 3 4 ... 10 -> currentPage is either 1 or 2 or 3
 * 1 ... 3 4 5 ... 10 -> currentPage is 4
 * 1 ... 4 5 6 ... 10 -> currentPage is 5
 * 1 ... 5 6 7 ... 10 --> currentPage is 6
 * 1 ... 6 7 8 ... 10 --> currentPage is 7
 * 1 ... 7 8 9 10 --> currentPage is either 8 or 9 or 10
 */

export type PaginationPageNumber =
  | number
  | "ellipsis_end"
  | "ellipsis_start"
  | "ellipsis_both_start"
  | "ellipsis_both_end";

export function generatePageNumbersToShow(
  currentPageNumber: number,
  totalRowsCount: number,
  resultsPerPage: number,
): PaginationPageNumber[] {
  const pageNumbersToShow: PaginationPageNumber[] = [];
  const lastPageNumber = Math.ceil(totalRowsCount / resultsPerPage);

  // NOTE (Max, 2024-08-21): We always want 5 results, so if there are a total of 5 pages or less, we can just
  // return the numbers [1 to totalRowsCount]
  if (lastPageNumber <= 5) {
    for (let value = 1; value <= lastPageNumber; value++) {
      pageNumbersToShow.push(value);
    }

    return pageNumbersToShow;
  }

  // NOTE (Max, 2024-08-21): Always show the 1st page
  pageNumbersToShow.push(1);

  if (currentPageNumber <= 3) {
    // Case: currentPage is close to the 1st page page (1, 2, 3)
    pageNumbersToShow.push(2);
    pageNumbersToShow.push(3);
    pageNumbersToShow.push(4);
    pageNumbersToShow.push("ellipsis_end");
  } else if (currentPageNumber >= lastPageNumber - 2) {
    // Case: currentPage is close to the last page (8, 9, 10)
    pageNumbersToShow.push("ellipsis_start");
    pageNumbersToShow.push(lastPageNumber - 3);
    pageNumbersToShow.push(lastPageNumber - 2);
    pageNumbersToShow.push(lastPageNumber - 1);
  } else {
    // Case: currentPage is somewhere in the middle
    pageNumbersToShow.push("ellipsis_both_start");
    pageNumbersToShow.push(currentPageNumber - 1);
    pageNumbersToShow.push(currentPageNumber);
    pageNumbersToShow.push(currentPageNumber + 1);
    pageNumbersToShow.push("ellipsis_both_end");
  }

  pageNumbersToShow.push(lastPageNumber);

  return pageNumbersToShow;
}
