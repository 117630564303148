import type { GlobalWindow } from "../../shared/Window";

import * as React from "react";

import values from "lodash-es/values";

import useScriptCallbackEvent from "../../shared/hooks/useScriptCallbackEvent";

type YotpoWidget = {
  instanceId: string;
  className: "ReviewsStarRatingsWidget" | "ReviewsMainWidget";
};

/**
 * Hook to check if there is an installation of the Yotpo Widget and also check
 * which version of the Widget the store has.
 * We know about two versions, one without instances and another with widget
 * instances. These will be initialized with the same function but the object
 * name differently.
 */
export const useGetWidgetYotpo = (
  widgetType: "ReviewsStarRatingsWidget" | "ReviewsMainWidget",
  componentId: string,
  globalWindow: GlobalWindow | null,
) => {
  const [yotpoWidget, setYotpoWidget] = React.useState<any | null>(null);
  const [yotpoVersion, setYotpoVersion] = React.useState<"v2" | "v3" | null>(
    null,
  );
  const initYotpoWidgets = React.useCallback(() => {
    if (globalWindow?.yotpoWidgetsContainer && !yotpoVersion) {
      const [yotpoInstanceData] = values(
        globalWindow.yotpoWidgetsContainer.guids,
      );
      let widget: YotpoWidget | undefined;
      if (widgetType) {
        const yotpoWidgets: YotpoWidget[] = values(
          yotpoInstanceData.config.widgets,
        );
        widget = yotpoWidgets.find(({ className }) => className === widgetType);
      }
      setYotpoVersion("v3");
      setYotpoWidget(widget);
    } else if (globalWindow?.yotpo && !yotpoVersion) {
      setYotpoVersion("v2");
      setYotpoWidget(null);
    }
  }, [globalWindow, yotpoVersion, widgetType]);

  useScriptCallbackEvent(
    {
      run: () => {
        initYotpoWidgets();
      },
      isReady: () => {
        return Boolean(
          globalWindow?.yotpo || globalWindow?.yotpoWidgetsContainer,
        );
      },
      identifier: "yotpo",
      componentId: componentId,
      scriptSourceRegex:
        /(staticw2\.yotpo\.com)|(cdn-widgetsrepository\.yotpo\.com\/v1\/loader)/,
    },
    [globalWindow, initYotpoWidgets],
  );

  return { version: yotpoVersion, widget: yotpoWidget };
};
