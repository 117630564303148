import * as React from "react";

import DynamicDataButton from "@common/designSystem/DynamicDataButton";
import { Input } from "@common/designSystem/Input";
import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";

import { Badge } from "@replo/design-system/components/badge";
import classNames from "classnames";
import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";
import { twMerge } from "tailwind-merge";

import FormFieldXButton from "../FormFieldXButton";

type InlineAssetSelectorProps = {
  asset?:
    | { type: "video"; src: string | undefined }
    | { type: "image"; src: string | undefined };
  emptyTitle?: string;
  onClickSelectAsset?(): void;
  onRemoveAsset?(): void;
  allowRemoveAsset?: boolean;
  allowsDynamicData?: boolean;
  onClickDynamicData?(): void;
  alwaysShowRemoveButton?: boolean;
  onInputChange?(url: string): void;
  isDisabled?: boolean;
  inputName?: string;
  autocomplete?: "on" | "off";
  wrapperClassnames?: string;
  inputPlaceholder?: string;
  size: "sm" | "base";
};

const InlineAssetSelector = ({
  asset,
  emptyTitle,
  onClickSelectAsset,
  onRemoveAsset,
  allowsDynamicData = false,
  allowRemoveAsset = true,
  onClickDynamicData,
  alwaysShowRemoveButton,
  onInputChange,
  isDisabled,
  inputName,
  autocomplete = "off",
  wrapperClassnames,
  inputPlaceholder,
  size,
}: InlineAssetSelectorProps) => {
  function _getTitle() {
    return asset?.src ?? emptyTitle;
  }

  const valueIsDynamic = isDynamicDataValue(asset?.src);
  const showInputField = !valueIsDynamic && Boolean(onInputChange);
  const srcInputProps = useOverridableInput({
    value: asset?.src ?? "",
    onValueChange: onInputChange,
  });
  const showRemoveButton =
    onRemoveAsset &&
    ((asset?.src && allowRemoveAsset) || alwaysShowRemoveButton);

  return (
    <div className={twMerge("flex", wrapperClassnames)}>
      <div className="flex w-full items-center gap-1">
        <div className="flex w-full items-center rounded bg-subtle gap-1">
          {showInputField && (
            <Input
              {...srcInputProps}
              shouldSelectTextOnFocus
              placeholder={inputPlaceholder ?? "Source"}
              startEnhancer={() => {
                if (!asset) {
                  return null;
                }
                return (
                  <button
                    type="button"
                    data-testid="select-asset"
                    disabled={isDisabled}
                    className={classNames({
                      "cursor-default": !onClickSelectAsset,
                    })}
                    onClick={isDisabled ? undefined : onClickSelectAsset}
                  >
                    <Badge type={asset.type} src={asset.src} isFilled={true} />
                  </button>
                );
              }}
              onOptionClick={onRemoveAsset}
              isDisabled={isDisabled}
              name={inputName}
              autoComplete={autocomplete}
              size={size}
              endEnhancer={() =>
                showRemoveButton && (
                  <RemoveAssetButton onRemoveAsset={onRemoveAsset} />
                )
              }
            />
          )}
          {!showInputField && (
            <span
              className={classNames("cursor-pointer truncate text-xs", {
                "text-default": asset,
                "text-subtle": !asset,
              })}
              onClick={() => onClickSelectAsset?.()}
            >
              {_getTitle()}
            </span>
          )}
          {!showInputField && showRemoveButton && (
            <RemoveAssetButton onRemoveAsset={onRemoveAsset} />
          )}
        </div>
        {allowsDynamicData && (
          <div className="flex">
            <DynamicDataButton onClick={() => onClickDynamicData?.()} />
          </div>
        )}
      </div>
    </div>
  );
};

const RemoveAssetButton: React.FC<{ onRemoveAsset(): void }> = ({
  onRemoveAsset,
}) => {
  return <FormFieldXButton onClick={() => onRemoveAsset()} />;
};

export default InlineAssetSelector;
