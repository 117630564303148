import * as React from "react";

import * as SeparatorPrimitive from "@radix-ui/react-separator";
import { twMerge } from "tailwind-merge";

type Orientation = "vertical" | "horizontal";

export type SeparatorProps = {
  orientation?: Orientation;
  className?: string;
};

const Separator: React.FC<SeparatorProps> = (props) => {
  const { orientation = "horizontal", className = "" } = props;

  if (orientation === "vertical") {
    return (
      <SeparatorPrimitive.Root
        orientation={orientation}
        className={twMerge("h-full w-[0.5px] bg-border", className)}
      />
    );
  }

  return (
    <SeparatorPrimitive.Root
      orientation={orientation}
      className={twMerge("h-[0.5px] w-full bg-border", className)}
    />
  );
};

export default Separator;
