import type {
  SavedColorStyle,
  SavedTextStyle,
} from "schemas/generated/designLibrary";

import * as React from "react";

import { useGetStepResultsData } from "@components/flows/hooks/useGetCurrentStepResultsData";
import { Skeleton } from "@editor/components/common/designSystem/SkeletonLoader";
import StepImage from "@editor/components/flows/onboardingSteps/components/StepImage";
import { trpc, useLastQueryWasError } from "@editor/utils/trpc";

import { convertHtmlTagToReadableString } from "@/features/savedStyles/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { skipToken } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { BsFileEarmarkExcel, BsMagic } from "react-icons/bs";
import {
  convertFontWeightToReadableString,
  extractSupportedFontFromFamily,
} from "replo-runtime/shared/utils/font";
import { z } from "zod";

import { useReploFlowsStepContext } from "../context/ReploFlowsStepContext";
import { useRouterFlowCallbacks } from "../hooks/useRouterFlowCallbacks";
import { CheckboxCallout } from "./components/CheckboxCallout";
import FlowActionButtons from "./components/FlowActionButtons";
import OnboardingStepLayout, {
  OnboardingStepForm,
} from "./components/OnboardingStepsLayout";
import StepSubtitle from "./components/StepSubtitle";
import StepTitle from "./components/StepTitle";

const formSchema = z.object({
  saveStyles: z.boolean(),
});
type FormValues = z.infer<typeof formSchema>;

// TODO (Gabe 2024-10-24): This step should be skipped if the user hasn't provided a URL.

const PrimarySavedStylesStep: React.FC = () => {
  const importFromUrlStepResultsData =
    useGetStepResultsData<"onboarding.user.import-from-url">("import-from-url");

  const { submitOrSkipStepCallback: submitStepCallback } =
    useRouterFlowCallbacks();

  const [shouldSaveStyles, setShouldSaveStyles] = React.useState(true);

  const { handleSubmit } = useForm({
    mode: "onSubmit",
    defaultValues: {
      saveStyles: true,
    },
    resolver: zodResolver(formSchema),
  });

  const { currentStep, submitStep } = useReploFlowsStepContext();

  const lastQueryWasError = useLastQueryWasError(
    trpc.ai.generatePrimaryStyles,
    {
      url: importFromUrlStepResultsData?.url,
    },
  );

  const { data: primaryStylesData, isLoading: arePrimaryStylesQueryLoading } =
    trpc.ai.generatePrimaryStyles.useQuery(
      importFromUrlStepResultsData?.url && !lastQueryWasError
        ? {
            url: importFromUrlStepResultsData.url,
          }
        : skipToken,
      { retry: false },
    );

  const onSubmit = React.useCallback(
    (
      values: FormValues & {
        primaryStyles?: { color: SavedColorStyle[]; text: SavedTextStyle[] };
      },
    ) => {
      if (currentStep) {
        submitStep(
          currentStep.id,
          currentStep.type,
          values,
          ({ instance, nextStep }) => {
            submitStepCallback({
              nextStep: nextStep ?? null,
              flowSlug: instance.flow.slug,
            });
          },
        );
      }
    },
    [currentStep, submitStep, submitStepCallback],
  );
  const onSubmitWithPrimaryStyles = (data: FormValues) => {
    onSubmit({ ...data, primaryStyles: primaryStylesData });
  };

  const colorStyles = primaryStylesData?.color;

  const textStyles = primaryStylesData?.text;

  return (
    <OnboardingStepLayout
      rightPanelContent={<StepImage src="/images/flows/styles.png" />}
    >
      <OnboardingStepForm
        onSubmit={(data) => {
          void handleSubmit(onSubmitWithPrimaryStyles)(data);
        }}
      >
        <div className="flex flex-col gap-8">
          <div className="flex flex-col ">
            <StepTitle>Save styles to speed up building</StepTitle>
            <StepSubtitle>
              {arePrimaryStylesQueryLoading ? (
                <div className="text-sm text-ai flex flex-row items-center gap-2">
                  <BsMagic />
                  Generating styles from url...
                </div>
              ) : (
                "These styles will be used to generate content with Replo AI."
              )}
            </StepSubtitle>
          </div>
          {lastQueryWasError ? (
            <div className="flex flex-row gap-2 items-center bg-slate-50 rounded-md px-4 py-2 text-slate-600 justify-start h-12">
              <BsFileEarmarkExcel />
              <p className="text-xs">
                Couldn&apos;t retrieve styles from URL. You&apos;ll have an
                opportunity to save styles later.
              </p>
            </div>
          ) : (
            <>
              <div className="flex flex-row gap-12 justify-between">
                {colorStyles?.length !== 0 && (
                  <div className="flex flex-col gap-4">
                    <div className="text-sm font-semibold text-slate-800">
                      Color
                    </div>
                    <div className="grid grid-cols-3 gap-4">
                      {arePrimaryStylesQueryLoading &&
                        new Array(5)
                          .fill(0)
                          .map((_, index) => (
                            <Skeleton key={index} className="h-8 w-8" />
                          ))}

                      {colorStyles?.map((colorStyle, index) => (
                        <SavedStyleColorSwatch
                          key={index}
                          color={colorStyle.attributes.color}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {textStyles?.length !== 0 && (
                  <div className="flex-1 flex flex-col gap-4">
                    <div className="text-sm font-semibold text-slate-800">
                      Text
                    </div>
                    {arePrimaryStylesQueryLoading &&
                      new Array(4)
                        .fill(0)
                        .map((_, index) => <TextStyleSkeleton key={index} />)}
                    {textStyles?.length === 0 && (
                      <div className="text-slate-500 text-sm">
                        No text styles found
                      </div>
                    )}
                    {textStyles?.map((textStyle) => {
                      return (
                        <div
                          key={textStyle.attributes.htmlTag}
                          className="flex flex-row gap-2 items-center"
                        >
                          <div className="w-8 h-8 text-slate-800 rounded-md text-sm border-1 border-slate-300 flex flex-row items-center justify-center">
                            Aa
                          </div>
                          <div className="flex flex-col text-xs">
                            <div className="font-semibold ">
                              {convertHtmlTagToReadableString(
                                textStyle.attributes.htmlTag,
                              )}
                            </div>
                            <div className="text-slate-500">
                              {[
                                textStyle.attributes.fontFamily &&
                                  extractSupportedFontFromFamily(
                                    textStyle.attributes.fontFamily ?? "",
                                  ),
                                textStyle.attributes.fontWeight &&
                                  convertFontWeightToReadableString(
                                    textStyle.attributes.fontWeight,
                                  ),
                                textStyle.attributes.fontSize &&
                                  textStyle.attributes.fontSize,
                              ]
                                .filter(Boolean)
                                .join(" • ")}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              {!arePrimaryStylesQueryLoading && (
                <CheckboxCallout
                  title="Save these styles"
                  description="These styles will be saved and used to generate content with Replo AI."
                  isChecked={shouldSaveStyles}
                  onCheckedChange={(checked) => setShouldSaveStyles(checked)}
                />
              )}
            </>
          )}
        </div>
        <FlowActionButtons shouldDisableNextButton={false} />
      </OnboardingStepForm>
    </OnboardingStepLayout>
  );
};

const SavedStyleColorSwatch: React.FC<{ color: string }> = ({ color }) => {
  return (
    <div
      className="w-8 h-8 rounded-md border-1 border-slate-300"
      style={{ backgroundColor: color }}
      title={color}
    />
  );
};

const TextStyleSkeleton: React.FC = () => {
  return (
    <div className="flex flex-row gap-3">
      <Skeleton className="h-8 w-8" />
      <div className="flex-1 flex flex-col gap-1">
        <Skeleton className="h-4 w-11" />
        <Skeleton className="h-3 w-full max-w-28" />
      </div>
    </div>
  );
};

export default PrimarySavedStylesStep;
