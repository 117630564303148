import type { StoreDataType } from "replo-runtime/shared/liquid";
import type { Swatch } from "replo-runtime/shared/types";
import type { ComponentEditorData } from "replo-runtime/shared/utils/context";
import type { Nullish } from "replo-utils/lib/types";
import type { ReploElement } from "schemas/generated/element";
import type { ReploSymbol } from "schemas/generated/symbol";

import { canUseDOM } from "replo-utils/dom/misc";

/**
 * Global environment (set of global variables) that is necessary for a Replo
 * paint to occur.
 */
export type AlchemyPaintEnvironment = {
  /**
   * Backend id of the store we're painting an element on
   */
  storeId: string;

  /**
   * List of elements that we know how to render in this store
   */
  elements: ReploElement[];

  /**
   * Mapping of callbacks to execute on a Replo-controlled page when a script
   * of a given src string loads. This is used to shim in code to run after
   * external scripts like Klaviyo are loaded, since sometimes we need to respond
   * to their loads and transpose elements, etc.
   */
  scriptCallbacks: Record<string, Function>;

  /**
   * Whether we've added the event listener which powers script callbacks
   */
  hasLoadedScriptCallbacks: boolean;

  /**
   * This function can be used by third parties or theme developers to retrigger the hydration of a Replo Element.
   * It is currently being used by GoPuff. It is simply the `initAlchemy` fn that is exposed at the key of `alchemy.reload`.
   * Added for GoPuff.
   */
  reload: () => Promise<void>;

  /**
   * Data loaded from the store page. Most of these come from embedded JSON on
   * the page, which we only have access to because we control the page liquid
   * (through the alchemy-data snippet).
   *
   * TODO (Noah, 2022-04-19): Loading products from the store like this doesn't
   * make sense since it limits the number of products we can get. We should
   * move to have this being calculated like product metafields as part of dependencies.ts
   */
  features: StoreDataType;

  /**
   * Mapping of element ids to methods which are used to update those elements
   * (e.g. for paint() to re-populate the elements with new component data
   */
  elementIdToElementMethods?: Record<string, any>;

  /**
   * List of swatches, used by components that need to use arbitrary dynamic
   * data from product variants or options.
   */
  swatches: Swatch[];

  /**
   * List of symbol definitions (used to resolve symbolRef components)
   */
  symbols: ReploSymbol[];

  /**
   * Additional global data used for the editor
   */
  componentIdToContext: Record<string, any>;
  componentIdToEditorData: Record<string, ComponentEditorData>;

  /**
   * Mapping of DOM nodes which have been cached from the prerendered page (necessary
   * for e.g. Shopify Liquid components)
   */
  prerenderedNodes: Record<string, any>;
};

/**
 * Return the global paint environment which contains all the data the Replo
 * runtime needs to paint (render the page's react component(s)). This function
 * returns the single global instance and takes care of fetching it from the
 * correct location in the editor vs non-editor, so this function can be used
 * the same in the editor and the runtime.
 *
 * @deprecated TODO (Chance 2024-05-16): No alternative yet, but this should be
 * avoided _when possible_.
 */
export const getAlchemyGlobalPaintContext =
  (): AlchemyPaintEnvironment | null => {
    if (canUseDOM) {
      if (window.alchemy?.targetWindow) {
        return window.alchemy?.targetWindow?.alchemy ?? null;
      }
      return window.alchemy ?? null;
    }
    return null;
  };

/**
 * This function is used to determine if a window is safe to access. In iframes
 * that are on a different domain, many window properties are not accessible. In
 * those cases this function will return `false`.
 */
export function isSafeWindow(window: Window): window is GlobalWindow {
  try {
    // NOTE (Chance 2023-12-13, USE-616): If we try to access the location of a
    // window belonging to a frame, but it's on a different domain, we'll get a
    // DOMException. We don't actually need to do anything with this, it just
    // ensures we're allowed to access the iframe window's properties to prevent
    // errors from blowing up the editor.
    if (window.location.href) {
      // do nothing
    }
    return true;
  } catch (error) {
    if (error instanceof DOMException) {
      return false;
    }
    throw error;
  }
}

/**
 * @returns If the runtime is a DOM environment and owner window is safe to
 * access (ie. not in an iframe on a different domain), this will return the
 * global `window` object. Otherwise it will return `null`.
 */
export function getGlobalWindowSafe(): GlobalWindow | null {
  if (!canUseDOM) {
    return null;
  }
  return isSafeWindow(window) ? window : null;
}

/**
 * This function will return the window object in which a given DOM node is
 * rendered if it's safe to access.
 *
 * @returns If the runtime is a DOM environment and owner window is safe to
 * access (ie. not in an iframe on a different domain), this will return the
 * node's `window` object. Otherwise it will return `null`.
 */
export function getOwnerWindowSafe(node: Node): GlobalWindow | null {
  if (!canUseDOM) {
    return null;
  }

  const _document = node.ownerDocument ?? document;
  const _window = _document.defaultView;
  return _window && isSafeWindow(_window) ? _window : null;
}

export const getAlchemyEditorWindow = () => {
  if (canUseDOM && window.alchemyEditor) {
    // NOTE (Chance 2023-08-01) This cast ensures that `alchemyEditor` is defined
    return window as GlobalWindow & { alchemyEditor: AlchemyEditorData };
  }
  return null;
};

type AlchemyEditorData = Partial<{
  root: any;
  parentDocument: any;
  shopifyPageId: string;
  canvasYOffset: number;
  canvasScale: number;
  canvasFrame: any;
  /**
   * Mapping of element id to the methods that the editor uses to update the
   * rendered runtime element. This is here as well as on the
   * `AlchemyPaintEnvironment` because we don't want to have to wait for the
   * store script to load in the editor before being able to render the element
   * in the editor (if we didn't have this, we would have to wait for the store
   * script to load in the editor in order for there to be an
   * `elementIdToElementMethods` mapping for us to register into).
   */
  elementIdToElementMethods: Record<string, any>;
  componentIdToContext: Record<string, any>;
  componentIdToEditorData: Record<string, ComponentEditorData>;
}>;

declare global {
  interface Window {
    targetFrame: HTMLIFrameElement;
    targetFrameWindow: GlobalWindow | Nullish;
    /**
     * Mapping of the element id to whether a Replo Runtime has been initialized for
     * that element. Useful for when we have multiple elements on the page with potentially
     * different runtimes, which know about only one element each.
     */
    elementIdToRuntimeInitialized?: Record<string, boolean>;
    alchemyEditor?: AlchemyEditorData;
    alchemy?: AlchemyPaintEnvironment & {
      targetWindow?: Window | (Window & typeof globalThis);
    };
    MSStream: any;
    SENTRY_RELEASE?: {
      id: string;
    };
    zE: any;
    pylon: any;
    Pylon: any;
    Shopify?: {
      country: string;
      locale: string;
      currency?: {
        active: string;
      };
      routes?: {
        root?: string;
      };
    };
    Image: any;
    reploPosthog?: any;
    okeWidgetApi?: any;
    /**
     * Note (Noah, 2022-10-19, REPL-4661): For some reason, some stores have
     * okendoReviews instead of okeWidgetApi on the window. Possibly due to an
     * older version of Okendo installed? Not completely sure, but this is
     * definitely necessary for sites like Aloha/Asystem
     */
    okendoReviews?: any;
    Kno?: any;
    ReviewsWidget?: any;
    Shoppad?: any;
    productJSON?: any;
    _klOnsite?: any;
    ReChargeWidget?: any;
    yotpo?: any;
    yotpoWidgetsContainer?: any;
    appstleSelectedSellingPlan?: string;
    retextionBuyBox?: Record<
      string,
      {
        form: HTMLFormElement;
        getSellingPlanId: () => number | undefined;
      }
    >;
    bwp?: any;
    junipLoaded?: boolean;
  }
}

export type GlobalWindow = Window & typeof globalThis;
