import type { RelativeRoutingType, To } from "react-router-dom";
import type { ButtonSize, ButtonVariant } from "./button-shared";

import * as React from "react";

import { Spinner } from "@replo/design-system/components/spinner";
import Tooltip from "@replo/design-system/components/tooltip";
import { Link } from "react-router-dom";
import { isNotNullish, isNullish } from "replo-utils/lib/misc";
import { isFunction, isString } from "replo-utils/lib/type-check";
import { useComposedEventHandlers } from "replo-utils/react/use-composed-event-handlers";
import { twMerge } from "tailwind-merge";

interface DataAttributeProps {
  [key: `data-${string}`]: string;
}

interface AriaProps {
  "aria-label"?: string;
  "aria-labelledby"?: string;
}

export interface ButtonSharedProps extends DataAttributeProps {
  children?: React.ReactNode;
  className?: string;
  endEnhancer?: (() => React.ReactNode) | React.ReactElement;
  hasMinDimensions?: boolean;
  icon?: React.ReactNode;
  id?: string;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
  isRounded?: boolean;
  size?: ButtonSize;
  spinnerSize?: number;
  startEnhancer?: (() => React.ReactNode) | React.ReactElement;
  style?: React.CSSProperties;
  textClassNames?: string;
  tooltipText?: React.ReactNode | string | null;
  variant: ButtonVariant;
  unsafe_className?: string;
  isPhonyButton?: boolean;
  href?: string;
  target?: string;
  rel?: string;
  collisionPadding?: number;
}

export interface ButtonProps
  extends ButtonSharedProps,
    AriaProps,
    React.ButtonHTMLAttributes<HTMLButtonElement> {
  tabIndex?: number;
}

export interface ButtonLinkProps extends ButtonSharedProps, AriaProps {
  onClick?(event: React.MouseEvent<HTMLAnchorElement>): void;
  rel?: React.HTMLProps<HTMLAnchorElement>["rel"];
  target?: React.HTMLAttributeAnchorTarget;
  tabIndex?: number;
  // NOTE (Chance 2024-01-24): These are specific to the Link component in React
  // Router. I intentionally did not import the type directly as there may be
  // props (including those marked as `unstable_`) we may don't want to expose
  // at this level.
  to: To;
  preventScrollReset?: boolean;
  relative?: RelativeRoutingType;
  reloadDocument?: boolean;
  replace?: boolean;
  state?: any;
}

export type ButtonPhonyProps = ButtonSharedProps;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(_props, ref) {
    const props = getPropsWithDefaults(_props);

    // TOTO (Chance 2024-01-24): These are here for backwards compatibility.
    // They should surface as type errors so we should be able to delete this.
    if ("isPhonyButton" in props && props.isPhonyButton) {
      // @ts-expect-error
      return <ButtonPhony ref={ref} {...props} />;
    }
    if ("href" in props && props.href) {
      const defaultTarget = props.href.startsWith("https://")
        ? "_blank"
        : "_self";
      return (
        <ButtonLink
          // @ts-expect-error
          ref={ref}
          {...props}
          to={props.href}
          target={props.target ?? defaultTarget}
        />
      );
    }

    const {
      children,
      id,
      isDisabled,
      onClick,
      tabIndex,
      tooltipText,
      isFullWidth,
      type = "button",
      collisionPadding,
    } = props;
    const { className, style } = getButtonDesignProps(props);
    const ariaProps = getAriaProps(props);
    const dataAttributes = getDataAttributeProps(props);

    return (
      <ButtonTooltip
        isDisabled={isDisabled}
        tooltipText={tooltipText}
        isFullWidth={isFullWidth}
        collisionPadding={collisionPadding}
      >
        <button
          type={type}
          className={className}
          disabled={isDisabled || undefined}
          id={id}
          onClick={onClick}
          ref={ref}
          style={style}
          tabIndex={tabIndex}
          {...ariaProps}
          {...dataAttributes}
        >
          <ButtonChildren {...props} width={style?.width}>
            {children}
          </ButtonChildren>
        </button>
      </ButtonTooltip>
    );
  },
);

const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  function ButtonLink(_props, ref) {
    const props = getPropsWithDefaults(_props);
    const {
      children,
      id,
      isDisabled,
      onClick,
      preventScrollReset,
      relative,
      reloadDocument,
      replace,
      state,
      tabIndex,
      target,
      isFullWidth,
      to,
      tooltipText,
    } = props;
    const { className, style } = getButtonDesignProps(props);
    let rel: React.HTMLProps<HTMLAnchorElement>["rel"] | undefined = props.rel;
    if (!rel && target === "_blank") {
      rel = "noreferrer";
    }
    const ariaProps = getAriaProps(props);
    const dataAttributes = getDataAttributeProps(props);
    const handleClick = useComposedEventHandlers(
      onClick,
      React.useCallback(
        (event: React.MouseEvent<HTMLAnchorElement>) => {
          if (isDisabled) {
            event.preventDefault();
          }
        },
        [isDisabled],
      ),
    );

    return (
      <ButtonTooltip
        isDisabled={isDisabled}
        tooltipText={tooltipText}
        isFullWidth={isFullWidth}
      >
        <Link
          className={twMerge(
            className,
            isDisabled && "pointer-events-none cursor-default",
          )}
          id={id}
          onClick={handleClick}
          preventScrollReset={preventScrollReset}
          ref={ref}
          rel={rel}
          relative={relative}
          reloadDocument={reloadDocument}
          replace={replace}
          state={state}
          style={style}
          target={target}
          to={to}
          aria-disabled={isDisabled || undefined}
          tabIndex={isDisabled ? -1 : tabIndex}
          {...ariaProps}
          {...dataAttributes}
        >
          <ButtonChildren {...props} width={style?.width}>
            {children}
          </ButtonChildren>
        </Link>
      </ButtonTooltip>
    );
  },
);

const ButtonPhony = React.forwardRef<HTMLDivElement, ButtonPhonyProps>(
  function ButtonPhony(_props, ref) {
    const props = getPropsWithDefaults(_props);
    const { children, id, isDisabled, tooltipText, isFullWidth } = props;
    const { className, style } = getButtonDesignProps(props);
    const dataAttributes = getDataAttributeProps(props);

    return (
      <ButtonTooltip
        isDisabled={isDisabled}
        isFullWidth={isFullWidth}
        tooltipText={tooltipText}
        {...dataAttributes}
      >
        <div className={className} id={id} ref={ref} style={style}>
          <ButtonChildren {...props} width={style?.width}>
            {children}
          </ButtonChildren>
        </div>
      </ButtonTooltip>
    );
  },
);

export { ButtonLink, ButtonPhony };
export default Button;

function ButtonChildren({
  isLoading,
  children,
  textClassNames,
  icon,
  variant,
  spinnerSize,
  size,
  startEnhancer,
  endEnhancer,
  width,
}: ButtonSharedProps & {
  width: React.CSSProperties["width"];
}) {
  const textClassName = twMerge(
    "flex leading-3 text-center font-medium items-center",
    size === "sm" && "p-small h-small typ-button-small",
    size === "base" && "p-base h-base typ-button-base",
    size === "lg" && "p-large h-large typ-button-large",
    Boolean(startEnhancer) && "ml-1",
    Boolean(endEnhancer) && "mr-1",
    textClassNames,
  );

  return (
    <>
      {isFunction(startEnhancer) ? startEnhancer() : startEnhancer}
      <span
        className={twMerge(
          "flex items-center justify-center relative",
          icon && "gap-x-2",
        )}
        style={{ width }}
      >
        {isLoading && (
          <div className="absolute left-1/2">
            <Spinner
              className="relative -left-1/2"
              variant={
                (
                  {
                    primary: "secondary",
                    secondary: "secondary",
                    tertiary: "secondary",
                    danger: "danger",
                    link: "link",
                    dangerLink: "dangerLink",
                    secondaryDanger: "danger",
                    inherit: "primary",
                    noStyle: "primary",
                  } as const
                )[variant]
              }
              size={spinnerSize}
            />
          </div>
        )}
        {children && (
          <span className={twMerge(textClassName, isLoading && "invisible")}>
            {children}
          </span>
        )}
        {!isLoading && icon}
      </span>
      {isFunction(endEnhancer) ? endEnhancer() : endEnhancer}
    </>
  );
}

function ButtonTooltip({
  children,
  isDisabled,
  tooltipText,
  isFullWidth,
  collisionPadding = 0,
}: {
  children: React.ReactNode;
  isDisabled: boolean | undefined;
  tooltipText: React.ReactNode | string | null | undefined;
  isFullWidth?: boolean;
  collisionPadding?: number;
}) {
  if (!tooltipText) {
    return children;
  }

  return (
    <Tooltip
      content={tooltipText}
      isFullWidth={isFullWidth}
      collisionPadding={collisionPadding}
      triggerAsChild
    >
      <span
        style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
        className={isFullWidth ? "w-full" : ""}
        // Note (Chance, 2023-09-16) Ensure that disabled buttons have a
        // focusable wrapper so that tooltip content can be accessible via
        // keyboard.
        //
        // TODO Ensure that the inner content makes it clear when the child is
        // disabled.
        tabIndex={isDisabled ? 0 : undefined}
      >
        {children}
      </span>
    </Tooltip>
  );
}

function getButtonDesignProps(props: ButtonSharedProps & { size: ButtonSize }) {
  const {
    hasMinDimensions = true,
    icon,
    isFullWidth,
    isRounded,
    size,
    isLoading,
    isDisabled,
    variant,
  } = props;
  const className =
    variant === "noStyle"
      ? twMerge(
          "group",
          isFullWidth ? "w-full" : "w-auto",
          isRounded ? "rounded-full" : "rounded",
          props.className,
          props.unsafe_className,
        )
      : twMerge(
          isFullWidth ? "w-full" : "w-auto",
          "group flex items-center justify-center font-normal cursor-pointer transition duration-300 disabled:pointer-events-none whitespace-nowrap font-sans",
          size === "sm" && "p-small h-small typ-button-small",
          size === "base" && "p-base h-base typ-button-base",
          size === "lg" && "p-large h-large typ-button-large",
          isDisabled && "disabled:opacity-50 disabled:cursor-not-allowed",
          getColorClassNames(variant, isLoading),
          isRounded ? "rounded-full" : "rounded",
          props.className,
          props.unsafe_className,
        );

  let style = props.style;
  if (hasMinDimensions) {
    style = !icon
      ? { minWidth: "4.75rem", ...style }
      : {
          minHeight: "1.5rem",
          minWidth: "1.5rem",
          ...style,
        };
  }

  return {
    className,
    style,
  };
}

function getPropsWithDefaults<Props extends ButtonSharedProps>(props: Props) {
  const { size = "sm", ...rest } = props;
  return { size, ...rest } satisfies ButtonSharedProps;
}

/**
 * This function returns different styles depending on the provided button type
 */
function getColorClassNames(variant: ButtonVariant, isLoading?: boolean) {
  const typeToClassNames: Record<ButtonVariant, string> = {
    primary: "bg-primary hover:bg-primary-hover text-white",
    secondary: "bg-light-surface hover:bg-light-surface-hover",
    tertiary: `bg-transparent hover:bg-light-surface${isLoading ? " bg-light-surface" : ""}`,
    link: `bg-transparent text-primary hover:bg-info-soft${isLoading ? " bg-info-soft" : ""}`,
    danger: "bg-danger hover:bg-danger-hover text-white",
    dangerLink: `bg-transparent hover:bg-danger-soft text-danger${isLoading ? " bg-danger-soft" : ""}`,
    secondaryDanger: "text-red-600 bg-red-100 hover:bg-gray-200",
    inherit: "bg-inherit text-inherit m-0 p-0",
    noStyle: "",
  };
  return typeToClassNames[variant];
}

function getAriaProps(props: ButtonSharedProps & AriaProps) {
  const ariaLabelledBy = props["aria-labelledby"];

  let ariaLabel = props["aria-label"];

  // NOTE (Fran 2024-11-13): If the button has a tooltip and no aria-label or
  // aria-labelledby, we want to set the aria-label to the tooltip text.
  if (
    typeof props.tooltipText === "string" &&
    isNullish(ariaLabel) &&
    isNullish(ariaLabelledBy)
  ) {
    ariaLabel = props.tooltipText;
  }

  // NOTE (Chance 2023-12-04): If `aria-labelledby` is provided, then we
  // shouldn't provide `aria-label` as well. This is confusing and won't be
  // reliably read as expected.
  if (isNotNullish(ariaLabelledBy)) {
    ariaLabel = undefined;
  }

  return {
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
  };
}

function getDataAttributeProps(props: Record<string, unknown>) {
  const dataAttributes: Record<`data-${string}`, string> = {};
  for (const key of Object.keys(props)) {
    if (key.startsWith("data-")) {
      const value = props[key];
      dataAttributes[key as `data-${string}`] = isString(value)
        ? value
        : String(value);
    }
  }
  return dataAttributes;
}
