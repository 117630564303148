import type { MenuItem } from "@editor/components/common/designSystem/Menu";
import type { ContextMenuActions } from "@editor/types/component-tree";
import type { GetAttributeFunction } from "@editor/types/get-attribute-function";
import type { ReorderableTreeNode } from "@editor/types/tree";
import type { Hotkey } from "@utils/hotkeys";
import type { ComponentDataMapping } from "replo-runtime/shared/Component";
import type { VariantWithState } from "replo-runtime/shared/types";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { MediaSize } from "schemas/breakpoints";
import type { Component, ReploComponentType } from "schemas/component";
import type { ReploElementType } from "schemas/generated/element";

import * as React from "react";

import { successToast } from "@common/designSystem/Toast";
import { HotkeyIndicator } from "@editor/components/common/HotkeyIndicator";
import AskAIHeader from "@editor/components/editor/ai/AskAiHeader";
import { elementTypeToEditorData } from "@editor/components/editor/element";
import { DropEdge } from "@editor/types/tree";
import { canGroupIntoContainer } from "@utils/component";

import copy from "copy-to-clipboard";
import cloneDeep from "lodash-es/cloneDeep";
import flatten from "lodash-es/flatten";
import isEmpty from "lodash-es/isEmpty";
import isEqual from "lodash-es/isEqual";
import { getMediaSizeStyleAttributeValue } from "replo-runtime/shared/utils/breakpoints";
import {
  forEachComponentAndDescendants,
  getChildren,
} from "replo-runtime/shared/utils/component";
import { filterNulls } from "replo-utils/lib/array";

export function elementToDataTree({
  component,
  elementType,
  componentDataMapping,
}: {
  component: Component;
  elementType: ReploElementType;
  componentDataMapping: ComponentDataMapping;
}): ReorderableTreeNode {
  const dataNode = componentToDataNodeFromMapping(
    component,
    componentDataMapping,
  );

  return {
    ...dataNode,
    label: elementTypeToEditorData[elementType].singularDisplayName,
  };
}

function componentToDataNodeFromMapping(
  component: Component,
  componentDataMapping: ComponentDataMapping,
): ReorderableTreeNode {
  const componentData = componentDataMapping[component.id];

  // NOTE (Ovishek, 2022-04-26): We are only showing the first child in the tree if
  // there's a dynamic data involved, b/c in that case we are only using that first child
  // to dynamically render the others.
  // TODO (Martin, 2024-03-29): We should probably do this for more than the
  // "carouselV3Slides" component type.
  const childrenNodes =
    component.type === "carouselV3Slides" &&
    componentData?.ancestorIsDynamicRepetition
      ? component.children?.slice(0, 1)
      : component.children;

  const childrenNodesFromProps = filterNulls(
    componentData?.customPropDefinitions
      .filter((definition) => definition.type === "component")
      .map((definition) => component.props[definition.id] as Component),
  );

  return {
    id: component.id,
    label: componentData?.label ?? "Component",
    children:
      (childrenNodes ?? []).concat(childrenNodesFromProps).map((c) => {
        return componentToDataNodeFromMapping(c, componentDataMapping);
      }) ?? [],
  };
}

export const getDescendantNodeKeys = (
  node: ReorderableTreeNode | null,
): string[] => {
  // Return list of keys that are in node's subtree, including itself
  if (!node) {
    return [];
  }
  if (node.children?.length === 0) {
    return [node.id];
  }
  return [node.id].concat(
    flatten(node.children.map((c) => getDescendantNodeKeys(c))),
  );
};

export const getAncestorNodeKeys = (
  node: ReorderableTreeNode,
  selectedNodeId: string,
): string[] => {
  // Return list of keys that are node's ancestors (not including itself)
  if (node === null || node === undefined) {
    return [];
  }

  const findAmongChildren = (
    currNode: ReorderableTreeNode,
  ): [string[], boolean] => {
    if (currNode.id === selectedNodeId) {
      return [[], true];
    }

    if (currNode.children?.length === 0) {
      return [[], false];
    }

    const keysOnPath = currNode.children.map((childNode) => {
      const [childPath, didFindTargetNode] = findAmongChildren(childNode);
      if (didFindTargetNode) {
        return [currNode.id].concat(childPath);
      }
      return [];
    });
    const flattenedKeys = flatten(keysOnPath);

    return [flattenedKeys, flattenedKeys.length > 0];
  };
  const [ancestorNodeIds, didFindAncestorNode] = findAmongChildren(node);
  if (didFindAncestorNode) {
    return ancestorNodeIds;
  }
  return [];
};

export const getTreeNodeByKey = (
  node: ReorderableTreeNode,
  id: string,
): ReorderableTreeNode | null => {
  // Return the TreeNode in treeData that has the id
  if (!node) {
    return null;
  }
  if (node.id === id) {
    return node;
  }
  for (const child of node.children) {
    const possibleChildNode = getTreeNodeByKey(child, id);
    if (possibleChildNode) {
      return possibleChildNode;
    }
  }
  return null;
};

const hotkeyEndEnhancer = (hotkey: Hotkey) => {
  const HotKeyEndEnhancer = () => <HotkeyIndicator hotkey={hotkey} title="" />;
  return HotKeyEndEnhancer;
};

export const getContextMenuItems = (config: {
  contextMenuActions: ContextMenuActions | null;
  selectedIds: string[];
  activeVariant: VariantWithState | undefined;
  componentWithVariantsId: string | undefined;
  source: "canvasRightClickMenu" | "componentTree";
  rootComponentId: string | null;
  onStartRename: (() => void) | null;
  isDebugMode?: boolean;
  activeCanvas: EditorCanvas;
  draftComponentName: string | null;
  draftComponentType: ReploComponentType | null;
  draftComponentOrDescendantIsText: boolean;
  componentDataMapping: ComponentDataMapping;
}): MenuItem[] => {
  const {
    contextMenuActions,
    selectedIds,
    activeVariant,
    componentWithVariantsId,
    source,
    rootComponentId,
    onStartRename = null,
    isDebugMode = false,
    activeCanvas,
    draftComponentName,
    draftComponentOrDescendantIsText,
    draftComponentType,
    componentDataMapping,
  } = config;
  const selectedNodesLength = selectedIds.length;
  const isMultiNodeSelected = selectedNodesLength > 1;
  const [selectedComponentId] = selectedIds;
  const isRootSelected = rootComponentId
    ? selectedIds.includes(rootComponentId)
    : false;

  if (!contextMenuActions) {
    return [];
  }

  const {
    handlePaste,
    handlePasteFromFigma,
    handleCopy,
    handleDuplicate,
    hasParent,
    onReplaceComponent,
    onReplaceAllContentWithPlaceholders,
    getStringFromComponentJson,
    getComponent,
    onGroupContainer,
    onGroupDelete,
    onDelete,
    canDelete,
    handleOpenModal,
    getAssetComponent,
    handleChangeImage,
    forceSentryError,
    setDraftComponentId,
    onResetStylesToDefaultState,
    onPushPropsToDefaultState,
    handleCopyStyles,
    onPersistStylesToUpstreamDevices,
    generateAiCopy,
    resetComponentAlignment,
    getAlignSelf,
    resetStyleOverrides,
    openCodeEditor,
    turnInto,
  } = contextMenuActions;

  const items: MenuItem[] = [];
  if (selectedComponentId) {
    items.push({
      id: "replo-ai",
      type: "leaf",
      title: (
        <div className="grow px-4 py-2">
          <AskAIHeader
            content="Generate AI Copy"
            showIcon
            isDisabled={!draftComponentOrDescendantIsText}
          />
        </div>
      ),
      onSelect: () => {
        generateAiCopy(selectedComponentId);
      },
      isDisabled: !draftComponentOrDescendantIsText,
    });
    if (openCodeEditor) {
      items.push({
        id: "openCodeEditor",
        type: "leaf",
        title: "Open Code Editor",
        onSelect: () => openCodeEditor(),
      });
    }
  }
  if (
    !isRootSelected &&
    canGroupIntoContainer(selectedIds, componentDataMapping)
      .canGroupIntoContainer
  ) {
    items.push({
      type: "leaf",
      id: "groupContainer",
      title: "Group Into Container",
      onSelect: () => onGroupContainer([...selectedIds]),
      endEnhancer: hotkeyEndEnhancer("groupIntoContainer"),
    });
  }

  items.push(
    {
      id: "createTemplate",
      type: "leaf",
      title: "Create Saved Component",
      onSelect: () => {
        handleOpenModal({
          type: "saveTemplateModal",
          props: {
            initialName: draftComponentName,
          },
        });
      },
      endEnhancer: hotkeyEndEnhancer("saveComponentTemplate"),
    },
    {
      id: "exportToSection",
      type: "leaf",
      title: "Export To Section",
      onSelect: () => {
        handleOpenModal({
          type: "exportToSectionModal",
        });
      },
    },
  );

  const getTurnIntoItems = (): MenuItem[] => {
    const turnIntoItems: MenuItem[] = [];
    if (selectedComponentId) {
      const component = componentDataMapping[selectedComponentId];

      const ancestorButton = component?.ancestorComponentData?.some(
        ([, type]) => type === "button",
      );
      const childButton = component?.containedComponentData?.some(
        ([, type]) => type === "button",
      );

      turnIntoItems.push({
        id: "turnIntoButton",
        type: "leaf",
        title: "Button",
        onSelect: () => turnInto({ type: "button" }),
        isDisabled:
          isRootSelected ||
          draftComponentType !== "container" ||
          Boolean(ancestorButton) ||
          Boolean(childButton),
      });
      turnIntoItems.push({
        id: "turnIntoProduct",
        type: "leaf",
        title: "Product",
        onSelect: () => turnInto({ type: "product" }),
        isDisabled: isRootSelected || draftComponentType !== "container",
      });

      const ancestorTicker = component?.ancestorComponentData?.some(
        ([, type]) => type === "marquee",
      );
      const childTicker = component?.containedComponentData?.some(
        ([, type]) => type === "marquee",
      );

      turnIntoItems.push({
        id: "turnIntoTicker",
        type: "leaf",
        title: "Ticker",
        onSelect: () =>
          turnInto({ type: "ticker", selectedComponentIds: selectedIds }),
        isDisabled:
          isRootSelected ||
          draftComponentType !== "container" ||
          Boolean(ancestorTicker) ||
          Boolean(childTicker),
      });

      const ancestorTooltip = component?.ancestorComponentData?.some(
        ([, type]) => type === "tooltip",
      );
      const childTooltip = component?.containedComponentData?.some(
        ([, type]) => type === "tooltip",
      );

      turnIntoItems.push({
        id: "addTooltipToComponent",
        type: "leaf",
        title: "Tooltip Trigger",
        onSelect: () =>
          turnInto({
            type: "tooltipTrigger",
            selectedComponentIds: selectedIds,
          }),
        isDisabled:
          isRootSelected ||
          Boolean(ancestorTooltip) ||
          Boolean(childTooltip) ||
          draftComponentType === "tooltip",
      });
    }

    return turnIntoItems;
  };

  const turnIntoItems = getTurnIntoItems();

  if (turnIntoItems.length > 0) {
    items.push({
      id: "turnInto",
      type: "nested",
      title: "Turn Into",
      items: turnIntoItems,
    });
  }

  if (isMultiNodeSelected) {
    return items.concat([
      {
        type: "leaf",
        id: "groupDelete",
        title: `Delete (${selectedNodesLength})`,
        onSelect: () => onGroupDelete([...selectedIds]),
        endEnhancer: hotkeyEndEnhancer("delete"),
      },
      {
        type: "leaf",
        id: "groupCopy",
        title: `Copy (${selectedNodesLength})`,
        onSelect: () => handleCopy([...selectedIds]),
        endEnhancer: hotkeyEndEnhancer("copy"),
      },
      {
        type: "leaf",
        id: "paste",
        title: "Paste",
        onSelect: () => {
          void handlePaste("normal");
        },
        endEnhancer: hotkeyEndEnhancer("paste"),
      },
      {
        type: "leaf",
        id: "pasteFigma",
        title: "Paste from Figma",
        onSelect: () => {
          void handlePasteFromFigma();
        },
      },
    ]);
  }

  items.push(
    {
      type: "leaf",
      id: "copy",
      title: "Copy",
      endEnhancer: hotkeyEndEnhancer("copy"),
      onSelect: () => handleCopy([...selectedIds]),
    },
    {
      type: "leaf",
      id: "paste",
      title: "Paste",
      endEnhancer: hotkeyEndEnhancer("paste"),
      onSelect: () => {
        void handlePaste("normal");
      },
    },
    {
      type: "leaf",
      id: "pasteFigma",
      title: "Paste from Figma",
      onSelect: () => {
        void handlePasteFromFigma();
      },
    },
  );

  if (!isMultiNodeSelected && !isRootSelected) {
    const assetComponent = getAssetComponent(selectedComponentId as string);
    if (assetComponent?.component) {
      const { component, value } = assetComponent;
      items.push({
        id: "changeMediaContent",
        type: "leaf",
        title: component.type === "image" ? "Change Image" : "Change Video",
        onSelect: () => {
          handleOpenModal(
            component.type === "image"
              ? {
                  type: "assetLibraryModal",
                  props: {
                    referrer: "modifier/image",
                    value,
                    onChange: (value) => {
                      if (value) {
                        handleChangeImage(selectedComponentId as string, value);
                      }
                    },
                    assetContentType: "image",
                  },
                }
              : {
                  type: "assetLibraryModal",
                  props: {
                    referrer: "modifier/video",
                    value,
                    assetContentType: "video",
                  },
                },
          );
        },
      });
    }
    items.push(
      {
        type: "leaf",
        id: "rename",
        title: "Rename",
        onSelect: () => onStartRename?.(),
        isDisabled: !onStartRename,
      },
      {
        type: "leaf",
        id: "duplicate",
        title: "Duplicate",
        onSelect: () => handleDuplicate(selectedComponentId as string, source),
        isDisabled: !hasParent(selectedComponentId as string),
        endEnhancer: hotkeyEndEnhancer("duplicate"),
      },
      {
        type: "leaf",
        id: "delete",
        title: "Delete",
        onSelect: () => onDelete(selectedComponentId as string, source),
        isDisabled: !canDelete(selectedComponentId as string),
        endEnhancer: hotkeyEndEnhancer("delete"),
      },
    );
  }

  const getStateStyleItems = (): MenuItem[] => {
    if (
      !isMultiNodeSelected &&
      activeVariant &&
      selectedComponentId &&
      componentWithVariantsId
    ) {
      const isDisabled = activeVariant.name === "default";
      return [
        {
          id: "resetStylesToDefaultState",
          type: "leaf",
          title: "Reset Styles to Default State",
          onSelect: () => {
            onResetStylesToDefaultState(selectedComponentId, activeVariant.id);
          },
          isDisabled,
        },
        {
          id: "pushPropsToDefaultState",
          type: "leaf",
          title: "Push Changes to Default State",
          onSelect: () => {
            onPushPropsToDefaultState(selectedComponentId, activeVariant.id);
          },
          isDisabled,
        },
      ];
    }
    return [];
  };

  items.push({
    id: "styles",
    type: "nested",
    title: "Styles",
    items: [
      {
        type: "leaf",
        id: "copyStyles",
        title: "Copy Styles",
        onSelect: () =>
          handleCopyStyles(selectedComponentId as string, activeVariant?.id),
        endEnhancer: hotkeyEndEnhancer("copyStyles"),
      },
      {
        type: "leaf",
        id: "pasteStyles",
        title: "Paste Styles",
        onSelect: () => {
          void handlePaste("alt");
        },
        endEnhancer: hotkeyEndEnhancer("pasteStyles"),
      },
      {
        type: "leaf",
        id: "resetContentsAlignment",
        title: "Reset Content Alignment",
        isDisabled: Boolean(
          selectedComponentId &&
            !getAlignSelf(selectedComponentId, activeCanvas),
        ),
        onSelect: () => {
          if (selectedComponentId) {
            resetComponentAlignment(selectedComponentId);
          }
        },
      },
      {
        type: "leaf",
        id: "resetStyleOverrides",
        title: "Reset Style Overrides",
        isDisabled: activeCanvas === "desktop",
        onSelect: () => {
          if (selectedComponentId) {
            resetStyleOverrides(selectedComponentId, activeVariant?.id);
          }
        },
      },
      ...(isDebugMode
        ? [
            {
              type: "leaf",
              id: "stripNonDesktopStyles",
              title: "Strip Non-Desktop Styles",
              onSelect: () => {
                if (!selectedComponentId) {
                  return "No component selected";
                }
                const component = cloneDeep(getComponent(selectedComponentId));
                if (!component) {
                  return "Component not found";
                }

                forEachComponentAndDescendants(component, (component) => {
                  delete component.props["style@md"];
                  delete component.props["style@sm"];
                });
                onReplaceComponent(selectedComponentId as string, component);
              },
            } as const,
          ]
        : []),
      ...getStateStyleItems(),
    ],
  });

  const classNameForJSXMenuItems = "w-[12.5rem] px-4 py-2";
  if (isDebugMode) {
    items.push(
      {
        type: "leaf",
        id: "copyJson",
        title: (
          <div
            className={classNameForJSXMenuItems}
            onClick={() => {
              copy(getStringFromComponentJson(selectedComponentId as string));
              successToast("Component JSON Copied", "");
            }}
          >
            Copy Component JSON
          </div>
        ),
      },
      {
        id: "replaceComponentJSON",
        type: "leaf",
        title: (
          <div
            className={classNameForJSXMenuItems}
            onClick={() => {
              let componentJSON = window.prompt();
              if (componentJSON) {
                try {
                  componentJSON = JSON.parse(componentJSON);
                } catch {}

                if (componentJSON) {
                  onReplaceComponent(
                    selectedComponentId as string,
                    componentJSON,
                  );
                }
              }
            }}
          >
            Set Component JSON
          </div>
        ),
      },
      {
        type: "leaf",
        id: "copyAiResponsiveInfo",
        title: (
          <div
            className={classNameForJSXMenuItems}
            onClick={() => {
              const component = cloneDeep(
                getComponent(selectedComponentId as string),
              );
              if (!component) {
                return "Component not found";
              }
              const actions: object[] = [];

              forEachComponentAndDescendants(component, (component) => {
                // NOTE (Gabe 2024-07-09): We merge md and sm styles here
                // because we currently only care about mobile responsivity. If
                // we want to have tablet specific styles we'll have to generate
                // multiple actions.
                if (
                  !isEmpty(component.props["style@md"]) ||
                  !isEmpty(component.props["style@sm"])
                ) {
                  actions.push({
                    componentId: component.id,
                    type: "setStylesMobile",
                    value: {
                      ...component.props["style@md"],
                      ...component.props["style@sm"],
                    },
                  });
                }
                delete component.props["style@md"];
                delete component.props["style@sm"];
              });

              const result = { component, actions };
              copy(JSON.stringify(result, null, 2));
              successToast("AI Responsive Info Copied", "");
            }}
          >
            Copy AI Responsive Info
          </div>
        ),
      },
    );

    items.push({
      type: "nested",
      id: "debug",
      title: "Other Debug Options",
      items: [
        {
          type: "leaf",
          id: "copyComponentId",
          title: (
            <div
              className={classNameForJSXMenuItems}
              onClick={() => {
                copy(selectedComponentId!);
                successToast("Component ID Copied", "");
              }}
            >
              Copy Component ID
            </div>
          ),
        },
        {
          id: "setDraftElement",
          type: "leaf",
          title: (
            <div
              className={classNameForJSXMenuItems}
              onClick={(e) => {
                e.stopPropagation();
                const id = window.prompt();
                if (id) {
                  setDraftComponentId(id);
                }
              }}
            >
              Set Draft Component ID
            </div>
          ),
        },
        {
          id: "cleanComponentWithoutImages",
          type: "leaf",
          title: "Replace all content with placeholders",
          onSelect: () => {
            if (selectedComponentId) {
              onReplaceAllContentWithPlaceholders(selectedComponentId);
            }
          },
        },
        {
          id: "forceSentryError",
          type: "leaf",
          title: "Force Sentry Error",
          onSelect: () => {
            forceSentryError();
          },
        },
        {
          type: "nested",
          title: "Persist Styles Upstream",
          items: [
            {
              id: "persistStylesToUpstreamDevicesExcludingDescendants",
              type: "leaf",
              title: "Copy Styles to Larger Devices (This Component Only)",
              onSelect: () => {
                onPersistStylesToUpstreamDevices(
                  selectedComponentId as string,
                  false,
                );
              },
            },
            {
              id: "persistStylesToUpstreamDevicesExcludingDescendants",
              type: "leaf",
              title: "Copy Styles to Larger Devices (Include Descendants)",
              onSelect: () => {
                onPersistStylesToUpstreamDevices(
                  selectedComponentId as string,
                  true,
                );
              },
            },
          ],
        },
      ],
    });
  }

  return items;
};

export const getNewDraftComponentIds = (
  e: React.MouseEvent,
  nodeId: string,
  parent: Component | null,
  draftComponentIds: string[],
) => {
  const { ctrlKey, shiftKey, metaKey, type } = e;
  let newDraftComponentIds: string[] = [];

  // Get the most recent id added to draftComponentIds
  const latestDraftComponentId = draftComponentIds.slice(-1)[0] ?? null;

  const recentlySelectedIndex = getChildren(parent).findIndex(
    (child: Component) => child.id === nodeId,
  );

  const isAllIdsShareSameParent = [...draftComponentIds, nodeId].every(
    (id: string) => getChildren(parent).some((child) => child.id === id),
  );

  if (type === "contextmenu") {
    if (!draftComponentIds.includes(nodeId)) {
      newDraftComponentIds.push(nodeId);
    } else {
      newDraftComponentIds = draftComponentIds;
    }
  } else if (!shiftKey && !ctrlKey && !metaKey) {
    // treat as a normal single click
    newDraftComponentIds.push(nodeId);
  } else if (!parent || !isAllIdsShareSameParent) {
    // clicked but not under same parent
    return null;
  } else if (ctrlKey || metaKey) {
    newDraftComponentIds = [...draftComponentIds];
    // ctrl key will toggle selection
    if (newDraftComponentIds.includes(nodeId)) {
      newDraftComponentIds = newDraftComponentIds.filter((id) => id !== nodeId);
    } else {
      newDraftComponentIds.push(nodeId);
    }
  } else if (shiftKey) {
    // shift click will expand selection
    const previouslySelectedIndex = getChildren(parent).findIndex(
      (child: Component) => child.id === latestDraftComponentId,
    );
    if (previouslySelectedIndex === -1) {
      newDraftComponentIds.push(nodeId);
    } else {
      newDraftComponentIds = [...draftComponentIds];
      const minIndex = Math.min(previouslySelectedIndex, recentlySelectedIndex);
      const maxIndex = Math.max(previouslySelectedIndex, recentlySelectedIndex);
      const children = getChildren(parent);
      for (let i = minIndex; i <= maxIndex; i++) {
        if (!newDraftComponentIds.includes(children[i]!.id)) {
          newDraftComponentIds.push(children[i]!.id);
        }
      }
    }
  }

  return newDraftComponentIds;
};

export const isComponentHidden = (
  component: Component,
  getAttribute: GetAttributeFunction,
) => {
  return getAttribute(component, "style.display").value === "none";
};

export const getIconColor = (isRowHidden: boolean, isSymbolRef: boolean) => {
  if (isRowHidden) {
    return isSymbolRef ? "text-purple-200" : "text-blue-200";
  }
  return isSymbolRef ? "text-purple-600" : "text-blue-600";
};

export const forEachNodeAndDescendent = (
  node: ReorderableTreeNode,
  parentNode: ReorderableTreeNode | null,
  callback: (
    node: ReorderableTreeNode,
    parentNode: ReorderableTreeNode | null,
    depth: number,
  ) => void,
  depth = 0,
) => {
  if (node) {
    callback(node, parentNode, depth);
    for (const child of node.children) {
      forEachNodeAndDescendent(child, node, callback, depth + 1);
    }
  }
};

export const getDropEdge = (
  currentTop: number,
  dropY: number,
  treeRowHeight: number,
  isItemDroppable: boolean,
  isItemExpanded: boolean,
): DropEdge => {
  const percentage = ((dropY - currentTop) / treeRowHeight) * 100;

  let dropEdge = null;

  if (isItemDroppable) {
    if (percentage <= 33) {
      dropEdge = DropEdge.beforeCurrentNode;
    } else if (percentage <= 66) {
      dropEdge = isItemExpanded ? DropEdge.afterCurrentNode : DropEdge.middle;
    } else {
      dropEdge = DropEdge.afterCurrentNode;
    }
  } else {
    if (percentage <= 50) {
      dropEdge = DropEdge.beforeCurrentNode;
    } else {
      dropEdge = DropEdge.afterCurrentNode;
    }
  }

  return dropEdge;
};

export const isArrayUnOrderlyEqual = (a: Array<any>, b: Array<any>) => {
  const sortedA = [...a].sort();
  const sortedB = [...b].sort();
  return isEqual(sortedA, sortedB);
};

export const getComponentAlignment = (
  size: MediaSize,
  component?: Component,
): string | null => {
  if (!component || !component.props) {
    return null;
  }
  return getMediaSizeStyleAttributeValue("alignSelf", component.props, size);
};

export const getComponentFlexDirection = (
  size: MediaSize,
  componentProps?: Partial<Component>,
): string | null => {
  if (!componentProps) {
    return null;
  }
  return getMediaSizeStyleAttributeValue("flexDirection", componentProps, size);
};

export const getComponentPositioning = (
  size: MediaSize,
  component?: Component,
): string | null => {
  if (!component || !component.props) {
    return null;
  }
  return getMediaSizeStyleAttributeValue("position", component.props, size);
};

export const getComponentWidthProperties = (
  size: MediaSize,
  component?: Component,
): {
  width: string | undefined;
  maxWidth: string | undefined;
  minWidth: string | undefined;
} => {
  if (!component?.props) {
    return {
      width: undefined,
      maxWidth: undefined,
      minWidth: undefined,
    };
  }
  return {
    width: getMediaSizeStyleAttributeValue("width", component.props, size),
    maxWidth: getMediaSizeStyleAttributeValue(
      "maxWidth",
      component.props,
      size,
    ),
    minWidth: getMediaSizeStyleAttributeValue(
      "minWidth",
      component.props,
      size,
    ),
  };
};
