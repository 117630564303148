import type {
  ComparisonTimeFrame,
  RelativeTimeFrame,
} from "@/features/analytics/time";
import type { DateRange } from "react-day-picker";

import { routes } from "@editor/utils/router";

import { generatePath } from "react-router-dom";
import { hours } from "replo-utils/lib/datetime";

export function calculateDelta(
  newValue: number | undefined,
  oldValue: number | undefined,
) {
  if (!oldValue || newValue === undefined) {
    return 0;
  }

  return oldValue === 0 ? newValue : ((newValue - oldValue) / oldValue) * 100;
}

/**
 * Given a selected timeframe/period, returns true if it's a custom date range.
 * If it returns true, we can be 100% sure that the value has a .from/.to
 *
 * @author Max 2024-09-17
 */
export function isCustomDateRange(
  value: ComparisonTimeFrame | RelativeTimeFrame | DateRange,
): value is DateRange {
  return (
    (value as DateRange).from !== undefined &&
    (value as DateRange).to !== undefined
  );
}

/**
 * Sanitizes the page URL path by replacing "/" with "Homepage".
 *
 * @author Kurt 2024-10-13
 */
export function sanitizePageUrlPath(urlPath: string) {
  return urlPath !== "/" ? urlPath : "Homepage";
}

/**
 * Navigates to the target path with the current search query appended to it.
 *
 * @author Kurt 2024-10-13
 */
export const getUrlWithAnalyticsUrlParams = (
  targetPath: string,
  currentSearchParams: URLSearchParams,
) => {
  const queryUrlParamString = currentSearchParams.get("query");

  return getAnalyticsPathWithAppendedQueryParam(
    targetPath,
    queryUrlParamString,
  );
};

/**
 * Get url without query params.
 *
 * @author Kurt 2024-10-14
 */
export const getUrlWithoutQueryParams = (url: string) => {
  return url.split("?")[0];
};

/**
 * Return url host with . replaced with ;
 * NOTE (Kurt, 2024-10-23): We encode the . in the host with a special character ; because
 * because vercel doesn't support . in the host and fails with a 404 during URL resolution from
 * the browser address bar. However, navigation within react works fine with the . in the host.
 */
export const encodeUrlHost = (url: string) => {
  return encodeURIComponent(url.replace(/\./g, ";"));
};

/**
 * Return url host with ; replaced with .
 */
export const decodeUrlHost = (url: string) => {
  return decodeURIComponent(url).replace(/;/g, ".");
};

/**
 * Returns the interval from the selectedTimePeriod param. If the date range is
 * less than 2 days, it returns hours(1) instead.
 */
export const getIntervalFromTimePeriod = (
  startDatetime: number,
  endDatetime: number,
) => {
  const diffInDays = (endDatetime - startDatetime) / hours(24);
  return diffInDays < 2 ? hours(1) : hours(24);
};

export function generateAnalyticsPageDetailsPath(
  workspaceId: string,
  urlPath: string,
  url: string,
) {
  const mainDomain = url.match(/^(?:https?:\/\/)?([^/]+)/);

  return generatePath(routes.analytics.pageDetails, {
    workspaceId,
    pageUrlPath: encodeURIComponent(urlPath),
    host: encodeUrlHost(mainDomain?.[1] ?? ""),
  });
}

export function getAnalyticsPathWithAppendedQueryParam(
  path: string | null,
  queryUrlParamString: string | null,
) {
  if (!path) {
    return "";
  }

  return queryUrlParamString
    ? `${path}?query=${encodeURIComponent(queryUrlParamString)}`
    : path;
}
