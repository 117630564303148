import * as React from "react";

import { invariant } from "../lib/misc";

/**
 * Lightweight wrapper around `React.createContext` that requires a
 * `displayName`, which is useful for debugging in React DevTools.
 */
export function createContext<T>(
  displayName: string,
  initialValue: T,
): React.Context<T>;
export function createContext<T>(
  displayName: string,
  initialValue: T | null,
): React.Context<T | null>;
export function createContext<T>(
  displayName: string,
): React.Context<T | undefined>;

// NOTE (Chance 2024-06-20): `@__NO_SIDE_EFFECTS__` is an esbuild and rollup
// convention to indicate that this function has no side effects. By default
// esbuild might not tree-shake code that relies on this function because it
// sets `displayName`, and the bundler doesn't know whether that has side
// effects (ex, if there's a setter for the property). This annotation tells the
// bundler it's tree-shakable. Note too that the position in which the comment
// appears is important!
// https://github.com/evanw/esbuild/issues/3149#issuecomment-1585820844

/* @__NO_SIDE_EFFECTS__ */ export function createContext<T>(
  displayName: string,
  initialValue?: T,
) {
  const ctx = React.createContext(initialValue);
  ctx.displayName = displayName;
  return ctx;
}

export function useRequiredContext<T>(
  context: React.Context<T>,
): NonNullable<T> {
  const ctx = React.useContext(context);
  invariant(
    ctx != null,
    `useRequiredContext: ${context.displayName ?? "Unknown context"} is not defined. Caller component must be rendered in a provider.`,
  );
  return ctx;
}
