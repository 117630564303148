import * as React from "react";

/**
 * Hook that returns an array of options that match the search term.
 */
export default function useSearcheableOptions<T extends { label: string }>(
  options: T[] = [],
) {
  const [searchTerm, setSearchTerm] = React.useState("");

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.trim().toLowerCase()),
  );

  return {
    filteredOptions,
    searchTerm,
    setSearchTerm,
  };
}
