import type { MenuItem } from "@editor/components/common/designSystem/Menu";
import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { BoxShadow } from "replo-runtime/shared/types";
import type { TransformObject } from "replo-runtime/shared/utils/transform";
import type { ToggleGroupOption, Transform } from "schemas/effects";
import type { SavedColorStyle } from "schemas/generated/designLibrary";
import type { EffectsControlType } from "schemas/modifiers";

import * as React from "react";

import InlinePopover from "@editor/components/common/designSystem/InlinePopover";
import Popover from "@editor/components/common/designSystem/Popover";
import Selectable from "@editor/components/common/designSystem/Selectable";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import Separator from "@editor/components/common/designSystem/Separator";
import ToggleGroup from "@editor/components/common/designSystem/ToggleGroup";
import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import useGetDesignLibrarySavedStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import { useGetModifierControls } from "@editor/hooks/rightBar/useGetModifierControls";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useDynamicCommandMenuItem } from "@editor/hooks/useDynamicCommandMenuItems";
import { useModal } from "@editor/hooks/useModal";
import {
  selectCursor,
  selectDraftComponentId,
  selectDraftComponentPositionKey,
  selectParsedBoxShadows,
  selectRotation,
  selectTransform,
  selectTransformOrigin,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import {
  selectOpenPopoverId,
  setOpenPopoverId,
} from "@editor/reducers/ui-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { getBoxShadowString } from "@editor/utils/boxShadow";
import { getSavedStyleValue } from "@editor/utils/designLibrary";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { DraggingDirections } from "@editor/utils/editor";
import {
  getReadableTransformValue,
  getTransformOriginString,
  parseTransformOrigin,
} from "@editor/utils/effects";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import ModifierGroup from "@editorExtras/ModifierGroup";
import LengthInputModifier, {
  LengthInputSelector,
} from "@editorModifiers/LengthInputModifier";
import { transformDefaultValues } from "@editorModifiers/utils";

import { Badge } from "@replo/design-system/components/badge";
import merge from "lodash-es/merge";
import { CgArrowTopLeft } from "react-icons/cg";
import { MdOpacity, MdOutlineCropRotate } from "react-icons/md";
import { RiCursorFill, RiShadowFill } from "react-icons/ri";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { isDynamicDesignLibraryValue } from "replo-runtime/shared/utils/designLibrary";
import { getTransformStyleString } from "replo-runtime/shared/utils/transform";
import {
  CSS_ANGLE_TYPES,
  CSS_LENGTH_TYPES,
} from "replo-runtime/shared/utils/units";
import { v4 as uuidv4 } from "uuid";

import ModifierLabel from "../extras/ModifierLabel";
import { DynamicColorSelector } from "./DynamicColorModifier";

const DEFAULT_BOX_SHADOW = {
  shadowType: "dropShadow" as const,
  offsetX: "0px",
  offsetY: "4px",
  blur: "4px",
  spread: "0px",
  shadowColor: "#00000040",
};

type EffectsPopoverId = "transform" | "transformOrigin" | null;

const EffectsModifier: React.FC = () => {
  const [controls, addControl] = useGetModifierControls<"effects">("effects");
  const handleAddBoxShadow = useAddNewBoxShadow();
  const [openPopoverId, setOpenPopoverId] =
    React.useState<EffectsPopoverId>(null);

  const menuItems: MenuItem[] = [
    {
      id: "transform",
      title: "Transform",
      onSelect: () => {
        addControl("transform");
        setOpenPopoverId("transform");
      },
      type: "leaf",
      isDisabled: controls.has("transform"),
    },
    {
      id: "transformOrigin",
      title: "Transform Origin",
      onSelect: () => {
        addControl("transformOrigin");
        setOpenPopoverId("transformOrigin");
      },
      type: "leaf",
      isDisabled: controls.has("transformOrigin"),
    },
    {
      id: "cursor",
      title: "Cursor",
      onSelect: () => addControl("cursor"),
      type: "leaf",
      isDisabled: controls.has("cursor"),
    },
    {
      id: "opacity",
      title: "Opacity",
      onSelect: () => addControl("opacity"),
      type: "leaf",
      isDisabled: controls.has("opacity"),
    },
    {
      id: "boxShadow",
      title: "Box Shadow",
      onSelect: () => {
        addControl("boxShadow");
        handleAddBoxShadow();
      },
      type: "leaf",
      isDisabled: false,
    },
  ];

  const controlsToComponent: Array<{
    id: EffectsControlType;
    component: React.ReactNode;
  }> = [
    {
      id: "transform",
      component: (
        <TransformControl
          isOpen={openPopoverId === "transform"}
          setIsOpen={(isOpen) => setOpenPopoverId(isOpen ? "transform" : null)}
        />
      ),
    },
    {
      id: "transformOrigin",
      component: (
        <TransformOriginControl
          isOpen={openPopoverId === "transformOrigin"}
          setIsOpen={(isOpen) =>
            setOpenPopoverId(isOpen ? "transformOrigin" : null)
          }
        />
      ),
    },
    {
      id: "opacity",
      component: <OpacityControl />,
    },
    {
      id: "cursor",
      component: <CursorControl />,
    },
    {
      id: "boxShadow",
      component: <ShadowControl />,
    },
  ];

  const hasControlsToRender = controls.size > 0;

  const onSelectTransform = React.useCallback(() => {
    addControl("transform");
    setOpenPopoverId("transform");
  }, [addControl]);

  const transformDynamicMenuItem = React.useMemo(() => {
    return !controls.has("transform")
      ? {
          label: "Add Transform",
          group: "add",
          icon: MdOutlineCropRotate,
          onSelect: onSelectTransform,
        }
      : null;
  }, [controls, onSelectTransform]);

  useDynamicCommandMenuItem("transform", transformDynamicMenuItem);

  const onSelectTransformOrigin = React.useCallback(() => {
    addControl("transformOrigin");
    setOpenPopoverId("transformOrigin");
  }, [addControl]);

  const transformOriginDynamicMenuItem = React.useMemo(() => {
    return !controls.has("transformOrigin")
      ? {
          label: "Add Transform Origin",
          group: "add",
          icon: CgArrowTopLeft,
          onSelect: onSelectTransformOrigin,
        }
      : null;
  }, [controls, onSelectTransformOrigin]);

  useDynamicCommandMenuItem("transformOrigin", transformOriginDynamicMenuItem);

  const onSelectCursor = React.useCallback(() => {
    addControl("cursor");
  }, [addControl]);

  const cursorDynamicMenuItem = React.useMemo(() => {
    return !controls.has("cursor")
      ? {
          label: "Add Cursor",
          group: "add",
          icon: RiCursorFill,
          onSelect: onSelectCursor,
        }
      : null;
  }, [controls, onSelectCursor]);

  useDynamicCommandMenuItem("cursor", cursorDynamicMenuItem);

  const onSelectOpacity = React.useCallback(() => {
    addControl("opacity");
  }, [addControl]);

  const opacityDynamicMenuItem = React.useMemo(() => {
    return !controls.has("opacity")
      ? {
          label: "Add Opacity",
          group: "add",
          icon: MdOpacity,
          onSelect: onSelectOpacity,
        }
      : null;
  }, [controls, onSelectOpacity]);

  useDynamicCommandMenuItem("opacity", opacityDynamicMenuItem);

  const onSelectBoxShadow = React.useCallback(() => {
    addControl("boxShadow");
    handleAddBoxShadow();
  }, [addControl, handleAddBoxShadow]);

  const boxShadowDynamicMenuItem = React.useMemo(() => {
    return !controls.has("boxShadow")
      ? {
          label: "Add Box Shadow",
          group: "add",
          icon: RiShadowFill,
          onSelect: onSelectBoxShadow,
        }
      : null;
  }, [controls, onSelectBoxShadow]);

  useDynamicCommandMenuItem("boxShadow", boxShadowDynamicMenuItem);

  return (
    <ModifierGroup
      title="Effects"
      isDefaultOpen={hasControlsToRender}
      isCollapsible={hasControlsToRender}
      menuItems={menuItems}
      tooltipText="Add Additional Effects"
      // Note (Noah, 2024-10-25): Disable focusing the menu trigger on close, since this will
      // dismiss the popover that opens when we add a box shadow
      disableMenuTriggerFocusOnClose
    >
      <div className="flex flex-col gap-2">
        {controlsToComponent.map((control) => {
          const shouldRender = controls.has(control.id);
          if (!shouldRender) {
            return null;
          }

          return control.component;
        })}
      </div>
    </ModifierGroup>
  );
};

const OpacityControl: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const draftComponentId = useEditorSelector(selectDraftComponentId);

  const onChange = (newValue: string) => {
    applyComponentAction({
      componentId: draftComponentId,
      type: "setStyles",
      value: { opacity: newValue },
    });
  };

  const opacityDefaultValue = `${
    Number.parseInt(styleAttributeToEditorData.opacity.defaultValue) * 100
  }%`;

  return (
    <div className="flex items-center">
      <LengthInputModifier
        label={<ModifierLabel label="Opacity" />}
        dragTrigger="label"
        menuOptions={["0%", "50%", "100%"].map((v) => ({
          label: v,
          value: v,
        }))}
        placeholder={opacityDefaultValue}
        anchorValue="50%"
        resetValue={opacityDefaultValue}
        metrics={["%"]}
        field="style.opacity"
        onChange={onChange}
        minValues={{ "%": 0 }}
        maxValues={{ "%": 100 }}
        minDragValues={{ "%": 0 }}
        maxDragValues={{ "%": 100 }}
        previewProperty="opacity"
      />
    </div>
  );
};

const cursorSelectableItems = [
  {
    label: "Default",
    value: "default",
  },
  {
    label: "Pointer",
    value: "pointer",
  },
  {
    label: "Crosshair",
    value: "crosshair",
  },
  {
    label: "Not Allowed",
    value: "not-allowed",
  },
  {
    label: "Input",
    value: "text",
  },
  {
    label: "Arrows",
    value: "ew-resize",
  },
  {
    label: "Vertical Arrows",
    value: "ns-resize",
  },
  {
    label: "No Cursor",
    value: "none",
  },
];

const CursorControl: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const value = useEditorSelector(selectCursor);

  return (
    <div className="flex w-full items-center">
      <ModifierLabel label="Cursor" />
      <Selectable
        value={value && value !== "auto" ? value : "default"}
        options={cursorSelectableItems}
        onSelect={(newValue) => {
          applyComponentAction({
            type: "setStyles",
            value: { cursor: newValue },
          });
        }}
      />
    </div>
  );
};

const TransformControl: React.FC<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
  const [verticalPosition, horizontalPosition] = useEditorSelector(
    selectDraftComponentPositionKey,
  );
  const shouldDisableTranslateTab =
    verticalPosition === "center" || horizontalPosition === "center";
  const activeTabInitialValue = shouldDisableTranslateTab ? "scale" : "move";
  const [activeTab, setActiveTab] = React.useState<Transform>(
    activeTabInitialValue,
  );

  const applyComponentAction = useApplyComponentAction();

  const transformObjectOrNull = useEditorSelector(selectTransform);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const draftElementAlchemyRotation = useEditorSelector(selectRotation);
  const draftElementTransform = merge(
    {},
    transformDefaultValues,
    transformObjectOrNull,
  );

  const handleApplyTransform = (value: TransformObject) => {
    const actions: UseApplyComponentActionType[] = [
      {
        type: "setStyles",
        componentId: draftComponentId,
        value: { __transform: value },
      },
    ];
    // Note (Sebas, 2022-07-20): The __alchemyRotation value is not necessary anymore.
    // If it exists we need to reset it to null
    if (draftElementAlchemyRotation) {
      actions.push({
        componentId: draftComponentId,
        type: "setStyles",
        value: {
          __alchemyRotation:
            styleAttributeToEditorData.__alchemyRotation.defaultValue,
        },
      });
    }
    applyComponentAction({
      componentId: draftComponentId,
      type: "applyCompositeAction",
      value: actions,
    });
  };

  const handleChange = (value: TransformObject) => {
    handleApplyTransform(value);
  };

  const onPropertyChange = (value: string, type: string) => {
    const updatedProps = { ...draftElementTransform, [type]: value };
    handleChange(updatedProps);
  };

  const onMultiplePropertyChange = (types: string[], value: string) => {
    const newValues = types.reduce(
      (accum: Record<string, string>, currentValue: string) => {
        accum[currentValue] = value;
        return accum;
      },
      {},
    );
    handleChange({ ...draftElementTransform, ...newValues });
  };

  const calculateValue = (
    value: string,
    operation: "add" | "substract" | "multiply",
    operand: number,
    unit?: string,
  ) => {
    const operations = {
      add: `${(Number.parseInt(value, 10) + operand).toString()}`,
      substract: `${(Number.parseInt(value, 10) - operand).toString()}`,
      multiply: `${(Number.parseInt(value, 10) * operand).toString()}`,
    };
    return `${operations[operation]}${unit ?? ""}`;
  };

  const scaleSharedMenuOptions = [
    { label: "110%", value: "110%" },
    { label: "120%", value: "120%" },
    { label: "130%", value: "130%" },
  ];

  const rotateSharedMenuOptions = [
    { label: "90deg", value: "90deg" },
    { label: "180deg", value: "180deg" },
    { label: "270deg", value: "270deg" },
  ];

  const getScaleSimplifiedValue = () => {
    const values = draftElementTransform;
    if (values.scaleX !== values.scaleY) {
      return "100%";
    } else if (values.scaleX.includes("%")) {
      return values.scaleX;
    }
    return calculateValue(values.scaleX, "multiply", 100);
  };

  const toggleGroupOptions: ToggleGroupOption<Transform>[] = [
    {
      label: "Move",
      value: "move",
      metrics: CSS_LENGTH_TYPES,
      inputs: [
        {
          label: "X Axis",
          resetValue: transformDefaultValues.translateX,
          anchorValue: transformDefaultValues.translateX,
          placeholder: transformDefaultValues.translateX,
          onChange: (value: string) => onPropertyChange(value, "translateX"),
          value: draftElementTransform.translateX,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.translateX },
          ],
          previewSubProperty: "translateX",
          autofocus: activeTab === "move",
        },
        {
          label: "Y Axis",
          resetValue: transformDefaultValues.translateY,
          anchorValue: transformDefaultValues.translateY,
          placeholder: transformDefaultValues.translateY,
          onChange: (value: string) => onPropertyChange(value, "translateY"),
          value: draftElementTransform.translateY,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.translateY },
          ],
          previewSubProperty: "translateY",
        },
        {
          label: "Z Axis",
          resetValue: transformDefaultValues.translateZ,
          anchorValue: transformDefaultValues.translateZ,
          placeholder: transformDefaultValues.translateZ,
          onChange: (value: string) => onPropertyChange(value, "translateZ"),
          value: draftElementTransform.translateZ,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.translateZ },
          ],
          previewSubProperty: "translateZ",
        },
      ],
    },
    {
      label: "Scale",
      value: "scale",
      metrics: ["%"],
      inputs: [
        {
          label: "Transform",
          resetValue: transformDefaultValues.scaleX,
          anchorValue: transformDefaultValues.scaleX,
          placeholder: transformDefaultValues.scaleX,
          onChange: (value: string) =>
            onMultiplePropertyChange(["scaleX", "scaleY"], value),
          value: getScaleSimplifiedValue(),
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.scaleX },
            ...scaleSharedMenuOptions,
          ],
          previewSubProperty: "scaleXY",
          hasDivider: true,
        },
        {
          label: "X Axis",
          resetValue: transformDefaultValues.scaleX,
          anchorValue: transformDefaultValues.scaleX,
          placeholder: transformDefaultValues.scaleX,
          onChange: (value: string) => onPropertyChange(value, "scaleX"),
          value: draftElementTransform.scaleX.includes("%")
            ? draftElementTransform.scaleX
            : calculateValue(draftElementTransform.scaleX, "multiply", 100),
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.scaleX },
            ...scaleSharedMenuOptions,
          ],
          previewSubProperty: "scaleX",
        },
        {
          label: "Y Axis",
          resetValue: transformDefaultValues.scaleY,
          anchorValue: transformDefaultValues.scaleY,
          placeholder: transformDefaultValues.scaleY,
          onChange: (value: string) => onPropertyChange(value, "scaleY"),
          value: draftElementTransform.scaleY.includes("%")
            ? draftElementTransform.scaleY
            : calculateValue(draftElementTransform.scaleY, "multiply", 100),
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.scaleY },
            ...scaleSharedMenuOptions,
          ],
          previewSubProperty: "scaleY",
        },
        {
          label: "Z Axis",
          resetValue: transformDefaultValues.scaleZ,
          anchorValue: transformDefaultValues.scaleZ,
          placeholder: transformDefaultValues.scaleZ,
          onChange: (value: string) => onPropertyChange(value, "scaleZ"),
          value: draftElementTransform.scaleZ.includes("%")
            ? draftElementTransform.scaleZ
            : calculateValue(draftElementTransform.scaleZ, "multiply", 100),
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.scaleZ },
            ...scaleSharedMenuOptions,
          ],
          previewSubProperty: "scaleZ",
        },
      ],
    },
    {
      label: "Rotate",
      value: "rotate",
      metrics: ["deg", "rad", "turn"],
      inputs: [
        {
          label: "Transform",
          resetValue: transformDefaultValues.rotateZ,
          anchorValue: transformDefaultValues.rotateZ,
          placeholder: transformDefaultValues.rotateZ,
          onChange: (value: string) => onPropertyChange(value, "rotateZ"),
          value: draftElementTransform.rotateZ,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.rotateZ },
            ...rotateSharedMenuOptions,
          ],
          previewSubProperty: "rotateZ",
          hasDivider: true,
        },
        {
          label: "X Axis",
          resetValue: transformDefaultValues.rotateX,
          anchorValue: transformDefaultValues.rotateX,
          placeholder: transformDefaultValues.rotateX,
          onChange: (value: string) => onPropertyChange(value, "rotateX"),
          value: draftElementTransform.rotateX,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.rotateX },
            ...rotateSharedMenuOptions,
          ],
          previewSubProperty: "rotateX",
        },
        {
          label: "Y Axis",
          resetValue: transformDefaultValues.rotateY,
          anchorValue: transformDefaultValues.rotateY,
          placeholder: transformDefaultValues.rotateY,
          onChange: (value: string) => onPropertyChange(value, "rotateY"),
          value: draftElementTransform.rotateY,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.rotateY },
            ...rotateSharedMenuOptions,
          ],
          previewSubProperty: "rotateY",
        },
        {
          label: "Z Axis",
          resetValue: transformDefaultValues.rotateZ,
          anchorValue: transformDefaultValues.rotateZ,
          placeholder: transformDefaultValues.rotateZ,
          onChange: (value: string) => onPropertyChange(value, "rotateZ"),
          value: draftElementTransform.rotateZ,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.rotateZ },
            ...rotateSharedMenuOptions,
          ],
          previewSubProperty: "rotateZ",
        },
      ],
    },
    {
      label: "Skew",
      value: "skew",
      metrics: CSS_ANGLE_TYPES,
      inputs: [
        {
          label: "X Axis",
          resetValue: transformDefaultValues.skewX,
          anchorValue: transformDefaultValues.skewX,
          placeholder: transformDefaultValues.skewX,
          onChange: (value: string) => onPropertyChange(value, "skewX"),
          value: draftElementTransform.skewX,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.skewX },
          ],
          previewSubProperty: "skewX",
          autofocus: activeTab === "skew",
        },
        {
          label: "Y Axis",
          resetValue: transformDefaultValues.skewY,
          anchorValue: transformDefaultValues.skewY,
          placeholder: transformDefaultValues.skewY,
          onChange: (value: string) => onPropertyChange(value, "skewY"),
          value: draftElementTransform.skewY,
          menuOptions: [
            { label: "Reset", value: transformDefaultValues.skewY },
          ],
          previewSubProperty: "skewY",
        },
      ],
    },
  ];

  const activeGroupOption = toggleGroupOptions.find(
    (option) => option.value === activeTab,
  );

  const transformTitle = getReadableTransformValue(
    getTransformStyleString(draftElementTransform),
  );

  return (
    <div className="flex items-center w-full">
      <ModifierLabel label="Transform" />
      <InlinePopover
        isOpen={isOpen}
        shouldPreventDefaultOnInteractOutside={false}
        onOpenChange={setIsOpen}
        title="Transform"
        triggerAsChild
        // NOTE (Sebas, 2024-10-18): This offset is different from the others because this
        // popover is being trigger from an entire input and not from an internal badge.
        sideOffset={84}
        content={
          <div className="flex flex-col gap-2">
            <ToggleGroup
              allowsDeselect={false}
              type="single"
              options={toggleGroupOptions}
              value={activeTab}
              onChange={(value) => {
                setActiveTab(value);
              }}
              style={{ width: "100%" }}
            />
            {activeGroupOption
              ? activeGroupOption.inputs.map(
                  ({
                    label,
                    resetValue,
                    anchorValue,
                    placeholder,
                    value,
                    onChange,
                    menuOptions,
                    previewSubProperty,
                    hasDivider,
                  }) => (
                    <div key={label} className="flex flex-col gap-2">
                      <div className="flex flex-row items-end justify-between">
                        <LengthInputSelector
                          metrics={activeGroupOption.metrics}
                          className="col-span-1"
                          label={<ModifierLabel label={label} />}
                          resetValue={resetValue}
                          anchorValue={anchorValue}
                          placeholder={placeholder}
                          value={value!}
                          onChange={onChange!}
                          menuOptions={menuOptions}
                          previewProperty="transform"
                          previewSubProperty={previewSubProperty}
                          autofocus
                          dragTrigger="label"
                          // NOTE (Fran 2024-10-02): Actually Length Input selector does not need
                          // this prop to set a value. It is used for testing or parse the unit, we don't
                          // need that on these fields.
                          field=""
                        />
                      </div>
                      {hasDivider ? <Separator /> : null}
                    </div>
                  ),
                )
              : null}
          </div>
        }
      >
        <div className="flex-1">
          <SelectionIndicator
            className="max-w-40"
            title={transformTitle}
            onClick={() => setIsOpen(true)}
            onReset={() => handleApplyTransform(transformDefaultValues)}
            placeholder="Translate 0px"
            endEnhancer={
              transformTitle.length > 0 && (
                <FormFieldXButton
                  onClick={(e) => {
                    // NOTE (Sebas, 2024-10-10): This is necessary to prevent executing the onClick event of
                    // the parent element.
                    e.stopPropagation();
                    handleApplyTransform(transformDefaultValues);
                  }}
                />
              )
            }
          />
        </div>
      </InlinePopover>
    </div>
  );
};

const TransformOriginControl: React.FC<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
  const draftElementTransformOrigin =
    useEditorSelector(selectTransformOrigin) ?? "";
  const draftElementTransformOriginObject = parseTransformOrigin(
    String(draftElementTransformOrigin),
  );

  const draftComponentId = useEditorSelector(selectDraftComponentId);

  const applyComponentAction = useApplyComponentAction();

  const transformOriginDefaultValues =
    styleAttributeToEditorData.transformOrigin.defaultValue.split(" ");
  const transformOriginX = transformOriginDefaultValues[0]!;
  const transformOriginY = transformOriginDefaultValues[1]!;
  const transformOriginZ = transformOriginDefaultValues[2]!;
  const transformOriginInputs = [
    {
      label: "X Axis",
      title: "transformOriginX" as const,
      resetValue: transformOriginX,
      anchorValue: transformOriginY,
      placeholder: transformOriginZ,
      metrics: CSS_LENGTH_TYPES,
      menuOptions: [
        {
          label: "Reset",
          value: transformOriginX,
        },
        {
          label: "Left",
          value: "0%",
        },
        {
          label: "Center",
          value: "50%",
        },
        {
          label: "Right",
          value: "100%",
        },
      ],
      autofocus: true,
    },
    {
      label: "Y Axis",
      title: "transformOriginY" as const,
      resetValue: transformOriginY,
      anchorValue: transformOriginY,
      placeholder: transformOriginY,
      metrics: CSS_LENGTH_TYPES,
      menuOptions: [
        {
          label: "Reset",
          value: transformOriginY,
        },
        {
          label: "Top",
          value: "0%",
        },
        {
          label: "Center",
          value: "50%",
        },
        {
          label: "Bottom",
          value: "100%",
        },
      ],
    },
    {
      label: "Z Axis",
      title: "transformOriginZ" as const,
      resetValue: transformOriginZ,
      anchorValue: transformOriginZ,
      placeholder: transformOriginZ,
      metrics: CSS_LENGTH_TYPES,
      menuOptions: [
        {
          label: "Reset",
          value: transformOriginZ,
        },
      ],
    },
  ];

  const handleApplyTransformOrigin = (value: string | null) => {
    applyComponentAction({
      componentId: draftComponentId,
      type: "setStyles",
      value: {
        transformOrigin: value,
      },
    });
  };

  const handleChange = (value: string) => {
    handleApplyTransformOrigin(value);
  };

  const internalOnChange = (
    value: string,
    inputName: "transformOriginX" | "transformOriginY" | "transformOriginZ",
  ) => {
    const newValues = {
      ...draftElementTransformOriginObject,
      [inputName]: value,
    };
    handleChange(getTransformOriginString(newValues));
  };

  return (
    <div className="flex items-center w-full">
      <ModifierLabel label="Origin" />
      <InlinePopover
        isOpen={isOpen}
        shouldPreventDefaultOnInteractOutside={false}
        onOpenChange={setIsOpen}
        title="Transform Origin"
        triggerAsChild
        // NOTE (Sebas, 2024-10-18): This offset is different from the others because this
        // popover is being trigger from an entire input and not from an internal badge.
        sideOffset={84}
        content={
          <div className="flex flex-col gap-2">
            {transformOriginInputs.map(
              ({
                label,
                title,
                anchorValue,
                placeholder,
                resetValue,
                menuOptions,
                metrics,
                autofocus = false,
              }) => (
                <LengthInputSelector
                  label={<ModifierLabel label={label} />}
                  key={title}
                  metrics={metrics}
                  className="col-span-1"
                  field={title}
                  resetValue={resetValue}
                  anchorValue={anchorValue}
                  placeholder={placeholder}
                  value={draftElementTransformOriginObject[title]}
                  onChange={(value: string) => internalOnChange(value, title)}
                  menuOptions={menuOptions}
                  previewProperty="transformOrigin"
                  previewSubProperty={title}
                  autofocus={autofocus}
                  dragTrigger="label"
                />
              ),
            )}
          </div>
        }
      >
        <div className="flex-1">
          <SelectionIndicator
            title={draftElementTransformOrigin}
            onClick={() => setIsOpen(true)}
            onReset={() => handleApplyTransformOrigin(null)}
            placeholder="0px 0px 0px"
            endEnhancer={
              draftElementTransformOrigin !== "" && (
                <FormFieldXButton
                  onClick={(e) => {
                    // NOTE (Sebas, 2024-10-10): This is necessary to prevent executing the onClick event of
                    // the parent element.
                    e.stopPropagation();
                    handleApplyTransformOrigin(null);
                  }}
                />
              )
            }
          />
        </div>
      </InlinePopover>
    </div>
  );
};

const ShadowControl: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const dispatch = useEditorDispatch();
  const draftComponentBoxShadows = useEditorSelector(selectParsedBoxShadows);
  const openPopoverId = useEditorSelector(selectOpenPopoverId);
  const { colorSavedStyles } = useGetDesignLibrarySavedStyles();

  if (!draftComponentBoxShadows) {
    return null;
  }

  const isPopoverOpen =
    openPopoverId &&
    typeof openPopoverId === "object" &&
    "box-shadow" in openPopoverId;

  const currentShadowIndex = isPopoverOpen ? openPopoverId["box-shadow"] : null;
  const currentShadow =
    currentShadowIndex != null
      ? draftComponentBoxShadows[currentShadowIndex]
      : null;

  const onCreate = (value: string) => {
    applyComponentAction({
      type: "setStyles",
      value: {
        boxShadow: value,
      },
    });
  };

  const handleRemoveBoxShadow = (id: string) => {
    const filteredBoxShadows = draftComponentBoxShadows.filter(
      (boxShadow) => boxShadow.id !== id,
    );
    const updatedBoxShadowes = getBoxShadowString(filteredBoxShadows);
    onCreate(updatedBoxShadowes);
  };

  const handleBoxShadowChange = (value: BoxShadow) => {
    const newBoxShadows = draftComponentBoxShadows.map((boxShadow) => {
      return boxShadow.id === value.id ? value : boxShadow;
    });
    const updatedBoxShadows = getBoxShadowString(newBoxShadows);
    onCreate(updatedBoxShadows);
  };

  const getBadgeColor = (color: string) => {
    if (color && isDynamicDesignLibraryValue(color)) {
      const savedStyle = getSavedStyleValue(colorSavedStyles, color);
      return savedStyle?.attributes && "color" in savedStyle.attributes
        ? String(savedStyle.attributes.color)
        : "text-subtle";
    }
    return color ?? "text-subtle";
  };

  return (
    <div
      // NOTE (Fran 2024-10-16): 74px is the fixed width of the label.
      className="grid grid-cols-[74px,auto] w-full items-center gap-y-2"
    >
      <ModifierLabel label="Shadow" />
      {draftComponentBoxShadows.map((boxShadow, index) => {
        const badgeColor = getBadgeColor(boxShadow.shadowColor);
        return (
          <div className="col-start-2" key={boxShadow.id}>
            <SelectionIndicator
              className="max-w-40"
              title={formatTitle(boxShadow, colorSavedStyles)}
              onClick={() =>
                dispatch(setOpenPopoverId({ "box-shadow": index }))
              }
              startEnhancer={
                <Badge type="color" isFilled backgroundColor={badgeColor} />
              }
              endEnhancer={
                <FormFieldXButton
                  onClick={(e) => {
                    // NOTE (Sebas, 2024-10-10): This is necessary to prevent executing the onClick event to avoid open
                    // the popover when the user wants to remove the shadow.
                    e.stopPropagation();
                    handleRemoveBoxShadow(boxShadow.id);
                  }}
                />
              }
            />
          </div>
        );
      })}
      {currentShadow && (
        <BoxShadowPopover
          isOpen={isPopoverOpen ?? false}
          onRequestClose={() => dispatch(setOpenPopoverId(null))}
          shadow={currentShadow}
          onBoxShadowChange={handleBoxShadowChange}
          boxShadowIndex={currentShadowIndex ?? 0}
        />
      )}
    </div>
  );
};

const BoxShadowPopover: React.FC<{
  isOpen: boolean;
  shadow: BoxShadow;
  onRequestClose: () => void;
  onBoxShadowChange(value: BoxShadow): void;
  boxShadowIndex: number;
}> = ({
  isOpen = false,
  onRequestClose,
  shadow,
  onBoxShadowChange,
  boxShadowIndex,
}) => {
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  return (
    <Popover
      isOpen={isOpen}
      onOpenChange={(newValue: boolean) => {
        if (!newValue) {
          onRequestClose();
        }
      }}
    >
      <Popover.Content
        shouldPreventDefaultOnInteractOutside={areModalsOpen}
        title="Box Shadow"
        onRequestClose={onRequestClose}
      >
        <BoxShadowPopoverContent
          shadow={shadow}
          handleBoxShadowChange={onBoxShadowChange}
          boxShadowIndex={boxShadowIndex}
        />
      </Popover.Content>
      <Popover.Anchor className="relative top-0 left-0" />
    </Popover>
  );
};

const BoxShadowPopoverContent: React.FC<{
  shadow: BoxShadow;
  handleBoxShadowChange(value: BoxShadow): void;
  boxShadowIndex: number;
}> = ({ shadow, handleBoxShadowChange, boxShadowIndex }) => {
  const modal = useModal();
  const componentId = useEditorSelector(selectDraftComponentId);

  if (!componentId) {
    return null;
  }

  const handleInputChange = (
    value: string,
    inputType:
      | "shadowType"
      | "offsetX"
      | "offsetY"
      | "blur"
      | "spread"
      | "shadowColor",
  ) => {
    const newBoxShadow = {
      ...shadow,
      [inputType]: value,
    };
    handleBoxShadowChange(newBoxShadow);
  };

  const openDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.TEXT_COLOR,
        referrerData: {
          type: "callback",
          onChange: (value: string) => {
            handleInputChange(value, "shadowColor");
          },
        },
        initialPath: shadow?.shadowType
          ? getPathFromVariable(shadow.shadowType)
          : undefined,
      },
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center w-full">
        <ModifierLabel label="Style" />
        <Selectable
          className="col-span-2"
          onSelect={(value: string) => handleInputChange(value, "shadowType")}
          value={shadow?.shadowType}
          options={[
            {
              label: "None",
              value: "none",
            },
            {
              label: "Drop Shadow",
              value: "dropShadow",
            },
            { label: "Inset", value: "inset" },
          ]}
        />
      </div>
      <LengthInputSelector
        label={<ModifierLabel label="X Axis" />}
        metrics={CSS_LENGTH_TYPES}
        className="col-span-1"
        field="offsetX"
        resetValue="0px"
        anchorValue="0px"
        placeholder="0px"
        value={shadow?.offsetX || null}
        onChange={(value: string) => handleInputChange(value, "offsetX")}
        isDisabled={shadow?.shadowType === "none"}
        draggingDirection={DraggingDirections.Negative}
        previewProperty="boxShadow"
        previewSubProperty="offsetX"
        previewPropertyIndex={boxShadowIndex}
        autofocus
        dragTrigger="label"
      />
      <LengthInputSelector
        label={<ModifierLabel label="Y Axis" />}
        metrics={CSS_LENGTH_TYPES}
        className="col-span-1"
        field="offsetY"
        resetValue="0px"
        anchorValue="0px"
        placeholder="0px"
        dragTrigger="label"
        draggingDirection={DraggingDirections.Negative}
        value={shadow?.offsetY || null}
        onChange={(value: string) => handleInputChange(value, "offsetY")}
        isDisabled={shadow?.shadowType === "none"}
        previewProperty="boxShadow"
        previewSubProperty="offsetY"
        previewPropertyIndex={boxShadowIndex}
      />
      <LengthInputSelector
        label={<ModifierLabel label="Blur" />}
        metrics={CSS_LENGTH_TYPES}
        className="col-span-1"
        minDragValues={{ px: 0 }}
        minValues={{ px: 0 }}
        field="blur"
        resetValue="0px"
        anchorValue="0px"
        placeholder="0px"
        dragTrigger="label"
        value={shadow?.blur || null}
        onChange={(value: string) => handleInputChange(value, "blur")}
        isDisabled={shadow?.shadowType === "none"}
        previewProperty="boxShadow"
        previewSubProperty="blur"
        previewPropertyIndex={boxShadowIndex}
      />
      <LengthInputSelector
        label={<ModifierLabel label="Spread" />}
        metrics={CSS_LENGTH_TYPES}
        className="col-span-1"
        field="spread"
        resetValue="0px"
        anchorValue="0px"
        placeholder="0px"
        dragTrigger="label"
        value={shadow?.spread || null}
        onChange={(value: string) => handleInputChange(value, "spread")}
        isDisabled={shadow?.shadowType === "none"}
        previewProperty="boxShadow"
        previewSubProperty="spread"
        previewPropertyIndex={boxShadowIndex}
      />
      <div className="flex items-center w-full">
        <ModifierLabel label="Color" />

        <DynamicColorSelector
          field="color"
          componentId={componentId}
          allowsGradientSelection={false}
          popoverTitle="Shadow Color"
          value={shadow?.shadowColor || ""}
          onChange={(value: string) => handleInputChange(value, "shadowColor")}
          showSavedStyles
          onSavedStyleSelect={(value: string) =>
            handleInputChange(value, "shadowColor")
          }
          onRemove={() => handleInputChange("", "shadowColor")}
          openDynamicData={openDynamicData}
        />
      </div>
    </div>
  );
};

const formatTitle = (
  boxShadow: BoxShadow,
  colorSavedStyles?: (SavedColorStyle & { id: string })[],
) => {
  // NOTE (Fran 2024-12-03): If the color is a dynamic design library value, we need to get the saved style name
  // and replace the color value with the saved style name.
  if (
    boxShadow.shadowColor &&
    isDynamicDesignLibraryValue(boxShadow.shadowColor) &&
    colorSavedStyles
  ) {
    const savedStyle = getSavedStyleValue(
      colorSavedStyles,
      boxShadow.shadowColor,
    );
    return getBoxShadowString(
      [
        {
          ...boxShadow,
          shadowColor: savedStyle?.name ?? "SavedStyle",
        },
      ],
      true,
    );
  }
  const boxShadowTitle = getBoxShadowString([boxShadow], true);
  return boxShadowTitle.includes("none") ? "None" : boxShadowTitle;
};

const useAddNewBoxShadow = () => {
  const store = useEditorStore();
  const applyComponentAction = useApplyComponentAction();
  const dispatch = useEditorDispatch();

  return () => {
    const draftComponentBoxShadows =
      selectParsedBoxShadows(store.getState()) ?? [];

    const newBoxShadow = {
      id: uuidv4(),
      ...DEFAULT_BOX_SHADOW,
    };
    const newBoxShadowString = getBoxShadowString([
      ...draftComponentBoxShadows,
      newBoxShadow,
    ]);
    applyComponentAction({
      type: "setStyles",
      value: {
        boxShadow: newBoxShadowString,
      },
    });
    dispatch(
      setOpenPopoverId({
        "box-shadow": draftComponentBoxShadows.length,
      }),
    );
  };
};

export default EffectsModifier;
