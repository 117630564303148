export function getFolderNameFromElementName(name: string) {
  if (name.includes("/")) {
    return name.trim().split("/")[0]!.trim();
  }
  return null;
}

export function removeFolderNameFromElementName(name: string) {
  const [folderName] = name.trim().split("/");
  return name.toString().trim().replace(`${folderName}/`, "");
}
