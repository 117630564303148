import type { PendingShopifyIntegrationCookie } from "./usePendingShopifyIntegrationCookie";

import * as React from "react";

import { trpcUtils } from "@editor/utils/trpc";

import { SHOPIFY_APP_LISTING_URL } from "replo-runtime/shared/config";

import { useLogAnalytics } from "./useLogAnalytics";
import { useModal } from "./useModal";
import usePendingShopifyIntegrationCookie from "./usePendingShopifyIntegrationCookie";

const useInstallShopify = () => {
  const { setPendingShopifyIntegrationCookie } =
    usePendingShopifyIntegrationCookie();
  const analytics = useLogAnalytics();
  const modal = useModal();

  const installShopify = React.useCallback(
    async ({
      workspaceId,
      projectId,
      type,
    }: {
      workspaceId: string;
      projectId?: string;
      type: PendingShopifyIntegrationCookie["type"];
    }) => {
      const canAddShopifyIntegration =
        await trpcUtils.workspace.canAddShopifyIntegration.fetch(workspaceId!);
      if (!canAddShopifyIntegration.hasCapacity) {
        if (canAddShopifyIntegration.reason === "shopifyWorkspace") {
          modal.openModal({
            type: "fullPageErrorModal",
            props: {
              details: {
                header: "Cannot add more Shopify Integrations",
                message:
                  "This workspace is billed through Shopify, so it cannot add more Shopify integrations. To learn more about how to change your billing method, please contact support@replo.app.",
                callToAction: {
                  type: "link",
                  name: "Learn More",
                  to: "https://replo.help.usepylon.com/articles/7579785312-how-do-i-migrate-to-payment-method-to-stripe",
                  target: "_blank",
                },
              },
            },
          });
        } else {
          modal.openModal({
            type: "billingModal",
            props: {
              source: "integrationHub.shopify",
              billingPlanMessageKey:
                "billingPlan.entitlements.insufficient.integrations.shopify",
            },
          });
        }
        return;
      }

      analytics("shopify.connect", {
        source: type,
      });

      setPendingShopifyIntegrationCookie({
        type,
        workspaceId,
        projectId,
      });
      window.location.href = SHOPIFY_APP_LISTING_URL;
    },
    [setPendingShopifyIntegrationCookie, modal, analytics],
  );

  return { installShopify };
};

export default useInstallShopify;
