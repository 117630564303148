import type { DataTable } from "replo-runtime/shared/DataTable";

export const getSingularOrCollectionName = (
  singularItemName: string,
  collectionName: string,
) => singularItemName ?? `${collectionName} Item`;

export const getPluralOrCollectionName = (
  pluralItemName: string,
  collectionName: string,
) => pluralItemName ?? `${collectionName} Items`;

export const getSingularOrCollectionNameFromDataTable = (
  dataTable: DataTable | null,
  collectionName: string,
) => dataTable?.singularItemName ?? `${collectionName} Item`;

export const getPluralOrCollectionNameFromDataTable = (
  dataTable: DataTable | null,
  collectionName: string,
) => dataTable?.pluralItemName ?? `${collectionName} Item`;
