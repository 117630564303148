import * as React from "react";

import LoadingLogo from "@svg/logo-loading";

import { useNProgress } from "@tanem/react-nprogress";

const getRandomInRange = (min: number, max: number): number => {
  return Math.random() * (max - min) + min;
};

const MINIMUM = getRandomInRange(0, 0.1);

/**
 * A very naive loading bar that slowly just loads
 */
export const LoadingBar: React.FC<{
  isLoading: boolean;
  loadingText?: string;
}> = ({ isLoading, loadingText = "Loading" }) => {
  const { animationDuration, progress } = useNProgress({
    isAnimating: isLoading,
    minimum: MINIMUM,
    animationDuration: 50,
    incrementDuration: 0,
  });

  return (
    <Bar
      animationDuration={animationDuration}
      progress={progress}
      loadingText={loadingText}
    />
  );
};

const Bar: React.FC<{
  animationDuration: number;
  progress: number;
  loadingText: string;
}> = ({ animationDuration, progress, loadingText }) => {
  const percentage = `${(progress * 100).toFixed(0)}%`;
  return (
    <div className="w-96">
      <div className="relative mx-auto h-16 w-16">
        <LoadingLogo className="absolute top-0 left-0 h-16 w-16 fill-blue-100" />
        <div
          className="hello-world absolute right-0 top-0 h-16 bg-blue-800"
          style={{
            transition: `width ${animationDuration}ms linear`,
            width: `${100 - progress * 100}%`,
          }}
        />
      </div>
      <div className="mt-2 text-center font-mono text-lg font-medium text-blue-100">{`${loadingText} ${percentage}`}</div>
    </div>
  );
};
