import { selectEditorMode } from "@editor/reducers/core-reducer";
import { selectIsRightBarAnalyticsOpen } from "@editor/reducers/ui-reducer";
import { useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";

import { selectCanvasIsLoading } from "@/features/canvas/canvas-reducer";

import { useShowDraftComponentsControls } from "./useShowDraftComponentsControls";

export default function useRightBarVisibility() {
  const showDraftComponentsControls = useShowDraftComponentsControls();
  const editorMode = useEditorSelector(selectEditorMode);
  const isCanvasLoading = useEditorSelector(selectCanvasIsLoading);

  const isRightBarAnalyticsOpen = useEditorSelector(
    selectIsRightBarAnalyticsOpen,
  );

  return (
    !isCanvasLoading &&
    (showDraftComponentsControls ||
      (editorMode === EditorMode.edit && isRightBarAnalyticsOpen))
  );
}
