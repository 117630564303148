import * as React from "react";

import useCurrentUser from "@editor/hooks/useCurrentUser";

import { Navigate, useLocation, useSearchParams } from "react-router-dom";

const AuthenticatedUserRequiredRoute: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { isAuthenticated, isLoading } = useCurrentUser();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  if (!location.pathname.startsWith("/editor/")) {
    document.title = `Dashboard${
      window.__BUILD_MODE__ === "development" ? " - Replo Dev Mode" : " - Replo"
    }`;
  }

  if (!isAuthenticated && !isLoading) {
    return (
      <Navigate
        to={`/auth/signup?next=${pathname}&${searchParams.toString()}`}
        replace
      />
    );
  }

  return <>{children}</>;
};

export default AuthenticatedUserRequiredRoute;
