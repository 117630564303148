import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const knoCommerceWidget: ComponentTemplate = {
  id: "b74bb617-8d8b-44cd-a14b-862a53511ec5",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "KnoCommerce",
  leftBarCategory: { main: "pageIntegrations", subCategory: "other" },
  preview: "integrations/knocommerce.svg",
  transforms: [],
  tags: ["widget"],
  template: {
    id: "$uid",
    type: "knoCommerceWidget",
    props: {},
  },
};
