import type { AlchemyActionType } from "../../../shared/enums";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

const styleElements = {
  root: {},
} satisfies StyleElements;

const actionTypes = [
  "setCurrentCollectionSelection",
] as const satisfies AlchemyActionType[];

export type ActionType = (typeof actionTypes)[number];

const config = {
  renderData: {
    newInstancesUseNumbering: true,
    allowsLayoutModification: true,
    acceptsArbitraryChildren: () => true,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    canContainChildren: true,
    styleElements,
    variantTriggers: ["collectionSelect.item"],
    actionTypes,
  },
} satisfies ComponentConfig;

export default config;
