import type { ComponentTemplate } from "@editor/types/component-template";
import type {
  ComponentTemplateLeftBarCategory,
  ComponentTemplateScope,
} from "schemas/generated/componentTemplates";

import { orderOfCategories } from "@editor/reducers/utils/core-reducer-utils";

import split from "lodash-es/split";
import startCase from "lodash-es/startCase";
import { Env } from "replo-runtime/shared/enums";

import { orderByDate } from "./array";

export function groupComponentTemplatesByCategory(
  componentTemplates: ComponentTemplate[],
  {
    scope,
    isDebugMode,
  }: {
    scope: ComponentTemplateScope;
    isDebugMode?: boolean;
  },
) {
  const categoryToComponentTemplates: Record<string, ComponentTemplate[]> = {};
  const scopedComponentTemplates = componentTemplates.filter(
    (componentTemplate) => componentTemplate.scope === scope,
  );

  // if a component template is named player/play button, we assume that
  // the component template should be named Play Button under player
  // https://www.figma.com/best-practices/components-styles-and-shared-libraries/organizing-and-creating-libraries/
  for (const componentTemplate of scopedComponentTemplates) {
    const { name, env } = componentTemplate;

    if (env === Env.DEV && !isDebugMode) {
      continue;
    }

    const categoryName = name?.includes("/")
      ? split(name, "/", 2)[0]!
      : "Other";

    categoryToComponentTemplates[categoryName] = [
      ...(categoryToComponentTemplates[categoryName] || []),
      componentTemplate,
    ];
  }

  const categories = [
    {
      title: "Basic",
      componentTemplates: categoryToComponentTemplates?.Basic,
    },
  ];
  const priorityCategoryNames = new Set(categories.map((c) => c.title));
  for (const [categoryName, componentTemplates] of Object.entries(
    categoryToComponentTemplates,
  )) {
    if (priorityCategoryNames.has(categoryName)) {
      continue;
    }
    categories.push({
      title: startCase(categoryName),
      componentTemplates,
    });
  }
  // Note (Sebas, 2023-09-05): When loading saved component templates (scope === "store")
  // we need to make sure that the order of the categories does not change because we are
  // showing the category "Other" first.
  if (scope === "left-bar") {
    categories.sort((a, b) => {
      const aOrder = orderOfCategories[a.title] || Number.MAX_SAFE_INTEGER;
      const bOrder = orderOfCategories[b.title] || Number.MAX_SAFE_INTEGER;
      return aOrder - bOrder;
    });
  }

  return categories.filter(
    (category) => (category.componentTemplates ?? []).length > 0,
  );
}

export function groupComponentTemplatesByLeftBarCategory(
  templates: ComponentTemplate[],
  scope: ComponentTemplateScope,
) {
  const categories = groupComponentTemplatesByCategory(
    orderByDate(templates, "updatedAt"),
    { scope },
  );

  const groupedCategories: Record<
    ComponentTemplateLeftBarCategory["main"],
    Record<string, ComponentTemplate[]>
  > = {
    basic: {},
    layout: {},
    product: {},
    productIntegrations: {},
    media: {},
    interactive: {},
    pageIntegrations: {},
    customCode: {},
  };

  categories.forEach((category) => {
    category.componentTemplates?.forEach((componentTemplate) => {
      const leftBarCategory = componentTemplate.leftBarCategory;

      if (leftBarCategory) {
        const main = leftBarCategory.main;
        const subCategory = leftBarCategory.subCategory ?? "other";

        if (!groupedCategories[main]) {
          groupedCategories[main] = {};
        }

        const subCategoryArray = groupedCategories[main]?.[subCategory] ?? [];
        if (subCategoryArray && groupedCategories[main]) {
          subCategoryArray.push(componentTemplate);
          groupedCategories[main][subCategory] = subCategoryArray;
        }
      }
    });
  });

  return groupedCategories;
}
