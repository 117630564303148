import jss from "jss";
import preset from "jss-preset-default";

let isSetup = false;

export function setupJss() {
  if (!isSetup) {
    isSetup = true;
    jss.setup(preset());
  }
}
