import * as React from "react";

import { trpc } from "@editor/utils/trpc";

import { Navigate } from "react-router-dom";

export const WorkspaceBillingRedirectRoute: React.FC = () => {
  const { data: workspaceId, isLoading } =
    trpc.workspace.getUserDefaultWorkspaceId.useQuery();

  if (isLoading) {
    return null;
  }
  return (
    <Navigate
      to={workspaceId ? `/workspace/${workspaceId}/billing` : "/home"}
    />
  );
};
