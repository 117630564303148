import type {
  Cast,
  Head,
  Push,
  Resolve,
  Shift,
} from "replo-runtime/store/components/CarouselV3/renderer/types/types";

import { slice } from "replo-runtime/store/components/CarouselV3/renderer/utils/arrayLike";
import { forOwn } from "replo-runtime/store/components/CarouselV3/renderer/utils/object/forOwn/forOwn";
import {
  isArray,
  isObject,
} from "replo-runtime/store/components/CarouselV3/renderer/utils/type/type";

/**
 * Merges U to T.
 *
 * @param T - An object to merge U into.
 * @param U - An object to merge properties from.
 *
 * @returns A merged object type.
 */
export type Merge<T extends object, U extends object> = Omit<T, keyof U> & {
  [K in keyof T & keyof U]: U[K] extends object
    ? U[K] extends any[]
      ? U[K]
      : T[K] extends object
        ? Merge<T[K], U[K]> extends infer A
          ? Resolve<Cast<A, object>>
          : never
        : U[K]
    : U[K];
} & Omit<U, keyof T>;

/**
 * Recursively merges U[] to T.
 *
 * @param T - An object to assign to.
 * @param U - A tuple contains objects.
 *
 * @returns An assigned object type.
 */
export type Merged<
  T extends object,
  U extends object[],
  N extends number,
  C extends any[] = [],
> = {
  0: T;
  1: Merged<Merge<T, Head<U>>, Shift<U>, N, Push<C>>;
}[C["length"] extends N ? 0 : 1] extends infer A
  ? Cast<A, any>
  : never;

export function merge<T extends object>(object: T): T;

export function merge<T extends object, U extends object[]>(
  object: T,
  ...sources: U
): Resolve<Merged<T, U, U["length"]>>;

/**
 * Recursively merges source properties to the object.
 * Be aware that this method does not merge arrays. They are just duplicated by `slice()`.
 *
 * @param object - An object to merge properties to.
 *
 * @returns A new object with merged properties.
 */
export function merge<T extends object>(object: T): any {
  // eslint-disable-next-line prefer-rest-params
  slice(arguments, 1).forEach((source) => {
    forOwn(source, (value, key) => {
      if (isArray(value)) {
        // @ts-ignore
        object[key] = value.slice();
      } else if (isObject(value)) {
        // @ts-ignore
        object[key] = merge(
          {},
          // @ts-ignore
          isObject(object[key]) ? object[key] : {},
          value,
        );
      } else {
        // @ts-ignore
        object[key] = value;
      }
    });
  });

  return object;
}
