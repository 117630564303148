import type { SvgProps } from "replo-utils/react/components";

import * as React from "react";

import { Svg } from "replo-utils/react/components";

const BorderSide = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <rect
      width={15}
      height={15}
      x={15.5}
      y={0.5}
      fill="#E2E8F0"
      stroke="#E2E8F0"
      rx={0.5}
      transform="rotate(90 15.5 .5)"
    />
    <rect
      width={0.667}
      height={10}
      x={0.333}
      y={2.333}
      stroke="#1E293B"
      strokeWidth={0.667}
      rx={0.333}
    />
    <rect
      width={0.667}
      height={10}
      x={15}
      y={3}
      stroke="#1E293B"
      strokeWidth={0.667}
      rx={0.333}
    />
    <rect
      width={0.667}
      height={10}
      x={13}
      y={0.333}
      stroke="#1E293B"
      strokeWidth={0.667}
      rx={0.333}
      transform="rotate(90 13 .333)"
    />
    <rect
      width={0.667}
      height={10}
      x={13}
      y={15}
      stroke="#1E293B"
      strokeWidth={0.667}
      rx={0.333}
      transform="rotate(90 13 15)"
    />
  </Svg>
);

export default BorderSide;
