import type { RenderComponentProps } from "../../../shared/types";
import type { ActionType } from "./config";

import * as React from "react";

import { useSetActiveStateId } from "replo-runtime/store/contexts/RuntimeActiveState/hooks";

import { mergeContext } from "../../../shared/utils/context";
import Container from "../Container";

const Collapsible = (props: RenderComponentProps) => {
  const setActiveStateId = useSetActiveStateId(props.component.id);
  const activeStateId = props.context.variants?.find((v) => v.isActive)?.id;
  const actionHooks = {
    toggleCollapsible: () => {
      setActiveStateId?.(
        activeStateId === "alchemy:collapsibleOpen"
          ? "alchemy:collapsibleClosed"
          : "alchemy:collapsibleOpen",
      );
    },
  } satisfies {
    [K in ActionType]: Function;
  };

  return (
    <Container
      {...props}
      context={mergeContext(props.context, {
        actionHooks,
      })}
    />
  );
};

export default Collapsible;
