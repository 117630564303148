import type { Component, ReploComponentType } from "schemas/component";
import type { ReploSymbol } from "schemas/generated/symbol";

import { getComponentEditorData } from "@components/editor/componentTypes";

import startCase from "lodash-es/startCase";
import { forEachOnlyTreeComponentAndDescendants } from "replo-runtime/shared/utils/component";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";
import { componentTypeToRenderData } from "replo-runtime/store/components";

const isNameStillDefault = (currentName: string, defaultName: string) => {
  const regexPat = new RegExp(`^${defaultName} (\\d+)?$`);
  return regexPat.test(currentName) || defaultName === currentName;
};

const getIsAllowedNumbering = (component: Component) => {
  const componentType = component.type as ReploComponentType;
  return componentTypeToRenderData[componentType]?.newInstancesUseNumbering;
};

export const getComponentName = (
  component: Component,
  symbols: Record<string, ReploSymbol> | null,
) => {
  return (
    component?.name ||
    getComponentEditorData(component?.type)?.formatName?.(component, {
      symbols,
    }) ||
    startCase(component?.type)
  );
};

export const getComponentDefaultName = (
  component: Component,
  symbols: Record<string, ReploSymbol> | null,
) => {
  if (!component) {
    return null;
  }
  return (
    getComponentEditorData(component?.type)?.formatName?.(component, {
      symbols,
    }) || startCase(component?.type)
  );
};

export const getCountsForAllComponentTypes = (
  rootComponent: Component,
): Record<string, number> => {
  const countsOfComponent: Record<string, number> = <Record<string, number>>{};
  const setMaxValue = (name: string, value: number) => {
    if (!countsOfComponent[name]) {
      countsOfComponent[name] = 1;
    }
    countsOfComponent[name] = Math.max(countsOfComponent[name] || 1, value);
  };

  forEachOnlyTreeComponentAndDescendants(
    rootComponent,
    (component: Component) => {
      const defaultName = getComponentDefaultName(component, null);

      if (component.name) {
        const regexResult = component.name.match(/\d+$/g);
        if (regexResult && defaultName) {
          setMaxValue(
            defaultName,
            Number.parseInt(Array.from(regexResult)[0]!),
          );
        }
      }
    },
  );
  return countsOfComponent;
};

export const giveNameToAllChildren = (
  newComponent: Component,
  rootComponent: Component,
) => {
  const countsOfComponent = getCountsForAllComponentTypes(rootComponent);
  forEachOnlyTreeComponentAndDescendants(
    newComponent,
    (component: Component) => {
      const defaultName = getComponentDefaultName(component, null);
      const componentName = getComponentName(component, null);
      const maxNumber =
        getFromRecordOrNull(countsOfComponent, defaultName) ?? 0;
      const isAllowedNumbering = getIsAllowedNumbering(component);
      if (
        isAllowedNumbering &&
        defaultName &&
        componentName &&
        isNameStillDefault(componentName, defaultName)
      ) {
        countsOfComponent[defaultName] = maxNumber + 1;
        component.name = `${defaultName} ${maxNumber + 1}`;
      }
    },
  );
};

export const giveNameToComponent = (
  newComponent: Component,
  rootComponent: Component,
) => {
  const isAllowedNumbering = getIsAllowedNumbering(newComponent);
  if (!isAllowedNumbering) {
    return;
  }
  const countsOfComponent = getCountsForAllComponentTypes(rootComponent);

  const defaultName = getComponentDefaultName(newComponent, null);
  const componentNameWithoutNumber = getComponentName(
    newComponent,
    null,
  )?.replace(/\d+$/g, "");

  if (componentNameWithoutNumber) {
    newComponent.name = `${componentNameWithoutNumber} ${
      (getFromRecordOrNull(countsOfComponent, defaultName) ?? 0) + 1
    }`;
  }
};
