import type { Middleware } from "redux";

import { isDebugMode } from "@editor/components/editor/debug/useIsDebugMode";
import { trpcUtils } from "@editor/utils/trpc";

import { canUseDOM } from "replo-utils/dom/misc";

// Copied from https://github.com/jarretmoses/react-native-redux-example/blob/aa20bcbe54b5e15cf1c817a394df6fbacd87a63c/app/store/middleware/logging.js
const loggingMiddleware: Middleware = (store) => (next) => (action) => {
  const user = trpcUtils.user.get.getData({});
  const params = canUseDOM ? new URLSearchParams(window.location.search) : null;
  const isSuperuser = user?.isSuperuser ?? false;
  if (!isDebugMode(isSuperuser, params)) {
    return next(action);
  }

  const reduxLoggingToConsole = JSON.parse(
    localStorage.getItem("replo.debug.reduxLoggingToConsole") ?? "false",
  );

  if (reduxLoggingToConsole) {
    console.debug(
      "%cINFO:",
      "color: yellow",
      `Dispatching a ${action.type} action with payload:`,
      action.payload,
      "and params",
      action.params,
    );
  }
  const result = next(action);
  if (reduxLoggingToConsole) {
    console.debug("%cNext State:", "color: cyan", store.getState());
  }
  return result;
};

export default loggingMiddleware;
