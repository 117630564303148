import type { ExperimentState } from "@/features/experiments/tabs/ExperimentsEditTabV2";
import type { Experiment } from "schemas/generated/experiment";

import * as React from "react";

import { Loader } from "@editor/components/common/Loader";

import { ExperimentActionsBar } from "@/features/experiments/components/ExperimentActionsBar";
import { ExperimentsLayout } from "@/features/experiments/Layout";
import {
  ExperimentsEditTabV2,
  validateExperiment,
} from "@/features/experiments/tabs/ExperimentsEditTabV2";
import { useExperimentDetails } from "@/features/experiments/tabs/hooks/useExperimentDetails";
import { getExperimentStatus } from "schemas/experiment";

const ExperimentDetailsContent: React.FC<{
  experiment: Experiment;
  workspaceId: string;
  dataChanged: boolean;
  onDataChange: (dataChanged: boolean) => void;
  state: ExperimentState;
  setState: React.Dispatch<React.SetStateAction<ExperimentState>>;
}> = ({
  experiment,
  workspaceId,
  dataChanged,
  onDataChange,
  state,
  setState,
}) => {
  const status = getExperimentStatus(experiment);

  return (
    <ExperimentsLayout
      headerTitle={experiment.name}
      headerActions={
        <ExperimentActionsBar
          workspaceId={workspaceId}
          experiment={experiment}
          isEditingExperiment={dataChanged}
          state={state}
        />
      }
      status={status}
      editable
      showBackButton
    >
      <ExperimentsEditTabV2
        dataChanged={dataChanged}
        onDataChange={onDataChange}
        state={state}
        setState={setState}
      />
    </ExperimentsLayout>
  );
};

const ExperimentDetailsWithData: React.FC<{
  experiment: Experiment;
  workspaceId: string;
}> = ({ experiment, workspaceId }) => {
  const [dataChanged, setDataChanged] = React.useState(false);
  // NOTE (Kurt, 2024-11-14): We set the state in this parent component because we want the
  // actions bar to have access to the state. The actions bar uses the state to trigger user
  // events for experiment with information related to the experiment (e.g. variant count, description).
  const [state, setState] = React.useState<ExperimentState>(() => {
    return {
      data: experiment,
      validation: validateExperiment(experiment),
      analyticsLinkId: experiment.analyticsLinkId,
    };
  });

  const onDataChange = (dataChanged: boolean) => {
    setDataChanged(dataChanged);
  };

  return (
    <ExperimentDetailsContent
      experiment={experiment}
      workspaceId={workspaceId}
      dataChanged={dataChanged}
      onDataChange={onDataChange}
      state={state}
      setState={setState}
    />
  );
};

const ExperimentDetailsTab: React.FC = () => {
  const { isLoadingExperiment, experiment, workspaceId } =
    useExperimentDetails();

  if (isLoadingExperiment || !experiment || !workspaceId) {
    return <Loader />;
  }

  return (
    <ExperimentDetailsWithData
      experiment={experiment}
      workspaceId={workspaceId}
    />
  );
};

export default ExperimentDetailsTab;
