/* eslint-disable import/no-extraneous-dependencies */
import { addBreadcrumb, captureMessage } from "@sentry/react";

export type BreadcrumbCategory = "general" | "error";

export const logNonFatalMessage = (message: string) => {
  captureMessage(message);
};

export const breadcrumb = (
  message: string,
  category: BreadcrumbCategory = "general",
  data?: Record<string, any>,
) => {
  addBreadcrumb({ category, level: "info", message, data });
};
