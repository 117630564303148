import type { CustomPropsRecord } from "schemas/component";
import type { ComponentMovementSource } from "../../../shared/utils/dragging";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

import { convertToLegacyProps } from "../../../shared/utils/renderComponents";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _productVariants: {
      name: "Variants",
      type: "productVariants",
      defaultValue: null,
      description: "Select which variants will be shown in this list",
    },
    _swatches: {
      type: "swatches",
      name: "Swatches",
      description: "",
      defaultValue: null,
    },
  };
}

export const styleElements = {
  root: {
    defaultStyles: {
      display: "flex",
    },
  },
} as const satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: true,
    allowsLayoutModification: true,
    acceptsArbitraryChildren: (config) => {
      // Note (Noah, 2023-12-22, REPL-9838): We can accept arbitrary children only
      // if the child being dragged in is NOT a template. This is because it's too
      // easy to accidentally drag a component in as a child of the list, which will
      // mess up the list's rendering in a way you almost always don't want.
      return ![
        "leftBarTemplate" satisfies ComponentMovementSource,
        "canvas" satisfies ComponentMovementSource,
      ].includes(config.movementSource);
    },
    isAlwaysDynamic: true,
    canvasIndicatorDragDirections: [],
    ancestorAllow: {
      ancestorTypes: ["product"],
      message:
        "Variant List components may only be placed inside a Product box.",
    },
    ancestorDisallowList: [
      {
        ancestorTypes: ["marquee"],
        message: "Variant Lists cannot be nested inside tickers.",
      },
    ],
    canContainChildren: true,
    showComponentControlsFromChildren: true,
    styleElements,
    variantTriggers: [
      "state.product.selectedVariant",
      "state.product.currentVariantIsOnSale",
      "state.product.isVariantUnavailable",
    ],
  },
} satisfies ComponentConfig;

export default config;
