import type {
  DataTable,
  DataTableColumn,
} from "replo-runtime/shared/DataTable";

import * as React from "react";

import Modal from "@common/designSystem/Modal";
import { ModalLayout } from "@common/ModalLayout";
import { dataCollectionColumnTypeToEditorData } from "@components/editor/page/data-tables-page/EditorData";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useModal } from "@editor/hooks/useModal";
import {
  mappingDataTables,
  selectDraftDataTable,
  setDraftDataTable as setDraftDataTableAction,
  updateDraftDataTable as updateDraftDataTableAction,
  updateDraftDataTableRow,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";
import {
  getPluralOrCollectionNameFromDataTable,
  getSingularOrCollectionNameFromDataTable,
} from "@utils/dataTable";

import Button from "@replo/design-system/components/button";
import classNames from "classnames";
import { BsTrash } from "react-icons/bs";
import { defaultValueMapping } from "replo-runtime/shared/DataTable";

type DataValueProps = {
  column: DataTableColumn;
  rowIndex: number;
  columnIndex: number;
  value: any;
  onChange: (rowIndex: number, columnId: string, value: string) => void;
};

const DataValue: React.FC<DataValueProps> = ({
  column,
  rowIndex,
  value,
  onChange,
  columnIndex,
}) => {
  const onChangeHandler = React.useCallback(
    (value: any) => {
      const editorData = dataCollectionColumnTypeToEditorData[column.type];
      const transformedValue =
        "transformValue" in editorData
          ? editorData.transformValue(value)
          : value;
      onChange(rowIndex, column.id, transformedValue);
    },
    [column.type, column.id, onChange, rowIndex],
  );

  const Component = React.useMemo(() => {
    return dataCollectionColumnTypeToEditorData[column.type].component;
  }, [column.type]);

  return (
    <div
      className="flex items-center"
      data-testid={`data-collection-cell-${rowIndex}-${columnIndex}`}
      style={{
        width: dataCollectionColumnTypeToEditorData[column.type].width,
      }}
    >
      <Component
        value={value}
        onChange={onChangeHandler}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
      />
    </div>
  );
};

export const DataCollectionEditModal = () => {
  const modal = useModal();
  const dataTable = useEditorSelector(selectDraftDataTable);
  const dispatch = useEditorDispatch();
  const setDraftDataTable = (payload: { id: string }) =>
    dispatch(setDraftDataTableAction(payload));
  const updateDraftDataTable = React.useCallback(
    (payload: DataTable) => dispatch(updateDraftDataTableAction(payload)),
    [dispatch],
  );

  const projectId = useCurrentProjectId();

  const { mutateAsync: updateOrCreateDataTable } =
    trpc.dataTable.updateOrCreate.useMutation({
      onMutate: (props) => {
        dispatch(updateDraftDataTable(props.dataTable));
      },
      onSuccess: (response) => {
        if (response.success) {
          dispatch(mappingDataTables());
        }
      },
    });

  const _renderCollectionName = () => {
    if (!dataTable) {
      return null;
    }
    return (
      <div className="flex flex-row" style={{ alignItems: "center" }}>
        <div className="mb-4 flex flex-col">
          <div className="text-xs text-slate-400">Collection Name</div>
          <div className="text-sm font-medium">{dataTable?.name}</div>
        </div>
        <div>
          <Button
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              setDraftDataTable({ id: dataTable.id });
              modal.openModal({
                type: "createOrConfigureDataCollectionModal",
                props: {
                  modalType: "configure",
                },
              });
            }}
            className="ml-4 self-start text-blue-600"
            variant="tertiary"
          >
            Edit Fields
          </Button>
        </div>
      </div>
    );
  };

  const _renderSingularPluralName = () => (
    <div className="mb-2 flex flex-row">
      <div className="flex flex-col">
        <div className="pb-0.5 text-xs text-slate-400">Singular Name</div>
        <div className="text-sm font-medium text-default">
          {dataTable?.singularItemName || "-"}
        </div>
      </div>
      <div className="ml-8 flex flex-col">
        <div className="pb-0.5 text-xs text-slate-400">Plural Name</div>
        <div className="text-sm font-medium text-default">
          {dataTable?.pluralItemName || "-"}
        </div>
      </div>
    </div>
  );

  const _renderAddRow = () => {
    if (!dataTable) {
      return null;
    }
    return (
      <div
        className="mb-2 flex flex-row"
        style={{ justifyContent: "flex-end" }}
      >
        <Button
          onClick={() => {
            const schema = dataTable.data.schema;
            const newRow: Record<string, any> = {};
            schema.forEach((column) => {
              newRow[column.id] = defaultValueMapping[column.type];
            });
            updateDraftDataTable({
              ...dataTable,
              data: {
                ...dataTable.data,
                rows: [...dataTable.data.rows, newRow],
              },
            });
          }}
          className="self-start"
          variant="secondary"
        >{`New ${getSingularOrCollectionNameFromDataTable(
          dataTable,
          dataTable?.name,
        )}`}</Button>
      </div>
    );
  };

  const _renderDeleteRow = (rowIndex: number) => {
    if (!dataTable) {
      return null;
    }
    return (
      <div>
        <BsTrash
          size="20px"
          className="text-slate-400"
          cursor="pointer"
          style={{ marginLeft: "auto" }}
          onClick={() => {
            const newRows = [...dataTable.data.rows];
            newRows.splice(rowIndex, 1);
            updateDraftDataTable({
              ...dataTable,
              data: {
                ...dataTable.data,
                rows: newRows,
              },
            });
          }}
        />
      </div>
    );
  };

  const _renderEditFields = () => {
    if (!dataTable) {
      return null;
    }
    return (
      <div>
        <Button
          onClick={(e: any) => {
            e.stopPropagation();
            setDraftDataTable({ id: dataTable.id });
            modal.openModal({
              type: "createOrConfigureDataCollectionModal",
              props: {
                modalType: "configure",
              },
            });
          }}
          className="self-start text-blue-600"
          variant="tertiary"
        >
          Edit Fields
        </Button>
      </div>
    );
  };

  const updateRowValue = React.useCallback(
    (rowIndex: number, schemaId: string, value: any) => {
      dispatch(
        updateDraftDataTableRow({
          rowIndex,
          schemaId,
          value,
        }),
      );
    },
    [dispatch],
  );

  const _renderDataTable = () => {
    if (!dataTable) {
      return null;
    }
    return (
      <div className="flex flex-col">
        <div className="flex flex-row items-center justify-between border-b-2 border-slate-800 p-1">
          <div className="p-1 text-sm font-medium w-[100px]">Row</div>
          {dataTable.data.schema.map((column) => {
            return (
              <div
                className="p-1 text-sm font-medium capitalize w-[200px] whitespace-nowrap overflow-hidden text-ellipsis"
                key={column.id}
              >
                {column.name}
              </div>
            );
          })}
          <div className="p-1 w-[100px]">{_renderEditFields()}</div>
        </div>
        {dataTable.data.rows.map((row, rowIndex) => {
          return (
            <div
              key={rowIndex}
              className={classNames(
                "cursor-pointer border-b-2 bg-white p-0.5 transition duration-200 ease-in-out hover:bg-slate-50",
              )}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              <div className="flex flex-row justify-between items-center px-2">
                <div className="w-[100px]">
                  <span>{rowIndex + 1}</span>
                </div>
                {dataTable.data.schema.map((column, columnIndex) => {
                  const schemaId = column.id;
                  const value = row[schemaId];
                  return (
                    <DataValue
                      key={column.id}
                      value={value}
                      onChange={updateRowValue}
                      rowIndex={rowIndex}
                      columnIndex={columnIndex}
                      column={column}
                    />
                  );
                })}
                <div className="flex justify-center w-[100px]">
                  {_renderDeleteRow(rowIndex)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const _renderSaveButton = () => {
    if (!dataTable) {
      return null;
    }
    return (
      <div className="flex flex-col">
        <div className="flex flex-row justify-between bg-slate-200" />
        <Button
          onClick={(e: any) => {
            e.stopPropagation();
            void updateOrCreateDataTable({
              projectId: projectId ?? "",
              dataTable,
            });
            modal.closeModal({ type: "dataCollectionEditModal" });
          }}
          className="self-start"
          variant="primary"
        >{`Save ${getPluralOrCollectionNameFromDataTable(
          dataTable,
          dataTable.name,
        )}`}</Button>
      </div>
    );
  };

  return (
    <Modal
      isOpen={true}
      includesCloseIcon
      onRequestClose={() => {
        modal.closeModal({ type: "dataCollectionEditModal" });
      }}
      className="w-auto"
    >
      <ModalLayout
        height={600}
        width="auto"
        mainContent={() => (
          <div className="relative flex flex-col justify-between bg-white w-full">
            <div className="flex flex-col">
              {_renderCollectionName()}
              {_renderSingularPluralName()}
              {_renderAddRow()}
            </div>
            <div className="flex flex-col grow overflow-auto">
              {_renderDataTable()}
            </div>
          </div>
        )}
        footerContent={() => _renderSaveButton()}
      />
    </Modal>
  );
};
