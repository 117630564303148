import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const carouselWithImageIndicators: ComponentTemplate = {
  id: "80fbdd01-7ff7-4216-ba77-c4cff8eac3f9",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Carousel/Thumbnail Carousel",
  leftBarCategory: { main: "interactive", subCategory: "carousel" },
  preview: "carousel/thumbnail.png",
  template: {
    id: "9d60bfc3-2b51-4028-b8df-f73ea65538f8",
    name: "thumbnail carousel",
    type: "carouselV3",
    props: {
      style: {
        display: "flex",
        __flexGap: "24px",
        alignSelf: "start",
        flexBasis: 0,
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
      "style@sm": {
        __flexGap: "16px",
        flexDirection: "column-reverse",
      },
      _animationStyle: "fade",
    },
    children: [
      {
        id: "b544a81d-f85f-41f1-8f86-c1450fbeee72",
        name: "Thumbnails",
        type: "carouselV3Indicators",
        props: {
          style: {
            display: "flex",
            __flexGap: "12px",
            alignSelf: "stretch",
            alignItems: "flex-start",
            flexDirection: "column",
            justifyContent: "flex-start",
          },
          "style@sm": {
            width: "auto",
            display: "flex",
            flexWrap: "wrap",
            maxWidth: "100vw",
            overflow: "visible",
            __flexGap: "8px",
            alignSelf: "stretch",
            __overflow: "visible",
            alignItems: "flex-start",
            flexDirection: "row",
            justifyContent: "center",
          },
        },
        children: [
          {
            id: "fdffa92b-3e00-4b2c-973f-4afb883ad1be",
            name: "For Slide 1",
            type: "image",
            props: {
              style: {
                width: "64px",
                cursor: "pointer",
                height: "64px",
                __width: "64px",
                opacity: "50%",
                __height: "64px",
                objectFit: "cover",
                __imageSource:
                  "https://images.unsplash.com/photo-1504198266287-1659872e6590?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTd8fHxlbnwwfHx8fHw%3D",
                __imageAltText:
                  "A single green leaf against a white background.",
                borderTopLeftRadius: "100%",
                borderTopRightRadius: "100%",
                borderBottomLeftRadius: "100%",
                borderBottomRightRadius: "100%",
              },
              loading: "lazy",
              "style@md": {
                width: "64px",
                height: "64px",
                __width: "64px",
                __height: "64px",
                flexGrow: "unset",
              },
              "style@sm": {
                width: "48px",
                height: "48px",
                __width: "48px",
                __height: "48px",
                flexGrow: "unset",
              },
            },
            markers: {
              _aiGeneratedAltText: true,
            },
            variants: [
              {
                id: "0e75b5ed-c81c-48ca-8f49-3a6930528d54",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "13725c44-b3e0-4ab2-b8a3-08feba47f11e",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "7139f491-1640-4972-90f1-f6a3d3a2a168",
                      field: "state.group.isCurrentItemActive",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "13725c44-b3e0-4ab2-b8a3-08feba47f11e": {
                componentOverrides: {
                  "fdffa92b-3e00-4b2c-973f-4afb883ad1be": {
                    props: {
                      style: {
                        opacity: "100%",
                      },
                    },
                  },
                },
              },
            },
          },
          {
            id: "8c1f32e4-bb95-4777-8cdb-732f4734e61f",
            name: "For Slide 2",
            type: "image",
            props: {
              style: {
                width: "64px",
                cursor: "pointer",
                height: "64px",
                __width: "64px",
                opacity: "50%",
                __height: "64px",
                objectFit: "cover",
                __imageSource:
                  "https://images.unsplash.com/photo-1504204267155-aaad8e81290d?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTR8fHxlbnwwfHx8fHw%3D",
                __imageAltText:
                  "A single palm leaf in a clear glass of water on a white background.",
                borderTopLeftRadius: "100%",
                borderTopRightRadius: "100%",
                borderBottomLeftRadius: "100%",
                borderBottomRightRadius: "100%",
              },
              loading: "lazy",
              "style@md": {
                width: "64px",
                height: "64px",
                __width: "64px",
                __height: "64px",
                flexGrow: "unset",
              },
              "style@sm": {
                width: "48px",
                height: "48px",
                __width: "48px",
                __height: "48px",
                flexGrow: "unset",
              },
            },
            markers: {
              _aiGeneratedAltText: true,
            },
            variants: [
              {
                id: "3effd057-7bf0-414d-956a-36d2d54935ee",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "febcbd86-e776-42f0-b261-652504e49a2f",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "2ec7d288-c57c-49ba-8ffe-85e0aade3494",
                      field: "state.group.isCurrentItemActive",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "febcbd86-e776-42f0-b261-652504e49a2f": {
                componentOverrides: {
                  "8c1f32e4-bb95-4777-8cdb-732f4734e61f": {
                    props: {
                      style: {
                        opacity: "100%",
                      },
                    },
                  },
                },
              },
            },
          },
          {
            id: "c9b84305-01ee-4645-a3a8-426481018ec9",
            name: "For Slide 3",
            type: "image",
            props: {
              style: {
                width: "64px",
                cursor: "pointer",
                height: "64px",
                __width: "64px",
                opacity: "50%",
                __height: "64px",
                objectFit: "cover",
                __imageSource:
                  "https://images.unsplash.com/photo-1471086569966-db3eebc25a59?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MjZ8fHxlbnwwfHx8fHw%3D",
                __imageAltText:
                  "A single monstera leaf in a clear glass vase against a white background.",
                borderTopLeftRadius: "100%",
                borderTopRightRadius: "100%",
                borderBottomLeftRadius: "100%",
                borderBottomRightRadius: "100%",
              },
              loading: "lazy",
              "style@md": {
                width: "64px",
                height: "64px",
                __width: "64px",
                __height: "64px",
                flexGrow: "unset",
              },
              "style@sm": {
                width: "48px",
                height: "48px",
                __width: "48px",
                __height: "48px",
                flexGrow: "unset",
              },
            },
            markers: {
              _aiGeneratedAltText: true,
            },
            variants: [
              {
                id: "177217fe-f5fe-48be-aafd-9eb9cb6c7eb2",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "0558a10a-edd3-4c2d-b42a-4110ee249db3",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "e4169129-12fd-43fa-84cd-15c077a6af67",
                      field: "state.group.isCurrentItemActive",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "0558a10a-edd3-4c2d-b42a-4110ee249db3": {
                componentOverrides: {
                  "c9b84305-01ee-4645-a3a8-426481018ec9": {
                    props: {
                      style: {
                        opacity: "100%",
                      },
                    },
                  },
                },
              },
            },
          },
          {
            id: "dbee8c52-9993-41ae-a458-974409b75cba",
            name: "For Slide 4",
            type: "image",
            props: {
              style: {
                width: "64px",
                cursor: "pointer",
                height: "64px",
                __width: "64px",
                opacity: "50%",
                __height: "64px",
                objectFit: "cover",
                __imageSource:
                  "https://images.unsplash.com/photo-1655658233701-a0bada796e94?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MXx8fGVufDB8fHx8fA%3D%3D",
                __imageAltText:
                  "Green palm leaf beside blank white cards on a plain background.",
                borderTopLeftRadius: "100%",
                borderTopRightRadius: "100%",
                borderBottomLeftRadius: "100%",
                borderBottomRightRadius: "100%",
              },
              loading: "lazy",
              "style@md": {
                width: "64px",
                height: "64px",
                __width: "64px",
                __height: "64px",
                flexGrow: "unset",
              },
              "style@sm": {
                width: "48px",
                height: "48px",
                __width: "48px",
                __height: "48px",
                flexGrow: "unset",
              },
            },
            markers: {
              _aiGeneratedAltText: true,
            },
            variants: [
              {
                id: "f392f647-f63d-4056-845d-910c099faf34",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "9a4c7013-5231-4f40-8c51-35b1fd47c356",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "a577620a-970e-494c-8322-6984a9d55f7d",
                      field: "state.group.isCurrentItemActive",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "9a4c7013-5231-4f40-8c51-35b1fd47c356": {
                componentOverrides: {
                  "dbee8c52-9993-41ae-a458-974409b75cba": {
                    props: {
                      style: {
                        opacity: "100%",
                      },
                    },
                  },
                },
              },
            },
          },
          {
            id: "df7ac72f-db1f-4963-ac1d-ae59ef418eef",
            name: "For Slide 5",
            type: "image",
            props: {
              style: {
                width: "64px",
                cursor: "pointer",
                height: "64px",
                __width: "64px",
                opacity: "50%",
                __height: "64px",
                objectFit: "cover",
                __imageSource:
                  "https://images.unsplash.com/photo-1625474941653-4343bbb8267a?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8NHx8fGVufDB8fHx8fA%3D%3D",
                __imageAltText:
                  "Smartphone with content on screen beside a potted plant against a white background with sunlight shadows.",
                borderTopLeftRadius: "100%",
                borderTopRightRadius: "100%",
                borderBottomLeftRadius: "100%",
                borderBottomRightRadius: "100%",
              },
              loading: "lazy",
              "style@md": {
                width: "64px",
                height: "64px",
                __width: "64px",
                __height: "64px",
                flexGrow: "unset",
              },
              "style@sm": {
                width: "48px",
                height: "48px",
                __width: "48px",
                __height: "48px",
                flexGrow: "unset",
              },
            },
            markers: {
              _aiGeneratedAltText: true,
            },
            variants: [
              {
                id: "b9ffa7e8-f133-4770-ac4a-172f4454448c",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "12e1d673-bbfe-4c87-a754-95805f378c39",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "a577620a-970e-494c-8322-6984a9d55f7d",
                      field: "state.group.isCurrentItemActive",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "12e1d673-bbfe-4c87-a754-95805f378c39": {
                componentOverrides: {
                  "df7ac72f-db1f-4963-ac1d-ae59ef418eef": {
                    props: {
                      style: {
                        opacity: "100%",
                      },
                    },
                  },
                },
              },
            },
          },
          {
            id: "105136a0-384f-4b7e-a7b1-cb8f537ab02c",
            name: "For Slide 6",
            type: "image",
            props: {
              style: {
                width: "64px",
                cursor: "pointer",
                height: "64px",
                __width: "64px",
                opacity: "50%",
                __height: "64px",
                objectFit: "cover",
                __imageSource:
                  "https://images.unsplash.com/photo-1623824505948-2938b8818e12?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8N3x8fGVufDB8fHx8fA%3D%3D",
                __imageAltText: "Green palm leaves against a white background.",
                borderTopLeftRadius: "100%",
                borderTopRightRadius: "100%",
                borderBottomLeftRadius: "100%",
                borderBottomRightRadius: "100%",
              },
              loading: "lazy",
              "style@md": {
                width: "64px",
                height: "64px",
                __width: "64px",
                __height: "64px",
                flexGrow: "unset",
              },
              "style@sm": {
                width: "48px",
                height: "48px",
                __width: "48px",
                __height: "48px",
                flexGrow: "unset",
              },
            },
            markers: {
              _aiGeneratedAltText: true,
            },
            variants: [
              {
                id: "5c2b4274-446b-4399-bd8f-79b247a5f757",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "6efc2e67-f158-4d84-acfb-3e1d54b0cbe0",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "a577620a-970e-494c-8322-6984a9d55f7d",
                      field: "state.group.isCurrentItemActive",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "6efc2e67-f158-4d84-acfb-3e1d54b0cbe0": {
                componentOverrides: {
                  "105136a0-384f-4b7e-a7b1-cb8f537ab02c": {
                    props: {
                      style: {
                        opacity: "100%",
                      },
                    },
                  },
                },
              },
            },
          },
          {
            id: "941c6090-8819-44ea-90bb-706aea14ee62",
            name: "For Slide 7",
            type: "image",
            props: {
              style: {
                width: "64px",
                cursor: "pointer",
                height: "64px",
                __width: "64px",
                opacity: "50%",
                __height: "64px",
                objectFit: "cover",
                __imageSource:
                  "https://images.unsplash.com/photo-1622624671653-18a35aba747d?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTJ8fHxlbnwwfHx8fHw%3D",
                __imageAltText:
                  "Smartphone on a table displaying an article, with a blurry green foreground and white background.",
                borderTopLeftRadius: "100%",
                borderTopRightRadius: "100%",
                borderBottomLeftRadius: "100%",
                borderBottomRightRadius: "100%",
              },
              loading: "lazy",
              "style@md": {
                width: "64px",
                height: "64px",
                __width: "64px",
                __height: "64px",
                flexGrow: "unset",
              },
              "style@sm": {
                width: "48px",
                height: "48px",
                __width: "48px",
                __height: "48px",
                flexGrow: "unset",
              },
            },
            markers: {
              _aiGeneratedAltText: true,
            },
            variants: [
              {
                id: "4a312e43-cd88-4165-9ba5-abca20524a4b",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "604b7870-882c-4495-8eae-4d721807a0e9",
                name: "Active",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "a577620a-970e-494c-8322-6984a9d55f7d",
                      field: "state.group.isCurrentItemActive",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "604b7870-882c-4495-8eae-4d721807a0e9": {
                componentOverrides: {
                  "941c6090-8819-44ea-90bb-706aea14ee62": {
                    props: {
                      style: {
                        opacity: "100%",
                      },
                    },
                  },
                },
              },
            },
          },
        ],
      },
      {
        id: "119b088d-af1b-4210-b341-0b0d5ce66658",
        name: "Slides",
        type: "carouselV3Slides",
        props: {
          style: {
            width: "600px",
            __width: "600px",
            __height: "400px",
            overflow: "hidden",
            flexBasis: 0,
          },
          "style@md": {
            width: "auto",
            flexGrow: 1,
          },
          "style@sm": {
            width: "auto",
            height: "auto",
            __width: "350px",
            __height: "350px",
            flexGrow: "unset",
            alignSelf: "stretch",
          },
        },
        children: [
          {
            id: "c3b5d031-bd55-410d-946b-558bc5ac4695",
            name: "Slide 1",
            type: "container",
            props: {
              style: {
                width: "100%",
                height: "100%",
                display: "flex",
                flexBasis: 0,
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              },
              "style@sm": {
                width: "auto",
                height: "auto",
                flexGrow: 1,
                alignSelf: "stretch",
              },
            },
            children: [
              {
                id: "f1fccdc6-56c4-4e1a-a227-4d7857e97a57",
                name: "Image",
                type: "image",
                props: {
                  style: {
                    width: "100%",
                    __width: "100%",
                    __height: "100%",
                    flexBasis: 0,
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1504198266287-1659872e6590?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTd8fHxlbnwwfHx8fHw%3D",
                    __imageAltText:
                      "A single green leaf against a white background.",
                    height: "100%",
                  },
                  loading: "lazy",
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
              },
            ],
          },
          {
            id: "18d7b1eb-c398-48e1-9208-3a72129e81c8",
            name: "Slide 2",
            type: "container",
            props: {
              style: {
                width: "100%",
                height: "100%",
                display: "flex",
                flexBasis: 0,
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              },
              "style@sm": {
                width: "auto",
                height: "auto",
                flexGrow: 1,
                alignSelf: "stretch",
              },
            },
            children: [
              {
                id: "cbc89134-ca8a-452c-94b3-fff7f98ad7e1",
                name: "Image",
                type: "image",
                props: {
                  style: {
                    width: "100%",
                    height: "100%",
                    __width: "100%",
                    __height: "100%",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1504204267155-aaad8e81290d?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTR8fHxlbnwwfHx8fHw%3D",
                    __imageAltText:
                      "A single green leaf in a clear glass of water on white background.",
                  },
                  loading: "lazy",
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
              },
            ],
          },
          {
            id: "a173d4d3-a8df-4f45-9bdc-33bf63ae7b26",
            name: "Slide 3",
            type: "container",
            props: {
              style: {
                width: "100%",
                height: "100%",
                display: "flex",
                flexBasis: 0,
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              },
            },
            children: [
              {
                id: "3665a71e-4a8a-4bc2-bcd3-9cf9fd5ef0d6",
                name: "Image",
                type: "image",
                props: {
                  style: {
                    width: "100%",
                    height: "100%",
                    __width: "100%",
                    __height: "100%",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1471086569966-db3eebc25a59?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MjZ8fHxlbnwwfHx8fHw%3D",
                    __imageAltText:
                      "A single Monstera leaf in a clear glass vase against a white background.",
                  },
                  loading: "lazy",
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
              },
            ],
          },
          {
            id: "2803a44b-1ecd-408c-a2fe-f8bc0f229f63",
            name: "Slide 4",
            type: "container",
            props: {
              style: {
                width: "100%",
                height: "100%",
                display: "flex",
                flexBasis: 0,
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              },
            },
            children: [
              {
                id: "51346b30-4b4d-45e3-8d9e-53a255e719d2",
                name: "Image",
                type: "image",
                props: {
                  style: {
                    width: "100%",
                    height: "100%",
                    __width: "100%",
                    __height: "100%",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1655658233701-a0bada796e94?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MXx8fGVufDB8fHx8fA%3D%3D",
                    __imageAltText:
                      "Green palm leaf beside two blank white cards on a white background.",
                  },
                  loading: "lazy",
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
              },
            ],
          },
          {
            id: "4e52cff5-9949-47f9-9be5-afd5f3ff6f6e",
            name: "Slide 5",
            type: "container",
            props: {
              style: {
                width: "100%",
                height: "100%",
                display: "flex",
                flexBasis: 0,
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              },
            },
            children: [
              {
                id: "1489f2e3-df16-4ea3-ab6a-aa3eb4ab5358",
                name: "Image",
                type: "image",
                props: {
                  style: {
                    width: "100%",
                    height: "100%",
                    __width: "100%",
                    __height: "100%",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1622624671653-18a35aba747d?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTJ8fHxlbnwwfHx8fHw%3D",
                    __imageAltText:
                      "Smartphone on a white desk displaying an app with a minimalist design.",
                  },
                  loading: "lazy",
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
              },
            ],
          },
          {
            id: "4c2df9a4-16e0-4ffe-935f-75eae5a9549d",
            name: "Slide 6",
            type: "container",
            props: {
              style: {
                width: "100%",
                height: "100%",
                display: "flex",
                flexBasis: 0,
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              },
            },
            children: [
              {
                id: "c62be4cf-fb90-4b8e-8070-756dcc339889",
                name: "Image",
                type: "image",
                props: {
                  style: {
                    width: "100%",
                    height: "100%",
                    __width: "100%",
                    __height: "100%",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1655658233701-a0bada796e94?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MXx8fGVufDB8fHx8fA%3D%3D",
                    __imageAltText:
                      "Green palm leaf beside two blank white cards on a white background.",
                  },
                  loading: "lazy",
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
              },
            ],
          },
          {
            id: "70fbf963-f03c-4c07-8d8b-92b2f95c000b",
            name: "Slide 7",
            type: "container",
            props: {
              style: {
                width: "100%",
                height: "100%",
                display: "flex",
                flexBasis: 0,
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              },
            },
            children: [
              {
                id: "ab602852-1d18-411e-bea9-f330ceddbca4",
                name: "Image",
                type: "image",
                props: {
                  style: {
                    width: "100%",
                    height: "100%",
                    __width: "100%",
                    __height: "100%",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1622624671653-18a35aba747d?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTJ8fHxlbnwwfHx8fHw%3D",
                    __imageAltText:
                      "Green palm leaf beside two blank white cards on a white background.",
                  },
                  loading: "lazy",
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
};
