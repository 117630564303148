import * as React from "react";

export const Svg = React.forwardRef<SVGSVGElement, SvgProps>(
  function Svg(props, forwardedRef) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" ref={forwardedRef} {...props} />
    );
  },
);

export interface SvgProps extends React.ComponentPropsWithRef<"svg"> {}
