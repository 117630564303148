import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";

import * as React from "react";

import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useGetAttribute } from "@editor/hooks/useGetAttribute";
import {
  selectAlignItems,
  selectDraftComponentChildren,
  selectFlexDirection,
  selectFlexWrap,
  selectJustifyContent,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { getNormalizedFlexDirection } from "replo-runtime/shared/utils/flexDirection";

import { styleAttributeToEditorData } from "./styleAttribute";

const FLEX_START_PROPERTY_VALUES = new Set(["flex-start", "start"]);
const FLEX_END_PROPERTY_VALUES = new Set(["flex-end", "end"]);

const VALUE_TO_ALIGNMENT_OPTION: Record<string, string> = {
  start: "flex-start",
  center: "center",
  end: "flex-end",
  "flex-start": "flex-start",
  "flex-end": "flex-end",
  "space-between": "space-between",
};

export function useFlexDirection() {
  const rawFlexDirection = useEditorSelector(selectFlexDirection);
  const rawFlexWrap = useEditorSelector(selectFlexWrap);
  const applyComponentAction = useApplyComponentAction();
  const draftComponentChildren = useEditorSelector(
    selectDraftComponentChildren,
  );
  const getAttribute = useGetAttribute();

  const flexDirection =
    rawFlexDirection || styleAttributeToEditorData.flexDirection.defaultValue;
  const flexWrap =
    rawFlexWrap || styleAttributeToEditorData.flexWrap.defaultValue;
  const orientation = getFlexOrientation(flexDirection);

  const onChange = React.useCallback(
    (value: "row" | "column" | "wrap" | "row-reverse" | "column-reverse") => {
      const actions: UseApplyComponentActionType[] = [];
      if (value !== "wrap") {
        actions.push({
          type: "setStyles",
          value: {
            display: "flex",
            flexDirection: value,
            flexWrap: styleAttributeToEditorData.flexWrap.defaultValue,
          },
        });
      } else {
        actions.push({
          type: "setStyles",
          value: {
            flexDirection: "row",
            flexWrap: "wrap",
          },
        });
      }

      draftComponentChildren?.forEach((child) => {
        const childFlexGrow = getAttribute(child, "style.flexGrow", null).value;
        const childHasFlexGrow =
          childFlexGrow && !Number.isNaN(Number(childFlexGrow));

        const childAlignSelf = getAttribute(
          child,
          "style.alignSelf",
          null,
        ).value;
        const childHasAlignSelfStretch = childAlignSelf === "stretch";

        // Note (Noah, 2023-05-29): If we're about to go from column -> row and
        // the child has flex grow, get rid of the width since otherwise it would
        // override the new flex grow
        if (getNormalizedFlexDirection(value) === "row" && childHasFlexGrow) {
          actions.push({
            type: "setStyles",
            value: { width: "auto" },
            componentId: child.id,
            analyticsExtras: {
              actionType: "edit",
              createdBy: "replo",
            },
          });
        }

        // Note (Noah, 2023-05-29): If we're about to go from column -> row and
        // the child has align-self stretch, get rid of the height since otherwise it would
        // override the new align-self stretch
        if (
          getNormalizedFlexDirection(value) === "row" &&
          childHasAlignSelfStretch
        ) {
          actions.push({
            type: "setStyles",
            value: { height: "auto" },
            componentId: child.id,
            analyticsExtras: {
              actionType: "edit",
              createdBy: "replo",
            },
          });
        }

        // Note (Noah, 2023-05-29): If we're about to go from row -> column and
        // the child has flex grow, get rid of the height since otherwise it would
        // override the new flex grow
        if (
          getNormalizedFlexDirection(value) === "column" &&
          childHasFlexGrow
        ) {
          actions.push({
            type: "setStyles",
            // Note: Ovishek (2022-03-23): setting flexGrow to 1 is necessary
            // actually we're converting other flexGrow values to 1 b/c flexGrow can be greater than 1 in row
            // but when transforming it to column those values doesn't make same sense as row
            value: { height: "auto", flexGrow: 1, flexBasis: "auto" },
            componentId: child.id,
            analyticsExtras: {
              actionType: "edit",
              createdBy: "replo",
            },
          });
        }

        // Note (Noah, 2023-05-29): If we're about to go from row -> column and
        // the child has align-self stretch, get rid of the width since otherwise it would
        // override the new align-self stretch
        if (
          getNormalizedFlexDirection(value) === "column" &&
          childHasAlignSelfStretch
        ) {
          actions.push({
            type: "setStyles",
            value: { width: "auto" },
            componentId: child.id,
            analyticsExtras: {
              actionType: "edit",
              createdBy: "replo",
            },
          });
        }
      });

      applyComponentAction({
        type: "applyCompositeAction",
        value: actions,
      });
    },
    [applyComponentAction, draftComponentChildren, getAttribute],
  );

  const onReverseChange = (value: "forward" | "reverse") => {
    if (value === "reverse") {
      onChange(`${orientation}-reverse`);
    } else {
      onChange(orientation);
    }
  };

  const getCurrentValue = () => {
    if (flexWrap === "wrap") {
      return "wrap";
    }
    if (flexDirection.includes("column")) {
      return "column";
    }
    return "row";
  };

  const currentValue = getCurrentValue();

  return {
    value: currentValue,
    isReversed: flexDirection.includes("reverse"),
    orientation,
    onChange,
    onReverseChange,
  };
}

export function useFlexJustifyState() {
  const rawJustifyContent = useEditorSelector(selectJustifyContent);
  const justifyContent =
    VALUE_TO_ALIGNMENT_OPTION[rawJustifyContent ?? "flex-start"];

  return useOverridableState<"packed" | "spaced">(
    justifyContent === "space-between" ? "spaced" : "packed",
  );
}

/**
 * Given a flex start or flex end, returns the reverse
 */
export function flipFlexStartAndFlexEnd(startOrEnd: string) {
  if (FLEX_START_PROPERTY_VALUES.has(startOrEnd)) {
    return "flex-end";
  } else if (FLEX_END_PROPERTY_VALUES.has(startOrEnd)) {
    return "flex-start";
  }
  return startOrEnd;
}

export function useAlignItems() {
  const alignItems: string = useEditorSelector(selectAlignItems) ?? "center";
  return VALUE_TO_ALIGNMENT_OPTION[alignItems];
}

export function useJustifyContent() {
  const justifyContent: string =
    useEditorSelector(selectJustifyContent) ?? "flex-start";
  const { isReversed } = useFlexDirection();
  const value = isReversed
    ? flipFlexStartAndFlexEnd(justifyContent)
    : justifyContent;
  return VALUE_TO_ALIGNMENT_OPTION[value];
}

export function getFlexOrientation(direction: string) {
  if (direction.includes("row")) {
    return "row";
  }
  return "column";
}
