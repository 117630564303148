import * as React from "react";

import { Loader } from "@editor/components/common/Loader";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { trpc } from "@editor/utils/trpc";

import { AllMetricsTable } from "@/features/analytics/AllMetricsTable";
import AnalyticsLayout from "@/features/analytics/Layout";
import { SetupPage } from "@/features/analytics/Setup";
import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import { BillingTiers } from "schemas/billing";

export const AnalyticsOverview: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId();
  const { data: isEnabled, isLoading: isLoadingEnableCheck } =
    trpc.analytics.isEnabled.useQuery(
      workspaceId ? { workspaceId } : reactQuerySkipToken,
    );
  const { data: workspaceSubscription, isLoading: isLoadingSubscription } =
    trpc.subscriptions.getActiveSubscriptionByWorkspace.useQuery(
      workspaceId ?? reactQuerySkipToken,
    );
  const isLoading =
    !workspaceId || isLoadingEnableCheck || isLoadingSubscription;
  const isCurrentlyPaying =
    Boolean(workspaceSubscription) &&
    workspaceSubscription?.name !== BillingTiers.FREE;
  const showAllMetricsTable = isEnabled;
  const showSetupPage = !isEnabled && !isLoading;
  const showLoadingPage = isLoading;

  return (
    <AnalyticsLayout>
      {showLoadingPage && <Loader className="grow h-full min-h-0 h-[70vh]" />}
      {showAllMetricsTable && <AllMetricsTable />}
      {showSetupPage && <SetupPage isCurrentlyPaying={isCurrentlyPaying} />}
    </AnalyticsLayout>
  );
};
