// #region CONSTANTS

import type { TouchContext } from "./types";

export const EMPTY_ARRAY = Object.freeze([]) as [];
// biome-ignore lint/suspicious/noEmptyBlockStatements: ignore for now as noop
export const NOOP = () => {};

export const KEY_ARROW_LEFT = "ArrowLeft";
export const KEY_ARROW_RIGHT = "ArrowRight";
export const KEY_ARROW_UP = "ArrowUp";
export const KEY_ARROW_DOWN = "ArrowDown";
export const KEY_HOME = "Home";
export const KEY_END = "End";
export const KEY_PAGE_UP = "PageUp";
export const KEY_PAGE_DOWN = "PageDown";

export const END_NORMAL = "normal";
export const END_LOOP = "loop";
export const END_RESET = "reset";

export const TRANSITION_SLIDE = "slide";
export const TRANSITION_FADE = "fade";
export const TRANSITION_OFF = "off";

export const TRANSITION_BACKWARD = 0;
export const TRANSITION_FORWARD = 1;

export const SLIDE_POSITION_START = "start";
export const SLIDE_POSITION_CENTER = "center";

export const DRAG_MOVE_ONCE = "move-once";
export const DRAG_FREE = "free";
export const DRAG_FREE_SNAP = "free-snap";
export const DRAG_OFF = "off";

export const WHEEL_MOVE_ONCE = "move-once";
export const WHEEL_FREE = "free";
export const WHEEL_OFF = "off";

export const ORIENTATION_HORIZONTAL = "horizontal";
export const ORIENTATION_VERTICAL = "vertical";
export const AXIS_X = "x";
export const AXIS_Y = "y";

export const DIRECTION_LTR = "ltr";
export const DIRECTION_RTL = "rtl";
export const DIRECTION_TTB = "ttb";

export const MOVE_DIRECTION_NEXT = "next";
export const MOVE_DIRECTION_PREVIOUS = "previous";

// NOTE: These are from Splide. Just here for reference.

// biome-ignore lint/correctness/noUnusedVariables: ignore unused var for now
const I18N = {
  prev: "Previous slide",
  next: "Next slide",
  first: "Go to first slide",
  last: "Go to last slide",
  slideX: "Go to slide %s",
  pageX: "Go to page %s",
  play: "Start autoplay",
  pause: "Pause autoplay",
  carousel: "carousel",
  slide: "slide",
  select: "Select a slide to show",
  /** [slide number] of [slide size] */
  slideLabel: "%s of %s",
};

// biome-ignore lint/correctness/noUnusedVariables: ignore unused var for now
const DEFAULTS = {
  type: "slide",
  role: "region",
  speed: 400,
  perPage: 1,
  cloneStatus: true,
  arrows: true,
  pagination: true,
  paginationKeyboard: true,
  interval: 5000,
  pauseOnHover: true,
  pauseOnFocus: true,
  resetProgress: true,
  easing: "cubic-bezier(0.25, 1, 0.5, 1)",
  drag: true,
  direction: "ltr",
  trimSpace: true,
  focusableNodes: "a, button, textarea, input, select, iframe",
  live: true,
  reducedMotion: {
    speed: 0,
    rewindSpeed: 0,
    autoplay: "pause",
  },
};

// biome-ignore lint/correctness/noUnusedVariables: ignore unused var for now
const SCROLL_LISTENER_OPTIONS = { passive: false, capture: true };

// biome-ignore lint/correctness/noUnusedVariables: ignore unused var for now
const MEDIA_MOTION_OK = "(prefers-reduced-motion: no-preference)";

export const INITIAL_TOUCH_CONTEXT = {
  state: null,
  swipeLeft: null,
  curX: 0,
  curY: 0,
  startX: 0,
  startY: 0,
} as const satisfies TouchContext;

export const TOUCH_DRAGGING = "dragging";
export const TOUCH_SWIPING = "swiping";
