import type {
  AnalyticsReadQuery,
  AnalyticsReadQueryFilters,
} from "schemas/generated/analyticsRead";

import { z } from "zod";

export const metricNameSchema = z
  .enum([
    "conversions",
    "views",
    "unique_sessions",
    "conversion_rates",
    "revenue",
    "revenue_per_session",
    "average_order_value",
  ])
  .describe("MetricName");

/**
 * --------------------------------
 * Schemas for AnalyticsReadResponse
 * --------------------------------
 */

const rangeResultMetadataSchema = z.object({
  elementId: z.string().uuid().nullable(),
  title: z.string(),
  url: z.string(),
});

const rangeResultMetricsSchema = z.record(
  metricNameSchema,
  z.array(z.number()),
);

export const rangeResultSchema = z
  .object({
    urlPath: z.string(),
    metadata: rangeResultMetadataSchema,
    rangeId: z.string().uuid(),
    metrics: rangeResultMetricsSchema,
  })
  .describe("RangeResult");

const totalRowsCountSchema = z.number().min(0);

export const analyticsReadResponseSchema = z
  .object({
    totalRowsCount: totalRowsCountSchema,
    rangeResults: z.array(rangeResultSchema),
  })
  .describe("AnalyticsReadResponse");

/**
 * --------------------------------
 * Schemas for AnalyticsReadQuery
 * --------------------------------
 */

const urlHostsSchema = z.array(z.string());
const offsetSchema = z.number().min(0);
const limitSchema = z.number().positive().max(100);

export const orderSchema = z.enum(["ASC", "DESC"]).describe("Order");

// biome-ignore lint/nursery/noEnum: This is a legacy enum, we should convert to a string union
export enum PageTypeEnum {
  ALL_PAGES = "allPages",
  ENTRY_PAGES = "entryPages",
}

export const pageTypeEnumSchema = z
  .nativeEnum(PageTypeEnum)
  .describe("PageTypeEnum");

// biome-ignore lint/nursery/noEnum: This is a legacy enum, we should convert to a string union
export enum ConditionOperatorEnum {
  EQUALS = "equals",
  DOES_NOT_EQUAL = "does_not_equal",
  CONTAINS = "contains",
  DOES_NOT_CONTAIN = "does_not_contain",
}

export const conditionOperatorEnumSchema = z
  .nativeEnum(ConditionOperatorEnum)
  .describe("ConditionOperatorEnum");

export const filterConditionSchema = z
  .discriminatedUnion("operator", [
    z.object({
      operator: z
        .literal(ConditionOperatorEnum.EQUALS)
        .describe("ConditionOperatorEnum.EQUALS"),
      value: z.array(z.string()),
    }),
    z.object({
      operator: z
        .literal(ConditionOperatorEnum.DOES_NOT_EQUAL)
        .describe("ConditionOperatorEnum.DOES_NOT_EQUAL"),
      value: z.array(z.string()),
    }),
    z.object({
      operator: z
        .literal(ConditionOperatorEnum.CONTAINS)
        .describe("ConditionOperatorEnum.CONTAINS"),
      value: z.string(),
    }),
    z.object({
      operator: z
        .literal(ConditionOperatorEnum.DOES_NOT_CONTAIN)
        .describe("ConditionOperatorEnum.DOES_NOT_CONTAIN"),
      value: z.string(),
    }),
  ])
  .describe("FilterCondition");

export const filterConditionsSchema = z
  .array(filterConditionSchema)
  .describe("FilterConditions");

export const urlParamsSchema = z
  .record(filterConditionsSchema)
  .describe("QueryFiltersUrlParams");

export const filterSchema = z
  .object({
    urlPath: filterConditionsSchema,
    pageType: z.nativeEnum(PageTypeEnum).describe("PageTypeEnum"),
    urlParams: urlParamsSchema,
  })
  .describe("AnalyticsReadQueryFilters");

export type FilterName = keyof AnalyticsReadQueryFilters;

export const queryRangeSchema = z
  .object({
    id: z.string().uuid(),
    startDatetime: z.number().min(0),
    endDatetime: z.number().positive(),
    interval: z.number().min(10),
  })
  .describe("QueryRange");

export const queryRangesSchema = z
  .object({
    mainRange: queryRangeSchema,
    compareAtRanges: z.array(queryRangeSchema),
  })
  .describe("QueryRanges");

export const analyticsReadQuerySchema = z
  .object({
    /**
     * Workspace is always required. This is how we
     * check the individual paths to be sure we're looking up the correct ones for the workspace.
     */
    urlHosts: urlHostsSchema,
    /**
     * The metrics that are directly stored in ClickHouse's timeseries_records table, and/or metrics
     * that need to be calculated from metrics that are stored in ClickHouse. E.g. "conversions",
     * "views", "conversion_rates".
     */
    metrics: z.array(metricNameSchema).min(1),
    /**
     * Sorting order for the sorting metric.
     */
    order: orderSchema,
    /**
     * Sort based on a given metric name.
     */
    sortMetric: metricNameSchema,
    /**
     * The range being considered, including the interval (in MINUTES) of the buckets that we want to retrieve. E.g. if interval = 30, then we're
     * aggregating the metric calculation in buckets of 30min.
     */
    ranges: queryRangesSchema,
    /**
     * Offset used in pagination.
     */
    offset: offsetSchema,
    /**
     * Limit of the results per page.
     */
    limit: limitSchema,
    filters: filterSchema,
  })
  .superRefine((input, ctx) => {
    if (!input.metrics.includes(input.sortMetric)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Sort value must be one of of the specified metrics.",
        path: ["sort"],
      });
    }
  })
  .describe("AnalyticsReadQuery");

export type AnalyticsReadQueryWithoutRanges = Omit<
  AnalyticsReadQuery,
  "ranges"
>;

/**
 * NOTE (Max, 2024-09-23): Filters that are used in sql.ts to filter out individual
 * events, regardless of the pageType (entryPages | allPages). We're not including
 * pageType as this one is used to alter the construction of CTEs, whereas the
 * SQL WHERE clause for the other filters remains the same, regardless of pageType.
 */
export type QueryFiltersRoot = Pick<AnalyticsReadQueryFilters, "urlPath">;

/**
 * --------------------------------
 * Schemas for retrieving urlParams
 * --------------------------------
 */

export const findUrlParamsQuerySchema = z
  .object({
    urlHosts: urlHostsSchema,
    order: orderSchema,
    offset: offsetSchema,
    limit: limitSchema,
    key: z.string(),
    value: z.string(),
  })
  .describe("FindUrlParamsQuery");

export const findUrlParamsResponseSchema = z
  .object({
    urlParams: z.array(z.string()),
  })
  .describe("FindUrlParamsResponse");

export const findSubDomainsForUrlHostsResponseSchema = z
  .array(
    z.object({
      urlHost: z.string(),
      isRootDomain: z.boolean(),
    }),
  )
  .describe("FindSubDomainsForUrlHostsResponse");
