import {
  selectDraftElementId,
  selectDraftElementType,
  selectProjectId,
} from "@editor/reducers/core-reducer";
import { selectTemplateEditorStoreProduct } from "@editor/reducers/template-reducer";
import { useEditorSelector } from "@editor/store";
import useGetStoreNameAndUrl from "@hooks/useGetStoreNameAndUrl";

export default function usePreviewBeforePublishInfo() {
  const { storeUrl } = useGetStoreNameAndUrl();
  const draftElementType = useEditorSelector(selectDraftElementType);
  const projectId = useEditorSelector(selectProjectId);
  const draftElementId = useEditorSelector(selectDraftElementId);
  const templateEditorProduct = useEditorSelector(
    selectTemplateEditorStoreProduct,
  );

  const baseUrl = `https://${storeUrl}/apps/alchemy/element/preview`;

  if (!draftElementId || !projectId) {
    return {
      url: baseUrl,
      previewUrlAvailable: false,
    };
  }

  const queryParams: Record<string, string> = {
    elementId: draftElementId,
    storeId: projectId,
  };

  if (draftElementType === "shopifyProductTemplate" && templateEditorProduct) {
    queryParams.productHandle = templateEditorProduct.handle;
  }

  const url = new URL(baseUrl);
  url.search = new URLSearchParams(queryParams).toString();

  return {
    url: url.href,
    previewUrlAvailable: true,
  };
}
