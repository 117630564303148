import * as React from "react";

import { Spinner } from "@replo/design-system/components/spinner";

export function FullScreenLoader() {
  return (
    <div className="position--center z-max fixed h-screen w-screen bg-white">
      <Spinner size={50} variant="primary" />
    </div>
  );
}
