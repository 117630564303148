import type {
  ElementContent,
  ElementErrorType,
  ReploEditableElement,
  ReploEditableElementWithId,
} from "@editor/utils/element";
import type { EditorRoute } from "@editor/utils/router";
import type { ReploElement, ReploElementType } from "schemas/generated/element";
import type { DuplicateElementModalProps } from "../AppModalTypes";

import * as React from "react";

import { cloneMetafieldsRemovingIds } from "@common/utils";
import PageElementEditor from "@components/editor/elementEditors/PageElementEditor";
import ProductTemplateElementEditor from "@components/editor/elementEditors/ProductTemplateElementEditor";
import { SectionElementEditor } from "@components/editor/elementEditors/SectionElementEditor";
import { ElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import {
  useElementEditorErrorContext,
  withElementEditorErrorContext,
} from "@editor/contexts/ElementEditorErrorContext";
import { useElementValidation } from "@editor/hooks/element";
import { useModal } from "@editor/hooks/useModal";
import { analytics } from "@editor/infra/analytics";
import {
  createElement,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  setElementsLoading,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import {
  elementFormHasErrors,
  getDuplicatedElementName,
  getDuplicateElementPath,
  getFormattedElementName,
} from "@editor/utils/element";
import { routes } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button";
import classNames from "classnames";
import { generatePath, useNavigate } from "react-router-dom";
import { isPageOrShopifyArticleType } from "schemas/element";

import { ElementEditorModalForm } from "../editor/elementEditors/ElementEditorModalForm";
import { ElementEditorTitle } from "../editor/elementEditors/ElementEditorTitle";

const DUPLICATE_ELEMENT_TYPE_CONTENT: Record<ReploElementType, ElementContent> =
  {
    page: {
      header: "Duplicate Page",
      submitText: "Duplicate Page",
    },
    shopifyArticle: {
      header: "Duplicate Blog",
      submitText: "Duplicate Blog",
    },
    shopifyProductTemplate: {
      header: "Duplicate Product",
      submitText: "Duplicate Product",
    },
    shopifySection: {
      header: "Duplicate Section",
      submitText: "Duplicate Section",
    },
  };

export const DuplicateElementModal: React.FC<DuplicateElementModalProps> =
  withElementEditorErrorContext(
    ({ element: initialElement, isSupportMode }) => {
      const {
        element,
        onChangeElement,
        duplicateElement,
        validateElement,
        isDuplicatingElement,
      } = useDuplicableElement({
        element: initialElement,
        isSupportMode,
      });

      const modal = useModal();
      const { errorMapping, setErrors, clearErrors } =
        useElementEditorErrorContext();

      const navigate = useNavigate();

      const content = DUPLICATE_ELEMENT_TYPE_CONTENT[element.type];
      const isPageOrShopifyArticle = isPageOrShopifyArticleType(element.type);

      async function onSubmit() {
        clearErrors("path", "allErrorsFromAllFormKeys");

        const { isValid, errors: validationErrors } = validateElement(element);
        if (!isValid) {
          if (validationErrors.title.length > 0) {
            setErrors("title", validationErrors.title);
          }
          if (validationErrors.path.length > 0) {
            setErrors("path", validationErrors.path);
          }
          return;
        }

        const result = await duplicateElement(
          element as ReploEditableElementWithId,
        );

        if (result.isDuplicated) {
          modal.closeModal({ type: "duplicateElementModal" });
          navigate(
            generatePath(routes.editor.element, {
              projectId: result.data.element.projectId,
              elementId: result.data.element.id,
            } as EditorRoute),
          );
        } else {
          if (result.errors && result.errors.path.length > 0) {
            setErrors("path", result.errors.path);
          }
        }
      }

      return (
        <ElementEditorDataContext.Provider value={{ element, onChangeElement }}>
          <ElementEditorModalForm
            testId="duplicate-element-form"
            onSubmit={(e) => {
              e.preventDefault();
              if (isDuplicatingElement) {
                return;
              }
              void onSubmit();
            }}
            onCloseModal={() => {
              modal.closeModal({ type: "duplicateElementModal" });
            }}
          >
            <div className="no-scrollbar max-h-[85vh] overflow-y-auto">
              <div
                className={classNames({
                  "h-full": isPageOrShopifyArticle,
                })}
              >
                <ElementEditorTitle>{content.header}</ElementEditorTitle>
                {(() => {
                  switch (element.type) {
                    case "page":
                    case "shopifyArticle":
                      return <PageElementEditor requestType="duplicate" />;
                    case "shopifyProductTemplate":
                      return <ProductTemplateElementEditor />;
                    case "shopifySection":
                      return <SectionElementEditor requestType="duplicate" />;
                    default:
                      return null;
                  }
                })()}
              </div>
            </div>
            <div className="mt-8 flex flex-row justify-end gap-x-2">
              <Button
                size="lg"
                variant="primary"
                type="submit"
                isLoading={isDuplicatingElement}
                data-testid="page-submit-button"
                isDisabled={elementFormHasErrors(errorMapping)}
              >
                {content?.submitText}
              </Button>
            </div>
          </ElementEditorModalForm>
        </ElementEditorDataContext.Provider>
      );
    },
  );

/**
 * This hook handles duplication, validation and the state of a Replo element.
 */
function useDuplicableElement({
  element: initialElement,
  isSupportMode,
}: DuplicateElementModalProps) {
  const draftElement = useEditorSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  );
  const originalElement = initialElement ?? draftElement;
  const now = new Date();
  const [element, setElement] = React.useState<
    ReploEditableElement | ReploEditableElementWithId
  >({
    ...originalElement,
    name: getDuplicatedElementName(
      originalElement.name,
      now,
      originalElement.type,
      isSupportMode ?? false,
    ),
    shopifyPagePath: getDuplicateElementPath(
      originalElement.name,
      now,
      originalElement.shopifyPagePath,
      isSupportMode ?? false,
    ),
  });
  const dispatch = useEditorDispatch();
  const validateElement = useElementValidation(element.type);
  const { mutateAsync: duplicateElement, isPending: isLoading } =
    trpc.element.duplicate.useMutation({
      onMutate: () => {
        dispatch(setElementsLoading(true));
      },
      onSuccess: (data) => {
        if (data.element) {
          dispatch(
            createElement({
              element: data.element,
            }),
          );
        }
      },
      onSettled: () => {
        dispatch(setElementsLoading(false));
      },
    });

  const onDuplicateElement = React.useCallback(
    async function onDuplicateElementFn(
      element: ReploEditableElementWithId,
    ): Promise<
      | {
          isDuplicated: true;
          data: { element: ReploElement };
        }
      | {
          isDuplicated: false;
          errors?: { path: ElementErrorType[] };
        }
    > {
      try {
        const payload = await duplicateElement({
          element: {
            ...element,
            name: getFormattedElementName(element),
            // Note (Noah, 2023-04-22, USE-85): Don't pass a component here - the backend
            // will take the duplicated element id and automatically sub in the component
            component: undefined,
            // Note (Ovishek, 2023-01-09): We remove all the ids from metafields when duplicating old page metafields.
            // This is because in the backend we update metafields with ids but we should create fresh metafields here.
            shopifyMetafields: cloneMetafieldsRemovingIds(
              element.shopifyMetafields,
            ),
            // Note (Noah, 2022-10-19, REPL-4659): Default to not having pages published
            // for duplicated pages, because otherwise if a page is published but user
            // is on the trial plan, the page will have is_published = True and the user
            // will be able to publish even if they're not paying
            isPublished: false,
            // Note (Noah, 2023-04-27, USE-112): If this page is a homepage, we don't want
            // the duplicated page to be a homepage two, since that would create two homepages
            isHomepage: false,
          },
          duplicatingElementId: element.id,
        });

        if (payload.key === "pathAlreadyInUse") {
          return {
            isDuplicated: false,
            errors: { path: ["pathAlreadyExists"] },
          };
        }

        if (payload.element) {
          analytics.logEvent("element.new", {
            operation: "duplicate",
            type: element.type,
            creationMethod: "duplicate",
          });
          return { isDuplicated: true, data: { element: payload.element } };
        }
      } catch {
        return { isDuplicated: false };
      }

      return { isDuplicated: false };
    },
    [duplicateElement],
  );

  return {
    element,
    onChangeElement: setElement,
    duplicateElement: onDuplicateElement,
    validateElement,
    isDuplicatingElement: isLoading,
  };
}
