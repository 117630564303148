import type { ChartConfig } from "@replo/design-system/components/shadcn/core/chart";

import * as React from "react";

import { DEFAULT_DASHED_SUFFIX } from "@replo/design-system/components/shadcn/constants";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@replo/design-system/components/shadcn/core/card";
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@replo/design-system/components/shadcn/core/chart";
import { NoChartData } from "@replo/design-system/components/shadcn/NoChartData";
import { getLineChartDashCompatibleData } from "@replo/design-system/components/shadcn/utils/charts";
import clsx from "clsx";
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";

export type LineChartDataPoint = {
  x: string | number;
  [key: string]: string | number | null;
};

export type LineChartData = LineChartDataPoint[];

export type ReploLineChartProps<X extends string | number> = {
  title?: React.ReactNode;
  data: LineChartData;
  config: ChartConfig;
  showTooltipCursor?: boolean;
  tooltipKey?: X;
  tooltipKeyFormatter?: (value: X) => string;
  tooltipValueFormatter?: (value: X) => string;
};

const ReploLineChart = <X extends string | number>({
  title,
  data,
  config,
  showTooltipCursor = false,
  tooltipKey,
  tooltipValueFormatter,
}: ReploLineChartProps<X>) => {
  // NOTE (kurt, 2024-08-28): This function generates custom Y-axis ticks for the chart.
  // It calculates the maximum value from all data points, adjusts it upwards,
  // and then creates an array of evenly spaced ticks from 0 to the adjusted maximum.
  // This ensures the Y-axis always starts at 0 and ends slightly above the highest data point.
  const generateYAxis = React.useMemo(() => {
    const allValues = data
      .flatMap((point) =>
        Object.entries(config.lines).map(([key, _]) =>
          Number(point[key as keyof typeof point]),
        ),
      )
      .filter((value) => !Number.isNaN(value));

    const dataMax = Math.max(...allValues);
    const dataMin = 0;

    if (dataMax === 0) {
      return {
        ticks: [0],
        domain: [0, 1],
      };
    }

    const desiredTickCount = 5;

    const range = niceNumber(dataMax - dataMin, false);

    let tickInterval = niceNumber(range / (desiredTickCount - 1), true);

    if (tickInterval === 0) {
      tickInterval = Math.pow(10, Math.floor(Math.log10(dataMax)));
    }

    const niceMin = dataMin;
    const niceMax = Math.ceil(dataMax / tickInterval) * tickInterval;

    const ticks = [];
    for (let tick = niceMin; tick <= niceMax; tick += tickInterval) {
      ticks.push(tick);
    }

    return {
      ticks,
      domain: [niceMin, niceMax],
    };

    function niceNumber(range: number, round: boolean) {
      const exponent = Math.floor(Math.log10(range));
      const fraction = range / Math.pow(10, exponent);
      let niceFraction: number;

      if (round) {
        if (fraction < 1.5) {
          niceFraction = 1;
        } else if (fraction < 3) {
          niceFraction = 2;
        } else if (fraction < 7) {
          niceFraction = 5;
        } else {
          niceFraction = 10;
        }
      } else {
        if (fraction <= 1) {
          niceFraction = 1;
        } else if (fraction <= 2) {
          niceFraction = 2;
        } else if (fraction <= 5) {
          niceFraction = 5;
        } else {
          niceFraction = 10;
        }
      }

      return niceFraction * Math.pow(10, exponent);
    }
  }, [data, config]);

  const dashedData = React.useMemo(() => {
    return getLineChartDashCompatibleData(data, config);
  }, [data, config]);

  const hasNonZeroValues = React.useMemo(() => {
    return Object.values(config.lines).some((line) =>
      data.some((point) => Number(point[line.dataKey]) !== 0),
    );
  }, [data, config.lines]);

  return (
    <Card className="rounded-xl border-[1px] border-slate-300 w-full">
      <CardHeader>
        <CardTitle className="text-base text-slate-800 font-medium">
          <div className="inline-block">{title}</div>
        </CardTitle>
      </CardHeader>
      <CardContent className="h-[250px]">
        {!hasNonZeroValues ? (
          <NoChartData />
        ) : (
          <ChartContainer
            config={config}
            className={clsx("ml-[-1rem] w-full h-[250px]")}
          >
            <LineChart accessibilityLayer data={dashedData}>
              <CartesianGrid vertical={false} syncWithTicks={true} />
              <XAxis
                tickLine={false}
                axisLine={false}
                tick={{ fill: "var(--replo-color-slate-400-a100)" }}
                tickMargin={16}
                interval={
                  data.length > 7 ? "equidistantPreserveStart" : undefined
                }
                {...config.xAxis}
                tickFormatter={(value: any, index: number) =>
                  config.xAxis.tickFormatter?.(value, index) ?? value
                }
              />
              <ChartTooltip
                position={{ y: 20 }}
                cursor={showTooltipCursor}
                content={
                  <ChartTooltipContent
                    xAxisDataKey={String(tooltipKey ?? config.xAxis.dataKey)}
                    xAxisDataKeyFormatter={config.tooltipKeyFormatter}
                    tooltipValueFormatter={tooltipValueFormatter}
                  />
                }
              />
              <ChartLegend content={<ChartLegendContent />} />
              <YAxis
                tickLine={false}
                axisLine={false}
                ticks={generateYAxis.ticks}
                domain={generateYAxis.domain}
                tick={{ fill: "var(--replo-color-slate-400-a100)" }}
                tickMargin={16}
                allowDecimals={true}
                {...config.yAxis}
              />
              {Object.entries(config.lines).map(([key, line]) => {
                return (
                  <React.Fragment key={key}>
                    <Line
                      dataKey={line.dataKey}
                      type={line.type || "linear"}
                      stroke={`var(--replo-color-${line.color}-a100)`}
                      strokeWidth={line.strokeWidth || 2}
                      dot={line.dot || false}
                    />
                    {line.dashed && (
                      <Line
                        key={`${key}-dashed`}
                        dataKey={`${line.dataKey}${line.dashed?.suffix || DEFAULT_DASHED_SUFFIX}`}
                        type={line.type || "linear"}
                        stroke={`var(--replo-color-${line.color}-a100)`}
                        strokeWidth={line.strokeWidth || 2}
                        dot={line.dot || false}
                        strokeDasharray="5 5"
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </LineChart>
          </ChartContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default ReploLineChart;
