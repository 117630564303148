import type { WidthOrHeight } from "replo-runtime/shared/types";

import * as React from "react";

import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import {
  selectAliasedFlexShrink,
  selectDraftComponentNodeFromActiveCanvas,
  selectDraftComponentTypeIsModal,
  selectedDraftComponentIsRoot,
  selectParentFlexDirection,
  selectPosition,
} from "@editor/reducers/core-reducer";
import { useEditorStore } from "@editor/store";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";

const useGetFlexboxWidthOrHeightOnChange = () => {
  const applyComponentAction = useApplyComponentAction();
  const store = useEditorStore();

  const onChange = React.useCallback(
    (value: "fill" | "wrap" | "fixed", selectedModifier: WidthOrHeight) => {
      const storeState = store.getState();

      const draftComponentNode =
        selectDraftComponentNodeFromActiveCanvas(storeState);
      const isRoot = selectedDraftComponentIsRoot(storeState);
      const isModal = selectDraftComponentTypeIsModal(storeState);

      if (!draftComponentNode || isRoot || isModal) {
        return;
      }

      const draftComponentPosition = selectPosition(storeState);
      const isFixedOrAbsolutePosition =
        draftComponentPosition &&
        ["fixed", "absolute"].includes(draftComponentPosition);

      const flexShrink = selectAliasedFlexShrink(storeState);
      const parentFlexDirection = selectParentFlexDirection(storeState);

      const isFixed = value === "fixed";
      const newValues: Record<string, string | number> = {};

      const draftComponentNodeBoundingClientRect =
        draftComponentNode?.getBoundingClientRect();
      const newWidthOrHeightValue =
        draftComponentNodeBoundingClientRect?.[selectedModifier].toFixed(0) ||
        "";

      // Apply flex-grow
      if (
        (parentFlexDirection === "row" && selectedModifier === "width") ||
        (parentFlexDirection === "column" && selectedModifier === "height")
      ) {
        if (isFixed) {
          newValues[selectedModifier] = `${newWidthOrHeightValue}px`;
        } else {
          if (isFixedOrAbsolutePosition && value === "fill") {
            newValues[selectedModifier] = "100%";
          } else {
            newValues[selectedModifier] =
              styleAttributeToEditorData[selectedModifier].defaultValue;
          }
          newValues.flexGrow = value === "fill" ? 1 : "unset";
        }
      }

      // Apply align-self
      if (
        (parentFlexDirection === "row" && selectedModifier === "height") ||
        (parentFlexDirection === "column" && selectedModifier === "width")
      ) {
        if (isFixed) {
          newValues[selectedModifier] = `${newWidthOrHeightValue}px`;
        } else {
          if (isFixedOrAbsolutePosition && value === "fill") {
            newValues[selectedModifier] = "100%";
          } else {
            newValues[selectedModifier] =
              styleAttributeToEditorData[selectedModifier].defaultValue;
          }
          newValues.alignSelf = value === "fill" ? "stretch" : "auto";
        }
      }

      // Note (Evan, 2024-07-29): If there's a __flexShrink set (only from
      // Figma as of now), delete it.
      if (flexShrink) {
        applyComponentAction({
          type: "applyCompositeAction",
          value: [
            {
              type: "setStyles",
              value: newValues,
            },
            {
              type: "deleteStyle",
              style: "__flexShrink",
            },
          ],
        });
      } else {
        applyComponentAction({
          type: "setStyles",
          value: newValues,
        });
      }
    },
    [applyComponentAction, store],
  );

  return onChange;
};

export default useGetFlexboxWidthOrHeightOnChange;
