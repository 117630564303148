import { publisherApi } from "@editor/reducers/api-reducer";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { useDispatch } from "react-redux";

const useInitializeSubscriptionUpdateMutation = () => {
  const dispatch = useDispatch();
  const {
    mutateAsync: initializeSubscriptionUpdate,
    isPending,
    data,
  } = trpc.subscriptions.initializeSubscriptionUpdate.useMutation({
    onSuccess: (data) => {
      void trpcUtils.workspace.getUserWorkspacesList.invalidate();
      void trpcUtils.subscriptions.getActiveSubscriptionByProject.invalidate();
      void trpcUtils.subscriptions.getActiveSubscriptionByWorkspace.invalidate();
      dispatch(publisherApi.util.invalidateTags(["workspaces"]));
      if (
        window &&
        data.success &&
        data.confirmationRequired &&
        data.confirmationUrl
      ) {
        window.location.href = data.confirmationUrl;
      }
    },
  });

  return {
    initializeSubscriptionUpdate,
    isLoading: isPending,
    data,
  };
};

export default useInitializeSubscriptionUpdateMutation;
