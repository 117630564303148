import * as React from "react";

import {
  createRuntimeActiveStateStore,
  RuntimeActiveStateContext,
} from "./context";

/**
 * Provider which stores the store containing the states that are active -
 * anything inside of this can access the store via these hooks
 */
export const RuntimeActiveStateContextProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const [store] = React.useState(() => {
    return createRuntimeActiveStateStore();
  });

  return (
    <RuntimeActiveStateContext.Provider value={store}>
      {children}
    </RuntimeActiveStateContext.Provider>
  );
};
