import type { CustomPropsRecord } from "schemas/component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

import { convertToLegacyProps } from "../../../shared/utils/renderComponents";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _embedCode: {
      name: "Klaviyo Embed Code",
      type: "string",
      defaultValue: null,
      description:
        "Embed code copied from Klaviyo. Find this under Edit Form in the Behaviors tab.",
    },
    _loadIndicatorImage: {
      name: "Loading Image",
      type: "image",
      defaultValue: {},
      description:
        "If set, this image will be shown while the Klaviyo Embed Form loads.",
    },
  };
}

export const styleElements = {
  root: {},
} as const satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
