import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const productPageUpsell: ComponentTemplate = {
  id: "686a5bdf-5c50-4b8f-bca3-c5350d8c770e",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Rebuy/Rebuy Product Page Upsell",
  leftBarCategory: { main: "pageIntegrations", subCategory: "rebuy" },
  preview: "rebuy/product-page-upsell.svg",
  transforms: [],
  template: {
    id: "$uid",
    type: "rebuyWidget",
    props: {
      style: {
        alignSelf: "stretch",
        flexGrow: 1,
      },
    },
  },
};
