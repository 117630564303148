import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

export const customProperties = {
  width: "--rwi",
  height: "--rhe",
};

export const styleElements = {
  root: {
    defaultStyles: {
      overflow: "hidden",
      width: `var(${customProperties.width}, auto)`,
      height: `var(${customProperties.height}, auto)`,
    },
  },
} satisfies StyleElements;

const config = {
  renderData: {
    customProps: [
      {
        id: "_embedHtml",
        type: "string",
        name: "Vimeo Embed Code",
        defaultValue: null,
        description:
          "Embed code copied from Vimeo. Find from the video's Share button, then copy the text under Embed.",
      },
      {
        id: "_hideControls",
        type: "boolean",
        name: "Hide Controls",
        defaultValue: false,
        description:
          "If enabled, no play/pause or fullscreen controls will be shown on the video.",
      },
    ],
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    allowsLayoutModification: false,
    newInstancesUseNumbering: true,
    canvasIndicatorDragDirections: [],
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
