import type { ButtonSharedProps } from "@replo/design-system/components/button";
import type { ButtonSize } from "@replo/design-system/components/button/button-shared";

import * as React from "react";

import { useExperimentApi } from "@components/projectDashboard/experiments/common";
import {
  errorToast,
  successToast,
} from "@editor/components/common/designSystem/Toast";
import { useSubscriptionTier } from "@editor/hooks/subscription";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { routes } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";

import { buildCreateExperimentArgs } from "@/features/experiments/utils";
import Button from "@replo/design-system/components/button";
import { skipToken } from "@tanstack/react-query";
import { generatePath, useNavigate } from "react-router-dom";

type CreateExperimentButtonProps = {
  size?: ButtonSize;
};
export const CreateExperimentButton: React.FC<CreateExperimentButtonProps> = ({
  size = "base",
}) => {
  const commonProps: ButtonSharedProps = {
    size: "base",
    variant: "primary",
    textClassNames: "font-medium",
    className: "bg-blue-600 text-white py-2 px-2.5 transition-colors",
  };

  const workspaceId = useCurrentWorkspaceId() ?? undefined;

  const {
    list: { data },
    create: { mutateAsync: create, isPending: isCreatingExperiment },
  } = useExperimentApi({ workspaceId, projectId: null });

  const logEvent = useLogAnalytics();
  const navigate = useNavigate();
  const subscriptionTier = useSubscriptionTier();

  const shopifyUrls = trpc.workspace.getShopifyUrls.useQuery(
    workspaceId ?? skipToken,
  );
  const firstShopifyUrl = shopifyUrls.data?.shopifyUrls[0] ?? "example.com";

  const handleCreateNewExperiment = async () => {
    const experiments = data?.experiments;

    if (!workspaceId || !experiments) {
      errorToast("Failed Creating Experiment", "");
    } else {
      const experimentData = buildCreateExperimentArgs(
        workspaceId,
        experiments,
        firstShopifyUrl,
      );
      await create(experimentData);
      logEvent("experiment.create", { billingPlanTier: subscriptionTier });
      successToast("Experiment Created", "");
      navigate(
        generatePath(routes.workspace.experiments.details, {
          workspaceId,
          experimentId: experimentData.id,
        }),
      );
    }
  };

  return (
    <Button
      {...commonProps}
      size={size}
      onClick={() => void handleCreateNewExperiment()}
      isLoading={isCreatingExperiment}
    >
      Create New Experiment
    </Button>
  );
};
