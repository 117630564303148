import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { setDraggingCursor } from "@reducers/drag-and-drop-reducer";

export default function useDraggingCursor() {
  const draggingCursor = useEditorSelector(
    (state) => state.dragAndDrop.draggingCursor,
  );
  const dispatch = useEditorDispatch();

  return {
    draggingCursor,
    setDraggingCursor: (draggingCursor: string | null) =>
      dispatch(setDraggingCursor(draggingCursor)),
  };
}
