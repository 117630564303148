import * as React from "react";

/**
 * Hook for disabling the pinch to zoom in the Editor
 */
export default function usePreventZoom() {
  const scrollRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function handleScroll(event: WheelEvent) {
      if (event.ctrlKey == true || event.metaKey) {
        event.preventDefault();
      }
    }

    const scrollEl = (scrollRef.current ?? window) as HTMLElement;
    scrollEl.addEventListener("wheel", handleScroll);
    return () => {
      scrollEl.removeEventListener("wheel", handleScroll);
    };
  }, []);

  return scrollRef;
}
