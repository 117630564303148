import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const carouselPanels: ComponentTemplate = {
  id: "dac3e1c4-95c0-4d30-9976-8634f0419086",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Carousel/Carousel Panels Count",
  leftBarCategory: { main: "interactive", subCategory: "carousel" },
  preview: "carousel/carousel-panels-count.svg",
  template: {
    id: "ce83316a-6fe9-46ae-812c-526c48442241",
    name: "Panels",
    type: "carouselPanelsCount",
    props: {
      style: {
        display: "flex",
        maxWidth: "100%",
        alignSelf: "stretch",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        __flexGap: "4px",
      },
    },
    children: [
      {
        id: "6d022f6d-a814-499a-bd2a-2a6f28651abe",
        type: "text",
        props: {
          text: "{{attributes._currentPanel}}",
          style: {
            fontSize: "24px",
            lineHeight: "26px",
            fontWeight: "600",
          },
        },
        name: "Current Panel",
      },
      {
        id: "c30f5f25-c06a-4be2-9358-d2ddfc59205f",
        type: "text",
        props: {
          text: "<p>/</p>",
          style: {
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: "600",
          },
        },
      },
      {
        id: "f3688dc7-a1b6-4715-856b-65abd3f6ad86",
        type: "text",
        props: {
          text: "{{attributes._carouselPanels}}",
          style: {
            fontSize: "24px",
            lineHeight: "26px",
            fontWeight: "600",
          },
        },
        name: "Carousel Panels",
      },
    ],
  },
};
