import type {
  FlowStepConfigPropsValueOf,
  FlowStepOptionSchema,
} from "schemas/flow";
import type { FlowStepConfig } from "schemas/generated/flow";

import { hasOwnProperty, isNotNullish } from "replo-utils/lib/misc";

/**
  NOTE (Sebas, 2024-02-09): 
  This function processes the "other" option in a flow step's options. It returns an array of strings
  that includes the "other" value if it was selected, and removes any other "other" values if
  they were selected previously. This can happen when the user selects "other", clicks next, and
  then goes back and wants to change the "other" value.
 */
export function processOtherOptions(
  heardFrom: string[],
  heardFromOther: string | undefined,
  stepProps: FlowStepConfigPropsValueOf<FlowStepConfig["type"]>,
) {
  return heardFrom.reduce((acc, item) => {
    const valueToAdd = item === "other" ? heardFromOther : item;
    const stepPropsOptions = hasOwnProperty(stepProps, "options")
      ? (stepProps.options as FlowStepOptionSchema[])
      : [];
    if (
      isNotNullish(valueToAdd) &&
      (item === "other" ||
        (hasOwnProperty(stepProps, "options") &&
          stepPropsOptions.some((option) => option.value === item)))
    ) {
      acc.push(valueToAdd);
    }
    return acc;
  }, [] as string[]);
}
