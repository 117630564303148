import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";

export const TEXT_STYLE_DEFAULTS: Record<string, SavedStyleTextAttributes> = {
  h1: {
    type: "text",
    htmlTag: "h1",
    fontSize: "60px",
    lineHeight: "72px",
    fontWeight: "700",
    color: "#000000",
  },
  h2: {
    type: "text",
    htmlTag: "h2",
    fontSize: "30px",
    lineHeight: "36px",
    fontWeight: "600",
    color: "#000000",
  },
  h3: {
    type: "text",
    htmlTag: "h3",
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: "600",
    color: "#000000",
  },
  h4: {
    type: "text",
    htmlTag: "h4",
    fontSize: "20px",
    lineHeight: "26px",
    fontWeight: "500",
    color: "#000000",
  },
  h5: {
    type: "text",
    htmlTag: "h5",
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: "500",
    color: "#000000",
  },
  h6: {
    type: "text",
    htmlTag: "h6",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "400",
    color: "#000000",
  },
  p: {
    type: "text",
    htmlTag: "p",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
    color: "#000000",
  },
};

export const TEXT_MENU_ITEMS = [
  { id: "h1", title: "Heading 1" },
  { id: "h2", title: "Heading 2" },
  { id: "h3", title: "Heading 3" },
  { id: "h4", title: "Heading 4" },
  { id: "h5", title: "Heading 5" },
  { id: "h6", title: "Heading 6" },
  { id: "p", title: "Paragraph" },
];
