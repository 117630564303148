import type { ProjectMembership } from "schemas/generated/projectMembership";

import * as React from "react";

import ErrorMessage from "@editor/components/account/Dashboard/ErrorMessage";
import Avatar from "@editor/components/common/designSystem/Avatar";
import InputComponent from "@editor/components/common/designSystem/Input";
import Modal from "@editor/components/common/designSystem/Modal";
import Popover from "@editor/components/common/designSystem/Popover";
import Separator from "@editor/components/common/designSystem/Separator";
import { errorToast } from "@editor/components/common/designSystem/Toast";
import { InvitationModalSkeleton } from "@editor/components/dashboard/SkeletonLoaders";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useHasWorkspaceMembership } from "@editor/hooks/useHasWorkspaceMembership";
import { useIsWorkspaceOwner } from "@editor/hooks/useIsWorkspaceOwner";
import { useModal } from "@editor/hooks/useModal";
import { trpc } from "@editor/utils/trpc";

import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@replo/design-system/components/button";
import IconButton from "@replo/design-system/components/button/IconButton";
import { skipToken } from "@tanstack/react-query";
import ContentLoader from "react-content-loader";
import { Controller, useForm } from "react-hook-form";
import { BsChevronRight, BsPeople, BsSearch, BsX } from "react-icons/bs";
import { Link } from "react-router-dom";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";
import { pluralize } from "replo-utils/lib/pluralize";
import { z } from "zod";

/**
 * Emails which we'll reject instead of adding them to the project if the user types them in
 */
const blockedEmailConfig: Record<string, { title: string; subtitle: string }> =
  {
    "support@replo.app": {
      title: "support@replo.app doesn't need to be added as a collaborator.",
      subtitle:
        "Don't worry, Replo Support will be able to access this project to help.",
    },
  };

const AddCollaboratorSchema = z.object({
  email: z
    .string()
    .email("Please enter a valid email address")
    .min(1, "Please enter a valid email address"),
});

export const ProjectMembershipModal = () => {
  const modal = useModal();
  const {
    handleSubmit,
    formState: { errors, isLoading: isLoadingForm },
    reset,
    control,
  } = useForm<{ email: string }>({
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(AddCollaboratorSchema),
  });
  const projectId = useCurrentProjectId();
  const { isAuthenticated } = useCurrentUser();
  const trpcUtils = trpc.useUtils();

  const { data, isLoading } = trpc.project.membership.list.useQuery(
    projectId && isAuthenticated ? { projectId } : skipToken,
  );

  const workspaceId = useCurrentWorkspaceId();
  const isWorkspaceOwner = useIsWorkspaceOwner(workspaceId);

  const { data: workspaceData, isLoading: isLoadingWorkspace } =
    trpc.workspace.getById.useQuery(
      workspaceId ? { id: workspaceId } : skipToken,
    );
  const { workspaceMembershipsIsLoading, workspaceMembershipsData } =
    useHasWorkspaceMembership();

  const workspaceName = workspaceData?.workspace.name;

  const { mutateAsync: _createProjectMembership } =
    trpc.project.membership.create.useMutation({
      onSuccess: async ({ projectMembership }) => {
        void trpcUtils.workspace.getWorkspaceAndProjectMembers.invalidate();
        void trpcUtils.project.membership.list.invalidate({ projectId });
        if (isWorkspaceOwner && workspaceId && workspaceName) {
          modal.closeModal({ type: "projectMembershipModal" });
          modal.openModal({
            type: "workspaceMembershipModal",
            props: {
              workspaceId,
              workspaceName,
              email: projectMembership.project.user.email,
            },
          });
        }
      },
    });

  const createProjectMembership = async ({ email }: { email: string }) => {
    const values = {
      email,
      projectId: projectId ?? "",
    };
    const blockedConfig = getFromRecordOrNull(blockedEmailConfig, values.email);
    if (blockedConfig) {
      errorToast(blockedConfig.title, blockedConfig.subtitle);
      return;
    }
    await _createProjectMembership(values);
    reset();
  };
  const areProjectOrWorkspaceMembershipsLoading =
    isLoading || workspaceMembershipsIsLoading || isLoadingWorkspace;
  const shouldShowWorkspaceMembershipsWarn =
    !areProjectOrWorkspaceMembershipsLoading && workspaceId;

  const projectMembershipsThatAreNotWorkspaceMembers =
    data?.projectMemberships.filter(
      (projectMembership) =>
        !workspaceMembershipsData?.workspaceMemberships.some(
          (workspaceMembership) =>
            workspaceMembership.user.id === projectMembership.project.user.id,
        ),
    );

  return (
    <Modal
      style={{ maxWidth: "384px", width: "384px" }}
      className="z-max p-6"
      onRequestClose={() =>
        modal.closeModal({ type: "projectMembershipModal" })
      }
      isOpen={true}
    >
      <div className="flex w-full flex-col gap-y-4 text-default">
        <div className="font-semibold text-lg">Invite to Project</div>
        <form
          onSubmit={(data) => {
            void handleSubmit(createProjectMembership)(data);
          }}
          className="flex flex-col gap-1"
        >
          <div className="flex w-full flex-row gap-x-2">
            <Controller
              render={({ field }) => (
                <InputComponent
                  {...field}
                  autoComplete="off"
                  placeholder="Collaborator Email"
                  startEnhancer={() => (
                    <BsSearch size={8} className="text-slate-400" />
                  )}
                />
              )}
              control={control}
              name="email"
            />
            <Button
              variant="primary"
              type="submit"
              className="text-xs"
              size="sm"
              isDisabled={isLoading || isLoadingForm}
            >
              Invite
            </Button>
          </div>
          <ErrorMessage error={errors["email"]?.message} />
        </form>
        {areProjectOrWorkspaceMembershipsLoading && <InvitationModalSkeleton />}
        {shouldShowWorkspaceMembershipsWarn && (
          <Popover>
            <Popover.Trigger className="flex gap-2 text-xs items-center text-left ">
              <BsPeople size={14} />
              <div className="flex-grow">
                <div>Everyone in {workspaceName} has edit access</div>
                <div className="font-bold">
                  {workspaceData?.workspace.workspaceMemberships.length}{" "}
                  {pluralize({
                    singular: "person",
                    plural: "people",
                    count:
                      workspaceData?.workspace.workspaceMemberships.length ?? 0,
                  })}
                </div>
              </div>
              <BsChevronRight size={16} />
            </Popover.Trigger>
            <Popover.Content
              shouldPreventDefaultOnInteractOutside={false}
              title={`${workspaceName} Members`}
              titleClassnames="font-semibold text-sm"
              side="right"
              sideOffset={30}
              className="w-[320px] p-4"
            >
              <div className="text-sm font-normal w-full">
                <div className="text-muted mb-3 text-xs">
                  These people have access to this project because they are
                  members of the workspace {workspaceName} which owns this
                  project.
                </div>
                <Link
                  to={`/workspace/${workspaceId}/members`}
                  className="text-blue-600 text-xs font-medium"
                  onClick={() => {
                    modal.closeModal({ type: "projectMembershipModal" });
                  }}
                >
                  View {workspaceName} Membership Page
                </Link>
                <div className="flex gap-3 flex-col mt-3 max-h-72 overflow-y-scroll">
                  {workspaceData?.workspace.workspaceMemberships.map(
                    (membership) => (
                      <UserRow key={membership.id} user={membership.user} />
                    ),
                  )}
                </div>
              </div>
            </Popover.Content>
          </Popover>
        )}
        <Separator />
        <div className="text-sm">More People with Access</div>
        <div className="flex flex-col max-h-72 gap-y-4 overflow-y-scroll">
          {isLoading && <ProjectMembershipRowSkeletonLoader />}
          {!isLoading &&
            projectMembershipsThatAreNotWorkspaceMembers?.map(
              (project: ProjectMembership) => {
                return (
                  <ProjectMembershipRow
                    projectMembership={project}
                    key={project.id}
                  />
                );
              },
            )}
          {((!isLoading && !projectMembershipsThatAreNotWorkspaceMembers) ||
            projectMembershipsThatAreNotWorkspaceMembers?.length === 0) && (
            <p className="text-xs text-gray-300">
              No members have single-project access.
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};
const UserRow = ({
  user,
}: {
  user: {
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  };
}) => {
  const { firstName, lastName, email } = user;
  const userName = (firstName || lastName) && `${firstName} ${lastName}`;

  return (
    <div className="flex gap-2 items-center">
      <Avatar name={userName || undefined} className="h-7 w-7" />
      <div className="flex flex-col items-start text-sm">
        <div className="text-xs text-default">{userName}</div>
        <div className="text-xs text-slate-400">{email}</div>
      </div>
    </div>
  );
};

type ProjectMembershipRowProps = {
  projectMembership: ProjectMembership;
};

const ProjectMembershipRow = ({
  projectMembership,
}: ProjectMembershipRowProps) => {
  const projectId = projectMembership.project.id;
  const trpcUtils = trpc.useUtils();
  const { mutateAsync: deleteProjectMembership } =
    trpc.project.membership.delete.useMutation({
      onSuccess: async () => {
        void trpcUtils.project.membership.list.invalidate({ projectId });
      },
    });
  const { user: currentUser } = useCurrentUser();

  const { user } = projectMembership.project;
  const removeLabel = `Remove ${
    user.name && user.name.length > 0 ? user.name : user.email
  }`;

  const isCurrentUser = currentUser?.id === user.id;

  return (
    <div className="align-center flex flex-row items-center justify-center">
      <div className="flex grow flex-row items-center gap-x-3">
        <Avatar name={user.name} className="h-7 w-7" />
        <div className="flex flex-col items-start text-sm">
          <div className="text-xs text-default">{user.name}</div>
          <div className="text-xs text-slate-400">{user.email}</div>
        </div>
      </div>
      {!isCurrentUser && (
        <IconButton
          className="col-span-1 justify-self-end"
          icon={<BsX size={16} />}
          tooltipText={removeLabel}
          variant="tertiary"
          aria-label={removeLabel}
          onClick={() => {
            void deleteProjectMembership({
              id: projectMembership.id,
              projectId: projectMembership.project.id,
            });
          }}
        />
      )}
    </div>
  );
};

const ProjectMembershipRowSkeletonLoader = () => {
  return (
    <>
      {Array.from({ length: 4 }).map((_, i) => (
        <ContentLoader
          key={i}
          speed={2}
          width={302}
          height={40}
          viewBox="0 0 302 40"
          backgroundColor="#f1f5f9"
          foregroundColor="#e2e8f0"
        >
          <circle cx="20" cy="20" r="18" />
          <rect x="48" y="4" rx="4" ry="4" width="170" height="12" />
          <rect x="48" y="22" rx="4" ry="4" width="170" height="12" />
        </ContentLoader>
      ))}
    </>
  );
};
