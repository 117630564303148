import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { SharedReviewsIoReviews } from "../SharedReviewsIoReviews";

const ReviewsIoProductRatingSummary: React.FC<RenderComponentProps> = (
  props,
) => {
  const liquidSourceWidget = `<div class="ruk_rating_snippet" data-sku="{{ product.handle }};{{ product.variants | map: 'sku' | join: ';' }};{{ product.variants | map: 'id' | join: ';' }}"></div>`;

  return (
    <SharedReviewsIoReviews
      attributes={props.componentAttributes}
      component={props.component}
      context={props.context}
      liquidSource={liquidSourceWidget}
      reviewsComponentType="Product Rating"
    />
  );
};

export default ReviewsIoProductRatingSummary;
