import * as React from "react";

// NOTE (Kurt, 2024-08-20): we use React.useId() to generate a stable id for the component this
// hook is called in. The primary purpose of this id is to be used as a suffix to uniquely identify
// liquid variables to prevent overwrites as liquid variables are global and we don't want them to
// have the same name.
export function useConsistentLiquidId() {
  // NOTE (Gabe 2023-11-09): useId returns an id of the form :r20v: and we
  // need to remove the colons
  const randomId = React.useId().replaceAll(":", "");
  return randomId;
}
