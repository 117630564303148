import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { useWorkspaceDashboardContext } from "@editor/contexts/WorkspaceDashboardContext";

// NOTE (Matt 2024-09-10): The workspaceId in the WorkspaceDashboardContext corresponds to the workspaceId
// of the current workspace in the workspace dashboard, while the project's ownerWorkspaceId corresponds to
// the workspace that the project belongs to. `project` should only exist when in the editor, which is
// when `workspaceId` would not be the correct workspaceId for the project.
export default function useCurrentWorkspaceId() {
  const { workspaceId } = useWorkspaceDashboardContext();
  const { project } = useCurrentProjectContext();
  return project?.ownerWorkspaceId ?? workspaceId;
}
