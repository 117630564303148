import * as React from "react";

import DashboardLayout from "@editor/components/layouts/DashboardLayout";
import { initWorkspaceBasedAnalytics } from "@editor/infra/analytics";

import { Outlet } from "react-router-dom";

import { useUserWorkspaceData } from "./useUserWorkspaceData";

const WorkspaceDashboard: React.FC = () => {
  const { currentWorkspace } = useUserWorkspaceData();

  // NOTE Ben 2024-08-16: We initialize workspace analytics here because all
  // workspace - based components (tabs for analytics + settings, etc) are
  // used as interior components.
  React.useEffect(() => {
    if (currentWorkspace) {
      initWorkspaceBasedAnalytics(currentWorkspace);
    }
  }, [currentWorkspace]);

  return (
    <DashboardLayout>
      <div className="bg-white no-scrollbar col-span-9 mt-4 flex flex-grow flex-col items-start gap-y-2 overflow-scroll">
        <Outlet />
      </div>
    </DashboardLayout>
  );
};

export default WorkspaceDashboard;
