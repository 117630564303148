import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { useInterval } from "replo-utils/react/use-interval";

import {
  GlobalWindowContext,
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { useProductFromProps } from "../../hooks/useProductFromProps";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

function InfiniteOptionsWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;

  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const globalWindow = useRuntimeContext(GlobalWindowContext);

  const [scriptLoading, setScriptLoading] = React.useState(true);
  const product = useProductFromProps(component.props, context);

  React.useEffect(() => {
    if (
      globalWindow &&
      product &&
      !globalWindow.Shoppad?.apps?.infiniteoptions?.themeAppExtensionActive
    ) {
      globalWindow.productJSON = product;
      if (!globalWindow.Shoppad) {
        globalWindow.Shoppad = {
          apps: {},
        };
      }
      if (!globalWindow.Shoppad.apps.infiniteoptions) {
        globalWindow.Shoppad.apps.infiniteoptions = {};
      }
      globalWindow.Shoppad.apps.infiniteoptions.themeAppExtensionActive = true;
      globalWindow.Shoppad.apps.infiniteoptions.activeProductId = product.id;
      globalWindow.Shoppad.apps.infiniteoptions.themeAppExtensionId = `product-form-${product.id}`;
    }
  }, [product, globalWindow]);

  useInterval(
    () => {
      if (
        product &&
        globalWindow?.Shoppad?.apps?.infiniteoptions?.init &&
        globalWindow?.Shoppad?.apps?.infiniteoptions?._fieldsReady === false
      ) {
        globalWindow.Shoppad.apps.infiniteoptions.init(product.handle);
        setScriptLoading(false);
      }
    },
    scriptLoading ? 150 : null,
  );

  const liquidSource = '<div id="infiniteoptions-container"></div>';
  return (
    <SharedShopifyLiquid
      forceEditorPlaceholder={isEditorApp}
      liquidSource={liquidSource}
      componentId={component.id}
      componentAttributes={componentAttributes}
      placeholder="Infinite Options Widget will appear here"
      repeatedIndexPath={context.repeatedIndexPath}
      isLiquidSupported
    />
  );
}

export default InfiniteOptionsWidget;
