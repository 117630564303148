import * as React from "react";

import SvgEmpyProjects from "@svg/empty-projects";

import Button from "@replo/design-system/components/button";

export function EmptyProjectMembership({
  shouldShowCreateProjectButton = true,
}: {
  shouldShowCreateProjectButton?: boolean;
}) {
  return (
    <div className="max-w-80 mx-auto mt-20 text-default flex flex-col items-center gap-8">
      <SvgEmpyProjects />
      <h3 className="font-medium text-xl">
        {shouldShowCreateProjectButton
          ? "Start by adding a new project"
          : "It looks like you don’t have any projects. Please connect a Shopify Store."}
      </h3>
      {shouldShowCreateProjectButton && (
        <Button size="base" variant="primary" href="../project/new">
          New Project
        </Button>
      )}
    </div>
  );
}
