import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { ReploElement } from "schemas/generated/element";

import * as React from "react";

import { getAlchemyEditorWindow } from "replo-runtime/shared/Window";

export const useElementsMethods = (
  canvas: EditorCanvas | null,
  reploElementId: string,
  {
    setRuntimeContexts,
    setReploElement,
    isEditor,
  }: {
    setRuntimeContexts: (contexts: React.SetStateAction<any>) => void;
    setReploElement: (element: React.SetStateAction<ReploElement>) => void;
    isEditor: boolean;
  },
) => {
  React.useEffect(() => {
    if (!isEditor) {
      return;
    }
    const editorWindow = getAlchemyEditorWindow();
    if (
      editorWindow &&
      !editorWindow.alchemyEditor?.elementIdToElementMethods
    ) {
      editorWindow.alchemyEditor.elementIdToElementMethods = {};
    }
    const elementIdToElementMethods =
      editorWindow?.alchemyEditor?.elementIdToElementMethods;

    if (elementIdToElementMethods && canvas) {
      elementIdToElementMethods[reploElementId] = {
        updateElement: {
          ...elementIdToElementMethods[reploElementId]?.updateElement,
          [canvas]: (payload: any) => {
            setRuntimeContexts(payload.contexts);
            setReploElement((prevElement) => {
              return {
                ...prevElement,
                ...payload.element,
              };
            });
          },
        },
      };
    } else {
      // eslint-disable-next-line no-console
      console.error(
        "[Replo] Global context not loaded, there may be issues editing this page",
      );
    }
  }, [canvas, isEditor, reploElementId, setReploElement, setRuntimeContexts]);
};
