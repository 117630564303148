import * as React from "react";

import { formatCurrencyWithShopifyMoneyFormat } from "../liquid";
import { ShopifyStoreContext, useRuntimeContext } from "../runtime-context";

export function useShopifyMoneyFormat() {
  const { activeCurrency, activeLanguage, moneyFormat } =
    useRuntimeContext(ShopifyStoreContext);

  return {
    moneyFormat,
    formatCurrency: React.useCallback<FormatCurrencyFunction>(
      (value, options) => {
        return formatCurrencyWithShopifyMoneyFormat(value, {
          ...options,
          moneyFormat,
          currencyCode: activeCurrency,
          language: activeLanguage,
        });
      },
      [moneyFormat, activeCurrency, activeLanguage],
    ),
  };
}

export type FormatCurrencyFunction = (
  value: string | number,
  options: {
    currencyCode: string;
    hideSymbol?: boolean;
    showCents?: boolean;
    zeroString?: string;
  },
) => string;
