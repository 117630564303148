import type { Middleware } from "redux";

const redirectMiddleware: Middleware = () => (next) => (action) => {
  if (!(action && action.type && action.type.endsWith("_SUCCESS"))) {
    return next(action);
  }

  const redirectTo = action?.payload?.redirectTo;
  if (!redirectTo) {
    return next(action);
  }

  window.location.replace(redirectTo);
  return undefined;
};

export default redirectMiddleware;
