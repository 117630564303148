import type { SyncRuntimeStatePayload } from "replo-runtime/shared/types";

import * as React from "react";

import {
  selectSyncRuntimeState,
  setSyncRuntimeState,
} from "@editor/reducers/paint-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";

export function useSyncRuntimeValue(key: string) {
  const { syncRuntimeState } = useSyncRuntime();

  return syncRuntimeState[key];
}

export function useSyncRuntime() {
  const syncRuntimeState = useEditorSelector(selectSyncRuntimeState);
  const dispatch = useEditorDispatch();

  return {
    syncRuntimeState,
    setSyncRuntimeState: React.useCallback(
      (setSyncRuntimeStatePayload: SyncRuntimeStatePayload) =>
        dispatch(setSyncRuntimeState(setSyncRuntimeStatePayload)),
      [dispatch],
    ),
  };
}
