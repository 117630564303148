import type { CustomPropsRecord } from "schemas/component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _rating: {
      name: "Rating",
      type: "ratingBalancer",
      description:
        "Rating value out of the total number of stars (e.g. for 4/5, this is 4)",
      defaultValue: 5,
    },
    _totalStars: {
      name: "Total Stars",
      type: "integer",
      description: "Total number of possible stars (e.g. for 4/5, this is 5)",
      defaultValue: 5,
    },
    _starColor: {
      name: "Color",
      type: "color",
      description: "Color of the Stars",
      defaultValue: "#000000",
    },
    _size: {
      name: "Size",
      type: "pixels",
      description: "Size of each star",
      defaultValue: "16px",
    },
  };
}

export const styleElements = {
  root: {},
  icon: {
    overrideStyles({ component }) {
      const size = component.props._size;
      const starColor = component.props._starColor;
      return {
        width: size,
        height: size,
        fill: starColor,
      };
    },
  },
} as const satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: true,
    allowsLayoutModification: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: true,
    customProps: Object.entries(getConfigurableProps()).map(([key, value]) => {
      return {
        ...value,
        id: key,
        name: value.name,
      };
    }),
    canvasIndicatorDragDirections: [],
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
