import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { createArrayOfLength } from "replo-utils/lib/array";

import { useComponentClassNames } from "../../../shared/utils/renderComponents";
import { ReploComponent } from "../ReploComponent";

const Marquee: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
  context,
}) => {
  const repetition = component.props._internalUseRepetition ?? true;
  const { width: innerWidth, ref: innerDivRef } = useMeasureScrollWidth();
  const classNameMap = useComponentClassNames("marquee", component, context);
  const template = component.children?.length && component.children[0];
  if (!template) {
    return null;
  }
  // Note (Noah, 2021-09-09): Reset whitespace to normal here, otherwise
  // it cascades to text components inside the marquee and they don't line wrap
  // Note (Ovishek, 2022-12-16): We are not merging here b/c of performance issues
  // merge takes a little bit longer time than copy destructuring
  const templateWithStyles = {
    ...template,
    props: {
      ...template.props,
      style: {
        ...template.props.style,
        flexShrink: 0,
        whiteSpace: "normal",
      },
    },
  };

  const numberOfRepetitions = repetition ? 20 : 2;
  const extraProperties = {
    "--replo-marquee-repetitions": String(numberOfRepetitions),
    // Note (Chance 2023-08-03, USE-318) We can't always rely on 100% width because there
    // seems to be a bug in marquees with images where the browser's 100%
    // calculation is off after images load and content in the marquee shifts as
    // a result. Instead we set a CSS custom property to the width of the
    // marquee's parent element, which is the width of the marquee's container,
    // which is updated as needed with a ResizeObserver.
    // Credit to Evan for the solution!
    // https://github.com/replohq/andytown/pull/5108
    "--replo-marquee-width": innerWidth != null ? `${innerWidth}px` : "100%",
  };

  const { style: attributesStyle, ...domAttributes } = componentAttributes;

  return (
    <div
      {...domAttributes}
      data-replo-marquee-root
      style={{ ...attributesStyle, ...extraProperties }}
    >
      <div
        className={classNameMap?.inner}
        data-replo-marquee-track
        ref={innerDivRef}
      >
        {createArrayOfLength(numberOfRepetitions).map((_, index) => {
          return (
            <ReploComponent
              context={context}
              key={index}
              component={templateWithStyles}
              repeatedIndexPath={`${context.repeatedIndexPath}.${index}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Marquee;

/**
 * Adapted and simplified from react-use-measure, this hook provides a ref and
 * measures the scrollWidth of the component that the ref is assigned to.
 */
function useMeasureScrollWidth() {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = React.useState<number | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: ref extra dep
  React.useEffect(() => {
    // NOTE (Evan, 8/2/23) This defines the fallback behavior for old browsers
    // that don't support ResizeObserver
    if (typeof ResizeObserver === "undefined") {
      return;
    }

    function updateWidth(entries: ResizeObserverEntry[]) {
      const node = entries[0]?.target;
      if (node) {
        setWidth(node.scrollWidth);
      }
    }

    const resizeObserver = new ResizeObserver(updateWidth);
    const node = ref.current;
    if (node) {
      resizeObserver.observe(node);
      return () => {
        resizeObserver.unobserve(node);
      };
    }
  }, [ref]);

  return { ref, width };
}
