import * as React from "react";

export const RenderComponentPlaceholder: React.FC<{
  title: string | null;
  icon?: () => React.ReactNode;
  style?: React.CSSProperties;
  isLoading?: boolean;
}> = (props) => {
  return (
    <div
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "20px",
        paddingBottom: "20px",
        borderWidth: 2,
        borderColor: "#5393E9",
        borderStyle: "dashed",
        minHeight: "200px",
        minWidth: "200px",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Helvetica, sans-serif",
        fontSize: "15px",
        whiteSpace: "normal",
        color: "black",
        backgroundColor: "white",
        ...props.style,
      }}
    >
      <>
        {props.icon && props.icon()}
        {props.title && !props.isLoading && (
          <p
            style={{
              textAlign: "center",
              width: "100%",
              padding: 0,
              margin: 0,
            }}
          >
            {props.title}
          </p>
        )}
        {/* TODO (Reinaldo, 2022-04-21): Figure out why the <Spinner /> component doesn't spin here
      and use it instead of the loading text */}
        {props.isLoading && (
          <p
            style={{
              textAlign: "center",
              width: "100%",
              padding: 0,
              margin: 0,
            }}
          >
            Loading...
          </p>
        )}
      </>
    </div>
  );
};

export default RenderComponentPlaceholder;
