import type { FlowStepConfigPropsValueOf } from "schemas/flow";

import * as React from "react";

import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import { useGetCurrentStepResultsData } from "@components/flows/hooks/useGetCurrentStepResultsData";
import StepSubtitle from "@components/flows/onboardingSteps/components/StepSubtitle";
import StepTitle from "@components/flows/onboardingSteps/components/StepTitle";
import ErrorMessage from "@editor/components/account/Dashboard/ErrorMessage";
import Input from "@editor/components/common/designSystem/Input";
import SelectableButtons from "@editor/components/common/designSystem/SelectableButtons";
import useIdentifyOtherValue from "@editor/components/flows/hooks/useIdentifyOtherValue";
import { useRouterFlowCallbacks } from "@editor/components/flows/hooks/useRouterFlowCallbacks";
import FlowActionButtons from "@editor/components/flows/onboardingSteps/components/FlowActionButtons";
import OnboardingStepLayout, {
  OnboardingStepForm,
} from "@editor/components/flows/onboardingSteps/components/OnboardingStepsLayout";
import StepImage from "@editor/components/flows/onboardingSteps/components/StepImage";
import { processOtherOptions } from "@editor/components/flows/utils/processOtherOptions";

import { Controller, useForm, useWatch } from "react-hook-form";
import { isEmpty } from "replo-utils/lib/misc";

type FormValues = {
  heardFrom: string[];
  heardFromOther?: string;
};

const HowDidYouHearAboutUsStep: React.FC = () => {
  const { currentStep, submitStep } = useReploFlowsStepContext();
  const stepProps =
    currentStep?.props as FlowStepConfigPropsValueOf<"onboarding.user.how-did-you-hear-about-us">;
  const stepResultsData =
    useGetCurrentStepResultsData<"onboarding.user.how-did-you-hear-about-us">();

  const stepCommonOptions = stepProps.options.map((option) => option.value);
  const { otherValue: otherInitialValue, values: initialHeardFromValues } =
    useIdentifyOtherValue(stepCommonOptions, stepResultsData?.heardFrom);
  const { submitOrSkipStepCallback: submitStepCallback } =
    useRouterFlowCallbacks();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      heardFrom: initialHeardFromValues,
      heardFromOther: otherInitialValue,
    },
  });

  const onSubmit = ({ heardFrom, heardFromOther }: FormValues) => {
    if (currentStep) {
      const values = processOtherOptions(heardFrom, heardFromOther, stepProps);
      void submitStep(
        currentStep.id,
        currentStep.type,
        {
          heardFrom: values,
        },
        ({ instance, nextStep }) =>
          submitStepCallback({
            nextStep: nextStep ?? null,
            flowSlug: instance.flow.slug,
          }),
      );
    }
  };

  const heardFromValue = useWatch({ control, name: "heardFrom" });
  const heardFromOtherValue = useWatch({ control, name: "heardFromOther" });
  const isOtherSelected = heardFromValue?.includes("other");
  const heardFromOtherError = errors.heardFromOther?.message;
  const shouldDisableNextButton =
    isEmpty(heardFromValue) ||
    (isOtherSelected &&
      (!Boolean(heardFromOtherValue) || Boolean(heardFromOtherError)));

  return (
    <OnboardingStepLayout
      rightPanelContent={<StepImage src="/images/flows/about-us.png" />}
    >
      <OnboardingStepForm
        onSubmit={(data) => {
          void handleSubmit(onSubmit)(data);
        }}
      >
        <div className="flex flex-col gap-14">
          <div>
            <StepTitle>
              How did you hear about Replo?
              <span className="text-red-600">*</span>
            </StepTitle>
            <StepSubtitle>Select all that apply.</StepSubtitle>
          </div>

          <Controller
            name="heardFrom"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectableButtons
                multiSelect
                options={stepProps.options ?? []}
                value={value}
                onChange={(value) => onChange(value ?? "")}
                className="my-4"
                textClassName="font-normal"
                unselectedClassName="border border-slate-200 bg-transparent text-default hover:border-blue-600 hover:bg-blue-200 hover:text-blue-600"
                selectedClassName="border border-blue-600 bg-blue-200 text-blue-600 hover:bg-blue-300"
                inputEnhancer={
                  isOtherSelected ? (
                    <div className="flex w-full flex-col gap-2">
                      <Controller
                        name="heardFromOther"
                        control={control}
                        rules={{
                          required: "Please enter a value.",
                          pattern: {
                            value: /^[\d A-Za-z]+$/,
                            message:
                              "Please enter only alphanumeric characters and whitespaces.",
                          },
                        }}
                        render={({ field: { onChange, value, name } }) => (
                          <Input
                            type="text"
                            size="base"
                            autoFocus
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            name={name}
                            validityState={
                              Boolean(heardFromOtherError) ? "invalid" : "valid"
                            }
                            aria-invalid={
                              Boolean(heardFromOtherError) ? "true" : undefined
                            }
                            aria-describedby={
                              Boolean(heardFromOtherError)
                                ? "error-heard-from"
                                : undefined
                            }
                          />
                        )}
                      />
                      <ErrorMessage
                        id="error-heard-from"
                        error={heardFromOtherError}
                      />
                    </div>
                  ) : null
                }
              />
            )}
          />
        </div>
        <FlowActionButtons shouldDisableNextButton={shouldDisableNextButton} />
      </OnboardingStepForm>
    </OnboardingStepLayout>
  );
};

export default HowDidYouHearAboutUsStep;
