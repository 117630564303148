import type { EditorRootState } from "@editor/store";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { ComponentData } from "replo-runtime/shared/Component";
import type {
  SharedStatePayload,
  SyncRuntimeStatePayload,
} from "replo-runtime/shared/types";

import { selectComponentDataMapping } from "@reducers/core-reducer";

import { createSelector, createSlice } from "@reduxjs/toolkit";
import pickBy from "lodash-es/pickBy";
import { shallowEqual } from "react-redux";
import { isFunction } from "replo-utils/lib/type-check";

export type PaintState = {
  sharedState: Record<string, any>;
  syncRuntimeState: Record<string, any>;
};

const initialState: PaintState = {
  sharedState: {},
  syncRuntimeState: {},
};

const paintSlice = createSlice({
  name: "paint",
  initialState,
  reducers: {
    setSharedState: (state, action: PayloadAction<SharedStatePayload>) => {
      const { key, value } = action.payload;
      const currentValue = state.sharedState[key];
      const next = isFunction(value) ? value(currentValue) : value;
      if (currentValue !== next) {
        state.sharedState[key] = next;
      }
    },
    setSyncRuntimeState: (
      state,
      action: PayloadAction<SyncRuntimeStatePayload>,
    ) => {
      const { key, value } = action.payload;
      if (state.syncRuntimeState[key] !== value) {
        state.syncRuntimeState[key] = value;
      }
    },
  },
});

export const selectSharedState = (state: EditorRootState) =>
  state.paint.sharedState;
export const selectSyncRuntimeState = (state: EditorRootState) =>
  state.paint.syncRuntimeState;

// Note (Evan, 2024-10-16): i.e., the keys that could cause a given component
// to be shown/hidden by a collapsible/accordion block
const getCollapsibleRelevantSharedStateKeys = (
  componentData: ComponentData,
) => {
  const keys = [];
  const ancestorComponentData = componentData.ancestorComponentData;
  for (const [id, type] of ancestorComponentData) {
    // Note (Evan, 2024-10-16): Rather than doing the more expensive calculation of checking
    // which ancestor (accordion or collapsible) occurs first, we just return for both, since
    // having a few extraneous keys here doesn't harm us.
    if (type === "accordionBlock") {
      keys.push(`${id}.accordionOpenItems`);
    }
    if (type === "collapsibleV2") {
      keys.push(`${id}.isOpen`);
    }
  }
  return keys;
};

// Note (Evan, 2024-10-16): i.e., the shared state that could cause a given component
// to be shown/hidden by a collapsible/accordion block
export const selectCollapsibleRelevantSharedState = createSelector(
  selectSharedState,
  selectComponentDataMapping,
  (_: EditorRootState, componentId: string) => componentId,
  (sharedState, componentDataMapping, componentId) => {
    const componentData = componentDataMapping[componentId];
    const collapsibleRelevantSharedStateKeys = componentData
      ? getCollapsibleRelevantSharedStateKeys(componentData)
      : [];
    return pickBy(sharedState, (_, key) =>
      collapsibleRelevantSharedStateKeys.includes(key),
    );
  },
  // Note (Evan, 2024-10-16): pickBy will create a new object each time,
  // so this shallowEqual will prevent us from returning a new object unless
  // its contents have actually changed.
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  },
);

const { actions, reducer } = paintSlice;

export const { setSharedState, setSyncRuntimeState } = actions;
export default reducer;
