import * as React from "react";

import classNames from "classnames";

type InfoTagProps = {
  children: React.ReactNode;
  className?: string;
};

export const InfoTag = ({ children, className }: InfoTagProps) => {
  return (
    <div
      className={classNames(
        "inline-flex items-center justify-center rounded p-2 gap-2.5",
        className,
      )}
    >
      {children}
    </div>
  );
};
