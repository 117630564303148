import type { ProductRef } from "schemas/product";
import type {
  RenderComponentProps,
  ReploPriceRule,
} from "../../../shared/types";
import type { TempCart } from "../../../shared/utils/temporaryCart";
import type { ActionType } from "./config";

import * as React from "react";

import cloneDeep from "lodash-es/cloneDeep";

import { useShopifyMoneyFormat } from "../../../shared/hooks/use-shopify-money-format";
import {
  RenderEnvironmentContext,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { mergeContext } from "../../../shared/utils/context";
import { getPriceIncludingDiscounts } from "../../../shared/utils/temporaryCart";
import { getProduct } from "../../ReploProduct";
import { ReploComponent } from "../ReploComponent";

const TemporaryCart: React.FC<RenderComponentProps> = ({
  componentAttributes,
  component,
  context,
}) => {
  const { formatCurrency } = useShopifyMoneyFormat();
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const {
    fakeProducts,
    activeCurrency: currencyCode,
    activeLanguage: language,
    moneyFormat,
    templateProduct,
  } = useRuntimeContext(ShopifyStoreContext);
  const products = useRuntimeContext(RuntimeHooksContext).useShopifyProducts();
  const productMetafieldValues =
    useRuntimeContext(RuntimeHooksContext).useShopifyProductMetafieldValues();
  const variantMetafieldValues =
    useRuntimeContext(RuntimeHooksContext).useShopifyVariantMetafieldValues();
  const [temporaryCart, setTemporaryCart] = React.useState<TempCart>({
    items: [],
  });

  const priceRules = (component.props["_priceRules"] as ReploPriceRule[]) || [];
  const tempCartItems = isEditorCanvas
    ? ((component.props["_alchemyEditor_previewProducts"] as any[]) || []).map(
        (x: any) => {
          return { productRef: x };
        },
      )
    : temporaryCart.items;

  const totalPrice = tempCartItems.reduce(
    (acc: number, curr: { productRef: ProductRef }) => {
      const priceOfOneVariant =
        getProduct(curr.productRef, context, {
          products,
          currencyCode,
          language,
          moneyFormat,
          productMetafieldValues,
          variantMetafieldValues,
          isEditor: isEditorApp,
          fakeProducts,
          templateProduct,
        })?.variant?.price || 0;
      const price =
        Number(priceOfOneVariant) * (curr.productRef?.quantity || 1);
      return acc + price;
    },
    0,
  );
  const totalPriceIncludingDiscounts = tempCartItems.reduce(
    (acc: number, curr: { productRef: ProductRef }) =>
      acc +
      getPriceIncludingDiscounts(priceRules, curr, tempCartItems, context, {
        products,
        currencyCode,
        moneyFormat,
        language,
        templateProduct,
        isEditor: isEditorApp,
      }),
    0,
  );

  const actionHooks = {
    addVariantToTemporaryCart: (ref: ProductRef, quantity?: number) => {
      if (!quantity) {
        quantity = 1;
      }
      const productVariant = ref;
      const clone = cloneDeep(temporaryCart);
      const existingItem = clone.items.find((item) => {
        const indexRef = item.productRef;
        return (
          String(indexRef.productId) === String(productVariant.productId) &&
          String(indexRef.variantId) === String(productVariant.variantId)
        );
      });
      if (existingItem) {
        existingItem.productRef.quantity =
          (existingItem.productRef?.quantity || 1) + quantity;
      } else {
        clone.items.push({ productRef: productVariant });
      }
      setTemporaryCart(clone);
    },
    removeVariantFromTemporaryCart: (variant: ProductRef) => {
      const clone = cloneDeep(temporaryCart);
      clone.items = clone.items.filter((item) => {
        if (
          String(item.productRef.productId) === String(variant.productId) &&
          String(item.productRef.variantId) === String(variant.variantId)
        ) {
          return false;
        }
        return true;
      });
      setTemporaryCart(clone);
    },
    decreaseVariantCountInTemporaryCart: (variant: ProductRef) => {
      const clone = cloneDeep(temporaryCart);
      clone.items = clone.items
        .map((item) => {
          if (
            String(item.productRef.productId) === String(variant.productId) &&
            String(item.productRef.variantId) === String(variant.variantId)
          ) {
            return {
              ...item,
              productRef: {
                ...item.productRef,
                quantity: (item.productRef?.quantity || 1) - 1,
              },
            };
          }
          return item;
        })
        .filter((item) => {
          let quantity = item.productRef?.quantity;
          if (quantity === null || quantity === undefined) {
            quantity = 1;
          }
          return quantity > 0;
        });
      setTemporaryCart(clone);
    },
  } satisfies {
    [K in ActionType]: Function;
  };

  const newContext = mergeContext(context, {
    actionHooks,
    state: {
      temporaryCart: {
        ...temporaryCart,
        items: tempCartItems,
        priceRules,
      },
    },
    attributes: {
      _temporaryCartItems: tempCartItems,
      // Note (Noah, 2022-03-27): Temporary cart items are always derived from
      // alchemy-data liquid products, which means their prices are always
      // in cents
      _temporaryCartTotalPrice: formatCurrency(totalPrice, { currencyCode }),
      _temporaryCartTotalPriceIncludingDiscounts: formatCurrency(
        totalPriceIncludingDiscounts,
        { currencyCode },
      ),
    },
  });

  return (
    <div {...componentAttributes}>
      {(component.children || []).map((child) => {
        return (
          <ReploComponent
            key={child.id}
            component={child}
            context={newContext}
            repeatedIndexPath={context.repeatedIndexPath ?? ".0"}
          />
        );
      })}
    </div>
  );
};

export default TemporaryCart;
