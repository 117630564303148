import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { useRenderChildren } from "../../../shared/utils/renderComponents";
import { ReploComponent } from "../ReploComponent";

const CollectionV2: React.FC<RenderComponentProps> = ({
  componentAttributes,
  component,
  context,
}) => {
  const items = component.props._items;
  const { repeatedIndexPath = ".0" } = context;
  const children = useRenderChildren(component, {
    itemsConfig: items,
    context,
  });

  return (
    <div {...componentAttributes}>
      {children.map((child) => {
        return (
          <ReploComponent
            key={child.component.id}
            component={child.component}
            context={child.context}
            repeatedIndexPath={repeatedIndexPath}
          />
        );
      })}
    </div>
  );
};

export default CollectionV2;
