import * as React from "react";

import { Spinner } from "@replo/design-system/components/spinner";
import { twMerge } from "tailwind-merge";

export const Loader: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      className={twMerge(
        "flex items-center content-center justify-center h-full min-h-full",
        className,
      )}
    >
      <Spinner size={50} variant="primary" />
    </div>
  );
};
