import type {
  BillingTier,
  SubscriptionDetails,
} from "schemas/generated/billing";

export const isSelfServeSubscription = (
  subscription: SubscriptionDetails | undefined,
) => {
  return (
    !subscription ||
    !(
      subscription.isCustom ||
      subscription.paymentProcessor === "none" ||
      subscription.name === "custom" ||
      subscription.name === "enterprise"
    )
  );
};

const tierOrder: Record<BillingTier, number> = {
  free: 0,
  basic: 1,
  standard: 2,
  growth: 3,
  enterprise: 4,
  custom: 5,
};

/**
 * Compares to billing tiers and returns a negative number if a comes before b
 * (is a lower tier), 0 if they are the same tier, and a positive number if a
 * comes after b (is a higher tier).
 */
export const billingTierCompare = (a: BillingTier, b: BillingTier): number => {
  if (a === b) {
    return 0;
  }
  return tierOrder[a] - tierOrder[b];
};
