import type { DebouncedFunc } from "lodash-es/debounce";

import * as React from "react";

import debounce from "lodash-es/debounce";

import { unsafe_useLayoutEffectWithoutWarning as useLayoutEffect } from "./use-layout-effect";

export function useDebouncedCallback<T extends (...args: any[]) => any>(
  callback: T,
  wait: number,
  options?: DebounceOptions,
) {
  const savedCallback = React.useRef(callback);
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const { leading = false, trailing = true, maxWait = null } = options ?? {};

  const debouncedCallback = React.useMemo<DebouncedFunc<T>>(() => {
    const options: DebounceOptions = {
      leading,
      trailing,
      maxWait: maxWait ?? undefined,
    };
    if (wait == null || wait <= 0) {
      const handler = (...args: any[]) => {
        savedCallback.current?.(...args);
      };
      handler.cancel = () => void 0;
      handler.flush = () => void 0;
      return handler as DebouncedFunc<T>;
    }

    const debounced = debounce(
      (...args: any[]) => {
        savedCallback.current?.(...args);
      },
      wait,
      options,
    );
    return debounced as DebouncedFunc<T>;
  }, [leading, maxWait, trailing, wait]);

  const previousCallbackRef = React.useRef(debouncedCallback);
  React.useEffect(() => {
    const previousCallback = previousCallbackRef.current;
    previousCallbackRef.current = debouncedCallback;
    const flush = debouncedCallback.flush;
    if (previousCallback !== debouncedCallback) {
      return () => {
        // NOTE (Chance 2024-03-29): If the callback reference changes we want
        // to flush pending calls before the next is called, but we only want to
        // do this once. Hence the comparison.
        flush();
      };
    }
  }, [debouncedCallback]);

  return debouncedCallback;
}

interface DebounceOptions {
  /**
   * Specify invoking on the leading edge of the timeout. Defaults to `false`.
   */
  leading?: boolean;
  /**
   * The maximum time the callback is allowed to be delayed before it's invoked.
   * Defaults to `0`.
   */
  maxWait?: number;
  /**
   * Specify invoking on the trailing edge of the timeout. Defaults to `true`.
   */
  trailing?: boolean;
}
