import type { ReploElementType } from "schemas/generated/element";

import { exhaustiveSwitch } from "replo-utils/lib/misc";

const isLiquidAllowed = ({
  elementType,
  isPublishing,
  disableLiquid,
}: {
  elementType?: ReploElementType;
  isPublishing?: boolean;
  disableLiquid?: boolean;
}) => {
  // NOTE (Matt 2024-11-08): We only allow liquid in the `isPublishing` phase (not in the editor, and not after page load)
  // and disableLiquid is a toggle that can be set primarily in the instances of taking screenshots of an element (ie VR tests)
  if (disableLiquid || !isPublishing || !elementType) {
    return false;
  }
  const allowsLiquid = exhaustiveSwitch({ type: elementType })({
    page: true,
    shopifyProductTemplate: true,
    shopifySection: true,
    shopifyArticle: false,
  });
  return allowsLiquid;
};

export default isLiquidAllowed;
