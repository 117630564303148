import * as React from "react";

import { Loader } from "@components/marketplace/Loader";

import InfiniteScroll from "react-infinite-scroll-component";

export type InfiniteLoadingSettings = {
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  loadNextPage(): void;
};

export const InfiniteLoadingGrid: React.FC<
  React.PropsWithChildren<{
    listLength: number;
    loadMoreItems: InfiniteLoadingSettings["loadNextPage"];
    hasNextPage: InfiniteLoadingSettings["hasNextPage"];
    /**
     * Id of an element to use as a scroll container. loadMoreItems will be
     * called when the user scrolls to the bottom of this container (defaults
     * to document.body)
     */
    scrollableTargetId: string;
    className?: string;
    style?: React.CSSProperties;
    loaderLabel?: string;
  }>
> = ({
  children,
  listLength,
  loadMoreItems,
  hasNextPage,
  className,
  style,
  scrollableTargetId,
  loaderLabel = "Loading...",
}) => {
  return (
    <InfiniteScroll
      dataLength={listLength}
      next={loadMoreItems}
      hasMore={hasNextPage}
      scrollableTarget={scrollableTargetId}
      loader={
        <Loader
          label={loaderLabel}
          className="col-span-3 flex w-full flex-col items-center gap-2 pt-8 pb-4 text-blue-600"
        />
      }
      className={className}
      style={style}
    >
      {children}
    </InfiniteScroll>
  );
};
