import type { StyleElements } from "../../../shared/utils/renderComponents";

// NOTE (Chance 2023-11-20): This file is not a complete config because
// CarouselV4 is not technically a Replo runtime component. Rather, we use this
// to expose styles that are specifically relevant to the CarouselV4 React
// component, and CarouselV3 will conditionally use these styles in the
// CarouselV3 config based on the feature flag. Ultimately this file will go
// away when we're ready to completely replace v3.

export const styleElements = {
  root: {
    overrideStyles({ component }) {
      if (component.props._dragToScroll === true) {
        return {
          userSelect: "none",
          WebkitTouchCallout: "none",
          touchAction: "pan-y",
          WebkitTapHighlightColor: "transparent",
        };
      }
    },
  },
} as const satisfies StyleElements;

export const slidesStyleElements = {
  root: {
    defaultStyles: {
      // TODO (Chance 2024-01-12): This should probably change for vertical
      // carousels, but this is what we do in v3 so I am keeping for back-compat
      overflowY: "visible",
      overflowX: "clip",
      // Note (Noah, 2024-05-11, USE-939): In certain contexts, Safari calculates
      // 100% as 100% of the containing flex container (which may not be the parent
      // element). This is here so that the height: 100% which we have on slides
      // track is relative to this component, not this component's parent Container
      display: "flex",
    },
  },
  slide: {
    defaultStyles: {
      // TODO (Chance 2024-01-31, REPL-10139): This is a temporary fix to
      // prevent cutoff outlines appearing while navigating via arrow keys. The
      // better solution is to move manage visual focus on the top-level slides
      // element and move the indicator to that element. This will be resolved
      // during the a11y testing phase in v4.
      outline: "none",
    },
  },
} as const satisfies StyleElements;

export const controlStyleElements = {
  root: {},
} as const satisfies StyleElements;

export const indicatorsStyleElements = {
  root: {},
} as const satisfies StyleElements;
