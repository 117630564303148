import type { EditorCanvas } from "@/features/canvas/canvas-types";
import type { EditorRootState } from "@editor/store";
import type { PayloadAction } from "@reduxjs/toolkit";

import { findComponentById } from "@editor/utils/component";
import { selectDraftElement_warningThisWillRerenderOnEveryUpdate } from "@reducers/core-reducer";

import { createSelector, createSlice } from "@reduxjs/toolkit";

export type CandidateState = {
  candidateNode: HTMLElement | null;
  lastCandidateComponentId: string | null;
  lastCandidateRepeatedIndex: string | null;
  componentIdToDrag: string | null;
  componentNodeToDrag: HTMLElement | null;
  candidateCanvas: EditorCanvas | null;
};

const initialState: CandidateState = {
  candidateNode: null,
  lastCandidateComponentId: null,
  lastCandidateRepeatedIndex: null,
  componentIdToDrag: null,
  componentNodeToDrag: null,
  candidateCanvas: null,
};

export type SetCandidateNodePayload = {
  candidateNode: HTMLElement | null;
  lastCandidateComponentId?: string | null;
  lastCandidateRepeatedIndex?: string | null;
  componentIdToDrag?: string | null;
  componentNodeToDrag?: HTMLElement | null;
  candidateCanvas: EditorCanvas | null;
};

const candidateSlice = createSlice({
  name: "candidate",
  initialState,
  reducers: {
    setCandidateNode: (
      state,
      action: PayloadAction<SetCandidateNodePayload>,
    ) => {
      // Note (Noah, 2024-07-08): Typescript/RTK don't play nicely with assigning
      // HTMLElement nodes to the state (errors about HTMLElement not being assignable
      // to properties of WritableDraft). There's probably a better way to go about
      // this, but this works for now
      // @ts-expect-error
      state.candidateNode = action.payload.candidateNode;
      state.candidateCanvas = action.payload.candidateCanvas;
      if (action.payload.lastCandidateComponentId !== undefined) {
        state.lastCandidateComponentId =
          action.payload.lastCandidateComponentId;
      }
      if (action.payload.lastCandidateRepeatedIndex !== undefined) {
        state.lastCandidateRepeatedIndex =
          action.payload.lastCandidateRepeatedIndex;
      }
      if (action.payload.componentIdToDrag !== undefined) {
        state.componentIdToDrag = action.payload.componentIdToDrag;
      }
      if (action.payload.componentNodeToDrag !== undefined) {
        // Note (Noah, 2024-07-08): Typescript/RTK don't play nicely with assigning
        // HTMLElement nodes to the state (errors about HTMLElement not being assignable
        // to properties of WritableDraft). There's probably a better way to go about
        // this, but this works for now
        // @ts-expect-error
        state.componentNodeToDrag = action.payload.componentNodeToDrag;
      }
    },
  },
});

const { actions, reducer } = candidateSlice;

export const selectCandidateNode = (state: EditorRootState) =>
  state.candidate.candidateNode;

export const selectLastCandidateComponentId = (state: EditorRootState) =>
  state.candidate.lastCandidateComponentId;

export const selectLastCandidateRepeatedIndex = (state: EditorRootState) =>
  state.candidate.lastCandidateRepeatedIndex;

export const selectComponentIdToDrag = (state: EditorRootState) =>
  state.candidate.componentIdToDrag;

export const selectComponentNodeToDrag = (state: EditorRootState) =>
  state.candidate.componentNodeToDrag;

export const selectCandidateCanvas = (state: EditorRootState) =>
  state.candidate.candidateCanvas;

export const selectCandidateComponent = createSelector(
  selectCandidateNode,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  (candidateNode, draftElement) => {
    if (!draftElement || !candidateNode) {
      return null;
    }
    const candidateComponentId = candidateNode.dataset.rid;
    return findComponentById(draftElement, candidateComponentId ?? null);
  },
);

export const { setCandidateNode } = actions;
export default reducer;
