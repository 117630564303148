import type { CarouselController } from "@replo/carousel";
import type {
  ActiveSlidePosition,
  DragBehavior,
  EndBehavior,
  Orientation,
  TransitionStyle,
  WheelBehavior,
} from "@replo/carousel/lib/types";
import type { Component } from "schemas/component";
import type { ItemsConfig } from "schemas/dynamicData";
import type { RenderComponentProps } from "../../../shared/types";
import type {
  SupportedMediaSize,
  SupportedMediaSizeWithDefault,
} from "../../../shared/utils/breakpoints";
import type { RenderChildren } from "../../../shared/utils/renderComponents";
import type { Context as AlchemyContext } from "../../AlchemyVariable";
import type { ActionType } from "../CarouselV3/config";

import * as React from "react";

import * as CarouselPrimitive from "@replo/carousel";
import isEqual from "fast-deep-equal";
import useForceRerenderOnMount from "replo-runtime/store/hooks/useForceRerenderOnMount";
import useSyncRuntimeValue from "replo-runtime/store/hooks/useSyncRuntimeValue";
import { parseInteger, round } from "replo-utils/lib/math";
import { isNotNullish, noop } from "replo-utils/lib/misc";
import { isNumber, isString } from "replo-utils/lib/type-check";
import { normalizeUrlScheme } from "replo-utils/lib/url";
import { useEffectEvent } from "replo-utils/react/use-effect-event";
import { useForceUpdate } from "replo-utils/react/use-force-update";
import { useIsHydrated } from "replo-utils/react/use-is-hydrated";
import { useOnValueChange } from "replo-utils/react/use-on-value-change";

import {
  FeatureFlagsContext,
  GlobalWindowContext,
  RenderEnvironmentContext,
  RuntimeHooksContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import {
  defaultMediaSize,
  mediaQueries,
  supportedMediaSizes,
} from "../../../shared/utils/breakpoints";
import {
  findComponentByTypeDepthFirst,
  getPropsWithOverrides,
} from "../../../shared/utils/component";
import { mergeContext } from "../../../shared/utils/context";
import {
  useComponentClassNames,
  useRenderChildren,
} from "../../../shared/utils/renderComponents";
import { isCompletelyHiddenComponent } from "../../utils/isCompletelyHiddenComponent";
import { isItemsDynamic } from "../../utils/items";
import Container from "../Container";
import { ReploComponent } from "../ReploComponent";
import ReploLiquidChunk from "../ReploLiquid/ReploLiquidChunk";

const SLIDE_GAP = "var(--replo-gap, 0px)";

type GroupContextValue = {
  currentIndex: number;
  isDynamic: boolean;
  isFirstItem: boolean;
  isLastItem: boolean;
  items: RenderChildren;
  total: number;
};

type CarouselContextValue = {
  activeSlideIndex: number;
  activeSlidePosition: ActiveSlidePosition;
  autoPlayInterval: number;
  autoWidth: boolean;
  controller: React.RefObject<CarouselController>;
  dragBehavior: DragBehavior;
  dynamicItems: ItemsConfig | undefined;
  endBehavior: EndBehavior;
  mainSlidesComponent: Component;
  mainSlideCount: number;
  name: string | undefined;
  onActiveSlideChange: (index: number) => void;
  orientation: "horizontal" | "vertical";
  pauseOnHover: boolean;
  // TODO (Chance 2024-01-22): Strengthen this type
  selectedDynamicItem: any;
  shouldTransitionOnSlideClick: boolean;
  slidesPerMove: number;
  slidesPerPage: number;
  transitionSpeed: number;
  transitionStyle: TransitionStyle;
  wheelBehavior: WheelBehavior;
  swipe: boolean;
};

const CarouselContext = React.createContext<CarouselContextValue | null>(null);
CarouselContext.displayName = "CarouselContext";
const GroupContext = React.createContext<GroupContextValue | null>(null);
GroupContext.displayName = "CarouselGroupContext";

const TRANSITION_SPEED = 250;

/**
 * Main carousel component that takes care of building the slides that will
 * be used for rendering purposes.
 */
export const CarouselV4: React.FC<RenderComponentProps> = ({
  componentAttributes,
  component,
  context,
}) => {
  const globalWindow = useRuntimeContext(GlobalWindowContext);

  // Note (Evan, 2024-06-19): If no overrides are set, we don't need to run this hook here (we
  // can avoid some extra renders). We will fall back to the default media size, and therefore
  // not override any props.
  const hasOverrides =
    component.props.overrides?.sm || component.props.overrides?.md;

  const currentMediaSize = useCurrentMediaSize({
    fallback: defaultMediaSize,
    window: globalWindow,
    skip: !hasOverrides,
  });

  const propsWithOverrides =
    getPropsWithOverrides(component.props, currentMediaSize) ?? component.props;

  // Note (Evan, 2024-06-19): Get these four values from propsWithOverrides since they are overridable
  const autoWidth = propsWithOverrides._autoWidth ?? false;
  const activeSlidePosition =
    getActiveSlidePosition(propsWithOverrides._activeArea) ?? "start";
  const slidesPerPage = coerceToInteger(propsWithOverrides._itemsPerView) ?? 1;
  const slidesPerMove = coerceToInteger(propsWithOverrides._itemsPerMove) ?? 1;

  const { repeatedIndexPath = ".0" } = context;
  const endBehavior: EndBehavior = component.props._infinite
    ? "loop"
    : "normal";

  const items = component.props._items;
  const name = component.props._title;
  const screenreaderInstructions =
    component.props._accessibilityScreenreaderInstructions;
  const autoPlayInterval =
    parseInterval(component.props._autoNextInterval) * 1000;
  const transitionStyle = component.props._animationStyle ?? "slide";
  // NOTE (Chance 2024-02-14): There are some bugs with dragging/swiping in the
  // underlying carousel. Since we don't support free-dragging yet anyway I'm
  // opting for a much simpler behavior to allow swiping in the runtime
  // component and disabling both swipe and drag.
  const dragBehavior: DragBehavior = "off";
  // component.props._dragToScroll === true ? "move-once" : "off";
  const swipe = false;
  const wheelBehavior: WheelBehavior =
    component.props._mouseWheel === true ? "move-once" : "off";
  const shouldTransitionOnSlideClick =
    component.props._slideClickTransition ?? true;
  const selectedDynamicItem = component.props._selectedItem;

  // NOTE (Chance 2023-09-21): I'm not sure we should make this configurable
  // TBH, as I'd consider this an accessibility issue. If there are cases
  // where it really needs to be disabled, I think our UI should tuck it
  // away behind some "advanced" config section and make it clear that we
  // don't recommend disabling it.
  const pauseOnHover = component.props._pauseOnHover ?? true;

  const carouselId = `replo-carousel:${React.useId()}`;

  const mainSlidesComponent = findComponentByTypeDepthFirst(
    component,
    "carouselV3Slides",
    "carouselV3",
    // Note (Evan, 2024-03-14): The main slides component cannot be a completely hidden component,
    // (or a descendant of one), since those are rendered as null.
    isCompletelyHiddenComponent,
  );

  const isDynamic = isItemsDynamic(items);
  const mainSlides = useRenderChildren(mainSlidesComponent ?? null, {
    itemsConfig: items,
    context,
    currentItemId: component.id,
  });

  const [activeSlideIndex, setActiveSlideIndex] = useCarouselState({
    key: `${component.id}.activeSlide`,
    initialValue: 0,
  });

  const activeSlide = mainSlides[activeSlideIndex];

  useSyncRuntimeValue([component.id, "totalItems"], mainSlides.length);

  useAutoSetActiveIndex({
    isDynamic,
    selectedDynamicItem,
    mainSlides,
    setActiveSlideIndex,
  });

  useResetOnDynamicDataChange({
    activeSlideIndex,
    isDynamic,
    mainSlides,
    setActiveSlideIndex,
  });

  const controller = React.useRef<CarouselController>(null);
  const actionHooks = React.useMemo(() => {
    return {
      goToItem: (index: number | undefined) => {
        // TODO (Chance 2024-05-23): Types are not quite right. If the input is
        // empty in some cases the index may be undefined. Fix action types to
        // account for this.
        if (index != null) {
          index = Math.max(0, index - 1);
          if (!Number.isNaN(index)) {
            controller.current?.goToSlide(index);
          }
        }
      },
      goToNextItem: () => {
        controller.current?.goNext();
      },
      goToPrevItem: () => {
        controller.current?.goBack();
      },
    } satisfies {
      [K in ActionType]: Function;
    };
  }, []);

  if (!mainSlidesComponent) {
    return null;
  }

  const carouselContext = {
    activeSlideIndex: activeSlideIndex,
    activeSlidePosition,
    autoPlayInterval,
    autoWidth,
    controller,
    dragBehavior,
    dynamicItems: items,
    endBehavior,
    mainSlideCount: mainSlides.length,
    mainSlidesComponent,
    name,
    onActiveSlideChange: setActiveSlideIndex,
    // TODO (Chance 2024-01-08): Use correct orientation for vertical carousels.
    // Right now this works fine visually, but it breaks accessibility and
    // keyboard navigation.
    orientation: "horizontal",
    pauseOnHover,
    selectedDynamicItem,
    slidesPerMove,
    slidesPerPage,
    transitionSpeed: TRANSITION_SPEED,
    transitionStyle,
    wheelBehavior,
    shouldTransitionOnSlideClick,
    swipe,
  } satisfies CarouselContextValue;

  const isInfinite = endBehavior === "loop";
  const groupContext = {
    isDynamic,
    items: mainSlides,
    total: mainSlides.length,
    currentIndex: activeSlideIndex,
    isFirstItem: isInfinite ? false : activeSlideIndex === 0,
    isLastItem: isInfinite ? false : activeSlideIndex === mainSlides.length - 1,
  } satisfies GroupContextValue;

  const contextWithSlides: ContextWithSlides = mergeContext(context, {
    attributes: {
      ...activeSlide?.context.attributes,
      // NOTE (Fran 2024-06-18): With autoWidth, the number of panels will be equivalent to the number of slides.
      _carouselPanels: autoWidth
        ? String(mainSlides.length)
        : String(Math.ceil(mainSlides.length / slidesPerPage)),
      _currentPanel: autoWidth
        ? String(activeSlideIndex + 1)
        : String(Math.ceil(activeSlideIndex / slidesPerPage) + 1),
      _currentSlide: String(activeSlideIndex + 1),
      _carouselSlides: String(mainSlides.length),
    },
    actionHooks,
    group: groupContext,
    isInsideProductImageCarousel: isInsideProductImageCarousel(items),
  });

  return (
    <div
      {...componentAttributes}
      id={carouselId}
      role="region"
      aria-label={name ?? "Carousel"}
      data-replo-carousel="true"
    >
      {screenreaderInstructions && (
        <p className="replo-sr-only">{screenreaderInstructions}</p>
      )}
      <CarouselContext.Provider value={carouselContext}>
        <GroupContext.Provider value={groupContext}>
          {component.children?.map((child) => (
            <ReploComponent
              key={child.id}
              component={child}
              context={contextWithSlides}
              repeatedIndexPath={repeatedIndexPath}
            />
          ))}
        </GroupContext.Provider>
      </CarouselContext.Provider>
    </div>
  );
};

const useCurrentMediaSize = ({
  fallback,
  window,
  skip,
}: {
  fallback: SupportedMediaSizeWithDefault;
  window: Window | null;
  skip: boolean;
}) => {
  const rerender = useForceUpdate();

  const [mqls, setMqls] = React.useState<
    Record<SupportedMediaSize, MediaQueryList | null>
  >({
    sm: null,
    md: null,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: using extra deps rerender
  React.useEffect(() => {
    // Note (Evan, 2024-06-19): If the window isn't defined for some reason, there's nothing we can do
    if (!window || skip) {
      return noop;
    }
    const removeListenerFunctions: (() => void)[] = [];

    const getHandler =
      (size: SupportedMediaSize) => (event: MediaQueryListEvent) => {
        const mql = event.currentTarget as MediaQueryList;
        setMqls((mqls) => ({ ...mqls, [size]: mql }));
      };

    supportedMediaSizes.forEach((size) => {
      const queryString = mediaQueries[size];
      const mql = window.matchMedia(queryString);

      const handleChange = getHandler(size);

      mql.addEventListener("change", handleChange);

      removeListenerFunctions.push(() =>
        mql.removeEventListener("change", handleChange),
      );

      setMqls((mqls) => ({ ...mqls, [size]: mql }));
    });

    return () => {
      removeListenerFunctions.forEach((fn) => fn());
    };
  }, [window, rerender, skip]);

  for (const mediaSize of supportedMediaSizes) {
    if (mqls[mediaSize]?.matches) {
      return mediaSize;
    }
  }
  return fallback;
};

type SlidesContextValue = {
  autoWidth: boolean;
  slidesPerPage: number;
  slidesPerMove: number;
  transitionStyle: TransitionStyle;
  activeSlidePosition: ActiveSlidePosition;
  shouldTransitionOnSlideClick: boolean;
  isMainSlidesComponent: boolean;
  slideClassName: string | undefined;
};

const SlidesContext = React.createContext<SlidesContextValue | null>(null);
SlidesContext.displayName = "CarouselSlidesContext";

export const CarouselV4Slides: React.FC<RenderComponentProps> = (props) => {
  const carousel = React.useContext(CarouselContext);
  if (!carousel || !props.context) {
    return null;
  }
  return <_CarouselV4Slides {...props} />;
};

const _CarouselV4Slides: React.FC<RenderComponentProps> = (props) => {
  const carousel = React.useContext(CarouselContext)!;
  const { componentAttributes, component, context } = props;
  const { featureFlags } = useRuntimeContext(FeatureFlagsContext);

  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const isDraggable = carousel.dragBehavior !== "off";

  let {
    activeSlideIndex,
    activeSlidePosition,
    autoPlayInterval,
    autoWidth,
    controller,
    dragBehavior,
    mainSlidesComponent,
    onActiveSlideChange,
    shouldTransitionOnSlideClick,
    slidesPerMove,
    slidesPerPage,
    swipe,
    transitionSpeed,
    transitionStyle,
    wheelBehavior,
  } = carousel;

  // NOTE (Chance 2024-02-21, REPL-10444): When publishing a carousel that uses
  // Liquid, we can't enable infinite scrolling because of how the carousel
  // primitive handles slide duplication. The carousel track renders:
  //   - the liquid string that starts the loop
  //   - the slides*
  //   - the liquid string that ends the loop
  //
  // While we might only pass a single slide as a child to the track, internally
  // the carousel will take that single slide and assume its the only actual
  // slide, then make copies to fill the track if infinite scrolling is enabled.
  // The resulting liquid template then would look something like this:
  //
  //   {% for reploProductImage in product.images %}
  //     <Slide key="clone-before" />
  //     <Slide />
  //     <Slide key="clone-after" />
  //   {% endfor %}
  //
  // So instead we will disable infinite scrolling when using Liquid. But we
  // can't only do this while publishing, because the liquid template would
  // include markup that would be inconsistent with the hydrated component. So
  // for the published page we need to disable infinite scrolling until the
  // component is hydrated to prevent a mismatch. This means we'll potentially
  // get a flash of the carousel with a different position or visible number of
  // slides, but this is at least no worse than v3 which also has this issue but
  // for *all* infinite carousels.
  let endBehavior = carousel.endBehavior;
  const usesLiquidWhenPublishing = willUseLiquidWhenPublishing(context);
  const isHydrated = useIsHydrated();
  if (usesLiquidWhenPublishing && !isEditorApp && !isHydrated) {
    endBehavior = "normal";
  }

  const isMainSlidesComponent = component.id === mainSlidesComponent.id;

  if (component.props._useCustomConfig) {
    autoWidth = component.props._autoWidth ?? carousel.autoWidth;
    slidesPerPage =
      component.props._itemsPerView != null
        ? parseInteger(component.props._itemsPerView)
        : carousel.slidesPerPage;
    transitionStyle =
      component.props._animationStyle ?? carousel.transitionStyle;
    activeSlidePosition =
      getActiveSlidePosition(props.component.props._activeArea) ??
      carousel.activeSlidePosition;
    if (isNotNullish(component.props._slideClickTransition)) {
      shouldTransitionOnSlideClick = component.props._slideClickTransition;
    }
  }

  // NOTE (Chance 2024-01-22): The carousel config UI does not allow
  // `slidesPerMove` or `slidesPerPage` to be set when auto-width is enabled.
  // Conversely it does not allow center mode in fixed width mode. This ensures
  // that the results are consistent when incompatible options are selected
  // while the user toggles between fixed and auto-width.
  if (autoWidth) {
    // NOTE (Chance 2024-03-06, REPL-10737 + REPL-10758): Auto-width carousels
    // do not work correctly with fade transitions. This should be impossible to
    // set in the editor, but until now we will just switch to slide which is
    // consistent w/ v3.
    if (transitionStyle === "fade") {
      transitionStyle = "slide";
    }
    slidesPerMove = 1;
    slidesPerPage = 1;
  } else {
    // TODO (Chance 2024-01-22): While this is technically not possible in the
    // editor UI, we do have a test that relies on centered active slide in
    // fixed-width. Ignoring this for now but we need to confirm the intent.
    // activeSlidePosition = "start";
  }

  // NOTE (Chance 2024-03-06): Fade transitions are similarly incompatible with
  // multiple slides per page.
  if (slidesPerPage > 1 && transitionStyle === "fade") {
    transitionStyle = "slide";
  }

  // NOTE (Chance 2024-03-06): We want to disable all transitions and
  // interactions while editing!
  if (isEditorCanvas) {
    wheelBehavior = "off";
    dragBehavior = "off";
    transitionStyle = "off";
    autoPlayInterval = 0;
    swipe = false;
  }

  const classNameMap = useComponentClassNames(
    "carouselV3Slides",
    component,
    context,
  );

  const overridableProps = {
    autoWidth,
    slidesPerPage,
    slidesPerMove,
    transitionStyle,
    activeSlidePosition,
    isMainSlidesComponent,
    slideClassName: classNameMap?.slide,
    shouldTransitionOnSlideClick,
  } satisfies SlidesContextValue;

  const slidesRef = React.useRef<HTMLDivElement>(null);

  useSlideGestures(slidesRef, {
    swipeDirection: carousel.orientation === "vertical" ? "y" : "x",
    onSwipe: isEditorCanvas
      ? null
      : ({ swipeDirection }) => {
          if (carousel.orientation === "vertical") {
            if (swipeDirection === "down") {
              controller.current?.goBack();
            } else if (swipeDirection === "up") {
              controller.current?.goNext();
            }
          } else {
            if (swipeDirection === "right") {
              controller.current?.goBack();
            } else if (swipeDirection === "left") {
              controller.current?.goNext();
            }
          }
        },
    onSlideClick:
      isEditorCanvas || !shouldTransitionOnSlideClick
        ? null
        : ({ slideIndex }) => {
            if (slideIndex !== activeSlideIndex) {
              controller.current?.goToSlide(slideIndex);
            }
          },
    allowSwipeWithMouse: true,
  });

  const CarouselV4SlidesContent = isMainSlidesComponent
    ? CarouselV4SlidesContentMain
    : CarouselV4SlidesContentSecondary;

  return (
    <div {...componentAttributes}>
      <CarouselPrimitive.Root
        activeSlideIndex={activeSlideIndex}
        activeSlidePosition={activeSlidePosition}
        autoPlayInterval={autoPlayInterval}
        autoWidth={autoWidth}
        controller={isMainSlidesComponent ? controller : undefined}
        dragBehavior={dragBehavior}
        swipe={swipe}
        endBehavior={endBehavior}
        onActiveSlideChange={onActiveSlideChange}
        slidesPerMove={slidesPerMove}
        slidesPerPage={slidesPerPage}
        transitionSpeed={transitionSpeed}
        transitionStyle={transitionStyle}
        wheelBehavior={wheelBehavior}
        debugEvents={featureFlags.carouselDebug}
      >
        <CarouselPrimitive.Slides
          ref={slidesRef}
          // Note (Noah, 2024-11-23, REPL-14475): CarouselPrimitive correctly adds
          // role="region" to the carousel slides, and requires a descriptive label
          // for the region. We currently don't let the user edit this value, since
          // "Carousel slides" is descriptive enough, and we also allow the user to
          // specify an aria-label for the carousel wrapper region as well.
          //
          // We specifically don't use the accessibility name here, since that's the
          // label of the carousel wrapper region and using it would mean duplication
          aria-label="Carousel slides"
          style={{
            // NOTE (Chance 2023-11-20): Important for consistency with v3 styles
            paddingLeft: 0,
            paddingRight: 0,
            width: "100%",
            height: "100%",
            overflow: "visible",
            position: "relative",
            ...(isDraggable
              ? {
                  userSelect: "none",
                  WebkitTouchCallout: "none",
                }
              : {}),
          }}
          disableKeyboardNavigation={isEditorCanvas}
        >
          <SlidesContext.Provider value={overridableProps}>
            <CarouselV4SlidesContent {...props} />
          </SlidesContext.Provider>
        </CarouselPrimitive.Slides>
      </CarouselPrimitive.Root>
    </div>
  );
};

const _CarouselV4SlidesContent: React.FC<
  RenderComponentProps & { items: RenderChildren }
> = ({ component, componentAttributes, context, items }) => {
  const { repeatedIndexPath = ".0", actionHooks } = context;
  const carousel = React.useContext(CarouselContext)!;
  const slides = React.useContext(SlidesContext)!;
  const group = React.useContext(GroupContext)!;

  if (items.length === 0) {
    return (
      <Container
        component={component}
        componentAttributes={componentAttributes}
        context={context}
      />
    );
  }

  return (
    <CarouselPrimitive.SlideTrack
      style={getTrackInlineStyles({ orientation: carousel.orientation })}
      slideGap={SLIDE_GAP}
    >
      {items.map((item, index, items) => {
        const key = getSlideIdentifier(item, index, items);
        return (
          <CarouselPrimitive.Slide
            key={key as React.Key}
            data-component-id={item.component.id}
            className={slides.slideClassName}
            style={getSlideInlineStyles({
              orientation: carousel.orientation,
              autoWidth: slides.autoWidth,
            })}
          >
            <CarouselSlideChild
              actionHooks={actionHooks}
              component={item.component}
              context={{
                ...item.context,
                // NOTE (Fran 2024-06-19): We need to add the parent's context attributes so we can use those
                // in every children of the carousel inner components.
                attributes: {
                  ...context.attributes,
                  ...item.context.attributes,
                },
              }}
              index={index}
              isActiveItem={index === group.currentIndex}
              repeatedIndexPath={repeatedIndexPath}
            />
          </CarouselPrimitive.Slide>
        );
      })}
    </CarouselPrimitive.SlideTrack>
  );
};

function CarouselV4SlidesContentMain(props: RenderComponentProps) {
  const group = React.useContext(GroupContext)!;
  // NOTE (Gabe 2023-08-29): We use a slightly different technique for deciding
  // whether or not to use the liquid version in the carousel since we need to
  // know whether or not the carousel is being used for product images.
  let CarouselV4SlidesContent = _CarouselV4SlidesContent;
  if (shouldUseLiquid(props.context)) {
    CarouselV4SlidesContent = _CarouselV4SlidesContentLiquid;
  }
  return <CarouselV4SlidesContent items={group.items} {...props} />;
}

function CarouselV4SlidesContentSecondary(props: RenderComponentProps) {
  const carousel = React.useContext(CarouselContext)!;
  const items = useRenderChildren(props.component, {
    itemsConfig: carousel.dynamicItems,
    context: props.context,
    currentItemId: props.component.id,
  });
  let CarouselV4SlidesContent = _CarouselV4SlidesContent;
  if (shouldUseLiquid(props.context)) {
    CarouselV4SlidesContent = _CarouselV4SlidesContentLiquid;
  }
  return <CarouselV4SlidesContent items={items} {...props} />;
}

const _CarouselV4SlidesContentLiquid: React.FC<
  RenderComponentProps & { items: RenderChildren }
> = ({ component, componentAttributes, context, items }) => {
  const { repeatedIndexPath = ".0", actionHooks } = context;
  const carousel = React.useContext(CarouselContext)!;
  const slides = React.useContext(SlidesContext)!;
  if (items.length === 0) {
    return (
      <Container
        component={component}
        componentAttributes={componentAttributes}
        context={context}
      />
    );
  }

  return (
    // Note (Noah, 2024-06-29, USE-992): This liquid chunk needs to be outside of SlideTrack
    // because SlideTrack collects specific types of children and doesn't always render them.
    // Note (TODO, 2024-06-29, REPL-12378): We should throw an error if the track finds
    // unexpected children.
    <ReploLiquidChunk>
      <CarouselPrimitive.SlideTrack
        style={getTrackInlineStyles({ orientation: carousel.orientation })}
      >
        {"{% for reploProductImage in product.images %}"}
        {`{% case forloop.index0 %}`}
        {items.map((item, childIndex) => (
          <>
            {`{% when ${childIndex} %}`}
            <CarouselPrimitive.Slide
              // biome-ignore lint/correctness/useJsxKeyInIterable: allow no jsx key in iterable
              data-component-id={item.component.id}
              className={slides.slideClassName}
              style={getSlideInlineStyles({
                orientation: carousel.orientation,
                autoWidth: slides.autoWidth,
              })}
            >
              <CarouselSlideChild
                actionHooks={actionHooks}
                component={item.component}
                context={item.context}
                index={childIndex}
                isActiveItem={true}
                repeatedIndexPath={repeatedIndexPath}
                parentContext={context}
              />
            </CarouselPrimitive.Slide>
          </>
        ))}
        {`{% endcase %}`}
        {"{% endfor %}"}
      </CarouselPrimitive.SlideTrack>
    </ReploLiquidChunk>
  );
};

function CarouselSlideChild({
  actionHooks,
  component,
  context,
  index,
  isActiveItem,
  parentContext,
  repeatedIndexPath,
}: {
  actionHooks: AlchemyContext["actionHooks"];
  component: Component;
  context: AlchemyContext;
  index: number;
  isActiveItem: boolean;
  parentContext?: AlchemyContext;
  repeatedIndexPath: string;
}) {
  const group = React.useContext(GroupContext)!;
  return (
    <ReploComponent
      component={component}
      context={
        // NOTE (Chance 2024-01-25): I split this into a separate component so
        // that it would be easier to optimize this merge with useMemo. Unsure
        // now if that's doable without changes higher in the tree, but we
        // shouldn't need to do this on every render.
        mergeContext(context, {
          // NOTE (Gabe 2024-01-03): The parent's context must be merged here so
          // that the slides know whether or not they are inside of a
          // productImageCarousel.
          ...parentContext,
          group: {
            isActiveItem,
          },
          actionHooks,
        })
      }
      // Note (Noah, 2024-05-16, USE-958): Repeated index path is for dynamic repetitions -
      // if the carousel is not dynamic, then children are always repeated the 0th time.
      // Thus, if the carousel is dynamic, we'll use the index, otherwise 0.
      // TODO (Noah, 2024-05-16): This does not account for child overrides in dynamic components
      // (e.g. if you add a second child so that the second repetition can be custom-styled).
      // We need to re-think the dynamic repeated index path system to account for this
      repeatedIndexPath={`${repeatedIndexPath}.${group.isDynamic ? index : 0}`}
    />
  );
}

/**
 * Component that renders either a Next or a Previous button.
 */
export const CarouselV4Control: React.FC<RenderComponentProps> = ({
  componentAttributes,
  component,
  context,
}) => {
  const { repeatedIndexPath = ".0" } = context;
  const direction = component.props.direction ?? "next";

  const carousel = React.useContext(CarouselContext);
  const group = React.useContext(GroupContext);

  // Note (Noah, 2024-07-12, USE-1108): If we use dynamic liquid when publishing
  // (e.g. we're a product images carousel) then we won't know at publish time whether
  // we should disable this control or not, since we don't know the number of slides.
  // So, we have to rerender after hydrating to make sure that if we prerendered as
  // disabled, we now render as enabled.
  const usesLiquidWhenPublishing = willUseLiquidWhenPublishing(context);
  const key = useForceRerenderOnMount({ skip: !usesLiquidWhenPublishing });

  if (!carousel || !group) {
    return null;
  }

  function canGoBack() {
    if (!carousel || !group || group.total === 0) {
      return false;
    }
    if (carousel.endBehavior === "loop") {
      return true;
    }
    return carousel.autoWidth
      ? // NOTE (Chance 2024-04-05): For auto-width carousels we don't know the
        // number of slides per page.
        carousel.activeSlideIndex !== 0
      : carousel.activeSlideIndex !== 0 && group.total > carousel.slidesPerPage;
  }

  function canGoNext() {
    if (!carousel || !group || group.total === 0) {
      return false;
    }
    if (carousel.endBehavior === "loop") {
      return true;
    }
    const lastIndex = group.total - 1;
    if (
      carousel.activeSlidePosition === "center" &&
      carousel.activeSlideIndex >= lastIndex
    ) {
      return false;
    }
    if (carousel.autoWidth) {
      return carousel.activeSlideIndex !== lastIndex;
    }
    if (
      group.total <= carousel.slidesPerPage ||
      carousel.activeSlideIndex >= group.total - carousel.slidesPerPage
    ) {
      return false;
    }
    return true;
  }

  const { controller } = carousel;
  let isDisabled = false;
  if (direction === "previous" && !canGoBack()) {
    isDisabled = true;
  } else if (direction === "next" && !canGoNext()) {
    // TODO (Chance 2024-01-09): Similar to in V3, we need to track if the
    // native next button in the main carousel would gets disabled here because
    // that means we're at the end of the carousel. We cannot simply rely on
    // slides size and current index for that matter because there could be a
    // variable amount of slides on the screen at the same time (autoWidth).
    isDisabled = true;
  }

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (isDisabled) {
      return;
    }
    if (direction === "previous") {
      event.preventDefault();
      controller.current?.goBack();
    }
    if (direction === "next") {
      event.preventDefault();
      controller.current?.goNext();
    }
  }

  return (
    <button
      type="button"
      data-replo-component-root="carousel"
      data-replo-part="nav-button"
      data-direction={direction}
      // TODO (Chance 2024-01-08): Add IDs for all carousel slides components
      // since the buttons will control all of them
      // aria-controls="..."
      aria-label={direction === "next" ? "Next slide" : "Previous slide"}
      onClick={handleClick}
      disabled={isDisabled || undefined}
      {...componentAttributes}
      key={`${componentAttributes.key}-${key}`}
    >
      {component.children?.map((child) => {
        return (
          <ReploComponent
            key={child.id}
            component={child}
            context={context}
            repeatedIndexPath={repeatedIndexPath}
          />
        );
      })}
    </button>
  );
};

export const CarouselV4Indicators: React.FC<RenderComponentProps> = (props) => {
  const { componentAttributes, component, context } = props;
  const { actionHooks, repeatedIndexPath = ".0" } = context;
  const template = component.children?.length && component.children[0];
  const carousel = React.useContext(CarouselContext);
  const group = React.useContext(GroupContext);

  // Note (Noah, 2023-12-07, USE-607): We check specifically for the context
  // here instead of using useLiquidAlternate since whether we want to convert
  // to liquid depends on the carousel-specific context
  if (shouldUseLiquid(context)) {
    return <_CarouselV4ProductImageIndicatorsLiquid {...props} />;
  }

  if (!carousel || !group || !template) {
    return null;
  }

  const items = group.items;
  if (items.length === 0) {
    return null;
  }

  return (
    <ul {...componentAttributes}>
      {items.map((item, index) => {
        const childComponent = component.children?.[index] ?? template;
        const key = getSlideIdentifier(item, index, items);
        return (
          <CarouselIndicator
            key={key as React.Key}
            isActiveItem={index === group.currentIndex}
            onClick={() => {
              carousel.controller.current?.goToSlide(index);
            }}
            onKeyDown={(event) => {
              // look at siblings to determine if the list is horizontal or vertical
              // we don't care which sibling we use
              const sibling =
                event.currentTarget.nextElementSibling ??
                event.currentTarget.previousElementSibling;
              let orientation: Orientation = "horizontal";
              if (sibling) {
                const siblingRect = sibling.getBoundingClientRect();
                const currentRect = event.currentTarget.getBoundingClientRect();
                if (siblingRect.top !== currentRect.left) {
                  orientation = "vertical";
                }
              }

              // TODO (Chance 2024-01-24): Implements keyboard controls for
              // toggle buttons. This should be a DS primitive.
              const preventScroll = () => event.preventDefault();
              switch (event.key) {
                case " ":
                case "Enter":
                  preventScroll();
                  event.currentTarget.click();
                  break;
                case "Home": {
                  preventScroll();
                  const first = event.currentTarget.parentElement
                    ?.firstElementChild as HTMLElement | null;
                  first?.focus();
                  break;
                }
                case "End": {
                  preventScroll();
                  const last = event.currentTarget.parentElement
                    ?.lastElementChild as HTMLElement | null;
                  last?.focus();
                  break;
                }
                case "ArrowLeft":
                case "ArrowUp": {
                  const isRoving =
                    orientation === "vertical"
                      ? event.key === "ArrowUp"
                      : event.key === "ArrowLeft";
                  if (isRoving) {
                    preventScroll();
                    const previous = event.currentTarget
                      .previousElementSibling as HTMLElement | null;
                    previous?.focus();
                  }
                  break;
                }
                case "ArrowDown":
                case "ArrowRight": {
                  const isRoving =
                    orientation === "vertical"
                      ? event.key === "ArrowDown"
                      : event.key === "ArrowRight";
                  if (isRoving) {
                    preventScroll();
                    const next = event.currentTarget
                      .nextElementSibling as HTMLElement | null;
                    next?.focus();
                  }
                  break;
                }
                default:
                  return;
              }
            }}
            aria-label={`Go to slide ${index + 1}`}
            actionHooks={actionHooks}
            component={childComponent}
            context={{
              ...item.context,
              // NOTE (Fran 2024-06-19): We need to add the parent's context attributes so we can use those
              // in every children of the carousel inner components.
              attributes: {
                ...context.attributes,
                ...item.context.attributes,
              },
            }}
            repeatedIndexPath={repeatedIndexPath}
            index={index}
          />
        );
      })}
    </ul>
  );
};

const _CarouselV4ProductImageIndicatorsLiquid: React.FC<
  RenderComponentProps
> = ({ componentAttributes, component, context }) => {
  const { actionHooks, repeatedIndexPath = ".0" } = context;
  const template = component.children?.length && component.children[0];
  const carousel = React.useContext(CarouselContext);
  const group = React.useContext(GroupContext);
  if (!carousel || !group || !template) {
    return null;
  }

  const items = group.items;
  if (items.length === 0) {
    return null;
  }

  return (
    <ul {...componentAttributes}>
      <ReploLiquidChunk>
        {"{% for reploProductImage in product.images %}"}
        {`{% case forloop.index0 %}`}
        {component.children?.map((_, childIndex) => {
          const childComponent = component.children?.[childIndex] ?? template;
          // NOTE (Chance 2024-06-04): We aren't guaranteed to have the same
          // number of items as children, so we'll fall back to the first if the
          // item at the current index is undefined.
          const item = items[childIndex] ?? items[0]!;
          return (
            <>
              {`{% when ${childIndex} %}`}
              <CarouselIndicator
                // biome-ignore lint/correctness/useJsxKeyInIterable: allow no jsx key in iterable
                isActiveItem={childIndex === 0}
                actionHooks={actionHooks}
                component={childComponent}
                context={item.context}
                repeatedIndexPath={repeatedIndexPath}
                index={childIndex}
                parentContext={context}
                aria-label={`Go to slide ${childIndex + 1}`}
              />
            </>
          );
        })}
        {`{% else %}`}
        <CarouselIndicator
          index={0}
          isActiveItem={false}
          actionHooks={actionHooks}
          component={template}
          context={items[0]!.context}
          repeatedIndexPath={repeatedIndexPath}
          parentContext={context}
          aria-label="Go to slide 1"
        />
        {`{% endcase %}`}
        {"{% endfor %}"}
      </ReploLiquidChunk>
    </ul>
  );
};

function CarouselIndicator({
  "aria-label": ariaLabel,
  actionHooks,
  component,
  context,
  index,
  isActiveItem,
  onClick,
  onKeyDown,
  parentContext,
  repeatedIndexPath,
}: {
  "aria-label"?: string;
  actionHooks: AlchemyContext["actionHooks"];
  component: Component;
  context: AlchemyContext;
  index: number;
  isActiveItem: boolean;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLLIElement>;
  parentContext?: AlchemyContext;
  repeatedIndexPath: string;
}) {
  return (
    <li
      role="button"
      onKeyDown={onKeyDown}
      onClick={onClick}
      tabIndex={0}
      style={{
        // TODO (Chance 2024-01-31): This breaks accessibility as the button is
        // no longer focusable. Need to fix this in M4 without breaking styles.
        display: "contents",
      }}
      aria-label={ariaLabel}
      aria-current={isActiveItem}
    >
      <ReploComponent
        component={component}
        context={mergeContext(context, {
          // NOTE (Gabe 2024-01-03): The parent's context must be merged here so
          // that the slides know whether or not they are inside of a
          // productImageCarousel.
          ...parentContext,
          group: {
            isActiveItem,
          },
          actionHooks,
        })}
        repeatedIndexPath={`${repeatedIndexPath}.${index}`}
      />
    </li>
  );
}

const normalizeSelectedItem = <T,>(item: T): T extends string ? string : T =>
  (typeof item === "string"
    ? normalizeUrlScheme(item)
    : item) as T extends string ? string : T;

function useAutoSetActiveIndex(props: {
  isDynamic: boolean;
  selectedDynamicItem: any;
  mainSlides: RenderChildren;
  setActiveSlideIndex: (index: number) => void;
}) {
  const {
    isDynamic,
    selectedDynamicItem: _selectedDynamicItem,
    mainSlides,
    setActiveSlideIndex,
  } = props;

  let initialIndex = -1;

  if (isDynamic && _selectedDynamicItem) {
    const normalizedSelectedItem = normalizeSelectedItem(_selectedDynamicItem);
    initialIndex = mainSlides
      .map((slide) => slide.item)
      .findIndex((item) =>
        isEqual(normalizeSelectedItem(item), normalizedSelectedItem),
      );
  }

  // NOTE (Kurt, 2024-08-29): This useEffect runs once on initial render to set the correct
  // active slide index. Removing this will cause the carousel to default to the first slide, which
  // might not be the correct slide depending on the default variant set.
  React.useEffect(() => {
    if (initialIndex !== -1) {
      setActiveSlideIndex(initialIndex);
    }
  }, [initialIndex, setActiveSlideIndex]);
}

function useResetOnDynamicDataChange({
  mainSlides,
  setActiveSlideIndex,
  activeSlideIndex,
  isDynamic,
}: {
  mainSlides: RenderChildren;
  setActiveSlideIndex: (index: number) => void;
  activeSlideIndex: number;
  isDynamic: boolean;
}) {
  const mainSlidesItems = React.useMemo(() => {
    return mainSlides.map((slide) => slide.item);
  }, [mainSlides]);

  useOnValueChange(
    mainSlidesItems,
    () => {
      setActiveSlideIndex(0);
    },
    {
      equalityFn: isEqual,
      // Note (Chance, 2023-06-12) The activeSlideIndex check isn't strict
      // necessary, but it prevents a deep comparison if we are already at our
      // current index.
      shouldSkip: activeSlideIndex === 0 || !isDynamic,
    },
  );
}

/**
 * Looks for a unique identifier for a given slide. If one does not exist, fall
 * back to the index. This can probably be improved for dynamic items where
 * component ID and element ID are not unique but other props are.
 */
function getSlideIdentifier(
  slideChild: RenderChildren[number],
  index: number,
  slideChildren: RenderChildren,
) {
  const matches = new Map<"item" | "componentId" | "elementId", number>();
  for (const child of slideChildren) {
    if (isString(child.item) && child.item === slideChild.item) {
      matches.set("item", (matches.get("item") ?? 0) + 1);
    }
    if (child.component.id === slideChild.component.id) {
      matches.set("componentId", (matches.get("componentId") ?? 0) + 1);
    }
    if (child.context.elementId === slideChild.context.elementId) {
      matches.set("elementId", (matches.get("elementId") ?? 0) + 1);
    }
  }
  if (matches.get("item") === 1) {
    return slideChild.item;
  }
  if (matches.get("componentId") === 1) {
    return slideChild.component.id;
  }
  if (matches.get("elementId") === 1) {
    return slideChild.context.elementId;
  }
  return String(index);
}

function parseInterval(value: unknown) {
  if (isString(value)) {
    const parsed = Number.parseFloat(value);
    return getValidInterval(parsed);
  }
  if (isNumber(value)) {
    return getValidInterval(value);
  }
  return 0;
}

function getValidInterval(interval: number) {
  const FLOOR = 0;
  return Number.isFinite(interval)
    ? Math.max(FLOOR, round(interval, 0))
    : FLOOR;
}

function getActiveSlidePosition(
  activeArea?: "first" | "center" | undefined | null,
): ActiveSlidePosition | null {
  if (activeArea === "first") {
    return "start";
  } else if (activeArea === "center") {
    return "center";
  }
  return null;
}

function useCarouselState<T>(props: {
  key: string;
  initialValue: T;
}): [T, React.Dispatch<React.SetStateAction<T>>] {
  const { key, initialValue } = props;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const sharedStateValue =
    useRuntimeContext(RuntimeHooksContext).useSharedState(key);
  const setSharedState =
    useRuntimeContext(RuntimeHooksContext).useSetSharedState();

  const [state, setState] = React.useState(initialValue);

  const editorSetState: React.Dispatch<React.SetStateAction<T>> =
    React.useCallback(
      (value) => {
        setSharedState?.({ key, value });
      },
      [key, setSharedState],
    );

  if (isEditorApp) {
    const editorState = sharedStateValue ?? initialValue;
    return [editorState, editorSetState];
  }

  return [state, setState];
}

function coerceToInteger(value: string | number | null | undefined) {
  // NOTE (Fran 2024-03-29): We need to check for null and empty string because if we try to parse an empty
  // string or null, it will return NaN. Resulting in some carousel features not working as expected.
  if (value == null || value === "") {
    return null;
  }
  return parseInteger(value);
}

function getSlideInlineStyles({
  orientation,
  autoWidth,
}: {
  orientation: Orientation;
  autoWidth: boolean;
}): React.CSSProperties {
  const isVertical = orientation === "vertical";
  let slideSize: string | undefined;
  if (!autoWidth) {
    const slidesPerPage = "var(--replo-carousel-slides-per-page, 1)";
    slideSize = `calc((100% + ${SLIDE_GAP}) / ${slidesPerPage} - ${SLIDE_GAP})`;
  }
  return {
    // NOTE (Chance 2023-11-20): Important for consistency with v3 styles
    display: "flex",
    height: isVertical ? slideSize : "100%",
    width: isVertical ? "100%" : slideSize,
    padding: 0,
    margin: 0,
    backfaceVisibility: "hidden",
    flexShrink: 0,
    position: "relative",
  };
}

function getTrackInlineStyles({
  orientation,
}: {
  orientation: Orientation;
}): React.CSSProperties {
  const isVertical = orientation === "vertical";
  return {
    // NOTE (Chance 2023-11-20): Styles aren't in the style config because
    // this component is an implementation detail, not an exposed runtime
    // component.
    //
    // NOTE (Chance 2023-11-20): Important for functionality
    transform: "translate3d(0, 0, 0)",
    display: "flex",
    overflow: "visible",
    backfaceVisibility: "hidden",
    gap: SLIDE_GAP,
    // NOTE (Chance 2023-11-20): Important for consistency with v3 styles
    height: isVertical ? undefined : "100%",
    width: isVertical ? "100%" : undefined,
    margin: 0,
    padding: 0,
  };
}

function willUseLiquidWhenPublishing(context: AlchemyContext) {
  return Boolean(
    context.isInsideProductComponent &&
      context.isInsideProductImageCarousel &&
      !context.disableLiquid,
  );
}

function shouldUseLiquid(context: AlchemyContext) {
  return Boolean(context.isPublishing && willUseLiquidWhenPublishing(context));
}

function isInsideProductImageCarousel(items: ItemsConfig | undefined) {
  return (
    items?.type === "productImages" ||
    (items?.type === "inline" &&
      items.valueType === "dynamic" &&
      items.dynamicPath.includes("attributes._product.images"))
  );
}

type SwipeDirection = "up" | "down" | "left" | "right";

type OnGestureProps = {
  swipeDirection: SwipeDirection;
  state: GestureState;
};

type OnSlideClickProps = {
  event: MouseEvent;
  slideElement: HTMLElement;
  slideIndex: number;
  state: GestureState;
};

type UseGestureOptions = {
  allowSwipeWithMouse?: boolean;
  swipeDirection: "x" | "y";
  onSwipe?: ((props: OnGestureProps) => void) | null;
  onSlideClick?: ((event: OnSlideClickProps) => void) | null;
};

export type GestureState = {
  touchStartX: number;
  touchStartY: number;
  touchEndX: number;
  touchEndY: number;
  touchTime: number;
  gestureTriggered: boolean;
};

const initialState: GestureState = {
  touchStartX: 0,
  touchStartY: 0,
  touchEndX: 0,
  touchEndY: 0,
  touchTime: 0,
  gestureTriggered: false,
};

// simplified/adapted from https://gist.github.com/KristofferEriksson/4a406501889d7fb768ed8de101f7be72
function useSlideGestures(
  ref: React.RefObject<HTMLElement>,
  config: UseGestureOptions,
) {
  const {
    onSwipe,
    onSlideClick,
    swipeDirection,
    allowSwipeWithMouse = false,
  } = config;
  const _onSwipe = useEffectEvent(onSwipe);
  const _onSlideClick = useEffectEvent(onSlideClick);
  const gestureStateRef = React.useRef<GestureState>(initialState);
  const hasSwipeHandler = Boolean(onSwipe);
  const hasSlideClickHandler = Boolean(onSlideClick);

  // biome-ignore lint/correctness/useExhaustiveDependencies: extra dep _onSlideClick
  React.useEffect(() => {
    const targetElement = ref.current;
    if (!hasSwipeHandler || !targetElement) {
      return;
    }

    const _window = targetElement.ownerDocument.defaultView;

    function onTouchStart(event: MouseEvent): void;
    function onTouchStart(event: TouchEvent): void;
    function onTouchStart(event: MouseEvent | TouchEvent) {
      const isTouch = isTouchEvent(event);
      const touches = isTouch ? event.touches : [toTouch(event)];
      const touch = touches[0];
      if (!touch) {
        return;
      }
      gestureStateRef.current = {
        ...gestureStateRef.current,
        touchStartX: touch.clientX,
        touchStartY: touch.clientY,
        // Note (Noah, 2024-03-17): Make sure to also reset the touchEndX/Y, otherwise
        // if the container which is being swiped on scrolls during a touch, you might
        // have incorrect/state state which can cause swipes to be triggered incorrectly
        touchEndX: touch.clientX,
        touchEndY: touch.clientY,
        touchTime: Date.now(),
        gestureTriggered: false,
      };
      addGestureListeners();
    }

    function onTouchMove(event: MouseEvent): void;
    function onTouchMove(event: TouchEvent): void;
    function onTouchMove(event: TouchEvent | MouseEvent) {
      const isTouch = isTouchEvent(event);
      const touches = isTouch ? event.touches : [toTouch(event)];
      if (!gestureStateRef.current.gestureTriggered) {
        const touch = touches[0];
        if (!touch) {
          removeGestureListeners();
          return;
        }

        const deltaX = Math.abs(
          touch.clientX - gestureStateRef.current.touchStartX,
        );
        const deltaY = Math.abs(
          touch.clientY - gestureStateRef.current.touchStartY,
        );
        let scrollAxis: "x" | "y" | null = null;
        if (deltaX > deltaY) {
          scrollAxis = "x";
        } else if (deltaX < deltaY) {
          scrollAxis = "y";
        }

        // NOTE Ben 2024-02-22: When we're swiping in the carousel direction, we want to stop
        // the default scroll event, because we're doing own own thing. But if the user is swiping
        // in the alternate direction, they're scrolling, and we should stop tracking the swipe.
        if (scrollAxis === swipeDirection) {
          event.preventDefault();
        } else {
          removeGestureListeners();
          return;
        }

        gestureStateRef.current.touchEndX = touch.clientX;
        gestureStateRef.current.touchEndY = touch.clientY;
      } else {
        removeGestureListeners();
      }
    }

    function onTouchEnd() {
      handleGesture();
      removeGestureListeners();
    }

    function handleGesture() {
      if (gestureStateRef.current.gestureTriggered) {
        return;
      }

      const { touchStartX, touchStartY, touchEndX, touchEndY } =
        gestureStateRef.current;
      const dx = touchEndX - touchStartX;
      const dy = touchEndY - touchStartY;
      let swipeDirection: SwipeDirection | undefined;

      if (dy < -50 && Math.abs(dx) < 50) {
        swipeDirection = "up";
      } else if (dy > 50 && Math.abs(dx) < 50) {
        swipeDirection = "down";
      } else if (dx < -50 && Math.abs(dy) < 50) {
        swipeDirection = "left";
      } else if (dx > 50 && Math.abs(dy) < 50) {
        swipeDirection = "right";
      }

      if (swipeDirection != null) {
        gestureStateRef.current.gestureTriggered = true;
        _onSwipe({
          state: gestureStateRef.current,
          swipeDirection,
        });
      } else {
        gestureStateRef.current.gestureTriggered = false;
      }
    }

    function handleDragStart(event: DragEvent) {
      event.preventDefault();
    }

    const listenerOptions = { passive: false, capture: true };

    function addInitListeners() {
      targetElement!.addEventListener(
        "touchstart",
        onTouchStart,
        listenerOptions,
      );
      if (allowSwipeWithMouse) {
        targetElement!.addEventListener(
          "mousedown",
          onTouchStart,
          listenerOptions,
        );
      }
      targetElement!.addEventListener("dragstart", handleDragStart);
    }

    function removeInitListeners() {
      targetElement!.removeEventListener(
        "touchstart",
        onTouchStart,
        listenerOptions,
      );
      if (allowSwipeWithMouse) {
        targetElement!.removeEventListener(
          "mousedown",
          onTouchStart,
          listenerOptions,
        );
      }
      targetElement!.removeEventListener("dragstart", handleDragStart);
    }

    function addGestureListeners() {
      targetElement!.addEventListener(
        "touchmove",
        onTouchMove,
        listenerOptions,
      );
      targetElement!.addEventListener("touchend", onTouchEnd, listenerOptions);
      targetElement!.addEventListener(
        "touchcancel",
        onTouchEnd,
        listenerOptions,
      );
      if (allowSwipeWithMouse) {
        targetElement!.addEventListener(
          "mousemove",
          onTouchMove,
          listenerOptions,
        );
        targetElement!.addEventListener("mouseup", onTouchEnd, listenerOptions);
        targetElement!.addEventListener(
          "mouseout",
          onTouchEnd,
          listenerOptions,
        );
        _window?.addEventListener("mouseout", onTouchEnd);
      }
    }

    function removeGestureListeners() {
      targetElement!.removeEventListener(
        "touchmove",
        onTouchMove,
        listenerOptions,
      );
      targetElement!.removeEventListener(
        "touchend",
        onTouchEnd,
        listenerOptions,
      );
      targetElement!.removeEventListener(
        "touchcancel",
        onTouchEnd,
        listenerOptions,
      );
      if (allowSwipeWithMouse) {
        targetElement!.removeEventListener(
          "mousemove",
          onTouchMove,
          listenerOptions,
        );
        targetElement!.removeEventListener(
          "mouseup",
          onTouchEnd,
          listenerOptions,
        );
        _window?.removeEventListener("mouseout", onTouchEnd);
      }
    }

    addInitListeners();
    return () => {
      removeGestureListeners();
      removeInitListeners();
    };
  }, [
    ref,
    _onSlideClick,
    _onSwipe,
    hasSwipeHandler,
    allowSwipeWithMouse,
    swipeDirection,
  ]);

  React.useEffect(() => {
    const targetElement = ref.current;
    if (!targetElement || !hasSlideClickHandler) {
      return;
    }

    function handleSlideClick(event: MouseEvent) {
      // NOTE (Chance 2024-05-23): Only register a slide click if the user is
      // not swiping
      if (gestureStateRef.current.gestureTriggered) {
        return;
      }

      // NOTE (Chance 2024-05-23): Make sure the clicked element is actually a
      // slide since we're attaching the handler to the slide list.
      const currentTarget = event.currentTarget as HTMLElement | null;
      const target = event.target as HTMLElement | null;
      if (currentTarget == null || target == null || currentTarget === target) {
        return;
      }

      // NOTE (Chance 2024-05-23): Make sure the clicked element is a slide (or
      // slide child) in the same carousel as the target element.
      //
      // TODO: This is a little fragile and depends on internals of the carousel
      // primitive. All of the gesture handling should be moved there.
      const carouselId = currentTarget.dataset?.reploRootId;
      const slideElement = target.closest<HTMLElement>(
        `[data-replo-root-id='${carouselId}'][data-replo-part='slide']`,
      );
      if (slideElement) {
        const slideIndex =
          slideElement.dataset.slideIndex != null
            ? parseInteger(slideElement.dataset.slideIndex)
            : null;
        if (slideIndex == null) {
          throw new Error(
            `Missing [data-slide-index]. This is probably an error in the carousel primitive implementation.`,
          );
        }
        if (Number.isNaN(slideIndex)) {
          throw new Error(
            `[data-slide-index='${slideElement.dataset.slideIndex}'] is invalid. This is probably an error in the carousel primitive implementation.`,
          );
        }

        _onSlideClick({
          event,
          slideIndex,
          slideElement: slideElement,
          state: gestureStateRef.current,
        });
      }
    }

    targetElement.addEventListener("click", handleSlideClick);
    return () => {
      targetElement.removeEventListener("click", handleSlideClick);
    };
  }, [ref, _onSlideClick, hasSlideClickHandler]);
}

function isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return "touches" in event;
}

function toTouch(mouseEvent: MouseEvent): Touch {
  return {
    identifier: 0,
    target: mouseEvent.target!,
    clientX: mouseEvent.clientX,
    clientY: mouseEvent.clientY,
    screenX: mouseEvent.screenX,
    screenY: mouseEvent.screenY,
    pageX: mouseEvent.pageX,
    pageY: mouseEvent.pageY,
    force: 1,
    radiusX: 1,
    radiusY: 1,
    rotationAngle: 0,
  };
}

type ActionHooks = {
  goToItem: (index: number) => void;
  goToNextItem: () => void;
  goToPrevItem: () => void;
};

type ContextWithSlides = AlchemyContext & {
  actionHooks: ActionHooks;
  group: GroupContextValue;
  isInsideProductImageCarousel: boolean;
};
