import type { StoreProduct } from "replo-runtime/shared/types";
import type { ReploElementType } from "schemas/generated/element";

import * as React from "react";

import { elementTypeToEditorData } from "@editor/components/editor/element";
import { useSpecificStoreProducts } from "@editor/hooks/useStoreProducts";
import {
  selectDraftElementId,
  selectDraftElementIsPublished,
  selectDraftElementShopifyBlogId,
  selectDraftElementShopifyPagePath,
  selectDraftElementType,
  selectProject,
  selectProjectId,
} from "@editor/reducers/core-reducer";
import { selectTemplateEditorProduct } from "@editor/reducers/template-reducer";
import { useEditorSelector } from "@editor/store";
import { getPageUrl } from "@editor/utils/element";
import { getStoreData } from "@editor/utils/project-utils";
import { trpc } from "@editor/utils/trpc";
import useGetStoreNameAndUrl from "@hooks/useGetStoreNameAndUrl";

import { skipToken } from "@tanstack/react-query";
import { mapNull } from "replo-runtime/shared/utils/optional";
import { fakeProducts } from "replo-runtime/store/utils/fakeProducts";
import { exhaustiveSwitch, isNullish } from "replo-utils/lib/misc";

function getTooltipLabel(opts: {
  elementType: ReploElementType;
  urlIsDisabled: boolean;
  isProductTemplateWithFakeProduct: boolean;
  isShopifyStoreNotConnected?: boolean;
}) {
  const {
    elementType,
    urlIsDisabled,
    isProductTemplateWithFakeProduct,
    isShopifyStoreNotConnected,
  } = opts;

  const singularDisplayName =
    elementTypeToEditorData[elementType].singularDisplayName;

  if (isShopifyStoreNotConnected) {
    return `Connect Shopify to View Live ${singularDisplayName}`;
  }

  if (isProductTemplateWithFakeProduct) {
    return "Select a Non-Placeholder Product to View Live Template";
  }

  if (urlIsDisabled) {
    return `Publish to View Live ${singularDisplayName}`;
  }

  if (elementType === "shopifySection") {
    return "View in Theme Editor";
  }

  return `View Live ${singularDisplayName}`;
}

const getElementUrls = (
  {
    id,
    type,
    shopifyPagePath,
  }: {
    id: string | undefined;
    type: ReploElementType;
    shopifyPagePath: string | undefined;
  },
  storeUrl: string | undefined,
  customDomain: string | undefined,
  product: StoreProduct | null | undefined,
  blogData: { handle: string } | undefined,
  isLoading = false,
  isLoadingProducts = false,
): {
  shopifyUrl: string | undefined;
  customDomainUrl: string | undefined;
} => {
  return exhaustiveSwitch({ type })({
    page: () => ({
      shopifyUrl: getPageUrl({ storeUrl, shopifyPagePath }),
      customDomainUrl: getPageUrl({
        storeUrl: customDomain,
        shopifyPagePath,
      }),
    }),
    shopifyArticle: () => {
      const hasData = blogData?.handle && shopifyPagePath && !isLoading;

      return hasData
        ? {
            shopifyUrl: `https://${storeUrl}/blogs/${blogData.handle}/${shopifyPagePath}`,
            customDomainUrl: `https://${customDomain}/blogs/${blogData.handle}/${shopifyPagePath}`,
          }
        : {
            shopifyUrl: undefined,
            customDomainUrl: undefined,
          };
    },
    shopifySection: () => ({
      shopifyUrl: `https://${storeUrl}/admin/themes/current/editor`,
      customDomainUrl: `https://${customDomain}/admin/themes/current/editor`,
    }),
    shopifyProductTemplate: () => {
      const hasData = product && !isLoadingProducts;

      return hasData
        ? {
            shopifyUrl: `https://${storeUrl}/products/${product.handle}?view=replo.${id}`,
            customDomainUrl: `https://${customDomain}/products/${product.handle}?view=replo.${id}`,
          }
        : { shopifyUrl: undefined, customDomainUrl: undefined };
    },
  });
};

export function useTemplateProductPublishingInfo() {
  const draftElementType = useEditorSelector(selectDraftElementType);
  const templateEditorProduct = useEditorSelector(selectTemplateEditorProduct);
  const productIds = React.useMemo(() => {
    const ids = [];
    if (templateEditorProduct?.productId) {
      ids.push(Number(templateEditorProduct.productId));
    }
    return ids;
  }, [templateEditorProduct?.productId]);

  const { products, isLoading: isLoadingProducts } =
    useSpecificStoreProducts(productIds);

  const product = products
    ? products.find(
        (product) =>
          String(product.id) ===
          mapNull(templateEditorProduct?.productId, (value) => String(value)),
      )
    : null;
  const isProductTemplateWithFakeProduct =
    draftElementType === "shopifyProductTemplate" &&
    product &&
    fakeProducts.some((p) => String(p.id) === String(product.id));
  return { product, isLoadingProducts, isProductTemplateWithFakeProduct };
}

export default function usePublishedInfo() {
  const draftElementType = useEditorSelector(selectDraftElementType);
  const draftElementIsPublished = useEditorSelector(
    selectDraftElementIsPublished,
  );
  const draftElementShopifyPagePath = useEditorSelector(
    selectDraftElementShopifyPagePath,
  );
  const draftElementBlogId = useEditorSelector(selectDraftElementShopifyBlogId);
  const projectId = useEditorSelector(selectProjectId);

  const { data: blogData, isLoading } = trpc.shopify.getBlogById.useQuery(
    isNullish(projectId) || isNullish(draftElementBlogId)
      ? skipToken
      : {
          projectId: projectId!,
          blogId: draftElementBlogId!,
        },
  );

  const { storeUrl, customDomain } = useGetStoreNameAndUrl();
  const draftElementId = useEditorSelector(selectDraftElementId);
  const project = useEditorSelector(selectProject);
  const store = getStoreData(project);
  const hasConnectedStore = Boolean(store?.shopifyUrl);

  const path = exhaustiveSwitch({ type: draftElementType })({
    page: () =>
      `${elementTypeToEditorData[draftElementType].path}${draftElementShopifyPagePath}`,
    shopifyArticle: () => {
      if (!isLoading) {
        return `${elementTypeToEditorData[draftElementType].path}${blogData?.handle}/${draftElementShopifyPagePath}`;
      }
      return null;
    },
    shopifySection: () => null,
    shopifyProductTemplate: () => null,
  });

  const { product, isLoadingProducts, isProductTemplateWithFakeProduct } =
    useTemplateProductPublishingInfo();

  const { shopifyUrl, customDomainUrl } = getElementUrls(
    {
      type: draftElementType,
      id: draftElementId,
      shopifyPagePath: draftElementShopifyPagePath,
    },
    storeUrl,
    customDomain,
    product,
    blogData,
    isLoading,
    isLoadingProducts,
  );

  const urlIsDisabled =
    !Boolean(shopifyUrl) ||
    !draftElementIsPublished ||
    isProductTemplateWithFakeProduct ||
    !hasConnectedStore;
  const tooltipLabel = getTooltipLabel({
    elementType: draftElementType ?? "page",
    urlIsDisabled: urlIsDisabled ?? false,
    isProductTemplateWithFakeProduct: isProductTemplateWithFakeProduct ?? false,
    isShopifyStoreNotConnected: !hasConnectedStore,
  });

  return {
    shopifyUrl: draftElementIsPublished ? shopifyUrl : undefined,
    customDomainUrl: draftElementIsPublished ? customDomainUrl : undefined,
    path,
    urlIsDisabled: urlIsDisabled ?? !draftElementIsPublished,
    tooltipLabel,
  };
}
