import * as React from "react";

import * as ScrollArea from "@radix-ui/react-scroll-area";

type Orientation = "horizontal" | "vertical" | "both";

type ScrollableProps = {
  className?: string;
  type?: Orientation;
  shouldShowScrollbar?: boolean;
};

const Scrollable: React.FC<React.PropsWithChildren<ScrollableProps>> = ({
  children,
  className,
  type = "both",
  shouldShowScrollbar = true,
}) => (
  <ScrollArea.Root className={className} type="hover">
    <ScrollArea.Viewport className="h-full w-full">
      {children}
    </ScrollArea.Viewport>
    {/* https://www.radix-ui.com/docs/primitives/components/scroll-area */}
    {(type === "vertical" || type === "both") && (
      <ScrollArea.Scrollbar
        orientation="vertical"
        className="z-max bg-gray-100 hover:bg-gray-200"
        style={{
          display: shouldShowScrollbar ? "flex" : "none",
          // ensures no selection
          userSelect: "none",
          // disable browser handling of all panning and zooming gestures on touch devices
          touchAction: "none",
          padding: 2,
          transition: "background 160ms ease-out",
          width: 10,
        }}
      >
        <ScrollArea.Thumb
          className="bg-gray-300 hover:bg-gray-400"
          style={{
            flex: 1,
            borderRadius: 10,
            position: "relative",
          }}
        />
      </ScrollArea.Scrollbar>
    )}
    {(type === "horizontal" || type === "both") && (
      <ScrollArea.Scrollbar
        orientation="horizontal"
        className="z-max bg-gray-100 hover:bg-gray-200"
        style={{
          display: shouldShowScrollbar ? "flex" : "none",
          // ensures no selection
          userSelect: "none",
          // disable browser handling of all panning and zooming gestures on touch devices
          touchAction: "none",
          padding: 2,
          transition: "background 160ms ease-out",
          height: 10,
        }}
      >
        <ScrollArea.Thumb
          className="bg-gray-300 hover:bg-gray-400"
          style={{
            flex: 1,
            borderRadius: 10,
            position: "relative",
          }}
        />
      </ScrollArea.Scrollbar>
    )}
    <ScrollArea.Corner />
  </ScrollArea.Root>
);

export default Scrollable;
