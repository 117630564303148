import type {
  ObjectFitType,
  ObjectPositionXType,
  ObjectPositionYType,
} from "@editor/types/object-fit";
import type { AssetLoadingType } from "replo-runtime/shared/asset-loading";

import * as React from "react";

import ChevronMenuIndicator from "@common/designSystem/ChevronMenuIndicator";
import DynamicDataButton from "@common/designSystem/DynamicDataButton";
import InlineAssetSelector from "@common/designSystem/InlineAssetSelector";
import { Input } from "@common/designSystem/Input";
import Selectable from "@common/designSystem/Selectable";
import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import ControlGroup from "@editor/components/editor/page/element-editor/components/extras/ControlGroup";
import { DynamicDataValueIndicator } from "@editor/components/editor/page/element-editor/components/extras/DynamicDataValueIndicator";
import ModifierLabel from "@editor/components/editor/page/element-editor/components/extras/ModifierLabel";
import { LengthInputSelector } from "@editor/components/editor/page/element-editor/components/modifiers/LengthInputModifier";
import { DraggingTypes } from "@editor/utils/editor";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";

import classNames from "classnames";
import startCase from "lodash-es/startCase";
import useMeasure from "react-use-measure";
import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";
import { twMerge } from "tailwind-merge";

// Note (Evan, 2024-04-26): Support either passing in { allowsChangingAltText, altTextValue, onChangeAltText, altTextControlLabel }
// to use the default LabeledControl or a altTextComponent to be rendered as a child (composition ftw).
type AltTextValueProps = {
  altTextValue?: string;
  allowsChangingAltText?: boolean;
  onChangeAltText?(value: string): void;
  altTextControlLabel?: string;
  altTextComponent?: undefined;
};

type AltTextComponentProps = {
  altTextValue?: undefined;
  allowsChangingAltText?: undefined;
  onChangeAltText?: undefined;
  altTextControlLabel?: undefined;
  altTextComponent: React.ReactNode;
};

type AltTextProps = AltTextValueProps | AltTextComponentProps;

type AssetPickerProps = {
  assetType: "image" | "video";
  url?: string;
  emptyTitle: string;
  selectAssetTitle: string;
  changeAssetTitle: string;
  objectPositionValue?: { x?: string; y?: string };
  objectFitValue?: ObjectFitType;
  assetLoadingValue?: AssetLoadingType;
  onClickSelectAsset(): void;
  onChangeObjectFit?(value: ObjectFitType): void;
  onChangeAssetLoading?(value: AssetLoadingType): void;
  onChangeObjectPositionX?(value: ObjectPositionXType): void;
  onChangeObjectPositionY?(value: ObjectPositionYType): void;
  allowsSettingDynamicData?: boolean;
  onClickDynamicDataForUrl?(): void;
  onClickDynamicDataForAltText?(): void;
  onClickDynamicDataForPoster?(): void;
  onClickSelectAssetForPoster?(): void;
  onPosterInputChange?(value: string): void;
  componentId?: string;
  urlIsDynamicData?: boolean;
  templateValue?: string;
  onClickRemoveDynamicData?(): void;
  onChangeAssetLoadingPoster?(value: string): void;
  onRemovePoster?(): void;
  posterSrc?: string;
} & AltTextProps;

type PreviewProps = {
  url: string;
  objectFitValue?: ObjectFitType;
  objectPositionXValue?: string;
  objectPositionYValue?: string;
};

const loadingOptions = [
  { value: "lazy", label: "Lazy: Loads on scroll" },
  { value: "eager", label: "Eager: Loads with page" },
];

const objectFitOptions = [
  { value: "fill", label: "Stretch To Fill" },
  { value: "contain", label: "Contain In Bounds" },
  { value: "cover", label: "Resize To Fill" },
  { value: "none", label: "Don't Resize" },
  { value: "scale-down", label: "Scale Down" },
];

const AssetPicker = ({
  assetType,
  url,
  emptyTitle,
  selectAssetTitle,
  changeAssetTitle,
  objectPositionValue,
  objectFitValue,
  assetLoadingValue,
  onClickSelectAsset,
  onChangeObjectFit,
  onChangeAssetLoading,
  onChangeObjectPositionX,
  onChangeObjectPositionY,
  altTextControlLabel = "Alt Text",
  allowsChangingAltText = false,
  onChangeAltText,
  allowsSettingDynamicData = false,
  onClickDynamicDataForUrl,
  onClickDynamicDataForAltText,
  onClickDynamicDataForPoster,
  altTextValue,
  componentId,
  urlIsDynamicData = false,
  templateValue,
  onClickRemoveDynamicData,
  onChangeAssetLoadingPoster,
  onRemovePoster,
  posterSrc,
  onClickSelectAssetForPoster,
  onPosterInputChange,
  altTextComponent,
}: AssetPickerProps) => {
  const [objectPositionXValue, setObjectPositionXValue] = React.useState<
    ObjectPositionXType | undefined
  >(objectPositionValue?.x);
  const [objectPositionYValue, setObjectPositionYValue] = React.useState<
    ObjectPositionYType | undefined
  >(objectPositionValue?.y);
  const [selectableRef, { width }] = useMeasure({ offsetSize: true });

  function _onSelectX(value: ObjectPositionXType) {
    setObjectPositionXValue(value);
    onChangeObjectPositionX?.(value);
  }

  function _onSelectY(value: ObjectPositionYType) {
    setObjectPositionYValue(value);
    onChangeObjectPositionY?.(value);
  }

  const objectPositionXOptions = [
    {
      id: "left",
      type: "leaf" as const,
      value: "left",
      title: "Left",
      onSelect: () => _onSelectX("left"),
    },
    {
      id: "center",
      type: "leaf" as const,
      value: "center",
      title: "Center",
      onSelect: () => _onSelectX("center"),
    },
    {
      id: "right",
      type: "leaf" as const,
      value: "right",
      title: "Right",
      onSelect: () => _onSelectX("right"),
    },
  ];

  const objectPositionYOptions = [
    {
      id: "top",
      type: "leaf" as const,
      title: "Top",
      onSelect: () => _onSelectY("top"),
    },
    {
      id: "center",
      type: "leaf" as const,
      title: "Center",
      onSelect: () => _onSelectY("center"),
    },
    {
      id: "bottom",
      type: "leaf" as const,
      title: "Bottom",
      onSelect: () => _onSelectY("bottom"),
    },
  ];

  const onSelectAsset = (isImagePreview: boolean) => {
    if (urlIsDynamicData && isImagePreview) {
      onClickDynamicDataForUrl?.();
    } else if (!urlIsDynamicData) {
      onClickSelectAsset();
    }
  };

  const altTextInputProps = useOverridableInput({
    value: altTextValue ?? "",
    onValueChange: onChangeAltText,
  });

  return (
    <div className="flex flex-col gap-2">
      <div
        onClick={() => onSelectAsset(true)}
        className="mb-2 flex h-[150px] cursor-pointer"
      >
        {url && assetType === "image" && (
          <ImagePreview url={url} objectFitValue={objectFitValue} />
        )}
        {url && assetType === "video" && (
          <VideoPreview url={url} objectFitValue={objectFitValue} />
        )}
        {!url && <EmptyUrlPreview emptyTitle={emptyTitle} />}
      </div>
      {urlIsDynamicData ? (
        <DynamicDataValueIndicator
          type="image"
          templateValue={templateValue ?? "Dynamic Image"}
          onClick={() => onClickDynamicDataForUrl?.()}
          componentId={componentId}
          onRemove={onClickRemoveDynamicData}
        />
      ) : (
        <div className="flex h-6 gap-2">
          <div
            id="asset-picker-change-image-button"
            onClick={() => onSelectAsset(false)}
            className={twMerge(
              classNames(
                "flex w-full cursor-pointer items-center justify-center rounded bg-subtle",
                {
                  "cursor-not-allowed bg-disbled": urlIsDynamicData,
                },
              ),
            )}
          >
            <span
              className={twMerge(
                classNames("text-xs text-default", {
                  "text-disabled": urlIsDynamicData,
                }),
              )}
            >
              {url ? changeAssetTitle : selectAssetTitle}
            </span>
          </div>
          {allowsSettingDynamicData && (
            <DynamicDataButton onClick={() => onClickDynamicDataForUrl?.()} />
          )}
        </div>
      )}
      {(Boolean(onChangeObjectFit) || allowsChangingAltText) && (
        <div className="flex flex-col w-full gap-2">
          {onChangeObjectFit ? (
            <ControlGroup label="Object Fit">
              <Selectable
                placeholder="Don't Resize"
                options={objectFitOptions}
                value={objectFitValue}
                defaultValue={styleAttributeToEditorData.objectFit.defaultValue}
                onSelect={(value: ObjectFitType) => onChangeObjectFit(value)}
                isDisabled={!url}
              />
            </ControlGroup>
          ) : null}
          {onChangeAssetLoading ? (
            <ControlGroup label="Loading">
              <Selectable
                placeholder="Loading"
                options={loadingOptions}
                value={assetLoadingValue}
                defaultValue="eager"
                onSelect={(value: AssetLoadingType) =>
                  onChangeAssetLoading(value)
                }
                labelClassName="text-ellipsis whitespace-nowrap overflow-hidden max-w-28"
                className="max-w-40"
              />
            </ControlGroup>
          ) : null}
          {onChangeAssetLoadingPoster && assetLoadingValue === "lazy" ? (
            <ControlGroup label="Poster" className="items-start">
              <div className="flex flex-col gap-2 align-start items-start">
                {isDynamicDataValue(posterSrc) ? (
                  <DynamicDataValueIndicator
                    type="image"
                    templateValue={posterSrc ?? null}
                    onClick={() => onClickDynamicDataForPoster?.()}
                    onRemove={() => onRemovePoster?.()}
                    componentId={componentId}
                  />
                ) : (
                  <InlineAssetSelector
                    size="sm"
                    emptyTitle="Select Poster"
                    onClickDynamicData={onClickDynamicDataForPoster}
                    allowsDynamicData
                    onClickSelectAsset={() => onClickSelectAssetForPoster?.()}
                    onRemoveAsset={() => onRemovePoster?.()}
                    allowRemoveAsset={Boolean(onRemovePoster)}
                    asset={
                      posterSrc
                        ? {
                            type: "image",
                            src: posterSrc,
                          }
                        : undefined
                    }
                    onInputChange={onPosterInputChange}
                  />
                )}
                <p className="text-xs text-subtle font-normal">
                  Poster image will be shown while the video is loading.
                </p>
              </div>
            </ControlGroup>
          ) : null}
          {Boolean(onChangeObjectPositionX) &&
          Boolean(onChangeObjectPositionY) ? (
            <div className="flex gap-2 flex-col w-full">
              <div className="w-full" ref={selectableRef}>
                <LengthInputSelector
                  value={
                    objectPositionXValue?.includes("px") ||
                    objectPositionXValue?.includes("%")
                      ? objectPositionXValue
                      : startCase(objectPositionXValue)
                  }
                  label={<ModifierLabel label="X Position" />}
                  onChange={_onSelectX}
                  endEnhancer={() => (
                    <ChevronMenuIndicator
                      items={objectPositionXOptions}
                      menuWidth={width}
                    />
                  )}
                  draggingType={DraggingTypes.Vertical}
                  dragTrigger="label"
                  field="PositionX"
                  placeholder="center"
                  metrics={["px", "%", "inherit"]}
                  allowedNonUnitValues={["left", "center", "right"]}
                  isDisabled={!url}
                  previewProperty="objectPosition"
                  previewSubProperty="objectPositionX"
                  resetValue="center"
                />
              </div>
              <div className="w-full">
                <LengthInputSelector
                  value={
                    objectPositionYValue?.includes("px") ||
                    objectPositionYValue?.includes("%")
                      ? objectPositionYValue
                      : startCase(objectPositionYValue)
                  }
                  label={<ModifierLabel label="Y Position" />}
                  onChange={_onSelectY}
                  endEnhancer={() => (
                    <ChevronMenuIndicator
                      items={objectPositionYOptions}
                      menuWidth={width}
                    />
                  )}
                  draggingType={DraggingTypes.Vertical}
                  dragTrigger="label"
                  field="PositionY"
                  placeholder="center"
                  metrics={["px", "%", "inherit"]}
                  allowedNonUnitValues={["top", "center", "bottom"]}
                  isDisabled={!url}
                  previewProperty="objectPosition"
                  previewSubProperty="objectPositionY"
                  resetValue="center"
                />
              </div>
            </div>
          ) : null}
          {altTextComponent ??
            (allowsChangingAltText ? (
              <div className="w-full">
                <ControlGroup label={altTextControlLabel}>
                  {altTextValue?.includes("{{") && (
                    <DynamicDataValueIndicator
                      type="text"
                      templateValue={altTextValue ?? ""}
                      onClick={() => onClickDynamicDataForAltText?.()}
                      onRemove={() => onChangeAltText?.("")}
                      componentId={componentId}
                    />
                  )}
                  {!altTextValue?.includes("{{") && (
                    <div className="flex gap-2">
                      <div className="flex-grow">
                        <Input {...altTextInputProps} />
                      </div>
                      {allowsSettingDynamicData && (
                        <DynamicDataButton
                          onClick={() => onClickDynamicDataForAltText?.()}
                        />
                      )}
                    </div>
                  )}
                </ControlGroup>
              </div>
            ) : null)}
        </div>
      )}
    </div>
  );
};

const ImagePreview = ({ url, objectFitValue }: PreviewProps) => {
  return (
    <img
      src={url}
      alt="image"
      className="h-full w-full rounded object-cover object-center shadow-symmetrical"
      style={{
        objectFit: objectFitValue,
      }}
    />
  );
};

const VideoPreview = ({ url, objectFitValue }: PreviewProps) => {
  return (
    <video
      className="h-full w-full rounded object-cover object-center"
      style={{
        objectFit: objectFitValue,
      }}
      src={url}
    />
  );
};

const EmptyUrlPreview = ({ emptyTitle }: { emptyTitle: string }) => {
  return (
    <div className="flex h-full w-full items-center justify-center rounded bg-subtle">
      <span className="text-xs text-subtle">{emptyTitle}</span>
    </div>
  );
};

export default AssetPicker;
