import * as React from "react";

import { selectIsPreviewMode } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import { selectCanvasArea } from "./canvas-reducer";
import { useSetCandidateNodeFromPoint } from "./useCandidateNode";

export function useCanvasEnterLeaveMouseHandlers() {
  const canvasArea = useEditorSelector(selectCanvasArea);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);

  const { setCandidateNodeFromPoint, setCandidateNode } =
    useSetCandidateNodeFromPoint();

  const updateCandidateNodeOnMouseMove = React.useCallback(
    (e: MouseEvent) => {
      setCandidateNodeFromPoint(e.clientX, e.clientY);
    },
    [setCandidateNodeFromPoint],
  );

  React.useEffect(() => {
    if (isPreviewMode) {
      return;
    }

    function handleMouseLeave() {
      setCandidateNode(null, null);
    }

    // NOTE (Chance 2024-05-05): Casting window because TS struggles with the
    // event type overload if the element is typed as `HTMLElement | Window`.
    // MouseEvent is correct in either case.
    const _window = window as unknown as HTMLElement;
    const mouseEventsElement = canvasArea ?? _window;

    mouseEventsElement.addEventListener("mouseleave", handleMouseLeave);
    mouseEventsElement.addEventListener(
      "mousemove",
      updateCandidateNodeOnMouseMove,
    );
    return () => {
      mouseEventsElement.removeEventListener("mouseleave", handleMouseLeave);
      mouseEventsElement.removeEventListener(
        "mousemove",
        updateCandidateNodeOnMouseMove,
      );
    };
  }, [
    canvasArea,
    isPreviewMode,
    setCandidateNode,
    updateCandidateNodeOnMouseMove,
  ]);
}
