import type { ChartConfig } from "@replo/design-system/components/shadcn/core/chart";
import type {
  LineChartData,
  LineChartDataPoint,
} from "@replo/design-system/components/shadcn/LineChart";

import { DEFAULT_DASHED_SUFFIX } from "@replo/design-system/components/shadcn/constants";

function getDashedCompatibleRow(
  row: LineChartDataPoint,
  suffix: string,
  isSolid: boolean,
  currentKey: string,
): LineChartDataPoint {
  if (currentKey !== "x" && row[currentKey] !== undefined) {
    const newRow: LineChartDataPoint = { ...row };
    newRow[currentKey + suffix] = row[currentKey] as string | number | null;
    newRow[currentKey] = isSolid
      ? (row[currentKey] as string | number | null)
      : null;
    return newRow;
  }
  return row;
}

// NOTE (Kurt, 2024-09-11): We use this function to inject dashed line points for a specified number
// of intervals. This is useful for displaying a given number of intervals from the end of the chart
// with a dashed line instead of a solid line.
export function getLineChartDashCompatibleData(
  data: LineChartData,
  chartConfig: ChartConfig,
): LineChartData {
  return data.map((row, idx) => {
    return Object.entries(chartConfig.lines).reduce(
      (newRow, [key, currentLine]) => {
        if (currentLine && currentLine.dashed) {
          const { numberOfTicks } = currentLine.dashed;
          const suffix = currentLine.dashed?.suffix ?? DEFAULT_DASHED_SUFFIX;
          const isSolid = idx < data.length - numberOfTicks;
          return getDashedCompatibleRow(newRow, suffix, isSolid, key);
        }
        return newRow;
      },
      row,
    );
  });
}
