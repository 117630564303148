import type { StateSetter } from "./context";

import React from "react";

import { useRequiredContext } from "replo-utils/react/context";
import { useStore } from "zustand";

import { RuntimeActiveStateContext } from "./context";

/**
 * Returns a callback which should be called whenever the active state runtime for a component
 * is created. The callback accepts a function, which should update the active Replo State id
 * for the given component.
 */
export const useRegisterStateSetter = (componentId: string) => {
  const store = useRequiredContext(RuntimeActiveStateContext);
  const registerActiveStateSetter = useStore(
    store,
    (state) => state.actions.registerActiveStateSetter,
  );
  const deregisterActiveStateSetter = useStore(
    store,
    (state) => state.actions.deregisterActiveStateSetter,
  );
  return {
    register: React.useCallback(
      (setter: StateSetter) => {
        registerActiveStateSetter(componentId, setter);
      },
      [registerActiveStateSetter, componentId],
    ),
    deregister: React.useCallback(() => {
      deregisterActiveStateSetter(componentId);
    }, [deregisterActiveStateSetter, componentId]),
  };
};

/**
 * Returns a function which can be called to set the active Replo State id for a given component.
 * This should only be used in the runtime. If you want to override a state in the editor, use
 * the RuntimeHooksContext overrides.
 */
export const useSetActiveStateId = (componentId: string) => {
  const store = useRequiredContext(RuntimeActiveStateContext);
  const setActiveStateId = useStore(
    store,
    (state) => state.activeStateSetters[componentId],
  );
  return setActiveStateId;
};

export const useRuntimeActiveStateStore = () => {
  return useRequiredContext(RuntimeActiveStateContext);
};
