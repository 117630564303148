import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import {
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { useProductFromProps } from "../../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedLiquidReviewIntegrations } from "../SharedLiquidReviewIntegrations";

const JUDGE_PRODUCT_RATING_WIDGET_LIQUID_SOURCE = `
  <!-- Start of Judge.me code -->
    <div style='{{ jm_style }}' class='jdgm-widget jdgm-preview-badge' data-id='{{ product.id }}'>
      {{ product.metafields.judgeme.badge }}
    </div>
  <!-- End of Judge.me code -->
`;

function JudgeProductRatingWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const product = useProductFromProps(component.props, context);
  const liquidSourceWithProductAssignment = wrapProductAssignmentToLiquidSource(
    {
      product,
      liquidSource: JUDGE_PRODUCT_RATING_WIDGET_LIQUID_SOURCE,
      context: props.context,
    },
  );
  return (
    <SharedLiquidReviewIntegrations
      attributes={componentAttributes}
      component={component}
      liquidSource={liquidSourceWithProductAssignment}
      isProductRating
      placeholder="Judge Product Rating Widget will appear here"
      shouldHavePlaceHolder={isEditorApp || !product}
      appName="Judge.me"
      context={context}
    />
  );
}

export default JudgeProductRatingWidget;
