import type { To } from "react-router-dom";

import * as React from "react";

import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { routes } from "@editor/utils/router";

import Tooltip from "@replo/design-system/components/tooltip";
import classNames from "classnames";
import { BsSliders } from "react-icons/bs";
import { generatePath, Link } from "react-router-dom";

const ActionButtons = () => {
  const projectId = useCurrentProjectId();
  return (
    <div className="flex items-center rounded gap-1 text-default">
      <ActionLink
        tooltip="Project Settings"
        disabled={!projectId}
        to={
          projectId ? generatePath(routes.editor.settings, { projectId }) : "."
        }
      >
        <BsSliders size={20} />
      </ActionLink>
    </div>
  );
};

function ActionLink({
  children,
  disabled = false,
  onClick,
  to,
  tooltip,
  state,
}: React.PropsWithChildren<{
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  state?: any;
  to: To;
  tooltip: string;
}>) {
  return (
    <Tooltip content={tooltip} triggerAsChild>
      <Link
        className={classNames("p-2 rounded text-center", {
          "text-slate-300 cursor-not-allowed pointer-events-none": disabled,
          "hover:bg-slate-100": !disabled,
        })}
        to={to}
        aria-label={tooltip}
        aria-disabled={disabled || undefined}
        onClick={onClick}
        state={state}
      >
        {children}
      </Link>
    </Tooltip>
  );
}

export default ActionButtons;
