import * as React from "react";

import { sanityApi } from "@editor/reducers/sanity-reducer";
import { useEditorDispatch } from "@editor/store";

// TODO (Evan, 2024-04-16): We should try to generalize this to take an arbitrary Api -
// I took a crack at this but it got tricky, bailing out for now.
export function usePrefetchBillingInfoImmediately() {
  const dispatch = useEditorDispatch();
  React.useEffect(() => {
    dispatch(
      sanityApi.util.prefetch(
        "getBillingInformation",
        {},
        {
          // Note (Evan, 2024-04-16): Refetch if the results are > 1 day old
          ifOlderThan: 60 * 60 * 24,
        },
      ),
    );
  }, [dispatch]);
}
