import * as React from "react";

import useCurrentDragType from "./useCurrentDragType";

/**
 * Hook to get a dynamic value for the CSS overflow property, based on the
 * current state of the dragging action. This helps mitigate a known CSS
 * limitation, which is that different values of overflow-x and overflow-y
 * on the same element are handled unexpectedly by the browser. Any value
 * set to visible will be auto-converted to auto as soon as the other value
 * is not visible.
 * https://stackoverflow.com/a/6433475
 */
function useDynamicCSSOverflow() {
  const { currentDragType } = useCurrentDragType();
  const elementRef = React.useRef<HTMLDivElement | null>(null);
  const scrollTop = currentDragType ? elementRef.current?.scrollTop : null;

  return {
    elementRef,
    offsetY: -1 * (scrollTop ?? 0),
    overflowClassname:
      scrollTop !== null ? "overflow-x-visible" : "overflow-y-scroll",
  };
}

export default useDynamicCSSOverflow;
