import type { EditorRootState } from "@editor/store";
import type { Selector } from "@reduxjs/toolkit";

import * as React from "react";

import { useEditorSelector } from "@editor/store";

/**
 * @deprecated
 * This hook takes a selector and returns a ref of the value of the given selector and
 * this ref.current value will be always updated as it's in redux.
 */
export default function useOverridableRefWithSelector<T>(
  selector: Selector<EditorRootState, T>,
) {
  const value = useEditorSelector(selector);
  const ref = React.useRef<T>(value);

  React.useEffect(() => {
    ref.current = value as T;
  }, [value]);

  return ref;
}
