import type { ActionValueTypeValueOf } from "replo-runtime/shared/enums";

import * as React from "react";

import Switch from "@common/designSystem/Switch";
import Input from "@editor/components/common/designSystem/Input";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Selectable from "@editor/components/common/designSystem/Selectable";
import { LengthInputSelector } from "@editor/components/editor/page/element-editor/components/modifiers/LengthInputModifier";
import { selectDraftElementHashmarks } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { DraggingTypes } from "@editor/utils/editor";

import { CSS_LENGTH_TYPES } from "replo-runtime/shared/utils/units";

type OffsetHashmarkProps = {
  value: ActionValueTypeValueOf<"offsetHashmark"> | null | undefined;
  onChange: (value: ActionValueTypeValueOf<"offsetHashmark"> | null) => void;
};

const OffsetHashmarkSelector: React.FC<OffsetHashmarkProps> = ({
  value,
  onChange,
}) => {
  const normalizedValue =
    typeof value === "string"
      ? { hashmark: value, offset: 0, smoothScroll: true }
      : value;
  const elementHashmarks = useEditorSelector(selectDraftElementHashmarks);
  const isCustomHashmark =
    (normalizedValue?.hashmark != null &&
      !elementHashmarks.includes(normalizedValue.hashmark)) ||
    elementHashmarks.length === 0;

  const [isCustomSelected, setIsCustomSelected] = React.useState<
    boolean | null
  >(isCustomHashmark);

  const selectableOptions = [
    ...elementHashmarks.map((hashmark) => ({
      label: hashmark,
      value: hashmark,
    })),
    { label: "Custom Hashmark", value: "custom-replo-hashmark" },
  ];

  const onSelectableChange = (selectableValue: string) => {
    const commonProps = {
      offset: normalizedValue?.offset ?? 0,
      smoothScroll: normalizedValue?.smoothScroll ?? true,
    };
    if (selectableValue === "custom-replo-hashmark") {
      setIsCustomSelected(true);
    } else {
      setIsCustomSelected(false);
      onChange({
        hashmark: selectableValue,
        ...commonProps,
      });
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-1">
        <Selectable
          placeholder="Select a hashmark"
          options={selectableOptions}
          value={
            isCustomSelected
              ? "custom-replo-hashmark"
              : normalizedValue?.hashmark
          }
          defaultValue={
            elementHashmarks.length === 0 ? "custom-replo-hashmark" : undefined
          }
          onSelect={onSelectableChange}
        />
        {isCustomSelected && (
          <Input
            autoFocus
            size="sm"
            value={normalizedValue?.hashmark}
            placeholder="your-hashmark-here"
            onChange={(e) => {
              onChange({
                ...normalizedValue,
                offset: normalizedValue?.offset ?? 0,
                hashmark: e.target?.value?.replace("#", ""),
                smoothScroll: normalizedValue?.smoothScroll ?? true,
              });
            }}
          />
        )}
      </div>
      <LabeledControl label="Offset" size="sm">
        <LengthInputSelector
          value={normalizedValue?.offset?.toString() ?? null}
          onChange={(stringValue: string) => {
            onChange({
              ...normalizedValue,
              offset: stringValue ? Number.parseInt(stringValue) : undefined,
            });
          }}
          draggingType={DraggingTypes.Vertical}
          field="HashmarkOffset"
          placeholder="0px"
          metrics={CSS_LENGTH_TYPES}
          minValues={{ px: 0 }}
          allowsNegativeValue={false}
        />
      </LabeledControl>
      <div className="flex w-full flex-row justify-between">
        <span className="text-xs font-normal text-slate-400">
          Smooth Scroll
        </span>
        <Switch
          isOn={normalizedValue?.smoothScroll ?? true}
          onChange={(isOn) => {
            onChange({
              ...normalizedValue,
              smoothScroll: isOn,
            });
          }}
          thumbColor="#fff"
          backgroundOnColor="bg-blue-600"
        />
      </div>
    </div>
  );
};

export default OffsetHashmarkSelector;
