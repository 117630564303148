import * as React from "react";

// Note (Yuxin) Converted via https://react-svgr.com/playground/
// https://thenounproject.com/term/corners/185187/
export type BorderProps = {
  size: number;
  className: string;
};
const Border = (props: BorderProps) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      className={`${props.className}`}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" rx="1" fill="currentColor" />
      <mask id="path-2-inside-1" fill="white">
        <rect width="2.25" height="18" rx="1" />
      </mask>
      <rect
        width="2.25"
        height="18"
        rx="1"
        fill="black"
        stroke="black"
        strokeWidth="2.25"
        mask="url(#path-2-inside-1)"
      />
      <mask id="path-3-inside-2" fill="white">
        <rect x="16" width="2.25" height="18" rx="1" />
      </mask>
      <rect
        x="16"
        width="2.25"
        height="18"
        rx="1"
        fill="black"
        stroke="black"
        strokeWidth="2.25"
        mask="url(#path-3-inside-2)"
      />
      <rect
        x="0.5"
        y="0.5"
        width="17"
        height="1"
        rx="0.5"
        fill="black"
        stroke="black"
      />
      <rect
        x="0.5"
        y="16.5"
        width="17"
        height="1"
        rx="0.5"
        fill="black"
        stroke="black"
      />
    </svg>
  );
};

export default Border;
