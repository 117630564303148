import type { ReploShopifyVariant } from "replo-runtime/shared/types";

import { isNullish } from "replo-utils/lib/misc";

export const variantIsOnSale = (variant: ReploShopifyVariant) => {
  if (isNullish(variant.compareAtPrice) || isNullish(variant.price)) {
    return false;
  }
  return Number(variant.compareAtPrice) > Number(variant.price);
};
