import type { FlowStepDataValueOf } from "schemas/flow";

import * as React from "react";

import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import StepTitle from "@components/flows/onboardingSteps/components/StepTitle";
import ErrorMessage from "@editor/components/account/Dashboard/ErrorMessage";
import Input from "@editor/components/common/designSystem/Input";
import { useGetCurrentStepResultsData } from "@editor/components/flows/hooks/useGetCurrentStepResultsData";
import { useRouterFlowCallbacks } from "@editor/components/flows/hooks/useRouterFlowCallbacks";
import FlowActionButtons from "@editor/components/flows/onboardingSteps/components/FlowActionButtons";
import OnboardingStepLayout, {
  OnboardingStepForm,
} from "@editor/components/flows/onboardingSteps/components/OnboardingStepsLayout";
import StepImage from "@editor/components/flows/onboardingSteps/components/StepImage";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { COMMON_EMAIL_DOMAINS } from "@editor/utils/email";

import startCase from "lodash-es/startCase";
import { Controller, useForm, useWatch } from "react-hook-form";

import { useGetCurrentFlow } from "../hooks/useGetCurrentFlow";
import StepSubtitle from "./components/StepSubtitle";

type FormValues = {
  workspaceName: string;
};

const WorkspaceName: React.FC = () => {
  const stepResultsData =
    useGetCurrentStepResultsData<"onboarding.user.workspace-name">();
  const { user } = useCurrentUser();
  const { currentStep, submitStep } = useReploFlowsStepContext();
  const { currentInstance } = useGetCurrentFlow();
  const workspaceName = getWorkspaceNamePlaceholder(
    user?.name ?? "",
    user?.email ?? "",
  );
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      workspaceName: stepResultsData?.workspaceName ?? workspaceName,
    },
  });
  const { submitOrSkipStepCallback: submitStepCallback } =
    useRouterFlowCallbacks();

  const whoAreYouBuildingPagesForData = currentInstance?.stepResults[
    "who-are-you-building-pages-for"
  ]
    ?.data as FlowStepDataValueOf<"onboarding.user.who-are-you-building-pages-for">;

  const workspaceNameValue = useWatch({ control, name: "workspaceName" });
  const workspaceNameValueError = errors.workspaceName?.message;
  const shouldDisableNextButton =
    !workspaceNameValue || Boolean(errors.workspaceName);

  const onSubmit = ({ workspaceName }: FormValues) => {
    if (currentStep) {
      void submitStep(
        currentStep.id,
        currentStep.type,
        {
          workspaceName,
        },
        ({ instance, nextStep }) => {
          submitStepCallback({
            nextStep: nextStep ?? null,
            flowSlug: instance.flow.slug,
          });
        },
      );
    }
  };

  return (
    <OnboardingStepLayout
      rightPanelContent={<StepImage src="/images/flows/person-logo.png" />}
    >
      <OnboardingStepForm
        onSubmit={(data) => {
          void handleSubmit(onSubmit)(data);
        }}
      >
        <div className="flex flex-col gap-14">
          <div>
            <StepTitle>
              What&apos;s the name of your
              <span className="whitespace-nowrap">
                {getWorkspaceTitleSuffix(
                  whoAreYouBuildingPagesForData?.buildingFor ?? "",
                )}
                ?<span className="text-red-600">*</span>
              </span>
            </StepTitle>
            <StepSubtitle>
              We&apos;ll use this to set up your first workspace. If you
              don&apos;t have a name for your team yet, don&apos;t worry, just
              click Next.
            </StepSubtitle>
          </div>

          <Controller
            name="workspaceName"
            control={control}
            rules={{
              required: "Please enter a workspace name.",
              pattern: {
                value: /^[\d 'A-Za-z-]+$/,
                message:
                  "Please enter only alphanumeric characters, whitespaces, apostrophes ( ' ), or hyphens ( - ).",
              },
            }}
            render={({ field: { value, onChange, name } }) => (
              <div className="flex flex-col gap-2">
                <Input
                  type="text"
                  size="base"
                  autoFocus
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  name={name}
                  placeholder={workspaceName}
                  validityState={
                    Boolean(workspaceNameValueError) ? "invalid" : "valid"
                  }
                  aria-invalid={
                    Boolean(workspaceNameValueError) ? "true" : undefined
                  }
                  aria-describedby={
                    Boolean(workspaceNameValueError)
                      ? "error-workspace-name"
                      : undefined
                  }
                />
                <ErrorMessage
                  id="error-workspace-name"
                  error={workspaceNameValueError}
                />
              </div>
            )}
          />
        </div>
        <FlowActionButtons shouldDisableNextButton={shouldDisableNextButton} />
      </OnboardingStepForm>
    </OnboardingStepLayout>
  );
};

const getWorkspaceTitleSuffix = (buildingFor: string) => {
  if (buildingFor === "brand") {
    return " brand";
  } else if (
    ["development_agency", "digital_marketing_agency"].includes(buildingFor)
  ) {
    return " agency";
  }
  return " team";
};

const getWorkspaceNamePlaceholder = (name: string, email: string) => {
  if (COMMON_EMAIL_DOMAINS.some((domain) => email.endsWith(domain))) {
    return `${name}'s Workspace`;
  }
  const emailDomain = email.split("@")[1]?.split(".")[0];

  const formattedDomain = emailDomain
    ? startCase(emailDomain.replace(/-/g, " ").toLowerCase())
    : null;

  return formattedDomain ? formattedDomain : "Name your team's workspace";
};

export default WorkspaceName;
