import * as React from "react";

import DynamicDataButton from "@common/designSystem/DynamicDataButton";
import { Input } from "@common/designSystem/Input";
import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import { BADGE_TRIGGER_OFFSET } from "@editor/components/editor/constants";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useModal } from "@editor/hooks/useModal";
import {
  selectColor,
  selectDraftComponentId,
  selectIconAltText,
  selectPropIconName,
} from "@editor/reducers/core-reducer";
import {
  selectOpenPopoverId,
  setOpenPopoverId,
} from "@editor/reducers/ui-reducer";
import { useEditorSelector } from "@editor/store";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import SelectablePopover from "@editorComponents/SelectablePopover";
import { DynamicDataValueIndicator } from "@editorExtras/DynamicDataValueIndicator";
import ModifierGroup from "@editorExtras/ModifierGroup";

import { Badge } from "@replo/design-system/components/badge";
import { BsCaretDownFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { iconDirectory } from "replo-runtime/store/iconDirectory";

import ModifierLabel from "../extras/ModifierLabel";
import { DynamicColorSelector } from "./DynamicColorModifier";

const IconModifierRow: React.FC<{
  label: string;
  children: React.ReactNode;
}> = ({ label, children }) => (
  <div className="flex items-center w-full">
    <ModifierLabel label={label} />
    <div className="w-full">{children}</div>
  </div>
);

export const IconModifier = () => {
  return (
    <ModifierGroup title="Icons">
      <div className="flex flex-col gap-2">
        <IconModifierRow label="Icon">
          <IconSelectable />
        </IconModifierRow>
        <IconModifierRow label="Alt Text">
          <IconAltTextControl />
        </IconModifierRow>
        <IconModifierRow label="Color">
          <IconColorControl />
        </IconModifierRow>
      </div>
    </ModifierGroup>
  );
};

type IconOption = {
  label: string;
  value?: string | number | null;
  startEnhancer?: React.ReactNode;
  isDefaultActive?: boolean;
  isSelectable: boolean;
};

const IconSelectable: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const value = useEditorSelector(selectPropIconName);
  const Icon = iconDirectory[value]?.Component;
  const iconName = iconDirectory[value]?.displayName;
  const openPopoverId = useEditorSelector(selectOpenPopoverId);
  const dispatch = useDispatch();
  if (!Icon) {
    return null;
  }

  const isDefaultOpen = openPopoverId === "modifier-icon-selector";
  const iconOptions = Object.keys(iconDirectory)
    .sort()
    .map((key): IconOption => {
      const Icon = iconDirectory[key]?.Component;
      return {
        value: key,
        startEnhancer: (
          <Badge
            type="icon"
            icon={<Icon />}
            className="bg-accent text-xs text-white p-0.5"
          />
        ),
        label: iconDirectory[key]?.displayName || "Select an icon",
        isSelectable: true,
        isDefaultActive: value === key,
      };
    });
  return (
    <SelectablePopover
      options={iconOptions}
      triggerEndEnhancer={<BsCaretDownFill size={10} className="text-subtle" />}
      title="Icons"
      itemSize={26}
      // NOTE (Sebas, 2024-10-18): This offset is different from the others because this
      // popover is being trigger from an entire input and not from an internal badge.
      popoverSideOffset={84}
      onSelect={(v: string) =>
        applyComponentAction({
          type: "setProps",
          value: {
            iconName: v,
          },
        })
      }
      isDefaultOpen={isDefaultOpen}
      onOpenChange={() => {
        // NOTE (Sebas, 2024-05-02): Once the popover is closed, we should reset the open popover state
        // to prevent from opening it again automatically.
        if (isDefaultOpen) {
          dispatch(setOpenPopoverId(null));
        }
      }}
      isRemovable={false}
      childrenClassname="w-28"
    >
      <div className="flex items-center">
        <Badge
          type="icon"
          icon={<Icon />}
          className="bg-accent p-1 mr-2 text-white"
        />
        <span className="truncate">{iconName}</span>
      </div>
    </SelectablePopover>
  );
};

const IconAltTextControl: React.FC = () => {
  const text = useEditorSelector(selectIconAltText);
  const modal = useModal();

  const onOpenDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.TEXT,
        referrerData: {
          type: "style",
          styleAttribute: "__iconAltText",
        },
        initialPath: text ? getPathFromVariable(text) : undefined,
      },
    });
  };

  return typeof text === "string" && text.includes("{{") ? (
    <DynamicIconAltTextControl onOpenDynamicData={onOpenDynamicData} />
  ) : (
    <StaticIconAltTextControl onOpenDynamicData={onOpenDynamicData} />
  );
};

const DynamicIconAltTextControl: React.FC<{
  onOpenDynamicData: () => void;
}> = ({ onOpenDynamicData }) => {
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const value = useEditorSelector(selectIconAltText);
  const applyComponentAction = useApplyComponentAction();

  return (
    <DynamicDataValueIndicator
      type="text"
      templateValue={value ?? null}
      onClick={onOpenDynamicData}
      onRemove={() => {
        applyComponentAction({
          type: "setStyles",
          value: { __iconAltText: "" },
        });
      }}
      componentId={draftComponentId ?? undefined}
    />
  );
};

const StaticIconAltTextControl: React.FC<{
  onOpenDynamicData: () => void;
}> = ({ onOpenDynamicData }) => {
  const iconAltText = useEditorSelector(selectIconAltText);
  const applyComponentAction = useApplyComponentAction();
  const draftComponentId = useEditorSelector(selectDraftComponentId);

  const handleChange = React.useCallback(
    (value: string) =>
      applyComponentAction({
        componentId: draftComponentId,
        type: "setStyles",
        value: { __iconAltText: value },
      }),
    [applyComponentAction, draftComponentId],
  );

  const inputProps = useOverridableInput({
    value: iconAltText ?? "",
    onValueChange: handleChange,
  });

  return (
    <div className="flex gap-1">
      <Input placeholder="Icon alt text" {...inputProps} />
      <div>
        <DynamicDataButton onClick={onOpenDynamicData} />
      </div>
    </div>
  );
};

const IconColorControl: React.FC = () => {
  const color = useEditorSelector(selectColor);
  const applyComponentAction = useApplyComponentAction();
  const componentId = useEditorSelector(selectDraftComponentId);
  if (!componentId) {
    return null;
  }
  return (
    <DynamicColorSelector
      allowsGradientSelection={false}
      field="color"
      componentId={componentId}
      value={color || "#000000"}
      onChange={(value) =>
        applyComponentAction({
          type: "setStyles",
          componentId: componentId,
          value: { color: value },
        })
      }
      popoverSideOffset={BADGE_TRIGGER_OFFSET}
      showSavedStyles
      onSavedStyleSelect={(value) => {
        applyComponentAction({
          type: "setStyles",
          value: { color: value },
        });
      }}
      onRemove={() => {
        applyComponentAction({
          type: "setStyles",
          value: { color: "" },
        });
      }}
    />
  );
};
