export const getTokenFromStorage = () => {
  if (!window.localStorage) {
    console.error("LocalStorage not defined");
  }
  return localStorage.getItem("andytown__token2");
};

export function addAuthorizationHeaders(headers = {}): Record<string, string> {
  const token = getTokenFromStorage();
  if (!token) {
    return headers;
  }
  return {
    ...headers,
    Authorization: `Token ${token}`,
  };
}

export function addPublisherStagingHeadersIfNeeded(
  headers = {},
): Record<string, string> {
  if (localStorage.getItem("replo.debug.usePublisherStagingUrl") === "true") {
    return {
      ...headers,
      "X-Replo-Use-Publisher-Staging-Url": "true",
    };
  }
  return headers;
}
