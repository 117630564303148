import type { EditorRootState } from "@editor/store";
import type { DragType } from "@editor/types/drag-type";
import type { DropTarget } from "@editor/types/drop-target";
import type { PayloadAction } from "@reduxjs/toolkit";

import { createSlice } from "@reduxjs/toolkit";

export type DragAndDropState = {
  // Type of current drag, e.g. existing component, new template, etc
  currentDragType: DragType | null;
  // Unique identifier of the thing being dragged
  currentDraggingIdentifier: string | null;
  // Current drop target in canvas
  dropTarget: DropTarget;
  // Whether we're dragging spacing indicators (padding, margin) or not
  isDraggingSpacingIndicators: boolean;
  // Cursor which should be shown everywhere in the app during drag
  draggingCursor?: string;
};

const initialState: DragAndDropState = {
  currentDragType: null,
  currentDraggingIdentifier: null,
  dropTarget: { edge: null, error: null, componentId: null },
  isDraggingSpacingIndicators: false,
};

const dragAndDropSlice = createSlice({
  name: "dragAndDrop",
  initialState,
  reducers: {
    setCurrentDragTypeAndIdentifier: (
      state,
      action: PayloadAction<{
        dragType: DragType | null;
        identifier: string | null;
      }>,
    ) => {
      state.currentDragType = action.payload.dragType;
      state.currentDraggingIdentifier = action.payload.identifier;
    },
    setDropTarget: (state, action: PayloadAction<DropTarget>) => {
      state.dropTarget = action.payload;
    },
    setDraggingCursor: (state, action: PayloadAction<string | null>) => {
      state.draggingCursor = action.payload ?? undefined;
    },
    setIsDraggingSpacingIndicators: (state, action: PayloadAction<boolean>) => {
      state.isDraggingSpacingIndicators = action.payload;
    },
  },
});

export const selectIsDraggingSpacingIndicators = (state: EditorRootState) => {
  return state.dragAndDrop.isDraggingSpacingIndicators;
};

export const selectCurrentDragType = (state: EditorRootState) => {
  return state.dragAndDrop.currentDragType;
};

export const selectCurrentDraggingIdentifier = (state: EditorRootState) => {
  return state.dragAndDrop.currentDraggingIdentifier;
};

const { actions, reducer } = dragAndDropSlice;

export const {
  setCurrentDragTypeAndIdentifier,
  setDropTarget,
  setDraggingCursor,
  setIsDraggingSpacingIndicators,
} = actions;
export default reducer;
