import type { ToggleGroupOption } from "@common/designSystem/ToggleGroup";
import type { ReploSpecificComponentIssue } from "@editor/types/component-issues";
import type {
  AlchemyAnimationTrigger,
  AlchemyAnimationTriggerType,
  AlchemyAnimationType,
  Animation,
} from "schemas/animations";
import type { MediaSize } from "schemas/breakpoints";

import * as React from "react";

import { ToggleGroup } from "@common/designSystem/ToggleGroup";
import Chip from "@editor/components/common/designSystem/Chip";
import InlinePopover from "@editor/components/common/designSystem/InlinePopover";
import Selectable from "@editor/components/common/designSystem/Selectable";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import Switch from "@editor/components/common/designSystem/Switch";
import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import { LengthInputSelector } from "@editor/components/editor/page/element-editor/components/modifiers/LengthInputModifier";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import {
  selectDraftComponentAnimationIssues,
  selectDraftComponentAnimations,
  selectDraftComponentNodeFromActiveCanvas,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector } from "@editor/store";
import { getAnimationField, getAnimationName } from "@editor/utils/animations";
import { DraggingTypes } from "@editor/utils/editor";
import { hasAnimationIssues } from "@editor/utils/getIssuesForComponent";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import ModifierGroup from "@editorExtras/ModifierGroup";
import RightBarIssues from "@editorExtras/RightBarIssues";

import { Badge } from "@replo/design-system/components/badge";
import Button from "@replo/design-system/components/button";
import classNames from "classnames";
import startCase from "lodash-es/startCase";
import { BsInfoCircle, BsLightningChargeFill, BsPlus } from "react-icons/bs";
import { MdOutlineWatchLater } from "react-icons/md";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { getDeviceNameFromMediaSize } from "replo-runtime/shared/utils/breakpoints";
import {
  animationTriggerTypes,
  disableAnimation,
  getAnimationTriggerType,
  getAnimationValueFromType,
  resetAnimation,
} from "replo-runtime/store/utils/animations";
import { isNotNullish } from "replo-utils/lib/misc";
import { AlchemyAnimationTriggers } from "schemas/animations";
import { v4 as uuidv4 } from "uuid";

import DocumentationInfoIcon from "../DocumentationInfoIcon";
import ModifierLabel from "../extras/ModifierLabel";

const AnimationsModifier: React.FC = () => {
  const animations = useEditorSelector(selectDraftComponentAnimations);
  const animationIssues = useEditorSelector(
    selectDraftComponentAnimationIssues,
  );
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const applyComponentAction = useApplyComponentAction();
  const isDisabled = AlchemyAnimationTriggers.some((trigger) => {
    return animations?.some((a) => a.trigger?.type === trigger);
  });
  let shouldOpenAnimationWithError = false;

  const createAnimation = () => {
    const newAnimation = {
      id: uuidv4(),
      type: "fadeIn" as AlchemyAnimationType,
      value: getAnimationValueFromType("fadeIn"),
      trigger: {
        type: "onViewportEnter",
        value: null,
      } as AlchemyAnimationTrigger,
      // Note (Sebas, 2024-04-24): We set devices as an empty array because
      // it will be calculated inside the addAnimation action. It will apply
      // the ones that are not already set.
      devices: [],
      runOnlyOnce: true,
    };

    applyComponentAction({
      type: "addAnimation",
      value: newAnimation,
    });
  };

  const updateAnimation = (
    value: { id: Animation["id"] } & Partial<Animation>,
    resetValues?: boolean,
  ) => {
    applyComponentAction({
      type: "updateAnimation",
      value,
      resetValues,
    });
  };

  return (
    <ModifierGroup
      title="Animations"
      icon={BsPlus}
      titleEnhancer={
        <DocumentationInfoIcon href="https://support.replo.app/articles/1716423267-animations" />
      }
      iconTooltip={
        isDisabled
          ? "You can only add one animation per trigger"
          : "Add Animation"
      }
      isDisabled={isDisabled}
      onClick={createAnimation}
      hideEndEnhancerOnGroupClosed={false}
      iconShouldOpenModifierGroup
    >
      {isNotNullish(animations) && animations.length > 0 ? (
        <div className="w-full">
          {animations.map((animation) => {
            const hasIssues = hasAnimationIssues(animation.id, animationIssues);
            if (hasIssues && !shouldOpenAnimationWithError) {
              shouldOpenAnimationWithError = true;
            }
            return (
              <InlinePopover
                key={animation.id}
                shouldPreventDefaultOnInteractOutside={areModalsOpen}
                skipTriggerStyles
                triggerAsChild
                title="Configure Animation"
                isDefaultOpen={shouldOpenAnimationWithError}
                content={
                  <AnimationEditor
                    animation={animation}
                    onUpdate={updateAnimation}
                  />
                }
              >
                <div
                  className={classNames("w-full", {
                    "opacity-50": hasIssues,
                  })}
                >
                  <SelectionIndicator
                    className="mb-2 cursor-pointer"
                    startEnhancer={
                      <Badge
                        type="icon"
                        icon={BsLightningChargeFill}
                        isFilled
                      />
                    }
                    title={getAnimationName(
                      animation.trigger?.type as AlchemyAnimationTriggerType,
                      animation.type,
                    )}
                    endEnhancer={
                      <FormFieldXButton
                        onClick={(e) => {
                          e.stopPropagation();
                          applyComponentAction({
                            type: "deleteAnimation",
                            value: animation.id,
                          });
                        }}
                      />
                    }
                  />
                </div>
              </InlinePopover>
            );
          })}
          {animationIssues.length > 0 ? (
            <RightBarIssues issues={animationIssues} />
          ) : null}
        </div>
      ) : (
        <div
          className="mx-auto w-full cursor-pointer text-subtle"
          onClick={createAnimation}
        >
          <div className="text-left text-xs">
            <span>Click the + icon to add an animation</span>
          </div>
        </div>
      )}
    </ModifierGroup>
  );
};

const AnimationEditor: React.FC<{
  animation: Animation | null;
  onUpdate: (
    value: { id: Animation["id"] } & Partial<Animation>,
    resetValues?: boolean,
  ) => void;
}> = ({ animation, onUpdate }) => {
  const animations = useEditorSelector(selectDraftComponentAnimations);
  const draftComponentNode = useEditorSelector(
    selectDraftComponentNodeFromActiveCanvas,
  );
  const animationIssues = useEditorSelector(
    selectDraftComponentAnimationIssues,
  );

  if (!animation) {
    return null;
  }
  const animationWithRepeatedDevicesIssue =
    animationIssues.find(
      (
        issue,
      ): issue is ReploSpecificComponentIssue<"animations.animationWithRepeatedDevices"> =>
        issue.type === "animations.animationWithRepeatedDevices",
    )?.animationWithIssuesIdentifiers ?? null;

  const options = Object.keys(animationTriggerTypes).map((triggerType) => ({
    label: triggerType,
    value: triggerType,
  }));

  const triggerOptions = AlchemyAnimationTriggers.map((trigger) => ({
    label: startCase(trigger),
    value: trigger,
    isDisabled: animations?.some((a) => a.trigger?.type === trigger),
  }));

  const animationDurationOptions = ["Reset", "300ms", "1000ms"].map((v) => ({
    label: v,
    value: v === "Reset" ? "" : v,
  }));

  const duration = styleAttributeToEditorData.animationDuration.defaultValue;
  const delay = styleAttributeToEditorData.animationDelay.defaultValue;

  const triggerType = getAnimationTriggerType(animation.type);
  const triggers = triggerType ? animationTriggerTypes[triggerType] : null;

  let animationTypeOptions: ToggleGroupOption<AlchemyAnimationType>[] = [];
  if (triggers?.variants) {
    animationTypeOptions = triggers.variants.map((animationType) => ({
      label: startCase(animationType),
      value: animationType,
    }));
  }

  const onAnimationDeviceChange = (device: "sm" | "md" | "lg") => {
    const animationDevices = animation?.devices || [];
    const newDevices = animationDevices.includes(device)
      ? animationDevices.filter((d) =>
          device === "lg" ? !["lg"].includes(d) : d !== device,
        )
      : animationDevices.concat(device === "lg" ? ["lg"] : device);
    onUpdate({
      id: animation.id,
      devices: newDevices,
    });
  };

  const getChipStartEnhancer = (device: MediaSize) => {
    if (animationWithRepeatedDevicesIssue?.[animation.id]?.includes(device)) {
      return <BsInfoCircle size={12} />;
    } else if (animation?.devices?.includes(device)) {
      return <RiCheckboxCircleFill size={12} />;
    }
    return null;
  };

  return (
    <div className="flex flex-col justify-between gap-y-2 py-1">
      <div className="flex flex-col gap-y-3">
        <div className="flex w-full items-center">
          <ModifierLabel label="Trigger" className="text-subtle" />
          <Selectable
            value={animation?.trigger?.type}
            options={triggerOptions}
            placeholder="Choose Animation Trigger"
            onSelect={(triggerType: AlchemyAnimationTriggerType) => {
              onUpdate({
                id: animation.id,
                trigger: {
                  type: triggerType,
                  value: null,
                },
              });
            }}
          />
        </div>
        <div className="flex w-full items-center">
          <ModifierLabel label="Animation" className="text-subtle" />
          <Selectable
            value={triggerType}
            options={options}
            placeholder="Choose Animation Type"
            onSelect={(triggerType) => {
              if (triggerType) {
                const newTriggers = animationTriggerTypes[triggerType];
                const type = newTriggers?.variants[0];
                if (type) {
                  onUpdate(
                    {
                      id: animation.id,
                      value: getAnimationValueFromType(type),
                      type,
                    },
                    // NOTE (Sebas, 2024-04-24): We want to reset the values when the trigger type changes
                    // to avoid having invalid values for the new trigger type.
                    true,
                  );
                }
              }
            }}
          />
        </div>
        <div className="border-b border-slate-200"></div>
        <ToggleGroup
          allowsDeselect={false}
          type="single"
          options={animationTypeOptions}
          value={animation.type}
          onChange={(type: AlchemyAnimationType) => {
            onUpdate({
              id: animation.id,
              value: getAnimationValueFromType(type),
              type,
            });
          }}
          style={{ width: "100%" }}
        />
        {animationTriggerTypes[triggerType!]?.fields?.map((field) => {
          const onChange = (value: string) => {
            const option = field.options.find((o) => o.value === value);
            if (!option) {
              return;
            }
            onUpdate({
              id: animation.id,
              value: {
                styles: {
                  ...option.override,
                },
                [field.id]: option.value,
              },
            });
          };

          return (
            <div key={field.name} className="flex items-center">
              <ModifierLabel label={field.name} className="text-subtle" />
              <div key={animation.id} className="flex w-full flex-col">
                {getAnimationField({
                  animation,
                  field,
                  onChange,
                })}
              </div>
            </div>
          );
        })}
        <div className="flex flex-col">
          <div className="flex w-full justify-between items-center">
            <ModifierLabel label="Duration" className="text-subtle" />
            <LengthInputSelector
              metrics={["ms"]}
              startEnhancer={() => <MdOutlineWatchLater />}
              field="animationDuration"
              resetValue={duration}
              anchorValue={duration}
              placeholder={duration}
              draggingType={DraggingTypes.Vertical}
              value={animation?.value?.styles?.animationDuration ?? duration}
              onChange={(value: string) => {
                onUpdate({
                  id: animation.id,
                  value: {
                    styles: {
                      animationDuration: value,
                    },
                  },
                });
              }}
              menuOptions={animationDurationOptions}
              autofocus
            />
          </div>
        </div>
        <div className="flex w-full justify-between items-center">
          <ModifierLabel label="Delay" className="text-subtle" />
          <LengthInputSelector
            metrics={["ms"]}
            startEnhancer={() => <MdOutlineWatchLater />}
            field="animationDelay"
            resetValue={delay}
            anchorValue={duration}
            placeholder={delay}
            draggingType={DraggingTypes.Vertical}
            value={animation?.value?.styles?.animationDelay ?? delay}
            onChange={(value: string) => {
              onUpdate({
                id: animation.id,
                value: {
                  styles: {
                    animationDelay: value,
                  },
                },
              });
            }}
            menuOptions={animationDurationOptions}
          />
        </div>
        <div className="flex justify-between">
          <span className="text-xs text-subtle">Run only once</span>
          <Switch
            isOn={animation.runOnlyOnce !== false}
            onChange={(checked) => {
              onUpdate({ id: animation.id, runOnlyOnce: checked });
            }}
            backgroundOnColor="bg-blue-600"
            thumbColor="#fff"
          />
        </div>
        <div className="flex flex-col">
          <div className="mb-1 text-xs text-subtle">Devices</div>
          <div className="flex gap-1">
            {(["sm", "md", "lg"] as MediaSize[]).map((device: MediaSize) => {
              return (
                <Chip
                  key={device}
                  className="w-1/3 cursor-pointer font-normal"
                  startEnhancer={getChipStartEnhancer(device)}
                  isSelected={animation?.devices?.includes(device)}
                  isInvalid={animationWithRepeatedDevicesIssue?.[
                    animation.id
                  ]?.includes(device)}
                  onClick={() => onAnimationDeviceChange(device)}
                >
                  {getDeviceNameFromMediaSize(device)}
                </Chip>
              );
            })}
          </div>
        </div>
        <Button
          isFullWidth
          variant="primary"
          isDisabled={!animations?.some((a) => a.id === animation.id)}
          onClick={() => {
            if (draftComponentNode) {
              resetAnimation(
                draftComponentNode,
                animation.value?.styles?.animationName,
                false,
              );
              draftComponentNode.style.animationPlayState = "running";
              draftComponentNode.addEventListener(
                "animationend",
                () => {
                  disableAnimation(draftComponentNode);
                },
                {
                  once: true,
                },
              );
            }
          }}
        >
          Preview
        </Button>
      </div>
    </div>
  );
};

export default AnimationsModifier;
