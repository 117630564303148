import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { getAccessibilityAttributes } from "replo-runtime/store/utils/accessibility";

import { ReploComponent } from "../ReploComponent";

const Container: React.FC<RenderComponentProps> = ({
  componentAttributes,
  component,
  context,
  overrideComponentTagType,
}) => {
  const { repeatedIndexPath = ".0" } = context;
  const accessibilityAttributes = getAccessibilityAttributes({
    role: component.props._accessibilityRole,
    hidden: component.props._accessibilityHidden,
    checked: component.props._accessibilityChecked,
    labelledBy: component.props._accessibilityLabelledBy,
  });

  const TagType =
    component.props._accessibilityHtmlTagType ??
    overrideComponentTagType ??
    "div";
  const isListTagType = ["ol", "ul"].includes(TagType);

  return (
    <TagType
      {...componentAttributes}
      {...accessibilityAttributes}
      key={componentAttributes.key}
    >
      {component?.children?.map((child) => {
        return (
          <ReploComponent
            key={child.id}
            component={child}
            context={context}
            repeatedIndexPath={repeatedIndexPath}
            overrideComponentTagType={isListTagType ? "li" : undefined}
          />
        );
      })}
    </TagType>
  );
};

export default Container;
