import type { Options } from "@splidejs/react-splide";

import { forOwn } from "replo-runtime/store/components/CarouselV3/renderer/utils";

interface Styles {
  [breakpoint: string]: {
    [selector: string]: Record<
      string,
      { value: string | number; isImportant: boolean }
    >;
  };
}

/**
 * The class for generating styles as a string.
 *
 * @since 3.0.0
 */
export class Style {
  /**
   * The collection of registered styles categorized by each breakpoint.
   */
  private readonly styles: Styles = {};

  /**
   * The ID of the slider.
   */
  private readonly id: string;

  /**
   * Holds options.
   */
  private readonly options: Options;

  /**
   * The common prefix for all selectors
   */
  private readonly selectorPrefix: string | undefined;

  /**
   * The Style constructor.
   */
  constructor(
    id: string,
    selectorPrefix: string | undefined,
    options: Options,
  ) {
    this.id = id;
    this.selectorPrefix = selectorPrefix;
    this.options = options;
  }

  /**
   * Registers a CSS rule.
   */
  rule(
    selector: string,
    prop: string,
    value: string | number,
    breakpoint?: string,
    isImportant = false,
  ): void {
    breakpoint = breakpoint || "default";
    // biome-ignore lint/suspicious/noAssignInExpressions: allow expression
    const selectors = (this.styles[breakpoint] = this.styles[breakpoint] || {});
    // biome-ignore lint/suspicious/noAssignInExpressions: allow expression
    const styles = (selectors[selector] = selectors[selector] || {});
    styles[prop] = { value, isImportant };
  }

  /**
   * Builds styles as a single string.
   *
   * @returns Built styles.
   */
  build(): string {
    let css = "";

    if (this.styles.default) {
      css += this.buildSelectors(this.styles.default);
    }

    Object.keys(this.styles)
      .sort((n, m) =>
        this.options.mediaQuery === "min"
          ? Number(n) - Number(m)
          : Number(m) - Number(n),
      )
      .forEach((breakpoint) => {
        if (breakpoint !== "default" && this.styles[breakpoint]) {
          css += `@media screen and (max-width: ${breakpoint}px) {`;
          css += this.buildSelectors(this.styles[breakpoint]!);
          css += `}`;
        }
      });

    return css;
  }

  /**
   * Builds styles for each breakpoint.
   *
   * @param selectors - An object with styles.
   *
   * @returns Built styles.
   */
  private buildSelectors(
    selectors: Record<
      string,
      Record<string, { value: string | number; isImportant: boolean }>
    >,
  ): string {
    let css = "";

    forOwn(selectors, (styles, selector) => {
      selector = `${selector}`.trim();
      if (!selector) {
        return;
      }
      if (this.selectorPrefix) {
        selector = `${this.selectorPrefix}${selector}`;
      }
      css += `${selector} {`;

      forOwn(styles, ({ value, isImportant }, prop) => {
        if (value || value === 0) {
          css += `${prop}: ${value}${isImportant ? " !important" : ""};`;
        }
      });

      css += "}";
    });

    return css;
  }
}
