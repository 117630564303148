import type { PaymentMethod } from "replo-runtime/shared/types";

import * as React from "react";

import Input from "@common/designSystem/Input";
import ErrorMessage from "@components/account/Dashboard/ErrorMessage";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import { SimpleSkeletonLoader } from "@editor/components/common/designSystem/SkeletonLoader";
import toast from "@editor/components/common/designSystem/Toast";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useIsWorkspaceOwner } from "@editor/hooks/useIsWorkspaceOwner";
import { getErrorMessage } from "@editor/utils/rtk-query-error-helpers";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@replo/design-system/components/button";
import IconButton from "@replo/design-system/components/button/IconButton";
import { skipToken } from "@tanstack/react-query";
import get from "lodash-es/get";
import { useForm } from "react-hook-form";
import { BsPencil } from "react-icons/bs";
import { z } from "zod";

const initialValues = {
  email: "",
};

const validationSchema = z.object({
  email: z
    .string()
    .email("Please enter a valid email")
    .min(1, "Please enter a valid email"),
});

export const PaypalForm = () => {
  const [isEditMode, setIsEditMode] = React.useState(false);
  const workspaceId = useCurrentWorkspaceId();
  const isWorkspaceOwner = useIsWorkspaceOwner(workspaceId);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: zodResolver(validationSchema),
  });

  const hasErrors = Object.keys(errors).length > 0;

  const {
    mutateAsync: updatePaymentMethod,
    error,
    isPending: isLoading,
  } = trpc.workspace.createOrUpdatePaymentMethod.useMutation({
    onSuccess: async () => {
      await trpcUtils.workspace.getById.invalidate({
        id: workspaceId ?? "",
      });
      toast({
        header: "Payment Method Updated",
        message:
          "We've updated your Paypal email. If you have any questions, please contact support@replo.app",
      });
      setIsEditMode(false);
      reset();
    },
  });

  const onSubmit = async ({ email }: Pick<PaymentMethod, "email">) => {
    if (workspaceId) {
      await updatePaymentMethod({
        email,
        workspaceId,
      });
    }
  };

  const { data, isPending } = trpc.workspace.getById.useQuery(
    workspaceId ? { id: workspaceId } : skipToken,
  );
  const paypalPayoutEmail = data?.workspace.paypalPayoutEmail;

  return (
    <div className="flex w-96 flex-col items-start justify-items-start">
      <form
        className="w-full"
        onSubmit={(data) => {
          void handleSubmit(onSubmit)(data);
        }}
      >
        <div className="flex flex-col gap-y-2 px-0.5">
          {isEditMode ? (
            <>
              <ErrorMessage error={getErrorMessage(error)?.message} />
              <ReferralInput
                field="email"
                label="Paypal Account Email"
                placeholder="ar@replo.app"
                errors={errors}
                register={register}
              />
              <Button
                variant="primary"
                type="submit"
                size="base"
                className="px-6"
                isDisabled={isLoading || hasErrors || !isWorkspaceOwner}
                isLoading={isLoading}
                tooltipText={
                  isWorkspaceOwner
                    ? undefined
                    : "Only Workspace Owners have the ability to change the PayPal settings"
                }
              >
                Update Email
              </Button>
            </>
          ) : (
            <LabeledControl
              label={
                <div className="flex items-center gap-2">
                  <span>Current Payout Email</span>
                  <img
                    className="h-3 object-contain"
                    src="/images/dashboard/partner/payment-methods/paypal.svg"
                    alt="PayPal Logo"
                  />
                </div>
              }
              className="mb-1 text-base text-slate-500"
            >
              {isPending ? (
                <SimpleSkeletonLoader width="200" height="30" />
              ) : (
                <div className="flex gap-1">
                  <span className="font-medium text-default">
                    {paypalPayoutEmail ?? "Not Set"}
                  </span>
                  <IconButton
                    variant="tertiary"
                    onClick={() => {
                      setIsEditMode(true);
                    }}
                    isDisabled={!isWorkspaceOwner}
                    tooltipText={
                      isWorkspaceOwner
                        ? null
                        : "Only Workspace Owners can edit this referral code"
                    }
                    icon={<BsPencil size={16} className="text-blue-600" />}
                    aria-label="Edit PayPal Email"
                    className="h-full"
                  />
                </div>
              )}
            </LabeledControl>
          )}
        </div>
      </form>
    </div>
  );
};

const ReferralInput = ({
  label,
  field,
  className = "",
  placeholder = "",
  errors,
  register,
}: {
  label: string;
  field: string;
  className?: string;
  placeholder?: string;
  errors: Record<string, any>;
  register: Function;
}) => {
  const fieldError = get(errors, `${field}.message`);
  const errorId = fieldError ? `error-${field}` : undefined;
  return (
    <LabeledControl
      label={label}
      className={`${className} mb-1 text-base text-slate-500`}
    >
      <div className={fieldError ? "mb-1.5" : "mb-3"}>
        <Input
          placeholder={placeholder}
          type="text"
          size="base"
          controlled={false}
          {...register(field)}
        />
      </div>
      <ErrorMessage error={fieldError} id={errorId} />
    </LabeledControl>
  );
};
