import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

export const styleElements = {
  root: {},
} as const satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: false,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
    ancestorDisallowList: [
      {
        elementTypes: ["page", "shopifyProductTemplate", "shopifyArticle"],
        message: "App blocks can only be used in a Shopify Section",
      },
      {
        ancestorTypes: ["button"],
        message: "App blocks cannot be nested inside buttons.",
      },
    ],
  },
} satisfies ComponentConfig;

export default config;
