import * as React from "react";

import Popover from "@common/designSystem/Popover";
import { RIGHT_BAR_WIDTH } from "@editor/components/editor/constants";
import {
  selectShowAIWelcomeModal,
  setShowWelcomeModal,
} from "@editor/reducers/ai-reducer";
import { selectEditorMode } from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";

import Button from "@replo/design-system/components/button";

const AI_WELCOME_MODAL_WIDTH = 360;
const AI_WELCOME_MODAL_HEIGHT = 140;

const AIWelcomeModalWrapper = () => {
  const showWelcomeModal = useEditorSelector(selectShowAIWelcomeModal);
  const editorMode = useEditorSelector(selectEditorMode);
  const dispatch = useEditorDispatch();
  return (
    showWelcomeModal &&
    editorMode === "edit" && (
      <div>
        <Popover isOpen={true} onOpenChange={() => {}}>
          <Popover.Anchor asChild>
            <div
              className="absolute bottom-0"
              style={{
                right: `${RIGHT_BAR_WIDTH + AI_WELCOME_MODAL_WIDTH / 2 + 16}px`,
              }}
            ></div>
          </Popover.Anchor>
          <Popover.Content
            shouldPreventDefaultOnInteractOutside
            side="top"
            sideOffset={35}
            className="relative p-4"
            style={{
              width: AI_WELCOME_MODAL_WIDTH,
              height: AI_WELCOME_MODAL_HEIGHT,
            }}
            hideCloseButton
          >
            <div className="h-full flex flex-col justify-between">
              <div className="flex flex-col gap-2">
                <div className="text-sm font-bold text-slate-800">
                  Welcome to your first page
                </div>
                <div className="text-sm font-normal text-slate-500">
                  We&apos;re excited to help get your first page cleaned up and
                  ready to publish!
                </div>
              </div>
              <Button
                variant="primary"
                onClick={() => dispatch(setShowWelcomeModal(false))}
                className="self-end"
                size="base"
              >
                Get Started
              </Button>
            </div>
          </Popover.Content>
        </Popover>
      </div>
    )
  );
};

export default AIWelcomeModalWrapper;
