import * as React from "react";

import { BsX } from "react-icons/bs";
import { twMerge } from "tailwind-merge";

type ModalContentParams = {
  ref?: React.MutableRefObject<HTMLDivElement | null>;
};

type ModalLayoutProps = {
  onRequestClose?(): void;
  width?: number | string;
  height?: number | string;
  minWidth?: number | string;
  headerContent?: React.ReactNode;
  mainContent(params: ModalContentParams): React.ReactNode;
  footerContent?(params: ModalContentParams): React.ReactNode;
  footerContentClassnames?: string;
  layoutClassnames?: string;
  wrapperClassnames?: string;
  mainContentClassnames?: string;
  testId?: string;
};

export const ModalLayout: React.FC<ModalLayoutProps> = ({
  wrapperClassnames = "",
  layoutClassnames = "",
  mainContentClassnames = "",
  mainContent,
  footerContent,
  footerContentClassnames,
  height,
  onRequestClose,
  width,
  headerContent,
  minWidth,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  return (
    <div
      ref={ref}
      className={twMerge(
        "relative flex flex-col justify-between bg-white",
        wrapperClassnames,
      )}
      style={{
        width: width || 800,
        height: height || 500,
        maxHeight: "98vh",
        minWidth: minWidth || 0,
      }}
    >
      <div className={twMerge("flex h-full flex-col p-2", layoutClassnames)}>
        {headerContent && <div className="flex">{headerContent}</div>}
        {onRequestClose && (
          <BsX
            style={{ top: "20px", right: "20px" }}
            size={24}
            className="z-50 cursor-pointer self-end text-slate-400"
            onClick={() => onRequestClose?.()}
          />
        )}
        <div
          className={twMerge(
            // Note (Noah, 2023-12-23): the "-ml-2 pl-2" is to give the content space so that
            // focus outlines don't get cut off. For some reason, even if you set overflow-x to
            // visible, if overflow-y is scroll then focus rings which are aligned with the left
            // side get cut off
            "no-scrollbar mb-2 -ml-2 px-2 flex flex-grow overflow-scroll",
            mainContentClassnames,
          )}
        >
          {mainContent({ ref })}
        </div>
        {footerContent && (
          <div
            className={twMerge(
              "flex w-full flex-row justify-end border-t border-slate-200 bg-white pt-6",
              footerContentClassnames,
            )}
          >
            {footerContent({ ref })}
          </div>
        )}
      </div>
    </div>
  );
};
