import type * as React from "react";
import type { RuntimeStyleAttribute } from "schemas/styleAttribute";

export type RuntimeStyleAttributeEditorData<Key extends string = string> = {
  /**
   
   * Whether this style should be removed from the component json if it's the
   * same as the default value. This is so things like backgroundImage: "none"
   * don't get added if they don't need to - we don't want this to happen because
   * it just unnecessarily bloats the component json. However, there are certain
   * properties that we want to keep around even if they're the default value,
   * mainly because other computed values in getAliasedStyles can depend on them.
   * Those styles should be set to "false".
   */
  shouldFilterDefaultValue: boolean;
  defaultValue: Key extends keyof React.CSSProperties
    ? React.CSSProperties[Key] | null
    : string | number | null;
  allowNullishValue?: boolean;
};

export const styleAttributeToEditorData = {
  "--rmh": {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  "--rmw": {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __alchemyGradient__backgroundColor__stops: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __alchemyGradient__backgroundColor__tilt: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __alchemyGradient__color__stops: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __alchemyGradient__color__tilt: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __alchemyRotation: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __animateVariantTransitions: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __display: {
    shouldFilterDefaultValue: true,
    defaultValue: "inline",
  },
  __flexGap: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  __hasHeight: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __hasWidth: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __height: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __iconAltText: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __imageAltText: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __imageSource: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
    allowNullishValue: true,
  },
  __numberOfColumns: {
    shouldFilterDefaultValue: false,
    defaultValue: 1,
  },
  __overflow: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __parentAlignItems: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __parentDisplay: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __parentFlexDirection: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __parentHasHeight: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __parentHasWidth: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __parentJustifyContent: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __transform: {
    shouldFilterDefaultValue: false,
    defaultValue: "",
  },
  __width: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  __zoom: {
    shouldFilterDefaultValue: true,
    defaultValue: "100%",
  },
  alignItems: {
    shouldFilterDefaultValue: true,
    defaultValue: "start",
  },
  alignSelf: {
    shouldFilterDefaultValue: true,
    defaultValue: "auto",
  },
  animationDelay: {
    shouldFilterDefaultValue: true,
    defaultValue: "0ms",
  },
  animationDuration: {
    shouldFilterDefaultValue: true,
    defaultValue: "300ms",
  },
  appearance: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  backgroundColor: {
    shouldFilterDefaultValue: true,
    defaultValue: "none",
  },
  backgroundImage: {
    shouldFilterDefaultValue: true,
    defaultValue: "none",
  },
  backgroundPositionX: {
    shouldFilterDefaultValue: true,
    defaultValue: "0%",
  },
  backgroundPositionY: {
    shouldFilterDefaultValue: true,
    defaultValue: "0%",
  },
  backgroundRepeat: {
    shouldFilterDefaultValue: true,
    defaultValue: "repeat",
  },
  backgroundSize: {
    shouldFilterDefaultValue: true,
    defaultValue: "auto",
  },
  borderBottomColor: {
    shouldFilterDefaultValue: true,
    defaultValue: "currentColor",
  },
  borderBottomLeftRadius: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  borderBottomRightRadius: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  borderBottomStyle: {
    shouldFilterDefaultValue: true,
    defaultValue: "none",
  },
  borderBottomWidth: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  borderColor: {
    shouldFilterDefaultValue: true,
    defaultValue: "currentColor",
  },
  borderLeftColor: {
    shouldFilterDefaultValue: true,
    defaultValue: "currentColor",
  },
  borderLeftStyle: {
    shouldFilterDefaultValue: true,
    defaultValue: "none",
  },
  borderLeftWidth: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  borderRightColor: {
    shouldFilterDefaultValue: true,
    defaultValue: "currentColor",
  },
  borderRightStyle: {
    shouldFilterDefaultValue: true,
    defaultValue: "none",
  },
  borderRightWidth: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  borderStyle: {
    shouldFilterDefaultValue: true,
    defaultValue: "none",
  },
  borderTopColor: {
    shouldFilterDefaultValue: true,
    defaultValue: "currentColor",
  },
  borderTopLeftRadius: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  borderTopRightRadius: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  borderTopStyle: {
    shouldFilterDefaultValue: true,
    defaultValue: "none",
  },
  borderTopWidth: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  borderWidth: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  bottom: {
    shouldFilterDefaultValue: true,
    defaultValue: "auto",
  },
  boxShadow: {
    shouldFilterDefaultValue: true,
    defaultValue: "none",
  },
  color: {
    shouldFilterDefaultValue: true,
    defaultValue: "currentColor",
  },
  columnGap: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  cursor: {
    shouldFilterDefaultValue: true,
    defaultValue: "auto",
  },
  display: {
    shouldFilterDefaultValue: true,
    defaultValue: "inline",
  },
  fill: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  flexBasis: {
    shouldFilterDefaultValue: true,
    defaultValue: "auto",
  },
  flexDirection: {
    shouldFilterDefaultValue: true,
    defaultValue: "row",
  },
  flexGrow: {
    shouldFilterDefaultValue: true,
    defaultValue: "unset",
  },
  flexShrink: {
    shouldFilterDefaultValue: false,
    defaultValue: 1,
  },
  flexWrap: {
    shouldFilterDefaultValue: true,
    defaultValue: "nowrap",
  },
  fontFamily: {
    shouldFilterDefaultValue: true,
    defaultValue: "inherit",
  },
  fontSize: {
    shouldFilterDefaultValue: true,
    defaultValue: "medium",
  },
  fontStyle: {
    shouldFilterDefaultValue: true,
    defaultValue: "normal",
  },
  fontWeight: {
    shouldFilterDefaultValue: true,
    defaultValue: "normal",
  },
  gridColumnEnd: {
    shouldFilterDefaultValue: true,
    defaultValue: "auto",
  },
  gridRowEnd: {
    shouldFilterDefaultValue: true,
    defaultValue: "auto",
  },
  gridTemplateColumns: {
    shouldFilterDefaultValue: false,
    defaultValue: "none",
  },
  height: {
    shouldFilterDefaultValue: true,
    defaultValue: "auto",
  },
  inset: {
    shouldFilterDefaultValue: true,
    defaultValue: "auto",
  },
  justifyContent: {
    shouldFilterDefaultValue: true,
    defaultValue: "start",
  },
  left: {
    shouldFilterDefaultValue: true,
    defaultValue: "auto",
  },
  letterSpacing: {
    shouldFilterDefaultValue: true,
    defaultValue: "",
  },
  lineHeight: {
    shouldFilterDefaultValue: true,
    defaultValue: "normal",
  },
  margin: {
    // TODO: confirm we should filter
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  marginBottom: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  marginLeft: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  marginRight: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  marginTop: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  maxHeight: {
    shouldFilterDefaultValue: true,
    defaultValue: "none",
  },
  maxWidth: {
    shouldFilterDefaultValue: true,
    defaultValue: "none",
  },
  minHeight: {
    shouldFilterDefaultValue: true,
    defaultValue: "auto",
  },
  minWidth: {
    shouldFilterDefaultValue: true,
    defaultValue: "auto",
  },
  objectFit: {
    shouldFilterDefaultValue: true,
    defaultValue: "none",
  },
  objectPosition: {
    shouldFilterDefaultValue: true,
    defaultValue: "50% 50%",
  },
  opacity: {
    shouldFilterDefaultValue: true,
    defaultValue: "1",
  },
  overflow: {
    shouldFilterDefaultValue: true,
    defaultValue: null,
  },
  padding: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  paddingBottom: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  paddingLeft: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  paddingRight: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  paddingTop: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  position: {
    // Note (Sebas, 2022-10-12): We should not filter the position
    // relative value because this is not the real default one
    shouldFilterDefaultValue: false,
    defaultValue: "relative",
  },
  right: {
    shouldFilterDefaultValue: true,
    defaultValue: "auto",
  },
  rowGap: {
    shouldFilterDefaultValue: true,
    defaultValue: "0px",
  },
  textAlign: {
    // TODO (Noah, 2022-08-07): There's an issue where we're not resetting text-align in
    // our reset, so for things which have other text-align set in the theme, if you set
    // the text-align to left it filters the value out and nothing happens (setting left
    // doesn't work). We should see if just setting the reset value would result in any
    // issues, but setting the value to null for now so it doesn't get filtered out
    shouldFilterDefaultValue: true,
    defaultValue: "start",
  },
  textDecoration: {
    shouldFilterDefaultValue: true,
    defaultValue: "none",
  },
  textTransform: {
    shouldFilterDefaultValue: false,
    defaultValue: "none",
  },
  top: {
    shouldFilterDefaultValue: true,
    defaultValue: "auto",
  },
  transform: {
    shouldFilterDefaultValue: false,
    defaultValue: "none",
  },
  transformOrigin: {
    shouldFilterDefaultValue: true,
    defaultValue: "50% 50% 0px",
  },
  transition: {
    shouldFilterDefaultValue: true,
    defaultValue: "all 0s ease 0s",
  },
  width: {
    shouldFilterDefaultValue: true,
    defaultValue: "auto",
  },
  zIndex: {
    shouldFilterDefaultValue: false,
    defaultValue: "auto",
  },
} as const satisfies Partial<{
  [Key in RuntimeStyleAttribute]: RuntimeStyleAttributeEditorData<Key>;
}>;
