import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { ReploComponent } from "../ReploComponent";

const FullScreenIcon = (props: RenderComponentProps) => {
  return (
    <ReploComponent
      context={props.context}
      component={props.component}
      repeatedIndexPath={props.context.repeatedIndexPath ?? ".0"}
      extraAttributes={props.extraAttributes}
      overrideComponentType="icon"
      overrideComponentProps={React.useMemo(
        () => ({ iconName: "fullScreen", _accessibilityLabel: "Full screen" }),
        [],
      )}
      // @ts-expect-error: TODO (Noah, 2024-01-01, REPL-9866): These are errors
      // because the toggleFullScreen action doesn't have a value, but that's
      // only the case because our types are wrong
      defaultActions={React.useMemo(
        () => ({
          actions: {
            onClick: [
              {
                id: "alchemy:toggleFullScreen",
                type: "toggleFullScreen",
              },
            ],
          },
          placement: "after",
        }),
        [],
      )}
    />
  );
};

export default FullScreenIcon;
