import type { PopoverContentProps } from "@common/designSystem/Popover";
import type { FlowStepConfigPropsValueOf } from "schemas/flow";

import * as React from "react";

import Popover from "@common/designSystem/Popover";
import { ReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import { useGetCurrentFlow } from "@components/flows/hooks/useGetCurrentFlow";
import useGetFlowActions from "@components/flows/hooks/useGetFlowActions";
import TourFlowActions from "@editor/components/flows/tour/TourFlowActions";
import { selectShowAIWelcomeModal } from "@editor/reducers/ai-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector } from "@editor/store";
import { routes } from "@editor/utils/router";
import TourFlowArrow from "@svg/tour-flow-arrow";

import { useMatch, useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const TourStepTrigger: React.FC<
  React.PropsWithChildren<{
    step: "step-1" | "step-2" | "step-3" | "step-4" | "step-5";
  }>
> = ({ children, step }) => {
  const areStateModalsOpen = useEditorSelector(selectAreModalsOpen);
  const isAIWelcomeModalOpen = useEditorSelector(selectShowAIWelcomeModal);
  const areRouteModalsOpen = !useMatch(routes.editor.element);
  const [searchParams] = useSearchParams();
  const isDebug = Boolean(searchParams.get("debug"));
  const { currentFlow, nextStep } = useGetCurrentFlow({
    entityType: "user",
    isDebug,
  });
  const currentStep = nextStep ?? null;
  const stepProps =
    currentStep?.props as FlowStepConfigPropsValueOf<"tour.editor.generic">;
  const { submitStep, skipStep, goBack, isSubmitting } = useGetFlowActions(
    "user",
    currentStep,
  );

  const onSkipStep = () => {
    if (currentStep) {
      skipStep(currentStep.id, currentStep.type);
    }
  };

  if (
    currentFlow?.slug !== "editor-tour" ||
    currentStep === null ||
    areStateModalsOpen ||
    areRouteModalsOpen ||
    // Note (Evan, 2024-10-10): The AI welcome modal acts as a pseudo-first-step, so if it's showing we should hide the first step
    // of the editor tour.
    (isAIWelcomeModalOpen && step === "step-1")
  ) {
    return children;
  }

  const parsedSide = (
    stepProps.side.includes("-") ? stepProps.side.split("-")[0] : stepProps.side
  ) as PopoverContentProps["side"];

  return (
    <ReploFlowsStepContext.Provider
      value={{
        currentStep,
        goBack,
        submitStep,
        skipStep,
        isSubmitting,
      }}
    >
      <Popover isOpen={currentStep?.id === step} onOpenChange={onSkipStep}>
        {/* NOTE (Sebas, 2024-02-16): This is to prevent the opening of the popover when
            clicking on the wrapped component. */}
        <Popover.Trigger asChild onClick={(e) => e.preventDefault()}>
          {/* NOTE (Sebas, 2024-02-16): DON'T remove this div, if you do it the popover
              will not show anymore and we don't want that :) */}
          <div className="flex">{children}</div>
        </Popover.Trigger>
        <Popover.Content
          shouldPreventDefaultOnInteractOutside
          side={parsedSide}
          sideOffset={35}
          className="relative w-[302px] p-4"
          title={stepProps.content.text}
          titleClassnames="text-default text-sm font-normal max-w-[30ch]"
        >
          <TourFlowActions />
          <Triangle side={stepProps.side} />
        </Popover.Content>
      </Popover>
    </ReploFlowsStepContext.Provider>
  );
};

const Triangle: React.FC<{
  side: "top" | "bottom" | "left" | "right" | "bottom-left" | null;
}> = ({ side }) => {
  const sideToCss = {
    top: "-bottom-5 left-1/2 transform -translate-x-1/2",
    "bottom-left": "rotate-180 -top-5 right-12",
    bottom: "rotate-180 -top-5 left-1/2 transform -translate-x-1/2",
    left: "-rotate-90 -right-6 top-1/2 transform -translate-y-1/2",
    right: "rotate-90 -left-6 top-1/2 transform -translate-y-1/2",
  };

  return (
    <div className={twMerge("absolute", side && sideToCss[side])}>
      <TourFlowArrow />
    </div>
  );
};

export default TourStepTrigger;
