import * as React from "react";

export function useTextDirection(props: {
  ref: React.RefObject<HTMLElement>;
  controlledValue: TextDirection | null | undefined;
  defaultValue: TextDirection;
}) {
  const { controlledValue, defaultValue, ref } = props;
  const [textDirection, setTextDirection] = React.useState(
    controlledValue ?? null,
  );
  React.useEffect(() => {
    if (controlledValue != null) {
      setTextDirection(controlledValue);
      return;
    }

    const rootDocument = ref.current?.ownerDocument ?? document;
    const root = rootDocument.firstElementChild;
    if (!root) {
      return;
    }

    const observer = new MutationObserver(handleAttributeMutation);
    setTextDirection(getDirAttribute(root));
    observer.observe(root, {
      subtree: false,
      attributeFilter: ["dir"],
    });
    return () => observer.disconnect();

    function handleAttributeMutation(mutationList: MutationRecord[]) {
      for (const mutation of mutationList) {
        const dir = getDirAttribute(mutation.target as HTMLHtmlElement);
        if (dir) {
          return setTextDirection(dir);
        }
      }
      setTextDirection(null);
    }

    function getDirAttribute(node: Element) {
      const dir = node.getAttribute("dir");
      if (dir === "rtl" || dir === "ltr") {
        return dir;
      }
      return null;
    }
  }, [ref, controlledValue]);

  return controlledValue ?? textDirection ?? defaultValue;
}

type TextDirection = "ltr" | "rtl";
