import type {
  SavedColorStyle,
  SavedTextStyle,
} from "schemas/generated/designLibrary";

import * as React from "react";

import { selectProjectId } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";

function useGetDesignLibrarySavedStyles() {
  const projectId = useEditorSelector(selectProjectId);
  const { data: designLibrary } = trpc.designLibrary.get.useQuery(
    projectId ? { projectId } : skipToken,
  );

  const savedStylesIncludingDeletedOnes = React.useMemo(() => {
    if (!designLibrary?.savedStyles) {
      return [];
    }

    return Object.entries(designLibrary.savedStyles).map(([id, savedStyle]) => {
      return { ...savedStyle, id };
    });
  }, [designLibrary?.savedStyles]);

  const { textSavedStyles, colorSavedStyles } = React.useMemo(() => {
    const savedStyles = savedStylesIncludingDeletedOnes.reduce(
      (savedStyles, savedStyle) => {
        if (savedStyle.deletedAt) {
          return savedStyles;
        }

        if (savedStyle.type === "text") {
          savedStyles.textSavedStyles.push(savedStyle);
        } else {
          savedStyles.colorSavedStyles.push(savedStyle);
        }
        return savedStyles;
      },
      {
        colorSavedStyles: [],
        textSavedStyles: [],
      } as {
        colorSavedStyles: (SavedColorStyle & { id: string })[];
        textSavedStyles: (SavedTextStyle & { id: string })[];
      },
    );

    const sortedTextSavedStyles = savedStyles.textSavedStyles.sort(
      (textStyleA, textStyleB) => {
        if (textStyleA.attributes.htmlTag === textStyleB.attributes.htmlTag) {
          return textStyleA.name.localeCompare(textStyleB.name);
        }
        return textStyleA.attributes.htmlTag.localeCompare(
          textStyleB.attributes.htmlTag,
        );
      },
    );

    const sortedColorSavedStyles = savedStyles.colorSavedStyles.sort(
      (colorStyleA, colorStyleB) =>
        colorStyleA.name.localeCompare(colorStyleB.name),
    );

    return {
      textSavedStyles: sortedTextSavedStyles,
      colorSavedStyles: sortedColorSavedStyles,
    };
  }, [savedStylesIncludingDeletedOnes]);

  return {
    textSavedStyles,
    colorSavedStyles,
    designLibrary,
    savedStylesIncludingDeletedOnes,
  };
}

export default useGetDesignLibrarySavedStyles;
