import type { SvgProps } from "replo-utils/react/components";

import * as React from "react";

import { Svg } from "replo-utils/react/components";

export const SvgMax = (props: SvgProps) => (
  <Svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fill="#94A3B8"
      d="M.636 5.91h.933l1.62 3.956h.06l1.62-3.957h.933V11h-.73V7.316h-.048l-1.502 3.676h-.606L1.415 7.314h-.048V11h-.73V5.91ZM7.35 11h-.816l1.832-5.09h.888L11.085 11h-.816L8.83 6.834h-.04L7.35 11Zm.136-1.994h2.645v.647H7.485v-.647Zm4.87-3.097L13.572 7.9h.04l1.218-1.99h.89l-1.586 2.545L15.73 11h-.895l-1.223-1.964h-.04L12.35 11h-.895l1.623-2.545-1.613-2.546h.89Z"
    />
  </Svg>
);
export default SvgMax;
