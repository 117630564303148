/**
 * NOTE (Jackson, 12-12-24): These are the integer slug of the GIDs of these apps,
 * which is potentially different from the custom appId found on SellingPlanGroups & that
 * is accessible through product JSON. However, often apps will have the same appId
 * as their GID. We should update this list as we discover what actual appIds are for these
 */

const __SUB_APP_APPID_TO_NAME = {
  "3501525": "Seal",
  "294517": "Recharge",
  "4836205": "Smartrr",
  "5284869": "Loop",
  "3151009": "Ordergroove",
  "5836469": "Prive",
  "5859381": "Stay AI",
  "4877949": "Appstle",
  "4370661": "Bold",
  "4387885": "PayWhirl",
  "4818605": "Yotpo",
  SKIO: "Skio",
} as const;

export type SubAppIdToName = typeof __SUB_APP_APPID_TO_NAME;
export const SUB_APP_APPID_TO_NAME = __SUB_APP_APPID_TO_NAME;
