import type { ElementVersionRevisionHistory } from "@editor/types/core-state";
import type { User } from "replo-runtime/shared/types";
import type {
  ReploElementVersionFilter,
  ReploElementVersionRevision,
} from "schemas/generated/element";

import * as React from "react";

import CollapsibleVersionCard from "@components/version-history/CollapsibleVersionCard";
import VersionCard from "@components/version-history/VersionCard";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import useElementVersioning from "@editor/hooks/useElementVersioning";
import {
  selectEditorMode,
  selectSelectedRevisionId,
  setEditorMode,
  setSelectedRevisionId,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { orderByDate } from "@utils/array";

import { RadioGroup, RadioGroupItem } from "@radix-ui/react-radio-group";
import IconButton from "@replo/design-system/components/button/IconButton";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@replo/design-system/components/shadcn/core/popover";
import { Spinner } from "@replo/design-system/components/spinner";
import { BsCheck, BsFilterCircle, BsX } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { ReploElementVersionKinds } from "schemas/element";
import { twMerge } from "tailwind-merge";
import { v4 as uuid } from "uuid";

import Separator from "../common/designSystem/Separator";

// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */

const createFakeRevision = (user: User): ReploElementVersionRevision => {
  return {
    id: uuid(),
    createdAt: new Date().toISOString(),
    kind: ReploElementVersionKinds.current,
    title: "Current Version",
    createdBy: user,
  };
};

const AUTOMATIC_VERSIONS = "AUTOMATIC_VERSIONS";

type ElementVersionCombinedList = Array<
  ReploElementVersionRevision | ElementVersionRevisionHistory
>;

const useElementVersionCombinedList = (
  versions: ReploElementVersionRevision[],
  fakeRevision: ReploElementVersionRevision,
) =>
  React.useMemo(() => {
    const orderedVersions = versions.reduce(
      (
        versionHistory: ElementVersionCombinedList,
        version: ReploElementVersionRevision,
      ) => {
        const mostRecentVersion = versionHistory[versionHistory.length - 1];
        const mostRecentVersionIsAutomatic = Boolean(
          mostRecentVersion?.title == AUTOMATIC_VERSIONS,
        );
        let mostRecentVersionGroup = mostRecentVersionIsAutomatic
          ? (mostRecentVersion as ElementVersionRevisionHistory)
          : null;

        if (version.kind == "automatic") {
          if (!mostRecentVersionIsAutomatic || !mostRecentVersionGroup) {
            mostRecentVersionGroup = {
              title: AUTOMATIC_VERSIONS,
              items: [],
            };
            versionHistory.push(mostRecentVersionGroup);
          }
          mostRecentVersionGroup.items.push(version);
        } else {
          if (
            mostRecentVersionGroup &&
            mostRecentVersionIsAutomatic &&
            mostRecentVersionGroup.items.length < 3
          ) {
            versionHistory = [
              ...versionHistory.slice(0, -1),
              ...mostRecentVersionGroup.items,
            ];
          }
          versionHistory.push(version);
        }
        return versionHistory;
      },
      [],
    );
    return [fakeRevision, ...orderedVersions];
  }, [versions, fakeRevision]);

const VERSION_HISTORY_FILTER_OPTIONS: {
  label: string;
  value: string;
}[] = [
  { label: "All", value: "all" },
  { label: "By you", value: "user" },
  { label: "Published", value: "publish" },
  { label: "Restored", value: "revert" },
];

// TODO (Matt 2024-12-11): This is a placeholder until the Menu and MenuItem components are added
// into the design system. They are currently on a feature branch, all of this should be updated
// when that is merged in.
const VersionHistoryFilter = ({
  filterBy,
  setFilterBy,
}: {
  filterBy: ReploElementVersionFilter;
  setFilterBy: (filter: ReploElementVersionFilter) => void;
}) => {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          className={twMerge(
            filterBy !== "all" && "bg-selectable-selected text-primary",
          )}
          icon={<BsFilterCircle size={12} />}
          tooltipText="Filter"
          variant="tertiary"
          size="sm"
        />
      </PopoverTrigger>
      <PopoverContent asChild className="p-2">
        <div className="flex flex-col gap-2 text-default typ-body-small w-[200px]">
          <div className="flex flex-col gap-1">
            <RadioGroup
              defaultValue={filterBy}
              value={filterBy}
              onValueChange={(value) =>
                setFilterBy(value as ReploElementVersionFilter)
              }
              className="flex flex-col gap-0.5 items-start"
            >
              {VERSION_HISTORY_FILTER_OPTIONS.map(({ label, value }) => (
                <RadioGroupItem
                  key={value}
                  value={value}
                  className={twMerge(
                    "w-full rounded text-left px-2 py-1 flex justify-between items-center hover:bg-hover",
                    filterBy === value && "bg-menu-item-selected",
                  )}
                >
                  {label}
                  {filterBy === value && <BsCheck size={16} />}
                </RadioGroupItem>
              ))}
            </RadioGroup>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default function VersionHistoryModal() {
  const editorMode = useEditorSelector(selectEditorMode);
  return editorMode === EditorMode.versioning && <VersionHistoryPane />;
}

function VersionHistoryPane() {
  const dispatch = useDispatch();
  const selectedRevisionId = useEditorSelector(selectSelectedRevisionId);
  const { user } = useCurrentUser();
  const {
    versions: _versions,
    isLoading,
    isFetchingNextPage,
    loadMoreRef,
    filterBy,
    setFilterBy,
  } = useElementVersioning();
  const versions: ReploElementVersionRevision[] = orderByDate(
    _versions,
    "createdAt",
  );
  const closeVersionHistory = React.useCallback(() => {
    dispatch(setEditorMode(EditorMode.edit));
  }, [dispatch]);

  const fakeRevision = React.useMemo(() => {
    return createFakeRevision(user!);
  }, [user]);

  const versionHistory: ElementVersionCombinedList =
    useElementVersionCombinedList(versions, fakeRevision);

  const onItemClick = (item: ReploElementVersionRevision) => {
    if (item.kind == ReploElementVersionKinds.current) {
      dispatch(setSelectedRevisionId(null));
    } else {
      dispatch(setSelectedRevisionId(item.id));
    }
  };

  return (
    <div className="fixed left-0 top-[61px] w-[268px] bg-white h-[calc(100%-60px)] border-r-0.5">
      <div className="flex h-full w-full flex-col relative">
        <div className="fixed flex justify-between items-center z-max bg-white w-[268px] px-3 pt-3 pb-6 border-r-0.5">
          <h2 className="text-sm font-semibold text-default transition-colors">
            Version History
          </h2>
          <div className="flex items-center gap-2">
            <VersionHistoryFilter
              filterBy={filterBy}
              setFilterBy={setFilterBy}
            />
            <IconButton
              variant="tertiary"
              size="sm"
              tooltipText="Close"
              onClick={() => closeVersionHistory()}
              icon={<BsX size={16} />}
            />
          </div>
        </div>
        <div className="h-full pt-12 mt-1.5 overflow-y-scroll no-scrollbar">
          {!isLoading ? (
            <div className="relative pl-3 pt-2 pr-2">
              {versionHistory.length > 1 && (
                <Separator
                  orientation="vertical"
                  className={twMerge(
                    "absolute ml-[0.6rem] top-4 z-0",
                    "max-h-[calc(100%-2rem)]",
                  )}
                />
              )}
              {versionHistory.map((version, groupIndex) => {
                const isFirstItem = groupIndex === 0;
                const isLastItem = groupIndex === versionHistory.length - 1;
                if (version.title == AUTOMATIC_VERSIONS) {
                  const versionsGroup =
                    version as ElementVersionRevisionHistory;
                  return (
                    <div
                      key={versionsGroup.items[0]!.id}
                      ref={isLastItem ? loadMoreRef : undefined}
                    >
                      <CollapsibleVersionCard
                        className={twMerge(
                          isLastItem && "relative bg-white",
                          isFirstItem && "mt-0",
                        )}
                        versionsGroup={versionsGroup}
                        selectedRevisionId={selectedRevisionId}
                        onItemClick={onItemClick}
                      />
                    </div>
                  );
                }
                const item = version as ReploElementVersionRevision;
                const cardIsActive =
                  (item.id === fakeRevision.id && !selectedRevisionId) ||
                  selectedRevisionId === item.id;
                return (
                  <VersionCard
                    key={item.id}
                    ref={isLastItem ? loadMoreRef : undefined}
                    className={twMerge(
                      isLastItem && "relative bg-white",
                      isFirstItem && "mt-0",
                    )}
                    version={item}
                    isActive={cardIsActive}
                    onClick={() => {
                      onItemClick(item);
                    }}
                  />
                );
              })}

              {isFetchingNextPage && (
                <div className="flex min-h-[100px] items-center justify-center">
                  <Spinner variant="primary" size={33} />
                </div>
              )}
            </div>
          ) : (
            <div className="flex h-full pt-2 items-center justify-center">
              <Spinner variant="primary" size={33} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
