import type {
  Flow,
  FlowInstance,
  FlowInstanceStepResult,
  FlowStep,
} from "schemas/generated/flow";

/**
 * To calculate the previous step first we should know every step that could lead to the current step.
 * Then we should check the completed steps data to find the last one that could lead to the current step.
 */
const getPreviousStepId = (
  currentFlow: Flow,
  currentStep: FlowStep,
  currentInstance: FlowInstance | null,
) => {
  // NOTE (Fran 2024-02-01): To calculate the previous step first we should know every step
  // that could lead to the current step.
  const stepsThatCouldLeadToCurrentStep = currentFlow?.steps.filter((step) => {
    if (typeof step.nextStep === "string") {
      return step.nextStep === currentStep.id;
    }

    if (Array.isArray(step.nextStep)) {
      return step.nextStep.some((step) => step.nextStep === currentStep.id);
    }

    return false;
  });

  // NOTE (Fran 2024-02-01): Then we should order the completed steps results.
  const resultsOrderedByDate = Object.values(currentInstance?.stepResults ?? {})
    .filter(
      (result): result is FlowInstanceStepResult & { completedAt: string } =>
        Boolean(result.completedAt),
    )
    .sort((a, b) => {
      return a.completedAt > b.completedAt ? -1 : 1;
    });

  // NOTE (Fran 2024-02-01): Finally we should check the completed steps data to find the last one
  // that could lead to the current step.
  const previousStepType = resultsOrderedByDate.find((result) => {
    return stepsThatCouldLeadToCurrentStep?.some(
      (step) => step.type === result.type,
    );
  })?.type;

  return (
    currentFlow?.steps.find((step) => step.type === previousStepType)?.id ??
    null
  );
};

export default getPreviousStepId;
