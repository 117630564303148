import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

import { transformReviewsTemplateToUseProductForContextIfNecessary } from "../defaultComponentTemplates";

export const okendoProductRatingSummary: ComponentTemplate = {
  id: "cb187da5-41b4-4b57-91fd-74a70f8ca3d0",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Okendo Product Rating",
  leftBarCategory: { main: "productIntegrations", subCategory: "okendo" },
  preview: "integrations/okendo-rating.svg",
  transforms: [transformReviewsTemplateToUseProductForContextIfNecessary],
  tags: ["reviews", "star"],
  template: {
    id: "$uid",
    type: "okendoProductRatingSummary",
    props: {},
  },
};
