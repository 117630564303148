import * as React from "react";

import { useComposedRefs } from "./use-composed-refs";

export function useStatefulElement<T extends Element>(
  ref: React.RefObject<T>,
): [T | null, React.RefCallback<T>] {
  const [element, setElement] = React.useState<T | null>(null);
  const setElementRef = useComposedRefs(
    ref,
    setElement as React.RefCallback<T>,
  );
  return [element, setElementRef];
}
