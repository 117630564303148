import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { SharedJunipReviews } from "../SharedJunipReviews";

const JUNIP_LIQUID_SOURCE = `
  <span
    class="junip-product-review"
    data-product-id="{{ product.id }}">
  </span>
`;

const JunipReviews: React.FC<RenderComponentProps> = (props) => {
  return (
    <SharedJunipReviews
      attributes={props.componentAttributes}
      component={props.component}
      context={props.context}
      liquidSource={JUNIP_LIQUID_SOURCE}
      reviewsComponentType="Reviews"
    />
  );
};

export default JunipReviews;
