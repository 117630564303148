import type { PopoverProps } from "@editor/components/common/designSystem/Popover";
import type { Swatch, SwatchType } from "replo-runtime/shared/types";

import * as React from "react";

import Selectable from "@common/designSystem/Selectable";
import * as ProductSelectionPopover from "@components/editor/page/ProductSelectionPopover";
import Banner from "@editor/components/common/designSystem/Banner";
import {
  Group,
  GroupHeader,
} from "@editor/components/common/designSystem/Group";
import InputComponent from "@editor/components/common/designSystem/Input";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Popover from "@editor/components/common/designSystem/Popover";
import Scrollable from "@editor/components/common/designSystem/Scrollable";
import {
  getSwatchOptionsRows,
  getSwatchVariantsRows,
  SwatchOptionRow,
  SwatchVariantRow,
} from "@editor/components/editor/page/SwatchRow";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useModal } from "@editor/hooks/useModal";
import { useSpecificStoreProducts } from "@editor/hooks/useStoreProducts";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { getRandomProductId } from "replo-runtime/shared/utils/product";
import { useDebouncedCallback } from "replo-utils/react/use-debounced-callback";

type SwatchPopoverProps = {
  swatch: Swatch;
  isNewSwatch: boolean;
  isReadOnly: boolean;
  isOpen?: PopoverProps["isOpen"];
  onOpenChange?: PopoverProps["onOpenChange"];
};

const SwatchPopover: React.FC<SwatchPopoverProps> = ({
  swatch,
  isNewSwatch,
  isReadOnly,
  isOpen,
  onOpenChange,
}) => {
  const projectId = useCurrentProjectId()!;
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const modal = useModal();
  const utils = trpc.useUtils();
  const { mutate: mutateSwatch } = trpc.swatch.update.useMutation({
    onMutate: async ({ swatch: updatedSwatch }) => {
      await utils.swatch.list.cancel();
      const previousSwatches = utils.swatch.list.getData();
      utils.swatch.list.setData({ projectId }, (old: Swatch[] | undefined) => {
        const index = old?.findIndex((s) => s.id === updatedSwatch.id);
        if (index === -1 || index === undefined) {
          return [...(old || []), updatedSwatch];
        }
        return old?.map((swatch) =>
          swatch.id === updatedSwatch.id
            ? { ...swatch, ...updatedSwatch }
            : swatch,
        );
      });

      return { previousSwatches };
    },
    onError: (_err, _updatedSwatch, context) => {
      utils.swatch.list.setData({ projectId }, context?.previousSwatches);
    },
    onSettled: () => {
      void utils.swatch.list.invalidate();
    },
  });
  const { products } = useSpecificStoreProducts(swatch.data.productIds!, {
    forceSkip: !swatch.data.productIds,
  });

  const numOptions = React.useMemo(
    () => products.reduce((acc, product) => acc + product.options.length, 0),
    [products],
  );

  const onChangeName = React.useCallback(
    (value: string) => {
      mutateSwatch({
        projectId,
        swatch: { ...swatch, name: value },
      });
    },
    [swatch, mutateSwatch, projectId],
  );

  const handleDeletion = () => {
    modal.openModal({
      type: "deleteSwatchConfirmationModal",
      props: {
        swatchId: swatch.id,
        swatchName: swatch.name,
        onDelete: () => onOpenChange?.(false),
      },
    });
  };

  const [name, setName] = useOverridableState(
    swatch.name,
    useDebouncedCallback(onChangeName, 300),
  );

  return (
    <Popover isOpen={isOpen} onOpenChange={onOpenChange}>
      <Popover.Content
        title={getTitle(isReadOnly, isNewSwatch)}
        shouldPreventDefaultOnInteractOutside={areModalsOpen}
        className="w-[348px]"
        side="left"
        sideOffset={-170}
      >
        <Scrollable type="vertical" shouldShowScrollbar={false}>
          <div className="max-h-[275px] mx-1">
            {isReadOnly && (
              <Banner
                className="py-1 pl-3 text-xs text-gray-500"
                backgroundColor="bg-yellow-100"
              >
                Editing is disabled for placeholder swatches. Click the + icon
                to add a swatch.
              </Banner>
            )}
            <div className="flex gap-2">
              <div className="flex-1">
                <LabeledControl label="Name" size="sm" id="swatch-name">
                  <div className="mb-2">
                    <InputComponent
                      size="sm"
                      autoFocus
                      value={name}
                      onChange={(e) => {
                        const { value } = e.target;
                        setName(value);
                      }}
                      isDisabled={isReadOnly}
                      id="swatch-name"
                    />
                  </div>
                </LabeledControl>
              </div>
              <div className="flex-1">
                <LabeledControl label="Swatch Type" size="sm">
                  <Selectable
                    placeholder=""
                    options={[
                      { label: "By Option", value: "option" },
                      { label: "By Variant", value: "variant" },
                    ]}
                    defaultValue={swatch.data?.type}
                    onSelect={(newValue) => {
                      if (projectId && newValue) {
                        mutateSwatch({
                          projectId,
                          swatch: {
                            ...swatch,
                            data: {
                              ...swatch.data,
                              type: newValue as SwatchType,
                            },
                          },
                        });
                      }
                    }}
                    isDisabled={isReadOnly}
                  />
                </LabeledControl>
              </div>
            </div>
            {!isReadOnly && (
              <ProductSelectionPopover.Root
                key={swatch.id}
                onSubmit={(newValue) => {
                  const newProductIds = newValue.map(
                    (product) => product.productId,
                  );
                  const newData =
                    newProductIds.length > 0
                      ? {
                          ...swatch.data,
                          productIds: newValue.map((p) => Number(p.productId)),
                        }
                      : Object.assign({}, swatch.data, {
                          productIds: undefined,
                        });
                  if (projectId) {
                    mutateSwatch({
                      projectId,
                      swatch: { ...swatch, data: newData },
                    });
                  }
                }}
                value={(swatch.data?.productIds ?? []).map((id) => ({
                  productId: id,
                  id: getRandomProductId(),
                }))}
                isMultiSelection
                className="w-full text-center"
              >
                <ProductSelectionPopover.Content offset={18} />
                <ProductSelectionPopover.Trigger />
              </ProductSelectionPopover.Root>
            )}

            {swatch.data?.type === "option" && numOptions > 0 && (
              <Group name="Options" className="mt-4 flex flex-col">
                <ul className="flex w-full flex-col gap-2 text-xs">
                  {products.map((product) => (
                    <Group
                      key={product.id}
                      name={product.title}
                      isCollapsible
                      isDefaultOpen
                      header={
                        <GroupHeader className="overflow-hidden w-[320px]" />
                      }
                    >
                      {product.options.length > 0 && (
                        <ul className="flex w-full flex-col gap-2 text-xs">
                          {getSwatchOptionsRows(product, swatch).map(
                            (option) => (
                              <li
                                key={option.id}
                                className="grid grid-cols-2 items-center gap-1"
                              >
                                <SwatchOptionRow
                                  option={option}
                                  swatch={swatch}
                                  isReadOnly={isReadOnly}
                                />
                              </li>
                            ),
                          )}
                        </ul>
                      )}
                    </Group>
                  ))}
                </ul>
              </Group>
            )}

            {swatch.data?.type === "variant" && (
              <Group name="Variants" className="mt-4 flex flex-col">
                <div className="mt-1 flex flex-col gap-3">
                  {products.map((product) => (
                    <Group
                      key={product.id}
                      name={product.title}
                      isCollapsible
                      isDefaultOpen
                      header={
                        <GroupHeader className="overflow-hidden w-[320px]" />
                      }
                    >
                      {product.variants.length > 0 && (
                        <ul className="flex w-full flex-col gap-2 text-xs">
                          {getSwatchVariantsRows(product, swatch).map(
                            (variant) => (
                              <li
                                key={variant.id}
                                className="grid grid-cols-2 items-center gap-1"
                              >
                                <SwatchVariantRow
                                  variant={variant}
                                  swatch={swatch}
                                  isReadOnly={isReadOnly}
                                />
                              </li>
                            ),
                          )}
                        </ul>
                      )}
                    </Group>
                  ))}
                </div>
              </Group>
            )}
            {!isReadOnly && (
              <Button
                className="mt-3 w-full"
                onClick={handleDeletion}
                variant="secondaryDanger"
              >
                Delete Swatch
              </Button>
            )}
          </div>
        </Scrollable>
      </Popover.Content>
      <Popover.Anchor className="absolute top-0 left-[-180px]" />
    </Popover>
  );
};

export default SwatchPopover;

function getTitle(isReadOnly: boolean, isNewSwatch: boolean) {
  if (isNewSwatch) {
    return "New Swatch";
  }
  if (isReadOnly) {
    return "View Swatch";
  }
  return "Edit Swatch";
}
