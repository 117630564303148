import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

import { transformReviewsTemplateToUseProductForContextIfNecessary } from "../defaultComponentTemplates";

export const yotpoReviews: ComponentTemplate = {
  id: "cbc81b16-b47f-4bd9-8b4e-dd3b6d7f4c8d",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Yotpo Reviews",
  leftBarCategory: { main: "productIntegrations", subCategory: "yotpo" },
  preview: "integrations/yotpo-product-reviews.svg",
  transforms: [transformReviewsTemplateToUseProductForContextIfNecessary],
  template: {
    id: "$uid",
    type: "yotpoReviews",
    props: {},
  },
};
