import type { ButtonProps } from "@replo/design-system/components/button";

import * as React from "react";

import Button from "@replo/design-system/components/button";

// NOTE (Chance 2023-12-04): This union type ensures that all icon buttons have
// a prop that can be used for accessible labels. A button shouldn't have both
// `aria-label` and `aria-labelledby`, but it can have one or the other
// alongside a `tooltipText` prop. If neither `aria-label` nor `aria-labelledby`
// is provided, then `tooltipText` is required as it will be used as the
// accessible label.
type AccessibleLabelProps =
  | {
      isPhonyButton?: false;
      tooltipText?: string | null;
      "aria-label": string;
      "aria-labelledby"?: never;
    }
  | {
      isPhonyButton?: false;
      tooltipText?: string | null;
      "aria-label"?: never;
      "aria-labelledby": string;
    }
  | {
      isPhonyButton?: false;
      tooltipText: string;
      "aria-label"?: string;
      "aria-labelledby"?: never;
    }
  | {
      isPhonyButton?: false;
      tooltipText: string;
      "aria-label"?: never;
      "aria-labelledby"?: string;
    }
  // NOTE (Chance 2023-12-04): For fake buttons, neither aria-label nor
  // aria-labelledby will make a difference because the underlying button
  // doesn't have a role. So we'd expect the parent component to ensure there is
  // an accessible label.
  | {
      isPhonyButton: true;
      tooltipText?: string | null;
      "aria-label"?: never;
      "aria-labelledby"?: never;
    };

type InheritedButtonProps = Omit<
  ButtonProps,
  "aria-label" | "aria-labelledby" | "tooltipText"
>;

type IconButtonProps = {
  icon: React.ReactNode;
} & InheritedButtonProps &
  AccessibleLabelProps;

const IconButton = React.forwardRef<any, IconButtonProps>(
  ({ isPhonyButton, ...props }, ref) => {
    if (isPhonyButton) {
      return (
        <Button ref={ref} {...props} isPhonyButton>
          {props.children}
        </Button>
      );
    }
    return <Button ref={ref} {...props} />;
  },
);
IconButton.displayName = "IconButton";

export default IconButton;
