import * as React from "react";

import { getVisibleBreakpoints } from "@editor/components/editor/page/element-editor/components/modifiers/utils";
import { selectDraftComponent } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";

import { mapNull } from "replo-runtime/shared/utils/optional";

import useApplyComponentAction from "./useApplyComponentAction";
import { useGetAttribute } from "./useGetAttribute";

export function useVisibleBreakpoints() {
  const draftComponent = useEditorSelector(selectDraftComponent);
  const getAttribute = useGetAttribute();
  const applyComponentAction = useApplyComponentAction();

  const visibleBreakpoints = mapNull(draftComponent, (component) =>
    getVisibleBreakpoints(component, getAttribute),
  );

  const onChangeVisibility = React.useCallback(
    (value: string) => {
      if (visibleBreakpoints) {
        if (!visibleBreakpoints.includes(value)) {
          // Making the component VISIBLE for the selected breakpoint value
          applyComponentAction({
            type: "setProps",
            value: {
              [`${value}`]: {
                display:
                  getAttribute(draftComponent, `props.${value}.__display`)
                    ?.value ||
                  styleAttributeToEditorData["__display"].defaultValue,
              },
            },
          });
        } else {
          const thisDisplayValue =
            getAttribute(draftComponent, `props.${value}.display`)?.value ||
            styleAttributeToEditorData["display"].defaultValue;
          // Making the component HIDDEN for the selected breakpoint value.
          // Set __display correctly, so that display: flex components get
          // reset to display: flex (instead of block) when they're unhidden
          const propsToUpdate: Record<string, any> = {
            [`${value}`]: {
              __display: thisDisplayValue,
              display: "none",
            },
          };

          const valueToSmallerAliases: Record<string, string[]> = {
            style: ["sm", "md"],
            "style@md": ["sm"],
          };

          // If we're setting the desktop style to hidden and we don't have a
          // mobile style set, we need to set the mobile style to block so
          // it will still show up on mobile
          if (valueToSmallerAliases[value]) {
            for (const alias of valueToSmallerAliases[value]!) {
              const smallerDisplayValue = getAttribute(
                draftComponent,
                `props.style@${alias}.display`,
              )?.value;

              if (!smallerDisplayValue) {
                const newValue = smallerDisplayValue || thisDisplayValue;
                propsToUpdate[`style@${alias}`] = {
                  display: newValue,
                  __display: newValue,
                };
              }
            }
          }
          applyComponentAction({
            type: "setProps",
            value: propsToUpdate,
          });
        }
      }
      return null;
    },
    [draftComponent, visibleBreakpoints, applyComponentAction, getAttribute],
  );

  return {
    visibleBreakpoints,
    onChangeVisibility,
  };
}
