import * as React from "react";

import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type BackButtonProps = {
  label?: string;
  classname?: string;
};
export const BackButton: React.FC<BackButtonProps> = ({ label, classname }) => {
  const navigate = useNavigate();

  const onBack = () => {
    navigate(-1);
  };

  return (
    <button
      className={twMerge(
        "flex items-center gap-1 font-medium text-lg text-blue-600",
        classname,
      )}
      onClick={onBack}
    >
      <BsArrowLeft size={18} />
      {label}
    </button>
  );
};
