import type { Digits, LowercaseAlphabet } from "replo-runtime/shared/types";
import type { Command } from "./commands";

import { Commands } from "./commands";

export type Hotkey =
  | Command
  | "escape"
  | "deselectCurrentComponent"
  | "editText"
  | "debug"
  | "groupIntoContainerTwo"
  | "preventSelectingAllTextsOnEditor"
  | "openHotkeysModal"
  | "enter"
  | "selectAIText"
  | "selectAIMobileResponsive";

export type HotkeyMetaKey =
  | "meta"
  | "shift"
  | "altOption"
  | "backspace"
  | "delete"
  | "space"
  | "esc"
  | "arrowUp"
  | "arrowDown"
  | "arrowLeft"
  | "arrowRight";

export type HotkeyIndicatorCharacter =
  | HotkeyMetaKey
  | LowercaseAlphabet
  | Digits
  | "-"
  | "+"
  | "="
  | "<"
  | ">"
  | "↑"
  | "↓";

export const HotkeyMetaKeyToLabel: Record<
  HotkeyMetaKey,
  string | { mac: string; windows: string }
> = {
  meta: {
    mac: "⌘",
    windows: "CTRL",
  },
  shift: "⇧",
  altOption: {
    mac: "⌥",
    windows: "⎇",
  },
  backspace: "⌫",
  delete: "del",
  esc: {
    mac: "⎋",
    windows: "ESC",
  },
  space: "␣",
  arrowRight: "→",
  arrowDown: "↓",
  arrowLeft: "←",
  arrowUp: "↑",
};

export type HotkeyData = {
  controlStrings: string[];
  indicatorCharacters: HotkeyIndicatorCharacter[];
  ignoresModifiersOnKeyup: boolean;
  displayName: string;
  triggerInTextInputs: boolean;
  group?: "Text" | "Edit" | "View" | "Zoom";
};

// TODO (Martin 2024-12-05): Convert this into a static map once we remove the
// saved styles feature flag.
export function getHotkeys(
  isSavedStylesEnabled: boolean,
): Record<Hotkey, HotkeyData> {
  return {
    undo: {
      controlStrings: ["mod+z"],
      indicatorCharacters: Commands.undo.hotkey!,
      displayName: Commands.undo.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Edit",
    },
    redo: {
      controlStrings: ["mod+shift+z"],
      indicatorCharacters: Commands.redo.hotkey!,
      displayName: Commands.redo.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "Edit",
    },
    copy: {
      controlStrings: ["mod+c"],
      indicatorCharacters: Commands.copy.hotkey!,
      displayName: Commands.copy.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "Edit",
    },
    copyStyles: {
      controlStrings: ["alt+mod+c"],
      indicatorCharacters: Commands.copyStyles.hotkey!,
      displayName: Commands.copyStyles.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "Edit",
    },
    paste: {
      controlStrings: ["mod+v"],
      indicatorCharacters: Commands.paste.hotkey!,
      displayName: Commands.paste.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "Edit",
    },
    pasteStyles: {
      controlStrings: ["alt+mod+v"],
      indicatorCharacters: Commands.pasteStyles.hotkey!,
      displayName: Commands.pasteStyles.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "Edit",
    },
    toggleVisibility: {
      controlStrings: ["mod+shift+h"],
      indicatorCharacters: Commands.toggleVisibility.hotkey!,
      displayName: Commands.toggleVisibility.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "View",
    },
    delete: {
      controlStrings: ["backspace", "delete"],
      indicatorCharacters: Commands.delete.hotkey!,
      displayName: Commands.delete.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "Edit",
    },
    duplicate: {
      controlStrings: ["mod+d"],
      indicatorCharacters: Commands.duplicate.hotkey!,
      displayName: Commands.duplicate.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "Edit",
    },
    debug: {
      controlStrings: ["mod+shift+o"],
      indicatorCharacters: ["meta", "shift", "o"],
      displayName: "Debug",
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
    },
    groupIntoContainer: {
      controlStrings: ["mod+g"],
      indicatorCharacters: Commands.groupIntoContainer.hotkey!,
      displayName: Commands.groupIntoContainer.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
    },
    groupIntoContainerTwo: {
      controlStrings: ["shift+a"],
      indicatorCharacters: ["shift", "a"],
      displayName: "Group into container",
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "Edit",
    },
    saveComponentTemplate: {
      controlStrings: ["mod+shift+s"],
      indicatorCharacters: Commands.saveComponentTemplate.hotkey!,
      displayName: Commands.saveComponentTemplate.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "Edit",
    },
    mockSave: {
      controlStrings: ["mod+s"],
      indicatorCharacters: Commands.mockSave.hotkey!,
      displayName: Commands.mockSave.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "Edit",
    },
    zoomIn: {
      controlStrings: ["mod+="],
      indicatorCharacters: Commands.zoomIn.hotkey!,
      displayName: Commands.zoomIn.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "Zoom",
    },
    zoomOut: {
      controlStrings: ["mod+-"],
      indicatorCharacters: Commands.zoomOut.hotkey!,
      displayName: Commands.zoomOut.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "Zoom",
    },
    resetZoom: {
      controlStrings: ["mod+0"],
      indicatorCharacters: Commands.resetZoom.hotkey!,
      displayName: Commands.resetZoom.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "Zoom",
    },
    grabCanvas: {
      // Note (Noah, 2023-04-22, USE-77): We want to ignore modifier keys on keyup
      // here because the keydown and keyup for this hotkey are different - keydown
      // enables grab mode and keyup disables it. If we don't ignore modifiers on
      // keyup, then holding down space, then pressing shift, then releasing space
      // will result in the keyup not being called and the grab mode will be stuck.
      ignoresModifiersOnKeyup: true,
      controlStrings: ["space"],
      indicatorCharacters: Commands.grabCanvas.hotkey!,
      displayName: Commands.grabCanvas.label,
      triggerInTextInputs: false,
      group: "View",
    },
    deselectCurrentComponent: {
      controlStrings: ["escape"],
      indicatorCharacters: ["esc"],
      displayName: "Deselect component",
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "Edit",
    },
    toggleCodeEditor: {
      controlStrings: ["shift+d"],
      indicatorCharacters: Commands.toggleCodeEditor.hotkey!,
      displayName: Commands.toggleCodeEditor.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "Edit",
    },
    preventSelectingAllTextsOnEditor: {
      ignoresModifiersOnKeyup: false,
      controlStrings: ["mod+a"],
      indicatorCharacters: ["meta", "a"],
      displayName: "Prevent selecting all texts on editor",
      triggerInTextInputs: false,
    },
    togglePreviewMode: {
      ignoresModifiersOnKeyup: false,
      controlStrings: ["shift+p"],
      indicatorCharacters: Commands.togglePreviewMode.hotkey!,
      displayName: Commands.togglePreviewMode.label,
      triggerInTextInputs: false,
      group: "View",
    },
    toggleVersionHistory: {
      controlStrings: ["alt+shift+h"],
      indicatorCharacters: Commands.toggleVersionHistory.hotkey!,
      displayName: Commands.toggleVersionHistory.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "View",
    },
    escape: {
      ignoresModifiersOnKeyup: false,
      controlStrings: ["escape"],
      indicatorCharacters: ["esc"],
      displayName: "Escape",
      triggerInTextInputs: false,
      group: "Edit",
    },
    toggleAIMenu: {
      controlStrings: ["mod+e"],
      indicatorCharacters: Commands.toggleAIMenu.hotkey!,
      displayName: Commands.toggleAIMenu.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "View",
    },
    toggleH1Text: {
      controlStrings: ["mod+alt+1"],
      indicatorCharacters: Commands.toggleH1Text.hotkey!,
      displayName: Commands.toggleH1Text.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    toggleH2Text: {
      controlStrings: ["mod+alt+2"],
      indicatorCharacters: Commands.toggleH2Text.hotkey!,
      displayName: Commands.toggleH2Text.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    toggleH3Text: {
      controlStrings: ["mod+alt+3"],
      indicatorCharacters: Commands.toggleH3Text.hotkey!,
      displayName: Commands.toggleH3Text.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    toggleH4Text: {
      controlStrings: ["mod+alt+4"],
      indicatorCharacters: Commands.toggleH4Text.hotkey!,
      displayName: Commands.toggleH4Text.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    toggleH5Text: {
      controlStrings: ["mod+alt+5"],
      indicatorCharacters: Commands.toggleH5Text.hotkey!,
      displayName: Commands.toggleH5Text.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    toggleH6Text: {
      controlStrings: ["mod+alt+6"],
      indicatorCharacters: Commands.toggleH6Text.hotkey!,
      displayName: Commands.toggleH6Text.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    toggleBoldText: {
      controlStrings: ["mod+b"],
      indicatorCharacters: Commands.toggleBoldText.hotkey!,
      displayName: Commands.toggleBoldText.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    toggleLinkText: {
      controlStrings: ["mod+k"],
      indicatorCharacters: Commands.toggleLinkText.hotkey!,
      displayName: Commands.toggleLinkText.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    toggleItalicText: {
      controlStrings: ["mod+i"],
      indicatorCharacters: Commands.toggleItalicText.hotkey!,
      displayName: Commands.toggleItalicText.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    toggleUnderlineText: {
      controlStrings: ["mod+u"],
      indicatorCharacters: Commands.toggleUnderlineText.hotkey!,
      displayName: Commands.toggleUnderlineText.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    toggleStrikethroughText: {
      controlStrings: ["shift+mod+x"],
      indicatorCharacters: Commands.toggleStrikethroughText.hotkey!,
      displayName: Commands.toggleStrikethroughText.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    toggleBulletList: {
      controlStrings: ["mod+alt+7"],
      indicatorCharacters: Commands.toggleBulletList.hotkey!,
      displayName: Commands.toggleBulletList.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    toggleNumberedList: {
      controlStrings: ["mod+alt+8"],
      indicatorCharacters: Commands.toggleNumberedList.hotkey!,
      displayName: Commands.toggleNumberedList.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    setDesignPanel: {
      controlStrings: ["s"],
      indicatorCharacters: Commands.setDesignPanel.hotkey!,
      displayName: Commands.setDesignPanel.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "View",
    },
    setConfigPanel: {
      controlStrings: ["d"],
      indicatorCharacters: Commands.setConfigPanel.hotkey!,
      displayName: Commands.setConfigPanel.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "View",
    },
    setInteractionsPanel: {
      controlStrings: ["i"],
      indicatorCharacters: Commands.setInteractionsPanel.hotkey!,
      displayName: Commands.setInteractionsPanel.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "View",
    },
    setElementsPanel: {
      controlStrings: ["alt+1"],
      indicatorCharacters: Commands.setElementsPanel.hotkey!,
      displayName: Commands.setElementsPanel.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "View",
    },
    setLayersPanel: {
      controlStrings: ["alt+2"],
      indicatorCharacters: Commands.setLayersPanel.hotkey!,
      displayName: Commands.setLayersPanel.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "View",
    },
    setComponentsPanel: {
      controlStrings: ["alt+3"],
      indicatorCharacters: Commands.setComponentsPanel.hotkey!,
      displayName: Commands.setComponentsPanel.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "View",
    },
    setSavedStylesPanel: {
      controlStrings: [isSavedStylesEnabled ? "alt+4" : ""],
      indicatorCharacters: Commands.setSavedStylesPanel.hotkey!,
      displayName: Commands.setSavedStylesPanel.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "View",
    },
    openPageSettings: {
      controlStrings: [isSavedStylesEnabled ? "alt+5" : "alt+4"],
      indicatorCharacters: ["altOption", isSavedStylesEnabled ? "5" : "4"],
      displayName: Commands.openPageSettings.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "View",
    },
    openProjectSettings: {
      controlStrings: ["mod+alt+s"],
      indicatorCharacters: Commands.openProjectSettings.hotkey!,
      displayName: Commands.openProjectSettings.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "View",
    },
    openHotkeysModal: {
      controlStrings: ["shift+?"],
      indicatorCharacters: ["shift"],
      displayName: "Show hotkeys modal",
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
    },
    decreaseFontSize: {
      controlStrings: ["shift+mod+,", "shift+mod+<"],
      indicatorCharacters: Commands.decreaseFontSize.hotkey!,
      displayName: Commands.decreaseFontSize.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    increaseFontSize: {
      controlStrings: ["shift+mod+.", "shift+mod+>"],
      indicatorCharacters: Commands.increaseFontSize.hotkey!,
      displayName: Commands.increaseFontSize.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    decreaseLetterSpacing: {
      controlStrings: ["alt+,", "alt+<"],
      indicatorCharacters: Commands.decreaseLetterSpacing.hotkey!,
      displayName: Commands.decreaseLetterSpacing.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    increaseLetterSpacing: {
      controlStrings: ["alt+.", "alt+>"],
      indicatorCharacters: Commands.increaseLetterSpacing.hotkey!,
      displayName: Commands.increaseLetterSpacing.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    decreaseLineHeight: {
      controlStrings: ["alt+shift+,", "alt+shift+<"],
      indicatorCharacters: Commands.decreaseLineHeight.hotkey!,
      displayName: Commands.decreaseLineHeight.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    increaseLineHeight: {
      controlStrings: ["alt+shift+.", "alt+shift+>"],
      indicatorCharacters: Commands.increaseLineHeight.hotkey!,
      displayName: Commands.increaseLineHeight.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    decreaseFontWeight: {
      controlStrings: ["alt+mod+,", "alt+mod+<"],
      indicatorCharacters: Commands.decreaseFontWeight.hotkey!,
      displayName: Commands.decreaseFontWeight.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    increaseFontWeight: {
      controlStrings: ["alt+mod+.", "alt+mod+>"],
      indicatorCharacters: Commands.increaseFontWeight.hotkey!,
      displayName: Commands.increaseFontWeight.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Text",
    },
    moveUpInTheTree: {
      controlStrings: ["ArrowUp"],
      indicatorCharacters: Commands.moveUpInTheTree.hotkey!,
      displayName: Commands.moveUpInTheTree.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "View",
    },
    moveDownInTheTree: {
      controlStrings: ["ArrowDown"],
      indicatorCharacters: Commands.moveDownInTheTree.hotkey!,
      displayName: Commands.moveDownInTheTree.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "View",
    },
    exportToSection: {
      controlStrings: ["alt+mod+k"],
      indicatorCharacters: Commands.exportToSection.hotkey!,
      displayName: Commands.exportToSection.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Edit",
    },
    setWidthToFillAvailable: {
      controlStrings: ["mod+shift+arrowRight"],
      indicatorCharacters: Commands.setWidthToFillAvailable.hotkey!,
      displayName: Commands.setWidthToFillAvailable.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Edit",
    },
    setWidthToWrapContent: {
      controlStrings: ["mod+shift+arrowLeft"],
      indicatorCharacters: Commands.setWidthToWrapContent.hotkey!,
      displayName: Commands.setWidthToWrapContent.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Edit",
    },
    setHeightToFillAvailable: {
      controlStrings: ["mod+shift+arrowUp"],
      indicatorCharacters: Commands.setHeightToFillAvailable.hotkey!,
      displayName: Commands.setHeightToFillAvailable.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Edit",
    },
    setHeightToWrapContent: {
      controlStrings: ["mod+shift+arrowDown"],
      indicatorCharacters: Commands.setHeightToWrapContent.hotkey!,
      displayName: Commands.setHeightToWrapContent.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
      group: "Edit",
    },
    openMarketplace: {
      controlStrings: ["alt+mod+o"],
      indicatorCharacters: Commands.openMarketplace.hotkey!,
      displayName: Commands.openMarketplace.label,
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
      group: "View",
    },
    enter: {
      controlStrings: ["Enter"],
      indicatorCharacters: [],
      displayName: "Enter",
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
    },
    editText: {
      controlStrings: ["Enter"],
      indicatorCharacters: [],
      displayName: "Edit Text",
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: true,
    },
    // TODO (Gabe 2024-08-09): Get these out of here, there's no reason to define
    // component specific hotkeys so far away from the components.
    selectAIText: {
      controlStrings: ["t"],
      indicatorCharacters: [],
      displayName: "Select AI Text",
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
    },
    selectAIMobileResponsive: {
      controlStrings: ["r"],
      indicatorCharacters: [],
      displayName: "Select AI Mobile Responsive",
      ignoresModifiersOnKeyup: false,
      triggerInTextInputs: false,
    },
  };
}

export function getHotkeyData(
  action: Hotkey,
  // TODO (Sebas, 2024-11-13): Remove this once we remove the saved styles feature flag.
  isSavedStylesEnabled: boolean,
): HotkeyData {
  return getHotkeys(isSavedStylesEnabled)[action];
}
