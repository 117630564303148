import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";

import useCurrentUser from "./useCurrentUser";
import useCurrentWorkspaceId from "./useCurrentWorkspaceId";

export function useHasWorkspaceMembership() {
  const { user } = useCurrentUser();
  const workspaceId = useCurrentWorkspaceId();

  const {
    data: workspaceMembershipsData,
    isLoading: workspaceMembershipsIsLoading,
  } = trpc.workspace.getMembers.useQuery(workspaceId ?? skipToken);

  const isUserMemberOfWorkspace =
    workspaceMembershipsData?.workspaceMemberships.some(
      (membership) => membership.user.id === user?.id,
    );

  return {
    isUserMemberOfWorkspace,
    workspaceMembershipsIsLoading,
    workspaceMembershipsData,
  };
}
