import type { PayloadAction } from "@reduxjs/toolkit";

import { createSlice } from "@reduxjs/toolkit";

export type EditorMediaUploadState = {
  componentIds: string[];
};

const initialState: EditorMediaUploadState = {
  componentIds: [],
};

const editorMediaUploadSlice = createSlice({
  name: "editorMediaUpload",
  initialState,
  reducers: {
    addEditorMediaUploadingComponentId: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.componentIds.push(action.payload);
    },
    removeEditorMediaUploadingComponentId: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.componentIds = state.componentIds.filter(
        (id) => id !== action.payload,
      );
    },
  },
});

export const selectEditorMediaUploadingComponentIds = (state: {
  editorMediaUpload: EditorMediaUploadState;
}) => state.editorMediaUpload.componentIds;

const { actions, reducer } = editorMediaUploadSlice;

export const {
  addEditorMediaUploadingComponentId,
  removeEditorMediaUploadingComponentId,
} = actions;
export default reducer;
