/**
 * If the given value is nullish, return null. Otherwise, return the result of the given function
 * with the non-null value. E.g.:
 *
 * const result = mapNull(someNumberThatMightBeNull, number => number + 1)
 */
export const mapNull = <ValueType, ReturnType>(
  value: ValueType | null | undefined,
  execute: (value: ValueType) => ReturnType,
): ReturnType | null => {
  if (!value) {
    return null;
  }
  return execute(value);
};

/**
 * Access the given record with the given key, unless the key is nullish, in
 * which case return null.
 *
 * NOTE (Chance 2024-03-06): This has been simplified as a one-liner. I don't
 * think we really need this function anymore.
 */
export const getFromRecordOrNull = <RecordKey extends keyof any, RecordValue>(
  record: Record<RecordKey, RecordValue> | null | undefined,
  key: RecordKey | undefined | null,
) => {
  return key ? record?.[key] ?? null : null;
};
