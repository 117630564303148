import type { ProductRef, ProductRefOrDynamic } from "schemas/product";

import * as React from "react";

import { ProductSelector } from "@common/designSystem/ProductSelector";
import ModifierGroup from "@editorExtras/ModifierGroup";

import { BsPlus } from "react-icons/bs";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { getRandomProductId } from "replo-runtime/shared/utils/product";
import { isContextRef } from "replo-runtime/store/ReploProduct";
import { v4 as uuidv4 } from "uuid";

type MultiProductsProps =
  | {
      isMultiProducts: true;
      onChange: (value: ProductRefOrDynamic[]) => void;
      products: ProductRefOrDynamic[];
    }
  | {
      isMultiProducts: undefined | false;
      onChange: (value: ProductRefOrDynamic | null) => void;
      products: ProductRefOrDynamic;
    };

type ProductsModifierProps = {
  allowDynamicData?: boolean;
  description?: string;
} & MultiProductsProps;

export const ProductsCustomPropModifier: React.FC<ProductsModifierProps> = ({
  description,
  products,
  allowDynamicData,
  onChange,
  isMultiProducts,
}) => {
  const initialProducts = React.useMemo(
    () =>
      isMultiProducts
        ? products.map((p) => {
            if (!isContextRef(p)) {
              return p.id ? p : { ...p, id: getRandomProductId() };
            }
            return p;
          })
        : [products],
    [products, isMultiProducts],
  );
  const onChangeProductList = React.useCallback(
    (productsRef: ProductRefOrDynamic[]) => {
      if (isMultiProducts) {
        onChange(productsRef);
      }
    },
    [onChange, isMultiProducts],
  );

  const [productRefList, setProductRefList] = useOverridableState(
    initialProducts,
    onChangeProductList,
  );

  const addNewProductSelector = () => {
    if (isMultiProducts) {
      const newProductRefList = [
        ...products,
        { productId: null, variantId: null, id: uuidv4() },
      ];
      onChange(newProductRefList as ProductRef[]);
    }
  };

  return (
    <ModifierGroup
      title={isMultiProducts ? "Products" : "Product"}
      icon={isMultiProducts ? BsPlus : null}
      iconAriaLabel={isMultiProducts ? "Add Product" : null}
      onClick={addNewProductSelector}
      hideEndEnhancerOnGroupClosed={false}
    >
      {isMultiProducts && products.length === 0 && (
        <div
          className="cursor-pointer text-left text-xs text-gray-400"
          onClick={addNewProductSelector}
        >
          Click the + icon to add a Product
        </div>
      )}
      {
        // @ts-expect-error
        <ProductSelector
          selectedProductRef={isMultiProducts ? productRefList : products}
          onChange={isMultiProducts ? setProductRefList : onChange}
          allowDynamicData={allowDynamicData}
          isMultiProducts={isMultiProducts ?? false}
          dataType="full"
        />
      }
      {description && (
        <div className="pt-1.5 text-xs leading-tight text-slate-400">
          {description}
        </div>
      )}
    </ModifierGroup>
  );
};
