import * as React from "react";

import { canUseDOM } from "../dom/misc";
import { noop } from "../lib/misc";

/**
 * NOTE (Chance 2023-09-21): When using a layout effect in SSR, React will log a
 * warning to the console that useLayoutEffect is a noop on the server and
 * likely to result in a hydration mismatch if used for effects that update
 * state that change the rendered DOM. In some cases we might want to use a
 * layout effect for non-visual effects, such as time-based execution of a
 * callback or syncing values before an event handler is fired. In these cases
 * we should use this to avoid the warning, but it should be used sparingly as
 * the warning is there to catch real potential bugs for server-rendered
 * content.
 */
export const unsafe_useLayoutEffectWithoutWarning = canUseDOM
  ? React.useLayoutEffect
  : noop;
