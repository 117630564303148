const REPLO_CUSTOM_PROPERTY_PREFIX = "replo";

type CSSSideProperty = "border";
type CSSSideModifier = "Color" | "Height" | "Width" | "Style";
type CSSSide = "Top" | "Right" | "Bottom" | "Left";
type CSSSideName<
  Property extends CSSSideProperty,
  Modifier extends CSSSideModifier,
  Side extends CSSSide | "",
> = `${Property}${Side}${Modifier}`;

/**
 * Returns an array of CSS side names for the given properties (autogenerates
 * {name}{Top,Bottom,Left,Right}{property}
 */
export function allCSSSideNames<
  Property extends CSSSideProperty,
  Modifier extends CSSSideModifier,
>(
  property: Property,
  modifier: Modifier,
  options?: {
    /** Whether to include the shorthand property, e.g. "borderWidth" */
    includeShorthand?: false;
  },
): CSSSideName<Property, Modifier, CSSSide>[];

/**
 * Returns an array of CSS side names for the given properties (autogenerates
 * {name}{Top,Bottom,Left,Right}{property}
 */
export function allCSSSideNames<
  Property extends CSSSideProperty,
  Modifier extends CSSSideModifier,
>(
  property: Property,
  modifier: Modifier,
  options: {
    /** Whether to include the shorthand property, e.g. "borderWidth" */
    includeShorthand: true;
  },
): CSSSideName<Property, Modifier, CSSSide | "">[];

export function allCSSSideNames<
  Property extends CSSSideProperty,
  Modifier extends CSSSideModifier,
>(
  property: Property,
  modifier: Modifier,
  options?: {
    includeShorthand?: boolean;
  },
) {
  const { includeShorthand = false } = options ?? {};
  const sides: (CSSSide | "")[] = ["Top", "Right", "Bottom", "Left"];
  if (includeShorthand) {
    sides.push("");
  }
  return sides.map((side) => `${property}${side}${modifier}` as const);
}

/**
 * TODO (Chance 2024-02-23): This will also return true if the value is 0.
 * Unclear if that's the desired behavior but I'm afraid to change it! 0 is a
 * valid, non-empty value for several CSS properties so we should at least
 * clarify this.
 */
export const isEmptyOrAuto = (value: string | number | null | undefined) => {
  if (!value) {
    return true;
  }
  return value === "auto";
};

/**
 * Returns a string that identifies a dynamic data value for CSS custom
 * properties.
 */
export function getDynamicDataCustomPropertyName(dynamicDataKey: string) {
  // NOTE (Chance, 2023-06-09): We have a slugify function already but I think
  // it could be improved a bit. It doesn't account for separating punctuation
  // so user input can easily be converted to an unreadable string. I didn't
  // change it to avoid unintended consequences elsewhere.
  //
  // I think we should slugify functions for performance over readability. TBH
  // there are a million ways to do this and we should probably just reach for a
  // library everywhere unless we have special requirements for some cases.
  const key = dynamicDataKey
    // replace `url({{value}})` with {{value}}, where {{value}} may be wrapped
    // in single or double quotes
    .replace(/url\((["']?){{(.*?)}}\1\)/g, "$2")
    // replace whitespace and separating punctuation with dashes
    .replace(/[\s!&,./:;=\\_]+/g, "-")
    // remove any remaining non-alphanumeric characters
    .replace(/[^\w-]+/g, "")
    // trim starting and trailing dashes
    .replace(/^-+/, "")
    .replace(/-+$/, "")
    .toLowerCase();
  return `--${REPLO_CUSTOM_PROPERTY_PREFIX}-${key}`;
}
