import * as React from "react";

/** 
    NOTE (Sebas, 2024-02-09): 
    This hook identifies the "other" value from the results values and returns it along with the
    heardFrom values. It is used to set the default values for the forms that include the "other"
    option.
 */
const useIdentifyOtherValue = (
  commonOptions: string[],
  resultsValues: string[] | undefined,
) => {
  const { otherValue, values } = React.useMemo(() => {
    const otherValue =
      resultsValues?.filter((value) => !commonOptions.includes(value))?.[0] ??
      "";

    const values =
      otherValue !== ""
        ? ["other", ...(resultsValues ?? [])]
        : resultsValues ?? [];

    return { otherValue, values };
  }, [commonOptions, resultsValues]);

  return { otherValue, values };
};

export default useIdentifyOtherValue;
