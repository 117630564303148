import type {
  AIStatus,
  MenuTriggerSource,
} from "@editor/providers/AIStreamingProvider";
import type { AIStreamingOperation, BrandDetails } from "schemas/generated/ai";
import type { UrlFormValues } from "schemas/url";

import * as React from "react";

import Input, { DebouncedInput } from "@common/designSystem/Input";
import { HotkeyIndicator } from "@common/HotkeyIndicator";
import ErrorMessage from "@components/account/Dashboard/ErrorMessage";
import Popover from "@editor/components/common/designSystem/Popover";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@editor/components/common/designSystem/Tabs";
import Textarea from "@editor/components/common/designSystem/Textarea";
import { infoToast, toast } from "@editor/components/common/designSystem/Toast";
import useExponentialProgressInterval from "@editor/hooks/useExponentialProgressInterval";
import { useReploHotkeys } from "@editor/hooks/useHotkeys";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { analytics } from "@editor/infra/analytics";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import {
  selectDraftComponentId,
  selectDraftComponentOrDescendantMeetsCondition,
  selectLoadableProject,
  selectRootComponentId,
  selectStoreShopifyUrl,
  selectStreamingUpdateId,
} from "@editor/reducers/core-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import { trpc, trpcClient } from "@editor/utils/trpc";

import { zodResolver } from "@hookform/resolvers/zod";
import * as Progress from "@radix-ui/react-progress";
import Button from "@replo/design-system/components/button";
import { Spinner } from "@replo/design-system/components/spinner";
import Tooltip from "@replo/design-system/components/tooltip";
import { skipToken } from "@tanstack/react-query";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import {
  BsArrowReturnLeft,
  BsArrowsCollapse,
  BsCheckCircleFill,
  BsFileEarmarkExcel,
  BsGlobe,
  BsHandThumbsDownFill,
  BsHandThumbsUpFill,
  BsInfoCircle,
  BsMagic,
  BsPalette,
  BsPencilSquare,
  BsPhone,
  BsX,
} from "react-icons/bs";
import {
  exhaustiveSwitch,
  exhaustiveSwitchGeneric,
} from "replo-utils/lib/misc";
import { urlFormSchema } from "schemas/url";

const AI_POPOVER_ALIGN_OFFSET = -20;

const AIMenuWrapper: React.FC<{
  controlsWidth: number;
  isDisabled: boolean;
}> = ({ isDisabled, controlsWidth }) => {
  const {
    isMenuOpen,
    isTextSelected,
    setIsMenuOpen: setMenuOpen,
    status,
  } = useAIStreaming();

  const isDisplayingGenerationBar = [
    "generationInitialized",
    "generating",
    "finishedGenerating",
  ].includes(status);

  return (
    <Popover
      isOpen={isMenuOpen}
      onOpenChange={(val) => setMenuOpen(val, "previewMenu")}
    >
      <Popover.Trigger asChild>
        <Button
          id="ai-button"
          variant="noStyle"
          icon={<BsMagic size={20} className="text-ai relative -top-0.5" />}
          className={classNames(
            "text-slate-400 p-2 rounded-md h-auto",
            !isDisabled && "hover:bg-slate-100",
          )}
          tooltipText={
            isMenuOpen ? null : <HotkeyIndicator hotkey="toggleAIMenu" />
          }
          isDisabled={isDisabled}
        />
      </Popover.Trigger>

      <Popover.Content
        // Note (Evan, 2024-07-19): i.e., if generation has already started, don't
        // close the popover on outside interaction
        shouldPreventDefaultOnInteractOutside={
          status !== "preGeneration" || isTextSelected
        }
        side="top"
        style={{
          width:
            isDisplayingGenerationBar || isTextSelected
              ? controlsWidth
              : undefined,
        }}
        sideOffset={16}
        className={classNames(
          "w-unset h-unset py-2 font-normal rounded overflow-hidden",
          isDisplayingGenerationBar && "p-0",
          status === "autoApplyingChanges" && "hidden",
        )}
        hideCloseButton
        stayInPosition
        disableTriggerFocusOnClose
        align="end"
        alignOffset={AI_POPOVER_ALIGN_OFFSET}
      >
        <AIMenu />
      </Popover.Content>
    </Popover>
  );
};

type AIMenuOption = {
  key: AIStreamingOperation;
  title: string;
  subtitle?: string;
  icon: React.ReactNode;
  shortcut: string;
};

const menuOptions: AIMenuOption[] = [
  {
    key: "textV2",
    title: "Update Text",
    subtitle: "Rewrite, translate, shorten",
    icon: <BsPencilSquare size={12} />,
    shortcut: "t",
  },
  {
    key: "mobileResponsive",
    title: "Optimize for Mobile",
    subtitle: "Update responsiveness",
    icon: <BsPhone size={12} />,
    shortcut: "r",
  },
  ...(isFeatureEnabled("ai-menu-styles")
    ? [
        {
          key: "savedStyles" as const,
          title: "Apply Saved Styles",
          subtitle: "Apply saved styles to component",
          icon: <BsPalette size={12} />,
          shortcut: "s",
        },
      ]
    : []),
];

const AIMenu: React.FC = () => {
  const { menuState, isTextSelected, status } = useAIStreaming();

  if (status === "generating" || status === "generationInitialized") {
    return <AIMenuGenerating />;
  }

  if (status === "finishedGenerating") {
    return <AIMenuFinishedGenerating />;
  }

  if (isTextSelected) {
    return <AITextInterface />;
  }
  if (menuState === "template") {
    return <AITemplateMenu />;
  }
  return <AIMenuSelect />;
};

const AIMenuSelect: React.FC<{}> = () => {
  const draftComponentId = useEditorSelector(selectDraftComponentId);

  const { setMenuState, initiateGeneration } = useAIStreaming();
  const handleSelect = (key: AIStreamingOperation) => {
    exhaustiveSwitch({ type: key })({
      textV2: () => {
        setMenuState("text");
      },
      mobileResponsive: () => {
        if (!draftComponentId) {
          infoToast(
            "No component selected",
            "To optimize for mobile, please select a component.",
          );
          return;
        }
        void initiateGeneration({ type: "mobileResponsive" });
      },
      savedStyles: () => {
        if (isFeatureEnabled("ai-menu-styles")) {
          void initiateGeneration({ type: "savedStyles" });
        }
      },
      multi: () => {
        // TODO (Evan, 2024-11-05): Perhaps we want to allow for multi-generation here
      },
    });
  };

  // TODO (Gabe 2024-08-12): Update once we have a better way to define one of
  // hotkeys.
  useReploHotkeys({
    selectAIText: () => handleSelect("textV2"),
    selectAIMobileResponsive: () => handleSelect("mobileResponsive"),
  });
  return (
    <div className="w-[240px] overflow-hidden flex flex-col text-xs">
      <div>Replo AI</div>
      <div className="flex flex-col pt-1">
        {menuOptions.map((menuOption, index) => (
          <AIMenuEntry
            {...menuOption}
            key={menuOption.key}
            autoFocus={index === 0}
            onSelect={() => handleSelect(menuOption.key)}
          />
        ))}
      </div>
    </div>
  );
};

const AIMenuEntry: React.FC<
  AIMenuOption & {
    autoFocus?: boolean;
    onSelect: () => void;
  }
> = ({ title, subtitle, icon, autoFocus, onSelect, shortcut }) => {
  return (
    <Button
      onClick={onSelect}
      // Note (Evan, 2024-07-19): Without this, there will be a flash when clicking where
      // first the focus shifts on mouse down (causing the styles to change), but the
      // click handler only runs on mouse up.
      onMouseDown={(event) => {
        event.preventDefault();
      }}
      textClassNames="w-full font-normal"
      variant="noStyle"
      className="group focus:bg-slate-100 hover:bg-slate-50 focus:outline-none w-full py-2 rounded h-auto"
      onKeyDown={(event) => {
        const { key } = event;
        if (key === "ArrowUp") {
          event.preventDefault();
          event.stopPropagation();
          const previous = event.currentTarget
            .previousElementSibling as HTMLElement | null;
          previous?.focus();
        }
        if (key === "ArrowDown") {
          event.preventDefault();
          event.stopPropagation();
          const next = event.currentTarget
            .nextElementSibling as HTMLElement | null;
          next?.focus();
        }
      }}
      autoFocus={autoFocus}
    >
      <div className="flex flex-row px-2 text-slate-600 justify-between w-full">
        <div className="flex flex-row gap-2 items-center">
          {icon}
          <div className="flex flex-col items-start gap-1">
            <span className="text-black">{title}</span>
            <span className="text-[10px]">{subtitle}</span>
          </div>
        </div>
        <div className="flex items-center">
          <div className="bg-slate-200  flex gap-1 rounded items-center py-1 px-1.5 text-[10px]">
            <span>{shortcut.toUpperCase()}</span>
          </div>
        </div>
      </div>
    </Button>
  );
};

const useBrandDetails = () => {
  const { project } = useEditorSelector(selectLoadableProject);

  const { data, isLoading } = trpc.ai.getBrandDetails.useQuery(
    project ? { projectId: project.id } : skipToken,
  );

  const utils = trpc.useUtils();

  const brandDetails = data?.brandDetails;

  const { mutate } = trpc.ai.createOrUpdateBrandDetails.useMutation({
    // Note (Evan, 2024-09-27): This is the react-query pattern for optimistic updates
    // see: https://tanstack.com/query/v4/docs/framework/react/guides/optimistic-updates
    onMutate: async ({ projectId, brandDetails: newBrandDetails }) => {
      // Note (Evan, 2024-09-24): Optimistically update, first cancel any outgoing refetches
      await utils.ai.getBrandDetails.cancel({
        projectId,
      });

      // Capture the previous value to return as context
      const previousBrandDetails = utils.ai.getBrandDetails.getData();

      // Optimistically update the cache
      utils.ai.getBrandDetails.setData({ projectId }, () => ({
        brandDetails: newBrandDetails,
      }));

      return { previousBrandDetails };
    },
    // Note (Evan, 2024-09-24): On error, roll back the cache to the previous value
    onError: (_err, newBrandDetails, context) => {
      utils.ai.getBrandDetails.setData(
        { projectId: newBrandDetails.projectId },
        context?.previousBrandDetails,
      );
    },
    onSettled: () => {
      void utils.ai.getBrandDetails.invalidate();
    },
  });

  const setBrandDetails = (brandDetails: BrandDetails) => {
    if (!project) {
      return;
    }
    mutate({ projectId: project.id, brandDetails });
  };

  return { brandDetails, setBrandDetails, isLoading };
};

const AITemplateMenu: React.FC = () => {
  const { brandDetails } = useBrandDetails();

  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const rootComponentId = useEditorSelector(selectRootComponentId);
  const setDraftElement = useSetDraftElement();

  const {
    initiateGeneration,
    setMenuState,
    setIsMenuOpen: setMenuOpen,
  } = useAIStreaming();

  const handleConfirm = () => {
    if (!draftComponentId) {
      setDraftElement({ componentIds: [rootComponentId] });
    }
    if (brandDetails?.whatBusinessSells || brandDetails?.whoIsCustomer) {
      void initiateGeneration({
        type: "textV2",
        userPrompt: "Rewrite text",
      });
    } else {
      setMenuState("text.brandDetails");
    }
  };
  const handleDeny = () => {
    setMenuOpen(false);
  };
  useReploHotkeys({
    enter: handleConfirm,
    escape: handleDeny,
  });
  return (
    <div className="text-sm text-default flex flex-col gap-2 w-[196px]">
      <div className="font-semibold ">Update Template Text?</div>
      <div>Update your template with content tailored to your store.</div>
      <div className="flex flex-row gap-2 justify-end">
        <Button
          variant="secondary"
          onClick={handleDeny}
          size="base"
          textClassNames="gap-2"
        >
          No <span className="text-slate-600 font-normal">ESC</span>
        </Button>
        <Button
          variant="primary"
          size="base"
          onClick={handleConfirm}
          textClassNames="items-center justify-center gap-2"
        >
          Yes <BsArrowReturnLeft />
        </Button>
      </div>
    </div>
  );
};

type BrandDetailsMenuState =
  | {
      view: "detailsList";
      // Note (Evan, 2024-09-26): i.e., whether each field was generated by AI
      isAIGenerated: Partial<Record<keyof BrandDetails, boolean | undefined>>;
      error?: boolean;
    }
  | {
      view: "pullFromUrl";
      isLoading: boolean;
    };

const BrandDetailsMenu: React.FC = () => {
  const {
    brandDetails,
    setBrandDetails,
    isLoading: isLoadingBrandDetails,
  } = useBrandDetails();
  const [brandDetailsMenuState, setBrandDetailsMenuState] =
    React.useState<BrandDetailsMenuState>({
      view: "detailsList",
      isAIGenerated: {},
    });

  const storeShopifyUrl = useEditorSelector(selectStoreShopifyUrl);
  const { project } = useEditorSelector(selectLoadableProject);

  // Note (Evan, 2024-09-26): Hook form for the URL input (to get validation)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      url: storeShopifyUrl ?? "",
    },
    resolver: zodResolver(urlFormSchema),
  });

  const urlError = errors.url?.message;

  const onSubmitPullFromUrl = async ({ url }: UrlFormValues) => {
    setBrandDetailsMenuState({ view: "pullFromUrl", isLoading: true });

    try {
      const result = await trpcClient.ai.generateBrandDetailsAndIndustry.query({
        url,
        projectId: project?.id,
      });
      setBrandDetails(result.brandDetails);
      setBrandDetailsMenuState({
        view: "detailsList",
        isAIGenerated: Object.keys(result.brandDetails).reduce(
          (acc, key) => {
            if (result.brandDetails[key as keyof BrandDetails] !== undefined) {
              acc[key as keyof BrandDetails] = true;
            }
            return acc;
          },
          {} as Partial<Record<keyof BrandDetails, boolean>>,
        ),
      });
    } catch {
      setBrandDetailsMenuState({
        view: "detailsList",
        isAIGenerated: {},
        error: true,
      });
    }
  };

  const setFieldAIGenerated = (key: keyof BrandDetails, value: boolean) => {
    setBrandDetailsMenuState((state) => {
      // Note (Evan, 2024-09-27): This is purely for TS's sake
      if (state.view !== "detailsList") {
        return state;
      }

      return {
        ...state,
        isAIGenerated: {
          ...state.isAIGenerated,
          [key]: value,
        },
      };
    });
  };

  return (
    <div className="text-default text-sm flex flex-col gap-3 font-normal mt-0">
      <div className="flex flex-col gap-2 pt-1">
        <div className="w-full flex flex-row gap-2 items-center justify-between">
          <div className="flex flex-row gap-2 items-center pb-1">
            <div className="text-base font-semibold">Brand Details</div>
            <Tooltip
              content="These details will help generate personalized content."
              className="w-[200px]"
              triggerAsChild
            >
              <div>
                <BsInfoCircle size={14} />
              </div>
            </Tooltip>
          </div>
          {brandDetailsMenuState.view === "detailsList" && (
            <Button
              variant="noStyle"
              onClick={() =>
                setBrandDetailsMenuState({
                  view: "pullFromUrl",
                  isLoading: false,
                })
              }
            >
              <span className="text-blue-600">Pull from URL</span>
            </Button>
          )}
        </div>
        {exhaustiveSwitchGeneric(
          brandDetailsMenuState,
          "view",
        )({
          detailsList: ({ isAIGenerated, error }) => {
            if (isLoadingBrandDetails) {
              return (
                <div className="h-[244px] flex items-center justify-center">
                  <Spinner size={40} variant="primary" />
                </div>
              );
            }

            const numFieldsAIGenerated =
              Object.values(isAIGenerated).filter(Boolean).length;
            return (
              <>
                {numFieldsAIGenerated > 0 && (
                  <div className="text-ai flex flex-row gap-2 items-center">
                    <BsMagic size={16} />
                    {numFieldsAIGenerated === 4
                      ? "Results generated from URL"
                      : "Some results generated from URL"}
                  </div>
                )}
                {error && (
                  <div className="w-full h-12 p-4 bg-blue-50 flex flex-row items-center justify-between rounded-lg">
                    <div className="flex flex-row gap-2 items-center">
                      <BsFileEarmarkExcel size={18} className="text-blue-600" />
                      <span className="text-blue-600">
                        Couldn&apos;t retrieve details from URL
                      </span>
                    </div>
                    <Button
                      variant="noStyle"
                      size="sm"
                      onClick={() => {
                        // Note (Evan, 2024-09-27): Clear the error
                        setBrandDetailsMenuState({
                          view: "detailsList",
                          isAIGenerated: {},
                        });
                      }}
                    >
                      <BsX size={18} />
                    </Button>
                  </div>
                )}
                <div className="flex flex-col gap-2">
                  <div className="text-sm font-semibold">Brand Name</div>
                  <DebouncedInput
                    size="base"
                    value={brandDetails?.brandName ?? ""}
                    unsafe_className={classNames("w-full h-9", {
                      "bg-orange-50": isAIGenerated.brandName,
                    })}
                    onValueChange={(value) => {
                      setFieldAIGenerated("brandName", false);
                      setBrandDetails({
                        ...brandDetails,
                        brandName: value,
                      });
                    }}
                    placeholder="Rockstar Bags"
                    maxLength={512}
                  />
                </div>
                <div className="text-sm font-semibold">Tone and voice</div>
                <Textarea
                  size="base"
                  textLength="short"
                  value={brandDetails?.brandVoice}
                  className={classNames(
                    "w-full h-[72px] placeholder:text-slate-400 pl-2",
                    {
                      "bg-orange-50": isAIGenerated.brandVoice,
                    },
                  )}
                  debounce={true}
                  onChange={(value) => {
                    setFieldAIGenerated("brandVoice", false);
                    setBrandDetails({
                      ...brandDetails,
                      brandVoice: value,
                    });
                  }}
                  placeholder="Fun, quirky, and playful - we use humor to connect with our audience and keep things light-hearted..."
                  maxLength={512}
                />
                <div className="flex flex-col gap-2">
                  <div className="text-sm font-semibold">
                    What are you selling?
                  </div>
                  <DebouncedInput
                    size="base"
                    value={brandDetails?.whatBusinessSells ?? ""}
                    unsafe_className={classNames("w-full h-9", {
                      "bg-orange-50": isAIGenerated.whatBusinessSells,
                    })}
                    onValueChange={(value) => {
                      setFieldAIGenerated("whatBusinessSells", false);
                      setBrandDetails({
                        ...brandDetails,
                        whatBusinessSells: value,
                      });
                    }}
                    placeholder="We sell handmade purses made from recycled concert tshirts"
                    maxLength={512}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-sm font-semibold">
                    Who do you sell to?
                  </div>
                  <DebouncedInput
                    size="base"
                    value={brandDetails?.whoIsCustomer ?? ""}
                    unsafe_className={classNames("w-full h-9", {
                      "bg-orange-50": isAIGenerated.whoIsCustomer,
                    })}
                    onValueChange={(value) => {
                      setFieldAIGenerated("whoIsCustomer", false);
                      setBrandDetails({
                        ...brandDetails,
                        whoIsCustomer: value,
                      });
                    }}
                    placeholder="Our customers are usually Gen Z teenagers who love TikTok"
                    maxLength={512}
                  />
                </div>
              </>
            );
          },
          pullFromUrl: ({ isLoading }) => (
            <form
              className="flex flex-col gap-3"
              onSubmit={(data) => {
                void handleSubmit(onSubmitPullFromUrl)(data);
              }}
            >
              <div className="flex flex-col gap-2">
                <Input
                  aria-invalid={Boolean(urlError) ? "true" : undefined}
                  aria-describedby={
                    Boolean(urlError) ? "error-first-name" : undefined
                  }
                  autoComplete="off"
                  placeholder="https://www.mywebsite.com"
                  {...register("url")}
                  type="text"
                  size="base"
                  validityState={Boolean(urlError) ? "invalid" : "valid"}
                  autoFocus
                />
                {urlError && (
                  <ErrorMessage id="error-first-name" error={urlError} />
                )}
              </div>
              <div className="flex flex-row items-center justify-between">
                <div>
                  {!isLoading && (
                    <Button
                      variant="noStyle"
                      className="text-blue-600 p-1"
                      onClick={() =>
                        setBrandDetailsMenuState({
                          view: "detailsList",
                          isAIGenerated: {},
                        })
                      }
                    >
                      Enter Manually
                    </Button>
                  )}
                </div>

                <Button
                  type="submit"
                  variant="primary"
                  isLoading={isLoading}
                  isDisabled={isLoading}
                >
                  Pull from URL
                </Button>
              </div>
            </form>
          ),
        })}
      </div>
    </div>
  );
};

const AITextInterface: React.FC = () => {
  const {
    initiateGeneration,
    setIsMenuOpen: setMenuOpen,
    menuState,
    setMenuState,
  } = useAIStreaming();

  const initialTabIsDetails = menuState.endsWith("brandDetails");

  // Note (Evan, 2024-09-26): Default to "rewrite" as the prompt
  const [prompt, setPrompt] = React.useState(
    initialTabIsDetails ? "Rewrite " : "",
  );

  const setPromptAndFocus = (value: string) => {
    setPrompt(value);
    // TODO (Gabe 2024-07-29): For some reason the ref passed into Textarea is
    // not attaching.
  };

  const draftComponentId = useEditorSelector(selectDraftComponentId);

  const store = useEditorStore();

  const handleSubmit = () => {
    // NOTE (Gabe 2024-08-06): We only use this selector in the callback because
    // it's expensive. We don't want to run it on every render.
    const draftComponentOrDescendantIsText =
      selectDraftComponentOrDescendantMeetsCondition(store.getState(), (c) =>
        Boolean(
          c.type === "text" &&
            c.props.text &&
            c.props.text.includes("{{") === false,
        ),
      );

    if (!draftComponentId || !draftComponentOrDescendantIsText) {
      // Note (Evan, 2024-06-18): Show a toast if the modal can't be opened specifically
      // because no valid component is selected (i.e., don't show a modal if
      // a global modal is open)
      infoToast(
        "No text selected",
        "To generate AI copy, please select a component that contains (non-dynamic) text.",
      );
      return;
    }

    void initiateGeneration({
      type: "textV2",
      userPrompt: prompt,
    });
  };

  const handleTextChange = (value: string) => {
    if (prompt !== "") {
      setPrompt(value);
    } else if (value === "0") {
      setPrompt("Translate to ");
    } else if (value === "1") {
      setPrompt("Rewrite ");
    } else if (value === "2") {
      setPrompt("Shorten ");
    } else {
      setPrompt(value);
    }
  };

  return (
    <div className="w-full">
      <Tabs
        value={menuState.endsWith("brandDetails") ? "details" : "prompt"}
        onValueChange={(value) => {
          if (value === "prompt") {
            setMenuState("text");
          } else if (value === "details") {
            setMenuState("text.brandDetails");
          }
        }}
        className="flex flex-col gap-3"
      >
        <div>
          <TabsContent value="details">
            <BrandDetailsMenu />
          </TabsContent>
          <TabsContent
            value="prompt"
            className="flex flex-col pt-1 mt-0 relative"
          >
            <Textarea
              className="w-full"
              value={prompt}
              size="base"
              textLength="long"
              onChange={handleTextChange}
              onEnter={handleSubmit}
              placeholder="What copy do you want to write?"
              autoFocus
            />

            <div
              className={classNames(
                "absolute bottom-2 flex flex-row bg-slate-100 px-[10px] py-2 text-sm gap-2 rounded-b-md",
                "transition-opacity",
                {
                  "opacity-0 pointer-events-none": Boolean(prompt),
                  "opacity-100": !prompt,
                },
              )}
            >
              <TextSuggestionButton
                shortcut="0"
                onClick={() => setPromptAndFocus("Translate to ")}
              >
                <BsGlobe />
                Translate to
              </TextSuggestionButton>
              <TextSuggestionButton
                shortcut="1"
                onClick={() => setPromptAndFocus("Rewrite")}
              >
                <BsPencilSquare />
                Rewrite
              </TextSuggestionButton>
              <TextSuggestionButton
                shortcut="2"
                onClick={() => setPromptAndFocus("Shorten")}
              >
                <BsArrowsCollapse />
                Shorten
              </TextSuggestionButton>
            </div>
          </TabsContent>
        </div>
        <div className="flex flex-row items-center gap-2">
          <TabsList className="grow flex flex-row justify-start">
            <TabsTrigger value="details">Details</TabsTrigger>
            <TabsTrigger value="prompt">Prompt</TabsTrigger>
          </TabsList>
          <Button
            variant="secondary"
            size="base"
            onClick={() => setMenuOpen(false)}
          >
            Cancel
            <span className="text-slate-600 font-normal pl-1">ESC</span>
          </Button>
          <Button
            textClassNames="gap-2 items-center justify-center"
            variant="primary"
            size="base"
            onClick={handleSubmit}
            isDisabled={!prompt}
          >
            Enter
            <BsArrowReturnLeft />
          </Button>
        </div>
      </Tabs>
    </div>
  );
};

// TODO (Gabe 2024-08-12): Update this to use the design system Button
const TextSuggestionButton: React.FC<
  React.PropsWithChildren<{
    onClick: () => void;
    shortcut: string;
  }>
> = ({ children, onClick, shortcut }) => {
  return (
    <Button variant="noStyle" onClick={onClick}>
      <div className="flex flex-row gap-2 items-center border-dashed border-slate-200 border-2 px-2 py-1 rounded">
        {children}
        <div className="bg-white px-1 border-slate-200 border-1 rounded-sm">
          {shortcut}
        </div>
      </div>
    </Button>
  );
};

const getLoadingText = (
  generationSource: MenuTriggerSource | null,
  status: AIStatus,
  generationMode: AIStreamingOperation | null,
): { title: string; subtitle?: string } => {
  if (generationSource === "onboarding") {
    if (status === "generationInitialized" || !generationMode) {
      return {
        title: "Building your first page",
        subtitle: "Thinking ...",
      };
    }
    return exhaustiveSwitch({ type: generationMode })({
      mobileResponsive: {
        title: "Building your first page",
        subtitle: "Optimizing for mobile ...",
      },
      textV2: {
        title: "Building your first page",
        subtitle: "Generating text ...",
      },
      savedStyles: {
        title: "Building your first page",
        subtitle: "Applying saved styles ...",
      },
      multi: {
        title: "Building your first page",
        subtitle: "Generating ...",
      },
    });
  }

  if (status === "generationInitialized" || !generationMode) {
    return {
      title: "Thinking ...",
    };
  }

  return exhaustiveSwitch({ type: generationMode })({
    mobileResponsive: {
      title: "Optimizing for mobile ...",
    },
    textV2: {
      title: "Generating text ...",
    },
    savedStyles: {
      title: "Applying saved styles ...",
    },
    multi: {
      title: "Generating ...",
    },
  });
};

const AIMenuGenerating: React.FC = () => {
  const {
    abort,
    completionPercentage,
    generationSource,
    status,
    generationMode,
  } = useAIStreaming();

  useReploHotkeys({
    escape: abort,
  });

  const { title, subtitle } = getLoadingText(
    generationSource,
    status,
    generationMode,
  );

  // Note (Evan, 2024-10-03): Using a classic hook here to account for the fact
  // that the completionPercentage will be 0 until we start to receive actions.
  // We generate this fake progress value, estimating it should be 20% at around
  // the 10 second mark, and use the greater of the two values for the progress bar shown.
  const fakeProgress =
    useExponentialProgressInterval(250, {
      valueAtExpectedTimeToComplete: 0.3,
      expectedTimeToComplete: 10_000,
    }) * 100;

  return (
    <>
      <div
        className={classNames("overflow-hidden flex flex-col px-4 w-full", {})}
      >
        <div className="grow flex flex-row justify-between items-stretch mt-1">
          <div className="flex flex-row items-center gap-3 py-4">
            <div className="flex flex-col justify-start h-full">
              <BsMagic
                size={20}
                className="text-ai mt-0.5 animate-pulseShallow"
              />
            </div>

            <div className="flex flex-col gap-1 h-full justify-center">
              <div
                className={classNames("text-sm text-default", {
                  "font-semibold": Boolean(subtitle),
                  "font-normal": !Boolean(subtitle),
                })}
              >
                {title}
              </div>
              {subtitle && (
                <div className="text-sm text-slate-600 font-normal">
                  {subtitle}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row items-center">
            <Button variant="secondary" size="sm" onClick={abort}>
              Cancel
              <span className="text-slate-600 font-normal pl-1">ESC</span>
            </Button>
          </div>
        </div>
      </div>
      <AIProgressIndicator
        completionPercentage={Math.max(completionPercentage ?? 0, fakeProgress)}
      />
    </>
  );
};

const AIProgressIndicator: React.FC<{
  completionPercentage: number;
}> = ({ completionPercentage }) => {
  // Note (Evan, 2024-10-03): Never show "true 0"
  const percentageToShow = Math.max(completionPercentage, 3);
  return (
    <Progress.Root value={percentageToShow} max={100} className="h-1 -mx-3">
      <Progress.Indicator
        className="bg-ai h-1 transition-all duration-500 ease-linear"
        style={{
          width: `${percentageToShow}%`,
        }}
      />
    </Progress.Root>
  );
};

const AIMenuFinishedGenerating = () => {
  const { applyChanges, discardChanges, generationMode } = useAIStreaming();
  const [isApplyingChanges, setIsApplyingChanges] = React.useState(false);
  const handleApplyChanges = () => {
    setIsApplyingChanges(true);
    // HACK (Gabe 2024-08-13): Applying this many action at once has a tendency
    // to lock up rendering so we must do it in a setTimeout so that the isBusy
    // Button can be rendered.
    setTimeout(() => {
      applyChanges();
    }, 0);
  };
  useReploHotkeys({
    enter: handleApplyChanges,
    escape: discardChanges,
  });

  const streamingUpdateId = useEditorSelector(selectStreamingUpdateId);

  const [feedbackPopoverOpen, setFeedbackPopoverOpen] = React.useState(false);
  const [feedback, setFeedback] = React.useState("");
  const [feedbackSubmitted, setFeedbackSubmitted] = React.useState(false);

  const submitFeedback = (isPositive: boolean) => {
    setFeedbackPopoverOpen(false);
    if (streamingUpdateId && generationMode) {
      analytics.logEvent("ai.feedback.submitted", {
        feedback,
        isPositive,
        streamingUpdateId,
        generationType: generationMode,
      });
    }
    setFeedbackSubmitted(true);
    toast({
      header: "Feedback submitted",
      message: "Thank you!",
    });
  };

  return (
    <div className="text-sm flex flex-col px-3 w-full">
      {!feedbackSubmitted && (
        <div className="border-b-1 border-slate-100 px-6 py-2 -mx-4 text-slate-600 flex flex-row gap-2 items-center">
          <div className="flex-grow ">
            This feature is in beta. Is this what you expected?
          </div>
          <button onClick={() => submitFeedback(true)}>
            <BsHandThumbsUpFill
              size={14}
              className={classNames(
                "stroke-slate-600 text-white hover:text-slate-400 active:text-slate-600 stroke-1 overflow-visible",
              )}
            />
          </button>
          <Popover
            isOpen={feedbackPopoverOpen}
            onOpenChange={(val) => setFeedbackPopoverOpen(val)}
          >
            <Popover.Trigger asChild>
              <button>
                <BsHandThumbsDownFill
                  size={14}
                  className={classNames(
                    "stroke-slate-600 stroke-1 overflow-visible",
                    feedbackPopoverOpen
                      ? "text-slate-600"
                      : "text-white hover:text-slate-400 active:text-slate-600",
                  )}
                />
              </button>
            </Popover.Trigger>
            <Popover.Content
              side="top"
              sideOffset={16}
              align="end"
              alignOffset={AI_POPOVER_ALIGN_OFFSET}
              className="p-2 rounded font-normal text-sm w-[306px] flex flex-col items-end gap-3"
              hideCloseButton
              shouldPreventDefaultOnInteractOutside={false}
            >
              <Textarea
                size="base"
                value={feedback}
                textLength="short"
                placeholder="How could this output be improved?"
                onChange={(value) => setFeedback(value)}
                onEnter={() => submitFeedback(false)}
                className="w-full h-14"
                autoFocus
              />
              <Button
                variant="primary"
                size="sm"
                onClick={() => submitFeedback(false)}
              >
                Submit
                <BsArrowReturnLeft />
              </Button>
            </Popover.Content>
          </Popover>
        </div>
      )}
      <div className="flex flex-row justify-between items-center px-3 w-full py-2">
        <div className="flex flex-row items-center gap-3">
          <BsCheckCircleFill size={16} className="text-ai" />
          <span className="text-slate-600 font-medium">
            Generation Completed!
          </span>
        </div>
        <div className="flex flex-row gap-1.5">
          <Button variant="secondary" size="sm" onClick={discardChanges}>
            Cancel
            <span className="text-slate-600 font-normal pl-1">ESC</span>
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={handleApplyChanges}
            isLoading={isApplyingChanges}
          >
            Accept
            <BsArrowReturnLeft />
          </Button>
        </div>
      </div>
      <AIProgressIndicator completionPercentage={100} />
    </div>
  );
};

export default AIMenuWrapper;
