import type { Flow } from "schemas/generated/flow";

import * as React from "react";

import { useGetCurrentFlow } from "@components/flows/hooks/useGetCurrentFlow";

import * as Progress from "@radix-ui/react-progress";
import { useParams, useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type FlowProgressProps = {
  className?: string;
  entityType?: Flow["type"];
};

export const FlowProgress: React.FC<FlowProgressProps> = ({
  entityType,
  className,
}) => {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const { currentFlow, nextStep } = useGetCurrentFlow({
    entityType,
    isDebug: searchParams.get("debug") === "true",
  });
  const nextStepId =
    params.flowStepId ?? nextStep?.id ?? currentFlow?.steps?.[0]?.id;
  const currentStepIndex =
    (currentFlow?.steps.findIndex((step) => step.id === nextStepId) ?? 0) + 1;
  const steps = currentFlow?.steps.length ?? 0;

  const currentProgress = 100 - (currentStepIndex / steps) * 100;

  return (
    <Progress.Root
      max={steps}
      value={currentStepIndex}
      className={twMerge(
        "h-3 bg-slate-200 w-full overflow-hidden rounded-full",
        className,
      )}
    >
      <Progress.Indicator
        style={{
          transform: `translateX(-${currentProgress}%)`,
        }}
        className="h-full bg-blue-600"
      />
    </Progress.Root>
  );
};
