import type { ContextRef } from "schemas/product";

import * as React from "react";

import DynamicDataButton from "@editor/components/common/designSystem/DynamicDataButton";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import { useModal } from "@editor/hooks/useModal";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { DynamicDataValueIndicator } from "@editorExtras/DynamicDataValueIndicator";

import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { isContextRef } from "replo-runtime/store/ReploProduct";

type AnyDynamicDataValueSelectorProps = {
  dynamicDataTemplate: string | ContextRef | null;
  onChange(newTemplate: string | ContextRef): void;
  onDelete(): void;
};

/**
 * A selector which displays an arbitrary dynamic data value.
 *
 * Useful for things like custom component props of type ANY_DYNAMIC_DATA, where a
 * prop can be of any type but must be specified as a dynamic reference to something.
 * This can be used for things like carousels auto-scrolling to a particular item.
 */
const AnyDynamicDataValueSelector = ({
  dynamicDataTemplate,
  onChange,
  onDelete,
}: AnyDynamicDataValueSelectorProps) => {
  const { openModal } = useModal();

  const openDynamicDataModal = () => {
    openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        // TODO (Noah, 2022-11-13): This really shouldn't be TEXT, because that means
        // that we couldn't have a carousel of variants which would scroll to the selected
        // variant, for example. Really we should have something like ITEM_TYPE, which
        // would automatically infer the type from the type of the dynamic items (_items)
        // prop. For now, TEXT works for the most common use case, which is scrolling a
        // product images carousel to the selected variant featured image automatically
        targetType: DynamicDataTargetType.TEXT,
        referrerData: {
          type: "callback",
          onChange: (value) => {
            onChange(value);
          },
        },
        initialPath:
          typeof dynamicDataTemplate === "string"
            ? getPathFromVariable(dynamicDataTemplate)
            : undefined,
      },
    });
  };

  if (dynamicDataTemplate) {
    return (
      <DynamicDataValueIndicator
        type="other"
        templateValue={
          isContextRef(dynamicDataTemplate)
            ? dynamicDataTemplate.ref
            : dynamicDataTemplate
        }
        onClick={() => {
          openDynamicDataModal();
        }}
        onRemove={onDelete}
      />
    );
  }
  return (
    <div className="flex flex-row gap-2">
      <SelectionIndicator
        onClick={() => {
          openDynamicDataModal();
        }}
        title="Select a dynamic data value"
      />
      <DynamicDataButton
        onClick={() => {
          openDynamicDataModal();
        }}
      />
    </div>
  );
};

export default AnyDynamicDataValueSelector;
