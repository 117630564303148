import * as React from "react";

import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";

import { Combobox } from "@replo/design-system/components/shadcn/combobox/Combobox";
import { BsCaretDownFill } from "react-icons/bs";
import { convertDaysToMs } from "replo-utils/lib/datetime";

export type IntervalLabel = "hour" | "day" | "month";

type IntervalOption = {
  value: string;
  label: IntervalLabel;
};

type AnalyticsQueryIntervalComboboxProps = {
  currentInterval: number;
  rangeInDays: number;
  onChange: (interval: number) => void;
};

const getIntervalOptions = (rangeInDays: number): IntervalOption[] => {
  if (rangeInDays <= 1) {
    return [{ value: convertDaysToMs(1 / 24).toString(), label: "hour" }];
  }
  if (rangeInDays <= 7) {
    return [
      { value: convertDaysToMs(1 / 24).toString(), label: "hour" },
      { value: convertDaysToMs(1).toString(), label: "day" },
    ];
  }
  if (rangeInDays <= 31) {
    return [
      { value: convertDaysToMs(1 / 24).toString(), label: "hour" },
      { value: convertDaysToMs(1).toString(), label: "day" },
    ];
  }
  if (rangeInDays <= 90) {
    return [{ value: convertDaysToMs(1).toString(), label: "day" }];
  }
  if (rangeInDays <= 365) {
    return [{ value: convertDaysToMs(1).toString(), label: "day" }];
  }
  return [{ value: convertDaysToMs(30).toString(), label: "month" }];
};

const AnalyticsQueryIntervalCombobox: React.FC<
  AnalyticsQueryIntervalComboboxProps
> = ({ currentInterval, rangeInDays, onChange }) => {
  const analytics = useLogAnalytics();
  const intervalOptions = React.useMemo(
    () => getIntervalOptions(rangeInDays),
    [rangeInDays],
  );
  const currentIntervalOption = intervalOptions.find(
    (option) => Number(option.value) === currentInterval,
  ) ||
    intervalOptions[0] || {
      value: convertDaysToMs(1 / 24).toString(),
      label: "hour",
    };

  return (
    <Combobox
      options={intervalOptions}
      placeholderPrefix="Interval: "
      value={currentIntervalOption?.value}
      onChange={(value) => {
        onChange(Number(value));
        analytics("analytics.chart.update", {
          time: currentIntervalOption.label,
        });
      }}
      endEnhancer={() => <BsCaretDownFill className="h-2 w-2 text-subtle" />}
      placeholder={currentIntervalOption?.label}
    />
  );
};

export default AnalyticsQueryIntervalCombobox;
