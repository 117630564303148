import type { ReploElementType } from "schemas/generated/element";
import type { FeatureType } from "../types";

type SupportedFeatures = Record<ReploElementType, FeatureType[]>;

export const supportedFeatures: SupportedFeatures = {
  shopifyArticle: [],
  page: ["shopifyLiquid"],
  shopifyProductTemplate: ["shopifyLiquid"],
  shopifySection: ["shopifyLiquid", "shopifyAppBlocks"],
};

export const isFeatureSupported = (
  elementType: ReploElementType,
  feature: FeatureType,
): boolean => {
  return supportedFeatures[elementType]?.includes(feature);
};
