import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import {
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { useProductFromProps } from "../../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedLiquidReviewIntegrations } from "../SharedLiquidReviewIntegrations";

const SHOPIFY_PRODUCT_RATING_WIDGET_LIQUID_SOURCE =
  '<span class="shopify-product-reviews-badge" data-id="{{ product.id }}"></span>';

function ShopifyProductRatingWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const product = useProductFromProps(component.props, context);
  const liquidSourceWithProductAssignment = wrapProductAssignmentToLiquidSource(
    {
      product,
      liquidSource: SHOPIFY_PRODUCT_RATING_WIDGET_LIQUID_SOURCE,
      context: props.context,
    },
  );
  return (
    <SharedLiquidReviewIntegrations
      attributes={componentAttributes}
      component={component}
      liquidSource={liquidSourceWithProductAssignment}
      isProductRating
      placeholder="Shopify Product Rating will appear here"
      shouldHavePlaceHolder={isEditorApp || !product}
      appName="Shopify Product Rating"
      context={context}
    />
  );
}

export default ShopifyProductRatingWidget;
