import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

const circle: ComponentTemplate = {
  id: "b288b1e8-78c5-47af-bd19-b3c168108f2f",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Basic/Circle",
  leftBarCategory: { main: "basic", subCategory: "shapes" },
  preview: "basic/circle.svg",
  transforms: [],
  template: {
    id: "fa7bed5f-70f9-46ba-8229-d0c1844386ba",
    type: "circle",
    children: [],
    props: {
      style: {
        borderTopLeftRadius: "50%",
        borderTopRightRadius: "50%",
        borderBottomLeftRadius: "50%",
        borderBottomRightRadius: "50%",
        backgroundColor: "#64748BB3",
        width: "250px",
        height: "250px",
        color: "#FFFFFFFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },
};

export default circle;
