import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const productAddOns: ComponentTemplate = {
  id: "7a65d80b-8ad9-47fd-be9e-6f4ddf2ca88d",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Rebuy/Rebuy Product Add-Ons",
  leftBarCategory: { main: "pageIntegrations", subCategory: "rebuy" },
  preview: "rebuy/product-add-ons.svg",
  transforms: [],
  template: {
    id: "$uid",
    type: "rebuyWidget",
    props: {
      style: {
        alignSelf: "stretch",
        flexGrow: 1,
      },
    },
  },
};
