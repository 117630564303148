import * as React from "react";

import Input from "@editor/components/common/designSystem/Input";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Separator from "@editor/components/common/designSystem/Separator";
import {
  SkeletonLoaderItem,
  SkeletonLoaderWrapper,
} from "@editor/components/common/designSystem/SkeletonLoader";
import toast, {
  errorToast,
} from "@editor/components/common/designSystem/Toast";
import { Loader } from "@editor/components/common/Loader";
import Header from "@editor/components/dashboard/Header";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useIsWorkspaceOwner } from "@editor/hooks/useIsWorkspaceOwner";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import {
  publisherApi,
  useGetProjectsByWorkspaceIdQuery,
} from "@editor/reducers/api-reducer";
import { useEditorDispatch } from "@editor/store";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { skipToken as rtkSkipToken } from "@reduxjs/toolkit/dist/query";
import Button from "@replo/design-system/components/button";
import { skipToken } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { z } from "zod";

export default function WorkspaceSettings() {
  const workspaceId = useCurrentWorkspaceId();

  const logEvent = useLogAnalytics();
  const modal = useModal();

  const dispatch = useEditorDispatch();

  const { data, isPending: isLoadingWorkspace } =
    trpc.workspace.getById.useQuery(
      workspaceId ? { id: workspaceId } : skipToken,
    );
  const { mutate: updateWorkspace, isPending: isLoadingUpdateWorkspace } =
    trpc.workspace.update.useMutation({
      onSuccess: (data) => {
        toast({
          header: "Workspace information updated successfully",
        });
        logEvent("workspace.setting.updated", {
          workspaceId: data.workspace.id,
          newWorkspaceName: data.workspace.name,
        });
        void trpcUtils.workspace.getUserWorkspacesList.invalidate();
        // NOTE (Fran 2024-07-19): Remove this when TRPC project is finished
        dispatch(publisherApi.util.invalidateTags(["workspaces", "workspace"]));
      },
    });
  const {
    data: workspaceProjectData,
    isLoading: isLoadingWorkspaceProjectData,
  } = useGetProjectsByWorkspaceIdQuery(workspaceId ?? rtkSkipToken);
  const { data: workspaceSubscription, isLoading: isLoadingSubsciption } =
    trpc.subscriptions.getActiveSubscriptionByWorkspace.useQuery(
      workspaceId ?? skipToken,
    );

  const userIsOwner = useIsWorkspaceOwner(workspaceId);
  const referalCodes = data?.workspace?.referralCodes ?? [];
  // NOTE (Fran 2023-11-21): This shouldn't be necessary, but the input in the design system do not
  // work with default value. Created this ticket to fixed after REPL-9402
  const [workspaceName, setWorkspaceName] = useOverridableState<string>(
    data?.workspace?.name ?? "",
  );

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formDataParsed = z
      .object({ workspaceName: z.string() })
      .parse(Object.fromEntries(formData));
    if (!workspaceId) {
      errorToast(
        "Missing Workspace ID",
        "Please input a workspace ID in order to update the workspace.",
      );
    } else {
      void updateWorkspace({
        id: workspaceId,
        name: formDataParsed.workspaceName,
      });
    }
  };

  const workspaceProjectLength = workspaceProjectData?.projects?.length ?? 0;
  const hasReferralCodes = referalCodes.length > 0;
  const hasSubscription =
    isLoadingSubsciption || Boolean(workspaceSubscription);
  const hasActiveProjects =
    isLoadingWorkspaceProjectData ||
    (workspaceProjectData?.projects &&
      workspaceProjectData.projects.length > 0);
  const shouldDisableDeleteButton =
    hasActiveProjects || hasSubscription || hasReferralCodes || !userIsOwner;

  return (
    <div className="px-6 w-full">
      <Header
        title="General Settings"
        subtitle={`Configure settings for ${data?.workspace.name}`}
        isLoading={isLoadingWorkspace}
      />
      <Separator className="my-4 col-span-12" />
      {isLoadingWorkspace ? (
        <Loader className="mt-16" />
      ) : (
        <div className="flex flex-col gap-4 m-1">
          <div className="flex flex-col gap-3">
            <form
              className="flex flex-col gap-4"
              onSubmit={(event) => void onSubmit(event)}
            >
              <div className="flex flex-col gap-1 max-w-fit">
                <label
                  htmlFor="workspaceName"
                  className="text-sm font-semibold"
                >
                  Workspace name
                </label>
                <div className="w-72">
                  <Input
                    size="base"
                    placeholder="Workspace name"
                    value={workspaceName}
                    id="workspaceName"
                    name="workspaceName"
                    required
                    type="text"
                    onChange={(e) => setWorkspaceName(e.target.value)}
                  />
                </div>
              </div>
              <Button
                variant="primary"
                size="base"
                type="submit"
                className="max-w-fit"
                isLoading={isLoadingUpdateWorkspace}
              >
                Update Workspace
              </Button>
            </form>
            {data?.workspace.referralCode?.code && (
              <LabeledControl label="Referrer Code">
                <div className="mb-3 text-sm text-default">
                  {data.workspace.referralCode.code}
                </div>
              </LabeledControl>
            )}
          </div>
          <div className="flex flex-col gap-3">
            <h3 className="text-sm font-semibold">Delete Workspace</h3>
            {hasSubscription || hasActiveProjects || hasReferralCodes ? (
              <div className="flex flex-col gap-1">
                <p className="text-sm text-muted">
                  To delete this workspace you must:
                </p>
                {isLoadingWorkspaceProjectData ||
                isLoadingSubsciption ||
                isLoadingWorkspace ? (
                  <SkeletonLoaderWrapper
                    speed={2}
                    width={430}
                    height={60}
                    viewBox="0 0 430 60"
                    backgroundColor="#f1f5f9"
                    foregroundColor="#e2e8f0"
                  >
                    <SkeletonLoaderItem yAxis="0" width="430" height="16" />
                    <SkeletonLoaderItem yAxis="20" width="430" height="16" />
                    <SkeletonLoaderItem yAxis="40" width="430" height="16" />
                  </SkeletonLoaderWrapper>
                ) : (
                  <ul className="text-sm text-muted list-disc ml-6">
                    {(workspaceProjectData?.projects?.length ?? 0) > 0 ? (
                      <li>
                        Delete all projects from your{" "}
                        <Link
                          className="underline"
                          to={`/workspace/${workspaceId}/projects`}
                        >
                          project list
                        </Link>{" "}
                        <span className="font-bold">
                          [Active Project
                          {workspaceProjectLength > 1 ? "s" : ""}:{" "}
                          {workspaceProjectLength}]
                        </span>
                      </li>
                    ) : null}
                    {Boolean(workspaceSubscription) ? (
                      <li>
                        Cancel your Replo Subscription{" "}
                        <span className="font-bold capitalize">
                          [Current Subscription: {workspaceSubscription?.name}]
                        </span>
                      </li>
                    ) : null}
                    {hasReferralCodes ? (
                      <li>
                        Delete all{" "}
                        <Link
                          className="underline"
                          to={`/workspace/${workspaceId}/partner/home`}
                        >
                          referral codes
                        </Link>{" "}
                        <span className="font-bold">
                          [Active Referral Codes: {referalCodes.length}]
                        </span>
                      </li>
                    ) : null}
                  </ul>
                )}
              </div>
            ) : null}

            <Button
              variant="danger"
              size="base"
              className="max-w-fit"
              isDisabled={shouldDisableDeleteButton}
              onClick={() => {
                if (workspaceId) {
                  modal.openModal({
                    type: "deleteWorkspaceModal",
                    props: {
                      workspaceId,
                    },
                  });
                }
              }}
            >
              Delete Workspace
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
