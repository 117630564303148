import type { TextOutline } from "replo-runtime/shared/types";

import { getFormattedColorWithoutOpacity } from "@editor/utils/colors";

import { isDynamicDataValue } from "replo-runtime";
import { filterNulls } from "replo-utils/lib/array";

export const getTextOutlineObject = (textOutlines: string) => {
  if (isDynamicDataValue(textOutlines)) {
    return null;
  }

  const regex = /^(\S+)\s+(.*)$/;
  const match = textOutlines.match(regex);

  return {
    width: match![1]!,
    color: match![2]!,
  };
};

export const getTextOutlineString = (
  textOutline: TextOutline,
  withoutOpacity = false,
) => {
  const propertiesOrder = ["width", "color"] as const;

  const orderedOutlineValues = filterNulls(
    propertiesOrder.map((prop) => {
      if (textOutline[prop]?.includes("#") && withoutOpacity) {
        return getFormattedColorWithoutOpacity(textOutline[prop]);
      }
      return textOutline[prop];
    }),
  );

  return orderedOutlineValues.join(" ");
};
