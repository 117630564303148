export type Hotkey = "shiftKey" | "altKey" | null;

/**
 *  This function converts an event-like structure to a standardized representation of whether there's a hotkey pressed or not
 * @param event
 * @returns The hotkey pressed name or null
 */

export const getHotKey = (event: {
  altKey: boolean;
  shiftKey: boolean;
  key?: string;
}): Hotkey => {
  const { shiftKey, altKey, key } = event;
  if (shiftKey || key === "Shift") {
    return "shiftKey";
  } else if (altKey || key === "Alt") {
    return "altKey";
  }
  return null;
};
