import * as React from "react";

import Separator from "@editor/components/common/designSystem/Separator";
import DashboardMenuItem from "@editor/components/dashboard/DashboardMenuItem";
import { DashboardMenuItems } from "@editor/components/dashboard/DashboardMenuItems";
import WorkspaceCard from "@editor/components/dashboard/sidebar/WorkspaceCard";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { storeToken } from "@editor/reducers/utils/store-token";
import { routes } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";

import {
  BsArrowUpRight,
  BsBook,
  BsChevronRight,
  BsPerson,
  BsPersonCheck,
  BsSend,
  BsUpload,
} from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

const DashboardSidebar = () => {
  const location = useLocation();
  const { from } = location.state ?? {};
  const isAllProjectsRoute = location.pathname.startsWith(routes.allProjects);
  const shouldHideItems = isAllProjectsRoute || from === "allProjects";
  const logEvent = useLogAnalytics();
  const navigate = useNavigate();
  const { user } = useCurrentUser();
  const workspaceId = useCurrentWorkspaceId();
  const { mutateAsync: logout } = trpc.auth.logout.useMutation({
    onSuccess: async () => {
      storeToken(null);
      window.location.replace("/auth/login");
    },
  });

  const userItem = {
    title: user?.email ?? "",
    className: "text-default",
    startEnhancer: () => <BsPerson size={16} className="shrink-0" />,
    endEnhancer: () => <BsChevronRight size={16} className="shrink-0" />,
    menuItems: [
      {
        type: "leaf" as const,
        id: "my-account",
        title: "My Account",
        startEnhancer: () => <BsPerson size={16} />,
        onSelect: () => {
          logEvent("dashboard.nav.click", {
            tab: "your_profile",
            userId: user?.id ?? null,
            workspaceId,
          });
          navigate("/settings/profile", {
            state: { from: isAllProjectsRoute ? "allProjects" : undefined },
          });
        },
      },
      {
        // NOTE (Sebas, 2024-12-04): We need this to be a section to add
        // the separator between the two items.
        type: "section" as const,
        items: [
          {
            type: "leaf" as const,
            id: "logout",
            title: "Logout",
            startEnhancer: () => <BsUpload className="rotate-90" size={16} />,
            onSelect: () => {
              logEvent("dashboard.logout", {});
              void logout({});
            },
          },
        ],
      },
    ],
  };

  return (
    <div className="flex flex-col border-r-0.5 border-border justify-between">
      <div className="p-2 gap-2 flex flex-col">
        <WorkspaceCard />
        {!shouldHideItems && <DashboardMenuItems />}
      </div>
      <div className="flex px-3 flex-col gap-1 pb-4">
        {RESOURCES_LINKS.map((item) => (
          <DashboardMenuItem key={item.title} item={item} />
        ))}
        <Separator className="my-2" />
        <DashboardMenuItem item={userItem} />
      </div>
    </div>
  );
};

const RESOURCES_LINKS = [
  {
    title: "Learn Replo",
    href: "https://www.replo.app/learn",
    startEnhancer: () => <BsBook size={16} />,
    endEnhancer: () => <BsArrowUpRight size={16} />,
    className: "text-muted",
  },
  {
    title: "Find an Expert",
    href: "https://www.replo.app/experts",
    startEnhancer: () => <BsPersonCheck size={16} />,
    endEnhancer: () => <BsArrowUpRight size={16} />,
    className: "text-muted",
  },
  {
    title: "Feedback",
    href: "https://feedback.replo.app/?utm_source=dashboard",
    startEnhancer: () => <BsSend size={16} />,
    endEnhancer: () => <BsArrowUpRight size={16} />,
    className: "text-muted",
  },
];

export default DashboardSidebar;
