import type { CustomPropsRecord } from "schemas/component";
import type { AlchemyActionType } from "../../../shared/enums";
import type {
  EditorPropsRecord,
  StyleElements,
} from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

import { convertToLegacyProps } from "../../../shared/utils/renderComponents";

function getConfigurableProps(): CustomPropsRecord {
  return {
    _openComponent: {
      name: "Open Component",
      type: "component",
      defaultValue: null,
      description: "This component is displayed for every item in the dropdown",
    },
    _collapsibleComponent: {
      type: "component",
      name: "Collapsible Component",
      defaultValue: null,
      description: "This component is displayed for every item in the dropdown",
    },
    _defaultOpen: {
      type: "boolean",
      name: "Start Open",
      defaultValue: false,
      description:
        "If set, the Collapsible will be expanded when the page first loads.",
    },
    _isAnimated: {
      type: "boolean",
      name: "Animate",
      defaultValue: false,
      description:
        "If set, the Collapsible will animate when opening or closing.",
    },
  };
}

function getEditorProps(): EditorPropsRecord {
  return {
    isOpen: {
      type: "collapsibility",
      title: "Is open",
      description: "If set, the editor will show the content",
      defaultValue: false,
    },
  };
}

export const styleElements = {
  root: {},
} as const satisfies StyleElements;

const actionTypes = [
  "toggleCollapsible",
] as const satisfies AlchemyActionType[];

export type ActionType = (typeof actionTypes)[number];

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    editorProps: getEditorProps(),
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: true,
    allowChildrenReorder: false,
    canContainChildren: true,
    styleElements,
    ancestorDisallowList: [
      {
        ancestorTypes: ["button"],
        message: "Collapsibles cannot be nested inside buttons.",
      },
      {
        ancestorTypes: ["marquee"],
        message: "Collapsibles cannot be nested inside tickers.",
      },
    ],
    variantTriggers: ["state.collapsibleV2.isOpen"],
    actionTypes,
  },
} satisfies ComponentConfig;

export default config;
