import type { AppRouterQueryArgs } from "@editor/utils/trpc";
import type { Experiment } from "schemas/generated/experiment";

import { v4 as uuid } from "uuid";

/**
 * Simple way to get create a default slug that is probably unique,
 * and reasonable to the user.
 */
export function positiveIntToCapitalLetter(i: number): string {
  return String.fromCharCode(96 + Math.min(Math.max(i, 1), 26));
}

export function buildCreateExperimentArgs(
  workspaceId: string,
  experiments: Array<Experiment>,
  urlHost: string,
): AppRouterQueryArgs["experiment"]["create"] {
  const newExperimentId = uuid();
  const placeholderUrl = `https://${urlHost}/pages/example`;

  let slug = `experiment-${experiments?.length ?? 0 + 1}`;
  if (experiments?.find((v) => v.slug === slug)) {
    slug = `experiment-${uuid().slice(0, 8)}`;
  }
  return {
    id: newExperimentId,
    projectId: null,
    workspaceId,
    name: slug,
    description: "",
    slug,
    variations: [
      {
        id: uuid(),
        target: placeholderUrl,
        slug: "a",
        allocationPercent: 50,
      },
      {
        id: uuid(),
        target: placeholderUrl,
        slug: "b",
        allocationPercent: 50,
      },
    ],
  };
}

export const NO_LINK_TEXT = "(no link)";
