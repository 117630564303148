// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import type { MenuItem } from "@common/designSystem/Menu";
import type { EditorRootState } from "@editor/store";
import type { ReploElementMetadata } from "schemas/element";
import type { ReploElement, ReploElementType } from "schemas/generated/element";
import type { Folder } from "schemas/generated/folder";

import * as React from "react";

import { updateAndSaveElement } from "@actions/core-actions";
import {
  Group,
  GroupCollapsibleIndicator,
  GroupHeader,
  GroupHeaderActionButton,
  GroupTitleContainer,
} from "@common/designSystem/Group";
import Input from "@common/designSystem/Input";
import { Menu, MenuTrigger } from "@common/designSystem/Menu";
import Separator from "@common/designSystem/Separator";
import Switch from "@common/designSystem/Switch";
import { elementTypeToEditorData } from "@components/editor/element";
import { useIsDebugMode } from "@editor/components/editor/debug/useIsDebugMode";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useLocalStorageState } from "@editor/hooks/useLocalStorage";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useOpenModal } from "@editor/hooks/useModal";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import {
  selectAllElementMetadata,
  selectDefaultCollapsedElementTypeGroup,
  selectDraftElementId,
  selectDraftElementType,
  selectElementById,
  selectElementMetadata,
  selectIsShopifyIntegrationEnabled,
} from "@editor/reducers/core-reducer";
import { setIsRightBarAnalyticsOpen } from "@editor/reducers/ui-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import {
  getElementFolderName,
  mapReploElementToReploElementMetadata,
  REPLO_ELEMENT_DEFAULT_FOLDER_NAME,
} from "@editor/utils/element";
import { saveLastSelectedElementIdForTypeForStore } from "@editor/utils/localStorage";
import {
  generateMarketplacePathnameAndCategoryId,
  routes,
} from "@editor/utils/router";
import { getEmptyTemplate } from "@editor/utils/template";
import { trpc } from "@editor/utils/trpc";

import { RadioGroup, RadioGroupItem } from "@radix-ui/react-radio-group";
import IconButton from "@replo/design-system/components/button/IconButton";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@replo/design-system/components/shadcn/core/popover";
import { Spinner } from "@replo/design-system/components/spinner";
import Tooltip from "@replo/design-system/components/tooltip";
import { skipToken } from "@tanstack/react-query";
import classNames from "classnames";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import groupBy from "lodash-es/groupBy";
import isEqual from "lodash-es/isEqual";
import orderBy from "lodash-es/orderBy";
import size from "lodash-es/size";
import { BiSearch } from "react-icons/bi";
import {
  BsCart,
  BsCheck,
  BsFile,
  BsFileText,
  BsFillCaretDownFill,
  BsFillCircleFill,
  BsFilter,
  BsFolder,
  BsGraphUpArrow,
  BsLayoutTextWindow,
  BsPlus,
  BsViewList,
  BsWindow,
} from "react-icons/bs";
import { HiDotsHorizontal } from "react-icons/hi";
import { RiHomeFill } from "react-icons/ri";
import {
  generatePath,
  Link,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { animated, config, useSpring } from "react-spring";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { removeFolderNameFromElementName } from "replo-utils/element";
import { isEmpty } from "replo-utils/lib/misc";
import { useDebouncedCallback } from "replo-utils/react/use-debounced-callback";
import { reploElementTypeSchema } from "schemas/element";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

type PaneInfo = {
  id: string;
  label: string;
  newTooltip: string;
  searchBoxPlaceholder: string;
};

type PaneInfoRecord = Record<ReploElementType, PaneInfo>;

type FolderWithElements = {
  folder?: Folder;
  elements: ReploElementMetadata[];
};

type FolderElementMetadataMapping = Record<Folder["id"], FolderWithElements>;

type GroupedFoldersWithElementMetadata = Record<
  ReploElementType,
  FolderElementMetadataMapping
>;

type ElementListProps = {
  folderElementMetadataMapping: FolderElementMetadataMapping;
  onClick: (contentItem: ReploElementMetadata) => void;
  currentElementType: ReploElementType;
};

type ElementTypeGroupInfo = {
  type: ReploElementType;
  title: string;
  createTooltipText: string;
  createButtonDataTestId: string;
  groupDataTestId: string;
};

type ElementTypeGroupContentProps = {
  folderElementMetadataMapping: FolderElementMetadataMapping;
  elementTypeGroup: ElementTypeGroupInfo;
  resetElement: (elementId: string) => void;
  isCollapsed: boolean;
  setCollapsedElementTypeGroups: (elementType: ReploElementType | null) => void;
  groupDataTestId: string;
};

type SortMenuProps = {
  sortBy: SortBy;
  setSortBy: (value: SortBy) => void;
  sortOrder: SortOrder;
  setSortOrder: (value: SortOrder) => void;
  showFirst: ShowFirst[];
  setShowFirst: (value: ShowFirst, checked: boolean) => void;
};

const paneInfo: PaneInfoRecord = {
  page: {
    id: "add-page",
    label: "Pages",
    newTooltip: "New Page",
    searchBoxPlaceholder: "Search Pages",
  },
  shopifyArticle: {
    id: "add-article",
    label: "Posts",
    newTooltip: "New Blog Post",
    searchBoxPlaceholder: "Search Posts",
  },
  shopifySection: {
    id: "add-section",
    label: "Sections",
    newTooltip: "New Shopify Section",
    searchBoxPlaceholder: "Search Sections",
  },
  shopifyProductTemplate: {
    id: "add-product-template",
    label: "Product Templates",
    newTooltip: "New Product Template",
    searchBoxPlaceholder: "Search Product Templates",
  },
};

const ELEMENT_TYPE_GROUP_LIST: Array<ElementTypeGroupInfo> = [
  {
    title: "Pages",
    type: "page",
    createTooltipText: "New Page",
    createButtonDataTestId: "add-page",
    groupDataTestId: "pages-group",
  },
  {
    title: "Product Templates",
    type: "shopifyProductTemplate",
    createTooltipText: "New Product Template",
    createButtonDataTestId: "add-product-template",
    groupDataTestId: "product-templates-group",
  },
  {
    title: "Sections",
    type: "shopifySection",
    createTooltipText: "New Shopify Section",
    createButtonDataTestId: "add-section",
    groupDataTestId: "sections-group",
  },
  {
    title: "Blog Posts",
    type: "shopifyArticle",
    createTooltipText: "New Blog Post",
    createButtonDataTestId: "add-article",
    groupDataTestId: "blog-posts-group",
  },
];

const mapIconToElementType: Record<ReploElementType, React.ReactNode> = {
  page: <BsWindow size={12} />,
  shopifySection: <BsViewList size={12} />,
  shopifyProductTemplate: <BsCart size={12} />,
  shopifyArticle: <BsFileText size={12} />,
};

type SortBy = "name" | "createdAt" | "updatedAt";
type SortOrder = "asc" | "desc";
type ShowFirst = "folders" | "published";

export const useFolders = () => {
  const projectId = useCurrentProjectId();
  const { data: foldersData, isLoading } = trpc.folder.list.useQuery(
    projectId ? { projectId } : skipToken,
  );
  return { folders: foldersData?.folders ?? [], isLoading };
};

const getSortedElements = (
  elements: ReploElementMetadata[],
  sortOptions: {
    sortBy: SortBy;
    sortOrder: SortOrder;
    showFirst: ShowFirst[];
  },
) => {
  const { showFirst, sortBy, sortOrder } = sortOptions;

  const getSortKeyAndOrder = () => {
    const sortKey = [];
    const order: ("asc" | "desc")[] = [];

    if (showFirst.includes("published")) {
      sortKey.push("publishedAt");
      order.push("asc");
    }

    if (sortBy === "name") {
      // NOTE (Fran 2024-12-12): We need to normalize the name to lowercase to sort by name correctly.
      sortKey.push((element: ReploElementMetadata) =>
        element.name.toLowerCase(),
      );
    } else {
      sortKey.push(sortBy);
    }

    order.push(sortOrder);
    return { sortKey, order };
  };
  const { sortKey, order } = getSortKeyAndOrder();
  return orderBy(elements, sortKey, order);
};

const useGroupedFilteredFoldersWithElementMetadata = (
  folders: Folder[],
  searchTerm: string | undefined,
  sortOptions: {
    showFirst: ShowFirst[];
    sortBy: SortBy;
    sortOrder: SortOrder;
  },
) => {
  const elementMetadata = useEditorSelector(selectAllElementMetadata);

  const matchesSearchTerm = React.useCallback(
    (title: string) => {
      if (!searchTerm) {
        return true;
      }
      return title.toLowerCase().includes(searchTerm.toLowerCase());
    },
    [searchTerm],
  );

  return React.useMemo(() => {
    const result: GroupedFoldersWithElementMetadata = {
      page: {},
      shopifyArticle: {},
      shopifyProductTemplate: {},
      shopifySection: {},
    };

    // Note (Evan, 2024-12-11): Process folders and their elements
    for (const folder of folders) {
      const folderElements = elementMetadata.filter(
        (element) => element.folderId === folder.id,
      );

      // Note (Evan, 2024-12-11): If we're not searching or the folder's name matches the search term, include it and all its elements
      if (!searchTerm || matchesSearchTerm(folder.name)) {
        const sortedFolderElements = getSortedElements(
          folderElements,
          sortOptions,
        );

        result[folder.kind][folder.id] = {
          folder,
          elements: sortedFolderElements,
        };
        continue;
      }

      // Note (Evan, 2024-12-11): Otherwise, only include the folder if it has elements matching the search term
      const elementsMatchingSearchTerm = folderElements.filter((element) =>
        matchesSearchTerm(element.name),
      );

      const sortedElementsMatchingSearchTerm = getSortedElements(
        elementsMatchingSearchTerm,
        sortOptions,
      );

      if (!isEmpty(sortedElementsMatchingSearchTerm)) {
        result[folder.kind][folder.id] = {
          folder,
          elements: sortedElementsMatchingSearchTerm,
        };
      }
    }

    // Note (Evan, 2024-12-11): Process elements not in any folder, by type
    for (const elementType of [
      "page",
      "shopifyProductTemplate",
      "shopifyArticle",
      "shopifySection",
    ] as const) {
      const elementsWithoutFolders = elementMetadata.filter(
        (element) => !element.folderId && element.type === elementType,
      );

      const filteredElementsWithoutFolders = searchTerm
        ? elementsWithoutFolders.filter((element) =>
            matchesSearchTerm(element.name),
          )
        : elementsWithoutFolders;

      const sortedElementsWithoutFolders = getSortedElements(
        filteredElementsWithoutFolders,
        sortOptions,
      );

      if (!isEmpty(sortedElementsWithoutFolders)) {
        result[elementType][REPLO_ELEMENT_DEFAULT_FOLDER_NAME] = {
          folder: undefined,
          elements: sortedElementsWithoutFolders,
        };
      }
    }

    return result;
  }, [elementMetadata, folders, searchTerm, matchesSearchTerm, sortOptions]);
};

type ElementsPaneContextType = {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
};

const ElementsPaneContext = React.createContext<
  ElementsPaneContextType | undefined
>(undefined);

export const useElementsPaneContext = () => {
  const context = React.useContext(ElementsPaneContext);
  if (context === undefined) {
    throw new Error(
      "useElementsPaneContext must be used within ElementsPaneContext",
    );
  }
  return context;
};

const ElementsPane: React.FC = () => {
  const [searchTerm, setSearchTerm] = React.useState("");

  const [sortBy, setSortBy] = useLocalStorageState("replo.sort-by", "name", {
    schema: z.enum(["name", "createdAt", "updatedAt"]),
  });
  const [sortOrder, setSortOrder] = useLocalStorageState(
    "replo.sort-order",
    "asc",
    {
      schema: z.enum(["asc", "desc"]),
    },
  );
  const [showFirst, setShowFirst] = useLocalStorageState(
    "replo.show-first",
    ["folders"],
    {
      schema: z.array(z.enum(["folders", "published"])),
    },
  );

  const { search } = useLocation();
  const { elementId } = useParams();
  const navigate = useNavigate();

  const logEvent = useLogAnalytics();
  const setDraftElement = useSetDraftElement();
  const { isLoading: isLoadingProject, project } = useCurrentProjectContext();
  const projectId = project?.id;
  const { isLoading: isLoadingFolders, folders } = useFolders();
  const isLoading = isLoadingProject || isLoadingFolders;

  const groupedFoldersWithElementMetadata =
    useGroupedFilteredFoldersWithElementMetadata(folders, searchTerm, {
      showFirst,
      sortBy,
      sortOrder,
    });

  const resetDraftElement = () => {
    setDraftElement({
      componentIds: [],
    });
  };

  const resetElement = (elementId: string) => {
    resetDraftElement();
    navigate(
      `${generatePath(routes.editor.element, {
        projectId,
        elementId,
      })}${search}`,
    );
  };

  const defaultCollapsedElementTypeGroups = useEditorSelector((state) =>
    selectDefaultCollapsedElementTypeGroup(state, elementId),
  );

  const [collapsedElementTypeGroups, setCollapsedElementTypeGroups] =
    useLocalStorageState(
      "replo.collapsed-elements-type-groups",
      defaultCollapsedElementTypeGroups,
      {
        schema: z.array(reploElementTypeSchema),
      },
    );

  // NOTE (Fran 2024-10-24): Every time we search, we will add a debounce to open all groups that have
  // results. We don't want to do it every time the user types because it is unnecessary and can
  // result in a performance issue.
  const collapseElementsTypeGroupAfterSearch = useDebouncedCallback(
    (value: string) => {
      if (value.length === 0) {
        setCollapsedElementTypeGroups(defaultCollapsedElementTypeGroups);
      } else {
        const collapsedElementType = new Set<ReploElementType>();

        for (const [elementType, folders] of Object.entries(
          groupedFoldersWithElementMetadata,
        )) {
          if (size(folders) > 0) {
            collapsedElementType.add(elementType as ReploElementType);
          }
        }

        setCollapsedElementTypeGroups(Array.from(collapsedElementType));
      }
    },
    300,
  );

  if (isLoading) {
    return (
      <div className="grid h-full w-full items-center justify-items-center">
        <Spinner size={20} variant="primary" />
      </div>
    );
  }

  return (
    <ElementsPaneContext.Provider value={{ searchTerm, setSearchTerm }}>
      <div className="flex flex-col w-full h-full gap-3 overflow-y-hidden pl-2 pt-1">
        <div className="pl-1 pr-3 flex gap-2">
          <Input
            autoComplete="off"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              // NOTE (Fran 2024-10-23): We want to collapse all the element type groups after the search.
              collapseElementsTypeGroupAfterSearch(e.target.value);
            }}
            onEnter={() => {
              // NOTE (Sebas, 2024-10-18): We want to log the search event when the user
              // press enter becasuse after this the search input will be cleared.
              if (searchTerm.length > 0) {
                logEvent("element.search", {
                  searchString: searchTerm,
                });
              }
            }}
            onBlur={() => {
              // NOTE (Sebas, 2024-10-18): We want to log the search event when the user
              // press enter becasuse after this the search input will be cleared.
              if (searchTerm.length > 0) {
                logEvent("element.search", {
                  searchString: searchTerm,
                });
              }
            }}
            startEnhancer={() => <BiSearch size={12} />}
          />
          <SortMenu
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            showFirst={showFirst}
            setShowFirst={(value, checked) =>
              setShowFirst((previousValue) => {
                const newValue = new Set(previousValue);
                if (checked) {
                  newValue.add(value);
                } else {
                  newValue.delete(value);
                }
                return Array.from(newValue);
              })
            }
          />
        </div>
        <div className="flex flex-col gap-2 overflow-y-hidden h-full">
          {ELEMENT_TYPE_GROUP_LIST.map((elementTypeGroup) => {
            const folderElementMetadataMapping =
              groupedFoldersWithElementMetadata[elementTypeGroup.type];
            const isCollapsed = collapsedElementTypeGroups.includes(
              elementTypeGroup.type,
            );
            return (
              <ElementTypeGroupContent
                key={elementTypeGroup.createButtonDataTestId}
                folderElementMetadataMapping={folderElementMetadataMapping}
                elementTypeGroup={elementTypeGroup}
                resetElement={resetElement}
                isCollapsed={isCollapsed}
                groupDataTestId={elementTypeGroup.groupDataTestId}
                setCollapsedElementTypeGroups={(
                  elementType: ReploElementType | null,
                ) => {
                  const newCollapsedElementTypeGroups = new Set(
                    collapsedElementTypeGroups,
                  );
                  if (elementType) {
                    newCollapsedElementTypeGroups.add(elementType);
                  } else {
                    newCollapsedElementTypeGroups.delete(elementTypeGroup.type);
                  }
                  setCollapsedElementTypeGroups(
                    Array.from(newCollapsedElementTypeGroups),
                  );
                }}
              />
            );
          })}
          <Separator />
        </div>
      </div>
    </ElementsPaneContext.Provider>
  );
};

const SortMenu: React.FC<SortMenuProps> = ({
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
  showFirst,
  setShowFirst,
}) => {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          icon={<BsFilter />}
          tooltipText="Filter"
          variant="secondary"
        />
      </PopoverTrigger>
      <PopoverContent asChild className="p-2">
        <div className="flex flex-col gap-2 text-default typ-body-small w-[200px]">
          <div className="flex flex-col gap-1">
            <h3 className="typ-header-small px-2">Sort By</h3>
            <RadioGroup
              defaultValue={sortBy}
              value={sortBy}
              onValueChange={(value) => setSortBy(value as SortBy)}
              className="flex flex-col gap-0.5 items-start"
            >
              <RadioGroupItem
                value="name"
                checked={sortBy === "name"}
                className={twMerge(
                  "w-full rounded text-left px-2 py-1 flex justify-between items-center",
                  sortBy === "name" && "bg-menu-item-selected",
                  sortBy !== "name" && "hover:bg-menu-item-hover",
                )}
              >
                Alphabetical
                {sortBy === "name" && <BsCheck size={16} />}
              </RadioGroupItem>
              <RadioGroupItem
                value="createdAt"
                checked={sortBy === "createdAt"}
                className={twMerge(
                  "w-full rounded text-left px-2 py-1 flex justify-between items-center",
                  sortBy !== "createdAt" && "hover:bg-menu-item-hover",
                  sortBy === "createdAt" && "bg-menu-item-selected",
                )}
              >
                Date Created
                {sortBy === "createdAt" && <BsCheck size={16} />}
              </RadioGroupItem>
              <RadioGroupItem
                value="updatedAt"
                checked={sortBy === "updatedAt"}
                className={twMerge(
                  "w-full rounded text-left px-2 py-1 flex justify-between items-center",
                  sortBy !== "updatedAt" && "hover:bg-menu-item-hover",
                  sortBy === "updatedAt" && "bg-menu-item-selected",
                )}
              >
                Last Modified
                {sortBy === "updatedAt" && <BsCheck size={16} />}
              </RadioGroupItem>
            </RadioGroup>
          </div>
          <Separator />
          <div className="flex flex-col gap-1">
            <h3 className="typ-header-small px-2">Sort Order</h3>
            <RadioGroup
              defaultValue={sortOrder}
              value={sortOrder}
              onValueChange={(value) => setSortOrder(value as SortOrder)}
              className="flex flex-col gap-0.5 items-start"
            >
              <RadioGroupItem
                value="asc"
                className={twMerge(
                  "w-full rounded text-left px-2 py-1 flex justify-between items-center",
                  sortOrder !== "asc" && "hover:bg-menu-item-hover",
                  sortOrder === "asc" && "bg-menu-item-selected",
                )}
              >
                {sortBy === "name" ? "A to Z" : "Oldest First"}
                {sortOrder === "asc" && <BsCheck size={16} />}
              </RadioGroupItem>
              <RadioGroupItem
                value="desc"
                className={twMerge(
                  "w-full rounded text-left px-2 py-1 flex justify-between items-center",
                  sortOrder !== "desc" && "hover:bg-menu-item-hover",
                  sortOrder === "desc" && "bg-menu-item-selected",
                )}
              >
                {sortBy === "name" ? "Z to A" : "Newest First"}
                {sortOrder === "desc" && <BsCheck size={16} />}
              </RadioGroupItem>
            </RadioGroup>
          </div>
          <Separator />
          <div className="flex flex-col gap-1">
            <h3 className="typ-header-small px-2">Group at top</h3>
            <div className="flex justify-between items-center px-2">
              <Switch
                isOn={showFirst.includes("published")}
                backgroundOnColor="bg-primary"
                label={<span className="text-default">Published</span>}
                onChange={(isOn) => setShowFirst("published", isOn)}
              />
            </div>
            <div className="flex justify-between items-center px-2">
              <Switch
                isOn={showFirst.includes("folders")}
                backgroundOnColor="bg-primary"
                label={<span className="text-default">Folders</span>}
                onChange={(isOn) => setShowFirst("folders", isOn)}
              />
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

const CreateNewElementButton: React.FC<{
  elementType: ReploElementType;
}> = ({ elementType }) => {
  const [isOpen, setOpen] = React.useState(false);
  const openModal = useOpenModal();
  const navigate = useNavigate();

  const elementId = useEditorSelector(selectDraftElementId);
  const projectId = useCurrentProjectId();
  const createNewFolder = useCreateNewFolder();

  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );

  const { searchTerm, setSearchTerm } = useElementsPaneContext();

  const displayName = elementTypeToEditorData[elementType].singularDisplayName;
  const createButtonText =
    !isShopifyIntegrationEnabled && elementType !== "page"
      ? "Enabled with Shopify store integration"
      : "Create New";

  const menuItems: MenuItem[] = [
    {
      type: "leaf",
      id: "blank",
      // Note (Evan, 2024-12-04): Set a fixed width on the items so the text is aligned
      // regardless of the different aspect ratios of each icon
      title: (
        <div className="flex items-center px-2 gap-1">
          <BsFile size={12} className="w-[14px]" />
          <span>{`Blank ${displayName}`}</span>
        </div>
      ),
      onSelect: () => {
        if (searchTerm) {
          setSearchTerm("");
        }
        openModal({
          type: "createElementModal",
          props: {
            initialElementType: elementType,
            initialTemplate: getEmptyTemplate(elementType),
          },
        });
      },
    },
    {
      type: "leaf",
      id: "template",
      title: (
        <div className="flex items-center px-2 gap-1">
          <BsLayoutTextWindow size={12} className="w-[14px]" />
          <span>Choose Template</span>
        </div>
      ),
      onSelect: () => {
        if (searchTerm) {
          setSearchTerm("");
        }
        const { generatedPath, categoryId } =
          generateMarketplacePathnameAndCategoryId({
            elementType,
            elementId,
            projectId,
          });
        navigate(generatedPath, {
          state: {
            elementType,
            marketplaceModalRequestType: "create",
            // NOTE (Fran 2024-10-16): When we create a new element using a template, we need to pass
            // the pre-selected filters so the user can find a template that matches the element type.
            // This is only needed if the user creates a new Blog Post or Product Page. For the
            // other element types, we can have no selected filters.
            selectedFilters: {
              category: categoryId ? [categoryId] : [],
              badge: [],
              industry: [],
            },
          },
        });
      },
    },
    {
      type: "leaf",
      id: "folder",
      title: (
        <div className="flex items-center px-2 gap-1">
          <BsFolder size={12} className="w-[14px]" />
          <span>Folder</span>
        </div>
      ),
      onSelect: () => {
        if (searchTerm) {
          setSearchTerm("");
        }
        createNewFolder(elementType, "Untitled Folder");
      },
    },
  ];

  return (
    <Menu
      items={menuItems}
      isOpen={isOpen}
      onRequestClose={() => setOpen(false)}
      contentClassNames="mt-2"
      align="start"
      trigger={
        <MenuTrigger asChild>
          <IconButton
            icon={<BsPlus size={20} className="text-muted" />}
            className="items-center justify-center p-0"
            tooltipText={createButtonText}
            variant="tertiary"
            onClick={() => setOpen(true)}
            isDisabled={!isShopifyIntegrationEnabled && elementType !== "page"}
          />
        </MenuTrigger>
      }
      disableTriggerFocusOnClose
    />
  );
};

const ElementTypeGroupContent: React.FC<
  React.PropsWithChildren<ElementTypeGroupContentProps>
> = ({
  folderElementMetadataMapping,
  elementTypeGroup,
  resetElement,
  isCollapsed,
  setCollapsedElementTypeGroups,
  groupDataTestId,
}) => {
  const { title, type, createButtonDataTestId } = elementTypeGroup;

  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );

  const hasElements = Object.values(folderElementMetadataMapping).length > 0;
  const { folders: allFolders } = useFolders();

  const hasFolders = allFolders.some((folder) => folder.kind === type);

  const hasData = hasElements || hasFolders;

  const shouldAllowCreateReploElements =
    type === "page" || isShopifyIntegrationEnabled;

  const numberOfElements = Object.values(folderElementMetadataMapping).reduce(
    (acc, { elements }) => acc + elements.length,
    0,
  );

  const [isHovered, setIsHovered] = React.useState(false);

  // Animation for the first child (hidden by default, shown on hover)
  const showOnHoverStyle = useSpring({
    opacity: isHovered ? 1 : 0,
    display: isHovered ? "block" : "none",
    config: config.slow,
  });

  // Animation for the second child (visible by default, hidden on hover)
  const hideOnHoverStyle = useSpring({
    opacity: isHovered ? 0 : 1,
    display: isHovered ? "none" : "block",
    config: config.slow,
  });

  return (
    <div
      key={type}
      className={classNames(
        "flex flex-col gap-2",
        // NOTE (Fran 2024-10-24): We want to make sure the element list shows at least two elements
        // if there are more than one group collapsed and one of them has several elements.
        isCollapsed && hasElements ? "min-h-16" : "min-h-8",
      )}
    >
      <div className="mr-3">
        <Separator />
      </div>
      <Group
        name={title}
        header={
          <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <GroupHeader
              className="pr-3 gap-1.5 text-default"
              hideEndEnhancerOnGroupClosed={false}
              shouldStopPropagationOnTitleEnhancer={false}
              startEnhancer={
                <animated.div
                  style={
                    !isCollapsed && numberOfElements === 0
                      ? {
                          display: "block",
                        }
                      : hideOnHoverStyle
                  }
                  className="p-0.5"
                >
                  {mapIconToElementType[type]}
                </animated.div>
              }
              titleEnhancer={
                <span className="text-default text-xs self-center whitespace-nowrap flex gap-1.5">
                  <span className="text-border">|</span> {numberOfElements}
                </span>
              }
              endEnhancer={
                <div
                  data-testid={`${createButtonDataTestId}-button`}
                  className="h-6"
                >
                  <CreateNewElementButton elementType={type} />
                </div>
              }
            >
              <GroupTitleContainer
                groupDataTestId={groupDataTestId}
                collapsibleIndicator={
                  <animated.div
                    style={
                      !isCollapsed
                        ? showOnHoverStyle
                        : {
                            display: "block",
                          }
                    }
                    className={twMerge(
                      "p-1 hover:bg-light-surface rounded-sm text-default",
                      !isCollapsed && numberOfElements === 0 && "hidden",
                      !isCollapsed && "-rotate-90",
                    )}
                  >
                    <BsFillCaretDownFill size={8} />
                  </animated.div>
                }
              />
            </GroupHeader>
          </div>
        }
        isCollapsible={hasData && shouldAllowCreateReploElements}
        isOpen={isCollapsed}
        onOpenChange={(isOpen) => {
          setCollapsedElementTypeGroups(isOpen ? type : null);
        }}
        contentClassName="overflow-y-scroll styled-scrollbar pr-0.5"
        className="overflow-hidden"
      >
        {hasData && isCollapsed ? (
          <ElementList
            onClick={(element) => resetElement(element.id)}
            folderElementMetadataMapping={folderElementMetadataMapping}
            currentElementType={type}
          />
        ) : null}
      </Group>
    </div>
  );
};

const ElementList: React.FC<React.PropsWithChildren<ElementListProps>> = ({
  folderElementMetadataMapping,
  onClick,
  currentElementType,
}) => {
  const isDebugMode = useIsDebugMode();
  const logEvent = useLogAnalytics();
  const { isLoading } = useCurrentProjectContext();

  const {
    handleDeletion,
    handleDuplication,
    handleEditPage,
    handleMoveToFolder,
    handleSupportDuplication,
  } = usePageModificationHandlers();

  const { folders: allFolders } = useFolders();

  // NOTE (Fran 2024-10-18): We handle the loading state in the new left bar UI in other component.
  if (isLoading) {
    return null;
  }

  const sortedFolderElementMetadataMapping = Object.values(
    folderElementMetadataMapping,
  ).sort((a, b) => {
    // Note (Evan, 2024-12-11): The sorting here is as follows -
    // 1) Folders (newest first so that new folders are always on top)
    // 2) elements not in a folder

    // Note (Evan, 2024-12-11): If a or b has no folder (is the uncategorized elements array), put it last
    if (!a.folder) {
      return 1;
    }
    if (!b.folder) {
      return -1;
    }

    return b.folder.createdAt.getTime() - a.folder.createdAt.getTime();
  });

  return (
    <ul
      className="overflow-hidden flex flex-col gap-1"
      data-testid="elements-list"
    >
      {sortedFolderElementMetadataMapping.map(({ folder, elements }) => {
        // Note (Evan, 2024-12-05): Undefined folder means they're in no folder.
        if (folder === undefined) {
          return elements.map((element) => (
            <Menu
              key={element.id}
              items={getMenuItemsForElementItems({
                element,
                currentFolder: null,
                allFolders,
                handleDeletion,
                handleDuplication,
                handleSupportDuplication,
                handleEditPage,
                handleMoveToFolder,
                isDebugMode,
              })}
              menuType="context"
              customWidth="auto"
              trigger={
                <li>
                  <ElementItem
                    element={element}
                    folder={folder}
                    onClick={(element) => {
                      onClick(element);
                      logEvent("element.select", {
                        elementId: element.id,
                        elementType: element.type,
                      });
                      saveLastSelectedElementIdForTypeForStore(
                        element.projectId,
                        element.type,
                        element.id,
                      );
                    }}
                  />
                </li>
              }
            />
          ));
        }
        return (
          <FolderItem
            folder={folder}
            elements={elements}
            elementType={currentElementType}
            onClick={onClick}
            key={folder.id}
            startInRenameMode={
              new Date().getTime() - folder.createdAt.getTime() < 1000
            }
          />
        );
      })}
    </ul>
  );
};

const ElementItem: React.FC<{
  element: ReploElementMetadata;
  folder: Folder | undefined;
  onClick(element: ReploElementMetadata): void;
}> = React.memo(function ElementItem({ element, folder, onClick }) {
  const draftElementType = useEditorSelector(selectDraftElementType);
  const { elementId } = useParams();
  const location = useLocation();
  const isCurrentElement = element.id === elementId;
  const ref = React.useRef<HTMLAnchorElement>(null);
  const difference = element.publishedAt
    ? formatDistanceToNowStrict(new Date(element.publishedAt))
    : null;
  const publishedTooltipLabel =
    element.isPublished && difference
      ? `Last Published ${difference} ago`
      : "Not Published";

  const dispatch = useEditorDispatch();

  React.useEffect(() => {
    if (isCurrentElement) {
      ref.current?.scrollIntoView({
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [isCurrentElement]);

  const { id, name, isHomepage, isTurbo, projectId } = element;

  if (!draftElementType) {
    return null;
  }

  if (!draftElementType) {
    return null;
  }

  const elementHasCategory = Boolean(folder);
  const elementName = elementHasCategory
    ? removeFolderNameFromElementName(name)
    : name;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!e.metaKey && !e.ctrlKey) {
      e.preventDefault();
      onClick(element);

      if (element.type !== "page" || !element.isPublished) {
        dispatch(setIsRightBarAnalyticsOpen(false));
      }
    }
  };

  return (
    <Link
      ref={ref}
      to={`${generatePath(routes.editor.element, {
        projectId,
        elementId: id,
      })}${location.search}`}
      className={twMerge(
        "group flex h-8 cursor-pointer items-center gap-1 py-1 px-2 text-xs tracking-tight text-black transition duration-300 rounded",
        elementHasCategory && "pl-6",
        isCurrentElement
          ? "bg-blue-100 font-normal"
          : "bg-default hover:bg-slate-50",
      )}
      onClick={handleClick}
    >
      <span
        className={twMerge(
          classNames("flex-1 truncate", {
            "text-blue-600": isCurrentElement,
            "text-black": !element.isPublished && !isCurrentElement,
          }),
        )}
      >
        {elementName}
      </span>
      {isHomepage && (
        <Tooltip content="This page is set as the Home Page" triggerAsChild>
          <div tabIndex={0}>
            <RiHomeFill size={16} className="text-subtle" />
          </div>
        </Tooltip>
      )}
      {isTurbo && (
        <Tooltip content="This page is using Replo Turbo" triggerAsChild>
          <div
            className="w-4 h-4 bg-subtle rounded-full text-[10px] font-bold leading-none grid place-content-center text-onEmphasis"
            tabIndex={0}
          >
            T
          </div>
        </Tooltip>
      )}
      {element.isPublished && (
        // Note (Sebas, 2023-11-06): This margin is necessary to align the green dots
        // with the content inside the buttons (plus icon)
        <div className="flex flex-row gap-1 mr-0.5 items-center">
          {element.type === "page" && (
            <IconButton
              icon={<BsGraphUpArrow size={12} />}
              tooltipText="Open Page Analytics"
              className={twMerge(
                isCurrentElement
                  ? "text-accent hover:bg-blue-200"
                  : "text-default hover:bg-slate-200 hidden group-hover:flex",
              )}
              variant="inherit"
              onClick={() => {
                dispatch(setIsRightBarAnalyticsOpen(true));
              }}
            />
          )}
          <Tooltip content={publishedTooltipLabel} triggerAsChild>
            <div tabIndex={0} className="ml-1">
              <BsFillCircleFill size={8} className="text-green-400" />
            </div>
          </Tooltip>
        </div>
      )}
    </Link>
  );
});

const FolderItem: React.FC<{
  folder: Folder;
  elements: ReploElementMetadata[];
  elementType: ReploElementType;
  onClick: (element: ReploElementMetadata) => void;
  startInRenameMode?: boolean;
}> = ({
  folder,
  elements,
  elementType,
  onClick,
  startInRenameMode = false,
}) => {
  const { name, id } = folder;
  const openModal = useOpenModal();
  const isDebugMode = useIsDebugMode();
  const { folders: allFolders } = useFolders();

  const {
    handleDeletion,
    handleDuplication,
    handleEditPage,
    handleMoveToFolder,
    handleSupportDuplication,
  } = usePageModificationHandlers();

  const { handleUpdateFolderName, handleDeleteFolder } =
    useFolderModificationHandlers();

  const actionLabel = `New ${elementTypeToEditorData[elementType].singularDisplayName} in ${name}`;

  const [areQuickActionsOpen, setAreQuickActionsOpen] = React.useState(false);

  const [isEditingName, setIsEditingName] = React.useState(startInRenameMode);
  const [isHovered, setIsHovered] = React.useState(false);
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const [draftName, setDraftName] = React.useState(name);
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (isEditingName && inputRef.current) {
      inputRef.current.select();
    }
  }, [isEditingName]);

  const menuItems: MenuItem[] = [
    {
      type: "leaf",
      id: "rename",
      title: "Rename Folder",
      onSelect: () => {
        setIsEditingName(true);
      },
      isDisabled: false,
    },
    // Note (Evan, 2024-12-06): Currently only allow deleting empty folders.
    ...(elements.length === 0
      ? [
          {
            type: "leaf" as const,
            id: "delete",
            title: "Delete Folder",
            onSelect: () => handleDeleteFolder(id),
          },
        ]
      : []),
  ];

  const showOnHoverStyle = useSpring({
    opacity: isHovered ? 1 : 0,
    display: isHovered ? "block" : "none",
    config: config.slow,
  });

  const hideOnHoverStyle = useSpring({
    opacity: isHovered ? 0 : 1,
    display: isHovered ? "none" : "block",
    config: config.slow,
  });

  const hasElements = elements.length > 0;

  return (
    <Group
      name={`${draftName} (${elements.length})`}
      isDefaultOpen
      isCollapsible={hasElements}
      contentClassName="py-0 overflow-hidden"
      isOpen={isCollapsed}
      onOpenChange={setIsCollapsed}
      header={
        isEditingName ? (
          <div className="flex flex-row items-center gap-2 pl-2 h-8">
            <div>
              <BsFolder size={12} className="grow" />
            </div>
            <div className="w-full pl-1 pr-2">
              <Input
                value={draftName}
                onChange={(e) => setDraftName(e.target.value)}
                onBlur={() => {
                  handleUpdateFolderName(id, draftName);
                  setIsEditingName(false);
                }}
                onEnter={() => {
                  handleUpdateFolderName(id, draftName);
                  setIsEditingName(false);
                }}
                ref={inputRef}
              />
            </div>
          </div>
        ) : (
          <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <GroupHeader
              className="bg-default sticky top-0 z-10 pl-2 pr-px group/folder-header w-full"
              contentClassName="min-w-0"
              hideEndEnhancerOnGroupClosed={false}
              startEnhancer={
                <animated.div
                  className={twMerge("p-0.5", !hasElements && "block")}
                  style={hasElements ? hideOnHoverStyle : undefined}
                >
                  <BsFolder size={12} className="shrink-0" />
                </animated.div>
              }
              endEnhancer={
                <div
                  className={twMerge(
                    // Note (Evan, 2024-12-09): We have to do this dance with the w-0 because just doing "hidden" breaks Radix's
                    // ability to place the menu items.
                    "gap-0 flex group-hover/folder-header:visible group-hover/folder-header:w-auto",
                    // Note (Evan, 2024-12-09): We always want the end enhancer to be visible when the menu is open
                    areQuickActionsOpen ? "visible w-auto" : "invisible w-0",
                  )}
                >
                  <Menu
                    items={menuItems}
                    isOpen={areQuickActionsOpen}
                    onRequestClose={() => setAreQuickActionsOpen(false)}
                    trigger={
                      <MenuTrigger asChild>
                        <IconButton
                          icon={
                            <HiDotsHorizontal
                              size={10}
                              className="text-muted"
                            />
                          }
                          className={twMerge("items-center justify-center p-0")}
                          variant="tertiary"
                          onClick={() => setAreQuickActionsOpen(true)}
                          tooltipText="More"
                        />
                      </MenuTrigger>
                    }
                    disableTriggerFocusOnClose
                  />
                  <GroupHeaderActionButton
                    aria-label={actionLabel}
                    onClick={() => {
                      openModal({
                        type: "createElementModal",
                        props: {
                          initialElementType: elementType,
                          initialTemplate: getEmptyTemplate(elementType),
                          folderId: folder.id,
                        },
                      });
                    }}
                  >
                    <div data-testid={`${name}-${id}-add`}>
                      <IconButton
                        icon={<BsPlus size={20} className="text-muted" />}
                        tooltipText={actionLabel}
                        className="items-center justify-center p-0 h-6 w-6"
                        variant="tertiary"
                        // NOTE (Chance, 2023-11-08): This is purely decorative, as
                        // `GroupHeaderActionButton` will render the button element
                        isPhonyButton
                      />
                    </div>
                  </GroupHeaderActionButton>
                </div>
              }
            >
              <GroupTitleContainer
                className="h-8 w-full"
                collapsibleIndicator={
                  <animated.div
                    style={!isCollapsed ? showOnHoverStyle : undefined}
                    className={twMerge(
                      !isCollapsed && "-rotate-90",
                      isCollapsed && "block",
                    )}
                  >
                    <GroupCollapsibleIndicator size="large" />
                  </animated.div>
                }
              />
            </GroupHeader>
          </div>
        )
      }
    >
      <div className="flex flex-col gap-1">
        {elements.map((element) => (
          <Menu
            key={element.id}
            items={getMenuItemsForElementItems({
              element,
              currentFolder: folder,
              allFolders,
              handleDeletion,
              handleDuplication,
              handleSupportDuplication,
              handleEditPage,
              handleMoveToFolder,
              isDebugMode,
            })}
            menuType="context"
            customWidth="auto"
            trigger={
              <ElementItem
                element={element}
                folder={folder}
                onClick={(element) => {
                  saveLastSelectedElementIdForTypeForStore(
                    element.projectId,
                    element.type,
                    element.id,
                  );
                  onClick(element);
                }}
              />
            }
          />
        ))}
      </div>
    </Group>
  );
};

export function getMenuItemsForElementItems(params: {
  element: ReploElementMetadata;
  currentFolder: Folder | null;
  allFolders: Folder[];
  handleDeletion: (element: ReploElementMetadata) => void;
  handleDuplication: (element: ReploElementMetadata) => void;
  handleSupportDuplication: (element: ReploElementMetadata) => void;
  handleEditPage: (element: ReploElementMetadata) => void;
  handleMoveToFolder: (
    element: ReploElementMetadata,
    folder: Folder | null,
  ) => void;
  isDebugMode?: boolean;
}): MenuItem[] {
  const { element } = params;
  const menuItems: MenuItem[] = [
    {
      type: "leaf",
      id: "edit",
      title: `Edit ${
        elementTypeToEditorData[element.type].singularDisplayName
      } Settings`,
      onSelect: () => params.handleEditPage(element),
      isDisabled: false,
    },
    {
      type: "leaf",
      id: "rename",
      title: `Rename ${
        elementTypeToEditorData[element.type].singularDisplayName
      }`,
      onSelect: () => params.handleEditPage(element),
      isDisabled: false,
    },
    {
      type: "leaf",
      id: "duplicate",
      title: `Duplicate ${
        elementTypeToEditorData[element.type].singularDisplayName
      }`,
      onSelect: () => params.handleDuplication(element),
      isDisabled: false,
    },
    {
      type: "leaf",
      id: "delete",
      title: `Delete ${
        elementTypeToEditorData[element.type].singularDisplayName
      }`,
      onSelect: () => params.handleDeletion(element),
      isDisabled: false,
    },
  ];

  if (params.isDebugMode) {
    menuItems.push({
      type: "leaf",
      id: "duplicate.support",
      title: `Duplicate | Support Copy`,
      onSelect: () => params.handleSupportDuplication(element),
      isDisabled: false,
    });
  }

  const suitableFolders = params.allFolders.filter(
    (folder) =>
      folder.kind === element.type && folder.id !== params.currentFolder?.id,
  );

  if (suitableFolders.length > 0) {
    menuItems.push({
      type: "nested",
      title: `Move ${
        elementTypeToEditorData[element.type].singularDisplayName
      } To Folder`,
      items: suitableFolders.map((folder) => {
        return {
          type: "leaf",
          id: folder.id,
          title: folder.name,
          onSelect: () => params.handleMoveToFolder(element, folder),
          isDisabled: false,
        };
      }),
    });
  }

  if (params.currentFolder) {
    menuItems.push({
      type: "leaf",
      id: "remove.from.folder",
      title: "Remove from Folder",
      onSelect: () => params.handleMoveToFolder(element, null),
      isDisabled: false,
    });
  }
  return menuItems;
}

export function useGetElementsByCurrentElementType() {
  const elementType = useEditorSelector(selectDraftElementType);
  const [currentElementType, setCurrentElementType] =
    useOverridableState<ReploElementType>(elementType);
  const elements = useEditorSelector((state: EditorRootState) => {
    return selectElementMetadata(state, currentElementType);
  }, isEqual);

  const groupedElementsByFolderName = React.useMemo(
    () =>
      groupBy(
        elements.map(mapReploElementToReploElementMetadata),
        getElementFolderName,
      ) as Record<string, ReploElementMetadata[]>,
    [elements],
  );

  return {
    elements,
    currentElementType,
    setCurrentElementType,
    currentPaneInfo: paneInfo[currentElementType],
    groupedElementsByFolderName,
  };
}

function useSearchableItems(elements: ReploElement[]) {
  const [searchTerm, setSearchTerm] = React.useState("");

  const filteredElements = elements.filter((element) => {
    return element.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const groupedFilteredElementsByFolderName = React.useMemo(
    () =>
      groupBy(
        filteredElements.map(mapReploElementToReploElementMetadata),
        getElementFolderName,
      ) as Record<string, ReploElementMetadata[]>,
    [filteredElements],
  );

  return {
    filteredElements,
    searchTerm,
    setSearchTerm,
    groupedFilteredElementsByFolderName,
  };
}

export function usePageModificationHandlers() {
  const store = useEditorStore();
  const openModal = useOpenModal();
  const dispatch = useEditorDispatch();
  const { elements } = useGetElementsByCurrentElementType();
  const { filteredElements } = useSearchableItems(elements);

  const handleDeletion = (metadata: ReploElementMetadata) => {
    const element = selectElementById(
      store.getState(),
      metadata.id,
    ) as ReploElement;
    openModal({
      type: "deleteElementConfirmationModal",
      props: { type: "delete", element, elements: filteredElements },
    });
  };

  const handleDuplication = (metadata: ReploElementMetadata) => {
    const element = selectElementById(store.getState(), metadata.id);
    openModal({
      type: "duplicateElementModal",
      props: {
        element,
      },
    });
  };

  const handleSupportDuplication = (metadata: ReploElementMetadata) => {
    const element = selectElementById(store.getState(), metadata.id);
    openModal({
      type: "duplicateElementModal",
      props: {
        element,
        isSupportMode: true,
      },
    });
  };

  const handleEditPage = (metadata: ReploElementMetadata) => {
    const element = selectElementById(store.getState(), metadata.id);
    openModal({
      type: "updateElementModal",
      props: {
        element,
      },
    });
  };

  const handleMoveToFolder = (
    metadata: ReploElementMetadata,
    folder: Folder | null,
  ) => {
    const element = selectElementById(
      store.getState(),
      metadata.id,
    ) as ReploElement;
    return dispatch(
      updateAndSaveElement(element.id, {
        ...element,
        folderId: folder?.id ?? null,
      }),
    );
  };

  return {
    handleDeletion,
    handleDuplication,
    handleSupportDuplication,
    handleEditPage,
    handleMoveToFolder,
  };
}

const useCreateNewFolder = () => {
  const utils = trpc.useUtils();
  const projectId = useCurrentProjectId();
  const { mutate: createFolderMutate } = trpc.folder.create.useMutation({
    onMutate: async ({ projectId, kind, name }) => {
      // Note (Evan, 2024-12-06): Cancel outgoing refetches
      await utils.folder.list.cancel();

      // Capture the previous value to return as context
      const previousFolders = utils.folder.list.getData({ projectId });

      // Optimistically update the cache
      // Note (Evan, 2024-12-06): This should be defined, this is a check for TS's sake
      if (previousFolders) {
        utils.folder.list.setData({ projectId }, () => ({
          folders: [
            { id: "new", name, kind, createdAt: new Date() },
            ...previousFolders.folders,
          ],
        }));
      }

      return { previousFolders };
    },
    onError: (_err, { projectId }, context) => {
      if (context?.previousFolders) {
        utils.folder.list.setData({ projectId }, context.previousFolders);
      }
    },
    onSettled: (_data, _error, { projectId }) => {
      void utils.folder.list.invalidate({ projectId });
    },
  });

  const createNewFolder = (kind: ReploElementType, name: string) => {
    if (projectId) {
      createFolderMutate({ projectId, kind, name });
    }
  };

  return createNewFolder;
};

const useFolderModificationHandlers = () => {
  const utils = trpc.useUtils();
  const projectId = useCurrentProjectId();
  const { mutate: updateFolderMutate } = trpc.folder.update.useMutation({
    onMutate: async ({ projectId, folderId, name }) => {
      // Note (Evan, 2024-12-06): Cancel outgoing refetches
      await utils.folder.list.cancel();

      // Capture the previous value to return as context
      const previousFolders = utils.folder.list.getData({ projectId });

      // Optimistically update the cache
      // Note (Evan, 2024-12-06): This should be defined, this is a check for TS's sake
      if (previousFolders) {
        utils.folder.list.setData({ projectId }, () => ({
          folders: previousFolders.folders.map((folder) =>
            folder.id === folderId ? { ...folder, name } : folder,
          ),
        }));
      }

      return { previousFolders };
    },
    onError: (_err, { projectId }, context) => {
      if (context?.previousFolders) {
        utils.folder.list.setData({ projectId }, context.previousFolders);
      }
    },
    onSettled: (_data, _error, { projectId }) => {
      void utils.folder.list.invalidate({ projectId });
    },
  });

  const handleUpdateFolderName = (folderId: string, name: string) => {
    if (projectId) {
      updateFolderMutate({ projectId, folderId, name });
    }
  };

  const { mutate: deleteFolderMutate } = trpc.folder.remove.useMutation({
    onMutate: async ({ projectId, folderId }) => {
      // Note (Evan, 2024-12-06): Cancel outgoing refetches
      await utils.folder.list.cancel();

      // Capture the previous value to return as context
      const previousFolders = utils.folder.list.getData({ projectId });

      // Optimistically update the cache
      // Note (Evan, 2024-12-06): This should be defined, this is a check for TS's sake
      if (previousFolders) {
        utils.folder.list.setData({ projectId }, () => ({
          folders: previousFolders.folders.filter(
            (folder) => folder.id !== folderId,
          ),
        }));
      }

      return { previousFolders };
    },
    onError: (_err, { projectId }, context) => {
      if (context?.previousFolders) {
        utils.folder.list.setData({ projectId }, context.previousFolders);
      }
    },
    onSettled: (_data, _error, { projectId }) => {
      void utils.folder.list.invalidate({ projectId });
    },
  });

  const handleDeleteFolder = (folderId: string) => {
    if (projectId) {
      deleteFolderMutate({ projectId, folderId });
    }
  };

  return {
    handleUpdateFolderName,
    handleDeleteFolder,
  };
};

export default ElementsPane;
