export function getAbsoluteOffsetY(element: Element | null) {
  let currentElement = element;
  let currentOffset = 0;
  while (currentElement != null) {
    currentOffset += (currentElement as HTMLElement).offsetTop ?? 0;
    currentElement = (currentElement as HTMLElement).offsetParent;
  }
  return currentOffset;
}

export { observeRect, type RectProps } from "./observe-rect";
