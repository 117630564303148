import type { User } from "replo-runtime/shared/types";

import * as React from "react";

import "@editor/reducers/core-reducer";
import "@editor/store";
import "@editor/utils/localStorage";

import { initUserBasedAnalytics } from "@editor/infra/analytics";
import { storeToken } from "@editor/reducers/utils/store-token";
import { trpc } from "@editor/utils/trpc";

type CurrentUserContext = {
  user: User | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
  isError: boolean;
};

export const CurrentUserContext = React.createContext<CurrentUserContext>({
  user: undefined,
  isAuthenticated: false,
  isLoading: false,
  isError: false,
});

export const CurrentUserProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { user, isAuthenticated, isLoading, isError } = useFetchCurrentUser();
  return (
    <CurrentUserContext.Provider
      value={{
        user,
        isAuthenticated,
        isLoading,
        isError,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

function useFetchCurrentUser() {
  const {
    data: user,
    isLoading,
    isError,
    isSuccess,
  } = trpc.user.get.useQuery({});
  const userIsInitialized = React.useRef(false);

  React.useEffect(() => {
    // Note (Noah, 2024-08-21): Only initialize the user-based analytics once
    // throughout the lifetime of the page, since we don't want to re-initialize
    // if multiple components are using this hook.
    if (isSuccess && user && !userIsInitialized.current) {
      initUserBasedAnalytics(user);
      userIsInitialized.current = true;
    }
  }, [isSuccess, user]);

  React.useEffect(() => {
    if (isError) {
      void storeToken(null);
    }
  }, [isError]);

  return {
    user: user ?? undefined,
    isAuthenticated: Boolean(user?.id),
    isLoading,
    isError,
  };
}
