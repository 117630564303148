import * as React from "react";

/**
 * Hook which registers an intersection observer which calls the provided callback
 * the first time the element referenced by the provided ref is visible in the viewport.
 */
function useOnFirstViewportIntersection(
  ref: React.MutableRefObject<Element | null>,
  onIntersect: () => void,
  intersectionObserverOptions?: {
    root?: Element | null;
    rootMargin?: string;
    threshold?: number | number[];
  },
  hookOptions?: { skip?: boolean },
): void {
  const hasIntersected = React.useRef(false);

  React.useEffect(() => {
    // Don't fire if the node is not yet present or if the intersection has already occurred
    if (!ref.current || hasIntersected.current || hookOptions?.skip) {
      return;
    }

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !hasIntersected.current) {
          hasIntersected.current = true;
          onIntersect();
          observer.disconnect(); // No longer need to observe since we only want this once
        }
      });
    };

    const observer = new IntersectionObserver(
      handleIntersection,
      intersectionObserverOptions,
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, onIntersect, intersectionObserverOptions, hookOptions?.skip]);
}

export default useOnFirstViewportIntersection;
