import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const collapsibleV2: ComponentTemplate = {
  env: Env.PRODUCTION,
  storeId: null,
  scope: "left-bar",
  type: "component",
  id: "collapsibleV2",
  name: "Component/Collapsible",
  leftBarCategory: { main: "interactive", subCategory: "toggles" },
  preview: "component/collapsible.svg",
  template: {
    id: "3213dd5d-8972-48dd-9da7-8327eba4adc7",
    name: "Collapsible 1",
    type: "collapsibleV2",
    props: {
      style: {
        display: "flex",
        flexGrow: 1,
        alignSelf: "stretch",
        flexDirection: "column",
      },
      _openComponent: {
        id: "0d19879c-d826-43a7-84ca-49728e5850b3",
        name: "Collapsible Header",
        type: "collapsibleV2Header",
        props: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingTop: "12px",
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingBottom: "12px",
            justifyContent: "space-between",
            borderBottomColor: "#000000",
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
            __animateVariantTransitions: true,
          },
          onClick: [
            {
              id: "dc58aacb-f9ad-4653-b7ec-ef8c5cddbf86",
              type: "toggleCollapsible",
              value: null,
            },
          ],
        },
        children: [
          {
            id: "1e06011e-4413-4295-b39c-996a7053163d",
            type: "text",
            props: {
              text: "<p>Click To Open</p>",
              style: {
                color: "black",
                fontSize: "16px",
                lineHeight: "20px",
              },
            },
          },
          {
            id: "e8835357-8b6a-446a-8922-db7fc739c5b4",
            type: "icon",
            props: {
              style: {
                width: "24px",
                height: "24px",
              },
              iconName: "chevronDown",
            },
          },
        ],
        variants: [
          {
            id: "e5cee377-6321-4372-a729-ad9ee4abb05d",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "2cfefddd-fecd-419c-95e9-cf9a4e26e975",
            name: "Collapsible Open",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "cf57cf8a-9404-42ac-8c0b-79f432c55087",
                  field: "state.collapsibleV2.isOpen",
                  value: null,
                  operator: null,
                },
              ],
            },
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "2cfefddd-fecd-419c-95e9-cf9a4e26e975": {
            componentOverrides: {
              "e8835357-8b6a-446a-8922-db7fc739c5b4": {
                props: {
                  iconName: "chevron-up",
                },
              },
            },
          },
        },
      },
      _collapsibleComponent: {
        id: "ff7a7f06-7a3c-4701-ae85-d8a305a3916a",
        name: "Collapsible Content",
        type: "collapsibleV2Content",
        props: {
          style: {
            display: "flex",
            paddingTop: "12px",
            paddingLeft: "12px",
            paddingRight: "12px",
            flexDirection: "column",
            paddingBottom: "12px",
          },
        },
        children: [
          {
            id: "551a2f36-2373-45e1-87de-c60567864ba5",
            type: "text",
            props: {
              text: "<p>This content will be hidden until the user opens the collapsible. You can replace this content with whatever you feel should be hidden.</p>",
              style: {
                color: "black",
                width: "100%",
                __width: "100%",
                fontSize: "14px",
                lineHeight: "24px",
              },
            },
          },
        ],
      },
    },
  },
};
