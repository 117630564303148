import { getHexColor } from "@editor/components/common/designSystem/utils/colors";

import { isNotNullish } from "replo-utils/lib/misc";

// Test for #hex colors
export const colorStringRegex = new RegExp(/^#(?:[\dA-Fa-f]{3,4}){1,2}$/);

// Return uppercase color if it matches the color string regex
export const getFormattedColor = (color: string): string => {
  if (color && colorStringRegex.test(color)) {
    return color.toUpperCase();
  }

  return color;
};

export const getFormattedColorWithoutOpacity = (
  color?: string | null,
): string | null | undefined => {
  if (color && colorStringRegex.test(color)) {
    return getHexColor(color).toUpperCase();
  }
  return color;
};

export const getFormattedOpacity = (opacity: number | undefined) => {
  if (isNotNullish(opacity)) {
    return `${(opacity * 100).toFixed(0)}%`;
  }
  return "100%";
};
