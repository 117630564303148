import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { SharedReviewsIoReviews } from "../SharedReviewsIoReviews";

const ReviewsIoReviewsWidget: React.FC<RenderComponentProps> = (props) => {
  const html = `<div id="ReviewsWidget-${props.component.id}"></div>`;
  return (
    <SharedReviewsIoReviews
      attributes={props.componentAttributes}
      component={props.component}
      context={props.context}
      htmlContent={html}
    />
  );
};

export default ReviewsIoReviewsWidget;
