import * as React from "react";

import ErrorMessage from "@editor/components/account/Dashboard/ErrorMessage";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import {
  selectDraftComponentId,
  selectObjectFit,
  selectObjectPosition,
  selectPropLoading,
  selectPropPoster,
  selectPropUrl,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import ModifierGroup from "@editorExtras/ModifierGroup";
import VideoSourceIndicator from "@editorModifiers/VideoSourceIndicator";

import { isValidHttpUrl } from "replo-utils/lib/url";

import ModifierLabel from "../extras/ModifierLabel";

const VideoSourceModifier: React.FC<React.PropsWithChildren<unknown>> = () => {
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const applyComponentAction = useApplyComponentAction();
  const objectFit = useEditorSelector(selectObjectFit);
  // Note (Noah, 2024-03-06): Fine to cast this as string here since we never support
  // setting numbers as object position
  const rawObjectPosition = useEditorSelector(selectObjectPosition) as
    | string
    | null;
  const value = useEditorSelector(selectPropUrl);
  const posterSrc = useEditorSelector(selectPropPoster);
  const loading = useEditorSelector(selectPropLoading);

  const objectPosition = rawObjectPosition?.split(" ");
  // NOTE (Sebas, 2024-04-30): If the value is null, we should consider it as a valid URL
  // to avoid showing the error message.
  const isValidUrl = value ? isValidHttpUrl(value) : true;
  return (
    <ModifierGroup title="Video Source">
      <div data-testid="video-source-modifier" className="flex items-center">
        <ModifierLabel label="Video URL" />
        <VideoSourceIndicator
          src={value}
          assetLoadingValue={loading ?? "eager"}
          posterSrc={posterSrc ?? undefined}
          objectFitValue={objectFit}
          objectPositionValues={{
            x: objectPosition?.[0],
            y: objectPosition?.[1],
          }}
          onChangeObjectFit={(value) => {
            applyComponentAction({
              type: "setStyles",
              value: { objectFit: value },
            });
          }}
          onChangeObjectPositionX={(value: string) => {
            applyComponentAction({
              type: "setStyles",
              value: {
                objectPosition: `${value} ${objectPosition?.[1] || "50%"}`,
              },
            });
          }}
          onChangeObjectPositionY={(value: string) => {
            applyComponentAction({
              type: "setStyles",
              value: {
                objectPosition: `${objectPosition?.[0] || "50%"} ${value}`,
              },
            });
          }}
          onRemove={() => {
            applyComponentAction({
              type: "setProps",
              value: { url: "" },
            });
          }}
          onChangeAssetLoading={(value) => {
            applyComponentAction({
              type: "setProps",
              value: { loading: value },
            });
          }}
          onChangePoster={(value) => {
            applyComponentAction({
              type: "setProps",
              value: { poster: value },
            });
          }}
          componentId={draftComponentId ?? undefined}
        />
      </div>
      {!isValidUrl ? (
        <ErrorMessage error="Please enter a valid URL" className="mt-1" />
      ) : null}
    </ModifierGroup>
  );
};
export default VideoSourceModifier;
