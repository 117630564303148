import * as React from "react";

export const GatheringData = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-2 min-h-[400px]">
      <div className="flex flex-col items-center justify-center gap-1">
        <div className="text-default text-base font-medium">
          <span className="relative flex m-8 h-10 w-10 duration-1000 justify-center items-center">
            <span className="animate-ping-slow absolute inline-flex h-full w-full rounded-full bg-blue-300 opacity-75"></span>
            <div className="pl-3 pt-3 rounded-full h-4 w-4 bg-blue-600 z-10"></div>
          </span>
        </div>
        <div className="text-default text-lg font-semibold text-default">
          Gathering data...
        </div>
        <div className="text-slate-500 text-sm max-w-[400px] text-center">
          As visitors start exploring your store, you’ll see real-time data
          populate this dashboard. Check back soon!
        </div>
      </div>
    </div>
  );
};
