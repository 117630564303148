import type { SetCandidateNodePayload } from "@editor/reducers/candidate-reducer";
import type { CanvasOffset } from "@providers/DragAndDropProvider";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";

import * as React from "react";

import { HEADER_HEIGHT } from "@components/editor/constants";
import useCurrentDragType from "@editor/hooks/useCurrentDragType";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  selectCandidateNode,
  selectComponentIdToDrag,
  selectComponentNodeToDrag,
  selectLastCandidateComponentId,
  selectLastCandidateRepeatedIndex,
  setCandidateNode as setCandidateNodeAction,
} from "@editor/reducers/candidate-reducer";
import {
  selectDraftComponentIds,
  selectDraftRepeatedIndex,
} from "@editor/reducers/core-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { closestElementToPoint } from "@editor/utils/dom";
import useDragAndDrop from "@providers/DragAndDropProvider";

import { ElementRootAttribute } from "replo-runtime/shared/constants";
import {
  fullPageQuerySelector,
  modalMountPointQuerySelector,
  shopifyAnnouncementBarSelector,
  shopifyFooterSelector,
  shopifyHeaderSelector,
} from "replo-runtime/store/utils/cssSelectors";
import { isCloseTo } from "replo-utils/lib/math";

import {
  CANVAS_FRAME_GAP,
  OFFSET_LIMIT,
  REPLO_COMPONENT_SELECTOR,
} from "./canvas-constants";
import {
  selectActiveCanvasWidth,
  selectCanvasDeltaXY,
  selectCanvasFrameWidths,
  selectCanvasInteractionMode,
  selectCanvasScale,
  selectVisibleCanvases,
} from "./canvas-reducer";

function firstReploElementFromPoint(
  canvasDocument: Document,
  x: number,
  y: number,
): HTMLElement | null {
  // NOTE (Matt 2024-12-02): The reason we do this instead of using
  // elementFromPoint is because the BoundingBoxes are now in the same
  // rendering context as the replo components, so elementFromPoint would
  // just always spit back a BoundingBox.
  const elements: HTMLElement[] = canvasDocument.elementsFromPoint(
    x,
    y,
  ) as HTMLElement[];
  return elements.find((element) => element.dataset.rid)!;
}

export const useSetCandidateNodeFromPoint = () => {
  const { currentDragType } = useCurrentDragType();
  const { setOffset } = useDragAndDrop();
  const dispatch = useEditorDispatch();
  const store = useEditorStore();
  const isNoMirrorEnabled = isFeatureEnabled("no-mirror");

  const setCandidateNode = React.useCallback(
    (
      newCandidateNode: HTMLElement | null,
      candidateCanvas: EditorCanvas | null,
    ) => {
      const lastCandidateComponentId = selectLastCandidateComponentId(
        store.getState(),
      );
      const lastCandidateRepeatedIndex = selectLastCandidateRepeatedIndex(
        store.getState(),
      );
      const { deltaX, deltaY } = selectCanvasDeltaXY(store.getState());
      const canvasInteractionMode = selectCanvasInteractionMode(
        store.getState(),
      );
      const isDragging = canvasInteractionMode === "dragging-components";
      const canvasScale = selectCanvasScale(store.getState());
      if (newCandidateNode === null) {
        const payload: SetCandidateNodePayload = {
          candidateNode: null,
          lastCandidateComponentId: null,
          lastCandidateRepeatedIndex: null,
          candidateCanvas: null,
        };
        if (!isDragging) {
          payload.componentIdToDrag = null;
          payload.componentNodeToDrag = null;
        }
        dispatch(setCandidateNodeAction(payload));
      } else {
        const componentId = newCandidateNode?.dataset.rid ?? null;
        const repeatedIndex = newCandidateNode?.getAttribute(
          "data-replo-repeated-index",
        );

        // Only update the candidate node if it's different from the last one.
        if (
          componentId === lastCandidateComponentId &&
          repeatedIndex === lastCandidateRepeatedIndex
        ) {
          return;
        }

        setOffset?.((offset: CanvasOffset | null) => ({
          x: deltaX,
          y: deltaY + HEADER_HEIGHT,
          scale: canvasScale,
          canvasHeight: offset?.canvasHeight || 0,
          canvasWidth: offset?.canvasHeight || 0,
        }));

        const payload: SetCandidateNodePayload = {
          candidateNode: newCandidateNode,
          candidateCanvas,
          lastCandidateComponentId: componentId,
          lastCandidateRepeatedIndex: repeatedIndex,
        };
        if (!isDragging) {
          payload.componentIdToDrag = componentId;
          payload.componentNodeToDrag = newCandidateNode;
        }
        dispatch(setCandidateNodeAction(payload));
      }
    },
    [dispatch, setOffset, store],
  );

  const setCandidateNodeFromPoint = React.useCallback(
    (clientX: number, clientY: number) => {
      const { deltaX, deltaY } = selectCanvasDeltaXY(store.getState());
      const activeCanvasWidth = selectActiveCanvasWidth(store.getState());
      const visibleCanvasesFrameWidth = selectCanvasFrameWidths(
        store.getState(),
      );
      const canvasScale = selectCanvasScale(store.getState());
      const canvasInteractionMode = selectCanvasInteractionMode(
        store.getState(),
      );
      const visibleCanvases = selectVisibleCanvases(store.getState());
      const isDragging = canvasInteractionMode === "dragging-components";
      const targetIframe = closestElementToPoint(
        Array.from(document.querySelectorAll("[data-canvas-id]")).filter(
          (iframe) => {
            // Note (Noah, 2024-07-08): We only want to consider iframes that apply
            // to currently visible canvases. This is because even if a canvas is
            // hidden, its iframe might still exist.
            return Object.keys(visibleCanvases).includes(
              (iframe as HTMLElement).dataset.canvasId as EditorCanvas,
            );
          },
        ) as HTMLElement[],
        clientX,
        clientY,
      );
      if (!targetIframe) {
        return;
      }
      const targetFrameDocument = isNoMirrorEnabled
        ? window.document
        : (targetIframe as HTMLIFrameElement).contentDocument!;
      const frameNodeAtPoint = firstReploElementFromPoint(
        targetFrameDocument,
        clientX,
        clientY,
      );

      if (frameNodeAtPoint?.closest(".ignore-frame-cursor")) {
        return;
      }

      const candidateCanvas = targetIframe.dataset.canvasId;

      if (isNoMirrorEnabled) {
        const closestComponent = frameNodeAtPoint?.closest(
          REPLO_COMPONENT_SELECTOR,
        ) as HTMLElement | null;
        setCandidateNode(
          closestComponent,
          candidateCanvas as EditorCanvas | null,
        );
        return closestComponent;
      }

      let xOffset = 0;
      for (const [key, visibleCanvas] of Object.entries(visibleCanvases)) {
        if (key === candidateCanvas) {
          break;
        }
        const width = isFeatureEnabled("no-mirror")
          ? visibleCanvasesFrameWidth[key as EditorCanvas]
          : visibleCanvas.canvasWidth;
        xOffset += width + CANVAS_FRAME_GAP;
      }

      const x = (clientX - deltaX - xOffset * canvasScale) / canvasScale;
      const y = (clientY - deltaY - HEADER_HEIGHT) / canvasScale;

      // Note (Noah, 2023-10-01): Whether the user is dragging something
      // (a component, template, etc). Used since we calculate the target
      // element slightly differently, to make it easier to drag things
      // in certain situations like dragging components onto the very edges
      // of nested containers, etc
      const isDraggingSomething = isDragging || Boolean(currentDragType);

      const newCandidateNode = getCandidateNodeFromDocument({
        x,
        y,
        frameWidth: activeCanvasWidth,
        offsetLimit: isDraggingSomething ? OFFSET_LIMIT : 0,
        allowCloseToHeaderOrFooter: isDraggingSomething,
        selectParentNodeNearTopAndBottomBoundaries: isDraggingSomething,
        targetFrameDocument,
      });

      setCandidateNode(
        newCandidateNode,
        candidateCanvas as EditorCanvas | null,
      );
      return newCandidateNode;
    },
    [currentDragType, setCandidateNode, store, isNoMirrorEnabled],
  );
  return {
    setCandidateNodeFromPoint,
    setCandidateNode,
  };
};

export function useCandidateNode() {
  const draftComponentIds = useEditorSelector(selectDraftComponentIds);
  const draftComponentRepeatedIndex = useEditorSelector(
    selectDraftRepeatedIndex,
  );
  const candidateNode = useEditorSelector(selectCandidateNode);
  const componentIdToDrag = useEditorSelector(selectComponentIdToDrag);
  const componentNodeToDrag = useEditorSelector(selectComponentNodeToDrag);
  const lastCandidateRepeatedIndex = useEditorSelector(
    selectLastCandidateRepeatedIndex,
  );

  const { setCandidateNodeFromPoint } = useSetCandidateNodeFromPoint();

  // Note (Noah, 2023-06-18): Show the candidate box for non-draft components if
  // the user hovers over them. This box allows the user to start a drag of a
  // non-draft component. We don't show this box for draft components because
  // <DraftBox /> renders one internally.
  // NOTE (Martin 2024-11-25): Since we are using draft components for multi-selection
  // now, we only want to apply conditional logic to show candidate boxes if there's
  // a single component being selected.
  let isCandidateBoxVisible = true;
  if (draftComponentIds.length === 1) {
    isCandidateBoxVisible = componentIdToDrag
      ? !draftComponentIds.includes(componentIdToDrag)
      : false;
  }

  const shouldShowCandidateBox =
    (componentNodeToDrag && isCandidateBoxVisible) ||
    (lastCandidateRepeatedIndex &&
      draftComponentRepeatedIndex &&
      lastCandidateRepeatedIndex !== draftComponentRepeatedIndex);

  return {
    candidateNode,
    setCandidateNodeFromPoint,
    shouldShowCandidateBox,
  };
}

export function getCandidateNodeFromDocument({
  x,
  y,
  offsetLimit,
  allowCloseToHeaderOrFooter,
  selectParentNodeNearTopAndBottomBoundaries,
  targetFrameDocument,
}: {
  x: number;
  y: number;
  offsetLimit: number;
  frameWidth: number;
  allowCloseToHeaderOrFooter: boolean;
  selectParentNodeNearTopAndBottomBoundaries: boolean;
  targetFrameDocument: Document | null;
}): HTMLElement | null {
  // Note (Noah, 2024-08-13, REPL-13103): iframe loading is async, so even if we
  // have a target frame document, we might not have an element to query in that
  // document yet. If we don't, just return null.
  if (!targetFrameDocument || !targetFrameDocument.documentElement) {
    return null;
  }

  const page = targetFrameDocument.querySelector(`[${ElementRootAttribute}]`);
  const pageBounds = page?.getBoundingClientRect() || {
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  };

  let positionX = x;
  let positionY = y;

  let nodeAtPoint: HTMLElement | null = firstReploElementFromPoint(
    targetFrameDocument,
    x,
    y,
  );

  const style = window.getComputedStyle(targetFrameDocument.documentElement);
  const height = Number.parseInt(style.getPropertyValue("height"), 10);
  const width = Number.parseInt(style.getPropertyValue("width"), 10);

  const footerHeight =
    targetFrameDocument
      ?.querySelector(shopifyFooterSelector)
      ?.getBoundingClientRect().height || 0;
  const headerHeight =
    targetFrameDocument
      ?.querySelector(shopifyHeaderSelector)
      ?.getBoundingClientRect().height || 0;
  const announcementBarHeight =
    targetFrameDocument
      ?.querySelector(shopifyAnnouncementBarSelector)
      ?.getBoundingClientRect().height || 0;

  const headerOffset = headerHeight + announcementBarHeight;
  const heightUptoFooter = height - footerHeight;
  const isOnTop = y - headerOffset < 0 && y - headerOffset > offsetLimit * -1;
  const isOnBottom = y > heightUptoFooter && y < heightUptoFooter + offsetLimit;
  const isOnLeft = x < 0 && x > offsetLimit * -1;
  const isOnRight = x > width && x < width + offsetLimit;
  const isOutsideElementWithinTolerance =
    isOnTop || isOnBottom || isOnLeft || isOnRight;

  // Note (Ovishek, 2022-08-23) if not inside the iframe, check if it's just around it
  // considering the offsetLimit if so, get the closest node at the point
  if (isOutsideElementWithinTolerance) {
    if (isOnBottom) {
      positionY = heightUptoFooter - 1;
    } else if (isOnTop) {
      positionY = pageBounds.y;
    }

    if (isOnLeft) {
      positionX = pageBounds.x + 1;
    } else if (isOnRight) {
      positionX = pageBounds.width - 1;
    }

    nodeAtPoint = firstReploElementFromPoint(
      targetFrameDocument,
      positionX,
      positionY,
    );
    if (isOnTop) {
      nodeAtPoint = targetFrameDocument.querySelector(REPLO_COMPONENT_SELECTOR);
    }
  }

  let candidateNode = nodeAtPoint?.closest(REPLO_COMPONENT_SELECTOR);

  if (!candidateNode && allowCloseToHeaderOrFooter) {
    const isCloserToFooter = Math.abs(y - height) < Math.abs(y - offsetLimit);

    // if we don't have a candidate node, check if we are over the footer
    // if so, try to find the closest component from there
    if (isCloserToFooter) {
      const footer = nodeAtPoint?.closest(shopifyFooterSelector);
      if (footer) {
        const elementAboveFooter = firstReploElementFromPoint(
          targetFrameDocument,
          x,
          pageBounds.height,
        );

        const closestToFooter = elementAboveFooter?.closest(
          REPLO_COMPONENT_SELECTOR,
        );

        if (closestToFooter) {
          positionY = heightUptoFooter - 1;
          candidateNode = closestToFooter;
        }
      }
    } else {
      // if we are not on the footer
      // try to find the closest component from the header
      const header = nodeAtPoint?.closest(shopifyHeaderSelector);
      if (header) {
        positionX = x;
        positionY = pageBounds.y + 1;
        const elementAboveHeader = firstReploElementFromPoint(
          targetFrameDocument,
          positionX,
          positionY,
        );
        const closestToHeader = elementAboveHeader?.closest(
          REPLO_COMPONENT_SELECTOR,
        );
        const page = targetFrameDocument
          ?.querySelector(`[${ElementRootAttribute}]`)
          ?.closest(REPLO_COMPONENT_SELECTOR);
        if (closestToHeader && page) {
          candidateNode = page;
        }
      }
    }
  }

  const parentNode = candidateNode?.parentElement?.closest(
    REPLO_COMPONENT_SELECTOR,
  );
  const parentOffsetLimit = 10;
  if (parentNode) {
    const parentBounds = parentNode.getBoundingClientRect();
    if (
      // Note (Noah, 2023-07-23, REPL-3701): If we're over a child node but
      // really close to the top/bottom of the parent node, choose the parent
      // node instead. This makes it easier to drag things below a container
      // which is made up of multiple children where each child's bounding box's
      // bottom is the same as the parent's bounding box's bottom (common case
      // for things like two-column product layouts).
      //
      // We specifically DON'T do this for left/right, because it's more prone
      // to making small components inside complicated layouts hard to select
      (isCloseTo(parentBounds.top, positionY, parentOffsetLimit) ||
        isCloseTo(parentBounds.bottom, positionY, parentOffsetLimit)) &&
      // Note (Noah, 2023-10-01, REPL-8600): However, don't do this unless we've
      // specified to (which happens when we're not dragging anything). If we're not
      // dragging, we want to default to actually selecting the node we're over, always
      selectParentNodeNearTopAndBottomBoundaries
    ) {
      candidateNode = parentNode;
    }
  }

  // NOTE (Evan, 7/11/23) Double-check that the element is a child of the full page element,
  // since it's possible to manually include a replo section in theme.liquid (as in USE-122).
  // If it's not a child of the full page element, it should not be returned as a candidate.
  const fullPageElement = targetFrameDocument.querySelector(
    fullPageQuerySelector,
  );
  const modalBodyElement = targetFrameDocument.querySelector(
    modalMountPointQuerySelector,
  );
  const isInsidePaintableArea =
    candidateNode &&
    (fullPageElement?.contains(candidateNode) ||
      modalBodyElement?.contains(candidateNode));

  if (!isInsidePaintableArea) {
    return null;
  }

  return candidateNode as HTMLElement;
}
