import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";
import { ItemsConfigType } from "schemas/dynamicData";

const productWithVariantAndOptionList: ComponentTemplate = {
  id: "c813e3e1-6b57-4dd1-a19a-aee7fdbcb33e",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Product with Variant & Option",
  leftBarCategory: { main: "product", subCategory: "productLayouts" },
  preview: "product/product-with-variant-and-option-list.png",
  template: {
    id: "feab6231-77f3-42f3-b897-bcd34bfb561d",
    name: "Product 1",
    type: "product",
    props: {
      style: {
        display: "flex",
        __flexGap: "36px",
        alignSelf: "stretch",
        alignItems: "center",
        justifyContent: "center",
      },
      "style@sm": {
        display: "flex",
        __flexGap: "0px",
        flexDirection: "column",
      },
    },
    children: [
      {
        id: "a2df6093-27aa-461f-b8f2-87eb2a805257",
        name: "Carousel 1",
        type: "carouselV3",
        props: {
          style: {
            display: "flex",
            flexGrow: "1",
            maxWidth: "100%",
            alignSelf: "stretch",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          },
          _items: {
            type: ItemsConfigType.inline,
            valueType: "dynamic",
            dynamicPath: "{{attributes._product.images}}",
          },
        },
        children: [
          {
            id: "ca96a21d-c20b-4f8f-9b51-a32829782367",
            name: "Previous Button 1",
            type: "carouselV3Control",
            props: {
              style: {
                cursor: "pointer",
                display: "flex",
                flexGrow: "unset",
                flexWrap: "wrap",
                alignSelf: "stretch",
                flexBasis: "auto",
                alignItems: "center",
                justifyContent: "center",
              },
              direction: "previous",
            },
            children: [
              {
                id: "78c41bdf-4950-4912-8f64-7d6e5685901d",
                type: "icon",
                props: {
                  style: {
                    width: "60px",
                    height: "60px",
                    __width: "60px",
                    __height: "60px",
                    flexGrow: "unset",
                  },
                  iconName: "chevronLeft",
                },
                variantOverrides: {},
              },
            ],
            variants: [
              {
                id: "47a8f5df-3032-4034-bee1-4b47a20220d2",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "42815cfb-6bc5-47ed-b1f5-d8ac450d8499",
                name: "First Item",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "bf8b0b4c-79fd-4043-a18b-9840a038a6e3",
                      field: "state.group.isFirstItemActive",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "42815cfb-6bc5-47ed-b1f5-d8ac450d8499": {
                componentOverrides: {
                  "78c41bdf-4950-4912-8f64-7d6e5685901d": {
                    props: {
                      style: {
                        opacity: "20%",
                      },
                    },
                  },
                  "ca96a21d-c20b-4f8f-9b51-a32829782367": {
                    props: {
                      style: {
                        cursor: "default",
                      },
                    },
                  },
                },
              },
            },
          },
          {
            id: "e8cfec54-969f-4aee-9171-a4c1dd2fbe9e",
            name: "Container 2",
            type: "container",
            props: {
              style: {
                display: "flex",
                flexGrow: "1",
                maxWidth: "100%",
                alignSelf: "stretch",
                flexBasis: "auto",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "flex-start",
              },
            },
            children: [
              {
                id: "c7917c44-48aa-47a1-935a-84b1226f823f",
                name: "Carousel Slides",
                type: "carouselV3Slides",
                props: {
                  style: {
                    __width: "1197px",
                    flexGrow: 1,
                    overflow: "hidden",
                    alignSelf: "stretch",
                  },
                  _useCustomConfig: false,
                },
                children: [
                  {
                    id: "e61ec779-4797-4f4c-9959-e01db8b279a7",
                    name: "Slide 1",
                    type: "container",
                    props: {
                      style: {
                        __width: "auto",
                        display: "flex",
                        flexGrow: "1",
                        __flexGap: "12px",
                        flexBasis: "auto",
                        alignItems: "center",
                        paddingTop: "24px",
                        paddingBottom: "24px",
                        justifyContent: "center",
                      },
                    },
                    children: [
                      {
                        id: "dac3e75c-a4c5-4f07-ae8c-0ea77cd2f0b2",
                        name: "Image 1",
                        type: "image",
                        props: {
                          src: "",
                          style: {
                            objectFit: "cover",
                            __imageSource: "{{attributes._currentItem}}",
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              {
                id: "ebc60b4d-8a0d-4453-89ca-0e880e4a8c90",
                name: "Carousel Indicators",
                type: "carouselV3Indicators",
                props: {
                  style: {
                    top: "auto",
                    left: "auto",
                    right: "auto",
                    bottom: "auto",
                    zIndex: 2,
                    display: "flex",
                    position: "relative",
                    alignSelf: "stretch",
                    marginTop: "0px",
                    alignItems: "center",
                    marginLeft: "0px",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    justifyContent: "center",
                  },
                },
                children: [
                  {
                    id: "00f623c5-631c-4c34-b802-b94360cec9e2",
                    name: "Container 3",
                    type: "container",
                    props: {
                      style: {
                        width: "10px",
                        cursor: "pointer",
                        height: "10px",
                        __width: "10px",
                        display: "flex",
                        __height: "10px",
                        flexGrow: "unset",
                        maxWidth: "100%",
                        alignSelf: "auto",
                        alignItems: "flex-start",
                        marginLeft: "10px",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        backgroundColor: "#0000004D",
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                        borderBottomLeftRadius: "20px",
                        borderBottomRightRadius: "20px",
                      },
                    },
                    children: [],
                    variants: [
                      {
                        id: "5c4cc7f7-b2b2-4faa-8da9-d0a50fbc76a5",
                        name: "default",
                        query: null,
                        componentOverrides: null,
                      },
                      {
                        id: "e14041e9-5a3a-4fe6-84b1-c688b1671110",
                        name: "Current item is active",
                        query: {
                          type: "expression",
                          operator: "or",
                          statements: [
                            {
                              id: "a5eefa18-3bc3-4890-a191-335f2c071f83",
                              field: "state.group.isCurrentItemActive",
                              value: null,
                              operator: null,
                            },
                          ],
                        },
                        componentOverrides: null,
                      },
                    ],
                    variantOverrides: {
                      "e14041e9-5a3a-4fe6-84b1-c688b1671110": {
                        componentOverrides: {
                          "00f623c5-631c-4c34-b802-b94360cec9e2": {
                            props: {
                              style: {
                                backgroundColor: "#000000FF",
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            id: "88a63bd1-06db-4896-a8fd-a5937d84314c",
            name: "Next Button 1",
            type: "carouselV3Control",
            props: {
              style: {
                cursor: "pointer",
                display: "flex",
                flexGrow: "unset",
                alignSelf: "stretch",
                flexBasis: "auto",
                alignItems: "center",
                justifyContent: "center",
              },
              direction: "next",
            },
            children: [
              {
                id: "f6013629-5161-4784-b4a7-861058c1f54e",
                type: "icon",
                props: {
                  style: {
                    width: "60px",
                    height: "60px",
                    __width: "60px",
                    __height: "60px",
                    flexGrow: "unset",
                  },
                  iconName: "chevronRight",
                },
                variantOverrides: {},
              },
            ],
            variants: [
              {
                id: "9fd2a595-0541-4015-8e27-c629d5a5ca69",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "342989ae-230e-4c90-9718-f774b6128580",
                name: "Last Item",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "fa7db26c-7755-450b-b10f-b5f0f5c6a624",
                      field: "state.group.isLastItemActive",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "342989ae-230e-4c90-9718-f774b6128580": {
                componentOverrides: {
                  "f6013629-5161-4784-b4a7-861058c1f54e": {
                    props: {
                      style: {
                        opacity: "20%",
                      },
                    },
                  },
                  "88a63bd1-06db-4896-a8fd-a5937d84314c": {
                    props: {
                      style: {
                        cursor: "default",
                      },
                    },
                  },
                },
              },
            },
          },
        ],
      },
      {
        id: "d2c6fb80-f2b9-403c-92d5-9238dc47fbd6",
        name: "Container 1",
        type: "container",
        props: {
          style: {
            color: "#000000FF",
            display: "flex",
            flexGrow: "1",
            maxWidth: "800px",
            __flexGap: "24px",
            alignSelf: "stretch",
            flexBasis: 0,
            alignItems: "flex-start",
            paddingTop: "24px",
            paddingLeft: "24px",
            paddingRight: "24px",
            flexDirection: "column",
            paddingBottom: "24px",
            justifyContent: "center",
          },
          "style@md": {
            flexGrow: "1",
            maxWidth: "auto",
            flexBasis: 0,
          },
          "style@sm": {
            flexGrow: 1,
            flexBasis: "auto",
          },
        },
        children: [
          {
            id: "32415f90-c6d4-49fd-b090-4c729218079b",
            type: "text",
            props: {
              text: "<p>{{attributes._product.title}}</p>",
              style: {
                fontSize: "48px",
                fontWeight: "400",
                lineHeight: "48px",
              },
            },
          },
          {
            id: "d46fbc16-3cc5-46c0-9899-bc6bf6a007ee",
            type: "text",
            props: {
              text: "{{attributes._product.description}}",
              style: {
                fontSize: "20px",
                lineHeight: "24px",
              },
            },
          },
          {
            id: "2dcbd9c0-0c18-4ed3-be3d-509949d7caf5",
            type: "text",
            props: {
              text: "<p>{{attributes._variant.displayPrice}}</p>",
              style: {
                fontSize: "32px",
                fontWeight: 300,
                lineHeight: "48px",
              },
            },
          },
          {
            id: "97e85491-70e9-4e42-b0e0-8fa7c55d4318",
            name: "Variant Container",
            type: "container",
            props: {
              style: {
                display: "flex",
                maxWidth: "100%",
                __flexGap: "10px",
                alignSelf: "stretch",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "center",
              },
            },
            children: [
              {
                id: "4eed1772-40a6-419b-88f5-6982f5bb1b6e",
                type: "text",
                props: {
                  text: "<p>Variant List</p>",
                  style: {
                    fontSize: "20px",
                    lineHeight: "24px",
                  },
                },
              },
              {
                id: "4bdc3096-f002-4456-8ef7-f1279c86fb3d",
                name: "Variant List 1",
                type: "variantSelect",
                props: {
                  style: {
                    display: "flex",
                    __flexGap: "12px",
                    alignSelf: "stretch",
                    flexDirection: "row",
                  },
                },
                children: [
                  {
                    id: "71280acc-aab2-4f1a-a575-c68a461627e9",
                    name: "Container 4",
                    type: "container",
                    props: {
                      style: {
                        alignItems: "center",
                        paddingTop: "4px",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        flexDirection: "column",
                        paddingBottom: "4px",
                        justifyContent: "center",
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                        borderBottomLeftRadius: "4px",
                        borderBottomRightRadius: "4px",
                        __animateVariantTransitions: true,
                      },
                    },
                    children: [
                      {
                        id: "153accd8-c380-45df-9444-cebeeb97a2ba",
                        type: "text",
                        props: {
                          text: "<p>{{attributes._currentVariant.title}}</p>",
                          style: {
                            fontSize: "16px",
                            fontWeight: 300,
                            lineHeight: "24px",
                          },
                        },
                      },
                    ],
                    variants: [
                      {
                        id: "45e784bb-b140-44de-bb04-504c7bdeb95c",
                        name: "default",
                        query: null,
                        componentOverrides: null,
                      },
                      {
                        id: "c9120678-0d83-4907-afa4-55853fdc8e20",
                        name: "Selected",
                        query: {
                          type: "expression",
                          operator: "or",
                          statements: [
                            {
                              id: "8e1821de-3d2f-4bea-87fb-91b41957b391",
                              field: "state.product.selectedVariant",
                            },
                          ],
                        },
                      },
                    ],
                    variantOverrides: {
                      "c9120678-0d83-4907-afa4-55853fdc8e20": {
                        componentOverrides: {
                          "71280acc-aab2-4f1a-a575-c68a461627e9": {
                            props: {
                              style: {
                                backgroundColor: "#000000FF",
                              },
                            },
                          },
                          "153accd8-c380-45df-9444-cebeeb97a2ba": {
                            props: {
                              style: {
                                color: "#FFFFFFFF",
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            id: "a70e969a-2d6d-4caf-9c28-a8a0955eaef3",
            name: "Option Container",
            type: "container",
            props: {
              style: {
                display: "flex",
                maxWidth: "100%",
                __flexGap: "10px",
                alignSelf: "stretch",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "center",
              },
            },
            children: [
              {
                id: "02c0bd98-1a6c-4cb1-83fe-54990f1306f3",
                type: "text",
                props: {
                  text: "<p>Option List</p>",
                  style: {
                    fontSize: "20px",
                    lineHeight: "24px",
                  },
                },
              },
              {
                id: "28e165af-14a5-4dc1-8044-6b55051d401a",
                name: "Variant List 1",
                type: "variantSelect",
                props: {
                  style: {
                    display: "flex",
                    __flexGap: "12px",
                    alignSelf: "stretch",
                    flexDirection: "row",
                  },
                },
                children: [
                  {
                    id: "a893fb5d-ff95-4eb5-bb86-b236452f129e",
                    name: "Container 4",
                    type: "container",
                    props: {
                      style: {
                        alignItems: "center",
                        paddingTop: "4px",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        flexDirection: "column",
                        paddingBottom: "4px",
                        justifyContent: "center",
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                        borderBottomLeftRadius: "4px",
                        borderBottomRightRadius: "4px",
                        __animateVariantTransitions: true,
                      },
                    },
                    children: [
                      {
                        id: "d075cd64-95af-445d-9315-d19825330429",
                        type: "text",
                        props: {
                          text: "<p>{{attributes._currentVariant.title}}</p>",
                          style: {
                            fontSize: "16px",
                            fontWeight: 300,
                            lineHeight: "24px",
                          },
                        },
                      },
                    ],
                    variants: [
                      {
                        id: "d90efe3f-7f27-4ef7-82f9-6e5be1e48bbc",
                        name: "default",
                        query: null,
                        componentOverrides: null,
                      },
                      {
                        id: "da623797-6b4b-4e73-b6ca-722270c4a8b4",
                        name: "Selected",
                        query: {
                          type: "expression",
                          operator: "or",
                          statements: [
                            {
                              id: "8e1821de-3d2f-4bea-87fb-91b41957b391",
                              field: "state.product.selectedVariant",
                            },
                          ],
                        },
                      },
                    ],
                    variantOverrides: {
                      "da623797-6b4b-4e73-b6ca-722270c4a8b4": {
                        componentOverrides: {
                          "a893fb5d-ff95-4eb5-bb86-b236452f129e": {
                            props: {
                              style: {
                                backgroundColor: "#000000FF",
                              },
                            },
                          },
                          "d075cd64-95af-445d-9315-d19825330429": {
                            props: {
                              style: {
                                color: "#FFFFFFFF",
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            id: "aaaa70c4-4f4e-43d7-8621-c0deec011934",
            type: "button",
            props: {
              style: {
                color: "#FFFFFF",
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "20px",
                paddingTop: "20px",
                paddingLeft: "32px",
                paddingRight: "32px",
                letterSpacing: "2px",
                paddingBottom: "20px",
                backgroundColor: "#000000FF",
              },
              onClick: [
                {
                  id: "$uid",
                  type: "addProductVariantToCart",
                  value: {
                    product: {
                      ref: "attributes._variant",
                      type: "contextRef",
                    },
                    redirectToCart: true,
                    redirectToCheckout: false,
                  },
                },
              ],
            },
            children: [
              {
                id: "e2e754b6-dd74-4057-83a2-a5c5f773a4e2",
                type: "text",
                props: {
                  text: "<p>Add To Cart</p>",
                  style: {
                    color: "#FFFFFF",
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    letterSpacing: "2px",
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
};

export default productWithVariantAndOptionList;
