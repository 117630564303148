import type { SolidOrGradient } from "replo-runtime/shared/types";

import * as React from "react";

import ColorPicker from "@editor/components/common/designSystem/ColorPicker";
import DynamicDataButton from "@editor/components/common/designSystem/DynamicDataButton";
import InlinePopover from "@editor/components/common/designSystem/InlinePopover";
import Input from "@editor/components/common/designSystem/Input";
import { getAutoCompletedColor } from "@editor/components/common/designSystem/utils/colors";
import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import {
  selectDraftComponentId,
  selectDraftElementColors,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector } from "@editor/store";
import {
  getFormattedColor,
  getFormattedColorWithoutOpacity,
} from "@editor/utils/colors";
import { getHotKey } from "@editor/utils/getHotKey";
import { hasDynamicData } from "@editorModifiers/utils";

import { Badge } from "@replo/design-system/components/badge";
import Tooltip from "@replo/design-system/components/tooltip";
import debounce from "lodash-es/debounce";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { gradientToCssGradient } from "replo-runtime/shared/utils/gradient";

type Props = {
  value: SolidOrGradient | null;
  onPreviewChange?(value: SolidOrGradient | null): void;
  onChange(value: SolidOrGradient | null): void;
  onRemove?(): void;
  onDragStart?(e: React.MouseEvent): void;
  onDragEnd?(e: React.MouseEvent): void;
  openDynamicData?(): void;
  popoverTitle?: string;
  isDisabled?: boolean;
  allowColorPickerPopover?: boolean;
  popoverSideOffset?: number;
  isPopoverOpen?: boolean;
  onOpenPopoverChange?(isOpen: boolean): void;
  onSavedStyleSelect?(value: string): void;
  showSavedStyles?: boolean;
};

const GradientSelector: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  onChange,
  onRemove,
  onPreviewChange,
  onDragEnd,
  onDragStart,
  openDynamicData,
  popoverTitle = "Color",
  isDisabled,
  allowColorPickerPopover = true,
  popoverSideOffset,
  isPopoverOpen,
  onOpenPopoverChange,
  onSavedStyleSelect,
  showSavedStyles,
}) => {
  const [inputValue, setInputValue] = useOverridableState(value);
  const [lastValidColorValue, setLastValidColorValue] = React.useState(value);
  const debounceOnChange = debounce((value) => onChange(value), 300);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const dynamicData =
    hasDynamicData(draftComponentId) && openDynamicData !== undefined;

  const inputValueText = getInputTextValue(inputValue);
  const badgeValue = getBadgeValue(inputValue);

  const handleChangeComplete = (value: SolidOrGradient) => {
    if (value.type === "solid") {
      setInputValue(value);
      setLastValidColorValue(value);
      onPreviewChange?.(value);
      debounceOnChange(value);
    } else {
      setInputValue(value);
      onPreviewChange?.(value);
      debounceOnChange(value);
    }
  };

  const handleColorInputChange = (value: string) => {
    setInputValue({ type: "solid", color: getFormattedColor(value) });
  };

  const handleColorChange = () => {
    if (inputValue?.type === "solid") {
      const color = getAutoCompletedColor(inputValue.color);
      if (color) {
        // Always convert to uppercase for display in the input field
        const hexColor = getFormattedColor(color);
        setInputValue({
          type: "solid",
          color: hexColor,
        });
        setLastValidColorValue({ type: "solid", color: hexColor });
        onChange({ type: "solid", color: hexColor });
      } else if (color === "") {
        setLastValidColorValue({ type: "solid", color });
        setInputValue({ type: "solid", color });
        onChange(null);
      } else {
        setInputValue(lastValidColorValue);
      }
    }
  };

  const handleColorRemove = () => {
    if (onRemove) {
      onRemove();
    } else {
      setInputValue({ type: "solid", color: "" });
      onChange({ type: "solid", color: "" });
    }
  };

  // Note (Sebas 2022-11-14): In case we want to reset the value with opt + click
  // we need to prevent the popover from opening
  const onTriggerClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const hotkey = getHotKey(e);
    if (hotkey === "altKey") {
      e.preventDefault();
    }
  };

  if (isDisabled) {
    return (
      <div className="flex flex-grow flex-row justify-items-start">
        <Input
          unsafe_className="flex-grow"
          value={inputValueText}
          startEnhancer={() => (
            <Badge type="color" isFilled backgroundColor={badgeValue} />
          )}
          isDisabled
          endEnhancer={() =>
            handleColorRemove &&
            inputValueText && (
              <FormFieldXButton
                onClick={() => {
                  setLastValidColorValue(null);
                  handleColorRemove?.();
                }}
              />
            )
          }
        />
      </div>
    );
  }
  return (
    <div className="flex flex-grow flex-row justify-items-start gap-1">
      <Input
        unsafe_className="flex-grow"
        value={inputValueText.replace("#", "")}
        placeholder="#000000"
        onChange={(e) => handleColorInputChange(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()}
        startEnhancer={() => {
          return allowColorPickerPopover ? (
            <ColorPickerPopover
              isPopoverOpen={isPopoverOpen}
              onOpenPopoverChange={onOpenPopoverChange}
              value={inputValue}
              onChange={handleChangeComplete}
              onPreviewChange={onPreviewChange}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              popoverTitle={popoverTitle}
              onTriggerClick={onTriggerClick}
              popoverSideOffset={popoverSideOffset}
              onSavedStyleSelect={onSavedStyleSelect}
              showSavedStyles={showSavedStyles}
            >
              <Badge type="color" isFilled backgroundColor={badgeValue} />
            </ColorPickerPopover>
          ) : (
            <Badge type="color" isFilled backgroundColor={badgeValue} />
          );
        }}
        onOptionClick={handleColorRemove}
        onBlur={handleColorChange}
        onEnter={handleColorChange}
        isDisabled={badgeValue.includes("linear-gradient")}
        endEnhancer={() =>
          handleColorRemove &&
          inputValueText && (
            <FormFieldXButton
              onClick={() => {
                setLastValidColorValue(null);
                handleColorRemove?.();
              }}
            />
          )
        }
        autoFocus={false}
      />
      {dynamicData && (
        <div className="flex">
          <DynamicDataButton
            tooltipText="Add Dynamic Data"
            onClick={(e) => {
              e.stopPropagation();
              openDynamicData?.();
            }}
          />
        </div>
      )}
    </div>
  );
};

type ColorPickerPopoverProps = {
  value: SolidOrGradient | null;
  onChange(value: SolidOrGradient | null): void;
  onPreviewChange?(value: SolidOrGradient | null): void;
  onDragStart?(e: React.MouseEvent): void;
  onDragEnd?(e: React.MouseEvent): void;
  openDynamicData?(): void;
  popoverTitle?: string;
  onTriggerClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  popoverSideOffset?: number;
  isPopoverOpen?: boolean;
  onOpenPopoverChange?(isOpen: boolean): void;
  onSavedStyleSelect?(value: string): void;
  showSavedStyles?: boolean;
};

const ColorPickerPopover: React.FC<
  React.PropsWithChildren<ColorPickerPopoverProps>
> = ({
  popoverTitle = "Color",
  value,
  onChange,
  onPreviewChange,
  onDragStart,
  onDragEnd,
  onTriggerClick,
  children,
  popoverSideOffset = 28,
  isPopoverOpen,
  onOpenPopoverChange,
  onSavedStyleSelect,
  showSavedStyles,
}) => {
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const elementColors = useEditorSelector(selectDraftElementColors);
  return (
    <InlinePopover
      title={popoverTitle}
      isOpen={isPopoverOpen}
      onOpenChange={onOpenPopoverChange}
      shouldPreventDefaultOnInteractOutside={areModalsOpen}
      sideOffset={popoverSideOffset}
      content={
        <ColorPicker
          value={value}
          allowsGradientSelection
          onChange={onChange}
          onPreviewChange={onPreviewChange}
          documentColors={elementColors}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          showSavedStyles={showSavedStyles}
          onSavedStyleSelect={onSavedStyleSelect}
        />
      }
      triggerAsChild
    >
      <button
        type="button"
        onClick={onTriggerClick}
        data-testid={`${popoverTitle.toLowerCase()}-gradient-selector`}
      >
        <Tooltip content={popoverTitle} triggerAsChild>
          <div
            tabIndex={0}
            // NOTE (Sebas, 2024-11-05): This is needed to prevent selecting the input when
            // clicking on the color picker popover trigger. If we don't do this, the input
            // will focus and unfocus triggering an onChange event because of the onBlur event.
            onMouseDown={(e) => e.preventDefault()}
          >
            {children}
          </div>
        </Tooltip>
      </button>
    </InlinePopover>
  );
};

const getInputTextValue = (color: SolidOrGradient | null): string => {
  if (!color) {
    return "";
  }
  if (color.type === "solid") {
    return getFormattedColorWithoutOpacity(color.color) ?? "";
  }
  return "Gradient";
};

const getBadgeValue = (color: SolidOrGradient | null): string => {
  if (!color) {
    return "";
  }
  if (color.type === "solid") {
    return color.color ?? "";
  }
  return gradientToCssGradient(color.gradient) ?? "";
};

export default GradientSelector;
