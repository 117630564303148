import loadash_isObjectLike from "lodash-es/isObjectLike";

export { default as isArray } from "lodash-es/isArray";
export { default as isBoolean } from "lodash-es/isBoolean";
export { default as isFunction } from "lodash-es/isFunction";
export { default as isNumber } from "lodash-es/isNumber";
export { default as isString } from "lodash-es/isString";

export const isObjectLike = loadash_isObjectLike as (
  value: unknown,
) => value is object;
