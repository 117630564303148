import * as React from "react";

import { ButtonLink } from "@replo/design-system/components/button";
import { BsQuestionCircle } from "react-icons/bs";

export const HelpDocumentationIcon = () => (
  <ButtonLink
    tooltipText="Open Help Documentation"
    variant="secondary"
    size="base"
    icon={<BsQuestionCircle size={16} className="text-slate-800" />}
    to="https://support.replo.app/articles/2069301880-experiments-(a/b-testing)"
    target="_blank"
    rel="noopener noreferrer"
  />
);
