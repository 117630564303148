import type { Component } from "schemas/component";
import type { ItemsConfig } from "schemas/dynamicData";
import type { RenderComponentProps } from "../../../shared/types";
import type { ActionType } from "./config";

import * as React from "react";

import {
  RenderEnvironmentContext,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { mergeContext } from "../../../shared/utils/context";
import { getItemObjectsForRender } from "../../utils/items";
import { ReploComponent } from "../ReploComponent";

type ItemForRender = {
  id: string;
};

export const TabsBlock = (props: RenderComponentProps) => {
  const itemsConfig = props.component.props["_tabsConfig"] as ItemsConfig;
  const dataTableMapping =
    useRuntimeContext(RuntimeHooksContext).useDataTableMapping();
  const {
    fakeProducts,
    activeCurrency: currencyCode,
    activeLanguage: language,
    moneyFormat,
    templateProduct,
  } = useRuntimeContext(ShopifyStoreContext);
  const products = useRuntimeContext(RuntimeHooksContext).useShopifyProducts();
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const { isPublishing } = useRuntimeContext(RenderEnvironmentContext);
  const items = React.useMemo(() => {
    return (
      getItemObjectsForRender(itemsConfig, dataTableMapping, props.context, {
        products,
        currencyCode,
        moneyFormat,
        fakeProducts,
        language,
        templateProduct,
        isEditor: isEditorApp,
      }) ?? []
    );
  }, [
    itemsConfig,
    dataTableMapping,
    props.context,
    products,
    currencyCode,
    moneyFormat,
    fakeProducts,
    language,
    templateProduct,
    isEditorApp,
  ]);

  const [activeTabIdFromState, setActiveTabId] = React.useState<string | null>(
    items?.length > 0 ? (items[0] as ItemForRender).id : null,
  );
  let activeTabId;
  if (isPublishing) {
    activeTabId = items?.length > 0 ? (items[0] as ItemForRender).id : null;
  } else {
    activeTabId = activeTabIdFromState;
  }

  const actionHooks = {
    activateTabId(id: string) {
      setActiveTabId(id);
    },
  } satisfies {
    [K in ActionType]: Function;
  };

  const nextContext = mergeContext(props.context, {
    state: {
      tabsBlock: {
        items: items,
        activeTabId,
        tabsBlockComponentId: props.component.id,
      },
    },
    actionHooks,
  });

  return (
    <div {...props.componentAttributes}>
      {(props.component.children || []).map((child) => {
        return (
          <ReploComponent
            key={child.id}
            context={nextContext}
            component={child}
            repeatedIndexPath={props.context.repeatedIndexPath ?? ".0"}
            extraAttributes={props.extraAttributes}
          />
        );
      })}
    </div>
  );
};

export const TabsList = (props: RenderComponentProps) => {
  const template =
    props.component.children?.length && props.component.children[0];
  if (!template) {
    return null;
  }

  const items = props.context.state.tabsBlock?.items;

  const renderOptions = () => {
    return items?.map((item: any, index: number) => {
      const context = mergeContext(props.context, {
        attributes: {
          _currentTabItem: item.value,
        },
        state: {
          tabsBlock: {
            currentTabsListItem: item,
          },
        },
      });

      return (
        <ReploComponent
          context={context}
          key={index}
          component={template}
          extraAttributes={props.extraAttributes}
          defaultActions={{
            actions: {
              onClick: [
                {
                  id: "alchemy:tabsItemClick",
                  type: "activateTabId",
                  value: {
                    tabItemId: item.id,
                  },
                },
              ],
            },
          }}
          repeatedIndexPath={`${props.context.repeatedIndexPath}.${index}`}
        />
      );
    });
  };

  return <div {...props.componentAttributes}>{renderOptions()}</div>;
};

export const TabPanel = (props: RenderComponentProps) => {
  const items: { id: string; value: string }[] =
    props.context.state.tabsBlock?.items || [];
  const activeTabId = props.context.state.tabsBlock?.activeTabId;

  return (
    <div {...props.componentAttributes}>
      {items.map((item) => {
        const template = props.component.props[
          `_tabPanel_${item.id}`
        ] as Component;

        if (!template) {
          return null;
        }

        const context = mergeContext(props.context, {
          attributes: {
            _currentTabItem: item.value,
          },
        });

        const rendered = (
          <ReploComponent
            context={context}
            component={template}
            repeatedIndexPath={`${props.context.repeatedIndexPath}.0`}
            extraAttributes={props.extraAttributes}
          />
        );

        // Note (Noah, 2021-08-30): We render inside a hidden div instead of not
        // rendering since we want all panels to assume an initial context, so that
        // they can automatically be found and have actions executed when the user
        // clicks on a tree items in the editor. In the future we can consider adding
        // transitions between tabs
        if (item.id !== activeTabId) {
          return (
            <div key={item.id} style={{ display: "none" }}>
              {rendered}
            </div>
          );
        }
        return <React.Fragment key={item.id}>{rendered}</React.Fragment>;
      })}
    </div>
  );
};
