import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const vimeoEmbed: ComponentTemplate = {
  id: "vimeoEmbed",
  storeId: null,
  scope: "left-bar",
  type: "component",
  env: Env.PRODUCTION,
  name: "Video/Vimeo",
  leftBarCategory: { main: "media", subCategory: "video" },
  preview: "video/vimeo-embed.svg",
  tags: ["embed", "video"],
  template: {
    id: "7675e384-f51b-40a2-a5b1-908918bc9bce",
    type: "vimeoEmbedV2",
    props: {},
  },
};
