import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

const title: ComponentTemplate = {
  id: "e0d6cb42-0612-42b0-b358-25415429b95c",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Product Title",
  leftBarCategory: { main: "product", subCategory: "productData" },
  preview: "product/title.svg",
  template: {
    id: "22a244ee-4cec-4f37-8203-bf74a705555d",
    type: "text",
    props: {
      text: "{{attributes._product.title}}",
      style: { fontSize: "20px", lineHeight: "24px" },
    },
  },
};

export default title;
