/**
 * The type for the regular slider.
 *
 * @since 3.0.0
 */
export const SLIDE = "slide";

/**
 * The type for the carousel slider.
 *
 * @since 3.0.0
 */
export const LOOP = "loop";

/**
 * The type for the fade slider that can not have multiple slides in a page.
 *
 * @since 3.0.0
 */
export const FADE = "fade";
