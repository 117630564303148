import type { MediaSize } from "schemas/breakpoints";

export type AnimationValue = {
  styles: {
    "--offset"?: string;
    "--final-opacity"?: string;
    "--initial-opacity"?: string;
    "--initial-scale"?: string;
    "--final-scale"?: string;
    "--initial-angle"?: string;
    "--final-angle"?: string;
    "--angle"?: string;
    animationName?: string;
    animationDelay?: string;
    animationDuration?: string;
    animationFillMode?: "none" | "forwards" | "backwards" | "both";
    animationDirection?:
      | "normal"
      | "reverse"
      | "alternate"
      | "alternate-reverse";
    animationTimingFunction?: string;
    transformStyle?: string;
  } | null;
  from?: "top";
  direction?: "clockwise";
  [key: string]: any;
};

export const AlchemyAnimationTypes = [
  "fadeIn",
  "fadeOut",
  "slideIn",
  "slideOut",
  "flipIn",
  "flipOut",
  "growIn",
  "growOut",
  "growBigIn",
  "growBigOut",
  "shrinkIn",
  "shrinkOut",
  "shrinkBigIn",
  "shrinkBigOut",
  "spinIn",
  "spinOut",
  "flyIn",
  "flyOut",
  "dropIn",
  "dropOut",
] as const;

export type AlchemyAnimationType = (typeof AlchemyAnimationTypes)[number];

export const AlchemyAnimationTriggers = ["onViewportEnter"] as const;

export type AlchemyAnimationTriggerType =
  (typeof AlchemyAnimationTriggers)[number];

export type AlchemyAnimationTrigger =
  | {
      type: AlchemyAnimationTriggerType;
    }
  | {
      type: AlchemyAnimationTriggerType;
      value: any;
    };

export interface Animation {
  id: string;
  trigger: AlchemyAnimationTrigger;
  type: AlchemyAnimationType;
  value: AnimationValue;
  devices: MediaSize[];
  runOnlyOnce: boolean;
}
