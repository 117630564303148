import type { SvgProps } from "replo-utils/react/components";

import * as React from "react";

import { Svg } from "replo-utils/react/components";

export const SvgBlankFilled = (props: SvgProps) => (
  <Svg fill="none" viewBox="0 0 800 550" {...props}>
    <rect width={800} height={550} fill="#1D4ED8" rx={20} />
  </Svg>
);
export default SvgBlankFilled;
