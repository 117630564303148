import * as React from "react";

import classNames from "classnames";
import { CgSpinner } from "react-icons/cg";
import { twMerge } from "tailwind-merge";

export type SpinnerProps = {
  variant: "primary" | "secondary" | "danger" | "link" | "dangerLink";
  size?: number;
  className?: string;
  testId?: string;
  "aria-hidden"?: boolean;
  "aria-label"?: string;
  "aria-labelledby"?: string;
  role?: string;
};

const Spinner = ({
  variant,
  className,
  size = 16,
  "aria-hidden": ariaHidden,
  "aria-label": ariaLabel,
  "aria-labelledby": ariaLabelledBy,
  role,
  testId,
}: SpinnerProps) => {
  const colorDict: any = {
    "text-primary": variant === "link",
    "text-white": variant === "danger" || variant === "primary",
    "text-danger": variant === "dangerLink",
  };

  const cn = twMerge(classNames("animate-spin", colorDict, className));
  return (
    <CgSpinner
      size={size}
      className={cn}
      data-testid={testId}
      aria-hidden={ariaHidden}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      role={role}
    />
  );
};

export { Spinner };
