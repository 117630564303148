import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

export const styleElements = {
  root: {},
} as const satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: false,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: true,
    canContainChildren: true,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
