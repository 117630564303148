import type { TRPCClientErrorBase } from "@trpc/client";
import type { ErrorType } from "replo-publisher/src/trpc/trpc";
import type { PreviewElementProps } from "schemas/generated/element";

import React from "react";

import InlinePopover from "@common/designSystem/InlinePopover";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import usePreviewBeforePublishInfo from "@editor/hooks/usePreviewBeforePublishInfo";
import { useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";
import {
  selectDraftElementCurrentVersion,
  selectDraftElementId,
} from "@reducers/core-reducer";

import Button from "@replo/design-system/components/button";
import { ButtonGroup } from "@replo/design-system/components/button/ButtonGroup";
import { Spinner } from "@replo/design-system/components/spinner";
import copy from "copy-to-clipboard";
import { BiCopy } from "react-icons/bi";
import { BsArrowUpRight, BsChevronDown } from "react-icons/bs";
import { QRCode } from "react-qrcode-logo";

import { errorToast, successToast } from "../common/designSystem/Toast";
import { ConnectShopifyCallout } from "../editor/page/ConnectShopifyCallout";
import { PreviewLinkAndSVGSkeleton } from "../editor/SkeletonLoaders";

type PreviewPageButtonProps = {
  onCreatePreviewData: () => PreviewElementProps | undefined;
  onCreatePreviewSuccess?: (data: { html: string }) => void;
  isStoreClosed: boolean;
  isProjectConnectedToShopify: boolean;
};

const PreviewPageButton: React.FC<PreviewPageButtonProps> = ({
  onCreatePreviewData,
  onCreatePreviewSuccess,
  isStoreClosed,
  isProjectConnectedToShopify,
}) => {
  const logEvent = useLogAnalytics();
  const [isPreviewPopoverOpen, setIsPreviewPopoverOpen] = React.useState(false);
  const { url: previewUrl } = usePreviewBeforePublishInfo();

  const [
    lastCreatedPreviewElementVersion,
    setLastCreatedPreviewElementVersion,
  ] = React.useState<number | null>(null);
  const currentElementVersion = useEditorSelector(
    selectDraftElementCurrentVersion,
  );

  const [lastCreatedPreviewElementId, setLastCreatedPreviewElementId] =
    React.useState<string | null>(null);
  const currentElementId = useEditorSelector(selectDraftElementId);

  const {
    mutateAsync: createPreviewElement,
    isPending: isPreviewCreateLoading,
    isSuccess: isPreviewCreateSuccessful,
  } = trpc.element.createPreview.useMutation({
    onSuccess: (data) => {
      if (data.html) {
        onCreatePreviewSuccess?.({ html: data.html });
      }
    },
    onError: (error) => {
      handleCreatePreviewElementError(error);
    },
  });

  function handleCreatePreviewElementError(
    error: TRPCClientErrorBase<ErrorType>,
  ) {
    setIsPreviewPopoverOpen(false);
    if ("status" in error && error.status === 404) {
      errorToast(
        "Page Not Found",
        "The page you are trying to preview does not exist. Maybe it was deleted by another user?",
      );
    } else {
      errorToast("Error", "Something went wrong. Please try again later.");
    }
  }

  async function onPopoverOpen(isPreviewPopoverOpen: boolean) {
    setIsPreviewPopoverOpen(isPreviewPopoverOpen);
    logEvent("preview.requested", {});
    if (
      isPreviewPopoverOpen &&
      (currentElementVersion !== lastCreatedPreviewElementVersion ||
        currentElementId !== lastCreatedPreviewElementId)
    ) {
      const previewData = onCreatePreviewData();
      if (previewData) {
        await createPreviewElement(previewData);
        setLastCreatedPreviewElementVersion(currentElementVersion ?? null);
        setLastCreatedPreviewElementId(currentElementId ?? null);
      }
    }
  }

  const popoverProps = isProjectConnectedToShopify
    ? {
        isOpen: isPreviewPopoverOpen,
        onOpenChange: (isOpen: boolean) => void onPopoverOpen(isOpen),
        content: (
          <PublishPreview
            isPreviewCreateLoading={isPreviewCreateLoading}
            isPreviewCreateSuccessful={isPreviewCreateSuccessful}
          />
        ),
        title: "Preview Options",
        titleClassnames: "text-sm font-semibold leading-5",
        className: "flex flex-col p-4 w-[234px] border border-slate-300 gap-2",
        sideOffset: 5,
        skipTriggerStyles: true,
      }
    : {
        content: <ConnectShopifyCallout type="preview" />,
        className: "w-72",
        sideOffset: 16,
        hideCloseButton: true,
      };

  return (
    <ButtonGroup variant="segmented" isDisabled={isStoreClosed}>
      <Button
        variant="secondary"
        size="base"
        className="rounded-tr-none rounded-br-none border-r-1 disabled:border-slate-300"
        isDisabled={!isProjectConnectedToShopify}
        tooltipText={
          isProjectConnectedToShopify
            ? "Preview page in browser"
            : "Connect to Shopify to preview"
        }
        onClick={() => {
          void (async () => {
            logEvent("preview.requested", {});
            if (
              currentElementVersion === lastCreatedPreviewElementVersion &&
              currentElementId === lastCreatedPreviewElementId
            ) {
              window.open(previewUrl, "_blank");
            } else {
              const previewData = onCreatePreviewData();
              if (previewData) {
                await createPreviewElement(previewData);
                setLastCreatedPreviewElementVersion(
                  currentElementVersion ?? null,
                );
                setLastCreatedPreviewElementId(currentElementId ?? null);
                window.open(previewUrl, "_blank");
              }
            }
          })();
        }}
      >
        {isPreviewCreateLoading ? (
          <div className="flex items-center gap-2">
            <div className="animate-spin">
              <Spinner size={16} variant="secondary" />
            </div>
          </div>
        ) : (
          "Preview"
        )}
      </Button>
      <InlinePopover
        shouldPreventDefaultOnInteractOutside={false}
        side="bottom"
        align="end"
        triggerAsChild
        {...popoverProps}
      >
        <Button
          variant="secondary"
          size="base"
          className="rounded-tl-none rounded-bl-none w-[30px]"
          tooltipText="Preview Options"
          aria-label="Preview Options"
          icon={<BsChevronDown aria-hidden size={12} />}
        />
      </InlinePopover>
    </ButtonGroup>
  );
};

type PublishPreviewProps = {
  isPreviewCreateLoading: boolean;
  isPreviewCreateSuccessful: boolean;
};

const PublishPreview: React.FC<PublishPreviewProps> = (props) => {
  const { isPreviewCreateLoading, isPreviewCreateSuccessful } = props;
  const { url: previewUrl, previewUrlAvailable } =
    usePreviewBeforePublishInfo();

  const logEvent = useLogAnalytics();

  return (
    <div className="flex flex-col items-center gap-4">
      <div className="flex flex-col w-full gap-1">
        <div className="text-slate-800 typ-header-small">Browser</div>
        <div className="flex flex-row w-full gap-1">
          <Button
            variant="secondary"
            className="w-full"
            endEnhancer={
              !isPreviewCreateLoading ? <BsArrowUpRight size={16} /> : undefined
            }
          >
            {isPreviewCreateLoading ? (
              <div className="flex items-center gap-2">
                <div className="animate-spin">
                  <Spinner size={16} variant="secondary" />
                </div>
              </div>
            ) : (
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={previewUrl}
                className="cursor-pointer"
              >
                View Preview in Browser
              </a>
            )}
          </Button>
          <Button
            variant="secondary"
            icon={<BiCopy size={16} />}
            onClick={() => {
              copy(previewUrl);
              successToast(
                "Preview URL copied",
                "You can now open the Preview in a new window.",
              );
              logEvent("preview.copied", {});
            }}
          />
        </div>
      </div>
      <div className="flex flex-col w-full gap-3">
        <div className="flex flex-col gap-1">
          <div className="text-slate-800 typ-header-small">Mobile</div>
          <div className="typ-body-small font-normal text-muted">
            Scan the QR code with your mobile device to preview.
          </div>
        </div>
        <div className="flex items-center justify-center">
          {!isPreviewCreateLoading &&
          isPreviewCreateSuccessful &&
          previewUrlAvailable ? (
            <QRCode
              value={previewUrl}
              qrStyle="dots"
              bgColor="black"
              fgColor="white"
              logoImage="/replo-logo-quartercircle-black-bg.svg"
              logoWidth={60}
              removeQrCodeBehindLogo={true}
              quietZone={5}
              // NOTE (Kurt, 2024-12-04): A width of 190px aligns the QR code with
              // the width of the parent container
              size={190}
              eyeRadius={2}
            />
          ) : (
            <PreviewLinkAndSVGSkeleton />
          )}
        </div>
      </div>
    </div>
  );
};

export default PreviewPageButton;
