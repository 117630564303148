import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { getProjectName, getStoreData } from "@editor/utils/project-utils";

export default function useGetStoreNameAndUrl(): {
  storeName?: string;
  storeUrl?: string;
  customDomain?: string;
} {
  const { project } = useCurrentProjectContext();

  if (!project) {
    return {};
  }

  const storeData = getStoreData(project);

  return {
    storeName: getProjectName(project),
    storeUrl: storeData?.shopifyUrl,
    customDomain: storeData?.url ?? undefined,
  };
}
