import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import VimeoPlayer from "react-player/vimeo";

import {
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import RenderComponentPlaceholder from "../RenderComponentPlaceholder";
import { customProperties } from "./config";

const VimeoEmbedV2: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
}) => {
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const url = component.props.url;
  const hideControls = Boolean(component.props.hideControls);
  const attributes = componentAttributes;
  const autoPlay = Boolean(component.props.autoPlay);
  const loop = Boolean(component.props.loop);
  const defaultMuted = Boolean(component.props.defaultMuted);

  return (
    <div {...attributes}>
      {url ? (
        <VimeoPlayer
          playing={autoPlay}
          // We need to set playsinline to true to make autoplay work on iOS
          playsinline={autoPlay}
          url={url}
          controls={!hideControls}
          muted={defaultMuted}
          loop={loop}
          width="100%"
          height={`var(${customProperties.height})`}
          style={{ aspectRatio: `var(${customProperties.aspectRatio}, auto)` }}
        />
      ) : (
        isEditorApp && (
          <RenderComponentPlaceholder title="Once you set the Vimeo url, the Vimeo video will appear here." />
        )
      )}
    </div>
  );
};

export default VimeoEmbedV2;
