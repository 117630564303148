import type {
  BillingInformation,
  IntegrationSummary,
} from "@editor/types/sanity";

import { sanityClient } from "@editor/sanity/client";

import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { getFieldMapping } from "./utils/core-reducer-utils";

async function sanityBaseQuery(args: { queryString: string }) {
  try {
    const data = await sanityClient.fetch(args.queryString);
    return { data };
  } catch (error) {
    return { error };
  }
}

export const sanityApi = createApi({
  reducerPath: "sanityApi",
  baseQuery: sanityBaseQuery,
  endpoints: (builder) => ({
    getBillingInformation: builder.query<
      Record<BillingInformation["tier"], BillingInformation>,
      {}
    >({
      query: () => ({
        queryString: `*[_type == "billingInformation"] {
            tier,
            features,
            showOnPricingPlans,
            order,
            }`,
      }),
      transformResponse: (billingInformationArray: BillingInformation[]) => {
        return getFieldMapping(billingInformationArray, "tier");
      },
    }),
    getIntegrations: builder.query<IntegrationSummary[], {}>({
      query: () => ({
        queryString: `*[_type == "integration"] | order(name asc) {
          "slug": slug.current,
          name,
          documentationUrl,
          heroDescription,
          "image": logo.asset->url
        }`,
      }),
    }),
    getModalData: builder.query<
      { buttonText: string; customQuestion: string; order?: number }[],
      {}
    >({
      query: () => ({
        queryString: `*[_type == "downgradeModal"]{
          buttonText,
          customQuestion,
          order
        }`,
      }),
      transformResponse: (response) => {
        return response.map(
          (item: {
            buttonText: string;
            customQuestion: string;
            order?: number;
          }) => ({
            buttonText: item.buttonText,
            customQuestion: item.customQuestion,
            order: item.order,
          }),
        );
      },
    }),
  }),
});

export const {
  useGetBillingInformationQuery,
  useGetIntegrationsQuery,
  useGetModalDataQuery,
} = sanityApi;
