/**
 * Note (Noah, 2023-10-18): These are deprecated, they're the original old action
 * types we used to use before introducing RTK. Use an RTK createSlice() instead!
 * @deprecated
 */
export const EditorActionType = {
  UPDATE_ELEMENT: "UPDATE_ELEMENT",
} as const;

export type EditorActionType =
  (typeof EditorActionType)[keyof typeof EditorActionType];

export default EditorActionType;
