import type { MenuItem } from "@editor/components/common/designSystem/Menu";
import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { RichTextEditorTag } from "@editor/types/rich-text-editor";
import type { Editor as CoreEditor } from "@tiptap/core";
import type { SolidOrGradient } from "replo-runtime/shared/types";
import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";
import type { TextControlType } from "schemas/modifiers";
import type { GradientStop } from "schemas/styleAttribute";
import type { TextShadow } from "schemas/textShadow";

import * as React from "react";

import ToggleGroup from "@common/designSystem/ToggleGroup";
import ResizablePane from "@components/ResizablePane";
import DynamicDataButton from "@editor/components/common/designSystem/DynamicDataButton";
import { Menu } from "@editor/components/common/designSystem/Menu";
import Popover from "@editor/components/common/designSystem/Popover";
import Selectable from "@editor/components/common/designSystem/Selectable";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import DesignLibraryTextValueIndicator from "@editor/components/designLibrary/DesignLibraryTextValueIndicator";
import {
  BADGE_TRIGGER_OFFSET,
  TIPTAP_EDITOR_SCROLLABLE_DIV_ID,
} from "@editor/components/editor/constants";
import ControlGroup from "@editor/components/editor/page/element-editor/components/extras/ControlGroup";
import { useRichTextComponent } from "@editor/components/RichTextComponentContext";
import TipTapToolbar from "@editor/components/TipTapRichTextToolbar";
import Tiptap, { useTipTapEditor } from "@editor/components/TiptapTextEditor";
import useGetDeletedSavedStyleValueIfNeeded from "@editor/hooks/designLibrary/useGetDeletedSavedStyleValueIfNeeded";
import useGetDesignLibrarySavedStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import { useGetModifierControls } from "@editor/hooks/rightBar/useGetModifierControls";
import { useApplyComponentAction } from "@editor/hooks/useApplyComponentAction";
import { useEnableNonDynamicTextEditing } from "@editor/hooks/useEnableNonDynamicTextEditing";
import { useGetAttribute } from "@editor/hooks/useGetAttribute";
import { useGlobalEditorActions } from "@editor/hooks/useGlobalEditorActions";
import { useReploHotkeys } from "@editor/hooks/useHotkeys";
import { useModal } from "@editor/hooks/useModal";
import {
  getTargetFrameDocument,
  useTargetFrameDocument,
} from "@editor/hooks/useTargetFrame";
import { isSavedStylesEnabled } from "@editor/infra/featureFlags";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import {
  selectAncestorTextColor,
  selectColor,
  selectColorGradientStops,
  selectColorGradientTilt,
  selectDraftComponent,
  selectDraftComponentComputedStyleValue,
  selectDraftComponentId,
  selectDraftComponentText,
  selectDraftElementId,
  selectDraftRepeatedIndex,
  selectFontFamily,
  selectFontSize,
  selectFontStyle,
  selectFontWeight,
  selectLineHeight,
  selectParsedTextShadows,
  selectTextAlign,
  selectTextDecoration,
  selectTextOutline,
  selectTextShadow,
  selectTextTransform,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import {
  selectOpenPopoverId,
  setIsRichTextEditorFocused,
  setOpenPopoverId,
} from "@editor/reducers/ui-reducer";
import {
  convertCSSStylesToReploStyles,
  getValidFilteredStyleProps,
} from "@editor/reducers/utils/component-actions";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { getEditorComponentNode } from "@editor/utils/component";
import { getSavedStyleValue } from "@editor/utils/designLibrary";
import { naiveRemoveHtmlTags } from "@editor/utils/dom";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { DraggingDirections } from "@editor/utils/editor";
import { useInCanvasPreview } from "@editor/utils/preview";
import { isAllTextColored } from "@editor/utils/rte";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import {
  getTextOutlineObject,
  getTextOutlineString,
} from "@editor/utils/textOutline";
import {
  formatTitle,
  getTextShadowString,
  parseTextShadows,
} from "@editor/utils/textShadow";
import { DynamicDataValueIndicator } from "@editorExtras/DynamicDataValueIndicator";
import ModifierGroup from "@editorExtras/ModifierGroup";
import LengthInputModifier, {
  LengthInputSelector,
} from "@editorModifiers/LengthInputModifier";
import {
  getCurrentTag,
  getUpdatedLineHeightForFontSize,
} from "@editorModifiers/utils";

import {
  selectActiveCanvas,
  selectActiveCanvasFrame,
} from "@/features/canvas/canvas-reducer";
import { Badge } from "@replo/design-system/components/badge";
import Button from "@replo/design-system/components/button";
import Tooltip from "@replo/design-system/components/tooltip";
import debounce from "lodash-es/debounce";
import differenceBy from "lodash-es/differenceBy";
import { EditorView } from "prosemirror-view";
import { AiOutlineEllipsis, AiOutlineFontSize } from "react-icons/ai";
import { BsBorderWidth } from "react-icons/bs";
import {
  RiAlignCenter,
  RiAlignLeft,
  RiAlignRight,
  RiItalic,
  RiLineHeight,
  RiStrikethrough,
  RiTextSpacing,
  RiUnderline,
} from "react-icons/ri";
import { isDynamicDataValue } from "replo-runtime";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { isDynamicDesignLibraryValue } from "replo-runtime/shared/utils/designLibrary";
import { FONT_WEIGHT_OPTIONS } from "replo-runtime/shared/utils/font";
import {
  CSS_LENGTH_TYPES,
  CSS_LENGTH_TYPES_WITH_COMPUTED,
} from "replo-runtime/shared/utils/units";
import { coerceNumberToString, isNotNullish } from "replo-utils/lib/misc";
import { v4 as uuidv4 } from "uuid";

import ModifierLabel from "../extras/ModifierLabel";
import DynamicColorModifier, {
  DynamicColorSelector,
} from "./DynamicColorModifier";
import FontFamilyControl from "./FontFamilyControl";

// NOTE (Reinaldo 2022-06-03): Hacky solution to fix editor breaking when selecting a text node
// https://github.com/ueberdosis/tiptap/issues/1451#issuecomment-953348865
EditorView.prototype.updateState = function updateState(state) {
  // @ts-ignore
  if (!this.docView) {
    return;
  } // This prevents the matchesNode error on hot reloads
  // @ts-ignore
  this.updateStateInner(state, this.state.plugins != state.plugins);
};

const DEFAULT_TEXT_SHADOW = {
  id: uuidv4(),
  offsetX: "0px",
  offsetY: "4px",
  blur: "1px",
  color: "#00000040",
};

const TEXT_TRANSFORM_OPTIONS = [
  {
    value: "capitalize",
    label: "Ag",
    tooltipContent: "Capitalize",
  },
  {
    value: "uppercase",
    label: "AG",
    tooltipContent: "Uppercase",
  },
  {
    value: "lowercase",
    label: "ag",
    tooltipContent: "Lowercase",
  },
];

const MIN_EDITOR_HEIGHT = 40;
const CUTOFF_EDITOR_EXPANSION = 200;

const TextStyleModifier: React.FC = () => {
  const modal = useModal();
  const text = useEditorSelector(selectDraftComponentText);
  useTextStyleHotkeys();

  const handleOpenDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.TEXT,
        referrerData: {
          type: "prop",
          propId: "text",
        },
        initialPath: getPathFromVariable(text),
      },
    });
  };

  const [controls, addControl] = useGetModifierControls<"text">("text");
  const handleAddTextShadow = useAddDefaultShadow();
  const fontSize = useEditorSelector(selectFontSize);
  const savedStylesEnabled = isSavedStylesEnabled();
  const isDesignLibraryValue = isDynamicDesignLibraryValue(
    String(fontSize) ?? "",
  );

  const menuItems: MenuItem[] = [
    {
      id: "textDecoration",
      title: "Format",
      type: "leaf",
      onSelect: () => addControl("textDecoration"),
      isDisabled: controls.has("textDecoration") || isDesignLibraryValue,
    },
    {
      id: "textTransform",
      title: "Casing",
      type: "leaf",
      onSelect: () => addControl("textTransform"),
      isDisabled: controls.has("textTransform") || isDesignLibraryValue,
    },
    {
      id: "textOutline",
      title: "Outline",
      type: "leaf",
      onSelect: () => addControl("textOutline"),
      isDisabled: controls.has("textOutline") || isDesignLibraryValue,
    },
    {
      id: "textShadow",
      title: "Shadow",
      type: "leaf",
      onSelect: () => {
        addControl("textShadow");
        handleAddTextShadow();
      },
      isDisabled: isDesignLibraryValue,
    },
  ];

  const mapControlsToComponent: Array<{
    property: TextControlType;
    component: React.ReactNode;
  }> = [
    { property: "textAlign", component: <TextAlignmentControl /> },
    {
      property: "fontFamily",
      component: (
        <div id="font-family-selectable">
          <FontFamilyControl />
        </div>
      ),
    },
    {
      property: "fontWeight",
      component: <FontWeightControl />,
    },
    {
      property: "fontSize",
      component: <FontSizeControl />,
    },
    {
      property: "lineHeight",
      component: <LineHeightControl />,
    },
    { property: "color", component: <ForegroundColorControl /> },
    {
      property: "letterSpacing",
      component: <LetterSpacingControl />,
    },
    {
      property: "textDecoration",
      component: <TextFormatControl />,
    },
    {
      property: "htmlTag",
      component: <TagControl />,
    },
    {
      property: "textTransform",
      component: <TextTransformControl />,
    },
    {
      property: "textOutline",
      component: <TextOutlineControl />,
    },
    {
      property: "textShadow",
      component: <TextShadowControl />,
    },
  ];

  return (
    <ModifierGroup
      title="Text"
      menuItems={menuItems}
      tooltipText="Add Additional Text Format"
      // Note (Noah, 2024-10-25): Disable focusing the menu trigger on close, since this will
      // dismiss the popover that opens when we add a text shadow
      disableMenuTriggerFocusOnClose
      endEnhancer={<HeaderMenu />}
    >
      <div id="text-style-modifier" className="flex flex-col gap-2">
        <TextControl handleOpenDynamicData={handleOpenDynamicData} />
        {savedStylesEnabled && <TextSavedStyleGroup />}
        {mapControlsToComponent.map(({ property, component }) => {
          if (controls.has(property) && component) {
            return <React.Fragment key={property}>{component}</React.Fragment>;
          }
          return null;
        })}
      </div>
    </ModifierGroup>
  );
};

const HeaderMenu: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const colorValue = useEditorSelector(selectColor);
  const textValue = useEditorSelector(selectDraftComponentText);
  return (
    <Menu
      items={[
        {
          type: "leaf",
          id: "pasteFromFigma",
          title: "Paste Style From Figma",
          onSelect: () => {
            void navigator.clipboard.readText().then((text) => {
              const styleObject = convertCSSStylesToReploStyles(text, true);
              const { style: textValidStyles } = getValidFilteredStyleProps(
                { style: styleObject },
                "text",
                { colorValue: colorValue ?? null, textValue },
              );
              applyComponentAction({
                type: "setStyles",
                value: textValidStyles ?? {},
              });
            });
          },
        },
      ]}
      customWidth={200}
      trigger={
        <Button
          // NOTE (Chance 2023-11-02): `Menu` renders a button by default so this
          // is just for the styles
          variant="secondary"
          style={{ minWidth: 0 }}
          className="h-6 w-6"
          isPhonyButton
        >
          <AiOutlineEllipsis className="h-4 w-4" />
        </Button>
      }
    />
  );
};

const TextControl: React.FC<{
  handleOpenDynamicData: () => void;
}> = ({ handleOpenDynamicData }) => {
  const text = useEditorSelector(selectDraftComponentText);

  return typeof text === "string" && text.includes("{{") ? (
    <DynamicTextControl handleOpenDynamicData={handleOpenDynamicData} />
  ) : (
    <RichTextControl handleOpenDynamicData={handleOpenDynamicData} />
  );
};

const DynamicTextControl: React.FC<{
  handleOpenDynamicData: () => void;
}> = ({ handleOpenDynamicData }) => {
  const text = useEditorSelector(selectDraftComponentText);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const applyComponentAction = useApplyComponentAction();

  return (
    <div className="col-span-2">
      <DynamicDataValueIndicator
        type="text"
        templateValue={text}
        componentId={draftComponentId ?? undefined}
        onClick={handleOpenDynamicData}
        onRemove={() => {
          applyComponentAction({
            type: "applyCompositeAction",
            value: [
              {
                type: "setStyles",
                value: {
                  fontWeight: "normal",
                  textDecoration: "none",
                  fontStyle: "normal",
                },
                analyticsExtras: {
                  actionType: "edit",
                  createdBy: "replo",
                },
              },
              {
                type: "setProps",
                value: { text: "Add new text here" },
                analyticsExtras: {
                  actionType: "edit",
                  createdBy: "replo",
                },
              },
            ],
          });
        }}
      />
    </div>
  );
};

const toolbarId = "pane-rte-toolbar";

const RichTextControl: React.FC<{ handleOpenDynamicData(): void }> = ({
  handleOpenDynamicData,
}) => {
  const dispatch = useEditorDispatch();

  const { applyChanges, setTipTapEditor, setTag } = useRichTextComponent();
  const onChangeRichTextEditor = React.useMemo(
    () => debounce((newValue: string) => applyChanges(newValue), 300),
    [applyChanges],
  );

  const onBlurRichTextEditor = React.useCallback(() => {
    dispatch(setIsRichTextEditorFocused(false));
  }, [dispatch]);

  const onFocusRichTextEditor = React.useCallback(() => {
    dispatch(setIsRichTextEditorFocused(true));
  }, [dispatch]);

  const onSelectionUpdate = (editor: CoreEditor | null) => {
    setTag(getCurrentTag(editor));
  };

  const onCreate = (editor: CoreEditor | null) => {
    setTag(getCurrentTag(editor));
  };

  const text = useEditorSelector(selectDraftComponentText);
  const tipTapEditor = useTipTapEditor(
    text,
    onChangeRichTextEditor,
    onBlurRichTextEditor,
    onFocusRichTextEditor,
    onSelectionUpdate,
    onCreate,
  );

  const isRichTextEditorFocused = useEditorSelector(
    (state) => state.ui.isRichTextEditorFocused,
  );

  const [shouldAutoResize, setShouldAutoResize] = React.useState(true);
  const [resizableHeight, setResizableHeight] =
    React.useState(MIN_EDITOR_HEIGHT);
  const [currentEditorHeight, setCurrentEditorHeight] =
    React.useState(MIN_EDITOR_HEIGHT);

  // Synchronize text changes to the editor that don't originate from the
  // editor.
  const textRef = React.useRef(text);
  React.useEffect(() => {
    const prevText = textRef.current;
    textRef.current = text;
    if (!isRichTextEditorFocused && tipTapEditor && text !== prevText) {
      tipTapEditor.commands.setContent(text!);
      setShouldAutoResize(true);
    }
  }, [isRichTextEditorFocused, tipTapEditor, text]);

  React.useEffect(() => {
    // pass this instance of the editor to context so it can be used elsewhere
    if (tipTapEditor) {
      setTipTapEditor(tipTapEditor);
    }
    // Make sure to remove the editor from context when this component is
    // unmounted
    return () => {
      setTipTapEditor(null);
    };
  }, [setTipTapEditor, tipTapEditor]);

  // NOTE (Max 2024-05-08): Listens for when editor's height changes, as we need
  // to update the resizable's component height accordingly. We only allow to
  // automatically increase the height until cutoffEditorExpansion
  React.useEffect(() => {
    const editorDiv = document.getElementById(TIPTAP_EDITOR_SCROLLABLE_DIV_ID);

    if (editorDiv && shouldAutoResize) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const { height } = entry.contentRect;
          setCurrentEditorHeight(height);
          if (height <= CUTOFF_EDITOR_EXPANSION) {
            // NOTE (Sebas, 2024-10-22): If the text editor is smaller than the minimum height, we set the
            // current editor height to the minimum height. This is needed to prevent a weird flash on the
            // text editor when the user selects a text component. Also if the user tries to expand the editor
            // the size will be set automatically to the minimum height without moving the mouse, which was weird.
            setResizableHeight(
              height <= MIN_EDITOR_HEIGHT ? MIN_EDITOR_HEIGHT : height + 10,
            );
          } else {
            setResizableHeight(CUTOFF_EDITOR_EXPANSION);
          }
        }
      });

      resizeObserver.observe(editorDiv);
      return () => resizeObserver.disconnect();
    }
  }, [shouldAutoResize]);

  // NOTE (Max 2024-05-08): In the context of the ResizablePane's height being larger than the
  // actual editor's height, we want the user to be able to click on ResizablePane and still
  // trigger the editor. Otherwise, the only place where user could click to start typing
  // would be within the actual editor - not good UX.
  const propagateClick = () => {
    const editorDiv = document.getElementById(
      "in-tiptap-editor",
    ) as HTMLElement | null;
    editorDiv?.focus();
  };

  return (
    <>
      <div
        className="col-span-2"
        data-testid="rich-text-editor"
        // NOTE (Sebas, 2024-06-27): We need to stop propagation of the keydown event
        // to prevent the hotkeys from being triggered.
        onKeyDown={(e) => e.stopPropagation()}
      >
        <div className="rounded bg-subtle  focus-within:outline focus-within:outline-1 p-2 flex flex-col gap-2">
          <TipTapToolbar editor={tipTapEditor} id={toolbarId} />
          <div onClick={propagateClick}>
            <ResizablePane
              isVertical
              minSize={MIN_EDITOR_HEIGHT}
              maxSize={1500}
              size={resizableHeight}
              onResize={(newSize) => {
                setResizableHeight(newSize);
              }}
              onResizeStart={() => setShouldAutoResize(false)}
              contentClassName={`cursor-text styled-scrollbar ${currentEditorHeight > resizableHeight ? "overflow-auto" : "overflow-none"}`}
            >
              <div
                id={TIPTAP_EDITOR_SCROLLABLE_DIV_ID}
                className="pb-2 text-xs w-full h-max"
              >
                <Tiptap editor={tipTapEditor} />
              </div>
              <div className="absolute right-0 bottom-0">
                <DynamicDataButton onClick={handleOpenDynamicData} />
              </div>
            </ResizablePane>
          </div>
        </div>
      </div>
    </>
  );
};

const tagOptions: {
  label: string;
  value: RichTextEditorTag;
}[] = [
  {
    label: "Paragraph",
    value: "P",
  },
  {
    label: "Heading 1",
    value: "1",
  },
  {
    label: "Heading 2",
    value: "2",
  },
  {
    label: "Heading 3",
    value: "3",
  },
  {
    label: "Heading 4",
    value: "4",
  },
  {
    label: "Heading 5",
    value: "5",
  },
  {
    label: "Heading 6",
    value: "6",
  },
];

const TagControl: React.FC = () => {
  const { setTag, currentTag } = useRichTextComponent();
  const applyComponentAction = useApplyComponentAction();
  const text = useEditorSelector(selectDraftComponentText);

  const onChangeTag = (tag: RichTextEditorTag) => {
    if (isDynamicDataValue(text)) {
      const dynamicString = naiveRemoveHtmlTags(text);
      applyComponentAction({
        type: "setProps",
        value: {
          text:
            tag === "P"
              ? `<p>${dynamicString}</p>`
              : `<h${tag}>${dynamicString}</h${tag}>`,
        },
      });
    } else {
      setTag(tag);
    }
  };

  return (
    <div className="flex items-center w-full">
      <ModifierLabel label="Tag" />
      <Selectable
        className="w-full"
        placeholder="Select tag"
        defaultValue="P"
        value={currentTag}
        options={tagOptions}
        onSelect={onChangeTag}
      />
    </div>
  );
};

const calculateFontWeights = (
  paintableDocument: Document | null,
  fontFamily: string | null,
  isSafeOrNotAvailableFont?: boolean,
) => {
  if (!fontFamily || isSafeOrNotAvailableFont) {
    return FONT_WEIGHT_OPTIONS.map(({ label, value }) => ({
      label,
      value: value.toString(),
    }));
  }
  if (!paintableDocument) {
    return [];
  }
  const editorFontsArray = Array.from(paintableDocument.fonts);
  return FONT_WEIGHT_OPTIONS.filter((fontWeight) =>
    editorFontsArray
      .filter((font) => fontFamily.includes(font.family))
      .find((font) => font.weight === fontWeight.value.toString()),
  ).map(({ label, value }) => ({
    label,
    value: value.toString(),
  }));
};

const useFontWeightOptions = () => {
  const fontFamily = useEditorSelector(selectFontFamily) ?? null;
  // NOTE (Chance 2024-06-24): It shouldn't matter which canvas we use here
  // since the DOM contents should be the same for each. We just need to read
  // the document's `fonts` property.
  const targetFrameDocument = useTargetFrameDocument("desktop");
  // NOTE (Sebas, 2023-03-27): This counter is required to avoid
  // an infinite loop in case the font is not found on the document.
  // This can happen if the user selects a safe font, like Arial,
  // or if the font is not being imported correctly.
  const intervalCounter = React.useRef(0);
  const [options, setOptions] = React.useState(
    calculateFontWeights(targetFrameDocument, fontFamily),
  );
  // NOTE (Sebas, 2023-03-21): We need this effect because we need a timeout in case the
  // user selects a new font we need to wait for the font to be downloaded/added to the
  // DOM and then calculate the available weights for that font.
  React.useEffect(() => {
    const interval = setInterval(() => {
      const newOptions = calculateFontWeights(
        targetFrameDocument,
        fontFamily,
        intervalCounter.current > 5,
      );
      setOptions(newOptions);
      intervalCounter.current += 1;
      if (newOptions.length > 0) {
        clearInterval(interval);
        intervalCounter.current = 0;
      }
    }, 500);
    return () => {
      clearInterval(interval);
      intervalCounter.current = 0;
    };
  }, [fontFamily, targetFrameDocument]);

  return options;
};

const FontWeightControl: React.FC<React.PropsWithChildren<unknown>> = () => {
  const applyComponentAction = useApplyComponentAction();
  const fontWeightFromSelector = useEditorSelector(selectFontWeight);
  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const savedStyleFontWeightValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleTextAttributes>(
      String(fontWeightFromSelector) ?? null,
    )?.fontWeight;
  const fontWeight = savedStyleFontWeightValue ?? fontWeightFromSelector;

  const options = useFontWeightOptions();

  return (
    <ControlGroup label="Weight">
      <Selectable
        className="w-full"
        placeholder="Weight"
        options={options}
        ignoreValueMismatchError
        value={fontWeight?.toString()}
        onSelect={(newValue: string) =>
          applyComponentAction({
            type: "setStyles",
            value: {
              fontWeight: newValue,
            },
          })
        }
      />
    </ControlGroup>
  );
};

const FontSizeControl: React.FC = () => {
  const fontSizeFromSelector = useEditorSelector(selectFontSize);
  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleTextAttributes>(
      String(fontSizeFromSelector) ?? null,
    )?.fontSize;
  const fontSize = deletedSavedStyleValue ?? fontSizeFromSelector;

  const draftComponent = useEditorSelector(selectDraftComponent);
  const draftElementId = useEditorSelector(selectDraftElementId);
  const lineHeight = useEditorSelector(selectLineHeight);
  const applyComponentAction = useApplyComponentAction();
  const getAttribute = useGetAttribute();
  const {
    setPreviewCSSPropertyValue,
    enableCanvasPreviewCSSProperties,
    disableCanvasPreviewCSSProperties,
  } = useInCanvasPreview();

  const draftRepeatedIndex = useEditorSelector(selectDraftRepeatedIndex);

  const activeCanvasFrame = useEditorSelector(selectActiveCanvasFrame);
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const targetDocument = getTargetFrameDocument(activeCanvasFrame);

  if (!draftComponent) {
    return null;
  }

  const fontSizeMenuItems = [
    "Reset",
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "24px",
    "32px",
  ].map((v) => ({
    label: v,
    value: v === "Reset" ? null : v,
  }));

  // Note (Noah, 2022-12-09, REPL-5261): Calculate the computed value of the
  // node's font size in order to use it as a placeholder if no value is set. We
  // actually use the parent node's computed value here, to avoid an issue where
  // when you reset the font size, the input will still show the old value as
  // the placeholder because React hasn't yet completed its DOM commits (this is
  // fine, since it's the same value on the parent due to CSS cascading)
  const draftComponentNode = targetDocument
    ? getEditorComponentNode({
        targetDocument,
        canvas: activeCanvas,
        elementId: draftElementId,
        componentId: draftComponent.id,
        repeatedId: draftRepeatedIndex,
      })?.parentElement
    : null;
  const propertyForPlaceholder = draftComponentNode
    ? getComputedStyle(draftComponentNode).fontSize
    : null;

  return (
    <LengthInputModifier
      label={<ModifierLabel label="Size" />}
      field="style.fontSize"
      value={fontSize?.toString()}
      dragTrigger="label"
      minDragValues={{ px: 0 }}
      minValues={{ px: 0 }}
      allowsNegativeValue={false}
      anchorValue="16px"
      resetValue={null}
      placeholder={propertyForPlaceholder ?? "Font Size"}
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      startEnhancer={() => (
        <Tooltip inheritCursor content="Font Size" triggerAsChild>
          <span tabIndex={0}>
            <AiOutlineFontSize />
          </span>
        </Tooltip>
      )}
      menuOptions={fontSizeMenuItems}
      onPreviewChange={(value: string) => {
        const lineHeight = getUpdatedLineHeightForFontSize(
          value,
          draftComponent,
          getAttribute,
        );
        setPreviewCSSPropertyValue(["lineHeight"], lineHeight);
      }}
      onDragStart={() => {
        const lineHeightValue = lineHeight ?? "normal";
        enableCanvasPreviewCSSProperties(
          ["lineHeight"],
          String(lineHeightValue),
        );
        setPreviewCSSPropertyValue(["lineHeight"], String(lineHeightValue));
      }}
      onDragEnd={() => {
        disableCanvasPreviewCSSProperties(["lineHeight"]);
      }}
      onChange={(value: string) => {
        const lineHeight = getUpdatedLineHeightForFontSize(
          value,
          draftComponent,
          getAttribute,
        );
        applyComponentAction({
          type: "setStyles",
          value: {
            fontSize: value,
            lineHeight,
          },
        });
      }}
      previewProperty="fontSize"
    />
  );
};

const LineHeightControl: React.FC<React.PropsWithChildren<unknown>> = () => {
  const applyComponentAction = useApplyComponentAction();
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const fontSize = useEditorSelector(selectFontSize);
  const computedFontSize =
    useEditorSelector((state) =>
      selectDraftComponentComputedStyleValue(state, "fontSize", activeCanvas),
    ) ?? "16px";
  const lineHeightDefaultValue =
    styleAttributeToEditorData.lineHeight.defaultValue;

  const lineHeightFromSelector = useEditorSelector(selectLineHeight);
  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleTextAttributes>(
      String(lineHeightFromSelector) ?? null,
    )?.lineHeight;
  const lineHeight = deletedSavedStyleValue ?? lineHeightFromSelector;

  return (
    <LengthInputModifier
      label={<ModifierLabel label="Line" />}
      startEnhancer={() => (
        <Tooltip inheritCursor content="Line Height" triggerAsChild>
          <span tabIndex={0}>
            <RiLineHeight />
          </span>
        </Tooltip>
      )}
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      field="style.lineHeight"
      value={lineHeight?.toString()}
      anchorValue={fontSize ? String(fontSize) : String(computedFontSize)}
      dragTrigger="label"
      minDragValues={{ px: 0 }}
      minValues={{ px: 0 }}
      resetValue={lineHeightDefaultValue}
      allowsNegativeValue={false}
      previewProperty="lineHeight"
      onChange={(newValue: string) => {
        applyComponentAction({
          type: "setStyles",
          value: {
            lineHeight: newValue === "auto" ? lineHeightDefaultValue : newValue,
          },
        });
      }}
    />
  );
};

const LetterSpacingControl: React.FC<React.PropsWithChildren<unknown>> = () => {
  const applyComponentAction = useApplyComponentAction();
  const letterSpacingDefaultValue =
    styleAttributeToEditorData.letterSpacing.defaultValue;

  return (
    <LengthInputModifier
      label={<ModifierLabel label="Spacing" />}
      startEnhancer={() => (
        <Tooltip inheritCursor content="Letter Spacing" triggerAsChild>
          <span tabIndex={0}>
            <RiTextSpacing />
          </span>
        </Tooltip>
      )}
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      field="style.letterSpacing"
      resetValue={letterSpacingDefaultValue}
      anchorValue="1px"
      dragTrigger="label"
      onChange={(newValue: string) =>
        applyComponentAction({
          type: "setStyles",
          value: {
            letterSpacing: newValue,
          },
        })
      }
      menuOptions={[
        { label: "Reset", value: "" },
        { label: "1px", value: "1px" },
        { label: "2px", value: "2px" },
        { label: "4px", value: "4px" },
      ]}
      previewProperty="letterSpacing"
    />
  );
};

const normalizeTextDirectionForInput = (
  textDirection?: CSSStyleDeclaration["textAlign"] | null,
) => {
  if (textDirection === "start") {
    return "left";
  } else if (textDirection === "end") {
    return "right";
  }
  return textDirection;
};

const TextAlignmentControl: React.FC<React.PropsWithChildren<unknown>> = () => {
  const applyComponentAction = useApplyComponentAction();
  const textAlignFromSelector = useEditorSelector(selectTextAlign);
  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleTextAttributes>(
      String(textAlignFromSelector) ?? null,
      true,
    )?.textAlign;
  const textAlign = deletedSavedStyleValue ?? textAlignFromSelector;
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const computedStyleValue =
    normalizeTextDirectionForInput(
      useEditorSelector((state) =>
        selectDraftComponentComputedStyleValue(
          state,
          "textAlign",
          activeCanvas,
        ),
      ),
    ) ?? null;

  const options = [
    {
      value: "left",
      label: <RiAlignLeft size={16} />,
      tooltipContent: "Align Text Left",
    },
    {
      value: "center",
      label: <RiAlignCenter size={16} />,
      tooltipContent: "Align Text Center",
    },
    {
      value: "right",
      label: <RiAlignRight size={16} />,
      tooltipContent: "Align Text Right",
    },
  ];

  return (
    <ControlGroup label="Alignment">
      <ToggleGroup
        type="single"
        style={{
          // TODO (Fran 2024-10-09 REPL-14029): Make the changes need it in the ToggleGroup component to use
          // w-full instead of this.
          width: "100%",
        }}
        value={textAlign ?? computedStyleValue}
        options={options}
        onChange={(newValue: string) => {
          applyComponentAction({
            type: "setStyles",
            value: { textAlign: newValue },
          });
        }}
      />
    </ControlGroup>
  );
};

const TextFormatControl: React.FC = () => {
  const textDecorationFromSelector = useEditorSelector(selectTextDecoration);
  const fontStyle = useEditorSelector(selectFontStyle);
  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleTextAttributes>(
      String(textDecorationFromSelector) ?? null,
    )?.textDecoration;
  const textDecoration = deletedSavedStyleValue ?? textDecorationFromSelector;

  const { tipTapEditor, queueAction, clearQueuedActions } =
    useRichTextComponent();

  const applyComponentAction = useApplyComponentAction();

  const selectedValues = [textDecoration, fontStyle]
    .filter(isNotNullish)
    .map((value) => String(value));

  function onChange(newValue: string, isSelected: boolean) {
    let chain = tipTapEditor?.chain().selectAll();
    let action: UseApplyComponentActionType | undefined;
    if (newValue !== "italic") {
      let textDecoration;
      if (
        newValue === "line-through" &&
        textDecoration !== newValue &&
        isSelected
      ) {
        textDecoration = "line-through";
      } else if (
        newValue === "underline" &&
        textDecoration !== newValue &&
        isSelected
      ) {
        textDecoration = "underline";
      } else {
        textDecoration = "none";
      }
      action = {
        type: "setStyles",
        value: { textDecoration },
      };
      queueAction(action);
      chain = chain?.unsetStrike().unsetUnderline();
    } else if (newValue === "italic") {
      let fontStyle;
      if (fontStyle !== newValue && isSelected) {
        fontStyle = "italic";
      } else {
        fontStyle = "normal";
      }
      action = {
        type: "setStyles",
        value: { fontStyle },
      };
      queueAction(action);
      chain = chain?.unsetItalic();
    }
    const prevHtml = tipTapEditor?.getHTML();
    chain?.run();
    const postHtml = tipTapEditor?.getHTML();
    // if the html is the same, then we need to manually apply the changes
    if (prevHtml === postHtml && action) {
      applyComponentAction(action);
      clearQueuedActions();
    }
  }

  const options = [
    {
      value: "italic",
      label: <RiItalic size={16} />,
      tooltipContent: "Italic",
    },
    {
      value: "line-through",
      label: <RiStrikethrough size={16} />,
      tooltipContent: "Strikethrough",
    },
    {
      value: "underline",
      label: <RiUnderline size={16} />,
      tooltipContent: "Underline",
    },
  ];

  const handleOnChange = (values: string[]) => {
    if (selectedValues.length > values.length) {
      const [deselectValue] = differenceBy(selectedValues, values);
      // NOTE (Chance 2023-12-05): Non-null assertion is safe here because
      // we know checked that `selectedValues.length > values.length`, which
      // means it's not empty.
      onChange(deselectValue!, false);
    } else if (values.length > selectedValues.length) {
      // NOTE (Chance 2023-12-05): Non-null assertion is safe here because
      // we know checked that `values.length > selectedValues.length`, which
      // means it's not empty.
      const [selectValue] = differenceBy(values, selectedValues);
      onChange(selectValue!, true);
    }
  };

  return (
    <ControlGroup label="Format">
      <ToggleGroup
        type="multi"
        style={{
          // TODO (Fran 2024-10-09 REPL-14029): Make the changes need it in the ToggleGroup component to use
          // w-full instead of this.
          width: "100%",
        }}
        value={selectedValues}
        options={options}
        onChange={handleOnChange}
      />
    </ControlGroup>
  );
};

const TextTransformControl: React.FC<React.PropsWithChildren> = () => {
  const applyComponentAction = useApplyComponentAction();
  const textTransform = useEditorSelector(selectTextTransform);

  const handleOnChange = (newValue: string | null) => {
    applyComponentAction({
      type: "setStyles",
      value: { textTransform: !newValue ? "none" : newValue },
    });
  };

  return (
    <ControlGroup label="Casing">
      <ToggleGroup
        type="single"
        style={{
          // TODO (Fran 2024-10-09 REPL-14029): Make the changes need it in the ToggleGroup component to use
          // w-full instead of this.
          width: "100%",
        }}
        allowsDeselect
        value={textTransform ?? "none"}
        options={TEXT_TRANSFORM_OPTIONS}
        onChange={handleOnChange}
      />
    </ControlGroup>
  );
};

const ForegroundColorControl: React.FC = () => {
  // NOTE (Fran 2024-04-15): Given the text color inheritance, we need to show if the component
  // has a color or if any ancestor text color will apply.
  const ancestorTextColor = useEditorSelector(selectAncestorTextColor);
  const color = useEditorSelector(selectColor);
  const colorGradientTilt = useEditorSelector(selectColorGradientTilt);
  const colorGradientStops = useEditorSelector(
    selectColorGradientStops,
  ) as GradientStop[];

  const applyComponentAction = useApplyComponentAction();

  // if there is a instance of the tipTapEditor being used by the rich text
  // control we must use the same one and queue our changes
  const { tipTapEditor, queueAction } = useRichTextComponent();

  const changeColor = (newValue: string | SolidOrGradient) => {
    let gradientOrSolid = null;
    if (typeof newValue === "string") {
      gradientOrSolid = newValue;
    } else if (newValue.type === "solid") {
      gradientOrSolid = newValue.color;
    } else {
      gradientOrSolid = newValue.gradient;
    }

    const newStyleValue =
      gradientOrSolid && typeof gradientOrSolid === "object"
        ? {
            color: "alchemy:gradient",
            __alchemyGradient__color__tilt: gradientOrSolid?.tilt,
            __alchemyGradient__color__stops: gradientOrSolid?.stops,
            // NOTE (Fran 2024-11-27): Remove gradient data from design library when setting gradient color
            __reploGradient__color__design_library: null,
          }
        : {
            color: gradientOrSolid,
            // NOTE (Fran 2024-11-27): Remove gradient data when setting solid color
            __alchemyGradient__color__tilt: null,
            __alchemyGradient__color__stops: null,
            __reploGradient__color__design_library: null,
          };
    const action: UseApplyComponentActionType = {
      type: "setStyles",
      value: newStyleValue,
    };
    // If all text in the editor already has a color then we've got to unset it
    // in order for the changes to have an effect.
    if (tipTapEditor && isAllTextColored(tipTapEditor)) {
      queueAction(action);
      tipTapEditor?.chain().selectAll().unsetColor().run();
    } else {
      applyComponentAction(action);
    }
  };

  const modifierValue = color ?? ancestorTextColor;
  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleTextAttributes>(
      String(modifierValue) ?? null,
    )?.color;
  const colorValue = deletedSavedStyleValue ?? modifierValue;

  const onRemove = () => {
    // NOTE (Fran 2024-05-09): If the color is not set we will show the inherited color from any
    // ancestor, so in this case, we should set the color to transparent. If the color is set, we
    // should remove it and show the inherited color.
    const onRemoveNewColor = color ? null : "#00000000";
    changeColor({
      type: "solid",
      color: onRemoveNewColor,
    });
  };

  return (
    <ControlGroup label="Color">
      <DynamicColorModifier
        previewProperty="color"
        popoverTitle="Text Color"
        gradientSelectionType="color"
        gradientData={{
          tilt: coerceNumberToString(colorGradientTilt) ?? "90deg",
          stops: colorGradientStops ?? [
            {
              id: "c7795a8c-4e13-4011-889b-64adb0e11e41",
              color: "#df9393",
              location: "0%",
            },
          ],
        }}
        field="style.color"
        value={colorValue ?? undefined}
        onChange={changeColor}
        onRemove={onRemove}
        popoverSideOffset={BADGE_TRIGGER_OFFSET}
        showSavedStyles
      />
    </ControlGroup>
  );
};

const TextOutlineControl: React.FC = () => {
  const store = useEditorStore();
  const applyComponentAction = useApplyComponentAction();
  const componentId = useEditorSelector(selectDraftComponentId);
  const textOutlineFromSelector = useEditorSelector(selectTextOutline);
  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleTextAttributes>(
      String(textOutlineFromSelector) ?? null,
    )?.textStroke;
  const textOulineValue = deletedSavedStyleValue ?? textOutlineFromSelector;

  const textOutline = textOulineValue
    ? getTextOutlineObject(textOulineValue)
    : null;

  if (!componentId) {
    return null;
  }
  const handleInputChange = (value: string, inputType: "width" | "color") => {
    let textOutlineString = null;
    if (value && value !== "0px") {
      const newTextOutline = {
        width: textOutline?.width || "1px",
        color: textOutline?.color || "#000000",
        [inputType]: value,
      };
      textOutlineString = getTextOutlineString(newTextOutline);
    }
    const componentId = selectDraftComponentId(store.getState());
    applyComponentAction({
      componentId,
      type: "setStyles",
      value: {
        __textStroke: textOutlineString,
      },
    });
  };
  const onSavedOutlineSelect = (value: string) => {
    let textOutlineString = null;

    if (value) {
      const newTextOutline = {
        width: textOutline?.width || "1px",
        color: value,
      };
      textOutlineString = getTextOutlineString(newTextOutline);
    }
    applyComponentAction({
      type: "setStyles",
      value: {
        __textStroke: textOutlineString,
      },
    });
  };

  return (
    <>
      <LengthInputSelector.Root
        metrics={CSS_LENGTH_TYPES}
        className="col-span-1"
        minDragValues={{ px: 0 }}
        minValues={{ px: 0 }}
        maxValues={{ px: 30 }}
        maxDragValues={{ px: 30 }}
        field="width"
        resetValue="0px"
        anchorValue="0px"
        value={textOutline?.width ?? null}
        onChange={(value: string) => handleInputChange(value, "width")}
        previewProperty="__textStroke"
        previewSubProperty="width"
        dragTrigger="label"
      >
        {/* NOTE (Fran 2024-10-16): 74px is the fixed width of the label. */}
        <div className="grid grid-cols-[74px,auto] w-full gap-y-2 items-center">
          <LengthInputSelector.DraggableArea>
            <ModifierLabel label="Outline" />
          </LengthInputSelector.DraggableArea>
          <LengthInputSelector.Input
            placeholder="0px"
            startEnhancer={() => <BsBorderWidth />}
          />
          <div className="col-start-2">
            <DynamicColorSelector
              field="color"
              componentId={componentId}
              allowsGradientSelection={false}
              popoverTitle="Color"
              value={textOutline?.color ?? null}
              onChange={(value: string) => handleInputChange(value, "color")}
              popoverSideOffset={BADGE_TRIGGER_OFFSET}
              showSavedStyles
              onSavedStyleSelect={onSavedOutlineSelect}
            />
          </div>
        </div>
      </LengthInputSelector.Root>
    </>
  );
};

const TextShadowPopover: React.FC<
  React.PropsWithChildren<{
    isOpen: boolean;
    activeTextShadow: TextShadow | undefined;
    textShadowIndex: number;
    handleTextShadowChange(value: TextShadow): void;
    onRequestClose(): void;
  }>
> = ({
  isOpen,
  activeTextShadow,
  handleTextShadowChange,
  textShadowIndex,
  onRequestClose,
}) => {
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);

  if (!activeTextShadow) {
    return null;
  }

  const handleInputChange = (
    value: string,
    inputType: "offsetX" | "offsetY" | "blur" | "color",
  ) => {
    if (!activeTextShadow) {
      return;
    }

    const newTextShadow = {
      ...activeTextShadow,
      [inputType]: value,
    };
    handleTextShadowChange(newTextShadow);
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpenChange={(newValue: boolean) => {
        if (!newValue) {
          onRequestClose();
        }
      }}
    >
      <Popover.Content
        title="Text Shadow"
        shouldPreventDefaultOnInteractOutside={areModalsOpen}
        onRequestClose={onRequestClose}
      >
        <TextShadowPopoverContent
          offsetX={activeTextShadow.offsetX}
          offsetY={activeTextShadow.offsetY}
          blur={activeTextShadow.blur}
          color={activeTextShadow.color}
          textShadowIndex={textShadowIndex}
          handleInputChange={handleInputChange}
        />
      </Popover.Content>
      <Popover.Anchor className="relative top-0 left-0" />
    </Popover>
  );
};

const TextShadowControl: React.FC = () => {
  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const textShadowFromSelector = useEditorSelector(selectTextShadow);
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleTextAttributes>(
      String(textShadowFromSelector) ?? null,
    )?.textShadow;
  const draftComponentParsedTextShadows = useEditorSelector(
    selectParsedTextShadows,
  );
  const savedStyleParsedTextShadows = deletedSavedStyleValue
    ? parseTextShadows(deletedSavedStyleValue.split(",") ?? "")
    : // TODO (Fran 2024-11-29 REPL-14885): Allow add a text shadow if there is no shadow selected in the
      // deleted saved style
      [DEFAULT_TEXT_SHADOW];
  const draftComponentTextShadows = isDynamicDesignLibraryValue(
    String(textShadowFromSelector),
  )
    ? savedStyleParsedTextShadows
    : draftComponentParsedTextShadows;

  const openPopoverId = useEditorSelector(selectOpenPopoverId);
  const dispatch = useEditorDispatch();

  const store = useEditorStore();
  const applyComponentAction = useApplyComponentAction();

  const { colorSavedStyles } = useGetDesignLibrarySavedStyles();

  if (!draftComponentTextShadows) {
    return null;
  }

  const isPopoverOpen =
    openPopoverId &&
    typeof openPopoverId === "object" &&
    "text-shadow" in openPopoverId;

  const currentShadowIndex = isPopoverOpen
    ? openPopoverId["text-shadow"]
    : null;
  const currentShadow =
    currentShadowIndex != null
      ? draftComponentTextShadows[currentShadowIndex]
      : null;

  const createOrUpdateTextShadow = (value: string) => {
    const componentId = selectDraftComponentId(store.getState());
    applyComponentAction({
      componentId,
      type: "setStyles",
      value: {
        textShadow: value,
      },
    });
  };

  const handleRemoveTextShadow = (index: number) => {
    const filteredTextShadows = draftComponentTextShadows.filter(
      (_, shadowIndex) => shadowIndex !== index,
    );
    const updatedTextShadowes = getTextShadowString(filteredTextShadows);
    createOrUpdateTextShadow(updatedTextShadowes);
  };

  const handleTextShadowChange = (value: TextShadow) => {
    const newTextShadows = draftComponentTextShadows.map((textShadow) => {
      return textShadow.id === value.id ? value : textShadow;
    });
    const updatedTextShadows = getTextShadowString(newTextShadows);
    createOrUpdateTextShadow(updatedTextShadows);
  };

  const getBadgeColor = (color?: string) => {
    if (color && isDynamicDesignLibraryValue(color)) {
      const savedStyle = getSavedStyleValue(colorSavedStyles, color);
      return savedStyle?.attributes && "color" in savedStyle.attributes
        ? String(savedStyle.attributes.color)
        : "text-subtle";
    }
    return color ?? "text-subtle";
  };

  return (
    // NOTE (Fran 2024-10-16): 74px is the fixed width of the label.
    <div className="grid grid-cols-[74px,auto] w-full items-center">
      <ModifierLabel label="Shadow" />
      {draftComponentTextShadows.map((textShadow, index) => {
        const badgeColor = getBadgeColor(textShadow.color);
        return (
          <div className="col-start-2 py-1" key={textShadow.id}>
            <SelectionIndicator
              className="max-w-40"
              title={formatTitle(textShadow, colorSavedStyles)}
              onClick={() =>
                dispatch(setOpenPopoverId({ "text-shadow": index }))
              }
              startEnhancer={
                <Badge type="color" isFilled backgroundColor={badgeColor} />
              }
              endEnhancer={
                <FormFieldXButton
                  onClick={(event) => {
                    // NOTE (Sebas, 2024-10-10): This is necessary to prevent executing the onClick event to avoid open
                    // the popover when the user wants to remove the shadow.
                    event.stopPropagation();
                    handleRemoveTextShadow(index);
                  }}
                />
              }
            />
          </div>
        );
      })}
      {currentShadow ? (
        <TextShadowPopover
          isOpen={isPopoverOpen ?? false}
          activeTextShadow={currentShadow}
          handleTextShadowChange={handleTextShadowChange}
          textShadowIndex={currentShadowIndex ?? 0}
          onRequestClose={() => dispatch(setOpenPopoverId(null))}
        />
      ) : null}
    </div>
  );
};

const TextShadowPopoverContent: React.FC<{
  offsetX?: string;
  offsetY?: string;
  blur?: string;
  color?: string;
  textShadowIndex: number;
  handleInputChange(
    value: string,
    inputType: "offsetX" | "offsetY" | "blur" | "color",
  ): void;
}> = ({
  offsetX,
  offsetY,
  blur,
  color,
  textShadowIndex,
  handleInputChange,
}) => {
  const componentId = useEditorSelector(selectDraftComponentId);

  if (!componentId) {
    return null;
  }
  return (
    <div className="flex flex-col gap-2">
      <LengthInputSelector
        label={<ModifierLabel label="X Axis" />}
        metrics={CSS_LENGTH_TYPES}
        className="col-span-1"
        field="offsetX"
        resetValue="0px"
        anchorValue="0px"
        placeholder="0px"
        value={offsetX || null}
        onChange={(value: string) => handleInputChange(value, "offsetX")}
        previewProperty="textShadow"
        previewSubProperty="offsetX"
        previewPropertyIndex={textShadowIndex}
        draggingDirection={DraggingDirections.Negative}
        autofocus
        dragTrigger="label"
      />
      <LengthInputSelector
        metrics={CSS_LENGTH_TYPES}
        className="col-span-1"
        label={<ModifierLabel label="Y Axis" />}
        field="offsetY"
        resetValue="0px"
        anchorValue="0px"
        placeholder="0px"
        value={offsetY || null}
        onChange={(value: string) => handleInputChange(value, "offsetY")}
        previewProperty="textShadow"
        previewSubProperty="offsetY"
        previewPropertyIndex={textShadowIndex}
        dragTrigger="label"
        draggingDirection={DraggingDirections.Negative}
      />
      <LengthInputSelector
        label={<ModifierLabel label="Blur" />}
        metrics={CSS_LENGTH_TYPES}
        className="col-span-1"
        minDragValues={{ px: 0 }}
        minValues={{ px: 0 }}
        field="blur"
        resetValue="0px"
        anchorValue="0px"
        placeholder="0px"
        value={blur || null}
        onChange={(value: string) => handleInputChange(value, "blur")}
        previewProperty="textShadow"
        previewSubProperty="blur"
        previewPropertyIndex={textShadowIndex}
        dragTrigger="label"
      />
      <div className="flex items-center">
        <ModifierLabel label="Color" />
        <DynamicColorSelector
          field="color"
          componentId={componentId}
          allowsGradientSelection={false}
          popoverTitle="Color"
          value={color ?? ""}
          onChange={(value: string) => handleInputChange(value, "color")}
          showSavedStyles
          onSavedStyleSelect={(value: string) =>
            handleInputChange(value, "color")
          }
          onRemove={() => handleInputChange("", "color")}
        />
      </div>
    </div>
  );
};

const TextSavedStyleGroup: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const fontSize = useEditorSelector(selectFontSize);

  const onSavedStyleSelect = (value: string) => {
    applyComponentAction({
      type: "setStyles",
      value: {
        color: `{{ ${value}.color }}`,
        fontFamily: `{{ ${value}.fontFamily }}`,
        fontSize: `{{ ${value}.fontSize }}`,
        fontWeight: `{{ ${value}.fontWeight }}`,
        letterSpacing: `{{ ${value}.letterSpacing }}`,
        lineHeight: `{{ ${value}.lineHeight }}`,
        textAlign: `{{ ${value}.textAlign }}`,
        textDecoration: `{{ ${value}.textDecoration }}`,
        fontStyle: `{{ ${value}.textDecoration }}`,
        textTransform: `{{ ${value}.textTransform }}`,
        textShadow: `{{ ${value}.textShadow }}`,
        __textStroke: `{{ ${value}.textStroke }}`,
      },
    });
  };

  const onSavedStyleRemove = () => {
    applyComponentAction({
      type: "setStyles",
      value: {
        color: null,
        fontFamily: null,
        fontSize: null,
        fontWeight: null,
        letterSpacing: null,
        lineHeight: null,
        textAlign: null,
        textDecoration: null,
        textTransform: null,
        textShadow: null,
        __textStroke: null,
      },
    });
  };

  return (
    <ControlGroup label="Style">
      <DesignLibraryTextValueIndicator
        popoverSideOffset={84}
        // NOTE (Sebas, 2024-11-14): When applying saved styles to texts we apply the
        // prop to multiple properties, I choose fontSize but it could be any other value.
        savedStyleValueReference={String(fontSize) ?? ""}
        onSavedStyleSelect={onSavedStyleSelect}
        onRemove={onSavedStyleRemove}
      />
    </ControlGroup>
  );
};

function useTextStyleHotkeys() {
  const globalActions = useGlobalEditorActions();
  const { isMenuOpen: isAIMenuOpen } = useAIStreaming();
  const enableNonDynamicTextEditing = useEnableNonDynamicTextEditing();
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);

  useReploHotkeys({
    toggleBoldText: globalActions.toggleBoldText,
    toggleH1Text: globalActions.toggleH1Text,
    toggleH2Text: globalActions.toggleH2Text,
    toggleH3Text: globalActions.toggleH3Text,
    toggleH4Text: globalActions.toggleH4Text,
    toggleH5Text: globalActions.toggleH5Text,
    toggleH6Text: globalActions.toggleH6Text,
    toggleBulletList: globalActions.toggleBulletList,
    toggleNumberedList: globalActions.toggleNumberedList,
    toggleLinkText: globalActions.toggleLinkText,
    toggleItalicText: globalActions.toggleItalicText,
    toggleUnderlineText: globalActions.toggleUnderlineText,
    toggleStrikethroughText: globalActions.toggleStrikethroughText,
    decreaseFontSize: globalActions.decreaseFontSize,
    increaseFontSize: globalActions.increaseFontSize,
    decreaseLetterSpacing: globalActions.decreaseLetterSpacing,
    increaseLetterSpacing: globalActions.increaseLetterSpacing,
    decreaseLineHeight: globalActions.decreaseLineHeight,
    increaseLineHeight: globalActions.increaseLineHeight,
    decreaseFontWeight: globalActions.decreaseFontWeight,
    increaseFontWeight: globalActions.increaseFontWeight,
    ...(!isAIMenuOpen && !areModalsOpen
      ? { editText: enableNonDynamicTextEditing }
      : {}),
  });
}

const useAddDefaultShadow = () => {
  const store = useEditorStore();
  const applyComponentAction = useApplyComponentAction();
  const dispatch = useEditorDispatch();

  return () => {
    const draftComponentTextShadow = selectTextShadow(store.getState());
    const draftComponentId = selectDraftComponentId(store.getState());
    const draftComponentTextShadows =
      draftComponentTextShadow?.split(",") ?? [];
    const textShadows =
      draftComponentTextShadows.length > 0
        ? parseTextShadows(draftComponentTextShadows)
        : [];
    const updatedTextShadows = getTextShadowString([
      ...textShadows,
      DEFAULT_TEXT_SHADOW,
    ]);

    const lastIndex = textShadows.length;
    dispatch(setOpenPopoverId({ "text-shadow": lastIndex }));

    applyComponentAction({
      componentId: draftComponentId,
      type: "setStyles",
      value: {
        textShadow: updatedTextShadows,
      },
    });
  };
};

export default TextStyleModifier;
