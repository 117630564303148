import type { DropdownMenuContentProps } from "@radix-ui/react-dropdown-menu";

import * as React from "react";

import Scrollable from "@editor/components/common/designSystem/Scrollable";

import {
  Content,
  Item,
  Portal,
  Root,
  Trigger,
} from "@radix-ui/react-dropdown-menu";
import isFunction from "lodash-es/isFunction";
import isString from "lodash-es/isString";
import { BsCaretDownFill } from "react-icons/bs";
import { animated, config, useTransition } from "react-spring";
import useMeasure from "react-use-measure";
import { useControllableState } from "replo-utils/react/use-controllable-state";
import { twMerge } from "tailwind-merge";

import FormFieldXButton from "../FormFieldXButton";

type LabelProps = {
  isSelected: boolean;
  isHovered: boolean;
};

export type SelectableOption = {
  value: string | null;
  label:
    | string
    | React.ReactNode
    | (({ isSelected, isHovered }: LabelProps) => React.ReactNode);
  isDisabled?: boolean;
  simpleSelectedLabel?: string;
};

export type SelectableDropdownSize = "xs" | "sm" | "md" | "lg";

type SelectableDropdownProps = {
  id?: string;
  defaultValue?: string;
  value?: string | null;
  options: SelectableOption[];
  isDisabled?: boolean;
  onSelect(value: string | null): void;
  onHover?(value: string | null): void;
  isOpen?: boolean;
  onOpenChange?(): void;
  className?: string;
  labelClassName?: string;
  arrowClassName?: string;
  /**
   * NOTE (Max, 2024-09-26): If true, removes the padding/hover between items as these
   * styles would be overriden by the parent. Otherwise, the hover styles look off as the
   * selected option's div would go beyond the actual label.
   */
  overrideItemSpacing?: boolean;
  style?: React.CSSProperties;
  size?: SelectableDropdownSize;
  onRemove?(): void;
  disableDropdownFixedWidth?: boolean;
  dropdownAlign?: DropdownMenuContentProps["align"];
  alignOffset?: DropdownMenuContentProps["alignOffset"];
  sideOffset?: DropdownMenuContentProps["sideOffset"];
};

type PlaceholderProps = {
  isDisabled: boolean;
};

type ValueIndicatorProps = {
  value: string;
  label: SelectableOption["label"];
  isDisabled: boolean;
};

type SelectableProps = SelectableDropdownProps & {
  contentClassName?: string;
  placeholder?:
    | string
    | (({ isDisabled }: PlaceholderProps) => React.ReactNode);
  valueIndicator?({ value, isDisabled }: ValueIndicatorProps): React.ReactNode;
  startEnhancer?(): React.ReactNode;
  endEnhancer?(): React.ReactNode;
  ignoreValueMismatchError?: boolean;
};

const SelectableMenuItem: React.FC<{
  item: SelectableOption;
  selectedValue: string | null;
  overrideItemSpacing?: boolean;
  size?: SelectableDropdownSize;
  onSelect(value: string | null): void;
  onHover?(value: string | null): void;
}> = ({
  item,
  selectedValue,
  overrideItemSpacing,
  size = "xs",
  onSelect,
  onHover,
}) => {
  const { label, value, isDisabled = false, simpleSelectedLabel = null } = item;
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const changeFocus = () => setIsFocused(!isFocused);

  function _renderSelectableItem() {
    if (isString(label)) {
      return (
        <span
          className={twMerge("truncate", size === "md" ? "text-sm" : "text-xs")}
        >
          {label}
        </span>
      );
    }

    if (isFunction(label)) {
      return label({
        isHovered: isFocused,
        isSelected: selectedValue === item.value,
      });
    }
    return label;
  }

  return (
    <Item
      asChild
      disabled={isDisabled}
      onClick={(e) => {
        e.stopPropagation();
        if (!isDisabled) {
          onSelect(value);
        }
      }}
      onMouseOver={() => onHover?.(value)}
    >
      <div
        onFocus={changeFocus}
        onBlur={changeFocus}
        className={twMerge(
          "flex w-full items-center justify-between text-xs focus:outline-none cursor-pointer rounded",
          !overrideItemSpacing && "px-2 py-1.5 hover:bg-hover",
          simpleSelectedLabel && "h-full",
          !simpleSelectedLabel && "h-7",
          !simpleSelectedLabel && size === "md" && "h-8 text-sm",
          isString(label) && !isDisabled && "text-default",
          isDisabled && "cursor-not-allowed text-disabled",
        )}
      >
        {_renderSelectableItem()}
      </div>
    </Item>
  );
};

const SelectableBox: React.FC<
  { boxLabel: string | React.ReactNode; value?: string | null } & Pick<
    SelectableProps,
    | "isDisabled"
    | "startEnhancer"
    | "size"
    | "className"
    | "placeholder"
    | "defaultValue"
  >
> = ({
  boxLabel,
  value,
  isDisabled,
  startEnhancer,
  size,
  defaultValue,
  className,
  placeholder,
}) => {
  if (isString(boxLabel)) {
    const defaultTextClassName =
      value !== "" && boxLabel !== placeholder ? "text-default" : "text-subtle";
    return (
      <span
        className={twMerge(
          "w-full text-xs truncate gap-2",
          !isDisabled && defaultTextClassName,
          value === defaultValue && "text-subtle",
          isDisabled && "text-disabled",
          Boolean(startEnhancer) && "flex items-center",
          (size === "sm" || size === "md") && "text-sm",
          size === "lg" && "text-lg",
          className,
        )}
      >
        {Boolean(startEnhancer) && startEnhancer?.()}
        {boxLabel}
      </span>
    );
  }
  return <>{boxLabel}</>;
};

const Selectable: React.FC<SelectableProps> = ({
  id,
  placeholder,
  valueIndicator,
  defaultValue = "",
  value: controlledValue,
  options,
  size = "xs",
  isDisabled = false,
  onSelect: onControllableSelect,
  onHover,
  isOpen,
  onOpenChange,
  className,
  labelClassName,
  arrowClassName,
  contentClassName,
  overrideItemSpacing,
  style,
  startEnhancer,
  endEnhancer,
  onRemove,
  disableDropdownFixedWidth = false,
  dropdownAlign = "center",
  ignoreValueMismatchError = false,
  alignOffset,
  sideOffset = 8,
}) => {
  // Delay measuring so that react-spring starts the animation (200ms default delay)
  const [measureRef, { width }] = useMeasure({ debounce: 200 });
  const [dropdownOpen, setDropdownOpen] = useControllableState(
    isOpen,
    false,
    onOpenChange,
  );
  const [value, setValue] = useControllableState<string | null>(
    controlledValue,
    defaultValue,
    onControllableSelect,
  );

  let boxLabel: string | React.ReactNode;
  if (!value) {
    boxLabel = placeholder as string | React.ReactNode;
  } else if (valueIndicator) {
    boxLabel = valueIndicator({
      value,
      label: options.find((option) => option.value === value)?.label ?? "",
      isDisabled,
    });
  } else {
    const selectedOption = options.find((option) => option.value === value);
    boxLabel =
      selectedOption?.simpleSelectedLabel ??
      (selectedOption?.label as string | React.ReactNode);
  }

  const transitions = useTransition(dropdownOpen, {
    from: {
      opacity: 0,
      transform: "translate(0, 0px)",
    },
    enter: {
      opacity: 1,
      transform: "translate(0, 0px)",
    },
    leave: {
      opacity: 0,
      transform: "translate(0, 0px)",
    },
    config: config.stiff,
  });

  function _onOpenChange(checked: boolean) {
    if (!isDisabled) {
      setDropdownOpen(checked);
      onOpenChange?.();
    }
  }

  const selectionClassName = twMerge(
    size === "xs" && "h-6",
    size === "sm" && "h-9",
    size === "lg" && "h-12",
  );

  const valueDoesNotExistInOptions =
    value && !options.some((option) => option.value === value);

  const endEnhancerContent = endEnhancer?.();

  return (
    <Root open={dropdownOpen} onOpenChange={_onOpenChange}>
      <div
        id={id}
        className={twMerge(
          "flex w-full items-center justify-between rounded text-xs cursor-pointer bg-subtle p-1",
          selectionClassName,
          isDisabled && "cursor-not-allowed bg-disabled",
          valueDoesNotExistInOptions &&
            !ignoreValueMismatchError &&
            "border border-red-600 bg-danger-emphasis",
          size === "md" && "text-sm py-1.5 px-2",
          className,
        )}
        style={style}
        ref={measureRef}
      >
        <Trigger asChild className="flex w-full items-center justify-between">
          <div className="flex gap-1 w-full truncate">
            <SelectableBox
              boxLabel={boxLabel}
              value={value}
              isDisabled={isDisabled}
              startEnhancer={startEnhancer}
              size={size}
              className={labelClassName}
              placeholder={placeholder}
              defaultValue={defaultValue}
            />
            <div className="flex h-4 w-4 items-center justify-center rounded shrink-0">
              {endEnhancerContent ? (
                <span>{endEnhancerContent}</span>
              ) : (
                <BsCaretDownFill
                  className={twMerge("text-subtle", arrowClassName)}
                  size={8}
                />
              )}
            </div>
          </div>
        </Trigger>
        {onRemove && <FormFieldXButton onClick={() => onRemove()} />}
      </div>
      {options.length > 0 &&
        transitions(
          (styles, item, i) =>
            item && (
              <Portal>
                {/* NOTE (Sebas, 2024-06-26): This stop propagation is needed to prevent
                the shortcuts from being triggered when the dropdown is open */}
                <div onKeyDown={(e) => e.stopPropagation()}>
                  <Content
                    forceMount
                    asChild
                    align={dropdownAlign}
                    alignOffset={alignOffset}
                    sideOffset={sideOffset}
                  >
                    <animated.div
                      key={i.toString()}
                      style={{
                        ...styles,
                        width: disableDropdownFixedWidth ? "auto" : width,
                      }}
                      className={twMerge(
                        "rounded bg-white shadow p-1",
                        contentClassName,
                      )}
                      onMouseLeave={() => onHover?.(null)}
                    >
                      <Scrollable type="vertical">
                        <div className="max-h-60">
                          {options.map((item, index) => (
                            <div
                              key={index}
                              data-testid={`selectable-item-${item.value}`}
                            >
                              <SelectableMenuItem
                                selectedValue={value}
                                item={item}
                                size={size}
                                onSelect={(value) => {
                                  setValue(value);
                                }}
                                onHover={onHover}
                                overrideItemSpacing={overrideItemSpacing}
                              />
                            </div>
                          ))}
                        </div>
                      </Scrollable>
                    </animated.div>
                  </Content>
                </div>
              </Portal>
            ),
        )}
    </Root>
  );
};

export default Selectable;
