import type { Hotkey } from "@editor/utils/hotkeys";

import * as React from "react";

import { HotkeyIndicator } from "@common//HotkeyIndicator";
import Modal from "@common/designSystem/Modal";
import { ModalLayout } from "@common/ModalLayout";
import { useModal } from "@editor/hooks/useModal";
import { isSavedStylesEnabled } from "@editor/infra/featureFlags";
import { getHotkeys } from "@editor/utils/hotkeys";

import groupBy from "lodash-es/groupBy";

type HotkeyDisplay = {
  group: string;
  displayName: string;
  key: Hotkey;
};

type OrderedGroups = [string, HotkeyDisplay[]][][];

export const HotkeysModal: React.FC = () => {
  const modal = useModal();

  // Define the grouping of groups
  const groupOrder: (string | string[])[] = ["Text", "Edit", ["View", "Zoom"]];

  const groupedHotkeyElements = groupHotkeys(groupOrder);

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => modal.closeModal({ type: "hotkeysModal" })}
      className="h-auto w-auto overflow-scroll no-scrollbar"
    >
      <ModalLayout
        height={630}
        width={800}
        mainContent={() => (
          <div className="flex flex-col w-full">
            <h1 className="my-3 text-lg font-medium text-default">
              Keyboard Shortcuts
            </h1>
            <div className="grid grid-cols-3 gap-6 pr-3 w-full">
              {groupedHotkeyElements.map((group, index) => (
                <div key={index}>
                  {group.map(([groupName, hotkeys]) => (
                    <div key={groupName}>
                      <h3 className="text-xs font-semibold">{groupName}</h3>
                      <hr className="my-2" />
                      {hotkeys.map((hotkey) => {
                        if (
                          !isSavedStylesEnabled() &&
                          hotkey.key === "setSavedStylesPanel"
                        ) {
                          return null;
                        }

                        return (
                          <HotkeyIndicator
                            key={hotkey.key}
                            title={hotkey.displayName}
                            hotkey={hotkey.key}
                            className="text-neutral-700 flex justify-between my-1.5 items-center"
                          />
                        );
                      })}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
      ></ModalLayout>
    </Modal>
  );
};

function groupHotkeys(groupOrder: (string | string[])[]): OrderedGroups {
  const hotkeys: HotkeyDisplay[] = [];
  const commandToHotkeyData = getHotkeys(isSavedStylesEnabled());
  Object.entries(commandToHotkeyData).forEach(([key, hotkeyData]) => {
    const { group, displayName } = hotkeyData;
    if (!group) {
      return;
    }
    hotkeys.push({ group, displayName, key: key as Hotkey });
  });

  const groupedHotkeys = groupBy(hotkeys, (hotkey) => hotkey.group);

  // Create the ordered groups based on the specified groupOrder
  const orderedGroups: OrderedGroups = groupOrder.map((group) => {
    if (Array.isArray(group)) {
      return group.map((subGroup) => [
        subGroup,
        groupedHotkeys[subGroup] || [],
      ]);
    }
    return [[group, groupedHotkeys[group] || []]];
  });

  return orderedGroups;
}
