type ConfigurableIntegration = {
  title: string;
  image: string;
  summary: string;
  type: "ShopifyIntegrationConfig";
  redirectUrl: string;
};

export const configurableIntegrations: ConfigurableIntegration[] = [
  {
    title: "Shopify",
    image: "/images/integration-logos/shopify.png",
    summary:
      "Shopify Store integrations allow you to pull in product information and deploy directly to your Shopify domains.",
    redirectUrl: "/workspace/:workspaceId/integrations/shopify",
    type: "ShopifyIntegrationConfig",
  },
];
