import * as React from "react";

export default function useForceRerenderOnMount(config?: { skip?: boolean }) {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    if (config?.skip) {
      return;
    }
    setHasMounted(true);
  }, [config?.skip]);
  return hasMounted;
}
