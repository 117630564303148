// NOTE (Chance 2023-04-16): This component probably needs to be revisited a few
// more times, as it mixes several UI patterns (popover, inputs, lists, buttons)
// w/ app logic (product selection) and closes over them in a way that makes it
// difficult to reuse and debug in certain contexts. I think way can wrap the
// logic up into a custom hook and redesign the components to be more
// composable.
// See https://github.com/replohq/andytown/pull/3997
import type { ProductRequestType } from "@editor/hooks/useStoreProducts";
import type { PageInfo } from "@editor/reducers/api-reducer";
import type { Option, SelectedValue } from "@editorComponents/Lists";
import type { ProductStatus, StoreProduct } from "replo-runtime/shared/types";
import type { ProductRef } from "schemas/product";

import * as React from "react";

import ToggleGroup from "@common/designSystem/ToggleGroup";
import { Chip } from "@editor/components/common/designSystem/Chip";
import Input from "@editor/components/common/designSystem/Input";
import Popover from "@editor/components/common/designSystem/Popover";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import { useIsDebugMode } from "@editor/components/editor/debug/useIsDebugMode";
import { ConnectShopifyCallout } from "@editor/components/editor/page/ConnectShopifyCallout";
import { getProductSummaryListOptions } from "@editor/components/editor/page/ProductSummaryList";
import {
  useInfiniteStoreProducts,
  useInfiniteStoreProductsSummary,
} from "@editor/hooks/useInfiniteStoreProducts";
import { useLocalStorage } from "@editor/hooks/useLocalStorage";
import {
  productSummaryRequestLimits,
  useFetchSpecificProductsSummary,
} from "@editor/hooks/useProductsSummary";
import {
  storeProductRequestLimits,
  useStoreProducts,
} from "@editor/hooks/useStoreProducts";
import { selectIsShopifyIntegrationEnabled } from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import {
  selectIsShopifyStoreClosed,
  selectOpenPopoverId,
  selectRightBarActiveTab,
  setOpenPopoverId,
} from "@editor/reducers/ui-reducer";
import { useEditorSelector } from "@editor/store";
import { imgOrPlaceholder } from "@editor/utils/image";
import { InfiniteList, RegularList } from "@editorComponents/Lists";

import Tooltip from "@replo/design-system/components/tooltip";
import { BsSearch } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { getRandomProductId } from "replo-runtime/shared/utils/product";
import { fakeProducts } from "replo-runtime/store/utils/fakeProducts";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { twMerge } from "tailwind-merge";

type ProductListType = "onlineStore" | "placeholders";

type OnSubmitProps =
  | {
      isMultiSelection: true;
      onSubmit: (value: ProductRef[]) => void;
    }
  | {
      isMultiSelection?: undefined | false;
      onSubmit: (value: ProductRef | null) => void;
    };

export type ProductSelectionPopoverProps = React.PropsWithChildren<{
  value?: ProductRef[];
  productRequestType?: ProductRequestType;
  className?: string;
  style?: React.CSSProperties;
  isControlledOpen?: boolean;
  onOpenChangeCallback?: (open: boolean) => void;
  dataType?: "summary" | "full";
  showPlaceholders?: boolean;
}> &
  OnSubmitProps;

export type ProductSelectionPopoverContentProps = {
  title?: string;
  side?: "top" | "right" | "bottom" | "left";
  offset?: number;
  className?: string;
};

export type ProductSelectionPopoverTriggerProps = {
  render?: (props: {
    selectedProducts: OptionWithProductData[];
    label: string;
    onClearSelection: () => void;
  }) => React.ReactElement;
  showDeleteButton?: boolean;
};

// NOTE (Chance 2023-04-16): This context object is only here because a) we are
// coupling much of our global state/app logic to this component's rendering,
// which isn't really necessary, and b) I added it as an incremental refactor to
// make the component more composable. If we can extract the underlying logic
// into hooks we use higher in the tree we should be able to get rid of it.
export type ProductSelectionPopoverContextValue = {
  productListType: ProductListType;
  searchTerm: string;
  selectedProductRefs: ProductRef[];
  setProductListType: React.Dispatch<React.SetStateAction<ProductListType>>;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  setSelectedProductRefs: (value: ProductRef[]) => void;
  initiallySelectedProducts: ProductRef[];
  availableProducts: OptionWithProductData[];
  paginatedProducts?: OptionWithProductData[];
  fetchNextPage?: () => Promise<void>;
  isFetchingNextPage?: boolean;
  pageInfo?: PageInfo | undefined;
  showPlaceholders: boolean;
} & OnSubmitProps;

const ProductSelectionPopoverContext =
  React.createContext<ProductSelectionPopoverContextValue | null>(null);
ProductSelectionPopoverContext.displayName = "ProductSelectionPopoverContext";

function useProductSelectionPopoverContext() {
  const context = React.useContext(ProductSelectionPopoverContext);
  if (!context) {
    throw new Error(
      "useProductSelectionPopoverContext must be used within a ProductSelectionPopover component",
    );
  }
  return context;
}

const productListEndEnhancer = (status: ProductStatus) =>
  exhaustiveSwitch({ type: status })({
    ARCHIVED: (
      <Chip
        tooltipText="This product is archived on your store."
        className="text-[10px]"
      >
        Archived
      </Chip>
    ),
    DRAFT: (
      <Chip
        tooltipText="This product is still a draft on your store."
        className="bg-blue-200 text-blue-600 text-[10px]"
      >
        Draft
      </Chip>
    ),
    ACTIVE: null,
  });

export type OptionWithProductData = Option & {
  productId: number;
  title: string;
  featuredImage: string | null;
  defaultVariantId: number;
  status?: ProductStatus;
};

function mapStoreProductToOption(
  product: StoreProduct,
  initiallySelectedProducts: ProductRef[],
): OptionWithProductData {
  return {
    productId: Number(product.id),
    title: product.title,
    featuredImage: product.featured_image ?? product.images[0] ?? null,
    defaultVariantId: product.variants[0]!.id,
    label: (
      <Tooltip
        triggerAsChild={true}
        content={`${product.title} (${product.id})`}
      >
        <span className="truncate">{product.title}</span>
      </Tooltip>
    ),
    searchValue: product.title,
    isSelectable: true,
    value: product.id,
    startEnhancer: imgOrPlaceholder(
      product.featured_image ?? product.images[0],
      "rounded-md h-5 w-5 object-contain shrink-0",
      "bg-gray-200",
    ),
    status: product.status,
    endEnhancer: productListEndEnhancer(product.status),
    isDefaultActive: initiallySelectedProducts
      .map((p) => p.productId)
      .includes(product.id),
  };
}

const ProductSelectionPopover: React.FC<ProductSelectionPopoverProps> = ({
  value: initiallySelectedProducts = [],
  productRequestType = "productsFromDraftElement",
  children,
  isMultiSelection = false,
  onSubmit,
  className,
  style,
  isControlledOpen,
  onOpenChangeCallback,
  dataType = "summary",
  showPlaceholders = true,
}) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = React.useState("");
  const rightBarActiveTab = useEditorSelector(selectRightBarActiveTab);
  const openPopoverId = useEditorSelector(selectOpenPopoverId);
  // NOTE (Sebas, 2024-05-02): This is required for opening the popover when the
  // user drops a new product component on the canvas.
  const isDefaultOpen =
    rightBarActiveTab === "custom" &&
    openPopoverId === "config-product-selector";
  // TODO (Noah, 2023-01-02, REPL-5792): This shouldn't need a useOverridableState because ProductSelectionPopover
  // always accepts a value and onSubmit, but swatches needs it to display currently selected products.
  // We should remove this and make the swatches editor use a useOverridableState instead
  const [selectedProductRefs, setSelectedProductRefs] = useOverridableState<
    ProductRef[]
  >(initiallySelectedProducts);

  const [productListType, setProductListType] = React.useState<ProductListType>(
    () => {
      const defaultProductListType =
        selectedProductRefs.length > 0 &&
        selectedProductRefs.every((ref) =>
          fakeProducts.some(
            (fakeProduct) => String(fakeProduct.id) === String(ref.productId),
          ),
        )
          ? "placeholders"
          : "onlineStore";
      return showPlaceholders
        ? defaultProductListType ?? "onlineStore"
        : "onlineStore";
    },
  );

  const { products } = useStoreProducts(productRequestType);

  return (
    <div
      className={twMerge(
        "flex flex-1 text-slate-400 flex-col truncate",
        className,
      )}
      style={style}
      // Note (Sebas, 2022-09-05): This preventDefault is needed because
      // when you click on the tooltip text, the popover closes
      onClick={(e) => e.preventDefault()}
    >
      <Popover
        onOpenChange={(isOpen) => {
          setSearchTerm("");
          if (onOpenChangeCallback) {
            onOpenChangeCallback(isOpen);
          }
          if (isDefaultOpen) {
            dispatch(setOpenPopoverId(null));
          }
        }}
        isDefaultOpen={isDefaultOpen}
        isOpen={isControlledOpen}
      >
        <ProductSelectionPopoverContext.Provider
          // NOTE (Chance 2023-04-14): TypeScript isn't quite smart enough to
          // handle the union type relationship between `isMultiSelection` and
          // `onSubmit` so we expect the error here
          // @ts-expect-error
          value={{
            productListType,
            availableProducts: products.map((product) =>
              mapStoreProductToOption(product, initiallySelectedProducts),
            ),
            searchTerm,
            selectedProductRefs,
            setProductListType,
            setSearchTerm,
            setSelectedProductRefs,
            isMultiSelection,
            onSubmit,
            initiallySelectedProducts,
            showPlaceholders,
          }}
        >
          {dataType === "summary" && (
            <ProductSelectionPopoverSummaries
              selectedProductIds={initiallySelectedProducts.map((p) =>
                Number(p.productId),
              )}
            >
              {children}
            </ProductSelectionPopoverSummaries>
          )}
          {dataType === "full" && (
            <ProductSelectionPopoverFull>
              {children}
            </ProductSelectionPopoverFull>
          )}
        </ProductSelectionPopoverContext.Provider>
      </Popover>
    </div>
  );
};

ProductSelectionPopover.displayName = "ProductSelectionPopover";

const ProductSelectionPopoverSummaries: React.FC<
  React.PropsWithChildren<{ selectedProductIds: number[] }>
> = ({ children, selectedProductIds }) => {
  const contextData = useProductSelectionPopoverContext();

  // Note (Noah, 2024-02-21, REPL-10503): Save the initial value of selectedProductIds
  // when this component first rendered, because we want to fetch and show them at the
  // top of the list. Using a ref here means products won't jump automatically to the
  // top when you select them, but when the component rerenders you'll see them there
  // initially
  // Note (Noah, 2024-05-08, REPL-11649): This is very similar to the code in ProductSummaryList
  // but this component works a little differently right now, we should consolidate them since it's
  // confusing to have both
  const initialSelectedProductIdsRef = React.useRef(selectedProductIds);
  const { productsSummary: specificProductSummaries } =
    useFetchSpecificProductsSummary(
      initialSelectedProductIdsRef.current,
      initialSelectedProductIdsRef.current.length === 0,
    );

  const {
    fetchNextPage,
    products: productsSummary,
    pageInfo,
    isFetching,
  } = useInfiniteStoreProductsSummary({
    pageSize: productSummaryRequestLimits.infinite,
    query: contextData.searchTerm,
    debounceDelay: 300,
  });

  let summaries = productsSummary;
  if (specificProductSummaries) {
    const specificProductIds = new Set(
      specificProductSummaries.map((s) => s.id),
    );
    summaries = specificProductSummaries.concat(
      productsSummary.filter((s) => !specificProductIds.has(s.id)),
    );
  }

  return (
    <ProductSelectionPopoverContext.Provider
      value={{
        ...contextData,
        fetchNextPage,
        pageInfo,
        isFetchingNextPage: isFetching,
        paginatedProducts:
          contextData.productListType === "onlineStore"
            ? getProductSummaryListOptions({
                productSummaries: summaries,
                productListType: "all",
                searchTerm: contextData.searchTerm,
              })
            : fakeProducts.map((product) =>
                mapStoreProductToOption(
                  product,
                  contextData.initiallySelectedProducts,
                ),
              ),
      }}
    >
      {children}
    </ProductSelectionPopoverContext.Provider>
  );
};

ProductSelectionPopoverSummaries.displayName =
  "ProductSelectionPopoverSummaries";

const ProductSelectionPopoverFull: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const contextData = useProductSelectionPopoverContext();

  const { fetchNextPage, products, pageInfo, isFetching } =
    useInfiniteStoreProducts({
      pageSize: storeProductRequestLimits.infiniteLoading,
      query: contextData.searchTerm,
      debounceDelay: 300,
    });

  return (
    <ProductSelectionPopoverContext.Provider
      value={{
        ...contextData,
        fetchNextPage,
        pageInfo,
        isFetchingNextPage: isFetching,
        paginatedProducts:
          contextData.productListType === "onlineStore"
            ? products.map((product) =>
                mapStoreProductToOption(
                  product,
                  contextData.initiallySelectedProducts,
                ),
              )
            : fakeProducts.map((product) =>
                mapStoreProductToOption(
                  product,
                  contextData.initiallySelectedProducts,
                ),
              ),
      }}
    >
      {children}
    </ProductSelectionPopoverContext.Provider>
  );
};

ProductSelectionPopoverFull.displayName = "ProductSelectionPopoverFull";

const ProductSelectionPopoverContent: React.FC<
  ProductSelectionPopoverContentProps
> = ({ title, side, offset, className }) => {
  const itemSize = 40;
  const labelClassName = "tracking-tight text-xs min-w-0";
  const noItemsPlaceholder =
    "No products found. Try searching for a different product or ensuring the products you're looking for are active and enabled for the Online Store sales channel.";

  const {
    isMultiSelection,
    onSubmit,
    productListType,
    selectedProductRefs,
    setProductListType,
    searchTerm,
    setSearchTerm,
    setSelectedProductRefs,
    pageInfo,
    fetchNextPage,
    isFetchingNextPage,
    paginatedProducts,
    showPlaceholders,
  } = useProductSelectionPopoverContext();

  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const isStoreClosed = useEditorSelector(selectIsShopifyStoreClosed);
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );

  const selectedItems: SelectedValue[] = selectedProductRefs.map(
    (product) => product.productId,
  );

  function onClickSelectableProduct(
    productId: SelectedValue | SelectedValue[],
  ) {
    const productRefs = (
      Array.isArray(productId) ? productId : [productId]
    ).map((productId) => {
      const product = paginatedProducts?.find(
        (product) => product.productId === productId,
      );
      return {
        id: getRandomProductId(),
        title: product?.title,
        productId: Number(productId),
        variantId: product?.defaultVariantId,
      };
    });

    setSelectedProductRefs(productRefs);

    if (isMultiSelection) {
      onSubmit(productRefs);
    } else {
      onSubmit(productRefs[0] ?? null);
    }
  }

  function onSearch(value: string) {
    setSearchTerm(value);
  }

  function onSelect(item: SelectedValue) {
    let updatedItems: SelectedValue[];
    if (item && selectedItems.includes(item)) {
      updatedItems = selectedItems.filter((i) => i !== item);
    } else {
      updatedItems = isMultiSelection ? [...selectedItems, item] : [item];
    }
    onClickSelectableProduct(isMultiSelection ? updatedItems : item);
  }

  function _renderList(
    productListType: string,
    isShopifyIntegrationEnabled: boolean,
  ) {
    if (productListType === "onlineStore" && !isShopifyIntegrationEnabled) {
      return <ConnectShopifyCallout type="productPicker" />;
    } else if (
      productListType === "onlineStore" &&
      isShopifyIntegrationEnabled
    ) {
      return (
        <InfiniteList
          options={paginatedProducts!}
          selectedItems={selectedItems}
          onSelect={onSelect}
          settings={{
            hasNextPage: pageInfo?.hasNextPage ?? false,
            isNextPageLoading: isFetchingNextPage ?? false,
            loadNextPage: fetchNextPage ?? (() => Promise.resolve()),
          }}
          isMultiselect={isMultiSelection}
          itemSize={itemSize}
          labelClassName={labelClassName}
          noItemsPlaceholder={noItemsPlaceholder}
        />
      );
    }
    return (
      <RegularList
        options={paginatedProducts!}
        itemSize={itemSize}
        onSelect={onSelect}
        selectedItems={selectedItems}
        isMultiselect={isMultiSelection}
        labelClassName={labelClassName}
        noItemsPlaceholder={noItemsPlaceholder}
      />
    );
  }

  return (
    <Popover.Content
      title={title ?? "Products"}
      shouldPreventDefaultOnInteractOutside={areModalsOpen}
      side={side}
      sideOffset={offset}
      className={className}
    >
      <div className="mb-2">
        <Input
          isDisabled={productListType === "placeholders"}
          autoFocus={true}
          size="sm"
          value={searchTerm}
          startEnhancer={() => <BsSearch size={10} />}
          endEnhancer={() =>
            searchTerm?.trim() && (
              <FormFieldXButton onClick={() => onSearch("")} />
            )
          }
          placeholder="Name or Product ID"
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
      {showPlaceholders ? (
        <ToggleGroup
          allowsDeselect={false}
          type="single"
          className="mb-2"
          style={{ width: "100%" }}
          options={[
            {
              label: "Online Store",
              value: "onlineStore",
              tooltipContent:
                "Product data is loaded automatically from your Shopify store",
              attributes: {
                disabled: isStoreClosed,
              },
            },
            {
              label: "Placeholders",
              value: "placeholders",
              tooltipContent:
                "Placeholders are generic examples of products to help when building product components",
            },
          ]}
          value={productListType}
          onChange={(value) => {
            setProductListType(value);
            setSearchTerm("");
          }}
        />
      ) : null}
      {_renderList(productListType, isShopifyIntegrationEnabled)}
    </Popover.Content>
  );
};

const ProductSelectionPopoverTrigger: React.FC<
  ProductSelectionPopoverTriggerProps
> = ({ render, showDeleteButton = true }) => {
  const {
    isMultiSelection,
    onSubmit,
    selectedProductRefs,
    setSelectedProductRefs,
    availableProducts,
    paginatedProducts,
  } = useProductSelectionPopoverContext();

  const isDebugMode = useIsDebugMode();
  const localStorage = useLocalStorage();
  const [shouldKeepTooltipOpen] = React.useState(() =>
    Boolean(
      isDebugMode &&
        JSON.parse(
          localStorage.getItem("replo.debug.keepProductTooltipOpen") ?? "false",
        ),
    ),
  );

  const selectedProducts = React.useMemo(() => {
    // Note (Noah, 2022-07-19): Try looking both in the default store products
    // (which could be any subset of the products in the store) and in the draft
    // element products, since we might just have selected a product in the
    // infinite product loading UI, and in that case it will definitely be in
    // draftElementProducts but not necessarily in storeProducts
    return selectedProductRefs
      .map((productRef) => {
        return (
          paginatedProducts?.find(
            (p) => p.productId === Number(productRef.productId),
          ) ??
          availableProducts?.find(
            (p) => p.productId === Number(productRef.productId),
          ) ??
          null
        );
      })
      .filter((v): v is Exclude<typeof v, undefined | null> => v != null);
  }, [selectedProductRefs, paginatedProducts, availableProducts]);

  const firstSelectedProduct = selectedProducts[0];

  const getPopoverLabel = () => {
    if (isMultiSelection) {
      // NOTE (Gabe 2023-10-09): We use selectedProductRefs here so that this
      // does not depend on having the actual product data.
      const selectedCount = selectedProductRefs.length;
      return {
        buttonText:
          selectedCount > 0
            ? `${selectedCount} ${
                selectedCount === 1 ? "Product" : "Products"
              } Selected`
            : "Select Products",
        tooltipText: null,
      };
    }

    if (firstSelectedProduct) {
      return {
        buttonText: firstSelectedProduct.title,
        tooltipText: `${firstSelectedProduct.title} (${firstSelectedProduct.productId})`,
      };
    }

    return {
      buttonText: "Select a Product",
      tooltipText: null,
    };
  };

  const { buttonText, tooltipText } = getPopoverLabel();

  function getEndEnhancer() {
    return (
      showDeleteButton && (
        <FormFieldXButton
          onClick={(e) => {
            e.stopPropagation();
            onClearSelection();
          }}
        />
      )
    );
  }

  function onClearSelection() {
    setSelectedProductRefs([]);
    if (isMultiSelection) {
      onSubmit([]);
    } else {
      onSubmit(null);
    }
  }

  return (
    <Popover.Trigger asChild>
      <div data-testid="trigger-product">
        <Tooltip
          content={tooltipText}
          triggerAsChild
          className="w-full"
          disableHoverableContent={!shouldKeepTooltipOpen}
        >
          {/* Note (Noah, 2022-12-29): We need this div here since we need a flex
              container which will expand appropriately so that the product title can
              be ellipsized using CSS (the default <button> that Tooltip renders if
              triggerAsChild messes this up) */}
          <div>
            {render != null ? (
              render({
                selectedProducts,
                onClearSelection,
                label: buttonText,
              })
            ) : (
              <SelectionIndicator
                className="w-full cursor-pointer"
                startEnhancer={imgOrPlaceholder(
                  firstSelectedProduct?.featuredImage,
                  "rounded-[2px] h-4 w-4 object-cover",
                  "bg-gray-200",
                )}
                endEnhancer={getEndEnhancer()}
                title={selectedProducts.length > 0 ? buttonText : undefined}
                placeholder={
                  selectedProducts.length === 0 ? buttonText : undefined
                }
              />
            )}
          </div>
        </Tooltip>
      </div>
    </Popover.Trigger>
  );
};

const ProductSelectionPopoverAnchor = Popover.Anchor;
ProductSelectionPopoverAnchor.displayName = "ProductSelectionPopoverAnchor";

export {
  ProductSelectionPopover as Root,
  ProductSelectionPopoverContent as Content,
  ProductSelectionPopoverTrigger as Trigger,
  ProductSelectionPopoverAnchor as Anchor,
};
