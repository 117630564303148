import type { MenuItem } from "@common/designSystem/Menu";
import type { AppRouterOutputs } from "@editor/utils/trpc";
import type { User } from "replo-runtime/shared/types";
import type { WorkspaceRole } from "schemas/generated/workspace";

import * as React from "react";

import Avatar from "@common/designSystem/Avatar";
import { Menu } from "@common/designSystem/Menu";
import { successToast } from "@common/designSystem/Toast";
import TableHeadTile from "@components/dashboard/tables/TableHeadTitle";
import { Input } from "@editor/components/common/designSystem/Input";
import Popover from "@editor/components/common/designSystem/Popover";
import Separator from "@editor/components/common/designSystem/Separator";
import { Loader } from "@editor/components/common/Loader";
import Header from "@editor/components/dashboard/Header";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useIsWorkspaceOwner } from "@editor/hooks/useIsWorkspaceOwner";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { docs } from "@editor/utils/docs";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import Tooltip from "@replo/design-system/components/tooltip";
import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import { BsInfoCircleFill, BsSearch, BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useDebouncedState } from "replo-utils/react/use-debounced-state";

type Field = "member.username" | "member.role";
type Direction = "asc" | "desc";

type OrderState = {
  field: Field;
  direction: Direction;
};

const Members: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId();

  const { user } = useCurrentUser();

  const { openModal } = useModal();
  const [searchQueryInputValue, searchQuery, setSearchQueryInputValue] =
    useDebouncedState("");
  const [order, setOrder] = React.useState<OrderState>({
    field: "member.username",
    direction: "asc",
  });

  const { data: workspaceData, isPending: isLoadingWorkspace } =
    trpc.workspace.getById.useQuery(
      workspaceId ? { id: workspaceId } : reactQuerySkipToken,
    );

  const { data: members, isPending: isLoading } =
    trpc.workspace.getWorkspaceAndProjectMembers.useQuery(
      workspaceId ?? reactQuerySkipToken,
    );

  const filteredMembers = members?.filter((member) => {
    const query = searchQuery.toLowerCase();
    return (
      `${member.firstName} ${member.lastName}`.toLowerCase().includes(query) ||
      member.email.toLowerCase().includes(query)
    );
  });

  const handleSortClick = (field: Field) => {
    if (field === order.field) {
      setOrder({
        field: field,
        direction: order.direction === "asc" ? "desc" : "asc",
      });
    } else {
      setOrder({ field: field, direction: "desc" });
    }
  };

  const sortedProjectMembers = React.useMemo(
    () =>
      filteredMembers &&
      sortMembers(order.field, order.direction, filteredMembers),
    [filteredMembers, order.field, order.direction],
  );

  const shouldShowEmptyState = !isLoading && sortedProjectMembers?.length === 0;

  return (
    // NOTE (Sebas, 2024-03-20): This padding bottom is necessary is for the support button
    <div className="flex flex-col px-6 w-full max-w-screen-xl h-full pb-14">
      <Header
        title="Members"
        subtitle={`Configure members for the ${workspaceData?.workspace.name} workspace`}
        buttonLabel="Add Members"
        onButtonClick={() => {
          if (workspaceId) {
            openModal({
              type: "inviteTeamMembersModal",
              props: { workspaceId },
            });
          }
        }}
        isLoading={isLoading || isLoadingWorkspace}
      />
      <Separator className="my-4 col-span-12" />
      <div className="mb-4 max-w-xs">
        <Input
          size="base"
          placeholder="Search by name or email"
          type="text"
          value={searchQueryInputValue}
          onChange={(event) => setSearchQueryInputValue(event.target.value)}
          startEnhancer={() => <BsSearch className="text-xs text-slate-400" />}
        />
      </div>
      <div className="grid grid-cols-12 my-2">
        <TableHeadTile
          title="Name"
          onClick={() => handleSortClick("member.username")}
          arrowDirection={order.direction}
          shouldShowArrow={order.field === "member.username"}
          wrapperClassName="col-span-4"
        />
        <TableHeadTile
          title="Role"
          onClick={() => handleSortClick("member.role")}
          arrowDirection={order.direction}
          shouldShowArrow={order.field === "member.role"}
          wrapperClassName="col-span-4"
          endEnhancer={
            <Tooltip content="Learn More" triggerAsChild>
              <Link to={docs.permissions} target="_blank">
                <BsInfoCircleFill
                  onClick={(e) => e.stopPropagation()}
                  className="text-slate-400"
                  size={12}
                />
              </Link>
            </Tooltip>
          }
        />
      </div>
      {shouldShowEmptyState && (
        <div className="py-12 justify-center flex text-slate-400 -ml-48">
          No members found
        </div>
      )}
      {isLoading && (
        <div className="w-full flex h-full justify-center items-center">
          <Loader className="mt-16" />
        </div>
      )}
      {!shouldShowEmptyState &&
        user &&
        workspaceId &&
        sortedProjectMembers &&
        sortedProjectMembers?.map((u) => (
          <RowItem
            key={u.id}
            user={u}
            currentUser={user}
            workspaceId={workspaceId}
          />
        ))}
    </div>
  );
};

const RowItem: React.FC<{
  user: AppRouterOutputs["workspace"]["getWorkspaceAndProjectMembers"][0];
  currentUser: User;
  workspaceId: string;
}> = ({ user, currentUser, workspaceId }) => {
  const logEvent = useLogAnalytics();

  const { mutate: resendInvite } = trpc.workspace.resendInvite.useMutation({
    onSuccess: () => {
      successToast("Invites sent", "The invites were sent successfully.");
      logEvent("workspace.invite.resend", {
        resendInviteTo: user.email,
        workspaceId: workspaceId,
      });
    },
  });

  const { mutate: updatePermissions } =
    trpc.workspace.updatePermission.useMutation({
      onSuccess: () => {
        void trpcUtils.workspace.getWorkspaceAndProjectMembers.invalidate(
          workspaceId,
        );
      },
    });

  const isCurrentUserOwner = useIsWorkspaceOwner(workspaceId);
  const { openModal } = useModal();

  const role = user.workspaceMemberships[0]?.role as WorkspaceRole | undefined;

  const menuItems: MenuItem[] = [];

  if (isCurrentUserOwner || currentUser.email === user.email) {
    menuItems.push({
      id: "remove-membership",
      type: "leaf",
      title: `Remove ${user.firstName ?? user.email}`,
      onSelect: () => {
        if (workspaceId) {
          openModal({
            type: "removeMemberModal",
            props: { workspaceId, user },
          });
        }
      },
    });
  }

  if (!user.registeredAt) {
    menuItems.push({
      id: "resend-email",
      type: "leaf",
      title: `Resend invitation to ${user.email}`,
      onSelect: () =>
        void resendInvite({
          email: user.email,
          workspaceId,
        }),
    });
  }

  if (isCurrentUserOwner && currentUser.id !== user.id && role) {
    const isUserMember = role === "member";
    menuItems.push({
      id: "update-permissions",
      type: "leaf",
      title: isUserMember ? "Upgrade to Owner" : "Downgrade to Member",
      onSelect: () => {
        void handleUpdatePermissions(workspaceId, user.email, role);
      },
    });
  }

  if (isCurrentUserOwner && currentUser.id !== user.id && !role) {
    menuItems.push({
      id: "invite-member",
      type: "leaf",
      title: "Invite to Workspace",
      onSelect: () => {
        openModal({
          type: "inviteTeamMembersModal",
          props: { workspaceId, email: user.email },
        });
      },
    });
  }

  const handleUpdatePermissions = async (
    workspaceId: string,
    email: string,
    role: WorkspaceRole,
  ) => {
    const isUserMember = role === "member";
    const newRole = isUserMember ? "owner" : "member";
    void updatePermissions({
      email,
      workspaceId,
      role: newRole,
    });
  };

  return (
    <div key={user.email} className="py-2 border-b border-slate-100">
      <div className="grid grid-cols-12 items-center">
        <div className="flex gap-1 items-center col-span-4 flex-nowrap overflow-auto md:gap-2">
          <Avatar name={user.firstName} className="w-8 h-8" />
          <div className="flex flex-col overflow-hidden">
            <p className="text-sm font-medium text-default truncate">
              {user.firstName} {user.lastName}
            </p>
            <p className="text-sm text-slate-400 truncate font-medium">
              {user.email}
            </p>
          </div>
        </div>
        <div className="col-span-7 items-center flex gap-8">
          {role ? (
            <p className="text-slate-400 capitalize text-sm">{role}</p>
          ) : (
            <div>
              <Popover>
                <Popover.Trigger>
                  <div className="text-blue-600 text-sm">
                    Can edit {user.projectMemberships.length} projects
                  </div>
                </Popover.Trigger>
                <Popover.Content
                  shouldPreventDefaultOnInteractOutside={false}
                  className="p-4 max-w-[300px] text-sm font-normal"
                  title="Projects"
                  titleClassnames="text-sm font-semibold"
                >
                  <div className="max-h-[180px] overflow-scroll">
                    {user.projectMemberships.map(({ project }, index) => (
                      <React.Fragment key={project.id}>
                        <div className="text-slate-600 whitespace-nowrap overflow-hidden text-ellipsis">
                          {project.name}
                        </div>
                        {index < user.projectMemberships.length - 1 && (
                          <hr className="my-2 text-slate-200" />
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </Popover.Content>
              </Popover>

              <div className="text-slate-400 text-sm">
                No workspace level access or permissions
              </div>
            </div>
          )}
        </div>
        {menuItems.length > 0 && (
          <div className="flex col-span-1 justify-end p-2">
            <Menu
              items={menuItems}
              customWidth="auto"
              align="start"
              trigger={<BsThreeDots size={16} className="text-slate-400" />}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const sortMembers = (
  field: Field,
  direction: Direction,
  data: AppRouterOutputs["workspace"]["getWorkspaceAndProjectMembers"],
) => {
  const sortedData = [...(data ?? [])];
  sortedData.sort((a, b) => {
    if (field === "member.username" && a.firstName && b.firstName) {
      return direction === "asc"
        ? a.firstName.localeCompare(b.firstName)
        : b.firstName.localeCompare(a.firstName);
    }

    if (field === "member.role") {
      const aRole =
        (a.workspaceMemberships[0]?.role as WorkspaceRole | undefined) ??
        "none";
      const bRole =
        (b.workspaceMemberships[0]?.role as WorkspaceRole | undefined) ??
        "none";
      const rolesOrder = {
        owner: { asc: 1, desc: -1 },
        member: { asc: 0, desc: 0 },
        viewer: { asc: -1, desc: 1 },
        none: { asc: -2, desc: 2 },
      };
      return rolesOrder[aRole][direction] - rolesOrder[bRole][direction];
    }

    return 0;
  });
  return sortedData;
};

export default Members;
