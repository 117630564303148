import * as React from "react";

import AskAIHeader from "@components/editor/ai/AskAiHeader";
import useExponentialProgressInterval from "@editor/hooks/useExponentialProgressInterval";
import { loadingTextChoices } from "@editor/utils/ai-loading-text";

const AskAILoadingAnimation = () => {
  return (
    <div className="relative m-[2.8em] h-[100px] w-[100px]">
      <div className="replo-cubes-loading-box">
        <div className="replo-cubes-loading-cube one"></div>
        <div className="replo-cubes-loading-cube two"></div>
        <div className="replo-cubes-loading-cube three"></div>
      </div>
    </div>
  );
};

const getRandomInRange = (min: number, max: number): number => {
  return Math.random() * (max - min) + min;
};

export const AskAILoading = (props: {
  expectedMillisecondsToComplete: number;
}) => {
  // Note (Noah, 2023-07-13): The progress here is fake of course, but we simulate using
  // an exponential curve which never reaches 100% but gets to 90% at the average expected
  // time based on what we've seen in our logs.
  const progress = useExponentialProgressInterval(1000, {
    valueAtExpectedTimeToComplete: 0.9,
    expectedTimeToComplete: props.expectedMillisecondsToComplete,
  });
  const [offset, setOffset] = React.useState(0);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  React.useEffect(() => {
    setOffset((prevOffset) => prevOffset + getRandomInRange(0, 0.005));
  }, [progress]);

  const totalProgress = Math.min(progress + offset, 0.9999);

  const text =
    useMemoEveryNthRender(
      3,
      () => {
        return getRandomArrayElement(loadingTextChoices);
      },
      [progress],
    ) ?? loadingTextChoices[0]!;
  return (
    <div className="align-center flex grow flex-col items-center justify-center gap-4">
      <AskAILoadingAnimation />
      <div className="flex flex-col items-center gap-2">
        <AskAIHeader
          content={`${(Math.max(totalProgress, 0) * 100).toFixed(2)}%`}
          className="text-md"
        />
        <AskAIHeader
          content={text.content}
          endEnhancer={<span>{text.emoji}</span>}
          className="text-sm"
        />
        <div className="text-sm text-slate-400">
          Your content may take a few minutes to generate.
        </div>
      </div>
    </div>
  );
};

function useMemoEveryNthRender<T>(n: number, memo: () => T, deps: any[]) {
  const [counter, setCounter] = React.useState(0);
  const [value, setValue] = React.useState<T | null>(null);
  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  const calculate = React.useCallback(() => {
    return memo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  React.useEffect(() => {
    if (counter % n === 0) {
      setValue(calculate());
    }
    setCounter(counter + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [n, ...deps]);

  return value;
}

function getRandomArrayElement<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)]!;
}
