import * as React from "react";

type Image = {
  src?: string;
  alt?: string;
  title: string;
  description: string;
  className?: string;
  href?: string;
};

type Images = {
  content: Image[];
  title?: string;
  button?: React.ReactNode;
};

type ContentWrapperProps = {
  children: React.ReactNode;
  className: string;
  href?: string;
};

export const BenefitsImagesLayout = ({ content, title, button }: Images) => {
  return (
    <div className="flex w-full">
      <div className="max-w-custom mx-auto gap-9 bg-white py-6 md:inline-flex md:flex-col">
        {title && (
          <div className="mb-10 w-full text-left text-4xl font-semibold md:mb-0 lg:pr-96">
            {title}
          </div>
        )}
        <div className="flex flex-col items-start justify-start gap-6 md:grid md:grid-cols-2 lg:grid-cols-3">
          {content.map(({ src, alt, title, description }) => {
            return (
              <ContentWrapper
                key={title}
                className="flex flex-col items-start justify-start gap-6"
              >
                {src && (
                  <div className="max-h-[284px] max-w-[403px]">
                    <img
                      className="h-fit w-full"
                      src={src}
                      alt={alt}
                      width={403}
                      height={284}
                    />
                  </div>
                )}
                <div className="flex flex-col items-start justify-start gap-6">
                  <div className="flex flex-col items-start justify-start gap-2 self-stretch">
                    <div className="self-stretch text-xl font-semibold leading-7 text-black">
                      {title}
                    </div>
                    <div className="self-stretch text-lg font-normal leading-7 text-stone-900">
                      {description}
                      <br />
                    </div>
                  </div>
                </div>
              </ContentWrapper>
            );
          })}
        </div>
        {button && (
          <div className="my-5 flex w-full justify-center">{button}</div>
        )}
      </div>
    </div>
  );
};

const ContentWrapper: React.FC<ContentWrapperProps> = ({
  children,
  className,
  href,
}) => {
  if (href) {
    return (
      <div className={className} ref={href}>
        {children}
      </div>
    );
  }
  return <div className={className}>{children}</div>;
};
