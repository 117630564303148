import type {
  ComparisonTimeFrame,
  RelativeTimeFrame,
} from "@/features/analytics/time";
import type { ComboboxProps } from "@replo/design-system/components/shadcn/combobox/types";
import type { DateAfter, DateRange } from "react-day-picker";

import * as React from "react";

import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import {
  calculateCompareRangePredefined,
  calculateCustomRange,
  calculateQueryRangesForRelativeTimeFrame,
  calculateRangesForCustomTimeFrame,
} from "@/features/analytics/time";
import { Combobox } from "@replo/design-system/components/shadcn/combobox/Combobox";
import { DatePickerWithRange } from "@replo/design-system/components/shadcn/core/date-range-picker";
import { BsCalendar3, BsCaretDownFill } from "react-icons/bs";

type AnalyticsTimeRangeComboboxProps<
  T extends RelativeTimeFrame | CompareRangeHandleChange,
> = Pick<
  ComboboxProps,
  "options" | "open" | "onOpenChange" | "placeholderPrefix"
> & {
  handleRangeChange: (timeFrame: T) => void;
  isCompare: boolean;
};

type CompareRangeHandleChange = ComparisonTimeFrame | DateRange;
type MainRangeHandleChange = RelativeTimeFrame | DateRange;

const rangeCustomDatePicker = (
  startDateTime: number,
  endDateTime: number,
  handleRangeChange: (dateRange: DateRange) => void,
  setIsRangePickerOpen: (isOpen: boolean) => void,
): React.ReactNode => {
  return (
    <DatePickerWithRange
      initialDateRange={{
        from: new Date(startDateTime),
        to: new Date(endDateTime),
      }}
      disabledDates={{ after: new Date() } as DateAfter}
      updateAction={(dateRange) => {
        handleRangeChange(dateRange);
        setIsRangePickerOpen(false);
      }}
      popoverTriggerComponent={() => (
        <span className="text-default">Custom date range</span>
      )}
      closeAction={() => setIsRangePickerOpen(false)}
      rangeExtendsOnSelect={false}
    />
  );
};

export const AnalyticsTimeRangeCombobox = <
  T extends MainRangeHandleChange | CompareRangeHandleChange,
>({
  handleRangeChange,
  isCompare,
  ...props
}: AnalyticsTimeRangeComboboxProps<T>) => {
  const { getParamValue } = useAnalyticsQueryContext();

  let startDatetime;
  let endDatetime;
  let selectedValue;
  const selectedTimePeriod = getParamValue("selectedTimePeriod");
  const selectedComparePeriod = getParamValue("selectedComparePeriod");
  const { updatedRanges, selectedTimeFrame } =
    selectedTimePeriod.type === "custom"
      ? calculateRangesForCustomTimeFrame({
          start: selectedTimePeriod.value.from,
          end: selectedTimePeriod.value.to,
        })
      : calculateQueryRangesForRelativeTimeFrame(
          selectedTimePeriod.value as RelativeTimeFrame,
        );

  if (!isCompare) {
    selectedValue = selectedTimeFrame;
    startDatetime = updatedRanges.mainRange.startDatetime;
    endDatetime = updatedRanges.mainRange.endDatetime;
  } else {
    const { range: compareAtRange, selectedPeriod } =
      selectedComparePeriod.type === "custom"
        ? calculateCustomRange({
            start: new Date(selectedComparePeriod.value.from),
            end: new Date(selectedComparePeriod.value.to),
          })
        : calculateCompareRangePredefined(
            selectedComparePeriod.value as ComparisonTimeFrame,
            updatedRanges.mainRange,
          );

    selectedValue = selectedPeriod;
    startDatetime = compareAtRange.startDatetime;
    endDatetime = compareAtRange.endDatetime;
  }

  const options = [
    ...props.options,
    {
      value: isCompare ? "custom-compare-range" : "custom-main-range",
      label: "Custom Range",
      component: rangeCustomDatePicker(
        startDatetime ?? 0,
        endDatetime ?? 0,
        (dateRange) => handleRangeChange(dateRange as T),
        (isOpen) => props.onOpenChange?.(isOpen),
      ),
      isBelowSeparator: true,
    },
  ];
  return (
    <Combobox
      {...props}
      value={selectedValue}
      options={options}
      onChange={(value) => {
        handleRangeChange(value as T);
      }}
      startEnhancer={() => <BsCalendar3 className="h-4 w-4" />}
      endEnhancer={() => <BsCaretDownFill className="h-2 w-2 text-subtle" />}
    />
  );
};
