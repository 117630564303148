import type { BillingUpgradeDowngradeModalProps } from "../AppModalTypes";

import * as React from "react";

import { ModalLayout } from "@common/ModalLayout";
import Modal from "@editor/components/common/designSystem/Modal";
import {
  SkeletonLoaderItem,
  SkeletonLoaderWrapper,
} from "@editor/components/common/designSystem/SkeletonLoader";
import { useSubscriptionDetails } from "@editor/hooks/subscription";
import useInitializeSubscriptionUpdateMutation from "@editor/hooks/trpc/useInitializeSubscriptionUpdateMutation";
import { useModal } from "@editor/hooks/useModal";
import { trpc } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button";
import { skipToken } from "@tanstack/react-query";
import classNames from "classnames";
import { format } from "date-fns";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { capitalizeFirstLetter } from "replo-utils/lib/string";
import { billingPlansByTier } from "schemas/billing";

import { PlanInfoDetails } from "./BillingModal";
import { DowngradeFeatureComparison } from "./BillingPlanChangeSurvey";
import { BillingConfirmation } from "./shared/BillingConfirmation";

export const BillingUpgradeDowngradeModal: React.FC<
  BillingUpgradeDowngradeModalProps
> = ({ workspaceId, currentTier, newTier, type, source }) => {
  const modal = useModal();
  const { data: subscriptionDetails } = useSubscriptionDetails(workspaceId);
  const {
    initializeSubscriptionUpdate,
    isLoading,
    data: subscriptionUpdateResult,
  } = useInitializeSubscriptionUpdateMutation();

  const {
    data: nextSubscriptionBillingDate,
    isFetching: isFetchingNextSubscriptionBillingDate,
  } = trpc.billing.getStripeNextBillingDate.useQuery(
    subscriptionDetails?.paymentProcessor === "stripe" &&
      subscriptionDetails.paymentProcessorSubscriptionId
      ? {
          workspaceId,
          stripeSubscriptionId:
            subscriptionDetails.paymentProcessorSubscriptionId,
        }
      : skipToken,
  );

  const nextSubscriptionBillingDateString = nextSubscriptionBillingDate
    ? format(new Date(nextSubscriptionBillingDate), "MMMM do, yyyy")
    : undefined;

  const currentTierName = capitalizeFirstLetter(currentTier);
  const newTierName = capitalizeFirstLetter(newTier);

  const currentPlanInfo = billingPlansByTier[currentTier];
  const newPlanInfo = billingPlansByTier[newTier];

  const copy = exhaustiveSwitch({ type })({
    upgrade: {
      header: `🚀 Upgrade to ${newTierName} Plan!`,
      subheader: `Join leading brands like ${newPlanInfo.exampleBrandName} and instantly access
      premium features.`,
      bullets: [
        {
          bold: "Immediate Access: ",
          text: `Switch to ${newTierName} Plan now, no waiting!`,
        },
        {
          bold: "Simple Billing: ",
          text: `Pay an additional prorated charge for this cycle.
      ${newTierName} Plan rates apply from your next billing
      cycle.`,
        },
      ],
      footer: "Upgrade now and unlock your workspace's full potential.",
      button: "Upgrade",
      successTitle: "Congratulations!",
      successBody: "You just upgraded your plan. 🥳",
    },
    downgrade: {
      header: `🌟 Switching to the ${newTierName} Plan?`,
      subheader:
        "We're here to ensure you're on the right plan for your needs.",
      bullets: [
        {
          bold: "Plan Change: ",
          text: `Moving from ${currentTierName} to ${newTierName} plan.`,
        },
        {
          bold: "Feature Access: ",
          text: `Your workspace will lose access to ${currentTierName} Plan features immediately.`,
        },
        {
          bold: "Billing Adjustments: ",
          text: `You'll receive a prorated refund for this cycle. ${newTierName} rates apply starting your next billing cycle.`,
        },
      ],
      footer: "Confirm downgrade to adjust your workspace.",
      button: "Downgrade",
      successTitle: "Looks good!",
      successBody: "You downgraded your plan. 👍🏻",
    },
  });

  const closeModal = () => {
    modal.closeModal({ type: "billingUpgradeDowngradeModal" });
  };

  return (
    <Modal
      isOpen
      className="h-auto w-auto overflow-scroll no-scrollbar"
      style={{ maxHeight: "100vh" }}
      includesCloseIcon
      data-testid="billing-plan-confirmation-modal"
      onRequestClose={closeModal}
    >
      <ModalLayout
        width={900}
        height={550}
        mainContent={() => {
          if (!isLoading && subscriptionUpdateResult?.success) {
            return (
              <BillingConfirmation
                title={copy.successTitle}
                body={copy.successBody}
                onClose={closeModal}
              />
            );
          }
          return (
            <div className="flex h-full w-full flex-col justify-between gap-2">
              <div className="flex flex-row gap-4 min-h-[400px] mt-8">
                <div className="w-1/3 bg-slate-50 px-4 pt-4 pb-2 rounded flex flex-col justify-between">
                  {type === "upgrade" ? (
                    <PlanInfoDetails plan={newPlanInfo} />
                  ) : (
                    <DowngradeFeatureComparison
                      currentPlan={currentPlanInfo}
                      lowerTier={newPlanInfo}
                    />
                  )}
                </div>
                <div className="flex flex-col justify-between pb-12 px-4 w-2/3">
                  <div className="flex flex-col gap-4">
                    <h2 className="text-xl font-semibold">{copy.header}</h2>
                    <div>{copy.subheader}</div>
                    <ul className="list-disc pl-5 space-y-2">
                      {copy.bullets.map(({ bold, text }) => (
                        <li key={bold}>
                          <span className="font-bold">{bold}</span>
                          {text}
                        </li>
                      ))}
                    </ul>
                    <div>{copy.footer}</div>
                  </div>
                  {isFetchingNextSubscriptionBillingDate && (
                    <SkeletonLoaderWrapper height={24} width={270}>
                      <SkeletonLoaderItem height="24" width="270" yAxis="0" />
                    </SkeletonLoaderWrapper>
                  )}
                  {nextSubscriptionBillingDateString && (
                    <div className="font-bold">{`Next Billing Cycle: ${nextSubscriptionBillingDateString}`}</div>
                  )}
                </div>
              </div>

              <div className="flex w-full flex-row justify-end gap-3">
                <Button
                  variant="secondary"
                  size="lg"
                  onClick={() => {
                    closeModal();
                    modal.openModal({
                      type: "billingModal",
                      props: { source },
                    });
                  }}
                  isDisabled={isLoading}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  size="lg"
                  className={classNames({
                    "hover:bg-blue-600 cursor-not-allowed": isLoading,
                  })}
                  isLoading={isLoading}
                  onClick={() => {
                    if (!isLoading) {
                      void initializeSubscriptionUpdate({
                        workspaceId,
                        tier: newTier,
                        source,
                      });
                    }
                  }}
                >
                  {copy.button}
                </Button>
              </div>
            </div>
          );
        }}
      />
    </Modal>
  );
};
