import type { EditorCanvas, IndividualCanvasState } from "./canvas-types";

import * as React from "react";

import InlinePopover from "@editor/components/common/designSystem/InlinePopover";
import { Menu, MenuTrigger } from "@editor/components/common/designSystem/Menu";
import { HotkeyIndicator } from "@editor/components/common/HotkeyIndicator";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { setEditorMode } from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";

import * as Checkbox from "@radix-ui/react-checkbox";
import Button from "@replo/design-system/components/button";
import classNames from "classnames";
import { RiCheckFill, RiListUnordered, RiPlayFill } from "react-icons/ri";
import { clamp } from "replo-utils/lib/math";
import { preventDefault } from "replo-utils/lib/misc";
import { capitalizeFirstLetter } from "replo-utils/lib/string";

import {
  CANVAS_DATA,
  CANVAS_FRAME_GAP,
  CANVAS_NAVBAR_HEIGHT,
  CANVAS_NAVBAR_VERTICAL_SPACING,
} from "./canvas-constants";
import {
  hideCanvas,
  selectCanvasDeltaXY,
  selectCanvases,
  selectCanvasesKeys,
  selectCanvasFrameWidths,
  selectCanvasInteractionMode,
  selectCanvasScale,
  selectVisibleCanvases,
  setPreviewWidth,
  showCanvas,
} from "./canvas-reducer";
import { CustomViewportControl } from "./CanvasControls";

export function CanvasHeaderBar() {
  const visibleCanvases = useEditorSelector(selectVisibleCanvases);
  const canvasScale = useEditorSelector(selectCanvasScale);
  const { deltaX, deltaY } = useEditorSelector(selectCanvasDeltaXY);
  const canvasInteractionMode = useEditorSelector(selectCanvasInteractionMode);
  // Note (Evan, 2024-10-01): When the canvas is locked (during AI streaming), we
  // hide this along with other aspects of the editor UI like sidebars
  if (canvasInteractionMode === "locked") {
    return null;
  }

  // Note (Noah, 2024-07-01): We want the nav bar to "stick" to the top of
  // the canvas with a gap, but when you zoom out or in too far, just
  // scaling this gap with the canvasScale makes it a little too big, so
  // we clamp it to a reasonable size.
  const yOffset =
    // First offset by deltaY so it's even with the top of the canvas
    deltaY -
    // Then offset by the navbar height so that the navbar will stick exactly
    // to the top of the canvas
    CANVAS_NAVBAR_HEIGHT -
    // Then offset by the gap so there's a bit of space between the navbar and
    // the top of the canvas
    clamp(
      CANVAS_NAVBAR_VERTICAL_SPACING * canvasScale,
      6,
      CANVAS_NAVBAR_VERTICAL_SPACING,
    );
  return (
    <div
      className="flex absolute top-0 left-0 origin-[0_0]"
      style={{
        transform: `translate3d(${deltaX}px, ${yOffset}px, 0px)`,
        gap: CANVAS_FRAME_GAP * canvasScale,
      }}
    >
      <DevicesToolbar />
      {Object.entries(visibleCanvases).map(([key, canvas]) => (
        <CanvasNavbar
          key={key}
          canvasKey={key as EditorCanvas}
          canvasState={canvas}
        />
      ))}
    </div>
  );
}

function CanvasNavbar({
  canvasKey,
  canvasState,
}: {
  canvasKey: EditorCanvas;
  canvasState: IndividualCanvasState;
}) {
  const dispatch = useEditorDispatch();
  const canvasScale = useEditorSelector(selectCanvasScale);
  const visibleCanvasesFrameWidth = useEditorSelector(selectCanvasFrameWidths);
  const logEvent = useLogAnalytics();

  function previewCanvas() {
    logEvent("canvas.preview", {
      source: "frameHeader",
      frame: canvasKey,
    });
    dispatch(setPreviewWidth(canvasState.canvasWidth));
    dispatch(setEditorMode(EditorMode.preview));
  }
  const canvasName = capitalizeFirstLetter(canvasKey);

  const visibleCanvases = useEditorSelector(selectVisibleCanvases);
  const isOnlyOneCanvasActive = Object.values(visibleCanvases).length === 1;
  const isNoMirrorEnabled = isFeatureEnabled("no-mirror");
  const width = isNoMirrorEnabled
    ? visibleCanvasesFrameWidth[canvasKey]
    : canvasState.canvasWidth;
  return (
    <div
      className="flex bg-white py-2 shadow-lg @container rounded"
      style={{
        width: width * canvasScale,
      }}
    >
      <Menu
        menuType="context"
        contentClassNames="w-full"
        items={[
          {
            type: "leaf",
            id: "hide-canvas",
            title: `Hide ${canvasName}`,
            onSelect: () => {
              dispatch(hideCanvas(canvasKey));
            },
            isDisabled: isOnlyOneCanvasActive,
          },
        ]}
        trigger={
          <MenuTrigger className="w-full flex bg-transparent cursor-default">
            <div className="px-1 @[100px]:pl-3 @[100px]:pr-2 justify-center w-full flex @[100px]:justify-between items-center">
              <div className="items-center gap-4 hidden @[100px]:flex">
                <div className="text-default text-sm font-bold hidden @[100px]:block">
                  {canvasName}
                </div>
                <div className="hidden @[210px]:block">
                  <CustomViewportControl canvas={canvasKey} />
                </div>
              </div>
              <Button
                id="preview-button"
                variant="secondary"
                size="base"
                icon={<RiPlayFill />}
                tooltipText={<HotkeyIndicator hotkey="togglePreviewMode" />}
                onClick={previewCanvas}
              />
            </div>
          </MenuTrigger>
        }
      />
    </div>
  );
}

function DevicesToolbar() {
  const canvasesKeys = useEditorSelector(selectCanvasesKeys);
  const canvasScale = useEditorSelector(selectCanvasScale);

  return (
    <div
      className="absolute top-0 right-full"
      style={{
        marginRight: CANVAS_FRAME_GAP * canvasScale,
      }}
    >
      <InlinePopover
        triggerAsChild
        shouldPreventDefaultOnInteractOutside={false}
        content={
          <fieldset className="flex flex-col gap-3 mt-4 rounded">
            <legend className="sr-only">Toggle devices</legend>
            <form className="contents" onSubmit={preventDefault}>
              {canvasesKeys.map((canvas) => (
                <CanvasCheckbox key={canvas} canvas={canvas as EditorCanvas} />
              ))}
            </form>
          </fieldset>
        }
        side="bottom"
        title="Show Devices"
        className="min-w-40 py-4"
        align="start"
        alignOffset={-10}
        stayInPosition
      >
        <button
          type="button"
          aria-label="Show Devices List"
          className="p-3 bg-white shadow-lg text-slate-400 hover:text-slate-600 rounded"
        >
          <RiListUnordered size={24} />
        </button>
      </InlinePopover>
    </div>
  );
}

function CanvasCheckbox({ canvas }: { canvas: EditorCanvas }) {
  const dispatch = useEditorDispatch();
  const canvases = useEditorSelector(selectCanvases);
  const logAnalytics = useLogAnalytics();
  const id = `toggle-${canvas}${React.useId()}`;

  const currentCanvas = Object.entries(canvases).find(
    ([key]) => key === canvas,
  )?.[1];

  if (!currentCanvas) {
    return null;
  }

  const isOnlyOneChecked =
    Object.values(canvases).filter((c) => c.isVisible).length === 1;
  const isDisabled = isOnlyOneChecked && currentCanvas?.isVisible;

  const onCheckedChange = (isChecked: boolean) => {
    if (isDisabled) {
      return;
    }
    logAnalytics("canvas.frame.toggle", {
      action: isChecked ? "show" : "hide",
      frame: canvas,
    });
    if (isChecked) {
      dispatch(
        showCanvas({
          canvas,
          width: CANVAS_DATA[canvas].defaultFrameWidth,
        }),
      );
    } else {
      dispatch(hideCanvas(canvas));
    }
  };

  return (
    <div className="flex gap-1.5 items-center">
      <Checkbox.Root
        checked={currentCanvas.isVisible}
        onCheckedChange={onCheckedChange}
        onKeyDown={(event) => {
          // TODO (Chance 2024-06-12): This should work out-of-the-box in Radix
          // but for some reason it doesn't. Will investigate further, but we'll
          // need this once we build it into the DS.
          if (event.key === " ") {
            event.preventDefault();
            onCheckedChange(!currentCanvas.isVisible);
          }
        }}
        id={id}
        className={classNames(
          "w-4 h-4 rounded-sm border grid place-content-center bg-white data-[state=checked]:text-white",
          isDisabled
            ? "border-blue-400 text-blue-400 data-[state=checked]:bg-blue-400"
            : "border-blue-600 text-blue-600 data-[state=checked]:bg-blue-600",
        )}
        aria-disabled={isDisabled || undefined}
        value="device"
        name={canvas}
      >
        <Checkbox.Indicator>
          <RiCheckFill size={12} />
        </Checkbox.Indicator>
      </Checkbox.Root>
      <label
        className="font-normal text-xs select-none cursor-pointer"
        htmlFor={id}
      >
        {capitalizeFirstLetter(canvas)}
      </label>
    </div>
  );
}
