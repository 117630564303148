import type { CustomPropsRecord } from "schemas/component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

import { convertToLegacyProps } from "../../../shared/utils/renderComponents";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _postscriptShopId: {
      type: "string",
      name: "Postscript Shop Id",
      description: "Found in Postscript dashboard in the 'Shop Settings'",
      defaultValue: null,
    },
    _postscriptKeywordId: {
      type: "string",
      name: "Postscript Keyword Id",
      description:
        "Found in Postscript dashboard in 'Acquisition' -> 'Keywords'",
      defaultValue: null,
    },
  };
}

export const styleElements = {
  root: {},
} as const satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: false,
    customProps: convertToLegacyProps(getConfigurableProps()),
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
