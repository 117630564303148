import type { ReploPriceRule, ReploShopifyVariant } from "../types";

export type PriceRuleItem = {
  quantity: number;
  variant: ReploShopifyVariant;
};

export const isPriceRuleApplicable = (
  rule: ReploPriceRule,
  item: PriceRuleItem,
  items: PriceRuleItem[],
): boolean => {
  // TODO (Noah, 2021-08-27): This is pretty bare bones and doesn't actually take
  // into account some of the prerequisites/entitlements reflected in the price rule.
  // We need to go and make sure this works with like, buy 2 get 1, free shipping,
  // etc

  if (
    rule.entitledProductIds &&
    !rule.entitledProductIds.includes(item.variant.productId)
  ) {
    return false;
  }
  if (
    rule.entitledVariantIds &&
    !rule.entitledVariantIds.includes(item.variant.variantId)
  ) {
    return false;
  }

  const entitledItems = items.filter((item) => {
    if (rule.allProductsAreEntitled) {
      return true;
    }
    const isEntitledBasedOnProduct =
      rule.entitledProductIds &&
      rule.entitledProductIds.includes(item.variant.productId);
    const isEntitledBasedOnVariant =
      rule.entitledVariantIds &&
      rule.entitledVariantIds.includes(item.variant.variantId);
    return isEntitledBasedOnProduct || isEntitledBasedOnVariant;
  });
  const entitledItemsQuantity = entitledItems.reduce(
    (previousValue, currentValue) => previousValue + currentValue.quantity,
    0,
  );
  const entitledItemsTotalPrice = entitledItems.reduce(
    (previousValue, currentValue) =>
      previousValue + Number(currentValue.variant.price),
    0,
  );

  if (
    rule.prerequisiteQuantityRange &&
    entitledItemsQuantity < rule.prerequisiteQuantityRange.greaterThanOrEqualTo
  ) {
    return false;
  }

  if (
    rule.prerequisiteSubtotalRange &&
    entitledItemsTotalPrice <
      rule.prerequisiteSubtotalRange.greaterThanOrEqualTo
  ) {
    return false;
  }

  return true;
};

export const calculateEffectivePrice = (
  rule: ReploPriceRule,
  item: PriceRuleItem,
  items: PriceRuleItem[],
): number => {
  if (isPriceRuleApplicable(rule, item, items)) {
    if (rule.valueType === "percentage") {
      const discountFactor = (100 - Math.abs(rule.value)) / 100;
      return Number(item.variant.price) * item.quantity * discountFactor;
    }
    const discountValue = Math.abs(rule.value);
    return Number(item.variant.price) * item.quantity - discountValue;
  }
  return Number(item.variant.price);
};
