import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import {
  Hovercard,
  HovercardAnchor,
  HovercardArrow,
  HovercardProvider,
  useHovercardStore,
} from "@ariakit/react";
import classNames from "classnames";
import { parseInteger } from "replo-utils/lib/math";

import {
  RuntimeHooksContext,
  useRuntimeContext,
} from "../../../shared//runtime-context";
import { mergeContext } from "../../../shared/utils/context";
import { useComponentClassNames } from "../../../shared/utils/renderComponents";
import useSharedState from "../../../store/hooks/useSharedState";
import { ReploComponent } from "../ReploComponent";

type BaseSide = "top" | "bottom" | "left" | "right";
type Alignment = "start" | "center" | "end";
type Side = BaseSide | `${BaseSide}-${Exclude<Alignment, "center">}`;

const Tooltip: React.FC<RenderComponentProps> = (props) => {
  const { component, context, componentAttributes } = props;
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const [isOpen, setIsOpen] = useSharedState([component.id, "isOpen"], false);
  const hovercard = useHovercardStore();
  const tooltipContentComponent =
    props.component.props._tooltipContentComponent!;
  const children = component.children ?? [];
  const contentClassNameMap = useComponentClassNames(
    "tooltipContent",
    tooltipContentComponent,
    context,
  );

  const nextContext = mergeContext(context, {
    state: {
      tooltip: {
        isOpen,
      },
    },
  });

  const contentConfig = {
    _showArrow: component.props._showArrow as boolean,
    _arrowSize: parseInteger(component.props._arrowSize as string),
    _side: component.props._side as BaseSide,
    _alignment: component.props._alignment as Alignment,
    _sideOffset: component.props._sideOffset as string,
    _alignOffset: component.props._alignOffset as string,
    _hoverDelayTime: component.props._hoverDelayTime as number,
  };

  const getSideProp = (): Side => {
    const side = contentConfig._side ?? "top";
    const _alignment = contentConfig._alignment ?? "center";
    if (_alignment === "center") {
      return side;
    }
    return `${side}-${_alignment}`;
  };

  // NOTE (Sebas, 2024-07-04): This is in charge of re-rendering the hovercard
  // when the arrow size changes. If not, the arrow position will be wrong.
  // biome-ignore lint/correctness/useExhaustiveDependencies: extra deps component.props._arrowSize
  React.useEffect(() => {
    if (isEditorCanvas) {
      hovercard.render();
    }
  }, [component.props._arrowSize, hovercard, isEditorCanvas]);

  return (
    <HovercardProvider
      store={hovercard}
      open={isOpen}
      timeout={contentConfig._hoverDelayTime ?? 0}
      setOpen={(value) => {
        if (!isEditorCanvas) {
          setIsOpen(value);
        }
      }}
      placement={getSideProp()}
    >
      <HovercardAnchor
        render={(props) => {
          return (
            <div {...componentAttributes} {...props}>
              {children.map((child) => (
                <ReploComponent
                  key={child.id}
                  context={nextContext}
                  component={child}
                  repeatedIndexPath={nextContext.repeatedIndexPath ?? ".0"}
                />
              ))}
            </div>
          );
        }}
      />
      <Hovercard
        gutter={parseInteger(contentConfig._sideOffset)}
        className={contentClassNameMap?.contentWrapper}
        shift={parseInteger(contentConfig._alignOffset)}
      >
        <ReploComponent
          context={nextContext}
          component={tooltipContentComponent}
          repeatedIndexPath={context.repeatedIndexPath ?? ".0"}
        />
        {contentConfig._showArrow && (
          <HovercardArrow
            size={
              !Boolean(contentConfig._arrowSize) ||
              Number.isNaN(contentConfig._arrowSize)
                ? 20
                : contentConfig._arrowSize
            }
            className={classNames("tooltip-arrow", contentClassNameMap?.arrow)}
          />
        )}
      </Hovercard>
    </HovercardProvider>
  );
};

export default Tooltip;
