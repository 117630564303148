// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import type { AppRouterQueryArgs } from "@editor/utils/trpc";
import type { ExperimentStatus } from "schemas/experiment";
import type { Experiment } from "schemas/generated/experiment";
import type { ReploProject } from "schemas/generated/project";

import * as React from "react";

import { InfoTag } from "@editor/components/projectDashboard/common/InfoTag";
import { trpc } from "@editor/utils/trpc";

import { positiveIntToCapitalLetter } from "@/features/experiments/utils";
import { skipToken } from "@tanstack/react-query";
import { v4 as uuid } from "uuid";

export function getExperimentBaseUrl(project: ReploProject) {
  return `https://${project.customDomain ?? `reploedge.com/${project.shortName ?? project.id}`}/`;
}

export const StatusTag = ({ status }: { status: ExperimentStatus }) => {
  const mapping = {
    active: ["bg-lime-200", "Active"],
    draft: ["bg-slate-200", "Draft"],
    archived: ["bg-red-200", "Archived"],
    complete: ["bg-blue-200", "Completed"],
  };
  const [color, text] = mapping[status]!;
  return <InfoTag className={color}>{text}</InfoTag>;
};

export function buildCreateExperimentArgs(
  workspaceId: string,
  projectId: string | null,
  experiments: Array<Experiment>,
  url?: string | undefined | null,
): AppRouterQueryArgs["experiment"]["create"] {
  const newExperimentId = uuid();
  const placeholderUrl = url
    ? `https://${url}/pages/example`
    : "https://example.com";
  let slug = `experiment-${positiveIntToCapitalLetter(experiments?.length ?? 0 + 1)}`;
  if (experiments?.find((v) => v.slug === slug)) {
    slug = `experiment-${uuid().slice(0, 8)}`;
  }
  return {
    id: newExperimentId,
    projectId,
    workspaceId,
    name: slug,
    description: "",
    slug,
    variations: [
      {
        id: uuid(),
        target: placeholderUrl,
        slug: "a",
        allocationPercent: 50,
      },
      {
        id: uuid(),
        target: placeholderUrl,
        slug: "b",
        allocationPercent: 50,
      },
    ],
  };
}

/**
 * Provides all experiment API interactions as a single hook, allowing us to more easily refetch
 * the list and achieve "cache-like" behavior across uses of the query while avoiding indirection.
 *
 * TODO (Max, 2024-10-23, REPL-14267): Move this to @editor/utils/experiments.ts when cleaning up (
 * and delete other functions in this file).
 *
 * @author Ben 2024-04-26
 */
export function useExperimentApi({
  workspaceId,
  projectId,
}: {
  workspaceId: string | undefined;
  projectId: string | null;
}) {
  /**
   * TODO (Max, 2024-10-10, REPL-14267): When cleaning up,
   * delete all the code that calls useExperimentApi by finding the workspaceId through the project
   * (we should use useCurrentWorkspaceId() instead of doing project?.ownerWorkspaceId).
   *
   * Also move this to @editor/utils/experiments.ts
   */
  const trpcUtils = trpc.useUtils();
  const list = trpc.experiment.list.useQuery(
    workspaceId
      ? {
          workspaceId,
          projectId,
        }
      : skipToken,
  );
  const links = trpc.analyticsLink.list.useQuery(
    workspaceId
      ? {
          workspaceId,
        }
      : skipToken,
  );
  const onSuccess = async () => {
    void trpcUtils.experiment.list.invalidate({ workspaceId, projectId });
    void trpcUtils.analyticsLink.list.invalidate({ workspaceId });
  };
  const create = trpc.experiment.create.useMutation({
    onSuccess,
  });
  const update = trpc.experiment.update.useMutation({
    onSuccess,
  });
  const remove = trpc.experiment.remove.useMutation({
    onSuccess,
  });
  const complete = trpc.experiment.complete.useMutation({
    onSuccess,
  });
  return { list, links, update, remove, create, complete };
}
