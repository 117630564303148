import * as React from "react";

import ContentLoader from "react-content-loader";
import { twMerge } from "tailwind-merge";

/**
 * @deprecated Use `Skeleton` instead.
 */
export const SkeletonLoaderWrapper = ContentLoader;
/**
 * @deprecated Use `Skeleton` instead.
 */
export const SkeletonLoaderItem: React.FC<{
  width: string;
  height: string;
  yAxis: string;
  xAxis?: string;
  ry?: string;
  rx?: string;
}> = ({ height, width, yAxis, xAxis = "0", rx = "6", ry = "6" }) => {
  return (
    <rect x={xAxis} y={yAxis} rx={rx} ry={ry} width={width} height={height} />
  );
};
/**
 * @deprecated
 */
export const SimpleSkeletonLoader: React.FC<{
  width: string;
  height: string;
}> = ({ width, height }) => (
  <SkeletonLoaderWrapper width={width} height={height}>
    <SkeletonLoaderItem width={width} height={height} yAxis="0" xAxis="0" />
  </SkeletonLoaderWrapper>
);
/**
 * Skeleton component for loading states. classNames can be used to style (size)
 * the skeleton.
 */
export const Skeleton: React.FC<{ className?: string }> = ({ className }) => {
  const classNames = twMerge("flex flex-row gap-3", className);
  return (
    <div className={classNames}>
      <SimpleSkeletonLoader width="100%" height="100%" />
    </div>
  );
};
