import * as React from "react";

import StandardCustomPropControl from "@editor/components/editor/page/element-editor/components/config-menus/StandardCustomPropControl";
import { useCustomPropDefinitionsWithValues } from "@editor/components/editor/page/element-editor/components/config-menus/utils";
import { selectDraftComponent } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import { StandardAccessibilityConfigMenu } from "./StandardAccessibilityConfigMenu";

const ACCESSIBILITY_CUSTOM_PROP_IDS = [
  "_accessibilityRole",
  "_accessibilityHidden",
  "_accessibilityChecked",
  "_accessibilityLabelledBy",
  "_accessibilityHtmlTagType",
];

export const ContainerConfigMenu: React.FC = () => {
  const customProps = useCustomPropDefinitionsWithValues();
  const draftComponent = useEditorSelector(selectDraftComponent);
  if (!draftComponent) {
    return null;
  }

  return (
    <div className="relative flex flex-col gap-4">
      {customProps.map(
        (customProp) =>
          !ACCESSIBILITY_CUSTOM_PROP_IDS.includes(customProp.definition.id) && (
            <div
              key={customProp.definition.id}
              className="border-b border-slate-200 pb-3 last:border-b-0 last:pb-0"
            >
              <StandardCustomPropControl
                draftComponent={draftComponent}
                customProp={customProp}
              />
            </div>
          ),
      )}
    </div>
  );
};

export const ContainerAccessibilityMenu: React.FC = () => {
  return (
    <StandardAccessibilityConfigMenu
      accessibilityPropIds={ACCESSIBILITY_CUSTOM_PROP_IDS}
    />
  );
};
