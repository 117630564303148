import type { UseMatchMediaOptions } from "./use-match-media";

import { useMatchMedia } from "./use-match-media";

export function usePrefersReducedMotion(
  options?: UseMatchMediaOptions | undefined,
) {
  const motionOk = useMatchMedia(
    "(prefers-reduced-motion: no-preference)",
    options,
  );
  return !motionOk;
}
