import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const kachingBundles: ComponentTemplate = {
  id: "96f0a4e7-8950-4813-9374-f3628519ba90",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Kaching Bundles Widget",
  leftBarCategory: {
    main: "productIntegrations",
    subCategory: "kachingBundles",
  },
  preview: "product/kaching-bundles.svg",
  transforms: [],
  template: {
    id: "$uid",
    type: "kachingBundles",
    props: {},
  },
};
