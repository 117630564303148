import type { HeaderProps } from "@/editor/components/dashboard/Header";

import * as React from "react";

import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import InputComponent from "@editor/components/common/designSystem/Input";

import Header from "@/editor/components/dashboard/Header";
import Button from "@replo/design-system/components/button";
import { GoPencil } from "react-icons/go";

type EditableHeaderProps = {
  title: string;
  onTitleChange: (newTitle: string) => Promise<void>;
} & HeaderProps;

const EditableHeader: React.FC<EditableHeaderProps> = ({
  title,
  onTitleChange,
  ...headerProps
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const inputProps = useOverridableInput({
    value: title,
    onValueChange: undefined,
    timeout: 0,
  });

  const handleEditClick = () => {
    setIsEditing(true);
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleBlur = () => {
    const newValue = inputProps.value;
    if (newValue !== title) {
      void onTitleChange(newValue);
    }
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const newValue = inputProps.value;
      if (newValue !== title) {
        void onTitleChange(newValue);
      }
      setIsEditing(false);
    }
    if (e.key === "Escape") {
      setIsEditing(false);
    }
  };

  if (isEditing) {
    return (
      <div className="flex w-full justify-between items-center">
        <div className="flex flex-col gap-2">
          <InputComponent
            ref={inputRef}
            size="base"
            type="text"
            {...inputProps}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            unsafe_inputClassName="text-sm font-medium"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-2">
      <Header {...headerProps} title={title} />
      <Button
        onClick={handleEditClick}
        variant="tertiary"
        icon={<GoPencil size={12} strokeWidth={1} className="text-slate-600" />}
      />
    </div>
  );
};

export default EditableHeader;
