import { Env } from "replo-runtime/shared/enums";
import { canUseDOM } from "replo-utils/dom/misc";

export const Envs = {
  ALL: [Env.PRODUCTION, Env.DEV, Env.STAGING],
};

export const isLocalhost = (): boolean => {
  if (!canUseDOM) {
    return false;
  }
  // NOTE (Chance 2024-05-31): Global window should be fine for this check
  // biome-ignore lint/style/noRestrictedGlobals: allow global
  return window.location.hostname === "localhost";
};

export const isUnitTest = () => {
  // https://stackoverflow.com/questions/50940640/how-to-determine-if-jest-is-running-the-code-or-not
  if (typeof process !== "undefined" && process.env.JEST_WORKER_ID) {
    return true;
  }
  return false;
};

export const isCIRunning = () => {
  // https://stackoverflow.com/questions/50940640/how-to-determine-if-jest-is-running-the-code-or-not
  if (typeof process !== "undefined" && process.env.CI) {
    return true;
  }
  return false;
};

export const getEnv = () => {
  const nodeEnv = process.env.NODE_ENV;

  if (nodeEnv === "production") {
    return Env.PRODUCTION;
  }

  return Env.DEV;
};
