import type { DateRange, Matcher } from "react-day-picker";

import * as React from "react";

import Button from "@replo/design-system/components/button";
import Calendar from "@replo/design-system/components/shadcn/core/calendar";
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "@replo/design-system/components/shadcn/core/popover";
import clsxMerge from "@replo/design-system/components/shadcn/utils/cn-merge";
import classNames from "classnames";
import { addDays, isEqual } from "date-fns";
import { BsCalendar3 } from "react-icons/bs";
import { dateFormatter, dateRangeFormatter } from "replo-utils/lib/datetime";
import { useControllableState } from "replo-utils/react/use-controllable-state";

// NOTE (Kurt, 2024-11-22): We use the popover align offset to vertically align the date picker
// with the trigger button.
const POPOVER_ALIGN_OFFSET = -15;
// NOTE (Kurt, 2024-11-22): We use the side offset to horizontally align the date picker
// with the trigger button. This is the horizontal distance between the date picker and
// the trigger button.
const POPOVER_SIDE_OFFSET = 20;

type DatePickerWithRangeProps = React.HTMLAttributes<HTMLDivElement> & {
  isDisabled?: boolean;
  popoverTriggerComponent?: () => React.ReactNode;
  initialDateRange?: DateRange;
  customTrigger?: React.ReactNode;
  triggerOnHover?: boolean;
  value?: DateRange;
  onChange?: (value: DateRange) => void;
  updateAction?: (dateRange: DateRange) => void;
  closeAction?: () => void;
  excludeDisabledDates?: boolean;
  disabledDates?: Matcher | Matcher[];
  rangeExtendsOnSelect?: boolean;
};

export const DateDisplay = ({
  date,
  isRange,
}: {
  date: Date | DateRange;
  isRange: boolean;
}) => {
  if (isRange) {
    return dateRangeFormatter(date as DateRange);
  }
  return dateFormatter(date as Date);
};

export function DatePickerWithRange({
  className,
  isDisabled,
  popoverTriggerComponent,
  initialDateRange,
  triggerOnHover = false,
  value: controlledValue,
  onChange: onControlledChange,
  updateAction,
  closeAction,
  excludeDisabledDates = false,
  disabledDates,
  rangeExtendsOnSelect = true,
}: DatePickerWithRangeProps) {
  const [open, setOpen] = React.useState(false);

  const [date, setDate] = useControllableState(
    controlledValue,
    initialDateRange ?? {
      from: new Date(),
      to: addDays(new Date(), 7),
    },
    onControlledChange,
  );

  const prevDateRef = React.useRef(date);

  const handleMouseEnter = () => {
    if (triggerOnHover) {
      setOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (triggerOnHover) {
      setTimeout(() => {
        setOpen(false);
      }, 100);
    }
  };

  const defaultTrigger = (
    <Button
      id="date"
      className={clsxMerge(
        classNames(
          "rounded-[4px] p-2 h-[32px] border flex items-center justify-center",
          {
            "border-slate-400 bg-slate-100 text-slate-800": !isDisabled,
            "border-gray-300 bg-gray-200 text-gray-500 cursor-not-allowed":
              isDisabled,
          },
        ),
        className,
      )}
      variant="noStyle"
    >
      <div className="flex items-center justify-center gap-[8px]">
        <BsCalendar3 className="h-4 w-4" />
        {date?.from ? (
          <DateDisplay date={date} isRange={true} />
        ) : (
          <span className="text-slate-800">Pick a date</span>
        )}
      </div>
    </Button>
  );

  const handleUpdate = () => {
    if (
      updateAction &&
      (!isEqual(date.from as Date, prevDateRef.current.from as Date) ||
        !isEqual(date.to as Date, prevDateRef.current.to as Date))
    ) {
      updateAction(date);
    }
  };

  return (
    <div className={clsxMerge("grid gap-2", className)}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {popoverTriggerComponent
              ? popoverTriggerComponent()
              : defaultTrigger}
          </div>
        </PopoverTrigger>
        <PopoverContent
          className="w-auto p-0 rounded-[12px] mb-2"
          align="start"
          side="right"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          avoidCollisions={true}
          sideOffset={POPOVER_SIDE_OFFSET}
          alignOffset={POPOVER_ALIGN_OFFSET}
        >
          <Calendar
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={
              !rangeExtendsOnSelect
                ? (nextRange, selectedDay) => {
                    if (nextRange) {
                      // NOTE (Kurt, 2024-09-10): This enables us to reset the date range whenever we
                      // select a date with a range already selected.
                      setDate((range) => {
                        if (range.from && range.to) {
                          return { from: selectedDay, to: undefined };
                        }
                        return nextRange as DateRange;
                      });
                    } else {
                      setDate({ from: selectedDay, to: selectedDay });
                    }
                  }
                : (newDate) => {
                    setDate(newDate as DateRange);
                  }
            }
            numberOfMonths={2}
            required={false}
            excludeDisabled={excludeDisabledDates}
            disabled={disabledDates}
          />
          <div className="h-[52px] w-full flex justify-between items-center py-[12px] px-[10px] border-t border-slate-200">
            <div className="flex items-center gap-[8px]">
              <div className="text-sm text-slate-600">Selected period</div>
              <div className="text-sm text-slate-800 font-semibold">
                {date?.from ? (
                  <DateDisplay date={date} isRange={true} />
                ) : (
                  <span className="text-slate-800">Pick a date</span>
                )}
              </div>
            </div>
            <div className="flex items-center justify-center gap-[8px]">
              <PopoverClose asChild>
                <Button variant="secondary" size="base" onClick={closeAction}>
                  Cancel
                </Button>
              </PopoverClose>
              <Button variant="primary" size="base" onClick={handleUpdate}>
                Update
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
