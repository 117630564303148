import type { ComponentTemplate } from "@editor/types/component-template";

import * as React from "react";

import ComponentPreview from "@components/ComponentPreview";
import ComponentTemplateDragOverlay from "@editor/components/ComponentTemplateDragOverlay";
import { isComponent } from "@editor/utils/component";

import classNames from "classnames";

type ComponentTemplateCardProps = {
  name: string;
  preview: string | null;
  isDraggable?: boolean;
  template: ComponentTemplate;
  onClick?: () => void;
  scope?: "left-bar" | "store";
};

type CardPreviewProps = Pick<
  ComponentTemplateCardProps,
  "preview" | "template"
>;

export const ComponentTemplateCard: React.FC<ComponentTemplateCardProps> = ({
  name,
  preview,
  isDraggable,
  template,
  onClick,
  scope,
}) => {
  const previewType = getPreviewType({ preview, template });
  return (
    <div className="relative aspect-[1/1] w-full cursor-pointer rounded bg-slate-50">
      <div
        className={classNames(
          "grid h-full w-full place-items-center overflow-hidden",
          {
            // NOTE (Sebas, 2024-04-08): We need to add this padding because if not,
            // the component's title on the `saved component` left bar tab will be
            // on top of the component preview.
            "pb-10": previewType === "snapshot",
          },
        )}
        onClick={onClick}
        data-testid="component-template-draggable"
      >
        <CardPreview preview={preview} template={template} />
        {isDraggable && template && (
          <ComponentTemplateDragOverlay template={template} scope={scope} />
        )}
        <div className="absolute bottom-3 text-muted flex items-center h-7 text-xs text-center">
          {name}
        </div>
      </div>
    </div>
  );
};

const CardPreview: React.FC<CardPreviewProps> = ({ preview, template }) => {
  const previewType = getPreviewType({ preview, template });
  switch (previewType) {
    case "img": {
      // NOTE (Sebas, 2023-11-28): This regex is used to extract the name of the
      // component from the preview image name. For example, if the preview is
      // "/image.svg", the name will be "image".
      const regex = /\/([^/]+)\./;
      const alt = preview!.match(regex);
      return (
        <ComponentPreview
          type="img"
          alt={alt?.[1] ?? "Component"}
          src={`/images/templates/${preview}`}
        />
      );
    }

    case "snapshot":
      return <ComponentPreview type="snapshot" componentTemplate={template} />;

    case "placeholder":
    default:
      return (
        <div className="text-3xl" aria-hidden>
          💎
        </div>
      );
  }
};

function getPreviewType(props: {
  preview: string | undefined | null;
  template: ComponentTemplate | undefined | null;
}) {
  if (typeof props.preview === "string") {
    return "img";
  }
  if (isComponent(props.template)) {
    return "snapshot";
  }
  return "placeholder";
}
