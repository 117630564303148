/**
 * NOTE (Chance 2023-09-21): Slightly more bullet-proof check for DOM APIs than
 * `typeof window !== "undefined"`, as some environments may stub window or
 * document. This is consistent with what React uses internally.
 * @see https://github.com/facebook/react/blob/main/packages/shared/ExecutionEnvironment.js
 */
export const canUseDOM = Boolean(
  typeof window !== "undefined" &&
    window.document &&
    window.document.createElement,
);
