type BuiltInEasingFunction = "smooth" | "linear";
const easingFunctions: Record<
  BuiltInEasingFunction,
  (percentage: number) => number
> = {
  smooth: (x) => (x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2),
  linear: (x) => x,
};

/**
 * Scroll the given element's parent to the given element using a custom animation
 * function.
 *
 * @param element Element to scroll to
 * @param leftOffset Offset from the left that the element should have relative
 * to its scroll parent at the end of the animation
 * @param duration Duration of the animation in milliseconds
 * @param easingFunction Animation easing function (accepts a percentage that
 * the animation is complete and returns a modified percentage). For linear,
 * pass the identity function (t => t).
 */
export function scrollParentToElementCustom(
  element: Element,
  leftOffset: number,
  duration: number,
  easingFunction: "smooth" | "linear" | ((percentage: number) => number),
): number | undefined {
  const globalWindow = element.ownerDocument.defaultView;
  const startingLeft = (element as HTMLElement).offsetParent?.scrollLeft ?? 0;
  const elementPosition = (element as HTMLElement).offsetLeft;
  const targetLeft = elementPosition - leftOffset;
  const diff = targetLeft - startingLeft;

  let start: number;
  let requestIdentifier: number | undefined;

  // https://stackoverflow.com/questions/17722497/scroll-smoothly-to-specific-element-on-page
  requestIdentifier = globalWindow?.requestAnimationFrame(
    function step(timestamp) {
      if (!start) {
        start = timestamp;
      }
      // Elapsed miliseconds since start of scrolling.
      const time = timestamp - start;
      // Get percent of completion in range [0, 1].
      let percent = Math.min(time / duration, 1);
      // Apply the easing.
      if (typeof easingFunction === "function") {
        percent = easingFunction(percent);
      } else {
        percent = easingFunctions[easingFunction](percent);
      }

      (element as HTMLElement).offsetParent?.scrollTo({
        left: startingLeft + diff * percent,
        behavior: "auto",
      });

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        requestIdentifier = globalWindow.requestAnimationFrame(step);
      }
    },
  );
  return requestIdentifier;
}
