import * as React from "react";

import { BackButton } from "@editor/components/common/BackButton";
import Separator from "@editor/components/common/designSystem/Separator";
import Header from "@editor/components/dashboard/Header";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { trpc } from "@editor/utils/trpc";

import { METRIC_DEFINITIONS_URL } from "@/features/analytics/constants";
import PageTypeSelect from "@/features/analytics/selects/PageTypeSelect";
import Tooltip from "@replo/design-system/components/tooltip";
import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import { BsBook } from "react-icons/bs";

interface AnalyticsLayoutProps {
  headerTitle?: string;
  children: React.ReactNode;
  showBackButton?: boolean;
}

const AnalyticsLayout: React.FC<AnalyticsLayoutProps> = ({
  headerTitle,
  children,
  showBackButton = false,
}) => {
  const analytics = useLogAnalytics();
  const workspaceId = useCurrentWorkspaceId();
  const { data: workspaceData, isPending: isLoadingWorkspace } =
    trpc.workspace.getById.useQuery(
      workspaceId ? { id: workspaceId } : reactQuerySkipToken,
    );
  const isLoadingRequiredData = isLoadingWorkspace;

  return (
    <div className="flex flex-col px-6 w-full relative overflow-hidden">
      <div className="flex flex-row gap-4 justify-between">
        <div className="flex flex-row gap-4">
          {showBackButton && <BackButton />}
          <Header
            title={headerTitle ?? `${workspaceData?.workspace?.name} Analytics`}
            isLoading={isLoadingRequiredData}
          />
        </div>
        <div className="w-auto flex items-center justify-between gap-2  ">
          <PageTypeSelect />
          <Tooltip
            triggerAsChild
            content="Metric Definitions"
            delay={300}
            collisionPadding={10}
          >
            <a
              href={METRIC_DEFINITIONS_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-end justify-center gap-[4px]"
              onClick={() => {
                analytics("analytics.definitions.open", {});
              }}
            >
              <div className="flex items-center justify-center bg-slate-100 hover:bg-slate-200 gap-[10px] h-8 w-8 rounded-[4px] group">
                <BsBook className="h-4 w-4 group-hover:text-default" />
              </div>
            </a>
          </Tooltip>
        </div>
      </div>
      <Separator className="my-4 col-span-12" />
      {children}
    </div>
  );
};

export default AnalyticsLayout;
