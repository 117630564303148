import * as React from "react";

import TourStepTrigger from "@components/flows/TourStepTrigger";
import { createPreviewData } from "@editor/actions/core-actions";
import { useIsDebugMode } from "@editor/components/editor/debug/useIsDebugMode";
import PreviewPageButton from "@editor/components/header/PreviewPageButton";
import { PublishPageButton } from "@editor/components/header/PublishPageButton";
import { PublishPageButtonWithCallout } from "@editor/components/header/PublishPageButtonWithCallout";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import useGetStoreNameAndUrl from "@editor/hooks/useGetStoreNameAndUrl";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import usePublishedInfo from "@editor/hooks/usePublishedInfo";
import usePublishOnClickInfo from "@editor/hooks/usePublishingInfo";
import {
  selectInternalDebugModeOn,
  setDebugPanelVisibility,
} from "@editor/reducers/core-reducer";
import { selectIsShopifyStoreClosed } from "@editor/reducers/ui-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { getStoreData } from "@editor/utils/project-utils";

import Button from "@replo/design-system/components/button";
import { AnimatePresence, motion } from "framer-motion";
import { BsBoxArrowUpRight } from "react-icons/bs";

export default function PageControlButtons() {
  const modal = useModal();
  const { draftElementType } = usePublishOnClickInfo();
  const { project } = useCurrentProjectContext();
  const store = getStoreData(project);
  const isDebugMode = useIsDebugMode();
  const isStoreClosed = useEditorSelector(selectIsShopifyStoreClosed);
  const reduxStore = useEditorStore();
  const { storeName } = useGetStoreNameAndUrl();
  const projectId = useCurrentProjectId();

  const logEvent = useLogAnalytics();
  const { shopifyUrl, urlIsDisabled, tooltipLabel } = usePublishedInfo();

  if (!draftElementType) {
    return null;
  }

  return (
    <div className="flex items-center gap-2">
      {isDebugMode && <DebugMenuButton />}
      <TourStepTrigger step="step-5">
        <Button
          onClick={() => {
            modal.openModal({
              type: "projectMembershipModal",
            });
          }}
          variant="secondary"
          size="base"
          tooltipText="Share Project"
        >
          Share
        </Button>
      </TourStepTrigger>
      <div className="w-[1px] h-[16px] bg-slate-300" />
      <PreviewPageButton
        isProjectConnectedToShopify={Boolean(store?.shopifyUrl)}
        onCreatePreviewData={() => {
          if (!projectId) {
            return;
          }
          return createPreviewData({
            state: reduxStore.getState(),
            projectId,
            storeName: storeName ?? "",
          });
        }}
        isStoreClosed={isStoreClosed}
      />
      <AnimatePresence>
        {!urlIsDisabled && (
          <motion.div
            initial={{ width: 0, opacity: 0 }}
            animate={{ width: "auto", opacity: 1 }}
            exit={{ width: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeOut" }}
          >
            <Button
              variant="secondary"
              data-testid="live-page-button"
              size="base"
              isDisabled={urlIsDisabled}
              icon={<BsBoxArrowUpRight size={18} />}
              tooltipText={tooltipLabel}
              aria-label={tooltipLabel}
              onClick={() => {
                if (shopifyUrl) {
                  logEvent("header.viewPage", {});
                  window.open(shopifyUrl, "_blank");
                }
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
      {store?.shopifyUrl ? (
        <PublishPageButton isPreviewBeforePublishView />
      ) : (
        <PublishPageButtonWithCallout />
      )}
    </div>
  );
}

function DebugMenuButton() {
  const dispatch = useEditorDispatch();
  const isDebugPanelVisible = useEditorSelector(selectInternalDebugModeOn);
  const handleDebug = React.useCallback(() => {
    dispatch(setDebugPanelVisibility(!isDebugPanelVisible));
  }, [dispatch, isDebugPanelVisible]);

  return (
    <Button
      variant="secondary"
      size="base"
      icon={<>🔮</>}
      onClick={handleDebug}
      tooltipText="Replo Debug Menu"
    />
  );
}
