import type { ComboboxProps } from "@replo/design-system/components/shadcn/combobox/types";

import * as React from "react";

import Button from "@replo/design-system/components/button";
import { OptionsList } from "@replo/design-system/components/shadcn/combobox/components/OptionsList";
import { Command } from "@replo/design-system/components/shadcn/core/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@replo/design-system/components/shadcn/core/popover";
import { useControllableState } from "replo-utils/react/use-controllable-state";
import { twMerge } from "tailwind-merge";

type TriggerSelectProps = {
  isDisabled?: boolean;
  className?: string;
  startEnhancer?: () => React.ReactNode;
  endEnhancer?: () => React.ReactNode;
  staticPlaceholder?: string;
  placeholderPrefix?: string;
  placeholder?: string;
  selectedLabel: string;
  open: boolean;
  onClick?: () => void;
} & Omit<React.ComponentPropsWithoutRef<typeof Button>, "variant">;

const TriggerSelect = React.forwardRef<HTMLButtonElement, TriggerSelectProps>(
  (
    {
      className,
      staticPlaceholder,
      placeholderPrefix,
      placeholder,
      selectedLabel,
      open,
      ...props
    },
    ref,
  ) => {
    const displayBoxLabel = () => {
      return (
        <div className="whitespace-nowrap overflow-hidden text-ellipsis">
          {staticPlaceholder ? (
            staticPlaceholder
          ) : (
            <>
              {placeholderPrefix}
              {selectedLabel ? (
                selectedLabel
              ) : (
                <span className="text-subtle">{placeholder}</span>
              )}
            </>
          )}
        </div>
      );
    };

    return (
      <Button
        ref={ref}
        variant="noStyle"
        role="combobox"
        aria-expanded={open}
        className={twMerge(
          "h-[32px] rounded-[4px] p-2 flex flex-shrink-0 justify-between items-center gap-[8px] overflow-hidden",
          props.isDisabled
            ? "bg-gray-200 text-gray-500 cursor-not-allowed"
            : " bg-slate-100 hover:bg-slate-200 text-slate-800",
          className,
        )}
        {...props}
      >
        <div className="text-xs leading-5 items-center font-normal">
          {displayBoxLabel()}
        </div>
      </Button>
    );
  },
);

TriggerSelect.displayName = "TriggerSelect";

export function Combobox({
  areOptionsSearchable,
  options,
  placeholder,
  placeholderPrefix,
  staticPlaceholder,
  startEnhancer,
  endEnhancer,
  className,
  defaultValue,
  value: controlledValue,
  onChange: onControlledChange,
  open: controlledOpen,
  onOpenChange: onControlledOpenChange,
  input: inputValue,
  onInputChange: onControlledInputChange,
  isDisabled,
  inputPlaceholder,
  isLoading,
  avoidCollisions,
}: ComboboxProps) {
  const [open, setOpen] = useControllableState(
    controlledOpen,
    false,
    onControlledOpenChange,
  );
  const [value, setValue] = useControllableState(
    controlledValue,
    defaultValue ?? "",
    onControlledChange,
  );

  const [input, setInput] = useControllableState(
    inputValue,
    "",
    onControlledInputChange,
  );

  const selectedLabel =
    options.find((option) => option.value === value)?.label || value;

  return (
    <Popover open={isDisabled ? false : open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <TriggerSelect
          isDisabled={isDisabled}
          className={className}
          startEnhancer={startEnhancer}
          endEnhancer={endEnhancer}
          staticPlaceholder={staticPlaceholder}
          placeholderPrefix={placeholderPrefix}
          placeholder={placeholder}
          selectedLabel={selectedLabel}
          open={open}
        />
      </PopoverTrigger>
      <PopoverContent
        className="w-full p-0 max-w-[550px]"
        style={{ minWidth: "var(--radix-popper-anchor-width)" }}
        avoidCollisions={avoidCollisions}
      >
        <Command>
          <OptionsList
            options={options}
            setValue={setValue}
            setOpen={setOpen}
            value={value}
            input={input}
            inputPlaceholder={inputPlaceholder}
            setInput={setInput}
            areOptionsSearchable={areOptionsSearchable}
            isLoading={isLoading}
          />
        </Command>
      </PopoverContent>
    </Popover>
  );
}
