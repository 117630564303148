import type { ComponentTemplate } from "@editor/types/component-template";

import * as React from "react";

import { Group } from "@common/designSystem/Group";
import { Menu } from "@common/designSystem/Menu";
import { ComponentTemplateCard } from "@components/ComponentTemplateCard";
import { getTemplateName } from "@components/editor/componentTemplates";
import { useModal } from "@editor/hooks/useModal";
import { selectDeletedSavedComponentTemplatesIds } from "@editor/reducers/component-templates-reducer";
import { useEditorSelector } from "@editor/store";

const ComponentTemplatesListPane: React.FC<{
  category: {
    componentTemplates: ComponentTemplate[] | undefined;
    title: string;
  };
  scope: "left-bar" | "store";
}> = ({ category, scope }) => {
  const savedComponentTemplateIds = useEditorSelector(
    selectDeletedSavedComponentTemplatesIds,
  );

  if (
    !category.componentTemplates ||
    category.componentTemplates?.length === 0
  ) {
    return null;
  }

  return (
    <Group
      key={category.title}
      name={category.title}
      isCollapsible
      isDefaultOpen
    >
      <ul className="grid grid-cols-2 gap-2">
        {category.componentTemplates.map((componentTemplate) => {
          if (savedComponentTemplateIds.includes(componentTemplate.id)) {
            return null;
          }
          return (
            <ComponentTemplateListPaneItem
              key={componentTemplate.id}
              scope={scope}
              componentTemplate={componentTemplate}
            />
          );
        })}
      </ul>
    </Group>
  );
};

export const ComponentTemplateListPaneItem: React.FC<{
  componentTemplate: ComponentTemplate;
  scope: "left-bar" | "store";
}> = ({ scope, componentTemplate }) => {
  const modal = useModal();
  return (
    <li key={componentTemplate.id}>
      <Menu
        key={componentTemplate.id}
        menuType="context"
        items={
          scope === "store"
            ? [
                {
                  type: "leaf",
                  id: "delete-saved-component",
                  title: `Delete Saved Component`,
                  onSelect: () => {
                    modal.openModal({
                      type: "deleteComponentTemplateConfirmationModal",
                      props: {
                        template: componentTemplate,
                      },
                    });
                  },
                },
              ]
            : []
        }
        trigger={
          <ComponentTemplateCard
            isDraggable
            template={componentTemplate}
            preview={
              componentTemplate.scope === "store"
                ? null
                : componentTemplate.preview ?? null
            }
            name={getTemplateName(componentTemplate) as string}
            scope={scope}
          />
        }
      />
    </li>
  );
};

export default ComponentTemplatesListPane;
