type ReferrerKeyCookieFormat = {
  referrerKey: string;
  createdAt: Date;
};

export const REFERRER_KEY_MAX_ALLOWED_DAYS_IN_MS = 60 * 60 * 24 * 60 * 1000;
export const REFERRER_KEY_COOKIE = "replo_referrerKey";

export const getReferrerKeyFromURL = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get("referral");
};

export const isValidCookie = (
  cookie: string | ReferrerKeyCookieFormat | null,
) => {
  if (!cookie) {
    return false;
  }

  if (typeof cookie === "string") {
    return true;
  }

  const now = new Date();
  const createdAtCookie = new Date(cookie.createdAt);
  const timeSinceCookieCreation = now.getTime() - createdAtCookie.getTime();
  return timeSinceCookieCreation < REFERRER_KEY_MAX_ALLOWED_DAYS_IN_MS;
};
