import type { Component } from "schemas/component";

import { findComponentInComponent } from "replo-runtime/shared/utils/component";
import {
  componentHasRedirectAction,
  hasAnchorTagInTextProp,
} from "replo-runtime/store/utils/component";

export function hasAnchorTagInComponentTree(component: Component) {
  return Boolean(
    findComponentInComponent(component, (component) => {
      if (component.type === "text" && hasAnchorTagInTextProp(component)) {
        return true;
      }
      if (component.type === "button") {
        return false;
      }
      if (componentHasRedirectAction(component)) {
        return true;
      }
      return false;
    }),
  );
}

export function hasAnchorTag(component: Component) {
  return (
    hasAnchorTagInTextProp(component) || hasAnchorTagInComponentTree(component)
  );
}
