import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { useProductFromProps } from "../../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedLiquidReviewIntegrations } from "../SharedLiquidReviewIntegrations";

const FERA_PRODUCT_REVIEWS_WIDGET_LIQUID_SOURCE =
  '<div data-fera-container="product_reviews" data-product-id="{{ product.id }}"></div>';

function FeraProductReviewsWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const product = useProductFromProps(component.props, context);
  const liquidSourceWithProductAssignment = wrapProductAssignmentToLiquidSource(
    {
      product,
      liquidSource: FERA_PRODUCT_REVIEWS_WIDGET_LIQUID_SOURCE,
      context,
    },
  );
  return (
    <SharedLiquidReviewIntegrations
      attributes={componentAttributes}
      component={component}
      liquidSource={liquidSourceWithProductAssignment}
      placeholder="Fera Product Reviews Widget will appear here"
      shouldHavePlaceHolder={!product}
      appName="Fera.ai"
      context={context}
    />
  );
}

export default FeraProductReviewsWidget;
