import { trpc } from "@editor/utils/trpc";

export const useIsWorkspaceOwner = (workspaceId?: string | null) => {
  const { data } = trpc.workspace.getUserWorkspacesList.useQuery();

  if (!workspaceId) {
    return false;
  }

  const userWorkspaceRole = data?.workspaces.find(
    (workspace) => workspace.id === workspaceId,
  )?.workspaceMemberships[0]?.role;

  return userWorkspaceRole === "owner";
};
