import type { FieldArrayWithId, UseFormReturn } from "react-hook-form";
import type { DesignSystem } from "schemas/designLibrary";

import * as React from "react";

import InlineAssetSelector from "@common/designSystem/InlineAssetSelector";
import InputComponent from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import { ProductSelector } from "@common/designSystem/ProductSelector";
import { successToast } from "@common/designSystem/Toast";
import { Loader } from "@components/marketplace/Loader";
import { ConnectShopifyCallout } from "@editor/components/editor/page/ConnectShopifyCallout";
import { BetaTag } from "@editor/components/projectDashboard/common/BetaTag";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { analytics } from "@editor/infra/analytics";
import { selectIsShopifyIntegrationEnabled } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { docs } from "@editor/utils/docs";
import { trpc } from "@editor/utils/trpc";
import { ColorSelector } from "@editorModifiers/ColorModifier";
import { LengthInputSelector } from "@editorModifiers/LengthInputModifier";

import Button from "@replo/design-system/components/button";
import IconButton from "@replo/design-system/components/button/IconButton";
import { skipToken } from "@tanstack/react-query";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { BsPlus, BsTrash } from "react-icons/bs";
import { parseInteger } from "replo-utils/lib/math";
import { isEmpty } from "replo-utils/lib/misc";
import { isValidHttpUrl } from "replo-utils/lib/url";

type DesignSystemForm = {
  id: string;
  projectId: string;
  brandLogoUrl: string | null;
  brandName: string;
  primaryButtonBorderRadius: string;
  primaryButtonBackgroundColor: string | null;
  primaryButtonTextColor: string | null;
  backgroundImageUrls: { url: string }[];
  placeholderImageUrls: { url: string }[];
  previewProduct: {
    title: string;
    productId: number;
    variantId: number;
  } | null;
};

type UrlInputProps = {
  fields: FieldArrayWithId<
    DesignSystemForm,
    "placeholderImageUrls" | "backgroundImageUrls",
    "id"
  >[];
  control: UseFormReturn<DesignSystemForm>["control"];
  errors: any;
  remove: (index: number) => void;
  name: "backgroundImageUrls" | "placeholderImageUrls";
};

type ErrorMessageProps = {
  message: string | undefined;
};

const DesignSystemTab: React.FC = () => {
  const projectId = useCurrentProjectId();
  const { isLoading, data: designSystems } = trpc.designLibrary.list.useQuery(
    projectId ? { projectId } : skipToken,
  );
  return (
    <div className="h-full">
      <div className="flex flex-col gap-5 max-w-96">
        <div className="font-semibold flex gap-3 items-center text-sm">
          Template Defaults
          <BetaTag />
        </div>
        <div className="flex flex-col gap-5 py-2">
          {!isLoading && projectId && (
            <InnerDesignSystemTab
              projectId={projectId}
              designSystems={designSystems as any as DesignSystem[]}
            />
          )}
        </div>
      </div>
      {(isLoading || !projectId) && (
        <div className="h-full flex items-center justify-center">
          <Loader label="Loading..." />
        </div>
      )}
    </div>
  );
};

const InnerDesignSystemTab: React.FC<{
  projectId: string;
  designSystems: DesignSystem[];
}> = ({ projectId, designSystems }) => {
  const designSystem = designSystems[0];
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );

  function renderInteriorComponent() {
    if (!isShopifyIntegrationEnabled) {
      return <ConnectShopifyCallout type="templateDefault" />;
    }
    if (designSystem) {
      return (
        <DesignSystemFormComponent
          projectId={projectId}
          designSystem={designSystem as DesignSystem}
        />
      );
    }
    return <NoDesignSystemYet projectId={projectId} />;
  }

  return (
    <>
      <p className="max-w-md text-sm text-slate-400">
        This is a beta version of Replo’s template defaults feature. Template
        defaults are pulled directly from your Shopify site and can be used when
        creating a page or section from a Replo Template. Learn more about the
        feature in{" "}
        <a
          className="hover:underline text-blue-600"
          href={docs.templateDefaults}
        >
          Replo’s documentation
        </a>{" "}
        and share any feedback in our{" "}
        <a
          className="hover:underline text-blue-600"
          href="https://replocommunity.slack.com/archives/C061SJS6PFG"
        >
          Community Slack.
        </a>
      </p>
      {renderInteriorComponent()}
    </>
  );
};

const NoDesignSystemYet: React.FC<{ projectId: string }> = ({ projectId }) => {
  const trpcUtils = trpc.useUtils();
  const {
    mutateAsync: createFromShopify,
    isPending: isCreateFromShopifyLoading,
  } = trpc.designLibrary.createFromShopify.useMutation({
    onSuccess: async () => {
      void trpcUtils.designLibrary.list.invalidate({ projectId });
    },
  });
  const onCreateFromShopify = () => {
    void createFromShopify({ projectId });
    analytics.logEvent("store.designSystem.created", {});
  };
  return (
    <Button
      variant="primary"
      size="base"
      onClick={onCreateFromShopify}
      isLoading={isCreateFromShopifyLoading}
    >
      Get from Shopify
    </Button>
  );
};
const DesignSystemFormComponent: React.FC<{
  designSystem: DesignSystem;
  projectId: string;
}> = ({ designSystem, projectId }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<DesignSystemForm>({});
  const {
    fields: placeholderImageUrlsFields,
    append: appendPlaceholderImageUrl,
    remove: removePlaceholderImageUrl,
  } = useFieldArray({
    control,
    name: "placeholderImageUrls",
  });
  const {
    fields: backgroundImageUrlsFields,
    append: appendBackgroundImageUrl,
    remove: removeBackgroundImageUrl,
  } = useFieldArray({
    control,
    name: "backgroundImageUrls",
  });
  const trpcUtils = trpc.useUtils();
  const {
    mutateAsync: updateDesignSystemFromURL,
    isPending: isUpdateDesignSystemURLMutationLoading,
  } = trpc.designLibrary.updateFromUrl.useMutation({
    onSuccess: async () => {
      void trpcUtils.designLibrary.list.invalidate({ projectId });
    },
  });
  const {
    mutateAsync: updateDesignSystem,
    isPending: isUpdateDesignSystemMutationLoading,
  } = trpc.designLibrary.update.useMutation({
    onSuccess: async () => {
      void trpcUtils.designLibrary.list.invalidate({ projectId });
    },
  });
  const onUpdateFromUrl = async () => {
    void updateDesignSystemFromURL({
      id: designSystem.id,
      projectId,
    });
    analytics.logEvent("store.designSystem.updated", { from: "shopify" });
    successToast("Template Defaults Updated", "");
  };
  const onSubmit = async ({
    id,
    projectId,
    backgroundImageUrls,
    brandLogoUrl,
    brandName,
    placeholderImageUrls,
    primaryButtonBackgroundColor,
    primaryButtonBorderRadius,
    primaryButtonTextColor,
    previewProduct,
  }: DesignSystemForm) => {
    void updateDesignSystem({
      id,
      projectId,
      brandLogoUrl: isEmpty(brandLogoUrl) ? null : brandLogoUrl,
      brandName,
      backgroundImageUrls: backgroundImageUrls.map((url) => url.url),
      placeholderImageUrls: placeholderImageUrls.map((url) => url.url),
      primaryButtonAttributes: {
        style: {
          backgroundColor: primaryButtonBackgroundColor,
          borderRadius: primaryButtonBorderRadius,
          color: primaryButtonTextColor!,
        },
      },
      previewProduct: {
        id: String(previewProduct?.productId) ?? null,
        title: previewProduct?.title ?? null,
        variantId: previewProduct?.variantId ?? null,
      },
    });
    analytics.logEvent("store.designSystem.updated", { from: "replo" });
    successToast("Template Defaults Updated", "");
  };
  // Note (Sebas, 2023-09-13): This is in charge of populating the form with
  // the data from the API.
  React.useEffect(() => {
    const buttonStyles = designSystem.primaryButtonAttributes?.style;
    reset({
      id: designSystem.id,
      projectId,
      brandLogoUrl: designSystem.brandLogoUrl ?? "",
      placeholderImageUrls:
        designSystem?.placeholderImageUrls?.map((url) => {
          return { url };
        }) ?? [],
      backgroundImageUrls:
        designSystem?.backgroundImageUrls?.map((url) => {
          return { url };
        }) ?? [],
      brandName: designSystem.brandName ?? "",
      primaryButtonBorderRadius: buttonStyles?.borderRadius ?? "",
      primaryButtonBackgroundColor: buttonStyles?.backgroundColor ?? "",
      primaryButtonTextColor: buttonStyles?.color ?? "",
      previewProduct: {
        productId:
          Number.parseInt(designSystem.previewProduct?.id ?? "0") ?? undefined,
        variantId: designSystem.previewProduct?.variantId ?? undefined,
      },
    });
  }, [designSystem, projectId, reset]);
  const requiredRule = { required: "This field is required" };

  return (
    <form
      onSubmit={(data) => {
        void handleSubmit(onSubmit)(data);
      }}
      className="flex flex-col gap-6 w-96 pb-20"
    >
      <div className="flex flex-col gap-2">
        <div className="text-sm font-semibold">Branding</div>
        <LabeledControl label="Name">
          <Controller
            render={({ field }) => (
              <InputComponent {...field} autoComplete="off" />
            )}
            control={control}
            name="brandName"
            rules={requiredRule}
          />
          <ErrorMessage message={errors["brandName"]?.message} />
        </LabeledControl>
        <LabeledControl label="Logo URL">
          <Controller
            render={({ field: { onChange, name, value } }) => (
              <InlineAssetSelector
                size="sm"
                inputPlaceholder="http://example.com/image.png"
                onInputChange={onChange}
                inputName={name}
                asset={{ type: "image", src: value ?? "" }}
                onRemoveAsset={() => onChange(null)}
              />
            )}
            control={control}
            name="brandLogoUrl"
            rules={{
              validate: {
                isURL: (value) => {
                  if (
                    value &&
                    !isValidHttpUrl(value) &&
                    !isValidSvgBase64(value)
                  ) {
                    return "The URL is not valid";
                  }
                  return true;
                },
              },
            }}
          />
          <ErrorMessage message={errors["brandLogoUrl"]?.message} />
        </LabeledControl>
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-sm font-semibold">Primary Button</div>
        <LabeledControl label="Border Radius">
          <Controller
            render={({ field }) => (
              <LengthInputSelector
                {...field}
                field="primaryButtonBorderRadius"
                className="w-1/2"
              />
            )}
            control={control}
            name="primaryButtonBorderRadius"
          />
        </LabeledControl>
        <LabeledControl label="Background Color">
          <Controller
            render={({ field }) => (
              <ColorSelector allowsGradientSelection={false} {...field} />
            )}
            control={control}
            name="primaryButtonBackgroundColor"
          />
        </LabeledControl>
        <LabeledControl label="Text Color">
          <Controller
            render={({ field }) => (
              <ColorSelector allowsGradientSelection={false} {...field} />
            )}
            control={control}
            name="primaryButtonTextColor"
          />
        </LabeledControl>
      </div>
      <div className="flex flex-col gap-2">
        <div className="w-full justify-between flex items-center">
          <div className="text-sm font-semibold">Placeholder Image URLs</div>
          <IconButton
            icon={<BsPlus />}
            tooltipText="Add Placeholder Image URL"
            variant="tertiary"
            onClick={() => appendPlaceholderImageUrl({ url: "" })}
          />
        </div>
        <URLInputs
          fields={placeholderImageUrlsFields}
          control={control}
          errors={errors}
          remove={removePlaceholderImageUrl}
          name="placeholderImageUrls"
        />
      </div>
      <div className="flex flex-col gap-2">
        <div className="w-full justify-between flex items-center">
          <div className="text-sm font-semibold">Background Image URLs</div>
          <BsPlus
            onClick={() => appendBackgroundImageUrl({ url: "" })}
            className="cursor-pointer"
          />
        </div>
        <URLInputs
          fields={backgroundImageUrlsFields}
          control={control}
          errors={errors}
          remove={removeBackgroundImageUrl}
          name="backgroundImageUrls"
        />
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-sm font-semibold">Preview Product</div>
        <Controller
          render={({ field: { value, onChange } }) => (
            <ProductSelector
              onChange={(value) =>
                onChange({
                  title: value?.title ?? "",
                  productId: parseInteger(
                    value?.productId ? value?.productId : 0,
                  ),
                  variantId: parseInteger(
                    value?.variantId ? value?.variantId : 0,
                  ),
                })
              }
              isMultiProducts={false}
              allowDynamicData={false}
              selectedProductRef={value}
            />
          )}
          control={control}
          name="previewProduct"
        />
      </div>

      <div className="flex gap-4">
        <Button
          variant="primary"
          size="base"
          type="submit"
          isLoading={isUpdateDesignSystemMutationLoading}
        >
          Update
        </Button>
        <Button
          variant="secondary"
          size="base"
          isLoading={isUpdateDesignSystemURLMutationLoading}
          onClick={() => void onUpdateFromUrl()}
        >
          Update from Shopify
        </Button>
      </div>
    </form>
  );
};

const URLInputs = ({ fields, control, errors, remove, name }: UrlInputProps) =>
  fields.map((field, index) => (
    <div key={field.id}>
      <Controller
        render={({ field: { onChange, value, name } }) => (
          <div className="flex items-center gap-2">
            <InlineAssetSelector
              size="sm"
              inputPlaceholder="http://example.com/image.png"
              onInputChange={onChange}
              inputName={name}
              asset={{ type: "image", src: value }}
              onRemoveAsset={() => onChange("")}
              wrapperClassnames="flex-1"
            />
            <BsTrash
              size={12}
              className="text-slate-400 cursor-pointer"
              onClick={() => remove(index)}
            />
          </div>
        )}
        name={`${name}.${index}.url`}
        control={control}
        rules={{
          required: "This field is required",
          validate: {
            isURL: (value) => {
              if (value && !isValidHttpUrl(value) && !isValidSvgBase64(value)) {
                return "The URL is not valid";
              }
              return true;
            },
          },
        }}
      />
      <ErrorMessage message={errors[name]?.[index]?.url?.message} />
    </div>
  ));

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return message ? <div className="text-red-500 text-xs">{message}</div> : null;
};

const isValidSvgBase64 = (value: string) => {
  const svgDataRegexRule = new RegExp(
    "^data:image/svg\\+xml;base64,([a-zA-Z0-9+/]{4})*([a-zA-Z0-9+/]{2}==|[a-zA-Z0-9+/]{3}=)?$",
  );
  return svgDataRegexRule.test(value);
};

export default DesignSystemTab;
