import type { CustomPropsRecord } from "schemas/component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

import { convertToLegacyProps } from "../../../shared/utils/renderComponents";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _hideDefaultArrowDisplay: {
      name: "Hide Default Arrow Display",
      type: "boolean",
      defaultValue: false,
      description:
        "If set, the default arrow which indicates the component is a dropdown will be hidden.",
    },
    _defaultText: {
      name: "Default Text",
      type: "string",
      defaultValue: null,
      description:
        "If set, this text will be displayed when the user has never selected a variant.",
    },
    _productVariants: {
      name: "Variants",
      type: "productVariants",
      defaultValue: null,
      description: "Select which variants will be shown in this list",
    },
    _swatches: {
      type: "swatches",
      name: "Swatches",
      description: "",
      defaultValue: null,
    },
  };
}

export const styleElements = {
  root: {
    defaultStyles: {
      cursor: "pointer",
      borderTopWidth: 0,
      borderBottomWidth: 0,
      borderRightWidth: 0,
      borderLeftWidth: 0,
    },
    overrideStyles: ({ component }) => {
      const hideDefaultArrowDisplay = component.props._hideDefaultArrowDisplay;
      return {
        appearance: hideDefaultArrowDisplay ? "none" : "auto",
      };
    },
  },
} as const satisfies StyleElements;

const config = {
  renderData: {
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: true,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: true,
    canvasIndicatorDragDirections: [],
    ancestorAllow: {
      ancestorTypes: ["product"],
      message:
        "Variant Dropdown components may only be placed inside a Product box.",
    },
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
