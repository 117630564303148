import { getAbsoluteOffsetY } from "replo-utils/dom/measure";

import { fullPageQuerySelector } from "../../store/utils/cssSelectors";
import { ElementRootAttribute } from "../constants";

export function selectRuntimeElementRootNode(
  document: Document,
  elementId: string | null,
): HTMLElement | null {
  return document.querySelector(`[${ElementRootAttribute}="${elementId}"]`);
}

export function getFullPageOffset(targetDocument: Document) {
  if (!targetDocument) {
    return 0;
  }
  return getAbsoluteOffsetY(
    targetDocument.querySelector(fullPageQuerySelector) ?? null,
  );
}
