import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

import { transformReviewsTemplateToUseProductForContextIfNecessary } from "../defaultComponentTemplates";

export const feraStoreReviewsWidget: ComponentTemplate = {
  id: "ebdbf4e5-ca60-4906-a2b8-a5b108de4b58",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Fera Store Reviews",
  leftBarCategory: { main: "productIntegrations", subCategory: "fera" },
  tags: ["product", "widget"],
  preview: "integrations/fera-store-reviews.svg",
  transforms: [transformReviewsTemplateToUseProductForContextIfNecessary],
  template: {
    id: "$uid",
    type: "feraStoreReviewsWidget",
    props: { style: { alignSelf: "stretch" } },
  },
};
