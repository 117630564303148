export const replaceNewlineWithBr = (value: string) => {
  return value.replace(/\r\n|\r|\n/g, "<br>");
};

// Note (Evan, 2024-01-17): This is a translation of the get_valid_filename
// util in Django -- it removes all whitespace (replacing with underscores) and
// any characters that aren't an alphanumeric, underscore, dash, or period.
export const getValidFilename = (value: string) => {
  value = value.trim().replace(/\s+/g, "_");
  return value.replace(/[^\w.-]/g, "");
};

// https://gist.github.com/mathewbyrne/1280286
export function slugify(text: string) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

// Note (Evan, 2024-02-13): This checks whether a metafield value (from Shopify)
// contains HTML. To quote Matt, "What is especially tricky here is that for
// multi_line_text_field metafields, a newline in liquid will be \n where in the
// API response it will be <br>. Because of this, we need to make sure we replace the <br>
// in the API value before determining if it contains HTML.
export function metafieldContainsHtml(metafieldValue: string) {
  return Boolean(
    metafieldValue.replace(/<br>/g, "\n").match(/<("[^"]*"|'[^']*'|[^"'>])*>/g),
  );
}

export function capitalizeFirstLetter(inputString: string): string {
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}
