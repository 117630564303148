import type { LineChartData } from "@replo/design-system/components/shadcn/LineChart";
import type {
  AnalyticsReadQuery,
  MetricName,
  RangeResult,
} from "schemas/generated/analyticsRead";

// NOTE (Kurt, 2024-09-19): This function is used to get the line chart data from the analytics read query.
// We take the data from the analytics read query and format it into the line chart data format that is then
// consumed by the LineChart component which ultimately passes to the shadcn chart component.
export const getLineChartDataFromAnalyticsRead = (
  pageData: RangeResult,
  compareAtData: RangeResult,
  query: AnalyticsReadQuery,
  metricName: MetricName,
) => {
  const data: LineChartData = [];
  const dataLength = pageData.metrics[metricName]?.length ?? 0;

  const formatDate = (date: Date) => {
    return date.toISOString();
  };

  for (let i = 0; i < dataLength; i++) {
    const targetDate = new Date(
      query.ranges.mainRange?.startDatetime +
        i * (query.ranges.mainRange?.interval ?? 0),
    );

    const formattedTargetDate = formatDate(targetDate);

    data.push({
      x: formattedTargetDate,
      [`thisPeriod`]: pageData.metrics[metricName]?.[i] ?? 0,
      [`previousPeriod`]: compareAtData.metrics[metricName]?.[i] ?? 0,
    });
  }

  return data;
};
