import * as React from "react";

import { errorToast } from "@editor/components/common/designSystem/Toast";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { trpc } from "@editor/utils/trpc";

export function useUserWorkspaceData() {
  const { user } = useCurrentUser();
  const workspaceId = useCurrentWorkspaceId();
  const { data, isSuccess, isLoading, isFetching } =
    trpc.workspace.getUserWorkspacesList.useQuery();
  const userWorkspaces = data?.workspaces ?? [];
  const workspace = userWorkspaces.find(({ id }) => id === workspaceId);
  const isMemberOfCurrentWorkspace = Boolean(workspace);

  React.useEffect(() => {
    if (
      !isFetching &&
      !isMemberOfCurrentWorkspace &&
      isSuccess &&
      !user?.isSuperuser
    ) {
      errorToast(
        "User cannot access Workspace",
        "Please try again or reach out to support@replo.app for help.",
      );
    }
  }, [isMemberOfCurrentWorkspace, isSuccess, isFetching, user]);

  return {
    userWorkspaces,
    currentWorkspace: workspace,
    isLoadingUserWorkspaces: isLoading,
    hasFinishedLoadingUserWorkspaces: isSuccess,
    isMemberOfCurrentWorkspace: Boolean(workspace),
  };
}
