import * as React from "react";

import Tooltip from "@replo/design-system/components/tooltip";
import { BsInfoCircleFill } from "react-icons/bs";

type DocumentationType =
  | "background"
  | "border"
  | "spacing"
  | "positioning"
  | "visibility";

type DocumentationInfoIconProps =
  | {
      documentationType: DocumentationType;
      href?: never;
    }
  | {
      documentationType?: never;
      href: string;
    };

const buildDocumentationLink = (documentationType: DocumentationType) => {
  return `https://docs.replo.app/docs/editing/${documentationType}`;
};

const DocumentationInfoIcon = ({
  documentationType,
  href,
}: DocumentationInfoIconProps) => {
  const hrefLink = documentationType
    ? buildDocumentationLink(documentationType)
    : href;
  return (
    <Tooltip content="Learn More" triggerAsChild>
      <a
        href={hrefLink}
        target="_blank"
        rel="noreferrer"
        className="text-xs text-slate-400 pr-1.5"
      >
        <span className="sr-only">Learn More</span>
        <BsInfoCircleFill aria-hidden />
      </a>
    </Tooltip>
  );
};

export default DocumentationInfoIcon;
