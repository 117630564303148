export function isValidHttpUrl(url: string) {
  const validUrl = normalizeUrlIncludingProtocolRelative(url);
  if (!validUrl) {
    return false;
  }
  return validUrl.protocol === "http:" || validUrl.protocol === "https:";
}

/**
 * Tries to parse and validate the given string as a URL, including protocol-relative
 * URLs. Returns a URL if parsing is possible, otherwise null.
 *
 * Note: Javascript URLs don't recognize protocol-relative URLs (e.g. //google.com)
 * as valid, so we need special casing here. If protocol-relative, the returned
 * url will have an https protocol. This default is arbitrary but backed up by best
 * practice: https://webhint.io/docs/user-guide/hints/hint-no-protocol-relative-urls/
 */
export function normalizeUrlIncludingProtocolRelative(url: string): URL | null {
  let validUrl;
  try {
    validUrl = new URL(url);
  } catch {
    if (url.startsWith("//")) {
      try {
        validUrl = new URL(`https:${url}`);
      } catch {
        return null;
      }
    } else {
      return null;
    }
  }
  return validUrl;
}

/**
 * Is the value a valid domain including subdomain + TLDs, not accounting for
 * real, recognized TLDs. Ie. we only make sure that the TLD contains at least
 * two alphanumeric characters. Additionally, this is case insensitive.
 */
export function isValidDomain(domain: string): boolean {
  const domainRegex =
    /^(?:[\dA-Za-z](?:[\dA-Za-z-]*[\dA-Za-z])?\.)+[A-Za-z]{2,}$/;
  return domainRegex.test(domain);
}

export function normalizeUrlScheme(url: string) {
  return url.startsWith("//") ? `https:${url}` : url;
}
