import type { SavedTextStyle } from "schemas/generated/designLibrary";
import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";

import * as React from "react";

import SavedStyleItemEndEnhancer from "@editor/components/designLibrary/SavedStyleItemEndEnhancer";
import SavedStyleRow from "@editor/components/designLibrary/SavedStyleRow";
import { SavedStyleSkeleton } from "@editor/components/designLibrary/SavedStyleSkeleton";
import TextSavedStyleModifier from "@editor/components/designLibrary/TextSavedStyleModifier";
import useDeleteDesignLibrarySavedStyle from "@editor/hooks/designLibrary/useDeleteDesignLibrarySavedStyle";
import useGetDesignLibrarySavedStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import {
  generateNewName,
  generateTextSavedStylePreviewValue,
} from "@editor/utils/designLibrary";
import { trpc } from "@editor/utils/trpc";
import ModifierGroup from "@editorExtras/ModifierGroup";

import { v4 as uuidv4 } from "uuid";

import { TEXT_MENU_ITEMS, TEXT_STYLE_DEFAULTS } from "../constants/textStyles";

type TextStyleGroupProps = {
  overrideSavedStyles?: SavedTextStyle[];
  isLoading?: boolean;
};

export const TextStyleGroup: React.FC<TextStyleGroupProps> = ({
  overrideSavedStyles,
  isLoading = false,
}) => {
  const [isTextModifierPopoverOpen, setIsTextModifierPopoverOpen] =
    React.useState(false);
  const [editingSavedStyleId, setEditingSavedStyleId] = React.useState<
    string | null
  >(null);

  const { textSavedStyles } = useGetDesignLibrarySavedStyles();

  const isEditable = !Boolean(overrideSavedStyles);
  const projectId = useCurrentProjectId();
  const utils = trpc.useUtils();
  const { mutateAsync: createStyleMutation } =
    trpc.designLibrary.savedStyles.create.useMutation({
      onSuccess: () => {
        void utils.designLibrary.get.invalidate();
      },
    });

  const handleItemClick = async (id: string, name: string) => {
    if (!projectId || !isEditable) {
      return null;
    }

    const newName = generateNewName(name, {
      savedStyles: textSavedStyles,
      type: "text",
    });
    const savedStyleToCreate = {
      id: uuidv4(),
      name: newName,
      attributes: TEXT_STYLE_DEFAULTS[id] ?? { type: "text", htmlTag: "p" },
    };

    await createStyleMutation({
      projectId,
      type: "text",
      ...savedStyleToCreate,
    });
    setIsTextModifierPopoverOpen(true);
    setEditingSavedStyleId(savedStyleToCreate.id);
  };

  const savedStyles = overrideSavedStyles ?? textSavedStyles;

  const menuItems = TEXT_MENU_ITEMS.map(({ id, title }) => ({
    id,
    title,
    onSelect: () => void handleItemClick(id, title),
    type: "leaf" as const,
    isDisabled: false,
  }));

  return (
    <div>
      {isEditable && (
        <div>
          <TextSavedStyleModifier
            isPopoverOpen={isTextModifierPopoverOpen}
            onPopoverOpenChange={(isOpen) => {
              if (!isOpen) {
                setEditingSavedStyleId(null);
              }
              setIsTextModifierPopoverOpen(isOpen);
            }}
            editingSavedStyle={
              textSavedStyles.find(
                (savedStyle) => savedStyle.id === editingSavedStyleId,
              ) ?? null
            }
            sideOffset={13}
            openedFrom="leftBar"
          />
        </div>
      )}
      <ModifierGroup
        title="Text Styles"
        tooltipText="Add new text style"
        isDefaultOpen={savedStyles.length > 0 || isLoading}
        isCollapsible={savedStyles.length > 0}
        menuItems={isEditable ? menuItems : undefined}
      >
        <div className="flex flex-col gap-1">
          {isLoading ? (
            <SavedStyleSkeleton />
          ) : (
            savedStyles.map((savedStyle, index) => (
              <SavedStylePaneRow
                key={index}
                savedStyle={savedStyle}
                editingSavedStyleId={editingSavedStyleId}
                setEditingSavedStyleId={(id) => {
                  setEditingSavedStyleId(id);
                  setIsTextModifierPopoverOpen(true);
                }}
              />
            ))
          )}
        </div>
      </ModifierGroup>
    </div>
  );
};

const SavedStylePaneRow: React.FC<{
  savedStyle: SavedTextStyle & { id?: string };
  editingSavedStyleId: string | null;
  setEditingSavedStyleId: (id: string | null) => void;
}> = ({ savedStyle, editingSavedStyleId, setEditingSavedStyleId }) => {
  const { deleteSavedStyle, isLoading } = useDeleteDesignLibrarySavedStyle();

  return (
    <SavedStyleRow
      name={savedStyle.name}
      value={generateTextSavedStylePreviewValue(
        savedStyle.attributes as SavedStyleTextAttributes,
      )}
      type="text"
      isLoading={isLoading}
      isSelected={editingSavedStyleId === savedStyle.id}
      onClick={() => setEditingSavedStyleId(savedStyle.id ?? null)}
      endEnhancer={
        // NOTE (Fran 2024-11-14): In the case we were showing the imported or duplicated saved styles,
        // we don't have an id. And we don't want to show the end enhancer in that case, because they
        // are not editable.
        savedStyle.id && (
          <SavedStyleItemEndEnhancer
            type="text"
            savedStyleAttributes={savedStyle.attributes}
            name={savedStyle.name}
            id={savedStyle.id}
            deleteSavedStyle={deleteSavedStyle}
            setEditingSavedStyleId={setEditingSavedStyleId}
          />
        )
      }
    />
  );
};
