/* eslint-disable no-multi-str */

export type UnpublishableWarningType =
  | "unpublishable.invalidSectionSettingsText"
  | "unpublishable.notActiveProducts";

interface WarningDetails {
  title: string;
  text: string;
  solution: string;
}

interface UnpublishableWarnings {
  [key: `unpublishable.${string}`]: WarningDetails;
}

export const unpublishableWarnings: UnpublishableWarnings = {
  "unpublishable.invalidSectionSettingsText": {
    title: "Text components in section not editable in Shopify",
    text: "Some text components in this section may not be editable in the \
              Shopify Theme Customizer since they include inline styles or too \
              many characters, which Shopify does not support. The following \
              text components are affected:",
    solution:
      "You can either toggle off the 'Edit in Theme\
              Editor'; toggle in this section's settings, or press\
              'Publish Anyway' if you don't want these text\
              components to be editable in the Shopify Theme Customizer.",
  },
  "unpublishable.notActiveProducts": {
    title: "Products Inactive in Shopify",
    text: "Some products have a status of 'Archived' or 'Draft' in Shopify. These will not be visible on your live store.",
    solution: "Ensure all products are active in Shopify.",
  },
};
