import * as React from "react";

import { generatePageNumbersToShow } from "@editor/utils/pagination";

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@replo/design-system/components/shadcn/core/pagination";

interface PaginationMetricsProps {
  currentPageNumber: number;
  totalRowsCount: number;
  resultsPerPage: number;
  handleOnPageNumberChange: (clickedPageNumber: number) => void;
}

const PaginationMetrics: React.FC<PaginationMetricsProps> = ({
  currentPageNumber,
  totalRowsCount,
  resultsPerPage,
  handleOnPageNumberChange,
}) => {
  const pageNumbersToShow = generatePageNumbersToShow(
    currentPageNumber,
    totalRowsCount,
    resultsPerPage,
  );

  const isFirstPage = currentPageNumber === 1;
  const isLastPage =
    currentPageNumber === pageNumbersToShow[pageNumbersToShow.length - 1];

  return (
    <Pagination>
      <PaginationContent className="gap-4">
        <PaginationItem
          onClick={() => {
            if (!isFirstPage) {
              handleOnPageNumberChange(currentPageNumber - 1);
            }
          }}
        >
          <PaginationPrevious
            href="#"
            isNavigationButton={true}
            isDisabled={isFirstPage}
            className="gap-1"
          />
        </PaginationItem>
        <div className="flex flex-row gap-1">
          {pageNumbersToShow.map((pageNumber) => {
            return typeof pageNumber === "string" ? (
              <PaginationItem key={pageNumber}>
                <PaginationEllipsis className="pt-3" />
              </PaginationItem>
            ) : (
              <PaginationItem
                key={`page_number_${pageNumber}`}
                onClick={() => handleOnPageNumberChange(pageNumber)}
              >
                <PaginationLink
                  href="#"
                  isNavigationButton={false}
                  isActive={pageNumber === currentPageNumber}
                >
                  {pageNumber}
                </PaginationLink>
              </PaginationItem>
            );
          })}
        </div>
        <PaginationItem
          onClick={() => {
            if (!isLastPage) {
              handleOnPageNumberChange(currentPageNumber + 1);
            }
          }}
        >
          <PaginationNext
            href="#"
            isNavigationButton={true}
            isDisabled={isLastPage}
            className="gap-1"
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
};

export default PaginationMetrics;
