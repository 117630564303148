import type {
  SavedColorStyle,
  SavedTextStyle,
} from "schemas/generated/designLibrary";
import type {
  SavedStyleColorType,
  SavedStyleTextAttributes,
} from "schemas/generated/savedStyles";

import { COLOR_MENU_ITEMS } from "@editor/components/common/constants/colorStyles";

export function generateTextSavedStylePreviewValue(
  attributes: SavedStyleTextAttributes,
) {
  return `${attributes.fontSize}/${attributes.lineHeight}`;
}

export const getSavedStyleValue = (
  savedStyles: ((SavedTextStyle | SavedColorStyle) & { id: string })[],
  selectedSavedStyleValue: string,
) => {
  const parsedSavedStyleId = getSavedStyleId(selectedSavedStyleValue);
  return savedStyles.find((style) => style.id === parsedSavedStyleId);
};

export const getSavedStyleId = (savedStyleDynamicDataValue: string) => {
  return savedStyleDynamicDataValue.split(".")[3];
};

type GenerateNewNameOptions =
  | {
      savedStyles: SavedTextStyle[];
      type: "text";
    }
  | {
      savedStyles: SavedColorStyle[];
      type: "color";
      colorType: SavedStyleColorType;
    };

export const generateNewName = (
  baseName: string,
  options: GenerateNewNameOptions,
): string => {
  if (options.type === "text") {
    const existingLetters = options.savedStyles
      .filter((style) => style.name.startsWith(baseName))
      .map((style) => {
        const match = style.name.match(/ ([a-z])$/);
        return match ? match[1] : "";
      })
      .filter(Boolean);

    if (existingLetters.length === 0) {
      return `${baseName} a`;
    }

    const highestLetter = existingLetters.sort().pop() || "a";
    const nextLetter = String.fromCharCode(highestLetter.charCodeAt(0) + 1);
    return `${baseName} ${nextLetter}`;
  }

  if (options.type === "color") {
    const name = COLOR_MENU_ITEMS.find(
      (item) => item.colorType === options.colorType,
    )?.title;

    const baseName = name ?? String(options.colorType);
    const existingNumbers = options.savedStyles
      .filter((style) => style.name.startsWith(baseName))
      .map((style) => {
        const match = style.name.match(/\d+$/);
        return match ? Number.parseInt(match[0], 10) : 0;
      });
    const highestNumber = Math.max(0, ...existingNumbers);
    return `${baseName} ${highestNumber + 1}`;
  }

  return baseName;
};
