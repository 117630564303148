import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";

/**
 * NOTE (Mariano, 2022-03-21): Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === "object" && error != null && "status" in error;
}

/**
 * NOTE (Mariano, 2022-03-21): Type predicate to narrow an unknown error to an object with a string 'message' property
 */
function isErrorWithMessage(error: unknown): error is { message: string } {
  return (
    typeof error === "object" &&
    error != null &&
    "message" in error &&
    typeof (error as any).message === "string"
  );
}

/**
 * NOTE (Mariano, 2022-03-21): the error object can be FetchBaseQueryError | SerializedError | undefined,
 * which means a message key might not be present in it.
 * https://redux-toolkit.js.org/rtk-query/usage-with-typescript#type-safe-error-handling
 */
export function getErrorMessage(error: unknown): { message: string } | null {
  if (isFetchBaseQueryError(error)) {
    const err = "error" in error ? error.error : error.data;
    return {
      message:
        typeof err === "object" &&
        err !== null &&
        "errors" in err &&
        Array.isArray((err as any).errors)
          ? (err as any).errors[0]
          : JSON.stringify(err),
    };
  } else if (isErrorWithMessage(error)) {
    return {
      message: error.message,
    };
  }

  return null;
}
