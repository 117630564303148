import type { SvgProps } from "replo-utils/react/components";

import * as React from "react";

import { Svg } from "replo-utils/react/components";

const BorderAllSides = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <rect
      width={14.667}
      height={14.667}
      x={0.667}
      y={0.667}
      fill="#E2E8F0"
      stroke="#1E293B"
      strokeWidth={1.333}
      rx={0.667}
    />
  </Svg>
);

export default BorderAllSides;
