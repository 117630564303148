import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const icon: ComponentTemplate = {
  id: "2f426eea-2519-4509-bb17-5d300f857c2f",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Basic/Icon",
  leftBarCategory: { main: "basic", subCategory: "shapes" },
  preview: "basic/icon.svg",

  transforms: [],
  template: {
    id: "$uid",
    type: "icon",
    props: {
      iconName: "arrow-right",
      style: {
        width: "24px",
        height: "24px",
      },
    },
  },
};
