import type {
  DesignLibrary,
  SavedColorStyle,
  SavedTextStyle,
} from "schemas/generated/designLibrary";

import * as React from "react";

import { ColorStyleGroup } from "@common/designSystem/ColorStyleGroup";
import Input from "@common/designSystem/Input";
import Selectable from "@common/designSystem/Selectable";
import Separator from "@common/designSystem/Separator";
import { TextStyleGroup } from "@common/designSystem/TextStyleGroup";
import { errorToast } from "@common/designSystem/Toast";
import ErrorMessage from "@components/account/Dashboard/ErrorMessage";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import useGetDesignLibrarySavedStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import { useHasWorkspaceMembership } from "@editor/hooks/useHasWorkspaceMembership";
import { trpc, trpcClient, trpcUtils } from "@editor/utils/trpc";

import { zodResolver } from "@hookform/resolvers/zod";
import Button, { ButtonLink } from "@replo/design-system/components/button";
import { skipToken } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { BsArrowUpRight } from "react-icons/bs";
import { isEmpty } from "replo-utils/lib/misc";
import { urlFormSchema } from "schemas/url";

type SelectableOption = "url" | "shopify" | "replo";
type FormValues = {
  url: string;
};

const SavedStylesPane: React.FC = () => {
  const { designLibrary } = useGetDesignLibrarySavedStyles();
  const hasSavedStyles = designLibrary
    ? Object.values(designLibrary.savedStyles).filter(
        (savedStyle) => savedStyle.deletedAt === null,
      ).length > 0
    : false;

  return (
    <div className="flex flex-col w-full h-full gap-3 pl-3 py-1 overflow-y-scroll styled-scrollbar pb-10">
      {!hasSavedStyles ? <ImportSavedStyles /> : <SavedStyleGroups />}
    </div>
  );
};

const ImportSavedStyles: React.FC = () => {
  const [isImportingStyles, setIsImportingStyles] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedOption, setSelectedOption] =
    React.useState<SelectableOption | null>(null);
  const [selectedReploProjectId, setSelectedReploProjectId] = React.useState<
    string | null
  >(null);
  const [overridedStyles, setOverridedStyles] = React.useState<{
    text: SavedTextStyle[] | null;
    color: SavedColorStyle[] | null;
  }>({ text: null, color: null });

  const { project } = useCurrentProjectContext();
  const projectId = project?.id;
  const projectHasShopifyIntegration = Boolean(project?.integrations?.shopify);
  const { isUserMemberOfWorkspace } = useHasWorkspaceMembership();
  const { data: designLibraries } =
    trpc.designLibrary.getProjectsWithDesignLibraries.useQuery(
      projectId ? { projectId } : skipToken,
    );

  const {
    mutateAsync: createDesignLibrary,
    isPending: isCreatingDesignLibrary,
  } = trpc.designLibrary.create.useMutation({
    onSettled: () => {
      if (projectId) {
        void trpcUtils.designLibrary.get.invalidate({
          projectId,
        });
      }
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm<FormValues>({
    mode: "onSubmit",
    defaultValues: {
      url: "",
    },
    resolver: zodResolver(urlFormSchema),
  });

  const urlError = errors.url?.message;
  const urlValue = watch("url");
  const shouldDisableButton =
    (selectedOption === "url" && (Boolean(urlError) || urlValue === "")) ||
    (selectedOption === "replo" && selectedReploProjectId === null);

  const options = [
    { label: "A URL", value: "url" },
    {
      label: "Connected Shopify site",
      value: "shopify",
      isDisabled: !projectHasShopifyIntegration,
    },
    {
      label: "Another Replo project",
      value: "replo",
      isDisabled: !isUserMemberOfWorkspace,
    },
  ];

  const reploProjectOptions = isEmpty(designLibraries)
    ? [
        {
          value: null,
          label: "There are no available projects",
          isDisabled: true,
        },
      ]
    : designLibraries.map((designLibrary) => ({
        value: designLibrary.id,
        label: designLibrary.name ?? "Untitled",
      }));

  const onSubmit = async ({ url }: FormValues) => {
    setIsImportingStyles(true);
    setIsLoading(true);
    if (selectedOption === "replo" && projectId && selectedReploProjectId) {
      const data = await trpcClient.designLibrary.get.query({
        projectId: selectedReploProjectId,
      });
      setOverridedStyles({
        text: getOverrideStyles(data, "text"),
        color: getOverrideStyles(data, "color"),
      });
    }
    if (selectedOption === "url" || selectedOption === "shopify") {
      try {
        const { color, text } = await trpcClient.ai.generatePrimaryStyles.query(
          {
            url,
          },
        );
        setOverridedStyles({
          text: makeStylesPrimary(text),
          color: makeStylesPrimary(color),
        });
      } catch {
        errorToast(
          "Failed to generate styles",
          "Please try again, or contact support@replo.app for help.",
        );
        setIsImportingStyles(false);
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  const onConfirm = () => {
    if (selectedOption === "replo" && projectId && selectedReploProjectId) {
      void createDesignLibrary({
        projectId,
        duplicateFromProjectId: selectedReploProjectId,
      });
    }
    if (
      (selectedOption === "url" || selectedOption === "shopify") &&
      projectId
    ) {
      void createDesignLibrary({
        projectId,
        savedStyles: {
          text: overridedStyles.text ?? [],
          color: overridedStyles.color ?? [],
        },
      });
    }

    setIsImportingStyles(false);
    setOverridedStyles({ text: null, color: null });
  };

  return (
    <div className="flex flex-col gap-2 h-full">
      {/* IMPORT BOX */}
      {!isImportingStyles && (
        <div className="flex flex-col text-default text-xs gap-2 border-slate-300 rounded-md p-2 border">
          <div className="font-semibold">Import brand styles</div>
          <div>Transfer type, color, and more from another source.</div>
          <form
            className="flex flex-col gap-2"
            onSubmit={(e) => {
              e.preventDefault();
              void handleSubmit(onSubmit)(e);
            }}
          >
            <Selectable
              placeholder="Transfer from"
              options={options}
              labelClassName={selectedOption ? "text-default" : "text-subtle"}
              value={selectedOption}
              onSelect={(value) => {
                setSelectedOption(value as SelectableOption);
                if (value === "shopify") {
                  setValue(
                    "url",
                    project?.integrations?.shopify?.store.shopifyUrl ?? "",
                  );
                } else {
                  reset();
                }
              }}
            />

            {(selectedOption === "url" || selectedOption === "shopify") && (
              <>
                <Input
                  aria-invalid={Boolean(urlError) ? "true" : undefined}
                  aria-describedby={Boolean(urlError) ? "error-url" : undefined}
                  autoComplete="off"
                  placeholder="https://"
                  {...register("url", {
                    required: "Url is required",
                  })}
                  isDisabled={selectedOption === "shopify"}
                  type="text"
                  validityState={Boolean(urlError) ? "invalid" : "valid"}
                />
                {urlError && <ErrorMessage id="error-url" error={urlError} />}
              </>
            )}
            {selectedOption === "replo" && (
              <Selectable
                placeholder="Select a Replo project"
                options={reploProjectOptions}
                onSelect={(value) => setSelectedReploProjectId(value)}
              />
            )}
            {selectedOption && (
              <Button
                variant="primary"
                className="w-full"
                isDisabled={shouldDisableButton}
                type="submit"
                isLoading={isCreatingDesignLibrary}
              >
                Generate Styles
              </Button>
            )}
          </form>
        </div>
      )}
      {/* END IMPORT BOX */}
      <SavedStyleGroups
        overrideTextStyles={overridedStyles.text ?? undefined}
        overrideColorStyles={overridedStyles.color ?? undefined}
        isLoading={isLoading}
      />
      {!isImportingStyles && <NoStylesBox />}
      {!isLoading && isImportingStyles && (
        <ConfirmSavedStyle
          onConfirm={onConfirm}
          onCancel={() => {
            reset();
            setOverridedStyles({ text: null, color: null });
            setIsImportingStyles(false);
          }}
        />
      )}
    </div>
  );
};

const NoStylesBox: React.FC = () => (
  <div className="flex flex-col text-muted bg-slate-100 gap-3 items-center p-10 rounded-lg text-center">
    <div className="font-medium text-sm">No Styles Added Yet</div>
    <div className="text-xs">
      Styles allow you to set consistent design styles across colors and text.
    </div>
    <ButtonLink
      target="_blank"
      variant="tertiary"
      // TODO (Sebas, 2024-11-12): Add the correct link
      to="https://support.replo.app/hc/en-us"
      size="sm"
      className="text-blue-600 hover:text-blue-600"
    >
      <div className="flex items-center gap-2">
        Learn More <BsArrowUpRight />
      </div>
    </ButtonLink>
  </div>
);

const SavedStyleGroups: React.FC<{
  overrideTextStyles?: SavedTextStyle[];
  overrideColorStyles?: SavedColorStyle[];
  isLoading?: boolean;
}> = ({ overrideTextStyles, overrideColorStyles, isLoading }) => {
  return (
    <>
      <TextStyleGroup
        overrideSavedStyles={overrideTextStyles}
        isLoading={isLoading}
      />
      <Separator />
      <ColorStyleGroup
        overrideSavedStyles={overrideColorStyles}
        isLoading={isLoading}
      />
    </>
  );
};

const ConfirmSavedStyle: React.FC<{
  onConfirm: () => void;
  onCancel: () => void;
}> = ({ onConfirm, onCancel }) => {
  return (
    <div className="flex flex-col text-default text-xs gap-2 border-slate-300 rounded-md p-2 border mt-auto">
      <div className="font-semibold">Save brand styles?</div>
      <div>If you accept, you can always edit styles later.</div>
      <div className="flex items-center gap-2">
        <Button variant="primary" onClick={onConfirm} className="w-full">
          Yes
        </Button>
        <Button variant="secondary" onClick={onCancel} className="w-full">
          No
        </Button>
      </div>
    </div>
  );
};

function getOverrideStyles<T extends SavedTextStyle | SavedColorStyle>(
  designLibrary: DesignLibrary | null,
  type: "text" | "color",
): T[] | null {
  if (!designLibrary) {
    return null;
  }
  const filteredStyles = Object.values(designLibrary.savedStyles).reduce<T[]>(
    (savedStyles, savedStyle) => {
      if (savedStyle.type === type) {
        savedStyles.push({
          type: savedStyle.type,
          name: savedStyle.name,
          attributes: savedStyle.attributes,
          optionalUsageGuidelines: savedStyle.optionalUsageGuidelines,
          deletedAt: savedStyle.deletedAt,
        } as T);
      }
      return savedStyles;
    },
    [],
  );

  return filteredStyles;
}

function makeStylesPrimary<T extends SavedTextStyle | SavedColorStyle>(
  styles: T[],
) {
  return styles.map((style) => ({
    ...style,
    isPrimary: true,
  }));
}

export default SavedStylesPane;
