import type { ProductRef } from "schemas/product";

// biome-ignore lint/nursery/noEnum: This is a legacy enum, we should convert to a string union
export enum ItemsConfigType {
  dataTable = "dataTable",
  productImages = "productImages",
  inline = "inline",
  htmlAttributes = "htmlAttributes",
}

export type DataTablesItemsConfig = {
  type: ItemsConfigType.dataTable;
  id?: string | null;
};

export type ProductImagesConfig = {
  type: ItemsConfigType.productImages;
  productRef?: ProductRef;
};

type InlineItemsValues =
  | {
      valueType: "string";
      values: { id: string; value: string | undefined }[] | null;
    }
  | {
      valueType: "dynamic";
      dynamicPath: string;
    };

export type InlineItemsConfig = {
  type: ItemsConfigType.inline;
} & InlineItemsValues;

type HTMLAttributeTypes = "dataset";

export type HTMLAttributesValues = {
  id: string;
  valueType: HTMLAttributeTypes;
  key: string | undefined;
  value: string | undefined;
};

export type HTMLAttributesConfig = {
  type: ItemsConfigType.htmlAttributes;
  values: HTMLAttributesValues[];
};
export type ItemsConfig =
  | DataTablesItemsConfig
  | ProductImagesConfig
  | InlineItemsConfig
  | HTMLAttributesConfig;
