import type { ComponentTemplate } from "@editor/types/component-template";

/**
 * This function is in charge of updating the image component template
 * with the new loading attribute value. This is used for applying the lazy loading attribute to the
 * image component when the user scrolls the canvas.
 */
function getUpdatedImageComponentTemplate(
  template: ComponentTemplate | null | undefined,
  deltaY: number,
) {
  let updatedTemplate = null;
  if (template?.template?.type === "image") {
    updatedTemplate = { ...template };
    if (updatedTemplate.template) {
      // NOTE (Sebas, 2024-05-02): This is a random value that I found that works well for applying
      // the lazy loading attribute to the image component
      if (deltaY <= -600) {
        updatedTemplate.template.props["loading"] = "lazy";
      } else {
        updatedTemplate.template.props["loading"] = "eager";
      }
    }
  }
  return updatedTemplate;
}

export default getUpdatedImageComponentTemplate;
