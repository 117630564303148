import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import classNames from "classnames";

import { hasAnchorTag } from "../../components/Button/utils";
import ContentEditableComponent from "../../components/ContentEditableComponent";

const Button: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
  context,
}) => {
  const { ref: containerRef, ...attributes } = componentAttributes;
  const buttonHasAnchorTag = hasAnchorTag(component);

  // Note (Noah, 2022-11-14): Buttons can have arbitrary children but text
  // cannot. However, buttons can also have a text prop (legacy). If we have
  // children, we have to make sure not to set dangerouslySetInnerHTML and instead
  // we have to pass the children through to the element (button, a, etc).
  const isPureText = component.children == null;

  return (
    <ContentEditableComponent
      componentType="button"
      isPureText={isPureText}
      component={component}
      containerRef={containerRef}
      context={context}
      hasAnchorTag={buttonHasAnchorTag}
      {...attributes}
      key={attributes.key}
      className={classNames("text", attributes.className)}
    />
  );
};

export default Button;
