import type {
  ReploProject,
  ReploSimpleProject,
} from "schemas/generated/project";

import { formatShopifyUrl } from "replo-utils/lib/misc/formatShopifyUrl";

export function getStoreData(data?: ReploProject | ReploSimpleProject | null) {
  return data?.integrations?.shopify?.store;
}

export function getProjectName(
  project: ReploProject | ReploSimpleProject | undefined,
) {
  if (project?.name) {
    return project.name;
  }
  const store = project?.integrations?.shopify?.store;
  if (store?.shopifyUrl) {
    return formatShopifyUrl(store.shopifyUrl);
  }
  return "Project";
}
