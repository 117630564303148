export function isMacintosh() {
  return window.navigator.platform.includes("Mac");
}

export function isWindows() {
  return window.navigator.platform.includes("Win");
}

export function isFirefox() {
  return navigator.userAgent.toLowerCase().includes("firefox");
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
