import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const feraMediaGalleryWidget: ComponentTemplate = {
  id: "7e79069b-0171-496a-803a-43aaf0b56ab3",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Fera Media Gallery",
  leftBarCategory: { main: "productIntegrations", subCategory: "fera" },
  tags: ["product", "widget"],
  preview: "integrations/fera-media-gallery.svg",
  transforms: [],
  template: {
    id: "$uid",
    type: "feraMediaGalleryWidget",
    props: { style: { alignSelf: "stretch" } },
  },
};
