import * as React from "react";

import { twMerge } from "tailwind-merge";

type ErrorProps = Omit<React.ComponentPropsWithoutRef<"div">, "children"> & {
  error?: string | null;
};

export default function ErrorMessage({
  error,
  className,
  ...props
}: ErrorProps) {
  return error ? (
    <div
      className={twMerge("mb-1.5 text-xs text-red-600", className)}
      {...props}
    >
      {error}
    </div>
  ) : null;
}
