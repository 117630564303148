import type { Swatch } from "replo-runtime/shared/types";

export const fakeSwatches: Swatch[] = [
  {
    id: "2df99473-73a5-4ce8-8c80-8f84a757d86c",
    name: "Fake Swatch 1",
    data: {
      type: "option",
      options: [
        {
          id: "9a282836-4ef4-4a16-a63f-207a596bf3a0",
          key: {
            name: "Color",
            value: "Black",
          },
          label: "Color/Black",
          value: {
            color: {
              type: "solid",
              color: "#000000FF",
            },
          },
        },
        {
          id: "2a2a75ea-c60c-461d-93a3-b9906562278f",
          key: {
            name: "Color",
            value: "Light Blue",
          },
          label: "Color/Light Blue",
          value: {
            color: {
              type: "solid",
              color: "#CCD6E1",
            },
          },
        },
        {
          id: "567f7602-46d0-4f6f-b795-d2bc938f12a6",
          key: {
            name: "Color",
            value: "Navy",
          },
          label: "Color/Navy",
          value: {
            color: {
              type: "solid",
              color: "#0E0F11FF",
            },
          },
        },
        {
          id: "0b8b300a-14eb-49da-912b-a3ca9b178fb2",
          key: {
            name: "Color",
            value: "Olive",
          },
          label: "Color/Olive",
          value: {
            color: {
              type: "solid",
              color: "#424133",
            },
          },
        },
        {
          id: "56fdb88d-4c22-4406-b751-357bfa3a80d0",
          key: {
            name: "Color",
            value: "Neon",
          },
          label: "Color/Neon",
          value: {
            color: {
              type: "solid",
              color: "#B7CA69",
            },
          },
        },
        {
          id: "946959d1-3f72-4dfc-84a0-f4ba4a47d0e2",
          key: {
            name: "Color",
            value: "Sky Blue",
          },
          label: "Color/Sky Blue",
          value: {
            color: {
              type: "solid",
              color: "#E6F6FA",
            },
          },
        },
        {
          id: "b1e7e00c-8ee0-4ab1-8b0b-767dcfdb89c6",
          key: {
            name: "Color",
            value: "Purple",
          },
          label: "Color/Purple",
          value: {
            color: {
              type: "solid",
              color: "#50386C",
            },
          },
        },
        {
          id: "8ef5048d-8315-4153-9db2-d7c5914c3e7e",
          key: {
            name: "Color",
            value: "Carbon Grey",
          },
          label: "Color/Carbon Grey",
          value: {
            color: {
              type: "solid",
              color: "#B1AFAB",
            },
          },
        },
        {
          id: "8d71d7f8-7ca5-457d-89b2-8a2e23107b29",
          key: {
            name: "Color",
            value: "Navy Blazer",
          },
          label: "Color/Navy Blazer",
          value: {
            color: {
              type: "solid",
              color: "#191C26",
            },
          },
        },
        {
          id: "8ce014f1-5a16-482a-a61b-e22c491a6254",
          key: {
            name: "Color",
            value: "Dusty Rose",
          },
          label: "Color/Dusty Rose",
          value: {
            color: {
              type: "solid",
              color: "#CF957F",
            },
          },
        },
        {
          id: "a76b29dd-781d-48a3-8f88-51c53585066f",
          key: {
            name: "Color",
            value: "Carolina Blue",
          },
          label: "Color/Carolina Blue",
          value: {
            color: {
              type: "solid",
              color: "#9CAADD",
            },
          },
        },
        {
          id: "48e0d038-529b-4f9f-8b89-b43cac688569",
          key: {
            name: "Color",
            value: "Charcoal Heather",
          },
          label: "Color/Charcoal Heather",
          value: {
            color: {
              type: "solid",
              color: "#31302E",
            },
          },
        },
        {
          id: "39975d56-10af-476e-8309-66ff0b7d333b",
          key: {
            name: "Color",
            value: "White",
          },
          label: "Color/White",
          value: {
            color: {
              type: "solid",
              color: "#F9F9F9",
            },
          },
        },
        {
          id: "4da2de4e-5360-41f1-a163-f4eecf03b556",
          key: {
            name: "Color",
            value: "Red",
          },
          label: "Color/Red",
          value: {
            color: {
              type: "solid",
              color: "#973321",
            },
          },
        },
        {
          id: "deb5d5ff-36c5-4bfc-8b85-91767e0bf4c8",
          key: {
            name: "Color",
            value: "Orange",
          },
          label: "Color/Orange",
          value: {
            color: {
              type: "solid",
              color: "#C69736",
            },
          },
        },
        {
          id: "8b8305a0-2e90-4dc2-8f74-1a9dd2be16e6",
          key: {
            name: "Color",
            value: "Pink",
          },
          label: "Color/Pink",
          value: {
            color: {
              type: "solid",
              color: "#D6AEC0",
            },
          },
        },
        {
          id: "8d4e00ab-2b32-45cc-a467-cb6a98f324e1",
          key: {
            name: "Color",
            value: "Light Pink",
          },
          label: "Color/Light Pink",
          value: {
            color: {
              type: "solid",
              color: "#DBC5D2",
            },
          },
        },
        {
          id: "f37c5199-72db-45e4-ad5b-8d767f9e5842",
          key: {
            name: "Color",
            value: "Grey",
          },
          label: "Color/Grey",
          value: {
            color: {
              type: "solid",
              color: "#C2C2C6",
            },
          },
        },
        {
          id: "235a7085-7ce7-44aa-a41a-4d8cfec769b7",
          key: {
            name: "Color",
            value: "Army",
          },
          label: "Color/Army",
          value: {
            color: {
              type: "solid",
              color: "#4F4931",
            },
          },
        },
        {
          id: "499b7cb6-ecff-479a-aba6-5acf925715ac",
          key: {
            name: "Color",
            value: "Burnt Orange",
          },
          label: "Color/Burnt Orange",
          value: {
            color: {
              type: "solid",
              color: "#C2834D",
            },
          },
        },
        {
          id: "447c0316-a3ae-4150-b189-2ee5e7f336b2",
          key: {
            name: "Color",
            value: "Brown",
          },
          label: "Color/Brown",
          value: {
            color: {
              type: "solid",
              color: "#2D2724",
            },
          },
        },
        {
          id: "61a24a62-af16-42c0-9039-715803cb2425",
          key: {
            name: "Color",
            value: "Citron",
          },
          label: "Color/Citron",
          value: {
            color: {
              type: "solid",
              color: "#EFF3DE",
            },
          },
        },
        {
          id: "9989ec79-8db3-454f-94a2-59eb480b4cf8",
          key: {
            name: "Color",
            value: "Heather",
          },
          label: "Color/Heather",
          value: {
            color: {
              type: "solid",
              color: "#C0E2C9",
            },
          },
        },
        {
          id: "6abdad45-d224-443c-ae9b-8929c2452888",
          key: {
            name: "Color",
            value: "Mustard",
          },
          label: "Color/Mustard",
          value: {
            color: {
              type: "solid",
              color: "#C99F41",
            },
          },
        },
        {
          id: "20dffe58-c394-4a16-867a-da95be5c8fd9",
          key: {
            name: "Color",
            value: "Maroon",
          },
          label: "Color/Maroon",
          value: {
            color: {
              type: "solid",
              color: "#572835",
            },
          },
        },
        {
          id: "88e91a9d-2560-4658-8a91-3b44a0521696",
          key: {
            name: "Color",
            value: "Yellow",
          },
          label: "Color/Yellow",
          value: {
            color: {
              type: "solid",
              color: "#E5D075",
            },
          },
        },
        {
          id: "8a444c2d-23e8-4a67-ab59-e90be9dcacd3",
          key: {
            name: "Color",
            value: "Graphite",
          },
          label: "Color/Graphite",
          value: {
            color: {
              type: "solid",
              color: "#343438",
            },
          },
        },
      ],
      productIds: [
        7_731_722_191_085, 7_912_740_716_781, 7_912_923_562_221,
        7_912_927_658_221, 7_912_932_802_797, 7_913_103_130_861,
        7_913_121_186_029, 7_913_123_971_309, 7_913_131_835_629,
      ],
    },
  },
  {
    id: "d35f54e8-ed45-40da-8428-7ee7225e3c63",
    name: "Fake Swatch 2",
    data: {
      type: "option",
      options: [
        {
          id: "4b819a8f-50fe-476a-8d76-f49202e226f6",
          key: {
            name: "Color",
            value: "Army",
          },
          label: "Color/Army",
          value: {
            image: {
              src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/premium-recycled-bomber-jacket-army-front-630a65c19210f.png?v=1661647551",
            },
          },
        },
        {
          id: "9c0d7816-9626-49df-a9a4-6ad3bba76fd9",
          key: {
            name: "Color",
            value: "Black",
          },
          label: "Color/Black",
          value: {
            image: {
              src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/premium-recycled-bomber-jacket-black-front-630a65c191080.png?v=1661647552",
            },
          },
        },
      ],
      productIds: [7_912_918_188_269],
    },
  },
  {
    id: "663b0613-cd7a-4a83-89b0-14a6e955c319",
    name: "Fake Swatch 3",
    data: {
      type: "variant",
      variants: [
        {
          id: "664815b9-f38d-4c74-a147-7aa60c5b7008",
          key: {
            productId: 7_913_121_186_029,
            variantId: 43_259_017_789_677,
          },
          label: "Yellow",
          value: {
            color: {
              type: "gradient",
              gradient: {
                tilt: "90deg",
                stops: [
                  {
                    id: "b7a7cb71-3b62-4d77-a4a4-d68623d8463f",
                    color: "#F5DA6EFF",
                    location: "0%",
                  },
                  {
                    id: "e658816f-fde0-4b42-a344-7a0fde597fad",
                    color: "#1E293BFF",
                    location: "100%",
                  },
                ],
              },
            },
            image: {
              src: "https://andytown-public.s3.us-west-1.amazonaws.com/miscellaneous/swatches/replo-mug/yellow.jpg",
            },
          },
        },
        {
          id: "031f06bc-8ef7-48c6-8dac-bd1846755a94",
          key: {
            productId: 7_913_121_186_029,
            variantId: 43_259_017_756_909,
          },
          label: "Pink",
          value: {
            color: {
              type: "gradient",
              gradient: {
                tilt: "90deg",
                stops: [
                  {
                    id: "c894ecc1-5d2d-4be4-8e4e-b0b33f958fcc",
                    color: "#DE97A3FF",
                    location: "0%",
                  },
                  {
                    id: "dd74e308-b9b4-48b0-be61-b0059029f7fe",
                    color: "#1E293BFF",
                    location: "100%",
                  },
                ],
              },
            },
            image: {
              src: "https://andytown-public.s3.us-west-1.amazonaws.com/miscellaneous/swatches/replo-mug/pink.jpg",
            },
          },
        },
        {
          id: "dfb390a9-83e5-4fa7-9ed3-5c341a3e7755",
          key: {
            productId: 7_913_121_186_029,
            variantId: 43_259_017_724_141,
          },
          label: "Orange",
          value: {
            color: {
              type: "gradient",
              gradient: {
                tilt: "90deg",
                stops: [
                  {
                    id: "8b653d18-88c7-45ca-ae2b-63def5593a59",
                    color: "#DA4924FF",
                    location: "0%",
                  },
                  {
                    id: "0b2b776a-aa0b-469a-9047-c00c71e1d16b",
                    color: "#1E293BFF",
                    location: "100%",
                  },
                ],
              },
            },
            image: {
              src: "https://andytown-public.s3.us-west-1.amazonaws.com/miscellaneous/swatches/replo-mug/orange.jpg",
            },
          },
        },
        {
          id: "4a9a4202-6143-4ed3-be47-1fa7ae4f059a",
          key: {
            productId: 7_913_121_186_029,
            variantId: 43_259_022_639_341,
          },
          label: "Black",
          value: {
            color: {
              type: "gradient",
              gradient: {
                tilt: "90deg",
                stops: [
                  {
                    id: "aeab6247-67f5-49a4-88b4-34fad60653b0",
                    color: "#FFFFFFFF",
                    location: "0%",
                  },
                  {
                    id: "a2630591-47e6-44cf-bb28-0f016746b6a9",
                    color: "#1E293BFF",
                    location: "100%",
                  },
                ],
              },
            },
            image: {
              src: "https://andytown-public.s3.us-west-1.amazonaws.com/miscellaneous/swatches/replo-mug/black.jpg",
            },
          },
        },
      ],
      productIds: [7_913_121_186_029],
    },
  },
  {
    id: "ae0c0078-3904-4fb7-936d-ad709372c65b",
    name: "Fake Swatch 4",
    data: {
      type: "option",
      options: [
        {
          id: "bc81c3bc-4ec3-4ffc-a5cb-38d44416a43e",
          key: {
            name: "Color",
            value: "Maroon",
          },
          label: "Color/Maroon",
          value: {
            image: {
              src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-maroon-front-630983085be08_350x350.png?v=1661632885",
            },
          },
        },
        {
          id: "defc69f1-b5d9-4a4d-821e-80a0a94f6cbb",
          key: {
            name: "Color",
            value: "Grey",
          },
          label: "Color/Grey",
          value: {
            image: {
              src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-sport-grey-front-630983087362d_350x350.png?v=1661632850",
            },
          },
        },
        {
          id: "a8091bac-dd5b-47a9-8b39-bfd624112c0e",
          key: {
            name: "Color",
            value: "Navy",
          },
          label: "Color/Navy",
          value: {
            image: {
              src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-navy-front-630983085aaff_350x350.png?v=1661632849",
            },
          },
        },
        {
          id: "d970b449-1cf0-4a32-883a-498a5e9c1c17",
          key: {
            name: "Color",
            value: "Light Blue",
          },
          label: "Color/Light Blue",
          value: {
            image: {
              src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-light-blue-front-630983086e6c9_350x350.png?v=1661632849",
            },
          },
        },
        {
          id: "6c0a9df0-6e3f-4cf2-95fb-c1f253749a27",
          key: {
            name: "Color",
            value: "Light Pink",
          },
          label: "Color/Light Pink",
          value: {
            image: {
              src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-light-pink-front-63098308791a0_350x350.png?v=1661632849",
            },
          },
        },
        {
          id: "886d333a-3fb1-45aa-a3c6-271199d489af",
          key: {
            name: "Color",
            value: "Black",
          },
          label: "Color/Black",
          value: {
            image: {
              src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-black-front-630983085a4b2_350x350.png?v=1661569050",
            },
          },
        },
        {
          id: "0498506f-a5ae-4fde-92d2-e9159f216651",
          key: {
            name: "Color",
            value: "Olive",
          },
          label: "Color/Olive",
          value: {
            image: {
              src: "https://cdn.shopify.com/s/files/1/0636/9404/5421/products/unisex-crew-neck-sweatshirt-military-green-front-63098308695ac_350x350.png?v=1661632849",
            },
          },
        },
      ],
      productIds: [7_731_722_191_085],
    },
  },
];
