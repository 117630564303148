import type { ComponentContext } from "replo-runtime/shared/styles";
import type {
  Component,
  CustomPropDefinition,
  ReploComponentType,
} from "schemas/component";

export type ComponentMapping = Record<
  string,
  { component: Component; parentComponent: Component | null }
>;

type ComponentIdAndType = [string, ReploComponentType]; // [ComponentId, ComponentType]
export type AncestorComponentData = ComponentIdAndType[];

export type ComponentIdAndTypeWithLevel = [string, ReploComponentType, number]; // [ComponentId, ComponentType, LevelFromStartComponent]
export type ContainedComponentData = ComponentIdAndTypeWithLevel[];

export type ComponentStylesForChildren = Pick<
  ComponentContext,
  "parentOverrideStyleRules" | "parentStyleProps"
>;

export type ComponentData = {
  id: string;
  parentId: string | null;
  label: string;
  type: ReploComponentType;
  depth: number;
  order: number;
  siblingIndex: number;
  ancestorIsDynamicRepetition: boolean;
  ancestorHasVariants: boolean;
  ancestorOrSelfWithVariantsId: string | null;
  customPropDefinitions: CustomPropDefinition[];
  ancestorComponentData: AncestorComponentData;
  containedComponentData: ContainedComponentData;
  // TODO (Martin, 2024-04-18): styleProps should use ComponentStyleProps
  // but it's triggering infinite re-renders on componentDataMapping so we need
  // to investigate why and then fix it.
  stylesForChildren: string | null;
  isLabelledByOtherComponent?: boolean;
};

// NOTE (Martin 2024-12-04): Use Record instead of Map to make it serializable
// for Redux store purposes.
// https://redux.js.org/style-guide/#do-not-put-non-serializable-values-in-state-or-actions
export type ComponentDataMapping = Record<string, ComponentData>;

export function isComponentInsideProductComponent(
  componentId: string,
  componentDataMapping: ComponentDataMapping | null,
): boolean {
  if (!componentDataMapping) {
    return false;
  }
  const componentData = componentDataMapping[componentId];
  if (!componentData) {
    return false;
  }
  return componentData.ancestorComponentData.some(
    ([, type]) => type === "product",
  );
}
