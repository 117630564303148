export const loadingTextChoices = [
  {
    content: `Hold on to your circuits, we're cookin' up some Replo AI magic!`,
    emoji: `💫`,
  },
  {
    content: `Generating content so good, you'll wonder if we stole your brain.`,
    emoji: `🧠`,
  },
  {
    content: `Stay calm and trust the Replo AI overlord.`,
    emoji: `🤖`,
  },
  { content: `Looking for content? Just Replo and chill, baby.`, emoji: `😎` },
  { content: `Summoning the meme lords for your amusement.`, emoji: `🎩` },
  {
    content: `Teaching Replo AI to beat you at tic-tac-toe while you wait.`,
    emoji: `😏`,
  },
  {
    content: `Cracking Replo AI code faster than you can say "banana"!`,
    emoji: `🍌`,
  },
  { content: `We're Replo. We're like a content unicorn.`, emoji: `🦄` },
  { content: `Fueling Replo AI with spicy memes and caffeine.`, emoji: `☕` },
  {
    content: `Time flies when you're waiting for Replo AI content!`,
    emoji: `⏰`,
  },
  {
    content: `Entering hyperspace to bring you content from the future.`,
    emoji: `🚀`,
  },
  { content: `So, you come here often? wink`, emoji: `😉` },
  {
    content: `Your content is loading at the speed of dank memes.`,
    emoji: `🚀`,
  },
  {
    content: `Hold your horses, human. We're generating the goods.`,
    emoji: `🐎`,
  },
  { content: `The content is coming, we promise. No bamboozles!`, emoji: `🦡` },
  { content: `Brace yourself, Replo AI awesomeness is coming.`, emoji: `❄️` },
  { content: `99 little bugs in the code, 99 little bugs...`, emoji: `🐞` },
  {
    content: `Generating content faster than a cheetah on roller skates.`,
    emoji: `🐆`,
  },
  { content: `Knock, knock! Who's there? Replo AI content.`, emoji: `🚪` },
  {
    content: `We're just checking if you're still there. You're so patient!`,
    emoji: `😇`,
  },
  {
    content: `Replo AI is working harder than a cat trying to catch a laser pointer.`,
    emoji: `🐱`,
  },
  {
    content: `Fact: Waiting for Replo AI content increases your coolness by 137%.`,
    emoji: `😎`,
  },
  {
    content: `You know what they say... Good things come to those who wait for Replo AI.`,
    emoji: `💁‍♀️`,
  },
  {
    content: `Hang tight! Replo AI is busy petting all the internet cats.`,
    emoji: `🐈`,
  },
  {
    content: `One does not simply generate content without waiting.`,
    emoji: `🧙`,
  },
  {
    content: `Upgrading our AI's humor module for your amusement.`,
    emoji: `😂`,
  },
  { content: `Doing the cha-cha while your content loads.`, emoji: `💃` },
  { content: `Meme level: over 9000!`, emoji: `📈` },
  { content: `Shh! Replo AI is thinking...`, emoji: `🤫` },
  { content: `Replo AI is faster than a sloth on a sugar rush.`, emoji: `🦥` },
  {
    content: `Your content is being handcrafted by a team of tiny robots.`,
    emoji: `🤖`,
  },
  { content: `Beep boop. It's content time!`, emoji: `🕰️` },
  { content: `dramatic pause ...And now back to your content.`, emoji: `🎭` },
  {
    content: `Replo AI is currently binge-watching cat videos for inspiration.`,
    emoji: `📺`,
  },
  {
    content: `You wait. We generate. It's a beautiful relationship.`,
    emoji: `💕`,
  },
  { content: `The suspense is killing us too!`, emoji: `🙀` },
  {
    content: `You're not just waiting for content. You're waiting for GREAT content.`,
    emoji: `🌟`,
  },
  { content: `We hope this loading screen sparks joy.`, emoji: `🌈` },
  { content: `We're in the endgame now. Content is near!`, emoji: `🦸` },
  { content: `Need a pick-me-up? Here's a virtual high-five!`, emoji: `✋` },
  {
    content: `Replo AI is writing you a love letter. Or content. One of those.`,
    emoji: `💌`,
  },
  { content: `Preparing to launch...content!`, emoji: `🚀` },
  { content: `Your content is loading like it's 1999`, emoji: `🕺` },
];
