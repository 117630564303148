import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  selectDraftComponentIds,
  selectEditorMode,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";

export function useShowDraftComponentsControls() {
  const draftComponentIds = useEditorSelector(selectDraftComponentIds);
  const editorMode = useEditorSelector(selectEditorMode);
  const hasDraftComponents = isFeatureEnabled("multi-selection")
    ? draftComponentIds.length >= 1
    : draftComponentIds.length === 1;

  return editorMode === EditorMode.edit && hasDraftComponents;
}
