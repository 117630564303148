import type { DateRange } from "react-day-picker";

import { format } from "date-fns";

export function hours(hoursToAdd: number) {
  return hoursToAdd * 60 * 60 * 1000;
}

export function minutes(minutesToAdd: number) {
  return minutesToAdd * 60 * 1000;
}

export function seconds(secondsToAdd: number) {
  return secondsToAdd * 1000;
}

export function convertDaysToMs(days: number) {
  return days * 24 * 60 * 60 * 1000;
}

export function convertMsToDays(ms: number) {
  const msPerDay = 1000 * 60 * 60 * 24;
  return Math.floor(ms / msPerDay);
}

export const dateRangeFormatter = (dateRange: DateRange) => {
  if (!dateRange.from || !dateRange.to) {
    return "Select a date range";
  }
  return `${dateFormatter(dateRange.from)} - ${dateFormatter(dateRange.to)}`;
};

export const dateFormatter = (date: Date) => format(date, "LLL dd, y");
