export function firstGroupMatchOfExpression(
  regex: RegExp,
  value: string,
): string | null {
  let matched;
  // biome-ignore lint/suspicious/noAssignInExpressions: allow assign in expression
  if ((matched = regex.exec(value)) !== null) {
    return matched[1] ?? null;
  }
  return null;
}

export function firstGroupMatchOfExpressionWithIndex(
  regex: RegExp,
  value: string,
): { match: string; index: number } | null {
  let matched;
  // biome-ignore lint/suspicious/noAssignInExpressions: allow assign in expression
  if ((matched = regex.exec(value)) !== null) {
    return matched[1] ? { match: matched[1], index: matched.index } : null;
  }
  return null;
}

export function firstMatchOfExpression(
  regex: RegExp,
  value: string,
): string | null {
  return value.match(regex)?.[0] ?? null;
}
