import * as React from "react";

import { FullScreenLoader } from "@editor/components/common/FullScreenLoader";
import Flows from "@editor/components/flows/Flows";
import { useGetCurrentFlow } from "@editor/components/flows/hooks/useGetCurrentFlow";
import { routes } from "@editor/utils/router";

import { Navigate, useParams, useSearchParams } from "react-router-dom";

export const FlowsLayout = () => {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const isDebug = Boolean(searchParams.get("debug"));
  const { currentFlow, isLoading } = useGetCurrentFlow({
    isDebug,
    // NOTE (Fran 2024-10-21): We have to exclude all the flows that are not onboarding, this is because
    // the onboarding flow is the only one that is triggered by a route.
    excludeNonOnboardingFlows: true,
  });

  if (isLoading) {
    return <FullScreenLoader />;
  }

  // NOTE (Fran 2024-02-06): We redirect to dashboard if there is no current
  // flow or if trying to access a different flow.
  if (!currentFlow) {
    return <Navigate to={routes.dashboard} />;
  }

  const flowStep = currentFlow?.steps.find(
    (step) => step.id === params.flowStepId,
  );

  const nextStepFromRoute = flowStep ?? null;

  return (
    <Flows
      entityType={currentFlow.type}
      nextStepFromRoute={nextStepFromRoute}
    />
  );
};
