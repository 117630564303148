import * as React from "react";

import {
  SkeletonLoaderItem,
  SkeletonLoaderWrapper,
} from "@editor/components/common/designSystem/SkeletonLoader";

export const PartnershipLevelCardSkeleton: React.FC = () => {
  return (
    <SkeletonLoaderWrapper
      speed={2}
      width={600}
      height={139}
      viewBox="0 0 600 139"
      backgroundColor="#f1f5f9"
      foregroundColor="#e2e8f0"
    >
      <SkeletonLoaderItem yAxis="6" width="117" height="16" />
      <SkeletonLoaderItem yAxis="28" width="200" height="35" />
      <SkeletonLoaderItem yAxis="74" width="155" height="20" />
      <SkeletonLoaderItem yAxis="119" width="500" height="20" />
    </SkeletonLoaderWrapper>
  );
};

const SidebarPartnershipCardSkeletonItem: React.FC<{
  width: string;
  height: string;
  yAxis: string;
}> = ({ height, width, yAxis }) => {
  return <rect x="16" y={yAxis} rx="6" ry="6" width={width} height={height} />;
};

export const SidebarPartnershipCardSkeleton: React.FC = () => {
  return (
    <SkeletonLoaderWrapper
      className="bg-slate-100"
      speed={2}
      width={267}
      height={227}
      viewBox="0 0 267 227"
      backgroundColor="#e2e8f0"
      foregroundColor="#cbd5e1"
    >
      <SidebarPartnershipCardSkeletonItem yAxis="16" width="117" height="16" />
      <SidebarPartnershipCardSkeletonItem yAxis="80" width="235" height="35" />
      <SidebarPartnershipCardSkeletonItem yAxis="123" width="235" height="32" />
      <SidebarPartnershipCardSkeletonItem yAxis="161" width="235" height="32" />
      <SidebarPartnershipCardSkeletonItem yAxis="39" width="156" height="35" />
    </SkeletonLoaderWrapper>
  );
};

export const PartnershipReferralBoardCardSkeleton: React.FC<{
  showCta: boolean;
}> = ({ showCta }) => {
  return (
    <SkeletonLoaderWrapper
      speed={2}
      width={240}
      height={118}
      viewBox="0 0 240 118"
      backgroundColor="#f1f5f9"
      foregroundColor="#e2e8f0"
    >
      <SkeletonLoaderItem yAxis="6" width="200" height="20" />
      <SkeletonLoaderItem yAxis="40" width="200" height="36" />
      {showCta && <SkeletonLoaderItem yAxis="90" width="138" height="24" />}
    </SkeletonLoaderWrapper>
  );
};

export const SidebarWorkspacesSkeleton: React.FC = () => {
  return (
    <SkeletonLoaderWrapper
      className="box-border bg-white rounded px-1"
      speed={2}
      height={35}
      width={310}
      backgroundColor="#e2e8f07E"
      foregroundColor="#cbd5e17E"
    >
      <SkeletonLoaderItem
        xAxis="0"
        yAxis="0"
        width="28"
        height="28"
        rx="50"
        ry="50"
      />
      <SkeletonLoaderItem xAxis="35" yAxis="8" width="240" height="12" />
    </SkeletonLoaderWrapper>
  );
};

export const TitleSkeleton = () => (
  <SkeletonLoaderWrapper
    speed={2}
    width={200}
    height={30}
    viewBox="0 0 200 30"
    backgroundColor="#f1f5f9"
    foregroundColor="#e2e8f0"
  >
    <SkeletonLoaderItem yAxis="0" width="200" height="28" />
  </SkeletonLoaderWrapper>
);

export const InvitationModalSkeleton = () => (
  <SkeletonLoaderWrapper
    speed={2}
    width={302}
    height={30}
    viewBox="0 0 302 30"
    backgroundColor="#f1f5f9"
    foregroundColor="#e2e8f0"
  >
    <SkeletonLoaderItem yAxis="0" width="302" height="28" />
  </SkeletonLoaderWrapper>
);

export const SubtitleSkeleton = () => (
  <SkeletonLoaderWrapper
    speed={2}
    width={400}
    height={20}
    viewBox="0 0 400 20"
    backgroundColor="#f1f5f9"
    foregroundColor="#e2e8f0"
  >
    <SkeletonLoaderItem yAxis="0" width="400" height="18" />
  </SkeletonLoaderWrapper>
);
