import type {
  Gradient,
  GradientOrSolidOnChangeProps,
  GradientValue,
  SolidOrGradient,
  SolidValue,
} from "replo-runtime/shared/types";
import type { SavedStyleColorAttributes } from "schemas/generated/savedStyles";
import type { PreviewableProperty } from "schemas/preview";

import * as React from "react";

import DesignLibraryColorValueIndicator from "@editor/components/designLibrary/DesignLibraryColorValueIndicator";
import useGetDeletedSavedStyleValueIfNeeded from "@editor/hooks/designLibrary/useGetDeletedSavedStyleValueIfNeeded";
import useGetDesignLibrarySavedStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useModal } from "@editor/hooks/useModal";
import {
  selectColorGradientDesignLibrary,
  selectDraftComponent,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { getSavedStyleValue } from "@editor/utils/designLibrary";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { useInCanvasPreview } from "@editor/utils/preview";
import { DynamicDataValueIndicator } from "@editorExtras/DynamicDataValueIndicator";
import { ColorSelector } from "@editorModifiers/ColorModifier";
import { hasDynamicData } from "@editorModifiers/utils";

import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { isDynamicDesignLibraryValue } from "replo-runtime/shared/utils/designLibrary";
import { cssGradientToGradient } from "replo-runtime/shared/utils/gradient";
import { hasOwnProperty, isNotNullish } from "replo-utils/lib/misc";

type DynamicColorModifierProps = {
  gradientSelectionType: "color" | "backgroundColor" | null;
  field: string;
  value?: string;
  previewProperty?: PreviewableProperty;
  onDragStart?(e: React.MouseEvent): void;
  onDragEnd?(e: React.MouseEvent): void;
  popoverTitle?: string;
  gradientData?: Gradient;
  popoverSideOffset?: number;
  isPopoverOpen?: boolean;
  onOpenPopoverChange?(isOpen: boolean): void;
  showSavedStyles?: boolean;
};

export type GradientOrSolidModifierProps =
  | {
      allowsGradientSelection: false;
    }
  | {
      allowsGradientSelection: true;
      gradientSelectionType: "color" | "backgroundColor";
    };

type DynamicColorSelectorProps = {
  hasDynamicData?: boolean;
  field: string;
  previewProperty?: PreviewableProperty;
  openDynamicData?: (attribute: string) => void;
  onRemove?(): void;
  popoverTitle?: string;
  onDragStart?(e: React.MouseEvent): void;
  onDragEnd?(e: React.MouseEvent): void;
  componentId: string;
  popoverSideOffset?: number;
  isPopoverOpen?: boolean;
  onOpenPopoverChange?(isOpen: boolean): void;
  showSavedStyles?: boolean;
  onSavedStyleSelect?: (value: string) => void;
} & GradientOrSolidOnChangeProps;

export const DynamicColorSelector = (props: DynamicColorSelectorProps) => {
  const isDraggingRef = React.useRef(false);
  const applyComponentAction = useApplyComponentAction();
  const {
    enableCanvasPreviewCSSProperties,
    disableCanvasPreviewCSSProperties,
    setPreviewCSSPropertyValue,
  } = useInCanvasPreview();

  const colorGradientDesignLibrary = useEditorSelector(
    selectColorGradientDesignLibrary,
  );

  const { colorSavedStyles } = useGetDesignLibrarySavedStyles();

  const attribute = props.field.replace("style.", "");
  const value =
    props.value != null &&
    typeof props.value === "object" &&
    props.value.type === "solid"
      ? props.value.color
      : props.value;

  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleColorAttributes>(
      String(value) ?? null,
    )?.color;

  const onSavedStyleSelect =
    props.onSavedStyleSelect ??
    ((value: string) => {
      const savedStyle = getSavedStyleValue(colorSavedStyles, value);
      if (savedStyle?.attributes.color?.includes("linear-gradient")) {
        applyComponentAction({
          type: "setStyles",
          value: {
            [attribute]: "alchemy:gradient",
            __reploGradient__color__design_library: value,
          },
        });
      } else {
        applyComponentAction({
          type: "setStyles",
          value: {
            [attribute]: value,
          },
        });
      }
    });

  // Note (Noah, 2022-03-14): We need this useEffect to disable the canvas preview
  // for colors because the color modifier is debounced - if we didn't have this
  // and just disabled the preview on dragEnd like normal, then there would be a time
  // before the debounced updated processed but after the preview was disabled where
  // we'd actually show the old color value. Instead, we just disable the preview
  // any time the value changes (i.e., when the debounce processes), unless the user
  // is currently dragging
  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  React.useEffect(() => {
    if (!isDraggingRef.current && props.previewProperty) {
      disableCanvasPreviewCSSProperties([props.previewProperty]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // NOTE (Fran 2024-11-29): We know that if the saved style value is undefined or null, the saved style
  // is not deleted, and we need to show it as it is.
  const isSavedStyleNotDeleted = !Boolean(deletedSavedStyleValue);
  const isGradientFromDesignLibrary =
    typeof value === "object" &&
    value?.type === "gradient" &&
    Boolean(colorGradientDesignLibrary);
  const isColorFromDesignLibrary =
    typeof value === "string" && isDynamicDesignLibraryValue(value);

  if (
    isSavedStyleNotDeleted &&
    (isColorFromDesignLibrary || isGradientFromDesignLibrary)
  ) {
    // NOTE (Fran 2024-11-27): If the color is from the design library, we need to
    // show the saved style value reference instead of the value itself.
    const savedStyleValueReference = isGradientFromDesignLibrary
      ? colorGradientDesignLibrary!
      : (value as string);

    return (
      <DesignLibraryColorValueIndicator
        savedStyleValueReference={savedStyleValueReference}
        onChange={(value: string | SolidOrGradient) => {
          if (props.allowsGradientSelection) {
            props.onChange(value as SolidOrGradient);
          } else {
            props.onChange((value as string) ?? null);
          }
        }}
        allowsGradientSelection={props.allowsGradientSelection}
        onRemove={props.onRemove}
        onSavedStyleSelect={onSavedStyleSelect}
        openDynamicData={() => props.openDynamicData?.(attribute)}
        popoverSideOffset={84}
      />
    );
  }

  // Note (Noah, 2022-03-15): If the value contains handlebars, that means it's a
  // dynamic data value (the user set it from a data table or something instead of
  // specifying a color manually), so render a dynamic data indicator
  if (typeof value === "string" && value?.includes("{{")) {
    return (
      <DynamicDataValueIndicator
        type="color"
        templateValue={value}
        onClick={() => {
          props.openDynamicData?.(attribute);
        }}
        onRemove={() => props.onRemove?.()}
        componentId={props.componentId}
      />
    );
  }

  const dragProps = {
    onDragEnd: (e: React.MouseEvent) => {
      isDraggingRef.current = false;
      props.onDragEnd?.(e);
    },
    onDragStart: (e: React.MouseEvent) => {
      isDraggingRef.current = true;

      if (
        props.previewProperty &&
        isNotNullish(value) &&
        typeof value === "string"
      ) {
        enableCanvasPreviewCSSProperties([props.previewProperty], value);
        setPreviewCSSPropertyValue([props.previewProperty], value);
      }

      props.onDragStart?.(e);
    },
    onPreviewChange: (value: any) => {
      if (typeof value === "string") {
        if (props.previewProperty && value) {
          setPreviewCSSPropertyValue([props.previewProperty], value);
        }
      } else if (
        hasOwnProperty(value, "type") &&
        hasOwnProperty(value, "color") &&
        props.previewProperty
      ) {
        setPreviewCSSPropertyValue([props.previewProperty], value.color);
      }
      props.onPreviewChange?.(value);
    },
  };

  if (props.allowsGradientSelection) {
    const propertyValue = deletedSavedStyleValue
      ? (getColorValueFromSavedStyles(
          deletedSavedStyleValue,
          value,
        ) as SolidOrGradient | null)
      : props.value;
    return (
      <ColorSelector
        isPopoverOpen={props.isPopoverOpen}
        onOpenPopoverChange={props.onOpenPopoverChange}
        {...dragProps}
        value={propertyValue}
        allowsGradientSelection
        onChange={props.onChange}
        openDynamicData={() => props.openDynamicData?.(attribute)}
        popoverTitle={props.popoverTitle}
        onRemove={() => props.onRemove?.()}
        popoverSideOffset={props.popoverSideOffset}
        onSavedStyleSelect={onSavedStyleSelect}
        showSavedStyles={props.showSavedStyles}
      />
    );
  }

  const propertyValue = deletedSavedStyleValue
    ? (getColorValueFromSavedStyles(deletedSavedStyleValue, value) as
        | string
        | null)
    : props.value;

  return (
    <ColorSelector
      isPopoverOpen={props.isPopoverOpen}
      onOpenPopoverChange={props.onOpenPopoverChange}
      {...dragProps}
      value={propertyValue}
      allowsGradientSelection={false}
      onChange={props.onChange}
      openDynamicData={() => props.openDynamicData?.(attribute)}
      popoverTitle={props.popoverTitle}
      popoverSideOffset={props.popoverSideOffset}
      onSavedStyleSelect={onSavedStyleSelect}
      showSavedStyles={props.showSavedStyles}
    />
  );
};

const DynamicColorModifier: React.FC<
  React.PropsWithChildren<
    DynamicColorModifierProps & { onChange: Function; onRemove?(): void }
  >
> = ({
  gradientSelectionType,
  previewProperty,
  onDragEnd,
  onDragStart,
  onRemove,
  popoverTitle,
  field,
  value,
  onChange,
  gradientData,
  popoverSideOffset,
  isPopoverOpen,
  onOpenPopoverChange,
  showSavedStyles,
}) => {
  const draftComponent = useEditorSelector(selectDraftComponent);
  const applyComponentAction = useApplyComponentAction();
  const modal = useModal();

  const attribute = field.replace("style.", "");

  if (!draftComponent) {
    return null;
  }

  const openDynamicData = (attribute: string) => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.TEXT_COLOR,
        referrerData: {
          type: "style",
          styleAttribute: attribute,
        },
        initialPath: getPathFromVariable(value),
      },
    });
  };

  const handleRemove = (attribute: string) => {
    applyComponentAction({
      type: "setStyles",
      value: {
        [attribute]: null,
        // NOTE (Fran 2024-11-27): Remove gradient data when remove color
        __alchemyGradient__color__tilt: null,
        __alchemyGradient__color__stops: null,
        __reploGradient__color__design_library: null,
      },
    });
  };

  if (gradientSelectionType !== null) {
    return (
      <DynamicColorSelector
        isPopoverOpen={isPopoverOpen}
        onOpenPopoverChange={onOpenPopoverChange}
        previewProperty={previewProperty}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        hasDynamicData={hasDynamicData(draftComponent?.id)}
        field={field}
        value={
          value === "alchemy:gradient"
            ? {
                type: "gradient",
                gradient: gradientData!,
              }
            : { type: "solid", color: value! }
        }
        allowsGradientSelection={true}
        onChange={(value: any) => onChange?.(value)}
        onRemove={() => {
          if (onRemove) {
            onRemove();
          } else {
            handleRemove(attribute);
          }
        }}
        openDynamicData={openDynamicData}
        popoverTitle={popoverTitle}
        componentId={draftComponent.id}
        popoverSideOffset={popoverSideOffset}
        showSavedStyles={showSavedStyles}
      />
    );
  }
  return (
    <DynamicColorSelector
      isPopoverOpen={isPopoverOpen}
      onOpenPopoverChange={onOpenPopoverChange}
      previewProperty={previewProperty}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      hasDynamicData={hasDynamicData(draftComponent?.id)}
      field={field}
      value={value ?? null}
      allowsGradientSelection={false}
      onChange={(value: any) => onChange?.(value)}
      onRemove={() => {
        if (onRemove) {
          onRemove();
        } else {
          handleRemove(attribute);
        }
      }}
      openDynamicData={openDynamicData}
      popoverTitle={popoverTitle}
      componentId={draftComponent.id}
      popoverSideOffset={popoverSideOffset}
      showSavedStyles={showSavedStyles}
    />
  );
};

const getColorValueFromSavedStyles = (
  deletedSavedStyleValue: string,
  value: string | GradientValue | null | undefined,
) => {
  if (
    typeof value === "string" &&
    isDynamicDesignLibraryValue(value) &&
    deletedSavedStyleValue.startsWith("linear-gradient")
  ) {
    return {
      type: "gradient",
      gradient: cssGradientToGradient(deletedSavedStyleValue),
    } as GradientValue;
  }

  if (
    typeof value === "string" &&
    isDynamicDesignLibraryValue(value) &&
    deletedSavedStyleValue
  ) {
    return {
      type: "solid",
      color: deletedSavedStyleValue,
    } as SolidValue;
  }

  return value ?? null;
};

export default DynamicColorModifier;
