import * as React from "react";

import { Skeleton } from "@common/designSystem/SkeletonLoader";

import { Badge } from "@replo/design-system/components/badge";
import { BsDot, BsType } from "react-icons/bs";
import { twMerge } from "tailwind-merge";

type SavedStyleRowProps = {
  name: string;
  value: string;
  type: "text" | "color";
  endEnhancer?: React.ReactNode;
  isLoading?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
};

const SavedStyleRow: React.FC<SavedStyleRowProps> = ({
  name,
  value,
  type,
  endEnhancer,
  isSelected,
  onClick,
  isLoading = false,
}) => {
  if (isLoading) {
    return <Skeleton className="w-full h-8" />;
  }

  const isText = type === "text";

  return (
    <div
      className={twMerge(
        "flex items-center gap-2 p-2 text-xs cursor-pointer rounded group w-full justify-between hover:bg-hover",
        isSelected && "bg-selected",
      )}
      onClick={onClick}
    >
      <div className="flex items-center gap-2">
        {isText ? (
          <BsType size={16} className="text-subtle shrink-0" />
        ) : (
          <Badge backgroundColor={value} type="color" isFilled />
        )}
        <div className="flex items-center gap-1 w-full">
          <div className="inline-grid">
            <span className="truncate">{name}</span>
          </div>
          <BsDot
            className="flex justify-center items-center text-subtle"
            size={6}
          />
          <div className="inline-grid">
            <span className="text-subtle truncate">{value}</span>
          </div>
        </div>
      </div>
      <div className="invisible group-hover:visible">{endEnhancer}</div>
    </div>
  );
};

export default SavedStyleRow;
