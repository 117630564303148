import type { Component } from "schemas/component";
import type { RenderComponentProps } from "../../../shared/types";
import type { ActionType } from "./config";

import * as React from "react";

import classNames from "classnames";

import { mergeContext } from "../../../shared/utils/context";
import { ReploComponent } from "../ReploComponent";

function SlidingCarousel(props: RenderComponentProps) {
  const _handleScroll = (e: any) => {
    const children = e.target.children;
    setIsShowingLeftArrow(e.target.scrollLeft > 0);

    const childRight =
      children[children.length - 1].getBoundingClientRect().right;

    setIsShowingRightArrow(childRight > e.target.scrollLeft);
  };
  const [isShowingLeftArrow, setIsShowingLeftArrow] = React.useState(false);
  const [isShowingRightArrow, setIsShowingRightArrow] = React.useState(true);

  const template = props.component.children?.[0];
  if (!template) {
    return null;
  }

  const actionHooks = {
    scrollContainerLeft: (pixels: number) => {
      const selector = `[data-rid="${template?.id}"]`;
      const scrollingElement =
        props.componentAttributes.ref.current?.querySelector(selector);
      scrollingElement.scroll({
        left: scrollingElement.scrollLeft - pixels,
        behavior: "smooth",
      });
    },
    scrollContainerRight: (pixels: number) => {
      const selector = `[data-rid="${template?.id}"]`;
      const scrollingElement =
        props.componentAttributes.ref.current?.querySelector(selector);
      scrollingElement.scroll({
        left: scrollingElement.scrollLeft + pixels,
        behavior: "smooth",
      });
    },
  } satisfies {
    [K in ActionType]: Function;
  };

  const newContext = mergeContext(props.context, {
    actionHooks,
  });

  const showArrowsOnlyOnHover =
    props.component.props["_showArrowsOnlyOnHover"] || false;
  const hideArrowsBasedOnScrollPosition =
    props.component.props["_hideArrowsBasedOnScrollPosition"] || false;

  const leftArrowComponent = props.component.props["_leftButton"] as Component;
  const isLeftArrowHidden =
    !isShowingLeftArrow && hideArrowsBasedOnScrollPosition;
  const rightArrowComponent = props.component.props[
    "_rightButton"
  ] as Component;
  const isRightArrowHidden =
    !isShowingRightArrow && hideArrowsBasedOnScrollPosition;

  return (
    <div {...props.componentAttributes}>
      {/*
        EXTREME HACK (Noah, 2021-06-14): Ideally we want :hover { .show-only-on-hover { opacity: 1 } }
        but there's no way in Radium to style based on a pseudo-selector of another element, see
        https://github.com/FormidableLabs/radium/issues/105 - there might be some way to do this
        with styled-components or emotion but we don't want those in the store bundle ideally.
        Just regular css works for now!
        */}
      {showArrowsOnlyOnHover && (
        <style
          dangerouslySetInnerHTML={{
            // Note (Noah, 2023-06-30): The carousel-button class here overrides
            // the z-index which is set in the editor, but since this component
            // is a legacy component and only used on Studs, I decided to not try
            // to fit it into the styleElements configuration since
            // currently we don't have a way to inject defaultStyles for child components.
            __html: `
            .show-only-on-hover { opacity: 0 }
            [data-rid='${props.component.id}']:hover .show-only-on-hover.is-shown { opacity: 1 }
            .replo-carousel-button { z-index: 2}
          `,
          }}
        />
      )}

      {leftArrowComponent && (
        <ReploComponent
          {...props}
          component={leftArrowComponent}
          context={newContext}
          extraAttributes={{
            className: classNames("show-only-on-hover replo-carousel-button", {
              "is-shown": !isLeftArrowHidden,
            }),
          }}
          repeatedIndexPath={props.context.repeatedIndexPath ?? ".0"}
        />
      )}
      {rightArrowComponent && (
        <ReploComponent
          {...props}
          component={rightArrowComponent}
          context={newContext}
          extraAttributes={{
            className: classNames("show-only-on-hover replo-carousel-button", {
              "is-shown": !isRightArrowHidden,
            }),
          }}
          repeatedIndexPath={props.context.repeatedIndexPath ?? ".0"}
        />
      )}
      <ReploComponent
        {...props}
        component={template}
        context={newContext}
        extraAttributes={{ onScroll: _handleScroll }}
        repeatedIndexPath={props.context.repeatedIndexPath ?? ".0"}
      />
    </div>
  );
}

export default SlidingCarousel;
