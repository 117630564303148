import * as React from "react";

import { KlaviyoIdentifiersContextProvider } from "./KlaviyoIdentifiers/provider";
import { RuntimeActiveStateContextProvider } from "./RuntimeActiveState/provider";

export function RuntimeContexts({ children }: { children: React.ReactNode }) {
  return (
    <RuntimeActiveStateContextProvider>
      <KlaviyoIdentifiersContextProvider>
        {children}
      </KlaviyoIdentifiersContextProvider>
    </RuntimeActiveStateContextProvider>
  );
}
