import * as React from "react";

import SetupWindows from "@/features/experiments/assets/images/SetupWindows";
import { CreateExperimentButton } from "@/features/experiments/components/CreateExperimentButton";

export const ExperimentsSetup = () => {
  return (
    <div className="h-[70vh] flex flex-col items-center justify-center text-center">
      <div className="h-full max-w-[436px] gap-6 flex flex-col items-center justify-center -ml-6">
        <SetupWindows />
        <div className="text-xl font-semibold">
          Launch Your First Experiment
        </div>
        <div className="text-slate-500 text-sm font-normal">
          Optimize your pages effortlessly by testing different versions. Set
          up, split traffic, and see results—all in just a few clicks.
        </div>
        <CreateExperimentButton />
      </div>
    </div>
  );
};

export default ExperimentsSetup;
