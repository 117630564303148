/**
 * Helper which returns correctly typed object values of a given record.
 */
export function recordValues<T>(record: Record<string, T>): T[] {
  return Object.values(record);
}

/**
 * Helper which returns correctly typed object keys of a given record.
 */
export function recordKeys<T extends string | number | symbol>(
  record: Record<T, any>,
): T[] {
  return Object.keys(record) as T[];
}

type NonNullValues<T> = {
  [K in keyof T]: NonNullable<T[K]>;
};

export function filterNullValues<T extends object>(obj: T): NonNullValues<T> {
  const result: any = {};

  for (const key in obj) {
    if (obj[key] !== null) {
      result[key] = obj[key];
    }
  }

  return result;
}

export function recordEntries<Key extends string | number | symbol, Value>(
  record: Record<Key, Value>,
): [Key, Value][] {
  return Object.entries(record) as [Key, Value][];
}
