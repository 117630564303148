import type { SvgProps } from "replo-utils/react/components";

import * as React from "react";

import { Svg } from "replo-utils/react/components";

export const SvgLogoLoading = (props: SvgProps) => (
  <Svg width="1em" height="1em" viewBox="0 0 109 110" {...props}>
    <path d="M0 109.404h108.702V.702a108.597 108.597 0 0 0-41.597 8.265 108.523 108.523 0 0 0-35.249 23.564A108.84 108.84 0 0 0 0 109.404Z" />
  </Svg>
);
export default SvgLogoLoading;
