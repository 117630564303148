import * as React from "react";

import Avatar from "@editor/components/common/designSystem/Avatar";
import Input from "@editor/components/common/designSystem/Input";
import Popover from "@editor/components/common/designSystem/Popover";
import { Skeleton } from "@editor/components/common/designSystem/SkeletonLoader";
import { SidebarWorkspacesSkeleton } from "@editor/components/dashboard/SkeletonLoaders";
import { WorkspaceCardSkeleton } from "@editor/components/editor/SkeletonLoaders";
import { useWorkspaceDashboardContext } from "@editor/contexts/WorkspaceDashboardContext";
import { useSubscriptionInfo } from "@editor/hooks/subscription";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { routes } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";

import {
  BsChevronExpand,
  BsGrid,
  BsGridFill,
  BsPlusLg,
  BsSearch,
} from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDebouncedState } from "replo-utils/react/use-debounced-state";
import { twMerge } from "tailwind-merge";

type CardProps = {
  workspaceName: string;
  endEnhancer?: React.ReactNode;
  className?: string;
  disableConditionalStyles?: boolean;
  isEmptyWorkspaces?: boolean;
  isPopoverOpen?: boolean;
};

const EMPTY_ARRAY: any[] = [];

const WorkspaceCard: React.FC = () => {
  const location = useLocation();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [searchStringInputValue, searchString, setSearchString] =
    useDebouncedState("");
  const logEvent = useLogAnalytics();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { data, isPending: isLoading } =
    trpc.workspace.getUserWorkspacesList.useQuery();
  const { setWorkspaceId, workspaceId } = useWorkspaceDashboardContext();
  const workspaces = data?.workspaces ?? EMPTY_ARRAY;
  const currentWorkspaceName = React.useMemo(
    () => workspaces.find((workspace) => workspace.id === workspaceId)?.name,
    [workspaceId, workspaces],
  );
  const isAllProjectsView = location.pathname.startsWith(routes.allProjects);
  const filteredWorkspaces = React.useMemo(() => {
    return workspaces.filter((workspace) => {
      return (
        workspace.name.toLowerCase().includes(searchString.toLowerCase()) &&
        // NOTE (Sebas, 2024-12-12): If we are in the all projects view, we want
        // to show all workspaces, including the current workspace.
        (workspace.id !== workspaceId || isAllProjectsView)
      );
    });
  }, [workspaces, searchString, workspaceId, isAllProjectsView]);

  const isEmptyWorkspaces = filteredWorkspaces.length === 0;
  const uniqueWorkspace = workspaces.length === 1;
  const showSearchInput = workspaces.length <= 5;

  const onWorkspaceClick = (workspaceId: string, workspaceName: string) => {
    setWorkspaceId(workspaceId);
    navigate(`/workspace/${workspaceId}/projects`);
    setIsPopoverOpen(false);
    logEvent("dashboard.workspace.switch", {
      toWorkspaceName: workspaceName,
      toWorkspaceId: workspaceId,
    });
  };

  const onPopoverOpenChange = (isOpen: boolean) => {
    setIsPopoverOpen(isOpen);
    setSearchString("");
  };

  return (
    <Popover isOpen={isPopoverOpen} onOpenChange={onPopoverOpenChange}>
      <Popover.Content
        shouldPreventDefaultOnInteractOutside={false}
        side="bottom"
        fromYTransform={-4}
        sideOffset={4}
        hideCloseButton
        align="start"
        className="p-0 w-[233px] border-0.5"
      >
        <div className="flex flex-col">
          {!uniqueWorkspace ||
          (uniqueWorkspace && workspaceId !== workspaces[0]?.id) ? (
            <div className="flex flex-col">
              {!showSearchInput ? (
                <div className="flex flex-col gap-2 p-3 border-b-0.5 border-border">
                  <Input
                    size="base"
                    placeholder="Search"
                    type="text"
                    value={searchStringInputValue}
                    onChange={(e) => setSearchString(e.target.value ?? "")}
                    startEnhancer={() => (
                      <BsSearch className="text-xs text-subtle" />
                    )}
                  />
                </div>
              ) : null}
              <div
                className={twMerge(
                  "flex flex-col max-h-44 px-3 py-1.5 gap-0.5",
                  // NOTE (Sebas, 2024-12-09): We need to add the scrollbar classes only if there are more than
                  // 5 workspaces, otherwise the paddings will not be correct.
                  workspaces.length > 5 &&
                    "overflow-y-scroll styled-scrollbar pr-0",
                )}
                aria-label="workspaces"
              >
                {!isLoading &&
                  filteredWorkspaces.map((workspace, i) => (
                    <div
                      key={i}
                      className="flex gap-2 py-1.5 px-2 items-center cursor-pointer hover:bg-menu-item-hover rounded"
                      onClick={() =>
                        onWorkspaceClick(workspace.id, workspace.name)
                      }
                    >
                      <Avatar
                        name={workspace.name}
                        size="xs"
                        className="rounded-md"
                        imageClassName="bg-dark-contrast text-[10px]"
                      />
                      <div className="typ-body-base text-default inline-grid">
                        <span className="truncate">{workspace.name}</span>
                      </div>
                    </div>
                  ))}
                {!isLoading && isEmptyWorkspaces && (
                  <p className="text-xs py-2 text-subtle font-normal">
                    No workspaces found.{" "}
                    {workspaces.length > 0
                      ? "Try searching for a different workspace or ensuring you have access to the workspace you’re searching for."
                      : "Ensure you have access to a workspace or connect a Shopify Store."}
                  </p>
                )}
                {isLoading &&
                  Array.from({ length: 5 }).map((_, i) => (
                    <SidebarWorkspacesSkeleton key={i.toString()} />
                  ))}
              </div>
            </div>
          ) : null}
          <div className="flex px-3 py-2 text-default border-t-0.5 border-border">
            <Link
              className="flex items-center gap-2 px-2 py-1.5 typ-button-base h-8 w-full hover:bg-menu-item-hover rounded"
              to={routes.allProjects}
            >
              <div className="h-5 w-5 flex justify-center items-center">
                <BsGrid size={16} />
              </div>
              All Projects
            </Link>
          </div>
          <div
            className={twMerge(
              "flex px-3 py-2 text-primary",
              !uniqueWorkspace && "border-t-0.5 border-border",
            )}
          >
            <button
              className="flex items-center gap-2 px-1 py-1.5 typ-button-base h-8 w-full hover:bg-button-link-hover rounded"
              onClick={() => openModal({ type: "addWorkspaceModal" })}
            >
              <div className="h-4 w-4 flex justify-center items-center">
                <BsPlusLg size={16} />
              </div>
              Add new workspace
            </button>
          </div>
        </div>
      </Popover.Content>
      <Popover.Trigger className="w-full" aria-label="select workspace">
        {isLoading ? (
          <WorkspaceCardSkeleton />
        ) : (
          <Card
            workspaceName={currentWorkspaceName}
            isEmptyWorkspaces={workspaces.length === 0}
            endEnhancer={<BsChevronExpand size={16} className="text-default" />}
            isPopoverOpen={isPopoverOpen}
          />
        )}
      </Popover.Trigger>
    </Popover>
  );
};

const Card: React.FC<CardProps> = ({
  workspaceName,
  endEnhancer,
  className,
  disableConditionalStyles,
  isEmptyWorkspaces,
  isPopoverOpen,
}) => {
  const location = useLocation();
  const { user } = useCurrentUser();
  const workspaceId = useCurrentWorkspaceId();
  const { from } = location.state ?? {};
  const {
    subscriptionInfo: { tier },
    isLoading,
  } = useSubscriptionInfo(workspaceId ?? undefined);

  if (!user) {
    return null;
  }

  const isAllProjectsView =
    location.pathname.startsWith(routes.allProjects) || from === "allProjects";
  const getCardTitle = () => {
    if (isAllProjectsView) {
      return "All Projects";
    }
    if (workspaceName) {
      return workspaceName;
    } else if (isEmptyWorkspaces) {
      return "No Workspaces";
    }
    return "Select a Workspace";
  };

  return (
    <div
      className={twMerge(
        "flex flex-row items-center gap-2 p-2 rounded-md",
        !disableConditionalStyles &&
          "hover:bg-button-tertiary-hover cursor-pointer",
        isPopoverOpen && "bg-button-tertiary-hover",
        className,
      )}
    >
      <Avatar
        name={workspaceName}
        icon={isAllProjectsView ? <BsGridFill size={16} /> : undefined}
        isSquare
        size="base"
        className="rounded-lg"
        imageClassName="bg-dark-contrast"
      />
      <div className="flex flex-col w-full text-left">
        <div className="text-sm font-semibold w-full text-default inline-grid">
          <span className="truncate">{getCardTitle()}</span>
        </div>
        <div className="inline-grid typ-body-small text-muted">
          {isAllProjectsView ? (
            "Owned or shared"
          ) : (
            <span className="truncate capitalize">
              {isLoading ? <Skeleton className="h-4 w-24" /> : tier}
            </span>
          )}
        </div>
      </div>
      <div className="shrink-0">{endEnhancer}</div>
    </div>
  );
};

export default WorkspaceCard;
