import type {
  RenderComponentProps,
  ReploShopifyVariant,
} from "../../../shared/types";

import * as React from "react";

import { useKlaviyoIdentifiersStore } from "replo-runtime/store/contexts/KlaviyoIdentifiers/context";
import { useRuntimeActiveStateStore } from "replo-runtime/store/contexts/RuntimeActiveState/hooks";
import { isEmpty } from "replo-utils/lib/misc";

import {
  RenderEnvironmentContext,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { withLiquidAlternate } from "../../../shared/utils/withLiquidAlternate";
import { executeAction } from "../../AlchemyAction";
import ReploLiquidChunk from "../ReploLiquid/ReploLiquidChunk";

const VariantSelectDropdown: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
  context,
}) => {
  const defaultText = component.props._defaultText;
  const noDefaultText = isEmpty(defaultText);
  const variants: ReploShopifyVariant[] = context.attributes?._variants ?? [];

  const [hasEverSelected, setHasEverSelected] = React.useState(false);

  const selectedVariants = component.props._productVariants;
  const filteredVariants = variants.filter((variant) => {
    return !selectedVariants || selectedVariants.includes(variant.id);
  });

  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const { templateProduct } = useRuntimeContext(ShopifyStoreContext);
  const products = useRuntimeContext(RuntimeHooksContext).useShopifyProducts();
  const runtimeActiveStateStore = useRuntimeActiveStateStore();
  const klaviyoIdentifiersStore = useKlaviyoIdentifiersStore();

  if (!isEditorApp && filteredVariants.length === 0) {
    return null;
  }

  const getCurrentValue = () => {
    if (!hasEverSelected && defaultText) {
      return defaultText;
    }

    const selectedVariant = context.state.product?.selectedVariant;

    if (!hasEverSelected && noDefaultText) {
      return selectedVariant?.id ?? undefined;
    }

    return hasEverSelected ? selectedVariant?.id ?? undefined : defaultText;
  };

  return (
    <select
      {...componentAttributes}
      role="listbox"
      value={getCurrentValue()}
      data-replo-variant-select-dropdown
      // Note (Noah, 2024-09-11, USE-1209): Stop propagation on click so that any
      // interactions on parent containers don't get triggered
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        const { value } = e.target;
        void executeAction(
          {
            id: `alchemy:selectVariant`,
            type: "setActiveVariant",
            value: { variantId: value },
          },
          {
            componentId: component.id,
            componentContext: context,
            repeatedIndex: context.repeatedIndexPath,
            products,
            templateProduct,
            stores: {
              runtimeActiveState: runtimeActiveStateStore,
              klaviyoIdentifiers: klaviyoIdentifiersStore,
            },
          },
        );
        setHasEverSelected(true);
      }}
    >
      {!hasEverSelected && defaultText && filteredVariants.length > 0 && (
        <option value={defaultText} key={defaultText} role="option">
          {defaultText}
        </option>
      )}
      {filteredVariants.map((variant) => (
        <option value={variant.id} key={variant.id} role="option">
          {variant.title}
        </option>
      ))}
    </select>
  );
};

const VariantSelectDropdownLiquid: React.FC<RenderComponentProps> = (props) => {
  const { componentAttributes, component } = props;
  const defaultText = component.props._defaultText;
  const selectedVariants = component.props._productVariants?.join(",");

  return (
    <ReploLiquidChunk>
      {`{% if product.variants[1] %}`}
      {`{% capture selectedVariantIds %}${
        selectedVariants ?? ""
      }{% endcapture %}`}
      <select
        {...componentAttributes}
        role="listbox"
        value={defaultText}
        data-replo-variant-select-dropdown
      >
        {defaultText && (
          <>
            {`{% if product.variants[0] != blank %}`}
            <option value={defaultText} key={defaultText} role="option">
              {defaultText}
            </option>
            {`{% endif %}`}
          </>
        )}
        {`{% for reploRepeatedVariant in product.variants %}`}
        {`{% if selectedVariantIds == blank or selectedVariantIds contains reploRepeatedVariant.id %}`}
        {`{% if reploSelectedVariant == blank or reploRepeatedVariant == reploSelectedVariant %}`}
        <option
          value="{{ reploRepeatedVariant.id }}"
          key="{{ reploRepeatedVariant.id }}"
          role="option"
          selected
        >
          {`{{ reploRepeatedVariant.title }}`}
        </option>
        {`{% else %}`}
        <option
          value="{{ reploRepeatedVariant.id }}"
          key="{{ reploRepeatedVariant.id }}"
          role="option"
        >{`{{ reploRepeatedVariant.title }}`}</option>
        {`{% endif %}`}
        {`{% endif %}`}
        {`{% endfor %}`}
      </select>
      {`{% endif %}`}
    </ReploLiquidChunk>
  );
};

export default withLiquidAlternate(
  VariantSelectDropdown,
  VariantSelectDropdownLiquid,
);
