import type { FeatureName } from "replo-utils/lib/featureFlags";

import { initializePosthogIfNeeded } from "@infra/analytics";

import posthog from "posthog-js";
import { isUnitTest } from "replo-runtime/shared/env";
import { featureFlags } from "replo-utils/lib/featureFlags";
import { isNotNullish } from "replo-utils/lib/misc";

export function isFeatureEnabled(featureName: FeatureName) {
  initializePosthogIfNeeded();
  // Note (Sebas, 2023-04-05): In case we are in a unit test, we don't want to
  // use any feature flags, so we return false.
  if (isUnitTest()) {
    return false;
  }

  // Note (Noah, 2024-11-14): Disable all announcements in e2e tests
  if (
    window.localStorage.getItem("REPLO_IS_E2E_TEST") &&
    featureName.startsWith("announcement_")
  ) {
    return false;
  }

  const featureFlagOverrides:
    | { [key in FeatureName]?: boolean }
    // @ts-expect-error
    | undefined = window.E2E_FEATURE_FLAG_OVERRIDES;
  if (featureFlagOverrides) {
    const override = featureFlagOverrides[featureName];
    if (isNotNullish(override)) {
      return override;
    }
  }

  return Boolean(posthog?.isFeatureEnabled(featureName, { send_event: false }));
}

export function enableFeatureFlag(featureName: FeatureName) {
  initializePosthogIfNeeded();
  return posthog.featureFlags.override({
    ...Object.fromEntries(featureFlags.map((f) => [f, isFeatureEnabled(f)])),
    [featureName]: true,
  });
}

export function disableFeatureFlag(featureName: FeatureName) {
  initializePosthogIfNeeded();
  return posthog.featureFlags.override({
    ...Object.fromEntries(featureFlags.map((f) => [f, isFeatureEnabled(f)])),
    [featureName]: false,
  });
}

export function clearFeatureFlagOverrides() {
  initializePosthogIfNeeded();
  return posthog.featureFlags.override(false);
}

function startsWithAnnouncement(
  featureName: string,
): featureName is `announcement_${string}` {
  return featureName.startsWith("announcement_");
}

export function getAnnouncementFeatureFlags() {
  initializePosthogIfNeeded();
  return (
    posthog?.featureFlags
      .getFlags()
      .filter(
        (flag) => startsWithAnnouncement(flag) && isFeatureEnabled(flag),
      ) ?? []
  );
}

// TODO (Fran 2024-09-20): Remove after the Library Project is done
// https://linear.app/replo/issue/REPL-13651/remove-all-feature-flags
export const isSavedStylesEnabled = () => isFeatureEnabled("saved-styles");
