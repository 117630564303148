import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const productCollection: ComponentTemplate = {
  id: "7102506c-12bb-44c9-8094-4490918946a7",
  storeId: null,
  scope: "left-bar",
  type: "component",
  env: Env.PRODUCTION,
  name: "Product/Product Collection",
  leftBarCategory: { main: "product", subCategory: "productLayouts" },
  preview: "product/collection.png",
  transforms: [
    (component, _parent, _element, extras) => {
      if (!extras.productResolutionDependencies) {
        return component;
      }

      const products = extras.productResolutionDependencies.products || [];
      if (products && products.length >= 4) {
        const productsForCollection = products
          .slice(0, 4)
          .map((product: any) => {
            return {
              // @ts-ignore
              productId: product.id,
              variantId:
                product.variants?.length > 0 ? product.variants[0].id : null,
            };
          });
        // @ts-ignore
        component.props["_products"] = productsForCollection;
      }
      return component;
    },
  ],
  template: {
    id: "b5415adc-8252-40d3-9334-e4154e78a680",
    name: "Product Collection  2",
    type: "productCollection",
    props: {
      style: {
        display: "grid",
        __flexGap: 0,
        alignSelf: "stretch",
        alignItems: "center",
        paddingTop: "24px",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingBottom: "24px",
        justifyContent: "flex-start",
        gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        __numberOfColumns: 4,
        columnGap: "30px",
        rowGap: "30px",
      },
      _products: [
        {
          id: 9_865_947_202,
          productId: 7_912_740_716_781,
          variantId: 43_258_711_408_877,
        },
        {
          id: 6_487_448_801,
          productId: 7_912_747_860_205,
          variantId: 43_258_718_617_837,
        },
        {
          id: 4_734_891_203,
          productId: 7_912_923_562_221,
          variantId: 43_258_891_469_037,
        },
        {
          id: 7_750_392_885,
          productId: 7_913_116_926_189,
          variantId: 43_259_017_396_461,
        },
      ],
      "style@md": {
        display: "grid",
        __numberOfColumns: 2,
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      },
      "style@sm": {
        display: "grid",
        __numberOfColumns: 1,
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
      },
    },
    children: [
      {
        id: "42071aca-69f2-4d4c-a7a0-068c9be41094",
        name: "PRODUCT TEMPLATE",
        type: "product",
        props: {
          style: {
            __width: "auto",
            display: "flex",
            flexGrow: 1,
            __flexGap: "20px",
            alignItems: "flex-start",
            flexDirection: "column",
            justifyContent: "space-between",
            alignSelf: "stretch",
          },
          _product: { ref: "attributes._product", type: "contextRef" },
          "style@md": {
            width: "auto",
            display: "flex",
            flexGrow: 1,
            alignSelf: "stretch",
            flexBasis: "auto",
            alignItems: "flex-start",
            flexDirection: "column",
            justifyContent: "center",
            height: "auto",
          },
          "style@sm": { flexGrow: 1, flexBasis: "auto" },
        },
        children: [
          {
            id: "22080f4c-e1fe-4fce-bb85-eecbf1c85f2a",
            name: "Image",
            type: "image",
            props: {
              style: {
                width: "100%",
                height: "auto",
                __width: "100%",
                __height: "auto",
                flexGrow: "unset",
                alignSelf: "start",
                objectFit: "contain",
                __imageSource: "{{attributes._product.featured_image}}",
              },
              loading: "lazy",
            },
          },
          {
            id: "8419034d-314d-4ee6-af0c-20420b5048a5",
            type: "container",
            props: {
              style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
                maxWidth: "100%",
                alignSelf: "stretch",
                __flexGap: "20px",
                flexGrow: "1",
                flexBasis: 0,
              },
            },
            children: [
              {
                id: "d427813c-bbf7-4567-b7ce-fb94b0fd1e57",
                type: "container",
                props: {
                  style: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    maxWidth: "100%",
                    alignSelf: "stretch",
                    __flexGap: "20px",
                  },
                },
                children: [
                  {
                    id: "f64a40c9-c8e5-4e06-952d-5c0395b96453",
                    name: "Container 1744",
                    type: "container",
                    props: {
                      style: {
                        display: "flex",
                        maxWidth: "100%",
                        __flexGap: "12px",
                        alignSelf: "stretch",
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      },
                      "style@md": {
                        width: null,
                        height: null,
                        flexGrow: 1,
                        flexBasis: "auto",
                      },
                    },
                    children: [
                      {
                        id: "087f58ce-fc65-437c-8847-b29815683b29",
                        type: "text",
                        props: {
                          text: "<p>{{attributes._product.title}}</p>",
                          style: {
                            color: "black",
                            width: null,
                            fontSize: "20px",
                            textAlign: "left",
                            fontWeight: "700",
                            lineHeight: "24px",
                            flexGrow: "1",
                            flexBasis: 0,
                          },
                        },
                      },
                      {
                        id: "46fc06b2-f187-4202-bf7a-df4be0a125bc",
                        type: "text",
                        props: {
                          text: "<p>{{attributes._variant.displayPrice}}</p>",
                          style: {
                            color: "black",
                            width: null,
                            fontSize: "16px",
                            alignSelf: "center",
                            textAlign: "left",
                            fontWeight: "700",
                            lineHeight: "20px",
                          },
                        },
                      },
                    ],
                  },
                  {
                    id: "01c0634c-290b-4acc-aab1-e80de6e560f2",
                    type: "text",
                    props: {
                      text: "{{attributes._product.description}}",
                      style: {
                        fontSize: "14px",
                        alignSelf: "flex-start",
                        lineHeight: "20px",
                      },
                      "style@md": {
                        width: null,
                        height: null,
                        flexGrow: 1,
                        flexBasis: "auto",
                      },
                    },
                  },
                ],
                name: "Container",
              },
              {
                id: "6a89838a-e279-4a63-b7cb-b1e0c50c0da2",
                type: "button",
                children: [
                  {
                    id: "9cd24dd1-79d4-4080-96c5-0a1fa3edbd3b",
                    type: "text",
                    props: {
                      text: "<p>Add To Cart</p>",
                      style: {
                        letterSpacing: "2px",
                        color: "#FFFFFF",
                        fontSize: "20px",
                        fontWeight: 400,
                        lineHeight: "20px",
                      },
                    },
                  },
                ],
                props: {
                  style: {
                    color: "#FFFFFF",
                    fontSize: "20px",
                    fontWeight: "700",
                    lineHeight: "20px",
                    paddingTop: "20px",
                    paddingLeft: "32px",
                    paddingRight: "32px",
                    letterSpacing: "2px",
                    paddingBottom: "20px",
                    backgroundColor: "#000000FF",
                  },
                  onClick: [
                    {
                      id: "9e727c10-818b-4fc4-ac3c-e64fd95ee5a8",
                      type: "addProductVariantToCart",
                      value: {
                        product: {
                          ref: "attributes._variant",
                          type: "contextRef",
                        },
                        redirectToCart: true,
                        redirectToCheckout: false,
                      },
                    },
                  ],
                  "style@md": {
                    flexGrow: "unset",
                    flexBasis: "auto",
                    height: "auto",
                  },
                },
              },
            ],
            name: "Container",
          },
        ],
      },
    ],
  },
};
