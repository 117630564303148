import type { ElementVersionRevisionHistory } from "@editor/types/core-state";
import type { ReploElementVersionRevision } from "schemas/generated/element";

import * as React from "react";

import {
  Group,
  GroupHeader,
  GroupTitle,
  GroupTitleContainer,
} from "@common/designSystem/Group";
import VersionCard, {
  formatVersionHistoryDateAndTime,
} from "@components/version-history/VersionCard";

import { BsChevronDown } from "react-icons/bs";
import { ReploElementVersionKinds } from "schemas/element";
import { twMerge } from "tailwind-merge";

import Avatar from "../common/designSystem/Avatar";

type CollapsibleVersionCardProps = {
  versionsGroup: ElementVersionRevisionHistory;
  selectedRevisionId: string | null;
  onItemClick: (item: ReploElementVersionRevision) => void;
  className?: string;
};

const AutosaveVersionsSummary = ({
  version,
}: {
  version: ReploElementVersionRevision;
}) => {
  const { createdBy, createdAt, title } = version;
  const date = formatVersionHistoryDateAndTime(createdAt);
  const userName = createdBy?.name ?? "Anonymous";
  return (
    <div className="flex flex-col ml-1.5 gap-1 p-1.5 rounded-[4px] grow corner-[6px] hover:bg-slate-50">
      <h3 className="truncate typ-header-small font-semibold text-default">
        {title}
      </h3>
      <span className="typ-body-small text-muted">{date}</span>
      <div className="flex items-center">
        <Avatar name={userName} className="mr-1" isSquare size="xxs" />
        <span className="typ-body-small text-muted">{userName}</span>
      </div>
    </div>
  );
};

export default function CollapsibleVersionCard({
  versionsGroup,
  selectedRevisionId,
  onItemClick,
  className,
}: CollapsibleVersionCardProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const title = `${versionsGroup.items.length} Autosave versions`;
  const mostRecentItem = versionsGroup.items[versionsGroup.items.length - 1]!;
  return (
    <Group
      key={versionsGroup.items[0]!.id}
      name={`${versionsGroup.items.length} Autosave versions`}
      className={twMerge("pb-6", className)}
      contentClassName="pt-0"
      header={
        <GroupHeader>
          <GroupTitleContainer
            className="grow gap-0 items-start"
            collapsibleIndicatorClassName="z-10 rounded-[4px] overflow-hidden h-[20px] w-[20px] flex items-center justify-center bg-light-surface"
            collapsibleIndicator={
              <BsChevronDown
                aria-hidden
                className="text-subtle"
                size={8}
                strokeWidth={2}
              />
            }
          >
            {isOpen ? (
              <AutosaveVersionsSummary version={{ ...mostRecentItem, title }} />
            ) : (
              <GroupTitle className="hover:bg-slate-50 ml-1.5 pl-1.5 py-1 grow corner-[6px]" />
            )}
          </GroupTitleContainer>
        </GroupHeader>
      }
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      isCollapsible
    >
      {versionsGroup.items.map((item) => {
        const cardIsActive =
          (item.kind === ReploElementVersionKinds.current &&
            !selectedRevisionId) ||
          selectedRevisionId === item.id;
        return (
          <VersionCard
            key={item.id}
            version={item}
            className="py-1"
            isActive={cardIsActive}
            isDateOnly
            onClick={() => {
              onItemClick(item);
            }}
          />
        );
      })}
    </Group>
  );
}
