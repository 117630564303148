import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const carouselWithMultipleItems: ComponentTemplate = {
  id: "bb8d9c68-7a52-420b-9328-fe8d13c391c0",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Carousel/Multi-slide Carousel",
  leftBarCategory: { main: "interactive", subCategory: "carousel" },
  preview: "carousel/multi.png",
  template: {
    id: "1bb25e71-f836-463c-848d-e0dd4d50cf91",
    name: "multi-slide carousel",
    type: "carouselV3",
    props: {
      style: {
        display: "flex",
        flexGrow: 1,
        maxWidth: "100%",
        alignSelf: "stretch",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
      _infinite: false,
      _autoWidth: true,
      _activeArea: "first",
      _mouseWheel: false,
      _itemsPerView: 1,
    },
    children: [
      {
        id: "71f394a1-17f7-40a9-8e33-601eafca2bd1",
        name: "Previous",
        type: "carouselV3Control",
        props: {
          style: {
            top: "0px",
            left: "0px",
            cursor: "pointer",
            height: "100%",
            zIndex: 1_000_000,
            display: "flex",
            __height: "375px",
            flexWrap: "wrap",
            position: "absolute",
            alignSelf: "stretch",
            alignItems: "center",
            marginLeft: "20px",
            justifyContent: "center",
            __animateVariantTransitions: true,
          },
          "style@sm": {
            top: "0px",
            left: "0px",
            marginLeft: "0px",
            paddingLeft: "24px",
            paddingBottom: "96px",
          },
          direction: "previous",
        },
        children: [
          {
            id: "5f7d24c2-4687-47ce-8f9e-5d975eb7ebd8",
            type: "icon",
            props: {
              style: {
                width: "40px",
                height: "40px",
                __width: "40px",
                __height: "40px",
                marginBottom: "96px",
              },
              iconName: "chevronLeft",
            },
            variantOverrides: {},
          },
        ],
        variants: [
          {
            id: "77b42e97-1c22-4729-b900-8caa2f8e13cd",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "27345fa7-ba81-41cb-8b4b-9746550dcd16",
            name: "Is First",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "4120cd97-420b-4283-ba4a-63ea3c472907",
                  field: "state.group.isFirstItemActive",
                  value: null,
                  operator: null,
                },
              ],
            },
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "27345fa7-ba81-41cb-8b4b-9746550dcd16": {
            componentOverrides: {
              "71f394a1-17f7-40a9-8e33-601eafca2bd1": {
                props: {
                  style: {
                    opacity: "0%",
                  },
                },
              },
            },
          },
        },
      },
      {
        id: "755a2975-a11f-4f63-81d4-431c925664c7",
        name: "Slides",
        type: "carouselV3Slides",
        props: {
          style: {
            __width: "1197px",
            display: "flex",
            flexGrow: 1,
            overflow: "hidden",
            __flexGap: "24px",
            alignSelf: "stretch",
            alignItems: "flex-start",
            marginLeft: "80px",
            justifyContent: "flex-start",
          },
          "style@sm": {
            flexWrap: "nowrap",
            __flexGap: "16px",
            marginLeft: "16px",
            marginRight: "16px",
          },
        },
        children: [
          {
            id: "331567df-2ba2-4506-bcea-f72d3abe5fc9",
            name: "Slide 1",
            type: "container",
            props: {
              style: {
                width: "400px",
                __width: "400px",
                display: "flex",
                __flexGap: "20px",
                flexBasis: 0,
                alignItems: "flex-start",
                paddingTop: "24px",
                flexDirection: "column",
                paddingBottom: "24px",
                justifyContent: "center",
              },
              "style@sm": {
                width: "300px",
                __width: "300px",
                flexGrow: "unset",
                maxWidth: "none",
                marginLeft: "0px",
              },
            },
            children: [
              {
                id: "aaf850ae-85e8-4d19-a309-025fdab60f33",
                name: "Image 1",
                type: "image",
                props: {
                  style: {
                    width: "100%",
                    __width: "100%",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1504198266287-1659872e6590?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTd8fHxlbnwwfHx8fHw%3D",
                    __imageAltText:
                      "A single green leaf against a white background.",
                  },
                  "style@sm": {
                    width: "auto",
                    alignSelf: "auto",
                  },
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
              },
              {
                id: "1f9a854b-0fc1-4e31-968d-158583b74355",
                name: "Container 1",
                type: "container",
                props: {
                  style: {
                    display: "flex",
                    maxWidth: "100%",
                    alignSelf: "stretch",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    justifyContent: "center",
                  },
                  "style@sm": {
                    marginLeft: "16px",
                    marginRight: "16px",
                  },
                },
                children: [
                  {
                    id: "80ea8221-1cab-45d5-8f13-8ac9e4be7ce2",
                    type: "text",
                    props: {
                      text: "<p>Slide Title</p>",
                      style: {
                        color: "#000000",
                        fontSize: "15px",
                        fontFamily: "Assistant, sans-serif",
                        fontWeight: "700",
                        lineHeight: "24px",
                      },
                    },
                  },
                  {
                    id: "ea83d89a-2b5b-450c-9ffd-c2a2a6217b11",
                    type: "text",
                    props: {
                      text: "<p>Description</p>",
                      style: {
                        color: "#000000",
                        fontSize: "15px",
                        fontFamily: "Assistant, sans-serif",
                        lineHeight: "24px",
                      },
                    },
                  },
                  {
                    id: "23f0b600-039b-460e-a11c-0004c3ac30f0",
                    type: "text",
                    props: {
                      text: "<p>SLIDE 1 OF 8</p>",
                      style: {
                        color: "#A8A8A8FF",
                        fontSize: "10px",
                        marginTop: "6px",
                        fontFamily: "IBM Plex Sans, sans-serif",
                        lineHeight: "24px",
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            id: "374c25f4-3a8f-495c-a6db-427c0eae8ca9",
            name: "Slide 2",
            type: "container",
            props: {
              style: {
                width: "400px",
                __width: "400px",
                display: "flex",
                __flexGap: "20px",
                flexBasis: 0,
                alignItems: "flex-start",
                paddingTop: "24px",
                flexDirection: "column",
                paddingBottom: "24px",
                justifyContent: "center",
              },
              "style@sm": {
                width: "300px",
                __width: "300px",
                flexGrow: "unset",
                maxWidth: "none",
              },
            },
            children: [
              {
                id: "751b64d9-dba4-4fb2-b461-157aa2c88e8f",
                name: "Image 2",
                type: "image",
                props: {
                  style: {
                    width: "100%",
                    __width: "100%",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1504204267155-aaad8e81290d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTR8fHxlbnwwfHx8fHw%3D",
                    __imageAltText:
                      "A single green leaf in a clear glass of water on a white background.",
                  },
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
              },
              {
                id: "26430809-5b2a-4d05-b30a-8a0925166d9b",
                name: "Container 2",
                type: "container",
                props: {
                  style: {
                    display: "flex",
                    maxWidth: "100%",
                    alignSelf: "stretch",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    justifyContent: "center",
                  },
                  "style@sm": {
                    marginLeft: "16px",
                    marginRight: "16px",
                  },
                },
                children: [
                  {
                    id: "69c35dfa-165b-43c7-a9ba-9779d22052fd",
                    type: "text",
                    props: {
                      text: "<p>Slide Title<br></p>",
                      style: {
                        color: "#000000",
                        fontSize: "15px",
                        fontFamily: "Assistant, sans-serif",
                        fontWeight: "700",
                        lineHeight: "24px",
                      },
                    },
                  },
                  {
                    id: "1e55978d-cd4d-452b-92f2-bfb564635ac0",
                    type: "text",
                    props: {
                      text: "<p>Description</p>",
                      style: {
                        color: "#000000",
                        fontSize: "15px",
                        fontFamily: "Assistant, sans-serif",
                        lineHeight: "24px",
                      },
                    },
                  },
                  {
                    id: "fcb37a87-f832-440e-a2cc-5048da5b018e",
                    type: "text",
                    props: {
                      text: "<p>SLIDE 2 OF 8</p>",
                      style: {
                        color: "#A8A8A8FF",
                        fontSize: "10px",
                        marginTop: "6px",
                        fontFamily: "IBM Plex Sans, sans-serif",
                        lineHeight: "24px",
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            id: "355cf177-2e22-4eb5-a8da-28591a2615c6",
            name: "Slide 3",
            type: "container",
            props: {
              style: {
                width: "400px",
                __width: "400px",
                display: "flex",
                __flexGap: "20px",
                flexBasis: 0,
                alignItems: "flex-start",
                paddingTop: "24px",
                flexDirection: "column",
                paddingBottom: "24px",
                justifyContent: "center",
              },
              "style@sm": {
                width: "300px",
                __width: "300px",
                flexGrow: "unset",
                maxWidth: "none",
              },
            },
            children: [
              {
                id: "cc8b27b3-f770-4b72-abc9-72b97704d88c",
                name: "Image 3",
                type: "image",
                props: {
                  style: {
                    width: "100%",
                    __width: "100%",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1471086569966-db3eebc25a59?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MjZ8fHxlbnwwfHx8fHw%3D",
                    __imageAltText:
                      "A monstera leaf in a clear glass vase against a white background.",
                  },
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
              },
              {
                id: "c14359f2-d71d-48dd-81bf-c0f3a0b904ea",
                name: "Container 3",
                type: "container",
                props: {
                  style: {
                    display: "flex",
                    maxWidth: "100%",
                    alignSelf: "stretch",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    justifyContent: "center",
                  },
                  "style@sm": {
                    marginLeft: "16px",
                    marginRight: "16px",
                  },
                },
                children: [
                  {
                    id: "214da271-8220-47e6-a3b1-ca7ca80c3566",
                    type: "text",
                    props: {
                      text: "<p>Slide Title</p>",
                      style: {
                        color: "#000000",
                        fontSize: "15px",
                        fontFamily: "Assistant, sans-serif",
                        fontWeight: "700",
                        lineHeight: "24px",
                      },
                    },
                  },
                  {
                    id: "67362cdd-c2e1-4825-91b4-a71a8e09ecdf",
                    type: "text",
                    props: {
                      text: "<p>Description</p>",
                      style: {
                        color: "#000000",
                        fontSize: "15px",
                        fontFamily: "Assistant, sans-serif",
                        lineHeight: "24px",
                      },
                    },
                  },
                  {
                    id: "2728a3e7-b142-46f7-95e4-65433a1b3c18",
                    type: "text",
                    props: {
                      text: "<p>SLIDE 3 OF 8</p>",
                      style: {
                        color: "#A8A8A8FF",
                        fontSize: "10px",
                        marginTop: "6px",
                        fontFamily: "IBM Plex Sans, sans-serif",
                        lineHeight: "24px",
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            id: "1474e8eb-7a64-48e4-8dd2-9db945100d77",
            name: "Slide 4",
            type: "container",
            props: {
              style: {
                width: "400px",
                __width: "400px",
                display: "flex",
                __flexGap: "20px",
                flexBasis: 0,
                alignItems: "flex-start",
                paddingTop: "24px",
                flexDirection: "column",
                paddingBottom: "24px",
                justifyContent: "center",
              },
              "style@sm": {
                width: "300px",
                __width: "300px",
                flexGrow: "unset",
                maxWidth: "none",
              },
            },
            children: [
              {
                id: "ecf8b334-6d5b-4523-aa05-b941761cc31c",
                name: "Image 4",
                type: "image",
                props: {
                  style: {
                    width: "100%",
                    __width: "100%",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1655658233701-a0bada796e94?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MXx8fGVufDB8fHx8fA%3D%3D",
                    __imageAltText:
                      "Green palm leaf beside two blank white cards on a white background.",
                  },
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
              },
              {
                id: "a8ee98c5-0779-4b7d-a19c-69503eeabfbe",
                name: "Container 4",
                type: "container",
                props: {
                  style: {
                    display: "flex",
                    maxWidth: "100%",
                    alignSelf: "stretch",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    justifyContent: "center",
                  },
                  "style@sm": {
                    marginLeft: "16px",
                    marginRight: "16px",
                  },
                },
                children: [
                  {
                    id: "5f2c10ff-bf2e-4453-9791-8cb9b9ec9d8b",
                    type: "text",
                    props: {
                      text: "<p>Slide Title</p>",
                      style: {
                        color: "#000000",
                        fontSize: "15px",
                        fontFamily: "Assistant, sans-serif",
                        fontWeight: "700",
                        lineHeight: "24px",
                      },
                    },
                  },
                  {
                    id: "b586dfdf-0798-422d-baea-58b8090d2fc0",
                    type: "text",
                    props: {
                      text: "<p>Description</p>",
                      style: {
                        color: "#000000",
                        fontSize: "15px",
                        fontFamily: "Assistant, sans-serif",
                        lineHeight: "24px",
                      },
                    },
                  },
                  {
                    id: "c8c5ea95-6f4f-416d-bbf3-d27591271870",
                    type: "text",
                    props: {
                      text: "<p>SLIDE 4 OF 8</p>",
                      style: {
                        color: "#A8A8A8FF",
                        fontSize: "10px",
                        marginTop: "6px",
                        fontFamily: "IBM Plex Sans, sans-serif",
                        lineHeight: "24px",
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            id: "e8e4a8ec-797e-4ddc-a47e-90993564711d",
            name: "Slide 5",
            type: "container",
            props: {
              style: {
                width: "400px",
                __width: "400px",
                display: "flex",
                __flexGap: "20px",
                flexBasis: 0,
                alignItems: "flex-start",
                paddingTop: "24px",
                flexDirection: "column",
                paddingBottom: "24px",
                justifyContent: "center",
              },
              "style@sm": {
                width: "300px",
                __width: "300px",
                flexGrow: "unset",
                maxWidth: "none",
              },
            },
            children: [
              {
                id: "08e2d2c8-56ce-44d6-8f98-03c8a052db38",
                name: "Image 5",
                type: "image",
                props: {
                  style: {
                    width: "100%",
                    __width: "100%",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1625474941653-4343bbb8267a?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8NHx8fGVufDB8fHx8fA%3D%3D",
                    __imageAltText:
                      "Indoor plant beside a smartphone with an app on screen, on a white background with shadows.",
                  },
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
              },
              {
                id: "f1701eed-2687-4111-8110-3f45f845ff0e",
                name: "Container 5",
                type: "container",
                props: {
                  style: {
                    display: "flex",
                    maxWidth: "100%",
                    alignSelf: "stretch",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    justifyContent: "center",
                  },
                  "style@sm": {
                    marginLeft: "16px",
                    marginRight: "16px",
                  },
                },
                children: [
                  {
                    id: "de426a4a-2a62-4532-a5fb-5816a927ac80",
                    type: "text",
                    props: {
                      text: "<p>Slide Title</p>",
                      style: {
                        color: "#000000",
                        fontSize: "15px",
                        fontFamily: "Assistant, sans-serif",
                        fontWeight: "700",
                        lineHeight: "24px",
                      },
                    },
                  },
                  {
                    id: "d0f1a3f1-c733-4180-b4e0-bc3225e2095b",
                    type: "text",
                    props: {
                      text: "<p>Description</p>",
                      style: {
                        color: "#000000",
                        fontSize: "15px",
                        fontFamily: "Assistant, sans-serif",
                        lineHeight: "24px",
                      },
                    },
                  },
                  {
                    id: "3ec730f9-00ec-45d4-a538-c55ef50d8ef1",
                    type: "text",
                    props: {
                      text: "<p>SLIDE 5 OF 8</p>",
                      style: {
                        color: "#A8A8A8FF",
                        fontSize: "10px",
                        marginTop: "6px",
                        fontFamily: "IBM Plex Sans, sans-serif",
                        lineHeight: "24px",
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            id: "5795a4d3-6bdd-4a56-bd52-ea23604c3d9b",
            name: "Slide 6",
            type: "container",
            props: {
              style: {
                width: "400px",
                __width: "400px",
                display: "flex",
                __flexGap: "20px",
                flexBasis: 0,
                alignItems: "flex-start",
                paddingTop: "24px",
                flexDirection: "column",
                paddingBottom: "24px",
                justifyContent: "center",
              },
              "style@sm": {
                width: "300px",
                __width: "300px",
                flexGrow: "unset",
                maxWidth: "none",
              },
            },
            children: [
              {
                id: "e006f16c-2510-4e01-947b-f3b08c03fb7b",
                name: "Image 6",
                type: "image",
                props: {
                  style: {
                    width: "100%",
                    __width: "100%",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1623824505948-2938b8818e12?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8N3x8fGVufDB8fHx8fA%3D%3D",
                    __imageAltText:
                      "Green palm leaves against a white background.",
                  },
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
              },
              {
                id: "ed87917a-d453-4518-b15f-7adc3b156b0c",
                name: "Container 6",
                type: "container",
                props: {
                  style: {
                    display: "flex",
                    maxWidth: "100%",
                    alignSelf: "stretch",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    justifyContent: "center",
                  },
                  "style@sm": {
                    marginLeft: "16px",
                    marginRight: "16px",
                  },
                },
                children: [
                  {
                    id: "437b2af7-1c68-4c5f-ad89-bd4dbdcb9a50",
                    type: "text",
                    props: {
                      text: "<p>Slide Title</p>",
                      style: {
                        color: "#000000",
                        fontSize: "15px",
                        fontFamily: "Assistant, sans-serif",
                        fontWeight: "700",
                        lineHeight: "24px",
                      },
                    },
                  },
                  {
                    id: "ba10337b-4f6e-47dd-ac5b-7d68d60b40b1",
                    type: "text",
                    props: {
                      text: "<p>Description</p>",
                      style: {
                        color: "#000000",
                        fontSize: "15px",
                        fontFamily: "Assistant, sans-serif",
                        lineHeight: "24px",
                      },
                    },
                  },
                  {
                    id: "2b740a66-f5fa-4d3a-bfc6-bae1b684faf9",
                    type: "text",
                    props: {
                      text: "<p>SLIDE 6 OF 8</p>",
                      style: {
                        color: "#A8A8A8FF",
                        fontSize: "10px",
                        marginTop: "6px",
                        fontFamily: "IBM Plex Sans, sans-serif",
                        lineHeight: "24px",
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            id: "f4936e13-3393-482d-9ef0-9c91a72a8ca5",
            name: "Slide 7",
            type: "container",
            props: {
              style: {
                width: "400px",
                __width: "400px",
                display: "flex",
                __flexGap: "20px",
                flexBasis: 0,
                alignItems: "flex-start",
                paddingTop: "24px",
                flexDirection: "column",
                paddingBottom: "24px",
                justifyContent: "center",
              },
              "style@sm": {
                width: "300px",
                __width: "300px",
                flexGrow: "unset",
                maxWidth: "none",
              },
            },
            children: [
              {
                id: "563ee553-6eae-49cf-9dbf-c4351970ca83",
                name: "Image 7",
                type: "image",
                props: {
                  style: {
                    width: "100%",
                    __width: "100%",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1622624671653-18a35aba747d?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTJ8fHxlbnwwfHx8fHw%3D",
                    __imageAltText:
                      "Smartphone on a white surface displaying an article with image and text.",
                  },
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
              },
              {
                id: "1c6173a9-c6f2-4814-b1ff-b19c69b6aa65",
                name: "Container 7",
                type: "container",
                props: {
                  style: {
                    display: "flex",
                    maxWidth: "100%",
                    alignSelf: "stretch",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    justifyContent: "center",
                  },
                  "style@sm": {
                    marginLeft: "16px",
                    marginRight: "16px",
                  },
                },
                children: [
                  {
                    id: "36dee09a-9264-4d02-88b7-a3166f7db7e3",
                    type: "text",
                    props: {
                      text: "<p>Slide Title</p>",
                      style: {
                        color: "#000000",
                        fontSize: "15px",
                        fontFamily: "Assistant, sans-serif",
                        fontWeight: "700",
                        lineHeight: "24px",
                      },
                    },
                  },
                  {
                    id: "9c26d0f5-8246-4850-b13b-105529aa9980",
                    type: "text",
                    props: {
                      text: "<p>Description</p>",
                      style: {
                        color: "#000000",
                        fontSize: "15px",
                        fontFamily: "Assistant, sans-serif",
                        lineHeight: "24px",
                      },
                    },
                  },
                  {
                    id: "11b38434-48e5-4236-b2b4-980b7d7fd609",
                    type: "text",
                    props: {
                      text: "<p>SLIDE 7 OF 8</p>",
                      style: {
                        color: "#A8A8A8FF",
                        fontSize: "10px",
                        marginTop: "6px",
                        fontFamily: "IBM Plex Sans, sans-serif",
                        lineHeight: "24px",
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            id: "57723119-17b5-428d-a6c7-92127e0025a8",
            name: "Slide 8",
            type: "container",
            props: {
              style: {
                width: "400px",
                __width: "400px",
                display: "flex",
                __flexGap: "20px",
                flexBasis: 0,
                alignItems: "flex-start",
                paddingTop: "24px",
                marginRight: "100px",
                flexDirection: "column",
                paddingBottom: "24px",
                justifyContent: "center",
              },
              "style@sm": {
                width: "300px",
                __width: "300px",
                flexGrow: "unset",
                maxWidth: "none",
                marginRight: "16px",
              },
            },
            children: [
              {
                id: "2d5dcbf1-7c79-409d-b026-7ff08f37c0ca",
                name: "Image 8",
                type: "image",
                props: {
                  style: {
                    width: "100%",
                    __width: "100%",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1504198322253-cfa87a0ff25f?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTZ8fHxlbnwwfHx8fHw%3D",
                    __imageAltText:
                      "Hand holding an open magazine with a black and white architectural photo.",
                  },
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
              },
              {
                id: "f0303f24-504d-4c20-bc89-d452958b092b",
                name: "Container 8",
                type: "container",
                props: {
                  style: {
                    display: "flex",
                    maxWidth: "100%",
                    alignSelf: "stretch",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    justifyContent: "center",
                  },
                  "style@sm": {
                    marginLeft: "16px",
                    marginRight: "16px",
                  },
                },
                children: [
                  {
                    id: "515b1589-7964-40e1-9712-dc5e74e8c582",
                    type: "text",
                    props: {
                      text: "<p>Slide Title</p>",
                      style: {
                        color: "#000000",
                        fontSize: "15px",
                        fontFamily: "Assistant, sans-serif",
                        fontWeight: "700",
                        lineHeight: "24px",
                      },
                    },
                  },
                  {
                    id: "3f224d5c-d894-466e-9821-9719d9ee9427",
                    type: "text",
                    props: {
                      text: "<p>Description</p>",
                      style: {
                        color: "#000000",
                        fontSize: "15px",
                        fontFamily: "Assistant, sans-serif",
                        lineHeight: "24px",
                      },
                    },
                  },
                  {
                    id: "f6dbee8f-708e-4c51-9d18-6c8a5c05ccbb",
                    type: "text",
                    props: {
                      text: "<p>SLIDE 8 OF 8</p>",
                      style: {
                        color: "#A8A8A8FF",
                        fontSize: "10px",
                        marginTop: "6px",
                        fontFamily: "IBM Plex Sans, sans-serif",
                        lineHeight: "24px",
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "d492f521-6b92-4a8e-93b5-283631646efc",
        name: "Next",
        type: "carouselV3Control",
        props: {
          style: {
            top: "0px",
            right: "0px",
            cursor: "pointer",
            height: "100%",
            zIndex: 1_000_000,
            display: "flex",
            __height: "375px",
            position: "absolute",
            alignSelf: "stretch",
            alignItems: "center",
            marginRight: "20px",
            justifyContent: "center",
            __animateVariantTransitions: true,
          },
          "style@sm": {
            top: "0px",
            right: "0px",
            marginRight: "0px",
            paddingRight: "24px",
            paddingBottom: "96px",
          },
          direction: "next",
        },
        children: [
          {
            id: "f964b446-974f-45eb-8b22-1157feea9fc4",
            type: "icon",
            props: {
              style: {
                width: "40px",
                height: "40px",
                __width: "40px",
                __height: "40px",
                marginBottom: "96px",
              },
              iconName: "chevronRight",
              "style@sm": {
                width: "40px",
                height: "40px",
                __width: "40px",
                __height: "40px",
                flexGrow: "unset",
              },
            },
            variantOverrides: {},
          },
        ],
        variants: [
          {
            id: "c26881dc-56ac-4d9f-bac1-60cd3bc700fa",
            name: "default",
            query: null,
            componentOverrides: null,
          },
          {
            id: "a01b2120-0f46-4c79-b765-f227de5ce496",
            name: "Last item",
            query: {
              type: "expression",
              operator: "or",
              statements: [
                {
                  id: "4a96c1e0-381d-4d91-83c1-172a62c4294d",
                  field: "state.group.isLastItemActive",
                  value: null,
                  operator: null,
                },
              ],
            },
            componentOverrides: null,
          },
        ],
        variantOverrides: {
          "a01b2120-0f46-4c79-b765-f227de5ce496": {
            componentOverrides: {
              "d492f521-6b92-4a8e-93b5-283631646efc": {
                props: {
                  style: {
                    opacity: "0%",
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
};
