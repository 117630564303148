import type { ComboboxProps } from "@replo/design-system/components/shadcn/combobox/types";

import * as React from "react";

import { OptionsList } from "@replo/design-system/components/shadcn/combobox/components/OptionsList";
import {
  Command,
  CommandInput,
} from "@replo/design-system/components/shadcn/core/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@replo/design-system/components/shadcn/core/popover";
import classNames from "classnames";
import { BsSearch } from "react-icons/bs";
import { useControllableState } from "replo-utils/react/use-controllable-state";

type ComboboxMultiSelectProps = Pick<
  ComboboxProps,
  | "options"
  | "defaultValue"
  | "value"
  | "onChange"
  | "open"
  | "onOpenChange"
  | "input"
  | "onInputChange"
  | "inputPlaceholder"
  | "endEnhancer"
> & { isCurrentOptionCreatable?: boolean };

export function ComboboxMultiSelect({
  options,
  defaultValue,
  value: controlledValue,
  onChange: onControlledChange,
  open: controlledOpen,
  onOpenChange: onControlledOpenChange,
  input: inputValue,
  onInputChange: onControlledInputChange,
  inputPlaceholder,
  endEnhancer,
  isCurrentOptionCreatable = false,
}: ComboboxMultiSelectProps) {
  const [open, setOpen] = useControllableState(
    controlledOpen,
    false,
    onControlledOpenChange,
  );
  const [value, setValue] = useControllableState(
    controlledValue,
    defaultValue ?? "",
    onControlledChange,
  );

  const label = options?.find((option) => option.value === value)?.label;

  const [input, setInput] = useControllableState(
    inputValue,
    label ?? "",
    onControlledInputChange,
  );

  /**
   * NOTE (Max, 2024-11-30): Provided that isCurrentOptionCreatable = true,
   * we'll add the option that's currently being typed
   * (i.e. the input) only if it's not empty, AND it's not an already-existing
   * option that was passed in the props.
   */
  const optionsWithCreatable = React.useMemo(() => {
    const optionExists = options.some((option) => option.value === input);

    if (!isCurrentOptionCreatable || !input || optionExists) {
      return options;
    }

    return [...options, { value: input, label: input, isBeingCreated: true }];
  }, [isCurrentOptionCreatable, options, input]);

  /**
   * NOTE (Max, 2024-11-30): this handleBlur is used to solve the following situation:
   * 1. The combobox has a selected value.
   * 2. The user starts typing a new value.
   * 3. The user clicks outside the combobox (without having selected a new value).
   *
   * In this case, we want to reset the input to the label of the already-selected
   * value, hence this handleBlur. Otherwise, the input would have still shown the
   * incomplete string that the user was typing (while the actual selected value wouldn't have
   * actually changed -> we want the input & the selected value to be in sync).
   */
  const handleBlur = () => {
    const labelFromOptionsWithCreatable = optionsWithCreatable?.find(
      (option) => option.value === value,
    )?.label;

    /**
     * NOTE (Max, 2024-11-30):
     * This ensures that we trigger the handleBlur only when the combobox just got
     * closed. Otherwise, this would be triggered also when a user chooses a
     * selected value from the dropdown (before the handleChange() is triggered).
     */
    if (!open) {
      /**
       * NOTE (Max, 2024-11-30):
       * We're doing "?? value" for the following situation:
       * 1. The user just created a new value (i.e. it wasn't present in the option props).
       * The input now contains this newly created value, and "value" is set to this value
       * 2. The user starts typing a new value.
       * 3. The user clicks outside the combobox (without having selected a new value).
       *
       * In this case, labelFromOptionsWithCreatable would be undefined, as the option that we're adding
       * in optionsWithCreatable wouldn't contain the value from step 1 anymore -> it would contain the value
       * being typed in step 3.
       *
       * In this case, we want to reset the input to the label of that newly-created value (from step 1).
       * hence this "?? value".
       */
      setInput(labelFromOptionsWithCreatable ?? value);
    }
  };

  const handleChange = (newValue: string) => {
    const labelFromOptionsWithCreatable = optionsWithCreatable?.find(
      (option) => option.value === newValue,
    )?.label;

    setValue(newValue);
    if (labelFromOptionsWithCreatable) {
      setInput(labelFromOptionsWithCreatable);
    }
  };

  return (
    <Command>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger>
          <CommandInput
            value={input}
            onValueChange={setInput}
            placeholder={inputPlaceholder ?? "Search..."}
            startEnhancer={<BsSearch className="text-slate-400 h-3 w-3" />}
            className="text-xs"
            endEnhancer={endEnhancer?.()}
            onBlur={handleBlur}
          />
        </PopoverTrigger>
        <PopoverContent
          className={classNames("w-full p-0 max-w-[550px]", {
            hidden: !open,
          })}
          style={{ minWidth: "var(--radix-popper-anchor-width)" }}
          forceMount={true}
        >
          <OptionsList
            options={optionsWithCreatable}
            setValue={handleChange}
            setOpen={setOpen}
            value={value}
          />
        </PopoverContent>
      </Popover>
    </Command>
  );
}
