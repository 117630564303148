import type { ReploComponentIssue } from "@editor/types/component-issues";

import * as React from "react";

import { BsInfoCircle } from "react-icons/bs";

const RightBarIssues: React.FC<{
  issues: ReploComponentIssue[];
  endEnhancer?: (issue: ReploComponentIssue) => React.ReactNode;
}> = ({ issues, endEnhancer }) => {
  return (
    <ul className="flex flex-col gap-2">
      {issues.map((issue, index) => (
        <li
          key={index}
          className="bg-red-50 p-2 rounded text-red-600 text-xs flex gap-2"
        >
          <BsInfoCircle className="shrink-0" />
          <div className="flex flex-col">
            {issue.message}
            {endEnhancer?.(issue)}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default RightBarIssues;
