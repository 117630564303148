import { setStorageItem } from "@editor/hooks/useLocalStorage";

export const saveCurrentElementId = (elementId: string, storeId: string) => {
  setStorageItem(`elementId-store-${storeId}`, elementId);
};

export const getLastSelectedElementIdForProject = (storeId: string) => {
  return localStorage.getItem(`elementId-store-${storeId}`);
};

export const saveLastSelectedElementIdForTypeForStore = (
  storeId: string,
  type: string,
  elementId: string,
) => {
  return setStorageItem(
    `replo.elementId-store-${storeId}-type-${type}`,
    elementId,
  );
};

export const getLastSelectedElementIdForTypeForStore = (
  storeId: string,
  type: string,
) => {
  return localStorage.getItem(`replo.elementId-store-${storeId}-type-${type}`);
};
