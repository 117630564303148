import type { Component } from "schemas/component";
import type { StoreApi } from "zustand";

import * as React from "react";

import { createStore } from "zustand";

export type StateSetter = (stateId: string) => void;

/**
 * Function which accepts a component id and a function which will set the state
 * for that component. This registers the state setter in the store so that other
 * components can set the active state for the given componentId
 */
export type RegisterStateSetter = (
  componentId: Component["id"],
  setter: StateSetter,
) => void;

/**
 * State type for the zustand store
 */
export type RuntimeActiveStateContextState = {
  activeStateSetters: Record<Component["id"], (stateId: string) => void>;
  actions: {
    registerActiveStateSetter: RegisterStateSetter;
    deregisterActiveStateSetter: (componentId: Component["id"]) => void;
  };
};

export type RuntimeActiveStateStore = StoreApi<RuntimeActiveStateContextState>;

/**
 * Context which stores the active state setters for each component. This context
 * is used at the top level of an element to store a mapping between component ids
 * and functions which can set the active state for those components, so that things
 * like the Activate State interaction can update component state, even if the target
 * component is in a different part of the React tree.
 */
export const RuntimeActiveStateContext =
  React.createContext<RuntimeActiveStateStore | null>(null);

export const createRuntimeActiveStateStore = () => {
  return createStore<RuntimeActiveStateContextState>((set) => {
    return {
      activeStateSetters: {},
      actions: {
        registerActiveStateSetter: (
          componentId: Component["id"],
          setter: (stateId: string) => void,
        ) => {
          set((previousState) => {
            return {
              ...previousState,
              activeStateSetters: {
                ...previousState.activeStateSetters,
                [componentId]: setter,
              },
            };
          });
        },
        deregisterActiveStateSetter: (componentId: Component["id"]) => {
          set((previousState) => {
            const newSetters = {
              ...previousState.activeStateSetters,
            };
            delete newSetters[componentId];
            return {
              ...previousState,
              activeStateSetters: newSetters,
            };
          });
        },
      },
    };
  });
};
