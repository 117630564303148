import { ConditionOperatorEnum } from "schemas/analyticsRead";

export type AnalyticsUrlParamsFilterTypes =
  | "utm_campaign"
  | "utm_content"
  | "utm_medium"
  | "utm_source"
  | "utm_term";

export const URL_PARAMS_FILTER_OPTIONS: {
  label: string;
  value: AnalyticsUrlParamsFilterTypes;
}[] = [
  {
    label: "UTM Campaign",
    value: "utm_campaign",
  },
  {
    label: "UTM Content",
    value: "utm_content",
  },
  {
    label: "UTM Medium",
    value: "utm_medium",
  },
  {
    label: "UTM Source",
    value: "utm_source",
  },
  {
    label: "UTM Term",
    value: "utm_term",
  },
];

export const OPERATOR_OPTIONS = [
  {
    label: "Equals",
    value: ConditionOperatorEnum.EQUALS,
  },
  {
    label: "Doesn't Equal",
    value: ConditionOperatorEnum.DOES_NOT_EQUAL,
  },
  {
    label: "Contains",
    value: ConditionOperatorEnum.CONTAINS,
  },
  {
    label: "Doesn't Contain",
    value: ConditionOperatorEnum.DOES_NOT_CONTAIN,
  },
];
