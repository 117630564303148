import { useGetCanvasDocumentPublisherQuery } from "@editor/reducers/api-reducer";
import {
  selectDraftElementId,
  selectDraftElementType,
  selectProjectId,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import { selectCanvasLoadingType } from "@/features/canvas/canvas-reducer";
import { usePrevious } from "replo-runtime/shared/hooks/usePrevious";
import { isNullish } from "replo-utils/lib/misc";

export default function useAutoRefetchCanvasDocument() {
  const projectId = useEditorSelector(selectProjectId);
  const draftElementId = useEditorSelector(selectDraftElementId);
  const draftElementType = useEditorSelector(selectDraftElementType);
  const loadingType = useEditorSelector(selectCanvasLoadingType);
  const previousDraftElementType = usePrevious(draftElementType);
  const isShopifyArticle = draftElementType === "shopifyArticle";
  const prevProjectId = usePrevious(projectId);

  useGetCanvasDocumentPublisherQuery(
    {
      storeId: projectId!,
      draftElementId,
    },
    {
      // Note (Fran, 2022-08-31): We need to skip fetching mirror when we don't
      // have a store or element selected and when we change between sections
      // and pages of the same store.
      skip:
        isNullish(projectId) ||
        isNullish(draftElementId) ||
        (previousDraftElementType === draftElementType &&
          !isShopifyArticle &&
          loadingType !== "fetchingContent" &&
          projectId === prevProjectId),
      refetchOnMountOrArgChange: true,
    },
  );
}
