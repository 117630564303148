import type { DataTableModalProps } from "@editor/components/AppModalTypes";
import type { DataTable } from "replo-runtime/shared/DataTable";

import * as React from "react";

import Modal from "@editor/components/common/designSystem/Modal";
import { useModal } from "@editor/hooks/useModal";
import {
  selectDataTables,
  setDraftDataTable as setDraftDataTableAction,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { getSingularOrCollectionNameFromDataTable } from "@utils/dataTable";

import Button from "@replo/design-system/components/button";
import classNames from "classnames";
import { FiDatabase } from "react-icons/fi";
import { useDispatch } from "react-redux";

export const DataTableModal: React.FC<DataTableModalProps> = (props) => {
  const [selectedDataTableId, setSelectedDataTableId] = React.useState(
    props.selectedDataTableId,
  );
  const modal = useModal();
  const dispatch = useDispatch();

  const setDraftDataTable = (payload: { id: string | null }) =>
    dispatch(setDraftDataTableAction(payload));
  const dataTables = useEditorSelector(selectDataTables);

  const _renderTitle = () => (
    <div className="flex flex-row justify-between pb-2 text-2xl text-default">
      {props.referrer === "header"
        ? "Manage Data Collections"
        : "Select Data Collection"}
    </div>
  );
  const _renderCreateDataTableButton = () => (
    <div className="mb-5 flex flex-row justify-between">
      <div className="pb-4 text-3xl">{props.title}</div>
      <Button
        size="base"
        variant="primary"
        onClick={(e: any) => {
          e.stopPropagation();
          setDraftDataTable({ id: null });
          modal.openModal({
            type: "createOrConfigureDataCollectionModal",
            props: {
              modalType: "create",
            },
          });
        }}
      >
        Create Collection
      </Button>
    </div>
  );

  const _renderUseButton = () => {
    if (!selectedDataTableId) {
      return null;
    }
    return (
      <div
        className="mb-5 flex flex-row justify-between"
        style={{ marginTop: "20px" }}
      >
        <div className="pb-4 text-3xl">{props.title}</div>
        <Button
          onClick={(e: any) => {
            e.stopPropagation();
            props.onDataTableClick?.(selectedDataTableId);
            setDraftDataTable({ id: selectedDataTableId });
            modal.closeModal({
              type: "dataTableModal",
            });
          }}
          variant="primary"
        >{`Use ${dataTables.mapping[selectedDataTableId]?.name}`}</Button>
      </div>
    );
  };

  const _renderDataTable = (dataTable: DataTable) => {
    return (
      <div
        key={dataTable.id}
        className={classNames(
          "min-w-[600px] cursor-pointer border-t-2 bg-white p-2 transition duration-200 ease-in-out",
          { "bg-purple-100": dataTable.id === selectedDataTableId },
        )}
        onClick={(e: any) => {
          e.stopPropagation();
          if (props.referrer === "header") {
            setDraftDataTable({ id: dataTable.id });
            modal.openModal({
              type: "dataCollectionEditModal",
            });
          } else {
            setSelectedDataTableId(dataTable.id);
          }
        }}
      >
        <div
          className="flex flex-row justify-between"
          style={{ alignItems: "center" }}
        >
          <div
            className="flex flex-row justify-between"
            style={{ alignItems: "center" }}
          >
            <div>
              <FiDatabase color="gray" size="20" />
            </div>
            <div className="flex flex-col" style={{ marginLeft: "16px" }}>
              <h1 className="bold text-base">{dataTable.name}</h1>
              <p style={{ color: "gray" }}>
                {getSingularOrCollectionNameFromDataTable(
                  dataTable,
                  dataTable.name,
                )}
              </p>
            </div>
          </div>
          <div
            className="flex flex-row justify-between"
            style={{ alignItems: "center" }}
          >
            <p>{dataTable.data.rows.length} Rows</p>
            {props.referrer === "modifier" && (
              <div style={{ marginLeft: "16px" }}>
                <Button
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setDraftDataTable({ id: dataTable.id });
                    modal.openModal({
                      type: "dataCollectionEditModal",
                    });
                  }}
                  variant="primary"
                >
                  Edit
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const _renderDataTableList = () =>
    Object.values(dataTables.mapping).length > 0 ? (
      <div>
        {_renderCreateDataTableButton()}
        <div
          className="grid-cols-1 border-t-2 border-gray-900 no-scrollbar"
          style={{
            height: 500,
            maxHeight: "60vh",
            overflow: "scroll",
          }}
        >
          {Object.values(dataTables.mapping)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((d) => _renderDataTable(d))}
        </div>
        {selectedDataTableId && _renderUseButton()}
      </div>
    ) : (
      <div
        className="border-top border-dark flex flex-col"
        style={{
          width: 400,
          height: 500,
          maxHeight: "60vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="flex flex-col justify-between"
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p className="mb-4 text-slate-400">
            Create a new Data Collection to save time by using Replo&apos;s
            content management system. Data Collections can be used to create
            dynamic interfaces with many components.
          </p>
          {_renderCreateDataTableButton()}
        </div>
      </div>
    );

  return (
    <Modal
      isOpen={true}
      includesCloseIcon
      onRequestClose={() => {
        modal.closeModal({ type: "dataTableModal" });
      }}
      className="w-auto"
    >
      <div className="relative flex flex-col justify-between bg-white w-full">
        <div className="flex flex-col p-10 pb-0">
          <div className="grid grid-cols-12 gap-4 pt-2">
            <div className="col-span-12">
              {_renderTitle()}
              {_renderDataTableList()}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
