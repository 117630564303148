import type { ComponentTemplate } from "@editor/types/component-template";

import { getCanBeAddedFunction } from "@editor/utils/template";

import { Env } from "replo-runtime/shared/enums";

const variantImage: ComponentTemplate = {
  id: "f81dccc8-13d5-426c-b96d-04d5204f7a93",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Product Variant Image",
  leftBarCategory: { main: "product", subCategory: "productData" },
  preview: "product/variant-img.png",
  template: {
    id: "015b2036-b4fb-432a-af73-e010f85a3908",
    type: "image",
    props: {
      src: "",
      style: {
        objectFit: "cover",
        __imageSource: "{{attributes._variant.featuredImage}}",
      },
    },
    name: "Variant Image",
  },
  canBeAddedAsChild: getCanBeAddedFunction(
    "product",
    "Product Variant Images can only be added inside Product boxes.",
  ),
};

export default variantImage;
