/**
 * The project code.
 *
 * @since 3.0.0
 */
export const PROJECT_CODE = "splide";

/**
 * The data attribute prefix.
 *
 * @since 3.0.0
 */
export const DATA_ATTRIBUTE = `data-${PROJECT_CODE}`;
