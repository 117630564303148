import type {
  PopoverContentProps,
  PopoverProps,
} from "@editor/components/common/designSystem/Popover";

import * as React from "react";

import Popover from "@editor/components/common/designSystem/Popover";

type InlinePopoverProps = Exclude<PopoverContentProps, "children"> &
  Pick<PopoverProps, "isOpen" | "isDefaultOpen" | "onOpenChange"> & {
    triggerAsChild?: boolean;
    content: React.ReactNode;
    // TODO (Chance 2024-05-13): This is a workaround to the fact that we pass a
    // width of `auto` to the button by default. We should not assume that child
    // buttons have a width of auto, nor should we need to override this.
    skipTriggerStyles?: boolean;
    stayInPosition?: boolean;
  };
// TODO (Gabe 2023-11-08): This should be refactored to enable proper
// composition. If you need to use composition (like we do in ActionsModifier)
// then use the design system Popover directly.
const InlinePopover: React.FC<React.PropsWithChildren<InlinePopoverProps>> = ({
  isDefaultOpen,
  isOpen,
  onOpenChange,
  children,
  content,
  style,
  triggerAsChild = false,
  skipTriggerStyles,
  stayInPosition = false,
  ...popoverContentProps
}) => {
  const popoverRootProps = { isDefaultOpen, isOpen, onOpenChange };
  return (
    <Popover {...popoverRootProps}>
      <Popover.Trigger
        style={
          !skipTriggerStyles ? { width: style?.width ?? "auto" } : undefined
        }
        asChild={triggerAsChild}
      >
        {children}
      </Popover.Trigger>
      <Popover.Content
        {...popoverContentProps}
        style={{ marginTop: style?.marginTop ?? 0 }}
        stayInPosition={stayInPosition}
      >
        {content}
      </Popover.Content>
    </Popover>
  );
};

export default InlinePopover;
