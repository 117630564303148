import * as React from "react";

import { BsX } from "react-icons/bs";

/**
 * FormFieldXButton is a component designed to be used within input fields.
 * It provides a standardized "X" button for actions such as clearing or closing.
 *
 * Key features:
 * - The container always maintains a size of 16x16 pixels for consistency and a bigger clickable area.
 * - The "X" icon has a size of 12 pixels.
 * - Ensures consistent alignment and dimensions of icons across all input fields.
 *
 * @author Juan 2024-18-10
 */

type FormFieldXButtonProps = {
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const FormFieldXButton: React.FC<FormFieldXButtonProps> = ({ onClick }) => {
  return (
    <div
      className="h-4 w-4 cursor-pointer text-subtle flex items-center justify-center"
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
    >
      <BsX size={12} />
    </div>
  );
};

export default FormFieldXButton;
