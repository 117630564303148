import * as React from "react";

import { URL_PARAMS_FILTER_OPTIONS } from "@/features/analytics/moreFilters/constants";
import { findLabelForValue } from "@/features/analytics/moreFilters/utils";
import { Combobox } from "@replo/design-system/components/shadcn/combobox/Combobox";
import classNames from "classnames";
import { BsFilter } from "react-icons/bs";

type TriggerBarPopoverComboboxProps = {
  handleUrlParamOptionClick: (value: string) => void;
};

const TriggerBarPopoverCombobox: React.FC<TriggerBarPopoverComboboxProps> = ({
  handleUrlParamOptionClick,
}) => {
  const utmFilterOptionDisplay = (value: string) => {
    const label = findLabelForValue(URL_PARAMS_FILTER_OPTIONS, value);

    return (
      <span onClick={() => handleUrlParamOptionClick(value)}>{label}</span>
    );
  };

  const [open, setOpen] = React.useState(false);

  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  return (
    <Combobox
      options={URL_PARAMS_FILTER_OPTIONS.map((option) => ({
        ...option,
        component: utmFilterOptionDisplay(option.value),
      }))}
      staticPlaceholder="Filter"
      endEnhancer={() => <BsFilter className="h-4 w-4 text-default" />}
      className={classNames("bg-white hover:bg-slate-100", {
        "bg-slate-100": open,
      })}
      open={open}
      onOpenChange={handleOpenChange}
    />
  );
};

export default TriggerBarPopoverCombobox;
