import type * as React from "react";
import type { AlchemyActionType } from "replo-runtime/shared/enums";
import type {
  ProductMetafieldMapping,
  RenderComponentAttributes,
  RenderPreviewEnvironment,
  VariantMetafieldMapping,
  VariantPriceLabels,
} from "replo-runtime/shared/types";
import type { AlchemyActionContext } from "replo-runtime/store/AlchemyAction";
import type { Context } from "replo-runtime/store/AlchemyVariable";
import type { ReploComponentProps } from "replo-runtime/store/components/ReploComponent";
import type { ProductResolutionDependencies } from "replo-runtime/store/ReploProduct";
import type { Action } from "schemas/actions";
import type { Component, CustomPropDefinition } from "schemas/component";
import type { HTMLAttributesValues } from "schemas/dynamicData";
import type { ReploState } from "schemas/generated/symbol";
import type { ProductRef, ProductRefOrDynamic } from "schemas/product";
import type { RuntimeStyleAttribute } from "schemas/styleAttribute";

import kebabCase from "lodash-es/kebabCase";
import keyBy from "lodash-es/keyBy";
import { AlchemyActionTriggers } from "replo-runtime/shared/enums";
import { isGradient } from "replo-runtime/shared/types";
import { getCustomPropDefinitions } from "replo-runtime/shared/utils/component";
import {
  resolveContextValue,
  setCurrentComponentContext,
} from "replo-runtime/shared/utils/context";
import { executeActions } from "replo-runtime/store/AlchemyAction";
import { evaluateVariable } from "replo-runtime/store/AlchemyVariable";
import { customPropTypeToRenderData } from "replo-runtime/store/customProps";
import { getProduct, isContextRef } from "replo-runtime/store/ReploProduct";
import { actionTypeToRenderData } from "replo-runtime/store/utils/action";
import { convertPropValueToSectionSetting } from "replo-runtime/store/utils/section-settings";
import { exhaustiveSwitch, isNullish } from "replo-utils/lib/misc";

/**
 * The purpose of this function is to override the action's value
 * when a corresponding sectionSetting should be used.
 * It either returns the modified action or the original action.
 */
function getActionWithSectionSettingValue(
  action: Action,
  component: Component,
) {
  const sectionSettingValue = convertPropValueToSectionSetting(
    action.type,
    action.value?.url ?? action.value,
    component.id,
  );
  if (sectionSettingValue) {
    return {
      ...action,
      value: sectionSettingValue,
    } as Action;
  }

  return action;
}

/**
 * Return component.props with all dynamic data values resolved, as well as
 * an array of all the dynamic data paths which were resolved.
 *
 * @param component Component whose props we're evaluating
 * @param context Context to evaluate dynamic data under
 */
const evaluateDynamicDataOnComponentProps = (
  component: Component,
  context: Context,
  repeatedIndex: number,
  onEvaluateDynamicStyleData: (config: {
    componentId: string;
    attribute: RuntimeStyleAttribute;
    repeatedIndex: number;
    dynamicDataKey: string;
    evaluatedValue: any;
  }) => void,
): {
  evaluatedProps: Record<string, any>;
  evaluatedDynamicDataPaths: string[];
} => {
  const evaluatedPaths: string[] = [];
  const evaluated: Record<string, any> = {};
  /**
   * Note (Ovishek, 2023-03-14): Some keys like _liquidContent have liquid code as a value
   * which seems exactly as our dynamic value structure, we shouldn't mix them up and ignore
   * dynamic evaluations.
   */
  const keysThatAreNotDynamic: Set<string> = new Set(["_liquidContent"]);
  for (let [key, value] of Object.entries(component.props)) {
    if (context.useSectionSettings) {
      const sectionSettingValue = convertPropValueToSectionSetting(
        key,
        value,
        component.id,
      );
      if (sectionSettingValue) {
        value = sectionSettingValue;
      }
    }

    if (key.includes("style")) {
      evaluated[key] = {};
      if (value) {
        for (let [styleAttribute, styleValue] of Object.entries(value)) {
          if (context.useSectionSettings) {
            const sectionSettingStyleValue = convertPropValueToSectionSetting(
              styleAttribute,
              styleValue,
              component.id,
              [key.replace("@", "")],
            );
            if (sectionSettingStyleValue) {
              styleValue = sectionSettingStyleValue;
            }
          }

          if (typeof styleValue === "string" && styleValue.startsWith("{{")) {
            const evaluatedStyleValue = evaluateVariable(styleValue, context);
            onEvaluateDynamicStyleData?.({
              componentId: component.id,
              attribute: styleAttribute as RuntimeStyleAttribute,
              dynamicDataKey: styleValue,
              repeatedIndex,
              evaluatedValue: evaluatedStyleValue,
            });
            // Dynamic data gradient needs some special treatment since it takes
            // more than one style attribute to represent it.
            if (isGradient(evaluatedStyleValue)) {
              evaluated[key][styleAttribute] = "alchemy:gradient";
              evaluated[key][`__alchemyGradient__${styleAttribute}__tilt`] =
                evaluatedStyleValue.tilt;
              evaluated[key][`__alchemyGradient__${styleAttribute}__stops`] =
                evaluatedStyleValue.stops;
            } else {
              evaluated[key][styleAttribute] = evaluatedStyleValue;
            }
          } else {
            evaluated[key][styleAttribute] = styleValue;
          }
        }
      }
    } else if (
      !keysThatAreNotDynamic.has(key) &&
      typeof value === "string" &&
      value?.includes("{{")
    ) {
      evaluated[key] = evaluateVariable(value, context);
      evaluatedPaths.push(value);
    } else if (isContextRef(value)) {
      evaluated[key] = evaluateVariable(`{{${value.ref}}}`, context);
    } else if (
      value?.type == "htmlAttributes" &&
      value.values &&
      value.values.some((val: HTMLAttributesValues) =>
        val.value?.includes("{{"),
      )
    ) {
      evaluated[key] = {
        ...value,
        values: value.values.map((valueProp: HTMLAttributesValues) => {
          if (valueProp.value?.includes("{{")) {
            return {
              ...valueProp,
              value: evaluateVariable(valueProp.value, context),
            };
          }
          return valueProp;
        }),
      };
    } else {
      evaluated[key] = value;
    }
  }

  for (const actionTrigger of AlchemyActionTriggers) {
    if (isNullish(component.props[actionTrigger])) {
      continue;
    }
    evaluated[actionTrigger] = [];
    for (const action of component.props[actionTrigger] ?? []) {
      const renderData = actionTypeToRenderData[action.type];
      if (renderData.supportsDynamicData) {
        evaluated[actionTrigger].push(
          renderData.evaluateDynamicData({
            action: context.useSectionSettings
              ? getActionWithSectionSettingValue(action, component)
              : action,
            context,
          }),
        );
      } else {
        evaluated[actionTrigger].push(action);
      }
    }
  }

  return {
    evaluatedProps: evaluated,
    evaluatedDynamicDataPaths: evaluatedPaths,
  };
};

export const evaluateComponentProps = (
  component: Component,
  context: Context,
  repeatedIndex: number,
  onEvaluateDynamicStyleData: (config: {
    componentId: string;
    attribute: RuntimeStyleAttribute;
    repeatedIndex: number;
    dynamicDataKey: string;
    evaluatedValue: any;
  }) => void,
): {
  evaluatedProps: Component["props"];
  evaluatedDynamicDataPaths: string[];
} => {
  const { evaluatedProps, evaluatedDynamicDataPaths } =
    evaluateDynamicDataOnComponentProps(
      component,
      context,
      repeatedIndex,
      onEvaluateDynamicStyleData,
    );
  return {
    evaluatedProps,
    evaluatedDynamicDataPaths,
  };
};

/**
 * Return the dict of attributes to pass directly as React props to the rendered
 * component DOM node. (This node will be different depending on the component
 * type, but all the components have these props in common)
 */
export const getComponentAttributes = ({
  isLabelledByOtherComponent,
  componentId,
  componentTextProp,
  componentRef,
  context,
  runtimeStyleProps,
  evaluatedComponentProps,
  extraAttributes,
  repeatedIndexPath,
  defaultActions,
  actionContext,
}: {
  componentId: string;
  componentTextProp: string | null;
  context: Context;
  runtimeStyleProps: React.CSSProperties;
  evaluatedComponentProps: Partial<Component["props"]>;
  variants: ReploState[];
  selfOrParentHasVariants: boolean;
  componentRef: React.RefObject<HTMLElement | null>;
  repeatedIndexPath: string;
  extraAttributes: Record<string, any> | undefined;
  isLabelledByOtherComponent: boolean;
  defaultActions: ReploComponentProps["defaultActions"];
  actionContext: AlchemyActionContext;
}) => {
  // NOTE (Matt 2024-02-05): These actionTriggers are used below for creating dataset attributes
  // based on specific click actions. If a component has these actions for an onClick, then
  // we can add a corresponding dataset attribute for the action.
  const actionTriggers: AlchemyActionType[] = [
    "increaseProductQuantity",
    "decreaseProductQuantity",
    "setProductQuantity",
    "setActiveVariant",
    "setActiveOptionValue",
    "addProductVariantToCart",
  ];
  const attributesDerivedFromEvaluatedProps = Object.entries(
    evaluatedComponentProps,
  ).reduce((attributes: any, [propKey, prop]) => {
    // NOTE (Matt, 09-15-23): There is a CustomComponentPropType of 'htmlAttribute'
    // that allows a user to assign HTML attributes to their component. Here we are
    // filtering the array of evaluatedComponentProps to get the array of component
    // props that have type 'htmlAttributes'. We then convert that array into an
    // object called customAttributes, where each key is the dataset attribute name
    // (ie 'data-variant-id') and each value is the value of that attribute.
    // We also have a 'valueType' on each attribute so we know whether to preface
    // the attribute name with `data-`. Additionally, we need to kebab-case the key
    // in order to make sure the attribute can be used.
    if (prop?.type === "htmlAttributes" && prop.values) {
      prop.values.forEach((propValue: any) => {
        const { key, value, valueType } = propValue;
        if (key) {
          const keyPrefix = valueType === "dataset" ? "data-" : "";
          const formattedKey = kebabCase(`${keyPrefix}${key}`);
          if (formattedKey !== "data-rid" && !attributes[formattedKey]) {
            // NOTE (Matt 2023-10-16): Based on REPL 8704, we want to allow users to input a dataset attribute key
            // without having to input a value. This allows them to put empty dataset attributes such as <div data-hi-noah />
            attributes[formattedKey] = value ?? "";
          }
        }
      });
    }
    // NOTE (Matt 2024-02-05): For certain integrations to work (like widebundle), we have added dataset
    // attributes to clickable elements that trigger certain product-component specific actions (ie update variant).
    if (propKey == "onClick") {
      prop?.forEach((action: Action) => {
        const matchingAction = actionTriggers.find(
          (actionType) => actionType == action.type,
        );
        if (matchingAction) {
          const formattedKey = `data-replo-${kebabCase(matchingAction)}`;
          // NOTE (Matt 2024-02-05): Different actions have different values of different types (yeesh)
          // In order to get a value that won't print out as [Object Object] we need to look at both the
          // action.value type as well as the value of matchingAction for more complicated action types.
          const value = exhaustiveSwitch(action)({
            increaseProductQuantity: (action) => action.value,
            decreaseProductQuantity: (action) => action.value,
            setProductQuantity: (action) => action.value,
            setActiveVariant: (action) => action.value?.variantId,
            setActiveOptionValue: (action) => action.value?.value,
            activateTabId: (action) => action.value,
            addTemporaryCartProductsToCart: (action) => action.value,
            addVariantToTemporaryCart: (action) => action.value,
            applyDiscountCode: (action) => action.value,
            clearCart: (action) => action.value,
            close: (action) => action.value,
            closeModalComponent: (action) => action.value,
            decreaseVariantCountInTemporaryCart: (action) => action.value,
            executeJavascript: (action) => action.value,
            goToItem: (action) => action.value,
            goToNextItem: (action) => action.value,
            goToPrevItem: (action) => action.value,
            multipleProductVariantsAddToCart: (action) => action.value,
            openModal: (action) => action.value,
            openKlaviyoModal: (action) => action.value,
            phoneNumber: (action) => action.value,
            redirect: (action) => action.value,
            redirectToProductPage: (action) => action.value,
            removeVariantFromTemporaryCart: (action) => action.value,
            scrollContainerLeft: (action) => action.value,
            scrollContainerRight: (action) => action.value,
            scrollToNextCarouselItem: (action) => action.value,
            scrollToPreviousCarouselItem: (action) => action.value,
            scrollToSpecificCarouselItem: (action) => action.value,
            scrollToUrlHashmark: (action) => action.value,
            setActiveAlchemyVariant: (action) => action.value,
            setActiveSellingPlan: (action) => action.value,
            setActiveTabIndex: (action) => action.value,
            setCurrentCollectionSelection: (action) => action.value,
            setDropdownItem: (action) => action.value,
            toggleCollapsible: (action) => action.value,
            setSelectedListItem: (action) => action.value,
            toggleDropdown: (action) => action.value,
            toggleFullScreen: (action) => action.value,
            toggleMute: (action) => action.value,
            togglePlay: (action) => action.value,
            updateCart: (action) => action.value,
            // NOTE (Matt 2024-03-21): We use an empty string here so that there can just be
            // the dataset attribute for selecting, instead of assigning any complex data as the value.
            addProductVariantToCart: "",
            updateCurrentProduct: (action) =>
              resolveContextValue(action.value)?.id,
          });
          attributes[formattedKey] = value;
        }
      });
    }
    return attributes;
  }, {});

  const componentAttributes: RenderComponentAttributes = {
    ref: componentRef,
    key: componentId,
    "data-rid": componentId,
    ...extraAttributes,
    ...attributesDerivedFromEvaluatedProps,
    "data-replo-repeated-index": repeatedIndexPath,
  };

  // NOTE (Martin, 2023-09-29): runtime calculated styles always come at last
  // because they might to overwrite things that are coming from build time.
  // We should reduce those use cases as we can't use media queries in
  // here but it's nice to have to ability to do it if necessary.
  componentAttributes.style = {
    ...componentAttributes.style,
    ...runtimeStyleProps,
  };

  // NOTE (Matt, 08-18-23): In order for Intelligems and other future integrations to work, we need to
  // add the selected variant ID, product ID and a stable selector to price elements. Prices appear on
  // on components as a Text prop when we select one of several different dynamic data sources. Checking for the {{,
  // a selectedVariant in State and a matching priceLabelKey will help determine whether to add
  // the data-product-id, data-variant-id and price label type attributes.
  if (
    componentTextProp?.includes("{{") &&
    context.state.product?.selectedVariant
  ) {
    const priceLabelKey: VariantPriceLabels[] = [
      "price",
      "priceWithoutSellingPlanDiscount",
      "priceWithoutSellingPlanDiscountRounded",
      "displayPriceWithoutSellingPlanDiscount",
      "priceRounded",
      "compareAtPrice",
      "compareAtPriceRounded",
      "displayPrice",
      "displayPriceRounded",
      "compareAtDisplayPrice",
      "compareAtDisplayPriceRounded",
      "compareAtPriceDifference",
      "compareAtPriceDifferencePercentage",
      "compareAtPriceDifferenceRounded",
      "compareAtDisplayPriceDifference",
      "compareAtDisplayPriceDifferenceRounded",
    ];
    const priceType = priceLabelKey.find((key: string) =>
      componentTextProp?.includes(`{{attributes._variant.${key}}}`),
    );
    if (priceType) {
      const { productId, id } = context.state.product.selectedVariant;
      componentAttributes["data-product-id"] = productId;
      componentAttributes["data-variant-id"] = id;
      if (context.isInsideTemplateProductComponent && context.isPublishing) {
        componentAttributes["data-product-id"] = "{{product.id}}";
        componentAttributes["data-variant-id"] =
          "{{product.selected_or_first_available_variant.id}}";
      }
      if (priceType.includes("SellingPlan")) {
        componentAttributes["data-replo-selling-plan"] = true;
      }
      if (priceType.includes("Percentage")) {
        componentAttributes["data-replo-compare-percentage"] = true;
      } else if (priceType.includes("Difference")) {
        componentAttributes["data-replo-compare-difference"] = true;
      } else if (priceType?.includes("compare")) {
        componentAttributes["data-replo-compare-price"] = true;
      } else {
        componentAttributes["data-replo-price"] = true;
      }
    }
  }

  const hashmark = evaluatedComponentProps["_urlHashmark"];
  if (hashmark) {
    componentAttributes["data-alchemy-url-hashmark"] = hashmark;
    // Note (Noah, 2024-11-27, REPL-14479): Set tabindex="-1", since this element
    // must be focusable in order for us to properly handle screenreader focus
    // in order to focus the element after scroll.
    // Note (Noah, 2024-11-27): This may be overridden later by the default
    // interaction processing, since if it happens that this container also has
    // an action on it, we actually want its tabindex to be 0
    componentAttributes.tabIndex = -1;
    // Note (Noah, 2022-12-08, REPL-5499): Make sure to set the hashmark as the
    // id of the component so that RTE text links to the hashmark can be set up
    // correctly
    componentAttributes.id = hashmark;

    const hashmarkOffset = evaluatedComponentProps["_urlHashmarkOffset"];
    if (typeof hashmarkOffset === "number") {
      componentAttributes["data-alchemy-url-hashmark-offset"] = hashmarkOffset;
    }

    // Note (Noah, 2022-09-06): The default is for the hashmark to NOT be enabled
    // since this is the most common use case, especially for landing pages where
    // buttons at the top scroll to "offer" sections below. However, enabling the
    // hashmark on scroll is a legit use case too (e.g. for Studs with the scrolling
    // menu bar).
    const autoEnableHashmark =
      evaluatedComponentProps["_autoEnableHashmark"] ?? false;
    if (!autoEnableHashmark) {
      componentAttributes["data-alchemy-url-hashmark-ignore"] = true;
    }
  } else if (isLabelledByOtherComponent) {
    // Note (Ovishek, 2022-12-15, REPL-5570): We need this id attribute for 'aria-labeledby' prop this is for
    // accessibility and aria label only works with ids, we support aria-labeledby for containers only now
    componentAttributes.id = componentId;
  }

  // Note (Evan, 2024-03-11): Merge in any defaultActions to get the final
  // onClick and onHover Action[] for rendering.
  const defaultOnClick = defaultActions?.actions.onClick ?? [];
  const defaultOnHover = defaultActions?.actions.onHover ?? [];

  const existingOnClick = evaluatedComponentProps.onClick ?? [];
  const existingOnHover = evaluatedComponentProps.onHover ?? [];

  const placement = defaultActions?.placement ?? "before";

  // Note (Evan, 2024-03-11): Depending on the "placement" value, we either
  // place default actions before or after existing actions (which may include variant overrides)
  // – the default is before.
  const onClick =
    placement === "before"
      ? [...defaultOnClick, ...existingOnClick]
      : [...existingOnClick, ...defaultOnClick];

  const onHover =
    placement === "before"
      ? [...defaultOnHover, ...existingOnHover]
      : [...existingOnHover, ...defaultOnHover];

  if (onClick.length > 0) {
    componentAttributes.tabIndex = 0;
    if (!componentAttributes.role) {
      if (
        onClick.some((action) =>
          ["redirect", "scrollToUrlHashmark", "phoneNumber"].includes(
            action.type,
          ),
        )
      ) {
        componentAttributes.role = "link";
      } else {
        componentAttributes.role = "button";
      }
    }
  }

  if (onClick.length > 0) {
    componentAttributes.onClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      void executeActions(onClick, actionContext, componentRef?.current);
    };

    componentAttributes.onKeyPress = (e: KeyboardEvent) => {
      if ((e.key !== "Enter" && e.key !== " ") || e.code === "Space") {
        return;
      }
      e.stopPropagation();
      e.preventDefault();
      void executeActions(onClick, actionContext, componentRef?.current);
    };
  }

  if (onHover.length > 0) {
    componentAttributes.onMouseEnter = (e: React.MouseEvent) => {
      e.stopPropagation();
      void executeActions(onHover, actionContext);
    };
  }

  return componentAttributes;
};

/**
 * Return a mapping of id -> value for each of the component's custom prop definitions.
 *
 * This handles looking for a value in the evaluatedComponentProps, returning a
 * default value, transforming values like ProductRef -> ReploShopifyProduct, etc.
 */
export const generateCustomPropValues = (
  component: Component,
  context: Context,
  productResolutionDependencies: ProductResolutionDependencies,
  productMetafieldValues: ProductMetafieldMapping,
  variantMetafieldValues: VariantMetafieldMapping,
  evaluatedComponentProps: Component["props"],
  previewEnvironment: RenderPreviewEnvironment | null,
  isInsideTemplateProductComponent?: boolean,
) => {
  const customPropValues: Record<string, any> = {};
  const customPropDefinitions: Record<string, CustomPropDefinition> = keyBy(
    getCustomPropDefinitions(component),
    (def) => def.id,
  );

  for (const customPropDefinition of Object.values(customPropDefinitions)) {
    const customPropValue =
      evaluatedComponentProps[customPropDefinition.id] ??
      customPropDefinition.defaultValue;
    if (
      customPropTypeToRenderData[customPropDefinition.type]
        .excludeFromDynamicData
    ) {
      continue;
    }
    if (customPropDefinition.type === "product") {
      let product = evaluateVariable(
        customPropValue,
        context,
      ) as ProductRefOrDynamic | null;
      /* Variable can evaluate to null if no options match */
      if (!product) {
        continue;
      }
      product = getProduct(product, context, {
        productMetafieldValues,
        variantMetafieldValues,
        products: productResolutionDependencies.products,
        currencyCode: productResolutionDependencies.currencyCode,
        moneyFormat: productResolutionDependencies.moneyFormat,
        language: productResolutionDependencies.language,
        fallbackStrategy:
          previewEnvironment === "componentPreview"
            ? { type: "nthProduct", defaultIndex: 0 }
            : null,
        isInsideTemplateProductComponentOverride:
          isInsideTemplateProductComponent,
        fakeProducts: productResolutionDependencies.fakeProducts,
        templateProduct: productResolutionDependencies.templateProduct,
        isEditor: productResolutionDependencies.isEditor,
      });

      customPropValues[customPropDefinition.id] = product;
    } else if (customPropDefinition.type === "products") {
      if (!customPropValue) {
        continue;
      }
      customPropValues[customPropDefinition.id] = customPropValue.map(
        (p: ProductRef, index: number) => {
          const product = getProduct(p, context, {
            productMetafieldValues,
            variantMetafieldValues,
            products: productResolutionDependencies.products,
            currencyCode: productResolutionDependencies.currencyCode,
            moneyFormat: productResolutionDependencies.moneyFormat,
            language: productResolutionDependencies.language,
            fallbackStrategy:
              previewEnvironment === "componentPreview"
                ? { type: "nthProduct", defaultIndex: index }
                : null,
            isInsideTemplateProductComponentOverride:
              isInsideTemplateProductComponent,
            fakeProducts: productResolutionDependencies.fakeProducts,
            templateProduct: productResolutionDependencies.templateProduct,
            isEditor: productResolutionDependencies.isEditor,
          });
          return product;
        },
      );
    } else {
      customPropValues[customPropDefinition.id] = customPropValue;
    }
  }

  return customPropValues;
};

/**
 * Cache this component's context in the global state so the editor can access it.
 */
export const setGlobalComponentData = (options: {
  componentId: string;
  repeatedIndexPath: string;
  context: Context;
  isEditor: boolean;
}) => {
  const { componentId, repeatedIndexPath, context } = options;
  const lastRepeatedIndex = repeatedIndexPath
    .split(".")
    .splice(-1, 1)[0] as string;
  setCurrentComponentContext(componentId, lastRepeatedIndex, context);
};
