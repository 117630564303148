import type { GlobalWindow } from "../../shared/Window";

import * as React from "react";

import {
  RenderEnvironmentContext,
  RuntimeHooksContext,
  useRuntimeContext,
} from "../../shared/runtime-context";
import { selectRuntimeElementRootNode } from "../../shared/utils/dom";
import ScrollSpy from "../../shared/utils/ScrollSpy";

export const useScrollSpy = (
  reploElementId: string,
  props: {
    globalWindow: GlobalWindow | null;
  },
) => {
  const { globalWindow } = props;
  const scrollSpyRef = React.useRef<ScrollSpy | null>(null);

  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const isPreviewMode = isEditorApp && !isEditorCanvas;
  const shouldEnableScrollSpy = isPreviewMode || !isEditorApp;

  React.useEffect(() => {
    if (!shouldEnableScrollSpy) {
      return;
    }
    // Always set the scroll offset to be the offset of the full page element,
    // since there may be a header etc
    if (globalWindow) {
      const scrollSpy = new ScrollSpy({
        querySelectorNode: selectRuntimeElementRootNode(
          globalWindow.document,
          reploElementId,
        ),
        targetWindow: globalWindow,
        scrollOffset: ScrollSpy.defaultScrollOffset,
        onBecomeActive: (element) => {
          if (
            element.dataset.alchemyUrlHashmark &&
            !element.dataset.alchemyUrlHashmarkIgnore
          ) {
            // NOTE (Evan, 6/29/23) This is a minor hack that prevents the page from
            // auto-scrolling when we change the hash on scroll, to avoid "jumpy" behavior.
            // See:
            // • USE-268 for the issue
            // • https://stackoverflow.com/questions/1489624/modifying-location-hash-without-page-scrolling) for the solution
            const oldId = element.id;
            element.id = "";
            globalWindow.location.hash = element.dataset.alchemyUrlHashmark;
            element.id = oldId;
          }
        },
      });
      scrollSpy.refreshTargets();
      scrollSpyRef.current = scrollSpy;
    }
    return () => {
      // Note (Noah, 2024-12-07): Make sure to reset the scroll spy to null,
      // otherwise it will continue to exist in the background and may cause
      // memory leaks since it retains an array of target DOM nodes
      scrollSpyRef.current = null;
    };
  }, [globalWindow, reploElementId, shouldEnableScrollSpy]);
};
