import type { SharedLiquidReviewsProps } from "../../shared/types";
import type { GlobalWindow } from "../../shared/Window";

import * as React from "react";

import debounce from "lodash-es/debounce";

import {
  GlobalWindowContext,
  RenderEnvironmentContext,
  ReploEditorActiveCanvasContext,
  useRuntimeContext,
} from "../../shared/runtime-context";
import { useProductFromProps } from "../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../utils/reviews";
import { SharedLiquidReviewIntegrations } from "./SharedLiquidReviewIntegrations";

// NOTE (Matt 2024-11-21): We debounce this fn because it can
// severely slow down the editor when there are multiple on the page.
const initWidgets = debounce((globalWindow: GlobalWindow) => {
  if (globalWindow.okeWidgetApi) {
    globalWindow.okeWidgetApi.initAllWidgets();
  }
}, 1000);

export const SharedOkendoReviews = ({
  liquidSource,
  component,
  context,
  attributes,
  reviewsComponentType,
}: SharedLiquidReviewsProps) => {
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const globalWindow = useRuntimeContext(GlobalWindowContext);
  const { activeCanvas } = useRuntimeContext(ReploEditorActiveCanvasContext);

  const product = useProductFromProps(component.props, context);

  const shouldHavePlaceHolder =
    isEditorApp && !globalWindow?.okeWidgetApi && !globalWindow?.okendoReviews;

  // Note (Fran 2022-09-14): Okendo docs link https://intercom.help/okendo-reviews/en/articles/1770332-setting-up-okendo-reviews-on-shopify-vintage-themes
  const liquidSourceStyles = `
      {{ shop.metafields.okendo.ReviewsWidgetStyleTag }}
      {{ shop.metafields.okendo.WidgetPreRenderBodyStyleTags }}
      {{ shop.metafields.okendo.WidgetPreRenderStyleTags }}
      {{ shop.metafields.okendo.ThemeHeaderContent }}`;

  const liquid = wrapProductAssignmentToLiquidSource({
    product,
    liquidSource,
    context,
  });

  // Note (Matt 2023-07-20, REPL-7511): Fix for issue with Okendo Widget not
  // properly loading within Replo Editor. Okendo Widget could not accurately
  // detect the size of the window nor changes in the window size within the
  // Replo Editor. We need to force initAllWidgets to call each time the canvas
  // size changes when in editor mode.
  // biome-ignore lint/correctness/useExhaustiveDependencies: activeCanvas extra dep
  React.useEffect(() => {
    if (isEditorApp && globalWindow?.okeWidgetApi) {
      initWidgets(globalWindow);
    }
  }, [activeCanvas, globalWindow, isEditorApp]);

  return (
    <SharedLiquidReviewIntegrations
      attributes={attributes}
      component={component}
      liquidSource={isEditorApp ? `${liquidSourceStyles} ${liquid}` : liquid}
      placeholder={`Okendo ${reviewsComponentType} Widget will appear here`}
      shouldHavePlaceHolder={shouldHavePlaceHolder || !product}
      appName="Okendo"
      context={context}
    />
  );
};
