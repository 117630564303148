import type { RenderComponentProps } from "replo-runtime/shared/types";
import type { ReploComponentType } from "schemas/component";

import AccordionBlock from "replo-runtime/store/components/AccordionBlock";
import BeforeAfterSlider from "replo-runtime/store/components/BeforeAfterSlider";
import Button from "replo-runtime/store/components/Button";
import BuyWithPrimeButton from "replo-runtime/store/components/BuyWithPrimeButton";
import {
  CarouselV2,
  CarouselV2Indicator,
  CarouselV2Panels,
} from "replo-runtime/store/components/CarouselV2";
import {
  CarouselV3,
  CarouselV3Control,
  CarouselV3Indicators,
  CarouselV3Slides,
} from "replo-runtime/store/components/CarouselV3";
import Collapsible from "replo-runtime/store/components/Collapsible";
import CollapsibleV2 from "replo-runtime/store/components/CollapsibleV2";
import Collection from "replo-runtime/store/components/Collection";
import CollectionSelect from "replo-runtime/store/components/CollectionSelect";
import CollectionV2 from "replo-runtime/store/components/CollectionV2";
import Container from "replo-runtime/store/components/Container";
import CountdownTimer from "replo-runtime/store/components/CountdownTimer";
import Dropdown from "replo-runtime/store/components/Dropdown";
import DynamicCheckoutButtons from "replo-runtime/store/components/DynamicCheckoutButtons";
import FeraMediaGalleryWidget from "replo-runtime/store/components/FeraMediaGalleryWidget";
import FeraProductRatingWidget from "replo-runtime/store/components/FeraProductRatingWidget";
import FeraProductReviewsWidget from "replo-runtime/store/components/FeraProductReviewsWidget";
import FeraStoreReviewsWidget from "replo-runtime/store/components/FeraStoreReviewsWidget";
import FullScreenIcon from "replo-runtime/store/components/FullScreenIcon";
import GoogleMapsEmbed from "replo-runtime/store/components/GoogleMapsEmbed";
import Icon from "replo-runtime/store/components/Icon";
import Image from "replo-runtime/store/components/Image";
import InfiniteOptionsWidget from "replo-runtime/store/components/InfiniteOptionsWidget";
import JudgeProductRatingWidget from "replo-runtime/store/components/JudgeProductRatingWidget";
import JudgeProductReviewsWidget from "replo-runtime/store/components/JudgeProductReviewsWidget";
import JunipProductRating from "replo-runtime/store/components/JunipProductRating";
import JunipReviews from "replo-runtime/store/components/JunipReviews";
import KachingBundles from "replo-runtime/store/components/KachingBundles";
import KlaviyoEmbed from "replo-runtime/store/components/KlaviyoEmbed";
import KnoCommerceWidget from "replo-runtime/store/components/KnoCommerceWidget";
import LooxProductRatingSummary from "replo-runtime/store/components/LooxProductRatingSummary";
import LooxReviewsWidget from "replo-runtime/store/components/LooxReviewsWidget";
import Marquee from "replo-runtime/store/components/Marquee";
import Modal from "replo-runtime/store/components/Modal";
import MuteIcon from "replo-runtime/store/components/MuteIcon";
import OkendoProductRatingSummary from "replo-runtime/store/components/OkendoProductRatingSummary";
import OkendoReviewsWidget from "replo-runtime/store/components/OkendoReviewsWidget";
import OptionSelect from "replo-runtime/store/components/OptionSelect";
import OptionSelectDropdown from "replo-runtime/store/components/OptionSelectDropdown";
import Player from "replo-runtime/store/components/Player";
import PlayIcon from "replo-runtime/store/components/PlayIcon";
import PostscriptSignupForm from "replo-runtime/store/components/PostscriptSignupForm";
import Product from "replo-runtime/store/components/Product";
import ProductCollection from "replo-runtime/store/components/ProductCollection";
import RawHTMLContent from "replo-runtime/store/components/RawHTMLContent";
import RebuyWidget from "replo-runtime/store/components/RebuyWidget";
import RechargeSubscriptionWidget from "replo-runtime/store/components/RechargeSubscriptionWidget";
import ReviewsIoProductRatingSummary from "replo-runtime/store/components/ReviewsIoProductRatingSummary";
import ReviewsIoReviewsWidget from "replo-runtime/store/components/ReviewsIoReviewsWidget";
import SelectionList from "replo-runtime/store/components/SelectionList";
import SellingPlanSelect from "replo-runtime/store/components/SellingPlanSelect";
import SellingPlanSelectDropdown from "replo-runtime/store/components/SellingPlanSelectDropdown";
import ShopifyAppBlocks from "replo-runtime/store/components/ShopifyAppBlocks";
import ShopifyProductRatingWidget from "replo-runtime/store/components/ShopifyProductRatingWidget";
import ShopifyProductReviewsWidget from "replo-runtime/store/components/ShopifyProductReviewsWidget";
import ShopifyRawLiquid from "replo-runtime/store/components/ShopifyRawLiquid";
import ShopifySection from "replo-runtime/store/components/ShopifySection";
import SlidingCarousel from "replo-runtime/store/components/SlidingCarousel";
import Spacer from "replo-runtime/store/components/Spacer";
import Spinner from "replo-runtime/store/components/Spinner";
import StampedProductRatingWidget from "replo-runtime/store/components/StampedProductRatingWidget";
import StampedProductReviewsWidget from "replo-runtime/store/components/StampedProductReviewsWidget";
import StarRating from "replo-runtime/store/components/StarRating";
import StaySubscriptionWidget from "replo-runtime/store/components/StaySubscriptionWidget";
import {
  TabPanel,
  TabsBlock,
  TabsList,
} from "replo-runtime/store/components/TabsBlock";
import {
  TabsBlockV2,
  TabsListV2,
  TabsPanelV2,
} from "replo-runtime/store/components/TabsV2";
import TemporaryCart from "replo-runtime/store/components/TemporaryCart";
import TemporaryCartItems from "replo-runtime/store/components/TemporaryCartItems";
import Text from "replo-runtime/store/components/Text";
import TikTokEmbed from "replo-runtime/store/components/TikTokEmbed";
import Tooltip from "replo-runtime/store/components/Tooltip";
import VariantSelect from "replo-runtime/store/components/VariantSelect";
import VariantSelectDropdown from "replo-runtime/store/components/VariantSelectDropdown";
import VimeoEmbed from "replo-runtime/store/components/VimeoEmbed";
import VimeoEmbedV2 from "replo-runtime/store/components/VimeoEmbedV2";
import YotpoProductRating from "replo-runtime/store/components/YotpoProductRating";
import YotpoReviews from "replo-runtime/store/components/YotpoReviews";
import YoutubeEmbed from "replo-runtime/store/components/YoutubeEmbed";
import YoutubeEmbedV2 from "replo-runtime/store/components/YoutubeEmbedV2";

type RuntimeComponentName = Exclude<ReploComponentType, "symbolRef">;

export type ComponentInventory = {
  [Key in RuntimeComponentName]: React.ComponentType<RenderComponentProps>;
};

export const componentInventory: ComponentInventory = {
  accordionBlock: AccordionBlock,
  button: Button,
  carouselV2: CarouselV2,
  carouselV2__indicator: CarouselV2Indicator,
  carouselV2__panels: CarouselV2Panels,
  carouselV3: CarouselV3,
  carouselV3Control: CarouselV3Control,
  carouselV3Indicators: CarouselV3Indicators,
  carouselV3Slides: CarouselV3Slides,
  carouselPanelsCount: Container,
  collapsible: Collapsible,
  collapsibleV2: CollapsibleV2,
  collapsibleV2Header: Container,
  collapsibleV2Content: Container,
  collection: Collection,
  collectionSelect: CollectionSelect,
  collectionV2: CollectionV2,
  container: Container,
  circle: Container,
  toggleContainer: Container,
  toggleIndicator: Container,
  countdownTimer: CountdownTimer,
  dropdown: Dropdown,
  googleMapsEmbed: GoogleMapsEmbed,
  h1: Text,
  h2: Text,
  h3: Text,
  icon: Icon,
  image: Image,
  junipProductRating: JunipProductRating,
  junipReviews: JunipReviews,
  klaviyoEmbed: KlaviyoEmbed,
  knoCommerceWidget: KnoCommerceWidget,
  looxProductRating: LooxProductRatingSummary,
  looxReviews: LooxReviewsWidget,
  marquee: Marquee,
  modal: Modal,
  okendoProductRatingSummary: OkendoProductRatingSummary,
  okendoReviewsWidget: OkendoReviewsWidget,
  optionSelect: OptionSelect,
  optionSelectDropdown: OptionSelectDropdown,
  player: Player,
  player__fullScreenIcon: FullScreenIcon,
  player__muteIcon: MuteIcon,
  player__playIcon: PlayIcon,
  product: Product,
  productCollection: ProductCollection,
  quantitySelector: Container,
  rawHtmlContent: RawHTMLContent,
  rechargeSubscriptionWidget: RechargeSubscriptionWidget,
  staySubscriptionWidget: StaySubscriptionWidget,
  reviewsIoProductRating: ReviewsIoProductRatingSummary,
  reviewsIoReviews: ReviewsIoReviewsWidget,
  sellingPlanSelectDropdown: SellingPlanSelectDropdown,
  shopifyAppBlocks: ShopifyAppBlocks,
  shopifyRawLiquid: ShopifyRawLiquid,
  shopifySection: ShopifySection,
  slidingCarousel: SlidingCarousel,
  spacer: Spacer,
  spinner: Spinner,
  starRating: StarRating,
  tabs__list: TabsList,
  tabs__onePanelContent: Container,
  tabs__panelsContent: TabPanel,
  tabsBlock: TabsBlock,
  tabsV2__block: TabsBlockV2,
  tabsV2__list: TabsListV2,
  tabsV2__panelsContent: TabsPanelV2,
  temporaryCart: TemporaryCart,
  temporaryCartItems: TemporaryCartItems,
  text: Text,
  tikTokEmbed: TikTokEmbed,
  tooltip: Tooltip,
  tooltipContent: Container,
  variantSelect: VariantSelect,
  variantSelectDropdown: VariantSelectDropdown,
  vimeoEmbed: VimeoEmbed,
  vimeoEmbedV2: VimeoEmbedV2,
  yotpoProductRating: YotpoProductRating,
  yotpoReviews: YotpoReviews,
  youtubeEmbed: YoutubeEmbed,
  youtubeEmbedV2: YoutubeEmbedV2,
  sellingPlanSelect: SellingPlanSelect,
  dynamicCheckoutButtons: DynamicCheckoutButtons,
  rebuyWidget: RebuyWidget,
  buyWithPrimeButton: BuyWithPrimeButton,
  stampedProductReviewsWidget: StampedProductReviewsWidget,
  stampedProductRatingWidget: StampedProductRatingWidget,
  feraProductRatingWidget: FeraProductRatingWidget,
  feraProductReviewsWidget: FeraProductReviewsWidget,
  feraStoreReviewsWidget: FeraStoreReviewsWidget,
  feraMediaGalleryWidget: FeraMediaGalleryWidget,
  shopifyProductReviewsWidget: ShopifyProductReviewsWidget,
  shopifyProductRatingWidget: ShopifyProductRatingWidget,
  judgeProductRatingWidget: JudgeProductRatingWidget,
  judgeProductReviewsWidget: JudgeProductReviewsWidget,
  infiniteOptionsWidget: InfiniteOptionsWidget,
  kachingBundles: KachingBundles,
  postscriptSignupForm: PostscriptSignupForm,
  subscribeAndSave: Container,
  beforeAfterSlider: BeforeAfterSlider,
  beforeAfterSliderThumb: Container,
  beforeAfterSliderBeforeContent: Container,
  beforeAfterSliderAfterContent: Container,
  selectionList: SelectionList,
};

export default componentInventory;
