import * as React from "react";

import { warningToast } from "@editor/components/common/designSystem/Toast";
import { selectIsTextDynamicData } from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorStore } from "@editor/store";

import { setCanvasInteractionMode } from "@/features/canvas/canvas-reducer";

export const useEnableNonDynamicTextEditing = () => {
  const store = useEditorStore();
  const dispatch = useEditorDispatch();

  return React.useCallback(() => {
    const isTextDynamicData = selectIsTextDynamicData(store.getState());
    if (isTextDynamicData) {
      warningToast(
        "Can't edit dynamic data",
        "This component cannot be edited since it contains dynamic data. Remove dynamic data to edit.",
      );
      return;
    }

    dispatch(setCanvasInteractionMode("content-editing"));
  }, [dispatch, store]);
};
