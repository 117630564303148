import type { SolidOrGradient } from "replo-runtime/shared/types";

import React from "react";

import ColorPicker from "@common/designSystem/ColorPicker";
import DynamicDataButton from "@common/designSystem/DynamicDataButton";
import InlinePopover from "@common/designSystem/InlinePopover";
import SelectionIndicator from "@common/designSystem/SelectionIndicator";
import FormFieldXButton from "@common/FormFieldXButton";
import useGetDesignLibrarySavedStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import { selectDraftElementColors } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { getSavedStyleValue } from "@editor/utils/designLibrary";

import { Badge } from "@replo/design-system/components/badge";
import { cssGradientToGradient } from "replo-runtime/shared/utils/gradient";

type DesignLibraryColorValueIndicatorProps = {
  savedStyleValueReference: string;
  onSavedStyleSelect(value: string): void;
  onRemove?(): void;
  popoverSideOffset?: number;
  allowsGradientSelection: boolean;
  onChange(value: string | SolidOrGradient | null): void;
  openDynamicData?: () => void;
};

const DesignLibraryColorValueIndicator: React.FC<
  DesignLibraryColorValueIndicatorProps
> = ({
  savedStyleValueReference,
  onSavedStyleSelect,
  onRemove,
  popoverSideOffset,
  allowsGradientSelection,
  onChange,
  openDynamicData,
}) => {
  const { textSavedStyles, colorSavedStyles } =
    useGetDesignLibrarySavedStyles();
  const savedStyle = getSavedStyleValue(
    // NOTE (Sebas, 2024-11-14): We look for the color in both text and color type saved
    // styles. This is because when we select a text style with a font color we
    // want to show the color in the value indicator.
    [...textSavedStyles, ...colorSavedStyles],
    savedStyleValueReference,
  );

  const badgeValue = savedStyle?.attributes.color;
  const isColorSavedStyleSelected = Boolean(badgeValue);
  const shouldRenderXButton = onRemove && isColorSavedStyleSelected;
  const allowsDynamicData = openDynamicData !== undefined;

  return (
    <div className="flex gap-1 w-full">
      <InlinePopover
        shouldPreventDefaultOnInteractOutside={false}
        title="Color Saved Style"
        content={
          <ColorValueIndicatorPopoverContent
            savedStyleValue={savedStyle?.attributes.color ?? ""}
            allowsGradientSelection={allowsGradientSelection}
            onChange={onChange}
            onSavedStyleSelect={onSavedStyleSelect}
          />
        }
        sideOffset={popoverSideOffset}
        triggerAsChild
      >
        <SelectionIndicator
          title={savedStyle?.name}
          startEnhancer={<ColorBadge color={badgeValue ?? "bg-subtle"} />}
          endEnhancer={
            shouldRenderXButton && <FormFieldXButton onClick={onRemove} />
          }
          style={{
            width: "100%",
          }}
        />
      </InlinePopover>
      {allowsDynamicData && <DynamicDataButton onClick={openDynamicData} />}
    </div>
  );
};

const ColorValueIndicatorPopoverContent: React.FC<
  Omit<DesignLibraryColorValueIndicatorProps, "savedStyleValueReference"> & {
    savedStyleValue: string;
  }
> = ({
  savedStyleValue,
  allowsGradientSelection,
  onChange,
  onSavedStyleSelect,
}) => {
  const elementColors = useEditorSelector(selectDraftElementColors);
  const gradient = savedStyleValue?.startsWith("linear-gradient")
    ? cssGradientToGradient(savedStyleValue)
    : null;

  return allowsGradientSelection ? (
    <ColorPicker
      value={
        gradient
          ? {
              type: "gradient",
              gradient,
            }
          : {
              type: "solid",
              color: savedStyleValue,
            }
      }
      allowsGradientSelection
      onChange={onChange}
      showSavedStyles
      documentColors={elementColors}
      onSavedStyleSelect={onSavedStyleSelect}
    />
  ) : (
    <ColorPicker
      value={savedStyleValue ?? null}
      allowsGradientSelection={false}
      onChange={(value) => onChange(value)}
      showSavedStyles
      documentColors={elementColors}
      onSavedStyleSelect={onSavedStyleSelect}
    />
  );
};

const ColorBadge: React.FC<{ color: string }> = ({ color }) => {
  return <Badge type="color" isFilled backgroundColor={color} />;
};

export default DesignLibraryColorValueIndicator;
