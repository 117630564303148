import type { ResponsiveToggleAllowedDevices } from "@common/designSystem/ResponsiveToggle";
import type { InfiniteLoadingSettings } from "@components/InfiniteLoadingGrid";
import type { ComponentTemplate } from "@editor/types/component-template";
import type { ReploElementType } from "schemas/generated/element";

import * as React from "react";

import ResponsiveToggle from "@common/designSystem/ResponsiveToggle";
import { InfiniteLoadingGrid } from "@components/InfiniteLoadingGrid";
import TemplateCard from "@editor/components/marketplace/TemplateCard";
import { useEditorSelector } from "@editor/store";

import { selectActiveCanvas } from "@/features/canvas/canvas-reducer";
import classNames from "classnames";

type TemplatesGridProps = {
  templates: ComponentTemplate[];
  settings: InfiniteLoadingSettings;
  elementType: ReploElementType;
  /**
   * Id of an element to use as a scroll container. loadMoreItems will be
   * called when the user scrolls to the bottom of this container (defaults
   * to document.body)
   */
  scrollableTargetId: string;
  title?: string | null;
};

type TemplateGridContextProps = {
  device: ResponsiveToggleAllowedDevices;
};

export const TemplateGridContext =
  React.createContext<TemplateGridContextProps>({
    device: "desktop",
  });

const TemplatesGrid: React.FC<React.PropsWithChildren<TemplatesGridProps>> = ({
  templates,
  settings,
  elementType,
  children,
  scrollableTargetId,
  title,
}) => {
  const { hasNextPage, isNextPageLoading, loadNextPage } = settings;
  const loadMoreItems = isNextPageLoading ? () => undefined : loadNextPage;
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const initialDevice: ResponsiveToggleAllowedDevices =
    activeCanvas === "desktop" || activeCanvas === "tablet"
      ? "desktop"
      : "mobile";
  const [device, setDevice] =
    React.useState<TemplateGridContextProps["device"]>(initialDevice);

  return (
    templates.length > 0 && (
      <TemplateGridContext.Provider value={{ device }}>
        <div className="flex flex-col gap-5">
          <div
            className={classNames("flex items-center", {
              "justify-between": title,
              "self-end": !title,
            })}
          >
            {title && (
              <h3 className="text-xl font-medium text-blue-600">{title}</h3>
            )}
            <ResponsiveToggle value={device} setValue={setDevice} size="sm" />
          </div>
          <InfiniteLoadingGrid
            listLength={templates?.length ?? 0}
            loadMoreItems={loadMoreItems}
            hasNextPage={hasNextPage}
            scrollableTargetId={scrollableTargetId}
            className="no-scrollbar flex h-full flex-col"
            loaderLabel="Beep Boop, Loading Sections"
          >
            {children}
            <div
              className={classNames("templates-grid", {
                "grid grid-cols-2 gap-9 xl:grid-cols-3": templates.length < 3,
              })}
            >
              {templates.map((item) => (
                <TemplateCard
                  key={item.id}
                  type="template"
                  componentTemplate={item}
                  elementType={elementType}
                />
              ))}
            </div>
          </InfiniteLoadingGrid>
        </div>
      </TemplateGridContext.Provider>
    )
  );
};

export default TemplatesGrid;
