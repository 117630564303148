import * as React from "react";

/**
 * @deprecated
 * This hook takes a value and returns a ref of the given value and
 * this ref.current value will be always updated as the given value.
 */
export default function useOverridableRef<T>(value: T) {
  const ref = React.useRef<T>(value);

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
}
