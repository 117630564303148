import type { Experiment } from "schemas/generated/experiment";

import { useExperimentApi } from "@components/projectDashboard/experiments/common";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";

import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { getExperimentStatus } from "schemas/experiment";

export type SimpleExperiment = Pick<
  Experiment,
  | "id"
  | "name"
  | "createdAt"
  | "activatedAt"
  | "archivedAt"
  | "completedAt"
  | "description"
  | "variations"
> & {
  analyticsLinkUrl: string | null;
  createdAtHuman: string;
  activatedAtHuman: string | null;
  endedAt: Date | null;
  endedAtHuman: string | null;
};

type UseExperimentDetailsResponse = {
  isLoadingExperiment: boolean;
  simpleExperiment: SimpleExperiment | null;
  experiment: Experiment | null;
  workspaceId: string | undefined;
};

export const useExperimentDetails = (): UseExperimentDetailsResponse => {
  const { experimentId } = useParams();
  const workspaceId = useCurrentWorkspaceId() ?? undefined;

  const {
    list: { data, isFetching: isFetchingExperiments },
    links: { data: links, isFetching: isFetchingLinks },
  } = useExperimentApi({ workspaceId, projectId: null });

  const experiment = data?.experiments.find((exp) => exp.id === experimentId);

  if (
    isFetchingExperiments ||
    isFetchingLinks ||
    !links ||
    !experiment ||
    !workspaceId
  ) {
    return {
      isLoadingExperiment: true,
      simpleExperiment: null,
      experiment: null,
      workspaceId,
    };
  }

  const { id, createdAt, activatedAt, archivedAt, completedAt, name } =
    experiment;
  const link = links?.find((link) => link.experimentId === experimentId);

  const status = getExperimentStatus(experiment);
  const description = experiment.description;
  const createdAtHuman = format(createdAt, "MMMM d, hh:mma");
  const activatedAtHuman =
    activatedAt && status !== "draft"
      ? format(activatedAt, "MMMM d, hh:mma")
      : null;

  const endedAt =
    status === "archived" || status === "complete"
      ? completedAt ?? archivedAt
      : null;

  const endedAtHuman = endedAt ? format(endedAt, "MMMM d, hh:mma") : null;

  const analyticsLinkUrl = link?.url ?? null;
  const variations = experiment.variations;

  return {
    isLoadingExperiment: false,
    simpleExperiment: {
      id,
      name,
      createdAt,
      activatedAt,
      archivedAt,
      completedAt,
      analyticsLinkUrl,
      createdAtHuman,
      activatedAtHuman,
      endedAt,
      endedAtHuman,
      description,
      variations,
    },
    workspaceId,
    experiment,
  };
};
