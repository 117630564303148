import type { Command } from "@editor/utils/commands";

import * as React from "react";

import {
  infoToast,
  successToast,
} from "@editor/components/common/designSystem/Toast";
import { useRichTextComponent } from "@editor/components/RichTextComponentContext";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import useContextMenuActions from "@editor/hooks/useContextMenuActions";
import useGetFlexboxWidthOrHeightOnChange from "@editor/hooks/useGetFlexboxWidthOrHeightOnChange";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import useOpenCodeEditor from "@editor/hooks/useOpenCodeEditor";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { useVisibleBreakpoints } from "@editor/hooks/useVisibleBreakpoints";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import {
  redoOperation,
  selectComponentDataMapping,
  selectDraftComponentId,
  selectDraftComponentIds,
  selectDraftComponentName,
  selectDraftComponentOrAncestorVariantId,
  selectDraftComponentType,
  selectEditorMode,
  selectFontSize,
  selectFontStyle,
  selectFontWeight,
  selectLetterSpacing,
  selectLineHeight,
  selectProjectId,
  selectTextDecoration,
  selectValidNextOperation,
  selectValidPreviousOperation,
  setEditorMode,
  undoOperation,
} from "@editor/reducers/core-reducer";
import {
  selectLastMarketplacePath,
  setLastMarketplacePath,
} from "@editor/reducers/marketplace-reducer";
import {
  selectIsRichTextEditorFocused,
  setLeftBarActiveTab,
  setOpenPopoverId,
  setRightBarActiveTab,
} from "@editor/reducers/ui-reducer";
import { useEditorDispatch, useEditorStore } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { canvasToStyleMap } from "@editor/utils/editor";
import { routes } from "@editor/utils/router";

import { handleTransformWillChange } from "@/features/canvas/canvas-actions";
import { INITIAL_CANVAS_SCALE } from "@/features/canvas/canvas-constants";
import {
  selectActiveCanvas,
  selectCanvasInteractionMode,
  setCanvasInteractionMode,
} from "@/features/canvas/canvas-reducer";
import { useCanvasZoom } from "@/features/canvas/useCanvasZoom";
import { generatePath, useNavigate } from "react-router-dom";
import { parseUnit } from "replo-runtime/shared/utils/units";

export function useGlobalEditorActions() {
  const store = useEditorStore();
  const dispatch = useEditorDispatch();
  const navigate = useNavigate();
  const logEvent = useLogAnalytics();
  // TODO (Noah, 2022-02-07): These actions are no longer really specific to
  // the context menu - they should be moved out of this hook and just made
  // available as either util functions (e.g. canPaste) or ComponentActionTypes
  // (e.g. paste) or normal redux actions (e.g. copy)
  const contextMenuActions = useContextMenuActions();
  const setDraftElement = useSetDraftElement();
  const modal = useModal();
  const { isMenuOpen: isAIMenuOpen, setIsMenuOpen: setIsAIMenuOpen } =
    useAIStreaming();
  const openCodeEditor = useOpenCodeEditor();
  const onFlexboxWidthOrHeightChange = useGetFlexboxWidthOrHeightOnChange();
  const { handleCanvasZoom, handleCanvasZoomIn, handleCanvasZoomOut } =
    useCanvasZoom();
  const applyComponentAction = useApplyComponentAction();
  const { onChangeVisibility } = useVisibleBreakpoints();
  const { setTag, toggleBulletList, toggleNumberedList } =
    useRichTextComponent();

  const handlePaste = React.useCallback(
    (event: ClipboardEvent) => {
      const isRichTextEditorFocused = selectIsRichTextEditorFocused(
        store.getState(),
      );
      if (
        isRichTextEditorFocused ||
        [HTMLInputElement, HTMLTextAreaElement, HTMLSelectElement].some(
          (elementType) => {
            return event.target instanceof elementType;
          },
        )
      ) {
        return;
      }

      void contextMenuActions?.handlePaste("normal", event.clipboardData);
    },
    [contextMenuActions, store],
  );

  const handleCopy = React.useCallback(
    (event: ClipboardEvent) => {
      const draftComponentIds = selectDraftComponentIds(store.getState());
      const selection = document.getSelection()?.toString();
      if (selection) {
        return;
      }

      if (draftComponentIds.length > 0) {
        contextMenuActions?.handleCopy(draftComponentIds, event.clipboardData);
      }
      event.preventDefault();
    },
    [contextMenuActions, store],
  );

  const handleDelete = React.useCallback(() => {
    const draftComponentIds = selectDraftComponentIds(store.getState());
    if (draftComponentIds.length > 1) {
      contextMenuActions?.onGroupDelete(draftComponentIds);
    } else if (draftComponentIds.length > 0) {
      contextMenuActions?.onDelete(draftComponentIds[0]!, "hotkey");
    }
  }, [store, contextMenuActions]);

  const handleDuplicate = React.useCallback(() => {
    const draftComponentId = selectDraftComponentId(store.getState());
    if (draftComponentId) {
      contextMenuActions?.handleDuplicate(draftComponentId);
    }
  }, [contextMenuActions, store]);

  const handleCopyStyles = React.useCallback(() => {
    const draftComponentId = selectDraftComponentId(store.getState());
    if (draftComponentId) {
      const activeDraftComponentOrAncestorVariantId =
        selectDraftComponentOrAncestorVariantId(store.getState());
      contextMenuActions?.handleCopyStyles(
        draftComponentId,
        activeDraftComponentOrAncestorVariantId,
      );
    }
  }, [contextMenuActions, store]);

  const handlePasteStyles = React.useCallback(() => {
    void contextMenuActions?.handlePaste("alt");
  }, [contextMenuActions]);

  const handleGroupIntoContainer = React.useCallback(() => {
    const draftComponentIds = selectDraftComponentIds(store.getState());
    if (draftComponentIds.length > 0) {
      contextMenuActions?.onGroupContainer(draftComponentIds);
    }
  }, [contextMenuActions, store]);

  const handleTogglePreviewMode = React.useCallback(() => {
    const editorMode = selectEditorMode(store.getState());
    const isEditMode = editorMode === EditorMode.edit;
    dispatch(setEditorMode(isEditMode ? EditorMode.preview : EditorMode.edit));
  }, [dispatch, store]);

  const handleToggleAIMenu = React.useCallback(() => {
    if (isAIMenuOpen) {
      setIsAIMenuOpen(false);
    }
    // Note (Evan, 2024-07-16) If the AI menu is enabled, it's not a modal,
    // so we can just toggle without any additional checks or anything
    else {
      setIsAIMenuOpen(true, "shortcut");
    }
  }, [isAIMenuOpen, setIsAIMenuOpen]);

  const handleToggleCodeEditor = React.useCallback(() => {
    if (openCodeEditor) {
      openCodeEditor?.();
    } else {
      infoToast(
        "Code Editor not available",
        "To open the code editor, please select a custom HTML, liquid, or integration component.",
      );
    }
  }, [openCodeEditor]);

  const handleToggleVersionHistory = React.useCallback(() => {
    const editorMode = selectEditorMode(store.getState());
    const isEditMode = editorMode === EditorMode.edit;
    dispatch(
      setEditorMode(isEditMode ? EditorMode.versioning : EditorMode.edit),
    );
  }, [dispatch, store]);

  const handleOpenProjectSettings = React.useCallback(() => {
    const projectId = selectProjectId(store.getState());
    const path = generatePath(routes.editor.settings, { projectId });
    navigate(path);
  }, [navigate, store]);

  const handleOpenPageSettings = React.useCallback(() => {
    modal.openModal({
      type: "updateElementModal",
    });
  }, [modal]);

  const handleMoveUpInTheTree = React.useCallback(() => {
    const draftComponentId = selectDraftComponentId(store.getState());
    if (draftComponentId) {
      const componentDataMapping = selectComponentDataMapping(store.getState());
      const draftComponentData = componentDataMapping[draftComponentId];
      const ancestors = draftComponentData?.ancestorComponentData;
      if (ancestors) {
        const nearAncestor = ancestors[ancestors.length - 1];
        const nearAncestorId = nearAncestor?.[0];
        if (nearAncestorId) {
          setDraftElement({ componentIds: [nearAncestorId] });
        }
      }
    }
  }, [store, setDraftElement]);

  const handleMoveDownInTheTree = React.useCallback(() => {
    const draftComponentId = selectDraftComponentId(store.getState());
    if (draftComponentId) {
      const componentDataMapping = selectComponentDataMapping(store.getState());
      const draftComponentData = componentDataMapping[draftComponentId];
      const descendants = draftComponentData?.containedComponentData;
      if (descendants) {
        const nearDescendant = descendants[0];
        const nearDescendantId = nearDescendant?.[0];
        if (nearDescendantId) {
          setDraftElement({ componentIds: [nearDescendantId] });
        }
      }
    }
  }, [store, setDraftElement]);

  const handleOpenExportToSectionModal = React.useCallback(() => {
    const draftComponentId = selectDraftComponentId(store.getState());
    if (draftComponentId) {
      modal.openModal({
        type: "exportToSectionModal",
      });
    }
  }, [store, modal]);

  const handleSetWidthToFillAvailable = React.useCallback(
    () => onFlexboxWidthOrHeightChange("fill", "width"),
    [onFlexboxWidthOrHeightChange],
  );

  const handleSetWidthToWrapContent = React.useCallback(
    () => onFlexboxWidthOrHeightChange("wrap", "width"),
    [onFlexboxWidthOrHeightChange],
  );

  const handleSetHeightToFillAvailable = React.useCallback(
    () => onFlexboxWidthOrHeightChange("fill", "height"),
    [onFlexboxWidthOrHeightChange],
  );

  const handleSetHeightToWrapContent = React.useCallback(
    () => onFlexboxWidthOrHeightChange("wrap", "height"),
    [onFlexboxWidthOrHeightChange],
  );

  const handleOpenMarketplace = React.useCallback(() => {
    const draftComponentId = selectDraftComponentId(store.getState());
    const lastPath =
      selectLastMarketplacePath(store.getState()) ?? routes.marketplaceModal;
    logEvent("editor.marketplace.browse", {
      from: "shortcut",
    });
    dispatch(setLastMarketplacePath(null));
    navigate(lastPath, {
      state: {
        marketplaceModalRequestType: draftComponentId ? "browse" : "create",
      },
    });
  }, [logEvent, dispatch, store, navigate]);

  const handleUndo = React.useCallback(() => {
    const hasPreviousOperation = selectValidPreviousOperation(store.getState());
    if (hasPreviousOperation) {
      dispatch(undoOperation());
    }
  }, [dispatch, store]);

  const handleRedo = React.useCallback(() => {
    const hasNextOperation = selectValidNextOperation(store.getState());
    if (hasNextOperation) {
      dispatch(redoOperation());
    }
  }, [dispatch, store]);

  const handleSaveComponentTemplate = React.useCallback(() => {
    const draftComponentId = selectDraftComponentId(store.getState());
    const draftComponentName = selectDraftComponentName(store.getState());
    if (draftComponentId) {
      modal.openModal({
        type: "saveTemplateModal",
        props: { initialName: draftComponentName },
      });
    }
  }, [store, modal]);

  const handleMockSave = React.useCallback(() => {
    successToast(`Changes Saved Automatically ✌️`, "");
  }, []);

  const handleResetZoom = React.useCallback(() => {
    handleCanvasZoom(INITIAL_CANVAS_SCALE);
  }, [handleCanvasZoom]);

  const handleSetDesignPanel = React.useCallback(() => {
    dispatch(setRightBarActiveTab("design"));
  }, [dispatch]);

  const handleSetConfigPanel = React.useCallback(() => {
    dispatch(setRightBarActiveTab("custom"));
  }, [dispatch]);

  const handleSetInteractionsPanel = React.useCallback(() => {
    dispatch(setRightBarActiveTab("interactions"));
  }, [dispatch]);

  const handleSetLayersPanel = React.useCallback(() => {
    logEvent("editor.panel.toggle", {
      toggle: "tree",
      isOpened: true,
      openedWithShortcut: true,
    });
    dispatch(setLeftBarActiveTab("tree"));
  }, [dispatch, logEvent]);

  const handleSetElementsPanel = React.useCallback(() => {
    logEvent("editor.panel.toggle", {
      toggle: "elements",
      isOpened: true,
      openedWithShortcut: true,
    });
    dispatch(setLeftBarActiveTab("elements"));
  }, [dispatch, logEvent]);

  const handleSetComponentsPanel = React.useCallback(() => {
    logEvent("editor.panel.toggle", {
      toggle: "components",
      isOpened: true,
      openedWithShortcut: true,
    });
    dispatch(setLeftBarActiveTab("components"));
  }, [dispatch, logEvent]);

  const handleSetSavedStylesPanel = React.useCallback(() => {
    logEvent("editor.panel.toggle", {
      toggle: "savedStyles",
      isOpened: true,
      openedWithShortcut: true,
    });
    dispatch(setLeftBarActiveTab("savedStyles"));
  }, [dispatch, logEvent]);

  const handleGrabCanvas = React.useCallback(
    (e: KeyboardEvent | React.KeyboardEvent<HTMLElement>) => {
      if (selectCanvasInteractionMode(store.getState()) === "locked") {
        return;
      }
      const { type } = e;
      dispatch(handleTransformWillChange());
      dispatch(
        setCanvasInteractionMode(type === "keydown" ? "readyToGrab" : "edit"),
      );
    },
    [dispatch, store],
  );

  const handleToggleVisibility = React.useCallback(() => {
    const activeCanvas = selectActiveCanvas(store.getState());
    onChangeVisibility(canvasToStyleMap[activeCanvas]);
  }, [onChangeVisibility, store]);

  const handleToggleBoldText = React.useCallback(() => {
    const draftComponentType = selectDraftComponentType(store.getState());
    if (draftComponentType === "text") {
      const fontWeight = selectFontWeight(store.getState());
      applyComponentAction({
        type: "setStyles",
        value: {
          fontWeight: fontWeight === "700" ? null : "700",
        },
      });
    }
  }, [applyComponentAction, store]);

  const handleToggleItalicText = React.useCallback(() => {
    const draftComponentType = selectDraftComponentType(store.getState());
    const fontStyle = selectFontStyle(store.getState());

    if (draftComponentType === "text") {
      applyComponentAction({
        type: "setStyles",
        value: {
          fontStyle: fontStyle === "italic" ? null : "italic",
        },
      });
    }
  }, [applyComponentAction, store]);

  const handleToggleUnderlineText = React.useCallback(() => {
    const draftComponentType = selectDraftComponentType(store.getState());
    const textDecoration = selectTextDecoration(store.getState());

    if (draftComponentType === "text") {
      applyComponentAction({
        type: "setStyles",
        value: {
          textDecoration: textDecoration === "underline" ? null : "underline",
        },
      });
    }
  }, [applyComponentAction, store]);

  const handleToggleStrikethroughText = React.useCallback(() => {
    const draftComponentType = selectDraftComponentType(store.getState());
    const textDecoration = selectTextDecoration(store.getState());

    if (draftComponentType === "text") {
      applyComponentAction({
        type: "setStyles",
        value: {
          textDecoration: textDecoration ? null : "line-through",
        },
      });
    }
  }, [applyComponentAction, store]);

  const handleToggleLinkText = React.useCallback(() => {
    dispatch(setOpenPopoverId("tiptap-toolbar-link"));
  }, [dispatch]);

  const handleToggleH1Text = React.useCallback(() => {
    setTag("1");
  }, [setTag]);

  const handleToggleH2Text = React.useCallback(() => {
    setTag("2");
  }, [setTag]);

  const handleToggleH3Text = React.useCallback(() => {
    setTag("3");
  }, [setTag]);

  const handleToggleH4Text = React.useCallback(() => {
    setTag("4");
  }, [setTag]);

  const handleToggleH5Text = React.useCallback(() => {
    setTag("5");
  }, [setTag]);

  const handleToggleH6Text = React.useCallback(() => {
    setTag("6");
  }, [setTag]);

  const handleToggleBulletList = React.useCallback(() => {
    toggleBulletList();
  }, [toggleBulletList]);

  const handleToggleNumberedList = React.useCallback(() => {
    toggleNumberedList();
  }, [toggleNumberedList]);

  const getFontSizeData = React.useCallback(() => {
    const fontSize = selectFontSize(store.getState());
    const { value, unit } = parseUnit(
      fontSize ?? 16,
      { value: "", unit: "" },
      "default",
      "px",
    );
    const newValue = typeof value === "string" ? Number.parseInt(value) : value;

    return {
      value: newValue,
      unit,
    };
  }, [store]);

  const adjustFontSize = React.useCallback(
    (type: "decrease" | "increase") => {
      const { value, unit } = getFontSizeData();

      let newFontSize;
      if (type === "decrease") {
        if (value === 1) {
          return;
        }
        newFontSize = Math.max(1, value - 1);
      } else {
        newFontSize = value + 1;
      }

      applyComponentAction({
        type: "setStyles",
        value: {
          fontSize: `${newFontSize}${unit}`,
        },
      });
    },
    [applyComponentAction, getFontSizeData],
  );

  const handleDecreaseFontSize = React.useCallback(() => {
    adjustFontSize("decrease");
  }, [adjustFontSize]);

  const handleIncreaseFontSize = React.useCallback(() => {
    adjustFontSize("increase");
  }, [adjustFontSize]);

  const getLetterSpacingData = React.useCallback(() => {
    const letterSpacing = selectLetterSpacing(store.getState());
    const { value, unit } = parseUnit(
      letterSpacing ?? 0,
      { value: "", unit: "" },
      "default",
      "px",
    );
    const newValue = typeof value === "string" ? Number.parseInt(value) : value;

    return {
      value: newValue,
      unit,
    };
  }, [store]);

  const adjustLetterSpacing = React.useCallback(
    (type: "decrease" | "increase") => {
      const { value, unit } = getLetterSpacingData();

      let newLetterSpacing;
      if (type === "decrease") {
        if (value === 0) {
          return;
        } else if (value === 1) {
          newLetterSpacing = null;
        } else {
          newLetterSpacing = value - 1;
        }
      } else {
        newLetterSpacing = value + 1;
      }

      applyComponentAction({
        type: "setStyles",
        value: {
          letterSpacing:
            newLetterSpacing !== null
              ? `${newLetterSpacing}${unit}`
              : newLetterSpacing,
        },
      });
    },
    [applyComponentAction, getLetterSpacingData],
  );

  const handleDecreaseLetterSpacing = React.useCallback(() => {
    adjustLetterSpacing("decrease");
  }, [adjustLetterSpacing]);

  const handleIncreaseLetterSpacing = React.useCallback(() => {
    adjustLetterSpacing("increase");
  }, [adjustLetterSpacing]);

  const getLineHeightData = React.useCallback(() => {
    const lineHeight = selectLineHeight(store.getState());
    const { value, unit } = parseUnit(
      lineHeight ?? 0,
      { value: "", unit: "" },
      "default",
      "px",
    );
    const newValue = typeof value === "string" ? Number.parseInt(value) : value;

    return {
      value: newValue,
      unit,
    };
  }, [store]);

  const adjustLineHeight = React.useCallback(
    (type: "decrease" | "increase") => {
      const { value, unit } = getLineHeightData();

      let newLineHeight;
      if (type === "decrease") {
        if (value === 0) {
          return;
        } else if (value === 1) {
          newLineHeight = null;
        } else {
          newLineHeight = value - 1;
        }
      } else {
        newLineHeight = value + 1;
      }

      applyComponentAction({
        type: "setStyles",
        value: {
          lineHeight:
            newLineHeight !== null ? `${newLineHeight}${unit}` : newLineHeight,
        },
      });
    },
    [applyComponentAction, getLineHeightData],
  );

  const handleDecreaseLineHeight = React.useCallback(() => {
    adjustLineHeight("decrease");
  }, [adjustLineHeight]);

  const handleIncreaseLineHeight = React.useCallback(() => {
    adjustLineHeight("increase");
  }, [adjustLineHeight]);

  const adjustFontWeight = React.useCallback(
    (type: "decrease" | "increase") => {
      const fontWeights = [100, 200, 300, 400, 500, 600, 700, 800, 900, 950];
      const fontWeight = selectFontWeight(store.getState());
      const currentFontWeight = fontWeight ? Number(fontWeight) : 400;
      let currentIndex = fontWeights.indexOf(currentFontWeight);

      if (type === "decrease") {
        if (currentIndex > 0) {
          currentIndex -= 1;
        } else {
          return;
        }
      } else {
        if (currentIndex < fontWeights.length - 1) {
          currentIndex += 1;
        } else {
          return;
        }
      }

      const newFontWeight = fontWeights[currentIndex];

      applyComponentAction({
        type: "setStyles",
        value: {
          fontWeight: newFontWeight,
        },
      });
    },
    [applyComponentAction, store],
  );

  const handleDecreaseFontWeight = React.useCallback(() => {
    adjustFontWeight("decrease");
  }, [adjustFontWeight]);

  const handleIncreaseFontWeight = React.useCallback(() => {
    adjustFontWeight("increase");
  }, [adjustFontWeight]);

  return {
    toggleH1Text: handleToggleH1Text,
    toggleH2Text: handleToggleH2Text,
    toggleH3Text: handleToggleH3Text,
    toggleH4Text: handleToggleH4Text,
    toggleH5Text: handleToggleH5Text,
    toggleH6Text: handleToggleH6Text,
    toggleBoldText: handleToggleBoldText,
    toggleLinkText: handleToggleLinkText,
    toggleItalicText: handleToggleItalicText,
    toggleUnderlineText: handleToggleUnderlineText,
    toggleStrikethroughText: handleToggleStrikethroughText,
    toggleAIMenu: handleToggleAIMenu,
    toggleBulletList: handleToggleBulletList,
    toggleNumberedList: handleToggleNumberedList,
    decreaseFontSize: handleDecreaseFontSize,
    increaseFontSize: handleIncreaseFontSize,
    decreaseLetterSpacing: handleDecreaseLetterSpacing,
    increaseLetterSpacing: handleIncreaseLetterSpacing,
    decreaseLineHeight: handleDecreaseLineHeight,
    increaseLineHeight: handleIncreaseLineHeight,
    decreaseFontWeight: handleDecreaseFontWeight,
    increaseFontWeight: handleIncreaseFontWeight,
    undo: handleUndo,
    redo: handleRedo,
    copy: handleCopy,
    copyStyles: handleCopyStyles,
    paste: handlePaste,
    pasteStyles: handlePasteStyles,
    delete: handleDelete,
    duplicate: handleDuplicate,
    groupIntoContainer: handleGroupIntoContainer,
    saveComponentTemplate: handleSaveComponentTemplate,
    mockSave: handleMockSave,
    exportToSection: handleOpenExportToSectionModal,
    setWidthToFillAvailable: handleSetWidthToFillAvailable,
    setWidthToWrapContent: handleSetWidthToWrapContent,
    setHeightToFillAvailable: handleSetHeightToFillAvailable,
    setHeightToWrapContent: handleSetHeightToWrapContent,
    toggleCodeEditor: handleToggleCodeEditor,
    toggleVisibility: handleToggleVisibility,
    togglePreviewMode: handleTogglePreviewMode,
    grabCanvas: handleGrabCanvas,
    toggleVersionHistory: handleToggleVersionHistory,
    setDesignPanel: handleSetDesignPanel,
    setConfigPanel: handleSetConfigPanel,
    setInteractionsPanel: handleSetInteractionsPanel,
    setElementsPanel: handleSetElementsPanel,
    setLayersPanel: handleSetLayersPanel,
    setComponentsPanel: handleSetComponentsPanel,
    setSavedStylesPanel: handleSetSavedStylesPanel,
    openPageSettings: handleOpenPageSettings,
    openProjectSettings: handleOpenProjectSettings,
    moveUpInTheTree: handleMoveUpInTheTree,
    moveDownInTheTree: handleMoveDownInTheTree,
    openMarketplace: handleOpenMarketplace,
    zoomIn: handleCanvasZoomIn,
    zoomOut: handleCanvasZoomOut,
    resetZoom: handleResetZoom,
  } satisfies Record<Command, Function>;
}
