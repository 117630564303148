import {
  errorToast,
  successToast,
} from "@editor/components/common/designSystem/Toast";
import { selectProjectId } from "@editor/reducers/core-reducer";
import { useEditorStore } from "@editor/store";
import { trpc, trpcUtils } from "@editor/utils/trpc";

function useDeleteDesignLibrarySavedStyle() {
  const store = useEditorStore();
  const { mutate, isPending } =
    trpc.designLibrary.savedStyles.delete.useMutation({
      onSuccess: () => {
        successToast(
          "Saved style deleted",
          "The saved style selected was deleted",
        );
      },
      onError: () => {
        errorToast(
          "Failed to delete saved style",
          "The saved style selected could not be deleted",
        );
      },
      onSettled: () => {
        const projectId = selectProjectId(store.getState());

        if (!projectId) {
          return;
        }

        void trpcUtils.designLibrary.get.invalidate({ projectId });
      },
    });

  return {
    deleteSavedStyle: (savedStyleId: string) => {
      const projectId = selectProjectId(store.getState());

      if (!projectId) {
        return;
      }

      mutate({
        savedStyleId,
        projectId,
      });
    },
    isLoading: isPending,
  };
}

export default useDeleteDesignLibrarySavedStyle;
