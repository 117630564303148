import type { SvgProps } from "replo-utils/react/components";

import * as React from "react";

import { Svg } from "replo-utils/react/components";

interface SvgMarketplaceTemplateBadgeProps extends SvgProps {
  primaryColor?: string;
  secondaryColor?: string;
}

const SvgMarketplaceTemplateBadge = (
  props: SvgMarketplaceTemplateBadgeProps,
) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? 139}
    height={45}
    fill="none"
    {...props}
  >
    <path
      fill={props.primaryColor ?? "#2563EB"}
      d={`M0 0h${props.width ?? 139}l-4.723 8-4.724 8L${
        props.width ?? 139
      } 32H0V0Z`}
    />
    <path
      fill={props.secondaryColor ?? "#1E40AF"}
      d="M14.444 32.000H0l14.444 12.222V32.222Z"
    />
  </Svg>
);

export default SvgMarketplaceTemplateBadge;
