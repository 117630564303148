import isLiquidAllowed from "replo-runtime/utils/isLiquidAllowed";

import {
  ExtraContext,
  RenderEnvironmentContext,
  ReploElementContext,
  useRuntimeContext,
} from "../../shared/runtime-context";

export function useCanUseLiquid() {
  const { isPublishing } = useRuntimeContext(RenderEnvironmentContext);
  const { elementType } = useRuntimeContext(ReploElementContext);
  const { disableLiquid } = useRuntimeContext(ExtraContext);
  return isLiquidAllowed({ elementType, isPublishing, disableLiquid });
}
