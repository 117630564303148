import type { IconType } from "react-icons";
import type { ReploComponentType } from "schemas/component";
import type { HotkeyIndicatorCharacter } from "./hotkeys";

import { AiOutlineInteraction } from "react-icons/ai";
import {
  BiDuplicate,
  BiHistory,
  BiPaste,
  BiPlay,
  BiRedo,
  BiUndo,
} from "react-icons/bi";
import {
  BsArrowDown,
  BsArrowUp,
  BsBoxArrowUp,
  BsCode,
  BsCopy,
  BsDatabase,
  BsEye,
  BsGear,
  BsLayers,
  BsLink,
  BsList,
  BsListOl,
  BsListUl,
  BsPalette,
  BsSave,
  BsSliders,
  BsTrash,
  BsTypeBold,
  BsTypeH1,
  BsTypeH2,
  BsTypeH3,
  BsTypeH4,
  BsTypeH5,
  BsTypeH6,
  BsTypeItalic,
  BsTypeStrikethrough,
  BsTypeUnderline,
  BsZoomIn,
  BsZoomOut,
} from "react-icons/bs";
import { CgComponents, CgTemplate } from "react-icons/cg";
import { FaLayerGroup } from "react-icons/fa";
import { FiGlobe } from "react-icons/fi";
import { HiOutlineHand } from "react-icons/hi";
import {
  MdHeight,
  MdOutlineDesignServices,
  MdWidthFull,
  MdWidthNormal,
} from "react-icons/md";
import { PiMagicWand, PiMagnifyingGlass, PiTextAaBold } from "react-icons/pi";
import { RiFontSize, RiLineHeight } from "react-icons/ri";
import { RxLetterSpacing } from "react-icons/rx";

type TextCommand =
  | "toggleH1Text"
  | "toggleH2Text"
  | "toggleH3Text"
  | "toggleH4Text"
  | "toggleH5Text"
  | "toggleH6Text"
  | "toggleBoldText"
  | "toggleLinkText"
  | "toggleItalicText"
  | "toggleUnderlineText"
  | "toggleStrikethroughText"
  | "toggleAIMenu"
  | "toggleBulletList"
  | "toggleNumberedList"
  | "decreaseFontSize"
  | "increaseFontSize"
  | "decreaseLetterSpacing"
  | "increaseLetterSpacing"
  | "decreaseLineHeight"
  | "increaseLineHeight"
  | "decreaseFontWeight"
  | "increaseFontWeight";

type EditCommand =
  | "undo"
  | "redo"
  | "copy"
  | "copyStyles"
  | "paste"
  | "pasteStyles"
  | "delete"
  | "duplicate"
  | "groupIntoContainer"
  | "saveComponentTemplate"
  | "mockSave"
  | "exportToSection"
  | "setWidthToFillAvailable"
  | "setWidthToWrapContent"
  | "setHeightToFillAvailable"
  | "setHeightToWrapContent"
  | "toggleCodeEditor";

type ViewCommand =
  | "toggleVisibility"
  | "togglePreviewMode"
  | "grabCanvas"
  | "toggleVersionHistory"
  | "toggleAIMenu"
  | "setDesignPanel"
  | "setConfigPanel"
  | "setInteractionsPanel"
  | "setElementsPanel"
  | "setLayersPanel"
  | "setComponentsPanel"
  | "setSavedStylesPanel"
  | "openPageSettings"
  | "openProjectSettings"
  | "moveUpInTheTree"
  | "moveDownInTheTree"
  | "openMarketplace"
  | "zoomIn"
  | "zoomOut"
  | "resetZoom";

export type Command = EditCommand | TextCommand | ViewCommand;

type CommandData = {
  label: string;
  hotkey?: HotkeyIndicatorCharacter[];
  icon?: IconType;
};

export const Commands: Record<Command, CommandData> = {
  undo: {
    label: "Undo",
    hotkey: ["meta", "z"],
    icon: BiUndo,
  },
  redo: {
    label: "Redo",
    hotkey: ["meta", "shift", "z"],
    icon: BiRedo,
  },
  copy: {
    label: "Copy",
    hotkey: ["meta", "c"],
    icon: BsCopy,
  },
  copyStyles: {
    label: "Copy styles",
    hotkey: ["altOption", "meta", "c"],
    icon: BsPalette,
  },
  paste: {
    label: "Paste",
    hotkey: ["meta", "v"],
    icon: BiPaste,
  },
  pasteStyles: {
    label: "Paste styles",
    hotkey: ["altOption", "meta", "v"],
    icon: BsPalette,
  },
  toggleVisibility: {
    label: "Toggle visibility",
    hotkey: ["meta", "shift", "h"],
    icon: BsEye,
  },
  delete: {
    label: "Delete",
    hotkey: ["backspace"],
    icon: BsTrash,
  },
  duplicate: {
    label: "Duplicate",
    hotkey: ["meta", "d"],
    icon: BiDuplicate,
  },
  groupIntoContainer: {
    label: "Group into container",
    hotkey: ["meta", "g"],
    icon: FaLayerGroup,
  },
  saveComponentTemplate: {
    label: "Save component template",
    hotkey: ["meta", "shift", "s"],
    icon: CgTemplate,
  },
  mockSave: {
    label: "Save",
    hotkey: ["meta", "s"],
    icon: BsSave,
  },
  zoomIn: {
    label: "Zoom in",
    hotkey: ["meta", "+"],
    icon: BsZoomIn,
  },
  zoomOut: {
    label: "Zoom out",
    hotkey: ["meta", "-"],
    icon: BsZoomOut,
  },
  resetZoom: {
    label: "Reset zoom",
    hotkey: ["meta", "0"],
    icon: PiMagnifyingGlass,
  },
  grabCanvas: {
    label: "Pan canvas",
    hotkey: ["space"],
    icon: HiOutlineHand,
  },
  toggleCodeEditor: {
    label: "Toggle code editor",
    hotkey: ["shift", "d"],
    icon: BsCode,
  },
  togglePreviewMode: {
    label: "Toggle preview mode",
    hotkey: ["shift", "p"],
    icon: BiPlay,
  },
  toggleVersionHistory: {
    label: "Show version history",
    hotkey: ["altOption", "shift", "h"],
    icon: BiHistory,
  },
  toggleAIMenu: {
    label: "Replo AI",
    hotkey: ["meta", "e"],
    icon: PiMagicWand,
  },
  toggleH1Text: {
    label: "Turn into H1",
    hotkey: ["meta", "altOption", "1"],
    icon: BsTypeH1,
  },
  toggleH2Text: {
    label: "Turn into H2",
    hotkey: ["meta", "altOption", "2"],
    icon: BsTypeH2,
  },
  toggleH3Text: {
    label: "Turn into H3",
    hotkey: ["meta", "altOption", "3"],
    icon: BsTypeH3,
  },
  toggleH4Text: {
    label: "Turn into H4",
    hotkey: ["meta", "altOption", "4"],
    icon: BsTypeH4,
  },
  toggleH5Text: {
    label: "Turn into H5",
    hotkey: ["meta", "altOption", "5"],
    icon: BsTypeH5,
  },
  toggleH6Text: {
    label: "Turn into H6",
    hotkey: ["meta", "altOption", "6"],
    icon: BsTypeH6,
  },
  toggleBoldText: {
    label: "Toggle bold",
    hotkey: ["meta", "b"],
    icon: BsTypeBold,
  },
  toggleLinkText: {
    label: "Create link",
    hotkey: ["meta", "k"],
    icon: BsLink,
  },
  toggleItalicText: {
    label: "Toggle italic",
    hotkey: ["meta", "i"],
    icon: BsTypeItalic,
  },
  toggleUnderlineText: {
    label: "Toggle underline",
    hotkey: ["meta", "u"],
    icon: BsTypeUnderline,
  },
  toggleStrikethroughText: {
    label: "Toggle strikethrough",
    hotkey: ["shift", "meta", "x"],
    icon: BsTypeStrikethrough,
  },
  toggleBulletList: {
    label: "Toggle bullet list",
    hotkey: ["meta", "altOption", "7"],
    icon: BsListUl,
  },
  toggleNumberedList: {
    label: "Toggle numbered list",
    hotkey: ["meta", "altOption", "8"],
    icon: BsListOl,
  },
  setDesignPanel: {
    label: "Show design panel",
    hotkey: ["s"],
    icon: MdOutlineDesignServices,
  },
  setConfigPanel: {
    label: "Show config panel",
    hotkey: ["d"],
    icon: BsDatabase,
  },
  setInteractionsPanel: {
    label: "Show interactions panel",
    hotkey: ["i"],
    icon: AiOutlineInteraction,
  },
  setElementsPanel: {
    label: "Show elements panel",
    hotkey: ["altOption", "1"],
    icon: BsList,
  },
  setLayersPanel: {
    label: "Show layers panel",
    hotkey: ["altOption", "2"],
    icon: BsLayers,
  },
  setComponentsPanel: {
    label: "Show components panel",
    hotkey: ["altOption", "3"],
    icon: CgComponents,
  },
  setSavedStylesPanel: {
    label: "Show saved styles panel",
    hotkey: ["altOption", "4"],
    icon: BsPalette,
  },
  openPageSettings: {
    label: "Open page settings",
    hotkey: ["altOption", "4"],
    icon: BsGear,
  },
  openProjectSettings: {
    label: "Open project settings",
    hotkey: ["meta", "altOption", "s"],
    icon: BsSliders,
  },
  decreaseFontSize: {
    label: "Decrease font size",
    hotkey: ["shift", "meta", "<"],
    icon: RiFontSize,
  },
  increaseFontSize: {
    label: "Increase font size",
    hotkey: ["shift", "meta", ">"],
    icon: RiFontSize,
  },
  decreaseLetterSpacing: {
    label: "Decrease letter spacing",
    hotkey: ["altOption", "<"],
    icon: RxLetterSpacing,
  },
  increaseLetterSpacing: {
    label: "Increase letter spacing",
    hotkey: ["altOption", ">"],
    icon: RxLetterSpacing,
  },
  decreaseLineHeight: {
    label: "Decrease line height",
    hotkey: ["altOption", "shift", "<"],
    icon: RiLineHeight,
  },
  increaseLineHeight: {
    label: "Increase line height",
    hotkey: ["altOption", "shift", ">"],
    icon: RiLineHeight,
  },
  decreaseFontWeight: {
    label: "Decrease font weight",
    hotkey: ["altOption", "meta", "<"],
    icon: PiTextAaBold,
  },
  increaseFontWeight: {
    label: "Increase font weight",
    hotkey: ["altOption", "meta", ">"],
    icon: PiTextAaBold,
  },
  moveUpInTheTree: {
    label: "Move up in the tree",
    hotkey: ["↑"],
    icon: BsArrowUp,
  },
  moveDownInTheTree: {
    label: "Move down in the tree",
    hotkey: ["↓"],
    icon: BsArrowDown,
  },
  exportToSection: {
    label: "Export to section",
    hotkey: ["altOption", "meta", "k"],
    icon: BsBoxArrowUp,
  },
  setWidthToFillAvailable: {
    label: "Fill Available Width",
    hotkey: ["meta", "shift", "arrowRight"],
    icon: MdWidthFull,
  },
  setWidthToWrapContent: {
    label: "Wrap Content Width",
    hotkey: ["meta", "shift", "arrowLeft"],
    icon: MdWidthNormal,
  },
  setHeightToFillAvailable: {
    label: "Fill Available Height",
    hotkey: ["meta", "shift", "arrowUp"],
    icon: MdHeight,
  },
  setHeightToWrapContent: {
    label: "Wrap Content Height",
    hotkey: ["meta", "shift", "arrowDown"],
    icon: MdHeight,
  },
  openMarketplace: {
    label: "Open marketplace",
    hotkey: ["altOption", "meta", "o"],
    icon: FiGlobe,
  },
};

export type CommandItem = CommandData & {
  isDisabled?: boolean;
  metadata?: {
    pageIsPublished: boolean;
  };
};

type CommandTextItems = Record<TextCommand, CommandItem>;
type CommandEditItems = Record<EditCommand, CommandItem>;
type CommandViewItems = Record<ViewCommand, CommandItem>;

export type CommandGroup = {
  id: string;
  heading: string;
  items: Record<string, CommandItem>;
};

export type CommandGroupWithActions = CommandGroup & {
  items: Record<
    string,
    CommandItem & {
      onSelect: () => void;
    }
  >;
};

type GetCommandMenuItemsConfig = {
  draftComponentType: ReploComponentType | null;
  hasPreviousOperation: boolean;
  hasNextOperation: boolean;
};

export function getCommandMenuItems(
  config: GetCommandMenuItemsConfig,
): CommandGroup[] {
  const { draftComponentType } = config;
  const commandGroups: CommandGroup[] = [];
  if (draftComponentType === "text") {
    commandGroups.push({
      id: "text",
      heading: "Text",
      items: getTextCommandItems(config),
    });
  }
  commandGroups.push({
    id: "edit",
    heading: "Edit",
    items: getEditCommandItems(config),
  });

  if (draftComponentType) {
    commandGroups.push({
      id: "add",
      heading: "Add",
      items: {},
    });
  }

  commandGroups.push({
    id: "view",
    heading: "View",
    items: getViewCommandItems(config),
  });

  return commandGroups;
}

function getTextCommandItems(_: GetCommandMenuItemsConfig): CommandTextItems {
  return {
    toggleH1Text: Commands.toggleH1Text,
    toggleH2Text: Commands.toggleH2Text,
    toggleH3Text: Commands.toggleH3Text,
    toggleH4Text: Commands.toggleH4Text,
    toggleH5Text: Commands.toggleH5Text,
    toggleH6Text: Commands.toggleH6Text,
    toggleBoldText: Commands.toggleBoldText,
    toggleLinkText: Commands.toggleLinkText,
    toggleItalicText: Commands.toggleItalicText,
    toggleUnderlineText: Commands.toggleUnderlineText,
    toggleStrikethroughText: Commands.toggleStrikethroughText,
    toggleAIMenu: Commands.toggleAIMenu,
    toggleBulletList: Commands.toggleBulletList,
    toggleNumberedList: Commands.toggleNumberedList,
    decreaseFontSize: Commands.decreaseFontSize,
    increaseFontSize: Commands.increaseFontSize,
    decreaseLetterSpacing: Commands.decreaseLetterSpacing,
    increaseLetterSpacing: Commands.increaseLetterSpacing,
    decreaseLineHeight: Commands.decreaseLineHeight,
    increaseLineHeight: Commands.increaseLineHeight,
    decreaseFontWeight: Commands.decreaseFontWeight,
    increaseFontWeight: Commands.increaseFontWeight,
  };
}

function getEditCommandItems(
  config: GetCommandMenuItemsConfig,
): CommandEditItems {
  const { draftComponentType, hasPreviousOperation, hasNextOperation } = config;
  return {
    undo: {
      ...Commands.undo,
      isDisabled: !hasPreviousOperation,
    },
    redo: {
      ...Commands.redo,
      isDisabled: !hasNextOperation,
    },
    copy: {
      ...Commands.copy,
      isDisabled: !draftComponentType,
    },
    copyStyles: {
      ...Commands.copyStyles,
      isDisabled: !draftComponentType,
    },
    paste: {
      ...Commands.paste,
      isDisabled: !draftComponentType,
    },
    pasteStyles: {
      ...Commands.pasteStyles,
      isDisabled: !draftComponentType,
    },
    delete: {
      ...Commands.delete,
      isDisabled: !draftComponentType,
    },
    duplicate: {
      ...Commands.duplicate,
      isDisabled: !draftComponentType,
    },
    groupIntoContainer: {
      ...Commands.groupIntoContainer,
      isDisabled: !draftComponentType,
    },
    saveComponentTemplate: {
      ...Commands.saveComponentTemplate,
      isDisabled: !draftComponentType,
    },
    mockSave: Commands.mockSave,
    exportToSection: {
      ...Commands.exportToSection,
      isDisabled: !draftComponentType,
    },
    setWidthToFillAvailable: {
      ...Commands.setWidthToFillAvailable,
      isDisabled: !draftComponentType,
    },
    setWidthToWrapContent: {
      ...Commands.setWidthToWrapContent,
      isDisabled: !draftComponentType,
    },
    setHeightToFillAvailable: {
      ...Commands.setHeightToFillAvailable,
      isDisabled: !draftComponentType,
    },
    setHeightToWrapContent: {
      ...Commands.setHeightToWrapContent,
      isDisabled: !draftComponentType,
    },
    toggleCodeEditor: {
      ...Commands.toggleCodeEditor,
      isDisabled: draftComponentType
        ? !["rawHtmlContent", "shopifyRawLiquid"].includes(draftComponentType)
        : true,
    },
  };
}

function getViewCommandItems(
  config: GetCommandMenuItemsConfig,
): CommandViewItems {
  const { draftComponentType } = config;
  return {
    toggleVisibility: {
      ...Commands.toggleVisibility,
      isDisabled: !draftComponentType,
    },
    togglePreviewMode: Commands.togglePreviewMode,
    grabCanvas: Commands.grabCanvas,
    toggleVersionHistory: Commands.toggleVersionHistory,
    toggleAIMenu: Commands.toggleAIMenu,
    setDesignPanel: {
      ...Commands.setDesignPanel,
      isDisabled: !draftComponentType,
    },
    setConfigPanel: {
      ...Commands.setConfigPanel,
      isDisabled: !draftComponentType,
    },
    setInteractionsPanel: {
      ...Commands.setInteractionsPanel,
      isDisabled: !draftComponentType,
    },
    setElementsPanel: Commands.setElementsPanel,
    setLayersPanel: Commands.setLayersPanel,
    setComponentsPanel: Commands.setComponentsPanel,
    setSavedStylesPanel: Commands.setSavedStylesPanel,
    openPageSettings: Commands.openPageSettings,
    openProjectSettings: Commands.openProjectSettings,
    moveUpInTheTree: {
      ...Commands.moveUpInTheTree,
      isDisabled: !draftComponentType,
    },
    moveDownInTheTree: {
      ...Commands.moveDownInTheTree,
      isDisabled: !draftComponentType,
    },
    openMarketplace: Commands.openMarketplace,
    zoomIn: Commands.zoomIn,
    zoomOut: Commands.zoomOut,
    resetZoom: Commands.resetZoom,
  };
}
