import * as React from "react";

import DashboardMenuItem from "@editor/components/dashboard//DashboardMenuItem";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  decodeUrlHost,
  encodeUrlHost,
  getAnalyticsPathWithAppendedQueryParam,
} from "@editor/utils/analytics";
import { routes } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";

import useWorkspaceUrlHosts from "@/features/analytics/useWorkspaceUrlHosts";
import { skipToken } from "@tanstack/react-query";
import {
  BsGear,
  BsGraphUpArrow,
  BsLayoutTextSidebarReverse,
} from "react-icons/bs";
import { PiFlask } from "react-icons/pi";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { filterNulls } from "replo-utils/lib/array";
import { validate as uuidValidate } from "uuid";

export const DashboardMenuItems = () => {
  // TODO (Sebas, 2023-07-10): I don't know why but removing this hook causes
  // the app to crash saying "Cannot access 'andytownApi' before initialization".
  // Investigate and remove this when solved. REPL-7916
  // Note (Fran, 2023-09-12): I will keep the comment above for now, just in case we remove the
  // usage of this hook in the future.
  const logEvent = useLogAnalytics();
  const { user } = useCurrentUser();
  const workspaceId = useCurrentWorkspaceId();
  const shouldFetchAnalyticsData = workspaceId && uuidValidate(workspaceId);
  const hasAnalyticsData = trpc.analytics.hasData.useQuery(
    shouldFetchAnalyticsData ? { workspaceId } : skipToken,
  );
  const workspaceHasAnalyticsData =
    Boolean(hasAnalyticsData.data) && !hasAnalyticsData.isLoading;

  const navigate = useNavigate();
  const location = useLocation();
  const [currentPathname, setCurrentPathname] = React.useState(
    location.pathname,
  );

  React.useEffect(() => {
    setCurrentPathname(location.pathname);
  }, [location]);

  const workspaceUrlHosts = useWorkspaceUrlHosts(workspaceId);

  const { pageUrlPath, host } = useParams<{
    pageUrlPath?: string;
    host?: string;
  }>();

  const currentSearchParams = new URLSearchParams(location.search);
  const queryUrlParamString = currentSearchParams.get("query");

  const workspaceRootUrlHosts = workspaceUrlHosts.data.filter(
    (hostObj) => hostObj.isRootDomain,
  );

  const analyticsPageDetailsPath =
    workspaceId &&
    generatePath(routes.analytics.pageDetails, {
      workspaceId,
      host: encodeUrlHost(
        host && host !== ""
          ? decodeUrlHost(host)
          : workspaceRootUrlHosts[0]?.urlHost ?? "",
      ),
      pageUrlPath: encodeURIComponent(pageUrlPath ?? "/"),
    });

  const analyticsPageDetailsPathWithParams =
    getAnalyticsPathWithAppendedQueryParam(
      analyticsPageDetailsPath,
      queryUrlParamString,
    );

  const analyticsOverviewPath =
    workspaceId &&
    generatePath(routes.analytics.overview, {
      workspaceId,
    });

  const analyticsOverviewPathWithParams =
    getAnalyticsPathWithAppendedQueryParam(
      analyticsOverviewPath,
      queryUrlParamString,
    );

  const menuItems = [
    {
      title: "Projects",
      path: `/workspace/${workspaceId}/projects`,
      additionalPath: "/home",
      onClick: () => {
        logEvent("dashboard.nav.click", {
          tab: "projects",
          userId: user?.id ?? null,
          workspaceId,
        });
        navigate(`/workspace/${workspaceId}/projects`);
      },
      startEnhancer: () => <BsLayoutTextSidebarReverse />,
    },
    isFeatureEnabled("analytics-dashboard") && {
      title: "Analytics",
      path: `/workspace/${workspaceId}/analytics/overview`,
      onClick: () => {
        logEvent("dashboard.nav.click", {
          tab: "analytics",
          userId: user?.id ?? null,
          workspaceId,
        });
        if (!workspaceHasAnalyticsData) {
          navigate(analyticsOverviewPathWithParams ?? "");
        }
      },
      startEnhancer: () => <BsGraphUpArrow />,
      endEnhancer: () => (
        <div className="flex flex-col items-end grow">
          <BetaLabel />
        </div>
      ),
      ...(workspaceHasAnalyticsData
        ? {
            subItems: [
              {
                title: "Overview",
                path: analyticsOverviewPath,
                onClick: () => {
                  logEvent("dashboard.nav.click", {
                    tab: "analytics_overview",
                    userId: user?.id ?? null,
                    workspaceId,
                  });
                  navigate(analyticsOverviewPathWithParams);
                },
              },
              {
                title: "Deep Dive",
                path: analyticsPageDetailsPath,
                onClick: () => {
                  logEvent("dashboard.nav.click", {
                    tab: "analytics_deep_dive",
                    userId: user?.id ?? null,
                    workspaceId,
                  });
                  navigate(analyticsPageDetailsPathWithParams);
                },
              },
            ],
          }
        : {}),
    },
    isFeatureEnabled("experiments-refresh") && {
      title: "Experiments",
      path: `/workspace/${workspaceId}/experiments`,
      onClick: () => {
        logEvent("dashboard.nav.click", {
          tab: "experiments",
          userId: user?.id ?? null,
          workspaceId,
        });
        navigate(`/workspace/${workspaceId}/experiments`);
      },
      startEnhancer: () => <PiFlask />,
    },
    {
      title: "Settings",
      path: `/workspace/${workspaceId}/settings`,
      onClick: () => {
        logEvent("dashboard.nav.click", {
          tab: "settings",
          userId: user?.id ?? null,
          workspaceId,
        });
        navigate(`/workspace/${workspaceId}/settings`);
      },
      startEnhancer: () => <BsGear />,
      subItems: [
        {
          title: "General",
          path: `/workspace/${workspaceId}/settings`,
          onClick: () => {
            logEvent("dashboard.nav.click", {
              tab: "settings",
              userId: user?.id ?? null,
              workspaceId,
            });
            navigate(`/workspace/${workspaceId}/settings`);
          },
        },
        {
          title: "Members",
          path: `/workspace/${workspaceId}/members`,
          onClick: () => {
            logEvent("dashboard.nav.click", {
              tab: "members",
              userId: user?.id ?? null,
              workspaceId,
            });
            navigate(`/workspace/${workspaceId}/members`);
          },
        },
        {
          title: "Billing",
          path: `/workspace/${workspaceId}/billing`,
          onClick: () => {
            logEvent("dashboard.nav.click", {
              tab: "billing",
              userId: user?.id ?? null,
              workspaceId,
            });
            navigate(`/workspace/${workspaceId}/billing`);
          },
        },
        {
          title: "Affiliates",
          path: `/workspace/${workspaceId}/partner`,
          onClick: () => {
            logEvent("dashboard.nav.click", {
              tab: "affiliates_program",
              userId: user?.id ?? null,
              workspaceId,
            });
            navigate(`/workspace/${workspaceId}/partner/home`);
          },
        },
        isFeatureEnabled("integration-hub")
          ? {
              title: "Integrations",
              path: `/workspace/${workspaceId}/integrations`,
              onClick: () => {
                logEvent("dashboard.nav.click", {
                  tab: "integrations",
                  userId: user?.id ?? null,
                  workspaceId,
                });
                navigate(`/workspace/${workspaceId}/integrations`);
              },
            }
          : {},
      ],
    },
  ];

  const [expandedParentMenuItems, setExpandedParentMenuItems] = React.useState<
    string[]
  >([]);
  const [selectedParentItem, setSelectedParentItem] = React.useState<
    string | null
  >(null);

  // NOTE (kurt, 2024-08-19): This is a bit of a hack to get the first sub item to expand
  // when the parent item is expanded.
  const toggleExpand = (title: string, item: (typeof menuItems)[number]) => {
    if (selectedParentItem !== title) {
      setSelectedParentItem(title);
      setExpandedParentMenuItems([title]);
    } else {
      const hasExpanded = expandedParentMenuItems.includes(title);
      setExpandedParentMenuItems(hasExpanded ? [] : [title]);
    }

    if (item && "subItems" in item && item.subItems?.length) {
      if (!expandedParentMenuItems.includes(title)) {
        item.subItems[0]?.onClick?.();
      }
    } else if (item) {
      item.onClick?.();
    }
  };

  const isItemSelected = (itemPath: string) => {
    return currentPathname.startsWith(itemPath);
  };

  const renderMenuItem = (item: any, isSubItem = false) => {
    if (!item) {
      return null;
    }

    const hasSubItems = item.subItems && item.subItems.length > 0;
    const hasSubItemsSelected =
      hasSubItems &&
      item.subItems.some((subItem: any) => isItemSelected(subItem.path));
    const isExpanded =
      expandedParentMenuItems.includes(item.title) ||
      isItemSelected(item.path) ||
      hasSubItemsSelected;
    const isSelected =
      isItemSelected(item.path) ||
      isItemSelected(item.additionalPath) ||
      hasSubItemsSelected;

    return (
      <React.Fragment key={item.title}>
        <DashboardMenuItem
          item={{
            ...item,
            onClick: () => toggleExpand(item.title, item),
            isSubItem,
            hasSubItems,
            isExpanded,
            isSelected,
          }}
        />
        {hasSubItems && isExpanded && (
          <div className="ml-3 mt-1 relative">
            <div className="absolute left-0 top-0 bottom-0 w-[0.5px] bg-border"></div>
            <div className="pl-4">
              <div className="flex flex-col gap-1">
                {item.subItems.map(
                  (subItem: (typeof item.subItems)[number]) => {
                    return (
                      <DashboardMenuItem
                        key={subItem.title}
                        item={{
                          ...subItem,
                          onClick: () => subItem.onClick(),
                          isSubItem: true,
                          hasSubItems: false,
                          isExpanded: false,
                          isSelected: isItemSelected(subItem.path),
                        }}
                      />
                    );
                  },
                )}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="flex w-full flex-col gap-1">
      {filterNulls(menuItems).map((item) => renderMenuItem(item))}
    </div>
  );
};

const BetaLabel = () => {
  return (
    <div className="uppercase text-xs font-medium px-2 py-[2px] bg-cyan-100 text-cyan-600 rounded">
      Beta
    </div>
  );
};
