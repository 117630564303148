import * as React from "react";

import useCurrentUser from "@editor/hooks/useCurrentUser";
import { routes } from "@editor/utils/router";

import { generatePath, Navigate } from "react-router-dom";

export const ExperimentsRedirect: React.FC = () => {
  const lastVisitedWorkspaceId = localStorage.getItem(
    "replo.workspace.lastvisitedWorkspaceId",
  );
  const { user } = useCurrentUser();

  if (!lastVisitedWorkspaceId) {
    return (
      <Navigate
        to={generatePath(routes.workspace.experiments.list, {
          workspaceId: user?.workspace?.id ?? "",
        })}
        replace
      />
    );
  }

  const redirectPath = generatePath(routes.workspace.experiments.list, {
    workspaceId: lastVisitedWorkspaceId,
  });

  return <Navigate to={redirectPath} replace />;
};
