import type {
  AnalyticsReadQuery,
  FilterCondition,
} from "schemas/generated/analyticsRead";

import * as React from "react";

import { decodeUrlHost } from "@editor/utils/analytics";

import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import { getRangeFromUrlParams } from "@/features/analytics/time";
import useBasicAnalyticsRead from "@/features/analytics/useBasicAnalyticsRead";
import { useParams } from "react-router-dom";
import { ConditionOperatorEnum } from "schemas/analyticsRead";

export type AnalyticsReadWithUrlParamsProps = {
  workspaceId: string | null;
  defaultInterval?: AnalyticsReadQuery["ranges"]["mainRange"]["interval"];
  urlPathFilter?: {
    type: "fullUrlPath" | "partialUrlPath";
    value: string;
  };
};

const useAnalyticsReadWithUrlParams = ({
  workspaceId,
  defaultInterval,
  urlPathFilter,
}: AnalyticsReadWithUrlParamsProps) => {
  // NOTE (Kurt, 2024-10-13): The host is only set in a page specific path (i.e. /analytics/:workspaceId/:host/:...),
  // otherwise it's undefined.
  const { host } = useParams<{ host?: string }>();
  const decodedHost = host ? decodeUrlHost(host) : undefined;
  const { query: queryWithoutUrlParams, getAllParams } =
    useAnalyticsQueryContext();

  const additionalQueryParams = React.useMemo(() => {
    if (
      urlPathFilter?.type === "partialUrlPath" &&
      urlPathFilter.value !== "" &&
      Boolean(decodedHost)
    ) {
      return {
        limit: 10,
        sortMetric: "unique_sessions" as AnalyticsReadQuery["sortMetric"],
        order: "DESC" as AnalyticsReadQuery["order"],
      };
    }
    return {};
  }, [urlPathFilter?.type, urlPathFilter?.value, decodedHost]);

  const urlParamObject = React.useMemo(() => {
    const params = getAllParams();

    if (urlPathFilter?.value) {
      const { type, value } = urlPathFilter;
      const urlPathFilters = params.filters?.urlPath || [];

      const filterCondition: FilterCondition =
        type === "fullUrlPath"
          ? {
              operator: ConditionOperatorEnum.EQUALS,
              value: [value],
            }
          : {
              operator: ConditionOperatorEnum.CONTAINS,
              value: value,
            };

      params.filters = {
        ...params.filters,
        urlPath: [...urlPathFilters, filterCondition],
      };
    }

    return params;
  }, [getAllParams, urlPathFilter]);

  const updatedRanges = getRangeFromUrlParams(
    urlParamObject.selectedTimePeriod,
    defaultInterval,
  ).updatedRanges;

  // NOTE (Kurt, 2024-11-12): This is a pretty stupid hack to make sure that within the chart context
  // we override the interval calculated from the time period. This is because we want the chart interval
  // to persist in the URL. This should definitely be fixed in future clean up tickets.
  if (defaultInterval) {
    updatedRanges.mainRange.interval =
      urlParamObject.selectedTimePeriod.interval ?? defaultInterval;
    updatedRanges.compareAtRanges[0]!.interval =
      urlParamObject.selectedComparePeriod.interval ?? defaultInterval;
  }

  const query = React.useMemo(
    (): AnalyticsReadQuery => ({
      ...queryWithoutUrlParams,
      // NOTE (Kurt, 2024-10-13): If the host is not set, we use the urlParamObject.urlHosts.
      // But if the host is set in the path (i.e. /analytics/:workspaceId/:host/:...),
      // then we only use that host instead of the hosts stored in the URL params.
      urlHosts: decodedHost ? [decodedHost] : urlParamObject.urlHosts,
      filters: urlParamObject.filters,
      ranges: updatedRanges,
      ...additionalQueryParams,
    }),
    [
      queryWithoutUrlParams,
      decodedHost,
      urlParamObject,
      updatedRanges,
      additionalQueryParams,
    ],
  );

  return useBasicAnalyticsRead({
    workspaceId,
    query,
  });
};

export default useAnalyticsReadWithUrlParams;
