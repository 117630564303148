import type {
  ModifierType,
  ModifierTypeToControlType,
} from "schemas/modifiers";

import * as React from "react";

import { selectDraftComponentId } from "@editor/reducers/core-reducer";
import {
  modifierTypeToSelector,
  selectTextModifierValuesWithSavedStylesReferences,
} from "@editor/selectors/modifiers";
import { useEditorSelector } from "@editor/store";
import { getSavedStyleValue } from "@editor/utils/designLibrary";
import useGetDesignLibrarySavedStyles from "@hooks/designLibrary/useGetDesignLibrarySavedStyles";
import { useLogAnalytics } from "@hooks/useLogAnalytics";

import isEqual from "lodash-es/isEqual";

export const useGetModifierControls = <T extends ModifierType>(
  modifierType: ModifierType,
): [
  Set<ModifierTypeToControlType[T]>,
  (
    newControl: ModifierTypeToControlType[T] | ModifierTypeToControlType[T][],
  ) => void,
] => {
  const logEvent = useLogAnalytics();
  const defaultControls = useGetModifierControlsAvailables<T>(modifierType);
  const draftComponentId = useEditorSelector(selectDraftComponentId);

  const draftComponentIdRef = React.useRef(draftComponentId);
  const defaultControlsRef = React.useRef(defaultControls);

  const textModifierValuesWithSavedStylesReferences = useEditorSelector(
    selectTextModifierValuesWithSavedStylesReferences,
  );

  const [controls, setControls] =
    React.useState<Set<ModifierTypeToControlType[T]>>(defaultControls);

  const addControl = React.useCallback(
    (
      newControl: ModifierTypeToControlType[T] | ModifierTypeToControlType[T][],
    ) => {
      const newControls = new Set(controls);
      if (Array.isArray(newControl)) {
        for (const control of newControl) {
          newControls.add(control);
        }
      } else {
        newControls.add(newControl);
      }

      if (
        Array.isArray(newControl) &&
        newControl.some((control) =>
          ["minWidth", "maxWidth", "minHeight", "maxHeight"].includes(control),
        )
      ) {
        logEvent("design.modifier.add", {
          type: modifierType,
          propertyType: "minMaxWidthHeight",
        });
      } else if (typeof newControl === "string") {
        logEvent("design.modifier.add", {
          type: modifierType,
          propertyType: newControl,
        });
      }

      setControls(newControls);
    },
    [controls, logEvent, modifierType],
  );

  React.useEffect(() => {
    const textModifierValuesWithSavedStylesReferencesControlTypes = Object.keys(
      textModifierValuesWithSavedStylesReferences,
    );
    // NOTE (Fran 2024-11-29): We need to know if some of the controls have a saved style reference
    // and the color is not the only one.
    const textModifierHasSavedStylesReferencesAndColorIsNotTheOnlyOne =
      textModifierValuesWithSavedStylesReferencesControlTypes.length > 0 &&
      !(
        textModifierValuesWithSavedStylesReferencesControlTypes.length === 1 &&
        textModifierValuesWithSavedStylesReferencesControlTypes.includes(
          "color",
        )
      );

    if (
      // NOTE (Sebas, 2024-10-14): We want to reset the control values to the values
      // from the selector only when the draft component id changes. This prevents
      // the controls from being reset when the user is adding a value to a control.
      draftComponentIdRef.current !== draftComponentId ||
      // NOTE (Fran 2024-11-29): We only want to reset the control values in the text modifier
      // since we need to know if the user deleted a saved style. In that case we will need to show
      // every control with static values even if they are referenced to a saved style. Unless the
      // control type color is the only with a reference in that case we will handle that diferently.
      (modifierType === "text" &&
        textModifierHasSavedStylesReferencesAndColorIsNotTheOnlyOne &&
        !isEqual(defaultControlsRef.current, defaultControls))
    ) {
      setControls(defaultControls);
      draftComponentIdRef.current = draftComponentId;
      defaultControlsRef.current = defaultControls;
    }
  }, [
    draftComponentId,
    defaultControls,
    modifierType,
    textModifierValuesWithSavedStylesReferences,
  ]);

  return [controls, addControl];
};

function useGetModifierControlsAvailables<T extends ModifierType>(
  modifierType: ModifierType,
): Set<ModifierTypeToControlType[T]> {
  const defaultControls: Set<ModifierTypeToControlType[T]> = useEditorSelector(
    modifierTypeToSelector[modifierType],
  );
  const textModifierValuesWithSavedStylesReferences = useEditorSelector(
    selectTextModifierValuesWithSavedStylesReferences,
  );
  const textModifierValuesWithSavedStylesReferencesControlTypes = Object.keys(
    textModifierValuesWithSavedStylesReferences,
  );

  const { savedStylesIncludingDeletedOnes: savedStyles } =
    useGetDesignLibrarySavedStyles();

  // NOTE (Sebas, 2024-11-13): In case we have a design library value, we only want to show
  // the alingment and color controls by default.
  if (
    modifierType === "text" &&
    Object.keys(textModifierValuesWithSavedStylesReferences).length > 0 &&
    // NOTE (Fran 2024-11-29): If the only saved style reference is the color we will handle this diferently.
    // We will handle this directly in the Dynamic Color Modifier.
    // This is because the color property is part of a group of other modifiers controls while
    // the text saved style is a group of properties.
    !(
      textModifierValuesWithSavedStylesReferencesControlTypes.length === 1 &&
      textModifierValuesWithSavedStylesReferencesControlTypes.includes("color")
    )
  ) {
    const firstTextModifierValueWithSavedStylesReferences = Object.values(
      textModifierValuesWithSavedStylesReferences,
    )[0]!;
    const savedStyleValue = getSavedStyleValue(
      savedStyles,
      String(firstTextModifierValueWithSavedStylesReferences),
    );

    if (!savedStyleValue?.deletedAt) {
      return new Set(["textAlign", "color"]) as Set<
        ModifierTypeToControlType[T]
      >;
    }
  }

  return defaultControls;
}
