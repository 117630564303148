import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const carouselNextControl: ComponentTemplate = {
  id: "21aa0dc7-9a19-48af-b80b-43907a109ce8",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Carousel/Carousel Next",
  leftBarCategory: { main: "interactive", subCategory: "carousel" },
  preview: "carousel/carousel-next.svg",
  template: {
    id: "430b0ba9-8f5f-45c5-b4b9-36c8d3a6e7ac",
    name: "Next Button 1",
    type: "carouselV3Control",
    props: {
      style: {
        cursor: "pointer",
        display: "flex",
        flexGrow: "unset",
        alignSelf: "stretch",
        flexBasis: "auto",
        alignItems: "center",
        justifyContent: "center",
      },
      direction: "next",
    },
    children: [
      {
        id: "fdda1657-af6c-4631-8f4c-46ea11270f93",
        type: "icon",
        props: {
          style: {
            width: "60px",
            height: "60px",
            __width: "60px",
            __height: "60px",
            flexGrow: "unset",
          },
          iconName: "chevronRight",
        },
        variantOverrides: {},
      },
    ],
    variants: [
      {
        id: "6503513c-db29-40b4-aff4-6a80ae2b6e63",
        name: "default",
        query: null,
        componentOverrides: null,
      },
      {
        id: "a23335eb-4f11-480a-8a0a-f452c70c400c",
        name: "Last Item",
        query: {
          type: "expression",
          operator: "or",
          statements: [
            {
              id: "fa7db26c-7755-450b-b10f-b5f0f5c6a624",
              field: "state.group.isLastItemActive",
              value: null,
              operator: null,
            },
          ],
        },
        componentOverrides: null,
      },
    ],
    variantOverrides: {
      "a23335eb-4f11-480a-8a0a-f452c70c400c": {
        componentOverrides: {
          "fdda1657-af6c-4631-8f4c-46ea11270f93": {
            props: { style: { opacity: "20%" } },
          },
          "430b0ba9-8f5f-45c5-b4b9-36c8d3a6e7ac": {
            props: { style: { cursor: "default" } },
          },
        },
      },
    },
  },
};
