import type { Option } from "@editor/components/editor/page/element-editor/components/Lists";
import type { UploadResult } from "@editor/reducers/commerce-reducer";
import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";

import * as React from "react";

import {
  errorToast,
  successToast,
} from "@editor/components/common/designSystem/Toast";
import DropZone from "@editor/components/editor/page/Dropzone";
import ControlGroup from "@editor/components/editor/page/element-editor/components/extras/ControlGroup";
import useGetDeletedSavedStyleValueIfNeeded from "@editor/hooks/designLibrary/useGetDeletedSavedStyleValueIfNeeded";
import { useApplyComponentAction } from "@editor/hooks/useApplyComponentAction";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import {
  usePageFontOptions,
  useShopifyFontOptions,
} from "@editor/hooks/useFontFamilyOptions";
import {
  selectDraftComponentNodeFromActiveCanvas,
  selectFontFamily,
  selectIsShopifyIntegrationEnabled,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import {
  filterOutPageFonts,
  FORMATTED_GOOGLE_FONT_OPTIONS,
  GENERIC_FONT_FAMILIES,
  getEndEnhancer,
} from "@editor/utils/font";
import SelectablePopover from "@editorComponents/SelectablePopover";
import { normalizeFontFamily } from "@editorModifiers/utils";

import Button from "@replo/design-system/components/button";
import classNames from "classnames";
import intersectionBy from "lodash-es/intersectionBy";
import { BsFonts } from "react-icons/bs";
import { hasOwnProperty } from "replo-utils/lib/misc";
import { useForceUpdate } from "replo-utils/react/use-force-update";
import { twMerge } from "tailwind-merge";

const FontFamilyControl: React.FC<{
  className?: string;
  value?: string | null;
  onChange?: (value: string | null) => void;
}> = ({ className, value: controlledValue, onChange: controlledOnChange }) => {
  // TODO (Sebas, 2024-10-25): This is used mainly to make the font family control
  // full width when the old right bar is enabled. This will be removed once
  // the new right bar is enabled by default.
  const applyComponentAction = useApplyComponentAction();
  const selectedFontFamily = useEditorSelector(selectFontFamily);
  // NOTE (Fran 2024-11-29): If the saved style is deleted we want to show the static value to allow the
  // user change it.
  const deletedSavedStyleValue =
    useGetDeletedSavedStyleValueIfNeeded<SavedStyleTextAttributes>(
      selectedFontFamily ?? null,
    )?.fontFamily;

  const fontFamily =
    controlledValue ?? deletedSavedStyleValue ?? selectedFontFamily ?? null;
  const normalizedFontFamily = normalizeFontFamily(fontFamily)
    ?.replaceAll("_", " ")
    .replaceAll("-", " ");
  const pageFontOptions = usePageFontOptions();
  const {
    fontOptions: shopifyFontOptions,
    fallbacks,
    refetch,
    nameToDisplayName,
  } = useShopifyFontOptions();

  // NOTE (Gabe 2024-05-15): Only show the fonts that are actually available
  // (via Shopify or Google fonts).
  const filteredPageFonts = intersectionBy(
    pageFontOptions,
    [...shopifyFontOptions, ...FORMATTED_GOOGLE_FONT_OPTIONS],
    (font) => font.value,
  );

  const fontOptions = [
    // Note (Evan, 6/2/23) We add the end enhancers to page fonts here, since we have to know whether they're shopify-uploaded fonts or not
    ...filteredPageFonts.map((font) => ({
      ...font,
      endEnhancer: getEndEnhancer(font, shopifyFontOptions),
      label: getDisplayName(font.value, nameToDisplayName) ?? font.label,
    })),
    // Note (Evan, 6/2/23) Filter out page fonts from shopify/google fonts so that we don't have any duplicates
    ...filterOutPageFonts(shopifyFontOptions, filteredPageFonts).map(
      (font) => ({
        ...font,
        label: getDisplayName(font.value, nameToDisplayName) ?? font.label,
      }),
    ),
    ...filterOutPageFonts(FORMATTED_GOOGLE_FONT_OPTIONS, filteredPageFonts),
  ];

  const draftComponentNode = useEditorSelector(
    selectDraftComponentNodeFromActiveCanvas,
  );

  const rerender = useForceUpdate();
  const computedFontStyle =
    draftComponentNode && getComputedStyle(draftComponentNode).fontFamily;

  const onSelect = (fontValue: string | null) => {
    if (controlledOnChange) {
      // Controlled mode
      controlledOnChange(fontValue);
    } else {
      // Uncontrolled mode (existing behavior)
      applyComponentAction({
        type: "setStyles",
        value: {
          fontFamily: fontValue ? `${fontValue}, ${fallbacks.join(",")}` : null,
        },
      });
    }
  };

  // NOTE (Sebas, 2023-03-22): When we reset the font to the default value,
  // we need to wait some time to get the updated computed font style.
  // NOTE (Chance, 2024-04-09) This should probably be handled in onSelect
  // instead to reduce complexity.
  const fontFamilyRef = React.useRef(fontFamily);
  React.useEffect(() => {
    const previousFontFamily = fontFamilyRef.current;
    fontFamilyRef.current = fontFamily;
    if (previousFontFamily && !fontFamily) {
      const id = setTimeout(() => {
        rerender();
      }, 500);
      return () => {
        clearTimeout(id);
      };
    }
  }, [rerender, fontFamily]);

  const onFontUploadComplete = async (data: UploadResult) => {
    const newFonts = await refetch();
    const fontName = newFonts?.find((font) =>
      // NOTE (Evan, 7/13/23) We replace spaces with underscores here because
      // we're matching against the Shopify files system, which does the same.
      data.asset.publicUrl.includes(font.name.replace(/ /g, "_")),
    )?.name;
    if (fontName) {
      onSelect(fontName);
    }
  };

  // Note (Evan, 2024-08-07): Attempt to retrieve a display name from
  // nameToDisplayName, checking first for the fontFamily, then the
  // fontFamily after removing any comma-joined fallbacks
  let displayName: string | undefined = undefined;
  if (fontFamily) {
    displayName =
      nameToDisplayName[fontFamily] ??
      nameToDisplayName[fontFamily.split(",")[0] ?? ""];
  }

  const fontDisplayName = displayName ?? normalizedFontFamily;

  const options = fontOptions.map((option) => ({
    ...option,
    label: <ImgOrLabel option={option} nameToDisplayName={nameToDisplayName} />,
  }));

  return (
    <ControlGroup label="Font">
      <SelectablePopover
        title="Fonts"
        itemSize={26}
        options={options}
        fallbackValidValues={GENERIC_FONT_FAMILIES}
        onSelect={onSelect}
        startEnhancer={<BsFonts size={16} />}
        placeholder={normalizeFontFamily(computedFontStyle) ?? "Select a font"}
        updatePlaceholderOnItemHover={true}
        extraContent={
          !controlledValue && (
            <UploadCustomFont
              onUploadComplete={(data) => {
                void onFontUploadComplete(data);
              }}
            />
          )
        }
        isRemovable={Boolean(fontFamily)}
        allowNull
        selectedItems={[normalizeFontFamily(fontFamily)]}
        triggerId="font-family-selectable-trigger"
        triggerClassname="w-full"
        placeholderClassname={twMerge(
          classNames(
            // NOTE (Sebas, 2024-10-22): This width is calculated based on the width of the
            // input without the startEnhancer and endEnhancer.
            "text-ellipsis truncate w-[122px]",
          ),
          className,
        )}
        childrenClassname={twMerge(
          classNames(
            // NOTE (Sebas, 2024-10-22): This width is calculated based on the width of the
            // input without the startEnhancer and endEnhancer.
            "text-ellipsis truncate w-[100px]",
          ),
          className,
        )}
        // NOTE (Juan, 2024-10-21): This offset is different from the others because this
        // popover is being trigger from an entire input and not from an internal badge.
        popoverSideOffset={84}
      >
        {fontDisplayName ? fontDisplayName : null}
      </SelectablePopover>
    </ControlGroup>
  );
};
const UploadCustomFont: React.FC<{
  onUploadComplete(data: UploadResult): void;
}> = ({ onUploadComplete }) => {
  const projectId = useCurrentProjectId();
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );
  const onUpload = (
    res:
      | { data: UploadResult }
      | { error: FetchBaseQueryError | SerializedError },
  ) => {
    if (uploadWasSuccessful(res)) {
      successToast("Font Uploaded", "");
      onUploadComplete(res.data);
    } else {
      errorToast(
        "Failed Uploading Font",
        "Please try again or reach out to support@replo.app for help.",
      );
    }
  };

  return (
    <>
      {isShopifyIntegrationEnabled ? (
        <div id="text-style-modifier-font-upload">
          <DropZone
            acceptDropAssetType={[".woff", ".woff2"]}
            onUploadComplete={onUpload}
            projectId={projectId}
            sourceType="files"
            inputSize="small"
            uploadText="Upload Custom Fonts"
          />
        </div>
      ) : (
        <Button
          variant="secondary"
          style={{ minWidth: 0 }}
          className="bg-transparent text-slate-400 hover:bg-transparent"
          isDisabled={true}
          tooltipText="Connect to Shopify to add custom fonts"
          isPhonyButton
        >
          Upload Custom Fonts
        </Button>
      )}
    </>
  );
};

const ImgOrLabel = ({
  option,
  nameToDisplayName,
}: {
  option: Option & { value: string | null };
  nameToDisplayName: Record<string, string | undefined>;
}) => {
  const [shouldShowLabel, setShouldShowLabel] = React.useState(false);
  const imgRef = React.useRef<HTMLImageElement>(null);

  const imgSrc = React.useMemo(() => {
    if (!option.value) {
      return null;
    }
    return `/images/font-previews/${option.value.replace(/\s+/g, "_")}.webp`;
  }, [option.value]);

  if (!imgSrc || shouldShowLabel) {
    return option.label;
  }

  return (
    <img
      ref={imgRef}
      src={imgSrc}
      alt={getDisplayName(option.value, nameToDisplayName)}
      width={182}
      height={16}
      loading="lazy"
      onError={() => setShouldShowLabel(true)}
    />
  );
};

const getDisplayName = (
  fontValue: string | null,
  nameToDisplayName: Record<string, string | undefined>,
) => {
  if (!fontValue) {
    return undefined;
  }
  return nameToDisplayName[fontValue];
};

function uploadWasSuccessful(
  res:
    | { data: UploadResult }
    | { error: FetchBaseQueryError | SerializedError },
): res is { data: UploadResult } {
  return hasOwnProperty(res, "data");
}

export default FontFamilyControl;
