import * as React from "react";

import { setIsCreateComponentFormVisible } from "@editor/reducers/ui-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";

/**
 * Hook to deal with create component form visibility.
 * It bridges the state on Redux and accepts an optional
 * callback which is called anytime visibility is turned on.
 */
export default function useCreateComponentForm(onVisible?: () => void) {
  const isCreateComponentFormVisible = useEditorSelector(
    (state) => state.ui.isCreateComponentFormVisible,
  );
  const dispatch = useEditorDispatch();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  React.useEffect(() => {
    if (isCreateComponentFormVisible) {
      onVisible?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateComponentFormVisible]);

  return {
    isCreateComponentFormVisible,
    setIsCreateComponentFormVisible: React.useCallback(
      (value: boolean) => {
        dispatch(setIsCreateComponentFormVisible(value));
      },
      [dispatch],
    ),
  };
}
