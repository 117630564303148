import type { IconType } from "react-icons";

import * as React from "react";

import Modal from "@editor/components/common/designSystem/Modal";
import { ModalLayout } from "@editor/components/common/ModalLayout";
import { useModal } from "@editor/hooks/useModal";
import { docs } from "@editor/utils/docs";

import classNames from "classnames";
import {
  BsArrowRight,
  BsBookmarkFill,
  BsChat,
  BsKeyboard,
  BsPersonFillAdd,
  BsSlack,
} from "react-icons/bs";

interface Action {
  title: string;
  icon: IconType;
  iconForeground: string;
  iconBackground: string;
  text: string;
  ctaText?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
}

function SupportModalContent() {
  const modal = useModal();
  const actions: Action[] = [
    {
      title: "Chat with us",
      icon: BsChat,
      iconForeground: "text-blue-600",
      iconBackground: "bg-blue-50",
      text: "We can get back to you in a few minutes over live chat, just look for the chat icon in the lower left.",
      onClick: window?.zE
        ? () => {
            window.zE("messenger", "open");
            modal.closeModal({ type: "supportModal" });
          }
        : undefined,
      ctaText: "Open Chat",
    },
    {
      title: "Find a Replo Expert",
      icon: BsPersonFillAdd,
      iconForeground: "text-purple-700",
      iconBackground: "bg-purple-50",
      text: "Need professional help with your Replo project? Hire an independent Replo Expert or Builder to help.",
      ctaText: "Find an Expert",
      onClick: () => {
        window.open("https://www.replo.app/experts", "_blank");
      },
    },
    {
      title: "Read our docs",
      icon: BsBookmarkFill,
      iconForeground: "text-sky-700",
      iconBackground: "bg-sky-50",
      text: "Check out our docs for all the deets on the what, why and where of Replo.",
      ctaText: "Read the Docs",
      onClick: () => {
        window.open(docs.home, "_blank");
      },
    },
    {
      title: "Join the community",
      icon: BsSlack,
      iconForeground: "text-rose-700",
      iconBackground: "bg-rose-50",
      text: "Join the Replo Slack community to hear about the latest and greatest in Replo.",
      ctaText: "Go to Slack",
      onClick: () => {
        window.open("https://replo.app/community", "_blank");
      },
    },
    {
      title: "Hotkeys",
      icon: BsKeyboard,
      iconForeground: "text-green-700",
      iconBackground: "bg-green-50",
      text: "View all available keyboard shortcuts for Replo.",
      ctaText: "View Hotkeys",
      onClick: () => {
        modal.closeModal({ type: "supportModal" });
        modal.openModal({ type: "hotkeysModal" });
      },
    },
  ];
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg transition-all sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
      {actions.map((action: Action) => (
        <div
          key={action.title}
          className={classNames("group relative p-2", {
            "cursor-pointer": Boolean(action.onClick),
          })}
          onClick={action.onClick}
        >
          <div>
            <span
              className={classNames(
                action.iconBackground,
                action.iconForeground,
                "inline-flex rounded-lg p-2 ring-4 ring-white",
              )}
            >
              <action.icon className="h-5 w-5" aria-hidden="true" />
            </span>
          </div>
          <div className="mt-2">
            <h3 className="text-sm font-medium">{action.title}</h3>
            <p className="mt-1 text-xs text-slate-400">{action.text}</p>
          </div>
          {action.onClick && (
            <div
              className="mt-2 flex flex-row items-center gap-x-1 text-blue-600 text-xs"
              aria-hidden="true"
            >
              <div>{action.ctaText}</div>
              <BsArrowRight
                size={14}
                className="pointer-events-none group-hover:ml-1 transition-all"
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export function SupportModal() {
  const modal = useModal();

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => modal.closeModal({ type: "supportModal" })}
      className="w-auto"
      includesCloseIcon
    >
      <ModalLayout
        height={500}
        mainContent={() => {
          return (
            <div className="flex h-full flex-col gap-2">
              <div className="flex flex-col">
                <p className="self-center text-lg font-medium">
                  Need some help?
                </p>
                <p className="self-center text-sm text-slate-400">
                  Here are a couple of options
                </p>

                <div className="flex gap-2 pt-4 text-sm">
                  <SupportModalContent />
                </div>
              </div>
            </div>
          );
        }}
      />
    </Modal>
  );
}
