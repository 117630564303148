import * as React from "react";

import { selectDraftComponentContext } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { TabsBanner } from "@editorComponents/banners/TabsBanner";

const StickyBanners: React.FC = () => {
  const context = useEditorSelector(selectDraftComponentContext);

  if (!context) {
    return null;
  }

  const hasChildren =
    Boolean(context?.state?.collapsibleV2) ||
    Boolean(context?.state?.tabsBlock);

  if (!hasChildren) {
    return null;
  }

  return context?.state?.tabsBlock ? (
    <div
      className="sticky top-0 flex flex-col items-stretch"
      style={{ zIndex: 1000 }}
    >
      <TabsBanner />
    </div>
  ) : null;
};

export default StickyBanners;
