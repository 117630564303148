import * as React from "react";

import { Checkbox } from "@replo/design-system/components/shadcn/core/checkbox";
import classNames from "classnames";

type CheckboxCalloutProps = {
  title: string;
  description: string;
  isChecked: boolean;
  onCheckedChange: (checked: boolean) => void;
};

export const CheckboxCallout = ({
  title,
  description,
  isChecked,
  onCheckedChange,
}: CheckboxCalloutProps) => (
  <label
    className={classNames(
      "px-3 py-2 rounded border justify-between flex items-center gap-2",
      isChecked ? "bg-blue-50 border-blue-300" : "border-slate-300",
    )}
  >
    <div className="flex flex-col gap-2 cursor-pointer">
      <div className="text-slate-800 text-sm font-semibold">{title}</div>
      <div className="text-slate-500 text-xs">{description}</div>
    </div>
    <Checkbox checked={isChecked} onCheckedChange={onCheckedChange} />
  </label>
);
