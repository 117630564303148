import type { CustomPropsRecord } from "schemas/component";
import type { AlchemyActionType } from "../../../shared/enums";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

import { convertToLegacyProps } from "../../../shared/utils/renderComponents";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _showArrowsOnlyOnHover: {
      type: "boolean",
      name: "Only Show Arrows on Hover",
      defaultValue: false,
      description:
        "If set, arrows will only be visible when the user hovers over the carousel",
    },
    _hideArrowsBasedOnScrollPosition: {
      type: "boolean",
      name: "Hide Arrows at End of Scroll",
      defaultValue: false,
      description:
        "If enabled, arrows will be hidden at either end of the carousel",
    },
  };
}

export const styleElements = {
  root: {},
} as const satisfies StyleElements;

const actionTypes = [
  "scrollContainerLeft",
  "scrollContainerRight",
] as const satisfies AlchemyActionType[];

export type ActionType = (typeof actionTypes)[number];

const config = {
  renderData: {
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    customProps: convertToLegacyProps(getConfigurableProps()),
    newInstancesUseNumbering: true,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: true,
    styleElements,
    actionTypes,
  },
} satisfies ComponentConfig;

export default config;
