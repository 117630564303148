import * as React from "react";

import { selectDraftComponentId } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";

/**
 * Sometimes Context doesn't have the required data until after the first render
 * of a component. To get around this, this hook will return the context after
 * that first render.
 */
export default function useCurrentContext() {
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const context = getCurrentComponentContext(draftComponentId, 0);

  const [, setRendered] = React.useState(false);
  React.useEffect(() => {
    setRendered(true);
  }, []);

  return context;
}
