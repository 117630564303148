import type { BaseSharedLiquidProps } from "../../shared/types";

import * as React from "react";

import {
  GlobalWindowContext,
  useRuntimeContext,
} from "../../shared/runtime-context";

interface SharedYotpoReviewsProps extends BaseSharedLiquidProps {
  htmlContent: string;
}

export const SharedYotpoReviews = ({
  htmlContent,
  attributes,
  component,
}: SharedYotpoReviewsProps) => {
  const globalWindow = useRuntimeContext(GlobalWindowContext);
  // Note (Fran, 2023-06-01): There is two versions on this widget and
  // we need to see if one of these is loaded.
  // New version cdn-widgetsrepository.yotpo.com/v1/loader
  // Old version staticw2.yotpo.com
  // We have a discussion in Slack:
  // - https://replohq.slack.com/archives/C03AACVP08Y/p1672962075379129
  // - https://replohq.slack.com/archives/C04APQHLWQJ/p1672951758250909?thread_ts=1672938137.344499&cid=C04APQHLWQJ

  // biome-ignore lint/correctness/useExhaustiveDependencies: htmlContent extra dep
  React.useEffect(() => {
    try {
      if (globalWindow?.yotpoWidgetsContainer) {
        globalWindow.yotpoWidgetsContainer.initWidgets();
      } else if (globalWindow?.yotpo) {
        globalWindow.yotpo.initWidgets();
      }
    } catch {
      // allow empty catch
    }
  }, [globalWindow, htmlContent]);
  return (
    <>
      {component.props._css && (
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: String(component.props._css),
          }}
        />
      )}
      <div {...attributes}>
        <span
          dangerouslySetInnerHTML={{
            __html: htmlContent,
          }}
        />
      </div>
    </>
  );
};
