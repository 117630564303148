import type { EditorRootState } from "@editor/store";
import type { PayloadAction } from "@reduxjs/toolkit";

import { logNonFatalMessage } from "@infra/nonfatal";

import { createSlice } from "@reduxjs/toolkit";
import { firstGroupMatchOfExpression } from "replo-runtime/shared/utils/regex";

export type LiquidRendererState = {
  renderedLiquidCache: Record<string, string>;
  requestsInProgress: Record<string, boolean>;
};

const initialState: LiquidRendererState = {
  renderedLiquidCache: {},
  requestsInProgress: {},
};

export const liquidRendererSlice = createSlice({
  name: "liquidRenderer",
  initialState,
  reducers: {
    setRequestInProgress: (
      state,
      action: PayloadAction<{
        liquidSource: string;
        inProgress: boolean;
      }>,
    ) => {
      if (!action.payload.inProgress) {
        delete state.requestsInProgress[action.payload.liquidSource];
      } else if (action.payload.liquidSource) {
        state.requestsInProgress[action.payload.liquidSource] = true;
      }
    },
    setLiquidRendererCache: (
      state,
      action: PayloadAction<{ html: string; liquidSource: string } | undefined>,
    ) => {
      if (!action.payload) {
        // NOTE (Reinaldo, 2022-04-21): We reset the whole requestsInProgress state since if we try to get the action here
        // it doesn't have the meta.liquidSource because that's not in the response of an error
        state.requestsInProgress = {};

        logNonFatalMessage(
          "Error rendering liquid! This probably means something is wrong with alchemy-mirror!",
        );
        console.error(
          "[Replo] error rendering liquid, please contact support@replo.app",
        );
      } else {
        const renderedLiquid = firstGroupMatchOfExpression(
          // NOTE (Mariano, 2022-04-18): We are enclosing the liquid HTML with a custom tag <replo-rendered-liquid>.
          // We look for anything inside this custom tag because the rendered liquid itself could contain any arbitrary html.
          // i.e: <replo-rendered-liquid><section ...</replo-rendered-liquid>
          /<replo-rendered-liquid>([\S\s]*)?<\/replo-rendered-liquid>/,
          action.payload.html,
        );

        const liquidError = firstGroupMatchOfExpression(
          /body>Liquid syntax error: (.*?)<\//,
          action.payload.html,
        );

        delete state.requestsInProgress[action.payload.liquidSource];
        if (renderedLiquid && !liquidError) {
          state.renderedLiquidCache[action.payload.liquidSource] =
            renderedLiquid;
        }

        if (liquidError) {
          state.renderedLiquidCache[action.payload.liquidSource] =
            `Liquid syntax error: ${liquidError}`;
        }
      }
    },
  },
});

const { actions, reducer } = liquidRendererSlice;

export const { setLiquidRendererCache, setRequestInProgress } = actions;

export default reducer;

export const selectRenderedLiquidCache = (state: EditorRootState) => {
  return state.liquidRenderer.renderedLiquidCache;
};

export const selectLiquidRequestsInProgress = (state: EditorRootState) => {
  return state.liquidRenderer.requestsInProgress;
};

export const selectRenderedLiquidCacheLength = (state: EditorRootState) => {
  return Object.keys(state.liquidRenderer.renderedLiquidCache).length;
};
