// TODO (Noah, 2022-02-24): These constants are used for Canvas offset coordinate
// transforms, but it's kind of hacky that we need them at all - I think we should
// probably just be able to use the Canvas's offsetTop/Left, or something
// TODO (Fran 2024-09-26): Remove unnecessary variables after LeftPanel UI is released
export const LEFT_BAR_WIDTH = 300;
export const MINIMUM_LEFT_BAR_WIDTH = 250;
export const RIGHT_BAR_WIDTH = 250;
export const DEBUG_PANEL_WIDTH = 350;
export const HEADER_HEIGHT = 60;
export const COMPONENT_PREVIEW_PAD = 20;
// NOTE (Juan, 2023-06-06) This zIndex number ensures that other browser extensions, like Loom, are not being blocked by Replo modals.
export const MAX_Z_INDEX = 2_147_482_645;

// Useful for when we need stable references to empty non-primitive values for
// when over-rendering is a problem
export const emptyObject = {};
export const emptyArray = [];

// NOTE (Fran 2024-04-22): This is the maximum length of an input string, check this will avoid
// the editor freeze because of a huge string. Example: a huge svg source.
export const MAX_STYLE_PROPERTY_VALUE_LENGTH = 10_000;

export const TIPTAP_EDITOR_SCROLLABLE_DIV_ID = "tipTapScrollableDiv";

export const NEW_STATE_NAME = "New State";

// NOTE (Sebas, 2024-10-18): This is the offset for the popovers that have a badge component
// inside an input as a trigger.
export const BADGE_TRIGGER_OFFSET = 88;
