import type { ExperimentStatus } from "schemas/experiment";

import * as React from "react";

import { InfoTag } from "@/features/experiments/components/InfoTag";
import classNames from "classnames";

const mapping: Record<ExperimentStatus, { className: string; label: string }> =
  {
    active: {
      className: "bg-green-100 text-green-600",
      label: "Live",
    },
    draft: {
      className: "bg-blue-100 text-blue-600",
      label: "Draft",
    },
    complete: {
      className: "bg-slate-100 text-slate-500",
      label: "Test Ended",
    },
    archived: {
      className: "bg-slate-100 text-slate-500",
      label: "Archived",
    },
  };

export const StatusTag = ({ status }: { status: ExperimentStatus }) => {
  const { className, label } = mapping[status];
  return (
    <InfoTag className={classNames(className, "text-xs font-medium truncate")}>
      {label}
    </InfoTag>
  );
};
