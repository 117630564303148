import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const spinner: ComponentTemplate = {
  id: "17f1eafb-b243-4772-a316-591273a3c8c7",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Basic/Spinner",
  leftBarCategory: { main: "interactive", subCategory: "animations" },
  preview: "basic/spinner.svg",
  transforms: [],
  template: {
    id: "$uid",
    type: "spinner",
    props: {
      circleWidth: "20px",
      foregroundColor: "#e2e8f0",
      secondaryColor: "#64748b",
    },
  },
};
