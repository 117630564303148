import * as React from "react";

import { useOverridableState } from "../../shared/hooks/useOverridableState";
import {
  RuntimeHooksContext,
  useRuntimeContext,
} from "../../shared/runtime-context";

/**
 * Hook for using shared state on runtime, if rendered inside
 * the editor. Otherwise, it will default to React's useState.
 */
export function useSharedState<T>(
  key: string | string[],
  defaultValue: T,
): readonly [T, (value: T) => void] {
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const computedKey = Array.isArray(key) ? key.join(".") : key;

  const sharedStateValue =
    useRuntimeContext(RuntimeHooksContext).useSharedState(computedKey);
  const setSharedState =
    useRuntimeContext(RuntimeHooksContext).useSetSharedState();

  const [value, setValue] = useOverridableState(
    sharedStateValue ?? defaultValue,
  );

  const editorSetState = React.useCallback(
    (value: T) => {
      setSharedState({ key: computedKey, value });
    },
    [computedKey, setSharedState],
  );

  return [value, isEditorCanvas ? editorSetState : setValue];
}

export default useSharedState;
