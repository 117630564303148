import * as React from "react";

import clsxMerge from "@replo/design-system/components/shadcn/utils/cn-merge";
import classNames from "classnames";
import { BsArrowDown, BsArrowUp, BsDash } from "react-icons/bs";
import { formatWithCommasAndTwoDecimals } from "replo-utils/lib/math";
import { twMerge } from "tailwind-merge";

const DeltaIndicator: React.FC<{
  delta: number;
  doesCompareValueExist: boolean;
}> = ({ delta, doesCompareValueExist }) => {
  const { icon, bgColor, textColor } = React.useMemo(() => {
    if (!doesCompareValueExist || delta === 0) {
      return {
        bgColor: "bg-gray-50",
        textColor: "text-gray-600",
      };
    }
    if (delta > 0) {
      return {
        icon: <BsArrowUp className="h-4 w-4" />,
        bgColor: "bg-green-50",
        textColor: "text-green-600",
      };
    }
    return {
      icon: <BsArrowDown className="h-[14px] w-[14px]" />,
      bgColor: "bg-red-50",
      textColor: "text-red-600",
    };
  }, [delta, doesCompareValueExist]);

  return (
    <div
      className={twMerge(
        classNames(
          "flex justify-center items-center rounded-[4px] px-[6px] py-0.5 gap-1 leading-4 min-w-[70px]",
          bgColor,
        ),
      )}
    >
      {doesCompareValueExist ? (
        <div className="flex items-center justify-center">
          {icon && <div className="h-4 w-4">{icon}</div>}
          <span
            className={clsxMerge(
              "flex w-full items-center justify-center text-xs font-normal",
              textColor,
            )}
          >
            {`${formatWithCommasAndTwoDecimals(Math.abs(delta), true)}%`}
          </span>
        </div>
      ) : (
        <span className="flex w-full items-center justify-center">
          <BsDash className="h-4 w-4" />
        </span>
      )}
    </div>
  );
};

export default DeltaIndicator;
