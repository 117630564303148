import * as React from "react";

/**
 * Rerenders every `interval` milliseconds, returning progress between [0, 1]
 * incrementing on an inverse exponential curve with each interval, such that it
 * never reaches 1, but reaches `valueAtExpectedTimeToComplete` after
 * `expectedTimeToComplete` milliseconds.
 */
export default function useExponentialProgressInterval(
  interval: number,
  opts: {
    expectedTimeToComplete: number;
    valueAtExpectedTimeToComplete: number;
  },
): number {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const startTime = Date.now();
    let timerId: NodeJS.Timeout | null = null;

    const updateProgress = () => {
      const elapsedTime = Date.now() - startTime;
      // Note (Noah, 2023-07-13): Raising e to the power of a log (with constants)
      // achieves the desired effect here, the formula is:
      // y = 1 - e^(ln(1 - valueAtExpectedTimeToComplete) * x / expectedTimeToComplete)
      //
      // I'm not smart enough to generate this myself, I asked GPT4: "I want to
      // write a function which monotonically approaches 1, but never reaches
      // it. The function's y values should start at 0, and should reach 0.98 at
      // an x value of 58, and after that approach 1 monotonically but never
      // reach it. I think a function of the form y = 1 - e^(-x) is the right
      // way to go here, but I'm not sure how to tweak the constants such that
      // the constraint of reaching 0.98 at 58 is met"
      //
      // Response: "Yes, you're right about the function form, `y = 1 -
      // e^(-ax)` will do the job. We need to tweak the constant `a` such that
      // it satisfies your requirement. We need to solve for `a` in the equation
      // `0.98 = 1 - e^(-58a)`. This gives us `e^(-58a) = 0.02` Taking natural
      // logarithm on both sides gives, `-58a = ln(0.02)` Therefore, `a = -
      // ln(0.02) / 58` Hence, a function that meets your criteria is `y = 1 -
      // e^(ln(0.02) * x / 58)`"
      //
      // Feel free to tweak these prompts if this equation needs to be updated
      // in the future!
      const percentage =
        1 -
        Math.pow(
          Math.E,
          (Math.log(1 - opts.valueAtExpectedTimeToComplete) * elapsedTime) /
            opts.expectedTimeToComplete,
        );
      setProgress(Math.min(percentage, 1));
    };

    updateProgress();

    timerId = setInterval(updateProgress, interval);

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [
    interval,
    opts.expectedTimeToComplete,
    opts.valueAtExpectedTimeToComplete,
  ]);

  return progress;
}
