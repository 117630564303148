import type { AppModalType } from "./AppModalTypes";

import * as React from "react";

import { useModal } from "@hooks/useModal";

import { useSearchParams } from "react-router-dom";
import { BILLING_PLAN_CONFIRMATION_PARAM } from "schemas/billing";

import { BillingModal } from "./billing/BillingModal";
import { BillingPlanChangeSurveyModal } from "./billing/BillingPlanChangeSurvey";
import { BillingStripeNotificationModal } from "./billing/BillingStripeNotificationModal";
import { BillingUpgradeDowngradeModal } from "./billing/BillingUpgradeDowngradeModal";
import { errorToast } from "./common/designSystem/Toast";
import { AssetLibraryModal } from "./editor/page/AssetLibraryModal";
import { CreateOrConfigureDataCollectionModal } from "./editor/page/data-tables-page/CreateOrConfigureDataCollectionModal";
import { DataCollectionEditModal } from "./editor/page/data-tables-page/DataCollectionEditModal";
import { DataTableModal } from "./editor/page/data-tables-page/DataTableModal";
import { DynamicDataModal } from "./editor/page/DynamicDataModal";
import { DeleteComponentTemplateConfirmationModal } from "./editor/page/element-editor/components/DeleteComponentTemplateConfirmationModal";
import { DeleteElementConfirmationModal } from "./editor/page/element-editor/components/DeleteElementConfirmationModal";
import { DeleteSwatchConfirmationModal } from "./editor/page/element-editor/components/DeleteSwatchConfirmationModal";
import { FullPageErrorModal } from "./editor/page/FullPageErrorModal";
import { ProjectMembershipModal } from "./editor/page/ProjectMembershipModal";
import { StorePasswordRequiredModal } from "./editor/page/StorePasswordRequiredModal";
import { SupportModal } from "./editor/page/SupportModal";
import { WorkspaceMembershipModal } from "./editor/page/WorkspaceMembershipModal";
import { ExportToSectionModal } from "./ExportToSectionModal";
import { HotkeysModal } from "./HotkeysModal";
import { AddAnalyticsCustomDomainModal } from "./modals/AddAnalyticsCustomDomainModal";
import { AddReferralCodeModal } from "./modals/AddReferralCodeModal";
import { AddWorkspaceModal } from "./modals/AddWorkspaceModal";
import { AIPageModal } from "./modals/AIPageModal";
import { AnnouncementsModal } from "./modals/AnnouncementsModal";
import { CodeEditorModal } from "./modals/CodeEditorModal";
import { CreateElementModal } from "./modals/CreateElementModal";
import { DeleteProjectModal } from "./modals/DeleteProjectModal";
import { DeleteReferralCodeModal } from "./modals/DeleteReferralCodeModal";
import { DeleteWorkspaceModal } from "./modals/DeleteWorkspaceModal";
import { DuplicateElementModal } from "./modals/DuplicateElementModal";
import { InviteTeamMembersModal } from "./modals/InviteTeamMembersModal";
import { MoveProjectModal } from "./modals/MoveProjectModal";
import { PendingShopifyIntegrationModal } from "./modals/PendingShopifyIntegrationModal";
import { RemoveMemberModal } from "./modals/RemoveMemberModal";
import { RemoveShopifyIntegrationModal } from "./modals/RemoveShopifyIntegrationModal";
import { UninstallShopifyIntegrationModal } from "./modals/UninstallShopifyIntegrationModal";
import { UpdateElementModal } from "./modals/UpdateElementModal";
import { SaveTemplateModal } from "./SaveTemplateModal";

const modalMapping = {
  projectMembershipModal: { ModalComponent: ProjectMembershipModal },
  createElementModal: {
    ModalComponent: CreateElementModal,
  },
  updateElementModal: {
    ModalComponent: UpdateElementModal,
  },
  duplicateElementModal: {
    ModalComponent: DuplicateElementModal,
  },
  deleteComponentTemplateConfirmationModal: {
    ModalComponent: DeleteComponentTemplateConfirmationModal,
  },
  deleteElementConfirmationModal: {
    ModalComponent: DeleteElementConfirmationModal,
  },
  deleteSwatchConfirmationModal: {
    ModalComponent: DeleteSwatchConfirmationModal,
  },
  assetLibraryModal: { ModalComponent: AssetLibraryModal },
  saveTemplateModal: { ModalComponent: SaveTemplateModal },
  hotkeysModal: { ModalComponent: HotkeysModal },
  dynamicDataModal: { ModalComponent: DynamicDataModal },
  supportModal: { ModalComponent: SupportModal },
  dataTableModal: { ModalComponent: DataTableModal },
  dataCollectionEditModal: { ModalComponent: DataCollectionEditModal },
  createOrConfigureDataCollectionModal: {
    ModalComponent: CreateOrConfigureDataCollectionModal,
  },
  storePasswordRequiredModal: {
    ModalComponent: StorePasswordRequiredModal,
  },
  fullPageErrorModal: {
    ModalComponent: FullPageErrorModal,
  },
  billingModal: {
    ModalComponent: BillingModal,
  },
  billingPlanChangeSurvey: {
    ModalComponent: BillingPlanChangeSurveyModal,
  },
  billingUpgradeDowngradeModal: {
    ModalComponent: BillingUpgradeDowngradeModal,
  },
  billingStripeNotificationModal: {
    ModalComponent: BillingStripeNotificationModal,
  },
  exportToSectionModal: {
    ModalComponent: ExportToSectionModal,
  },
  aiPageModal: {
    ModalComponent: AIPageModal,
  },
  announcementsModal: {
    ModalComponent: AnnouncementsModal,
  },
  inviteTeamMembersModal: {
    ModalComponent: InviteTeamMembersModal,
  },
  deleteWorkspaceModal: {
    ModalComponent: DeleteWorkspaceModal,
  },
  addWorkspaceModal: {
    ModalComponent: AddWorkspaceModal,
  },
  addReferralCodeModal: {
    ModalComponent: AddReferralCodeModal,
  },
  moveProjectModal: {
    ModalComponent: MoveProjectModal,
  },
  pendingShopifyIntegrationModal: {
    ModalComponent: PendingShopifyIntegrationModal,
  },
  removeShopifyIntegrationModal: {
    ModalComponent: RemoveShopifyIntegrationModal,
  },
  uninstallShopifyIntegrationModal: {
    ModalComponent: UninstallShopifyIntegrationModal,
  },
  deleteProjectModal: {
    ModalComponent: DeleteProjectModal,
  },
  removeMemberModal: {
    ModalComponent: RemoveMemberModal,
  },
  deleteReferralCodeModal: {
    ModalComponent: DeleteReferralCodeModal,
  },
  codeEditorModal: { ModalComponent: CodeEditorModal },
  workspaceMembershipModal: {
    ModalComponent: WorkspaceMembershipModal,
  },
  customDomainModal: {
    ModalComponent: AddAnalyticsCustomDomainModal,
  },
} satisfies {
  [K in AppModalType]: { ModalComponent: React.ComponentType<any> };
};

const AppModals = () => {
  const { modals, openModal } = useModal();

  // NOTE (Chance 2024-03-23): The billing modal is not a route modal, it's
  // managed in state. We do want to open it if the billing plan query param.
  // This seems to be the only modal we try to control with query params, so I
  // don't think we need to try and generalize or abstract this right now.
  const [searchParams, setSearchParams] = useSearchParams();
  const billingConfirmParam = searchParams.get(BILLING_PLAN_CONFIRMATION_PARAM);

  const isBillingPlanConfirmed = billingConfirmParam === "true";
  const billingModalIsOpen = modals.billingModal != null;
  React.useEffect(() => {
    if (!billingModalIsOpen && isBillingPlanConfirmed) {
      openModal({
        type: "billingModal",
        props: {
          source: "unknown",
        },
      });
    }
  }, [billingModalIsOpen, isBillingPlanConfirmed, openModal]);

  const isBillingPlanFailure = billingConfirmParam === "false";
  React.useEffect(() => {
    if (isBillingPlanFailure) {
      errorToast(
        "Something went wrong updating your plan",
        "Please contact support@replo.app for help.",
      );
      setSearchParams(
        (params) => {
          params.delete(BILLING_PLAN_CONFIRMATION_PARAM);
          return new URLSearchParams(params);
        },
        { replace: true },
      );
    }
  }, [isBillingPlanFailure, setSearchParams]);

  const rtn = [];
  for (const [type, params] of Object.entries(modals || {})) {
    const Modal = modalMapping[type as AppModalType].ModalComponent;
    if (!Modal) {
      continue;
    }
    rtn.push(<Modal key={type} {...params} />);
  }
  return rtn;
};

export default AppModals;
