import type { StoreProduct } from "replo-runtime/shared/types";

export const REPLO_UI_PRODUCTS: StoreProduct[] = [
  {
    id: "8222987845942",
    title: "The Magic Box",
    type: "",
    description:
      "<p>Unleash the power of magic with The Magic Box — the ultimate storage solution that will transport you to a world of wonder and enchantment! This magical box is like nothing you've ever seen before.</p>\n\n<p>It has the incredible ability to expand and contract according to your needs, making organizing your space effortless and otherworldly. Whether you need to store clothing, books, or even mystical artifacts, The Magic Box has got you covered.<br></p>",
    handle: "the-magic-box",
    status: "ACTIVE",
    images: [
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1.jpg?v=1680037856",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_1.jpg?v=1680037856",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_2.jpg?v=1680037856",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_3.jpg?v=1680037856",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_4.jpg?v=1680037856",
    ],
    variants: [
      {
        id: 44_876_393_808_182,
        sku: null,
        available: true,
        title: "Extra Small / Gray 100",
        name: "The Magic Box - Extra Small / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1.jpg?v=1680037856",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_393_840_950,
        sku: null,
        available: true,
        title: "Extra Small / Gray 200",
        name: "The Magic Box - Extra Small / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_1.jpg?v=1680037856",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_393_873_718,
        sku: null,
        available: true,
        title: "Extra Small / Gray 300",
        name: "The Magic Box - Extra Small / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_2.jpg?v=1680037856",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_393_906_486,
        sku: null,
        available: true,
        title: "Extra Small / Gray 400",
        name: "The Magic Box - Extra Small / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_3.jpg?v=1680037856",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_393_939_254,
        sku: null,
        available: true,
        title: "Extra Small / Gray 500",
        name: "The Magic Box - Extra Small / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_4.jpg?v=1680037856",
        },
        option2: "Gray 500",
      },
      {
        id: 44_896_574_079_286,
        sku: null,
        available: true,
        title: "Small / Gray 100",
        name: "The Magic Box - Small / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1.jpg?v=1680037856",
        },
        option2: "Gray 100",
      },
      {
        id: 44_896_574_112_054,
        sku: null,
        available: true,
        title: "Small / Gray 200",
        name: "The Magic Box - Small / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_1.jpg?v=1680037856",
        },
        option2: "Gray 200",
      },
      {
        id: 44_896_574_144_822,
        sku: null,
        available: true,
        title: "Small / Gray 300",
        name: "The Magic Box - Small / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_2.jpg?v=1680037856",
        },
        option2: "Gray 300",
      },
      {
        id: 44_896_574_177_590,
        sku: null,
        available: true,
        title: "Small / Gray 400",
        name: "The Magic Box - Small / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_3.jpg?v=1680037856",
        },
        option2: "Gray 400",
      },
      {
        id: 44_896_574_210_358,
        sku: null,
        available: true,
        title: "Small / Gray 500",
        name: "The Magic Box - Small / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_4.jpg?v=1680037856",
        },
        option2: "Gray 500",
      },
      {
        id: 44_876_393_972_022,
        sku: null,
        available: true,
        title: "Medium / Gray 100",
        name: "The Magic Box - Medium / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1.jpg?v=1680037856",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_394_004_790,
        sku: null,
        available: true,
        title: "Medium / Gray 200",
        name: "The Magic Box - Medium / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_1.jpg?v=1680037856",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_394_037_558,
        sku: null,
        available: true,
        title: "Medium / Gray 300",
        name: "The Magic Box - Medium / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_2.jpg?v=1680037856",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_394_070_326,
        sku: null,
        available: true,
        title: "Medium / Gray 400",
        name: "The Magic Box - Medium / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_3.jpg?v=1680037856",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_394_103_094,
        sku: null,
        available: true,
        title: "Medium / Gray 500",
        name: "The Magic Box - Medium / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_4.jpg?v=1680037856",
        },
        option2: "Gray 500",
      },
      {
        id: 44_876_394_135_862,
        sku: null,
        available: true,
        title: "Large / Gray 100",
        name: "The Magic Box - Large / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1.jpg?v=1680037856",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_394_168_630,
        sku: null,
        available: true,
        title: "Large / Gray 200",
        name: "The Magic Box - Large / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_1.jpg?v=1680037856",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_394_201_398,
        sku: null,
        available: true,
        title: "Large / Gray 300",
        name: "The Magic Box - Large / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_2.jpg?v=1680037856",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_394_234_166,
        sku: null,
        available: true,
        title: "Large / Gray 400",
        name: "The Magic Box - Large / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_3.jpg?v=1680037856",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_394_266_934,
        sku: null,
        available: true,
        title: "Large / Gray 500",
        name: "The Magic Box - Large / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_4.jpg?v=1680037856",
        },
        option2: "Gray 500",
      },
      {
        id: 44_876_394_299_702,
        sku: null,
        available: true,
        title: "Extra Large / Gray 100",
        name: "The Magic Box - Extra Large / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1.jpg?v=1680037856",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_394_332_470,
        sku: null,
        available: true,
        title: "Extra Large / Gray 200",
        name: "The Magic Box - Extra Large / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_1.jpg?v=1680037856",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_394_365_238,
        sku: null,
        available: true,
        title: "Extra Large / Gray 300",
        name: "The Magic Box - Extra Large / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_2.jpg?v=1680037856",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_394_398_006,
        sku: null,
        available: true,
        title: "Extra Large / Gray 400",
        name: "The Magic Box - Extra Large / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_3.jpg?v=1680037856",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_394_430_774,
        sku: null,
        available: true,
        title: "Extra Large / Gray 500",
        name: "The Magic Box - Extra Large / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1_4.jpg?v=1680037856",
        },
        option2: "Gray 500",
      },
    ],
    options: ["Size", "Color"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image1.jpg?v=1680037856",
    selling_plan_groups: [
      {
        id: "1259307318",
        app_id: "SKIO",
        options: [
          {
            name: "Every-gid://shopify/ProductVariant/44876394070326",
            position: 1,
            values: ["Every 60 days", "Every 30 days", "Every 90 days"],
          },
        ],
        selling_plans: [
          {
            id: 5_936_644_406,
            name: "Every 30 days",
            description: null,
            options: [
              {
                name: "Every-gid://shopify/ProductVariant/44876394070326",
                position: 1,
                value: "Every 30 days",
              },
            ],
            price_adjustments: [
              {
                value: 20,
                value_type: "percentage",
              },
            ],
          },
          {
            id: 5_936_677_174,
            name: "Every 60 days",
            description: null,
            options: [
              {
                name: "Every-gid://shopify/ProductVariant/44876394070326",
                position: 1,
                value: "Every 60 days",
              },
            ],
            price_adjustments: [
              {
                value: 20,
                value_type: "percentage",
              },
            ],
          },
          {
            id: 5_936_709_942,
            name: "Every 90 days",
            description: null,
            options: [
              {
                name: "Every-gid://shopify/ProductVariant/44876394070326",
                position: 1,
                value: "Every 90 days",
              },
            ],
            price_adjustments: [
              {
                value: 20,
                value_type: "percentage",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "8222997119286",
    title: "Secretive Spell Box",
    type: "",
    description:
      "Unlock the power of the occult with this mysterious and magical box. Open it to find ancient scrolls, rare ingredients, and powerful spells that can bring you luck and fortune.",
    handle: "secretive-spell-box",
    status: "ACTIVE",
    images: [
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2.jpg?v=1680037718",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_1.jpg?v=1680037718",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_2.jpg?v=1680037718",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_3.jpg?v=1680037718",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_5.jpg?v=1680037718",
    ],
    variants: [
      {
        id: 44_876_418_416_950,
        sku: null,
        available: true,
        title: "Extra Small / Gray 100",
        name: "Secretive Spell Box - Extra Small / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2.jpg?v=1680037718",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_418_449_718,
        sku: null,
        available: true,
        title: "Extra Small / Gray 200",
        name: "Secretive Spell Box - Extra Small / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_1.jpg?v=1680037718",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_418_482_486,
        sku: null,
        available: true,
        title: "Extra Small / Gray 300",
        name: "Secretive Spell Box - Extra Small / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_2.jpg?v=1680037718",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_418_515_254,
        sku: null,
        available: true,
        title: "Extra Small / Gray 400",
        name: "Secretive Spell Box - Extra Small / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_3.jpg?v=1680037718",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_418_548_022,
        sku: null,
        available: true,
        title: "Extra Small / Gray 500",
        name: "Secretive Spell Box - Extra Small / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_5.jpg?v=1680037718",
        },
        option2: "Gray 500",
      },
      {
        id: 44_896_573_325_622,
        sku: null,
        available: true,
        title: "Small / Gray 100",
        name: "Secretive Spell Box - Small / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2.jpg?v=1680037718",
        },
        option2: "Gray 100",
      },
      {
        id: 44_896_573_358_390,
        sku: null,
        available: true,
        title: "Small / Gray 200",
        name: "Secretive Spell Box - Small / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_1.jpg?v=1680037718",
        },
        option2: "Gray 200",
      },
      {
        id: 44_896_573_391_158,
        sku: null,
        available: true,
        title: "Small / Gray 300",
        name: "Secretive Spell Box - Small / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_2.jpg?v=1680037718",
        },
        option2: "Gray 300",
      },
      {
        id: 44_896_573_423_926,
        sku: null,
        available: true,
        title: "Small / Gray 400",
        name: "Secretive Spell Box - Small / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_3.jpg?v=1680037718",
        },
        option2: "Gray 400",
      },
      {
        id: 44_896_573_456_694,
        sku: null,
        available: true,
        title: "Small / Gray 500",
        name: "Secretive Spell Box - Small / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_5.jpg?v=1680037718",
        },
        option2: "Gray 500",
      },
      {
        id: 44_876_418_580_790,
        sku: null,
        available: true,
        title: "Medium / Gray 100",
        name: "Secretive Spell Box - Medium / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2.jpg?v=1680037718",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_418_613_558,
        sku: null,
        available: true,
        title: "Medium / Gray 200",
        name: "Secretive Spell Box - Medium / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_1.jpg?v=1680037718",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_418_646_326,
        sku: null,
        available: true,
        title: "Medium / Gray 300",
        name: "Secretive Spell Box - Medium / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_2.jpg?v=1680037718",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_418_679_094,
        sku: null,
        available: true,
        title: "Medium / Gray 400",
        name: "Secretive Spell Box - Medium / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_3.jpg?v=1680037718",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_418_711_862,
        sku: null,
        available: true,
        title: "Medium / Gray 500",
        name: "Secretive Spell Box - Medium / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_5.jpg?v=1680037718",
        },
        option2: "Gray 500",
      },
      {
        id: 44_876_418_744_630,
        sku: null,
        available: true,
        title: "Large / Gray 100",
        name: "Secretive Spell Box - Large / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2.jpg?v=1680037718",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_418_777_398,
        sku: null,
        available: true,
        title: "Large / Gray 200",
        name: "Secretive Spell Box - Large / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_1.jpg?v=1680037718",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_418_810_166,
        sku: null,
        available: true,
        title: "Large / Gray 300",
        name: "Secretive Spell Box - Large / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_2.jpg?v=1680037718",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_418_842_934,
        sku: null,
        available: true,
        title: "Large / Gray 400",
        name: "Secretive Spell Box - Large / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_3.jpg?v=1680037718",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_418_875_702,
        sku: null,
        available: true,
        title: "Large / Gray 500",
        name: "Secretive Spell Box - Large / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_5.jpg?v=1680037718",
        },
        option2: "Gray 500",
      },
      {
        id: 44_876_418_908_470,
        sku: null,
        available: true,
        title: "Extra Large / Gray 100",
        name: "Secretive Spell Box - Extra Large / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2.jpg?v=1680037718",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_418_941_238,
        sku: null,
        available: true,
        title: "Extra Large / Gray 200",
        name: "Secretive Spell Box - Extra Large / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_1.jpg?v=1680037718",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_418_974_006,
        sku: null,
        available: true,
        title: "Extra Large / Gray 300",
        name: "Secretive Spell Box - Extra Large / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_2.jpg?v=1680037718",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_419_006_774,
        sku: null,
        available: true,
        title: "Extra Large / Gray 400",
        name: "Secretive Spell Box - Extra Large / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_3.jpg?v=1680037718",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_419_039_542,
        sku: null,
        available: true,
        title: "Extra Large / Gray 500",
        name: "Secretive Spell Box - Extra Large / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2_5.jpg?v=1680037718",
        },
        option2: "Gray 500",
      },
    ],
    options: ["Size", "Color"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image2.jpg?v=1680037718",
    selling_plan_groups: [
      {
        id: "1256849718",
        app_id: "SKIO",
        options: [
          {
            name: "Every-gid://shopify/ProductVariant/44876418974006",
            position: 1,
            values: ["Every 60 days", "Every 90 days", "Every 30 days"],
          },
        ],
        selling_plans: [
          {
            id: 5_929_271_606,
            name: "Every 30 days",
            description: null,
            options: [
              {
                name: "Every-gid://shopify/ProductVariant/44876418974006",
                position: 1,
                value: "Every 30 days",
              },
            ],
            price_adjustments: [
              {
                value: 20,
                value_type: "percentage",
              },
            ],
          },
          {
            id: 5_929_304_374,
            name: "Every 60 days",
            description: null,
            options: [
              {
                name: "Every-gid://shopify/ProductVariant/44876418974006",
                position: 1,
                value: "Every 60 days",
              },
            ],
            price_adjustments: [
              {
                value: 20,
                value_type: "percentage",
              },
            ],
          },
          {
            id: 5_929_337_142,
            name: "Every 90 days",
            description: null,
            options: [
              {
                name: "Every-gid://shopify/ProductVariant/44876418974006",
                position: 1,
                value: "Every 90 days",
              },
            ],
            price_adjustments: [
              {
                value: 20,
                value_type: "percentage",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "8223001674038",
    title: "Legendary Fortune Box",
    type: "",
    description:
      "Unlock the power of luck and prosperity with this mysterious and magical box. Open it to find rare coins, exotic gems, and powerful spells that can bring you great wealth.",
    handle: "legendary-fortune-box",
    status: "ACTIVE",
    images: [
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_a7f0998a-4194-4464-93c3-0f871c43f48d.jpg?v=1680038021",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_2.jpg?v=1680038021",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_3.jpg?v=1680038021",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_5.jpg?v=1680038021",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_6.jpg?v=1680038021",
    ],
    variants: [
      {
        id: 44_876_440_502_582,
        sku: null,
        available: true,
        title: "Extra Small / Gray 100",
        name: "Legendary Fortune Box - Extra Small / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_a7f0998a-4194-4464-93c3-0f871c43f48d.jpg?v=1680038021",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_440_535_350,
        sku: null,
        available: true,
        title: "Extra Small / Gray 200",
        name: "Legendary Fortune Box - Extra Small / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_2.jpg?v=1680038021",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_440_568_118,
        sku: null,
        available: true,
        title: "Extra Small / Gray 300",
        name: "Legendary Fortune Box - Extra Small / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_3.jpg?v=1680038021",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_440_600_886,
        sku: null,
        available: true,
        title: "Extra Small / Gray 400",
        name: "Legendary Fortune Box - Extra Small / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_5.jpg?v=1680038021",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_440_633_654,
        sku: null,
        available: true,
        title: "Extra Small / Gray 500",
        name: "Legendary Fortune Box - Extra Small / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_6.jpg?v=1680038021",
        },
        option2: "Gray 500",
      },
      {
        id: 44_896_575_619_382,
        sku: null,
        available: true,
        title: "Small / Gray 100",
        name: "Legendary Fortune Box - Small / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_a7f0998a-4194-4464-93c3-0f871c43f48d.jpg?v=1680038021",
        },
        option2: "Gray 100",
      },
      {
        id: 44_896_575_652_150,
        sku: null,
        available: true,
        title: "Small / Gray 200",
        name: "Legendary Fortune Box - Small / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_2.jpg?v=1680038021",
        },
        option2: "Gray 200",
      },
      {
        id: 44_896_575_684_918,
        sku: null,
        available: true,
        title: "Small / Gray 300",
        name: "Legendary Fortune Box - Small / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_3.jpg?v=1680038021",
        },
        option2: "Gray 300",
      },
      {
        id: 44_896_575_717_686,
        sku: null,
        available: true,
        title: "Small / Gray 400",
        name: "Legendary Fortune Box - Small / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_5.jpg?v=1680038021",
        },
        option2: "Gray 400",
      },
      {
        id: 44_896_575_750_454,
        sku: null,
        available: true,
        title: "Small / Gray 500",
        name: "Legendary Fortune Box - Small / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_6.jpg?v=1680038021",
        },
        option2: "Gray 500",
      },
      {
        id: 44_876_440_666_422,
        sku: null,
        available: true,
        title: "Medium / Gray 100",
        name: "Legendary Fortune Box - Medium / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_a7f0998a-4194-4464-93c3-0f871c43f48d.jpg?v=1680038021",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_440_699_190,
        sku: null,
        available: true,
        title: "Medium / Gray 200",
        name: "Legendary Fortune Box - Medium / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_2.jpg?v=1680038021",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_440_731_958,
        sku: null,
        available: true,
        title: "Medium / Gray 300",
        name: "Legendary Fortune Box - Medium / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_3.jpg?v=1680038021",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_440_764_726,
        sku: null,
        available: true,
        title: "Medium / Gray 400",
        name: "Legendary Fortune Box - Medium / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_5.jpg?v=1680038021",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_440_797_494,
        sku: null,
        available: true,
        title: "Medium / Gray 500",
        name: "Legendary Fortune Box - Medium / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_6.jpg?v=1680038021",
        },
        option2: "Gray 500",
      },
      {
        id: 44_876_440_830_262,
        sku: null,
        available: true,
        title: "Large / Gray 100",
        name: "Legendary Fortune Box - Large / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_a7f0998a-4194-4464-93c3-0f871c43f48d.jpg?v=1680038021",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_440_863_030,
        sku: null,
        available: true,
        title: "Large / Gray 200",
        name: "Legendary Fortune Box - Large / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_2.jpg?v=1680038021",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_440_895_798,
        sku: null,
        available: true,
        title: "Large / Gray 300",
        name: "Legendary Fortune Box - Large / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_3.jpg?v=1680038021",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_440_928_566,
        sku: null,
        available: true,
        title: "Large / Gray 400",
        name: "Legendary Fortune Box - Large / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_5.jpg?v=1680038021",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_440_961_334,
        sku: null,
        available: true,
        title: "Large / Gray 500",
        name: "Legendary Fortune Box - Large / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_6.jpg?v=1680038021",
        },
        option2: "Gray 500",
      },
      {
        id: 44_876_440_994_102,
        sku: null,
        available: true,
        title: "Extra Large / Gray 100",
        name: "Legendary Fortune Box - Extra Large / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_a7f0998a-4194-4464-93c3-0f871c43f48d.jpg?v=1680038021",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_441_026_870,
        sku: null,
        available: true,
        title: "Extra Large / Gray 200",
        name: "Legendary Fortune Box - Extra Large / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_2.jpg?v=1680038021",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_441_059_638,
        sku: null,
        available: true,
        title: "Extra Large / Gray 300",
        name: "Legendary Fortune Box - Extra Large / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_3.jpg?v=1680038021",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_441_092_406,
        sku: null,
        available: true,
        title: "Extra Large / Gray 400",
        name: "Legendary Fortune Box - Extra Large / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_5.jpg?v=1680038021",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_441_125_174,
        sku: null,
        available: true,
        title: "Extra Large / Gray 500",
        name: "Legendary Fortune Box - Extra Large / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_6.jpg?v=1680038021",
        },
        option2: "Gray 500",
      },
    ],
    options: ["Size", "Color"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image3_a7f0998a-4194-4464-93c3-0f871c43f48d.jpg?v=1680038021",
    selling_plan_groups: [
      {
        id: "1257668918",
        app_id: "SKIO",
        options: [
          {
            name: "Every-gid://shopify/ProductVariant/44876441092406",
            position: 1,
            values: ["Every 60 days", "Every 90 days", "Every 30 days"],
          },
        ],
        selling_plans: [
          {
            id: 5_931_729_206,
            name: "Every 30 days",
            description: null,
            options: [
              {
                name: "Every-gid://shopify/ProductVariant/44876441092406",
                position: 1,
                value: "Every 30 days",
              },
            ],
            price_adjustments: [
              {
                value: 20,
                value_type: "percentage",
              },
            ],
          },
          {
            id: 5_931_761_974,
            name: "Every 60 days",
            description: null,
            options: [
              {
                name: "Every-gid://shopify/ProductVariant/44876441092406",
                position: 1,
                value: "Every 60 days",
              },
            ],
            price_adjustments: [
              {
                value: 20,
                value_type: "percentage",
              },
            ],
          },
          {
            id: 5_931_794_742,
            name: "Every 90 days",
            description: null,
            options: [
              {
                name: "Every-gid://shopify/ProductVariant/44876441092406",
                position: 1,
                value: "Every 90 days",
              },
            ],
            price_adjustments: [
              {
                value: 20,
                value_type: "percentage",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "8223008293174",
    title: "Enchanting Charm Box",
    type: "",
    description:
      "Unlock the power of good fortune with this mysterious and magical box. Open it to find ancient charms, powerful crystals, and powerful spells that can bring you good luck and success.",
    handle: "enchanting-charm-box",
    status: "ACTIVE",
    images: [
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4.jpg?v=1679763036",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_1.jpg?v=1679763036",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_2.jpg?v=1679763036",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_3.jpg?v=1680038078",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_4.jpg?v=1680038078",
    ],
    variants: [
      {
        id: 44_876_468_453_686,
        sku: null,
        available: true,
        title: "Extra Small / Gray 100",
        name: "Enchanting Charm Box - Extra Small / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4.jpg?v=1679763036",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_468_486_454,
        sku: null,
        available: true,
        title: "Extra Small / Gray 200",
        name: "Enchanting Charm Box - Extra Small / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_1.jpg?v=1679763036",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_468_519_222,
        sku: null,
        available: true,
        title: "Extra Small / Gray 300",
        name: "Enchanting Charm Box - Extra Small / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_2.jpg?v=1679763036",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_468_551_990,
        sku: null,
        available: true,
        title: "Extra Small / Gray 400",
        name: "Enchanting Charm Box - Extra Small / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_3.jpg?v=1680038078",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_468_584_758,
        sku: null,
        available: true,
        title: "Extra Small / Gray 500",
        name: "Enchanting Charm Box - Extra Small / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_4.jpg?v=1680038078",
        },
        option2: "Gray 500",
      },
      {
        id: 44_896_576_340_278,
        sku: null,
        available: true,
        title: "Small / Gray 100",
        name: "Enchanting Charm Box - Small / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4.jpg?v=1679763036",
        },
        option2: "Gray 100",
      },
      {
        id: 44_896_576_373_046,
        sku: null,
        available: true,
        title: "Small / Gray 200",
        name: "Enchanting Charm Box - Small / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_1.jpg?v=1679763036",
        },
        option2: "Gray 200",
      },
      {
        id: 44_896_576_405_814,
        sku: null,
        available: true,
        title: "Small / Gray 300",
        name: "Enchanting Charm Box - Small / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_2.jpg?v=1679763036",
        },
        option2: "Gray 300",
      },
      {
        id: 44_896_576_438_582,
        sku: null,
        available: true,
        title: "Small / Gray 400",
        name: "Enchanting Charm Box - Small / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_3.jpg?v=1680038078",
        },
        option2: "Gray 400",
      },
      {
        id: 44_896_576_471_350,
        sku: null,
        available: true,
        title: "Small / Gray 500",
        name: "Enchanting Charm Box - Small / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_4.jpg?v=1680038078",
        },
        option2: "Gray 500",
      },
      {
        id: 44_876_468_617_526,
        sku: null,
        available: true,
        title: "Medium / Gray 100",
        name: "Enchanting Charm Box - Medium / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4.jpg?v=1679763036",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_468_650_294,
        sku: null,
        available: true,
        title: "Medium / Gray 200",
        name: "Enchanting Charm Box - Medium / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_1.jpg?v=1679763036",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_468_683_062,
        sku: null,
        available: true,
        title: "Medium / Gray 300",
        name: "Enchanting Charm Box - Medium / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_2.jpg?v=1679763036",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_468_715_830,
        sku: null,
        available: true,
        title: "Medium / Gray 400",
        name: "Enchanting Charm Box - Medium / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_3.jpg?v=1680038078",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_468_748_598,
        sku: null,
        available: true,
        title: "Medium / Gray 500",
        name: "Enchanting Charm Box - Medium / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_4.jpg?v=1680038078",
        },
        option2: "Gray 500",
      },
      {
        id: 44_876_468_781_366,
        sku: null,
        available: true,
        title: "Large / Gray 100",
        name: "Enchanting Charm Box - Large / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4.jpg?v=1679763036",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_468_814_134,
        sku: null,
        available: true,
        title: "Large / Gray 200",
        name: "Enchanting Charm Box - Large / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_1.jpg?v=1679763036",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_468_846_902,
        sku: null,
        available: true,
        title: "Large / Gray 300",
        name: "Enchanting Charm Box - Large / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_2.jpg?v=1679763036",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_468_879_670,
        sku: null,
        available: true,
        title: "Large / Gray 400",
        name: "Enchanting Charm Box - Large / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_3.jpg?v=1680038078",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_468_912_438,
        sku: null,
        available: true,
        title: "Large / Gray 500",
        name: "Enchanting Charm Box - Large / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_4.jpg?v=1680038078",
        },
        option2: "Gray 500",
      },
      {
        id: 44_876_468_945_206,
        sku: null,
        available: true,
        title: "Extra Large / Gray 100",
        name: "Enchanting Charm Box - Extra Large / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4.jpg?v=1679763036",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_468_977_974,
        sku: null,
        available: true,
        title: "Extra Large / Gray 200",
        name: "Enchanting Charm Box - Extra Large / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_1.jpg?v=1679763036",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_469_010_742,
        sku: null,
        available: true,
        title: "Extra Large / Gray 300",
        name: "Enchanting Charm Box - Extra Large / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_2.jpg?v=1679763036",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_469_043_510,
        sku: null,
        available: true,
        title: "Extra Large / Gray 400",
        name: "Enchanting Charm Box - Extra Large / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_3.jpg?v=1680038078",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_469_076_278,
        sku: null,
        available: true,
        title: "Extra Large / Gray 500",
        name: "Enchanting Charm Box - Extra Large / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4_4.jpg?v=1680038078",
        },
        option2: "Gray 500",
      },
    ],
    options: ["Size", "Color"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image4.jpg?v=1679763036",
    selling_plan_groups: [
      {
        id: "1260126518",
        app_id: "SKIO",
        options: [
          {
            name: "Every-gid://shopify/ProductVariant/44876468814134",
            position: 1,
            values: ["Every 60 days", "Every 90 days", "Every 30 days"],
          },
        ],
        selling_plans: [
          {
            id: 5_939_102_006,
            name: "Every 30 days",
            description: null,
            options: [
              {
                name: "Every-gid://shopify/ProductVariant/44876468814134",
                position: 1,
                value: "Every 30 days",
              },
            ],
            price_adjustments: [
              {
                value: 20,
                value_type: "percentage",
              },
            ],
          },
          {
            id: 5_939_134_774,
            name: "Every 60 days",
            description: null,
            options: [
              {
                name: "Every-gid://shopify/ProductVariant/44876468814134",
                position: 1,
                value: "Every 60 days",
              },
            ],
            price_adjustments: [
              {
                value: 20,
                value_type: "percentage",
              },
            ],
          },
          {
            id: 5_939_167_542,
            name: "Every 90 days",
            description: null,
            options: [
              {
                name: "Every-gid://shopify/ProductVariant/44876468814134",
                position: 1,
                value: "Every 90 days",
              },
            ],
            price_adjustments: [
              {
                value: 20,
                value_type: "percentage",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "8223010455862",
    title: "Mysterious Mystery Box",
    type: "",
    description:
      "Unlock the power of the unknown with this mysterious and magical box. Open it to find mysterious artifacts, powerful relics, and powerful spells that can bring you untold secrets and knowledge.",
    handle: "mysterious-mystery-box",
    status: "ACTIVE",
    images: [
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5.jpg?v=1680037950",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_1.jpg?v=1680037950",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_3.jpg?v=1680037950",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_4.jpg?v=1680037950",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_5.jpg?v=1680037950",
    ],
    variants: [
      {
        id: 44_876_472_811_830,
        sku: null,
        available: true,
        title: "Extra Small / Gray 100",
        name: "Mysterious Mystery Box - Extra Small / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5.jpg?v=1680037950",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_472_844_598,
        sku: null,
        available: true,
        title: "Extra Small / Gray 200",
        name: "Mysterious Mystery Box - Extra Small / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_1.jpg?v=1680037950",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_472_877_366,
        sku: null,
        available: true,
        title: "Extra Small / Gray 300",
        name: "Mysterious Mystery Box - Extra Small / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_3.jpg?v=1680037950",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_472_910_134,
        sku: null,
        available: true,
        title: "Extra Small / Gray 400",
        name: "Mysterious Mystery Box - Extra Small / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_4.jpg?v=1680037950",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_472_942_902,
        sku: null,
        available: true,
        title: "Extra Small / Gray 500",
        name: "Mysterious Mystery Box - Extra Small / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_5.jpg?v=1680037950",
        },
        option2: "Gray 500",
      },
      {
        id: 44_896_574_898_486,
        sku: null,
        available: true,
        title: "Small / Gray 100",
        name: "Mysterious Mystery Box - Small / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5.jpg?v=1680037950",
        },
        option2: "Gray 100",
      },
      {
        id: 44_896_574_931_254,
        sku: null,
        available: true,
        title: "Small / Gray 200",
        name: "Mysterious Mystery Box - Small / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_1.jpg?v=1680037950",
        },
        option2: "Gray 200",
      },
      {
        id: 44_896_574_964_022,
        sku: null,
        available: true,
        title: "Small / Gray 300",
        name: "Mysterious Mystery Box - Small / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_3.jpg?v=1680037950",
        },
        option2: "Gray 300",
      },
      {
        id: 44_896_574_996_790,
        sku: null,
        available: true,
        title: "Small / Gray 400",
        name: "Mysterious Mystery Box - Small / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_4.jpg?v=1680037950",
        },
        option2: "Gray 400",
      },
      {
        id: 44_896_575_029_558,
        sku: null,
        available: true,
        title: "Small / Gray 500",
        name: "Mysterious Mystery Box - Small / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Small",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_5.jpg?v=1680037950",
        },
        option2: "Gray 500",
      },
      {
        id: 44_876_472_975_670,
        sku: null,
        available: true,
        title: "Medium / Gray 100",
        name: "Mysterious Mystery Box - Medium / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5.jpg?v=1680037950",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_473_008_438,
        sku: null,
        available: true,
        title: "Medium / Gray 200",
        name: "Mysterious Mystery Box - Medium / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_1.jpg?v=1680037950",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_473_041_206,
        sku: null,
        available: true,
        title: "Medium / Gray 300",
        name: "Mysterious Mystery Box - Medium / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_3.jpg?v=1680037950",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_473_073_974,
        sku: null,
        available: true,
        title: "Medium / Gray 400",
        name: "Mysterious Mystery Box - Medium / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_4.jpg?v=1680037950",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_473_106_742,
        sku: null,
        available: true,
        title: "Medium / Gray 500",
        name: "Mysterious Mystery Box - Medium / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Medium",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_5.jpg?v=1680037950",
        },
        option2: "Gray 500",
      },
      {
        id: 44_876_473_139_510,
        sku: null,
        available: true,
        title: "Large / Gray 100",
        name: "Mysterious Mystery Box - Large / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5.jpg?v=1680037950",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_473_172_278,
        sku: null,
        available: true,
        title: "Large / Gray 200",
        name: "Mysterious Mystery Box - Large / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_1.jpg?v=1680037950",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_473_205_046,
        sku: null,
        available: true,
        title: "Large / Gray 300",
        name: "Mysterious Mystery Box - Large / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_3.jpg?v=1680037950",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_473_237_814,
        sku: null,
        available: true,
        title: "Large / Gray 400",
        name: "Mysterious Mystery Box - Large / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_4.jpg?v=1680037950",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_473_270_582,
        sku: null,
        available: true,
        title: "Large / Gray 500",
        name: "Mysterious Mystery Box - Large / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_5.jpg?v=1680037950",
        },
        option2: "Gray 500",
      },
      {
        id: 44_876_473_303_350,
        sku: null,
        available: true,
        title: "Extra Large / Gray 100",
        name: "Mysterious Mystery Box - Extra Large / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5.jpg?v=1680037950",
        },
        option2: "Gray 100",
      },
      {
        id: 44_876_473_336_118,
        sku: null,
        available: true,
        title: "Extra Large / Gray 200",
        name: "Mysterious Mystery Box - Extra Large / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_1.jpg?v=1680037950",
        },
        option2: "Gray 200",
      },
      {
        id: 44_876_473_368_886,
        sku: null,
        available: true,
        title: "Extra Large / Gray 300",
        name: "Mysterious Mystery Box - Extra Large / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_3.jpg?v=1680037950",
        },
        option2: "Gray 300",
      },
      {
        id: 44_876_473_401_654,
        sku: null,
        available: true,
        title: "Extra Large / Gray 400",
        name: "Mysterious Mystery Box - Extra Large / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_4.jpg?v=1680037950",
        },
        option2: "Gray 400",
      },
      {
        id: 44_876_473_434_422,
        sku: null,
        available: true,
        title: "Extra Large / Gray 500",
        name: "Mysterious Mystery Box - Extra Large / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "Extra Large",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5_5.jpg?v=1680037950",
        },
        option2: "Gray 500",
      },
    ],
    options: ["Size", "Color"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/products/Image5.jpg?v=1680037950",
    selling_plan_groups: [
      {
        id: "1258488118",
        app_id: "SKIO",
        options: [
          {
            name: "Every-gid://shopify/ProductVariant/44876473368886",
            position: 1,
            values: ["Every 60 days", "Every 90 days", "Every 30 days"],
          },
        ],
        selling_plans: [
          {
            id: 5_934_186_806,
            name: "Every 30 days",
            description: null,
            options: [
              {
                name: "Every-gid://shopify/ProductVariant/44876473368886",
                position: 1,
                value: "Every 30 days",
              },
            ],
            price_adjustments: [
              {
                value: 20,
                value_type: "percentage",
              },
            ],
          },
          {
            id: 5_934_219_574,
            name: "Every 60 days",
            description: null,
            options: [
              {
                name: "Every-gid://shopify/ProductVariant/44876473368886",
                position: 1,
                value: "Every 60 days",
              },
            ],
            price_adjustments: [
              {
                value: 20,
                value_type: "percentage",
              },
            ],
          },
          {
            id: 5_934_252_342,
            name: "Every 90 days",
            description: null,
            options: [
              {
                name: "Every-gid://shopify/ProductVariant/44876473368886",
                position: 1,
                value: "Every 90 days",
              },
            ],
            price_adjustments: [
              {
                value: 20,
                value_type: "percentage",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "8262530171190",
    title: "Magic Potion",
    type: "",
    description:
      "<p>Our specially formulated potions are crafted from high-quality, natural ingredients sourced from around the world, ensuring that you receive a perfect blend of nutrients and antioxidants in every bottle.</p>\n<p>With our convenient monthly subscription plan, you'll never have to worry about running out of your favorite elixir again.</p>",
    handle: "magic-potion",
    status: "ACTIVE",
    images: [
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_7172edfa-f322-45d2-ac1f-a6bf477e3c37.jpg?v=1683665297",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_1_7eb3c8d6-192e-4076-9011-6d4ebab3d030.jpg?v=1683665299",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_2_48bfe3a1-5587-4156-b653-0ebbbf7c8a53.jpg?v=1683665302",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_3_f5e77220-1d80-4f4e-b8a6-bef9b67ecb28.jpg?v=1683665304",
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_4_bddf1d6d-8cc9-4866-9140-b66688373211.jpg?v=1683665306",
    ],
    variants: [
      {
        id: 45_065_727_934_774,
        sku: null,
        available: true,
        title: "10ml / Gray 100",
        name: "Magic Potion - 10ml / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "10ml",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_7172edfa-f322-45d2-ac1f-a6bf477e3c37.jpg?v=1683665297",
        },
        option2: "Gray 100",
      },
      {
        id: 45_065_728_000_310,
        sku: null,
        available: true,
        title: "10ml / Gray 200",
        name: "Magic Potion - 10ml / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "10ml",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_1_7eb3c8d6-192e-4076-9011-6d4ebab3d030.jpg?v=1683665299",
        },
        option2: "Gray 200",
      },
      {
        id: 45_065_728_033_078,
        sku: null,
        available: true,
        title: "10ml / Gray 300",
        name: "Magic Potion - 10ml / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "10ml",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_2_48bfe3a1-5587-4156-b653-0ebbbf7c8a53.jpg?v=1683665302",
        },
        option2: "Gray 300",
      },
      {
        id: 45_065_728_098_614,
        sku: null,
        available: true,
        title: "10ml / Gray 400",
        name: "Magic Potion - 10ml / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "10ml",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_3_f5e77220-1d80-4f4e-b8a6-bef9b67ecb28.jpg?v=1683665304",
        },
        option2: "Gray 400",
      },
      {
        id: 45_065_728_196_918,
        sku: null,
        available: true,
        title: "10ml / Gray 500",
        name: "Magic Potion - 10ml / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "10ml",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_4_bddf1d6d-8cc9-4866-9140-b66688373211.jpg?v=1683665306",
        },
        option2: "Gray 500",
      },
      {
        id: 45_065_728_262_454,
        sku: null,
        available: true,
        title: "50ml / Gray 100",
        name: "Magic Potion - 50ml / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "50ml",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_7172edfa-f322-45d2-ac1f-a6bf477e3c37.jpg?v=1683665297",
        },
        option2: "Gray 100",
      },
      {
        id: 45_065_728_327_990,
        sku: null,
        available: true,
        title: "50ml / Gray 200",
        name: "Magic Potion - 50ml / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "50ml",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_1_7eb3c8d6-192e-4076-9011-6d4ebab3d030.jpg?v=1683665299",
        },
        option2: "Gray 200",
      },
      {
        id: 45_065_728_393_526,
        sku: null,
        available: true,
        title: "50ml / Gray 300",
        name: "Magic Potion - 50ml / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "50ml",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_2_48bfe3a1-5587-4156-b653-0ebbbf7c8a53.jpg?v=1683665302",
        },
        option2: "Gray 300",
      },
      {
        id: 45_065_728_426_294,
        sku: null,
        available: true,
        title: "50ml / Gray 400",
        name: "Magic Potion - 50ml / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "50ml",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_3_f5e77220-1d80-4f4e-b8a6-bef9b67ecb28.jpg?v=1683665304",
        },
        option2: "Gray 400",
      },
      {
        id: 45_065_728_459_062,
        sku: null,
        available: true,
        title: "50ml / Gray 500",
        name: "Magic Potion - 50ml / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "50ml",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_4_bddf1d6d-8cc9-4866-9140-b66688373211.jpg?v=1683665306",
        },
        option2: "Gray 500",
      },
      {
        id: 45_065_728_491_830,
        sku: null,
        available: true,
        title: "100ml / Gray 100",
        name: "Magic Potion - 100ml / Gray 100",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "100ml",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_7172edfa-f322-45d2-ac1f-a6bf477e3c37.jpg?v=1683665297",
        },
        option2: "Gray 100",
      },
      {
        id: 45_065_728_524_598,
        sku: null,
        available: true,
        title: "100ml / Gray 200",
        name: "Magic Potion - 100ml / Gray 200",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "100ml",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_1_7eb3c8d6-192e-4076-9011-6d4ebab3d030.jpg?v=1683665299",
        },
        option2: "Gray 200",
      },
      {
        id: 45_065_728_557_366,
        sku: null,
        available: true,
        title: "100ml / Gray 300",
        name: "Magic Potion - 100ml / Gray 300",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "100ml",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_2_48bfe3a1-5587-4156-b653-0ebbbf7c8a53.jpg?v=1683665302",
        },
        option2: "Gray 300",
      },
      {
        id: 45_065_728_590_134,
        sku: null,
        available: true,
        title: "100ml / Gray 400",
        name: "Magic Potion - 100ml / Gray 400",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "100ml",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_3_f5e77220-1d80-4f4e-b8a6-bef9b67ecb28.jpg?v=1683665304",
        },
        option2: "Gray 400",
      },
      {
        id: 45_065_728_622_902,
        sku: null,
        available: true,
        title: "100ml / Gray 500",
        name: "Magic Potion - 100ml / Gray 500",
        price: "99.00",
        compare_at_price: "129.00",
        option1: "100ml",
        featured_image: {
          src: "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_4_bddf1d6d-8cc9-4866-9140-b66688373211.jpg?v=1683665306",
        },
        option2: "Gray 500",
      },
    ],
    options: ["Size", "Color"],
    featured_image:
      "https://cdn.shopify.com/s/files/1/0738/6123/4998/files/Image1_7172edfa-f322-45d2-ac1f-a6bf477e3c37.jpg?v=1683665297",
    selling_plan_groups: [
      {
        id: "1269793078",
        app_id: "SKIO",
        options: [
          {
            name: "gid://shopify/ProductVariant/45065728557366",
            position: 1,
            values: ["Every Month", "Every 2 Months", "Every 3 Months"],
          },
        ],
        selling_plans: [
          {
            id: 5_959_811_382,
            name: "Every Month",
            description: null,
            options: [
              {
                name: "gid://shopify/ProductVariant/45065728557366",
                position: 1,
                value: "Every Month",
              },
            ],
            price_adjustments: [
              {
                value: 20,
                value_type: "percentage",
              },
            ],
          },
          {
            id: 5_959_844_150,
            name: "Every 2 Month",
            description: null,
            options: [
              {
                name: "gid://shopify/ProductVariant/45065728557366",
                position: 1,
                value: "Every 2 Month",
              },
            ],
            price_adjustments: [
              {
                value: 20,
                value_type: "percentage",
              },
            ],
          },
          {
            id: 5_959_876_918,
            name: "Every 3 Months",
            description: null,
            options: [
              {
                name: "gid://shopify/ProductVariant/45065728557366",
                position: 1,
                value: "Every 3 Months",
              },
            ],
            price_adjustments: [
              {
                value: 20,
                value_type: "percentage",
              },
            ],
          },
        ],
      },
    ],
  },
];
