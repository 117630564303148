import type { ConditionOperatorEnum } from "schemas/analyticsRead";
import type { FilterCondition } from "schemas/generated/analyticsRead";

import * as React from "react";

import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import {
  OPERATOR_OPTIONS,
  URL_PARAMS_FILTER_OPTIONS,
} from "@/features/analytics/moreFilters/constants";
import {
  findLabelForValue,
  removeEntryFromUrlParams,
} from "@/features/analytics/moreFilters/utils";
import Tooltip from "@replo/design-system/components/tooltip";
import { BsX } from "react-icons/bs";

type AppliedFiltersTagProps = {
  attribute: string;
  filterCondition: FilterCondition;
  handleDeleteTag: any;
};

const AppliedFiltersTag: React.FC<AppliedFiltersTagProps> = ({
  attribute,
  filterCondition,
  handleDeleteTag,
}) => {
  const { operator, value } = filterCondition;

  const attributeLabel = findLabelForValue(
    URL_PARAMS_FILTER_OPTIONS,
    attribute,
  );

  const operatorLabel = findLabelForValue(OPERATOR_OPTIONS, operator);
  const operatorLabelLowercase = operatorLabel?.toLowerCase();

  return (
    <div className="text-xs text-default bg-subtle p-2 rounded flex flex-row items-center">
      <div>
        <Tooltip
          triggerAsChild
          content={value}
          delay={800}
          className="flex justify-center"
        >
          <div className="max-w-[250px] overflow-hidden truncate">
            <span>{attributeLabel}</span>
            &nbsp;
            <span className="font-semibold">{operatorLabelLowercase}</span>
            &nbsp;
            <span>{value}</span>
          </div>
        </Tooltip>
      </div>
      <div>
        <Tooltip triggerAsChild content="Remove filter" delay={800}>
          <span
            className="ml-3 cursor-pointer"
            onClick={() => handleDeleteTag(attribute, operator, value)}
          >
            <BsX className="h-4 w-4 bg-subtle" />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

const AppliedFiltersTags: React.FC = () => {
  const { getParamValue, setParamValue } = useAnalyticsQueryContext();

  const filters = getParamValue("filters");

  const urlParams = React.useMemo(() => filters.urlParams, [filters.urlParams]);

  const handleDeleteTag = (
    attribute: string,
    operator: ConditionOperatorEnum,
    value: string,
  ) => {
    const urlParamToRemove = {
      attribute,
      operator,
      value,
    };

    const updatedUrlParams = removeEntryFromUrlParams(
      urlParams,
      urlParamToRemove,
    );

    setParamValue("filters", {
      ...filters,
      urlParams: updatedUrlParams,
    });
  };

  return (
    <>
      {Object.entries(urlParams).map(([attribute, filterConditions], i) => {
        return filterConditions.map((filterCondition) => {
          return (
            <AppliedFiltersTag
              key={i}
              attribute={attribute}
              filterCondition={filterCondition}
              handleDeleteTag={handleDeleteTag}
            />
          );
        });
      })}
    </>
  );
};

export default AppliedFiltersTags;
