import type { SelectedFilters } from "schemas/componentTemplates";

import * as React from "react";

import { useLocation, useNavigate } from "react-router-dom";

export default function useMarketplaceFiltersState() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const selectedFilters = state?.selectedFilters ?? {
    badge: [],
    industry: [],
    category: [],
  };

  const setSelectedFilters = React.useCallback(
    (newFilters: SelectedFilters) => {
      navigate("", {
        state: {
          ...state,
          selectedFilters: newFilters,
        },
      });
    },
    [navigate, state],
  );

  return [selectedFilters, setSelectedFilters];
}
