import type { Option } from "@replo/design-system/components/shadcn/combobox/types";

import * as React from "react";

import {
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@replo/design-system/components/shadcn/core/command";
import classNames from "classnames";
import { BsSearch } from "react-icons/bs";

type OptionsListProps = {
  options: Option[];
  setValue: (value: string) => void;
  setOpen?: (value: boolean) => void;
  value: string;
  areOptionsSearchable?: boolean;
  input?: string;
  setInput?: (value: string) => void;
  inputPlaceholder?: string;
  isLoading?: boolean;
};

type CommandItemProps = Pick<
  OptionsListProps,
  "value" | "setValue" | "setOpen"
>;
const CommandOptionItem: React.FC<CommandItemProps & { option: Option }> = ({
  value,
  setValue,
  setOpen,
  option,
}) => {
  const label = option.label;
  const adjustedLabel = option.isBeingCreated ? `Create "${label}"` : label;

  return (
    <CommandItem
      key={option.value}
      value={option.value}
      disabled={option.isDisabled}
      onSelect={(currentValue) => {
        /**
         * NOTE (Max, 2024-09-17): If a component is provided, it means that clicking on the option should not
         * update the value: instead, the provided component will be responsible for updating the value (e.g. with a
         * date-picker).
         */
        option.onClick?.();
        if (!option.component) {
          setValue(currentValue);
          if (setOpen) {
            setOpen(false);
          }
        }
      }}
      className={classNames(
        "px-2 py-1 rounded-[4px] h-[32px] text-slate-800 font-normal cursor-pointer",
        {
          "hover:bg-hover": value !== option.value,
          "bg-blue-200": value === option.value,
        },
      )}
    >
      <div className="w-full">{option.component ?? adjustedLabel}</div>
    </CommandItem>
  );
};

const CommandOptionItems: React.FC<
  CommandItemProps & { options: Option[] }
> = ({ value, setValue, setOpen, options }) => {
  return (
    <>
      {options.map((option) => (
        <CommandOptionItem
          key={option.value}
          option={option}
          value={value}
          setValue={setValue}
          setOpen={setOpen}
        />
      ))}
    </>
  );
};

const OptionsList: React.FC<OptionsListProps> = ({
  options,
  setValue,
  setOpen,
  value,
  areOptionsSearchable = false,
  input,
  setInput,
  inputPlaceholder,
  isLoading,
}) => {
  const optionsAboveSeparator = options.filter(
    (option) => !option.isBelowSeparator,
  );
  const optionsBelowSeparator = options.filter(
    (option) => option.isBelowSeparator,
  );

  const showOptionsBelowSeparator = optionsBelowSeparator.length > 0;

  return (
    <>
      {areOptionsSearchable && (
        <div className="p-2">
          <CommandInput
            value={input}
            onValueChange={setInput}
            placeholder={inputPlaceholder ?? "Search..."}
            startEnhancer={<BsSearch className="text-slate-400 h-3 w-3" />}
            className="text-xs"
          />
        </div>
      )}
      <CommandList>
        <CommandEmpty>{isLoading ? "Loading..." : "No results"}</CommandEmpty>
        <CommandGroup
          className={classNames({
            "pb-1.5": showOptionsBelowSeparator,
          })}
        >
          <CommandOptionItems
            options={optionsAboveSeparator}
            value={value}
            setValue={setValue}
            setOpen={setOpen}
          />
        </CommandGroup>
        <div
          className={classNames("border-t border-gray-300 my-0 px-2", {
            "border-none": !showOptionsBelowSeparator,
          })}
        >
          {showOptionsBelowSeparator && (
            <CommandGroup className="py-1.5 -mx-2">
              <CommandOptionItems
                options={optionsBelowSeparator}
                value={value}
                setValue={setValue}
                setOpen={setOpen}
              />
            </CommandGroup>
          )}
        </div>
      </CommandList>
    </>
  );
};

export { OptionsList };
