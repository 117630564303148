import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const buyWithPrimeButton: ComponentTemplate = {
  id: "d7cfe57d-812e-436a-8c5c-2df0f4684333",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Buy With Prime Button",
  leftBarCategory: { main: "product", subCategory: "buttons" },
  tags: ["product", "widget"],
  preview: "integrations/buy-with-prime-button.svg",
  transforms: [],
  template: {
    id: "$uid",
    type: "buyWithPrimeButton",
    props: { style: { alignSelf: "stretch" } },
  },
};
