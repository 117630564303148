import type { EditorRootState } from "@editor/store";
import type { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import type { Middleware } from "redux";

type ResultType = Promise<number> | null;
export const sequenceActionMiddleware: Middleware<
  (
    action: (
      | AnyAction
      | ThunkAction<void, EditorRootState, unknown, AnyAction>
    )[],
  ) => null,
  EditorRootState
> = ({ dispatch }) => {
  return (next) => {
    return (action) => {
      if (!Array.isArray(action)) {
        return next(action);
      }

      let result: ResultType = null;
      for (const currAction of action) {
        const step = () => {
          if (!currAction) {
            return undefined;
          }

          if (Array.isArray(currAction)) {
            let isPromise = false;
            const results = [];

            for (const item of currAction) {
              const r = dispatch(item);
              results.push(r);
              if (r?.then) {
                isPromise = true;
              }
            }

            return isPromise ? Promise.all(results) : results;
          }
          return dispatch(currAction);
        };

        // @ts-ignore
        result = result?.then ? result.then(step) : step();
      }

      return result;
    };
  };
};
