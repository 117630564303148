import * as React from "react";

import Button from "../button/Button";

export function PaginationControl(props: {
  total: number;
  pageSize: number;
  onChangeIndex: (index: number) => void;
}) {
  const { total, pageSize, onChangeIndex } = props;
  return (
    <div className="flex px-2 justify-center">
      {Array.from({
        length: Math.ceil(total / pageSize),
      }).map((_, index) => (
        <Button
          key={index.toString()}
          variant="tertiary"
          onClick={() => onChangeIndex(index * pageSize)}
          size="lg"
        >
          <span className="font-medium">{index + 1}</span>
        </Button>
      ))}
    </div>
  );
}
