import * as React from "react";

import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import { getDynamicDataValueDisplayName } from "@editor/utils/dynamic-data";

import { Badge } from "@replo/design-system/components/badge";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import { evaluateVariable } from "replo-runtime/store/AlchemyVariable";
import tinycolor from "tinycolor2";

type DynamicDataIndicatorType =
  | "color"
  | "gradient"
  | "text"
  | "image"
  | "video"
  | "other";

type DynamicDataValueIndicatorProps = {
  type: DynamicDataIndicatorType;
  templateValue: string | null;
  onClick(): void;
  onRemove?(): void;
  componentId?: string;
};

export const DynamicDataValueIndicator = ({
  type,
  templateValue,
  onClick,
  onRemove,
  componentId,
}: DynamicDataValueIndicatorProps) => {
  const renderBadge = (
    templateValue: string | null,
    type: DynamicDataIndicatorType,
  ) => {
    const isText = type === "text";
    let bgColor = "bg-accent";
    let isValidColor = false;
    if (
      (type === "color" || type === "gradient") &&
      templateValue &&
      componentId
    ) {
      const context = getCurrentComponentContext(componentId, 0);
      const evaluatedVariable = evaluateVariable(templateValue, context);
      if (typeof evaluatedVariable === "string") {
        isValidColor = tinycolor(evaluatedVariable).isValid();
        if (isValidColor) {
          bgColor = evaluatedVariable;
        }
      }
    }

    if (isText || !isValidColor) {
      return <Badge type="dynamicData" />;
    }
    return <Badge type="color" isFilled backgroundColor={bgColor} />;
  };

  const title = templateValue
    ? getDynamicDataValueDisplayName(templateValue)
    : "Select Dynamic Data";

  return (
    <SelectionIndicator
      title={title}
      className="cursor-pointer"
      labelClassname={templateValue ? undefined : "text-slate-400"}
      onClick={onClick}
      startEnhancer={templateValue && renderBadge(templateValue, type)}
      endEnhancer={
        onRemove &&
        templateValue && (
          <FormFieldXButton
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
          />
        )
      }
    />
  );
};
