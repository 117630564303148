import * as React from "react";

import ToggleGroup from "@common/designSystem/ToggleGroup";
import Popover from "@editor/components/common/designSystem/Popover";
import Selectable from "@editor/components/common/designSystem/Selectable";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import ModifierLabel from "@editor/components/editor/page/element-editor/components/extras/ModifierLabel";
import { LengthInputSelector } from "@editor/components/editor/page/element-editor/components/modifiers/LengthInputModifier";
import SolidColorSelector from "@editor/components/editor/page/element-editor/components/SolidColorSelector";
import { DraggingDirections } from "@editor/utils/editor";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import {
  getTextOutlineObject,
  getTextOutlineString,
} from "@editor/utils/textOutline";
import {
  formatTitle,
  getTextShadowString,
  parseTextShadows,
} from "@editor/utils/textShadow";

import { Badge } from "@replo/design-system/components/badge";
import Tooltip from "@replo/design-system/components/tooltip";
import { AiOutlineFontSize } from "react-icons/ai";
import { BsBorderWidth } from "react-icons/bs";
import {
  RiAlignCenter,
  RiAlignLeft,
  RiAlignRight,
  RiDropLine,
  RiItalic,
  RiLineHeight,
  RiStrikethrough,
  RiTextSpacing,
  RiUnderline,
} from "react-icons/ri";
import { FONT_WEIGHT_OPTIONS } from "replo-runtime/shared/utils/font";
import {
  CSS_LENGTH_TYPES,
  CSS_LENGTH_TYPES_WITH_COMPUTED,
} from "replo-runtime/shared/utils/units";
import { v4 as uuidv4 } from "uuid";

const DEFAULT_TEXT_SHADOW = {
  id: uuidv4(),
  offsetX: "0px",
  offsetY: "4px",
  blur: "1px",
  color: "#00000040",
};

const FONT_SIZE_MENU_ITEMS = [
  { label: "Reset", value: "" },
  { label: "12px", value: "12px" },
  { label: "14px", value: "14px" },
  { label: "16px", value: "16px" },
  { label: "18px", value: "18px" },
  { label: "20px", value: "20px" },
  { label: "24px", value: "24px" },
  { label: "32px", value: "32px" },
];

const LETTER_SPACING_MENU_OPTIONS = [
  { label: "Reset", value: "" },
  { label: "1px", value: "1px" },
  { label: "2px", value: "2px" },
  { label: "4px", value: "4px" },
];

const TEXT_ALIGN_OPTIONS = [
  {
    value: "left",
    label: <RiAlignLeft size={16} />,
    tooltipContent: "Align Left",
  },
  {
    value: "center",
    label: <RiAlignCenter size={16} />,
    tooltipContent: "Align Center",
  },
  {
    value: "right",
    label: <RiAlignRight size={16} />,
    tooltipContent: "Align Right",
  },
];

const TEXT_DECORATION_OPTIONS = [
  {
    value: "italic",
    label: <RiItalic size={16} />,
    tooltipContent: "Italic",
  },
  {
    value: "line-through",
    label: <RiStrikethrough size={16} />,
    tooltipContent: "Strikethrough",
  },
  {
    value: "underline",
    label: <RiUnderline size={16} />,
    tooltipContent: "Underline",
  },
];

const TEXT_TRANSFORM_OPTIONS = [
  { value: "capitalize", label: "Ag", tooltipContent: "Capitalize" },
  { value: "uppercase", label: "AG", tooltipContent: "Uppercase" },
  { value: "lowercase", label: "ag", tooltipContent: "Lowercase" },
];

type BaseControlProps = {
  value?: string | null;
  onChange?: (value: string | undefined) => void;
  disableWrapper?: boolean;
  openedFrom?: "leftBar" | "rightBar";
};

const TOGGLE_GROUP_STYLES = {
  height: "26px",
  width: "100%",
} as const;

export const SavedFontWeightControl: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
}> = ({ value, onChange }) => {
  return (
    <Selectable
      className="w-full"
      placeholder="Weight"
      options={FONT_WEIGHT_OPTIONS.map((option) => ({
        label: option.label,
        value: option.value.toString(),
      }))}
      value={value?.toString()}
      onSelect={(value: string | null) => onChange?.(value ?? "")}
    />
  );
};

export const SavedFontSizeControl: React.FC<BaseControlProps> = ({
  value,
  onChange,
}) => (
  <LengthInputSelector
    className="w-full"
    label={<ModifierLabel label="Size" />}
    field="style.fontSize"
    value={value ?? ""}
    onChange={(value) => onChange?.(value || "")}
    placeholder="Font Size"
    metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
    minValues={{ px: 0 }}
    allowsNegativeValue={false}
    anchorValue="16px"
    resetValue=""
    menuOptions={FONT_SIZE_MENU_ITEMS}
    dragTrigger="label"
    startEnhancer={() => (
      <Tooltip inheritCursor content="Font Size" triggerAsChild>
        <span tabIndex={0}>
          <AiOutlineFontSize />
        </span>
      </Tooltip>
    )}
  />
);

export const SavedLineHeightControl: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
  disableWrapper?: boolean;
}> = ({ value, onChange }) => {
  const lineHeightDefaultValue =
    styleAttributeToEditorData.lineHeight.defaultValue;

  return (
    <LengthInputSelector
      label={<ModifierLabel label="Line" />}
      field="style.lineHeight"
      value={value}
      onChange={(newValue) => {
        onChange?.(newValue === "auto" ? lineHeightDefaultValue : newValue);
      }}
      placeholder="Line Height"
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      minValues={{ px: 0 }}
      allowsNegativeValue={false}
      anchorValue="16px"
      resetValue={lineHeightDefaultValue}
      startEnhancer={() => (
        <Tooltip inheritCursor content="Line Height" triggerAsChild>
          <span tabIndex={0}>
            <RiLineHeight />
          </span>
        </Tooltip>
      )}
    />
  );
};

export const SavedLetterSpacingControl: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
  disableWrapper?: boolean;
}> = ({ value, onChange }) => {
  const letterSpacingDefaultValue =
    styleAttributeToEditorData.letterSpacing.defaultValue;

  return (
    <LengthInputSelector
      label={<ModifierLabel label="Spacing" />}
      field="style.letterSpacing"
      value={value}
      onChange={(newValue) => {
        onChange?.(newValue);
      }}
      placeholder="Letter Spacing"
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      resetValue={letterSpacingDefaultValue}
      anchorValue="1px"
      menuOptions={LETTER_SPACING_MENU_OPTIONS}
      startEnhancer={() => (
        <Tooltip inheritCursor content="Letter Spacing" triggerAsChild>
          <span tabIndex={0}>
            <RiTextSpacing />
          </span>
        </Tooltip>
      )}
    />
  );
};

export const SavedTextAlignControl: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
  disableWrapper?: boolean;
}> = ({ value, onChange }) => {
  return (
    <ToggleGroup
      type="single"
      value={value ?? ""}
      options={TEXT_ALIGN_OPTIONS}
      style={TOGGLE_GROUP_STYLES}
      onChange={(value) => value && onChange?.(value)}
    />
  );
};

export const SavedTextDecorationControl: React.FC<{
  value?: string | null;
  onChange?: (value: string | undefined) => void;
}> = ({ value, onChange }) => {
  return (
    <ToggleGroup
      type="single"
      allowsDeselect
      value={value ?? ""}
      style={TOGGLE_GROUP_STYLES}
      options={TEXT_DECORATION_OPTIONS}
      onChange={(value) => onChange?.(value ?? undefined)}
    />
  );
};

export const SavedTextColorControl: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
  disableWrapper?: boolean;
  openedFrom?: "leftBar" | "rightBar";
}> = ({ value, onChange, openedFrom }) => {
  return (
    <SolidColorSelector
      popoverTitle="Text Color"
      value={value ?? ""}
      onChange={(value) => value && onChange?.(value)}
      defaultValue="#000000"
      popoverSide={openedFrom === "leftBar" ? "right" : "left"}
      popoverSideOffset={openedFrom === "leftBar" ? 150 : 87}
    />
  );
};

export const SavedTextTransformControl: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
  disableWrapper?: boolean;
}> = ({ value, onChange }) => {
  return (
    <ToggleGroup
      type="single"
      style={TOGGLE_GROUP_STYLES}
      value={value ?? "none"}
      options={TEXT_TRANSFORM_OPTIONS}
      allowsDeselect
      onChange={(value) => {
        if (value) {
          onChange?.(value);
        } else {
          onChange?.("none");
        }
      }}
    />
  );
};

export const SavedTextShadowControl: React.FC<BaseControlProps> = ({
  value,
  onChange,
  openedFrom,
}) => {
  const [openShadowPopover, setOpenShadowPopover] =
    React.useState<boolean>(false);

  const textShadow = value ? parseTextShadows([value])[0] : null;

  const handleAddShadow = () => {
    const newShadows = DEFAULT_TEXT_SHADOW;
    onChange?.(getTextShadowString([newShadows]));
    setOpenShadowPopover(true);
  };

  const handleRemoveShadow = () => {
    onChange?.(undefined);
  };

  const handleShadowChange = (updates: Partial<typeof DEFAULT_TEXT_SHADOW>) => {
    if (!textShadow) {
      return;
    }
    onChange?.(getTextShadowString([{ ...textShadow, ...updates }]));
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="grid grid-cols-[74px,auto] w-full items-center">
        <ModifierLabel label="Shadow" />
        <div className="flex flex-col gap-1">
          {textShadow ? (
            <div className="py-1">
              <SelectionIndicator
                className="max-w-40"
                title={formatTitle(textShadow)}
                onClick={() => setOpenShadowPopover(true)}
                startEnhancer={
                  <Badge
                    type="color"
                    isFilled
                    backgroundColor={textShadow.color ?? "text-subtle"}
                  />
                }
                endEnhancer={
                  <FormFieldXButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveShadow();
                    }}
                  />
                }
              />
              {openShadowPopover && (
                <TextShadowPopover
                  shadow={textShadow}
                  onChange={handleShadowChange}
                  onClose={() => setOpenShadowPopover(false)}
                  openedFrom={openedFrom}
                />
              )}
            </div>
          ) : (
            <ToggleGroup
              style={TOGGLE_GROUP_STYLES}
              type="single"
              options={[
                {
                  value: "shadow",
                  label: <RiDropLine size={16} />,
                  tooltipContent: "Add Shadow",
                },
              ]}
              value=""
              onChange={() => handleAddShadow()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const TextShadowPopover: React.FC<{
  shadow: typeof DEFAULT_TEXT_SHADOW;
  onChange: (updates: Partial<typeof DEFAULT_TEXT_SHADOW>) => void;
  onClose: () => void;
  openedFrom?: "leftBar" | "rightBar";
}> = ({ shadow, onChange, onClose, openedFrom }) => {
  return (
    <Popover isOpen onOpenChange={(isOpen) => !isOpen && onClose()}>
      <Popover.Content
        title="Text Shadow"
        onRequestClose={onClose}
        shouldPreventDefaultOnInteractOutside
        side={openedFrom === "leftBar" ? "right" : "left"}
        sideOffset={openedFrom === "leftBar" ? 9 : 84}
        onInteractOutside={onClose}
      >
        <div className="flex flex-col gap-2">
          <LengthInputSelector
            label={<ModifierLabel label="X Axis" />}
            metrics={CSS_LENGTH_TYPES}
            field="offsetX"
            resetValue="0px"
            anchorValue="0px"
            placeholder="0px"
            value={shadow.offsetX}
            onChange={(value) => onChange({ offsetX: value })}
            draggingDirection={DraggingDirections.Negative}
            autofocus
            dragTrigger="label"
          />
          <LengthInputSelector
            label={<ModifierLabel label="Y Axis" />}
            metrics={CSS_LENGTH_TYPES}
            field="offsetY"
            resetValue="0px"
            anchorValue="0px"
            placeholder="0px"
            value={shadow.offsetY}
            onChange={(value) => onChange({ offsetY: value })}
            draggingDirection={DraggingDirections.Negative}
            dragTrigger="label"
          />
          <LengthInputSelector
            label={<ModifierLabel label="Blur" />}
            metrics={CSS_LENGTH_TYPES}
            minDragValues={{ px: 0 }}
            minValues={{ px: 0 }}
            field="blur"
            resetValue="0px"
            anchorValue="0px"
            placeholder="0px"
            value={shadow.blur}
            onChange={(value) => onChange({ blur: value })}
            dragTrigger="label"
          />
          <div className="flex items-center">
            <ModifierLabel label="Color" />
            <SolidColorSelector
              popoverTitle="Color"
              value={shadow.color}
              onChange={(value) => onChange({ color: value ?? undefined })}
              defaultValue="#00000040"
              popoverSide={openedFrom === "leftBar" ? "right" : "left"}
              popoverSideOffset={openedFrom === "leftBar" ? 145 : 92}
            />
          </div>
        </div>
      </Popover.Content>
      <Popover.Anchor className="relative top-0 left-0" />
    </Popover>
  );
};

export const SavedTextOutlineControl: React.FC<BaseControlProps> = ({
  value,
  onChange,
}) => {
  const textOutline = value ? getTextOutlineObject(value) : null;

  const handleInputChange = (value: string, inputType: "width" | "color") => {
    let textOutlineString = null;

    if (value && value !== "0px") {
      const newTextOutline = {
        width: textOutline?.width || "1px",
        color: textOutline?.color || "#000000",
        [inputType]: value,
      };
      textOutlineString = getTextOutlineString(newTextOutline);
    }
    onChange?.(textOutlineString ?? undefined);
  };

  return (
    <>
      <LengthInputSelector.Root
        metrics={CSS_LENGTH_TYPES}
        className="col-span-1"
        minDragValues={{ px: 0 }}
        minValues={{ px: 0 }}
        maxValues={{ px: 30 }}
        maxDragValues={{ px: 30 }}
        field="width"
        resetValue="0px"
        anchorValue="0px"
        value={textOutline?.width ?? null}
        onChange={(value: string) => handleInputChange(value, "width")}
        dragTrigger="label"
      >
        {/* NOTE (Fran 2024-10-16): 74px is the fixed width of the label. */}
        <div className="grid grid-cols-[74px,auto] w-full gap-y-2 items-center">
          <LengthInputSelector.DraggableArea>
            <ModifierLabel label="Outline" />
          </LengthInputSelector.DraggableArea>
          <LengthInputSelector.Input
            placeholder="0px"
            startEnhancer={() => <BsBorderWidth />}
          />
          <div className="col-start-2">
            <SolidColorSelector
              popoverTitle="Color"
              value={textOutline?.color ?? null}
              onChange={(value: string) => handleInputChange(value, "color")}
              // NOTE (Fran 2024-11-28): 150px is the width of the label plus the spacing between the input and the side of the popover
              // minus the width of the badge and the spacing between the badge and the left side of the input.
              popoverSideOffset={150}
              popoverSide="right"
            />
          </div>
        </div>
      </LengthInputSelector.Root>
    </>
  );
};
