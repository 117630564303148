/**
 * Standardized group names we use for analytics (e.g. posthog). Important to
 * keep these in a standardized place, because passing the exact same string is
 * important across the backend and frontend to make sure that .group() calls in
 * the frontend line up with the group names we pass in the backend.
 */
export const ANALYTICS_GROUP_NAMES = {
  project: "project",
  /**
   * Holdover from when we used to use the domain on the user email address as a way to do orgs.
   *
   * @deprecated
   * @author Ben 2024-08-16
   */
  domain: "organization",
  workspace: "workspace",
} as const;
