import * as React from "react";

import { selectDraftComponent } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import StandardCustomPropControl from "./StandardCustomPropControl";
import { useCustomPropDefinitionsWithValues } from "./utils";

export const StandardAccessibilityConfigMenu: React.FC<{
  accessibilityPropIds: string[];
}> = ({ accessibilityPropIds }) => {
  const customProps = useCustomPropDefinitionsWithValues();
  const draftComponent = useEditorSelector(selectDraftComponent);
  if (!draftComponent) {
    return null;
  }

  return (
    <div className="relative flex flex-col gap-4">
      {customProps.map(
        (customProp) =>
          accessibilityPropIds.includes(customProp.definition.id) && (
            <div
              className="border-b border-slate-200 pb-3 last:border-b-0 last:pb-0"
              key={customProp.definition.id}
            >
              <StandardCustomPropControl
                draftComponent={draftComponent}
                customProp={customProp}
              />
            </div>
          ),
      )}
    </div>
  );
};
