import { selectComponentMapping } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

export const useComponentById = (id: string | null) => {
  const mapping = useEditorSelector(selectComponentMapping);
  if (!id) {
    return null;
  }

  return mapping[id]?.component;
};
