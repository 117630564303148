import type { ComponentActionType } from "@editor/types/component-action-type";
import type {
  CoreState,
  ElementsState,
  HistoryStateType,
} from "@editor/types/core-state";
import type { GetAttributeFunction } from "@editor/types/get-attribute-function";
import type { ComponentMapping } from "replo-runtime/shared/Component";
import type { StoreProduct } from "replo-runtime/shared/types";
import type { Component } from "schemas/component";
import type { ReploElement } from "schemas/generated/element";

import { findAncestorComponentOrSelf, isModal } from "@editor/utils/component";
import { objectId } from "@editor/utils/objectId";
import { applyComponentAction } from "@reducers/utils/component-actions";

import { enablePatches, produceWithPatches } from "immer";
import isEqual from "lodash-es/isEqual";
import { forEachComponentAndDescendants } from "replo-runtime/shared/utils/component";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";

enablePatches();

/**
 * Takes an action and applies it to a component.
 *
 * Does not handle creating a component
 */
export const getNextElements = ({
  action,
  state,
}: {
  action: { payload: ComponentActionType };
  state: CoreState;
}) => {
  const op = action.payload;
  const elements = state.elements.mapping;
  let updatedComponentIds: string[] = [];

  const [nextState, patches, inversePatches] = produceWithPatches(
    elements,
    (elements) => {
      updatedComponentIds = applyComponentAction({
        action: { ...op, analyticsExtras: action.payload.analyticsExtras },
        elements,
        state,
      });
    },
  );
  return {
    elements: nextState,
    patches,
    inversePatches,
    updatedComponentIds,
  };
};

export const getHistoryAfterPatch = ({
  action,
  history,
  patches,
  inversePatches,
  elementId,
  componentIds,
}: {
  action: { type: any };
  history: HistoryStateType;
  patches: any;
  inversePatches: any;
  elementId: string;
  componentIds: string[];
}) => {
  const next = history.index + 1;
  history.operations[next] = {
    type: action.type,
    redo: patches,
    undo: inversePatches,
    elementId,
    componentIds,
  };

  /* Delete one version in advance so we cannot redo */
  delete history.operations[next + 1];
  /* Delete anything that's more than the number of supported versions */
  delete history.operations[history.index - history.maxSize];

  return {
    ...history,
    operations: history.operations,
    index: next,
  };
};

export const getAncestorAlchemyModal = (
  draftElement: ReploElement | null,
  draftComponentId: Component["id"] | null,
): Component | null => {
  if (draftElement && draftComponentId) {
    const modalAncestor = findAncestorComponentOrSelf(
      draftElement,
      draftComponentId,
      (component) => isModal(component.type),
    );
    return modalAncestor;
  }
  return null;
};

export type PaintTypeResult =
  | { paintType: "none" }
  | { paintType: "all"; forceRemount: boolean }
  | { paintType: "components" };

export type EditorRepaintDependencies = {
  elementMapping: ElementsState["mapping"];
  draftElementId: string | null;
};

export const editorNeedsRepaint = (
  prevDependencies: EditorRepaintDependencies | null,
  currentDependencies: EditorRepaintDependencies,
): PaintTypeResult => {
  if (
    !prevDependencies ||
    !isEqual(
      prevDependencies.draftElementId,
      currentDependencies.draftElementId,
    )
  ) {
    return {
      paintType: "all",
      forceRemount: true,
    };
  }

  const previousElement = getFromRecordOrNull(
    prevDependencies.elementMapping,
    prevDependencies.draftElementId,
  );
  const currentElement = getFromRecordOrNull(
    currentDependencies.elementMapping,
    currentDependencies.draftElementId,
  );

  // NOTE (Martin, 2024-09-28): We first elements list separatedly from each
  // element component tree data, so we need to take that into account.
  const isElementComponentLoaded =
    previousElement?.id === currentElement?.id &&
    !previousElement?.component &&
    currentElement?.component;

  // NOTE (Martin, 2024-09-28): If the user has made an update to the element
  // but we've rejected the update on the backend and returned the most up to
  // date version, we want to repaint because the user needs to see the latest
  // version. In this case, the previous element component tree will be a
  // different object in-memory than the current one (since in core-reducer,
  // we reset the element mapping to have the new element from the server).
  const isDifferentElementComponentTree =
    previousElement?.component &&
    currentElement?.component &&
    objectId(previousElement.component) !== objectId(currentElement.component);

  if (isElementComponentLoaded || isDifferentElementComponentTree) {
    return { paintType: "all", forceRemount: false };
  }

  return {
    paintType: "none",
  };
};

export function getComponentMappingFromElement(
  draftElement: ReploElement | null,
) {
  if (!draftElement) {
    return {};
  }
  const mapping: ComponentMapping = {};
  forEachComponentAndDescendants(
    draftElement.component,
    (component, parentComponent) => {
      mapping[component.id] = {
        component,
        parentComponent,
      };
    },
  );
  return mapping;
}

export function findApplicableProduct(
  products: StoreProduct[],
  productTemplateSlug: string,
) {
  let applicableProduct: StoreProduct | null = null;
  const templateSuffix = `alchemy.${productTemplateSlug || "unknown"}`;
  if (products.length > 0) {
    applicableProduct =
      products.find((p: any) => p.templateSuffix === templateSuffix) ||
      products[0]!;
  }
  if (!applicableProduct) {
    applicableProduct = products.length > 0 ? products[0]! : null;
  }
  return applicableProduct;
}

export function getFieldMapping<Key extends string, T extends Record<Key, any>>(
  iterable: Array<T>,
  field: Key,
): Record<T[Key], T> {
  const mapping: Record<string, T> = {};
  iterable.forEach((item) => {
    mapping[String(item[field])] = item;
  });
  return mapping;
}

export const orderOfCategories: Record<string, number> = {
  Basic: 1,
  Layout: 2,
  Product: 3,
  Video: 4,
  Form: 5,
  Map: 6,
  Icon: 7,
  Component: 8,
  "Custom Code": 9,
  CMS: 10,
};

export type PositionAttribute = "top" | "bottom" | "left" | "right";

export function getDefaultPositionAttribute(
  positions: PositionAttribute[],
  draftComponent: Component,
  getAttribute: GetAttributeFunction,
): PositionAttribute | "center" {
  for (const position of positions) {
    const { value } = getAttribute(draftComponent, `style.${position}`);

    if (value === "50%") {
      return "center";
    }
    // Note (Noah, 2021-08-11): Unfortunately it seems as if some components have
    // acquired values of NaNpx for their positioning values. We should probably
    // write a migration to remove these but for now we just handle them the same
    // as null
    if (value !== null && value !== "auto" && value !== "NaNpx") {
      return position;
    }
  }
  return positions[0]!;
}

export const hardcodedActions = [
  "redirect",
  "addProductVariantToCart",
  "clearCart",
  "redirectToProductPage",
  "applyDiscountCode",
  "executeJavascript",
  "scrollToUrlHashmark",
] as const;

/**
 * Note (Chance 2023-07-11) This function should only return an updated element
 * state reference if the mapping or versionMapping have changed, based on the
 * new element and version. This will prevent over-calling effects that depend
 * on the elements state.
 */
export function mergeElementsStateWithNewElement(
  elements: ElementsState,
  element: ReploElement & { version: number },
): ElementsState {
  const mapping = Object.is(elements.mapping[element.id], element)
    ? elements.mapping
    : { ...elements.mapping, [element.id]: element };
  const versionMapping =
    elements.versionMapping[element.id] === element.version
      ? elements.versionMapping
      : { ...elements.versionMapping, [element.id]: element.version };

  if (
    Object.is(elements.mapping, mapping) &&
    Object.is(elements.versionMapping, versionMapping)
  ) {
    return elements;
  }

  return { ...elements, mapping, versionMapping };
}

/**
 * Note (Chance 2023-07-11) This function should only return an updated elements
 * state reference if the draft element exists and needs to be updated with a
 * new component reference. This will prevent over-calling effects that depend
 * on the elements state.
 *
 * Note (Evan, 2024-07-18): We parameterize the properties of the elements state actually
 * used in the calculation to avoid triggering recalculation when irrelevant properties
 * (particularly in the streamingUpdate) change. See selectElementWithRevisionState in
 * core-reducer.ts for more details.
 */
export function updateElementMappingWithRevision({
  draftElementId,
  elementRevisions,
  selectedRevisionId,
  elementMapping,
  streamingDraftElementComponent,
}: {
  draftElementId: string | null;
  elementRevisions: ElementsState["elementRevisions"];
  selectedRevisionId: string | null;
  elementMapping: ElementsState["mapping"];
  streamingDraftElementComponent: Component | undefined;
}): ElementsState["mapping"] {
  if (!draftElementId) {
    return elementMapping;
  }
  const draftElement = elementMapping[draftElementId];
  if (!draftElement) {
    return elementMapping;
  }

  const updatedDraftElementComponentFromRevision = elementRevisions[
    draftElementId
  ]?.find((revision) => revision.id === selectedRevisionId)?.component;

  const updatedDraftElementComponentFromStreaming =
    draftElement.component.id === streamingDraftElementComponent?.id &&
    streamingDraftElementComponent;

  const updatedDraftElementComponent =
    updatedDraftElementComponentFromRevision ??
    updatedDraftElementComponentFromStreaming;

  if (
    !updatedDraftElementComponent ||
    Object.is(updatedDraftElementComponent, draftElement.component)
  ) {
    return elementMapping;
  }

  return {
    ...elementMapping,
    [draftElementId]: {
      ...draftElement,
      component: updatedDraftElementComponent as Component,
    },
  };
}

export function getElementWithRevisionState(elements: ElementsState) {
  if (!elements.selectedRevisionId && !elements.streamingUpdate) {
    return elements;
  }

  const {
    draftElementId,
    elementRevisions,
    selectedRevisionId,
    mapping: elementMapping,
    streamingUpdate,
  } = elements;

  const newMapping = updateElementMappingWithRevision({
    draftElementId,
    elementRevisions,
    selectedRevisionId,
    elementMapping,
    streamingDraftElementComponent: streamingUpdate?.draftElementComponent,
  });
  return {
    ...elements,
    mapping: newMapping,
  };
}
