import type {
  ComparisonTimeFrame,
  RelativeTimeFrame,
} from "@/features/analytics/time";
import type { SetURLSearchParams } from "react-router-dom";
import type { AnalyticsReadQuery } from "schemas/generated/analyticsRead";

import { useCallback } from "react";

import { DEFAULT_FILTERS } from "@/features/analytics/constants";
import {
  DEFAULT_COMPARE_RANGE_TIME_FRAME,
  DEFAULT_MAIN_RELATIVE_TIME_FRAME,
} from "@/features/analytics/time";
import {
  compressObjectToLzString,
  decompressObjectFromLzString,
} from "replo-utils/lib/lzString";

type UrlHosts = AnalyticsReadQuery["urlHosts"];

export type selectedTimePeriod =
  | {
      type: "relative";
      value: RelativeTimeFrame | ComparisonTimeFrame;
      interval?: AnalyticsReadQuery["ranges"]["mainRange"]["interval"];
    }
  | {
      type: "custom";
      value: {
        from: AnalyticsReadQuery["ranges"]["mainRange"]["startDatetime"];
        to: AnalyticsReadQuery["ranges"]["mainRange"]["endDatetime"];
      };
      interval?: AnalyticsReadQuery["ranges"]["mainRange"]["interval"];
    };

export type AnalyticsUrlParams = {
  selectedTimePeriod: selectedTimePeriod;
  selectedComparePeriod: selectedTimePeriod;
  filters: AnalyticsReadQuery["filters"];
  urlHosts: UrlHosts;
};

export const generateAnalyticsUrlParams = ({
  urlHosts,
  interval,
  overrides,
}: {
  urlHosts: UrlHosts;
  interval?: AnalyticsReadQuery["ranges"]["mainRange"]["interval"];
  overrides?: Partial<AnalyticsUrlParams>;
}): AnalyticsUrlParams => {
  const defaultParams: AnalyticsUrlParams = {
    selectedTimePeriod: {
      type: "relative",
      value: DEFAULT_MAIN_RELATIVE_TIME_FRAME,
      interval,
    },
    selectedComparePeriod: {
      type: "relative",
      value: DEFAULT_COMPARE_RANGE_TIME_FRAME,
      interval,
    },
    filters: DEFAULT_FILTERS,
    urlHosts,
  };

  return { ...defaultParams, ...overrides };
};

export const useAnalyticsUrlParams = (
  urlHosts: UrlHosts,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
) => {
  const getCompressedQuery = useCallback(() => {
    return searchParams.get("query");
  }, [searchParams]);

  const setCompressedQuery = useCallback(
    (compressedQuery: string) => {
      if (getCompressedQuery() !== compressedQuery) {
        setSearchParams(
          (prev) => {
            prev.set("query", compressedQuery);
            return prev;
          },
          { replace: true },
        );
      }
    },
    [getCompressedQuery, setSearchParams],
  );

  const getDecompressedParams = (): AnalyticsUrlParams => {
    const compressedQuery = getCompressedQuery();
    if (!compressedQuery) {
      return generateAnalyticsUrlParams({ urlHosts });
    }
    return decompressObjectFromLzString<AnalyticsUrlParams>(compressedQuery);
  };

  const getAllParams = (): AnalyticsUrlParams => {
    return getDecompressedParams();
  };

  const setDecompressedParams = (params: AnalyticsUrlParams) => {
    const compressedQuery = compressObjectToLzString(params);
    setCompressedQuery(compressedQuery);
  };

  const getParamValue = <K extends keyof AnalyticsUrlParams>(
    key: K,
  ): AnalyticsUrlParams[K] => {
    const params = getDecompressedParams();
    return params[key];
  };

  const setParamValue = <K extends keyof AnalyticsUrlParams>(
    key: K,
    value: AnalyticsUrlParams[K],
  ) => {
    const params = getDecompressedParams();
    params[key] = value;
    setDecompressedParams(params);
  };

  return {
    getParamValue,
    setParamValue,
    getAllParams,
    setDecompressedParams,
  };
};
