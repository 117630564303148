import * as React from "react";

import Button from "@replo/design-system/components/button";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

export const ComponentTemplatesEmptyState: React.FC<{
  emptyStateLabel: string | React.ReactNode;
  resetButton?: React.ReactNode;
  startEnhancer?: React.ReactNode;
}> = ({ emptyStateLabel, startEnhancer, resetButton }) => {
  return (
    <div className="my-20 flex-shrink-0 flex-1">
      {startEnhancer}
      <p
        className={twMerge(
          classNames("pt-4 pb-4 text-center font-medium", {
            "pt-8": Boolean(startEnhancer),
          }),
        )}
      >
        {emptyStateLabel}
      </p>
      <div className="mx-auto flex justify-center gap-2">
        <Button
          variant="secondary"
          className="py-3.5 px-5 text-blue-600 font-medium bg-stone-50"
          isRounded
          href="https://replo.typeform.com/to/vIQu8b4B"
          target="_blank"
        >
          Request Templates
        </Button>
        {resetButton}
      </div>
    </div>
  );
};
