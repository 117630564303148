import type { CustomPropsRecord } from "schemas/component";
import type { ComponentConfig } from "..";
import type { StyleElements } from "../../../shared/utils/renderComponents";

import { convertToLegacyProps } from "../../../shared/utils/renderComponents";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _siteId: {
      type: "string",
      name: "Site ID",
      description:
        "Site ID from Buy With Prime. Find this under 'data-site-id' in the embed code.",
      defaultValue: null,
    },
    _widgetId: {
      type: "string",
      name: "Widget ID",
      description:
        "Widget ID from Buy With Prime. Find this under 'data-widget-id' in the embed code.",
      defaultValue: null,
    },
  };
}

export const styleElements = {
  root: {},
} as const satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: false,
    customProps: convertToLegacyProps(getConfigurableProps()),
    acceptsArbitraryChildren: () => false,
    ancestorAllow: {
      ancestorTypes: ["product"],
      message: "Buy With Prime Widget may only be placed inside a Product box.",
    },
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
