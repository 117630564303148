import { z } from "zod";

export const urlFormSchema = z.object({
  url: z.literal("").or(
    z
      .string()
      .transform((val) => {
        if (val.startsWith("http://") || val.startsWith("https://")) {
          return val;
        }
        return `https://${val}`;
      })
      .refine(
        (val) => {
          try {
            const url = new URL(val);
            // NOTE (Gabe 2024-09-26): new URL() accepts hostname without a
            // period (just 'replo') which is weird to me.
            if (!url.host.includes(".")) {
              return false;
            } else {
              return true;
            }
          } catch {
            return false;
          }
        },
        { message: "Invalid URL" },
      ),
  ),
});

export type UrlFormValues = z.infer<typeof urlFormSchema>;
