import type { SvgProps } from "replo-utils/react/components";

import * as React from "react";

import { Svg } from "replo-utils/react/components";

export const SvgComponenticonContainerColumn = (props: SvgProps) => (
  <Svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" {...props}>
    <rect width={5} height={12} fill="currentColor" rx={1} />
    <rect width={5} height={12} x={7} fill="currentColor" rx={1} />
  </Svg>
);
export default SvgComponenticonContainerColumn;
