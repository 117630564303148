import * as React from "react";

type UseOnScreenOptions = {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number | number[];
};

/**
 * Returns a ref which can be attached to a DOM element. When attached, the
 * provided callback will be called when the element becomes visible in the
 * viewport.
 *
 * The callback will also be called immediately upon the ref mounting, if the
 * element is already visible.
 */
export function useOnScreen(
  callback: () => void,
  options: UseOnScreenOptions = {},
) {
  const ref = React.useRef<HTMLElement | null>(null);
  const observer = React.useRef<IntersectionObserver | null>(null);

  const setRef = React.useCallback(
    (node: HTMLElement | null) => {
      if (observer.current) {
        observer.current.disconnect();
      }

      if (node) {
        observer.current = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                callback();
                if (observer.current) {
                  observer.current.disconnect();
                }
              }
            });
          },
          {
            root: options.root || null,
            rootMargin: options.rootMargin || "0px",
            threshold: options.threshold || 0.1,
          },
        );
        observer.current.observe(node);
      }

      ref.current = node;
    },
    [callback, options.root, options.rootMargin, options.threshold],
  );

  React.useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return setRef;
}
