import type { Component } from "schemas/component";
import type {
  RenderComponentProps,
  ReploShopifyVariant,
} from "../../../shared/types";
import type { DefaultActionsMap } from "../ReploComponent";

import * as React from "react";

import {
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { mergeContext } from "../../../shared/utils/context";
import { withLiquidAlternate } from "../../../shared/utils/withLiquidAlternate";
import { variantIsOnSale } from "../../utils/variant";
import { ReploComponent } from "../ReploComponent";
import ReploLiquidChunk from "../ReploLiquid/ReploLiquidChunk";

const VariantComponent = ({
  component,
  template,
  extraAttributes,
  variant,
  context,
  index,
  isSelectedOverride,
}: Pick<RenderComponentProps, "component" | "extraAttributes" | "context"> & {
  template: Component;
  variant: ReploShopifyVariant;
  index: number;
  isSelectedOverride?: boolean;
}) => {
  const childComponent = component.children?.[index] ?? template;

  const thisVariantIsSelected =
    isSelectedOverride ??
    variant.id === context.state.product?.selectedVariant?.id;
  const nextContext = React.useMemo(() => {
    return mergeContext(context, {
      attributes: {
        _currentVariant: variant,
      },
      attributeKeyToComponentId: {
        _currentVariant: component.id,
      },
      state: {
        variantSelect: {
          isSelected: thisVariantIsSelected,
          isVariantOnSale: variantIsOnSale(variant),
          isVariantUnavailable: !variant.available,
        },
      },
    });
  }, [component.id, context, variant, thisVariantIsSelected]);

  const defaultActions = React.useMemo<DefaultActionsMap>(() => {
    return {
      actions: {
        onClick: [
          {
            id: `alchemy:selectVariant`,
            type: "setActiveVariant",
            value: { variantId: variant.id },
          },
        ],
      },
      placement: "after",
    };
  }, [variant.id]);

  if (!childComponent) {
    return null;
  }

  const repeatedIndex = template.id === childComponent.id ? index : 0;

  return (
    <ReploComponent
      key={variant.id}
      component={childComponent}
      defaultActions={defaultActions}
      extraAttributes={{
        ...extraAttributes,
        role: "option",
        "aria-selected": thisVariantIsSelected,
      }}
      context={nextContext}
      repeatedIndexPath={`${context.repeatedIndexPath}.${repeatedIndex}`}
    />
  );
};

export const VariantSelect: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
  context,
  extraAttributes,
}) => {
  const template = component.children?.length && component.children[0];
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  if (!template) {
    return null;
  }

  const variants: ReploShopifyVariant[] = context.attributes?._variants ?? [];
  const selectedVariants = component.props._productVariants;
  const filteredVariants = variants.filter((variant) => {
    return !selectedVariants || selectedVariants.includes(variant.id);
  });
  if (!isEditorApp && filteredVariants.length === 0) {
    return null;
  }

  return (
    filteredVariants.length > 0 && (
      <div {...componentAttributes} role="listbox" data-replo-variant-select>
        {filteredVariants.map((variant, index) => (
          <VariantComponent
            key={variant.id}
            component={component}
            template={template}
            extraAttributes={extraAttributes}
            variant={variant}
            context={context}
            index={index}
          />
        ))}
      </div>
    )
  );
};

const VariantSelectDropdownLiquid: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
  context,
  extraAttributes,
}) => {
  const template = component.children?.length && component.children[0];
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  if (!template) {
    return null;
  }

  const variants: ReploShopifyVariant[] = context.attributes?._variants ?? [];
  const selectedVariants = component.props._productVariants;
  const filteredVariants = variants.filter((variant) => {
    return !selectedVariants || selectedVariants.includes(variant.id);
  });
  if (!isEditorApp && filteredVariants.length === 0) {
    return null;
  }

  const selectedVariantsString = selectedVariants?.join(",") ?? "";

  const children = component.children ?? [template];

  return (
    <ReploLiquidChunk>
      {`{% if product.variants[0] %}`}
      {`{% capture selectedVariantIds %}${selectedVariantsString}{% endcapture %}`}
      <div {...componentAttributes} role="listbox" data-replo-variant-select>
        {`{% for reploRepeatedVariant in product.variants %}`}
        {`{% if selectedVariantIds == blank or selectedVariantIds contains reploRepeatedVariant.id %}`}
        {/* NOTE (Matt 2024-03-27): this case statement allows us to render more than the first child pre-hydration while still using liquid variants */}
        {`{% case forloop.index0 %}`}
        {children.map((_, childIndex) => {
          return (
            <>
              {`{% when ${childIndex} %}`}
              {`{% if reploSelectedVariant == blank or reploRepeatedVariant == reploSelectedVariant %}`}
              <VariantComponent
                // biome-ignore lint/correctness/useJsxKeyInIterable: no keys
                component={component}
                template={template}
                extraAttributes={extraAttributes}
                context={context}
                variant={variants[0]!}
                index={childIndex}
                isSelectedOverride
              />
              {`{% else %}`}
              <VariantComponent
                // biome-ignore lint/correctness/useJsxKeyInIterable: no keys
                component={component}
                template={template}
                extraAttributes={extraAttributes}
                context={context}
                variant={variants[0]!}
                index={childIndex}
                isSelectedOverride={false}
              />
              {`{% endif %}`}
            </>
          );
        })}
        {`{% else %}`}
        {`{% if reploSelectedVariant == blank or reploRepeatedVariant == reploSelectedVariant %}`}
        <VariantComponent
          component={component}
          template={template}
          extraAttributes={extraAttributes}
          context={context}
          variant={variants[0]!}
          index={0}
          isSelectedOverride
        />
        {`{% else %}`}
        <VariantComponent
          component={component}
          template={template}
          extraAttributes={extraAttributes}
          context={context}
          variant={variants[0]!}
          index={0}
          isSelectedOverride={false}
        />
        {`{% endif %}`}
        {`{% endcase %}`}
        {`{% endif %}`}
        {`{% endfor %}`}
      </div>
      {`{% endif %}`}
    </ReploLiquidChunk>
  );
};

export default withLiquidAlternate(VariantSelect, VariantSelectDropdownLiquid);
