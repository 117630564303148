import type { ShopifyIntegrationCapacityReason } from "schemas/generated/billing";

import * as React from "react";

import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { shopifyIntegrationCapacityReasons } from "schemas/billing";

export function isShopifyIntegrationCapacityReason(
  errMessage: string,
): errMessage is ShopifyIntegrationCapacityReason {
  return (shopifyIntegrationCapacityReasons as readonly string[]).includes(
    errMessage,
  );
}

export function mapShopifyIntegrationCapacityReasonToError({
  reason,
  redirectToBilling,
}: {
  reason: ShopifyIntegrationCapacityReason;
  redirectToBilling: () => void;
}): React.ReactNode {
  return exhaustiveSwitch({ type: reason })({
    capacityExceeded: () => {
      return (
        <span>
          {
            "This workspace has reached its limit of Shopify integrations. To add another, "
          }
          <span
            className="underline cursor-pointer"
            onClick={redirectToBilling}
          >
            please upgrade
          </span>
          .
        </span>
      );
    },
    shopifyWorkspace: () => {
      return (
        <span>
          This workspace has reached its limit of Shopify integrations. Contact
          support to learn more about updating your plan.
        </span>
      );
    },
  });
}
