import * as React from "react";

export function createContext<T>(
  displayName: string,
  initialValue: T,
): React.Context<T>;
export function createContext<T>(
  displayName: string,
): React.Context<T | undefined>;
export function createContext<T>(displayName: string, initialValue?: T) {
  const ctx = React.createContext(initialValue);
  ctx.displayName = displayName;
  return ctx;
}
