import type { FlowStepDataValueOf, FlowStepType } from "schemas/flow";

import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import { useGetCurrentFlow } from "@components/flows/hooks/useGetCurrentFlow";

import { useSearchParams } from "react-router-dom";

export function useGetStepResultsData<T extends FlowStepType>(
  stepId: string | undefined,
) {
  const [searchParams] = useSearchParams();
  const isDebug = Boolean(searchParams.get("debug"));
  const { currentInstance } = useGetCurrentFlow({ isDebug });

  const stepResults = stepId ? currentInstance?.stepResults?.[stepId] : null;

  return (stepResults?.data as FlowStepDataValueOf<T> | undefined) ?? null;
}

export function useGetCurrentStepResultsData<T extends FlowStepType>() {
  const { currentStep } = useReploFlowsStepContext();
  return useGetStepResultsData<T>(currentStep?.id as T);
}
