import * as React from "react";

import { WorkspaceDashboardContext } from "@editor/contexts/WorkspaceDashboardContext";
import { routes } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";

import { Navigate, useLocation, useParams } from "react-router-dom";
import { validate as isUUIDValid } from "uuid";

const isWorkspacePath = (path: string) => {
  return new RegExp(`\\/workspace\\/.*\\/[a-zA-Z0-9]*`).test(path);
};

const WorkspaceProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const { workspaceId: workspaceIdFromParams } = useParams();
  const { data } = trpc.workspace.getUserWorkspacesList.useQuery();
  const workspaces = data?.workspaces ?? [];
  const firstWorkspace = workspaces[0];

  const lastVisitedWorkspaceId = localStorage.getItem(
    "replo.workspace.lastvisitedWorkspaceId",
  );

  const isValidWorkspaceIdFromParams = workspaceIdFromParams
    ? isUUIDValid(workspaceIdFromParams)
    : false;
  const isValidLastVisitedWorkspaceId = lastVisitedWorkspaceId
    ? isUUIDValid(lastVisitedWorkspaceId)
    : false;

  let defaultWorkspaceId = null;
  if (isValidWorkspaceIdFromParams) {
    defaultWorkspaceId = workspaceIdFromParams!;
  } else if (isValidLastVisitedWorkspaceId) {
    defaultWorkspaceId = lastVisitedWorkspaceId!;
  }

  const [workspaceId, setWorkspaceId] = React.useState<string | null>(
    defaultWorkspaceId,
  );

  // Note (Evan, 2024-11-20): If we have a workspaceId in the params, we should use that
  React.useEffect(() => {
    if (workspaceIdFromParams && isValidWorkspaceIdFromParams) {
      setWorkspaceId(workspaceIdFromParams!);
    }
  }, [workspaceIdFromParams, isValidWorkspaceIdFromParams]);

  // Note (Evan, 2024-11-20): Set the first workspace from the list, iff we don't have one
  React.useEffect(() => {
    if (!workspaceId && firstWorkspace) {
      setWorkspaceId(firstWorkspace.id);
    }
  }, [workspaceId, firstWorkspace]);

  // Update last visited workspace id in localstorage when needed
  React.useEffect(() => {
    if (!workspaceId) {
      return;
    }
    localStorage.setItem("replo.workspace.lastvisitedWorkspaceId", workspaceId);
  }, [workspaceId]);

  if (!isValidWorkspaceIdFromParams && isWorkspacePath(location.pathname)) {
    return <Navigate to={routes.dashboard} />;
  }

  return (
    <WorkspaceDashboardContext.Provider
      value={{
        workspaceId,
        setWorkspaceId,
      }}
    >
      {children}
    </WorkspaceDashboardContext.Provider>
  );
};

export default WorkspaceProvider;
