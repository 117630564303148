import { routes } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";

import { generatePath } from "react-router-dom";

import useCurrentWorkspaceId from "./useCurrentWorkspaceId";

const useGetHeaderLogoRedirection = () => {
  const workspaceId = useCurrentWorkspaceId();
  const { data: workspaceData, isLoading: isLoadingWorkspace } =
    trpc.workspace.getUserWorkspacesList.useQuery();
  const hasMultipleWorkspaces =
    workspaceData?.workspaces && workspaceData.workspaces.length > 1;
  const workspaceIdFromResponse = workspaceData?.workspaces[0]?.id;
  const multipleWorkspacesRedirectPath = workspaceId
    ? generatePath(routes.workspace.projects, {
        workspaceId,
      })
    : "/home";

  const redirectTo =
    !hasMultipleWorkspaces && !isLoadingWorkspace && workspaceIdFromResponse
      ? generatePath(routes.workspace.projects, {
          workspaceId: workspaceIdFromResponse,
        })
      : multipleWorkspacesRedirectPath;

  return redirectTo;
};

export default useGetHeaderLogoRedirection;
