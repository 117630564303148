import type { GetAttributeFunction } from "@editor/types/get-attribute-function";

import * as React from "react";

import { selectGetAttributeDependencies } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { memoizedGetAttributeRetriever } from "@editor/utils/component-attribute";
import { objectId } from "@editor/utils/objectId";
import useOverridableRef from "@hooks/useOverridableRef";

import { selectActiveCanvas } from "@/features/canvas/canvas-reducer";

/**
 * Hook to access a memoized version of getAttribute for the current draft component.
 * Generally, this is how you want to get style values for the draft component in
 * modifiers.
 */
export const useGetAttribute = () => {
  const dependencies = useEditorSelector(selectGetAttributeDependencies);
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const { element } = dependencies;

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  return React.useCallback<GetAttributeFunction>(
    (component, attribute, defaults) => {
      return memoizedGetAttributeRetriever(
        component,
        { ...dependencies, activeCanvas },
        element ? objectId(element) : null,
      )(attribute, defaults);
    },
    // Note (Noah, 2022-02-26): These are needed as dependencies since they're also
    // components of the memoization key for getAttribute. If dependencies are added
    // here, they should probably be added to the memoization `normalize` function
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dependencies, activeCanvas],
  );
};

export const useGetAttributeRef = () => {
  const getAttribute = useGetAttribute();
  const getAttributeRef = useOverridableRef(getAttribute);
  return getAttributeRef;
};
