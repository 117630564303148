const objIdMap = new WeakMap<object, number>();
let objectCount = 0;

/**
 * Given an object, returns a memory-unique id of that object. This id is
 * guaranteed to be the same for the same in-memory object for the lifetime
 * of the global scope (e.g. window).
 *
 * Useful for when we need to represent object reference equality with a key
 * (e.g. memoization functions, etc).
 *
 * https://stackoverflow.com/questions/2020670/javascript-object-id
 */
export function objectId(object: Object): number {
  if (!objIdMap.has(object)) {
    objIdMap.set(object, ++objectCount);
  }
  return objIdMap.get(object)!;
}
