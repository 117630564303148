import * as React from "react";

import useDraggingCursor from "@editor/hooks/useDraggingCursor";

const DraggingCursorFrame = () => {
  const { draggingCursor } = useDraggingCursor();
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: draggingCursor ? "block" : "none",
        cursor: draggingCursor,
        zIndex: 2_147_483_700,
      }}
    />
  );
};

export default DraggingCursorFrame;
