import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import {
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { useProductFromProps } from "../../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedLiquidReviewIntegrations } from "../SharedLiquidReviewIntegrations";

const JUDGE_PRODUCT_REVIEWS_WIDGET_LIQUID_SOURCE = `
  <!-- Start of Judge.me code -->
    <div style='clear:both'></div>
    <div id='judgeme_product_reviews' class='jdgm-widget jdgm-review-widget' data-id='{{ product.id }}'>
        {{ product.metafields.judgeme.widget }}
    </div>
  <!-- End of Judge.me code -->
`;

function JudgeProductReviewsWidget(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const product = useProductFromProps(component.props, context);
  const liquidSourceWithProductAssignment = wrapProductAssignmentToLiquidSource(
    {
      product,
      liquidSource: JUDGE_PRODUCT_REVIEWS_WIDGET_LIQUID_SOURCE,
      context,
    },
  );
  return (
    <SharedLiquidReviewIntegrations
      attributes={componentAttributes}
      component={component}
      liquidSource={liquidSourceWithProductAssignment}
      placeholder="Judge Product Reviews Widget will appear here"
      shouldHavePlaceHolder={isEditorApp || !product}
      appName="Judge.me"
      context={context}
    />
  );
}

export default JudgeProductReviewsWidget;
