// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */

import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import * as Accordion from "@radix-ui/react-accordion";

import { useRenderChildren } from "../../../shared/utils/renderComponents";
import { useSharedState } from "../../hooks/useSharedState";
import { getAccordionNewItems } from "../../utils/accordion";
import { ReploComponent } from "../ReploComponent";

type AccordionContextType = {
  openItems: string[];
  toggleItem: (id: string) => void;
};

const AccordionContext = React.createContext<AccordionContextType | null>(null);

const AccordionBlock: React.FC<RenderComponentProps> = ({
  context,
  componentAttributes,
  component,
}) => {
  const children = useRenderChildren(component, { context });

  const { repeatedIndexPath } = context;
  const { _autoClose } = component.props;
  const defaultOpenedChildCollapsibleId = children?.find(
    (component) =>
      component.component.type === "collapsibleV2" &&
      component.component.props._defaultOpen === true,
  )?.component.id;

  const defaultAccordionOpenItemsValue = React.useMemo(
    () =>
      defaultOpenedChildCollapsibleId
        ? [defaultOpenedChildCollapsibleId]
        : null,
    [defaultOpenedChildCollapsibleId],
  );

  const [accordionOpenItems, setAccordionOpenItems] = useSharedState(
    [component.id, `accordionOpenItems`],
    defaultAccordionOpenItemsValue,
  );

  const accordionContextValue: AccordionContextType = {
    openItems: accordionOpenItems ?? [],
    toggleItem: (componentId) => {
      const newAccordionOpenItems = getAccordionNewItems(
        componentId,
        accordionOpenItems ?? undefined,
        _autoClose,
      );
      setAccordionOpenItems(newAccordionOpenItems);
    },
  };

  return (
    <AccordionContext.Provider value={accordionContextValue}>
      <Accordion.Root asChild type="multiple" value={accordionOpenItems ?? []}>
        <div {...componentAttributes}>
          {children?.map(({ component, context }) => (
            <ReploComponent
              key={component.id}
              component={component}
              context={context}
              repeatedIndexPath={repeatedIndexPath}
            />
          ))}
        </div>
      </Accordion.Root>
    </AccordionContext.Provider>
  );
};

export function useAccordion() {
  return React.useContext(AccordionContext);
}

export default AccordionBlock;
