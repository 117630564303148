import type { Option } from "@replo/design-system/components/shadcn/combobox/types";
import type { ConstructedAnalyticsLink } from "schemas/generated/analyticsLink";
import type { Experiment } from "schemas/generated/experiment";

import * as React from "react";

import Input from "@common/designSystem/Input";
import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import { successToast } from "@editor/components/common/designSystem/Toast";
import { useSubscriptionInfo } from "@editor/hooks/subscription";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { trpc, trpcUtils } from "@editor/utils/trpc";
import { useModal } from "@hooks/useModal";

import { DetailsContainer } from "@/features/experiments/components/DetailsContainer";
import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@replo/design-system/components/button";
import { Combobox } from "@replo/design-system/components/shadcn/combobox/Combobox";
import { ComboboxMultiSelect } from "@replo/design-system/components/shadcn/combobox/ComboboxMultiSelect";
import { Spinner } from "@replo/design-system/components/spinner";
import Tooltip from "@replo/design-system/components/tooltip";
import { skipToken } from "@tanstack/react-query";
import copy from "copy-to-clipboard";
import isEqual from "lodash-es/isEqual";
import { useForm } from "react-hook-form";
import { BsCaretDownFill, BsInfoCircle, BsLockFill } from "react-icons/bs";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { DEFAULT_DOMAIN } from "schemas/analyticsLink";
import { BillingTiers } from "schemas/billing";
import { isPathSafeSlug } from "schemas/utils";
import { twMerge } from "tailwind-merge";
import * as z from "zod";

type SubSectionType = "domain" | "shortName" | "slug";

type SubSectionProps = {
  title: string;
  className?: string;
  type: SubSectionType;
  value: string;
  options?: Option[];
  helpTooltipText: string;
  error?: string;
  setValue: (value: string) => void;
  onBlur?: (value: string) => void;
  onEnter?: (value: string) => void;
};

const SubSection: React.FC<SubSectionProps> = ({
  title,
  className,
  type,
  value,
  options,
  helpTooltipText,
  error,
  setValue,
  onBlur,
  onEnter,
}) => {
  const { value: localValue, onChange: handleChange } = useOverridableInput({
    value,
    onValueChange: setValue,
  });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && onEnter) {
      onEnter(localValue);
    }
  };

  const inputComponent = exhaustiveSwitch({ type })({
    shortName: () => (
      <ComboboxMultiSelect
        options={options ?? []}
        value={value}
        onChange={setValue}
        endEnhancer={() => <BsCaretDownFill className="h-2 w-2 text-subtle" />}
        isCurrentOptionCreatable
        inputPlaceholder="Select Group Name"
      />
    ),
    domain: () => (
      <Combobox
        options={options ?? []}
        value={value}
        onChange={setValue}
        className="w-full"
        placeholder="Select Domain"
        endEnhancer={() => <BsCaretDownFill className="h-2 w-2 text-subtle" />}
      />
    ),
    slug: () => (
      <Input
        size="base"
        placeholder="Enter Slug"
        value={localValue}
        onChange={handleChange}
        onBlur={() => onBlur?.(localValue)}
        onKeyDown={handleKeyDown}
        unsafe_inputClassName="text-xs"
      />
    ),
  });

  return (
    <div className={twMerge(className, "flex flex-col gap-1")}>
      <div className="flex flex-row gap-1 items-center">
        <span className="text-sm font-semibold">{title}</span>
        <span className="text-danger">*</span>
        <Tooltip
          content={helpTooltipText}
          triggerAsChild
          side="top"
          delay={300}
        >
          <div>
            {" "}
            <BsInfoCircle size={12} className="text-muted" />{" "}
          </div>
        </Tooltip>
      </div>

      {inputComponent}

      {error && <span className="text-xs text-danger mt-1">{error}</span>}
    </div>
  );
};

type CreateNewLinkProps = {
  onPropertyChange: (changes: Partial<Experiment>) => void;
  experimentName: Experiment["name"];
  handleLinkSubsectionChange: (value: string) => void;
  shouldForceCreateNewLink: boolean;
  workspaceId: string | undefined;
  links: ConstructedAnalyticsLink[];
};

const createNewLinkSchema = (links: ConstructedAnalyticsLink[]) =>
  z
    .object({
      customDomain: z.string(),
      shortName: z.string().nullable(),
      path: z.string().min(1, "Slug is required").refine(isPathSafeSlug, {
        message: "Slug must contain only letters, numbers, or hyphens",
      }),
    })
    .superRefine((data, ctx) => {
      if (!data.customDomain && !data.shortName) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Either a custom domain or short name must be provided",
          path: ["_form"],
        });
      }

      if (data.customDomain === DEFAULT_DOMAIN && !data.shortName) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Short name is required when using the default domain",
          path: ["_form"],
        });
      }

      if (data.customDomain !== DEFAULT_DOMAIN && data.shortName) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "Short name should not be provided when using a custom domain",
          path: ["_form"],
        });
      }

      const doesLinkAlreadyExist = links.some((link) => {
        const { customDomainValue, shortNameValue, path } = link;

        return (
          customDomainValue ===
            (data.customDomain === DEFAULT_DOMAIN ? null : data.customDomain) &&
          shortNameValue === data.shortName &&
          path === data.path
        );
      });

      if (doesLinkAlreadyExist) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "This combination of domain, group name and slug is already used by another link.",
          path: ["_form"],
        });
      }
    });

type AnalyticsLinkFormData = z.infer<ReturnType<typeof createNewLinkSchema>> & {
  _form?: string;
};

const CreateNewLink: React.FC<CreateNewLinkProps> = ({
  onPropertyChange,
  experimentName,
  handleLinkSubsectionChange,
  shouldForceCreateNewLink,
  workspaceId,
  links,
}) => {
  const {
    mutateAsync: createAnalyticsLinkAsync,
    isPending: isCreatingAnalyticsLink,
  } = trpc.analyticsLink.create.useMutation({});

  const { data: customDomains, isLoading: isLoadingCustomDomains } =
    trpc.workspace.getCustomDomains.useQuery(
      workspaceId ? workspaceId : skipToken,
    );

  const domainOptions = [
    { value: DEFAULT_DOMAIN, label: DEFAULT_DOMAIN },
    ...(customDomains ?? []).map((domain) => ({
      value: domain.value,
      label: domain.value,
    })),
  ];

  const { data: shortNames, isLoading: isLoadingShortNames } =
    trpc.workspace.getShortNames.useQuery(
      workspaceId ? workspaceId : skipToken,
    );

  const shortNameOptions = (shortNames ?? []).map((shortName) => ({
    value: shortName.value,
    label: shortName.value,
  }));

  const customDomainOptions = domainOptions.filter(
    (option) => option.value !== DEFAULT_DOMAIN,
  );

  const domainOptionsValue = customDomainOptions[0]?.value ?? DEFAULT_DOMAIN;

  const defaultAnalyticsLinkCreate: AnalyticsLinkFormData = {
    customDomain: domainOptionsValue,
    shortName:
      domainOptionsValue !== DEFAULT_DOMAIN
        ? null
        : shortNameOptions[0]?.value ?? null,
    path: experimentName,
  };

  const {
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm<AnalyticsLinkFormData>({
    resolver: zodResolver(createNewLinkSchema(links)),
    defaultValues: defaultAnalyticsLinkCreate,
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const formLevelError = errors._form?.message;

  /**
   * TODO (Max, 2024-11-19, REPL-14716):
   * Temporary fix to make sure that we're updating the react-hook-form
   * values once we loaded the shortNameOptions. Otherwise, the shortName
   * field is empty
   */
  const [previousShortNameOptions, setPreviousShortNameOptions] =
    React.useState(shortNameOptions);

  if (!isEqual(previousShortNameOptions, shortNameOptions)) {
    setPreviousShortNameOptions(shortNameOptions);
    if (
      shortNameOptions.length > 0 &&
      (!watch("shortName") ||
        !shortNameOptions.find((opt) => opt.value === watch("shortName"))) &&
      watch("customDomain") === DEFAULT_DOMAIN
    ) {
      setValue("shortName", shortNameOptions[0]?.value ?? null);
      void trigger();
    }
  }

  const handleDomainChange = (value: string) => {
    setValue("customDomain", value);
    if (value !== DEFAULT_DOMAIN) {
      setValue("shortName", null);
    } else {
      setValue("shortName", shortNameOptions[0]?.value ?? null);
    }
    void trigger();
  };

  const handleShortNameChange = (value: string) => {
    setValue("shortName", value);
    void trigger();
  };

  const handleSlugChange = (value: string) => {
    setValue("path", value);
    void trigger();
  };

  const onSubmit = async (data: AnalyticsLinkFormData) => {
    if (workspaceId) {
      const analyticsLink = {
        ...data,
        customDomain:
          data.customDomain === DEFAULT_DOMAIN ? null : data.customDomain,
      };

      const result = await createAnalyticsLinkAsync({
        workspaceId,
        analyticsLink,
      });
      await trpcUtils.analyticsLink.list.invalidate({ workspaceId });
      await trpcUtils.workspace.getShortNames.invalidate(workspaceId);

      handleLinkSubsectionChange("chooseLink");
      successToast("Experiment Link Created", "");
      // TODO (Max, 2024-11-18, REPL-14716): It's not auto-selecting the newly created link on the dropdown.
      // Use context to fix this (see ticket)
      onPropertyChange({ analyticsLinkId: result.analyticsLinkId });
    }
  };

  const customDomain = watch("customDomain");
  const isShortNameFieldRequired =
    !customDomain || customDomain === DEFAULT_DOMAIN;

  const handleSubmission = handleSubmit(onSubmit);
  const onCreateLink = () => {
    void handleSubmission();
  };

  const modal = useModal();
  const openAddCustomDomainModal = () =>
    modal.openModal({
      type: "customDomainModal",
    });

  if (isLoadingCustomDomains || isLoadingShortNames) {
    return <Spinner size={25} variant="primary" />;
  }

  return (
    <div className="border border-slate-300 rounded-md p-4 flex flex-col gap-3">
      <div className="grid grid-cols-12 gap-3">
        <SubSection
          title="Domain"
          className="col-span-4"
          type="domain"
          value={customDomain}
          options={[
            ...domainOptions,
            {
              value: "addCustomDomain",
              label: "Add Custom Domain",
              component: (
                <span className="text-accent text-xs font-medium">
                  Add Custom Domain
                </span>
              ),
              onClick: openAddCustomDomainModal,
            },
          ]}
          setValue={handleDomainChange}
          helpTooltipText="Use our default reploedge domain, or use your own custom domain"
          error={errors.customDomain?.message}
        />
        {isShortNameFieldRequired && (
          <SubSection
            title="Group Name"
            className="col-span-4"
            type="shortName"
            value={watch("shortName") ?? ""}
            options={shortNameOptions}
            setValue={handleShortNameChange}
            helpTooltipText="A unique name identifying your workspace, to distinguish it from others in the Replo system. Only required when using reploedge.com as your domain"
            error={errors.shortName?.message}
          />
        )}
        <SubSection
          title="Slug"
          className="col-span-4"
          type="slug"
          value={watch("path") ?? ""}
          setValue={handleSlugChange}
          helpTooltipText="The URL path that distinguishes this link from the other links in your workspace"
          error={errors.path?.message}
        />
      </div>
      {formLevelError && (
        <span className="text-xs text-danger mt-1">{formLevelError}</span>
      )}
      <div className="flex flex-row justify-end gap-2">
        {!shouldForceCreateNewLink && (
          <Button
            variant="secondary"
            size="sm"
            onClick={() => handleLinkSubsectionChange("chooseLink")}
          >
            Cancel
          </Button>
        )}

        <Button
          variant="primary"
          size="sm"
          onClick={onCreateLink}
          isLoading={isCreatingAnalyticsLink}
          isDisabled={!isValid}
        >
          Create Link
        </Button>
      </div>
    </div>
  );
};

const ChooseLinkOption: React.FC<{
  url: string;
  isActive: boolean;
  analyticsLinkId: string;
  handleClick: (analyticsLinkId: string) => void;
}> = ({ url, isActive, analyticsLinkId, handleClick }) => {
  return (
    <div
      className="flex flex-row gap-2 items-center"
      onClick={() => handleClick(analyticsLinkId)}
    >
      <span>{url}</span>
      {isActive && <span className="h-1 w-1 bg-green-600 rounded-full" />}
    </div>
  );
};

type ChooseLinkProps = {
  analyticsLinkId: string | null;
  handleChooseLinkChange: (analyticsLinkId: string) => void;
  links: ConstructedAnalyticsLink[];
  isEditable?: boolean;
};
const ChooseLink: React.FC<ChooseLinkProps> = ({
  analyticsLinkId,
  handleChooseLinkChange,
  links,
  isEditable = true,
}) => {
  const [isComboboxOpen, setIsComboboxOpen] = React.useState(false);

  const handleOptionClick = (analyticsLinkId: string) => {
    handleChooseLinkChange(analyticsLinkId);
    setIsComboboxOpen(false);
  };

  return (
    <Combobox
      open={isComboboxOpen}
      onOpenChange={setIsComboboxOpen}
      options={
        links.map((constructedLink) => {
          const { id, url, isActive } = constructedLink;
          return {
            value: id,
            label: url,
            component: (
              <ChooseLinkOption
                url={url}
                isActive={isActive}
                analyticsLinkId={id}
                handleClick={handleOptionClick}
              />
            ),
            isDisabled: isActive,
          };
        }) ?? []
      }
      placeholder="Select Link"
      value={analyticsLinkId ?? undefined}
      className="w-full"
      endEnhancer={() =>
        isEditable ? <BsCaretDownFill size={8} /> : <BsLockFill size={12} />
      }
      isDisabled={!isEditable}
    />
  );
};

type LinkSectionProps = {
  isEditable?: boolean;
  linkSubSection: "chooseLink" | "createNewLink";
  handleLinkSubsectionChange: (value: string) => void;
  links: ConstructedAnalyticsLink[];
  onPropertyChange: (changes: Partial<Experiment>) => void;
  experiment: {
    analyticsLinkId: Experiment["analyticsLinkId"];
    name: Experiment["name"];
  };
  shouldForceCreateNewLink: boolean;
};

export const LinkSection: React.FC<LinkSectionProps> = ({
  isEditable = true,
  linkSubSection,
  handleLinkSubsectionChange,
  links,
  onPropertyChange,
  experiment,
  shouldForceCreateNewLink,
}) => {
  const workspaceId = useCurrentWorkspaceId() ?? undefined;
  const logEvent = useLogAnalytics();
  const { subscriptionInfo } = useSubscriptionInfo();
  const subscriptionTier = subscriptionInfo?.tier || BillingTiers.FREE;

  const handleChooseLinkChange = (analyticsLinkId: string) => {
    onPropertyChange({
      analyticsLinkId,
    });
  };

  const getSelectedLinkUrl = (): string | undefined => {
    if (!links || !experiment.analyticsLinkId) {
      return undefined;
    }

    const selectedLink = links.find(
      (link) => link.id === experiment.analyticsLinkId,
    );

    return selectedLink?.url;
  };

  return (
    <DetailsContainer
      title="Your Experiment Link"
      isRequired
      headerComponent={
        isEditable && linkSubSection === "chooseLink" ? (
          <div className="text-accent">
            <Button
              variant="tertiary"
              size="base"
              textClassNames="text-accent"
              onClick={() => handleLinkSubsectionChange("createNewLink")}
            >
              Create New Link
            </Button>
          </div>
        ) : undefined
      }
    >
      <div className="flex flex-col gap-3">
        {linkSubSection === "chooseLink" ? (
          <ChooseLink
            analyticsLinkId={experiment.analyticsLinkId}
            handleChooseLinkChange={handleChooseLinkChange}
            links={links ?? []}
            isEditable={isEditable}
          />
        ) : (
          <CreateNewLink
            onPropertyChange={onPropertyChange}
            experimentName={experiment.name}
            handleLinkSubsectionChange={handleLinkSubsectionChange}
            shouldForceCreateNewLink={shouldForceCreateNewLink}
            workspaceId={workspaceId}
            links={links ?? []}
          />
        )}

        <div className="flex flex-row justify-end">
          <div className="flex flex-row gap-2">
            {experiment.analyticsLinkId && linkSubSection === "chooseLink" && (
              <Button
                variant={!isEditable ? "tertiary" : "secondary"}
                size="base"
                onClick={() => {
                  const url = getSelectedLinkUrl();
                  if (url) {
                    copy(url);
                    logEvent("experiment.link.copy", {
                      billingPlanTier: subscriptionTier,
                    });
                  }
                }}
                textClassNames={!isEditable ? "text-blue-600" : ""}
              >
                Copy Link
              </Button>
            )}

            {!isEditable && (
              <Button
                variant="secondary"
                size="base"
                href={getSelectedLinkUrl() ?? ""}
                onClick={() => {
                  logEvent("experiment.link.preview", {
                    billingPlanTier: subscriptionTier,
                  });
                }}
              >
                Preview
              </Button>
            )}
          </div>
        </div>
      </div>
    </DetailsContainer>
  );
};
