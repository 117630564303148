import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { trpc } from "@editor/utils/trpc";

import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";

/**
 * NOTE Ben 2024-10-01: This is intended to be a stop-gap measure to allow self-onboarding from
 * the setup screen while we work on the rest of self-onboarding. It will be removed and replaced
 * in REPL-13622, REPL-13621, or REPL-13620.
 */
export function useAnalyticsOnboardingOAuthLink() {
  const workspaceId = useCurrentWorkspaceId();
  const { data: integrationsData, isLoading: isLoadingIntegration } =
    trpc.integration.getWorkspaceShopifyIntegrationData.useQuery(
      workspaceId ? { workspaceId } : reactQuerySkipToken,
    );
  const integration = (integrationsData?.integrations ?? [])[0];
  const shopifyUrl = integration?.shopifyIntegrationConfig?.shopifyUrl;
  const { data: oauthLink, isLoading: isLoadingOauthLink } =
    trpc.analytics.generateOAuthLink.useQuery(
      workspaceId && shopifyUrl
        ? {
            workspaceId,
            shopifyUrl,
          }
        : reactQuerySkipToken,
    );
  const isLoading = isLoadingIntegration || isLoadingOauthLink;
  return {
    isLoading,
    oauthLink,
  };
}
