import LZString from "lz-string";
import { z } from "zod";

// NOTE (Kurt, 2024-10-09): This is a helper function to generate a Zod schema
// from a TypeScript type.
function typeToZodSchema<T>(): z.ZodType<T> {
  return z.custom<T>((_): _ is T => true);
}

// NOTE (Kurt, 2024-10-09): This is a helper function to safely decompress and
// validate a stringified object from a URL. The type must be provided as a
// generic to the function which is used to generate a Zod schema to validate
// the decompressed object.
export function decompressObjectFromLzString<T extends object>(
  lzString: string,
): T {
  try {
    const decompressedString =
      LZString.decompressFromEncodedURIComponent(lzString);
    const parsedObject = JSON.parse(decompressedString);
    const schema = typeToZodSchema<T>();
    const result = schema.safeParse(parsedObject);
    if (!result.success) {
      const errorMessage = result.error.issues
        .map((issue) => `${issue.path.join(".")}: ${issue.message}`)
        .join(", ");
      throw new Error(`Validation failed: ${errorMessage}`);
    }
    return result.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(
        `Error decompressing or validating object: ${error.message}`,
      );
    } else {
      throw new Error(
        "Unknown error occurred while decompressing or validating object",
      );
    }
  }
}

export function compressObjectToLzString<T extends object>(obj: T): string {
  const stringifiedObject = JSON.stringify(obj);
  const compressedLzString =
    LZString.compressToEncodedURIComponent(stringifiedObject);
  return compressedLzString;
}
