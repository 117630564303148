import * as React from "react";

import AnalyticsContext from "@/features/analytics/AnalyticsContext";
import { Outlet } from "react-router-dom";

const WorkspaceAnalyticsDashboard: React.FC = () => {
  return (
    <AnalyticsContext>
      <Outlet />
    </AnalyticsContext>
  );
};

export default WorkspaceAnalyticsDashboard;
