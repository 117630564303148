import type { SelectedTab } from "@/features/experiments/tabs/constants/tabOptions";

import * as React from "react";

import { useSubscriptionInfo } from "@editor/hooks/subscription";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";

import {
  TAB_OPTION_DETAILS,
  TAB_OPTION_RESULTS,
} from "@/features/experiments/tabs/constants/tabOptions";
import { useExperimentDetails } from "@/features/experiments/tabs/hooks/useExperimentDetails";
import Tooltip from "@replo/design-system/components/tooltip";
import classNames from "classnames";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { BillingTiers } from "schemas/billing";
import { getExperimentStatus } from "schemas/experiment";

export type Tab = "Details" | "Results";

type DisplayTabProps = {
  tab: SelectedTab;
  disabled?: boolean;
};

const DisplayTab: React.FC<DisplayTabProps> = ({ tab, disabled }) => {
  const workspaceId = useCurrentWorkspaceId();
  const { experimentId } = useParams();
  const logEvent = useLogAnalytics();
  const { subscriptionInfo } = useSubscriptionInfo();
  const subscriptionTier = subscriptionInfo?.tier || BillingTiers.FREE;

  const location = useLocation();
  const isResultsTab = location.pathname.includes(TAB_OPTION_RESULTS.value);
  const selectedTab = isResultsTab ? TAB_OPTION_RESULTS : TAB_OPTION_DETAILS;

  const path = `/workspace/${workspaceId}/experiments/${experimentId}/${tab.value}`;

  const handleClick = () => {
    if (tab === TAB_OPTION_DETAILS) {
      logEvent("experiment.detail.select", {
        billingPlanTier: subscriptionTier,
      });
    } else if (tab === TAB_OPTION_RESULTS) {
      logEvent("experiment.results.select", {
        billingPlanTier: subscriptionTier,
      });
    }
  };

  if (disabled) {
    return (
      <Tooltip
        content="Start experiment to view results"
        delay={300}
        triggerAsChild
      >
        <span
          className={classNames(
            "text-sm font-normal cursor-pointer opacity-50 flex items-center",
            "text-subtle",
          )}
        >
          {tab.label}
        </span>
      </Tooltip>
    );
  }

  return (
    <NavLink key={tab.value} to={path} onClick={handleClick}>
      <span
        className={classNames(
          "text-sm font-normal cursor-pointer",
          tab === selectedTab ? "text-accent" : "text-subtle",
        )}
      >
        {tab.label}
      </span>
    </NavLink>
  );
};

export const TabMenu: React.FC = () => {
  const { experiment } = useExperimentDetails();
  const status = experiment ? getExperimentStatus(experiment) : null;
  const isResultsDisabled = status === "draft";

  return (
    <div className="gap-1">
      <div className="flex flex-row gap-4 pl-2 pb-2">
        <DisplayTab tab={TAB_OPTION_DETAILS} />
        <DisplayTab tab={TAB_OPTION_RESULTS} disabled={isResultsDisabled} />
      </div>
    </div>
  );
};
