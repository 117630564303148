import type { SavedStyleColorType } from "schemas/generated/savedStyles";

export const COLOR_DEFAULTS: Record<string, string> = {
  basic: "#000000",
  foregroundPrimary: "#333333",
  foregroundSecondary: "#666666",
  foregroundTertiary: "#999999",
  backgroundPrimary: "#FFFFFF",
  backgroundSecondary: "#F5F5F5",
  backgroundTertiary: "#E0E0E0",
};

export const COLOR_MENU_ITEMS: {
  colorType: SavedStyleColorType;
  title: string;
}[] = [
  { colorType: "basic", title: "Basic" },
  { colorType: "foregroundPrimary", title: "Foreground/Primary" },
  { colorType: "foregroundSecondary", title: "Foreground/Secondary" },
  { colorType: "foregroundTertiary", title: "Foreground/Tertiary" },
  { colorType: "backgroundPrimary", title: "Background/Primary" },
  { colorType: "backgroundSecondary", title: "Background/Secondary" },
  { colorType: "backgroundTertiary", title: "Background/Tertiary" },
];
