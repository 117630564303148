import * as React from "react";

import { isEmpty } from "replo-utils/lib/misc";

import { makeId } from "./utils";

export function useId(prefix?: string | null, override?: string | null) {
  let generatedId = React.useId();
  if (!isEmpty(override)) {
    return override;
  }
  if (prefix) {
    if (generatedId.startsWith(makeId.DELIMITER)) {
      generatedId = generatedId.slice(makeId.DELIMITER.length);
    }
    return makeId(prefix, generatedId);
  }
  return prefix ? makeId(prefix, generatedId.slice(1)) : generatedId;
}
