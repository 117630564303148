import { useLocation } from "react-router-dom";
import { getCategoryNameFromSlug } from "replo-runtime/shared/componentTemplates";

export const useBackButtonLabel = ({
  collectionName,
}: {
  collectionName?: string;
}) => {
  const { state } = useLocation();

  const fromPath = state?.fromPath;

  const searchString = state?.searchString;
  const categorySlug = state?.categorySlug;
  const componentTemplateName = state?.componentTemplateName;

  if (fromPath?.includes("details") && componentTemplateName) {
    return `Back to "${componentTemplateName}" Template`;
  }

  if (fromPath?.includes("collection") && collectionName) {
    return `Back "${collectionName}" Collection`;
  }

  if (categorySlug) {
    return getCategoryNameFromSlug(categorySlug);
  }

  if (searchString) {
    return `Back to "${searchString}" Search`;
  }

  return "Back";
};
