// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import * as React from "react";

import Modal from "@common/designSystem/Modal";
import Input from "@editor/components/common/designSystem/Input";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Selectable from "@editor/components/common/designSystem/Selectable";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import useInstallShopify from "@editor/hooks/useInstallShopify";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import {
  publisherApi,
  useGetUserWorkspaceDetailsQuery,
} from "@editor/reducers/api-reducer";
import { useEditorDispatch } from "@editor/store";
import { routes } from "@editor/utils/router";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button";
import { skipToken } from "@tanstack/react-query";
import classNames from "classnames";
import { Controller, useForm, useWatch } from "react-hook-form";
import { generatePath, useLocation, useNavigate } from "react-router-dom";

const buttonClassName =
  "border rounded-lg hover:border-blue-600 font-medium grid place-content-center flex-1 py-4 px-8 text-sm";
const selectedButtonClassName = "border-blue-600 bg-blue-50";

type CreateProjectFormData = {
  name: string;
  workspaceId: string;
  shopifyIntegrationId?: string | null;
  shouldConnectShopifyStore: boolean;
};

export const NEW_SHOPIFY_INTEGRATION_ID = "NEW_STORE_INTEGRATION";
export const NEW_WORKSPACE_ID = "NEW_WORKSPACE_ID";

const NewProjectFlow: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { initialWorkspaceId } = useUserWorkspaces();
  const { from } = location.state || {};

  const onClose = () => {
    if (from === "allProjects") {
      navigate(routes.allProjects);
    } else if (initialWorkspaceId) {
      navigate(
        generatePath(routes.workspace.projects, {
          workspaceId: initialWorkspaceId,
        }),
      );
    } else {
      navigate(routes.dashboard);
    }
  };
  return (
    <Modal isOpen className="w-[576px]" onRequestClose={onClose}>
      <div className="flex flex-col gap-4">
        <h1 className="text-lg font-semibold">New Replo Project</h1>
        <NewProjectForm />
      </div>
    </Modal>
  );
};

function NewProjectForm() {
  const location = useLocation();
  const { from } = location.state || {};
  const { user } = useCurrentUser();
  const { initialWorkspaceId } = useUserWorkspaces();
  const { register, handleSubmit, control, formState } =
    useForm<CreateProjectFormData>({
      defaultValues: {
        name: "",
        workspaceId: initialWorkspaceId,
        shouldConnectShopifyStore: false,
        shopifyIntegrationId: null,
      },
      mode: "onBlur",
    });

  const newProjectPayload = useWatch({ control });

  const { installShopify } = useInstallShopify();

  const analytics = useLogAnalytics();
  const dispatch = useEditorDispatch();
  const { mutate: createProject, isPending: isCreatingProject } =
    trpc.project.create.useMutation({
      onSuccess: async ({ project: newProject }) => {
        analytics("project.create", {
          withShopify: Boolean(newProjectPayload.shouldConnectShopifyStore),
          createdFrom: from === "allProjects" ? "allProjects" : "orgPage",
        });
        void trpcUtils.workspace.getUserWorkspacesList.invalidate();
        void trpcUtils.project.findByUserId.invalidate();
        // TODO (Sebas, 2024-07-15): Remove old invalidations once they are migrated to
        // TRPC.
        dispatch(publisherApi.util.invalidateTags(["workspaces", "projects"]));
        if (!newProject) {
          return;
        }
        if (
          newProjectPayload.shouldConnectShopifyStore &&
          newProjectPayload.shopifyIntegrationId === NEW_SHOPIFY_INTEGRATION_ID
        ) {
          await installShopify({
            type: "newProject",
            workspaceId: newProject.ownerWorkspaceId!,
            projectId: newProject.id,
          });
        } else {
          navigate(
            generatePath(routes.editor.project, {
              projectId: newProject.id,
            }),
          );
        }
      },
    });
  const navigate = useNavigate();

  const shouldShowShopifyStoreSelector =
    newProjectPayload.shouldConnectShopifyStore;

  function onSubmit({
    name,
    workspaceId,
    shouldConnectShopifyStore,
    shopifyIntegrationId,
  }: CreateProjectFormData) {
    const isNewStore = shopifyIntegrationId == NEW_SHOPIFY_INTEGRATION_ID;
    createProject({
      name: name ?? `${user?.name}’s project`,
      // TODO (Ben, 2024-10-09): Figure out what to do
      // with "NEW_WORKSPACE_ID": we were adding null for the
      // workspaceId if it was equal to "NEW_WORKSPACE_ID".
      workspaceId,
      shopifyIntegrationId:
        shouldConnectShopifyStore && !isNewStore ? shopifyIntegrationId : null,
    });
  }

  return (
    <form
      onSubmit={(values) => {
        void handleSubmit(onSubmit)(values);
      }}
      className="flex flex-col gap-4"
    >
      <div className="flex flex-col gap-2">
        <h2 className="text-sm font-medium text-default">
          Do you want to connect a Shopify Store?
        </h2>
        <div role="group" className="inline-grid grid-cols-2 gap-3">
          <Controller
            control={control}
            name="shouldConnectShopifyStore"
            render={({ field: { onChange, value } }) => (
              <button
                type="button"
                className={classNames(
                  buttonClassName,
                  !value && selectedButtonClassName,
                )}
                onClick={() => {
                  onChange(false);
                }}
                aria-pressed={!value}
              >
                <p>
                  Create project without <br /> Shopify store
                </p>
                <p className="text-muted text-xs font-normal whitespace-nowrap">
                  (You can connect a store later)
                </p>
              </button>
            )}
          />
          <Controller
            control={control}
            name="shouldConnectShopifyStore"
            render={({ field: { onChange, value } }) => (
              <button
                type="button"
                className={classNames(
                  buttonClassName,
                  value && selectedButtonClassName,
                )}
                onClick={() => {
                  onChange(true);
                }}
                aria-pressed={value}
              >
                Connect Shopify store
              </button>
            )}
          />
        </div>
      </div>
      <LabeledControl label="Project Name">
        <Input
          autoFocus
          placeholder="Project Name"
          {...register("name", {
            required: "Please enter a project name.",
          })}
          autoComplete="off"
          size="base"
        />
      </LabeledControl>
      <LabeledControl label="Workspace">
        <Controller
          render={({ field: { onChange, value } }) => (
            <WorkspaceSelectable value={value} onChange={onChange} />
          )}
          control={control}
          name="workspaceId"
        />
      </LabeledControl>
      {shouldShowShopifyStoreSelector && (
        <LabeledControl label="Shopify Integration">
          <Controller
            render={({ field: { value, onChange } }) => (
              <ShopifyIntegrationSelectable
                workspaceId={newProjectPayload.workspaceId}
                selectedIntegrationId={value}
                handleChangeSelection={onChange}
              />
            )}
            name="shopifyIntegrationId"
            control={control}
          />
        </LabeledControl>
      )}
      <div className="flex justify-between">
        <Button
          type="submit"
          variant="primary"
          size="lg"
          className="ml-auto"
          isDisabled={!formState.isValid || isCreatingProject}
          isLoading={isCreatingProject}
        >
          {isCreatingProject ? "Creating" : "Create"} Project
        </Button>
      </div>
    </form>
  );
}

export function ShopifyIntegrationSelectable({
  workspaceId,
  selectedIntegrationId,
  handleChangeSelection,
  isDisabled = false,
  className = "",
}: {
  workspaceId?: string | null;
  selectedIntegrationId?: string | null;
  handleChangeSelection: (id: string | null) => void;
  isDisabled?: boolean;
  className?: string;
}) {
  const { data } = trpc.integration.getWorkspaceShopifyIntegrationData.useQuery(
    workspaceId ? { workspaceId } : skipToken,
  );
  const existingShopifyIntegrations = data?.integrations;
  const shopifyIntegrations = React.useMemo(
    () => [
      ...(existingShopifyIntegrations ?? []),
      {
        id: NEW_SHOPIFY_INTEGRATION_ID,
        shopifyIntegrationConfig: {
          url: "+ Add a new Shopify Store",
        },
      },
    ],
    [existingShopifyIntegrations],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies(workspaceId): We actually want this
  React.useEffect(() => {
    if (
      selectedIntegrationId &&
      !shopifyIntegrations.find(({ id }) => id == selectedIntegrationId)
    ) {
      handleChangeSelection(null);
    }
  }, [
    shopifyIntegrations,
    workspaceId,
    selectedIntegrationId,
    handleChangeSelection,
  ]);
  return (
    <Selectable
      size="md"
      placeholder="Choose Shopify Store"
      className={`${className} bg-subtle`}
      options={shopifyIntegrations.map((integration) => ({
        label: integration.shopifyIntegrationConfig?.url ?? "",
        value: integration.id,
      }))}
      value={selectedIntegrationId}
      onSelect={handleChangeSelection}
      isDisabled={isDisabled}
      sideOffset={16}
    />
  );
}

export function WorkspaceSelectable({
  value,
  onChange,
}: {
  value: string | null;
  onChange: (value: string) => void;
}) {
  const { workspaces } = useUserWorkspaces();
  const options = React.useMemo(() => {
    if (!workspaces) {
      return [];
    }
    const options = [
      ...workspaces.map((workspace) => ({
        label: workspace.name,
        value: workspace.id,
      })),
    ];
    return options;
  }, [workspaces]);
  return (
    <Selectable
      size="md"
      placeholder="Choose Workspace"
      className="bg-subtle"
      options={options}
      value={value}
      onSelect={onChange}
      sideOffset={16}
    />
  );
}

function useUserWorkspaces() {
  const workspaceId = useCurrentWorkspaceId();
  const { user } = useCurrentUser();
  const { data } = useGetUserWorkspaceDetailsQuery();
  const workspaces = data?.workspaces;
  // Validate that the workspaceId that we got from the URL is valid
  const initialWorkspaceId = workspaces
    ? workspaces.find((workspace) => {
        return workspace.id === workspaceId;
      })?.id
    : workspaceId!;

  const personalWorkspaceId = workspaces?.find(
    (workspace) => workspace.domain === user?.email,
  )?.id;

  return {
    workspaces,
    initialWorkspaceId,
    personalWorkspaceId,
  };
}

export default NewProjectFlow;
