import * as React from "react";

import { useEffectEvent } from "./use-effect-event";

/**
 * @param value The value to react to
 * @param onChange The change handler
 * @param options.equalityFn A function to compare the values. Defaults to
 * `Object.is`
 * @param options.shouldSkip If true, skips the equality check and does not call
 * the change handler
 */
export function useOnValueChange<T>(
  value: T,
  onChange: (currentValue: T, previousValue: T) => void,
  options?: {
    equalityFn?: (value1: any, value2: any) => boolean;
    shouldSkip?: boolean;
  },
) {
  const _onChange = useEffectEvent(onChange);
  const valueRef = React.useRef(value);
  const { equalityFn = Object.is, shouldSkip } = options ?? {};
  React.useEffect(() => {
    const previousValue = valueRef.current;
    valueRef.current = value;
    // NOTE (Chance 2024-01-29): This is an optimization to avoid calling the
    // equality function if we don't need to.
    if (shouldSkip) {
      return;
    }
    if (!equalityFn(value, previousValue)) {
      _onChange(value, previousValue);
    }
  }, [_onChange, equalityFn, shouldSkip, value]);
}
