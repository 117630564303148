import type { RelativeTimeFrame } from "@/features/analytics/time";

import * as React from "react";

import { getIntervalFromTimePeriod } from "@editor/utils/analytics";

import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import {
  calculateQueryRangesForRelativeTimeFrame,
  calculateRangesForCustomTimeFrame,
} from "@/features/analytics/time";
import useAnalyticsReadWithUrlParams from "@/features/analytics/useAnalyticsReadWithUrlParams";
import { hours } from "replo-utils/lib/datetime";

const useChartAnalyticsRead = (
  workspaceId: string | null,
  pageUrlPath: string,
) => {
  const { getParamValue, setParamValue } = useAnalyticsQueryContext();

  const interval = React.useMemo(() => {
    const selectedTimePeriod = getParamValue("selectedTimePeriod");

    // NOTE (Kurt, 2024-10-31): This is just a hack to get the new interval from the url params if
    // it is set. In the future, we probably want there to be dedicated chart interval param in the URL instead
    // of the selectedTimePeriod.interval which we use. We only check for 1 hour and 24 hour intervals here
    // because that's all the intervals we currently support for the chart. There possibly some cleanup needed
    // here. General cleanup idead tracked here:
    // https://linear.app/replo/issue/REPL-14442/fix-default-interval-vs-chart-interval-in-analytics-query
    // Future idea: have a separate chart interval param that is only available in pages that are supposed to have
    // a chart.
    if (
      selectedTimePeriod.interval &&
      (selectedTimePeriod.interval === hours(1) ||
        selectedTimePeriod.interval === hours(24))
    ) {
      return selectedTimePeriod.interval;
    }

    const { updatedRanges } =
      selectedTimePeriod.type === "custom"
        ? calculateRangesForCustomTimeFrame({
            start: selectedTimePeriod.value.from,
            end: selectedTimePeriod.value.to,
          })
        : calculateQueryRangesForRelativeTimeFrame(
            selectedTimePeriod.value as RelativeTimeFrame,
          );

    const calculatedInterval = getIntervalFromTimePeriod(
      updatedRanges.mainRange.startDatetime,
      updatedRanges.mainRange.endDatetime,
    );

    return calculatedInterval;
  }, [getParamValue]);

  const result = useAnalyticsReadWithUrlParams({
    workspaceId,
    defaultInterval: interval,
    urlPathFilter: {
      type: "fullUrlPath",
      value: pageUrlPath,
    },
  });

  const handleIntervalChange = React.useCallback(
    (newInterval: number) => {
      setParamValue("selectedTimePeriod", {
        ...getParamValue("selectedTimePeriod"),
        interval: newInterval,
      });
      setParamValue("selectedComparePeriod", {
        ...getParamValue("selectedComparePeriod"),
        interval: newInterval,
      });
    },
    [getParamValue, setParamValue],
  );

  return {
    ...result,
    interval,
    handleIntervalChange,
  };
};

export default useChartAnalyticsRead;
