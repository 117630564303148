import type {
  CarouselDispatcher,
  CarouselEmittedEvent,
  CarouselEmitter,
} from "./carousel-state";
import type {
  ActiveSlidePosition,
  AutoPlayPauseEvent,
  AutoPlayPlayEvent,
  AutoPlayState,
  DragBehavior,
  EndBehavior,
  MoveDirection,
  Orientation,
  TextDirection,
  TransitionStyle,
  WheelBehavior,
} from "./lib/types";
import type { CarouselTransitionStates } from "./lib/utils";

import * as React from "react";

import { clamp } from "replo-utils/lib/math";
import { warning } from "replo-utils/lib/misc";
import { isFunction, isNumber, isString } from "replo-utils/lib/type-check";
import { useComposedEventHandlers } from "replo-utils/react/use-composed-event-handlers";
import { useComposedRefs } from "replo-utils/react/use-composed-refs";
import { useEffectEvent } from "replo-utils/react/use-effect-event";
import { useIsHydrated } from "replo-utils/react/use-is-hydrated";
import { useOnValueChange } from "replo-utils/react/use-on-value-change";
import { usePrefersReducedMotion } from "replo-utils/react/use-prefers-reduced-motion";
import { useRect } from "replo-utils/react/use-rect";
import { useStatefulElement } from "replo-utils/react/use-stateful-element";

import { useCarouselReducer } from "./carousel-state";
import {
  AXIS_X,
  AXIS_Y,
  DIRECTION_LTR,
  DIRECTION_RTL,
  DRAG_OFF,
  END_LOOP,
  END_NORMAL,
  KEY_ARROW_DOWN,
  KEY_ARROW_LEFT,
  KEY_ARROW_RIGHT,
  KEY_ARROW_UP,
  KEY_END,
  KEY_HOME,
  KEY_PAGE_DOWN,
  KEY_PAGE_UP,
  MOVE_DIRECTION_NEXT,
  MOVE_DIRECTION_PREVIOUS,
  NOOP,
  ORIENTATION_HORIZONTAL,
  ORIENTATION_VERTICAL,
  SLIDE_POSITION_CENTER,
  SLIDE_POSITION_START,
  TRANSITION_FADE,
  TRANSITION_OFF,
  TRANSITION_SLIDE,
  WHEEL_FREE,
  WHEEL_MOVE_ONCE,
  WHEEL_OFF,
} from "./lib/constants";
import { useId } from "./lib/use-id";
import { useTextDirection } from "./lib/use-text-direction";
import {
  canGoBack,
  canGoNext,
  getAfterClones,
  getBeforeClones,
  getElementHeight,
  getElementWidth,
  getSlideTransitionStates,
  getTrackAnimatingStyles,
  getTrackDOMId,
  getTrackStaticStyles,
  getTrackTransitionDelay,
  getTrackTransitionDuration,
  getTrackTransitionProperty,
  getTrackTransitionTimingFunction,
  isInfinite,
  makeId,
  parseInt,
  querySelectorAll,
} from "./lib/utils";

// #region CAROUSEL PRIMITIVE

// #region --- Carousel Context

const CarouselContext = React.createContext<CarouselContextValue | null>(null);
CarouselContext.displayName = "CarouselContext";

type GoToOptions = {
  disableAnimation?: boolean;
};

type CarouselController = {
  goBack: (opts?: GoToOptions) => void;
  goNext: (opts?: GoToOptions) => void;
  goToSlide: (slide: number, opts?: GoToOptions) => void;
  goToPage: (page: number, opts?: GoToOptions) => void;
  pause: () => void;
  play: () => void;
  canGoBack(): boolean;
  canGoNext(): boolean;
};

type SlideSizeEntry = [Width: number, Height: number];
type SlideSizeMap = Map<string, SlideSizeEntry>;

type CarouselContextValue = {
  activeSlideIndex: number;
  activeSlidePosition: ActiveSlidePosition;
  animationEndTimeoutId: React.MutableRefObject<number | undefined>;
  autoPlayInterval: number;
  autoPlayState: AutoPlayState | null;
  autoWidth: boolean;
  carouselId: string;
  controller: React.RefObject<CarouselController>;
  dispatch: CarouselDispatcher;
  dragBehavior: DragBehavior;
  edgeFriction: number;
  endBehavior: EndBehavior;
  indicatorsConfig: IndicatorsConfig | null;
  isTransitioning: boolean;
  isHydrated: boolean;
  listSize: number | null;
  orientation: Orientation;
  onActiveSlideChange: CarouselRootProps["onActiveSlideChange"];
  onSlideFocus: () => void;
  onSlideBlur: () => void;
  pauseOnHover: boolean;
  renderedSlideCount: number;
  setIndicatorsConfig: React.Dispatch<
    React.SetStateAction<IndicatorsConfig | null>
  >;
  setListSize: React.Dispatch<React.SetStateAction<number | null>>;
  setSlideCounts: React.Dispatch<
    React.SetStateAction<{
      slideCount: number;
      renderedSlideCount: number;
    }>
  >;
  setTrackElement: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  slideCount: number;
  slideListRef: React.MutableRefObject<HTMLDivElement | null>;
  slidesPerMove: number;
  slidesPerPage: number;
  swipe: boolean;
  swipeThreshold: number;
  targetSlideIndex: number;
  textDirection: TextDirection;
  trackRef: React.MutableRefObject<HTMLDivElement | null>;
  trackElement: HTMLDivElement | null;
  trackStartPosition: number;
  transitionEasing: string;
  transitionSpeed: number;
  transitionStyle: TransitionStyle;
  wheelBehavior: WheelBehavior;
  play: (playType?: AutoPlayPlayEvent) => void;
  pause: (pauseType?: AutoPlayPauseEvent) => void;

  // NOTE (Chance 2024-03-01): Include some derived properties so:
  //   1. we don't need to call the same functions multiple times to determine
  //      behavior, and
  //   2. reduce the number of events fired in some cases where the source
  //      property changes but the derived value does not.
  isFade: boolean;
  isImmediateTransition: boolean;
  isInfinite: boolean;
  shouldAutoPlay: boolean;
};

function useCarouselContext() {
  const context = React.useContext(CarouselContext);
  if (!context) {
    throw new Error(
      "useCarouselContext must be used within a CarouselRoot component",
    );
  }
  return context;
}

// #endregion

// #region --- CarouselRoot

type CarouselRootProps = {
  id?: string;
  activeSlideIndex?: number;
  activeSlidePosition?: ActiveSlidePosition;
  autoWidth?: boolean;
  controller?: React.RefObject<CarouselController>;
  debugEvents?: boolean;
  autoPlayInterval?: number;
  onActiveSlideChange?(nextSlide: number): void;
  children?:
    | React.ReactNode
    | ((context: CarouselContextValue) => React.ReactNode);
  dragBehavior?: DragBehavior;
  edgeFriction?: number;
  endBehavior?: EndBehavior;
  defaultActiveSlideIndex?: number;
  pauseOnFocus?: boolean;
  pauseOnHover?: boolean;
  orientation?: Orientation;
  textDirection?: TextDirection;
  slidesPerMove?: number;
  slidesPerPage?: number;
  transitionSpeed?: number;
  transitionStyle?: TransitionStyle;
  transitionEasing?: string;
  swipe?: boolean;
  swipeThreshold?: number;
  wheelBehavior?: WheelBehavior;
};

const defaultProps = {
  activeSlidePosition: SLIDE_POSITION_START,
  autoPlayInterval: 0,
  autoWidth: false,
  defaultActiveSlideIndex: 0,
  dragBehavior: DRAG_OFF,
  edgeFriction: 0.35,
  endBehavior: END_NORMAL,
  onActiveSlideChange: NOOP,
  orientation: ORIENTATION_HORIZONTAL,
  pauseOnFocus: false,
  pauseOnHover: true,
  slidesPerMove: 1,
  slidesPerPage: 1,
  swipe: false,
  swipeThreshold: 5,
  transitionEasing: "ease",
  transitionSpeed: 500,
  transitionStyle: TRANSITION_SLIDE,
  wheelBehavior: WHEEL_OFF,
} satisfies CarouselRootProps;

type IndicatorsConfig = {
  pauseOnHover: boolean;
  pauseOnFocus: boolean;
};

const CarouselRoot: React.FC<CarouselRootProps> = (props) => {
  const carouselId = useId("replo-carousel", props.id);
  const controllerRef = React.useRef<CarouselController>(null);
  const controller = props.controller || controllerRef;
  const [indicatorsConfig, setIndicatorsConfig] =
    React.useState<IndicatorsConfig | null>(null);

  const slideListRef = React.useRef<HTMLDivElement | null>(null);
  const trackRef = React.useRef<HTMLDivElement | null>(null);
  // NOTE (Chance 2023-11-17): We need to update state in the event that a
  // track element remounts for any reason so that event listeners are removed
  // and attached to the correct node.
  const [trackElement, setTrackElement] = React.useState<HTMLDivElement | null>(
    null,
  );
  const prefersReducedMotion = usePrefersReducedMotion();

  const autoplayTimer = React.useRef<number | undefined>();
  const animationEndTimeoutId = React.useRef<number | undefined>();

  const textDirection = useTextDirection({
    ref: slideListRef,
    controlledValue: props.textDirection,
    defaultValue: DIRECTION_LTR,
  });

  // validate and reset some props
  const { children, debugEvents = false, ...rest } = props;
  const _props = { ...defaultProps, ...rest, textDirection };
  if (_props.wheelBehavior === WHEEL_FREE) {
    warning(
      false,
      `wheelBehavior="${_props.wheelBehavior}" is not yet supported. Please use "${WHEEL_OFF}" or "${WHEEL_MOVE_ONCE}" instead.`,
    );
    _props.wheelBehavior = WHEEL_OFF;
  }
  if (_props.dragBehavior !== DRAG_OFF) {
    warning(
      false,
      `dragBehavior="${_props.dragBehavior}" is not yet supported. Please use "${DRAG_OFF}" instead.`,
    );
    _props.dragBehavior = DRAG_OFF;
  }
  if (_props.swipe) {
    warning(
      false,
      `swipe=true is not yet supported. Please set to false or remove the prop.`,
    );
    _props.swipe = false;
  }

  // force scrolling by one if active slide is centered
  if (_props.activeSlidePosition === SLIDE_POSITION_CENTER) {
    if (_props.slidesPerMove > 1) {
      warning(
        false,
        `slidesPerMove should be equal to 1 in when active slide is centered. You are using ${_props.slidesPerMove}`,
      );
    }
    _props.slidesPerMove = 1;
  }
  // force showing one slide and scrolling by one if the fade mode is on
  if (_props.transitionStyle === TRANSITION_FADE) {
    if (_props.slidesPerPage > 1) {
      warning(
        false,
        `slidesPerPage should be equal to 1 when fade is true, you're using ${_props.slidesPerPage}`,
      );
    }
    if (_props.slidesPerMove > 1) {
      warning(
        false,
        `slidesPerMove should be equal to 1 when fade is true, you're using ${_props.slidesPerMove}`,
      );
    }
    if (_props.autoWidth) {
      warning(false, `autoWidth is not supported with fade transitions.`);
    }
    _props.autoWidth = false;
    _props.slidesPerPage = 1;
    _props.slidesPerMove = 1;
  }

  if (prefersReducedMotion) {
    _props.transitionSpeed = 0;
  }

  // now let's get to work
  const {
    activeSlidePosition,
    autoPlayInterval,
    autoWidth,
    defaultActiveSlideIndex,
    dragBehavior,
    edgeFriction,
    endBehavior,
    onActiveSlideChange,
    orientation,
    pauseOnHover,
    slidesPerMove,
    slidesPerPage,
    swipe,
    swipeThreshold,
    transitionEasing,
    transitionSpeed,
    transitionStyle,
    wheelBehavior,
  } = _props;

  const [{ slideCount, renderedSlideCount }, setSlideCounts] = React.useState({
    slideCount: -1,
    renderedSlideCount: -1,
  });

  const controlledActiveSlideIndex = props.activeSlideIndex;

  const { state, dispatch, emitter } = useCarouselReducer({
    controlledActiveSlideIndex,
    defaultActiveSlideIndex,
    debugEvents,
  });

  const isControlled = controlledActiveSlideIndex !== undefined;

  const {
    activeSlideIndex,
    autoPlayState,
    isTransitioning,
    targetSlideIndex,
    trackStartPosition,
  } = state;

  const [listSize, setListSize] = React.useState<null | number>(null);

  const pause = React.useCallback(
    (pauseType?: AutoPlayPauseEvent) => {
      dispatch({
        type: "AUTO_PLAY",
        playType: pauseType ?? "pause",
        autoPlayInterval,
      });
    },
    [dispatch, autoPlayInterval],
  );

  const play = React.useCallback(
    (playType?: AutoPlayPlayEvent) => {
      dispatch({
        type: "AUTO_PLAY",
        playType: playType ?? "play",
        autoPlayInterval,
      });
    },
    [dispatch, autoPlayInterval],
  );

  const onSlideBlur = React.useCallback(() => {
    play("blur");
  }, [play]);

  const onSlideFocus = React.useCallback(() => {
    pause("focus");
  }, [pause]);

  const isInfinite = endBehavior === END_LOOP;
  const isFade = transitionStyle === TRANSITION_FADE;
  const isImmediateTransition = transitionStyle === TRANSITION_OFF;
  const shouldAutoPlay = autoPlayInterval != null && autoPlayInterval > 0;
  const isHydrated = useIsHydrated();

  const context = {
    activeSlideIndex,
    activeSlidePosition,
    animationEndTimeoutId,
    autoPlayInterval,
    autoPlayState,
    autoWidth,
    carouselId,
    controller,
    dispatch,
    dragBehavior,
    edgeFriction,
    endBehavior,
    indicatorsConfig,
    isFade,
    isHydrated,
    isImmediateTransition,
    isInfinite,
    isTransitioning,
    listSize,
    onActiveSlideChange,
    onSlideBlur: onSlideBlur,
    onSlideFocus: onSlideFocus,
    orientation,
    pause,
    pauseOnHover,
    play,
    renderedSlideCount,
    setIndicatorsConfig,
    setListSize,
    setSlideCounts,
    setTrackElement,
    shouldAutoPlay,
    slideCount,
    slideListRef,
    slidesPerMove,
    slidesPerPage,
    swipe,
    swipeThreshold,
    targetSlideIndex,
    textDirection,
    trackElement,
    trackRef,
    trackStartPosition,
    transitionEasing,
    transitionSpeed,
    transitionStyle,
    wheelBehavior,
  } satisfies CarouselContextValue;

  const changeSlide = useEffectEvent(
    (
      options: NavButtonChangeOpts | PageChangeOpts | SlideIndexChangeOpts,
      opts?: GoToOptions,
    ) => {
      const previousTargetSlide = targetSlideIndex;

      let nextTargetSlideIndex: number;
      const unevenOffset = slideCount % slidesPerMove !== 0;
      const indexOffset = unevenOffset
        ? 0
        : (slideCount - activeSlideIndex) % slidesPerMove;

      switch (options.type) {
        case "slide-index": {
          nextTargetSlideIndex = options.index;
          break;
        }
        case "previous": {
          if (!isInfinite) {
            nextTargetSlideIndex = previousTargetSlide - slidesPerMove;
            break;
          }
          const slideOffset =
            indexOffset === 0 ? slidesPerMove : slidesPerPage - indexOffset;
          nextTargetSlideIndex = activeSlideIndex - slideOffset;
          break;
        }
        case "next": {
          if (!isInfinite) {
            nextTargetSlideIndex = previousTargetSlide + slidesPerMove;
          } else {
            const slideOffset = indexOffset === 0 ? slidesPerMove : indexOffset;
            nextTargetSlideIndex = activeSlideIndex + slideOffset;
          }
          break;
        }
        case "page": {
          nextTargetSlideIndex = options.index * slidesPerMove;
          break;
        }
        default:
          throw new Error("Unexpected event type");
      }

      if (nextTargetSlideIndex == null) {
        return;
      }

      dispatch({
        type: "GO_TO_SLIDE",
        activeSlidePosition,
        autoWidth,
        carouselId,
        endBehavior,
        index: nextTargetSlideIndex,
        listSize,
        onActiveSlideChange,
        opts,
        orientation,
        slideCount,
        slidesPerMove,
        slidesPerPage,
        trackRef,
        transitionEasing,
        transitionSpeed,
        isFade,
        isImmediateTransition,
        isHydrated,
      });
    },
  );

  // NOTE (Chance 2024-02-16): Confusion ahead! So letting the consuming
  // component manage the active index is a bit of a mess because we need to
  // keep our own active index in the reducer state to manage transitions. To
  // make this work, the update flow looks a little different for controlled and
  // uncontrolled carousels.
  //
  // - For uncontrolled carousels:
  //   1. User interacts
  //   2. Call internal slide change function
  //   3. If transition is allowed, reducer emits `START_SLIDE_TRANSITION`,
  //      which kicks off the state transition
  //   4. Call `onActiveSlideChange` prop in response to the change
  //
  // - For controlled carousels:
  //   1. User interacts
  //   2. Call internal slide change function
  //   3. If transition is allowed, call `onActiveSlideChange` prop with the
  //      next slide index. We trigger this via a reducer event which sends us
  //      the calculated transition states we'll use after the consumer's state
  //      has updated. Because we'll need this context later, we store it in a
  //      ref so that our event handler can access it.
  //   4. Consumer updates its active slide index
  //   5. `useOnValueChange` reacts to the change. If the update occurred as a
  //      result of the previous chain of events, it triggers the previously
  //      calculated transition. If the value changes for any other reason
  //      (outside updates like a synchronized carousel), we calculate the
  //      transition states based on the current active slide and start the
  //      transition from there.
  const controlledStateTransitionResults =
    React.useRef<CarouselTransitionStates>();

  useSubscribeToEmitterEvent(
    emitter,
    "CALL_CONTROLLED_STATE_UPDATER",
    ({ changeResults }) => {
      if (isControlled) {
        const nextActiveSlideIndex = changeResults.state.activeSlideIndex;
        controlledStateTransitionResults.current = changeResults;
        onActiveSlideChange(nextActiveSlideIndex);
        return;
      }
    },
  );

  useOnValueChange(
    controlledActiveSlideIndex,
    (activeSlideIndex, previousActiveSlideIndex) => {
      if (activeSlideIndex === undefined) {
        return;
      }

      if (controlledStateTransitionResults.current) {
        const changeResults = controlledStateTransitionResults.current;
        emitter.emit("START_SLIDE_TRANSITION", {
          changeResults,
        });
        controlledStateTransitionResults.current = undefined;
      } else {
        let nextIndex = activeSlideIndex;
        // NOTE (Chance 2024-02-16): In infinite mode, even if the consumer is
        // controlling the index we want the transitions to fire in the
        // direction they are moving. The index used to calculate the transition
        // states can be negative if going back, or beyond the slide count if
        // going forward. `getSlideTransitionStates` will work out the final
        // index. This ensures that synchronized slides elements move in the
        // same direction as the user navigates.
        if (isInfinite) {
          if (
            previousActiveSlideIndex === 0 &&
            activeSlideIndex === slideCount - 1
          ) {
            nextIndex = -1;
          } else if (
            previousActiveSlideIndex === slideCount - 1 &&
            activeSlideIndex === 0
          ) {
            nextIndex = slideCount;
          }
        }

        const transitionStates = getSlideTransitionStates(nextIndex, context);
        if (transitionStates) {
          emitter.emit("START_SLIDE_TRANSITION", {
            changeResults: transitionStates,
          });
        }
      }
    },
  );

  useSubscribeToEmitterEvent(
    emitter,
    "START_SLIDE_TRANSITION",
    ({ changeResults }) => {
      const { state: _state, nextState } = changeResults;
      if (!isControlled) {
        onActiveSlideChange(_state.activeSlideIndex);
      }

      // NOTE (Chance 2023-11-17): If we're currently transitioning, clear the
      // timer set from the previous update to avoid clearing our animation
      // state before all transitions are complete.
      if (animationEndTimeoutId.current) {
        window.clearTimeout(animationEndTimeoutId.current);
        animationEndTimeoutId.current = undefined;
      }

      // NOTE (Chance 2023-11-16): If there's no next state, this is an
      // immediate transition. We don't need to queue any post-transition
      // steps. Update the state and bail.
      if (!nextState) {
        dispatch({
          type: "SET_IMMEDIATE_TRANSITION_STATE",
          state: _state,
          autoPlayInterval,
        });
      } else {
        dispatch({
          type: "SET_TRANSITION_START_STATE",
          state: _state,
          autoPlayInterval,
        });
        animationEndTimeoutId.current = window.setTimeout(() => {
          dispatch({
            // TODO (Chance 2023-11-16): We might not need to defer updating
            // the animation state. Test this.
            type: "SET_TRANSITION_STOP_STATE",
            state: nextState,
          });
          window.clearTimeout(animationEndTimeoutId.current);
        }, transitionSpeed);
      }
    },
  );

  React.useEffect(() => {
    if (activeSlideIndex === -1) {
      dispatch({
        type: "INIT_ACTIVE_SLIDE",
        defaultActiveSlideIndex,
        slideCount,
        textDirection,
      });
    }
  }, [
    dispatch,
    slideCount,
    textDirection,
    activeSlideIndex,
    defaultActiveSlideIndex,
  ]);

  const goBack = React.useCallback(
    (opts?: GoToOptions) => {
      changeSlide({ type: MOVE_DIRECTION_PREVIOUS }, opts);
    },
    [changeSlide],
  );

  const goNext = React.useCallback(
    (opts?: GoToOptions) => {
      changeSlide({ type: MOVE_DIRECTION_NEXT }, opts);
    },
    [changeSlide],
  );

  const goToSlide = React.useCallback(
    (slide: number, opts?: GoToOptions) => {
      changeSlide({ type: "slide-index", index: slide }, opts);
    },
    [changeSlide],
  );

  const goToPage = React.useCallback(
    (index: number, opts?: GoToOptions) => {
      changeSlide({ type: "page", index }, opts);
    },
    [changeSlide],
  );

  const _canGoBack = useEffectEvent(() => {
    return canGoBack(context);
  });

  const _canGoNext = useEffectEvent(() => {
    return canGoNext(context);
  });

  const _controller: CarouselController = React.useMemo(() => {
    return {
      goBack,
      goNext,
      goToSlide,
      goToPage,
      pause,
      play,
      canGoBack: _canGoBack,
      canGoNext: _canGoNext,
    };
  }, [
    goBack,
    goNext,
    goToSlide,
    goToPage,
    pause,
    play,
    _canGoBack,
    _canGoNext,
  ]);

  React.useEffect(() => {
    dispatch({ type: "INIT_AUTO_PLAY", autoPlayInterval });
  }, [autoPlayInterval, dispatch]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: dispatch is an extra dependency
  React.useEffect(() => {
    emitter.on("AUTO_PLAY_GO", () => {
      window.clearInterval(autoplayTimer.current);
      if (autoPlayInterval > 0) {
        autoplayTimer.current = window.setInterval(
          goNext,
          autoPlayInterval + 50,
        );
      }
    });
    emitter.on("AUTO_PLAY_STOPPED", () => {
      window.clearInterval(autoplayTimer.current);
    });
    return () => {
      emitter.off("AUTO_PLAY_GO");
      emitter.off("AUTO_PLAY_STOPPED");
      const id = autoplayTimer.current;
      autoplayTimer.current = undefined;
      window.clearInterval(id);
    };
  }, [emitter, goNext, autoPlayInterval, dispatch]);

  React.useEffect(() => {
    // NOTE (Chance 2023-11-17): We need to clear any lingering timers if the
    // component unmounts
    return () => {
      const to = animationEndTimeoutId.current;
      animationEndTimeoutId.current = undefined;
      window.clearTimeout(to);
    };
  }, []);

  React.useImperativeHandle(controller, () => _controller, [_controller]);

  return (
    <CarouselContext.Provider value={context}>
      {isFunction(children) ? children(context) : children}
    </CarouselContext.Provider>
  );
};

CarouselRoot.displayName = "CarouselRoot";

// #endregion

// #region --- CarouselSlides

type CarouselSlidesAccessibilityProps =
  | { "aria-label": string; "aria-labelledby"?: never }
  | { "aria-labelledby": string; "aria-label"?: never };

type CarouselSlidesOwnProps = {
  /**
   * NOTE (Chance 2023-12-13): This is not intended to be configurable for use
   * outside of the editor. In the editor we need to disable keyboard navigation
   * so that the arrow keys can be used while editing in text fields.
   */
  disableKeyboardNavigation?:
    | boolean
    | ((event: React.KeyboardEvent<HTMLDivElement>) => any);
};

type CarouselSlidesProps = Omit<
  React.ComponentPropsWithRef<"div">,
  keyof CarouselSlidesOwnProps | keyof CarouselSlidesAccessibilityProps | "id"
> &
  CarouselSlidesOwnProps &
  CarouselSlidesAccessibilityProps;

const CarouselSlides = React.forwardRef<HTMLDivElement, CarouselSlidesProps>(
  (
    {
      "aria-label": ariaLabel,
      "aria-labelledby": ariaLabelledBy,
      children,
      onKeyDown,
      disableKeyboardNavigation,
      ...props
    },
    forwardedRef,
  ) => {
    const context = useCarouselContext();
    const {
      activeSlidePosition,
      carouselId,
      controller,
      dragBehavior,
      isHydrated,
      orientation,
      slideListRef,
      textDirection,
      setListSize,
    } = context;

    const _canGoBack = canGoBack(context);
    const _canGoNext = canGoNext(context);
    const handleKeyDown = useComposedEventHandlers(
      onKeyDown,
      React.useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
          if (
            isFunction(disableKeyboardNavigation) &&
            disableKeyboardNavigation(event)
          ) {
            return;
          } else if (disableKeyboardNavigation) {
            return;
          }

          // Ignore keyboard events fired while in a from control
          if (
            (event.target as HTMLElement).tagName?.match(
              "TEXTAREA|INPUT|SELECT|DATALIST|FIELDSET",
            )
          ) {
            return;
          }

          switch (event.key) {
            case KEY_ARROW_LEFT:
            case KEY_ARROW_RIGHT:
            case KEY_ARROW_UP:
            case KEY_ARROW_DOWN: {
              // TODO: Support top-to-bottom (and reverse) text orientation for
              // vertical slides
              if (
                event.key === KEY_ARROW_LEFT ||
                event.key === KEY_ARROW_RIGHT
              ) {
                if (orientation === ORIENTATION_VERTICAL) {
                  return;
                }
              } else {
                if (orientation === ORIENTATION_HORIZONTAL) {
                  return;
                }
              }

              event.preventDefault();
              const direction = (() => {
                if (textDirection === DIRECTION_RTL) {
                  return event.key === KEY_ARROW_LEFT ||
                    event.key === KEY_ARROW_UP
                    ? MOVE_DIRECTION_NEXT
                    : MOVE_DIRECTION_PREVIOUS;
                }
                return event.key === KEY_ARROW_LEFT ||
                  event.key === KEY_ARROW_UP
                  ? MOVE_DIRECTION_PREVIOUS
                  : MOVE_DIRECTION_NEXT;
              })();

              if (direction === MOVE_DIRECTION_NEXT && _canGoNext) {
                controller.current?.goNext();
              }
              if (direction === MOVE_DIRECTION_PREVIOUS && _canGoBack) {
                controller.current?.goBack();
              }

              return;
            }

            // TODO (Chance 2023-12-03, REPL-9596): Some controls cause the
            // slide to jump even if we're already on the correct slide. Need to
            // fix.
            case KEY_HOME:
              event.preventDefault();
              controller.current?.goToSlide(0);
              break;
            case KEY_END:
              event.preventDefault();
              controller.current?.goToSlide(-1);
              return;

            case KEY_PAGE_UP:
              if (event.ctrlKey || event.metaKey) {
                event.preventDefault();
                controller.current?.goToSlide(0);
              }
              break;

            case KEY_PAGE_DOWN:
              if (event.ctrlKey || event.metaKey) {
                event.preventDefault();
                controller.current?.goToSlide(-1);
              }
              break;

            default:
              return;
          }
        },
        [
          controller,
          _canGoNext,
          _canGoBack,
          orientation,
          textDirection,
          disableKeyboardNavigation,
        ],
      ),
    );

    const ref = useComposedRefs(forwardedRef, slideListRef);

    const isVertical = orientation === ORIENTATION_VERTICAL;
    React.useEffect(() => {
      const list = slideListRef.current;
      if (!list) {
        return;
      }
      const ro = new ResizeObserver(() => {
        const size = isVertical
          ? getElementHeight(list)
          : getElementWidth(list);
        // TODO (Chance 2023-11-16): Looks pretty weird if an animation is in
        // effect while resize is happening. Maybe we should clear the
        // transition while resizing? Adds some complexity so we might come back
        // to this later.
        setListSize(size);
      });
      ro.observe(list);
      return () => {
        ro.disconnect();
      };
    }, [setListSize, slideListRef, isVertical]);

    // TODO (Chance 2023-11-16): Validate this and use a similar approach for sub-components
    const propsWhenInteractive: React.HTMLProps<HTMLDivElement> = isHydrated
      ? { tabIndex: -1 }
      : {};

    return (
      <div
        ref={ref}
        data-replo-component-root="carousel"
        data-replo-part="slides"
        data-replo-root-id={carouselId}
        data-orientation={orientation}
        data-draggable={dragBehavior !== DRAG_OFF || undefined}
        data-active-slide-position={activeSlidePosition}
        onKeyDown={handleKeyDown}
        // NOTE: There are some contexts in which a div with a `group` role is
        // more appropriate (in cases where the carousel is not related to the
        // main content of the page, such as in a sidebar, header or footer).
        role="region"
        data-is-hydrated={isHydrated || undefined}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        dir={textDirection}
        {...propsWhenInteractive}
        {...props}
        // NOTE (Chance 2023-10-12): Ensure our internal ID takes precedence
        // over props in case an ID is passed. This is important for
        // accessibility.
        id={getTrackDOMId(carouselId)}
      >
        {children}
      </div>
    );
  },
);
CarouselSlides.displayName = "Carousel.Slides";

// #endregion

// #region --- CarouselSlideTrack

type CarouselSlideTrackContextValue = {
  slideGap: number | string | undefined;
  slideSizes: SlideSizeMap;
  setSlideSizes: React.Dispatch<React.SetStateAction<SlideSizeMap>>;
};

const CarouselSlideTrackContext =
  React.createContext<CarouselSlideTrackContextValue | null>(null);
CarouselSlideTrackContext.displayName = "CarouselSlideTrackContext";

function useCarouselSlideTrackContext() {
  const context = React.useContext(CarouselSlideTrackContext);
  if (!context) {
    throw new Error(
      "useCarouselSlideTrackContext must be used within a CarouselSlideTrack component",
    );
  }
  return context;
}

function collectSlidesFromChildren(
  children: React.ReactNode,
  refs?: [
    Before: React.ReactNode[],
    Slides: React.ReactElement[],
    After: React.ReactNode[],
    phase: "before" | "slides" | "after",
  ],
): [
  Before: React.ReactNode[],
  Slides: React.ReactElement[],
  After: React.ReactNode[],
  phase: "before" | "slides" | "after",
] {
  const childrenArray = React.Children.toArray(children);
  // NOTE (Chance 2024-01-26): The idea here is that we need to collect all
  // child React elements as slides because we don't just render the children,
  // we need to loop through and potentially render multiple versions of the
  // same child (if infinite slides are enabled). But we also may have some
  // non-element children before and after the slides that we need to render as
  // well. The reason we need to support this is because liquid template strings
  // need to be rendered inside the carousel track before and after the slide
  // component for PDP support.
  let beforeSlides: React.ReactNode[] = refs?.[0] ?? [];
  let slideElements: React.ReactElement[] = refs?.[1] ?? [];
  let afterSlides: React.ReactNode[] = refs?.[2] ?? [];
  let phase = refs?.[3] ?? "before";

  for (const child of childrenArray) {
    // NOTE (Chance 2024-06-26): If the child is a React element but not a
    // slide, it may have children that are slides. Recursively look for slides
    // through the element's children.
    if (React.isValidElement(child) && !isValidSlideElement(child)) {
      if (child.props.children) {
        [beforeSlides, slideElements, afterSlides, phase] =
          collectSlidesFromChildren(child.props.children, [
            beforeSlides,
            slideElements,
            afterSlides,
            phase,
          ]);
      } else if (phase === "before") {
        beforeSlides.push(child);
      } else if (phase === "after") {
        afterSlides.push(child);
      }
      continue;
    }

    if (phase === "before") {
      if (isValidSlideElement(child)) {
        phase = "slides";
        slideElements.push(child);
      } else if (isValidNonSlideChild(child)) {
        beforeSlides.push(child);
      }
    } else if (phase === "slides") {
      if (isValidSlideElement(child)) {
        slideElements.push(child);
      } else if (isValidNonSlideChild(child)) {
        phase = "after";
        afterSlides.push(child);
      }
    } else {
      if (isValidNonSlideChild(child)) {
        afterSlides.push(child);
      }
    }
  }
  return [beforeSlides, slideElements, afterSlides, phase];
}

type CarouselSlideTrackOwnProps = {
  children: React.ReactNode;
  slideGap?: number | string;
};

type CarouselSlideTrackProps = Omit<
  React.ComponentPropsWithRef<"div">,
  keyof CarouselSlideTrackOwnProps
> &
  CarouselSlideTrackOwnProps;

const CarouselSlideTrack = React.forwardRef<
  HTMLDivElement,
  CarouselSlideTrackProps
>((props, forwardedRef) => {
  const {
    children,
    onMouseOver,
    onMouseEnter,
    onMouseLeave,
    style,
    slideGap,
    ...domProps
  } = props;
  const context = useCarouselContext();
  const {
    activeSlidePosition,
    carouselId,
    controller,
    dragBehavior,
    orientation,
    pauseOnHover,
    trackRef,
    setSlideCounts,
    setTrackElement,
    isTransitioning,
    transitionSpeed,
    wheelBehavior,
    play,
    pause,
  } = context;

  const [beforeSlides, slideElements, afterSlides] =
    collectSlidesFromChildren(children);

  const slideCount = slideElements.length;
  const beforeCloneCount = getBeforeClones(context);
  const afterCloneCount = getAfterClones(context);
  const slides = renderSlides(slideElements, context);
  React.useEffect(() => {
    setSlideCounts({
      slideCount,
      renderedSlideCount: beforeCloneCount + slideCount + afterCloneCount,
    });
  }, [slideCount, setSlideCounts, beforeCloneCount, afterCloneCount]);

  const handleTrackOver = React.useCallback(() => {
    if (!pauseOnHover) {
      return;
    }
    pause("hover");
  }, [pauseOnHover, pause]);

  const handleMouseEnter = useComposedEventHandlers(
    onMouseEnter,
    handleTrackOver,
  );

  const handleMouseOver = useComposedEventHandlers(
    onMouseOver,
    handleTrackOver,
  );

  const handleMouseLeave = useComposedEventHandlers(
    onMouseLeave,
    React.useCallback(() => {
      if (!pauseOnHover) {
        return;
      }
      play("leave");
    }, [pauseOnHover, play]),
  );

  const onRefMount = React.useCallback(
    (element: HTMLDivElement | null) => {
      setTrackElement(element);
      trackRef.current = element;
    },
    [setTrackElement, trackRef],
  );

  const ref = useComposedRefs(forwardedRef, onRefMount);

  const [slideSizes, setSlideSizes] = React.useState<SlideSizeMap>(new Map());
  const trackContext = {
    slideGap,
    setSlideSizes,
    slideSizes,
  } satisfies CarouselSlideTrackContextValue;

  useSyncTrackStart(context, trackContext);
  useTrackWheelEvents(trackRef, {
    controller,
    orientation,
    transitionSpeed,
    wheelBehavior,
  });

  const isDraggable = dragBehavior !== DRAG_OFF;

  const startPosition = context.trackStartPosition;

  // TODO (Chance 2023-12-03): Handle resizing. We want don't want to animate
  // the track if the user is currently resizing the container as it will appear
  // janky.
  const isResizing: boolean = false;

  const trackStyle =
    isTransitioning && !isResizing
      ? getTrackAnimatingStyles(startPosition, context)
      : getTrackStaticStyles(startPosition, context);

  return (
    <div
      ref={ref}
      data-replo-component-root="carousel"
      data-replo-part="slide-track"
      data-replo-root-id={carouselId}
      data-draggable={isDraggable || undefined}
      data-orientation={orientation}
      data-active-slide-position={activeSlidePosition}
      onMouseEnter={handleMouseEnter}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      {...domProps}
      style={{
        // @ts-expect-error
        "--replo-carousel-slides-per-page": context.slidesPerPage,
        ...style,
        ...trackStyle,
      }}
    >
      <CarouselSlideTrackContext.Provider value={trackContext}>
        {beforeSlides}
        {slides}
        {afterSlides}
      </CarouselSlideTrackContext.Provider>
    </div>
  );
});
CarouselSlideTrack.displayName = "Carousel.SlideTrack";

type SlideReactElement = React.ReactElement<
  CarouselSlideProviderProps,
  typeof CarouselSlideProvider
>;

// TODO (Chance 2024-01-16): Add comments to explain implementation
function renderSlides(
  children: React.ReactElement[],
  context: CarouselContextValue,
): SlideReactElement[] {
  const slideCount = children.length;
  if (slideCount === 0) {
    return [];
  }

  let slides: React.ReactElement<
    CarouselSlideProviderProps,
    typeof CarouselSlideProvider
  >[] = [];
  for (let index = 0; index < slideCount; index++) {
    const child = children[index]!;
    slides.push(
      <CarouselSlideProvider
        key={getKey(child, index)}
        index={index}
        sourceIndex={index}
      >
        {child}
      </CarouselSlideProvider>,
    );
  }

  const beforeCloneCount = getBeforeClones(context);
  const afterCloneCount = getAfterClones(context);
  const clonesBefore: SlideReactElement[] = [];
  const clonesAfter: SlideReactElement[] = [];

  if (beforeCloneCount > 0) {
    let trackingIndex = slideCount - 1;
    let position = -1;
    while (clonesBefore.length < beforeCloneCount) {
      const child = children[trackingIndex]!;
      clonesBefore.unshift(
        <CarouselSlideProvider
          key={`clone_before:${getKey(child, position)}`}
          index={position}
          sourceIndex={trackingIndex}
        >
          {child}
        </CarouselSlideProvider>,
      );

      trackingIndex = trackingIndex === 0 ? slideCount - 1 : trackingIndex - 1;
      position--;
    }
  }

  if (afterCloneCount > 0) {
    let trackingIndex = 0;
    let position = slideCount;
    while (clonesAfter.length < getAfterClones(context)) {
      const child = children[trackingIndex]!;
      clonesAfter.push(
        <CarouselSlideProvider
          key={`clone_after:${getKey(child, position)}`}
          index={position}
          sourceIndex={trackingIndex}
        >
          {child}
        </CarouselSlideProvider>,
      );

      trackingIndex = trackingIndex === slideCount - 1 ? 0 : trackingIndex + 1;
      position++;
    }
  }

  slides = clonesBefore.concat(slides, clonesAfter);
  if (context.textDirection === DIRECTION_RTL) {
    slides.reverse();
  }

  return slides;
}

function getKey(_child: React.ReactElement, fallbackKey?: string | number) {
  // NOTE (Chance 2024-02-14): This is not ideal because if the consumer passes
  // the index, which they expect to be unique, we'll end up with duplicate keys
  // since items are cloned. Using fallback only for now, though that also isn't
  // ideal since it's just the internal index.
  //   return child.key ?? fallbackKey;
  return fallbackKey;
}

// #endregion

// #region --- CarouselSlideProvider

const CarouselSlideContext =
  React.createContext<CarouselSlideContextValue | null>(null);
CarouselSlideContext.displayName = "CarouselSlideContext";

function useCarouselSlideContext() {
  const context = React.useContext(CarouselSlideContext);
  if (!context) {
    throw new Error(
      "useCarouselSlideContext must be used within a CarouselSlideProvider component",
    );
  }
  return context;
}

type CarouselSlideContextValue = {
  id: string;
  /**
   * NOTE (Chance 2023-11-03): In loop mode, the `index` is the index of the
   * slide that is rendered in context of the full slide list (including
   * clones). When not in loop mode it is the same as `sourceIndex`.
   */
  index: number;
  /**
   * NOTE (Chance 2023-11-03): In loop mode, the `sourceIndex` is the index of
   * slide in context of the slides passed into the track by the consumer. That
   * means that cloned slides will have the same `sourceIndex` as the slide they
   * were cloned from. When not in loop mode it is the same as `index`.
   */
  sourceIndex: number;
  isInActivePage: boolean;
  isActive: boolean;
  isCloned: boolean;
  isTarget: boolean;
};

type CarouselSlideProviderProps = {
  id?: string;
  children: React.ReactNode;
  index: number;
  sourceIndex: number;
};

const CarouselSlideProvider: React.FC<CarouselSlideProviderProps> = ({
  children,
  sourceIndex,
  index,
}) => {
  const context = useCarouselContext();
  const { activeSlideIndex } = context;
  const isActive = index === activeSlideIndex;
  const { isInActivePage, isCloned, isTarget } = getSlideData(
    sourceIndex,
    context,
  );
  const id = makeId(
    context.carouselId,
    "slide",
    sourceIndex,
    isCloned ? index : null,
  );
  return (
    <CarouselSlideContext.Provider
      value={{
        id,
        index,
        isActive,
        isCloned,
        isInActivePage,
        isTarget,
        sourceIndex,
      }}
    >
      {children}
    </CarouselSlideContext.Provider>
  );
};
CarouselSlideProvider.displayName = "Carousel.SlideProvider";

export function getSlideData(
  index: number,
  context: Pick<
    CarouselContextValue,
    | "activeSlideIndex"
    | "textDirection"
    | "slideCount"
    | "activeSlidePosition"
    | "slidesPerPage"
    | "targetSlideIndex"
  >,
) {
  // TODO (Chance 2024-02-28): The `isInActivePage` seems to be wrong in certain
  // cases. It might be better to use an intersection observer and just check if
  // the slide is in view. Just not using it at the moment since it's causing
  // problems for a few customers, but we'll need to revisit during the a11y
  // audit so we can prevent those slides from receiving focus or being read by
  // assistive tech.
  let isInActivePage = false;
  const _index =
    context.textDirection === DIRECTION_RTL
      ? context.slideCount - 1 - index
      : index;
  let centerOffset: number;

  const isCloned = _index < 0 || _index >= context.slideCount;
  if (context.activeSlidePosition === SLIDE_POSITION_CENTER) {
    centerOffset = Math.floor(context.slidesPerPage / 2);
    if (
      _index > context.activeSlideIndex - centerOffset - 1 &&
      _index <= context.activeSlideIndex + centerOffset
    ) {
      isInActivePage = true;
    }
  } else {
    isInActivePage =
      context.activeSlideIndex <= _index &&
      _index < context.activeSlideIndex + context.slidesPerPage;
  }

  let targetSlideIndex;
  // NOTE (Chance 2023-11-02): If the target slide index is out of bounds, that
  // means the carousel is in loop mode and we need to point the target slide
  // index to its actual index, which is offset by the slide count
  if (context.targetSlideIndex < 0) {
    targetSlideIndex = context.targetSlideIndex + context.slideCount;
  } else if (context.targetSlideIndex >= context.slideCount) {
    targetSlideIndex = context.targetSlideIndex - context.slideCount;
  } else {
    targetSlideIndex = context.targetSlideIndex;
  }

  const isTarget = _index === targetSlideIndex;
  return {
    isInActivePage,
    isCloned,
    isTarget,
  };
}

export function getSlideStyle(
  index: number,
  context: Pick<
    CarouselContextValue,
    | "activeSlideIndex"
    | "transitionEasing"
    | "transitionStyle"
    | "transitionSpeed"
    | "orientation"
    | "isFade"
    | "isImmediateTransition"
    | "slideCount"
  > & {
    slideSize: number;
    slideGap: number | string | undefined;
  },
) {
  const style: React.CSSProperties = {};
  const isVertical = context.orientation === ORIENTATION_VERTICAL;
  const isActiveSlide = context.activeSlideIndex === index;
  const { slideSize, slideGap, isFade } = context;
  if (isFade) {
    style.position = "relative";
    if (slideSize > 0) {
      const side = isVertical ? "top" : "left";
      if (index === 0) {
        style[side] = 0;
      } else if (slideGap == null || slideGap === 0 || slideGap === "0") {
        style[side] = -index * context.slideSize;
      } else {
        const gap = isNumber(slideGap) ? `${slideGap}px` : slideGap;
        style[side] = `calc(${-index} * (${parseInt(slideSize)}px + ${gap}))`;
      }
      // NOTE (Chance 2024-03-07): Since slides are stacked on top of each
      // other, we need to ensure that the active slide is on top and the do not
      // receive pointer events when not active.
      if (isActiveSlide) {
        style.zIndex = 0;
      } else {
        style.zIndex = -1;
        style.pointerEvents = "none";
      }
    }
    style.zIndex = -1;
    style.opacity = isActiveSlide ? 1 : 0;
    style.transitionDelay = getTrackTransitionDelay(context);
    style.transitionDuration = getTrackTransitionDuration(context);
    style.transitionProperty = getTrackTransitionProperty(context);
    style.transitionTimingFunction = getTrackTransitionTimingFunction(context);
  }

  return style;
}

// #endregion

// #region --- CarouselSlide

type CarouselSlideOwnProps = {};

type CarouselSlideProps = Omit<
  React.ComponentPropsWithRef<"div">,
  keyof CarouselSlideOwnProps
> &
  CarouselSlideOwnProps;

const CarouselSlide = React.forwardRef<HTMLDivElement, CarouselSlideProps>(
  (
    { children, style: styleProp, onBlur, onFocus, id: idProp, ...props },
    forwardedRef,
  ) => {
    const context = useCarouselContext();
    const { carouselId, slideListRef } = context;
    const slideContext = useCarouselSlideContext();
    const {
      index,
      isActive,
      isCloned,
      isTarget,
      sourceIndex,
      id: slideId,
    } = slideContext;
    const trackContext = useCarouselSlideTrackContext();
    const { slideGap } = trackContext;

    const slideRef = React.useRef<HTMLDivElement | null>(null);
    const [slideElement, setSlideElement] = useStatefulElement(slideRef);
    const ref = useComposedRefs(forwardedRef, setSlideElement);

    const isVertical = context.orientation === ORIENTATION_VERTICAL;

    const rect = useRect(slideElement, { observe: "size" });
    const slideSize = (isVertical ? rect?.height : rect?.width) ?? -1;
    const style: React.CSSProperties = {
      ...styleProp,
      ...getSlideStyle(index, { ...context, slideSize, slideGap }),
    };

    const { onBlur: handleBlur, onFocus: handleFocus } =
      useSlideFocusManagement(slideElement, { onBlur, onFocus });

    // NOTE (Chance 2023-11-03): If a slide has a direct child that is an image,
    // we want to focus its container when the image is clicked. Otherwise
    // clicking the image will not trigger dragging to navigate.
    React.useEffect(() => {
      const listElement = slideListRef.current;
      if (!listElement) {
        return;
      }
      const images = querySelectorAll<HTMLImageElement>(
        carouselId,
        listElement,
        "[data-replo-part='slide'] > img",
      );

      const imageHandlers = new Map<
        HTMLImageElement,
        HTMLImageElement["onclick"]
      >();
      for (const image of images) {
        const _onclick = image.onclick;
        imageHandlers.set(image, _onclick);
        image.addEventListener("click", (event) => {
          _onclick?.call(image, event);
          if (
            !event.defaultPrevented &&
            event.button === 0 &&
            image.parentNode &&
            image.parentNode instanceof HTMLElement
          ) {
            image.parentNode.focus();
          }
        });
      }
      return () => {
        for (const [image, onclick] of imageHandlers.entries()) {
          // @ts-expect-error
          image.addEventListener("click", onclick);
        }
      };
    }, [carouselId, slideListRef]);

    const uniqueSlideId = useId(slideId, idProp);
    const domId = isCloned
      ? makeId(uniqueSlideId, "clone", index)
      : uniqueSlideId;

    useSetSlideSizes(rect, trackContext, slideContext);

    return (
      <div
        ref={ref}
        data-replo-component-root="carousel"
        data-replo-part="slide"
        data-replo-root-id={carouselId}
        data-slide-index={index}
        data-slide-source-index={sourceIndex}
        data-is-active={isActive || undefined}
        data-is-cloned={isCloned || undefined}
        data-is-target={isTarget || undefined}
        data-slide-id={slideId}
        id={domId}
        aria-hidden={isCloned || undefined}
        tabIndex={-1}
        // NOTE (Chance 2024-03-07): As fade transitions result in slides that
        // are stacked on top of one another, we always want the inactive slides
        // to be inert to prevent them from receiving focus.
        // @ts-expect-error
        inert={context.isFade && !isActive ? "true" : undefined}
        // TODO: An exception for role="group" is that if this carousel controls
        // another element, the slide itself should be focusable and this
        // element should be a `button` (or take on button roles, behaviors,
        // etc.)
        role="group"
        // TODO: If this carousel controls another carousel, this should be the
        // ID of that carousel.
        aria-controls={undefined}
        // TODO: If this is a button, the label should be for the thing it
        // controls (could be tricky!)
        aria-label={`Slide ${index + 1}`}
        style={style}
        onBlur={handleBlur}
        onFocus={handleFocus}
        {...props}
      >
        {children}
      </div>
    );
  },
);
CarouselSlide.displayName = "Carousel.Slide";

// #endregion

// #region --- CarouselNavButton

type CarouselNavButtonOwnProps = {
  direction: MoveDirection;
};

type CarouselNavButtonProps = Omit<
  React.ComponentPropsWithRef<"button">,
  keyof CarouselNavButtonOwnProps
> &
  CarouselNavButtonOwnProps;

const CarouselNavButton = React.forwardRef<
  HTMLButtonElement,
  CarouselNavButtonProps
>(
  (
    { children, direction, onClick, disabled: disabledProp = false, ...props },
    forwardedRef,
  ) => {
    const context = useCarouselContext();
    const { controller, carouselId, slideListRef } = context;
    const buttonRef = React.useRef<HTMLButtonElement | null>(null);
    const ref = useComposedRefs(forwardedRef, buttonRef);

    let isDisabled = false;
    if (disabledProp) {
      isDisabled = true;
    } else if (direction === MOVE_DIRECTION_PREVIOUS) {
      isDisabled = !canGoBack(context);
    } else if (direction === MOVE_DIRECTION_NEXT) {
      isDisabled = !canGoNext(context);
    }

    React.useEffect(() => {
      if (isDisabled) {
        return;
      }

      const buttonElement = buttonRef.current;
      window.addEventListener("focusout", handleFocusOut);
      return () => {
        window.removeEventListener("focusout", handleFocusOut);
      };

      function handleFocusOut(event: FocusEvent) {
        const target = event.target as HTMLButtonElement | null;
        const listElement = slideListRef.current;
        if (!listElement || !target || target !== buttonElement) {
          return;
        }

        // The button can only lose focus and be disabled if it previously had
        // focus, and by default the browser will send focus to the body
        // element. This is not ideal because now the user will need to tab all
        // the way from the top of the document back to the carousel in order to
        // get back to where they were. Instead we can try to send focus to the
        // carousel content to prevent this.
        if (target.disabled) {
          // The user may have set the tabindex to 0, so we need to preserve
          // that so we can reset it just in case.
          const tabIndex = listElement.tabIndex;
          listElement.tabIndex = -1;
          listElement?.focus();
          listElement.tabIndex = tabIndex;
        }
      }
    }, [isDisabled, slideListRef]);

    const ariaLabelledBy = props["aria-labelledby"];
    const ariaLabel = ariaLabelledBy
      ? undefined
      : props["aria-label"] ?? `${direction} slide`;

    const handleClick = useComposedEventHandlers(
      onClick,
      React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
          if (isDisabled) {
            return;
          }
          if (direction === MOVE_DIRECTION_PREVIOUS) {
            event.preventDefault();
            controller.current?.goBack();
          }
          if (direction === MOVE_DIRECTION_NEXT) {
            event.preventDefault();
            controller.current?.goNext();
          }
        },
        [controller, direction, isDisabled],
      ),
    );

    return (
      <button
        ref={ref}
        type="button"
        data-replo-component-root="carousel"
        data-replo-part="nav-button"
        data-replo-root-id={carouselId}
        data-direction={direction}
        aria-controls={getTrackDOMId(carouselId)}
        aria-labelledby={ariaLabelledBy}
        aria-label={ariaLabel}
        disabled={isDisabled || undefined}
        onClick={handleClick}
        {...props}
      >
        {children}
      </button>
    );
  },
);
CarouselNavButton.displayName = "Carousel.NavButton";

// #endregion

// #region --- CarouselIndicators

type CarouselIndicatorsContextValue = {
  pauseOnHover: boolean;
  pauseOnFocus: boolean;
};

const CarouselIndicatorsContext =
  React.createContext<CarouselIndicatorsContextValue | null>(null);
CarouselIndicatorsContext.displayName = "CarouselIndicatorsContext";

function useCarouselIndicatorsContext() {
  const context = React.useContext(CarouselIndicatorsContext);
  if (context === null) {
    throw new Error(
      "CarouselIndicatorsContext cannot be accessed outside the CarouselIndicators component",
    );
  }
  return context;
}

type PageData = {
  key: React.Key;
  isActive: boolean;
};

type CarouselIndicatorsOwnProps = {
  // TODO (Chance 2024-01-08): Add proper a11y support for vertical orientation
  // indicators (they should be configurable even in horizontal carousels)
  // orientation?: Orientation;
  pauseOnHover?: boolean;
  pauseOnFocus?: boolean;
  children?:
    | React.ReactNode
    | ((props: { pages: PageData[] }) => React.ReactNode);
};

type CarouselIndicatorsProps = Omit<
  React.ComponentPropsWithRef<"div">,
  keyof CarouselIndicatorsOwnProps
> &
  CarouselIndicatorsOwnProps;

const CarouselIndicators = React.forwardRef<
  HTMLDivElement,
  CarouselIndicatorsProps
>(
  (
    {
      children,
      onKeyDown,
      pauseOnHover = false,
      pauseOnFocus = false,
      ...props
    },
    forwardedRef,
  ) => {
    const {
      activeSlideIndex,
      carouselId,
      setIndicatorsConfig,
      orientation,
      slidesPerMove,
      slidesPerPage,
      endBehavior,
      slideCount,
    } = useCarouselContext();
    React.useEffect(() => {
      setIndicatorsConfig({ pauseOnHover, pauseOnFocus });
      return () => {
        setIndicatorsConfig(null);
      };
    }, [setIndicatorsConfig, pauseOnHover, pauseOnFocus]);

    const infinite = isInfinite({ endBehavior });
    const dotCount = getDotCount({
      slideCount,
      slidesPerPage,
      slidesPerMove,
      endBehavior,
    });
    let pages: PageData[] = [];
    for (let index = 0; index < dotCount; index++) {
      const _rightBound = (index + 1) * slidesPerMove - 1;
      const rightBound = infinite
        ? _rightBound
        : clamp(_rightBound, 0, slideCount - 1);
      const _leftBound = rightBound - (slidesPerMove - 1);
      const leftBound = infinite
        ? _leftBound
        : clamp(_leftBound, 0, slideCount - 1);

      const isActive = infinite
        ? activeSlideIndex >= leftBound && activeSlideIndex <= rightBound
        : activeSlideIndex === leftBound;

      pages = pages.concat({ key: index, isActive });
    }

    const handleKeyDown = useIndicatorsOnKeyDown(onKeyDown, pages);

    return (
      <div
        ref={forwardedRef}
        data-replo-component-root="carousel"
        data-replo-part="indicators"
        data-replo-root-id={carouselId}
        role="tablist"
        aria-orientation={orientation}
        aria-label="Select a slide to show"
        onKeyDown={handleKeyDown}
        {...props}
      >
        <CarouselIndicatorsContext.Provider
          value={{ pauseOnHover, pauseOnFocus }}
        >
          {isFunction(children) ? children({ pages }) : children}
        </CarouselIndicatorsContext.Provider>
      </div>
    );
  },
);
CarouselIndicators.displayName = "Carousel.Indicators";

function useIndicatorsOnKeyDown<T extends Element>(
  onKeyDown: React.KeyboardEventHandler<T> | null | undefined,
  pages: PageData[],
) {
  const context = useCarouselContext();
  const { orientation, textDirection, controller } = context;
  const _canGoBack = canGoBack(context);
  const _canGoNext = canGoNext(context);
  const activePageIndex = pages.findIndex((page) => page.isActive);

  return useComposedEventHandlers(
    onKeyDown,
    useEffectEvent((event: React.KeyboardEvent<T>) => {
      function goToPage(index: number) {
        controller.current?.goToPage(index);
      }

      if (activePageIndex === -1) {
        return;
      }

      switch (event.key) {
        case KEY_ARROW_LEFT:
        case KEY_ARROW_RIGHT:
        case KEY_ARROW_UP:
        case KEY_ARROW_DOWN: {
          // TODO: Support top-to-bottom (and reverse) text orientation for
          // vertical slides
          if (event.key === KEY_ARROW_LEFT || event.key === KEY_ARROW_RIGHT) {
            if (orientation === ORIENTATION_VERTICAL) {
              return;
            }
          } else {
            if (orientation === ORIENTATION_HORIZONTAL) {
              return;
            }
          }

          event.preventDefault();
          const direction = (() => {
            if (textDirection === DIRECTION_RTL) {
              return event.key === KEY_ARROW_LEFT || event.key === KEY_ARROW_UP
                ? MOVE_DIRECTION_NEXT
                : MOVE_DIRECTION_PREVIOUS;
            }
            return event.key === KEY_ARROW_LEFT || event.key === KEY_ARROW_UP
              ? MOVE_DIRECTION_PREVIOUS
              : MOVE_DIRECTION_NEXT;
          })();

          const canGo =
            direction === MOVE_DIRECTION_PREVIOUS ? _canGoBack : _canGoNext;
          if (canGo) {
            goToPage(
              direction === MOVE_DIRECTION_PREVIOUS
                ? activePageIndex - 1
                : activePageIndex + 1,
            );
          }

          return;
        }

        case KEY_HOME:
          event.preventDefault();
          goToPage(0);
          break;
        case KEY_END:
          event.preventDefault();
          goToPage(Math.max(pages.length - 1, 0));
          return;

        case KEY_PAGE_UP:
          if (event.ctrlKey || event.metaKey) {
            event.preventDefault();
            goToPage(0);
          }
          break;

        case KEY_PAGE_DOWN:
          if (event.ctrlKey || event.metaKey) {
            event.preventDefault();
            goToPage(Math.max(pages.length - 1, 0));
          }
          break;

        default:
          return;
      }
    }),
  );
}

// #endregion

// #region --- CarouselIndicator

const CarouselIndicatorContext =
  React.createContext<CarouselIndicatorContextValue | null>(null);
CarouselIndicatorContext.displayName = "CarouselIndicatorContext";

type CarouselIndicatorContextValue = {
  page: number;
  isActive: boolean;
};

function useCarouselIndicatorContext() {
  const context = React.useContext(CarouselIndicatorContext);
  if (context === null) {
    throw new Error(
      "CarouselIndicatorContext cannot be accessed outside the CarouselIndicator component",
    );
  }
  return context;
}

type CarouselIndicatorOwnProps = {
  pageIndex: number;
  isActive?: boolean;
};

type CarouselIndicatorProps = Omit<
  React.ComponentPropsWithRef<"div">,
  keyof CarouselIndicatorOwnProps
> &
  CarouselIndicatorOwnProps;

const CarouselIndicator = React.forwardRef<
  HTMLDivElement,
  CarouselIndicatorProps
>(({ children, pageIndex, isActive = false, ...props }, forwardedRef) => {
  const { carouselId } = useCarouselContext();
  return (
    <div
      ref={forwardedRef}
      data-replo-component-root="carousel"
      data-replo-part="page"
      data-replo-root-id={carouselId}
      data-page-index={pageIndex}
      data-active={isActive || undefined}
      role="presentation"
      {...props}
    >
      <CarouselIndicatorContext.Provider value={{ page: pageIndex, isActive }}>
        {children}
      </CarouselIndicatorContext.Provider>
    </div>
  );
});
CarouselIndicator.displayName = "Carousel.Indicator";

// #endregion

// #region --- CarouselIndicatorButton

type CarouselIndicatorButtonOwnProps = {};

type CarouselIndicatorButtonProps = Omit<
  React.ComponentPropsWithRef<"button">,
  keyof CarouselIndicatorButtonOwnProps
> &
  CarouselIndicatorButtonOwnProps;

const CarouselIndicatorButton = React.forwardRef<
  HTMLButtonElement,
  CarouselIndicatorButtonProps
>(
  (
    { children, onClick, onMouseEnter, onMouseLeave, onMouseOver, ...props },
    forwardedRef,
  ) => {
    const {
      carouselId,
      controller,
      autoPlayInterval,
      autoPlayState,
      pause,
      play,
      // slidesPerPage,
      // activeSlidePosition,
    } = useCarouselContext();
    const { page, isActive } = useCarouselIndicatorContext();
    const labelId = useId(makeId(carouselId /* `indicator-label-${page}` */));
    const { pauseOnHover } = useCarouselIndicatorsContext();

    const handleClick = useComposedEventHandlers(
      onClick,
      useEffectEvent((event: React.MouseEvent) => {
        event.preventDefault();
        controller.current?.goToPage(page);
      }),
    );

    const onIndicatorOver = useEffectEvent(() => {
      if (!pauseOnHover) {
        return;
      }
      if (shouldAutoPlay({ autoPlayInterval })) {
        pause("hover");
      }
    });

    const handleMouseEnter = useComposedEventHandlers(
      onMouseEnter,
      onIndicatorOver,
    );

    const handleMouseOver = useComposedEventHandlers(
      onMouseOver,
      onIndicatorOver,
    );

    const handleMouseLeave = useComposedEventHandlers(
      onMouseLeave,
      useEffectEvent(() => {
        if (!pauseOnHover) {
          return;
        }
        if (
          shouldAutoPlay({ autoPlayInterval }) &&
          autoPlayState === "hovered"
        ) {
          play("leave");
        }
      }),
    );

    return (
      <button
        ref={forwardedRef}
        type="button"
        data-replo-component-root="carousel"
        data-replo-part="indicator-button"
        data-replo-root-id={carouselId}
        data-page={page}
        data-active={isActive || undefined}
        onClick={handleClick}
        tabIndex={isActive ? 0 : -1}
        // role="tab"
        // role={controlledSlideIndex ? "tab" : undefined}
        // aria-controls={controlledSlideId}
        // aria-selected={controlledSlideIndex ? isActive : undefined}
        aria-labelledby={labelId}
        onMouseEnter={handleMouseEnter}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onMouseOut={handleMouseLeave}
        {...props}
      >
        <span className="sr-only" id={labelId}>
          Go to page {page}
        </span>
        {children}
      </button>
    );
  },
);

CarouselIndicatorButton.displayName = "Carousel.IndicatorButton";

// #endregion

// #endregion

export {
  CarouselIndicator as Indicator,
  CarouselIndicatorButton as IndicatorButton,
  CarouselIndicators as Indicators,
  CarouselNavButton as NavButton,
  CarouselRoot as Root,
  CarouselSlide as Slide,
  CarouselSlideTrack as SlideTrack,
  CarouselSlides as Slides,
  useCarouselContext,
  useCarouselSlideContext,
  //   useCarouselTrackContext,
};

export type {
  // component props
  CarouselIndicatorProps,
  CarouselIndicatorOwnProps,
  CarouselIndicatorButtonProps,
  CarouselIndicatorButtonOwnProps,
  CarouselIndicatorsProps,
  CarouselIndicatorsOwnProps,
  CarouselNavButtonProps,
  CarouselNavButtonOwnProps,
  CarouselRootProps,
  CarouselSlideProviderProps,
  CarouselSlideProps,
  CarouselSlideOwnProps,
  CarouselSlideTrackProps,
  CarouselSlideTrackOwnProps,
  CarouselSlidesProps,
  CarouselSlidesOwnProps,

  // useful consumer types
  ActiveSlidePosition,
  CarouselContextValue,
  CarouselController,
  DragBehavior,
  EndBehavior,
  TransitionStyle,
  WheelBehavior,
};

function shouldAutoPlay(
  context: Pick<CarouselContextValue, "autoPlayInterval">,
): context is { autoPlayInterval: number } {
  return context.autoPlayInterval != null && context.autoPlayInterval > 0;
}

function isValidSlideElement(
  child: React.ReactNode,
): child is React.ReactElement & { type: typeof CarouselSlide } {
  return React.isValidElement(child) && child.type === CarouselSlide;
}

function isValidNonSlideChild(
  child: React.ReactNode,
): child is string | number {
  return isString(child) || isNumber(child);
}

function getDotCount(
  context: Pick<
    CarouselContextValue,
    "endBehavior" | "slideCount" | "slidesPerMove" | "slidesPerPage"
  >,
) {
  const infinite = isInfinite(context);
  if (infinite) {
    return Math.ceil(context.slideCount / context.slidesPerMove);
  }
  return (
    Math.ceil(
      (context.slideCount - context.slidesPerPage) / context.slidesPerMove,
    ) + 1
  );
}

function useSyncTrackStart(
  carouselContext: CarouselContextValue,
  trackContext: CarouselSlideTrackContextValue,
) {
  const {
    autoPlayInterval,
    activeSlidePosition,
    autoWidth,
    dispatch,
    endBehavior,
    isHydrated,
    orientation,
    slideCount,
    slidesPerMove,
    slidesPerPage,
    trackRef,
    isFade,
    listSize,
  } = carouselContext;
  const { slideSizes } = trackContext;
  // NOTE (Chance 2024-03-04): `SYNC_TRACK_START` needs the interval because it
  // may emit a GO event to restart the autoplay timer as the track is resized.
  // But we don't need to dispatch the SYNC_TRACK_START event if the interval
  // changes, hence the ref.
  const autoPlayIntervalRef = React.useRef(autoPlayInterval);
  React.useEffect(() => {
    autoPlayIntervalRef.current = autoPlayInterval;
  }, [autoPlayInterval]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: slideSizes is an extra dependency
  React.useEffect(() => {
    const autoPlayInterval = autoPlayIntervalRef.current;
    dispatch({
      type: "SYNC_TRACK_START",
      autoPlayInterval,
      activeSlidePosition,
      autoWidth,
      endBehavior,
      orientation,
      slideCount,
      slidesPerMove,
      slidesPerPage,
      trackRef,
      isFade,
      isHydrated,
      listSize,
    });
  }, [
    dispatch,
    activeSlidePosition,
    autoWidth,
    endBehavior,
    orientation,
    slideCount,
    slidesPerMove,
    slidesPerPage,
    trackRef,
    isFade,
    listSize,
    isHydrated,
    // NOTE (Chance 2024-03-05, REPL-10699): While `slideSizes` isn't explicitly
    // used to make the calculation, the track start position should be
    // recalculated if any slide's size changes.
    slideSizes,
  ]);
}

function useSetSlideSizes(
  slideRect: DOMRect | null,
  trackContext: CarouselSlideTrackContextValue,
  slideContext: CarouselSlideContextValue,
) {
  const { setSlideSizes } = trackContext;
  const { id: slideId, isCloned } = slideContext;
  React.useEffect(() => {
    if (isCloned) {
      return;
    }

    setSlideSizes((sizes) => {
      const entry = sizes.get(slideId);
      if (!entry) {
        if (!slideRect) {
          return sizes;
        }
        const { width, height } = slideRect;
        sizes.set(slideId, [width, height]);
        return new Map(sizes);
      }

      if (!slideRect) {
        sizes.delete(slideId);
        return new Map(sizes);
      }
      if (entry[0] !== slideRect.width || entry[1] !== slideRect.height) {
        const newEntry: SlideSizeEntry = [slideRect.width, slideRect.height];
        sizes.set(slideId, newEntry);
        return new Map(sizes);
      }
      return sizes;
    });
  }, [slideId, isCloned, slideRect, setSlideSizes]);
}

/**
 * NOTE (Chance 2024-02-05): Inactive slides should not be focusable via
 * keyboard navigation.
 *
 * TODO This is not fully implemented yet. Tracking in REPL-8596.
 */
function useSlideFocusManagement(
  _slideElement: HTMLElement | null,
  props: {
    onBlur: React.FocusEventHandler | undefined;
    onFocus: React.FocusEventHandler | undefined;
  },
) {
  const { onBlur, onFocus } = props;
  const { onSlideFocus, onSlideBlur } = useCarouselContext();
  const handleBlur = useComposedEventHandlers(onBlur, onSlideBlur);
  const handleFocus = useComposedEventHandlers(onFocus, onSlideFocus);

  return {
    onBlur: handleBlur,
    onFocus: handleFocus,
  };
}

function useTrackWheelEvents(
  trackRef: React.RefObject<HTMLDivElement>,
  {
    controller,
    orientation,
    transitionSpeed,
    wheelBehavior,
  }: Pick<
    CarouselContextValue,
    "controller" | "orientation" | "transitionSpeed" | "wheelBehavior"
  >,
) {
  // NOTE (Chance 2024-01-27): These values are only set and read in event
  // listeners. We do not need to render anything so no reason to use state
  // here.
  const isCssTransitioning = React.useRef(false);
  const lastTime = React.useRef(0);

  // biome-ignore lint/correctness/useExhaustiveDependencies: ignore exhaustive dependencies for now
  React.useEffect(() => {
    if (wheelBehavior === WHEEL_OFF) {
      return;
    }

    // NOTE (Chance 2024-01-27): Implement this if/when free scrolling mode is
    // supported. This is not currently supported in v3 but I think has been
    // requested as a future feature.
    if (wheelBehavior === WHEEL_FREE) {
      return;
    }

    const trackElement = trackRef.current;
    if (!trackElement) {
      return;
    }

    trackElement.addEventListener("wheel", handleWheel, { passive: false });
    trackElement.addEventListener("transitionrun", handleTransitionStart);
    trackElement.addEventListener("transitionend", handleTransitionEnd);
    trackElement.addEventListener("transitioncancel", handleTransitionEnd);
    return () => {
      trackElement.removeEventListener("wheel", handleWheel);
      trackElement.removeEventListener("transitionrun", handleTransitionStart);
      trackElement.removeEventListener("transitionend", handleTransitionEnd);
      trackElement.removeEventListener("transitioncancel", handleTransitionEnd);
    };

    function handleTransitionStart(event: TransitionEvent) {
      if (event.target === event.currentTarget) {
        isCssTransitioning.current = true;
      }
    }

    function handleTransitionEnd(event: TransitionEvent) {
      if (event.target === event.currentTarget) {
        isCssTransitioning.current = false;
      }
    }

    // NOTE (Chance 2024-03-01, REPL-10692): The wheel listener behavior is
    // adapted from Splide so we can get close to v3. The main improvements I
    // wanted to make are:
    //   1. being less aggressive about capturing scroll events if the carousel
    //      can't navigate, and
    //   2. only interpret the wheel event as a navigation if the user is
    //      scrolling in the visual direction of the carousel.
    //
    // https://github.com/Splidejs/splide/blob/master/src/js/components/Wheel/Wheel.ts
    function handleWheel(event: WheelEvent) {
      if (!event.cancelable || !controller.current) {
        return;
      }

      const slideControls = controller.current;

      // 1. Determine the direction the user is scrolling
      const absoluteDeltaX = Math.abs(event.deltaX);
      const absoluteDeltaY = Math.abs(event.deltaY);
      let scrollAxis: "x" | "y" | undefined;
      if (absoluteDeltaX > absoluteDeltaY) {
        if (orientation === ORIENTATION_HORIZONTAL) {
          scrollAxis = AXIS_X;
        }
      } else if (absoluteDeltaY > absoluteDeltaX) {
        if (orientation === ORIENTATION_VERTICAL) {
          scrollAxis = AXIS_Y;
        }
      } else if (absoluteDeltaY < 2) {
        // NOTE (Chance 2024-01-27): With tiny scroll movements, the delta
        // values can be equal, which isn't enough to determine the scroll axis.
        // This can happen frequently the first time the event fires, so even if
        // the user is triggering a navigation we won't know until the next
        // event fires. If we don't capture the event here the page or section
        // will scroll by one pixel which feels janky.
        captureScrollEvent();
        return;
      }

      if (!scrollAxis) {
        return;
      }

      // TODO (Chance 2024-01-27): This check is probably good but causes a
      // lot of scroll jank. We can probably optimize by getting a computed
      // style reference and storing it in a ref rather than triggering it on
      // every scroll.
      //
      // NOTE (Chance 2024-01-27): If the event target is a scrollable element
      // other than the track, don't trigger a slide transition or capture the
      // event.
      //
      // const targetElement = isHTMLElement(event.target) ? event.target : null;
      // const scrollableAxes =
      //   targetElement && targetElement !== trackElement
      //     ? getScrollableAxes(targetElement)
      //     : null;
      // if (scrollableAxes?.has(scrollAxis)) {
      //   return;
      // }

      const timeStamp = event.timeStamp;
      const delta = scrollAxis === AXIS_X ? event.deltaX : event.deltaY;
      const direction = delta < 0 ? "prev" : "next";

      // NOTE (Chance 2024-03-01): The following variables are taken from
      // settings used in Splide so that we can get a similar feel to the
      // scrolling behavior in v3.

      /**
       * The threshold to cut off the small delta produced by inertia scroll
       * (ie, trackpad or touchpad where scroll events fire in rapid succession)
       */
      const minimumWheelThreshold = 2;
      /**
       * The sleep duration until we accept the next wheel event. The timer
       * starts when the transition begins.
       */
      const wheelSleep = 0;
      /**
       * Determines whether to release the wheel event when the carousel reaches
       * the first or last slide so that the user can scroll the page
       * continuously.
       */
      const releaseWheel = true;

      if (
        Math.abs(delta) > minimumWheelThreshold &&
        timeStamp - lastTime.current > wheelSleep
      ) {
        if (direction === "next") {
          slideControls.goNext();
        } else {
          slideControls.goBack();
        }
        lastTime.current = timeStamp;
      }

      if (shouldPrevent(direction)) {
        captureScrollEvent();
      }

      function shouldPrevent(direction: "prev" | "next"): boolean {
        if (!releaseWheel || isCssTransitioning.current) {
          return true;
        }
        return direction === "prev"
          ? slideControls.canGoBack()
          : slideControls.canGoNext();
      }

      function captureScrollEvent() {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
    }
  }, [controller, orientation, trackRef, transitionSpeed, wheelBehavior]);
}

/**
 * NOTE (Chance 2024-02-16): Setting state from within the event emitter
 * callback can be problematic because events emitted from the reducer will fire
 * during render, and the function may get called while React is already
 * updating. This leads to a console warning and potentially non-deterministic
 * behavior.
 *
 * This is an awkward workaround, but instead we'll trigger a separate state
 * update when the reducer event is fired. Another effect will respond to that
 * update and trigger the callback safely.
 *
 * Important to note that this is only desirable for emitter callbacks that
 * update state. Others may simply reset timers stored in refs and those are
 * fine to handle directly.
 */
function useSubscribeToEmitterEvent<
  EventType extends keyof CarouselEmittedEvent,
>(
  emitter: CarouselEmitter,
  event: EventType,
  callback: CarouselEmittedEvent[EventType] extends undefined
    ? () => ReturnType<React.EffectCallback>
    : (
        args: CarouselEmittedEvent[EventType],
      ) => ReturnType<React.EffectCallback>,
) {
  const [updated, setUpdated] = React.useState(Object.create(null));
  const _callback = useEffectEvent(callback);
  const savedArgs = React.useRef<CarouselEmittedEvent[EventType]>();
  const shouldCall = React.useRef(false);
  React.useEffect(() => {
    emitter.on(event, (args) => {
      savedArgs.current = args;
      shouldCall.current = true;
      setUpdated(Object.create(null));
    });
    return () => {
      emitter.off(event);
    };
  }, [emitter, event]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: ignore exhaustive dependencies for now
  React.useEffect(() => {
    if (shouldCall.current) {
      const args = savedArgs.current;
      shouldCall.current = false;
      savedArgs.current = undefined;
      return _callback(args!);
    }
  }, [updated, _callback]);
}

// TODO (Chance 2024-01-27, REPL-10192): Keeping until issue is resolved
// function getScrollableAxes(element: Element) {
//   const axes = new Set<"x" | "y">();
//   let computedStyle: CSSStyleDeclaration;
//   if (element.scrollWidth > element.clientWidth) {
//     computedStyle ??= window.getComputedStyle(element);
//     if (
//       computedStyle.overflowX === "scroll" ||
//       computedStyle.overflowX === "auto"
//     ) {
//       axes.add("x");
//     }
//   }
//   if (element.scrollHeight > element.clientHeight) {
//     computedStyle ??= window.getComputedStyle(element);
//     if (
//       computedStyle.overflowY === "scroll" ||
//       computedStyle.overflowY === "auto"
//     ) {
//       axes.add("y");
//     }
//   }
//   return axes;
// }

type NavButtonChangeOpts = { type: MoveDirection };

type PageChangeOpts = {
  type: "page";
  index: number;
};

type SlideIndexChangeOpts = {
  type: "slide-index";
  index: number;
};
