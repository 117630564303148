import * as React from "react";

import { App } from "@editor/App";
import {
  initializeAnalytics,
  initializeErrorTracking,
  initializePerformanceMonitoring,
} from "@editor/infra/analytics";

import { createRoot } from "react-dom/client";

initializeErrorTracking();
initializeAnalytics();
initializePerformanceMonitoring();

const root = createRoot(document.querySelector("#root") as HTMLElement);
root.render(<App />);
