// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import type { MenuItem } from "@editor/components/common/designSystem/Menu";
import type { IconType } from "react-icons";

import * as React from "react";

import {
  Group,
  GroupHeader,
  GroupHeaderActionButton,
  GroupTitle,
  GroupTitleContainer,
} from "@editor/components/common/designSystem/Group";
import { Menu } from "@editor/components/common/designSystem/Menu";

import IconButton from "@replo/design-system/components/button/IconButton";
import Tooltip from "@replo/design-system/components/tooltip";
import { BsPlus } from "react-icons/bs";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { hasOwnProperty } from "replo-utils/lib/misc";

type ContextProps = {
  name: string;
  isCollapsed: boolean;
};

const ModifierGroupContext = React.createContext<ContextProps | null>(null);
ModifierGroupContext.displayName = "ModifierGroupContext";

// NOTE (Chance 2023-11-10): This hook is for external components to read to
// know whether or not they are rendered inside of a modifier group
export function useModifierGroupContext() {
  return React.useContext(ModifierGroupContext);
}

type IconProps =
  | {
      icon: React.ComponentType | null | undefined;
      iconTooltip: string | null | undefined;
      iconAriaLabel?: string | null;
      iconShouldOpenModifierGroup?: boolean;
    }
  | {
      icon: React.ComponentType | null | undefined;
      iconTooltip?: never;
      iconAriaLabel: string | null | undefined;
      iconShouldOpenModifierGroup?: boolean;
    }
  | {
      icon?: never;
      iconTooltip?: never;
      iconAriaLabel?: never;
      iconShouldOpenModifierGroup?: never;
    };

type ModifierGroupProps = IconProps & {
  isCollapsible?: boolean;
  isDefaultOpen?: boolean;
  isOpen?: boolean;
  onOpenChange?: (value: boolean) => void;
  hideEndEnhancerOnGroupClosed?: boolean;
  title: string;
  onClick?(isActive: boolean): void;
  isDisabled?: boolean;
  endEnhancer?: React.ReactNode;
  titleEnhancer?: React.ReactNode;
  titleClassName?: string;
  onClose?(): void;
  menuItems?: MenuItem[];
  tooltipText?: string;
  disableMenuTriggerFocusOnClose?: boolean;
};

const ModifierGroup: React.FC<React.PropsWithChildren<ModifierGroupProps>> = ({
  title,
  onClick,
  icon,
  iconTooltip,
  isDisabled = false,
  endEnhancer,
  isCollapsible = true,
  isDefaultOpen = true,
  children,
  hideEndEnhancerOnGroupClosed = false,
  titleEnhancer,
  titleClassName,
  iconShouldOpenModifierGroup = false,
  menuItems,
  disableMenuTriggerFocusOnClose = false,
  tooltipText,
}) => {
  const [isOpen, setIsOpen] = useOverridableState(
    isDefaultOpen,
    undefined,
    undefined,
  );
  const hasEnabledMenuItems =
    menuItems &&
    menuItems?.some(
      (menuItem) =>
        hasOwnProperty(menuItem, "isDisabled") && !menuItem.isDisabled,
    );

  const endEnhancers = (() => {
    const IconComponent = icon as IconType;
    return (
      <>
        {icon ? (
          <GroupHeaderActionButton
            aria-label={iconTooltip!}
            onClick={() => {
              onClick?.(true);
              if (iconShouldOpenModifierGroup) {
                setIsOpen(true);
              }
            }}
          >
            <div className="flex flex-row items-center justify-end">
              <IconButton
                className="px-0"
                icon={<IconComponent size={20} className="text-muted" />}
                tooltipText={iconTooltip}
                variant="tertiary"
                isDisabled={isDisabled}
                // NOTE (Fran 2023-10-04): With need this to avoid nesting buttons.
                isPhonyButton
              />
            </div>
          </GroupHeaderActionButton>
        ) : null}
        {endEnhancer}
        {hasEnabledMenuItems ? (
          <ModifierGroupMenu
            menuItems={menuItems}
            tooltipText={tooltipText}
            onTriggerClick={() => {
              if (!isOpen) {
                setIsOpen(true);
              }
            }}
            disableMenuTriggerFocusOnClose={disableMenuTriggerFocusOnClose}
          />
        ) : null}
      </>
    );
  })();

  return (
    <ModifierGroupContext.Provider
      value={{ name: title, isCollapsed: isCollapsible && !isOpen }}
    >
      <Group
        name={title ?? ""}
        isCollapsible={isCollapsible}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        className="relative w-full border-b border-slate-200 pb-3 last:border-b-0 last:pb-0"
        header={
          <GroupHeader
            endEnhancer={endEnhancers}
            titleEnhancer={titleEnhancer}
            hideEndEnhancerOnGroupClosed={hideEndEnhancerOnGroupClosed}
          >
            <GroupTitleContainer>
              <GroupTitle className={titleClassName} />
            </GroupTitleContainer>
          </GroupHeader>
        }
      >
        {children}
      </Group>
    </ModifierGroupContext.Provider>
  );
};

const ModifierGroupMenu: React.FC<{
  menuItems: MenuItem[];
  isDisabled?: boolean;
  onTriggerClick?: () => void;
  tooltipText?: string;
  disableMenuTriggerFocusOnClose?: boolean;
}> = ({
  menuItems,
  isDisabled = false,
  onTriggerClick,
  tooltipText,
  disableMenuTriggerFocusOnClose,
}) => {
  return (
    <Menu
      items={menuItems}
      menuType="normal"
      align="end"
      isDisabled={isDisabled}
      onRequestOpen={onTriggerClick}
      trigger={<ModifierPlusTrigger tooltipText={tooltipText} />}
      disableTriggerFocusOnClose={disableMenuTriggerFocusOnClose}
    />
  );
};

export const ModifierPlusTrigger: React.FC<{ tooltipText?: string }> = ({
  tooltipText,
}) => (
  <div className="flex h-6 w-6 items-center justify-center rounded hover:bg-slate-200">
    {tooltipText ? (
      <Tooltip content={tooltipText} aria-label={tooltipText} triggerAsChild>
        <button>
          <BsPlus className="text-muted" size={20} />
        </button>
      </Tooltip>
    ) : (
      <BsPlus className="text-muted" size={20} />
    )}
  </div>
);
export default ModifierGroup;
