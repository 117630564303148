import { isNotNullish } from "./misc";

export const EMPTY_ARRAY = Object.freeze([]) as unknown as any[];

export function filterNulls<T>(
  value: Array<T | null | undefined> | null | undefined,
): Array<T> {
  if (value == null) {
    return [];
  }
  return value.filter(isNotNullish);
}

export function createArrayOfLength(length: number) {
  const result = [];
  for (let i = 0; i < length; i++) {
    result.push(i);
  }
  return result;
}

export function at<T>(array: ArrayLike<T>, index: number): T | undefined {
  if ("at" in Array.prototype) {
    return Array.prototype.at.call(array, index);
  }
  const length = array.length;
  const relativeIndex = toNearestSafeIndex(index);
  const actualIndex =
    relativeIndex >= 0 ? relativeIndex : length + relativeIndex;
  return actualIndex < 0 || actualIndex >= length
    ? undefined
    : array[actualIndex];
}

// NOTE (Chance 2023-10-13): This just coerces potentially invalid indices (NaN,
// -0) to 0, or floats to integers, so that at() behaves the same as
// Array.prototype.at()
export function toNearestSafeIndex(number: number) {
  return number !== number || number === 0 ? 0 : Math.trunc(number);
}

export function toArray<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : [value];
}

export function getRandomElementFromArray<T>(values: Array<T>): T {
  return values[Math.floor(Math.random() * values.length)]!;
}

export function lastElement(array: number[]) {
  return array[array.length - 1];
}
