import type { ReploProject } from "schemas/generated/project";

import * as React from "react";

import InputComponent from "@editor/components/common/designSystem/Input";
import Modal from "@editor/components/common/designSystem/Modal";
import toast, {
  successToast,
} from "@editor/components/common/designSystem/Toast";
import { Loader } from "@editor/components/common/Loader";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { useIsWorkspaceOwner } from "@editor/hooks/useIsWorkspaceOwner";
import { useModal } from "@editor/hooks/useModal";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import { publisherApi } from "@editor/reducers/api-reducer";
import { selectPublishedElementsAmount } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { docs } from "@editor/utils/docs";
import { getProjectName } from "@editor/utils/project-utils";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button";
import { skipToken } from "@tanstack/react-query";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isValidDomain } from "replo-utils/lib/url";
import { isPathSafeSlug } from "schemas/utils";

export const ProjectSettingsTab = () => {
  const { project, isLoading: isProjectLoading } = useCurrentProjectContext();

  if (isProjectLoading || !project) {
    return <Loader />;
  }

  return (
    <InnerProjectsSettingsTab
      project={project}
      domain={project.customDomain}
      shortName={project.shortName ?? project.id}
    />
  );
};

type InnerProjectsSettingsTabProps = {
  project: ReploProject;
  shortName?: string | null;
  domain?: string | null;
};

const InnerProjectsSettingsTab: React.FC<InnerProjectsSettingsTabProps> = ({
  domain,
  project,
  shortName,
}) => {
  const [domainData, setDomainData] = React.useState({
    value: domain,
    isValid: isValidDomain(domain ?? ""),
  });
  const { openModal } = useModal();

  const projectId = project.id;
  const { data: projectActiveSubscription } =
    trpc.subscriptions.getActiveSubscriptionByProject.useQuery(
      projectId ?? skipToken,
    );
  const domainDataEdited = domain !== domainData.value;
  const customDomainEditButtonEnabled = domainData.isValid && domainDataEdited;
  const [slugData, setSlugData] = React.useState({
    value: "",
    isValid: isPathSafeSlug(shortName ?? ""),
  });
  const projectName = project ? getProjectName(project) : "";
  const [editedProjectName, setEditedProjectName] =
    React.useState<string>(projectName);
  const [isDomainConfirmationModalOpen, setDomainConfirmationModalOpen] =
    React.useState(false);
  const [isSlugConfirmationModalOpen, setSlugConfirmationModalOpen] =
    React.useState(false);
  const publishedElementsAmount = useEditorSelector(
    selectPublishedElementsAmount,
  );
  const dispatch = useDispatch();
  const { mutate: updateProjectName } = trpc.project.updateName.useMutation({
    onSuccess: () => {
      void trpcUtils.workspace.getUserWorkspacesList.invalidate();
      void trpcUtils.project.findByUserId.invalidate();
      void trpcUtils.project.get.invalidate();
      // TODO (Sebas, 2024-07-12): Remove these tags once the migration to TRPC
      // is done.
      dispatch(publisherApi.util.invalidateTags(["workspaces", "projects"]));
      toast({
        header: "Project Name Updated",
        message: `Project name has been changed to “${editedProjectName}”.`,
      });
    },
    onSettled: () => {
      setIsRenameProjectLoading(false);
    },
  });
  const navigate = useNavigate();
  const userIsOwner = useIsWorkspaceOwner(project?.ownerWorkspace?.id);
  const hasActiveShopifySubscriptionOnThisProject = Boolean(
    projectActiveSubscription?.paymentProcessor === "shopify" &&
      project?.integrations?.shopify?.store.shopifyUrl,
  );
  const hasPublishedElements =
    publishedElementsAmount && publishedElementsAmount > 0;
  const shouldDisableDeleteButton =
    hasPublishedElements ||
    !userIsOwner ||
    hasActiveShopifySubscriptionOnThisProject;
  const [isRenameProjectLoading, setIsRenameProjectLoading] =
    React.useState(false);

  const handleRenameClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    setIsRenameProjectLoading(true);
    updateProjectName({
      projectId,
      name: editedProjectName,
    });
  };

  const handleDeleteProject = () => {
    openModal({
      type: "deleteProjectModal",
      props: {
        projectId,
        callback: () => {
          navigate("/home");
        },
        from: "details",
      },
    });
  };

  // TODO (Kurt, 2024-10-30): Remove this once we've migrated all users to the
  // the new experiments feature flag.
  const isExperimentsEnabled = isFeatureEnabled("experiments-refresh");

  return (
    <div className="space-y-6">
      {isSlugConfirmationModalOpen && (
        <SlugConfirmationModal
          projectId={projectId}
          existingSlug={shortName ?? undefined}
          slug={slugData.value!}
          onClose={() => setSlugConfirmationModalOpen(false)}
          onConfirm={() => setSlugConfirmationModalOpen(false)}
        />
      )}
      {isDomainConfirmationModalOpen && (
        <DomainConfirmationModal
          existingDomain={domain ?? undefined}
          domain={domainData.value!}
          projectId={projectId}
          onClose={() => setDomainConfirmationModalOpen(false)}
        />
      )}
      <div className="flex justify-between items-center content-center text-sm">
        <div className="font-semibold flex gap-3 items-center">
          Project Settings
        </div>
      </div>
      <div className="max-w-2xl">
        <p className="text-sm text-slate-400">
          Manage settings for your project.
        </p>
        <div className="space-y-6 text-sm pt-4">
          <div className="flex flex-col w-full max-w-3xl gap-3">
            <div className="font-semibold mb-2">Rename Project</div>
            <InputComponent
              size="base"
              type="text"
              maxLength={256}
              value={editedProjectName}
              placeholder={projectName}
              onChange={(e) => setEditedProjectName(e.currentTarget.value)}
            />
            <div className="pt-2">
              <Button
                variant="primary"
                size="base"
                onClick={(e) => void handleRenameClick(e)}
                isDisabled={editedProjectName === ""}
                isLoading={isRenameProjectLoading}
              >
                Rename
              </Button>
            </div>
          </div>
          {!isExperimentsEnabled && (
            <>
              <div className="flex flex-col w-full max-w-3xl gap-3">
                <div className="font-semibold mb-2">Project Short Name</div>
                <p className="max-w-xl text-sm text-slate-500 mb-3">
                  A project short name allows you to have a custom,
                  user-friendly name for your project when directing users to
                  Experiments on reploedge.com.{" "}
                  <a
                    className="hover:underline text-blue-600"
                    href={docs.abTesting.projectShortName}
                  >
                    Learn more about the project short name
                  </a>
                  .
                </p>
                <InputComponent
                  size="base"
                  type="text"
                  maxLength={256}
                  onChange={(e) =>
                    setSlugData({
                      value: e.currentTarget.value,
                      isValid: isPathSafeSlug(e.currentTarget.value ?? ""),
                    })
                  }
                  value={slugData.value ?? undefined}
                  placeholder={shortName}
                />
                <div className="pt-2">
                  <Button
                    variant="primary"
                    size="base"
                    isDisabled={slugData.value === ""}
                    isLoading={false}
                    onClick={() => setSlugConfirmationModalOpen(true)}
                  >
                    Update
                  </Button>
                </div>
              </div>

              <div className="flex flex-col w-full gap-3">
                <div className="font-semibold mb-2">Custom Domain</div>
                <p className=" text-sm text-slate-500 mb-3 max-w-xl">
                  A custom domain allows you to use your own domain for A/B
                  testing Experiments instead of reploedge.com. This requires
                  additional changes with your DNS provider to add a CNAME
                  record.{" "}
                  <a
                    className="hover:underline text-blue-600"
                    href={docs.abTesting.projectCustomDomain}
                  >
                    Learn more about custom domain configuration
                  </a>
                  .
                </p>
                <InputComponent
                  size="base"
                  type="text"
                  placeholder="go.my-store.com"
                  maxLength={256}
                  onChange={(e) =>
                    setDomainData({
                      value: e.currentTarget.value,
                      isValid: isValidDomain(e.currentTarget.value ?? ""),
                    })
                  }
                  value={domainData.value ?? undefined}
                />
                <div className="pt-2">
                  <Button
                    variant="primary"
                    size="base"
                    isDisabled={!customDomainEditButtonEnabled}
                    onClick={() => setDomainConfirmationModalOpen(true)}
                  >
                    Check
                  </Button>
                </div>
              </div>
            </>
          )}
          <div className="flex flex-col gap-3">
            <h3 className="text-sm font-semibold">Delete Project</h3>
            <p className="text-sm text-slate-400">
              You must unpublish any live pages and cancel the Replo
              subscription associated with this project before deleting it.
            </p>
            <div className="text-slate-700 text-sm font-semibold flex items-center gap-2">
              Live Pages: {publishedElementsAmount}
            </div>
            <div>
              <Button
                variant="danger"
                size="base"
                textClassNames="text-xs"
                isDisabled={shouldDisableDeleteButton}
                onClick={handleDeleteProject}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type SlugConfirmationModalProps = {
  projectId: string;
  slug: string;
  existingSlug?: string;
  onClose: () => void;
  onConfirm: () => void;
};

const SlugConfirmationModal: React.FC<SlugConfirmationModalProps> = ({
  projectId,
  existingSlug,
  slug,
  onClose,
  onConfirm,
}) => {
  const { mutate: updateSlug, isPending: isUpdating } =
    trpc.project.updateProjectShortName.useMutation({
      onSuccess: () => {
        successToast("Project Name Updated", "");
        onConfirm();
      },
    });
  const handleOnConfirm = () => {
    updateSlug({
      projectId,
      slug,
    });
  };

  return (
    <Modal isOpen={true} className="max-w-3xl text-sm" onRequestClose={onClose}>
      <div className="flex flex-col items-stretch justify-between rounded-lg bg-white p-3 text-default gap-y-4 w-full">
        <h1 className="text-lg font-medium text-default">
          Are you sure you want to change your project short name?
        </h1>
        {existingSlug && (
          <p>
            Experiments URLs will no longer use{" "}
            <span className="text-default my-1 px-2 py-1 rounded bg-slate-100 font-semibold">
              reploedge.com/{existingSlug}/...
            </span>{" "}
          </p>
        )}
        <p>
          {existingSlug && "They will instead use "}
          <span className="text-default my-1 px-2 py-1 rounded bg-slate-100 font-semibold">
            reploedge.com/{slug}/...
          </span>
        </p>
        {existingSlug && (
          <p>
            Any existing Experiment URLs shared in social media or used in ad
            campaigns will no longer work.{" "}
            <a
              className="hover:underline text-blue-600"
              href={docs.abTesting.projectShortName}
            >
              Learn more about the project short name
            </a>
            .
          </p>
        )}
        <div className="mt-20 flex flex-row items-center content-center flex-grow w-full justify-end">
          <Button
            variant="primary"
            size="base"
            onClick={() => void handleOnConfirm()}
            isLoading={isUpdating}
          >
            Update Short Name
          </Button>
        </div>
      </div>
    </Modal>
  );
};

type DomainConfirmationModalProps = {
  existingDomain: string | undefined;
  domain: string;
  projectId: string;
  onClose: () => void;
};

const DomainConfirmationModal: React.FC<DomainConfirmationModalProps> = ({
  existingDomain,
  domain,
  projectId,
  onClose,
}) => {
  const { mutate: updateDomain, isPending: isUpdating } =
    trpc.project.updateCustomDomain.useMutation({
      onSuccess: () => {
        successToast("Custom Domain Updated", "");
      },
      onSettled: () => {
        onClose();
      },
    });

  const {
    data: cnameResults,
    isLoading,
    isFetching,
    refetch,
  } = trpc.project.checkCustomDomain.useQuery({
    customDomain: domain,
    projectId,
  });
  const onSubmitDomainUpdate = () => {
    updateDomain({
      projectId,
      customDomain: domain,
    });
  };
  const hasExistingDomain = Boolean(existingDomain);
  const status = (() => {
    if (isLoading || isFetching) {
      return "checking";
    }
    return cnameResults?.ok ? "ok" : "notOk";
  })();
  const onCheckAgain = async () => {
    await refetch();
  };

  return (
    <Modal isOpen={true} className="max-w-3xl text-sm" onRequestClose={onClose}>
      <div className="flex flex-col items-stretch justify-between rounded-lg bg-white p-3 text-default gap-y-4 w-full">
        <h1 className="text-lg font-medium text-default">
          Are you sure you want to change your custom domain?
        </h1>
        {hasExistingDomain && (
          <>
            <p>
              The existing Experiments for this project will use{" "}
              <span className="text-default my-1 px-2 py-1 rounded bg-slate-100 font-semibold">
                {domain}
              </span>{" "}
              instead of{" "}
              <span className="text-default my-1 px-2 py-1 rounded bg-slate-100 font-semibold">
                {existingDomain}
              </span>
            </p>
          </>
        )}
        <DomainCheckStatus
          status={status}
          domain={domain}
          onCheckAgain={() => void onCheckAgain()}
          isDisabled={isUpdating}
        />
        <p>
          Updating your CNAME record in your DNS configuration before updating
          your custom domain will ensure no traffic is lost. If your DNS records
          are correctly configured, your custom domain will automatically be
          enabled.{" "}
          <a
            className="hover:underline text-blue-600"
            href={docs.abTesting.projectCustomDomain}
          >
            Learn more about custom domain configuration
          </a>
          .
        </p>
        <div className="mt-20 flex flex-row items-center content-center flex-grow w-full justify-end">
          <Button
            variant="primary"
            size="base"
            isLoading={isUpdating}
            isDisabled={status === "checking"}
            onClick={onSubmitDomainUpdate}
          >
            Update Domain
          </Button>
        </div>
      </div>
    </Modal>
  );
};

type DomainCheckStatusProps = {
  status: "checking" | "ok" | "notOk";
  domain: string;
  onCheckAgain: () => void;
  isDisabled: boolean;
};

const DomainCheckStatus: React.FC<DomainCheckStatusProps> = ({
  status,
  domain,
  onCheckAgain,
  isDisabled,
}) => {
  return (
    <div className="w-full px-3 py-2 rounded-md bg-slate-100 flex flex-row items-center content-center justify-start gap-x-4">
      <div
        className={classNames(
          "rounded-full h-4 w-4 overflow-hidden flex-shrink-0 group relative",
          {
            "bg-green-500": status === "ok",
            "bg-red-500": status === "notOk",
            "bg-gray-500": status === "checking",
          },
        )}
      >
        {status === "checking" && (
          <div className="w-4 h-4 absolute bg-slate-800 opacity-0 animate-pulse group-hover:opacity-100 transition-opacity duration-200"></div>
        )}{" "}
      </div>
      <div className=" flex-shrink-0">
        {status === "ok" && (
          <>
            CNAME entry found for{" "}
            <span className="font-semibold">{domain}</span>.
          </>
        )}
        {status === "notOk" && (
          <>
            No DNS entry found for{" "}
            <span className="font-semibold">{domain}</span>
          </>
        )}
        {status === "checking" && (
          <>
            Checking DNS entry for{" "}
            <span className="font-semibold">{domain}</span>...
          </>
        )}
      </div>
      <div className="flex-grow-1 w-full h-8"></div>
      {status === "notOk" && (
        <Button
          variant="primary"
          size="base"
          className="flex-shrink-0"
          onClick={onCheckAgain}
          isDisabled={isDisabled}
        >
          Check again
        </Button>
      )}
    </div>
  );
};
