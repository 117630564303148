import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import {
  ExtraContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { SharedOkendoReviews } from "../SharedOkendoReviews";

const OkendoProductRatingSummary: React.FC<RenderComponentProps> = (props) => {
  const { okendoNamespace } = useRuntimeContext(ExtraContext);
  // Note (Fran, 2023-03-08, REPL-6564): For the Okendo Widget version that has
  // okendoReviews namespace, the liquid render is different.
  const liquidSourceWidget =
    okendoNamespace === "okendoReviews"
      ? `<div data-oke-reviews-product-listing-rating>{{ product.metafields.okendo.ProductListingSnippet }}</div>`
      : `
    <div
      data-oke-star-rating
      data-oke-reviews-product-id="shopify-{{ product.id }}">
        {{ product.metafields.okendo.StarRatingSnippet }}
    </div>`;

  return (
    <SharedOkendoReviews
      attributes={props.componentAttributes}
      component={props.component}
      context={props.context}
      liquidSource={liquidSourceWidget}
      reviewsComponentType="Product Rating"
    />
  );
};

export default OkendoProductRatingSummary;
