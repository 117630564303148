import * as React from "react";

import { getAlchemyGlobalPaintContext } from "../Window";

/**
 * This hook can be used to wait for third party JS to load before we execute some specific third party function.
 *
 * @param config.run Function to be executed when the third party JS library has loaded
 * @param config.isReady Function that returns a boolean indicating the `run` function is ready to `run`
 * @param config.identifier String to identify type of third party library (ie "yotpo", "reviewsio")
 * @param config.componentId Component's unique ID
 * @param config.scriptSourceRegex Regex used to find the corresponding script tag to confirm a third party library has been loaded.
 * @param dependencies An array of depenencies we can pass into the useEffect to allow the `run` function to be executed at specific intervals.
 */
export default function useScriptCallbackEvent(
  config: {
    isReady: () => boolean;
    run: () => void;
    identifier: string;
    componentId: string;
    scriptSourceRegex: RegExp;
  },
  dependencies: any[],
) {
  const { identifier, componentId, scriptSourceRegex, isReady, run } = config;
  React.useEffect(() => {
    if (isReady()) {
      run();
      return;
    }
    const globalContext = getAlchemyGlobalPaintContext();
    if (globalContext) {
      globalContext.scriptCallbacks[`${identifier}-${componentId}`] = (
        src: string,
      ) => {
        if (scriptSourceRegex.test(src)) {
          run();
        }
      };
      return () => {
        delete globalContext.scriptCallbacks[`${identifier}-${componentId}`];
      };
    }
  }, [
    identifier,
    componentId,
    scriptSourceRegex,
    isReady,
    run,
    // NOTE (Matt 2023-10-11): Adding this eslint-disable so that we can pass variable dependencies to the useEffect
    // these dependencies are typically used inside of the defined isReady and run fns, which this hook cannot
    // see the definitions of.
    // eslint-disable-next-line
    ...dependencies,
  ]);
}
