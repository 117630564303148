import { z } from "zod";

export const savedStyleTypeSchema = z.enum(["color", "text"]);

export const colorTypeSchema = z
  .enum([
    "basic",
    "foregroundPrimary",
    "foregroundSecondary",
    "foregroundTertiary",
    "backgroundPrimary",
    "backgroundSecondary",
    "backgroundTertiary",
  ])
  .describe("SavedStyleColorType");

export const savedStyleColorAttributesSchema = z
  .object({
    type: z.literal("color"),
    color: z.string(),
    colorType: colorTypeSchema,
  })
  .describe("SavedStyleColorAttributes");

export const savedStyleTextHtmlTagSchema = z.enum([
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "p",
]);

export const textAttributeSchema = z.enum([
  "color",
  "fontFamily",
  "fontSize",
  "fontWeight",
  "letterSpacing",
  "lineHeight",
  "textAlign",
  "textDecorationLine",
  "textShadow",
  "textStroke",
  "textTransform",
]);

export const savedStyleTextAttributesSchema = z
  .object({
    type: z.literal("text"),
    htmlTag: savedStyleTextHtmlTagSchema,
    color: z.string().optional(),
    fontFamily: z.string().nullish(),
    fontSize: z.string().optional(),
    fontWeight: z
      .enum([
        "100",
        "200",
        "300",
        "400",
        "500",
        "600",
        "700",
        "800",
        "900",
        "950",
      ])
      .optional(),
    letterSpacing: z.string().optional(),
    lineHeight: z.string().optional(),
    textAlign: z.enum(["left", "center", "right"]).optional(),
    textDecoration: z
      .enum(["underline", "line-through", "italic", "none"])
      .optional(),
    textShadow: z.string().optional(),
    textStroke: z.string().optional(),
    textTransform: z
      .enum(["lowercase", "uppercase", "capitalize", "none"])
      .optional(),
  })
  .describe("SavedStyleTextAttributes");

export const savedStyleAttributesSchema = z
  .discriminatedUnion("type", [
    savedStyleColorAttributesSchema,
    savedStyleTextAttributesSchema,
  ])
  .describe("SavedStyleAttributes");
