import type { MenuItem } from "@common/designSystem/Menu";
import type { SubmitHandler } from "react-hook-form";
import type { ReploSimpleProject } from "schemas/generated/project";

import * as React from "react";

import Input from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import { Menu, MenuTrigger } from "@common/designSystem/Menu";
import Modal from "@common/designSystem/Modal";
import toast from "@editor/components/common/designSystem/Toast";
import { useIsWorkspaceOwner } from "@editor/hooks/useIsWorkspaceOwner";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { publisherApi } from "@editor/reducers/api-reducer";
import { getProjectName } from "@editor/utils/project-utils";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@replo/design-system/components/button";
import IconButton from "@replo/design-system/components/button/IconButton";
import Tooltip from "@replo/design-system/components/tooltip";
import classNames from "classnames";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { useForm } from "react-hook-form";
import { BsThreeDots } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { filterNulls } from "replo-utils/lib/array";
import { isShopifyIntegrationEnabled } from "schemas/utils";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

const formSchema = z.object({
  projectName: z.string().min(1, "Please provide a project name"),
});
type FormDataType = z.infer<typeof formSchema>;
type ProjectItemProps = {
  project: ReploSimpleProject;
  onSelectProject: () => void;
  className?: string;
  shouldShowCreatedAt?: boolean;
  showWorkspaceColumn?: boolean;
  hideEllipsisButton?: boolean;
};

const ProjectItem: React.FC<ProjectItemProps> = ({
  project,
  onSelectProject,
  className,
  shouldShowCreatedAt = true,
  showWorkspaceColumn = false,
  hideEllipsisButton = false,
}) => {
  const logAnalytics = useLogAnalytics();
  const { openModal } = useModal();
  const [isRenameProjectModalOpen, setIsRenameProjectModalOpen] =
    React.useState(false);
  const dispatch = useDispatch();
  const { mutate: updateProjectName } = trpc.project.updateName.useMutation({
    onSuccess: ({ project }) => {
      setIsRenameProjectModalOpen(false);
      // TODO (Sebas, 2024-07-12): Remove these tags once the migration to TRPC
      // is done.
      dispatch(
        publisherApi.util.invalidateTags(["workspaces", "projects", "project"]),
      );
      void trpcUtils.workspace.getUserWorkspacesList.invalidate();
      void trpcUtils.project.findByUserId.invalidate();
      toast({
        header: "Project Name Updated",
        message: `Project name has been changed to “${project.name}”.`,
      });
    },
  });
  const isWorkspaceOwner = useIsWorkspaceOwner(project?.ownerWorkspace?.id);
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm<FormDataType>({
    resolver: zodResolver(formSchema),
    defaultValues: { projectName: project.name ?? "" },
  });
  const uniqueId = `project-${project.id}-${React.useId()}`;

  const onSubmit: SubmitHandler<FormDataType> = async (data) => {
    logAnalytics("project.edit", {
      type: "rename",
    });
    updateProjectName({
      projectId: project.id,
      name: data.projectName,
    });
  };

  const shopifyData = project.integrations?.shopify?.store;

  const selectableItems: MenuItem[] = isWorkspaceOwner
    ? filterNulls([
        {
          type: "leaf",
          id: "renameProject",
          title: "Rename",
          onSelect: () => {
            setIsRenameProjectModalOpen(true);
          },
        },
        {
          type: "leaf",
          id: "moveProject",
          title: "Move",
          onSelect: () => {
            if (project.ownerWorkspace?.id) {
              openModal({
                type: "moveProjectModal",
                props: {
                  projectId: project.id,
                  projectHasShopifyIntegration: Boolean(
                    project.integrations?.shopify,
                  ),
                  oldWorkspaceId: project?.ownerWorkspace?.id,
                  shopifyUrl: project.integrations?.shopify?.store.shopifyUrl,
                },
              });
            }
          },
        },
        shopifyData?.integrationId
          ? {
              type: "leaf",
              id: "removeShopifyIntegration",
              title: "Remove Shopify",
              onSelect: () =>
                openModal({
                  type: "removeShopifyIntegrationModal",
                  props: {
                    projectId: project.id,
                    integrationId: shopifyData.integrationId!,
                  },
                }),
            }
          : null,
        {
          type: "leaf",
          id: "deleteProject",
          title: "Delete",
          onSelect: () =>
            openModal({
              type: "deleteProjectModal",
              props: {
                projectId: project.id,
                from: "list",
              },
            }),
        },
      ])
    : [
        {
          type: "leaf",
          id: "renameProject",
          title: "Rename",
          onSelect: () => {
            setIsRenameProjectModalOpen(true);
          },
        },
      ];

  return (
    <>
      <div
        className={twMerge(
          classNames(
            "grid w-full grid-cols-12 items-center justify-center py-4 pr-4 gap-2 text-sm text-default",
            className,
          ),
        )}
      >
        <button
          type="button"
          onClick={onSelectProject}
          className="col-span-3 flex flex-row items-center gap-x-4 text-left"
          id={uniqueId}
        >
          {getProjectName(project)}
        </button>
        {showWorkspaceColumn ? (
          <button
            type="button"
            onClick={onSelectProject}
            className="col-span-3 flex flex-row items-center gap-x-4 text-left"
            aria-describedby={uniqueId}
            tabIndex={-1}
          >
            <span aria-hidden>{project.ownerWorkspace?.name ?? "-"}</span>
            <span className="sr-only">
              {project.ownerWorkspace?.name != null
                ? `Workspace ${project.ownerWorkspace?.name}`
                : "No workspace"}
            </span>
          </button>
        ) : null}
        {shouldShowCreatedAt && (
          <button
            type="button"
            onClick={onSelectProject}
            className="col-span-3 text-slate-400 flex"
            aria-describedby={uniqueId}
            tabIndex={-1}
          >
            <span className="sr-only">Created </span>
            {formatDistanceToNow(new Date(project.createdAt), {
              addSuffix: true,
            })}
          </button>
        )}
        <div
          className={twMerge(
            "flex flex-row items-center",
            showWorkspaceColumn ? "col-span-2" : "col-span-3",
          )}
        >
          {isShopifyIntegrationEnabled(project) ? (
            <Tooltip
              content={project.integrations?.shopify?.store.shopifyUrl}
              triggerAsChild
            >
              <div tabIndex={0}>
                <img
                  src="/images/shopify-logo.svg"
                  alt="Shopify logo"
                  aria-hidden
                />
                <span className="sr-only">
                  Shopify integration is available
                </span>
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              content="Shopify integration is not available"
              triggerAsChild
            >
              <div tabIndex={0}>
                <p className="ml-2" aria-hidden>
                  -
                </p>
                <span className="sr-only">
                  Shopify integration is not available
                </span>
              </div>
            </Tooltip>
          )}
        </div>
        {!hideEllipsisButton && (
          <div
            className={twMerge(
              "flex",
              showWorkspaceColumn ? "col-span-1" : "col-span-3",
            )}
          >
            <Menu
              align="end"
              size="sm"
              items={selectableItems}
              trigger={
                <MenuTrigger tooltipText="More options">
                  <IconButton
                    variant="tertiary"
                    size="sm"
                    icon={<BsThreeDots size={16} className="text-slate-400" />}
                    isPhonyButton
                    aria-hidden
                  />
                  <span className="sr-only">More options</span>
                </MenuTrigger>
              }
            />
          </div>
        )}
      </div>
      <Modal
        onRequestClose={() => setIsRenameProjectModalOpen(false)}
        isOpen={isRenameProjectModalOpen}
        className="w-[400px]"
      >
        <div className="flex flex-col gap-4">
          <p className="text-base font-medium">Rename Project</p>
          <LabeledControl label="Project Name" id="save-project-name">
            <Input
              size="base"
              autoFocus
              placeholder={project.name ?? "Enter project name..."}
              {...register("projectName")}
            />
          </LabeledControl>
          <Button
            variant="primary"
            size="lg"
            isFullWidth={true}
            onClick={(e) => {
              void handleSubmit(onSubmit)(e);
            }}
            isLoading={isSubmitting}
          >
            Save
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ProjectItem;
