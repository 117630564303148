import * as React from "react";

type StepImageProps = {
  src: string;
};

const StepImage: React.FC<StepImageProps> = ({ src }) => {
  return (
    <div className="col-span-7 xl:col-span-8 px-32 pt-40 lg:flex items-end hidden overflow-y-hidden">
      <img
        className="relative object-cover w-full h-full object-top overflow-visible"
        src={src}
        alt="Flow step image"
      />
    </div>
  );
};

export default StepImage;
