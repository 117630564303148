import type { FlowStepConfigPropsValueOf } from "schemas/flow";

import * as React from "react";

import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import StepTitle from "@components/flows/onboardingSteps/components/StepTitle";
import ErrorMessage from "@editor/components/account/Dashboard/ErrorMessage";
import Input from "@editor/components/common/designSystem/Input";
import SelectableButtons from "@editor/components/common/designSystem/SelectableButtons";
import { useGetCurrentStepResultsData } from "@editor/components/flows/hooks/useGetCurrentStepResultsData";
import useIdentifyOtherValue from "@editor/components/flows/hooks/useIdentifyOtherValue";
import { useRouterFlowCallbacks } from "@editor/components/flows/hooks/useRouterFlowCallbacks";
import FlowActionButtons from "@editor/components/flows/onboardingSteps/components/FlowActionButtons";
import OnboardingStepLayout, {
  OnboardingStepForm,
} from "@editor/components/flows/onboardingSteps/components/OnboardingStepsLayout";
import StepImage from "@editor/components/flows/onboardingSteps/components/StepImage";
import { processOtherOptions } from "@editor/components/flows/utils/processOtherOptions";

import { Controller, useForm, useWatch } from "react-hook-form";
import { isEmpty } from "replo-utils/lib/misc";

type FormValues = {
  industry: string;
  industryOther?: string;
};

const WhichBestDescribesYourIndustry: React.FC = () => {
  const { currentStep, submitStep } = useReploFlowsStepContext();
  const stepResultsData =
    useGetCurrentStepResultsData<"onboarding.user.which-best-describes-your-industry">();
  const stepProps =
    currentStep?.props as FlowStepConfigPropsValueOf<"onboarding.user.which-best-describes-your-industry">;

  const stepCommonOptions = stepProps.options.map((option) => option.value);
  const { otherValue: otherInitialValue, values: initialIndustryValues } =
    useIdentifyOtherValue(
      stepCommonOptions,
      stepResultsData?.industry ? [stepResultsData?.industry] : [],
    );
  const { submitOrSkipStepCallback: submitStepCallback } =
    useRouterFlowCallbacks();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      industry: initialIndustryValues[0],
      industryOther: otherInitialValue,
    },
  });
  const industryValue = useWatch({ control, name: "industry" });
  const industryOtherValue = useWatch({ control, name: "industryOther" });
  const isOtherSelected = industryValue === "other";

  const industryOtherError = errors.industryOther?.message;
  const shouldDisableNextButton =
    isEmpty(industryValue) ||
    (isOtherSelected &&
      (!Boolean(industryOtherValue) || Boolean(industryOtherError)));

  const onSubmit = ({ industry, industryOther }: FormValues) => {
    const [value] = processOtherOptions([industry], industryOther, stepProps);
    if (currentStep && value) {
      void submitStep(
        currentStep.id,
        currentStep.type,
        {
          industry: value,
        },
        ({ instance, nextStep }) =>
          submitStepCallback({
            nextStep: nextStep ?? null,
            flowSlug: instance.flow.slug,
          }),
      );
    }
  };

  return (
    <OnboardingStepLayout
      rightPanelContent={<StepImage src="/images/flows/person-logo.png" />}
    >
      <OnboardingStepForm
        onSubmit={(data) => {
          void handleSubmit(onSubmit)(data);
        }}
      >
        <div className="flex flex-col gap-14">
          <div>
            <StepTitle>
              Which Best Describes Your Industry?
              <span className="text-red-600">*</span>
            </StepTitle>
            <Controller
              name="industry"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectableButtons
                  multiSelect={false}
                  options={stepProps.options ?? []}
                  value={[value]}
                  onChange={(value) => onChange(value[0] ?? "")}
                  className="my-4"
                  textClassName="font-normal"
                  unselectedClassName="border border-slate-200 bg-transparent text-default hover:border-blue-600 hover:bg-blue-200 hover:text-blue-600"
                  selectedClassName="border border-blue-600 bg-blue-200 text-blue-600 hover:bg-blue-300"
                  inputEnhancer={
                    isOtherSelected ? (
                      <div className="flex w-full flex-col gap-2">
                        <Controller
                          name="industryOther"
                          control={control}
                          rules={{
                            required: "Please enter a value.",
                            pattern: {
                              value: /^[\d A-Za-z]+$/,
                              message:
                                "Please enter only alphanumeric characters and whitespaces.",
                            },
                          }}
                          render={({ field: { onChange, value, name } }) => (
                            <div className="w-full">
                              <Input
                                type="text"
                                size="base"
                                autoFocus
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                                name={name}
                                validityState={
                                  Boolean(industryOtherError)
                                    ? "invalid"
                                    : "valid"
                                }
                                aria-invalid={
                                  Boolean(industryOtherError)
                                    ? "true"
                                    : undefined
                                }
                                aria-describedby={
                                  Boolean(industryOtherError)
                                    ? "error-building-for"
                                    : undefined
                                }
                              />
                            </div>
                          )}
                        />
                        <ErrorMessage
                          id="error-building-for"
                          error={errors.industryOther?.message}
                        />
                      </div>
                    ) : null
                  }
                />
              )}
            />
          </div>
        </div>
        <FlowActionButtons shouldDisableNextButton={shouldDisableNextButton} />
      </OnboardingStepForm>
    </OnboardingStepLayout>
  );
};

export default WhichBestDescribesYourIndustry;
