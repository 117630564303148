import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const rechargeSubscriptionWidget: ComponentTemplate = {
  id: "a6221042-a3de-4b28-848d-4a19e206ab0f",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Recharge Subscription Widget",
  leftBarCategory: { main: "productIntegrations", subCategory: "subscription" },
  preview: "integrations/rechargeWidget.svg",
  transforms: [],
  template: {
    id: "$uid",
    type: "rechargeSubscriptionWidget",
    props: {},
  },
};
