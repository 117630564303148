/**
 * Enumerates slides from left to right.
 */
export const LTR = "ltr";

/**
 * Enumerates slides from right to left.
 */
export const RTL = "rtl";

/**
 * Enumerates slides in a col.
 */
export const TTB = "ttb";
