import type { Component } from "schemas/component";
import type {
  ProductRefOrDynamic,
  SelectedSellingPlanIdOrOneTimePurchase,
} from "schemas/product";
import type { FormatCurrencyFunction } from "../../../shared/hooks/use-shopify-money-format";
import type {
  RenderComponentProps,
  ReploShopifyOption,
  ReploShopifyOptionKey,
  ReploShopifyProduct,
  ReploShopifyVariant,
  SelectedOptionValuesMapping,
  ShopifySellingPlan,
} from "../../../shared/types";
import type { GlobalWindow } from "../../../shared/Window";
import type { ActionType } from "./config";

import * as React from "react";

import mapValues from "lodash-es/mapValues";
import { round } from "replo-utils/lib/math";
import { exhaustiveSwitch, isNotNullish } from "replo-utils/lib/misc";

import { useShopifyMoneyFormat } from "../../../shared/hooks/use-shopify-money-format";
import { getDefaultSelectedVariant } from "../../../shared/mappers/product";
import {
  GlobalWindowContext,
  RenderEnvironmentContext,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { mergeContext } from "../../../shared/utils/context";
import { getFromRecordOrNull, mapNull } from "../../../shared/utils/optional";
import { getAlchemyEditorWindow } from "../../../shared/Window";
import { useCanUseLiquid } from "../../hooks/useCanUseLiquid";
import { useConsistentLiquidId } from "../../hooks/useConsistentLiquidId";
import { getProduct, isProductRef } from "../../ReploProduct";
import { fakeSwatches } from "../../utils/fakeSwatches";
import {
  enhanceVariantsAndOptions,
  resolveProductSellingPlans,
  selectedVariantFromOptionValues,
} from "../../utils/product";
import { ReploComponent } from "../ReploComponent";
import ReploLiquidChunk, {
  wrapStringWithLiquidChunks,
} from "../ReploLiquid/ReploLiquidChunk";

type ReducerState = {
  product: ReploShopifyProduct | null;
  selectedVariantId: number | null;
  selectedSellingPlanId: SelectedSellingPlanIdOrOneTimePurchase | null;
  selectedOptionValues: SelectedOptionValuesMapping | null;
  quantity: number;
};

type ReducerActionSetVariantId = {
  type: "setActiveVariant";
  payload: number | null;
};

type ReducerActionSetActiveOptionValue = {
  type: "setActiveOptionValue";
  payload: { label: string; value: string };
};

type ReducerActionIncreaseQuantity = {
  type: "increaseQuantity";
  payload: { quantity: number };
};

type ReducerActionSetActiveSellingPlan = {
  type: "setActiveSellingPlan";
  payload: {
    sellingPlanId: SelectedSellingPlanIdOrOneTimePurchase | null;
  } | null;
};

type ReducerActionDecreaseQuantity = {
  type: "decreaseQuantity";
  payload: { quantity: number };
};

type ReducerActionSetQuantity = {
  type: "setQuantity";
  payload: { quantity: number };
};

type ReducerActionUpdateCurrentProduct = {
  type: "updateCurrentProduct";
  payload: {
    product: ReploShopifyProduct;
    variantId: ReploShopifyVariant["id"] | null;
  };
};

type ReducerAction =
  | ReducerActionSetVariantId
  | ReducerActionSetActiveOptionValue
  | ReducerActionIncreaseQuantity
  | ReducerActionDecreaseQuantity
  | ReducerActionSetQuantity
  | ReducerActionUpdateCurrentProduct
  | ReducerActionSetActiveSellingPlan;

const formatDisplayPriceInVariantsBySellingPlan = (
  variants: ReploShopifyVariant[],
  args: {
    selectedSellingPlan: ShopifySellingPlan | null;
    currencyCode: string;
    formatCurrency: FormatCurrencyFunction;
    quantity?: number;
  },
) => {
  const {
    formatCurrency,
    currencyCode,
    selectedSellingPlan,
    quantity = 1,
  } = args;
  if (isNotNullish(selectedSellingPlan)) {
    return variants.map((variant) => {
      let price = Number(variant.price);
      // Note (Fran, 2023-02-21): Here we calculate the price if the user selected a selling plan.
      // There could be more than one adjustment: the first adjustment is the initial one for the first order
      // and the second is for future orders. We need to calculate the price only with the first adjustment
      // https://shopify.dev/docs/api/storefront/2022-07/objects/sellingplanpriceadjustment
      // https://shopify.dev/docs/api/storefront/2022-07/unions/SellingPlanPriceAdjustmentValue
      const firstAdjustmentPrice = selectedSellingPlan.priceAdjustments[0];
      if (isNotNullish(firstAdjustmentPrice)) {
        price = exhaustiveSwitch({ type: firstAdjustmentPrice.value_type })({
          percentage: price - price * (firstAdjustmentPrice.value / 100),
          fixed_amount: price - firstAdjustmentPrice.value,
          price: firstAdjustmentPrice.value,
        });
      }

      const roundedVariantPrice = round(price, 0);
      const compareAtPrice = variant.compareAtPrice;
      const compareAtPriceNumber = Number(compareAtPrice);
      const roundedCompareAtPrice = round(compareAtPriceNumber, 0);

      return {
        ...variant,
        price: formatCurrency(price, { currencyCode, hideSymbol: true }),
        priceRounded: formatCurrency(roundedVariantPrice, {
          currencyCode,
          showCents: false,
          hideSymbol: true,
        }),
        displayPrice: formatCurrency(price * quantity, { currencyCode }),
        displayPriceRounded: formatCurrency(roundedVariantPrice * quantity, {
          currencyCode,
          showCents: false,
        }),
        compareAtPriceDifference: compareAtPrice
          ? formatCurrency(compareAtPriceNumber * quantity - price * quantity, {
              currencyCode,
              hideSymbol: true,
            })
          : null,
        compareAtPriceDifferenceRounded: roundedCompareAtPrice
          ? formatCurrency(
              round(roundedCompareAtPrice * quantity - price * quantity, 0),
              { currencyCode, showCents: false, hideSymbol: true },
            )
          : null,
        compareAtDisplayPriceDifference: formatCurrency(
          compareAtPrice
            ? compareAtPriceNumber * quantity - price * quantity
            : 0,
          { currencyCode, zeroString: "0" },
        ),
        compareAtDisplayPriceDifferenceRounded: roundedCompareAtPrice
          ? formatCurrency(
              roundedCompareAtPrice * quantity - price * quantity,
              {
                currencyCode,
                showCents: false,
                zeroString: "0",
              },
            )
          : null,
      };
    });
  }
  return variants;
};

const Product = (props: RenderComponentProps) => {
  const { component, componentAttributes, context, extraAttributes } = props;

  const { repeatedIndexPath } = context;

  const contextProductsAttributes = context.attributes?._product;
  const autoSelectVariant = context.attributes?._autoSelectVariant;
  const defaultSelectedVariantId =
    context.attributes?._defaultSelectedVariantId;
  const defaultSelectedSellingPlanId =
    component.props._defaultSelectedSellingPlanId ?? null;
  const componentProducts = component.props._product;
  const useVariantUrlParameter =
    component.props._useVariantUrlParameter ?? true;

  const productRef = componentProducts || contextProductsAttributes;

  const { formatCurrency } = useShopifyMoneyFormat();
  const canUseLiquid = useCanUseLiquid();
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const globalWindow = useRuntimeContext(GlobalWindowContext);
  const {
    fakeProducts,
    activeCurrency,
    activeLanguage,
    moneyFormat,
    templateProduct,
  } = useRuntimeContext(ShopifyStoreContext);
  const products = useRuntimeContext(RuntimeHooksContext).useShopifyProducts();
  const productMetafieldValues =
    useRuntimeContext(RuntimeHooksContext).useShopifyProductMetafieldValues();
  const variantMetafieldValues =
    useRuntimeContext(RuntimeHooksContext).useShopifyVariantMetafieldValues();
  const swatchesFromStore =
    useRuntimeContext(RuntimeHooksContext).useSwatches();
  const swatches = React.useMemo(
    () => [...(swatchesFromStore ?? []), ...fakeSwatches],
    [swatchesFromStore],
  );

  // NOTE (Matt 2024-03-20): If sectionSettings is enabled, we need to check if
  // the user has selected a different product for this corresponding component.
  // If so, we overrided the productRef that is passed to `productFromProps`.
  // NOTE (Matt 2024-03-20): commenting this out until we can figure out a way
  // to include this that doesn't allow users to create PDPs as sections.
  //
  //   const { useSectionSettings } = useRuntimeContext(ReploElementContext);

  //   if (useSectionSettings && sectionSettings?.[`replo-${component.id}`]) {
  //     const sectionProduct = extraProducts.find(
  //       (product) => product.handle == sectionSettings?.[`replo-${component.id}`],
  //     );
  //     if (sectionProduct) {
  //       productRef = { productId: sectionProduct.id };
  //     }
  //   }

  // TODO (Noah, 2022-12-16, REPL-5599): In order for the prices to be correct for
  // variants in dynamic data with quantity selectors, we need to pass the quantity
  // in the productRef here (which doesn't make sense, quantity shouldn't be part of
  // product ref anyway). However, we need the product to pass it to useProductState.
  // Probably useProductState should be refactored to take the product ref and select
  // the default quantity, but until then we pass one copy of the product to useProductState,
  // and use another copy with the correct quantity
  const productFromProps = React.useMemo(() => {
    return getProduct(productRef, context, {
      products,
      templateProduct,
      currencyCode: activeCurrency,
      language: activeLanguage,
      moneyFormat,
      productMetafieldValues,
      variantMetafieldValues,
      isEditor: isEditorApp,
      fallbackStrategy: { type: "defaultProduct" },
      fakeProducts,
    });
  }, [
    activeCurrency,
    activeLanguage,
    context,
    products,
    fakeProducts,
    isEditorApp,
    moneyFormat,
    productMetafieldValues,
    productRef,
    templateProduct,
    variantMetafieldValues,
  ]);

  const [
    {
      selectedVariantId,
      selectedOptionValues,
      quantity,
      selectedSellingPlanId,
      product: productFromReducer,
    },
    dispatch,
  ] = useProductState(productFromProps, {
    autoSelectVariant,
    defaultSelectedVariantId,
    defaultSelectedSellingPlanId,
    useVariantUrlParameter,
    globalWindow,
    initialQuantity: props.component.props._defaultQuantity ?? 1,
  });

  // Note (Noah, 2024-05-29): In order to add the ?variant= param which Shopify
  // themes generally use to make product variants linkable, we need to check wheher
  // this is a product template element, and that this product component is showing
  // the product from the template. If so, whenever the seletedVariantId changes
  // we update the URL.
  const elementType = context.elementType;
  const isTemplateProductRef =
    context.isInsideTemplateProductComponent && !context.disableLiquid;
  React.useEffect(() => {
    if (
      selectedVariantId &&
      elementType === "shopifyProductTemplate" &&
      isTemplateProductRef &&
      // Note (Noah, 2021-11-18): Don't try to set query parameters in the
      // editor since the pushState will fail due to our iframe setup (can't
      // access cross-origin window). This should hopefully be fixed when
      // proxy/mirror support is implemented
      !isEditorApp
    ) {
      updateQueryParameterWithTags([
        { field: "variant", value: String(selectedVariantId) },
      ]);
    }
  }, [elementType, isEditorApp, isTemplateProductRef, selectedVariantId]);

  const product = React.useMemo(() => {
    return getProduct(
      productFromReducer && selectedVariantId
        ? {
            productId: productFromReducer.id,
            variantId: selectedVariantId,
            quantity: quantity,
          }
        : productRef,
      context,
      {
        products: products,
        templateProduct,
        currencyCode: activeCurrency,
        language: activeLanguage,
        moneyFormat,
        productMetafieldValues,
        variantMetafieldValues,
        isEditor: isEditorApp,
        fallbackStrategy: { type: "defaultProduct" },
        fakeProducts,
        // Note (Noah, 2023-12-04, USE-588): In order for updateCurrentProduct
        // to work with products which have the "template product" set, we have
        // to figure out if the product has changed. If it has, we can assume we
        // need to do product resolution normally - that is, pass in false
        // to this param, which is isInsideTemplateProductComponentOverride.
        // Otherwise, we pass null, which means that we'll use whatever value
        // we have from our context which will be true if we're inside a template
        // product component.
        isInsideTemplateProductComponentOverride:
          productFromReducer &&
          productFromProps &&
          productFromReducer.id !== productFromProps.id
            ? false
            : null,
      },
    );
  }, [
    activeCurrency,
    activeLanguage,
    context,
    products,
    fakeProducts,
    isEditorApp,
    moneyFormat,
    productFromProps,
    productFromReducer,
    productMetafieldValues,
    productRef,
    quantity,
    selectedVariantId,
    templateProduct,
    variantMetafieldValues,
  ]);

  /**
   * Note (Noah, 2022-12-31, REPL-5765): when the product from props changes (i.e. a new product is selected
   * in the editor, or when the dynamic data value of the prop changes, like in a Tabs component or something),
   * we need to update the product in the reducer. This effectively functions as a useOverridableState, but
   * for the useReducer.
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: missing deps in effect
  React.useEffect(() => {
    const productFromPropsHasUpdated =
      productFromProps &&
      productFromReducer &&
      productFromReducer.id !== productFromProps.id;
    if (productFromPropsHasUpdated) {
      dispatch({
        type: "updateCurrentProduct",
        payload: { product: productFromProps, variantId: null },
      });
    }
    // Note (Noah, 2022-12-31): We specifically don't want this effect to run when the productFromReducer
    // changes, because that would possibly be an infinite loop, so disabling the lint rule here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFromProps?.id]);

  const { variants, options } = React.useMemo(() => {
    return enhanceVariantsAndOptions({
      product,
      variantMetafieldValues,
      swatches,
      selectedOptionValues,
      showOptionsNotSoldTogether: true,
    });
  }, [product, variantMetafieldValues, swatches, selectedOptionValues]);

  const selectedOptionValuesWithData = React.useMemo(() => {
    if (!selectedOptionValues) {
      return null;
    }

    return mapValues(selectedOptionValues, (value, key) => {
      const option = options.find((o) => o.name === key);
      return option?.values?.find((v) => v.title === value);
    });
  }, [selectedOptionValues, options]);

  const { sellingPlans, selectedSellingPlan } = React.useMemo(() => {
    return resolveProductSellingPlans(
      product,
      variants.find((v) => String(v.id) === String(selectedVariantId)),
      selectedSellingPlanId,
    );
  }, [product, variants, selectedVariantId, selectedSellingPlanId]);

  const formattedVariantsDisplayPricesBySellingPlan = React.useMemo(() => {
    return formatDisplayPriceInVariantsBySellingPlan(variants, {
      selectedSellingPlan,
      currencyCode: activeCurrency,
      quantity,
      formatCurrency,
    });
  }, [variants, selectedSellingPlan, activeCurrency, quantity, formatCurrency]);

  const selectedVariant = React.useMemo(() => {
    return formattedVariantsDisplayPricesBySellingPlan.find(
      (v) => String(v.id) === String(selectedVariantId),
    );
  }, [formattedVariantsDisplayPricesBySellingPlan, selectedVariantId]);

  // Note (Noah, 2024-06-12): Whenever the initial quantity from our props changes in the editor,
  // we want the quantity to update so there's an indication to the user that the quantity was updated
  React.useEffect(() => {
    if (isEditorApp) {
      dispatch({
        type: "setQuantity",
        payload: {
          quantity: props.component.props._defaultQuantity ?? 1,
        },
      });
    }
  }, [dispatch, props.component.props._defaultQuantity, isEditorApp]);

  const updateCurrentProduct = React.useCallback(
    (productRef: ProductRefOrDynamic) => {
      const product = getProduct(
        productRef
          ? {
              ...productRef,
              quantity: quantity,
            }
          : productRef,
        context,
        {
          products: products,
          currencyCode: activeCurrency,
          language: activeLanguage,
          moneyFormat,
          productMetafieldValues,
          variantMetafieldValues,
          isEditor: isEditorApp,
          fallbackStrategy: { type: "defaultProduct" },
          fakeProducts,
          // Note (Noah, 2023-12-04, USE-588): Always assume we're NOT
          // inside a template product for this product resolution, because
          // if we assume we ARE, then we'll always return the template product
          // and not whichever product is referenced by the new ref, which
          // will mean the product will not change.
          isInsideTemplateProductComponentOverride: false,
          templateProduct,
        },
      );
      if (!product) {
        console.warn(
          "[Replo] Could not find product to set, the page may not behave correctly. Please contact support@replo.app",
          { productRef },
        );
        return;
      }
      dispatch({
        type: "updateCurrentProduct",
        payload: {
          product,
          variantId: isProductRef(productRef)
            ? Number(productRef.variantId) ?? null
            : null,
        },
      });
    },
    [
      context,
      dispatch,
      products,
      fakeProducts,
      isEditorApp,
      activeCurrency,
      activeLanguage,
      moneyFormat,
      productMetafieldValues,
      quantity,
      templateProduct,
      variantMetafieldValues,
    ],
  );

  const componentId = component.id;
  const dataReploId = componentAttributes["data-rid"];

  const nextContext = React.useMemo(() => {
    const actionHooks = {
      setActiveVariant: (variantId: number) => {
        dispatch({
          type: "setActiveVariant",
          payload: variantId,
        });
      },
      setActiveOptionValue: (payload: { label: string; value: string }) => {
        dispatch({
          type: "setActiveOptionValue",
          payload,
        });
      },
      setActiveSellingPlan: (payload: {
        sellingPlanId: SelectedSellingPlanIdOrOneTimePurchase | null;
      }) => {
        dispatch({
          type: "setActiveSellingPlan",
          payload,
        });
      },
      increaseProductQuantity: (quantity: number) =>
        dispatch({
          type: "increaseQuantity",
          payload: { quantity },
        }),
      setProductQuantity: (quantity: number) =>
        dispatch({
          type: "setQuantity",
          payload: { quantity },
        }),
      decreaseProductQuantity: (quantity: number) =>
        dispatch({
          type: "decreaseQuantity",
          payload: { quantity },
        }),
      updateCurrentProduct,
    } satisfies {
      [K in ActionType]: Function;
    };

    return mergeContext(context, {
      attributes: {
        // Note (Noah, 2022-12-31): Since the product could have changed e.g. using a SetProduct action,
        // reset the dynamic data value so that children components show data for the correct product
        _product: product,
        _variants: formattedVariantsDisplayPricesBySellingPlan,
        _variant: selectedVariant,
        _options: options,
        _optionsValues: product?.optionsValues,
        _selectedOptionValues: selectedOptionValuesWithData,
        _quantity: quantity.toString(),
        _selectedSellingPlan: selectedSellingPlan,
        _sellingPlans: sellingPlans,
      },
      attributeKeyToComponentId: {
        _product: componentId,
        _variant: componentId,
        _variants: componentId,
        _options: componentId,
        _optionsValues: componentId,
        _selectedOptionValues: componentId,
        _quantity: componentId,
        _selectedSellingPlan: componentId,
        _sellingPlans: componentId,
      },
      state: {
        product: {
          product,
          selectedVariant,
          selectedOptionValues,
          quantity,
          selectedSellingPlan: {
            id: mapNull(selectedSellingPlan?.id ?? null, Number),
          },
          sellingPlans,
        },
        productWrapperComponentId: dataReploId,
      },
      actionHooks,
    });
  }, [
    componentId,
    context,
    dataReploId,
    dispatch,
    formattedVariantsDisplayPricesBySellingPlan,
    options,
    product,
    quantity,
    selectedOptionValues,
    selectedOptionValuesWithData,
    selectedSellingPlan,
    selectedVariant,
    sellingPlans,
    updateCurrentProduct,
  ]);

  const consistentLiquidId = useConsistentLiquidId();

  if (!product) {
    return null;
  }

  let productId: string | number = product.id;
  let variantId: string | number | undefined = selectedVariantId ?? undefined;
  let productHandle: string = product.handle;

  const productComponentWillUseLiquid = canUseLiquid && !context.disableLiquid;

  // NOTE (Gabe 2024-01-12): We overwrite the liquid product so that
  // sub-components can use liquid to ensure that the latest up to date data
  // from Shopify is utilized and so that image urls don't change on hydration.
  // This should only be done during publishing. We only need to overwrite the
  // liquid product in product templates if it's a static product (aka not the
  // template product).
  // NOTE (Matt 2024-03-29): There are other liquid variables that we set
  // (ie reploSelectedVariant, reploSortedSellingPlans, etc.). We need to overwrite
  // those as well so that there can be child-product components.
  const overwriteLiquidProduct =
    productComponentWillUseLiquid && !context.isInsideTemplateProductComponent;

  if (productComponentWillUseLiquid) {
    productId = wrapStringWithLiquidChunks("{{product.id}}");
    variantId = wrapStringWithLiquidChunks("{{reploSelectedVariant.id}}");
    productHandle = wrapStringWithLiquidChunks("{{product.handle}}");
  }
  return (
    <>
      {overwriteLiquidProduct && (
        <ReploLiquidChunk>
          {`{% assign reploOriginalProduct${consistentLiquidId} = product %}`}
          {`{% capture productHandle %}${product.handle}{% endcapture %}`}
          {`{% assign product = all_products[productHandle] %}`}
          {`{% assign reploOriginalProductVariant${consistentLiquidId} = reploSelectedVariant %}`}
          {`{% assign reploSelectedVariant = blank %}`}
          {`{% assign reploOriginalSPG${consistentLiquidId} = reploSortedSellingPlans %}`}
          {`{% assign reploSortedSellingPlans = blank %}`}
          {`{% assign reploOriginalComparePricePercent${consistentLiquidId} = reploCompareAtPriceDifferencePercentage %}`}
          {`{% assign reploCompareAtPriceDifferencePercentage = blank %}`}
        </ReploLiquidChunk>
      )}
      {/* NOTE (Gabe 2024-01-11): this handles the case where a user selects a default
          product variant in replo that is not the same as the one defined in Shopify

          NOTE (Matt 2024-03-07): We also need to handle reordering of selling plans
          in liquid to ensure that the selling plans are in the same order that pre
          and post hydration. That is what the reploSortedSellingPlans array logic is for.
          In order to do this, we have to create an array, which liquid does not natively
          allow. However, you can get around it by splitting an empty string. We unfortunately
          need to capture the empty string because we can't use `"` in our react liquid.
          */}
      {productComponentWillUseLiquid && (
        <ReploLiquidChunk>
          {`{% capture reploVariantIdString %}${defaultSelectedVariantId}{% endcapture %}`}
          {`{% capture reploSellingPlanIdString %}${defaultSelectedSellingPlanId}{% endcapture %}`}
          {`{% capture reploIdKey %}id{% endcapture %}`}
          {`{% capture spKey %}selling_plan{% endcapture %}`}
          {`{%- liquid
              assign reploVariantId = reploVariantIdString | times: 1
              assign reploSelectedVariant = product.variants | where: reploIdKey, reploVariantId | first
              if reploSelectedVariant == blank
                assign reploSelectedVariant = product.selected_or_first_available_variant
              endif
              if product.selling_plan_groups[0]
                assign reploSellingPlanId = reploSellingPlanIdString | times: 1
                assign reploAllSellingPlans = reploSelectedVariant.selling_plan_allocations | map: spKey
                assign reploSortedSellingPlans = reploAllSellingPlans | sort: reploIdKey
                assign reploSelectedSellingPlan = reploSortedSellingPlans | where: reploIdKey, reploSellingPlanID | first
              endif
            -%}`}

          {/* NOTE (Matt 2024-01-22):  In order to reduce the risk of liquid errors and also ensure that
           the prehydrated liquid output matches the hydrated element, we need to assign variables for the
           compare price formats here that can then be referenced elsewhere. When compare price doesn't exist,
           these variables are all blank and the difference percentage is 0.*/}
          {`{% assign reploCompareAtPriceDifferencePercentage = 0 %}`}
          {`{% if reploSelectedVariant.compare_at_price != blank %}`}
          {`{% assign reploCompareAtPriceDifferencePercentage = reploSelectedVariant.compare_at_price | minus: reploSelectedVariant.price | at_least: 0 | times: 100.0 | divided_by: reploSelectedVariant.compare_at_price | floor %}`}
          {`{% endif %}`}
        </ReploLiquidChunk>
      )}
      <div
        {...componentAttributes}
        data-replo-product-container={productId}
        data-replo-product-handle={productHandle}
      >
        {/* Note (Noah, 2022-07-28, REPL-3148): Set this product-form to
      display: none since it can cause incorrect layouts if the product
      component has flex-gap set.
      Note (Chance, 2023-05-19) This makes sense as an inline style since
      we always want it to be hidden. It'll make it more difficult to
      accidentally override. */}
        {/* @ts-expect-error */}
        <product-form style={{ display: "none" }}>
          <form
            id={`product-form-${productId}`}
            method="post"
            data-productid={productId}
            encType="multipart/form-data"
            action="/cart/add"
            data-type="add-to-cart-form"
          >
            <input
              type="hidden"
              name="id"
              data-productid={productId}
              value={variantId}
            />
            <input type="hidden" name="quantity" value={quantity} />
          </form>
          {/* @ts-expect-error */}
        </product-form>
        {component.children?.map((child: Component): any => (
          <ReploComponent
            key={child.id}
            component={child}
            context={nextContext}
            repeatedIndexPath={repeatedIndexPath ?? ".0"}
            extraAttributes={extraAttributes}
          />
        ))}
        {overwriteLiquidProduct && (
          <ReploLiquidChunk>
            {`{% assign product = reploOriginalProduct${consistentLiquidId} %}`}
            {`{% assign reploSelectedVariant = reploOriginalProductVariant${consistentLiquidId} %}`}
            {`{% assign reploSortedSellingPlans = reploOriginalSPG${consistentLiquidId} %}`}
            {`{% assign reploCompareAtPriceDifferencePercentage = reploOriginalComparePricePercent${consistentLiquidId} %}`}
          </ReploLiquidChunk>
        )}
      </div>
    </>
  );
};

/**
 * Generate a mapping of option name -> option value for the given product. Uses the default variant
 * to build this mapping, which defaults to the first variant if autoSelectVariant is not passed.
 * If existingSelectedOptionValues is passed, then this will prefer to keep the existing values
 * selected (useful for when product changes from one product to another but they have option values
 * in common)
 */
function getInitialSelectedOptionValues(
  options: ReploShopifyOption[],
  defaultVariant: ReploShopifyVariant | null,
  existingSelectedOptionValues: SelectedOptionValuesMapping | null,
): SelectedOptionValuesMapping {
  if (!defaultVariant) {
    return {};
  }

  const newOptionNameToOptionValue: SelectedOptionValuesMapping = {};
  options.forEach((option, index) => {
    const existingOptionNameToMatchAgainst = Object.keys(
      existingSelectedOptionValues ?? {},
    ).find((existingOptionName) => {
      // Note (Fran, 2023-02-27): Besides checking that the option exists,
      // we need to check if the selected option value exists inside the chosen option.
      // E.g., One product can have some colors that other products doesn't.
      if (existingOptionName === option.name) {
        option.values?.forEach((optionValue) => {
          return (
            optionValue.title ===
            existingSelectedOptionValues?.[existingOptionName]
          );
        });
      }
      return false;
    });
    const existingOptionValue = getFromRecordOrNull(
      existingSelectedOptionValues,
      existingOptionNameToMatchAgainst,
    );
    newOptionNameToOptionValue[option.name] =
      existingOptionValue ??
      defaultVariant[`option${index + 1}` as ReploShopifyOptionKey] ??
      null;
  });
  return newOptionNameToOptionValue;
}

function useProductState(
  product: ReploShopifyProduct | null,
  args: {
    autoSelectVariant: boolean;
    defaultSelectedVariantId: number;
    defaultSelectedSellingPlanId: SelectedSellingPlanIdOrOneTimePurchase | null;
    useVariantUrlParameter: boolean | null;
    globalWindow: GlobalWindow | null;
    initialQuantity: number;
  },
): [ReducerState, React.Dispatch<ReducerAction>] {
  const {
    autoSelectVariant,
    defaultSelectedVariantId,
    defaultSelectedSellingPlanId,
    useVariantUrlParameter,
    globalWindow,
    initialQuantity,
  } = args;
  const defaultVariant = getDefaultSelectedVariant(
    product,
    autoSelectVariant,
    defaultSelectedVariantId,
  );
  const defaultVariantId = defaultVariant?.id ?? null;

  // Product state managed in a single reducer.
  const [state, dispatch] = React.useReducer(
    (state: ReducerState, action: ReducerAction) => {
      return exhaustiveSwitch(action)({
        updateCurrentProduct: ({ payload: { product, variantId } }) => {
          const defaultVariant = getDefaultSelectedVariant(
            product,
            autoSelectVariant,
            defaultSelectedVariantId,
          );
          const newVariant =
            mapNull(variantId, (variantId) =>
              product.variants.find((v) => v.id === variantId),
            ) ?? defaultVariant;

          const newState = {
            ...state,
            product,
            selectedVariantId: newVariant?.id ?? null,
            // Note (Noah, 2022-12-31): If the previous product and current product have option values in
            // common, try to preserve the ones that were previously selected
            selectedOptionValues: getInitialSelectedOptionValues(
              product.options,
              newVariant,
              state.selectedOptionValues,
            ),
          };
          return newState;
        },
        setActiveSellingPlan: ({ payload }) => {
          return {
            ...state,
            selectedSellingPlanId: payload?.sellingPlanId ?? null,
          };
        },
        setActiveVariant: ({ payload }) => {
          if (!payload) {
            return {
              ...state,
              selectedVariantId: null,
              selectedOptionValues: {},
            };
          }

          const selectedVariant = state.product?.variants.find(
            // Note (Noah, 2022-03-27): convert to strings, since in liquid
            // products the ids are integers, but in GraphQL they're strings
            (v) => payload && String(v.id) === String(payload),
          );
          if (!selectedVariant) {
            return state;
          }

          const newState = {
            ...state,
            selectedVariantId: payload,
            selectedOptionValues: Object.fromEntries(
              product?.options.map((option, i) => [
                option.name,
                selectedVariant?.[`option${i + 1}` as ReploShopifyOptionKey] ??
                  null,
              ]) ?? [],
            ),
          };

          return newState;
        },
        setActiveOptionValue: ({ payload }) => {
          let selectedOptionValues = {
            ...state.selectedOptionValues,
            [payload.label]: payload.value,
          };

          let selectedVariant: ReploShopifyVariant | null = null;

          if (state.product) {
            selectedVariant = selectedVariantFromOptionValues(
              state.product,
              selectedOptionValues,
              { [payload.label]: payload.value },
            );

            if (selectedVariant) {
              selectedOptionValues = Object.fromEntries(
                state.product.options.map((option, i) => [
                  option.name,
                  selectedVariant?.[
                    `option${i + 1}` as ReploShopifyOptionKey
                  ] ?? null,
                ]) ?? [],
              );
            }
          }

          return {
            ...state,
            selectedOptionValues,
            selectedVariantId: selectedVariant
              ? Number.parseInt(String(selectedVariant.id), 10)
              : null,
          };
        },
        increaseQuantity: ({ payload }) => {
          return {
            ...state,
            quantity: state.quantity + payload.quantity,
          };
        },
        decreaseQuantity: ({ payload }) => {
          const newQuantity = state.quantity - payload.quantity;
          return {
            ...state,
            quantity: Math.max(1, newQuantity),
          };
        },
        setQuantity: ({ payload }) => {
          return {
            ...state,
            quantity: Math.max(payload.quantity, 1),
          };
        },
      });
    },
    {
      product,
      selectedVariantId: defaultVariantId,
      selectedOptionValues: getInitialSelectedOptionValues(
        product?.options ?? [],
        defaultVariant,
        null,
      ),
      selectedSellingPlanId: defaultSelectedSellingPlanId,
      quantity: initialQuantity,
    },
  );

  // Set proper initial values if possible.
  // biome-ignore lint/correctness/useExhaustiveDependencies: missing deps in effect
  React.useEffect(() => {
    if (!product) {
      return;
    }
    const _window = getAlchemyEditorWindow() ?? globalWindow;
    const variantFromLocation = _window
      ? getInitialValueFromLocation(_window, "variant")
      : null;
    const parsedVariantFromLocation = variantFromLocation
      ? Number.parseInt(variantFromLocation, 10)
      : null;
    if (
      useVariantUrlParameter &&
      variantFromLocation &&
      product.variants.some(
        (variant) => variant.variantId === parsedVariantFromLocation,
      )
    ) {
      dispatch({
        type: "setActiveVariant",
        payload: parsedVariantFromLocation,
      });
    } else if (defaultVariantId) {
      dispatch({
        type: "setActiveVariant",
        payload: defaultVariantId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    product?.id,
    globalWindow,
    autoSelectVariant,
    defaultVariantId,
    useVariantUrlParameter,
  ]);

  React.useEffect(() => {
    dispatch({
      type: "setActiveSellingPlan",
      payload: { sellingPlanId: defaultSelectedSellingPlanId },
    });
  }, [defaultSelectedSellingPlanId]);

  // When autoSelectProductVariant is changed (in the editor) we need to sync
  // it with the reducer state
  React.useEffect(() => {
    if (!autoSelectVariant) {
      dispatch({
        type: "setActiveVariant",
        payload: null,
      });
    }
  }, [autoSelectVariant]);

  return [state, dispatch];
}

export default Product;

function getInitialValueFromLocation(globalWindow: GlobalWindow, key: string) {
  const params = new URLSearchParams(globalWindow.location.search.slice(1));
  return params.get(key);
}

/**
 * Update the current WINDOW query parameters with tags iff they are different,
 * otherwise no-op. This does NOT refresh the page
 */
function updateQueryParameterWithTags(
  tags: { field: string; value: string }[],
) {
  // NOTE (Chance 2024-05-20): Global window is what we want here since it's the
  // basis for the location and we only run this outside of the editor.
  // biome-ignore lint/style/noRestrictedGlobals: allow window
  const next = new URL(window.location as any).searchParams;
  for (const tag of tags) {
    if (!tag.field || !tag.value) {
      continue;
    }
    next.set(tag.field, tag.value);
  }
  next.sort();
  // NOTE (Chance 2024-05-20): Global window is what we want here since it's the
  // basis for the location and we only run this outside of the editor.
  // biome-ignore lint/style/noRestrictedGlobals: allow window
  const current = new URL(window.location as any).searchParams;
  current.sort();

  if (next === current) {
    return null;
  }

  return history.pushState("", "", `?${next.toString()}`);
}
