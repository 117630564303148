import * as React from "react";

/**
 * Hook which uses either local state or outside controlled state depending on
 * whether a value is provided.
 *
 * Useful for components which have both controlled and uncontrolled states, e.g.
 * many of our design system components that take isOpen and onOpenChange but
 * that manage their state internally if those are not provided.
 *
 * Adapted from the internal Radix UI package:
 * https://www.npmjs.com/package/@radix-ui/react-use-controllable-state
 *
 * @param controlledValue Value controlled by outside state. If not undefined,
 * we assume we're using a controlled state.
 * @param defaultValue Value provided to useState if we're uncontrolled.
 * @param onChange Callback to be called when the value should change
 * @returns [value, setValue, isControlled] which reports either the controlled or uncontrolled
 * state and a way to change it.
 */
export function useControllableState<T>(
  controlledValue: T | undefined,
  defaultValue: T,
  onChange?: (value: T) => void,
): [T, (value: T | ((previousValue: T) => T)) => void, boolean] {
  const [stateValue, setStateValue] = React.useState<T>(defaultValue);
  const isControlled = controlledValue !== undefined;
  const isControlledRef = React.useRef(isControlled);

  React.useEffect(() => {
    const wasControlled = isControlledRef.current;
    if (wasControlled !== isControlled) {
      console.warn(
        `WARN: A component changed from ${
          wasControlled ? "controlled" : "uncontrolled"
        } to ${isControlled ? "controlled" : "uncontrolled"}.`,
      );
    }
    isControlledRef.current = isControlled;
  }, [isControlled]);

  const _onChange = React.useCallback(
    (value: T | ((previousValue: T) => T)) => {
      setStateValue(value);
      if (typeof value !== "function") {
        onChange?.(value);
      }
    },
    [onChange],
  );

  if (isControlled) {
    return [controlledValue, _onChange, true];
  }

  return [stateValue, _onChange, false];
}
