import * as React from "react";

import { BsFolderFill, BsGoogle } from "react-icons/bs";
import { GOOGLE_FONTS } from "replo-runtime/shared/utils/font";

export type FontOption = {
  label: string;
  isDefaultActive: boolean;
  isDisabled: boolean;
  value: string | null;
  isSelectable: boolean;
  endEnhancer?: JSX.Element | null;
};

export const GOOGLE_FONT_OPTIONS: FontOption[] = GOOGLE_FONTS.map((f) => ({
  value: f,
  label: f,
  isDisabled: false,
  isDefaultActive: false,
  isSelectable: true,
}));

export const fontSourceIcons = {
  google: <BsGoogle className="text-slate-400" />,
  shopify: <BsFolderFill className="text-slate-400" />,
};

// Adds label + enhancer
export const FORMATTED_GOOGLE_FONT_OPTIONS: FontOption[] = [
  {
    label: "Google Fonts",
    isSelectable: false,
    isDefaultActive: false,
    isDisabled: true,
    value: null,
  },
  ...GOOGLE_FONT_OPTIONS.map((font) => ({
    ...font,
    endEnhancer: fontSourceIcons.google,
  })),
];

// Helper
export const arrayContainsFontOption = (
  array: FontOption[],
  font: FontOption,
): boolean => {
  return array.some((fontFromArray) => fontFromArray.value === font.value);
};

export const getEndEnhancer = (
  font: FontOption,
  shopifyFontOptions: FontOption[],
): FontOption["endEnhancer"] => {
  if (font.isDisabled || font.value === null) {
    return null;
  }
  if (arrayContainsFontOption(FORMATTED_GOOGLE_FONT_OPTIONS, font)) {
    return fontSourceIcons.google;
  }
  if (arrayContainsFontOption(shopifyFontOptions, font)) {
    return fontSourceIcons.shopify;
  }
};

export const filterOutPageFonts = (
  fontOptions: FontOption[],
  pageFontOptions: FontOption[],
): FontOption[] => {
  const filteredFonts = fontOptions.filter(
    (f) => !arrayContainsFontOption(pageFontOptions, f) || f.isDisabled,
  );
  return filteredFonts.length > 1 ? filteredFonts : [];
};

// https://developer.mozilla.org/en-US/docs/Web/CSS/font-family#generic-name
export const GENERIC_FONT_FAMILIES = [
  "serif",
  "sans-serif",
  "monospace",
  "cursive",
  "fantasy",
  "system-ui",
  "ui-serif",
  "ui-sans-serif",
  "ui-monospace",
  "ui-rounded",
  "math",
  "emoji",
  "fangsong",
];
