import * as React from "react";

import debounce from "lodash-es/debounce";

/**
 * Similar to `useState` but it also returns a debounced state value. This is
 * useful if you need both an immediate state update, but you also want a
 * debounced state value for things like search queries where the filtered
 * results should be delayed while the user is typing.
 *
 * @returns A tuple containing:
 * 1. the current state
 * 2. the debounced state
 * 3. the state setter
 */
export function useDebouncedState<T>(
  initialValue: T | (() => T),
  timeout = 300,
): [
  State: T,
  DebouncedState: T,
  SetState: React.Dispatch<React.SetStateAction<T>>,
] {
  const [currentState, setCurrentState] = React.useState(initialValue);
  const [debouncedState, setDebouncedState] = React.useState(currentState);

  React.useEffect(() => {
    if (timeout <= 0) {
      setDebouncedState(currentState);
      return;
    }

    const debouncedSetState = debounce((value: T) => {
      setDebouncedState(value);
    }, timeout);
    debouncedSetState(currentState);
    return () => {
      debouncedSetState.cancel();
    };
  }, [currentState, timeout]);

  return [currentState, debouncedState, setCurrentState];
}
