import type { ChainedCommands, Editor } from "@tiptap/react";
import type { IconType } from "react-icons";

import * as React from "react";

import TipTapToolbarColorPicker from "@components/TipTapToolbarColorPicker";
import TipTapToolbarLink, {
  ToolbarButton,
} from "@components/TipTapToolbarLink";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import {
  selectFontStyle,
  selectTextDecoration,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import {
  isAllTextSelected,
  isSelectionEmpty,
  runCommand,
} from "@editor/utils/rte";

import { BiFontColor } from "react-icons/bi";
import {
  BsListOl,
  BsListUl,
  BsTypeBold,
  BsTypeItalic,
  BsTypeStrikethrough,
  BsTypeUnderline,
} from "react-icons/bs";
import { IoColorFill } from "react-icons/io5";

type ToolbarType =
  | "listUl"
  | "listOl"
  | "bold"
  | "italic"
  | "underline"
  | "strike";

type ColorAttribute = "color" | "background";

const toolbarConfigs: {
  id: ToolbarType;
  tooltipText: string;
  icon: IconType;
  onClick(chain?: ChainedCommands): ChainedCommands | undefined;
}[] = [
  {
    id: "bold",
    tooltipText: "Bold",
    icon: BsTypeBold,
    onClick: (chain?: ChainedCommands) => {
      return chain?.toggleBold();
    },
  },
  {
    id: "listUl",
    tooltipText: "Bullet List",
    icon: BsListUl,
    onClick: (chain?: ChainedCommands) => {
      return chain?.toggleBulletList();
    },
  },
  {
    id: "listOl",
    tooltipText: "Numbered List",
    icon: BsListOl,
    onClick: (chain?: ChainedCommands) => {
      return chain?.toggleOrderedList();
    },
  },
];

const TipTapToolbar: React.FC<
  React.PropsWithChildren<{
    id?: string;
    editor: Editor | null;
  }>
> = ({ id, editor }) => {
  const applyComponentAction = useApplyComponentAction();

  const applyChangeToComponent =
    isSelectionEmpty(editor) || isAllTextSelected(editor);

  const onUpdateColor = (color: string | null, attribute: ColorAttribute) => {
    if (!color) {
      // unset color
      if (attribute === "color") {
        runCommand(editor, (chain) => chain?.unsetColor());
      } else {
        runCommand(editor, (chain) => chain?.unsetHighlight());
      }
      return;
    }

    if (attribute === "color") {
      // Note (Ovishek, 2022-11-23, REPL-5202): If we have nothing selected we would rather update
      // via apply component action so that it can be different based on devices (like mobile/desktop)
      if (applyChangeToComponent) {
        applyComponentAction({
          type: "setStyles",
          value: {
            color,
          },
        });
        runCommand(editor, (chain) => chain?.unsetColor());
        return;
      }
      runCommand(editor, (chain) => chain?.unsetHighlight().setColor(color!));
    } else {
      runCommand(editor, (chain) =>
        chain?.unsetColor().setHighlight({ color: color! }),
      );
    }
  };

  const fontStyle = useEditorSelector(selectFontStyle);
  const textDecoration = useEditorSelector(selectTextDecoration);

  const isItalicActive = editor?.isActive("italic") || fontStyle === "italic";
  const onUpdateItalic = () => {
    if (applyChangeToComponent) {
      applyComponentAction({
        type: "setStyles",
        value: {
          fontStyle: isItalicActive ? null : "italic",
        },
      });
      runCommand(editor, (chain) => chain?.unsetItalic());
      return;
    }
    applyComponentAction({
      type: "setStyles",
      value: {
        fontStyle: null,
      },
    });
    runCommand(editor, (chain) => chain?.toggleItalic());
  };

  const isUnderlineActive =
    editor?.isActive("underline") || textDecoration === "underline";
  const onUpdateUnderline = () => {
    if (applyChangeToComponent) {
      applyComponentAction({
        type: "setStyles",
        value: {
          textDecoration: isUnderlineActive ? null : "underline",
        },
      });
      runCommand(editor, (chain) => chain?.unsetUnderline());
      return;
    }
    applyComponentAction({
      type: "setStyles",
      value: {
        textDecoration: null,
      },
    });
    runCommand(editor, (chain) => chain?.toggleUnderline());
  };

  const isStrikeActive =
    editor?.isActive("strike") || textDecoration === "line-through";
  const onUpdateStrike = () => {
    if (applyChangeToComponent) {
      applyComponentAction({
        type: "setStyles",
        value: {
          textDecoration: isStrikeActive ? null : "line-through",
        },
      });
      runCommand(editor, (chain) => chain?.unsetStrike());
      return;
    }
    applyComponentAction({
      type: "setStyles",
      value: {
        textDecoration: null,
      },
    });
    runCommand(editor, (chain) => chain?.toggleStrike());
  };

  const toolbarMap = Object.fromEntries(
    toolbarConfigs.map((config) => {
      return [
        config.id,
        {
          ...config,
          onClick: () => {
            runCommand(editor, config.onClick);
          },
        },
      ];
    }),
  );

  const renderToolbar = (keys: string[], editor: Editor | null) => {
    return keys.map((key) => {
      const config = toolbarMap[key];
      if (!config) {
        return null;
      }
      return (
        <ToolbarButton
          key={key}
          tooltipText={config.tooltipText}
          icon={config.icon}
          onClick={config.onClick}
          isActive={Boolean(editor?.isActive(key))}
        />
      );
    });
  };

  return (
    <div id={id} className="relative z-50 flex gap-2 border-b-slate-100">
      {renderToolbar(["bold"], editor)}
      <ToolbarButton
        tooltipText="Italic"
        icon={BsTypeItalic}
        onClick={onUpdateItalic}
        isActive={isItalicActive}
      />
      <ToolbarButton
        tooltipText="Strikethrough"
        icon={BsTypeStrikethrough}
        onClick={onUpdateStrike}
        isActive={isStrikeActive}
      />
      <ToolbarButton
        tooltipText="Underline"
        icon={BsTypeUnderline}
        onClick={onUpdateUnderline}
        isActive={isUnderlineActive}
      />
      {(["color", "background"] as ColorAttribute[]).map((attribute) => (
        <TipTapToolbarColorPicker
          key={`color-picker-${attribute}`}
          editor={editor}
          attributeName={attribute}
          icon={attribute === "color" ? BiFontColor : IoColorFill}
          onUpdateColor={(color) => onUpdateColor(color, attribute)}
          isActive={Boolean(
            editor?.isActive(attribute === "color" ? "textStyle" : "highlight"),
          )}
        />
      ))}
      <TipTapToolbarLink
        editor={editor}
        isActive={Boolean(editor?.isActive("link"))}
      />
      {renderToolbar(["listUl", "listOl"], editor)}
    </div>
  );
};

export default TipTapToolbar;
