import * as React from "react";

import { unsafe_useLayoutEffectWithoutWarning as useLayoutEffect } from "./use-layout-effect";

// https://usehooks-ts.com/react-hook/use-interval
export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = React.useRef(callback);
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    if (delay == null) {
      return;
    }
    const id = setInterval(() => savedCallback.current(), delay);
    return () => {
      clearInterval(id);
    };
  }, [delay]);
}
