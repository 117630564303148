import type {
  ComparisonTimeFrame,
  RelativeTimeFrame,
} from "@/features/analytics/time";
import type { DateRange } from "react-day-picker";

import * as React from "react";

import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import { Input } from "@editor/components/common/designSystem/Input";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import {
  getAnalyticsPathWithAppendedQueryParam,
  getUrlWithAnalyticsUrlParams,
  isCustomDateRange,
  sanitizePageUrlPath,
} from "@editor/utils/analytics";
import { routes } from "@editor/utils/router";

import { DEFAULT_URL_HOST } from "@/features/analytics/constants";
import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import AppliedFiltersTags from "@/features/analytics/moreFilters/AppliedFiltersTags";
import BarPopover from "@/features/analytics/moreFilters/BarPopover";
import TriggerBarPopoverCombobox from "@/features/analytics/moreFilters/TriggerBarPopoverCombobox";
import AnalyticsHostPagesCombobox from "@/features/analytics/selects/AnalyticsHostPagesCombobox";
import { AnalyticsTimeRangeCombobox } from "@/features/analytics/selects/AnalyticsTimeRangeCombobox";
import AnalyticsUrlHostCombobox from "@/features/analytics/selects/AnalyticsUrlHostCombobox";
import {
  COMPARE_PREVIOUS_PERIOD,
  COMPARE_RANGE_TIME_FRAME_OPTIONS,
  MAIN_RELATIVE_TIME_FRAMES,
} from "@/features/analytics/time";
import { BsSearch } from "react-icons/bs";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

type AnalyticsFiltersProps = {
  resetPagination?: () => void;
  search?: {
    value: string;
    onChange: (value: string) => void;
  };
};

const AnalyticsFilters: React.FC<AnalyticsFiltersProps> = ({
  resetPagination,
  search,
}) => {
  const workspaceId = useCurrentWorkspaceId();
  const { workspaceUrlHosts, setParamValue, getParamValue } =
    useAnalyticsQueryContext();
  const analytics = useLogAnalytics();
  const shopifyUrlOptionValues = React.useMemo(
    () => workspaceUrlHosts.data.map((hostObj) => hostObj.urlHost),
    [workspaceUrlHosts.data],
  );

  const navigate = useNavigate();
  const location = useLocation();

  // NOTE (Kurt, 2024-10-13): The host value might be an empty string on paths that don't have
  // a host defined on them (i.e. /analytics/overview). This value will be defined on paths that
  // are specific to a host (i.e. /analytics/:host/:pageUrlPath).
  const { pageUrlPath = "", host = "" } = useParams<{
    pageUrlPath: string;
    host: string;
  }>();

  const handleUrlHostChange = (value: string) => {
    if (host === "") {
      setParamValue(
        "urlHosts",
        value === DEFAULT_URL_HOST ? shopifyUrlOptionValues : [value],
      );
    } else {
      const hostPath = generatePath(routes.analytics.pageDetails, {
        workspaceId,
        pageUrlPath: encodeURIComponent("/"),
        host: encodeURIComponent(value),
      });

      const currentSearchParams = new URLSearchParams(location.search);
      const queryUrlParamString = currentSearchParams.get("query");

      const pathWithParams = getAnalyticsPathWithAppendedQueryParam(
        hostPath,
        queryUrlParamString,
      );

      navigate(pathWithParams);
    }
  };

  const [isMainRangePickerOpen, setIsMainRangePickerOpen] =
    React.useState<boolean>(false);
  const [isCompareRangePickerOpen, setIsCompareRangePickerOpen] =
    React.useState<boolean>(false);
  const [isHostPagePickerOpen, setIsHostPagePickerOpen] =
    React.useState<boolean>(false);

  const selectedPageUrlPath = sanitizePageUrlPath(
    decodeURIComponent(pageUrlPath),
  );

  const setSelectedPageUrlPath = (value: string) => {
    const path = generatePath(routes.analytics.pageDetails, {
      workspaceId,
      pageUrlPath: encodeURIComponent(value),
      host: encodeURIComponent(host),
    });
    const urlSearchParams = new URLSearchParams(location.search);
    const pathWithParams = getUrlWithAnalyticsUrlParams(path, urlSearchParams);
    navigate(pathWithParams);
  };

  /**
   * This function only ever updates the compareAt range.
   * Either the user selects to compare with a predefined period
   * (e.g. previous-period), or the user selects to compare with a
   * custom date period.
   *
   * @author Max 2024-09-17
   */
  const handleCompareRangeChange = (
    selectedComparePeriodValue: ComparisonTimeFrame | DateRange,
  ) => {
    if (isCustomDateRange(selectedComparePeriodValue)) {
      setParamValue("selectedComparePeriod", {
        type: "custom",
        value: {
          from: selectedComparePeriodValue.from!.getTime(),
          to: selectedComparePeriodValue.to!.getTime(),
        },
      });
    } else {
      setParamValue("selectedComparePeriod", {
        type: "relative",
        value: selectedComparePeriodValue as ComparisonTimeFrame,
      });
    }

    resetPagination?.();
    analytics("analytics.compare", {
      compareDetail:
        getParamValue("selectedComparePeriod").value === COMPARE_PREVIOUS_PERIOD
          ? "previous-period"
          : "custom",
      location:
        pageUrlPath === "" ? "analytics_overview" : "analytics_deep_dive",
    });
  };

  /**
   * Unlike handleCompareRangeChange(), this function can update
   * both ranges: it always updates the mainRange, AND if the compare period
   * is set to 'previous-period', it also updates the compareAt range (by setting
   * it to be the previous continuous range).
   *
   * @author Max 2024-09-17
   */
  const handleMainRangeChange = (
    selectedTimeFrameValue: RelativeTimeFrame | DateRange,
  ) => {
    if (isCustomDateRange(selectedTimeFrameValue)) {
      setParamValue("selectedTimePeriod", {
        type: "custom",
        value: {
          from: selectedTimeFrameValue.from!.getTime(),
          to: selectedTimeFrameValue.to!.getTime(),
        },
      });
    } else {
      setParamValue("selectedTimePeriod", {
        type: "relative",
        value: selectedTimeFrameValue as RelativeTimeFrame,
      });
    }
    resetPagination?.();
    analytics("analytics.time.filter", {
      range:
        typeof selectedTimeFrameValue === "string"
          ? selectedTimeFrameValue
          : "custom",
      location:
        pageUrlPath === "" ? "analytics_overview" : "analytics_deep_dive",
    });
  };

  const searchUrlPathInputProps = useOverridableInput({
    value: search?.value ?? "",
    onValueChange: search?.onChange ?? (() => {}),
  });

  const [isMoreFiltersBarOpen, setIsMoreFiltersBarOpen] =
    React.useState<boolean>(false);
  const [selectedUrlParamOptionValue, setSelectedUrlParamOptionValue] =
    React.useState<string>("");

  const handleUrlParamOptionClick = (value: string) => {
    setSelectedUrlParamOptionValue(value);
    setIsMoreFiltersBarOpen(!isMoreFiltersBarOpen);
  };

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-9">
        <div className="flex flex-row gap-2 mr-2">
          <AnalyticsTimeRangeCombobox<RelativeTimeFrame | DateRange>
            options={MAIN_RELATIVE_TIME_FRAMES}
            handleRangeChange={handleMainRangeChange}
            open={isMainRangePickerOpen}
            onOpenChange={setIsMainRangePickerOpen}
            isCompare={false}
          />
          <AnalyticsTimeRangeCombobox<ComparisonTimeFrame | DateRange>
            options={COMPARE_RANGE_TIME_FRAME_OPTIONS}
            placeholderPrefix="Compare to: "
            handleRangeChange={handleCompareRangeChange}
            open={isCompareRangePickerOpen}
            onOpenChange={setIsCompareRangePickerOpen}
            isCompare={true}
          />
          <AnalyticsUrlHostCombobox onChange={handleUrlHostChange} />
          {selectedPageUrlPath !== "" && (
            <AnalyticsHostPagesCombobox
              onChange={setSelectedPageUrlPath}
              value={selectedPageUrlPath}
              open={isHostPagePickerOpen}
              onOpenChange={setIsHostPagePickerOpen}
            />
          )}
          <TriggerBarPopoverCombobox
            handleUrlParamOptionClick={handleUrlParamOptionClick}
          />
        </div>
        <div className="mt-2 flex flex-wrap gap-2">
          <AppliedFiltersTags />
        </div>
      </div>
      {search && (
        <div className="col-span-3">
          <Input
            placeholder="Search path..."
            size="base"
            startEnhancer={() => (
              <BsSearch className="text-xs text-slate-400" />
            )}
            {...searchUrlPathInputProps}
          />
        </div>
      )}
      <div className="absolute">
        <BarPopover
          isOpen={isMoreFiltersBarOpen}
          onOpenChange={setIsMoreFiltersBarOpen}
          selectedUrlParamOptionValue={selectedUrlParamOptionValue}
        />
      </div>
    </div>
  );
};

export default AnalyticsFilters;
