import useCurrentUser from "@editor/hooks/useCurrentUser";
import { isDevelopment } from "@editor/utils/env";

import { useSearchParams } from "react-router-dom";

export const useIsDebugMode = () => {
  const { user } = useCurrentUser();
  const [searchParams] = useSearchParams();
  const isSuperuser = user?.isSuperuser ?? false;

  return isDebugMode(isSuperuser, searchParams);
};

export function isDebugMode(
  isSuperuser: boolean,
  searchParams?: URLSearchParams | null,
) {
  return (
    isDevelopment ||
    isSuperuser ||
    searchParams?.get("alchemy-development") === "true"
  );
}
