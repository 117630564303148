import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import {
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { useProductFromProps } from "../../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../../utils/reviews";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const DynamicCheckoutButtons: React.FC<RenderComponentProps> = ({
  componentAttributes,
  component,
  context,
}) => {
  const selectedVariantId = context.state.product?.selectedVariant?.id;
  const quantity = context.state.product?.quantity;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const product = useProductFromProps(component.props, context);

  const liquidSourceWithProductAssignment = wrapProductAssignmentToLiquidSource(
    {
      product,
      liquidSource: `{% form 'product', product, id: product_form_id, class: 'form', novalidate: 'novalidate', data-type: 'add-to-cart-form' %}
    <input type="hidden" name="id" id="dynamic-buttons" value="${
      selectedVariantId ?? product?.id
    }" disabled />
    <input type="hidden" id="dynamic-buttons-qty" name="quantity" value="${quantity ?? 1}"/>
    <div class="product-form__buttons">
        {{ form | payment_button }}
    </div>
  {% endform %}`,
      context: context,
    },
  );

  // Note (Fran, 2023-03-06): This type of buttons doesn't work in the editor, instead
  // we need to show the placeholder.
  const shouldHavePlaceHolder = isEditorApp;
  const placeholder =
    "Once the page is published, the Dynamic Checkout Buttons will appear here.";

  const dynamicCheckoutButtonsRef = React.useRef<HTMLDivElement | null>(null);

  // Note (Fran, 2023-03-07): Every time the selected variant id or the product
  // change we need to update the new id in the input for that purpose.
  // But we need to change this way because liquid component will not
  // re-render and the input value will not change otherwise.
  React.useEffect(() => {
    if (dynamicCheckoutButtonsRef.current) {
      const variantIdInput: HTMLInputElement | null =
        dynamicCheckoutButtonsRef.current.querySelector("#dynamic-buttons");
      if (variantIdInput) {
        const variantIdValue = selectedVariantId ?? product?.id ?? "";
        variantIdInput.value = String(variantIdValue);
      }
    }
  }, [selectedVariantId, product?.id]);

  React.useEffect(() => {
    if (dynamicCheckoutButtonsRef.current) {
      const quantityInput: HTMLInputElement | null =
        dynamicCheckoutButtonsRef.current.querySelector("#dynamic-buttons-qty");
      if (quantityInput) {
        const updatedQuantityValue = quantity ?? 1;
        quantityInput.value = String(updatedQuantityValue);
      }
    }
  }, [quantity]);

  return (
    <SharedShopifyLiquid
      forceEditorPlaceholder={shouldHavePlaceHolder}
      liquidSource={liquidSourceWithProductAssignment}
      componentId={component.id}
      componentAttributes={componentAttributes}
      isLiquidSupported={true}
      placeholder={placeholder}
      repeatedIndexPath={context.repeatedIndexPath}
      ref={dynamicCheckoutButtonsRef}
    />
  );
};

export default DynamicCheckoutButtons;
