import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const slideshowCarousel: ComponentTemplate = {
  id: "157b3953-3d24-4528-a549-908a653b1ff5",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Carousel/Slideshow Carousel",
  leftBarCategory: { main: "interactive", subCategory: "carousel" },
  preview: "carousel/slideshow.png",
  template: {
    id: "3744a62c-4f7a-48a9-beee-cd8a0f154500",
    name: "slideshow carousel",
    type: "carouselV3",
    props: {
      style: {
        display: "flex",
        __flexGap: "24px",
        alignSelf: "start",
        flexBasis: 0,
        alignItems: "flex-start",
        flexDirection: "column",
        justifyContent: "flex-start",
      },
      "style@sm": {
        display: "flex",
        __flexGap: "16px",
        flexDirection: "column",
      },
      _animationStyle: "fade",
    },
    children: [
      {
        id: "9d3001bb-7a64-4923-86e4-db06d1944784",
        name: "Slides",
        type: "carouselV3Slides",
        props: {
          style: {
            __width: "600px",
            __height: "400px",
            maxWidth: "656px",
            overflow: "hidden",
            alignSelf: "stretch",
            flexBasis: 0,
          },
          "style@md": {
            width: "auto",
            alignSelf: "stretch",
          },
          "style@sm": {
            width: "auto",
            height: "auto",
            __width: "350px",
            __height: "350px",
            flexGrow: 1,
            alignSelf: "stretch",
            flexBasis: "auto",
          },
        },
        children: [
          {
            id: "f36dd0ae-cd08-4af5-b916-9293ff6b8dcc",
            name: "Slide 1",
            type: "container",
            props: {
              style: {
                width: "100%",
                height: "100%",
                display: "flex",
                flexBasis: 0,
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              },
              "style@sm": {
                width: "auto",
                height: "auto",
                flexGrow: 1,
                alignSelf: "stretch",
              },
            },
            children: [
              {
                id: "711144cd-76e2-4c10-8714-b071c91896f0",
                name: "Image",
                type: "image",
                props: {
                  style: {
                    width: "100%",
                    height: "100%",
                    __width: "100%",
                    __height: "100%",
                    flexBasis: 0,
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1504198266287-1659872e6590?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTd8fHxlbnwwfHx8fHw%3D",
                    __imageAltText:
                      "A single green leaf against a white background.",
                  },
                  loading: "lazy",
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
              },
            ],
          },
          {
            id: "a9d35d99-5d7a-4f61-8446-f92fd79441f0",
            name: "Slide 2",
            type: "container",
            props: {
              style: {
                width: "100%",
                height: "100%",
                display: "flex",
                flexBasis: 0,
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              },
              "style@sm": {
                width: "auto",
                height: "auto",
                flexGrow: 1,
                alignSelf: "stretch",
              },
            },
            children: [
              {
                id: "b328cf40-2d12-4253-a498-4c28d3e1417b",
                name: "Image",
                type: "image",
                props: {
                  style: {
                    width: "100%",
                    height: "100%",
                    __width: "100%",
                    __height: "100%",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1504204267155-aaad8e81290d?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTR8fHxlbnwwfHx8fHw%3D",
                    __imageAltText:
                      "A single green leaf in a clear glass of water on white background.",
                  },
                  loading: "lazy",
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
              },
            ],
          },
          {
            id: "067c85b9-c150-4907-a1ae-5eebfb1fed04",
            name: "Slide 3",
            type: "container",
            props: {
              style: {
                width: "100%",
                height: "100%",
                display: "flex",
                flexBasis: 0,
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              },
            },
            children: [
              {
                id: "d3e8c2ab-311c-42d1-93d9-51f9e05bfdf2",
                name: "Image",
                type: "image",
                props: {
                  style: {
                    width: "100%",
                    height: "100%",
                    __width: "100%",
                    __height: "100%",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1471086569966-db3eebc25a59?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MjZ8fHxlbnwwfHx8fHw%3D",
                    __imageAltText:
                      "A single Monstera leaf in a clear glass vase against a white background.",
                  },
                  loading: "lazy",
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
              },
            ],
          },
          {
            id: "83aaf2dd-af8b-4d90-86c5-8f6a29954d81",
            name: "Slide 4",
            type: "container",
            props: {
              style: {
                width: "100%",
                height: "100%",
                display: "flex",
                flexBasis: 0,
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              },
            },
            children: [
              {
                id: "d1ceb4b6-0ee3-4fb4-9e63-61a7344dd6d5",
                name: "Image",
                type: "image",
                props: {
                  style: {
                    width: "100%",
                    height: "100%",
                    __width: "100%",
                    __height: "100%",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1655658233701-a0bada796e94?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MXx8fGVufDB8fHx8fA%3D%3D",
                    __imageAltText:
                      "Green palm leaf beside two blank white cards on a white background.",
                  },
                  loading: "lazy",
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
              },
            ],
          },
        ],
      },
      {
        id: "03f45167-de84-46f1-82d8-da4e030a718b",
        name: "Container",
        type: "container",
        props: {
          style: {
            display: "flex",
            maxWidth: "100%",
            __flexGap: "24px",
            alignSelf: "stretch",
            alignItems: "flex-start",
            justifyContent: "space-between",
          },
          "style@sm": {
            width: "auto",
            __flexGap: "8px",
          },
        },
        children: [
          {
            id: "d66f2eeb-0b60-409e-8707-8f47e03eb2f8",
            name: "Previous Button 1",
            type: "carouselV3Control",
            props: {
              style: {
                cursor: "pointer",
                display: "flex",
                flexGrow: "unset",
                flexWrap: "wrap",
                alignSelf: "stretch",
                flexBasis: "auto",
                alignItems: "center",
                justifyContent: "center",
              },
              direction: "previous",
            },
            children: [
              {
                id: "d3e40a54-bf4d-4073-bfd7-fce7a2b1345c",
                type: "icon",
                props: {
                  style: {
                    width: "40px",
                    height: "40px",
                    __width: "40px",
                    __height: "40px",
                  },
                  iconName: "chevronLeft",
                  "style@sm": {
                    width: "24px",
                    height: "24px",
                    __width: "24px",
                    __height: "24px",
                    flexGrow: "unset",
                  },
                },
                variantOverrides: {},
              },
            ],
            variants: [
              {
                id: "27a9199a-ffb8-4616-bc58-127f534425ab",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "7a849600-00ce-43fa-9a86-6a68c1341710",
                name: "First Item",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "bf8b0b4c-79fd-4043-a18b-9840a038a6e3",
                      field: "state.group.isFirstItemActive",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "7a849600-00ce-43fa-9a86-6a68c1341710": {
                componentOverrides: {
                  "d3e40a54-bf4d-4073-bfd7-fce7a2b1345c": {
                    props: {
                      style: {
                        opacity: "20%",
                      },
                    },
                  },
                  "d66f2eeb-0b60-409e-8707-8f47e03eb2f8": {
                    props: {
                      style: {
                        cursor: "default",
                      },
                    },
                  },
                },
              },
            },
          },
          {
            id: "bdab17a1-28a7-44c6-83d8-28daff85425e",
            name: "Slides",
            type: "carouselV3Slides",
            props: {
              style: {
                __width: "1197px",
                display: "flex",
                flexGrow: 1,
                overflow: "hidden",
                __flexGap: "16px",
                alignSelf: "stretch",
                alignItems: "center",
                justifyContent: "center",
              },
              "style@sm": {
                __flexGap: "12px",
              },
              _autoWidth: true,
              _activeArea: "first",
              _useCustomConfig: true,
              _slideClickTransition: true,
            },
            children: [
              {
                id: "d22f0637-efb7-46ec-9b6b-1dbf460100ae",
                name: "For Slide 1",
                type: "image",
                props: {
                  style: {
                    height: "80px",
                    __width: "80px",
                    opacity: "100%",
                    __height: "80px",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1504198266287-1659872e6590?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTd8fHxlbnwwfHx8fHw%3D",
                    __imageAltText:
                      "A single green leaf against a white background.",
                  },
                  loading: "lazy",
                  "style@sm": {
                    width: "auto",
                    cursor: "pointer",
                    height: "56px",
                    __width: "64px",
                    __height: "56px",
                    flexGrow: "unset",
                  },
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
                variants: [
                  {
                    id: "993cb10d-6922-418a-9d55-9ad4b6ca56ad",
                    name: "default",
                    query: null,
                    componentOverrides: null,
                  },
                  {
                    id: "958f749b-0ccd-4839-84fa-6799e7fd6fe0",
                    name: "Active",
                    query: {
                      type: "expression",
                      operator: "or",
                      statements: [
                        {
                          id: "7139f491-1640-4972-90f1-f6a3d3a2a168",
                          field: "state.group.isCurrentItemActive",
                          value: null,
                          operator: null,
                        },
                      ],
                    },
                    componentOverrides: null,
                  },
                ],
                variantOverrides: {
                  "958f749b-0ccd-4839-84fa-6799e7fd6fe0": {
                    componentOverrides: {
                      "d22f0637-efb7-46ec-9b6b-1dbf460100ae": {
                        props: {
                          style: {
                            opacity: "100%",
                            borderColor: null,
                            borderStyle: null,
                            borderWidth: null,
                            borderTopColor: "#000000",
                            borderTopStyle: "solid",
                            borderTopWidth: "2px",
                            borderLeftColor: "#000000",
                            borderLeftStyle: "solid",
                            borderLeftWidth: "2px",
                            borderRightColor: "#000000",
                            borderRightStyle: "solid",
                            borderRightWidth: "2px",
                            borderBottomColor: "#000000",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "2px",
                          },
                          "style@sm": {
                            width: "auto",
                            flexGrow: "unset",
                          },
                        },
                      },
                    },
                  },
                },
              },
              {
                id: "e19e510a-e6cb-4bfb-8dd3-441f886d33f4",
                name: "For Slide 2",
                type: "image",
                props: {
                  style: {
                    height: "80px",
                    __width: "80px",
                    opacity: "100%",
                    __height: "80px",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1504204267155-aaad8e81290d?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MTR8fHxlbnwwfHx8fHw%3D",
                    __imageAltText:
                      "A single palm leaf in a clear glass of water on a white background.",
                    borderTopLeftRadius: "0%",
                    borderTopRightRadius: "0%",
                    borderBottomLeftRadius: "0%",
                    borderBottomRightRadius: "0%",
                  },
                  loading: "lazy",
                  "style@sm": {
                    width: "auto",
                    cursor: "pointer",
                    height: "56px",
                    __width: "64px",
                    __height: "56px",
                    flexGrow: "unset",
                  },
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
                variants: [
                  {
                    id: "8bde3b70-73ee-4cc3-990a-34ea4c501ce5",
                    name: "default",
                    query: null,
                    componentOverrides: null,
                  },
                  {
                    id: "1753a620-bf10-4479-9789-997f2fb21fde",
                    name: "Active",
                    query: {
                      type: "expression",
                      operator: "or",
                      statements: [
                        {
                          id: "2ec7d288-c57c-49ba-8ffe-85e0aade3494",
                          field: "state.group.isCurrentItemActive",
                          value: null,
                          operator: null,
                        },
                      ],
                    },
                    componentOverrides: null,
                  },
                ],
                variantOverrides: {
                  "1753a620-bf10-4479-9789-997f2fb21fde": {
                    componentOverrides: {
                      "e19e510a-e6cb-4bfb-8dd3-441f886d33f4": {
                        props: {
                          style: {
                            opacity: "100%",
                            borderColor: null,
                            borderStyle: null,
                            borderWidth: null,
                            borderTopColor: "#000000",
                            borderTopStyle: "solid",
                            borderTopWidth: "2px",
                            borderLeftColor: "#000000",
                            borderLeftStyle: "solid",
                            borderLeftWidth: "2px",
                            borderRightColor: "#000000",
                            borderRightStyle: "solid",
                            borderRightWidth: "2px",
                            borderBottomColor: "#000000",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "2px",
                          },
                          "style@sm": {
                            width: "auto",
                            flexGrow: "unset",
                          },
                        },
                      },
                    },
                  },
                },
              },
              {
                id: "698b8f30-1d1d-4bda-a8e0-4bcad79e2a19",
                name: "For Slide 3",
                type: "image",
                props: {
                  style: {
                    height: "80px",
                    __width: "80px",
                    opacity: "100%",
                    __height: "80px",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1471086569966-db3eebc25a59?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MjZ8fHxlbnwwfHx8fHw%3D",
                    __imageAltText:
                      "A single monstera leaf in a clear glass vase against a white background.",
                  },
                  loading: "lazy",
                  "style@sm": {
                    width: "auto",
                    cursor: "pointer",
                    height: "56px",
                    __width: "64px",
                    __height: "56px",
                    flexGrow: "unset",
                  },
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
                variants: [
                  {
                    id: "12420b7a-8c97-486f-806c-a351b674eb4a",
                    name: "default",
                    query: null,
                    componentOverrides: null,
                  },
                  {
                    id: "8ea47509-d2aa-4d49-8418-38938890f03f",
                    name: "Active",
                    query: {
                      type: "expression",
                      operator: "or",
                      statements: [
                        {
                          id: "e4169129-12fd-43fa-84cd-15c077a6af67",
                          field: "state.group.isCurrentItemActive",
                          value: null,
                          operator: null,
                        },
                      ],
                    },
                    componentOverrides: null,
                  },
                ],
                variantOverrides: {
                  "8ea47509-d2aa-4d49-8418-38938890f03f": {
                    componentOverrides: {
                      "698b8f30-1d1d-4bda-a8e0-4bcad79e2a19": {
                        props: {
                          style: {
                            opacity: "100%",
                            borderColor: null,
                            borderStyle: null,
                            borderWidth: null,
                            borderTopColor: "#000000",
                            borderTopStyle: "solid",
                            borderTopWidth: "2px",
                            borderLeftColor: "#000000",
                            borderLeftStyle: "solid",
                            borderLeftWidth: "2px",
                            borderRightColor: "#000000",
                            borderRightStyle: "solid",
                            borderRightWidth: "2px",
                            borderBottomColor: "#000000",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "2px",
                          },
                          "style@sm": {
                            width: "auto",
                            flexGrow: "unset",
                          },
                        },
                      },
                    },
                  },
                },
              },
              {
                id: "820a83f5-a7ea-41c6-a444-a56bd217fc5a",
                name: "For Slide 4",
                type: "image",
                props: {
                  style: {
                    height: "80px",
                    __width: "80px",
                    opacity: "100%",
                    __height: "80px",
                    objectFit: "cover",
                    __imageSource:
                      "https://images.unsplash.com/photo-1655658233701-a0bada796e94?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MXx8fGVufDB8fHx8fA%3D%3D",
                    __imageAltText:
                      "Green palm leaf beside blank white cards on a plain background.",
                    borderTopLeftRadius: "0%",
                    borderTopRightRadius: "0%",
                    borderBottomLeftRadius: "0%",
                    borderBottomRightRadius: "0%",
                  },
                  loading: "lazy",
                  "style@sm": {
                    width: "auto",
                    cursor: "pointer",
                    height: "56px",
                    __width: "64px",
                    __height: "56px",
                    flexGrow: "unset",
                  },
                },
                markers: {
                  _aiGeneratedAltText: true,
                },
                variants: [
                  {
                    id: "d96066e0-a413-43dd-99c4-514885abbd7b",
                    name: "default",
                    query: null,
                    componentOverrides: null,
                  },
                  {
                    id: "f9b88b8a-043a-4989-9984-cc174be3d9ee",
                    name: "Active",
                    query: {
                      type: "expression",
                      operator: "or",
                      statements: [
                        {
                          id: "a577620a-970e-494c-8322-6984a9d55f7d",
                          field: "state.group.isCurrentItemActive",
                          value: null,
                          operator: null,
                        },
                      ],
                    },
                    componentOverrides: null,
                  },
                ],
                variantOverrides: {
                  "f9b88b8a-043a-4989-9984-cc174be3d9ee": {
                    componentOverrides: {
                      "820a83f5-a7ea-41c6-a444-a56bd217fc5a": {
                        props: {
                          style: {
                            opacity: "100%",
                            borderColor: null,
                            borderStyle: null,
                            borderWidth: null,
                            borderTopColor: "#000000",
                            borderTopStyle: "solid",
                            borderTopWidth: "2px",
                            borderLeftColor: "#000000",
                            borderLeftStyle: "solid",
                            borderLeftWidth: "2px",
                            borderRightColor: "#000000",
                            borderRightStyle: "solid",
                            borderRightWidth: "2px",
                            borderBottomColor: "#000000",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "2px",
                          },
                          "style@sm": {
                            width: "auto",
                            flexGrow: "unset",
                          },
                        },
                      },
                    },
                  },
                },
              },
            ],
          },
          {
            id: "8cd20531-f20f-4afa-85dd-41c3bb47b030",
            name: "Next Button 1",
            type: "carouselV3Control",
            props: {
              style: {
                cursor: "pointer",
                display: "flex",
                alignSelf: "stretch",
                alignItems: "center",
                justifyContent: "center",
              },
              "style@md": {
                width: "auto",
                flexGrow: "unset",
              },
              direction: "next",
            },
            children: [
              {
                id: "01ed9534-04f1-4ba4-961c-0262d3b6b5b6",
                type: "icon",
                props: {
                  style: {
                    width: "40px",
                    height: "40px",
                    __width: "40px",
                    __height: "40px",
                  },
                  iconName: "chevronRight",
                  "style@sm": {
                    width: "24px",
                    height: "24px",
                    __width: "24px",
                    __height: "24px",
                    flexGrow: "unset",
                  },
                },
                variantOverrides: {},
              },
            ],
            variants: [
              {
                id: "df8bc583-100d-42d0-b632-f8b9c1903969",
                name: "default",
                query: null,
                componentOverrides: null,
              },
              {
                id: "0236f0c4-3df4-43e5-a3af-9c9958da595c",
                name: "Last Item",
                query: {
                  type: "expression",
                  operator: "or",
                  statements: [
                    {
                      id: "fa7db26c-7755-450b-b10f-b5f0f5c6a624",
                      field: "state.group.isLastItemActive",
                      value: null,
                      operator: null,
                    },
                  ],
                },
                componentOverrides: null,
              },
            ],
            variantOverrides: {
              "0236f0c4-3df4-43e5-a3af-9c9958da595c": {
                componentOverrides: {
                  "01ed9534-04f1-4ba4-961c-0262d3b6b5b6": {
                    props: {
                      style: {
                        opacity: "20%",
                      },
                    },
                  },
                  "8cd20531-f20f-4afa-85dd-41c3bb47b030": {
                    props: {
                      style: {
                        cursor: "default",
                      },
                    },
                  },
                },
              },
            },
          },
        ],
      },
    ],
  },
};
