import type { ChainedCommands, Editor } from "@tiptap/react";

/**
 * This function returns whether tiptap editor has any selection, if it's empty
 * this function will return true.
 */
export const isSelectionEmpty = (editor: Editor | null) => {
  return editor?.view.state.selection.empty;
};

export const isAllTextSelected = (editor: Editor | null) => {
  if (!editor) {
    return false;
  }
  const { from, to } = editor.state.selection;
  const selectedText = editor.state.doc.textBetween(from, to);
  return editor.state.doc.textContent === selectedText;
};

/**
 * Checks whether or not all text nodes in the editor are wrapped by a colored
 * mark.
 */
export const isAllTextColored = (editor: Editor | null) => {
  if (!editor) {
    return false;
  }
  let result = true;
  editor.state.doc.descendants((node) => {
    if (
      node.type.name === "text" &&
      !node.marks.some((mark) => mark.attrs.color)
    ) {
      result = false;
      return false;
    }
    return true;
  });
  return result;
};

export const runCommand = (
  editor: Editor | null,
  onIntermediateChainCommand: (
    chain: ChainedCommands | undefined,
  ) => ChainedCommands | undefined,
) => {
  const focusedChain = editor?.chain().focus();
  if (isSelectionEmpty(editor)) {
    const from = editor?.state.selection.from ?? 1;
    onIntermediateChainCommand(focusedChain?.selectAll())
      ?.setTextSelection({ to: from, from })
      .run();
  } else {
    onIntermediateChainCommand(focusedChain)?.run();
  }
};
