import type { Component } from "schemas/component";
import type { ReploElementType } from "schemas/generated/element";

import * as React from "react";

import { getConditionFieldRenderData } from "replo-runtime/shared/condition";

import {
  RenderEnvironmentContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../shared/runtime-context";
import { isContextRef } from "../ReploProduct";

/**
 * Returns a string to be included in the React key for a component. The component
 * should rerender if the key changes.
 *
 * The reason we need this is sometimes there's global state that is different at
 * publish time and render time. Some examples:
 *
 * 1. Hashmarks - at publish time there is no url hashmark, but at render time there
 *    might be a hashmark currently in window.location.hash on page load. If there's a
 *    Replo state that depends on the hashmark, we need to rerender the component.
 * 2. Currencies - at publish time we use the default currency of the store, but at
 *    render time the active currency might be different, if the store is using Shopify
 *    Markets and the page is not being loaded in the default market.
 *
 * What will happen in these cases is that we'll render one thing to HTML at publish time,
 * then React will hydrate the HTML at load time, but because it's a hydration and not
 * a render, even if the virtual DOM is different, React won't update it and the visitor
 * will see outdated/incorrect HTML.
 *
 * This hook reads the global state, determines whether the current component has any
 * dependencies on it, and if so it returns a different string which when included in the
 * React component's `key` will cause it to rerender after hydration. This isn't really the
 * best solution since ideally we would "just know" the correct value for e.g. the active
 * currency on the server, but that's much harder to implement and this works for now.
 *
 * @param evaluatedDynamicDataPaths The list of dynamic data paths that were evaluated
 * for this component's props. We use these to check for dependencies like currency formatted
 * strings
 *
 * @returns a key to include in the React component's `key` prop for a given Replo component
 */
export default function useComponentRerenderKey(
  evaluatedDynamicDataPaths: string[],
  component: Component,
  elementType?: ReploElementType,
) {
  const [key, setKey] = React.useState<"initial" | "rerendered">("initial");

  /**
   * Note (Ovishek, 2022-11-15): If the component has hashmark or product variant unavailable type state actions, we force
   * the runtime React tree to re-mount by changing the key. For example any state which depend on a hashmark automatically
   * take effect after the published page has just loaded. See REPL-2577, REPL-5052, REPL-5306. This is also used
   * for making menu item's active state work for studs when opening in new tab with hashMark also for Nathan James Out of Stock state.
   */
  const componentStatesWantRemount = component.variants?.some((variant) => {
    return variant?.query?.statements.some((value) => {
      return getConditionFieldRenderData(value.field).shouldForceRemount;
    });
  });

  // NOTE (Martin, 2024-08-15): If we are in a product template and the current
  // component has the product template on its references, we need to remount
  // in order to get past potential hydration issues caused by the fact that we
  // don't know the state of the product during SSR since it's dynamic.
  const componentDependsOnProductTemplate =
    elementType === "shopifyProductTemplate" &&
    isContextRef(component.props._product) &&
    component.props._product.ref === "attributes._templateProduct" &&
    component.variants?.some((variant) => {
      return variant?.query?.statements.some((value) => {
        return getConditionFieldRenderData(value.field)
          .dependsOnProductTemplate;
      });
    });

  // NOTE (Martin, 2024-08-15): Since we don't pre-render swatches data on product
  // templates, a carousel that is using swatches data inside a product template
  // will have an empty first image on server side rendering. We need to remount
  // the component if that's the case in order to get past potential hydration
  // issues that the image might have.
  // TODO (Martin, 2024-08-15): Get rid of this remount condition as soon as we
  // support pre-rendering swatches data on product templates.
  const isElementProductTemplate = elementType === "shopifyProductTemplate";
  const isComponentAssignableToSwatchesDynamicDataList = [
    "carouselV3",
    "selectionList",
  ].includes(component.type);
  const componentHasSwatchItems =
    component.props._items?.type === "inline" &&
    component.props._items?.valueType === "dynamic" &&
    component.props._items?.dynamicPath.includes("_swatches");
  const isDynamicWithSwatchDataOnProductTemplate =
    isElementProductTemplate &&
    isComponentAssignableToSwatchesDynamicDataList &&
    componentHasSwatchItems;

  // Note (Ovishek, 2022-11-29, REPL-5260): We are checking the existence of dynamic product
  // fields that can be changed from shopify side, we should always do a re-mount to show the
  // new values to the page, that would cause a flicker and we are intentionally doing it right
  // now for a quick fix, we should think of another good solution for this thing.
  // Also see https://replohq.slack.com/archives/C037WRNNA3F/p1669667218196389 the conversation of
  // Noah and Martin on this thread.
  let sensitiveDynamicDataNeedsRemount = false;
  evaluatedDynamicDataPaths.forEach((dynamicDataPath) => {
    if (
      [
        "price",
        "name",
        "title",
        "available",
        "priceRounded",
        "featured_image",
        "compareAtPrice",
        "compareAtPriceRounded",
        "compareAtPriceDifference",
        "compareAtPriceDifferencePercentage",
        "compareAtPriceDifferenceRounded",
        "displayPrice",
        "displayPriceRounded",
        "compareAtDisplayPrice",
        "compareAtDisplayPriceRounded",
        "compareAtDisplayPriceDifference",
        "compareAtDisplayPriceDifferenceRounded",
        "productMetafields",
        "variantMetafields",
      ].some((testPath) => {
        // this regex tests if the text is inside curly braces and also it starts with a dot
        return new RegExp(`{{.*.(${testPath}).*}}`).test(dynamicDataPath);
      })
    ) {
      sensitiveDynamicDataNeedsRemount = true;
    }
  });

  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const { activeCurrency } = useRuntimeContext(ShopifyStoreContext);

  // biome-ignore lint/correctness/useExhaustiveDependencies: activeCurrency extra dep
  React.useEffect(() => {
    if (
      // Note (Noah, 2024-11-16, REPL-14705): The logic in this hook is only for hydration
      // in published pages, so we never need to trigger unnecessary re-mounts in the editor
      !isEditorApp &&
      (sensitiveDynamicDataNeedsRemount ||
        componentStatesWantRemount ||
        componentDependsOnProductTemplate ||
        isDynamicWithSwatchDataOnProductTemplate)
    ) {
      setKey("rerendered");
    }
  }, [
    sensitiveDynamicDataNeedsRemount,
    activeCurrency,
    componentStatesWantRemount,
    componentDependsOnProductTemplate,
    isDynamicWithSwatchDataOnProductTemplate,
    isEditorApp,
  ]);

  return key;
}
