import type { ElementErrorType } from "@editor/utils/element";
import type { EditorRoute } from "@editor/utils/router";
import type {
  ReploElement,
  ReploPartialElement,
} from "schemas/generated/element";

import * as React from "react";

import {
  prepareComponentTemplate,
  VERTICAL_CONTAINER_COMPONENT_TEMPLATE,
} from "@components/editor/defaultComponentTemplates";
import { ElementEditorDataContext } from "@editor/contexts/ElementEditorDataContext";
import {
  useElementEditorErrorContext,
  withElementEditorErrorContext,
} from "@editor/contexts/ElementEditorErrorContext";
import { useElementValidation } from "@editor/hooks/element";
import { useCreateElement } from "@editor/hooks/useCreateElement";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useModal } from "@editor/hooks/useModal";
import { analytics } from "@editor/infra/analytics";
import {
  selectComponentDataMapping,
  selectDraftComponent,
} from "@editor/reducers/core-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import { elementFormHasErrors } from "@editor/utils/element";
import { routes } from "@editor/utils/router";

import Button from "@replo/design-system/components/button";
import { generatePath, useLocation, useNavigate } from "react-router-dom";

import { ElementEditorModalForm } from "./editor/elementEditors/ElementEditorModalForm";
import { ElementEditorTitle } from "./editor/elementEditors/ElementEditorTitle";
import SectionElementEditor from "./editor/elementEditors/SectionElementEditor";

export const ExportToSectionModal = withElementEditorErrorContext(() => {
  const {
    element,
    onChangeElement,
    exportToSection,
    validateElement,
    isExporting,
  } = useElementToExport();

  const modal = useModal();
  const navigate = useNavigate();
  const { search } = useLocation();
  const draftComponent = useEditorSelector(selectDraftComponent);
  const { setErrors, clearErrors, errorMapping } =
    useElementEditorErrorContext();

  async function onSubmit() {
    clearErrors("path", "allErrorsFromAllFormKeys");

    const { isValid, errors: validationErrors } = validateElement(element);
    if (!isValid) {
      if (validationErrors.title.length > 0) {
        setErrors("title", validationErrors.title);
      }
      if (validationErrors.path.length > 0) {
        setErrors("path", validationErrors.path);
      }
      return;
    }

    const result = await exportToSection(element);

    if (result.isExported) {
      modal.closeModal({ type: "exportToSectionModal" });
      navigate(
        `${generatePath(routes.editor.element, {
          projectId: result.data!.element.projectId,
          elementId: result.data!.element.id,
        } as EditorRoute)}${search}`,
      );
    } else {
      if (result.errors && result.errors.path.length > 0) {
        setErrors("path", result.errors.path);
      }
    }
  }

  if (!draftComponent) {
    return null;
  }

  return (
    <ElementEditorDataContext.Provider value={{ element, onChangeElement }}>
      <ElementEditorModalForm
        testId="export-to-section-form"
        onSubmit={(e) => {
          e.preventDefault();
          if (isExporting) {
            return;
          }
          void onSubmit();
        }}
        onCloseModal={() => modal.closeModal({ type: "exportToSectionModal" })}
      >
        <ElementEditorTitle>Export To Section</ElementEditorTitle>
        <SectionElementEditor requestType="new" />
        <div className="mt-8 flex flex-row justify-end gap-x-2">
          <Button
            size="lg"
            variant="primary"
            type="submit"
            isLoading={isExporting}
            data-testid="page-submit-button"
            isDisabled={elementFormHasErrors(errorMapping)}
          >
            Export To Section
          </Button>
        </div>
      </ElementEditorModalForm>
    </ElementEditorDataContext.Provider>
  );
});

/**
 * This hook handles creation, validation and the state of a new Replo element.
 */
function useElementToExport() {
  const store = useEditorStore();
  const projectId = useCurrentProjectId();

  const [element, setElement] = React.useState<ReploPartialElement>({
    name: "",
    projectId: projectId ?? "",
    type: "shopifySection",
    hideDefaultHeader: false,
    hideDefaultFooter: false,
    hideShopifyAnnouncementBar: false,
    shopifyPagePath: "",
    shopifyTemplateSuffix: "",
    isHomepage: false,
    isPublished: false,
    shopifyMetafields: [],
    shopifyArticleImage: { src: "" },
  });

  const validateElement = useElementValidation("shopifySection");
  const { createElement, isLoading } = useCreateElement();

  const onExportToSection = React.useCallback(
    async function onExportToSectionFn(element: ReploPartialElement): Promise<
      | {
          isExported: true;
          data: { element: ReploElement };
        }
      | {
          isExported: false;
          errors?: { path: ElementErrorType[] };
        }
    > {
      const state = store.getState();
      const draftComponent = selectDraftComponent(state);
      if (draftComponent && projectId) {
        const componentDataMapping = selectComponentDataMapping(state);
        try {
          const response = await createElement({
            ...element,
            component: prepareComponentTemplate(
              {
                ...VERTICAL_CONTAINER_COMPONENT_TEMPLATE,
                template: {
                  ...VERTICAL_CONTAINER_COMPONENT_TEMPLATE.template!,
                  children: [draftComponent],
                },
              },
              null,
              null,
              {
                componentDataMapping,
                getAttribute: null,
                productResolutionDependencies: null,
                context: null,
              },
            ),
          });

          if ("key" in response && response.key === "pathAlreadyInUse") {
            return {
              isExported: false,
              errors: { path: ["pathAlreadyExists"] },
            };
          }

          if (response.element?.id) {
            analytics.logEvent("element.new", {
              operation: "create",
              type: "shopifySection",
              creationMethod: "exportToSection",
            });
            return {
              isExported: true,
              data: {
                element: response.element,
              },
            };
          }
        } catch {
          return {
            isExported: false,
          };
        }
      }

      return { isExported: false };
    },
    [createElement, projectId, store],
  );

  return {
    element,
    onChangeElement: setElement,
    exportToSection: onExportToSection,
    validateElement,
    isExporting: isLoading,
  };
}

export default ExportToSectionModal;
