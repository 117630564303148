import { slice } from "replo-runtime/store/components/CarouselV3/renderer/utils/arrayLike";
import { forOwn } from "replo-runtime/store/components/CarouselV3/renderer/utils/object/forOwn/forOwn";

/**
 * Assigns U to T.
 *
 * @param T - An object to assign to.
 * @param U - An object to assign.
 *
 * @returns An assigned object type.
 */
export type Assign<T, U> = Omit<T, keyof U> & U;

/**
 * Recursively assigns U[] to T.
 *
 * @param T - An object to assign to.
 * @param U - A tuple contains objects.
 *
 * @returns An assigned object type.
 */
export type Assigned<
  T extends object,
  N extends number,
  C extends any[] = [],
> = {
  0: T;
  1: Assigned<Assign<T, any>, N, any>;
}[C["length"] extends N ? 0 : 1];

export function assign<T extends object>(object: T): T;

export function assign<T extends object, U extends object[]>(
  object: T,
  ...sources: U
): any;

/**
 * Assigns all own enumerable properties of all source objects to the provided object.
 *
 * @param object - An object to assign properties to.
 *
 * @returns An object assigned properties of the sources to.
 */
export function assign<T extends object>(object: T): any {
  // eslint-disable-next-line prefer-rest-params, prefer-spread
  slice(arguments, 1).forEach((source) => {
    // biome-ignore lint/correctness/noUnusedVariables: This is from Splide, just ignore for now
    forOwn(source, (value, key) => {
      // @ts-ignore
      object[key] = source[key];
    });
  });

  return object;
}
