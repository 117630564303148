import type { SvgProps } from "replo-utils/react/components";

import * as React from "react";

const FixedWidthHeight = (props: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="currentColor"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.413 10.411v.74c0 .055.028.099.062.099h5.051c.034 0 .061-.044.061-.099v-.74c0-.054-.027-.098-.061-.098H6.375V1.688h2.15c.035 0 .062-.045.062-.1v-.74c0-.054-.027-.098-.061-.098H3.475c-.034 0-.062.044-.062.099v.74c0 .054.028.099.062.099h1.963V10.312H3.475c-.034 0-.062.044-.062.098Z"
      clipRule="evenodd"
    />
  </svg>
);

export default FixedWidthHeight;
