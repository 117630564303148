import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import {
  ReploElementContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { isFeatureSupported } from "../../../shared/utils/supportedFeatures";
import { wrapProductAssignmentToLiquidSource } from "../../utils/reviews";
import RenderComponentPlaceholder from "../RenderComponentPlaceholder";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const ShopifyRawLiquid: React.FC<RenderComponentProps> = ({
  component,
  context,
  componentAttributes,
}) => {
  const { storeUrl } = useRuntimeContext(ShopifyStoreContext);
  const isShopifyStoreConnected = Boolean(storeUrl);
  const { elementType } = useRuntimeContext(ReploElementContext);
  const { repeatedIndexPath, state } = context;
  const liquidContent = component.props._liquidContent ?? "";
  const product = state.product?.product ?? null;
  const liquidSourceWithProductAssignment = wrapProductAssignmentToLiquidSource(
    {
      product,
      liquidSource: liquidContent,
      context,
    },
  );

  if (!isShopifyStoreConnected) {
    return (
      <div {...componentAttributes}>
        <RenderComponentPlaceholder title="Connect Shopify to see Liquid preview." />
      </div>
    );
  }

  return (
    <SharedShopifyLiquid
      liquidSource={liquidSourceWithProductAssignment}
      forceEditorPlaceholder={false}
      componentId={component.id}
      componentAttributes={componentAttributes}
      isLiquidSupported={isFeatureSupported(elementType, "shopifyLiquid")}
      repeatedIndexPath={repeatedIndexPath}
    />
  );
};

export default ShopifyRawLiquid;
