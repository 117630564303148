import type { CommandGroup, CommandItem } from "@editor/utils/commands";
import type { Nullish } from "replo-utils/lib/types";

import * as React from "react";

import omit from "lodash-es/omit";
import { create } from "zustand";

export type DynamicCommandItem = CommandItem & {
  group: CommandGroup["id"];
  onSelect: () => void;
};

type CommandMenuStore = {
  items: Record<string, DynamicCommandItem>;
  addItem: (key: string, item: DynamicCommandItem) => void;
  removeItem: (key: string) => void;
};

const useCommandMenuStore = create<CommandMenuStore>((set) => ({
  items: {},
  addItem: (key, item) =>
    set((state) => ({ items: { ...state.items, [key]: item } })),
  removeItem: (key) => set((state) => ({ items: omit(state.items, key) })),
}));

export function useDynamicCommandMenuItems() {
  return useCommandMenuStore((state) => state.items);
}

export function useDynamicCommandMenuItem(
  key: string,
  item: DynamicCommandItem | Nullish,
) {
  const addItem = useCommandMenuStore((state) => state.addItem);
  const removeItem = useCommandMenuStore((state) => state.removeItem);

  React.useEffect(() => {
    if (!item) {
      removeItem(key);
      return;
    }

    addItem(key, item);

    return () => {
      removeItem(key);
    };
  }, [key, item, addItem, removeItem]);
}
