import type { KlaviyoIdentifiersStore } from "replo-runtime/store/contexts/KlaviyoIdentifiers/context";
import type { RuntimeActiveStateStore } from "replo-runtime/store/contexts/RuntimeActiveState/context";

import * as React from "react";

import Selectable from "@editor/components/common/designSystem/Selectable";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import {
  selectDraftComponentContext,
  selectDraftComponentId,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import { executeAction } from "replo-runtime/store/AlchemyAction";

export const TabsBanner: React.FC = () => {
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const context = useEditorSelector(selectDraftComponentContext);
  const setDraftElement = useSetDraftElement();

  if (!draftComponentId || !context?.state?.tabsBlock) {
    return null;
  }

  const activeId = context?.state?.tabsBlock?.activeTabId || null;
  const items: { id: string; value: string }[] =
    context?.state?.tabsBlock?.items || [];
  const activeItem =
    items.find((item) => item.id === activeId) ?? items?.[0] ?? null;
  const tabsComponentId = context.state.tabsBlock?.tabsBlockComponentId;
  return (
    <div
      className="-mx-3 flex flex-col items-stretch bg-purple-100 py-3 px-3"
      style={{ backgroundColor: "#DDD6FE", zIndex: 12_345 }}
    >
      <div className="mb-2 text-sm">
        Editing styles for{" "}
        <b className="hover:underline">{activeItem?.value}</b> of{" "}
        <a
          className="cursor-pointer font-bold underline"
          onClick={() => {
            setDraftElement({
              componentIds: [tabsComponentId],
            });
          }}
        >
          Tabs Block
        </a>
      </div>
      <div className="flex flex-row items-center justify-between">
        <Selectable
          value={activeItem?.id}
          options={items.map((item) => {
            return { label: item.value, value: item.id };
          })}
          onSelect={(newTabId: string) => {
            void executeAction(
              {
                id: "alchemyEditor:toggleTab",
                type: "activateTabId",
                value: {
                  tabItemId: newTabId,
                },
              },
              {
                componentId: draftComponentId,
                componentContext: context,
                repeatedIndex: "",
                products: [],
                templateProduct: null,
                stores: {
                  // Note (Noah, 2024-10-12): We shouldn't be calling executeAction
                  // in the editor like this, but this is an old legacy component, and
                  // activateTabId doesn't use this store, so it's okay to pass null
                  runtimeActiveState:
                    null as unknown as RuntimeActiveStateStore,
                  klaviyoIdentifiers:
                    null as unknown as KlaviyoIdentifiersStore,
                },
              },
            );
            setDraftElement({ componentIds: [newTabId] });
          }}
        />
      </div>
    </div>
  );
};
