import * as React from "react";

import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { twMerge } from "tailwind-merge";

type TableHeadTitleProps = {
  title: string;
  onClick?: () => void;
  arrowDirection?: "asc" | "desc";
  shouldShowArrow: boolean;
  wrapperClassName?: string;
  titleClassName?: string;
  endEnhancer?: React.ReactNode;
};

const TableHeadTitle: React.FC<TableHeadTitleProps> = ({
  title,
  onClick,
  arrowDirection,
  shouldShowArrow,
  wrapperClassName,
  titleClassName,
  endEnhancer,
}) => {
  const iconClassname = "text-sm text-default";

  return (
    <div className={wrapperClassName}>
      <div
        className={twMerge(
          "flex gap-x-2 items-center",
          onClick ? "cursor-pointer" : "cursor-default",
          titleClassName,
        )}
        onClick={onClick}
      >
        <div className="text-sm text-default font-semibold">{title}</div>
        {endEnhancer}
        {shouldShowArrow &&
          (arrowDirection === "asc" ? (
            <BsArrowUp className={iconClassname} />
          ) : (
            <BsArrowDown className={iconClassname} />
          ))}
      </div>
    </div>
  );
};

export default TableHeadTitle;
