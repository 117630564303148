import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import {
  GlobalWindowContext,
  RenderEnvironmentContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { useGetWidgetYotpo } from "../../hooks/useGetWidgetYotpo";
import { useProductFromProps } from "../../hooks/useProductFromProps";
import { RenderComponentPlaceholder } from "../RenderComponentPlaceholder";
import { SharedYotpoReviews } from "../SharedYotpoReviews";

const YotpoReviews: React.FC<RenderComponentProps> = (props) => {
  const { component, context, componentAttributes } = props;
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const globalWindow = useRuntimeContext(GlobalWindowContext);
  const { storeUrl } = useRuntimeContext(ShopifyStoreContext);
  const yotpo = useGetWidgetYotpo(
    "ReviewsMainWidget",
    component.id,
    globalWindow,
  );
  const product = useProductFromProps(component.props, context);

  if (isEditorApp && (!yotpo.version || !product)) {
    return (
      <div {...componentAttributes}>
        <RenderComponentPlaceholder title="The Yotpo Widget should appear here. Please ensure you have Yotpo installed, or reach out to support@replo.app" />
      </div>
    );
  }

  if (!product) {
    return <div {...componentAttributes}></div>;
  }

  return (
    <SharedYotpoReviews
      attributes={componentAttributes}
      component={component}
      context={context}
      htmlContent={
        yotpo?.widget
          ? `<div
              class="yotpo-widget-instance"
              data-yotpo-instance-id="${yotpo.widget.instanceId}"
              data-yotpo-product-id="${product.id}"
              data-yotpo-name="${product.title}"
              data-yotpo-url="https://${storeUrl}/products/${product.handle}"
              data-yotpo-image-url="${product.featured_image}"
              ></div>`
          : `<div
          class="yotpo yotpo-main-widget"
          data-product-id="${product.id}"
          data-name="${product.title}"
          data-url="https://${storeUrl}/products/${product.handle}"
          data-image-url="${product.featured_image}"
          ></div>`
      }
    />
  );
};

export default YotpoReviews;
