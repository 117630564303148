interface AccessibilityConfig {
  role?: string | undefined;
  hidden?: boolean | undefined;
  checked?: boolean | undefined;
  labelledBy?: string | undefined;
}

const mapAccessibilityTypeToAttributes: Record<
  keyof AccessibilityConfig,
  string
> = {
  role: "role",
  hidden: "aria-hidden",
  checked: "aria-checked",
  labelledBy: "aria-labelledby",
};

export const getAccessibilityAttributes = (
  accessibilityConfig: AccessibilityConfig,
) => {
  const accessibilityAttributes: Record<string, string | number | boolean> = {};
  for (const [key, value] of Object.entries(accessibilityConfig)) {
    if (value != null) {
      accessibilityAttributes[
        mapAccessibilityTypeToAttributes[key as keyof AccessibilityConfig]
      ] = value;
    }
  }

  if (accessibilityConfig.role === "switch") {
    accessibilityAttributes.tabIndex = 0;
  }

  return accessibilityAttributes;
};
