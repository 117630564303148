import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { usePreloadImagesFromDataTable } from "../../../shared/hooks/usePreloadImagesFromDataTable";
import {
  RenderEnvironmentContext,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { mergeContext } from "../../../shared/utils/context";
import { getRowObjectsFromDataTable } from "../../../store/AlchemyDataTable";
import { ReploComponent } from "../ReploComponent";

const Collection: React.FC<RenderComponentProps> = ({
  componentAttributes,
  component,
  context,
}) => {
  const { repeatedIndexPath = ".0" } = context;
  const dataTableMapping =
    useRuntimeContext(RuntimeHooksContext).useDataTableMapping();
  const {
    activeCurrency: currencyCode,
    activeLanguage: language,
    moneyFormat,
    templateProduct,
  } = useRuntimeContext(ShopifyStoreContext);
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const products = useRuntimeContext(RuntimeHooksContext).useShopifyProducts();

  const template = component.children?.length && component.children[0];
  const itemsConfig = component.props.items;
  const items = React.useMemo(() => {
    return (
      getRowObjectsFromDataTable(itemsConfig?.id, dataTableMapping, {
        products,
        currencyCode,
        moneyFormat,
        language,
        templateProduct,
        isEditor: isEditorApp,
      }) ?? []
    );
  }, [
    itemsConfig,
    dataTableMapping,
    products,
    currencyCode,
    moneyFormat,
    language,
    templateProduct,
    isEditorApp,
  ]);
  usePreloadImagesFromDataTable(itemsConfig?.id, { skipPreload: !template });

  if (!template) {
    return null;
  }

  return (
    <div {...componentAttributes}>
      {items?.map((item, index) => (
        <ReploComponent
          key={index}
          component={template}
          context={mergeContext(context, {
            attributes: { _currentItem: item },
          })}
          repeatedIndexPath={`${repeatedIndexPath}.${index}`}
        />
      ))}
    </div>
  );
};

export default Collection;
