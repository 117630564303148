import type { SvgProps } from "replo-utils/react/components";

import * as React from "react";

import { Svg } from "replo-utils/react/components";

export const SvgKlaviyo = (props: SvgProps) => (
  <Svg width="1em" height="1em" viewBox="0 0 92 62" fill="none" {...props}>
    <path
      fill="url(#klaviyo_svg__a)"
      d="M1.862 28.076 43.11.78c1.627-1.04 4.164-1.04 5.79 0l41.247 27.296c2.472 1.624 2.472 4.289 0 5.914l-5.53 3.639C76.484 24.826 62.17 16.377 45.972 16.377c-16.265 0-30.513 8.514-38.645 21.252l-5.53-3.64c-2.407-1.624-2.407-4.354.065-5.913Zm44.11-.325c-12.296 0-23.03 6.498-29.146 16.117l9.498 6.304c4.034-6.629 11.255-11.048 19.583-11.048 8.262 0 15.548 4.42 19.582 11.048l9.499-6.304c-5.92-9.619-16.72-16.117-29.016-16.117Zm0 22.81c-4.294 0-8.068 2.406-10.084 5.915l6.375 4.16C43.304 61.48 44.54 62 45.972 62c1.43 0 2.732-.52 3.708-1.365l6.376-4.159c-1.887-3.444-5.726-5.914-10.084-5.914Z"
    />
    <defs>
      <linearGradient
        id="klaviyo_svg__a"
        x1={46}
        x2={46}
        y1={62}
        y2={0}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F0C21A" />
        <stop offset={0.331} stopColor="#93C94B" />
        <stop offset={0.486} stopColor="#70C05A" />
        <stop offset={0.751} stopColor="#12B259" />
        <stop offset={0.903} stopColor="#22AD69" />
        <stop offset={1} stopColor="#22AD69" />
      </linearGradient>
    </defs>
  </Svg>
);
export default SvgKlaviyo;
