export type ReorderableTreeNode = {
  id: string;
  label: string;
  children: ReorderableTreeNode[];
};

export type TreeDropInfo = {
  dropPosition: number;
  dropParentId: string;
};

// biome-ignore lint/nursery/noEnum: This is a legacy enum, we should convert to a string union
export enum DropEdge {
  beforeCurrentNode = "beforeCurrentNode",
  afterCurrentNode = "afterCurrentNode",
  middle = "middle",
}

export type ReorderableTreeDropTarget = {
  newParentNodeId: string;
  positionWithinChildren: number;
};
