export type DomainProvider = {
  name: string;
  url: string;
};

export const DOMAIN_PROVIDERS: DomainProvider[] = [
  {
    name: "Shopify",
    url: "https://help.shopify.com/en/manual/domains/add-a-domain/add-subdomains",
  },
  {
    name: "Cloudflare",
    url: "https://developers.cloudflare.com/dns/manage-dns-records/how-to/create-subdomain",
  },
  { name: "GoDaddy", url: "https://www.godaddy.com/help/add-a-subdomain-4080" },
  {
    name: "Squarespace",
    url: "https://support.squarespace.com/hc/en-us/articles/205812058-Creating-a-subdomain-for-your-Squarespace-site",
  },
  {
    name: "Namecheap",
    url: "https://www.namecheap.com/support/knowledgebase/article.aspx/9776/2237/how-to-create-a-subdomain-for-my-domain",
  },
];
