import * as React from "react";

/**
 * Passes or assigns a value to multiple refs (typically a DOM node). Useful for
 * dealing with components that need an explicit ref for DOM calculations but
 * also forwards refs assigned by an app.
 *
 * IMPORTANT: We always expect refs to persist between renders, but since refs
 * is a variadic argument list we ignore React's lint warning internally because
 * the resulting callback must be memoized. This is technically a risk because
 * we need to always pass a static array of fixed length to the effect, meaning
 * `useComposedRefs` should never accept arguments conditionally. Never ever
 * pass anything other than an actual React ref as an argument to
 * `useComposedRefs`.
 *
 * @example
 * ```tsx
 * // this is ok!
 * const composedRef = useComposedRefs(ref, props.innerRef);
 * // this is *not* ok because the internal deps array holds different values between renders!
 * const composedRef = useComposedRefs(...[ref, props.innerRef].filter(someConditionCheck));
 * // this is *not* ok because the callback ref isn't memoized!
 * const composedRef = useComposedRefs(ref, props.innerRef, (node) => setNode(node));
 * ```
 */
export function useComposedRefs<RefValueType = unknown>(
  ...refs: (AssignableRef<RefValueType> | null | undefined)[]
) {
  // See docblock for explanation on why we ignore here
  // biome-ignore lint/correctness/useExhaustiveDependencies: see docblock on why we ignore
  return React.useCallback((node: RefValueType) => {
    for (const ref of refs) {
      assignRef(ref, node);
    }
  }, refs);
}

/**
 * React.Ref uses the readonly type `React.RefObject` instead of
 * `React.MutableRefObject`, We pretty much always assume ref objects are
 * mutable, so this type is a workaround so some of the weird mechanics of using
 * refs with TS.
 */
type AssignableRef<ValueType> =
  | {
      bivarianceHack(instance: ValueType | null): void;
    }["bivarianceHack"]
  | React.MutableRefObject<ValueType | null>;

/**
 * Passes or assigns an arbitrary value to a ref function or object.
 */
function assignRef<RefValueType = unknown>(
  ref: React.Ref<RefValueType> | null | undefined,
  value: RefValueType,
) {
  if (ref == null) {
    return;
  }
  if (typeof ref === "function") {
    ref(value);
  } else {
    try {
      (ref as React.MutableRefObject<RefValueType>).current = value;
    } catch {
      console.warn(
        `Cannot assign value "${value}" to ref "${ref}". This is likely a bug. Make sure refs are passed as stable callback functions or mutable ref objects. String refs are not supported.`,
      );
    }
  }
}
