import type { DynamicCommandItem } from "@editor/hooks/useDynamicCommandMenuItems";
import type {
  CommandGroupWithActions,
  CommandItem,
  Command as ReploCommand,
} from "@editor/utils/commands";
import type {
  HotkeyIndicatorCharacter,
  HotkeyMetaKey,
} from "@editor/utils/hotkeys";

import * as React from "react";

import {
  allElementTypes,
  elementTypeToEditorData,
} from "@editor/components/editor/element";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { useDynamicCommandMenuItems } from "@editor/hooks/useDynamicCommandMenuItems";
import { useGlobalEditorActions } from "@editor/hooks/useGlobalEditorActions";
import { useModal } from "@editor/hooks/useModal";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import {
  selectCommandMenuItems,
  selectPages,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { HotkeyMetaKeyToLabel } from "@editor/utils/hotkeys";
import { isMacintosh } from "@editor/utils/platform";
import { generateEditorPathname, routes } from "@editor/utils/router";
import { getEmptyTemplate } from "@editor/utils/template";

import { Command } from "cmdk";
import mapValues from "lodash-es/mapValues";
import { MdAdd } from "react-icons/md";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { CATEGORIES_IDS } from "schemas/componentTemplates";
import { twMerge } from "tailwind-merge";

export function CommandMenu() {
  const location = useLocation();
  const commandMenuItems = useCommandMenuItems();
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setIsOpen((isOpen) => !isOpen);
      }
    }

    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  React.useEffect(() => {
    if (location) {
      setIsOpen(false);
    }
  }, [location]);

  return (
    <Command.Dialog
      open={isOpen}
      onOpenChange={setIsOpen}
      label="Replo Command Menu"
    >
      <Command.Input placeholder="Type a command or search" />

      <Command.List>
        <Command.Empty>No results found.</Command.Empty>
        {commandMenuItems.map((group) => (
          <Command.Group key={group.heading} heading={group.heading}>
            {Object.entries(group.items)
              .filter(([, item]) => !item.isDisabled)
              .map(([key, item]) => (
                <Command.Item
                  key={key}
                  onSelect={() => {
                    item.onSelect();
                    setIsOpen(false);
                  }}
                >
                  <div className="icon">
                    <CommandIcon group={group.id} item={item} />
                  </div>
                  <div className="label">{item.label}</div>
                  {item.hotkey && (
                    <div className="ml-auto text-slate-400">
                      {getHotkeyLabel(item.hotkey)}
                    </div>
                  )}
                </Command.Item>
              ))}
          </Command.Group>
        ))}
      </Command.List>
    </Command.Dialog>
  );
}

function CommandIcon({
  group,
  item,
}: {
  group: CommandGroupWithActions["id"];
  item: CommandItem;
}) {
  if (group === "create") {
    return <MdAdd size={16} />;
  }

  if (group === "navigate") {
    return (
      <div className="w-[16px] h-[16px] flex items-center justify-center">
        <div
          className={twMerge(
            "w-[8px] h-[8px] rounded-full",
            item.metadata?.pageIsPublished ? "bg-green-500" : "bg-slate-400",
          )}
        />
      </div>
    );
  }

  if (item.icon) {
    const Icon = item.icon;
    return <Icon size={16} />;
  }

  return <div className="w-[16px] h-[16px] bg-slate-200 rounded-full" />;
}

function useCommandMenuItems(): CommandGroupWithActions[] {
  const commandMenuItems = useEditorSelector(selectCommandMenuItems);
  const globalActions = useGlobalEditorActions();
  const createMenuItems = useCreateMenuItems();
  const pagesMenuItems = usePagesMenuItems();
  const dynamicMenuItems = useDynamicCommandMenuItems();

  return commandMenuItems
    .map((group) => {
      return {
        ...group,
        items: appendDynamicItemsToGroup(
          // TODO (Martin 2024-12-09): Fix this type error
          // @ts-expect-error
          mapValues(group.items, (item: CommandItem, key: string) =>
            Object.assign(item, {
              onSelect: globalActions[key as ReploCommand],
            }),
          ),
          dynamicMenuItems,
          group.id,
        ),
      };
    })
    .concat([
      {
        id: "create",
        heading: "Create",
        items: appendDynamicItemsToGroup(
          createMenuItems,
          dynamicMenuItems,
          "create",
        ),
      },
      {
        id: "navigate",
        heading: "Navigate to...",
        items: appendDynamicItemsToGroup(
          pagesMenuItems,
          dynamicMenuItems,
          "navigate",
        ),
      },
    ]);
}

function useCreateMenuItems(): CommandGroupWithActions["items"] {
  const modal = useModal();
  const navigate = useNavigate();
  const params = useParams();

  const items: CommandGroupWithActions["items"] = {};

  for (const type of allElementTypes) {
    items[`${type}-blank`] = {
      label: `Create blank ${elementTypeToEditorData[type].singularDisplayName.toLowerCase()}`,
      onSelect: () => {
        modal.openModal({
          type: "createElementModal",
          props: {
            initialElementType: type,
            initialTemplate: getEmptyTemplate(type),
            initialName: undefined,
          },
        });
      },
    };
    items[`${type}-template`] = {
      label: `Create ${elementTypeToEditorData[type].singularDisplayName.toLowerCase()} from template`,
      onSelect: () => {
        let categoryId: string | undefined;
        let generatedPath: string;
        if (type === "shopifyArticle") {
          categoryId = CATEGORIES_IDS.blogPostPage;
          generatedPath = generateEditorPathname(routes.marketplaceModal, {
            projectId: params.projectId ?? "",
            elementId: params.elementId,
          });
        } else if (type === "shopifyProductTemplate") {
          categoryId = CATEGORIES_IDS.productPageTemplates;
          generatedPath = generateEditorPathname(routes.marketplaceModal, {
            projectId: params.projectId ?? "",
            elementId: params.elementId,
          });
        } else {
          generatedPath = generateEditorPathname(routes.marketplaceModal, {
            projectId: params.projectId ?? "",
            elementId: params.elementId,
          });
        }
        navigate(generatedPath, {
          state: {
            elementType: type,
            initialName: undefined,
            marketplaceModalRequestType: "create",
            // NOTE (Fran 2024-10-16): When we create a new element using a template, we need to pass
            // the pre-selected filters so the user can find a template that matches the element type.
            // This is only needed if the user creates a new Blog Post or Product Page. For the
            // other element types, we can have no selected filters.
            selectedFilters: {
              category: categoryId ? [categoryId] : [],
              badge: [],
              industry: [],
            },
          },
        });
      },
    };
  }

  return items;
}

function usePagesMenuItems(): CommandGroupWithActions["items"] {
  const navigate = useNavigate();
  const { project } = useCurrentProjectContext();
  const setDraftElement = useSetDraftElement();
  const projectId = project?.id;
  const pages = useEditorSelector(selectPages);
  return Object.fromEntries(
    pages.map((page) => {
      return [
        page.id,
        {
          label: page.name,
          metadata: {
            pageIsPublished: page.isPublished ?? false,
          },
          onSelect: () => {
            setDraftElement({
              componentIds: [],
            });
            navigate(
              generatePath(routes.editor.element, {
                projectId,
                elementId: page.id,
              }),
            );
          },
        },
      ];
    }),
  );
}

function appendDynamicItemsToGroup(
  items: CommandGroupWithActions["items"],
  dynamicItems: Record<string, DynamicCommandItem>,
  groupId: string,
): CommandGroupWithActions["items"] {
  return Object.assign(
    items,
    Object.fromEntries(
      Object.entries(dynamicItems).filter(([, item]) => item.group === groupId),
    ),
  );
}

const getHotkeyLabel = (hotkey: HotkeyIndicatorCharacter[]) => {
  return hotkey.map((character) => {
    const metaKeyLabel = HotkeyMetaKeyToLabel[character as HotkeyMetaKey];
    if (!metaKeyLabel) {
      return character.toUpperCase();
    }

    if (typeof metaKeyLabel === "string") {
      return metaKeyLabel;
    }

    return isMacintosh() ? metaKeyLabel.mac : metaKeyLabel.windows;
  });
};
