import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { setIsDraggingSpacingIndicators } from "@reducers/drag-and-drop-reducer";

export default function useIsDraggingSpacingIndicators() {
  const isDraggingSpacingIndicators = useEditorSelector(
    (state) => state.dragAndDrop.isDraggingSpacingIndicators,
  );
  const dispatch = useEditorDispatch();

  return {
    isDraggingSpacingIndicators,
    setIsDraggingSpacingIndicators: (isDraggingSpacingIndicators: boolean) =>
      dispatch(setIsDraggingSpacingIndicators(isDraggingSpacingIndicators)),
  };
}
