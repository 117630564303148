import type { ComponentTemplate } from "@editor/types/component-template";
import type { DropTarget, DropTargetEdge } from "@editor/types/drop-target";
import type { GetAttributeFunction } from "@editor/types/get-attribute-function";
import type { ComponentDataMapping } from "replo-runtime/shared/Component";
import type {
  BoundedComponent,
  NumericBounds,
  Position,
  Range,
} from "replo-runtime/shared/types";
import type { NormalizedFlexDirection } from "replo-runtime/shared/utils/flexDirection";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { Component } from "schemas/component";
import type { ReploElement } from "schemas/generated/element";

import {
  canMoveComponentToParent,
  findAncestorComponent,
  getEditorComponentNode,
} from "@utils/component";

import { findParent, getChildren } from "replo-runtime/shared/utils/component";
import { getNormalizedFlexDirection } from "replo-runtime/shared/utils/flexDirection";
import { getRenderData } from "replo-runtime/store/components";
import { filterNulls } from "replo-utils/lib/array";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

/**
 * Given a 2D rectangle, return a 1D range of the bounds of the
 * given rect along the given axis.
 */
function getAxisRange(
  direction: NormalizedFlexDirection,
  bounds: NumericBounds,
): Range {
  return direction === "row"
    ? { lower: bounds.left, upper: bounds.left + bounds.width }
    : { lower: bounds.top, upper: bounds.top + bounds.height };
}

/**
 * Whether the given value is inside the range's bounds, inclusive at edges.
 */
const inRange = (value: number, range: Range) => {
  return range.lower <= value && value <= range.upper;
};

/**
 * Get the halfway point of the given range.
 */
const getRangeMidpoint = (range: Range) => {
  return range.lower + (range.upper - range.lower) / 2;
};

type AxisEdges = { start: DropTargetEdge; end: DropTargetEdge };

/**
 * Given a position in the canvas, find the closest edge of an element in that position.
 *
 * @param position The position of the mouse in canvas coordinates
 * @param bounds The bounds of the candidate component
 * @returns The closest edge of the candidate component to the mouse
 */
const getClosestEdge = (
  position: Position,
  bounds: NumericBounds,
): DropTargetEdge => {
  const { top, left, width, height } = bounds;
  const { x, y } = position;
  const distanceToTop = Math.abs(y - top);
  const distanceToRight = Math.abs(x - (left + width));
  const distanceToBottom = Math.abs(y - (top + height));
  const distanceToLeft = Math.abs(x - left);
  const closestEdge: { [key: string]: number } = {
    top: distanceToTop,
    right: distanceToRight,
    bottom: distanceToBottom,
    left: distanceToLeft,
  };

  const closest = Object.keys(closestEdge).reduce(
    (closest: string, key: string) => {
      return closestEdge[closest]! < closestEdge[key]! ? closest : key;
    },
    "bottom",
  );

  return closest as DropTargetEdge;
};

/**
 * Assuming the user is hovering over the given candidate component at the given position,
 * return the correct edge to highlight as the drop target. This function takes care of
 * figuring out intracies of which side of which child to drop into based on the current
 * position.
 *
 * @param direction Direction of the candidateBoundedComponent (we assume it's a flexbox)
 * @param candidateBoundedComponent The component being hovered (and its bounds in the
 * same coordinate system as `position`)
 * @param boundedChildren The candidate's children (and their bounds)
 * @param position The position to query a drop target for
 * @returns Which component should be dropped next to/into, and which side
 */
export function getInsideDropTargetEdge(
  direction: "row" | "column",
  candidateBoundedComponent: BoundedComponent,
  boundedChildren: BoundedComponent[],
  position: Position,
): { edge: DropTargetEdge; componentId: string } | null {
  if (boundedChildren.length === 0) {
    return {
      edge: "inside",
      componentId: candidateBoundedComponent.component.id,
    };
  }

  const crossDirection: "row" | "column" =
    direction === "row" ? "column" : "row";

  const majorRangeEdges: AxisEdges =
    direction === "row"
      ? { start: "left", end: "right" }
      : { start: "top", end: "bottom" };
  const crossRangeEdges: AxisEdges =
    direction === "row"
      ? { start: "top", end: "bottom" }
      : { start: "left", end: "right" };

  const candidateMajorRange = getAxisRange(
    direction,
    candidateBoundedComponent.bounds,
  );
  const candidateCrossRange = getAxisRange(
    crossDirection,
    candidateBoundedComponent.bounds,
  );
  const majorAxisPosition = direction === "row" ? position.x : position.y;
  const crossAxisPosition = direction === "row" ? position.y : position.x;
  const childMajorRanges = boundedChildren.map((boundedChild) =>
    getAxisRange(direction, boundedChild.bounds),
  );
  const firstChildMajorRange = childMajorRanges[0]!;
  const lastChildMajorRange = childMajorRanges[childMajorRanges.length - 1]!;

  if (
    inRange(majorAxisPosition, {
      lower: candidateMajorRange.lower,
      upper: firstChildMajorRange.lower,
    })
  ) {
    // We're between the start of the candidate node and its first child. We should go right before
    // the first child
    return {
      edge: majorRangeEdges.start,
      componentId: boundedChildren[0]!.component.id,
    };
  }

  if (
    inRange(majorAxisPosition, {
      lower: lastChildMajorRange.upper,
      upper: candidateMajorRange.upper,
    })
  ) {
    // We're between the end of the last child and the end of the candidate node. We should go
    // right after the last child
    return {
      edge: majorRangeEdges.end,
      componentId: boundedChildren[boundedChildren.length - 1]!.component.id,
    };
  }

  for (let i = 0; i < boundedChildren.length - 1; i++) {
    const currentBoundedChild = boundedChildren[i]!;
    const currentBoundedChildRange = childMajorRanges[i]!;
    const nextBoundedChildRange = childMajorRanges[i + 1]!;

    if (
      inRange(majorAxisPosition, {
        lower: currentBoundedChildRange.upper,
        upper: nextBoundedChildRange.lower,
      })
    ) {
      // We're in between child i and child i + 1. We should go right after i
      return {
        edge: majorRangeEdges.end,
        componentId: currentBoundedChild.component.id,
      };
    }
  }

  // If we're not in any of the in-between ranges, we must be in the range of a child
  // on the major axis (e.g. vertical for direction = column), and since we're not
  // looking at that child itself we must be looking outside the bounds of the child
  // on the cross axis. Our only goal now is to determine whether to put the new component
  // before or after the child on the cross axis (e.g. in a vertical stack, we have to
  // determine whether to place the new component to the left/right of the existing child)
  for (const [i, boundedChild] of boundedChildren.entries()) {
    const childMajorRange = childMajorRanges[i]!;
    const childCrossRange = getAxisRange(crossDirection, boundedChild.bounds);
    if (
      inRange(majorAxisPosition, childMajorRange) &&
      inRange(crossAxisPosition, {
        lower: candidateCrossRange.lower,
        upper: childCrossRange.lower,
      })
    ) {
      return {
        edge: crossRangeEdges.start,
        componentId: boundedChild.component.id,
      };
    }

    if (
      inRange(majorAxisPosition, childMajorRange) &&
      inRange(crossAxisPosition, {
        lower: childCrossRange.upper,
        upper: candidateCrossRange.upper,
      })
    ) {
      return {
        edge: crossRangeEdges.end,
        componentId: boundedChild.component.id,
      };
    }

    if (inRange(majorAxisPosition, childMajorRange)) {
      const childMajorRangeMid = getRangeMidpoint(childMajorRange);
      if (majorAxisPosition > childMajorRangeMid) {
        return {
          edge: majorRangeEdges.end,
          componentId: boundedChild.component.id,
        };
      }
      return {
        edge: majorRangeEdges.start,
        componentId: boundedChild.component.id,
      };
    }
  }

  console.warn("Unexpectedly could not determine drop target edge", {
    boundedChildren,
    candidateBoundedComponent,
    position,
  });
  return null;
}

type DropTargetQueryResult =
  | { type: "error"; error: string }
  | { type: "success"; edge: DropTargetEdge; componentId: string };

/**
 * Return the drop target to highlight assuming the user has dragged to the
 * given position.
 *
 * This function is the main one we use during drag/drop of existing and new
 * components to figure out where we should put the dropped component in
 * relation to other components.
 *
 * @param position The current position to query the drop target for
 * @param dragSource The component or template which is being dragged (or null
 * if it's a new component)
 * @param candidateBoundedComponent The component we're currently hovered over
 * @param boundedChildren The currently hovered component's children
 * @param draftElement The currently editing element
 * @param getAttribute Attribute accessor for the components' props
 * @returns A successful drop target if we could find one, or an error message
 * if a drop at this position is invalid (component doesn't allow new children,
 * etc)
 */
export function getDropTargetEdge(
  position: Position,
  dragSource: ComponentOrTemplate | null,
  candidateBoundedComponent: BoundedComponent,
  boundedChildren: BoundedComponent[],
  /**
   * TODO (Noah, 2021-07-27): Since we get this Element from the draft element,
   * it's sometimes null, like when we're dragging a component into the page when
   * there is no draft element. What we do now is disable all element checks like
   * canMoveComponentToParent, which means we actually allow dropping into places
   * where components shouldn't be able to be dropped. What we should do instead
   * is get the element from the candidate node's root element id, pass in that
   * element here instead of the draft element, then do the element calculation
   * to figure out whether to allow drops.
   */
  draftElement: ReploElement | null,
  getAttribute: GetAttributeFunction,
  componentDataMapping: ComponentDataMapping,
): DropTargetQueryResult | null {
  const {
    component: candidateComponent,
    bounds: { top, left, width, height },
  } = candidateBoundedComponent;
  if (!dragSource || !draftElement) {
    return null;
  }

  const sourceComponent =
    dragSource.type === "component"
      ? dragSource.component
      : dragSource.template.template;

  // Note (Fran/Noah, 2022-01-19/2023-06-18): We don't have to do anything if
  // we're dragging a component over itself, since all edges don't make sense.
  // Top/bottom in a vertical stack or left/right in a horizontal stack would
  // mean we dropped the component in the same place, and the reverse axis would
  // mean that we're trying to drop the component into a new container next to
  // itself
  if (!sourceComponent || sourceComponent?.id === candidateComponent?.id) {
    return null;
  }

  const movementType = exhaustiveSwitch(dragSource)({
    component: () => "canvas" as const,
    template: () => "leftBarTemplate" as const,
  });

  let possibleParent = candidateComponent;

  // STEP 1: Determine the parent we're dragging into. This is either:
  // 1. The candidate component itself, if it can accept the child
  // 2. The candidate component's parent, in which case we'll drop the component
  //    as a sibling to the candidate component, to the top/left/right/bottom of it
  const initialResult = canMoveComponentToParent(
    draftElement,
    sourceComponent,
    candidateComponent,
    getAttribute,
    movementType,
    componentDataMapping,
  );
  if (!initialResult.canMove && initialResult.terminateSearch) {
    return { error: initialResult.message, type: "error" };
  }
  if (!initialResult.canMove) {
    const parentOfCandidateNode = findParent(
      draftElement,
      candidateComponent.id,
    );
    if (parentOfCandidateNode) {
      possibleParent = parentOfCandidateNode;
    }
  }

  /**
   * Whether the candidate component (the one we're dragging over) is the new
   * possibly parent or not. This would be true if we're dragging over a container,
   * but false if we're dragging over something like an image and we're going to
   * insert the new component to the left/right/top/bottom of the image, as a sibling
   * to the image, in the image's container.
   */
  const candidateIsPossibleParent = possibleParent === candidateComponent;

  // STEP 2: Now that we've determined the parent, figure out if that parent can
  // accept the component as a new child. If not, return an error.
  const parentResult = canMoveComponentToParent(
    draftElement,
    sourceComponent,
    possibleParent,
    getAttribute,
    movementType,
    componentDataMapping,
  );
  if (!parentResult.canMove) {
    return { error: parentResult.message, type: "error" };
  }

  const shouldBeRootChild = getRenderData(
    sourceComponent.type,
  )?.shouldBeRootChild?.(movementType);
  if (shouldBeRootChild) {
    return {
      type: "success",
      edge: getClosestEdge(position, candidateBoundedComponent.bounds),
      componentId: draftElement.component.id,
    };
  }

  // STEP 3: If we're dragging in a template, it might be that the template itself
  // does not want to be added as a child of the possible parent (for example, a
  // product price doesn't want to go inside a non-product container). If that's the
  // case, return an error
  if (dragSource.type === "template" && dragSource.template.canBeAddedAsChild) {
    const data = dragSource.template.canBeAddedAsChild?.({
      parent: possibleParent,
      element: draftElement,
    });
    if (data.canBeAdded === false) {
      return { error: data.message, type: "error" };
    }
  }

  // STEP 4: If the candidate component (the one we're dragging over) is NOT
  // the new parent, then we drop the component as a sibling to the candidate component
  if (!candidateIsPossibleParent) {
    return {
      type: "success",
      edge: getClosestEdge(position, candidateBoundedComponent.bounds),
      componentId: candidateBoundedComponent.component.id,
    };
  }

  // STEP 5: Otherwise, we're dropping the component inside the candidate component.
  // Figure out which side of an existing sibling to drop it beside, or whether to
  // just straight up drop it into the container.
  const boxTop = top;
  const boxLeft = left;
  const boxRight = boxLeft + width;
  const boxBottom = boxTop + height;

  const paddingBound = 20;

  if (
    inRange(position.x, {
      lower: boxLeft + paddingBound,
      upper: boxRight - paddingBound,
    }) &&
    inRange(position.y, {
      lower: boxTop + paddingBound,
      upper: boxBottom - paddingBound,
    }) &&
    candidateComponent
  ) {
    const result = getInsideDropTargetEdge(
      getNormalizedFlexDirection(
        getAttribute(candidateComponent, "style.flexDirection", null).value ||
          ("row" as "column" | "row"),
      ),
      candidateBoundedComponent,
      boundedChildren,
      position,
    );

    if (!result) {
      return null;
    }
    const { componentId, edge } = result;

    return {
      edge,
      componentId,
      type: "success",
    };
  }

  const horizontalSide =
    position.x - boxLeft < boxRight - position.x ? "left" : "right";
  const verticalSide =
    position.y - boxTop < boxBottom - position.y ? "top" : "bottom";

  // Note (Noah, 2023-12-22, REPL-9838): We're now going to return a side
  // of the candidate component, which means we're going to add the source component
  // as a child of the candidate component's parent. If that's not allowed,
  // return null
  const candidateParent = findParent(draftElement, candidateComponent.id);
  if (
    candidateParent &&
    !canMoveComponentToParent(
      draftElement,
      sourceComponent,
      candidateParent,
      getAttribute,
      movementType,
      componentDataMapping,
    ).canMove
  ) {
    return null;
  }

  return {
    type: "success",
    componentId: candidateComponent.id,
    edge:
      Math.min(position.x - boxLeft, boxRight - position.x) <
      Math.min(position.y - boxTop, boxBottom - position.y)
        ? horizontalSide
        : verticalSide,
  };
}

/**
 * Given a result drop target query, transforms it into a DropTarget.
 *
 * TODO (Noah, 2022-01-06): The only reason this really exists is to
 * find the DOM node corresponding to the dropped-on component - it's
 * a code smell that we even need this candidate node. We can probably
 * remove this after a bit of refactoring
 *
 * @param result Result of querying for the drop target
 * @param existingDropTarget The previous drop target we were showing
 */
function processDropTargetResult(
  result: DropTargetQueryResult | null,
  existingDropTarget: DropTarget,
): DropTarget | null {
  if (!result) {
    return null;
  }

  if (result?.type === "error" && !existingDropTarget.componentId) {
    return { error: result.error, componentId: null, edge: null };
  } else if (result?.type === "success") {
    return {
      edge: result.edge,
      componentId: result.componentId,
      error: null,
    };
  }
  return null;
}

function getDropTargetEdgeFromNode(
  positionInCanvasCoordinates: Position,
  candidateNode: HTMLElement,
  draggingComponent: ComponentOrTemplate,
  candidateComponent: Component,
  draftElement: ReploElement,
  getAttribute: GetAttributeFunction,
  componentDataMapping: ComponentDataMapping,
) {
  return getDropTargetEdge(
    positionInCanvasCoordinates,
    draggingComponent,
    {
      component: candidateComponent,
      bounds: candidateNode.getBoundingClientRect(),
    },
    filterNulls(
      getChildren(candidateComponent).map((c) => {
        const boundingRect = candidateNode
          .querySelector(`[data-rid="${c.id}"]`)
          ?.getBoundingClientRect();
        if (!boundingRect) {
          return null;
        }
        return {
          bounds: boundingRect,
          component: c,
        };
      }),
    ),
    draftElement,
    getAttribute,
    componentDataMapping,
  );
}

type ComponentOrTemplate =
  | { type: "component"; component: Component }
  | { type: "template"; template: ComponentTemplate };

/**
 * Process an incremental drag and the given position and return a new
 * drop target if one is relevant.
 *
 * @param positionInCanvasCoordinates Position to query, scaled/translated to canvas zoom
 * @param candidateNode Currently hovered node
 * @param draggingComponent The component (or component template) we're dragging in
 * @param candidateComponent The component we're hovered over
 * @param existingDropTarget The previous drop target we had before this drag, if any
 * @param draftElement The element we're editing
 * @param getAttribute Attribute retriever for components
 */
export function processDropTargetDrag(
  positionInCanvasCoordinates: Position,
  candidateNode: HTMLElement | null,
  draggingComponent: ComponentOrTemplate,
  candidateComponent: Component | null,
  existingDropTarget: DropTarget,
  draftElement: ReploElement,
  getAttribute: GetAttributeFunction,
  componentDataMapping: ComponentDataMapping,
  canvas: EditorCanvas,
): DropTarget | null {
  if (!candidateComponent || !candidateNode) {
    return null;
  }

  const ownerDocument = candidateNode.ownerDocument;

  let result = getDropTargetEdgeFromNode(
    positionInCanvasCoordinates,
    candidateNode,
    draggingComponent,
    candidateComponent,
    draftElement,
    getAttribute,
    componentDataMapping,
  );

  // Note (Noah, 2022-01-09): If we're dragging in a template and it can be
  // added NOT to this node but possibly to an ancestor, make sure we check
  // ancestors so that dragging over a deeply nested child doesn't preclude
  // us from dragging into an ancestor container. E.g., if a template can only
  // be a top-level section, make sure we let you drop it adjacent to the
  // closest top-level section even if that's not the node we're hovering over
  if (
    (!result || result.type === "error") &&
    draggingComponent.type === "template" &&
    draggingComponent.template.canBeAddedAsChild
  ) {
    const possiblyAbleToBeAddedToAncestor = findAncestorComponent(
      draftElement,
      candidateComponent.id,
      (component) => {
        return (
          draggingComponent.template.canBeAddedAsChild?.({
            parent: component,
            element: draftElement,
          })?.canBeAdded ?? false
        );
      },
    );
    if (possiblyAbleToBeAddedToAncestor) {
      const ancestorNode = getEditorComponentNode({
        targetDocument: ownerDocument,
        canvas,
        elementId: draftElement.id,
        componentId: possiblyAbleToBeAddedToAncestor.id,
      });
      if (ancestorNode) {
        result = getDropTargetEdgeFromNode(
          positionInCanvasCoordinates,
          ancestorNode,
          draggingComponent,
          possiblyAbleToBeAddedToAncestor,
          draftElement,
          getAttribute,
          componentDataMapping,
        );
      }
    }
  }

  return processDropTargetResult(result, existingDropTarget);
}
