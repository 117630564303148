import type {
  SavedStyleColorAttributes,
  SavedStyleTextAttributes,
} from "schemas/generated/savedStyles";

import { exhaustiveSwitch } from "replo-utils/lib/misc";

export const convertColorTypeToReadableString = (
  color: SavedStyleColorAttributes["colorType"],
) => {
  return exhaustiveSwitch({ type: color })({
    basic: "Basic",
    foregroundPrimary: "Foreground/Primary",
    foregroundSecondary: "Foreground/Secondary",
    foregroundTertiary: "Foreground/Tertiary",
    backgroundPrimary: "Background/Primary",
    backgroundSecondary: "Background/Secondary",
    backgroundTertiary: "Background/Tertiary",
  });
};

export const convertHtmlTagToReadableString = (
  tag: SavedStyleTextAttributes["htmlTag"],
) => {
  return exhaustiveSwitch({ type: tag })({
    h1: "Heading 1",
    h2: "Heading 2",
    h3: "Heading 3",
    h4: "Heading 4",
    h5: "Heading 5",
    h6: "Heading 6",
    p: "Paragraph",
  });
};
