/**
 * Note (Noah, 2023-04-25, USE-105): Earlier today Shopify
 * deployed a new cdn scheme for theme assets where all the
 * default css files are served from relative URLs. This messes
 * with our styles in the canvas iframe, so we need to rewrite
 * these URLs to be absolute in order for theme styles to load
 * correctly in the canvas so that headers/footers look correct.
 */
export function replaceCdnUrls(input: string, storeUrl: string): string {
  const myShopifyStoreUrlRegex = /[\dA-Za-z-]+\.myshopify\.com/;
  const prefix = `//${storeUrl}`;
  // This handles all the different cases Shopify adds in relative urls for,
  // like <link> hrefs, <img> srcs and srcsets, etc. Also excludes
  // /cdn.shopify.com urls
  const doubleSlashStoreUrlSlashCdnPattern = new RegExp(
    `(${prefix})?\\/cdn\\/`,
    "g",
  );

  // Note (Noah, 2023-07-13): It may be that the url is of the form //some-domain.com/cdn/...
  // and in this case, we don't want to replace it since that will work correctly in the editor
  if (
    input.includes(prefix) &&
    !doubleSlashStoreUrlSlashCdnPattern.test(input)
  ) {
    return input;
  }
  if (myShopifyStoreUrlRegex.test(storeUrl)) {
    const cdnUrl = `https:${prefix}/cdn/`;

    // Note (Noah, 2023-07-13): If there's another domain in front of the CDN, that
    // means Shopify tried to load a // url with the store's public domain instead of
    // the myshopify.com domain, which apparently it does now. We don't want to replace
    // in this case!
    const otherDomainPattern = new RegExp(`//(.*)?\\/cdn\\/`, "g");
    const match = otherDomainPattern.exec(input);
    if (match && match[1] && match[1] !== storeUrl) {
      return input;
    }

    return input.replace(doubleSlashStoreUrlSlashCdnPattern, cdnUrl);
  }
  // Some storeUrls are passed that are not myshopify.com urls, so we have to find the storeUrl from the input itself
  const pattern = new RegExp(`//(${myShopifyStoreUrlRegex.source})/cdn`, "g");
  const match = pattern.exec(input);
  if (match && match[1]) {
    const storeUrl = match[1];
    return input.replace(pattern, `https://${storeUrl}/cdn`);
  }
  return input;
}
