import type { CustomPropsRecord } from "schemas/component";
import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

import { convertToLegacyProps } from "../../../shared/utils/renderComponents";

export function getConfigurableProps(): CustomPropsRecord {
  return {
    _product: {
      name: "Product",
      type: "product",
      description: "Shopify product which should be displayed.",
      defaultValue: null,
    },
    _css: {
      type: "cssCodeEditor",
      name: "Custom Yotpo CSS",
      description:
        "Provide custom CSS for styling the Yotpo widget. See the Yotpo documentation for more info.",
      defaultValue: null,
    },
  };
}

export const styleElements = {
  root: {},
} satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: false,
    customProps: convertToLegacyProps(getConfigurableProps()),
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
