import type { AnalyticsReadQuery } from "schemas/generated/analyticsRead";

import * as React from "react";

import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";

export type BasicAnalyticsReadProps = {
  workspaceId: string | null;
  query: AnalyticsReadQuery;
};

const useBasicAnalyticsRead = ({
  workspaceId,
  query,
}: BasicAnalyticsReadProps) => {
  const shouldFetch = Boolean(workspaceId) && query.urlHosts.length > 0;

  const find = trpc.analytics.find.useQuery(
    shouldFetch
      ? {
          query,
          workspaceId: workspaceId ?? "",
        }
      : skipToken,
  );

  const hasData = trpc.analytics.hasData.useQuery(
    shouldFetch ? { workspaceId: workspaceId ?? "" } : skipToken,
  );
  const refetch = async () => Promise.all([find.refetch(), hasData.refetch()]);

  const mainRangeId = query.ranges.mainRange.id;
  const compareAtRangeId = query.ranges.compareAtRanges[0]?.id;

  const rangeResults = find.data?.rangeResults;

  const { mainRangeResults, compareAtRangeResults } = React.useMemo(
    () => ({
      mainRangeResults:
        rangeResults?.filter(
          (rangeResultObj) => rangeResultObj.rangeId === mainRangeId,
        ) ?? [],
      compareAtRangeResults:
        rangeResults?.filter(
          (rangeResultObj) => rangeResultObj.rangeId === compareAtRangeId,
        ) ?? [],
    }),
    [rangeResults, mainRangeId, compareAtRangeId],
  );

  return {
    refetch,
    hasAnyData: Boolean(hasData.data),
    totalRowsCount: find.data?.totalRowsCount ?? null,
    isLoading: find.isLoading || hasData.isLoading,
    mainRangeResults,
    compareAtRangeResults,
    constructedQuery: query,
  };
};

export default useBasicAnalyticsRead;
