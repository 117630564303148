import type { AnalyticsReadQueryWithoutUrlParams } from "@/features/analytics/constants";
import type { AnalyticsUrlParams } from "@/features/analytics/useAnalyticsUrlParams";
import type { WorkspaceUrlHost } from "@/features/analytics/useWorkspaceUrlHosts";

import * as React from "react";

import { DEFAULT_FILTERS, DEFAULT_QUERY } from "@/features/analytics/constants";
import {
  DEFAULT_COMPARE_RANGE_TIME_FRAME,
  DEFAULT_MAIN_RELATIVE_TIME_FRAME,
} from "@/features/analytics/time";

export const AnalyticsQueryContext = React.createContext<{
  query: AnalyticsReadQueryWithoutUrlParams;
  setQuery: (query: AnalyticsReadQueryWithoutUrlParams) => void;
  workspaceUrlHosts: WorkspaceUrlHost;
  getAllParams: () => AnalyticsUrlParams;
  getParamValue: <K extends keyof AnalyticsUrlParams>(
    key: K,
  ) => AnalyticsUrlParams[K];
  setParamValue: <K extends keyof AnalyticsUrlParams>(
    key: K,
    value: AnalyticsUrlParams[K],
  ) => void;
  setDecompressedParams: (params: AnalyticsUrlParams) => void;
  urlHosts: string[];
}>({
  query: DEFAULT_QUERY,
  setQuery: () => null,
  workspaceUrlHosts: {
    data: [],
    isLoading: false,
  },
  getAllParams: () => ({
    selectedTimePeriod: {
      type: "relative",
      value: DEFAULT_MAIN_RELATIVE_TIME_FRAME,
    },
    selectedComparePeriod: {
      type: "relative",
      value: DEFAULT_COMPARE_RANGE_TIME_FRAME,
    },
    filters: DEFAULT_FILTERS,
    urlHosts: [],
  }),
  getParamValue: () => null as any,
  setParamValue: () => null,
  setDecompressedParams: () => null,
  urlHosts: [],
});

export const useAnalyticsQueryContext = () =>
  React.useContext(AnalyticsQueryContext);
