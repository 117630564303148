import type { ElementErrorType } from "@editor/utils/element";
import type { ReploElementType } from "schemas/generated/element";

import * as React from "react";

import { errorMessages } from "@editor/utils/element";

export const ElementEditorErrors: React.FC<{
  errorType: ElementErrorType;
  elementType: ReploElementType;
}> = ({ errorType, elementType }) => {
  const errorMessage = errorMessages[errorType]?.[elementType];
  if (errorMessage) {
    return (
      <div>
        <span className="font-bold">{errorMessage.title} </span>
        {errorMessage.description}
        {errorMessage.link ? (
          <>
            {" "}
            <a
              className="font-normal underline"
              href={errorMessage.link.href}
              target="_blank"
              rel="noreferrer"
            >
              {errorMessage.link.text}
            </a>
          </>
        ) : null}
      </div>
    );
  }
  return null;
};
