import type { MenuItem } from "@common/designSystem/Menu";

import * as React from "react";

import Banner from "@common/designSystem/Banner";
import LogoBadge from "@common/LogoBadge";
import { ElementUpdateLoadingIndicator } from "@components/header/ElementUpdateLoadingIndicator";
import PublishedStatus from "@components/header/PublishedStatus";
import { updateAndSaveElement } from "@editor/actions/core-actions";
import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import { Menu, MenuTrigger } from "@editor/components/common/designSystem/Menu";
import { useIsDebugMode } from "@editor/components/editor/debug/useIsDebugMode";
import {
  getMenuItemsForElementItems,
  useFolders,
  usePageModificationHandlers,
} from "@editor/components/ElementsPane";
import PageControlButtons from "@editor/components/header/PageControlButtons";
import { useSubscriptionInfo } from "@editor/hooks/subscription";
import useGetHeaderLogoRedirection from "@editor/hooks/useGetHeaderLogoRedirection";
import useGetStoreNameAndUrl from "@editor/hooks/useGetStoreNameAndUrl";
import { useLocalStorageState } from "@editor/hooks/useLocalStorage";
import usePublishedInfo from "@editor/hooks/usePublishedInfo";
import {
  selectDoesExistDraftElement,
  selectDraftElementMetadata,
  selectDraftElementName,
  selectEditorMode,
  selectLoadableProject,
  setEditorMode,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { HEADER_HEIGHT } from "@/features/canvas/canvas-constants";
import Button from "@replo/design-system/components/button";
import { ButtonGroup } from "@replo/design-system/components/button/ButtonGroup";
import Tooltip from "@replo/design-system/components/tooltip";
import { skipToken } from "@tanstack/react-query";
import classNames from "classnames";
import { RiArrowDownSLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { z } from "zod";

import Input from "../common/designSystem/Input";
import ActionButtons from "./ActionButtons";

const Header: React.FC = React.memo(function Header() {
  const editorMode = useEditorSelector(selectEditorMode);
  const draftElementName = useEditorSelector(selectDraftElementName);
  const draftElementExists = useEditorSelector(selectDoesExistDraftElement);
  const { storeName } = useGetStoreNameAndUrl();
  const redirectTo = useGetHeaderLogoRedirection();
  const {
    isLoading: isSubscriptionDetailsLoading,
    isFetching: isSubscriptionFetching,
  } = useSubscriptionInfo();
  const { isLoading: isProjectLoading, project } = useEditorSelector(
    selectLoadableProject,
  );
  const isEditMode = useEditorSelector(selectEditorMode) === EditorMode.edit;

  const { data, isFetched } = trpc.workspace.getSimpleWorkspaceById.useQuery(
    !isProjectLoading && project?.ownerWorkspaceId
      ? { id: project.ownerWorkspaceId }
      : skipToken,
  );

  const currentWorkspaceName = isFetched ? data?.workspace.name : undefined;

  const isLoading =
    isProjectLoading || isSubscriptionDetailsLoading || isSubscriptionFetching;

  return (
    <header className="relative flex flex-col border-b border-slate-200">
      <div
        className={`flex h-[${HEADER_HEIGHT}px] w-full justify-between overflow-hidden bg-white px-2`}
      >
        <div className="flex items-center gap-2">
          <div className="flex gap-2">
            <div className="flex cursor-pointer items-center text-slate-200 transition-all hover:text-slate-400">
              <Tooltip
                triggerAsChild
                key="logo"
                content="Back to Replo Dashboard"
              >
                <Link to={redirectTo} className="flex flex-row items-center">
                  <span className="sr-only">Back to Replo Dashboard</span>
                  <LogoBadge
                    aria-hidden
                    className="cursor-pointer text-black h-8 w-8"
                  />
                </Link>
              </Tooltip>
            </div>
            <div className="flex flex-col text-xs min-w-0">
              <div className="overflow-hidden text-ellipsis whitespace-nowrap typ-header-base">
                {storeName}
              </div>
              <div
                className={classNames(
                  "flex items-center gap-2",
                  isLoading && "invisible",
                )}
              >
                <div className="typ-body-small text-slate-500">
                  {currentWorkspaceName}
                </div>
              </div>
            </div>
          </div>
          {isEditMode && <ActionButtons />}
        </div>
        {draftElementExists && (
          <TitleAndCenterMenu pageTitle={`${draftElementName}`} />
        )}
        <div className="flex items-center space-x-2">
          <ElementUpdateLoadingIndicator />
          {editorMode !== EditorMode.versioning && <PageControlButtons />}
        </div>
      </div>
      <DebugModeBanner />
    </header>
  );
});

const DebugModeBanner: React.FC = () => {
  const [usePublisherStagingUrl] = useLocalStorageState(
    "replo.debug.usePublisherStagingUrl",
    false,
    { schema: z.boolean() },
  );
  return usePublisherStagingUrl ? (
    <Banner
      backgroundColor="bg-yellow-200"
      className="color-white p-1 text-xs"
      isDismissable={false}
    >
      [Replo debug] Using publisher staging URL!
    </Banner>
  ) : null;
};

const PageLink = () => {
  const { path, shopifyUrl, urlIsDisabled } = usePublishedInfo();

  return path ? (
    <div
      className={classNames(
        "max-w-[280px] overflow-hidden text-ellipsis whitespace-nowrap text-center text-xs text-slate-400",
        {
          "hover:text-blue-600": !urlIsDisabled,
        },
      )}
    >
      <a
        href={shopifyUrl}
        target="_blank"
        rel="noreferrer"
        className={classNames({
          "pointer-events-none cursor-default": urlIsDisabled,
        })}
      >
        {path}
      </a>
    </div>
  ) : null;
};

const TitleAndCenterMenu: React.FC<{ pageTitle: string }> = ({ pageTitle }) => {
  const dispatch = useEditorDispatch();
  const [isOpen, setOpen] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const draftElementMetadata = useEditorSelector(selectDraftElementMetadata);

  const handleNameChange = React.useCallback(
    (name: string) => {
      const element = draftElementMetadata;

      const newName = name.replace(/[^\p{L}\p{M}\p{N}\p{P}\p{Z} -~]+/gu, "");
      const updatedElement = {
        ...element,
        name: newName,
      };
      dispatch(updateAndSaveElement(element.id, updatedElement));
      void trpcUtils.element.getById.invalidate(element.id);
    },
    [dispatch, draftElementMetadata],
  );

  const inputProps = useOverridableInput({
    value: pageTitle,
    onValueChange: handleNameChange,
    timeout: 0,
  });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === "Escape") {
      setIsEditing(false);
    }
  };

  const { folders: allFolders } = useFolders();
  const currentFolder =
    allFolders.find((folder) => folder.id === draftElementMetadata?.folderId) ??
    null;

  const {
    handleDeletion,
    handleDuplication,
    handleEditPage,
    handleMoveToFolder,
    handleSupportDuplication,
  } = usePageModificationHandlers();
  const isDebugMode = useIsDebugMode();

  const menuItems: MenuItem[] = [
    ...(draftElementMetadata
      ? getMenuItemsForElementItems({
          element: draftElementMetadata,
          currentFolder,
          allFolders,
          handleDeletion,
          handleDuplication,
          handleSupportDuplication,
          handleEditPage,
          handleMoveToFolder,
          isDebugMode,
        })
      : []),
    {
      type: "leaf",
      id: "history",
      title: "View Version History",
      onSelect: () => {
        dispatch(setEditorMode(EditorMode.versioning));
      },
    },
  ];

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [buttonDimensions, setButtonDimensions] = React.useState<{
    width?: number;
    height?: number;
  }>({});

  // biome-ignore lint/correctness/useExhaustiveDependencies: We want to update the button dimensions when the page title changes
  React.useEffect(() => {
    const updateDimensions = () => {
      if (buttonRef.current) {
        // NOTE (Kurt, 2024-12-09): We need to dynamically calculate the dimensions of the trigger button
        // so that when we switch to the editable input, it maintains the exact same width/height.
        // This prevents any layout shifts and maintains visual consistency during the transition.
        // The button ref gives us access to the rendered button's actual dimensions which we can then
        // apply to the input container to match perfectly.
        setButtonDimensions({
          width: buttonRef.current.offsetWidth,
          height: buttonRef.current.offsetHeight,
        });
      }
    };

    updateDimensions();

    window.addEventListener("resize", updateDimensions);

    return () => window.removeEventListener("resize", updateDimensions);
  }, [pageTitle, isEditing]);

  return (
    <div
      className="absolute flex h-[60px] flex-col items-center justify-center"
      style={{ left: "50%", transform: "translateX(-50%)" }}
    >
      <div className="relative flex flex-row group">
        <Menu
          items={menuItems}
          isOpen={isOpen}
          onRequestClose={() => setOpen(false)}
          contentClassNames="mt-2.5"
          trigger={
            <MenuTrigger asChild>
              <ButtonGroup
                variant="segmented"
                unsafe_className="hover:bg-slate-50 rounded"
              >
                {isEditing ? (
                  <div
                    style={{
                      width: buttonDimensions.width,
                      height: buttonDimensions.height,
                      maxWidth: buttonDimensions.width,
                      maxHeight: buttonDimensions.height,
                    }}
                  >
                    <Input
                      autoFocus
                      size="sm"
                      {...inputProps}
                      onKeyDown={handleKeyDown}
                      onBlur={() => setIsEditing(false)}
                    />
                  </div>
                ) : (
                  <Button
                    ref={buttonRef}
                    variant="tertiary"
                    size="sm"
                    className="px-1.5 py-0.5 rounded-r-none"
                    // NOTE (Kurt, 2024-12-06): We need to be able to set custom tailwind classes from the textClassNames prop
                    // what is happening here is that the custom classes like typ-header-base are not being applied.
                    textClassNames="max-w-[280px] overflow-hidden text-ellipsis whitespace-nowrap text-center text-sm leading-5 font-semibold"
                    onClick={() => setIsEditing(true)}
                  >
                    <div className="flex items-center gap-2">
                      <PublishedStatus />
                      <div
                        data-testid="element-page-title"
                        className="truncate"
                      >
                        {pageTitle}
                      </div>
                    </div>
                  </Button>
                )}
                <div className="w-px h-full bg-slate-200 opacity-0 group-hover:opacity-100 transition-opacity" />
                <Button
                  variant="tertiary"
                  size="sm"
                  className={classNames("p-0 rounded-l-none", {
                    "": !isOpen,
                    "bg-slate-200": isOpen,
                  })}
                  icon={
                    <RiArrowDownSLine className="text-slate-500" size={20} />
                  }
                  onClick={() => setOpen(true)}
                  data-testid="element-page-title-dropdown-button"
                />
              </ButtonGroup>
            </MenuTrigger>
          }
        />
      </div>
      <PageLink />
    </div>
  );
};

export default Header;
