import { publishDraftElement } from "@actions/core-actions";
import { elementTypeToEditorData } from "@components/editor/element";
import { useStoreProductsFromDraftElement } from "@editor/hooks/useStoreProducts";
import {
  selectDraftElementIsTurbo,
  selectDraftElementType,
  selectIsElementUpdateInProgressOrQueued,
  selectIsPublishing,
  selectRootComponentId,
  selectTemplateShopifyProductIdsLength,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";

import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";

const publishErrorsBeforeStartMessages = {
  elementUpdatingOrQueued: {
    title: "An update to the page is saving.",
    message: "Please publish after saving is complete.",
  },
  productsStillLoading: {
    title: "Shopify Product data is still loading.",
    message: "Please publish after Shopify Product data finishes loading.",
  },
  elementStillLoading: {
    title: "Page data is still loading.",
    message: "Please publish after page data finishes loading.",
  },
};

export default function usePublishOnClickInfo() {
  const isPublishing = useEditorSelector(selectIsPublishing);

  const dispatch = useEditorDispatch();

  // Note (Noah, 2024-02-29, USE-774): It might be that products have loaded but
  // the full component for this element hasn't yet. If this is the case, we still
  // want to block publishing
  const draftElementHasLoaded = Boolean(
    useEditorSelector(selectRootComponentId),
  );

  const { isLoading: isFetchProductLoading } =
    useStoreProductsFromDraftElement();

  const elementRecentlyPublished = useEditorSelector(
    (state) => state.core.elementRecentlyPublished,
  );

  const draftElementType = useEditorSelector(selectDraftElementType);
  const pageEditorData = elementTypeToEditorData[draftElementType];

  const numAssignedProducts = useEditorSelector(
    selectTemplateShopifyProductIdsLength,
  );

  const isTurbo = useEditorSelector(selectDraftElementIsTurbo);

  const publish = () => {
    dispatch(publishDraftElement() as any);
  };

  const isElementUpdateInProgressOrQueued = useEditorSelector(
    selectIsElementUpdateInProgressOrQueued,
  );

  let publishNotAvailableKey:
    | keyof typeof publishErrorsBeforeStartMessages
    | null = null;
  if (isElementUpdateInProgressOrQueued) {
    publishNotAvailableKey = "elementUpdatingOrQueued";
  } else if (isFetchProductLoading) {
    publishNotAvailableKey = "productsStillLoading";
  } else if (!draftElementHasLoaded) {
    publishNotAvailableKey = "elementStillLoading";
  }
  const publishNotAvailableReason = getFromRecordOrNull(
    publishErrorsBeforeStartMessages,
    publishNotAvailableKey,
  );

  return {
    publishNotAvailableReason,
    publishErrorsBeforeStartMessages,
    draftElementType,
    numAssignedProducts,
    publish,
    isPublishing,
    isTurbo,
    elementRecentlyPublished,
    pageEditorData,
  };
}
