import * as React from "react";

import { useEditorSelector } from "@editor/store";
import {
  getElementAbsoluteMargins,
  getElementMargin,
  getElementPaddings,
} from "@editor/utils/dom";

import { selectCanvasRelativeBoundingClientRect } from "./canvas-reducer";

const getBorderStyles = (
  node: Element,
  type: "margin" | "padding",
): React.CSSProperties => {
  const bounds =
    type === "margin"
      ? getElementAbsoluteMargins(node)
      : getElementPaddings(node);
  return {
    borderWidth: `${bounds.top} ${bounds.right} ${bounds.bottom} ${bounds.left}`,
  };
};

/**
 * Displays padding and margin indicators for a given element.
 *
 * TODO (Noah, 2022-01-28): This component should be responsible for calculating
 * the indicator position/bounds by itself, there's no reason for these styles
 * to be passed in as props (at least, I don't think)
 */
export const SpacingIndicators: React.FC<{
  node: Element;
}> = (props) => {
  const getCanvasRelativeBoundingClientRect = useEditorSelector(
    selectCanvasRelativeBoundingClientRect,
  );
  const margin = {
    top: Number.parseInt(getElementMargin("top", props.node), 10),
    left: Number.parseInt(getElementMargin("left", props.node), 10),
    bottom: Number.parseInt(getElementMargin("bottom", props.node), 10),
    right: Number.parseInt(getElementMargin("right", props.node), 10),
  };
  const {
    top: boundingTop,
    left: boundingLeft,
    width: boundingWidth,
    height: boundingHeight,
  } = getCanvasRelativeBoundingClientRect(props.node);

  const style = getComputedStyle(props.node);

  const marginIndicatorBounds = {
    top: boundingTop - Math.max(margin.top, 0),
    height:
      boundingHeight + Math.max(margin.top, 0) + Math.max(margin.bottom, 0),
    left: boundingLeft - Math.max(margin.left, 0),
    width: boundingWidth + Math.max(margin.left, 0) + Math.max(margin.right, 0),
  };

  const paddingIndicatorBounds = {
    top: `calc(${style.borderTopWidth} + min(0px, ${margin.top}px))`,
    left: `calc(${style.borderLeftWidth} + min(0px, ${margin.left}px))`,
    width: `calc(${boundingWidth}px - ${style.borderLeftWidth} - ${style.borderRightWidth})`,
    height: `calc(${boundingHeight}px - ${style.borderTopWidth} - ${style.borderBottomWidth})`,
  };

  return (
    <div
      className="ignore-frame-cursor pointer-events-none absolute cursor-default border-solid border-purple-600 border-opacity-10"
      style={{
        ...marginIndicatorBounds,
        ...getBorderStyles(props.node, "margin"),
      }}
    >
      <div
        className="ignore-frame-cursor pointer-events-none absolute cursor-default border-solid border-green-400 border-opacity-10"
        style={{
          ...paddingIndicatorBounds,
          ...getBorderStyles(props.node, "padding"),
        }}
      />
    </div>
  );
};
