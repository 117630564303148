import type { Component } from "schemas/component";
import type {
  BaseSharedLiquidProps,
  ReploShopifyProduct,
  SharedLiquidReviewsProps,
} from "../../shared/types";
import type { GlobalWindow } from "../../shared/Window";

import * as React from "react";

import useScript from "../../shared/hooks/useScript";
import useScriptCallbackEvent from "../../shared/hooks/useScriptCallbackEvent";
import {
  GlobalWindowContext,
  RenderEnvironmentContext,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../shared/runtime-context";
import { useProductFromProps } from "../hooks/useProductFromProps";
import { wrapProductAssignmentToLiquidSource } from "../utils/reviews";
import { RenderComponentPlaceholder } from "./RenderComponentPlaceholder";
import { SharedLiquidReviewIntegrations } from "./SharedLiquidReviewIntegrations";

interface SharedReviewsIoIntegration extends BaseSharedLiquidProps {
  htmlContent: string;
}

export const SharedReviewsIoReviews = ({
  attributes,
  component,
  context,
  ...props
}: SharedReviewsIoIntegration | SharedLiquidReviewsProps) => {
  const [reviewsSDKIsLoaded, setReviewsSDKIsLoaded] = React.useState(false);

  const liquidSource = "liquidSource" in props ? props.liquidSource : null;
  const reviewsComponentType =
    "reviewsComponentType" in props ? props.reviewsComponentType : null;

  const { htmlContent } = props as SharedReviewsIoIntegration;

  const renderedLiquidCacheLength =
    useRuntimeContext(RuntimeHooksContext).useRenderedLiquidCacheLength();
  const { storeUrl } = useRuntimeContext(ShopifyStoreContext);
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const globalWindow = useRuntimeContext(GlobalWindowContext);

  const product = useProductFromProps(component.props, context);

  const shouldHavePlaceHolder = isEditorApp && !globalWindow?.ReviewsWidget;

  // NOTE (Matt 2023-10-31): Reviews.io updated to not include this script tag in the theme
  // and instead reference it when the widget is rendered. We use the useScriptCallbackEvent
  // to make sure that we can skip the script request when the SDK is loaded.
  useScript("https://widget.reviews.io/polaris/build.js", {
    globalWindow,
    forceSkip: globalWindow?.ReviewsWidget || reviewsSDKIsLoaded,
  });

  const createReviewsIoWidgets = useCreateReviewIoWidgets(
    product,
    component,
    storeUrl,
  );

  // Note (Fran, 2022-11-22): This function should fire when the content is
  // loaded but these are fired before we populate the replo fullpage element
  // so when this is fired, the reviews widget are not in the page. We need to
  // fire manually instead.
  // Thread: https://replohq.slack.com/archives/C01JYU70754/p1669135978128119
  // This is a function that is added by Reviews.io installation
  useScriptCallbackEvent(
    {
      run: () => {
        if (globalWindow) {
          createReviewsIoWidgets(globalWindow);
          if (globalWindow.ReviewsWidget) {
            setReviewsSDKIsLoaded(true);
          }
        }
      },
      isReady: () => {
        return Boolean(globalWindow?.ReviewsWidget);
      },
      identifier: "reviewsio",
      componentId: component.id,
      scriptSourceRegex: /widget\.reviews\.io/,
    },
    [
      product?.id,
      createReviewsIoWidgets,
      globalWindow,
      renderedLiquidCacheLength,
      component.id,
      isEditorApp,
    ],
  );

  if (liquidSource) {
    const liquid = wrapProductAssignmentToLiquidSource({
      product,
      liquidSource,
      context,
    });

    return (
      <SharedLiquidReviewIntegrations
        attributes={attributes}
        component={component}
        liquidSource={liquid}
        placeholder={`Reviews.io ${reviewsComponentType} Widget will appear here`}
        shouldHavePlaceHolder={shouldHavePlaceHolder || !product}
        appName="Reviews.io"
        context={context}
      />
    );
  }

  return (
    <div {...attributes}>
      {component.props._css && (
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: String(component.props._css),
          }}
        />
      )}
      {shouldHavePlaceHolder ? (
        <RenderComponentPlaceholder title="The Reviews.io Widget should appear here. Please ensure you have Reviews.io installed, or reach out to support@replo.app" />
      ) : (
        <span
          dangerouslySetInnerHTML={{
            __html: htmlContent,
          }}
        />
      )}
    </div>
  );
};

function useCreateReviewIoWidgets(
  product: ReploShopifyProduct | null,
  component: Component,
  storeUrl: string | undefined,
) {
  // biome-ignore lint/correctness/useExhaustiveDependencies: extra deps product?.id, missing dep product
  const createReviewsIoWidgets = React.useCallback(
    (window: GlobalWindow) => {
      if (component.type === "reviewsIoProductRating" && product) {
        const loads = window?.loadReviewsIoRatingSnippets;
        if (loads && typeof loads === "function") {
          loads();
        }
        return;
      }
      if (component.type === "reviewsIoReviews" && product) {
        // Note (Fran, 2022-12-09): We need to grab all the variant ids and skus of the
        // product, because is what actually do the liquid content in the rating.
        // With we will have access to all the reviews for that product.
        // You can see how does it in the file ReviewsIoProductRatingSummary.tsx
        // in the liquid source string.
        // Note (Matt, 2023-08-09): Reviews.io App can be configured to use Variant ID or SKU
        // to identify product reviews. The inclusion of both here will not break the widget.
        const productAndVariantIds = [
          ...product.variants.map(
            (variant) => `${variant.id}${variant.sku ? `;${variant.sku}` : ""}`,
          ),
          product.id,
        ];

        window.ReviewsWidget(`#ReviewsWidget-${component.id}`, {
          // NOTE (Chance 2024-03-05): Unsure if emtpy string fallback is
          // needed, but previously the type defaulted to "" if the URL was
          // undefined so adding here for safety.
          store: storeUrl ?? "",
          widget: "polaris",
          options: {
            types: "product_review",
            // @ts-ignore
            lang: window.reviewsIoLang ?? "en",
            layout: "",
            per_page: 15,
            store_review: {
              hide_if_no_results: false,
            },
            third_party_review: {
              hide_if_no_results: false,
            },
            product_review: {
              sku: productAndVariantIds.join(";"),
              hide_if_no_results: false,
            },
            header: {
              enable_summary: true,
              enable_ratings: true,
              enable_attributes: true,
              enable_image_gallery: true,
              enable_percent_recommended: false,
              enable_write_review: true,
              enable_ask_question: true,
              enable_sub_header: true,
              rating_decimal_places: 2,
            },
            filtering: {
              enable: true,
              enable_text_search: true,
              enable_sorting: true,
              enable_overall_rating_filter: true,
              enable_ratings_filters: true,
              enable_attributes_filters: true,
            },
            reviews: {
              enable_avatar: true,
              enable_reviewer_name: true,
              enable_reviewer_address: true,
              reviewer_address_format: "city, country",
              enable_verified_badge: true,
              review_content_filter: "undefined",
              enable_reviewer_recommends: true,
              enable_attributes: true,
              enable_product_name: true,
              enable_review_title: undefined,
              enable_replies: undefined,
              enable_images: true,
              enable_ratings: true,
              enable_share: true,
              enable_helpful_vote: true,
              enable_helpful_display: true,
              enable_report: true,
              enable_date: true,
            },
          },
        });
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [product?.id, component.id, component.type, storeUrl],
  );

  return createReviewsIoWidgets;
}

declare global {
  interface Window {
    loadReviewsIoRatingSnippets?: unknown;
  }
}
