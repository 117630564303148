import * as React from "react";

import { Menu } from "@common/designSystem/Menu";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { selectModalComponentData } from "@editor/reducers/tree-reducer";
import { useEditorSelector } from "@editor/store";
import ModalIcon from "@svg/componenticon-modal";

import Button from "@replo/design-system/components/button";
import Tooltip from "@replo/design-system/components/tooltip";

export const TreeModalSelector = () => {
  const setDraftElement = useSetDraftElement();
  const modals = useEditorSelector(selectModalComponentData);

  if (modals.length === 0) {
    return null;
  }

  if (modals.length === 1) {
    return (
      <Tooltip content="Jump to Popup Component" triggerAsChild>
        <div>
          <Button
            variant="secondary"
            style={{ minWidth: 0 }}
            className="bg-transparent text-blue-600"
            onClick={(e) => {
              e.stopPropagation();
              setDraftElement({
                componentIds: [modals[0]!.id],
              });
            }}
            data-testid="tree-modal-selector"
          >
            <ModalIcon />
          </Button>
        </div>
      </Tooltip>
    );
  }

  const options = modals.map((modal) => {
    return {
      id: modal.id,
      type: "leaf" as const,
      value: modal.id,
      title: modal.name,
      onSelect: () =>
        setDraftElement({
          componentIds: [modal.id],
        }),
    };
  });

  return (
    <Tooltip content="Jump to Popup Components" triggerAsChild>
      <div>
        <Menu
          items={options}
          menuType="normal"
          customWidth={150}
          trigger={
            <Button
              // NOTE (Chance, 2023-05-02): This is purely decorative, as Menu
              // creates a trigger that renders the button element around the
              // visual button.
              variant="secondary"
              style={{ minWidth: 0 }}
              className="bg-transparent text-blue-600"
              isPhonyButton
            >
              <ModalIcon />
            </Button>
          }
        />
      </div>
    </Tooltip>
  );
};
