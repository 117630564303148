import * as React from "react";

import Tooltip from "@replo/design-system/components/tooltip";
import classNames from "classnames/dedupe";
import { twMerge } from "tailwind-merge";

type Enhancer = React.ReactNode | ((className: string) => React.ReactNode);

export interface ChipProps {
  title?: string;
  tooltipText?: string;
  isSelected?: boolean;
  isInvalid?: boolean;
  startEnhancer?: Enhancer;
  endEnhancer?: Enhancer;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  style?: React.CSSProperties;
  truncate?: boolean;
  tabIndex?: number;
}

type EnhancerProps = {
  className?: string;
};

export const Chip = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<ChipProps>
>(function Chip(props, ref) {
  const {
    tooltipText,
    children,
    isSelected,
    isInvalid,
    startEnhancer,
    endEnhancer,
    className,
    style,
    onClick,
    truncate,
    ...domProps
  } = props;

  const defaultClassName = classNames(
    "ease flex flex-row w-max items-center justify-center gap-1 rounded-full px-2 py-1 text-xs transition duration-300",
    {
      "text-subtle bg-slate-100": !isSelected && !isInvalid,
      "text-accent bg-blue-200 font-medium": isSelected && !isInvalid,
      "text-red-600 bg-red-100": isInvalid,
    },
  );

  const enhancerClassname = classNames({
    "text-accent": isSelected && !isInvalid,
    "text-red-600": isInvalid,
    "text-subtle": !isSelected && !isInvalid,
  });

  const chip = (
    <button
      {...domProps}
      ref={ref}
      type="button"
      className={classNames(
        className ? twMerge(defaultClassName, className) : defaultClassName,
        { "cursor-default": !onClick },
      )}
      style={style}
      onClick={onClick}
    >
      <ChipEnhancer className={enhancerClassname}>
        {startEnhancer as React.ReactNode}
      </ChipEnhancer>
      {truncate ? <span className="grow truncate">{children}</span> : children}
      <ChipEnhancer className={enhancerClassname}>
        {endEnhancer as React.ReactNode}
      </ChipEnhancer>
    </button>
  );

  return tooltipText ? (
    <Tooltip content={tooltipText} triggerAsChild>
      {chip}
    </Tooltip>
  ) : (
    chip
  );
});

/**
 * Enhancer component that augments the component sent as a prop.
 */
const ChipEnhancer: React.FC<React.PropsWithChildren<EnhancerProps>> = (
  props,
) => {
  const { className, children } = props;
  return (
    <>
      {React.isValidElement(children)
        ? React.cloneElement(children as React.ReactElement<EnhancerProps>, {
            className: twMerge(className, children.props.className),
          })
        : children}
    </>
  );
};

export default Chip;
