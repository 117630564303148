import type { ConditionField } from "schemas/generated/symbol";

// Note (Kurt, 2024-06-24): This map acts as the source of truth for any condition field display names.
// It was created to prevent a circular import error where the `selectDraftElement_warningThisWillRerenderOnEveryUpdate`
// selector was unintialized when it was imported by a dependency in the `condition.tsx` file.
const conditionFieldToDisplayName: Record<ConditionField, string> = {
  "state.temporaryCart.numberOfItems": "Number of Items in Temporary Cart",
  "state.temporaryCart.containsVariant":
    "Temporary Cart Contains Product Variant",
  "element.offsetY": "Pixels from Top Of Viewport",
  "state.product.quantity": "Product Quantity",
  "screen.pageY": "Pixels from Top Of Page",
  "interaction.clickEvent": "On Click",
  "state.product.noProductVariantSelected": "No product variant selected",
  "page.hashmark": "URL Hashmark",
  "collectionSelect.item": "Selected Data Collection Item",
  "interaction.hover": "Hover",
  "state.action.loading": "Interactions Loading",
  "state.dropdown.selectedItem": "This is the selected item",
  "state.collapsibleV2.isOpen": "Collapsible is open",
  "state.product.selectedVariant": "This is the selected variant",
  "state.product.selectedSellingPlan": "This is the selected selling plan",
  "state.product.isOptionValueUnavailable": "Option In List Is Unavailable",
  "state.product.isVariantUnavailable": "Variant In List Is Unavailable",
  "state.product.selectedVariantUnavailable": "Selected Variant Unavailable",
  product_variant_is_on_sale: "Product Variant Is On Sale",
  "state.product.currentVariantIsOnSale": "Variant In List Is On Sale",
  "state.product.selectedOptionValues": "This is the selected option",
  "state.carouselV2.isCurrentIndicatorItem":
    "This is the current carousel item",
  "state.carouselV2.isAtStart": "Carousel Is At Start",
  "state.carouselV2.isAtEnd": "Carousel Is At End",
  "state.tabsBlock.isCurrentTab": "This is the active tab",
  "state.tabsV2Block.isCurrentTab": "This is the active tab",
  "state.group.isCurrentItemActive": "Current item is active",
  "state.group.isFirstItemActive": "First item is active",
  "state.group.isLastItemActive": "Last item is active",
  "state.product.templateProductEquals": "Template Product",
  "state.product.selectedProductEquals": "Current Product",
  "state.product.selectedVariantEquals": "Selected Variant",
  "state.beforeAfterSlider.isDragging": "Slider is Dragging",
  "state.tooltip.isOpen": "Tooltip is Open",
  "state.selectionList.isItemSelected": "Item is Selected",
};

export const getConditionFieldDisplayName = (
  conditionField: ConditionField,
): string => {
  const conditionData = conditionFieldToDisplayName[conditionField];
  return conditionData;
};
