import type { SvgProps } from "replo-utils/react/components";

import * as React from "react";

import { Svg } from "replo-utils/react/components";

export const SvgDynamicData = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#1E293B"
      d="M14 6.333v2c0 1.657-2.687 3-6 3s-6-1.343-6-3v-2c0 1.657 2.687 3 6 3s6-1.343 6-3ZM2 9.667c0 1.656 2.687 3 6 3s6-1.344 6-3v2c0 1.656-2.687 3-6 3s-6-1.344-6-3v-2ZM8 8C4.687 8 2 6.657 2 5s2.687-3 6-3 6 1.343 6 3-2.687 3-6 3Z"
    />
  </Svg>
);
export default SvgDynamicData;
