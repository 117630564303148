import type { TransformOriginObject } from "schemas/effects";

import { filterNulls } from "replo-utils/lib/array";

import { styleAttributeToEditorData } from "./styleAttribute";

/**
 * Converts a CSS transform value into a human-readable format.
 * @param transformValue The CSS transform value as a string.
 * @returns The human-readable transform value.
 */
export const getReadableTransformValue = (transformValue: string): string => {
  const transformDefinitions: [RegExp, string][] = [
    [/translate3d\((.+?)\)/, "Translate"],
    [/scale3d\((.+?)\)/, "Scale"],
    [/rotateX\((.+?)\)/, "Rotate X"],
    [/rotateY\((.+?)\)/, "Rotate Y"],
    [/rotateZ\((.+?)\)/, "Rotate Z"],
    [/skew\((.+?)\)/, "Skew"],
  ];
  const readableTransforms = transformDefinitions.map(([regex, label]) => {
    const match = transformValue.match(regex);

    if (!match || !match[1]) {
      return null;
    }

    const values = match[1].split(",").map((val) => val.trim());

    const hasIdenticalValues = values.every((val) => val === values[0]);

    if (hasIdenticalValues) {
      return `${label} ${values[0]}`;
    }
    return `${label} ${values.join(" ")}`;
  });

  const filteredTransforms = filterNulls(readableTransforms);
  return filteredTransforms.join(" ");
};

export const getTransformOriginString = (values: TransformOriginObject) => {
  return Object.values(values).join(" ").trim();
};

export const parseTransformOrigin = (value: string) => {
  const stringArray = value.split(" ");
  const [transformOriginX, transformOriginY, transformOriginZ] = stringArray;
  const transformOriginDefaultValues =
    styleAttributeToEditorData.transformOrigin.defaultValue.split(" ");
  const defaultTransformOriginX = transformOriginDefaultValues[0]!;
  const defaultTransformOriginY = transformOriginDefaultValues[1]!;
  const defaultTransformOriginZ = transformOriginDefaultValues[2]!;
  return {
    transformOriginX: transformOriginX || defaultTransformOriginX,
    transformOriginY: transformOriginY || defaultTransformOriginY,
    transformOriginZ: transformOriginZ || defaultTransformOriginZ,
  };
};
