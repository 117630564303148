import type { Component } from "schemas/component";
import type { DesignLibrarySavedStyleMetadata } from "schemas/generated/designLibrary";
import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";
import type { RuntimeStyleAttribute } from "schemas/styleAttribute";

import { getSavedStyleId } from "@editor/utils/designLibrary";

import produce from "immer";
import { forEachComponentAndDescendants } from "replo-runtime";
import { allMediaSizeStyles } from "replo-runtime/shared/utils/breakpoints";
import { isDynamicDesignLibraryValue } from "replo-runtime/shared/utils/designLibrary";

import useGetDesignLibrarySavedStyles from "./useGetDesignLibrarySavedStyles";

/**
 * Hook that handles the paste of components with design library references.
 * If the components to paste have dynamic design library references,
 * it will replace them with the static values from the design library or
 * the dynamic values from the current design library if there is some saved style
 * with the same name.
 */
function useHandlePasteComponentWithDesignLibraryReferences() {
  const { designLibrary, textSavedStyles, colorSavedStyles } =
    useGetDesignLibrarySavedStyles();

  const savedStylesFromCurrentDesignLibrary = [
    ...textSavedStyles,
    ...colorSavedStyles,
  ];

  const updateComponentsSavedStyleReferences = (
    components: Component[],
    designLibraryMetadata: DesignLibrarySavedStyleMetadata,
  ) => {
    const designLibraryIdFromMetadata = Object.keys(
      designLibraryMetadata.library,
    )[0];
    const designLibrarySavedStylesMetadata =
      designLibraryMetadata?.library[designLibraryIdFromMetadata!]?.styles;

    if (!designLibrarySavedStylesMetadata) {
      return components;
    }

    return produce(components, (draftComponents) => {
      for (const component of draftComponents) {
        forEachComponentAndDescendants(component, (componentOrChild) => {
          allMediaSizeStyles.forEach((mediaSize) => {
            const styleProp = componentOrChild.props[mediaSize];
            if (!styleProp) {
              return;
            }
            const stylesValues = Object.entries(styleProp);
            for (const [styleKey, styleValue] of stylesValues) {
              if (
                !(
                  typeof styleValue === "string" &&
                  isDynamicDesignLibraryValue(styleValue)
                )
              ) {
                continue;
              }
              const savedStyleId = getSavedStyleId(styleValue)!;
              const savedStyleFromMetadata =
                designLibrarySavedStylesMetadata[savedStyleId];

              if (!savedStyleFromMetadata) {
                continue;
              }

              const savedStyleFromMetadataName = savedStyleFromMetadata.name;

              const savedStyleFromCurrentDesignLibrary =
                savedStylesFromCurrentDesignLibrary.find(
                  (savedStyle) =>
                    savedStyle.name === savedStyleFromMetadataName,
                );

              if (!savedStyleFromCurrentDesignLibrary) {
                const savedStyleStaticValue =
                  savedStyleFromMetadata.type === "color"
                    ? savedStyleFromMetadata.attributes.color
                    : savedStyleFromMetadata.attributes[
                        styleKey as keyof SavedStyleTextAttributes
                      ];
                if (!componentOrChild.props[mediaSize]) {
                  continue;
                }

                // NOTE (Fran 2024-11-28): Apply the static value from the design library.
                componentOrChild.props[mediaSize][
                  styleKey as RuntimeStyleAttribute
                ] = savedStyleStaticValue;
              } else {
                const styleValueSplitted = styleValue.split(".");

                // NOTE (Fran 2024-11-28): Update the design library id to the id from the current design library.
                styleValueSplitted.splice(1, 1, designLibrary!.id);
                // NOTE (Fran 2024-11-28): Update the saved style id to the id of the saved style with the same
                // name from the current design library.
                styleValueSplitted.splice(
                  3,
                  1,
                  savedStyleFromCurrentDesignLibrary.id,
                );

                const newSavedStyleDynamicDataValue =
                  styleValueSplitted.join(".");

                if (!componentOrChild.props[mediaSize]) {
                  continue;
                }

                componentOrChild.props[mediaSize][
                  styleKey as RuntimeStyleAttribute
                ] = newSavedStyleDynamicDataValue;
              }
            }
          });
        });
      }
    });
  };

  return {
    handlePasteComponentWithDesignLibraryReferences: (
      components: Component[],
      designLibraryMetadata: DesignLibrarySavedStyleMetadata | null,
    ) => {
      const designLibraryIdFromMetadata = designLibraryMetadata
        ? Object.keys(designLibraryMetadata.library)[0]
        : null;

      if (
        designLibraryMetadata &&
        designLibrary &&
        designLibraryIdFromMetadata &&
        designLibraryIdFromMetadata !== designLibrary.id
      ) {
        return updateComponentsSavedStyleReferences(
          components,
          designLibraryMetadata,
        );
      }
      return components;
    },
  };
}

export default useHandlePasteComponentWithDesignLibraryReferences;
