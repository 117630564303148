import { slugSchema } from "schemas/utils";
import { variationSchema } from "schemas/variation";
import { z } from "zod";

const experimentStatuses = ["draft", "archived", "complete", "active"] as const;

export type ExperimentStatus = (typeof experimentStatuses)[number];

export const experimentStatusSchema = z.enum(experimentStatuses);

const baseVariationsSchema = z.array(variationSchema);

export const requiredExperimentVariationsArraySchema = baseVariationsSchema
  .refine(
    (variations) =>
      variations.reduce((sum, v) => v.allocationPercent + sum, 0) === 100,
    {
      message: "sum of variation percentages must add up to 100",
    },
  )
  .refine(
    (variations) =>
      new Set(variations.map((v) => v.slug)).size === variations.length,
    {
      message: "All variation names must be unique",
    },
  )
  .describe("RequiredExperimentVariations");

/**
 * NOTE (Kurt, 2024-11-03): Optional variations schema used primarily for the experiment update endpoint.
 * While variations are required when creating an experiment, during updates we want
 * to allow updating individual fields without requiring the entire experiment object.
 * This schema makes it possible to omit variations during updates, but if variations
 * are provided, they must still pass all validation rules (percentage sum = 100, unique slugs).
 * For other operations like experiment creation, we use the required schema above.
 */
export const optionalExperimentVariationsArraySchema = baseVariationsSchema
  .optional()
  .refine(
    (variations) =>
      !variations ||
      variations.length === 0 ||
      variations.reduce((sum, v) => v.allocationPercent + sum, 0) === 100,
    {
      message: "sum of variation percentages must add up to 100",
    },
  )
  .refine(
    (variations) =>
      !variations ||
      variations.length === 0 ||
      new Set(variations.map((v) => v.slug)).size === variations.length,
    {
      message: "All variation names must be unique",
    },
  )
  .describe("OptionalExperimentVariations");

export const experimentSchema = z
  .object({
    id: z.string().uuid(),
    workspaceId: z.string().uuid(),
    /**
     * TODO (Max, 2024-10-10, REPL-14267): When cleaning up, delete
     * projectId from the schema
     */
    projectId: z.string().nullable(),
    slug: slugSchema,
    name: z.string(),
    description: z.string().nullable(),
    createdAt: z.coerce.date(),
    activatedAt: z.coerce.date().nullable(),
    archivedAt: z.coerce.date().nullable(),
    completedAt: z.coerce.date().nullable(),
    variations: requiredExperimentVariationsArraySchema,
    analyticsLinkId: z.string().uuid().nullable(),
    selectedDisplayWinnerVariationId: z.string().uuid().nullable(),
  })
  .describe("Experiment");

export const experimentDetailSchema = z
  .object({
    id: z.string().uuid(),
    workspaceId: z.string().uuid(),
    /**
     * TODO (Max, 2024-10-10, REPL-14267): When cleaning up, delete
     * projectId from the schema
     */
    projectId: z.string().nullable(),
    projectSlug: slugSchema.nullable(),
    projectCustomDomain: z.string().nullable(),
    slug: slugSchema,
    name: z.string(),
    description: z.string().nullish(),
    createdAt: z.coerce.date(),
    activatedAt: z.coerce.date().nullable(),
    archivedAt: z.coerce.date().nullable(),
    completedAt: z.coerce.date().nullable(),
    variations: requiredExperimentVariationsArraySchema,
  })
  .describe("ExperimentDetail");

export const experimentListSchema = z
  .object({
    experiments: z.array(experimentSchema),
  })
  .describe("ExperimentList");

/**
 * Determine an experiment's status by using the most recently set timestamp
 * on the experiment. The updating process for experiments results in timestamps
 * that are only increasing.
 *
 * @author Ben 2023-10-04
 */
export function getExperimentStatus(dates: {
  createdAt: Date;
  activatedAt: Date | null;
  completedAt: Date | null;
  archivedAt: Date | null;
}): ExperimentStatus {
  type Input = typeof dates;
  const keyedStatuses: { [index in keyof Input]: ExperimentStatus } = {
    createdAt: "draft",
    activatedAt: "active",
    completedAt: "complete",
    archivedAt: "archived",
  };
  const [key] = Object.entries(dates)
    .filter(([key]) => key in keyedStatuses)
    .filter(([, value]) => Boolean(value))
    .map(
      ([key, value]) =>
        [key, Number(new Date(value!))] as [keyof Input, number],
    )
    .sort(([, a], [, b]) => b - a)
    .find(Boolean)!;
  return keyedStatuses[key];
}
