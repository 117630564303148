import type { SvgProps } from "replo-utils/react/components";

import * as React from "react";

import { Svg } from "replo-utils/react/components";

export const SvgRiText = (props: SvgProps) => (
  <Svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M6.714 1.412V12H5.286V1.412H1V0h10v1.412H6.714Z"
    />
  </Svg>
);
export default SvgRiText;
