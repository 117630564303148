import { isString } from "replo-runtime/store/components/CarouselV3/renderer/utils/type/type";

/**
 * Appends `px` to the provided number.
 * If the value is already string, just returns it.
 *
 * @param value - A value to append `px` to.
 *
 * @returns A string with the CSS unit.
 */
export function unit(value: number | string | undefined): string {
  if (!value) {
    return "";
  }
  return isString(value) ? value : `${value}px`;
}
