import type { ReploSymbol } from "schemas/generated/symbol";

type BuiltInSymbolType = "dropdown" | "collapsible";

export const builtInSymbolDirectory: Record<BuiltInSymbolType, ReploSymbol> = {
  dropdown: {
    id: "alchemy:dropdown",
    name: "Dropdown",
    storeId: "",
    component: {
      type: "dropdown",
      id: "alchemy:dropdown",
      props: {},
    },
    variants: [
      {
        id: "alchemy:dropdownClosed",
        name: "default",
        query: null,
        componentOverrides: null,
      },
      {
        id: "alchemy:dropdownOpen",
        name: "Open",
        query: null,
        componentOverrides: null,
      },
    ],
  },
  collapsible: {
    id: "alchemy:collapsible",
    name: "Collapsible",
    storeId: "",
    component: {
      type: "collapsible",
      id: "alchemy:collapsible",
      props: {},
    },
    variants: [
      {
        id: "alchemy:collapsibleClosed",
        name: "default",
        query: null,
        componentOverrides: null,
      },
      {
        id: "alchemy:collapsibleOpen",
        name: "Open",
        query: null,
        componentOverrides: null,
      },
    ],
  },
};

export function hasBuiltInSymbol(type: string): type is BuiltInSymbolType {
  return builtInSymbolDirectory[type as BuiltInSymbolType] !== undefined;
}

export function getBuiltInSymbol(type: string) {
  return type in builtInSymbolDirectory
    ? builtInSymbolDirectory[type as BuiltInSymbolType]
    : null;
}
