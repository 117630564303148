import type {
  ElementGenericMetafield,
  StringMetafield,
} from "replo-runtime/shared/types";

import * as React from "react";

import Textarea from "@common/designSystem/Textarea";
import Input from "@editor/components/common/designSystem/Input";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import ImageSourceSelector from "@editorComponents/ImageSourceSelector";

import { ElementEditorHeading } from "./ElementEditorHeading";

const SeoElementEditor: React.FC<{
  isPageSettings: boolean;
  metafields: ElementGenericMetafield[];
  onMetafieldChange(
    metafieldName: string,
    value: string,
    namespace: string,
    type: "string",
  ): void;
}> = ({ isPageSettings, onMetafieldChange, metafields }) => {
  const handleSeoTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onMetafieldChange("title_tag", value, "global", "string");
  };

  const handleSeoDescriptionChange = (value: string) => {
    onMetafieldChange("description_tag", value, "global", "string");
  };

  const handleFeaturedImageChange = (value: string) => {
    onMetafieldChange("page_featured_image", value, "replo", "string");
  };

  const titleTag = (
    metafields?.find((field) => field.key === "title_tag") as
      | StringMetafield
      | undefined
  )?.value;
  const descriptionTag = (
    metafields?.find((field) => field.key === "description_tag") as
      | StringMetafield
      | undefined
  )?.value;
  const featuredImage = (
    metafields?.find((field) => field.key === "page_featured_image") as
      | StringMetafield
      | undefined
  )?.value;

  return (
    <div className="flex flex-col gap-y-2">
      <ElementEditorHeading className="text-base">
        SEO Settings
      </ElementEditorHeading>
      <LabeledControl label="Page Title" id="page-data-title">
        <Input
          id="page-data-title"
          autoFocus={false}
          value={titleTag}
          placeholder={titleTag}
          size="base"
          onChange={handleSeoTitleChange}
        />
      </LabeledControl>
      {isPageSettings && (
        <>
          <LabeledControl
            label="Page Featured Image"
            id="page-data-featured-image"
          >
            <ImageSourceSelector
              size="sm"
              src={featuredImage}
              onChangeImageSource={handleFeaturedImageChange}
              onRemove={() => handleFeaturedImageChange("")}
              allowsDynamicData={false}
              offset={33}
            />
          </LabeledControl>
        </>
      )}
      <LabeledControl label="Description" id="page-data-description">
        <Textarea
          debounce
          size="sm"
          className="w-full flex-grow text-sm"
          value={descriptionTag}
          id="page-data-description"
          onChange={handleSeoDescriptionChange}
        />
      </LabeledControl>
    </div>
  );
};

export default SeoElementEditor;
