import * as React from "react";

import { FlowProgress } from "@components/flows/components/FlowProgress";
import { useReploFlowsContext } from "@components/flows/context/ReploFlowsContext";
import { useReploFlowsStepContext } from "@components/flows/context/ReploFlowsStepContext";
import { useGetCurrentFlow } from "@editor/components/flows/hooks/useGetCurrentFlow";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import {
  selectDraftComponentId,
  selectRootComponentId,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";

import Button from "@replo/design-system/components/button";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

type TourFlowStepTypes = "step-1" | "step-2" | "step-3" | "step-4" | "step-5";

const TourFlowActions: React.FC = () => {
  const analytics = useLogAnalytics();
  // NOTE (Sebas, 2024-02-21): Used to keep track of the clicked option to show the loading state
  // on the correct button.
  const [clickedOption, setClickedOption] = React.useState<
    "skip" | "submit" | null
  >(null);
  const { isLoading: isUserLoading } = useCurrentUser();
  const {
    debug: { flowToDebug, setFlowToDebug },
  } = useReploFlowsContext();

  const { skipStep, submitStep, isSubmitting, currentStep } =
    useReploFlowsStepContext();
  const { nextStep } = useGetCurrentFlow({
    entityType: "user",
  });

  const isThereNextStep = nextStep?.nextStep !== null;

  const onSkipStep = React.useCallback(() => {
    if (currentStep) {
      setClickedOption("skip");
      skipStep(currentStep.id, currentStep.type);
      analytics("editor-tour.flow.skip", {
        skipOnStepId: currentStep.id,
      });
      if (flowToDebug) {
        setFlowToDebug?.(null);
      }
    }
  }, [analytics, currentStep, flowToDebug, setFlowToDebug, skipStep]);

  const stepCallback = useGetStepCallback(currentStep?.id as TourFlowStepTypes);

  const onSubmitStep = React.useCallback(() => {
    if (currentStep) {
      setClickedOption("submit");
      submitStep(currentStep.id, currentStep.type, {}, () => stepCallback?.());
      if (isThereNextStep) {
        analytics("editor-tour.flow.next", {
          currentStep: currentStep.id,
        });
      } else {
        analytics("editor-tour.flow.completed", {});
        if (flowToDebug) {
          setFlowToDebug?.(null);
        }
      }
    }
  }, [
    analytics,
    currentStep,
    flowToDebug,
    isThereNextStep,
    setFlowToDebug,
    stepCallback,
    submitStep,
  ]);

  return (
    <div className="flex w-full justify-between mt-4 items-center">
      <FlowProgress className="h-2 w-16" entityType="user" />
      <div className="flex gap-2">
        <Button
          className="bg-slate-100 text-slate-600"
          textClassNames="text-xs"
          variant="secondary"
          size="base"
          isDisabled={isSubmitting || isUserLoading}
          isLoading={
            (isSubmitting || isUserLoading) && clickedOption === "skip"
          }
          onClick={onSkipStep}
        >
          Skip
        </Button>
        <Button
          textClassNames="text-xs"
          variant="primary"
          size="base"
          onClick={onSubmitStep}
          isLoading={
            (isSubmitting || isUserLoading) && clickedOption === "submit"
          }
        >
          {isThereNextStep ? "Next" : "Finish"}
        </Button>
      </div>
    </div>
  );
};

const useGetStepCallback = (stepType: TourFlowStepTypes | null) => {
  const setDraftElement = useSetDraftElement();
  const rootComponentId = useEditorSelector(selectRootComponentId);
  const draftComponentId = useEditorSelector(selectDraftComponentId);

  if (!stepType) {
    return () => null;
  }

  return () =>
    exhaustiveSwitch({ type: stepType })({
      "step-1": () => null,
      "step-2": () => {
        // NOTE (Sebas, 2024-02-19): In case there is no element selected on the editor
        // we want to auto-select the root to open the right bar and show the next step.
        if (!Boolean(draftComponentId)) {
          setDraftElement({ componentIds: [rootComponentId] });
        }
      },
      "step-3": () => null,
      "step-4": () => null,
      "step-5": () => null,
    });
};

export default TourFlowActions;
