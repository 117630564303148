import * as React from "react";

import {
  createKlaviyoIdentifiersStore,
  KlaviyoIdentifiersContext,
} from "./context";

export const KlaviyoIdentifiersContextProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const [store] = React.useState(() => {
    return createKlaviyoIdentifiersStore();
  });

  return (
    <KlaviyoIdentifiersContext.Provider value={store}>
      {children}
    </KlaviyoIdentifiersContext.Provider>
  );
};
