import * as React from "react";

import Selectable from "@common/designSystem/Selectable";
import { useGetAttribute } from "@editor/hooks/useGetAttribute";
import { useStoreProductsFromDataTablesMapping } from "@editor/hooks/useStoreProducts";
import { selectLocaleData } from "@editor/reducers/commerce-reducer";
import {
  selectDataTablesMapping,
  selectDraftComponentId,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
} from "@editor/reducers/core-reducer";
import { selectTemplateEditorStoreProduct } from "@editor/reducers/template-reducer";
import { useEditorSelector } from "@editor/store";
import { findAncestorComponent } from "@utils/component";

import { getRowObjectsFromDataTablesMapping } from "replo-runtime/store/AlchemyDataTable";

const DataTableRowSelect = (props: {
  value?: number;
  onChange(value: number): void;
  className?: string;
  field: string;
}) => {
  const draftElement = useEditorSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  );
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const { activeCurrency, activeLanguage, moneyFormat } =
    useEditorSelector(selectLocaleData);
  const dataTables = useEditorSelector(selectDataTablesMapping);
  const getAttribute = useGetAttribute();
  const { products } = useStoreProductsFromDataTablesMapping();
  const templateProduct =
    useEditorSelector(selectTemplateEditorStoreProduct) ?? null;
  const productDependencies = {
    products,
    currencyCode: activeCurrency,
    language: activeLanguage,
    moneyFormat,
    templateProduct,
    isEditor: true,
  };
  const _getDataTableRowOptions = (): { display: string; id: string }[] => {
    const ancestor = findAncestorComponent(
      draftElement,
      draftComponentId,
      (component) => component.type === "collectionSelect",
    );
    if (!ancestor) {
      return [];
    }
    const dataTableId = getAttribute(ancestor, props.field, null).value?.id;
    if (!dataTableId) {
      return [];
    }
    const dataTable = dataTables[dataTableId]!;
    const items = getRowObjectsFromDataTablesMapping(
      dataTables,
      dataTableId,
      productDependencies,
    );
    return items.map((item, index) => {
      return {
        display: item[dataTable.data.schema[0]!.name],
        id: String(index),
      };
    });
  };

  return (
    <Selectable
      className={props.className}
      options={_getDataTableRowOptions()?.map((option) => {
        return { label: option.display, value: option.id };
      })}
      value={`${props.value}`}
      onSelect={(value) => props.onChange(Number.parseInt(value!))}
    />
  );
};

export default DataTableRowSelect;
