import type { StoreProductSummary } from "schemas/product";

import React from "react";

import { formatQueryWithIds } from "@editor/hooks/useStoreProducts";
import { useLazyGetShopifyProductsSummaryQuery } from "@editor/reducers/api-reducer";
import { selectProductSummaries } from "@editor/reducers/commerce-reducer";
import {
  selectDraftElementTemplateProducts,
  selectIsShopifyIntegrationEnabled,
} from "@editor/reducers/core-reducer";
import { selectTemplateEditorProduct } from "@editor/reducers/template-reducer";
import { useEditorSelector } from "@editor/store";

import uniqBy from "lodash-es/uniqBy";
import { fakeProducts } from "replo-runtime/store/utils/fakeProducts";
import { filterNulls } from "replo-utils/lib/array";
import { isEmpty } from "replo-utils/lib/misc";

import useCurrentProjectId from "./useCurrentProjectId";

export const productSummaryRequestLimits = {
  infinite: 20,
  default: 100,
  maximum: 250,
};

type ProductSummaryDataMap = {
  cachedProductSummaries: StoreProductSummary[];
  productIdsToBeRequested: number[];
};

const useCachedProductSummaries = ({
  ids,
  firstN,
}: {
  ids?: number[];
  firstN?: number;
}) => {
  const productSummariesFromStore: Record<string, StoreProductSummary> =
    useEditorSelector(selectProductSummaries);

  // NOTE (Chance 2023-06-28): This is a quick and dirty workaround to avoid the
  // need to memoize the incoming array of product ids unless one of its values
  // actually changes.
  let idsStringified: string | null = null;
  try {
    if (ids) {
      idsStringified = JSON.stringify(ids);
    }
  } catch {}

  const productSummaryDataMap: ProductSummaryDataMap = React.useMemo(() => {
    let ids: number[] = idsStringified ? JSON.parse(idsStringified) : [];
    // NOTE (Matt 2024-11-25): In some circumstances, this fn is called without a list
    // of ids but with a number of product summaries to be requested. In that case,
    // we just grab the first of that quantity from the store.
    if (ids.length < 1 && firstN) {
      ids = Object.keys(productSummariesFromStore)
        .slice(0, firstN)
        .map((id) => Number(id));
    }
    const uniqueIds = uniqBy(ids, Number);
    return {
      cachedProductSummaries: filterNulls(
        uniqueIds.map((id) => productSummariesFromStore[id]),
      ),
      productIdsToBeRequested: uniqueIds.filter(
        (id) => !Object.keys(productSummariesFromStore).includes(String(id)),
      ),
    };
  }, [idsStringified, firstN, productSummariesFromStore]);

  return productSummaryDataMap;
};

// NOTE (Matt 2024-11-21): This function is used to format queries for
// productSummaries based on given ids and/or pageSize. The real trick
// here is that we can't query more than 250 products per request,
// so we cap the pageSize and determine if we need to break up into
// multiple queries if the number of ids is greater than the number
// of pagesizes.
function generateQueries({
  ids,
  pageSize,
}: {
  ids?: number[];
  pageSize?: number;
}) {
  pageSize = pageSize ?? ids?.length ?? productSummaryRequestLimits.default;
  if (pageSize > productSummaryRequestLimits.maximum) {
    pageSize = productSummaryRequestLimits.maximum;
  }
  if (!ids) {
    return [{ pageSize }];
  }
  const queries = [];
  while (ids.length > pageSize) {
    queries.push({
      pageSize,
      query: formatQueryWithIds(ids.slice(0, pageSize)),
    });
    ids = ids.slice(pageSize);
  }
  queries.push({
    pageSize,
    query: formatQueryWithIds(ids),
  });
  return queries;
}

export function useFetchStoreProductSummaries({
  ids,
  pageSize,
  forceSkip,
}: {
  ids?: number[];
  pageSize?: number;
  forceSkip?: boolean;
}) {
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );
  const projectId = useCurrentProjectId();
  const { cachedProductSummaries, productIdsToBeRequested } =
    useCachedProductSummaries({ ids, firstN: pageSize });
  const [queries, setQueries] = React.useState(
    generateQueries({ ids: productIdsToBeRequested, pageSize }),
  );

  const [triggerGetProductsSummaryQuery, { isLoading, isFetching }] =
    useLazyGetShopifyProductsSummaryQuery();

  const shouldSkip =
    forceSkip || queries.length < 1 || !isShopifyIntegrationEnabled;

  // NOTE (Matt 2024-11-21): This useEffect is necessary primarily for when the
  // ids/pageSize params change.
  React.useEffect(() => {
    if (productIdsToBeRequested.length > 0 && !(isLoading || isFetching)) {
      setQueries(generateQueries({ ids: productIdsToBeRequested, pageSize }));
    }
  }, [productIdsToBeRequested, pageSize, isLoading, isFetching]);

  // NOTE (Matt 2024-11-21): If we are not actively making requests and we need to
  // then we trigger a request for each query. We don't need to worry about the
  // return value, that data is being set in the redux store in the api-reducer.
  // We're able to access the resolved product summaries in the cachedProductSummaries
  // when the redux store updates.
  React.useEffect(() => {
    if (shouldSkip || isLoading || isFetching || !projectId) {
      return;
    }
    queries.forEach((query) => {
      void triggerGetProductsSummaryQuery({ storeId: projectId, ...query });
    });
    setQueries([]);
  }, [
    shouldSkip,
    queries,
    triggerGetProductsSummaryQuery,
    isLoading,
    isFetching,
    projectId,
  ]);

  return {
    productsSummary: cachedProductSummaries,
    isLoading,
  };
}

const useFirstNProductsSummary = (nProducts: number) => {
  const { productsSummary, isLoading } = useFetchStoreProductSummaries({
    pageSize: nProducts,
  });
  return { productsSummary, isLoading };
};

export const useFirstNProductsSummaryOrPlaceholderProducts = (
  nProducts: number = productSummaryRequestLimits.default,
) => {
  const { productsSummary, isLoading } = useFirstNProductsSummary(nProducts);
  if (!isLoading && productsSummary && productsSummary.length === 0) {
    return {
      productsSummary: fakeProducts.map((fakeProduct) => ({
        id: Number(fakeProduct.id),
        title: fakeProduct.title,
        featuredImage: fakeProduct.featured_image,
        defaultVariantId: fakeProduct.variants[0]?.id ?? 0,
      })),
      isLoading,
      isPlaceholderProducts: true,
    };
  }
  return {
    productsSummary,
    isLoading,
    isPlaceholderProducts: false,
  };
};

export const useFetchSpecificProductsSummary = (
  ids: number[] | undefined,
  forceSkip: boolean = false,
) => {
  const { productsSummary, isLoading } = useFetchStoreProductSummaries({
    ids,
    forceSkip,
  });
  return { productsSummary, isLoading };
};

export const useTemplateEditorProductSummary = () => {
  const templateEditorProduct = useEditorSelector(selectTemplateEditorProduct);
  const { productsSummary: firstNProductsSummary } = useFirstNProductsSummary(
    productSummaryRequestLimits.default,
  );

  // Note (Evan, 2023-10-30): We check first in the fake products and the first n products, so that we only fire off
  // an additional request if we have to
  const placeholderProduct = fakeProducts.find(
    (fakeProduct) =>
      Number(fakeProduct.id) === Number(templateEditorProduct?.productId),
  );

  const cachedProduct =
    firstNProductsSummary &&
    firstNProductsSummary.find(
      (existingProduct) =>
        existingProduct.id === Number(templateEditorProduct?.productId),
    );

  const { productsSummary, isLoading } = useFetchSpecificProductsSummary(
    [templateEditorProduct ? Number(templateEditorProduct.productId) : 0],
    Boolean(!templateEditorProduct || placeholderProduct || cachedProduct),
  );

  if (!templateEditorProduct) {
    return {
      templateEditorProductSummary: null,
      isLoading: false,
    };
  }

  if (placeholderProduct) {
    return {
      templateEditorProductSummary: {
        title: placeholderProduct.title,
        id: Number(placeholderProduct.id),
        featuredImage: placeholderProduct.featured_image,
        defaultVariantId: placeholderProduct.variants[0]?.id ?? 0,
      },
      isLoading: false,
    };
  }

  if (cachedProduct) {
    return {
      templateEditorProductSummary: cachedProduct,
      isLoading: false,
    };
  }

  if (isLoading || !productsSummary) {
    return {
      templateEditorProductSummary: null,
      isLoading: true,
    };
  }

  if (!productsSummary[0]) {
    return {
      templateEditorProductSummary: null,
      isLoading: false,
    };
  }

  if (productsSummary[0].id !== Number(templateEditorProduct.productId)) {
    return {
      templateEditorProductSummary: null,
      isLoading: true,
    };
  }

  return {
    templateEditorProductSummary: productsSummary[0],
    isLoading: false,
  };
};

export const useAssignedProductsSummary = () => {
  const assignedProductIds = useEditorSelector(
    selectDraftElementTemplateProducts,
  );

  const { productsSummary, isLoading } = useFetchSpecificProductsSummary(
    assignedProductIds,
    isEmpty(assignedProductIds),
  );

  if (isEmpty(assignedProductIds) || isLoading || !productsSummary) {
    return { productsSummary: [], isLoading };
  }
  return {
    productsSummary,
    isLoading,
  };
};
