import * as React from "react";

export const ReploLogoBlack = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="91"
    height="27"
    viewBox="0 0 136 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 31.5445H23.2227V8.77222C20.1731 8.77109 17.1533 9.35946 14.3361 10.5037C11.519 11.6479 8.95997 13.3254 6.80561 15.4401C2.45071 19.714 0.00331622 25.5054 0 31.5445Z"
      fill="black"
    />
    <path
      d="M51.1889 8.70519V13.7019H47.6377C43.5971 13.7019 41.1613 15.7051 41.1613 19.962V31.344H35.6865V8.92145H40.945V12.9279C41.4638 11.5929 42.3891 10.4548 43.5901 9.67456C44.7912 8.8943 46.2071 8.51139 47.6377 8.57998C48.1613 8.62551 50.7109 8.62551 51.1889 8.70519Z"
      fill="black"
    />
    <path
      d="M74.4109 21.8655H57.6337C58.0321 25.8606 60.2857 27.6476 63.4613 27.6476C64.5471 27.7278 65.6301 27.4584 66.5517 26.8788C67.4733 26.2992 68.1852 25.4399 68.5833 24.4265H73.8418C72.7036 28.9793 68.7995 31.9045 63.4158 31.9045C56.8142 31.9045 52.25 27.3517 52.25 20.1696C52.25 12.9876 56.6776 8.43481 63.3247 8.43481C70.154 8.43481 74.4109 13.4429 74.4109 20.568V21.8655ZM57.6792 18.0412H68.9361C68.5036 14.877 66.5459 12.7031 63.2451 12.7031C60.3426 12.7031 58.2028 14.3535 57.6792 18.0412Z"
      fill="black"
    />
    <path
      d="M100.396 20.1697C100.396 27.2948 96.526 31.9045 90.3569 31.9045C89.0041 31.9666 87.6574 31.6862 86.4417 31.0893C85.2261 30.4924 84.1807 29.5983 83.4025 28.49V39.8719H77.9277V8.95845H83.1862V12.1796C83.9487 10.982 85.0125 10.0057 86.2709 9.34851C87.5294 8.69132 88.9384 8.37627 90.3569 8.43489C96.526 8.43489 100.396 13.0446 100.396 20.1697ZM94.8301 19.999C94.8301 15.2641 92.622 12.9649 89.0594 12.9649C85.4968 12.9649 83.2773 15.4348 83.2773 20.1697C83.2773 24.9046 85.4854 27.3859 89.0594 27.3859C92.6334 27.3859 94.8301 24.7339 94.8301 19.999Z"
      fill="black"
    />
    <path
      d="M103.834 0.444702H109.286V31.3809H103.812L103.834 0.444702Z"
      fill="black"
    />
    <path
      d="M112.699 20.1696C112.699 13.0445 117.309 8.43481 124.138 8.43481C130.967 8.43481 135.52 13.0445 135.52 20.1696C135.52 27.2948 130.967 31.9045 124.138 31.9045C117.309 31.9045 112.699 27.3517 112.699 20.1696ZM129.954 20.1696C129.954 15.4347 127.678 12.9648 124.138 12.9648C120.598 12.9648 118.311 15.4347 118.311 20.1696C118.311 24.9046 120.587 27.3858 124.138 27.3858C127.689 27.3858 129.989 24.9046 129.989 20.1696H129.954Z"
      fill="black"
    />
  </svg>
);
