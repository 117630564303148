import type { EditorRootState } from "@editor/store";
import type { PayloadAction } from "@reduxjs/toolkit";

import { createSlice } from "@reduxjs/toolkit";

export type ComponentTemplatesState = {
  deletedSavedComponentTemplateIds: string[];
};

const initialState: ComponentTemplatesState = {
  deletedSavedComponentTemplateIds: [],
};

const componentTemplatesSlice = createSlice({
  name: "componentTemplates",
  initialState,
  reducers: {
    setDeletedSavedComponentTemplatesIds: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.deletedSavedComponentTemplateIds = [
        ...state.deletedSavedComponentTemplateIds,
        action.payload,
      ];
    },
  },
});

export const selectDeletedSavedComponentTemplatesIds = (
  state: EditorRootState,
): string[] => {
  // @ts-ignore
  return state.componentTemplates.deletedSavedComponentTemplateIds;
};

const { actions, reducer } = componentTemplatesSlice;

export const { setDeletedSavedComponentTemplatesIds } = actions;
export default reducer;
