import type { ComponentTemplateCategoryType } from "schemas/componentTemplates";
import type { ReploElementType } from "schemas/generated/element";

import * as React from "react";

import Input from "@common/designSystem/Input";
import Modal from "@common/designSystem/Modal";
import { ModalLayout } from "@common/ModalLayout";
import { FiltersSidebar } from "@components/marketplace/FiltersSidebar";
import { Loader } from "@components/marketplace/Loader";
import { ComponentTemplatesEmptyState } from "@components/marketplace/TemplateEmptyState";
import TemplatesGrid from "@editor/components/marketplace/TemplatesGrid";
import useInfiniteTemplates from "@editor/hooks/useInfiniteTemplates";
import useMarketplaceFiltersState from "@editor/hooks/useMarketplaceFiltersState";
import { selectDraftElementType } from "@editor/reducers/core-reducer";
import { setLastMarketplacePath } from "@editor/reducers/marketplace-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { routes } from "@editor/utils/router";
import { useModal } from "@hooks/useModal";

import Button from "@replo/design-system/components/button";
import { BsSearch } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { componentTemplateCategoryTypeToModalTitle } from "replo-runtime/shared/componentTemplates";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { isNotNullish } from "replo-utils/lib/misc";

function MarketplaceModal() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const modal = useModal();

  const draftElementType = useEditorSelector(selectDraftElementType);
  const elementType: ReploElementType =
    state?.elementType ?? draftElementType ?? "shopifySection";

  const query = state?.searchString ?? "";
  const [searchString, setSearchString] = useOverridableState<string>(query);

  const [selectedFilters, setSelectedFilters] = useMarketplaceFiltersState();

  const defaultType = elementType === "shopifySection" ? "section" : "page";
  const [type, setType] =
    useOverridableState<ComponentTemplateCategoryType>(defaultType);

  const {
    componentTemplatesList,
    fetchNextPage,
    // NOTE (Fran, 2023-04-21): We are using isLoading instead of isFetching here
    // because this loading will only show before the first fetch after that we
    // will show the spinner on the infinite scroll.
    isLoading: isLoadingComponentTemplates,
    isLoadingInitialPage: isLoadingComponentTemplatesInitialPage,
    isSuccessComponentTemplates,
    hasNextPage,
    queryHasResults,
  } = useInfiniteTemplates({
    searchString,
    includeComponent: false,
    selectedBadges: selectedFilters.badge,
    selectedIndustries: selectedFilters.industry,
    selectedCategories: selectedFilters.category,
    templateType: type,
  });

  const onInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setSearchString(value);
      setSelectedFilters({
        badge: [],
        industry: [],
        category: [],
      });
      if (value.length > 0) {
        navigate("", {
          state: {
            ...state,
            searchString: value,
            selectedFilters: { badge: [], industry: [], category: [] },
          },
        });
      }
    },
    [navigate, setSearchString, setSelectedFilters, state],
  );

  const dispatch = useEditorDispatch();

  if (!draftElementType) {
    return null;
  }

  const isSearching = searchString.length > 0;

  const hasEmptyNormalSearchResults =
    componentTemplatesList &&
    ((componentTemplatesList.length ?? 0) === 0 || queryHasResults) &&
    isSearching;
  const shouldShowSearchEmptyState = hasEmptyNormalSearchResults;

  const shouldShowFullPageLoader = isLoadingComponentTemplatesInitialPage;

  const showComponentTemplatesEmptyState =
    componentTemplatesList?.length === 0 && !shouldShowSearchEmptyState;

  const showComponentTemplates =
    !showComponentTemplatesEmptyState &&
    !isLoadingComponentTemplatesInitialPage &&
    isSuccessComponentTemplates &&
    // Note (Noah, 2023-05-11): We specifically don't check shouldShowSearchEmptyState here because
    // this list can be populated even in the case where there was no result (in the case where we
    // default to the Replo UI collection)
    isNotNullish(componentTemplatesList);

  const handleClose = () => {
    modal.closeModal({});
    dispatch(setLastMarketplacePath(routes.marketplaceModal));
    return navigate("..", {
      relative: "path",
    });
  };

  return (
    <Modal
      isOpen
      onRequestClose={handleClose}
      className="w-auto p-0"
      closeButtonClassnames="right-7 top-7"
    >
      <ModalLayout
        height="90vh"
        width="90vw"
        minWidth="1100px"
        wrapperClassnames="rounded bg-stone-50"
        layoutClassnames="p-0"
        // Note (Noah, 2023-05-13): Set overflow-hidden to override the default behavior
        // since both the sidebar and the content here are independently scrollable
        mainContentClassnames="mb-0 overflow-hidden"
        mainContent={() => {
          return (
            <div className="flex flex-col w-full">
              <div className="w-full flex items-center py-5 border-b border-slate-200 relative">
                <div className="absolute left-6 text-xl text-default">
                  Replo Marketplace
                </div>
                <div className="max-w-md w-full mx-auto">
                  <Input
                    size="base"
                    startEnhancer={() => (
                      <BsSearch size={12} className="text-slate-400" />
                    )}
                    placeholder="Search"
                    value={searchString}
                    onChange={onInputChange}
                  />
                </div>
              </div>
              <div
                className="flex h-full flex-1 flex-row"
                // NOTE (Sebas, 2023-12-01): This is the height of the modal minus the height of the search bar
                // header.
                style={{ height: "calc(90vh - 73px)" }}
              >
                <FiltersSidebar
                  templateType={type}
                  setTemplateType={(value) => setType(value ?? defaultType)}
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                  elementType={elementType}
                  className="pl-6 pr-4 py-6"
                />
                {/* Start Content */}
                <div
                  id="marketplace-modal-content"
                  // Note (Noah, 2023-05-13): We use margin instead of padding for the top offset
                  // here since we don't want templates to be visible above the sticky header as
                  // you scroll through the infinite template list
                  className="no-scrollbar flex w-full flex-col overflow-auto px-6"
                >
                  {shouldShowSearchEmptyState && (
                    <ComponentTemplatesEmptyState
                      emptyStateLabel={
                        <>
                          No Results For{" "}
                          <span className="font-bold">{searchString}</span>
                        </>
                      }
                      resetButton={
                        <Button
                          variant="secondary"
                          className="bg-blue-600 py-3.5 px-5 text-stone-50"
                          isRounded
                          onClick={() => setSearchString("")}
                        >
                          Reset Search
                        </Button>
                      }
                    />
                  )}
                  {/* Start Components Templates */}
                  {showComponentTemplates && (
                    <div className="my-6">
                      <TemplatesGrid
                        scrollableTargetId="marketplace-modal-content"
                        templates={componentTemplatesList}
                        elementType={elementType}
                        settings={{
                          hasNextPage,
                          isNextPageLoading: isLoadingComponentTemplates,
                          loadNextPage: () => void fetchNextPage(),
                        }}
                        title={
                          isSearching && !shouldShowSearchEmptyState
                            ? "Search Results"
                            : componentTemplateCategoryTypeToModalTitle[type]
                        }
                      />
                    </div>
                  )}
                  {/* TODO (Fran 2023-12-04): Add empty state for component templates */}
                  {showComponentTemplatesEmptyState && (
                    <ComponentTemplatesEmptyState
                      emptyStateLabel="No Templates for the current filters"
                      resetButton={
                        <Button
                          variant="secondary"
                          className="bg-blue-600 py-3.5 px-5 text-stone-50"
                          isRounded
                          onClick={() =>
                            setSelectedFilters({
                              badge: [],
                              industry: [],
                              category: [],
                            })
                          }
                        >
                          Reset Filters
                        </Button>
                      }
                    />
                  )}
                  {shouldShowFullPageLoader && (
                    <Loader
                      label={
                        searchString.length === 0
                          ? "Loading Marketplace"
                          : "Hacking into the matrix, and computing your query"
                      }
                    />
                  )}
                  {/* End Components Templates */}
                </div>
                {/* End Content */}
              </div>
            </div>
          );
        }}
      />
    </Modal>
  );
}

export default MarketplaceModal;
