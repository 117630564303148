import type { ComponentTemplate } from "@editor/types/component-template";
import type { Component } from "schemas/component";

import { copyContainerParentFlexStyles } from "@editor/components/editor/templates/transforms";

import merge from "lodash-es/merge";
import { Env } from "replo-runtime/shared/enums";
import { createDefaultVerticalContainer } from "replo-runtime/shared/utils/component";
import { v4 as uuidv4 } from "uuid";

function generateContainers(amount: number) {
  return Array.from({ length: amount }).map(() => getNewContainer());
}

const HORIZONTAL_CONTAINER_TEMPLATE_ID = "938cd230-6afd-4522-b0c5-5ae3fb980580";
const VERTICAL_CONTAINER_TEMPLATE_ID = "c6497292-1eac-4d69-a589-1422ead18ce0";
const horizontalContainerTemplate = {
  id: "$uid",
  type: "container",
  props: {
    style: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      alignSelf: "stretch",
      maxWidth: "100%",
    },
  },
  children: [],
} as Component;

export const horizontalStack: ComponentTemplate = {
  id: HORIZONTAL_CONTAINER_TEMPLATE_ID,
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Layout/1 Column",
  leftBarCategory: { main: "layout", subCategory: "columns" },
  preview: "layout/one-column.svg",
  transforms: [copyContainerParentFlexStyles],
  template: horizontalContainerTemplate,
};

export const verticalStack: ComponentTemplate = {
  id: VERTICAL_CONTAINER_TEMPLATE_ID,
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Base/Containern",
  leftBarCategory: { main: "basic", subCategory: "core" },
  preview: "vertical-stack.png",
  transforms: [copyContainerParentFlexStyles],
  template: createDefaultVerticalContainer("$uid", []),
};

export const container: ComponentTemplate = {
  ...horizontalStack,
  id: "eb455411-87a3-4b99-815f-bb66b68b1954",
  name: "Basic/Container",
  tags: ["div block"],
  preview: "basic/container.svg",
  leftBarCategory: { main: "basic", subCategory: "core" },
};

const getGridTemplateContainer = (
  numberOfColumns: number,
  gridTemplateColumns?: string,
) => {
  return merge({}, horizontalContainerTemplate, {
    props: {
      style: {
        display: "grid",
        gridTemplateColumns:
          gridTemplateColumns ?? `repeat(${numberOfColumns}, minmax(0, 1fr))`,
        __numberOfColumns: numberOfColumns,
      },
    },
  });
};

const getNewContainer = () => {
  return {
    ...horizontalStack.template,
    id: uuidv4(),
  };
};

export const twoColumns: ComponentTemplate = {
  id: uuidv4(),
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Layout/2 Columns",
  leftBarCategory: { main: "layout", subCategory: "columns" },
  tags: ["grid", "2 cols"],
  preview: "layout/two-columns.svg",

  transforms: [],
  template: {
    ...getGridTemplateContainer(2),
    children: generateContainers(2),
  },
};

export const oneByTwoColumns: ComponentTemplate = {
  id: uuidv4(),
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Layout/1/2 Columns",
  leftBarCategory: { main: "layout", subCategory: "columns" },
  tags: ["grid"],
  preview: "layout/one-by-two-columns.svg",

  transforms: [],
  template: {
    ...getGridTemplateContainer(2, "minmax(0, 1fr) minmax(0, 2fr)"),
    children: generateContainers(2),
  },
};

export const twoByOneColumns: ComponentTemplate = {
  id: uuidv4(),
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Layout/2/1 Columns",
  leftBarCategory: { main: "layout", subCategory: "columns" },
  tags: ["grid"],
  preview: "layout/two-by-one-columns.svg",
  transforms: [],
  template: {
    ...getGridTemplateContainer(2, "minmax(0, 2fr) minmax(0, 1fr)"),
    children: generateContainers(2),
  },
};

export const threeColumns: ComponentTemplate = {
  id: uuidv4(),
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Layout/3 Columns",
  leftBarCategory: { main: "layout", subCategory: "columns" },
  tags: ["grid", "3 cols"],
  preview: "layout/three-columns.svg",
  transforms: [],
  template: {
    ...getGridTemplateContainer(3),
    children: generateContainers(3),
  },
};

export const fourColumns: ComponentTemplate = {
  id: uuidv4(),
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Layout/4 Columns",
  leftBarCategory: { main: "layout", subCategory: "columns" },
  tags: ["grid", "4 cols"],
  preview: "layout/four-columns.svg",
  transforms: [],
  template: {
    ...getGridTemplateContainer(4),
    children: generateContainers(4),
  },
};
