import type {
  ReploElementType,
  ReploPartialElement,
} from "schemas/generated/element";

import * as React from "react";

import { API_ACTIONS } from "@editor/constants/action-types";
import { useElementEditorErrorContext } from "@editor/contexts/ElementEditorErrorContext";
import { selectIsShopifyIntegrationEnabled } from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { validateElement } from "@editor/utils/element";
import { trpc } from "@editor/utils/trpc";

import { addListener } from "@reduxjs/toolkit";
import { skipToken } from "@tanstack/react-query";
import { isPageOrShopifyArticleType } from "schemas/element";

import useCurrentProjectId from "./useCurrentProjectId";

/**
 * This hook returns a function that validates an element.
 */
export function useElementValidation(elementType: ReploElementType) {
  const dispatch = useEditorDispatch();
  const { setErrors } = useElementEditorErrorContext();
  const projectId = useCurrentProjectId();
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );
  const isPageOrShopifyArticle = isPageOrShopifyArticleType(elementType);

  const { data: shopifyBlogs, isError } = trpc.shopify.getBlogs.useQuery(
    isPageOrShopifyArticle && projectId && isShopifyIntegrationEnabled
      ? projectId
      : skipToken,
  );

  // NOTE (Sebas 2023-11-27): This is in charge of setting the
  // "pathAlreadyExists" error when the API fails because of that.
  React.useEffect(() => {
    const unsubscribe = dispatch(
      addListener({
        predicate: (action) => {
          return (
            action.type === API_ACTIONS.CREATE_OR_UPDATE_ELEMENT.error &&
            action.payload.response.key === "pathAlreadyInUse"
          );
        },
        effect: () => {
          setErrors("path", ["pathAlreadyExists"]);
        },
      }),
    );
    return unsubscribe;
  }, [dispatch, setErrors]);

  return React.useCallback(
    (element: ReploPartialElement) => {
      return validateElement(element, {
        storeHasBlogs: shopifyBlogs && shopifyBlogs.length > 0,
        isErrorFetchingData: isError,
      });
    },
    [shopifyBlogs, isError],
  );
}

/**
 * This hook returns the Shopify blogs for the current project.
 */
export function useShopifyBlogs(elementType: ReploElementType) {
  const isPageOrShopifyArticle = isPageOrShopifyArticleType(elementType);
  const projectId = useCurrentProjectId();
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );
  const {
    data: shopifyBlogs,
    isError,
    isLoading,
  } = trpc.shopify.getBlogs.useQuery(
    isPageOrShopifyArticle && projectId && isShopifyIntegrationEnabled
      ? projectId
      : skipToken,
  );

  return {
    shopifyBlogs,
    isLoading,
    isError,
  };
}
