import type { OpenModalPayload } from "@editor/reducers/modals-reducer";
import type { ComponentTemplate } from "@editor/types/component-template";
import type {
  ContextMenuActions,
  TurnIntoArgs,
} from "@editor/types/component-tree";
import type {
  ReploClipboard,
  ReploClipboardFigmaStyles,
  ReploClipboardImage,
  ReploClipboardMultiple,
  ReploClipboardSingle,
  ReploClipboardStyles,
  ReploClipboardText,
} from "@editor/utils/copyPaste";
import type { UseApplyComponentActionType } from "@hooks/useApplyComponentAction";
import type { NormalizedFlexDirection } from "replo-runtime/shared/utils/flexDirection";
import type { ProductResolutionDependencies } from "replo-runtime/store/ReploProduct";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { Component } from "schemas/component";
import type { RuntimeStyleAttribute } from "schemas/styleAttribute";

import * as React from "react";

import {
  errorToast,
  successToast,
} from "@editor/components/common/designSystem/Toast";
import {
  HORIZONTAL_CONTAINER_COMPONENT_TEMPLATE,
  normalizeProductProps,
  prepareComponentTemplate,
  VERTICAL_CONTAINER_COMPONENT_TEMPLATE,
} from "@editor/components/editor/defaultComponentTemplates";
import { image } from "@editor/components/editor/templates/image";
import { text } from "@editor/components/editor/templates/text";
import { useFindParentForPaste } from "@editor/hooks/useFindParentForPaste";
import { useModal } from "@editor/hooks/useModal";
import useOpenCodeEditor from "@editor/hooks/useOpenCodeEditor";
import {
  useAnyStoreProducts,
  useStoreProductsFromDraftElement,
} from "@editor/hooks/useStoreProducts";
import { useAIStreaming } from "@editor/providers/AIStreamingProvider";
import { selectLocaleData } from "@editor/reducers/commerce-reducer";
import {
  selectColor,
  selectComponentDataMapping,
  selectComponentMapping,
  selectDoesExistDraftElement,
  selectDraftComponentId,
  selectDraftComponentIds,
  selectDraftComponentText,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectDraftElementId,
  selectSymbolsMapping,
} from "@editor/reducers/core-reducer";
import { selectTemplateEditorStoreProduct } from "@editor/reducers/template-reducer";
import { toggleExpandedTreeNode } from "@editor/reducers/tree-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import {
  canCopyComponent,
  canDeleteComponent,
  canDuplicateComponent,
  canGroupIntoContainer,
  canMoveComponentToParent,
  findAncestorComponentData,
  findAncestorComponentOrSelfWithVariants,
  findAndRenameComponentNames,
  findAndReplaceAssetUrls,
  findAndReplaceTextWithPlaceholderTexts,
  generateContextMenuWrapperComponentActions,
  getFlexDirection,
  getParentComponentFromMapping,
  sanitizeComponentName,
} from "@editor/utils/component";
import { hasVariants } from "@editor/utils/component-attribute";
import {
  getComponentClipboard,
  setComponentClipboard,
} from "@editor/utils/copyPaste";
import { canvasToStyleMap } from "@editor/utils/editor";
import { getApplicableRuntimeStyleAttributes } from "@editor/utils/modifierGroups";
import useGetDesignLibraryComponentReferences from "@hooks/designLibrary/useGetDesignLibraryComponentReferences";
import { useApplyComponentAction } from "@hooks/useApplyComponentAction";
import { useGetAttributeRef } from "@hooks/useGetAttribute";

import cloneDeep from "lodash-es/cloneDeep";
import flow from "lodash-es/flow";
import forIn from "lodash-es/forIn";
import mapValues from "lodash-es/mapValues";
import merge from "lodash-es/merge";
import reduce from "lodash-es/reduce";
import { useDispatch } from "react-redux";
import {
  forEachComponentAndDescendants,
  getChildren,
} from "replo-runtime/shared/utils/component";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import { getNormalizedFlexDirection } from "replo-runtime/shared/utils/flexDirection";
import {
  getFromRecordOrNull,
  mapNull,
} from "replo-runtime/shared/utils/optional";
import { findDefault } from "replo-runtime/shared/variant";
import { refreshComponentIds } from "replo-shared/refreshComponentIds";
import { exhaustiveSwitch, isEmpty, isNotNullish } from "replo-utils/lib/misc";
import { forEachPrimitiveValue } from "replo-utils/lib/object";
import { ReploComponentTypes } from "schemas/component";

import useHandlePasteComponentWithDesignLibraryReferences from "./designLibrary/useHandlePasteComponentWithDesignLibraryReferences";
import useFigmaPluginPaste from "./figmaToReplo/useFigmaPluginPaste";
import useSetDraftElement from "./useSetDraftElement";

const contextMenuSource = "contextMenu";

/**
 * Function that can be applied to a component when the component is pasted. Useful
 * for things like modifying product props to reference products when pasting into a
 * new store, etc
 */
type PasteTransform = (config: {
  component: Component;
  productResolutionDependencies: ProductResolutionDependencies;
  parent: Component | null;
}) => Component;

/**
 * List of default transforms that happen to components when they are pasted.
 */
const defaultPasteTransforms: PasteTransform[] = [
  ({ component, productResolutionDependencies, parent }) => {
    return normalizeProductProps(
      component,
      productResolutionDependencies,
      mapNull(parent, (parent) => getCurrentComponentContext(parent.id, 0)) ??
        null,
    );
  },
];

/**
 * Given an object, return it if it's a valid component, otherwise return null.
 *
 * Note (Noah, 2023-08-03): this is just used for pasting component json right now,
 * to avoid issues that come up with the editor when you add invalid components to
 * the redux state and try to paint them. Eventually we can use this for more things,
 * which will probably involve parsing the component as a zod schema or something
 */
const validateComponent = (possibleComponent: Object): Component | null => {
  const component = possibleComponent as Component;
  let isValid = true;
  forEachComponentAndDescendants(component, (component) => {
    if (!ReploComponentTypes.includes(component.type)) {
      isValid = false;
      return "stop";
    }
    return "continue";
  });
  return isValid ? component : null;
};

const useContextMenuActions = (): ContextMenuActions | null => {
  const store = useEditorStore();
  const openCodeEditor = useOpenCodeEditor();
  const {
    moneyFormat,
    activeCurrency: currencyCode,
    activeLanguage: language,
  } = useEditorSelector(selectLocaleData);
  const draftElementId = useEditorSelector(selectDraftElementId);
  const doesExistDraftElement = useEditorSelector(selectDoesExistDraftElement);
  const colorValue = useEditorSelector(selectColor);
  const textValue = useEditorSelector(selectDraftComponentText);
  const applyComponentAction = useApplyComponentAction();
  const { products: defaultProducts } = useAnyStoreProducts();
  const { products: draftElementProducts } = useStoreProductsFromDraftElement();
  const { setIsMenuOpen: setIsAIMenuOpen } = useAIStreaming();
  const templateProduct =
    useEditorSelector(selectTemplateEditorStoreProduct) ?? null;
  const productResolutionDependencies = {
    // We need to use some default products if draft element doesn't have any (REPL-4765)
    products: [...defaultProducts, ...draftElementProducts],
    currencyCode,
    moneyFormat,
    language,
    templateProduct,
    isEditor: true,
  };

  const symbolsMapping = useEditorSelector(selectSymbolsMapping);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const dispatch = useDispatch();
  const setDraftElement = useSetDraftElement();
  const getAttributeRef = useGetAttributeRef();
  const modal = useModal();
  const [shouldForceSentryError, setShouldForceSentryError] =
    React.useState(false);

  const draftComponentIds = useEditorSelector(selectDraftComponentIds);
  const componentMapping = useEditorSelector(selectComponentMapping);
  const componentDataMapping = useEditorSelector(selectComponentDataMapping);

  const { handlePasteComponentWithDesignLibraryReferences } =
    useHandlePasteComponentWithDesignLibraryReferences();
  const { getDesignLibraryComponentReferences } =
    useGetDesignLibraryComponentReferences();

  const findParentForPaste = useFindParentForPaste();

  // Note (Evan, 2024-07-31): Ideally we'd define this with the others, but since
  // it's a hook, we have to call it before any returns
  const {
    pasteFromFigma: handlePasteFigmaPluginv2,
    pasteFromFigmaOrToast: handlePasteFromFigma,
  } = useFigmaPluginPaste({
    findParentForPaste,
  });

  if (shouldForceSentryError) {
    throw new Error("This is a test sentry error!!");
  }

  if (!doesExistDraftElement) {
    return null;
  }

  const isMultiSelect = draftComponentIds.length > 1;
  let lastSelectedId = draftComponentId;

  if (isMultiSelect) {
    const parent = getParentComponentFromMapping(
      componentMapping,
      draftComponentIds[0]!,
    );
    // if it is multi select replace pasteComponentId with the last selected component
    getChildren(parent).forEach((child) => {
      if (draftComponentIds.includes(child.id)) {
        lastSelectedId = child.id;
      }
    });
  }

  const pasteOnComponentId = lastSelectedId;

  const _deleteCurrentComponent = (componentId: string, source: string) => {
    if (!draftComponentId) {
      return;
    }
    const parent = getParentComponentFromMapping(
      componentMapping,
      draftComponentId,
    );
    if (!parent) {
      return;
    }
    applyComponentAction({
      type: "deleteComponent",
      componentId: componentId,
      source,
    });
    setDraftElement({
      componentIds: [parent.id],
    });
  };

  const onDelete = (componentId: string, source: string) => {
    if (
      !draftElementId ||
      !componentMapping ||
      componentId === draftElementId
    ) {
      return;
    }
    const result = canDeleteComponent(componentId, componentMapping);
    if (result.canDelete) {
      _deleteCurrentComponent(componentId, source);
    } else {
      errorToast("Unable to Delete Component", result.message);
    }
  };

  const actionsToCopyComponentOverrides = (opts: {
    oldComponentId: string;
    oldIdToNewId: Record<string, string>;
  }) => {
    const { oldComponentId, oldIdToNewId } = opts;
    const oldComponent = getFromRecordOrNull(
      componentMapping,
      oldComponentId,
    )?.component;

    if (!oldComponent) {
      return [];
    }

    const ancestorWithVariantsData = findAncestorComponentData(
      oldComponent.id,
      (dataMapping) => {
        const component = componentMapping[dataMapping.id]?.component;
        if (!component) {
          return false;
        }
        return hasVariants(component, symbolsMapping);
      },
      componentDataMapping,
    );
    if (!ancestorWithVariantsData) {
      return [];
    }
    const ancestorWithVariants =
      componentMapping[ancestorWithVariantsData.id]?.component;

    if (!ancestorWithVariants) {
      return [];
    }
    const variantOverrides = ancestorWithVariants.variantOverrides;
    if (!variantOverrides) {
      return [];
    }
    const variantsOverridesEntries = Object.entries(variantOverrides);
    // Note (Fran, 2022-11-07): With this reduce we build a new array with
    // all components ids that have overrides and we relate them with the
    // corresponding variant id, to avoid use for loops inside each others
    const componentIdsWithVariantIds = reduce(
      variantsOverridesEntries,
      (result: Record<string, string>, variantsOverridesEntry) => {
        const [variantId, { componentOverrides }] = variantsOverridesEntry;
        forIn(componentOverrides, (_, key) => {
          result[key] = variantId;
        });
        return result;
      },
      {},
    );

    const actionsToApply: UseApplyComponentActionType[] = [];
    forEachComponentAndDescendants(oldComponent, (eachComponent) => {
      const variantId = componentIdsWithVariantIds[eachComponent.id];
      if (variantId) {
        const correspondingIdInNewComponent = oldIdToNewId[eachComponent.id];

        if (!correspondingIdInNewComponent) {
          return "continue";
        }

        actionsToApply.push({
          type: "duplicateComponentOverrides",
          componentIdToCopyOverridesFrom: eachComponent.id,
          destinationComponentId: correspondingIdInNewComponent,
          variantId: variantId,
          componentId: ancestorWithVariants.id,
        });
      }
      return "continue";
    });

    return actionsToApply;
  };

  const handleCopy = (
    multipleSelectedNodeIds: string[],
    clipBoard?: DataTransfer,
  ) => {
    const { canCopy, message } = canCopyComponent(
      multipleSelectedNodeIds[0]!,
      componentDataMapping,
    );

    if (!canCopy) {
      return errorToast("Failed Copying Component", message);
    }

    const designLibraryMetadata = getDesignLibraryComponentReferences(
      multipleSelectedNodeIds,
    );

    if (multipleSelectedNodeIds.length > 1) {
      const oldParent = getParentComponentFromMapping(
        componentMapping,
        multipleSelectedNodeIds[0]!,
      );
      const components = multipleSelectedNodeIds.map((selectedNodeId) => {
        return getFromRecordOrNull(componentMapping, selectedNodeId)!.component;
      });

      components.sort((a, b) => {
        const aIndex = getChildren(oldParent).indexOf(a);
        const bIndex = getChildren(oldParent).indexOf(b);
        return aIndex - bIndex;
      });

      setComponentClipboard(
        {
          type: "multipleComponents",
          components: components,
          designLibraryMetadata,
        },
        clipBoard,
      );
    } else {
      const data = getFromRecordOrNull(
        componentMapping,
        multipleSelectedNodeIds[0]!,
      );
      if (!data) {
        return;
      }
      setComponentClipboard(
        {
          type: "singleComponent",
          component: data.component,
          designLibraryMetadata,
        },
        clipBoard,
      );
    }
  };

  const onGroupContainer = (multipleSelectedNodeIds: string[]) => {
    const storeState = store.getState();
    const draftElement =
      selectDraftElement_warningThisWillRerenderOnEveryUpdate(storeState);
    if (!draftElement) {
      return;
    }
    const componentDataMapping = selectComponentDataMapping(storeState);
    const canGroupData = canGroupIntoContainer(
      multipleSelectedNodeIds,
      componentDataMapping,
    );
    if (!canGroupData.canGroupIntoContainer) {
      return errorToast("Failed Grouping Components", canGroupData.message);
    }

    const { actions, newContainerId } =
      generateContextMenuWrapperComponentActions({
        draftElement,
        multipleSelectedNodeIds: multipleSelectedNodeIds,
        productResolutionDependencies,
        componentDataMapping,
        getAttribute: getAttributeRef.current,
        wrapperType: "container",
      });

    applyComponentAction({
      type: "applyCompositeAction",
      value: actions,
    });

    setDraftElement({ componentIds: [newContainerId!] });
    dispatch(
      toggleExpandedTreeNode({ id: newContainerId!, isExpanding: true }),
    );
  };

  const onGroupDelete = (multipleSelectedNodeIds: string[]) => {
    const actions: any[] = [];

    multipleSelectedNodeIds.forEach((item) =>
      actions.push({
        type: "deleteComponent",
        componentId: item,
        analyticsExtras: {
          actionType: "delete",
          createdBy: "user",
        },
      }),
    );

    applyComponentAction({
      type: "applyCompositeAction",
      value: actions,
    });

    if (multipleSelectedNodeIds[0]) {
      const parent = getParentComponentFromMapping(
        componentMapping,
        multipleSelectedNodeIds[0],
      );
      if (parent) {
        setDraftElement({
          componentIds: [parent.id],
        });
      }
    }
  };

  const onRename = (name: string, id: string) => {
    applyComponentAction({
      type: "updateComponentName",
      value: name,
      componentId: id,
    });
  };

  const onReplaceComponent = (componentId: string, json: Object) => {
    const component = validateComponent(json);
    if (!component) {
      errorToast(
        "Invalid Component",
        "Please try again or reach out to support@replo.app for help.",
      );
      return;
    }

    applyComponentAction({
      type: "replaceComponent",
      componentId: componentId,
      value: { newComponent: component },
      analyticsExtras: {
        actionType: "other",
        createdBy: "replo",
      },
    });
  };

  const onPersistStylesToUpstreamDevices = (
    componentId: string,
    includeDescendants: boolean,
  ) => {
    applyComponentAction({
      type: "persistStylesToUpstreamDevices",
      componentId: componentId,
      includeDescendants,
      analyticsExtras: {
        actionType: "other",
        createdBy: "replo",
      },
    });
  };

  const getStringFromComponentJson = (componentId: string) => {
    const shouldMinify = localStorage.getItem(
      "replo.debug.copyComponentJsonMinify",
    );
    if (shouldMinify) {
      return JSON.stringify(
        getFromRecordOrNull(componentMapping, componentId)?.component,
      );
    }
    return JSON.stringify(
      getFromRecordOrNull(componentMapping, componentId)?.component,
      null,
      2,
    );
  };

  const getComponent = (componentId: string) => {
    return (
      getFromRecordOrNull(componentMapping, componentId)?.component ?? null
    );
  };

  const onReplaceAllContentWithPlaceholders = (componentId: string) => {
    if (componentId) {
      const componentJson = cloneDeep(
        getFromRecordOrNull(componentMapping, componentId)?.component,
      );
      if (componentJson) {
        componentJson.name = sanitizeComponentName(componentJson.type);
        forEachPrimitiveValue({
          target: componentJson,
          fn: findAndRenameComponentNames,
        });

        forEachPrimitiveValue({
          target: componentJson,
          fn: findAndReplaceAssetUrls,
        });

        forEachPrimitiveValue({
          target: componentJson,
          fn: findAndReplaceTextWithPlaceholderTexts,
        });

        applyComponentAction({
          type: "replaceComponent",
          value: {
            newComponent: componentJson,
            shouldRefreshComponentIdsAndNames: false,
          },
          analyticsExtras: {
            actionType: "other",
            createdBy: "replo",
          },
        });
      }
    }
  };

  // this function pastes a single component from clipboard into the selected component
  const handlePasteSingleComponent = (
    reploClipboard: ReploClipboardSingle,
    pasteOnComponentId: string,
  ) => {
    if (!componentMapping) {
      return;
    }
    const { component: componentFromClipboard, designLibraryMetadata } =
      reploClipboard;

    if (!componentFromClipboard) {
      return;
    }

    const componentWithDesignLibraryReferences =
      handlePasteComponentWithDesignLibraryReferences(
        [componentFromClipboard],
        designLibraryMetadata,
      )[0]!;

    const { newParent, positionWithinSiblings } = findParentForPaste(
      reploClipboard,
      pasteOnComponentId,
    )!;
    const componentIdCopied = componentWithDesignLibraryReferences?.id;
    let { component, oldIdToNewId } = refreshComponentIds(
      componentWithDesignLibraryReferences,
    );

    for (const transform of defaultPasteTransforms) {
      component = transform({
        component,
        productResolutionDependencies,
        parent: newParent,
      });
    }

    const addComponentAction: UseApplyComponentActionType = {
      type: "addComponentToComponent",
      componentId: newParent!.id,
      value: {
        newComponent: component,
        position: "child",
        positionWithinSiblings,
      },
      source: "componentContextMenu",
      analyticsExtras: {
        actionType: "create",
        createdBy: "user",
      },
    };

    const actionsToApply = [
      addComponentAction,
      ...actionsToCopyComponentOverrides({
        oldComponentId: componentIdCopied,
        oldIdToNewId,
      }),
    ];

    applyComponentAction({
      type: "applyCompositeAction",
      value: actionsToApply,
    });

    setDraftElement({
      componentIds: [component.id],
    });
  };

  const getMultipleComponentPasteActions = (
    newComponents: Component[],
    needsNewContainer: boolean,
    newParent: Component,
    positionWithinSiblings: number,
    newContainer: Component,
  ) => {
    const pasteIntoParentId = needsNewContainer
      ? newContainer.id
      : newParent.id;

    const pasteIntoSiblingsOffset = needsNewContainer
      ? 0
      : positionWithinSiblings;
    const actions: UseApplyComponentActionType[] = [];
    if (needsNewContainer) {
      actions.push({
        type: "addComponentToComponent",
        componentId: newParent.id,
        elementId: draftElementId,
        value: {
          newComponent: newContainer,
          position: "child",
          positionWithinSiblings: positionWithinSiblings,
        },
        analyticsExtras: {
          actionType: "create",
          createdBy: "user",
        },
      });
    }

    return actions.concat(
      newComponents.map((newComponent: Component, index: number) => {
        return {
          type: "addComponentToComponent",
          componentId: pasteIntoParentId,
          value: {
            newComponent,
            position: "child",
            positionWithinSiblings: pasteIntoSiblingsOffset + index,
          },
          source: contextMenuSource,
          analyticsExtras: {
            actionType: "create",
            createdBy: "user",
          },
        };
      }),
    );
  };

  // this function pastes multiple components from clipboard into the selected component
  const handlePasteMultipleComponents = (
    reploClipboard: ReploClipboardMultiple,
    pasteOnComponentId: string,
  ) => {
    if (!componentMapping) {
      return;
    }
    const storeState = store.getState();
    const draftElement =
      selectDraftElement_warningThisWillRerenderOnEveryUpdate(storeState);
    const componentDataMapping = selectComponentDataMapping(storeState);
    const { components: componentsFromClipboard, designLibraryMetadata } =
      reploClipboard;

    const components = handlePasteComponentWithDesignLibraryReferences(
      componentsFromClipboard,
      designLibraryMetadata,
    );

    const parent = getParentComponentFromMapping(
      componentMapping,
      components[0]!.id,
    );
    const { newParent, positionWithinSiblings } = findParentForPaste(
      reploClipboard,
      pasteOnComponentId,
    )!;

    let needsNewContainer = false;
    let parentFlexDirection: NormalizedFlexDirection | null = null;

    if (parent) {
      parentFlexDirection = getNormalizedFlexDirection(
        getFlexDirection(parent, getAttributeRef.current),
      );
      const newParentFlexDirection = getNormalizedFlexDirection(
        getFlexDirection(newParent, getAttributeRef.current),
      );

      if (parentFlexDirection !== newParentFlexDirection) {
        needsNewContainer = true;
      }
    }

    const newContainer = prepareComponentTemplate(
      parentFlexDirection === "row"
        ? HORIZONTAL_CONTAINER_COMPONENT_TEMPLATE
        : VERTICAL_CONTAINER_COMPONENT_TEMPLATE,
      parent,
      draftElement,
      {
        getAttribute: getAttributeRef.current,
        productResolutionDependencies,
        context: parent
          ? getCurrentComponentContext(parent.id, 0) ?? null
          : null,
        componentDataMapping,
      },
    );

    let actionsToApply: UseApplyComponentActionType[] = [];

    const newComponents = components.map((_component) => {
      const componentIdCopied = _component?.id;
      let { component, oldIdToNewId } = refreshComponentIds(_component);
      actionsToApply = [
        ...actionsToApply,
        ...actionsToCopyComponentOverrides({
          oldComponentId: componentIdCopied,
          oldIdToNewId,
        }),
      ];

      for (const transform of defaultPasteTransforms) {
        component = transform({
          component,
          productResolutionDependencies,
          parent: newParent,
        });
      }
      return normalizeProductProps(
        component,
        productResolutionDependencies,
        mapNull(parent, (parent) => getCurrentComponentContext(parent.id, 0)) ??
          null,
      );
    });

    const actions = getMultipleComponentPasteActions(
      newComponents,
      needsNewContainer,
      newParent,
      positionWithinSiblings,
      newContainer,
    );

    actionsToApply = [...actions, ...actionsToApply];

    applyComponentAction({
      type: "applyCompositeAction",
      value: actionsToApply,
    });

    const newSelectedIds = newComponents.map((component) => component.id);
    setDraftElement({
      componentIds: newSelectedIds,
    });
  };

  // this function pastes text or svg images from clipboard into the selected component
  const handlePasteCustom = (
    reploClipboard: ReploClipboardText | ReploClipboardImage,
    pasteOnComponentId: string,
  ) => {
    const { component: newComponent } = reploClipboard;
    if (!componentMapping) {
      return;
    }
    const { newParent, positionWithinSiblings } = findParentForPaste(
      reploClipboard,
      pasteOnComponentId,
    )!;

    applyComponentAction({
      type: "addComponentToComponent",
      componentId: newParent!.id,
      value: {
        newComponent,
        position: "child",
        positionWithinSiblings,
      },
      source: "componentContextMenu",
      analyticsExtras: {
        actionType: "create",
        createdBy: "user",
      },
    });

    setDraftElement({
      componentIds: [newComponent.id],
    });
  };

  const handlePasteFigmaStyles = (
    reploClipboard: ReploClipboardFigmaStyles,
  ) => {
    const { rawCss } = reploClipboard;
    if (!rawCss) {
      return;
    }
    applyComponentAction({
      type: "setStylesFromCSS",
      value: rawCss,
    });
  };

  // This function handles pasting from clipboard, clipboard can have
  // single component, multiple components, text or css styles
  const handlePaste = async (
    type: "normal" | "alt",
    clipBoard?: DataTransfer,
  ) => {
    const storeState = store.getState();
    const draftElement =
      selectDraftElement_warningThisWillRerenderOnEveryUpdate(storeState);
    if (!pasteOnComponentId || !draftElement) {
      return null;
    }
    const componentDataMapping = selectComponentDataMapping(storeState);
    const reploClipboard = await getComponentClipboard(
      clipBoard,
      draftElement,
      getAttributeRef.current,
      componentDataMapping,
      productResolutionDependencies,
    );

    if (!reploClipboard) {
      return null;
    }

    // NOTE (Jose, 2024-06-06) We could paste Figma components anywhere, so excluding from this check
    if (
      type === "normal" &&
      !["pasteCSSStyles", "figmaPluginExportv2"].includes(reploClipboard.type)
    ) {
      const isPasteAllowed = await canPaste(pasteOnComponentId, clipBoard);

      if (!isPasteAllowed) {
        return null;
      }
    }

    return exhaustiveSwitch({ type })({
      normal: () => {
        switch (reploClipboard.type) {
          case "singleComponent":
            return handlePasteSingleComponent(
              reploClipboard,
              pasteOnComponentId,
            );

          case "multipleComponents":
            // TODO: handle pasting multiple components from design library
            return handlePasteMultipleComponents(
              reploClipboard,
              pasteOnComponentId,
            );

          case "pasteCSSStyles":
            return handlePasteFigmaStyles(reploClipboard);

          case "text":
            return handlePasteCustom(reploClipboard, pasteOnComponentId);

          case "image":
            return handlePasteCustom(reploClipboard, pasteOnComponentId);

          case "figmaPluginExportv2":
            return handlePasteFigmaPluginv2(reploClipboard, pasteOnComponentId);

          default:
            return null;
        }
      },
      alt: () => {
        switch (reploClipboard.type) {
          case "copyPasteStyles":
            return handlePasteStyles(reploClipboard);

          case "pasteCSSStyles":
            return handlePasteFigmaStyles(reploClipboard);

          default:
            return null;
        }
      },
    });
  };

  const hasParent = (componentId: string) => {
    return (
      getParentComponentFromMapping(componentMapping, componentId) !== null
    );
  };

  const canMoveComponentsToComponent = (
    reploClipboard: ReploClipboard,
    componentId: string,
  ): boolean => {
    const storeState = store.getState();
    const draftElement =
      selectDraftElement_warningThisWillRerenderOnEveryUpdate(storeState);
    const componentDataMapping = selectComponentDataMapping(storeState);
    if (!draftElement) {
      return false;
    }
    const newParent = findParentForPaste(
      reploClipboard,
      componentId,
    )?.newParent;
    if (!newParent) {
      return false;
    }

    const getNewComponent = (template: ComponentTemplate) => {
      return prepareComponentTemplate(template, newParent, draftElement, {
        getAttribute: getAttributeRef.current,
        productResolutionDependencies,
        context: newParent
          ? getCurrentComponentContext(newParent.id, 0) ?? null
          : null,
        componentDataMapping,
      });
    };

    switch (reploClipboard.type) {
      case "multipleComponents": {
        const { components } = reploClipboard;

        const results = components.map((newComponent: Component) => {
          return canMoveComponentToParent(
            draftElement,
            newComponent,
            newParent,
            getAttributeRef.current,
            "contextMenu",
            selectComponentDataMapping(store.getState()),
          ).canMove;
        });
        return results.every(Boolean);
      }
      case "singleComponent": {
        const newComponent = reploClipboard.component;

        return canMoveComponentToParent(
          draftElement,
          newComponent,
          newParent,
          getAttributeRef.current,
          "contextMenu",
          selectComponentDataMapping(store.getState()),
        ).canMove;
      }
      case "text": {
        const newComponent = getNewComponent(text);
        return canMoveComponentToParent(
          draftElement,
          newComponent,
          newParent,
          getAttributeRef.current,
          "contextMenu",
          selectComponentDataMapping(store.getState()),
        ).canMove;
      }
      case "image": {
        const newComponent = getNewComponent(image);
        return canMoveComponentToParent(
          draftElement,
          newComponent,
          newParent,
          getAttributeRef.current,
          "contextMenu",
          selectComponentDataMapping(store.getState()),
        ).canMove;
      }
      default:
        return false;
    }
  };

  const canPaste = async (componentId: string, clipBoard?: DataTransfer) => {
    const storeState = store.getState();
    const draftElement =
      selectDraftElement_warningThisWillRerenderOnEveryUpdate(storeState);
    if (!draftElement) {
      return false;
    }
    const componentDataMapping = selectComponentDataMapping(storeState);
    const reploClipboard = await getComponentClipboard(
      clipBoard,
      draftElement,
      getAttributeRef.current,
      componentDataMapping,
      productResolutionDependencies,
    );
    if (reploClipboard) {
      const componentSelectedForPaste = getFromRecordOrNull(
        componentMapping,
        componentId,
      )?.component;
      if (!componentSelectedForPaste) {
        return false;
      }
      return canMoveComponentsToComponent(reploClipboard, componentId);
    }
    return false;
  };

  const canDelete = (componentId: string) => {
    if (!componentMapping) {
      return false;
    }
    return canDeleteComponent(componentId, componentMapping).canDelete;
  };

  const getAssetComponent = (
    componentId: string,
  ): { component: Component; value: string } | null => {
    const component = getFromRecordOrNull(
      componentMapping,
      componentId,
    )?.component;

    if (component?.type === "image") {
      return {
        component,
        value: getAttributeRef.current(component, "styles.__imageSource").value,
      };
    }

    if (component?.type === "player") {
      return {
        component,
        value: String(component.props.url),
      };
    }

    return null;
  };

  const handleChangeImage = (componentId: string, value: string) => {
    applyComponentAction({
      type: "setStyles",
      componentId: componentId,
      value: {
        __imageSource: value,
      },
      analyticsExtras: {
        actionType: "other",
        createdBy: "replo",
      },
    });
  };

  const handleDuplicate = (componentId: string, source?: string) => {
    const { canDuplicate, message } = canDuplicateComponent(
      componentId,
      componentDataMapping,
    );
    if (!canDuplicate) {
      return errorToast("Unable to duplicate component", message);
    }
    const component = getFromRecordOrNull(
      componentMapping,
      componentId,
    )?.component;
    if (component) {
      const { component: newComponent, oldIdToNewId } = refreshComponentIds(
        cloneDeep(component),
      );

      const duplicateComponentAction: UseApplyComponentActionType = {
        type: "duplicateComponent",
        componentId: componentId,
        newComponent,
        source,
        analyticsExtras: {
          actionType: "create",
          createdBy: "user",
        },
      };

      const actionsToApply = [
        duplicateComponentAction,
        ...actionsToCopyComponentOverrides({
          oldComponentId: componentId,
          oldIdToNewId,
        }),
      ];

      applyComponentAction({
        type: "applyCompositeAction",
        value: actionsToApply,
      });

      setDraftElement({
        componentIds: [newComponent.id],
      });
    }
  };

  const handleOpenModal = (openModalProps: OpenModalPayload) => {
    modal.openModal(openModalProps);
  };

  const forceSentryError = () => {
    setShouldForceSentryError(true);
  };

  const setDraftComponentId = (componentId: string) => {
    setDraftElement({
      componentIds: [componentId],
    });
  };

  const onResetStylesToDefaultState = (
    componentId: string,
    variantId: string,
  ) => {
    applyComponentAction({
      type: "resetStateToDefault",
      variantId,
      componentId,
    });
  };

  const onPushPropsToDefaultState = (
    componentId: string,
    variantId: string,
  ) => {
    applyComponentAction({
      type: "pushOverridePropsToDefault",
      variantId,
      componentId,
    });
  };

  const getStyleProps = (
    componentProps: Record<string, any> | undefined,
  ): Record<string, Record<RuntimeStyleAttribute, string | null>> => {
    // Note (Sebas, 2022-12-02): This function filters every element from the
    // componentProps object an returns an object with props related to styles only.
    return flow([
      Object.entries,
      (arr) => arr.filter(([key]: string[]) => key?.includes("style")),
      Object.fromEntries,
    ])(componentProps ?? {});
  };

  const handleCopyStyles = (componentId: string, activeVariantId?: string) => {
    const component = getFromRecordOrNull(
      componentMapping,
      componentId,
    )?.component;
    if (!component) {
      return;
    }
    const draftElement =
      selectDraftElement_warningThisWillRerenderOnEveryUpdate(store.getState());
    const styleProps = getStyleProps(component.props);
    const applicableCssProperties = getApplicableRuntimeStyleAttributes(
      component.type,
      colorValue,
      textValue,
    );
    // NOTE (Sebas, 2022-12-23): This is in charge of adding all the applicable
    // css properties with a null value. This allows us to merge the styles
    // when we paste them and prevents us from replacing the previous values a
    // component could have. For example, if I copy styles from a container and
    // then I paste them into a text, it shouldn't replace the text relevant styles.
    const completeStyleProps = mapValues(styleProps, (value) => {
      const styles = { ...value };
      applicableCssProperties?.forEach((cssProperty) => {
        if (!styles[cssProperty]) {
          styles[cssProperty] = null;
        }
      });
      return styles;
    });

    if (draftElement) {
      const componentWithVariants = findAncestorComponentOrSelfWithVariants(
        draftElement,
        component.id,
        symbolsMapping,
      );
      const defaultVariant = findDefault(componentWithVariants?.variants ?? []);
      const isDefaultVariant = activeVariantId === defaultVariant?.id;
      if (
        activeVariantId &&
        isNotNullish(defaultVariant) &&
        !isDefaultVariant
      ) {
        const variantOverrideProps =
          componentWithVariants?.variantOverrides?.[activeVariantId]
            ?.componentOverrides?.[componentId]?.props;
        if (!variantOverrideProps) {
          void setComponentClipboard({
            type: "copyPasteStyles",
            styles: JSON.stringify(completeStyleProps),
          });
        } else {
          const stylePropsFromVariantOverrides =
            getStyleProps(variantOverrideProps);
          const mergedStyles = merge(
            {},
            completeStyleProps,
            stylePropsFromVariantOverrides,
          );
          void setComponentClipboard({
            type: "copyPasteStyles",
            styles: JSON.stringify(mergedStyles),
          });
        }
      } else {
        void setComponentClipboard({
          type: "copyPasteStyles",
          styles: JSON.stringify(completeStyleProps),
        });
      }
      successToast(
        "Styles Copied",
        "The component styles have been copied to your clipboard.",
      );
    } else {
      errorToast(
        "Styles not Copied",
        "There was an error copying styles, please reach out to support@replo.app",
      );
    }
  };

  const handlePasteStyles = (clipBoard: ReploClipboardStyles) => {
    const stylesFromClipboard: Record<
      string,
      Record<RuntimeStyleAttribute, string>
    > = JSON.parse(clipBoard.styles);
    applyComponentAction({
      type: "pasteStyles",
      value: stylesFromClipboard,
    });
    if (!isEmpty(stylesFromClipboard)) {
      successToast(
        "Styles Pasted",
        "The component styles have been applied to the selected component.",
      );
    }
  };

  const generateAiCopy = () => {
    setIsAIMenuOpen(true, "rightClick");
  };

  const resetComponentAlignment = (componentId: string) => {
    if (!componentId) {
      return;
    }
    applyComponentAction({
      type: "setStyles",
      componentId,
      value: {
        alignSelf: null,
      },
    });
  };

  const getAlignSelf = (componentId: string, currentCanvas: EditorCanvas) => {
    const component = getFromRecordOrNull(
      componentMapping,
      componentId,
    )?.component;
    if (!component) {
      return;
    }
    return getStyleProps(component?.props)[canvasToStyleMap[currentCanvas]]
      ?.alignSelf;
  };

  const resetStyleOverrides = (
    componentId: string,
    activeVariantId?: string,
  ) => {
    applyComponentAction({
      type: "resetStyleOverrides",
      componentId,
      activeVariantId,
    });
  };

  const setDraftComponentType = (type: Component["type"]) => {
    applyComponentAction({
      type: "unsafeUpdateComponentType",
      componentId: draftComponentId,
      value: type,
      analyticsExtras: {
        actionType: "other",
        createdBy: "replo",
      },
    });
  };

  const turnInto = (args: TurnIntoArgs) => {
    const storeState = store.getState();
    const draftElement =
      selectDraftElement_warningThisWillRerenderOnEveryUpdate(storeState);
    const componentDataMapping = selectComponentDataMapping(storeState);
    exhaustiveSwitch(args)({
      button: () => {
        setDraftComponentType("button");
      },
      product: () => {
        setDraftComponentType("product");
      },
      ticker: (args) => {
        if (!draftElement) {
          return;
        }

        const { actions, newContainerId } =
          generateContextMenuWrapperComponentActions({
            draftElement,
            multipleSelectedNodeIds: args.selectedComponentIds,
            productResolutionDependencies,
            getAttribute: getAttributeRef.current,
            componentDataMapping,
            wrapperType: "ticker",
          });

        applyComponentAction({
          type: "applyCompositeAction",
          value: actions,
        });

        setDraftElement({ componentIds: [newContainerId!] });
      },
      tooltipTrigger: (args) => {
        if (!draftElement) {
          return;
        }

        const { actions, newContainerId } =
          generateContextMenuWrapperComponentActions({
            draftElement,
            multipleSelectedNodeIds: args.selectedComponentIds,
            productResolutionDependencies,
            getAttribute: getAttributeRef.current,
            componentDataMapping,
            wrapperType: "tooltip",
          });

        applyComponentAction({
          type: "applyCompositeAction",
          value: actions,
        });

        setDraftElement({ componentIds: [newContainerId!] });
        dispatch(
          toggleExpandedTreeNode({ id: newContainerId!, isExpanding: true }),
        );
      },
    });
  };

  return {
    setDraftComponentId,
    onDelete,
    handleCopy,
    onGroupContainer,
    onGroupDelete,
    onRename,
    onReplaceComponent,
    onResetStylesToDefaultState,
    onPushPropsToDefaultState,
    getStringFromComponentJson,
    getComponent,
    handlePaste,
    handlePasteFromFigma,
    hasParent,
    canPaste,
    canDelete,
    handleDuplicate,
    handleOpenModal,
    getAssetComponent,
    handleChangeImage,
    forceSentryError,
    handleCopyStyles,
    onPersistStylesToUpstreamDevices,
    generateAiCopy,
    resetComponentAlignment,
    getAlignSelf,
    resetStyleOverrides,
    onReplaceAllContentWithPlaceholders,
    openCodeEditor,
    turnInto,
  };
};

export default useContextMenuActions;
