import * as React from "react";

import classNames from "classnames";
import { BsX } from "react-icons/bs";
import ReactModal from "react-modal";
import { animated, config, useTransition } from "react-spring";
import { twMerge } from "tailwind-merge";

export type ModalController = {
  requestClose: () => void;
};

type ModalComponentProps = {
  children: React.ReactNode;
  isOpen: boolean;
  includesCloseIcon?: boolean;
  closesOnOverlayClick?: boolean;
  onRequestClose?(): void;
  className?: string;
  onEnterKeyPress?(): void;
  style?: React.CSSProperties;
  testId?: string;
  closeButtonClassnames?: string;
};

const Modal = ({
  children,
  isOpen,
  includesCloseIcon = true,
  onRequestClose,
  closesOnOverlayClick = true,
  className,
  style,
  onEnterKeyPress,
  testId,
  closeButtonClassnames,
  ...props
}: ModalComponentProps) => {
  const transitions = useTransition(isOpen, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
    config: config.stiff,
  });

  /**
   * Note (Fran, 2022-04-07): We force mount the modal component and we controlled
   * by the transitions. That's is because we don't use the open prop in the Root
   * component.
   *
   * Here is some open issues for the use of open prop on Root:
   * https://github.com/radix-ui/primitives/issues/1088
   * https://github.com/radix-ui/primitives/issues/1241
   */

  return (
    <ReactModal
      id="ReactModal"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.25)",
        },
      }}
      ariaHideApp={false}
      className={twMerge("Modal", className)}
      overlayClassName="fixed inset-0"
      isOpen={isOpen}
      onRequestClose={() => onRequestClose?.()}
      shouldCloseOnOverlayClick={closesOnOverlayClick}
      testId={testId}
    >
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              id="modal"
              tabIndex={0}
              onKeyDown={({ key }) => {
                if (key === "Enter") {
                  onEnterKeyPress?.();
                }
              }}
              className={twMerge(
                "fixed top-2/4 left-2/4 w-screen rounded-lg bg-white p-6 shadow",
                classNames({
                  "max-w-[250px]": !className && !style?.width,
                }),
                className,
              )}
              style={{
                ...style,
                ...styles,
                transform: "translate(-50%, -50%)",
              }}
              {...props}
            >
              {includesCloseIcon && (
                <button
                  className={twMerge(
                    "z-max absolute right-6 top-6 cursor-pointer",
                    closeButtonClassnames,
                  )}
                  onClick={() => {
                    onRequestClose?.();
                  }}
                  aria-label="Close"
                >
                  <BsX size="24px" className="text-slate-600" />
                </button>
              )}
              {children}
            </animated.div>
          ),
      )}
    </ReactModal>
  );
};

export default Modal;
