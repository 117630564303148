import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

import { transformReviewsTemplateToUseProductForContextIfNecessary } from "../defaultComponentTemplates";

export const looxProductRating: ComponentTemplate = {
  id: "02a4badd-308c-463e-943e-de9dd137a486",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Loox Product Rating",
  leftBarCategory: { main: "productIntegrations", subCategory: "loox" },
  preview: "integrations/loox-product-rating.svg",
  transforms: [transformReviewsTemplateToUseProductForContextIfNecessary],
  tags: ["reviews", "star"],
  template: {
    id: "$uid",
    type: "looxProductRating",
    props: {},
  },
};
