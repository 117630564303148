import * as React from "react";

/**
 * Creates a stable callback function that has access to the latest state and
 * can be used within event handlers and effect callbacks. Throws when used in
 * the render phase. This is effectively a "polyfill" for the unreleased
 * `useEffectEvent`, currently only available in canary and experimental builds
 * of React.
 *
 * @see https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event
 */
export function useEffectEvent<T extends AnyFunction>(
  callback: T | null | undefined,
) {
  const ref = React.useRef<AnyFunction | null | undefined>((): any => {
    throw new Error("Cannot call an event handler while rendering.");
  });
  // NOTE (Chance 2023-09-21): According to the React docs, `useInsertionEffect`
  // callbacks will fire before `useEffect` and `useLayoutEffect` callbacks.
  // Since `useInsertionEffect` isn't something we'll use outside of
  // *potentially* updating JSS stylesheets, it works well in this context to
  // ensure that callbacks fired in layout effects are always synchronized on
  // time.
  React.useInsertionEffect(() => {
    ref.current = callback;
  });
  return React.useCallback<AnyFunction>(
    (...args) => ref.current?.(...args),
    [],
  ) as T;
}

type AnyFunction = (...args: any[]) => any;
