import createImageUrlBuilder from "@sanity/image-url";

const imageBuilder = createImageUrlBuilder({
  projectId: "089m4oq9", // This is public, so no need to hide it
  dataset: process.env.REPLO_SANITY_DATASET ?? "development",
});

export type SanityImageSource = Parameters<typeof imageBuilder.image>[0] & {
  alt?: string;
  quote?: string;
};

export const urlForImage = (source: SanityImageSource) =>
  imageBuilder.image(source).auto("format").fit("max").url();
