import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import useScript from "../../../shared/hooks/useScript";
import {
  GlobalWindowContext,
  RuntimeHooksContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { useProductFromProps } from "../../hooks/useProductFromProps";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

const BuyWithPrimeButton: React.FC<RenderComponentProps> = ({
  componentAttributes: attributes,
  component,
  context,
}) => {
  const { _siteId, _widgetId } = component.props;
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const globalWindow = useRuntimeContext(GlobalWindowContext);
  const product = useProductFromProps(component.props, context);
  const variantSku = product?.variant?.sku;
  const selectedQuantity = product?.quantity;

  const quantity = selectedQuantity ?? 1;
  const updateWidget = React.useCallback(() => {
    if (
      !isEditorCanvas &&
      globalWindow?.bwp?.updateWidget &&
      variantSku &&
      (variantSku !== globalWindow.bwp.sku ||
        quantity !== globalWindow.bwp.quantity)
    ) {
      globalWindow.bwp.updateWidget(variantSku, quantity);
    }
  }, [variantSku, quantity, globalWindow, isEditorCanvas]);

  useScript("https://code.buywithprime.amazon.com/bwp.v1.js", {
    globalWindow,
    forceSkip: isEditorCanvas,
    onLoad: () => {
      // Update widget once on load, since we might be on a PDP and might not
      // have pre-rendered the variant sku
      updateWidget();
    },
  });
  React.useEffect(() => {
    // Update widget any time the variant SKU or quantity changes
    updateWidget();
  }, [updateWidget]);

  const placeholder =
    _siteId && _widgetId
      ? "Buy With Prime Widget will appear here when published."
      : "Once you set your Buy With Prime Site ID and Widget ID in the Config tab, your embedded Buy With Prime Widget will appear here.";
  const liquidSource = `<div
          id="amzn-buy-now"
          data-site-id="${_siteId}"
          data-widget-id="${_widgetId}"
          data-sku="{{ reploSelectedVariant.sku }}"
        />`;
  return (
    <SharedShopifyLiquid
      liquidSource={liquidSource}
      forceEditorPlaceholder={isEditorCanvas}
      componentId={component.id}
      componentAttributes={attributes}
      isLiquidSupported={true}
      placeholder={placeholder}
      repeatedIndexPath={context.repeatedIndexPath}
    />
  );
};

export default BuyWithPrimeButton;
