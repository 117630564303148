import { getTokenFromStorage } from "@editor/infra/auth";

export const setHeaderToken = (headers: Headers) => {
  headers.set("Content-Type", "application/json");
  const token = getTokenFromStorage();

  if (token) {
    headers.set("Authorization", `Token ${token}`);
  }

  return headers;
};
