import * as React from "react";

/**
 *
 * @param target The target DOM node to observe
 * @param callback - Callback to execute when mutations are observed.
 * Since callback is a dependency of useEffect, it should always be stable
 * via useCallback.
 * @param options - Options passed to mutation observer
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
 * @see https://react-hooks-library.vercel.app/core/useMutationObserver
 */
export function useMutationObserver<T extends Node>(
  target: T | null | undefined,
  callback: MutationCallback,
  options: MutationObserverInit = {},
) {
  const { attributes, childList, subtree } = options;
  const observer = React.useRef<MutationObserver | null>(null);

  React.useEffect(() => {
    if (!target) {
      return;
    }

    if (observer.current) {
      observer.current.disconnect();
    }

    const config = { attributes, childList, subtree };
    observer.current = new MutationObserver(callback);
    observer.current.observe(target, config);
    callback([], observer.current);

    return () => {
      if (!observer.current) {
        return;
      }

      observer.current.disconnect();
      observer.current = null;
    };
  }, [target, callback, attributes, childList, subtree]);
}
