import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

import { transformReviewsTemplateToUseProductForContextIfNecessary } from "../defaultComponentTemplates";

export const junipReviews: ComponentTemplate = {
  id: "a6ed9988-4900-4652-813b-1e71ee0f610e",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Junip Product Reviews",
  leftBarCategory: { main: "productIntegrations", subCategory: "junip" },
  preview: "integrations/junip-product-reviews.svg",
  transforms: [transformReviewsTemplateToUseProductForContextIfNecessary],
  template: {
    id: "$uid",
    type: "junipReviews",
    props: {},
  },
};
