import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import {
  RenderEnvironmentContext,
  RuntimeHooksContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import {
  resolveComponentDimensions,
  useAlchemyIframeProcessing,
} from "../../../shared/utils/embed";
import RenderComponentPlaceholder from "../RenderComponentPlaceholder";
import { customProperties } from "./config";

const VimeoEmbed = (props: RenderComponentProps) => {
  const { ref: componentRef, ...componentAttributes } =
    props.componentAttributes;

  const embedHtml = (props.component.props._embedHtml as string) ?? "";
  const hideControls = Boolean(props.component.props._hideControls);
  const componentWidth = componentAttributes.style?.width;
  const componentHeight = componentAttributes.style?.height;
  const wrapperWidth = componentRef.current?.offsetWidth;
  const embedAlignment = componentAttributes.style?.alignSelf;
  const embedParent = componentRef.current?.offsetParent;

  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();

  const initialParentHeight = React.useMemo(() => {
    if (isEditorApp && embedParent) {
      const { height } = embedParent.getBoundingClientRect();
      return height;
    }
    return 0;
  }, [isEditorApp, embedParent]);

  const { iframeProps, aspectRatio, iframeWidth, iframeHeight } =
    useAlchemyIframeProcessing({
      embedHtml,
      hideControls,
      forceMute: isEditorCanvas,
    });

  const { width, height } = resolveComponentDimensions({
    componentWidth,
    componentHeight,
    wrapperWidth,
    iframeWidth,
    iframeHeight,
    aspectRatio,
    embedAlignment,
    embedParent,
    initialParentHeight,
  });

  if (isEditorApp && !embedHtml) {
    return (
      <div {...componentAttributes}>
        <RenderComponentPlaceholder title="Once you set the Vimeo embed code, the Vimeo video will appear here." />
      </div>
    );
  }

  return (
    <div
      {...componentAttributes}
      ref={componentRef}
      style={{
        ...componentAttributes.style,
        [customProperties.width]: width,
        [customProperties.height]: height,
      }}
    >
      <iframe
        width="100%"
        height="100%"
        {...iframeProps}
        key={iframeProps.src}
      />
    </div>
  );
};

export default VimeoEmbed;
