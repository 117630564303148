import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

export const googleMapsEmbed: ComponentTemplate = {
  id: "googleMapsEmbed",
  storeId: null,
  scope: "left-bar",
  type: "component",
  env: Env.PRODUCTION,
  name: "Map/Google Maps Embed",
  leftBarCategory: { main: "pageIntegrations", subCategory: "map" },
  preview: "integrations/google-maps-embed.svg",
  template: {
    id: "5a19a969-5dd5-4f6d-a4ed-6f6a7a43bb12",
    type: "googleMapsEmbed",
    props: {
      style: {
        width: "300px",
        height: "300px",
      },
    },
  },
};
