import type { ComponentTemplate } from "@editor/types/component-template";

import { Env } from "replo-runtime/shared/enums";

const productWithSwatch: ComponentTemplate = {
  id: "7c99a858-ee0f-41c2-aadf-cf8b1903c557",
  storeId: null,
  env: Env.PRODUCTION,
  scope: "left-bar",
  type: "component",
  name: "Product/Product with Swatch",
  leftBarCategory: { main: "product", subCategory: "productLayouts" },
  preview: "product/product-with-swatch.png",
  template: {
    id: "1cb1bdff-c266-4aea-90b2-53f6520a6dfa",
    name: "Product 1",
    type: "product",
    props: {
      style: {
        display: "flex",
        __flexGap: "36px",
        alignSelf: "stretch",
        alignItems: "center",
        justifyContent: "center",
      },
      "style@sm": {
        display: "flex",
        __flexGap: "0px",
        flexDirection: "column",
      },
    },

    children: [
      {
        id: "56de7588-7c60-4875-aad2-9d33dce7af88",
        name: "Image 1",
        type: "image",
        props: {
          style: {
            width: "500px",
            height: "500px",
            __width: "500px",
            __height: "500px",
            flexBasis: 0,
            objectFit: "contain",
            __imageSource: "{{attributes._product.featured_image}}",
          },
          "style@md": {
            width: "362px",
            height: "500px",
            __width: "362px",
            __height: "500px",
            flexGrow: "unset",
            flexBasis: 0,
            objectFit: "contain",
          },
          "style@sm": {
            width: "auto",
            __width: "362px",
            flexGrow: 1,
            alignSelf: "stretch",
            flexBasis: "auto",
            objectFit: "contain",
          },
        },
      },
      {
        id: "36122868-491e-496b-8520-68c026bfd3d0",
        name: "Container 5",
        type: "container",
        props: {
          style: {
            color: "#000000FF",
            display: "flex",
            flexGrow: "1",
            maxWidth: "800px",
            __flexGap: "24px",
            alignSelf: "stretch",
            flexBasis: 0,
            alignItems: "flex-start",
            paddingTop: "24px",
            paddingLeft: "24px",
            paddingRight: "24px",
            flexDirection: "column",
            paddingBottom: "24px",
            justifyContent: "center",
          },
          "style@md": {
            flexGrow: "1",
            maxWidth: "auto",
            flexBasis: 0,
          },
          "style@sm": {
            flexGrow: 1,
            flexBasis: "auto",
          },
        },
        children: [
          {
            id: "149e8fc2-9807-4030-973d-b4d30ac0fdcc",
            type: "text",
            props: {
              text: "<p>{{attributes._product.title}}</p>",
              style: {
                fontSize: "48px",
                fontWeight: "400",
                lineHeight: "48px",
              },
            },
          },
          {
            id: "4a878697-c6ce-4793-8626-88f256f44af3",
            type: "text",
            props: {
              text: "{{attributes._product.description}}",
              style: {
                fontSize: "20px",
                lineHeight: "24px",
              },
            },
          },
          {
            id: "a63e8417-b451-4b5e-b81b-69fa3bff2292",
            name: "Circular Option List",
            type: "optionSelect",
            props: {
              style: {
                display: "flex",
                __flexGap: "12px",
                alignSelf: "stretch",
              },
            },
            children: [
              {
                id: "7266eb36-6cad-4b02-9286-a2592c6d4f09",
                name: "Outer Circle",
                type: "container",
                props: {
                  style: {
                    color: "#FFFFFFFF",
                    width: "50px",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "0.5%",
                    paddingLeft: "0.5%",
                    paddingRight: "0.5%",
                    paddingBottom: "0.5%",
                    borderTopColor: "#000000FF",
                    borderTopStyle: "solid",
                    borderTopWidth: "1px",
                    justifyContent: "center",
                    borderLeftColor: "#000000FF",
                    borderLeftStyle: "solid",
                    borderLeftWidth: "1px",
                    borderRightColor: "#000000FF",
                    borderRightStyle: "solid",
                    borderRightWidth: "1px",
                    borderBottomColor: "#000000FF",
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1px",
                    borderTopLeftRadius: "50%",
                    borderTopRightRadius: "50%",
                    borderBottomLeftRadius: "50%",
                    borderBottomRightRadius: "50%",
                  },
                },
                children: [
                  {
                    id: "21a43bcf-1f84-4b07-8021-fb9b6c028f55",
                    name: "Inner Circle",
                    type: "container",
                    props: {
                      style: {
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor:
                          "{{attributes._currentOption._swatches.Fake Swatch 1.color}}",
                        backgroundImage:
                          "{{attributes._currentOption._swatches.Frame Color.image}}",
                        borderTopLeftRadius: "50%",
                        borderTopRightRadius: "50%",
                        borderBottomLeftRadius: "50%",
                        borderBottomRightRadius: "50%",
                      },
                    },
                    children: [],
                  },
                ],
                variants: [
                  {
                    id: "0bc4842f-de75-4006-8fc4-68393decf6d2",
                    name: "default",
                    query: null,
                    componentOverrides: null,
                  },
                  {
                    id: "c02cab68-3eb5-430e-8801-3ed2da1ede99",
                    name: "Hover",
                    query: {
                      type: "expression",
                      operator: "or",
                      statements: [
                        {
                          id: "18ad1de3-50b3-4a81-901a-bcb487cd4698",
                          field: "interaction.hover",
                        },
                      ],
                    },
                    componentOverrides: null,
                  },
                  {
                    id: "24b1b25e-acc7-45b6-935b-befc6ca6f5f4",
                    name: "Selected",
                    query: {
                      type: "expression",
                      operator: "or",
                      statements: [
                        {
                          id: "b3e03892-5561-4c6a-b18d-40b2c4b3a2a5",
                          field: "state.product.selectedOptionValues",
                          value: null,
                          operator: null,
                        },
                      ],
                    },
                  },
                ],
                variantOverrides: {
                  "24b1b25e-acc7-45b6-935b-befc6ca6f5f4": {
                    componentOverrides: {
                      "7266eb36-6cad-4b02-9286-a2592c6d4f09": {
                        props: {
                          style: {
                            borderColor: null,
                            borderStyle: null,
                            borderWidth: null,
                            borderTopColor: "#000000FF",
                            borderTopStyle: "solid",
                            borderTopWidth: "1px",
                            borderLeftColor: "#000000FF",
                            borderLeftStyle: "solid",
                            borderLeftWidth: "1px",
                            borderRightColor: "#000000FF",
                            borderRightStyle: "solid",
                            borderRightWidth: "1px",
                            borderBottomColor: "#000000FF",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1px",
                          },
                        },
                      },
                    },
                  },
                  "c02cab68-3eb5-430e-8801-3ed2da1ede99": {
                    componentOverrides: {
                      "7266eb36-6cad-4b02-9286-a2592c6d4f09": {
                        props: {
                          style: {
                            borderColor: null,
                            borderStyle: null,
                            borderWidth: null,
                            borderTopColor: "#000000FF",
                            borderTopStyle: "solid",
                            borderTopWidth: "1px",
                            borderLeftColor: "#000000FF",
                            borderLeftStyle: "solid",
                            borderLeftWidth: "1px",
                            borderRightColor: "#000000FF",
                            borderRightStyle: "solid",
                            borderRightWidth: "1px",
                            borderBottomColor: "#000000FF",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1px",
                          },
                        },
                      },
                    },
                  },
                },
              },
            ],
          },
          {
            id: "a574cbe9-c3d9-445d-968d-de1c0f26cf82",
            type: "text",
            props: {
              text: "<p>{{attributes._variant.displayPrice}}</p>",
              style: {
                fontSize: "32px",
                fontWeight: 300,
                lineHeight: "48px",
              },
            },
          },
          {
            id: "0c22091a-4203-49b2-b03b-be1cd1606737",
            name: "Selling Plans List with Radio",
            type: "sellingPlanSelect",
            props: {
              style: {
                display: "flex",
                __flexGap: "15px",
                alignSelf: "stretch",
                flexDirection: "column",
              },
            },
            children: [
              {
                id: "64f68dc5-83cc-43d4-bbd6-9a843d5d8731",
                name: "Selling Plan Container",
                type: "container",
                props: {
                  style: {
                    cursor: "pointer",
                    display: "flex",
                    __flexGap: "15px",
                    alignItems: "center",
                    paddingTop: "5px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    paddingBottom: "5px",
                    justifyContent: "flex-start",
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                    borderBottomLeftRadius: "4px",
                    borderBottomRightRadius: "4px",
                    __animateVariantTransitions: true,
                  },
                  onClick: [],
                },
                children: [
                  {
                    id: "996a25e6-0e17-400f-9cf3-4afb7ccbb008",
                    name: "Selling Plan Radio",
                    type: "container",
                    props: {
                      style: {
                        width: "22px",
                        height: "22px",
                        __width: "22px",
                        display: "flex",
                        __height: "22px",
                        alignItems: "center",
                        borderTopColor: "#000000",
                        borderTopStyle: "solid",
                        borderTopWidth: "1px",
                        justifyContent: "center",
                        borderLeftColor: "#000000",
                        borderLeftStyle: "solid",
                        borderLeftWidth: "1px",
                        borderRightColor: "#000000",
                        borderRightStyle: "solid",
                        borderRightWidth: "1px",
                        borderBottomColor: "#000000",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1px",
                        borderTopLeftRadius: "50%",
                        borderTopRightRadius: "50%",
                        borderBottomLeftRadius: "50%",
                        borderBottomRightRadius: "50%",
                      },
                      _accessibilityRole: "radio",
                      _accessibilityChecked: false,
                    },
                    children: [
                      {
                        id: "5573896c-d883-46ef-8ef7-fe29a3975871",
                        name: "Selling Plan Radio Indicator",
                        type: "container",
                        props: {
                          style: {
                            width: "10px",
                            height: "10px",
                            __width: "10px",
                            display: "flex",
                            opacity: "0%",
                            __height: "10px",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#000000FF",
                            borderTopLeftRadius: "50%",
                            borderTopRightRadius: "50%",
                            borderBottomLeftRadius: "50%",
                            borderBottomRightRadius: "50%",
                          },
                        },
                        children: [],
                      },
                    ],
                    variants: [
                      {
                        id: "09e7442d-e595-4868-a6bf-1c3fbe3fa904",
                        name: "default",
                        query: null,
                        componentOverrides: null,
                      },
                      {
                        id: "63bf2161-55e1-4e6c-833a-06781d62b405",
                        name: "Active",
                        query: {
                          type: "expression",
                          operator: "or",
                          statements: [
                            {
                              id: "e4a4b396-827e-4cc7-a4dd-cef3bcd9a212",
                              field: "state.product.selectedSellingPlan",
                              value: null,
                              operator: null,
                            },
                          ],
                        },
                        componentOverrides: null,
                      },
                    ],
                    variantOverrides: {
                      "63bf2161-55e1-4e6c-833a-06781d62b405": {
                        componentOverrides: {
                          "5573896c-d883-46ef-8ef7-fe29a3975871": {
                            props: {
                              style: {
                                width: "15px",
                                height: "15px",
                                __width: "15px",
                                opacity: "100%",
                                __height: "15px",
                                flexGrow: "unset",
                                backgroundColor: "#FFFFFFFF",
                              },
                            },
                          },
                          "996a25e6-0e17-400f-9cf3-4afb7ccbb008": {
                            props: {
                              style: {
                                width: "20px",
                                height: "20px",
                                __width: "20px",
                                display: "flex",
                                __height: "20px",
                                flexGrow: "unset",
                                alignSelf: "auto",
                                marginTop: "0px",
                                borderColor: null,
                                borderStyle: null,
                                borderWidth: null,
                                flexDirection: "column",
                                borderTopColor: "#FFFFFFFF",
                                borderTopStyle: "solid",
                                borderTopWidth: "1px",
                                borderLeftColor: "#FFFFFFFF",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1px",
                                borderRightColor: "#FFFFFFFF",
                                borderRightStyle: "solid",
                                borderRightWidth: "1px",
                                borderBottomColor: "#FFFFFFFF",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1px",
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                  {
                    id: "87c42b97-5b76-412c-92c8-1f34d9dc0cd1",
                    name: "Selling Plan Information",
                    type: "container",
                    props: {
                      style: {
                        display: "flex",
                        flexGrow: 1,
                        maxWidth: "100%",
                        alignSelf: "stretch",
                        alignItems: "center",
                        justifyContent: "space-between",
                      },
                    },
                    children: [
                      {
                        id: "401d344c-c459-4f32-8aaf-ea71e8598068",
                        name: "Selling Plan Name",
                        type: "text",
                        props: {
                          text: "{{attributes._currentSellingPlan.name}}",
                          style: {
                            fontSize: "16px",
                            fontWeight: 300,
                            lineHeight: "24px",
                          },
                        },
                      },
                    ],
                  },
                ],
                variants: [
                  {
                    id: "e4b0d509-d3cc-4d52-89d8-21deb57f527f",
                    name: "default",
                    query: null,
                    componentOverrides: null,
                  },
                  {
                    id: "dcb75490-f86e-48ee-8224-21956ff4a88b",
                    name: "Selected",
                    query: {
                      type: "expression",
                      operator: "or",
                      statements: [
                        {
                          id: "8e1821de-3d2f-4bea-87fb-91b41957b391",
                          field: "state.product.selectedSellingPlan",
                        },
                      ],
                    },
                  },
                ],
                variantOverrides: {
                  "dcb75490-f86e-48ee-8224-21956ff4a88b": {
                    componentOverrides: {
                      "0e97acae-2fcf-4dd7-b2af-d839b51c4777": {
                        props: {
                          style: {
                            color: null,
                            marginLeft: "0px",
                          },
                        },
                      },
                      "17f75ced-2f15-4c69-8320-a265cafb8578": {
                        props: {
                          style: {
                            color: "#FFFFFFFF",
                          },
                        },
                      },
                      "401d344c-c459-4f32-8aaf-ea71e8598068": {
                        props: {
                          style: {
                            color: "#FFFFFFFF",
                          },
                        },
                      },
                      "4d8e483e-45f7-49a2-b72e-364721a76549": {
                        props: {
                          style: {
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "8px",
                            paddingRight: "8px",
                            justifyContent: "center",
                            backgroundColor: "#00A872",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            borderBottomRightRadius: "10px",
                          },
                        },
                      },
                      "64f68dc5-83cc-43d4-bbd6-9a843d5d8731": {
                        props: {
                          style: {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#000000FF",
                          },
                          onClick: [],
                        },
                      },
                      "d8949bdb-76cd-4720-b30a-cfbf7a9df1e6": {
                        props: {
                          style: {
                            color: "#FFFFFFFF",
                            fontSize: "16px",
                            lineHeight: "24px",
                          },
                        },
                      },
                    },
                  },
                },
              },
            ],
          },
          {
            id: "89fa7b74-e51b-4ca5-ae7b-bb07d6cee9e3",
            type: "button",
            props: {
              style: {
                color: "#FFFFFF",
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "20px",
                paddingTop: "20px",
                paddingLeft: "32px",
                paddingRight: "32px",
                letterSpacing: "2px",
                paddingBottom: "20px",
                backgroundColor: "#000000FF",
              },
              onClick: [
                {
                  id: "$uid",
                  type: "addProductVariantToCart",
                  value: {
                    product: {
                      ref: "attributes._variant",
                      type: "contextRef",
                    },
                    redirectToCart: true,
                    redirectToCheckout: false,
                  },
                },
              ],
            },
            children: [
              {
                id: "4636fb45-4dfc-495a-9a17-ad3613a98740",
                type: "text",
                props: {
                  text: "<p>Add To Cart</p>",
                  style: {
                    color: "#FFFFFF",
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    letterSpacing: "2px",
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
};

export default productWithSwatch;
