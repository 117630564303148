import type { StyleElements } from "../../../shared/utils/renderComponents";
import type { ComponentConfig } from "../../components";

export const styleElements = {
  root: {
    overrideStyles: {
      overflowWrap: "break-word",
    },
  },
} as const satisfies StyleElements;

const config = {
  renderData: {
    newInstancesUseNumbering: false,
    acceptsArbitraryChildren: () => false,
    isAlwaysDynamic: false,
    canvasIndicatorDragDirections: [],
    allowsLayoutModification: false,
    canContainChildren: false,
    styleElements,
  },
} satisfies ComponentConfig;

export default config;
