import * as React from "react";

import {
  SyncRuntimeStateContext,
  useRuntimeContext,
} from "../../shared/runtime-context";

/**
 * Hook for syncing values from runtime to the editor. It doesn't work on the
 * opposite direction, we have shared state for that.
 */
export function useSyncRuntimeValue<T>(
  key: string | string[],
  value: T | null = null,
) {
  const finalKey = Array.isArray(key) ? key.join(".") : key;
  const { setSyncRuntimeState } = useRuntimeContext(SyncRuntimeStateContext);

  React.useEffect(() => {
    // NOTE (Chance 2024-04-12): This is a no-op if the context is not set, so
    // we don't need to check the render environment before calling.
    // `setEditorSyncState` should be stable between renders.
    setSyncRuntimeState({ key: finalKey, value });
  }, [finalKey, value, setSyncRuntimeState]);
}

export default useSyncRuntimeValue;
