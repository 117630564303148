import * as React from "react";

/**
 * NOTE (Chance 2023-10-13): This hook is for creating an object *exactly once*
 * and never updating it. It's useful for cases where you want to create an
 * objec at the component level that you definitely don't want to re-create or
 * re-run. React does not guarantee useMemo or even useState callbacks are not
 * run more than once, hence why you might need something like this instead.
 */
export function useConstant<T>(create: () => T): T {
  const ref = React.useRef<{ value: T }>();
  if (!ref.current) {
    ref.current = { value: create() };
  }
  return ref.current.value;
}
