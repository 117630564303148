import type { FlowSlugs } from "schemas/generated/flow";

import * as React from "react";

import { useGetCurrentFlow } from "@editor/components/flows/hooks/useGetCurrentFlow";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

const useExecuteFlowCallback = (flowSlug?: FlowSlugs) => {
  const { isAuthenticated, isLoading: isLoadingUser } = useCurrentUser();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isDebug = Boolean(searchParams.get("debug"));
  const { isPending: isPendingFlowsQuery } = trpc.flow.getMany.useQuery(
    !isAuthenticated ? skipToken : {},
  );
  const { currentFlow, nextStep } = useGetCurrentFlow({
    isDebug,
  });
  const currentStep = nextStep;

  React.useEffect(() => {
    if (!flowSlug) {
      return;
    }
    exhaustiveSwitch({ type: flowSlug })({
      onboarding: () => {
        // NOTE (Fran 2024-02-23): We should wait until the user and the flow are fetched to have
        // the correct data to navigate to the next step.
        if (currentStep && !isLoadingUser && !isPendingFlowsQuery) {
          navigate({
            pathname: `/flows/${currentFlow?.slug}/${currentStep?.id}`,
            search: searchParams.toString(),
          });
        }
      },
      "editor-tour": () => null,
      "onboarding-ai": () => null,
      "onboarding-styles": () => null,
    });
  }, [
    currentFlow?.slug,
    currentStep,
    flowSlug,
    isPendingFlowsQuery,
    isLoadingUser,
    navigate,
    searchParams,
  ]);
};

export default useExecuteFlowCallback;
